import { ActionType, getType } from 'deox'
import dotProp from 'dot-prop'
import { camelCase } from 'lodash'
import { AnyAction } from 'redux'
import { Channel, channel } from 'redux-saga'
import { all, call, delay, fork, put, select, take } from 'typed-redux-saga'
import { v4 as uuid } from 'uuid'
import { Text } from '../../components/general/text'

import { DELETE, GET, PATCH, POST, PUT } from '../../generators/networking'
import {
    ConvertIndexPathToFieldDetails,
    IMerchantApplicationFieldDetails,
    ResourcePathFieldStructureMap
} from '../../pages/Merchant/Application/Application.Structure'
import { removeFromArray } from '../../utils'
import { ApplicationDataProvidersActions } from '../applicationDataProviders/actions'
import { ApplicationInternalsCollaboratorsActions } from '../applicationInternals/collaborators/actions'
import { ApplicationInternalsStampingActions } from '../applicationInternals/stamping/actions'
import {
    ApplicationsDispatchFetchCutterInfo,
    ApplicationsDispatchMarkCommentAsRead,
    ApplicationsDispatchSendInternalComment,
    ApplicationsDispatchUpdateCutterCommentsInfo
} from '../applications/actions'
import { Person } from '../applications/types'
import { camelCaseWithDots, deoxWaitFor } from '../applications/utils'
import { FilesActionDispatchStore } from '../files/actions'
import { File } from '../files/types'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../toasts/actions'
import { FetchAllPages } from '../utils'
import {
    WatcherDispatchStart,
    WatcherDispatchFail,
    WatcherDispatchSuccess,
    WATCHER_FAIL,
    WATCHER_SUCCESS
} from '../watcher/actions'
import { ApplicationResourceActions } from './actions'
import {
    ApplicationResourceComment,
    ApplicationResourceCommentWithLink,
    ApplicationSectionItemCommentsLoadingPayload,
    MerchantApplicationResourceIndexPath
} from './types'
import { ApplicationResourcePersonConvertRoleBoolsToString, ConvertIndexPathStringToIndexPath } from './utils'

export const GetSegmentsFromApplicationItemPath = (path: string) => {
    const pathSegments = path.split('.')
    const fieldName = pathSegments[pathSegments.length - 1]
    const sectionName = pathSegments[0]
    const sectionPath = pathSegments.slice(0, pathSegments.length - 1).join('.')
    return {
        fieldName,
        sectionPath: sectionPath.replace('.fields', ''),
        sectionName,
        pathSegments
    }
}

const FILES_BY_RESOURCE = Object.keys(ResourcePathFieldStructureMap).reduce((acc, indexPath) => {
    const fieldStructure = ResourcePathFieldStructureMap[indexPath].field
    if (fieldStructure.type === 'file' || fieldStructure.type === 'multipleFiles') {
        if (!acc[indexPath.split('.')[0]]) acc[indexPath.split('.')[0]] = []
        acc[indexPath.split('.')[0]].push(camelCase(fieldStructure.id.mapi))
    }
    return acc
}, {} as any)

const updateRequestBody = (
    body: any,
    indexPath: MerchantApplicationResourceIndexPath,
    fieldStructure: IMerchantApplicationFieldDetails,
    value: string
) => {
    if (indexPath.fieldKey === 'role' && indexPath.resourceKey === 'people') {
        switch (value) {
            case 'director':
                dotProp.set(body, 'role_director', true)
                dotProp.set(body, 'role_owner', false)
                break
            case 'owner':
                dotProp.set(body, 'role_owner', true)
                dotProp.set(body, 'role_director', false)
                break
            case 'director-and-owner':
                dotProp.set(body, 'role_owner', true)
                dotProp.set(body, 'role_director', true)
                break
            default:
                return body
        }
    } else {
        dotProp.set(body, fieldStructure?.field.id.mapi, value)
    }
}

let REQUESTS_CACHER: any = {}
const fixBackendValue = (ip: MerchantApplicationResourceIndexPath, to: string) => {
    if (ip.resourceKey === 'company' && ip.fieldKey === 'form') return to ? to : 'Other'
    return to
}

function* makeAllEditsAndWaitForSuccess(
    id: ActionType<typeof ApplicationResourceActions.MAKE_EDITS>['payload']['id'],
    changes: ActionType<typeof ApplicationResourceActions.MAKE_EDITS>['payload']['changes'],
    watcherId: string
) {
    const index: any = {}
    const edits: any[] = []
    const updateLinksDictionary: {
        [key: string]: {
            watcherId: string
            id: string
            items: {
                indexPath: MerchantApplicationResourceIndexPath
                to: string
                from: string
            }[]
        }
    } = {}

    for (const change of changes) {
        const indexPath = ConvertIndexPathStringToIndexPath(change.key)
        if (!indexPath) throw new Error('Failed to reivew application. Field IndexPath conversion failed.')
        if (indexPath.resourceKey === 'gateway') {
            const watcherId = uuid()
            index[watcherId] = 'started'
            edits.push(ApplicationResourceActions.SELECT_GATEWAY(watcherId, id, change.to))
        } else {
            const sectionDataPath = `applicationResources.data.forApplication.${id}.${indexPath.resourceKey}.fields.${
                indexPath.subsectionIndex || 0
            }`

            const updateLink = yield* select((state: RootState) => dotProp.get(state, `${sectionDataPath}.selfLink`))
            const previousValue = yield* select((state: RootState) =>
                dotProp.get(state, `${sectionDataPath}.${indexPath.fieldKey}`)
            )
            if (!updateLinksDictionary[updateLink]) {
                const watcherId = uuid()
                index[watcherId] = 'started'
                updateLinksDictionary[updateLink] = { watcherId, id, items: [] }
            }
            updateLinksDictionary[updateLink].items.push({
                to: fixBackendValue(indexPath, change.to),
                indexPath,
                from: previousValue
            })
        }
    }

    const linksToUpdate = Object.keys(updateLinksDictionary)
    for (const link of linksToUpdate) {
        const updateData = updateLinksDictionary[link]
        edits.push(ApplicationResourceActions.EDIT_ITEMS(updateData.watcherId, updateData.id, link, updateData.items))
    }
    // edits.push(ApplicationResourceActions.EDIT_ITEM(watcherId, indexPath, id, change.to, true))

    yield all(edits.map((a) => put(a)))

    let watcherAction: any = undefined
    do {
        watcherAction = yield* take([WATCHER_FAIL, getType(ApplicationResourceActions.STORE_FIELD_HISTORY)])
        if (watcherAction.type === WATCHER_FAIL) {
            const watcherId = watcherAction.watchers[0]
            index[watcherId] = 'fail'
        } else {
            index[watcherAction.payload.watcherId] = 'success'
        }
    } while (Object.keys(index).filter((w) => index[w] === 'started').length !== 0)

    let foundAnyErrors = false
    if (Object.keys(index).filter((w) => index[w] === 'fail').length !== 0) {
        foundAnyErrors = true
        // yield put(WatcherDispatchFail([watcherId], 'Failed to update some of the fields'))
    }

    if (Object.keys(index).filter((w) => index[w] === 'success').length !== 0) {
        // yield put(ToastsDispatchPush('Review has been partially successful.', 'success'))
        if (foundAnyErrors) {
            yield put(
                WatcherDispatchFail(
                    [watcherId],
                    'Application review has only been partially successful.',
                    <Text>
                        Some of the edits were made, <Text bold>but no messages have been sent,</Text> please see the
                        errors above.
                    </Text>
                )
            )
            throw new Error('Stop flow here.')
        }
    } else {
        yield put(
            WatcherDispatchFail(
                [watcherId],
                'Application review has completely failed.',
                <Text>No edits were made and no messages have been sent.</Text>
            )
        )
        throw new Error('Stop flow here.')
    }

    yield put(WatcherDispatchSuccess([watcherId], 'Application updated successfully.'))
}

export const ApplicationResourceSagas = {
    *CLEANUP({ payload: p }: ActionType<typeof ApplicationResourceActions.CLEANUP>) {
        REQUESTS_CACHER = {}
    },

    *MAKE_EDITS({ payload: p }: ActionType<typeof ApplicationResourceActions.MAKE_EDITS>) {
        yield put(WatcherDispatchStart([p.watcherId]))
        const waitForWatchers: string[] = []

        try {
            const actions: AnyAction[] = []

            yield makeAllEditsAndWaitForSuccess(p.id, p.changes, uuid())

            const steps = yield* select((state: RootState) => state.interface.applications[p.id]?.steps)

            if (p.messages && !steps?.['merchant-message']?.isCancelled) {
                const fieldPath = yield* select(
                    (state: RootState) => state.interface.applications?.[p.id].merchantCommentField?.indexPathString
                )
                if (!fieldPath) throw 'Failed to get merchant comment field'
                const indexPath = ConvertIndexPathStringToIndexPath(fieldPath)
                if (!indexPath) throw new Error('Failed to convert merchant field field path to indexPath')
                if (!fieldPath) throw new Error('No fieldPath found for merchant comment.')
                const [section, index, ...rest] = fieldPath.split('.')
                const commentsLink = yield* select((state: RootState) =>
                    dotProp.get(
                        state,
                        `applicationResources.data.forApplication.${p.id}.${section}.fields.${index}.commentsLink`
                    )
                )
                if (!commentsLink) throw new Error('Not commentsLink found for field.')
                if (!p.messages['merchant-message']) throw new Error('Merchant message is empty.')

                const watcherId = uuid()
                waitForWatchers.push(watcherId)
                actions.push(
                    ApplicationResourceActions.SEND_COMMENT(watcherId, p.id, p.messages['merchant-message'], indexPath)
                )
            }

            if (p.messages && !steps?.['internal-note']?.isCancelled) {
                const internalNoteLink = yield* select(
                    (state: RootState) => state.applications.applications.at[p.id]?.internalInteractions.commentsLink
                )
                if (!internalNoteLink) throw new Error('Not internal note link found.')

                if (!p.messages['internal-note']) throw new Error('Internal note is empty.')

                const watcherId = uuid()
                waitForWatchers.push(watcherId)
                actions.push(
                    ApplicationsDispatchSendInternalComment(
                        p.messages['internal-note'],
                        internalNoteLink,
                        p.id,
                        watcherId,
                        'message'
                    )
                )
            }

            if (steps?.['add-collaborator']) {
                if (
                    steps['add-collaborator'].isSuggested &&
                    !steps['add-collaborator'].isCancelled &&
                    steps['add-collaborator'].email &&
                    steps['add-collaborator'].name
                ) {
                    if (!p.messages['add-collaborator']) throw new Error('Add collaborator message is empty.')

                    const watcherId = uuid()
                    waitForWatchers.push(watcherId)
                    actions.push(
                        ApplicationInternalsCollaboratorsActions.INVITE(
                            watcherId,
                            p.id,
                            steps['add-collaborator'].email,
                            steps['add-collaborator'].name,
                            p.messages['add-collaborator']
                        )
                    )
                }
            }

            if (steps?.['remove-collaborator']) {
                if (
                    steps['remove-collaborator'].isSuggested &&
                    !steps['remove-collaborator'].isCancelled &&
                    steps['remove-collaborator'].selfLink &&
                    steps['remove-collaborator'].email
                ) {
                    const watcherId = uuid()
                    waitForWatchers.push(watcherId)
                    actions.push(
                        ApplicationInternalsCollaboratorsActions.REVOKE(
                            watcherId,
                            p.id,
                            steps['remove-collaborator'].selfLink,
                            steps['remove-collaborator'].email
                        )
                    )
                }
            }

            yield* put(ApplicationInternalsStampingActions.REFETCH(p.id))
            yield* put(ApplicationDataProvidersActions.FETCH(p.id))
            yield* put(ApplicationDataProvidersActions.SEARCH_FOR_MATCHES(p.id))

            yield* fork(function* () {
                let action: any = undefined
                while (waitForWatchers.length) {
                    action = yield* take([WATCHER_SUCCESS, WATCHER_FAIL])
                    const watcherId = action?.watchers?.[0]
                    if (watcherId && waitForWatchers.includes(watcherId)) {
                        removeFromArray(waitForWatchers, watcherId)
                    }
                }

                yield put(WatcherDispatchSuccess([p.watcherId], 'All steps completed successfully.'))
            })
            yield* all(actions.map((a) => put(a)))
        } catch (e) {
            console.error(e)
        }
    },

    *EDIT_ITEMS({ payload: p }: ActionType<typeof ApplicationResourceActions.EDIT_ITEMS>) {
        yield* put(WatcherDispatchStart([p.watcherID]))
        const userUuid = yield* select(
            (state: RootState) => state.auth.user?.['https://clearhaus.com/app_metadata'].uuid
        )

        const body = {}
        for (const item of p.items) {
            const fieldStructure = ConvertIndexPathToFieldDetails(item.indexPath)

            if (!fieldStructure?.field.id.mapi) {
                yield* put(
                    WatcherDispatchFail([p.watcherID], `Field structure is missing the mapi link ${fieldStructure}`)
                )
                throw Error(`Field structure is missing the mapi link ${fieldStructure}`)
            }

            updateRequestBody(body, item.indexPath, fieldStructure, item.to)
        }

        let success: any = undefined
        try {
            success = (yield* PATCH({
                url: p.link,
                successCode: 200,
                body,
                successText: 'Item edit successfully',
                errorText: 'Item edit failed'
            }))?.success
        } catch (e) {
            console.error('Caught the error:', e)
        }

        if (success) {
            for (const item of p.items) {
                yield* put(
                    ApplicationResourceActions.STORE_FIELD_HISTORY(
                        item.indexPath,
                        p.applicationId,
                        {
                            by: userUuid,
                            at: new Date().toString(),
                            from: item.from,
                            to: item.to,
                            id: 'LOCAL_CHANGE'
                        },
                        p.watcherID
                    )
                )
            }
            yield put(WatcherDispatchSuccess([p.watcherID]))
            // Disable comment sending for edits, for now
        } else {
            yield put(WatcherDispatchFail([p.watcherID]))
        }
    },

    *SELECT_GATEWAY({ payload: p }: ActionType<typeof ApplicationResourceActions.SELECT_GATEWAY>) {
        const gatewaysPath = `applicationResources.data.forApplication.${p.applicationId}.gateway.fields.0.selectable`
        // eslint-disable-next-line max-len
        const gatewaysHistoryPath = `applicationResources.history.forApplication.${p.applicationId}.gateway.fields.0.gateways.0.id`
        const gateway = (yield* select((state: RootState) => dotProp.get(state, gatewaysPath))).filter(
            (gateway: any) => gateway.id === p.value
        )[0]
        const previousValues = yield* select((state: RootState) => dotProp.get(state, `${gatewaysHistoryPath}`))
        const lastValue = previousValues?.[previousValues?.length - 1]?.to

        const userUuid = yield* select(
            (state: RootState) => state.auth.user?.['https://clearhaus.com/app_metadata'].uuid
        )

        if (!gateway) throw `Couldn't find gateway with ID ${p.value}`

        const { success } = yield* PUT({
            watcher: p.watcherID,
            url: gateway.selectionLink,
            successCode: 204,
            successText: 'Gateway selected successfully',
            errorText: 'Gateway selection failed.'
        })

        if (success) {
            yield put(
                ApplicationResourceActions.STORE_FIELD_HISTORY(
                    {
                        resourceKey: 'gateway',
                        subsectionIndex: 0,
                        fieldKey: 'gateways.0.id'
                    },
                    p.applicationId,
                    {
                        by: userUuid,
                        at: new Date().toString(),
                        from: lastValue,
                        to: p.value,
                        id: 'LOCAL_CHANGE'
                    },
                    p.watcherID
                )
            )
        }
    },

    *SEND_COMMENT({ payload: p }: ActionType<typeof ApplicationResourceActions.SEND_COMMENT>) {
        const commentsLink = yield* select((state: RootState) => {
            return state.applicationResources.comments.forApplication?.[p.applicationId]?.[p.indexPath.resourceKey]
                ?.fields?.[p.indexPath.subsectionIndex || 0]?.[p.indexPath.fieldKey]?.commentsLink
        })
        const sectionCommentsLink = yield* select((state: RootState) => {
            return state.applicationResources.data.forApplication?.[p.applicationId]?.[p.indexPath.resourceKey]
                ?.fields?.[p.indexPath.subsectionIndex || 0]?.commentsLink
        })

        if (!commentsLink && !sectionCommentsLink) {
            yield put(ToastsDispatchPush(`Comments link not found for section ${p.indexPath.resourceKey}`, 'error'))
            throw `Comments link not found for section ${p.indexPath.resourceKey}`
        }

        const fieldDetails = ConvertIndexPathToFieldDetails(p.indexPath)

        if (!fieldDetails) throw `Failed to map fieldPath ${JSON.stringify(p.indexPath)} to it's fieldDetails.`

        const body: any = {
            body: p.body
        }

        if (fieldDetails.field.type !== 'file' && fieldDetails.field.type !== 'multipleFiles') {
            if (p.indexPath.resourceKey === 'gateway' && p.indexPath.fieldKey === 'gateways.0.id')
                body.tags = [`ch-field:name`]
            else body.tags = [`ch-field:${fieldDetails.field.id.mapi}`]
        }

        const { success, cleanedResponse } = yield* POST({
            watcher: p.watcherID,
            url: commentsLink || sectionCommentsLink,
            successCode: 201,
            include: ['self'],
            body,
            successText: 'Comment sent successfully.',
            errorText: 'Comment failed to send'
        })

        if (success) {
            yield put(ApplicationResourceActions.STORE_COMMENT(p.indexPath, p.applicationId, cleanedResponse))
            yield put(ApplicationsDispatchMarkCommentAsRead(p.applicationId, cleanedResponse.id, true))
            yield put(ApplicationsDispatchUpdateCutterCommentsInfo(p.applicationId))
        } else {
            yield put(ToastsDispatchPush(`Comments link not found for section ${p.indexPath.resourceKey}`, 'error'))
            throw `Failed to send the comment`
        }
    },

    *REMOVE_COMMENT({ payload: p }: ActionType<typeof ApplicationResourceActions.REMOVE_COMMENT>) {
        const cutterCommentsInfo = yield* select(
            (state: RootState) =>
                state.applications.cutterCommentsInfo?.forApplication?.[p.applicationId]?.[p.commentId]
        )

        yield POST({
            watcher: p.watcherID,
            url: cutterCommentsInfo.cutterSelfLink,
            successCode: 200,
            body: {
                deleted: true
            },
            onSuccessDispatch: (r) => {
                return ApplicationResourceActions.UNSTORE_COMMENT(p.commentId, p.indexPath, p.applicationId)
            },
            successText: 'Comment removed successfully',
            errorText: 'Comment failed to remove comment'
        })
    },

    *STORE({ payload: p }: ActionType<typeof ApplicationResourceActions.STORE>) {
        const sectionCommentsLoadingChannel: Channel<any> = yield call(channel)
        if (p.fields?.length) {
            for (let subsectionIndex = 0; subsectionIndex < p.fields.length; subsectionIndex += 1) {
                if (p.resourceKey !== 'accounts') {
                    yield put(
                        ApplicationResourceActions.LOAD_FILES(
                            p.applicationId,
                            p.resourceKey,
                            subsectionIndex,
                            p.fields?.[subsectionIndex]?.filesLink,
                            sectionCommentsLoadingChannel
                        )
                    )
                    if (p.fields?.[subsectionIndex]?.comments) {
                        yield put(sectionCommentsLoadingChannel, {
                            loadingStatus: 'started',
                            subsectionIndex,
                            sectionKey: p.resourceKey
                        })

                        yield put(sectionCommentsLoadingChannel, {
                            loadingStatus: 'done',
                            sectionKey: p.resourceKey,
                            commentsLink: p.fields[subsectionIndex].commentsLink,
                            comments: p.fields[subsectionIndex].comments
                        })
                    } else if (p.fields?.[subsectionIndex]?.commentsLink) {
                        yield put(
                            ApplicationResourceActions.FETCH_COMMENTS(
                                p.applicationId,
                                p.resourceKey,
                                subsectionIndex,
                                p.fields[subsectionIndex].commentsLink,
                                undefined,
                                sectionCommentsLoadingChannel
                            )
                        )
                    }
                }
            }
        } else {
            yield put(ApplicationResourceActions.STORE_COMMENTS(p.applicationId, p.resourceKey, 0, []))
            yield put(ApplicationResourceActions.LINK_FILES(p.applicationId, p.resourceKey, 0, []))
        }

        let sectionLoadingRequestsCount = 0
        const sectionComments: { [sectionIndex: number]: any } = {}

        while (true) {
            const payload: ApplicationSectionItemCommentsLoadingPayload = yield* take(sectionCommentsLoadingChannel)

            if (payload.loadingStatus === 'started') {
                sectionLoadingRequestsCount += 1
            }

            if (payload.loadingStatus === 'done') {
                sectionLoadingRequestsCount -= 1
                const comments = payload.comments.map((c) => ({
                    ...c,
                    commentsLink: payload.commentsLink
                }))
                sectionComments[payload.subsectionIndex || 0] = [
                    ...(sectionComments[payload.subsectionIndex || 0]
                        ? sectionComments[payload.subsectionIndex || 0]
                        : []),
                    ...comments
                ]
            }
            if (sectionLoadingRequestsCount === 0) {
                // eslint-disable-next-line no-restricted-syntax
                for (const k of Object.keys(sectionComments)) {
                    const commentsBasedOnTheFetchedResource = sectionComments[parseInt(k, 10)]
                    const commentsBoundToThisSection: ApplicationResourceCommentWithLink[] = []

                    // Because some sections share the same resource link
                    // Application -> Business Model & Application -> Signer
                    // some comments might be re-fetched for each of those sections, even if they don't belong here
                    // this maps each comment tag to a field from the applicationStructure, and appends it to their
                    // corresponding section only if it actually belongs there

                    for (const comment of commentsBasedOnTheFetchedResource) {
                        const mapCommentPathToApplicationStructure = `${payload.resourceKey}.${comment.tags[0].replace(
                            'ch-field:',
                            ''
                        )}`

                        if (ResourcePathFieldStructureMap[camelCaseWithDots(mapCommentPathToApplicationStructure)]) {
                            commentsBoundToThisSection.push(comment)
                        }
                    }

                    yield put(
                        ApplicationResourceActions.STORE_COMMENTS(
                            p.applicationId,
                            payload.resourceKey,
                            parseInt(k, 10),
                            commentsBoundToThisSection
                        )
                    )
                }
            }
        }
    },
    *UNLINK_FILES({ payload: p }: ActionType<typeof ApplicationResourceActions.UNLINK_FILES>) {
        const hasApplicationLoaded = yield* select(
            (state: RootState) => state.applicationLoading?.at?.[p.applicationId]?.fieldWithMostComments
        )
        if (hasApplicationLoaded) yield put(ApplicationInternalsStampingActions.REFETCH(p.applicationId))
    },
    *LINK_FILES({ payload: p }: ActionType<typeof ApplicationResourceActions.LINK_FILES>) {
        const hasApplicationLoaded = yield* select(
            (state: RootState) => state.applicationLoading?.at?.[p.applicationId]?.fieldWithMostComments
        )
        if (hasApplicationLoaded) yield put(ApplicationInternalsStampingActions.REFETCH(p.applicationId))
    },
    *LOAD_FILES({ payload: p }: ActionType<typeof ApplicationResourceActions.LOAD_FILES>) {
        if (!p.sectionCommentsLoadingChannel) throw Error('No comments loading channel found.')

        // Stall the channel until the file comments links are loaded
        yield put(p.sectionCommentsLoadingChannel, {
            loadingStatus: 'started',
            subsectionIndex: p.subsectionIndex,
            resourceKey: p.resourceKey
        })

        const files = yield* FetchAllPages<File>(
            ["peopleHistory", "people", "websites", "websitesHistory", "company"].includes(p.resourceKey) ?
                p?.filesLink+"/version-history" : p?.filesLink,
            (acc, res) => {
                return [...acc, ...res.files]
            },
            ['self', 'data', 'comments']
        )

        // eslint-disable-next-line no-continue
        if (files) {
            yield put(FilesActionDispatchStore(files))
            yield put(ApplicationResourceActions.LINK_FILES(p.applicationId, p.resourceKey, p.subsectionIndex, files))

            // eslint-disable-next-line no-restricted-syntax
            for (const file of files) {
                if (!file.label) break
                if (!FILES_BY_RESOURCE[p.resourceKey]) break
                if (FILES_BY_RESOURCE[p.resourceKey].includes(camelCase(file.label))) {
                    yield put(
                        ApplicationResourceActions.FETCH_COMMENTS(
                            p.applicationId,
                            p.resourceKey,
                            p.subsectionIndex,
                            file.commentsLink,
                            file.label,
                            p.sectionCommentsLoadingChannel
                        )
                    )
                }
            }
        } else {
            yield put(ApplicationResourceActions.LINK_FILES(p.applicationId, p.resourceKey, p.subsectionIndex, []))
        }

        // Let the channel run
        yield put(p.sectionCommentsLoadingChannel, {
            loadingStatus: 'done',
            commentsLink: '',
            comments: [],
            subsectionIndex: p.subsectionIndex,
            resourceKey: p.resourceKey
        })
    },

    *FETCH_COMPANY({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_COMPANY>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'company'))
        const response = yield* GET({
            url: p.url,
            errorText: "Failed to load application's company section.",
            include: ['people', 'self', 'files', 'comments']
        })
        yield put(ApplicationResourceActions.STORE(p.applicationId, 'company', response?.selfLink, [response]))
    },

    *FETCH_BANK_ACCOUNT({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_BANK_ACCOUNT>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'bankAccount'))
        const response = yield* GET({
            url: p.url,
            errorText: "Failed to load application's bank Account section.",
            include: ['self', 'files', 'comments']
        })

        if (response)
            yield put(ApplicationResourceActions.STORE(p.applicationId, 'bankAccount', response.selfLink, [response]))
    },

    *FETCH_CONTACT({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_CONTACT>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'contact'))
        const response = yield* GET({
            url: p.url,
            errorText: "Failed to load application's contact section.",
            include: ['self', 'files', 'comments']
        })

        yield put(ApplicationResourceActions.STORE(p.applicationId, 'contact', response.selfLink, [response]))
    },

    *FETCH_GATEWAY({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_GATEWAY>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'gateway'))

        const response = yield* GET({
            url: `${import.meta.env.VITE_API_ROOT}/applications/${p.applicationId}/gateways`,
            errorText: "Failed to load application's gateway section.",
            include: ['self', 'selection', 'files', 'comments']
        })

        if (response?.gateways?.[0]?.selfLink) {
            const selectedGateway = yield* GET({
                url: response.gateways[0].selfLink,
                errorText: 'Failed to load selected gateway details.',
                include: ['self', 'selection', 'files', 'comments']
            })

            selectedGateway?.comments?.forEach((comment: ApplicationResourceComment) => {
                comment.tags[0] = comment.tags[0].replace(':name', ':gateways.0.id')
            })
            yield put(
                ApplicationResourceActions.STORE(p.applicationId, 'gateway', response.selfLink, [
                    {
                        ...(response.gateways?.[0]
                            ? {
                                  gateways: [
                                      {
                                          id: response.gateways[0].id
                                      }
                                  ]
                              }
                            : {}),
                        comments: selectedGateway.comments,
                        commentsLink: selectedGateway.commentsLink,
                        selectable: response.selectable,
                        selfLink: response.selfLink
                    }
                ])
            )
        } else {
            yield put(
                ApplicationResourceActions.STORE(p.applicationId, 'gateway', response.selfLink, [
                    {
                        selectable: response.selectable,
                        selfLink: response.selfLink
                    }
                ])
            )
        }
    },
    *FETCH_WEBSITES({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_WEBSITES>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'websites'))
        const response = yield* GET({
            url: `${import.meta.env.VITE_API_ROOT}/applications/${p.applicationId}/websites`,
            errorText: "Failed to load application's websites section.",
            include: ['self', 'files', 'comments', 'next']
        })

        // eslint-disable-next-line no-restricted-syntax
        yield put(ApplicationResourceActions.STORE(p.applicationId, `websites`, response?.selfLink,
            response?.websites, response?.nextLink))
    },
    *FETCH_MORE_WEBSITES({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_MORE_WEBSITES>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'websites'))
        const response = yield* GET({
            url: p.nextLink,
            errorText: "Failed to load application's websites section.",
            include: ['self', 'files', 'comments', 'next']
        })

        // eslint-disable-next-line no-restricted-syntax
        yield put(ApplicationResourceActions.STORE(p.applicationId, `websites`, response?.selfLink,
            [...response?.websites, ...p.websites], !response.nextLink ? undefined : response.nextLink))
    },
    *FETCH_WEBSITES_HISTORY({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_WEBSITES_HISTORY>) {
        const url = `${import.meta.env.VITE_API_ROOT}/applications/${p.applicationId}/websites/version-history`
        const response = yield* GET({
            url: url,
            errorText: "Failed to load application's websites section.",
            include: ['self', 'files']
        })
        // eslint-disable-next-line no-restricted-syntax
        yield put(ApplicationResourceActions.STORE(p.applicationId, 
            `websitesHistory`, url, response.websites))
    },

    *FETCH_PEOPLE({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_PEOPLE>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'people'))
        const response = yield* GET({
            url: p.url,
            errorText: "Failed to load application's websites section.",
            include: ['self', 'files', 'comments']
        })
        cleanMAPIPeopleResponse(response)
        // eslint-disable-next-line no-restricted-syntax
        yield put(ApplicationResourceActions.STORE(p.applicationId, `people`, response.selfLink, response.people))
    },

    *FETCH_PEOPLE_HISTORY({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_PEOPLE_HISTORY>) {
        const response = yield* GET({
            url: p.url,
            errorText: "Failed to load application's people history section.",
            include: ['files']
        })
        cleanMAPIPeopleResponse(response)
        // eslint-disable-next-line no-restricted-syntax
        yield put(ApplicationResourceActions.STORE(p.applicationId, 
            `peopleHistory`, p.url, response.people))
    },

    *STORE_SIGNER({ payload: p }: ActionType<typeof ApplicationResourceActions.STORE_SIGNER>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'signer'))
        const signerResource: any = { signer: p.signer || {} }

        if (p.application?.selfLink) signerResource.selfLink = p.application.selfLink
        if (p.application?.commentsLink) signerResource.commentsLink = p.application.commentsLink

        yield put(
            ApplicationResourceActions.STORE(p.applicationId, 'signer', signerResource.selfLink, [
                { ...signerResource }
            ])
        )
    },

    *STORE_ADDITIONAL({ payload: p }: ActionType<typeof ApplicationResourceActions.STORE_ADDITIONAL>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'additionalInformation'))
        const additionalResource = p.additionalInfo
        additionalResource.filesLink = p.application.filesLink
        additionalResource.selfLink = p.application.selfLink
        additionalResource.commentsLink = p.application.commentsLink

        yield put(
            ApplicationResourceActions.STORE(p.applicationId, 'additionalInformation', additionalResource.selfLink, [
                additionalResource
            ])
        )
    },

    *STORE_BUSINESS_MODEL({ payload: p }: ActionType<typeof ApplicationResourceActions.STORE_BUSINESS_MODEL>) {
        yield put(ApplicationResourceActions.BOOTSTRAP(p.applicationId, 'businessModel'))
        const businessModelResource: any = { businessModel: p.businessModel }
        businessModelResource.selfLink = p.application.selfLink
        businessModelResource.commentsLink = p.application.commentsLink
        yield put(
            ApplicationResourceActions.STORE(p.applicationId, 'businessModel', businessModelResource.selfLink, [
                businessModelResource
            ])
        )
    },

    *FETCH_COMMENTS({ payload: p }: ActionType<typeof ApplicationResourceActions.FETCH_COMMENTS>) {
        if (!p.sectionCommentsLoadingChannel) throw Error('Comments loading channel not available')
        yield put(p.sectionCommentsLoadingChannel, {
            loadingStatus: 'started',
            subsectionIndex: p.subsectionIndex,
            resourceKey: p.resourceKey
        })

        try {
            if (!REQUESTS_CACHER[p.applicationId]) REQUESTS_CACHER[p.applicationId] = {}

            if (REQUESTS_CACHER[p.applicationId]?.[p.commentsLink]) {
                if (REQUESTS_CACHER[p.applicationId][p.commentsLink] == 'WAITING-TO-BE-FETCHED') {
                    while (true) {
                        yield delay(16)
                        if (REQUESTS_CACHER[p.applicationId][p.commentsLink] !== 'WAITING-TO-BE-FETCHED') {
                            break
                        }
                    }
                }
            } else {
                REQUESTS_CACHER[p.applicationId][p.commentsLink] = 'WAITING-TO-BE-FETCHED'
                REQUESTS_CACHER[p.applicationId][p.commentsLink] = yield* FetchAllPages<{ [key: string]: any }>(
                    p.commentsLink,
                    (acc, res) => {
                        return [...acc, ...res.comments]
                    },
                    ['self', 'files', 'comments', 'person'],
                    50
                )
            }

            const commentsLinkBody = REQUESTS_CACHER[p.applicationId]?.[p.commentsLink]

            const request = JSON.parse(JSON.stringify(commentsLinkBody))

            if (p.overwriteTagWith) {
                // eslint-disable-next-line no-unused-expressions
                ;(request as ApplicationResourceComment[])?.forEach((c) => {
                    c.tags = [`ch-field:${p.overwriteTagWith}`]
                })
            }

            if (!p.sectionCommentsLoadingChannel) throw Error('Comment loading channel not available.')

            yield put(p.sectionCommentsLoadingChannel, {
                loadingStatus: 'done',
                commentsLink: p.commentsLink,
                subsectionIndex: p.subsectionIndex || 0,
                resourceKey: p.resourceKey,
                comments: request || []
            })
        } catch (e) {}
    },

    *ADD_SUBSECTION({ payload: p }: ActionType<typeof ApplicationResourceActions.ADD_SUBSECTION>) {
        const url = yield* select(
            (state: RootState) =>
                state.applicationResources.data.forApplication[p.applicationId]?.[p.sectionKey]?.selfLink
        )

        if (!url) yield put(ToastsDispatchPush(`Found no URL for this ${p.subsectionLabel}`, 'error'))

        yield put(WatcherDispatchStart([p.watcherId]))

        const { success, cleanedResponse } = yield* POST({
            // watcher: p.watcherId,
            url,
            body: {},
            include: ['self', 'comments', 'files'],
            successText: `New ${p.subsectionLabel} added sucesfully.`,
            errorText: `Failed create the new ${p.subsectionLabel}.`
        })

        if (success) {
            yield put(ApplicationInternalsStampingActions.REFETCH(p.applicationId))
            yield put(ApplicationsDispatchFetchCutterInfo(p.applicationId, p.watcherId))
            yield deoxWaitFor(
                ApplicationResourceActions.STORE_FIELDS_HISTORY,
                (payload) => payload.applicationId === p.applicationId
            )

            yield put(
                ApplicationResourceActions.STORE_SUBSECTION(p.applicationId, p.sectionKey, {
                    id: cleanedResponse.id,
                    commentsLink: cleanedResponse.commentsLink,
                    selfLink: cleanedResponse.selfLink,
                    filesLink: cleanedResponse.filesLink
                })
            )
        }
    },

    *REMOVE_SUBSECTION({ payload: p }: ActionType<typeof ApplicationResourceActions.REMOVE_SUBSECTION>) {
        const selfLink = yield* select(
            (state: RootState) =>
                state.applicationResources.data.forApplication[p.applicationId][p.indexPath.resourceKey].fields[
                    p.indexPath.subsectionIndex || 0
                ].selfLink
        )
        const { success } = yield DELETE({
            watcher: p.watcherId,
            url: selfLink,
            errorText: `Failed to remove ${p.subsectionLabel}.`,
            successText: `${p.subsectionLabel} removed successfully.`,
            onSuccessDispatch: (r) => ApplicationResourceActions.UNSTORE_SUBSECTION(p.applicationId, p.indexPath)
        })

        if (success) yield put(ApplicationInternalsStampingActions.REFETCH(p.applicationId))
    }
}

export const cleanMAPIPeopleResponse = (response: { people: Person[] }) => {
    response.people.forEach((p: any) => {
        p.role = ApplicationResourcePersonConvertRoleBoolsToString({
            director: p.roleDirector,
            owner: p.roleOwner
        })
        delete p.roleOwner
        delete p.roleDirector
    })
    return response
}
