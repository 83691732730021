import { cva, VariantProps } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('h-px bg-neutral-100 dark:bg-neutral-100/5', {
    variants: {
        color: {
            primary: 'bg-primary-500',
            neutral: 'bg-neutral-100 dark:bg-neutral-100/5',
            success: 'bg-success-500',
            warning: 'bg-warning-500',
            danger: 'bg-danger-500',
            notice: 'bg-notice-500'
        },
        shade: {
            '50': 'bg-neutral-50',
            '100': 'bg-neutral-100',
            '200': 'bg-neutral-200',
            '300': 'bg-neutrall-300',
            '400': 'bg-neutrall-400',
            '500': 'bg-neutrall-500',
            '600': 'bg-neutrall-600',
            '700': 'bg-neutrall-700',
            '800': 'bg-neutrall-800',
            '900': 'bg-neutrall-900',
            '950': 'bg-neutrall-950'
        },
        opacity: {
            '0': 'opacity-0',
            '5': 'opacity-5',
            '10': 'opacity-10',
            '15': 'opacity-15',
            '20': 'opacity-20',
            '25': 'opacity-25',
            '30': 'opacity-30',
            '40': 'opacity-40',
            '50': 'opacity-50',
            '60': 'opacity-60',
            '70': 'opacity-70',
            '75': 'opacity-75',
            '80': 'opacity-80',
            '90': 'opacity-90',
            '95': 'opacity-95',
            '100': 'opacity-100'
        }
    },
    compoundVariants: [
        {
            color: 'neutral',
            shade: '50',
            class: 'bg-neutral-50 dark:bg-neutral-50'
        },
        {
            color: 'neutral',
            shade: '100',
            class: 'bg-neutral-100 dark:bg-neutral-100'
        },
        {
            color: 'neutral',
            shade: '200',
            class: 'bg-neutral-200 dark:bg-neutral-200'
        },
        {
            color: 'neutral',
            shade: '300',
            class: 'bg-neutral-300 dark:bg-neutral-300'
        },
        {
            color: 'neutral',
            shade: '300',
            class: 'bg-neutral-300 dark:bg-neutral-300'
        },
        {
            color: 'neutral',
            shade: '400',
            class: 'bg-neutral-400 dark:bg-neutral-400'
        },
        {
            color: 'neutral',
            shade: '500',
            class: 'bg-neutral-500 dark:bg-neutral-500'
        },
        {
            color: 'neutral',
            shade: '600',
            class: 'bg-neutral-600 dark:bg-neutral-600'
        },
        {
            color: 'neutral',
            shade: '700',
            class: 'bg-neutral-700 dark:bg-neutral-700'
        },
        {
            color: 'neutral',
            shade: '800',
            class: 'bg-neutral-800 dark:bg-neutral-800'
        },
        {
            color: 'neutral',
            shade: '900',
            class: 'bg-neutral-900 dark:bg-neutral-900'
        },
        {
            color: 'neutral',
            shade: '950',
            class: 'bg-neutral-950 dark:bg-neutral-950'
        },
        {
            color: 'danger',
            shade: '50',
            class: 'bg-danger-50 dark:bg-danger-50'
        },
        {
            color: 'danger',
            shade: '100',
            class: 'bg-danger-100 dark:bg-danger-100'
        },
        {
            color: 'danger',
            shade: '200',
            class: 'bg-danger-200 dark:bg-danger-200'
        },
        {
            color: 'danger',
            shade: '300',
            class: 'bg-danger-300 dark:bg-danger-300'
        },
        {
            color: 'danger',
            shade: '400',
            class: 'bg-danger-400 dark:bg-danger-400'
        },
        {
            color: 'danger',
            shade: '500',
            class: 'bg-danger-500 dark:bg-danger-500'
        },
        {
            color: 'danger',
            shade: '600',
            class: 'bg-danger-600 dark:bg-danger-600'
        },
        {
            color: 'danger',
            shade: '700',
            class: 'bg-danger-700 dark:bg-danger-700'
        },
        {
            color: 'danger',
            shade: '800',
            class: 'bg-danger-800 dark:bg-danger-800'
        },
        {
            color: 'danger',
            shade: '900',
            class: 'bg-danger-900 dark:bg-danger-900'
        },
        {
            color: 'danger',
            shade: '950',
            class: 'bg-danger-950 dark:bg-danger-950'
        }
    ]
})
