import { ONLY_IF_REAL_PRODUCTION, uppercaseFirstLetter } from './utils'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'

import { ApplicationInternalsTagsActions } from './store/applicationInternals/tags/actions'
import { CriticalFlow } from './pages/CriticalFlow'
import { FilePreviewsRoot } from './filePreviewsRoot'
import { GlobalHotkeysBar } from './components/hotkeys/globalHotkeysBar'
import { InterfaceActions } from './store/interface/actions'
import { LoaderView } from './components/loaders/loader'
import { RootState } from '@/store'
import { Search } from './pages/Search/Search'
import { useQueryParams } from './hooks/general/useQueryParam'
import { Outlet } from 'react-router-dom'

const queryParams = ['show-search', 'skip-critical-flows']
export const Page = (): React.ReactElement => {
    const dispatch = useDispatch()
    const [query] = useQueryParams(queryParams, undefined)
    const criticalFlow = useSelector((state: RootState) => state.interface.criticalFlow)
    const isAuthInProgress = useSelector((state: RootState) => state.auth?.inProgress)

    useEffect(() => {
        dispatch(InterfaceActions.INIT_CRITICAL_FLOW_IF_IT_EXISTS())
        dispatch(ApplicationInternalsTagsActions.FETCH())
    }, [dispatch])

    const overlay = useMemo(() => {
        if (query?.['show-search']) return <Search />
        return null
    }, [query])

    const environmentTag = useMemo(() => {
        return ONLY_IF_REAL_PRODUCTION() ? null : (
            <EnvTag>ENV: {uppercaseFirstLetter(import.meta.env.VITE_ENV || 'unknown')}</EnvTag>
        )
    }, [])

    if (criticalFlow) {
        if (!query['skip-critical-flows'])
            return (
                <>
                    {environmentTag}
                    <CriticalFlow />
                </>
            )
    }

    if (isAuthInProgress) {
        return <LoaderView overBackground="back.background" />
    }

    return (
        <>
            <GlobalHotkeysBar />
            {overlay}
            {environmentTag}
            <FilePreviewsRoot />
            <RouterHolder shouldNotDisplay={!!overlay}>
                <Outlet />
            </RouterHolder>
        </>
    )
}

const EnvTag = styled.div`
    background-color: yellow;
    position: fixed;
    bottom: 0;
    left: 60px;
    z-index: 1000000000;
    padding: 2px 6px 1px 6px;
    font-size: 11px;
    letter-spacing: -0.1px;
    border-radius: 5px 5px 0 0;
    line-height: 16px;
    font-weight: 500;
`

const RouterHolder = styled.div<{ shouldNotDisplay?: boolean }>`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    ${(p) =>
        p.shouldNotDisplay &&
        css`
            display: none;
        `}
`
