import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('', {
    variants: {
        display: {
            'flex': 'flex',
            'inline-flex': 'inline-flex'
        },
        direction: {
            'row': 'flex-row',
            'row-reverse': 'flex-row-reverse',
            'column': 'flex-col',
            'column-reverse': 'flex-col-reverse'
        },
        align: {
            start: 'items-start',
            center: 'items-center',
            end: 'items-end',
            baseline: 'items-baseline',
            stretch: 'items-stretch'
        },
        justify: {
            normal: 'justify-normal',
            start: 'justify-start',
            end: 'justify-end',
            center: 'justify-center',
            between: 'justify-between',
            around: 'justify-around',
            evenly: 'justify-evenly',
            stretch: 'justify-stretch'
        },
        wrap: {
            'wrap': 'flex-wrap',
            'wrap-reverse': 'flex-wrap-reverse',
            'nowrap': 'flex-nowrap'
        },
        flex: {
            '1': 'flex-1',
            'auto': 'flex-auto',
            'initial': 'flex-initial',
            'none': 'flex-none'
        },
        grow: {
            '0': 'grow-0',
            '1': 'grow'
        },
        shrink: {
            '0': 'shrink-0',
            '1': 'shrink'
        },
        basis: {
            'auto': 'basis-auto',
            'px': 'basis-px',
            'full': 'basis-full',
            '0': 'basis-0',
            '1': 'basis-1',
            '2': 'basis-2',
            '3': 'basis-3',
            '4': 'basis-4',
            '5': 'basis-5',
            '6': 'basis-6',
            '7': 'basis-7',
            '8': 'basis-8',
            '9': 'basis-9',
            '10': 'basis-10',
            '11': 'basis-11',
            '12': 'basis-12',
            '14': 'basis-14',
            '16': 'basis-16',
            '20': 'basis-20',
            '24': 'basis-24',
            '28': 'basis-28',
            '32': 'basis-32',
            '36': 'basis-36',
            '40': 'basis-40',
            '44': 'basis-44',
            '48': 'basis-48',
            '52': 'basis-52',
            '56': 'basis-56',
            '60': 'basis-60',
            '64': 'basis-64',
            '72': 'basis-72',
            '80': 'basis-80',
            '96': 'basis-96',
            '0.5': 'basis-0.5',
            '1.5': 'basis-1.5',
            '2.5': 'basis-2.5',
            '3.5': 'basis-3.5',
            '1/2': 'basis-1/2',
            '1/3': 'basis-1/3',
            '2/3': 'basis-2/3',
            '1/4': 'basis-1/4',
            '2/4': 'basis-2/4',
            '3/4': 'basis-3/4',
            '1/5': 'basis-1/5',
            '2/5': 'basis-2/5',
            '3/5': 'basis-3/5',
            '4/5': 'basis-4/5',
            '1/6': 'basis-1/6',
            '2/6': 'basis-2/6',
            '3/6': 'basis-3/6',
            '4/6': 'basis-4/6',
            '5/6': 'basis-5/6',
            '1/12': 'basis-1/12',
            '2/12': 'basis-2/12',
            '3/12': 'basis-3/12',
            '4/12': 'basis-4/12',
            '5/12': 'basis-5/12',
            '6/12': 'basis-6/12',
            '7/12': 'basis-7/12',
            '8/12': 'basis-8/12',
            '9/12': 'basis-9/12',
            '10/12': 'basis-10/12',
            '11/12': 'basis-11/12'
        },
        gap: {
            '0': 'gap-0',
            'x-0': 'gap-x-0',
            'y-0': 'gap-y-0',
            'px': 'gap-px',
            'x-px': 'gap-x-px',
            'y-px': 'gap-y-px',
            '0.5': 'gap-0.5',
            'x-0.5': 'gap-x-0.5',
            'y-0.5': 'gap-y-0.5',
            '1': 'gap-1',
            'x-1': 'gap-x-1',
            'y-1': 'gap-y-1',
            '1.5': 'gap-1.5',
            'x-1.5': 'gap-x-1.5',
            'y-1.5': 'gap-y-1.5',
            '2': 'gap-2',
            'x-2': 'gap-x-2',
            'y-2': 'gap-y-2',
            '2.5': 'gap-2.5',
            'x-2.5': 'gap-x-2.5',
            'y-2.5': 'gap-y-2.5',
            '3': 'gap-3',
            'x-3': 'gap-x-3',
            'y-3': 'gap-y-3',
            '3.5': 'gap-3.5',
            'x-3.5': 'gap-x-3.5',
            'y-3.5': 'gap-y-3.5',
            '4': 'gap-4',
            'x-4': 'gap-x-4',
            'y-4': 'gap-y-4',
            '5': 'gap-5',
            'x-5': 'gap-x-5',
            'y-5': 'gap-y-5',
            '6': 'gap-6',
            'x-6': 'gap-x-6',
            'y-6': 'gap-y-6',
            '7': 'gap-7',
            'x-7': 'gap-x-7',
            'y-7': 'gap-y-7',
            '8': 'gap-8',
            'x-8': 'gap-x-8',
            'y-8': 'gap-y-8',
            '9': 'gap-9',
            'x-9': 'gap-x-9',
            'y-9': 'gap-y-9',
            '10': 'gap-10',
            'x-10': 'gap-x-10',
            'y-10': 'gap-y-10',
            '11': 'gap-11',
            'x-11': 'gap-x-11',
            'y-11': 'gap-y-11',
            '12': 'gap-12',
            'x-12': 'gap-x-12',
            'y-12': 'gap-y-12',
            '14': 'gap-14',
            'x-14': 'gap-x-14',
            'y-14': 'gap-y-14',
            '16': 'gap-16',
            'x-16': 'gap-x-16',
            'y-16': 'gap-y-16',
            '20': 'gap-20',
            'x-20': 'gap-x-20',
            'y-20': 'gap-y-20',
            '24': 'gap-24',
            'x-24': 'gap-x-24',
            'y-24': 'gap-y-24',
            '28': 'gap-28',
            'x-28': 'gap-x-28',
            'y-28': 'gap-y-28',
            '32': 'gap-32',
            'x-32': 'gap-x-32',
            'y-32': 'gap-y-32',
            '36': 'gap-36',
            'x-36': 'gap-x-36',
            'y-36': 'gap-y-36',
            '40': 'gap-40',
            'x-40': 'gap-x-40',
            'y-40': 'gap-y-40',
            '44': 'gap-44',
            'x-44': 'gap-x-44',
            'y-44': 'gap-y-44',
            '48': 'gap-48',
            'x-48': 'gap-x-48',
            'y-48': 'gap-y-48',
            '52': 'gap-52',
            'x-52': 'gap-x-52',
            'y-52': 'gap-y-52',
            '56': 'gap-56',
            'x-56': 'gap-x-56',
            'y-56': 'gap-y-56',
            '60': 'gap-60',
            'x-60': 'gap-x-60',
            'y-60': 'gap-y-60',
            '64': 'gap-64',
            'x-64': 'gap-x-64',
            'y-64': 'gap-y-64',
            '72': 'gap-72',
            'x-72': 'gap-x-72',
            'y-72': 'gap-y-72',
            '80': 'gap-80',
            'x-80': 'gap-x-80',
            'y-80': 'gap-y-80',
            '96': 'gap-96',
            'x-96': 'gap-x-96',
            'y-96': 'gap-y-96'
        }
    },
    defaultVariants: {
        display: 'flex'
    }
})
