export const Spacer: React.FC<{ width?: number; inline?: boolean; height?: number; cy?: string }> = ({
    width,
    height,
    inline,
    cy
}) => {
    const dynamicHeight = height !== undefined ? `${height}px` : `${width}px`
    return (
        <div
            data-cy={cy}
            style={{
                width: `${width}px`,
                height: dynamicHeight,
                display: inline ? 'inline-block' : 'block',
                flexShrink: 0
            }}
            className="spacer"
        />
    )
}
