import { Text } from '../../components/general/text'
import { uppercaseFirstLetter } from '../../utils'
import styled from 'styled-components'
import moment from 'moment'
import { ConditionallyShowInteractionDate } from './MerchantSidebarInteractions.ConditionallyShowInteractionDate'
import { Account } from '../../components/general/account'

export const MerchantInteractionStateChange: React.FC<{ interaction: any }> = ({ interaction }) => {
    return (
        <Block>
            <Text color="front.text.subtlerI" align="center">
                Marked as «{uppercaseFirstLetter(interaction.to)}» by <Account id={interaction.by} />
            </Text>
            <ConditionallyShowInteractionDate right={0}>
                <Right>{moment(interaction.at).format('HH:mm:ss')}</Right>
            </ConditionallyShowInteractionDate>
        </Block>
    )
}

const Block = styled.div`
    padding: 10px 70px;
    text-align: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const Right = styled.div`
    position: absolute;
    right: 0;
    color: ${(p) => p.theme['front.text.subtlerII']};
`
