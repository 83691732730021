import { SectionCheck } from '@/services/ongoing-due-diligence'
import React from 'react'
import { Section } from '../../../common/section'
import { useReportSection } from '../../../provider/report-provider/report-provider'

export const WebsiteCredibleRefundCreditPattern: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')

    return (
        <Section>
            <Section.Content>
                <Section.Field
                    name="website_credible_refund_credit_pattern"
                    data={checks.website_credible_refund_credit_pattern}
                />
            </Section.Content>
        </Section>
    )
}
