import {
    CallsActionLoadSummaries,
    CallsActionUpdateSummaries,
    CallsDispatchUpdateSummaries,
    CallActionAddResource,
    CallActionDeleteResource,
    CallActionFindResources,
    CallActionLoadResources,
    CallDispatchStoreFoundResources,
    SagasForCalls,
    CallsActionLoadQueues,
    CallsDispatchStoreQueues,
    CallsActionLoadPhoneName,
    CallsDispatchStorePhoneName,
    CallDispatchDeleteResource,
    CallDispatchStoreResources,
    CallsDispatchStoreSummaries,
    CallsActionRemoveQueueNumber
} from './actions'
import { GET, POST, DELETE } from '../../generators/networking'
import axios from 'axios'
import { getJWT } from '../auth/sagas'
import { put } from 'redux-saga/effects'

export class CallsSaga implements SagasForCalls {
    *loadSummaries(action: CallsActionLoadSummaries) {
        yield GET({
            url: `${import.meta.env.VITE_MERCURY_ENDPOINT}/calls?query=status:${action.filters.status}`,
            onSuccessDispatch: (r) => CallsDispatchStoreSummaries(Object.values(r)),
            errorText: 'Failed to load call summaries.'
        })
    }
    *updateSummaries(action: CallsActionUpdateSummaries) {
        CallsDispatchUpdateSummaries(action.update)
    }
    *addResource(action: CallActionAddResource) {
        yield POST({
            watcher: `call.addResource.${action.callId}`,
            url: `${import.meta.env.VITE_MERCURY_ENDPOINT}/calls/${action.callId}/applications`,
            body: { resourceType: 'application', resourceId: action.resourceId },
            successText: `Application was added.`,
            errorText: `Failed to add application.`
        })
    }
    *deleteResource(action: CallActionDeleteResource) {
        yield DELETE({
            watcher: `call.deleteResource.${action.callId}`,
            url: `${import.meta.env.VITE_MERCURY_ENDPOINT}/calls/${action.callId}/applications`,
            onSuccessDispatch: (r) => CallDispatchDeleteResource(action.callId),
            successText: `Application was removed.`,
            errorText: `Failed to remove application.`
        })
    }
    *findResources(action: CallActionFindResources) {
        yield GET({
            cutterUrl: (l) =>
                `${l.sherlockLink}?query=${encodeURIComponent(action.query)}${
                    action.page ? `&page=${action.page}` : ''
                }${action.perPage ? `&per_page=${action.perPage}` : `&per_page=${10}`}`,
            errorText: 'Failed to load search results.',
            onSuccessDispatch: (r) =>
                CallDispatchStoreFoundResources(r.applications, {
                    total: r.total,
                    page: r.page,
                    perPage: r.perPage
                })
        })
    }
    *loadQueues(action: CallsActionLoadQueues) {
        yield GET({
            url: `${import.meta.env.VITE_MERCURY_ENDPOINT}/queues/${action.filters.queue}/phones`,
            onSuccessDispatch: (r) => CallsDispatchStoreQueues(r),
            errorText: 'Failed to load call queues.'
        })
    }
    *removeNumber(action: CallsActionRemoveQueueNumber) {
        const token: string = yield getJWT()
        axios
            .delete(`${import.meta.env.VITE_MERCURY_ENDPOINT}/phones/${action.number}/queues`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    queues: [`100${action.queue}`]
                }
            })
            .then(
                (response) => {
                    console.log(response)
                },
                (error) => {
                    console.log(error)
                }
            )
    }
    *loadResources(action: CallActionLoadResources) {
        const mercuryResources: any[] = yield GET({
            url: `${import.meta.env.VITE_MERCURY_ENDPOINT}/phone-numbers/${action.phone}/applications`,
            errorText: 'Failed to load call resources from Mercury.'
        })
        const resources: any[] = []
        for (const r in mercuryResources) {
            const resId = mercuryResources[r].resourceId
            const mapiResource: { applications: any[] } = yield GET({
                cutterUrl: (l) => `${l.sherlockLink}?query=${resId}`,
                errorText: 'Failed to load search results.'
            })
            resources.push(mapiResource.applications[0])
        }
        yield put(CallDispatchStoreResources(resources))
    }
    *getPhoneName(action: CallsActionLoadPhoneName) {
        yield GET({
            url: `${import.meta.env.VITE_MERCURY_ENDPOINT}/phones/${action.localNumber}/names`,
            onSuccessDispatch: (r) => CallsDispatchStorePhoneName(r),
            errorText: 'Failed to fetch names for given local number.'
        })
    }
}
