import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Text } from '../../../components/general/text'
import { Flag } from '../../../components/icons/flag'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
// eslint-disable-next-line max-len
import { ApplicationInternalsSchemesScreeningActions } from '../../../store/applicationInternals/schemesScreening/actions'
import { RootState } from '@/store'
import { ApplicationSchemesScreeningPanelSchemeToggle } from './Application.SchemesScreeningPanel.SchemeToggle'
import { LinkButton } from '../../../components/buttons/linkButton'
import { SchemesIcon } from '../../../components/icons/schemesIcon'
import { Offset } from '../../../components/layout/offset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { CircleCheck } from '../../../components/icons/circleCheck'
import { ApplicationSchemesScreeningPastInquiries } from './Application.SchemesScreeningPastInquiries'
import { SchemesScreeningCountry2LetterCode, SchemesScreeningCountryName } from './Application.SchemesScreeningCountry'
import { LoaderView } from '../../../components/loaders/loader'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { useNavigate } from 'react-router-dom'
import { PastInquiry, Result } from '@/store/applicationInternals/schemesScreening/types'
import { DateFormats } from '@/utils/dateUtils'
import moment from 'moment'

export const ApplicationSchemesScreeningModalResults: React.FC<{ applicationId: string; handleGoBack: () => void }> = ({
    applicationId,
    handleGoBack
}) => {
    const state = useSelector((state: RootState) => {
        return state.applicationInternals.schemesScreening.forApplication[applicationId]
    })
    const status = state?.status
    const latestInquiry = state?.latestInquiry
    const inquiryId = state?.latestInquiry?.inquiryId
    const results: Result[] = []
    const pastInquiries: PastInquiry[] = []
    if (latestInquiry && latestInquiry.vmssResults) {
        results.push(...latestInquiry.vmssResults)
    }

    if (latestInquiry && latestInquiry.matchResults) {
        results.push(...latestInquiry.matchResults)
    }

    if (latestInquiry && latestInquiry.matchPastInquiries) {
        pastInquiries.push(...latestInquiry.matchPastInquiries)
    }

    const navigate = useNavigate()
    const [resultsFilter, setResultsFilter] = useState<'all' | 'vmss' | 'match'>('all')
    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const [selectedPastInquiries, setSelectedPastInquiries] = useState<string[]>([])
    const dispatch = useDispatch()
    const [concludeFailedScreeningWatcher, concludeFailedScreeningWatcherId] = useNamedWatcher(
        `${applicationId}-conclude-failed-screening`
    )
    const [concludePassedScreeningWatcher, concludePassedScreeningWatcherId] = useNamedWatcher(
        `${applicationId}-conclude-passed-screening`
    )
    const [rescreenWatcher, rescreenWatcherId] = useNamedWatcher(`${applicationId}-rescreen`)
    const handleStartScreeningClicked = useCallback(
        (e, watcherId) => {
            dispatch(ApplicationInternalsSchemesScreeningActions.SCREEN(applicationId, watcherId))
        },
        [dispatch, applicationId]
    )
    const loadingStatus = useSelector((state: RootState) => {
        return state.applicationInternals.schemesScreening?.forApplication?.[applicationId]?.loadingStatus
    })
    const handleConcludeScreeningClicked = useCallback(
        (e, wId, passed) => {
            if (!inquiryId) return
            dispatch(
                ApplicationInternalsSchemesScreeningActions.CONCLUDE_INQUIRY(
                    applicationId,
                    inquiryId,
                    wId,
                    selectedRows,
                    selectedPastInquiries,
                    passed
                )
            )
        },
        [dispatch, applicationId, inquiryId, selectedRows, selectedPastInquiries]
    )

    useEffect(() => {
        if (concludeFailedScreeningWatcher == 'success' || concludePassedScreeningWatcher == 'success') {
            handleGoBack()
        }
    }, [concludeFailedScreeningWatcher, concludePassedScreeningWatcher, applicationId, history, handleGoBack])

    useEffect(() => {
        if (rescreenWatcher == 'success') {
            navigate(`/merchant/${applicationId}/application/screening-results`)
        }
    }, [rescreenWatcher, applicationId, history])

    useEffect(() => {
        setSelectedRows([])
        setSelectedPastInquiries([])
    }, [latestInquiry])

    const filteredResults = useMemo(() => {
        return (
            <ResultsGrid>
                {results
                    ?.filter((f) => {
                        if (resultsFilter == 'all') return true
                        if (resultsFilter == 'vmss') return f.type.toLowerCase() == 'vmss'
                        if (resultsFilter == 'match') return f.type.toLowerCase() == 'match'
                    })
                    .sort((a, b) => {
                        return a.matchingScore > b.matchingScore ? -1 : 1
                    })
                    .map((r) => {
                        const isSelected = selectedRows.includes(r.resultId)

                        return (
                            <>
                                <Row>
                                    <Cell>
                                        <SchemeHolder>
                                            {r.type == 'MATCH' ? <SchemesIcon scheme="mastercard" /> : null}
                                            {r.type == 'VMSS' ? <SchemesIcon scheme="visa" /> : null}
                                        </SchemeHolder>
                                    </Cell>
                                    <Cell>
                                        <Flex noShrink>
                                            <Flag
                                                code={SchemesScreeningCountry2LetterCode(r.type, r.company?.country)}
                                            />
                                            <Spacer width={10} />
                                            <Text color="front.text" oneLine bold>
                                                {SchemesScreeningCountryName(r.type, r.company?.country)}
                                            </Text>
                                        </Flex>
                                    </Cell>
                                    <Cell>
                                        <Flex column>
                                            <Text oneLine bold color="front.text">
                                                {r.company?.name || 'Unnamed company'}
                                            </Text>
                                            <Text oneLine color="front.text.subtlerI">
                                                {r.company?.registrationId || 'No ID'}
                                            </Text>
                                        </Flex>
                                    </Cell>
                                    <Cell>
                                        <Flex column shrink>
                                            <ResultDescription>
                                                <Text color="front.danger.color" bold>
                                                    #{r.termination.mainReasonCode}{' '}
                                                    {r.termination.mainReasonDescription}
                                                </Text>
                                            </ResultDescription>
                                            <Text color="front.text.subtlerI">
                                                Reported on {moment(r.termination.madeAt).format('DD MMM, YYYY')}
                                            </Text>
                                        </Flex>
                                    </Cell>
                                    <Cell>
                                        {/* <Text oneLine>{`${r.acquirer.name || 'Unknown'} · ${
                                            r.acquirer.acquirerId
                                        }`}</Text> */}
                                    </Cell>
                                    <Cell>
                                        <Flex>
                                            <Text color="front.text.subtlerI" oneLine>
                                                Score: {r.matchingScore}
                                            </Text>
                                            <Spacer width={20} />
                                        </Flex>
                                    </Cell>
                                    <Cell>
                                        <Flex justify="flex-end" align="flex-start" grow>
                                            {!latestInquiry?.conclusion ? (
                                                <Offset top={-5}>
                                                    <Flex column align="baseline">
                                                        <SimpleButton
                                                            color={isSelected ? 'front.danger.color' : undefined}
                                                            onClick={() => {
                                                                const id = r.resultId
                                                                setSelectedRows((resultIds) => {
                                                                    let newArray = [...resultIds]
                                                                    if (!newArray.includes(id)) newArray.push(id)
                                                                    else newArray = newArray.filter((i) => i != id)
                                                                    return newArray
                                                                })
                                                            }}
                                                        >
                                                            <ButtonInset>
                                                                <CircleCheck
                                                                    selectable
                                                                    checked={isSelected}
                                                                    isDanger={isSelected}
                                                                />
                                                                {isSelected ? 'Hit' : 'Hit'}
                                                            </ButtonInset>
                                                        </SimpleButton>
                                                    </Flex>
                                                </Offset>
                                            ) : latestInquiry.conclusion.matches.includes(r.resultId) ? (
                                                <Offset top={-3}>
                                                    <Flex column align="baseline">
                                                        <SimpleButton>
                                                            <ButtonInset>
                                                                <CircleCheck
                                                                    checked
                                                                    isSuccess={latestInquiry?.conclusion?.passed}
                                                                    isDanger={!latestInquiry?.conclusion?.passed}
                                                                    larger
                                                                />
                                                            </ButtonInset>
                                                        </SimpleButton>
                                                    </Flex>
                                                </Offset>
                                            ) : null}
                                            <Spacer width={10} height={0} />
                                            <LinkButton
                                                color="front.accent.color"
                                                // eslint-disable-next-line max-len
                                                to={`/merchant/${applicationId}/application/screening-results/${r.resultId}`}
                                            >
                                                See details
                                            </LinkButton>
                                        </Flex>
                                    </Cell>
                                </Row>
                            </>
                        )
                    })}
            </ResultsGrid>
        )
    }, [results, resultsFilter, selectedRows, applicationId])

    const errorMemo = useMemo(() => {
        return <Text>Something went wrong. Screening service failed.</Text>
    }, [])

    if (loadingStatus == 'started') return <LoaderView minHeight="500px" overBackground="front.background" />

    if (status == 'inconclusive-inquiry') {
        if (!latestInquiry) return errorMemo
        return (
            <>
                <Flex column>
                    <Flex align="center" justify="space-between">
                        <Text color="front.text" bold>
                            We've found {results!.length} potential matches:
                        </Text>
                        <ApplicationSchemesScreeningPanelSchemeToggle
                            onChange={(option) => {
                                setResultsFilter(option)
                            }}
                            hasVisaResults={!!results!.find((r) => r.type == 'VMSS')}
                            hasMastercardResults={!!results!.find((r) => r.type == 'MATCH')}
                            selected={resultsFilter}
                        />
                    </Flex>
                    <Spacer height={30} />
                    {filteredResults}
                    <Spacer height={20} />
                </Flex>
                <ApplicationSchemesScreeningPastInquiries
                    selectedPastInquiries={selectedPastInquiries}
                    setSelectedPastInquiries={setSelectedPastInquiries}
                />
                <Footer>
                    <WatcherButton
                        background="front.accent.color"
                        onClick={handleStartScreeningClicked}
                        predefinedWatcher={rescreenWatcherId}
                    >
                        <ButtonInset>Re-screen merchant</ButtonInset>
                    </WatcherButton>
                    <Flex>
                        <WatcherButton
                            background="front.danger.color"
                            onClick={(e, wId) => {
                                handleConcludeScreeningClicked(e, wId, false)
                            }}
                            predefinedWatcher={concludeFailedScreeningWatcherId}
                        >
                            <ButtonInset>
                                Conclude - FAIL screening ({selectedRows.length + selectedPastInquiries.length})
                            </ButtonInset>
                        </WatcherButton>
                        <Spacer width={12} />
                        <WatcherButton
                            background="front.success.color"
                            onClick={(e, wId) => {
                                handleConcludeScreeningClicked(e, wId, true)
                            }}
                            predefinedWatcher={concludePassedScreeningWatcherId}
                        >
                            <ButtonInset>Successfully PASS screening</ButtonInset>
                        </WatcherButton>
                    </Flex>
                </Footer>
            </>
        )
    }
    if (status == 'successful-inquiry' || status == 'failed-inquiry') {
        if (!latestInquiry) return errorMemo
        return (
            <>
                <Flex column>
                    <Flex align="center" justify="space-between">
                        <Text color="front.text" bold>
                            We've found {results!.length} potential matches:
                        </Text>
                        <ApplicationSchemesScreeningPanelSchemeToggle
                            onChange={(option) => {
                                setResultsFilter(option)
                            }}
                            hasVisaResults={!!results!.find((r) => r.type == 'VMSS')}
                            hasMastercardResults={!!results!.find((r) => r.type == 'MATCH')}
                            selected={resultsFilter}
                        />
                    </Flex>
                    <Spacer height={30} />
                    {filteredResults}
                    <Spacer height={20} />
                </Flex>
                <ApplicationSchemesScreeningPastInquiries
                    latestInquiry={latestInquiry}
                    selectedPastInquiries={selectedPastInquiries}
                    setSelectedPastInquiries={setSelectedPastInquiries}
                />
                <Spacer height={20} />
                <Flex justify="center">
                    <WatcherButton
                        background="front.accent.color"
                        onClick={handleStartScreeningClicked}
                        predefinedWatcher={rescreenWatcherId}
                    >
                        <ButtonInset>Re-screen merchant</ButtonInset>
                    </WatcherButton>
                </Flex>
            </>
        )
    }

    if (status == 'no-results-inquiry') {
        return (
            <Flex column>
                <Flex justify="center">
                    <Text bold color="front.text.subtlerI">
                        No relevant matches found
                    </Text>
                </Flex>
                <Spacer height={10} />
                <ApplicationSchemesScreeningPastInquiries
                    selectedPastInquiries={selectedPastInquiries}
                    setSelectedPastInquiries={setSelectedPastInquiries}
                />
                {pastInquiries?.length ? (
                    <Footer>
                        <WatcherButton
                            background="front.accent.color"
                            onClick={handleStartScreeningClicked}
                            predefinedWatcher={rescreenWatcherId}
                        >
                            <ButtonInset>Re-screen merchant</ButtonInset>
                        </WatcherButton>
                        <Flex>
                            <WatcherButton
                                background="front.danger.color"
                                onClick={(e, wId) => {
                                    handleConcludeScreeningClicked(e, wId, false)
                                }}
                                predefinedWatcher={concludeFailedScreeningWatcherId}
                            >
                                <ButtonInset>
                                    Conclude - FAIL screening ({selectedRows.length + selectedPastInquiries.length})
                                </ButtonInset>
                            </WatcherButton>
                            <Spacer width={12} />
                            <WatcherButton
                                background="front.success.color"
                                onClick={(e, wId) => {
                                    handleConcludeScreeningClicked(e, wId, true)
                                }}
                                predefinedWatcher={concludePassedScreeningWatcherId}
                            >
                                <ButtonInset>Successfully PASS screening</ButtonInset>
                            </WatcherButton>
                        </Flex>
                    </Footer>
                ) : null}
            </Flex>
        )
    }

    if (status) throw 'Schemes Screening Unknown status detected'

    return null
}

const ResultsGrid = styled.div`
    display: grid;
    grid-template-columns: min-content min-content min-content 1fr min-content min-content 160px;
`

const Footer = styled.div`
    display: flex;
    bottom: 0;
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 40px;
`

const SchemeHolder = styled.div`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    margin-top: 2px;
    margin-right: 10px;
`

const ResultDescription = styled.span`
    margin-top: -1px;
`

const Cell = styled.div`
    display: block;
    border-bottom: 1px solid ${(p) => p.theme['back.border']};
    padding: 15px 10px 10px 10px;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
`

const Row = styled.div`
    display: contents;

    &:last-child ${Cell} {
        border-bottom: none;
    }
`
