import { camelCase } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { Flex } from '../../../components/layout/flex'
import { SlimLoader } from '../../../components/loaders/loader'
import { Spacer } from '../../../components/layout/spacer'
import { RootState } from '@/store'
import {
    MerchantApplicationStateControlsTypes,
    MerchantApplicationStatusAction
} from './Application.StateControlsTypes'
import { MerchantApplicationStateControlDeclineModal } from './Application.StateControlDeclineModal'
import { ApplicationInternalsDetailsActions } from '../../../store/applicationInternals/details/actions'
import { ToastsDispatchPush } from '../../../store/toasts/actions'
import { WatcherDispatchStart } from '../../../store/watcher/actions'
import { ApplicationInternalsStampingActions } from '../../../store/applicationInternals/stamping/actions'
import styled from 'styled-components'
import { Icon } from '../../../components/icons/icon'
import { MerchantApplicationStateControlsMoreButton } from './Application.StateControlsMoreButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { StampMethods } from '../../../store/applicationInternals/stamping/types'

export const MerchantApplicationStateControls: React.FC<{
    applicationId: string
}> = ({ applicationId }) => {
    const [shouldShowDeclinePrompt, setShouldShowDeclinePrompt] = useState(false)
    const dispatch = useDispatch()
    const applicationState = useSelector((state: RootState) => {
        return state.applications.applications.at?.[applicationId]?.metadata?.state
    })
    const stampsState = useSelector((state: RootState) => {
        return state.applicationInternals.stamping.forApplication?.[applicationId]
    })
    const stamps = stampsState?.stamps
    const shortBusinessModel = useSelector((state: RootState) => {
        return state.applicationInternals?.details?.[applicationId]?.businessModel?.shortBusinessModel
    })
    const [watcher, watcherId] = useNamedWatcher(`${applicationId}.ChangeState`)

    const declineStampLink = useMemo(() => {
        return stamps?.filter((s) => s.name === 'declined')[0]?.href
    }, [stamps])

    const controlsConfig = useMemo(() => {
        const c = { ...MerchantApplicationStateControlsTypes }
        if (applicationState === 'more_information') {
            if (c['PUT_needs_information'])
                c['PUT_needs_information'] = {
                    ...MerchantApplicationStateControlsTypes['PUT_needs_information'],
                    label: 'Remind info missing',
                    icon: 'mail'
                }
            if (c['PUT_under_review'])
                c['PUT_under_review'] = {
                    ...MerchantApplicationStateControlsTypes['PUT_under_review'],
                    label: 'Back to review'
                }
        }
        return c
    }, [applicationState])

    const arrayOfControls = useMemo(() => {
        let s: {
            name: string
            method: any
            link: string
        }[] = []
        stamps?.forEach((stamp) => {
            stamp.hints?.allow?.forEach((m) => {
                s.push({
                    name: m + '_' + stamp.name,
                    method: m,
                    link: stamp.href
                })
            })
        })

        s.push({
            name: 'clone',
            method: '',
            link: ''
        })

        if (
            (controlsConfig as any)?.['PUT_' + applicationState]?.shouldHaveAcceptedStamp &&
            !stamps?.find((s) => s.name === 'accepted')
        ) {
            s.push({
                name: 'accepted-missing',
                method: '',
                link: ''
            } as any)
        }

        if (
            (controlsConfig as any)?.['PUT_' + applicationState]?.shouldHaveUnderReviewStamp &&
            !stamps?.find((s) => s.name === 'under_review')
        ) {
            s.push({
                name: 'under-review-missing',
                method: '',
                link: ''
            } as any)
        }

        // Remove the submitted stamp (as it's not needed for agents)
        if (applicationState === 'more_information') {
            s = s.filter((i) => i.name !== 'PUT_submitted')
        }

        return s
    }, [stamps, controlsConfig, applicationState])

    const handleClick = useCallback(
        (action: { name: MerchantApplicationStatusAction; link: string; method: StampMethods }, generatedId) => {
            if (action.name === 'PUT_declined') {
                setTimeout(() => {
                    setShouldShowDeclinePrompt(true)
                }, 300)
                return
            }
            if (action.name === 'clone') {
                dispatch(ApplicationInternalsDetailsActions.START_CLONING(applicationId))
                return
            } else if (action.name === 'PUT_accepted') {
                if (!shortBusinessModel) {
                    dispatch(WatcherDispatchStart(['FOCUS_SHORT_BUSINESS_MODEL']))
                    dispatch(
                        ToastsDispatchPush(
                            'Please update the «Short business model» internal field',
                            'error',
                            undefined
                        )
                    )
                } else
                    dispatch(
                        ApplicationInternalsStampingActions.STAMP(
                            applicationId,
                            action.link,
                            action.method,
                            generatedId
                        )
                    )
                return
            } else {
                dispatch(
                    ApplicationInternalsStampingActions.STAMP(applicationId, action.link, action.method, generatedId)
                )
                return
            }
        },
        [applicationId, dispatch, shortBusinessModel, setShouldShowDeclinePrompt]
    )

    const renderedButtons = useMemo(() => {
        const buttonsThatShouldBeHidden: MerchantApplicationStatusAction[] = ['PUT_archived', 'clone']
        const normalButtons: React.ReactNode[] = []
        const floatRightButtons: React.ReactNode[] = []
        const buttonUnderDropdown: {
            name: string
            link: string
        }[] = []

        arrayOfControls
            .sort((a, b) => {
                const controlsKeys = Object.keys(controlsConfig)
                const x = controlsKeys.findIndex((k) => k == b.name)
                const y = controlsKeys.findIndex((k) => k == a.name)

                if (x > y) return -1
                return 1
            })
            .forEach((a) => {
                let c = controlsConfig[a.name]
                if (!c) {
                    c = {
                        label: a.name,
                        color: 'front.accent.color'
                    }
                }

                const pushButton = (arr: React.ReactNode[]) => {
                    arr.push(
                        <React.Fragment key={c.label}>
                            <WatcherButton
                                background={c.color}
                                color={c.textColor}
                                key={camelCase(c.label)}
                                hotkeys={c.hotkeys}
                                hotkeysClueDirection="bottom-left"
                                hotkeysScope={`Merchant.ApplicationPage.${applicationId}` as any}
                                onClick={(e, generatedId) => {
                                    handleClick(a, generatedId)
                                }}
                            >
                                <ButtonInset padding="small">
                                    {c.icon ? (
                                        <IconHolder>
                                            <Icon type={c.icon as any} weight={1} />
                                        </IconHolder>
                                    ) : null}
                                    {c.label}
                                </ButtonInset>
                            </WatcherButton>
                            <Spacer width={5} />
                        </React.Fragment>
                    )
                }

                if ((a as any).name === 'accepted-missing') {
                    floatRightButtons.push(<IncompleteButton>Application is missing data</IncompleteButton>)
                } else if ((a as any).name === 'under-review-missing') {
                    floatRightButtons.push(<IncompleteButton>Application is missing data</IncompleteButton>)
                } else if (buttonsThatShouldBeHidden.includes(a.name)) {
                    buttonUnderDropdown.push(a)
                } else if (c.floatRight) {
                    pushButton(floatRightButtons)
                } else {
                    pushButton(normalButtons)
                }
            })

        return (
            <Flex align="center">
                {normalButtons}
                {floatRightButtons.length ? (
                    <>
                        {normalButtons.length ? <ButtonsSeparator /> : undefined}
                        {floatRightButtons}
                    </>
                ) : null}
                <MerchantApplicationStateControlsMoreButton
                    onSelect={(item) => {
                        const selectedOption = arrayOfControls.find((i) => i.name == item)
                        if (selectedOption) handleClick(selectedOption, `${applicationId}.ChangeState`)
                    }}
                    actions={buttonUnderDropdown as any}
                />
            </Flex>
        )
    }, [arrayOfControls, controlsConfig, applicationId, handleClick])

    const handleDeclinePromptBack = useCallback(() => {
        setShouldShowDeclinePrompt(false)
    }, [])

    if (stampsState?.loadingStatus !== 'done' || watcher === 'started')
        return (
            <Flex align="center" style={{ maxWidth: 160, height: 40, width: '200px' }}>
                <SlimLoader background="back.background" />
            </Flex>
        )

    return (
        <Flex align="stretch">
            {shouldShowDeclinePrompt && declineStampLink ? (
                <MerchantApplicationStateControlDeclineModal
                    onCancel={handleDeclinePromptBack}
                    watcherId={watcherId}
                    link={declineStampLink}
                    applicationId={applicationId}
                    onComplete={handleDeclinePromptBack}
                />
            ) : null}
            {renderedButtons}
        </Flex>
    )
}

const IncompleteButton = styled.div`
    padding: 4px 8px;
    border: 1px dashed ${(p) => p.theme['overlay.background.strongerII']};
    background-color: ${(p) => p.theme['overlay.background']};
    color: ${(p) => p.theme['back.text']};
    border-radius: 10px;
    margin: 0 5px;
`

const IconHolder = styled.div`
    margin-right: 5px;
    position: relative;
    top: 1px;
`

const ButtonsSeparator = styled.div`
    display: flex;
    flex-direction: column;
    height: 15px;
    margin: 0 10px;
    width: 1px;
    background-color: ${(p) => p.theme['overlay.background.strongerI']};
`
