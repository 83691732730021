export const zIndexes = {
    modal: 10000,
    popoverLevel3: 1000,
    popoverLevel2: 101,
    popover: 100,
    sidebar: 10,
    content: 5,
    background: 0,
    modals: {
        page: 0,
        modal: 10000,
        supermodal: 100000,
        megamodal: 1000000
    },
    hotkey: 10000000
}
