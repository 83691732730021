import { delay, put } from 'redux-saga/effects'

import { SagasForToasts, ToastsActionPush, ToastsDispatchPop } from './actions'

export class ToastSagas implements SagasForToasts {
    *pushToast(action: ToastsActionPush) {
        switch (action.toast.duration) {
            case 'shorter':
                yield delay(1500)
                break
            case 'longer':
                yield delay(4000)
                break
            default:
                if (import.meta.env.VITE_ENV === 'cypress') yield delay(1200)
                else yield delay(2000)
                break
        }
        if (action.toast.type == 'error' && action.toast.duration === 'shorter') {
            yield put(ToastsDispatchPop(action.toast.id))
        }
        if (
            action.toast.type !== 'error' &&
            action.toast.type !== 'call' &&
            action.toast.duration !== 'infinite' &&
            action.toast.type !== 'context'
        )
            yield put(ToastsDispatchPop(action.toast.id))
        if (action.toast.type === 'error' && action.toast.duration === 'shorter')
            yield put(ToastsDispatchPop(action.toast.id))
    }
}
