import { cva, VariantProps } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('w-full', {
    variants: {
        size: {
            '0': 'max-w-0',
            'none': 'max-w-none',
            'xs': 'max-w-xs',
            'sm': 'max-w-sm',
            'md': 'max-w-md',
            'lg': 'max-w-lg',
            'xl': 'max-w-xl',
            '2xl': 'max-w-2xl',
            '3xl': 'max-w-3xl',
            '4xl': 'max-w-4xl',
            '5xl': 'max-w-5xl',
            '6xl': 'max-w-6xl',
            '7xl': 'max-w-7xl',
            'full': 'max-w-full',
            'min': 'max-w-min',
            'max': 'max-w-max',
            'screen-sm': 'max-w-screen-sm',
            'screen-md': 'max-w-screen-md',
            'screen-lg': 'max-w-screen-lg',
            'screen-xl': 'max-w-screen-xl',
            'screen-2xl': 'max-w-screen-2xl'
        }
    }
})
