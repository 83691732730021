import React from 'react'
import { TagsExpander } from '@/components/taskPages/tagsExpander'

interface FilteredTagsProps {
    tags: string[]
    tagIncludes: string
}

const filterTags = (tags, tagIncludes) => tags.filter((x) => x.includes(tagIncludes))

export const FilteredTags: React.FC<FilteredTagsProps> = (props) => {
    const { tags, tagIncludes } = props

    const tagArray = filterTags(tags, tagIncludes)

    return <TagsExpander tagArray={tagArray} bubbleType="bordered" />
}
