import { createReducer } from 'deox'

import { produce } from 'immer'
import { ApplicationInternalsActions } from '../actions'

import { ApplicationInternalsDetailsActions } from './actions'
import { InitialApplicationInternalsDetailsState } from './types'

export const ApplicationInternalsDetailsReducer = createReducer(
    InitialApplicationInternalsDetailsState,
    (handleAction) => [
        handleAction(ApplicationInternalsDetailsActions.START_CLONING, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft[p.id].shouldClone = true
                return draft
            })
        }),
        handleAction(ApplicationInternalsDetailsActions.STOP_CLONING, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft[p.id].shouldClone = false
                return draft
            })
        }),
        handleAction(ApplicationInternalsDetailsActions.STORE_VALUES, (state, { payload: p }) => {
            return produce(state, (draft) => {
                if (!p.data?.id) return draft

                if (!draft[p.data.id]) draft[p.data.id] = {} as any

                draft[p.data.id].businessModel = {
                    longBusinessModel: p.data.longBusinessModel,
                    shortBusinessModel: p.data.shortBusinessModel,
                    marketplaceId: p.data.marketplaceId,
                    selfLink: p.data.selfLink
                }

                draft[p.data.id].clonableStates = p.data.clonableStates
                draft[p.data.id].company = {
                    bvdId: p.data.company.bvdId,
                    noteOwnershipStructure: p.data.company.noteOwnershipStructure,
                    selfLink: p.data.company.selfLink
                }

                draft[p.data.id].people = p.data?.people?.reduce((acc: any, p: any) => {
                    acc[p.id] = {
                        notePerson: p.notePerson,
                        ownershipPercentage: p.ownershipPercentage,
                        selfLink: p.selfLink
                    }

                    return acc
                }, {} as any)

                draft[p.data.id].loadingStatus = 'done'
            })
        }),
        handleAction(ApplicationInternalsDetailsActions.STORE_FROM_ENTITY, (state, { payload: p }) => {
            return produce(state, (draft) => {
                if (!draft[p.id]) draft[p.id] = {} as any
                switch (p.entity) {
                    case 'company':
                        draft[p.id].company = {
                            bvdId: p.data.bvdId,
                            noteOwnershipStructure: p.data.noteOwnershipStructure,
                            selfLink: p.data.selfLink
                        }
                        break
                    case 'businessModel':
                        draft[p.id].businessModel = {
                            longBusinessModel: p.data.longBusinessModel,
                            shortBusinessModel: p.data.shortBusinessModel,
                            marketplaceId: p.data.marketplaceId,
                            selfLink: p.data.selfLink
                        }
                        break
                    case 'people':
                        draft[p.id].people = {
                            ...state[p.id].people,
                            [p.data.id]: {
                                notePerson: p.data.notePerson,
                                ownershipPercentage: p.data.ownershipPercentage,
                                selfLink: p.data.selfLink
                            }
                        }
                        break
                    default:
                        break
                }
                draft[p.id].loadingStatus = 'done'
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = { ...InitialApplicationInternalsDetailsState }
                return draft
            })
        })
    ]
)
