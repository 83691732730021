import React, { useCallback, useMemo, useState } from 'react'
import { SimpleButton } from '../../../../components/buttons/simpleButton'
import { Text } from '../../../../components/general/text'
import { Floater } from '../../../../components/layout/floater'
import { FloaterInset } from '../../../../components/layout/floaterInset'
import { useOnClickOutside } from '../../../../hooks/general/useOnClickOutside'
import { useRefTaker } from '../../../../hooks/general/useRefTaker'
import { MerchantNotificationType, WebhooksConfig } from '../../../../store/applicationInternals/record/types'
import { ListWebhooks } from './More.ListWebhooks'

export const MoreNotificationsWebhooks: React.FC<{ events?: MerchantNotificationType[] }> = ({ events }) => {
    const [holderRef, setHolderRef] = useRefTaker()
    const [ref, setRef] = useRefTaker()
    const [show, setShow] = useState(false)

    useOnClickOutside(
        holderRef,
        useCallback(() => {
            setShow(false)
        }, [])
    )
    const dictionary = useMemo(() => {
        const acc = {}
        events?.forEach((e) => {
            Object.keys(WebhooksConfig.eventsBySection).forEach((s) => {
                const eventsBySection = WebhooksConfig.eventsBySection[s]
                if (eventsBySection?.find((i) => i == e)) {
                    if (!acc[s]) {
                        acc[s] = 0
                    }
                    acc[s]++
                }
            })
        })
        return acc
    }, [events])

    const info = useMemo(() => {
        const t: string[] = []
        let acc = 0
        WebhooksConfig.sections.forEach((s, i, arr) => {
            if (dictionary[s]) {
                acc += dictionary[s]
                t.push(dictionary[s] + ' ' + s.toLowerCase())
            }
        })
        if (t.length == 0)
            return {
                text: '-',
                counter: 0
            }
        return {
            text: t.join(', '),
            counter: acc
        }
    }, [dictionary])

    return (
        <div ref={setHolderRef}>
            <div ref={setRef}>
                {info.text === '-' ? (
                    <Text>-</Text>
                ) : (
                    <SimpleButton
                        onClick={() => {
                            setShow((s) => !s)
                        }}
                    >
                        <Text oneLine>{info.text}</Text>
                        &nbsp;{info.counter == 1 ? 'listener' : 'listeners'}
                    </SimpleButton>
                )}
            </div>
            <Floater noFocusLock anchor={ref} shouldShow={show}>
                <FloaterInset>
                    <ListWebhooks horizontal selectedEvents={events} disabled overrideColor={'floating.text'} />
                </FloaterInset>
            </Floater>
        </div>
    )
}
