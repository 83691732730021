/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from 'deox'
import dotProp from 'dot-prop'
import { snakeCase } from 'lodash'
import { put, select } from 'redux-saga/effects'

import { POST } from '../../../generators/networking'
import { RootState } from '@/store'
import { WatcherDispatchFail, WatcherDispatchStart, WatcherDispatchSuccess } from '../../watcher/actions'
import { ApplicationInternalsDetailsActions } from './actions'
import { InternalDetailsStructure } from './types'

export const ApplicationInternalsDetailsSagas = {
    *CLONE({ payload }: ActionType<typeof ApplicationInternalsDetailsActions.CLONE>) {
        yield put(WatcherDispatchStart([payload.watcherId]))

        const { success, cleanedResponse } = yield POST({
            url: `${import.meta.env.VITE_CUTTER_ROOT}/applications/${payload.id}/duplicate`,
            body: {
                state: payload.toState
            },
            successCode: 200,
            include: ['self']
        })

        if (success) {
            yield put(
                WatcherDispatchSuccess([payload.watcherId], 'Application cloned successfully.', cleanedResponse.id)
            )
        } else yield put(WatcherDispatchFail([payload.watcherId], 'Failed to clone application.'))
    },
    *UPDATE_VALUE({ payload }: ActionType<typeof ApplicationInternalsDetailsActions.UPDATE_VALUE>) {
        const updateLink: string = yield select((state: RootState) => {
            return dotProp.get(
                state.applicationInternals.details,
                `${payload.applicationId}.${payload.fieldPath}.selfLink`
            )
        })

        yield POST({
            watcher: payload.watcherId,
            url: updateLink,
            body: {
                [snakeCase(payload.type)]: payload.value === "" ? null : payload.value
            },
            successCode: 200,
            include: ['self'],
            successText: `Internal detail saved.`,
            errorText: `Failed to save the internal detail`,
            onSuccessDispatch: (c) => {
                return ApplicationInternalsDetailsActions.STORE_FROM_ENTITY(
                    InternalDetailsStructure[payload.type].resource,
                    payload.applicationId,
                    c
                )
            }
        })

        return
    }
}
