import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { MessageBox, MessageBoxAction } from '../../components/forms/messageBox'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { ApplicationResourceActions } from '../../store/applicationResources/actions'
import { MerchantApplicationResourceIndexPath } from '../../store/applicationResources/types'
import { ToastsDispatchPush } from '../../store/toasts/actions'
import { merchantCommentTemplates } from '../../utils/templates'

export const MerchantApplicationCommentsModalMessageBox: React.FC<{
    applicationId: string
    indexPath?: MerchantApplicationResourceIndexPath
    after?: any
    focusOn?: any
    onTemplateSelection?: () => void
}> = ({ focusOn, applicationId, onTemplateSelection, indexPath, after }) => {
    const [input, setInput] = useRefTaker()
    const dispatch = useDispatch()

    useEffect(() => {
        input?.focus()
    }, [input, focusOn])

    const actions = useMemo(() => {
        const action: MessageBoxAction = {
            label: 'Send message',
            action: (watcherId, text, reset) => {
                if (!indexPath) {
                    dispatch(ToastsDispatchPush('Failed to send the comment. No indexPath found.', 'error'))
                    throw 'Cannot send comment, no indexPath found.'
                }
                if (text) dispatch(ApplicationResourceActions.SEND_COMMENT(watcherId, applicationId, text, indexPath))
                reset()
            }
        }

        return [action]
    }, [applicationId, dispatch, indexPath])

    return (
        <BoxHolder>
            <MessageBox
                escKeyOnceIsEnough
                noScrollOnReset
                cyPrefix="merchant"
                placeholder="Send a message to the merchant"
                pageId="Merchant.ApplicationPage.FieldComments"
                textareaRef={setInput}
                actions={actions}
                templates={merchantCommentTemplates}
                onTemplateSelection={onTemplateSelection}
            />
            {after}
        </BoxHolder>
    )
}

const BoxHolder = styled.div`
    position: sticky;
    bottom: 0;
    padding: 20px 5px 30px 5px;
    margin-bottom: -20px;
    margin-left: -5px;
    margin-right: -5px;
    z-index: 10;
    background-color: ${(p) => p.theme['front.background']};
    border-top: 1px solid ${(p) => p.theme['front.border']};
`
