import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GatewaysDispatchLoad } from '../../store/gateways/actions'
import { RootState } from '@/store'

export const useGateways = () => {
    const dispatch = useDispatch()
    const gateways = useSelector((state: RootState) => {
        return state.gateways.gateways
    })

    useEffect(() => {
        if (gateways.loadingStatus === 'not-started') dispatch(GatewaysDispatchLoad())
    }, [dispatch, gateways])

    return gateways
}
