import { camelCase, kebabCase } from 'lodash'

import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { camelCaseWithDots } from '../../../store/applications/utils'
import { isValidUUID } from '../../../utils/types'
import {
    IMerchantApplicationSectionItemDetails,
    IMerchantApplicationSectionStructure,
    IMerchantApplicationSubsectionItemDetails,
    IMerchantApplicationSubsectionStructure,
    TMerchantApplicationStructure
} from './Application.StructureTypes'

const SPACER: { kind: 'spacer' } = {
    kind: 'spacer'
}

export const MerchantApplicationStructure: Readonly<TMerchantApplicationStructure> = Object.freeze([
    {
        type: 'static',
        label: 'Company',
        sectionCheckedStatusFieldPath: 'company.fields.name',
        canBeChecked: true,
        waitForSections: ['company'],
        internalDetails: 'companyInternalDetails',
        reviewableResource: {
            cutterPath: 'company',
            field: 'name'
        },
        fields: [
            {
                skipRendering: true,
                kind: 'field',
                resource: 'company',
                label: 'Name',
                type: 'text',
                id: {
                    cutter: 'name',
                    mapi: 'name'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Registration #',
                type: 'text',
                id: {
                    cutter: 'number',
                    mapi: 'registration_number'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Form',
                type: 'dropdown',
                hasCommentsDisabled: true,
                id: {
                    cutter: 'form',
                    mapi: 'form'
                }
            },
            SPACER,
            {
                kind: 'field',
                resource: 'company',
                label: 'Phone',

                type: 'phone',
                id: {
                    cutter: 'phone_number',
                    mapi: 'phone'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Email',

                type: 'email',
                id: {
                    cutter: 'email',
                    mapi: 'email'
                }
            },
            SPACER,
            {
                kind: 'field',
                resource: 'company',
                label: 'Country',
                type: 'dropdown',
                id: {
                    cutter: 'country',
                    mapi: 'country'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Street line 1',
                type: 'text',
                id: {
                    cutter: 'address1',
                    mapi: 'address_line_1'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Street line 2',
                type: 'text',
                showEvenIfBlank: true,
                id: {
                    cutter: 'address2',
                    mapi: 'address_line_2'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'ZIP',
                type: 'text',
                id: {
                    cutter: 'zipcode',
                    mapi: 'zipcode'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'City',
                type: 'text',
                id: {
                    cutter: 'city',
                    mapi: 'city'
                }
            },
            SPACER,
            {
                kind: 'field',
                resource: 'company',
                label: 'Documentation',

                type: 'file',
                id: {
                    cutter: 'documentation',
                    mapi: 'documentation'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Suretyship',
                hasCommentsDisabled: true,
                type: 'file',
                id: {
                    cutter: 'suretyship',
                    mapi: 'suretyship'
                }
            }
        ]
    },
    {
        type: 'static',
        label: 'Sanitised Company',
        waitForSections: ['company'],
        fields: [
            {
                kind: 'field',
                resource: 'company',
                label: 'Sanitised name',
                type: 'text',
                sanitised: true,
                validation: {
                    maxLength: 20
                },
                id: {
                    cutter: 'sanitisedName',
                    mapi: 'sanitised.name'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Sanitised address',
                type: 'text',
                sanitised: true,
                validation: {
                    maxLength: 35
                },
                id: {
                    cutter: 'sanitisedAddressLine',
                    mapi: 'sanitised.address_line_1'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Sanitised ZIP',
                type: 'text',
                sanitised: true,
                validation: {
                    maxLength: 10
                },
                id: {
                    cutter: 'sanitisedZipcode',
                    mapi: 'sanitised.zipcode'
                }
            },
            {
                kind: 'field',
                resource: 'company',
                label: 'Sanitised city',
                type: 'text',
                sanitised: true,
                validation: {
                    maxLength: 13
                },
                id: {
                    cutter: 'sanitisedCity',
                    mapi: 'sanitised.city'
                }
            }
        ]
    },
    {
        canBeChecked: true,
        reviewableResource: {
            cutterPath: 'contact',
            field: 'name'
        },
        type: 'static',
        label: 'Contact',
        waitForSections: ['contact'],
        internalDetails: 'contactInternalDetails',
        fields: [
            {
                kind: 'field',

                resource: 'contact',
                type: 'text',
                label: 'Contact name',
                id: {
                    cutter: 'name',
                    mapi: 'name'
                }
            },
            {
                kind: 'field',

                resource: 'contact',
                type: 'email',
                label: 'Contact email',
                hasCommentsDisabled: true,
                id: {
                    cutter: 'email',
                    mapi: 'email'
                }
            },
            {
                kind: 'field',

                resource: 'contact',
                type: 'phone',
                label: 'Contact phone',
                id: {
                    cutter: 'phone',
                    mapi: 'phone'
                }
            },
            SPACER,
            {
                kind: 'field',
                resource: 'signer',
                type: 'text',
                label: 'Signer name',
                id: {
                    cutter: 'signer_name',
                    mapi: 'signer.name'
                }
            },
            {
                kind: 'field',
                resource: 'signer',
                type: 'email',
                label: 'Signer email',
                id: {
                    cutter: 'signer_email',
                    mapi: 'signer.email'
                }
            }
        ]
    },
    {
        canBeChecked: true,
        reviewableResource: {
            cutterPath: 'company',
            field: 'ownership_structure'
        },
        type: 'static',
        label: 'Ownership',
        suffix: 'Person',
        waitForSections: ['company', 'people', 'contact'],
        internalDetails: 'ownershipInternalDetails',
        fields: [
            {
                kind: 'field',
                resource: 'company',
                label: 'Ownership',
                type: 'dropdown',
                skipRendering: true,
                id: {
                    cutter: 'ownership_structure',
                    mapi: 'ownership_structure'
                }
            }
        ]
    },
    {
        type: 'dynamic',
        suffix: 'Person',
        // isDynamic = The fields are repeating, according to the data. e.g. User has multiple websites.
        canBeChecked: true,
        resource: 'people',
        reviewableResource: {
            cutterPath: 'people',
            field: 'role',
            multi: true
        },
        internalDetails: 'personInternalDetails',
        fields: [
            {
                kind: 'field',
                label: 'Name',
                type: 'text',
                skipRendering: true,
                id: {
                    cutter: 'name',
                    mapi: 'name'
                }
            },
            {
                kind: 'field',
                label: 'Role',
                type: 'dropdown',
                path: 'role',
                hasCommentsDisabled: true,
                id: {
                    cutter: 'role',
                    mapi: 'role'
                }
            },
            {
                kind: 'field',
                label: 'Social security #',
                type: 'text',
                id: {
                    cutter: 'social_security_number',
                    mapi: 'social_security_number'
                }
            },
            {
                kind: 'field',
                label: 'Date of birth',
                type: 'date',
                id: {
                    cutter: 'date_of_birth',
                    mapi: 'date_of_birth'
                }
            },
            SPACER,
            {
                kind: 'field',
                label: 'Country',
                type: 'dropdown',
                showEvenIfBlank: true,
                id: {
                    cutter: 'country',
                    mapi: 'country'
                }
            },
            {
                kind: 'field',
                label: 'Street line 1',
                type: 'text',
                id: {
                    cutter: 'address1',
                    mapi: 'address_line_1'
                }
            },
            {
                kind: 'field',
                label: 'Street line 2',
                type: 'text',
                showEvenIfBlank: true,
                id: {
                    cutter: 'address2',
                    mapi: 'address_line_2'
                }
            },
            {
                kind: 'field',
                label: 'ZIP',
                type: 'text',
                id: {
                    cutter: 'zipcode',
                    mapi: 'zipcode'
                }
            },
            {
                kind: 'field',
                label: 'City',
                type: 'text',
                showEvenIfBlank: true,
                id: {
                    cutter: 'city',
                    mapi: 'city'
                }
            },
            SPACER,
            {
                kind: 'field',
                label: 'Address legit.',
                type: 'file',
                id: {
                    cutter: 'address_legitimation',
                    mapi: 'address_legitimation'
                }
            },
            {
                kind: 'field',
                label: 'Picture legit.',
                type: 'file',
                id: {
                    cutter: 'pictureLegitimation',
                    mapi: 'picture_legitimation'
                }
            }
        ]
    },
    {
        type: 'static',
        label: 'Business model',
        canBeChecked: true,
        waitForSections: ['businessModel'],
        internalDetails: 'businessModelInternalDetails',
        reviewableResource: {
            cutterPath: 'application',
            field: 'business_model'
        },
        fields: [
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Business model',
                type: 'multilineText',
                id: {
                    cutter: 'description',
                    mapi: 'business_model.description'
                }
            },
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Trading name',
                type: 'text',
                id: {
                    cutter: 'trading_name',
                    mapi: 'business_model.trading_name'
                }
            },
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Recurring',
                type: 'question',
                id: {
                    cutter: 'recurring',
                    mapi: 'business_model.recurring'
                }
            },
            SPACER,
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Turnover currency',

                type: 'dropdown',
                path: 'estimateCurrency',
                hasCommentsDisabled: true,
                id: {
                    cutter: 'estimate_monthly_turnover_currency',
                    mapi: 'business_model.estimate_currency'
                }
            },
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Monthly turnover',
                type: 'number',

                path: 'estimatedMonthlyTurnover',
                id: {
                    cutter: 'estimate_monthly_turnover',
                    mapi: 'business_model.estimated_monthly_turnover'
                }
            },
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Avg. trans. amount',

                type: 'number',
                path: 'estimatedAverageTransactionAmount',
                id: {
                    cutter: 'estimate_average_transaction_amount',
                    mapi: 'business_model.estimated_average_transaction_amount'
                }
            },
            SPACER,
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Physical delivery',
                type: 'question',
                path: 'physicalDelivery',
                id: {
                    cutter: 'delivery_physical',
                    mapi: 'business_model.physical_delivery'
                }
            },
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Delivery delay',
                type: 'dropdown',
                path: 'deliveryDelay',
                showOnlyIfSiblingFieldExists: 'businessModel.0.businessModel.physicalDelivery',
                id: {
                    cutter: 'delivery_delay',
                    mapi: 'business_model.delivery_delay'
                }
            },
            {
                kind: 'field',
                resource: 'businessModel',
                label: 'Drop shipping',
                type: 'question',
                id: {
                    cutter: 'drop_shipping',
                    mapi: 'business_model.drop_shipping'
                }
            }
        ]
    },
    {
        type: 'static',
        label: 'Websites',
        suffix: 'Website',
        waitForSections: ['websites', 'gateway'],
        fields: [
            {
                kind: 'field',
                resource: 'gateway',
                label: 'Gateway',
                type: 'dropdown',
                hasCommentsDisabled: true,
                id: {
                    cutter: 'businessModel.gatewayId',
                    mapi: 'gateways.0.id'
                }
            }
        ]
    },
    {
        type: 'dynamic',
        suffix: 'Website',
        canBeChecked: true,
        resource: 'websites',
        reviewableResource: {
            cutterPath: 'websites',
            field: 'url',
            multi: true
        },
        internalDetails: 'websiteInternalDetails',
        fields: [
            {
                kind: 'field',
                label: 'URL',
                type: 'url',
                skipRendering: true,
                id: {
                    cutter: 'url',
                    mapi: 'url'
                }
            },
            {
                kind: 'field',
                label: 'Comment',
                type: 'multilineText',
                id: {
                    cutter: 'url_comment',
                    mapi: 'comment'
                }
            },
            {
                kind: 'field',
                label: 'Ownership',
                type: 'file',
                id: {
                    cutter: 'ownership',
                    mapi: 'ownership'
                }
            }
        ]
    },
    {
        type: 'static',
        label: 'Bank account',
        disableSectionOnceAccountsAreReady: true,
        canBeChecked: true,
        reviewableResource: {
            cutterPath: 'bankAccounts.0',
            field: 'bank'
        },
        waitForSections: ['bankAccount'],
        internalDetails: 'bankAccountInternalDetails',
        fields: [
            {
                kind: 'field',
                resource: 'bankAccount',
                label: 'Bank name',

                type: 'text',
                id: {
                    cutter: 'bank',
                    mapi: 'bank'
                }
            },
            {
                kind: 'field',
                resource: 'bankAccount',
                label: 'Currency',
                type: 'dropdown',
                id: {
                    cutter: 'currency',
                    mapi: 'currency'
                }
            },
            SPACER,
            {
                kind: 'field',
                resource: 'bankAccount',
                label: 'IBAN',

                type: 'text',
                id: {
                    cutter: 'iban',
                    mapi: 'iban'
                }
            },
            {
                kind: 'field',
                resource: 'bankAccount',
                label: 'Swift',
                type: 'text',
                id: {
                    cutter: 'swift_code',
                    mapi: 'swift_code'
                }
            }
        ]
    },
    {
        type: 'static',
        label: 'Additional information',
        waitForSections: ['additionalInformation'],
        reviewableResource: {
            cutterPath: 'application',
            field: 'additional_information'
        },
        fields: [
            {
                kind: 'field',
                resource: 'additionalInformation',
                label: 'Additional details',
                type: 'multilineText',
                id: {
                    cutter: 'additional_information',
                    mapi: 'additional_information'
                }
            },
            {
                kind: 'field',
                resource: 'additionalInformation',
                label: 'Additional files',
                type: 'multipleFiles',
                id: {
                    cutter: 'default',
                    mapi: 'default'
                }
            },
            {
                kind: 'field',
                resource: 'additionalInformation',
                label: 'Other files',
                type: 'multipleFiles',
                deprecated: true,
                id: {
                    cutter: 'additional_file',
                    mapi: 'additional_file'
                }
            }
        ]
    }
])

export const MapCutterIdToMAPIId = MerchantApplicationStructure.reduce((acc, section) => {
    section?.fields?.forEach((f) => {
        if (f.kind === 'spacer') return
        let resource
        if (section.type === 'dynamic') resource = section.resource
        else resource = (f as any).resource
        acc[`${resource}.${camelCase(f.id.cutter)}`] = camelCaseWithDots(f.id.mapi)
    })
    return acc
}, {} as any)

export interface IMerchantApplicationFieldDetails {
    section: Omit<IMerchantApplicationSectionStructure | IMerchantApplicationSubsectionStructure, 'fields'>
    field: IMerchantApplicationSubsectionItemDetails | IMerchantApplicationSectionItemDetails
    urlPath: string
}

export const URLPathToFieldDetails: {
    [k: string]: IMerchantApplicationFieldDetails
} = {}
export const ResourcePathFieldStructureMap: {
    [k: string]: IMerchantApplicationFieldDetails
} = MerchantApplicationStructure.reduce((acc, section) => {
    const { fields, ...sectionMeta } = section
    if (section.type === 'dynamic') {
        const { resource } = section
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
            // eslint-disable-next-line no-continue
            if (field.kind === 'spacer') continue
            const indexString = `${resource}.${camelCaseWithDots(field.id.mapi)}`
            const urlPath = `${kebabCase(section.suffix)}/<ID>/${kebabCase(field.label)}`
            URLPathToFieldDetails[urlPath] = {
                section: sectionMeta,
                field,
                urlPath
            }
            acc[indexString] = {
                section: sectionMeta,
                field,
                urlPath
            }
        }
    } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
            // eslint-disable-next-line no-continue
            if (field.kind === 'spacer') continue
            const urlPath = `${kebabCase(section.label)}/${kebabCase(field.label)}`
            URLPathToFieldDetails[urlPath] = {
                section: sectionMeta,
                field,
                urlPath
            }
            acc[`${(field as any).resource}.${camelCaseWithDots(field.id.mapi)}`] = {
                section: sectionMeta,
                field,
                urlPath
            }
        }
    }
    return acc
}, {} as { [k: string]: IMerchantApplicationFieldDetails })

const ResourcePathsExcludedCommentsFields = [
    'company.suretyship',
    'company.sanitised.name',
    'company.sanitised.addressLine1',
    'company.sanitised.zipcode',
    'company.sanitised.city',
    'additionalInformation.default',
    'contact.email',
    'people.role'
]

export const ResourceFieldsThatSupportComments = Object.keys(ResourcePathFieldStructureMap).filter(
    (f) => !ResourcePathsExcludedCommentsFields.includes(f)
)

export const ConvertURLPartsToFieldDetails = (sectionLabel: string, fieldLabelOrID: string, fieldLabel?: string) => {
    const path = isValidUUID(fieldLabelOrID)
        ? `${sectionLabel}/<ID>/${fieldLabel}`
        : `${sectionLabel}/${fieldLabelOrID}`
    return URLPathToFieldDetails[path]
}

export const ConvertIndexPathToResourcePath = (indexPath: MerchantApplicationResourceIndexPath) => {
    return `${indexPath.resourceKey}.${indexPath.fieldKey}`
}

export const ConvertIndexPathToFieldDetails = (indexPath: MerchantApplicationResourceIndexPath) => {
    return ResourcePathFieldStructureMap?.[
        ConvertIndexPathToResourcePath(indexPath)
    ] as IMerchantApplicationFieldDetails
}

export const GenerateIndexPathForSection = (field: IMerchantApplicationSectionItemDetails) => {
    return {
        resourceKey: field.resource,
        fieldKey: camelCaseWithDots(field.id.mapi)
    }
}

export const GenerateIndexPathForSubsection = (
    subsection: IMerchantApplicationSubsectionStructure,
    index: number,
    field: IMerchantApplicationSubsectionItemDetails
) => {
    return {
        resourceKey: subsection.resource,
        subsectionIndex: index,
        fieldKey: camelCaseWithDots(field.id.mapi)
    }
}

export type ResourcePath = string
const GetFieldStructureByResourcePath = (resourcePath: ResourcePath) => {
    return ResourcePathFieldStructureMap[resourcePath]
}

export const AllMerchantApplicationSections = (() => {
    const sectionDictionary: any = {}

    MerchantApplicationStructure.forEach((k) => {
        if (k.type === 'dynamic') {
            sectionDictionary[k.resource] = true
        } else {
            k.fields.forEach((f) => {
                if (f.kind !== 'spacer') {
                    sectionDictionary[f.resource] = true
                }
            })
        }
    })

    return Object.keys(sectionDictionary)
})()

const StaticSections = MerchantApplicationStructure.filter((section) => {
    return section.type === 'static'
})

const DynamicSections = MerchantApplicationStructure.filter((section) => {
    return section.type === 'dynamic'
})
