import styled from 'styled-components'

export const GeneralConflictBox = styled.div`
    padding: 20px;
    background-color: ${(p) => p.theme['overlay.background.strongerI']};
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    box-sizing: border-box;
    text-align: center;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
`
