import { useMemo } from 'react'
import { LinkButton } from '../../../../../../components/buttons/linkButton'
import { Text } from '../../../../../../components/general/text'
import { WarnBox } from '../../../../../../components/general/warnBox'
import { Flex } from '../../../../../../components/layout/flex'
import { BatchOfMonitoredChanges } from '../../../../../../store/tasksCompanyChanges/types'

export const DuplicatePeopleWarning: React.FC<{
    applicationId: string
    change: BatchOfMonitoredChanges
}> = ({ applicationId, change }) => {
    const shouldShow = useMemo(() => {
        const acc = {}
        change.fetchedApplicationData.data?.people
            .filter((p) => !!p.socialSecurityNumber)
            .forEach((p) => {
                const everythingButNumbers = (p.socialSecurityNumber as any).replace(/\D/g, '')
                if (!acc[everythingButNumbers]) acc[everythingButNumbers] = 0
                acc[everythingButNumbers]++
            })
        return Object.keys(acc).findIndex((k) => acc[k] > 1) !== -1
    }, [change])

    if (!shouldShow) return null

    return (
        <WarnBox withTopMargin>
            <Flex justify="space-between" grow>
                <Text>Multiple people seem to have similar Social Security Numbers in this application.</Text>
                <LinkButton to={`/merchant/${applicationId}/application#people`} target="managerApplications">
                    Go to the «People» section
                </LinkButton>
            </Flex>
        </WarnBox>
    )
}
