import { put, select, take } from 'typed-redux-saga'

import { GET } from '../../generators/networking'
import { getRootLinks } from '../../generators/selectors'
import { TQLConverter } from '../../utils/tqlConverter'
import { Application } from '../applications/types'
import { GatewaysDispatchLoad } from '../gateways/actions'
import { RootState } from '@/store'
import { USER_ACCOUNTS_STORE_ACCOUNT, UserAccountsDispatchGetAccount } from '../userAccounts/actions'
import {
    SagasForTransactions,
    TransactionActionLoad,
    TransactionActionLoadAppendix,
    TransactionActionLoadExtraDetails,
    TransactionDispatchStore,
    TransactionDispatchStoreAppendix,
    TransactionDispatchStoreExtraDetails,
    TransactionsActionLoadNextSummaries,
    TransactionsActionLoadSummaries,
    TransactionsDispatchAppendSummaries,
    TransactionsDispatchStoreSummaries
} from './actions'
import { Transaction, TransactionsFilters } from './types'

export class TransactionsSagas implements SagasForTransactions {
    *loadSummaries(action: TransactionsActionLoadSummaries) {
        yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/transactions${TQLConverter.transactions(
                action.filters ? action.filters : ({} as TransactionsFilters)
            )}`,
            include: ['account', 'next'],
            onSuccessDispatch: (r: any) => {
                return TransactionsDispatchStoreSummaries(r.transactions, r.nextLink)
            },
            onFailureDispatch: (r: any) => {
                if (r.status == 504)
                    return TransactionsDispatchStoreSummaries(
                        undefined,
                        undefined,
                        'Unfortunately the search timed out. Please restrict it, e.g. with a smaller time frame.'
                    )
                return TransactionsDispatchStoreSummaries(
                    undefined,
                    undefined,
                    'Unfortunately the search returned an error.'
                )
            },
            errorText: 'Failed to load transactions summaries.'
        })
    }

    *load(action: TransactionActionLoad) {
        const transaction: Transaction = yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/transactions/${action.id}`,
            errorText: 'Failed to load transaction.',
            include: ['account', 'settlement', 'appendix']
        })

        yield put(TransactionDispatchStore(transaction))
    }

    *loadNextSummaries(action: TransactionsActionLoadNextSummaries) {
        const nextLink = yield* select((state: RootState) => state.transactions.summaries.nextLink)
        if (!nextLink) return
        yield GET({
            url: nextLink!,
            include: ['account', 'next'],
            onSuccessDispatch: (r) => {
                return TransactionsDispatchAppendSummaries(r.transactions, r.nextLink)
            },
            errorText: 'Failed to load the next transactions summaries'
        })
    }

    *loadExtraDetails(action: TransactionActionLoadExtraDetails) {
        yield put(GatewaysDispatchLoad())
        yield put(
            UserAccountsDispatchGetAccount(action.transaction.accountId, {
                isMerchant: true
            })
        )

        let rawMerchant: { id: string; data: any }
        do {
            rawMerchant = yield take(USER_ACCOUNTS_STORE_ACCOUNT)
        } while (rawMerchant.id != action.transaction.accountId)

        const newTransaction = { ...action.transaction }
        newTransaction.extraDetails.account = rawMerchant.data
        newTransaction.extraDetails.application = undefined

        if (newTransaction?.extraDetails?.account?.applicationLink) {
            const rootLinks = yield* getRootLinks()

            const applicationId = newTransaction.extraDetails.account.applicationLink.split('/').pop()
            const applicationLink = `${rootLinks?.cutter.applicationLink}/${applicationId}`

            newTransaction.extraDetails.application = (yield GET({
                url: `${applicationLink}?expand=websites,contact,tags`,
                errorText: "Failed to load transactions' merchant' application."
            })) as Application
        }

        if (newTransaction?.extraDetails?.account?.mcc) {
            const mccDescription = (
                (yield GET({
                    url: `${import.meta.env.VITE_MCC_ENDPOINT}/?limit=1&q=${newTransaction.extraDetails.account.mcc}`,
                    errorText: "Failed to load task's merchant MCC description."
                })) as any[]
            )?.[0]

            newTransaction.extraDetails.account.mccDescription = mccDescription ? mccDescription.label : undefined
        }

        yield put(TransactionDispatchStoreExtraDetails(newTransaction))
    }

    *loadAppendix(action: TransactionActionLoadAppendix) {
        const newTransaction = { ...action.transaction }
        const appendixData = yield GET({
            url: action.transaction.appendixLink,
            rawResponse: true,
            errorText: 'Failed to load transaction appendix.'
        })
        newTransaction.appendix.data = appendixData
        yield put(TransactionDispatchStoreAppendix(newTransaction))
    }
}
