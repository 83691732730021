import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { RootState } from '@/store'

export const useMerchantApplicationFilesReselector = (
    applicationId: string,
    indexPath: MerchantApplicationResourceIndexPath
) => {
    const fileIds = useSelector(
        (state: RootState) =>
            state.applicationResources?.data?.forApplication?.[applicationId]?.[indexPath.resourceKey]?.fields?.[
                indexPath.subsectionIndex || 0
            ]?.[indexPath.fieldKey]
    )
    const files = useSelector((state: RootState) => state.files.at)

    return useMemo(() => {
        return typeof fileIds === 'string' ? [fileIds] : fileIds?.map((id: string) => files?.[id]) || []
    }, [fileIds, files])
}
