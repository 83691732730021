import { Report } from '@/services/ongoing-due-diligence'

const getRadioDefault = (value: string | undefined) => {
    return value ? value : 'Not check?ed'
}

export const getDefaultValues = (data: Report) => {
    const { Details, Documentation, Checks, Comments } = data?.report_data?.sections

    // Details
    const details = {
        report_reason: Details?.report_reason?.value
    }

    // Documentation
    const { check_doc } = Documentation

    const documentation = {
        company: check_doc?.company?.value,
        websites: check_doc?.websites?.value,
        accounts: check_doc?.accounts?.value,
        ownership_ubo: check_doc?.ownership_ubo?.value,
        management: check_doc?.management?.value,
        ownership_structure: check_doc?.ownership_structure?.value
    }

    // Checks
    const { check } = Checks

    const checks = {
        risk_classification: getRadioDefault(check?.risk_classification?.value),
        comment_google_negative_info_owners: check?.comment_google_negative_info_owners?.value,
        website_likely_chargeback_pattern: getRadioDefault(check?.website_likely_chargeback_pattern?.value),
        comment_website_available_contact_info: check?.comment_website_available_contact_info?.value,
        website_credible_capture_pattern: getRadioDefault(check?.website_credible_capture_pattern?.value),
        google_negative_info_company_url: getRadioDefault(check?.google_negative_info_company_url?.value),
        website_credible_refund_credit_pattern: getRadioDefault(check?.website_credible_refund_credit_pattern?.value),
        comment_risk_classification: check?.comment_risk_classification?.value,
        comment_google_negative_info_company_url: check?.comment_google_negative_info_company_url?.value,
        comment_sanction_lists_owners_listed: check?.comment_sanction_lists_owners_listed?.value,
        website_available_terms_and_condition: getRadioDefault(check?.website_available_terms_and_condition?.value),
        website_average_expected_transaction_size: getRadioDefault(
            check?.website_average_expected_transaction_size?.value
        ),
        company_payment_accounts: getRadioDefault(check?.company_payment_accounts?.value),
        comment_trustpilot_satisfying: check?.comment_trustpilot_satisfying?.value,
        google_negative_info_owners: getRadioDefault(check?.google_negative_info_owners?.value),
        company_fundamental_changes: getRadioDefault(check?.company_fundamental_changes?.value),
        comment_company_fundamental_changes: check?.comment_company_fundamental_changes?.value,
        trustpilot_satisfying: getRadioDefault(check?.trustpilot_satisfying?.value),
        comment_website_satisfying_business_model: check?.comment_website_satisfying_business_model?.value,
        sanction_lists_owners_listed: getRadioDefault(check?.sanction_lists_owners_listed?.value),
        website_available_contact_info: getRadioDefault(check?.website_available_contact_info?.value),
        website_available_refund_policy: getRadioDefault(check?.website_available_refund_policy?.value),
        website_satisfying_business_model: getRadioDefault(check?.website_satisfying_business_model?.value)
    }

    // Comments
    const { comment } = Comments

    const comments = {
        review_confirmation: comment?.review_confirmation?.value,
        aml_escalated: comment?.aml_escalated?.value,
        possibility_of_misconduct: comment?.possibility_of_misconduct?.value,
        comment: comment?.comment?.value,
        no_suspecious_behaviour: comment?.no_suspecious_behaviour?.value
    }

    return {
        ...details,
        ...documentation,
        ...checks,
        ...comments
    }
}
