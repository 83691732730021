import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Icon } from '../icons/icon'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { TextInput } from './textInput'

export const TextInputSearch: React.FC<{
    placeholder: string
    onEnter: React.ComponentProps<typeof TextInput>['onEnter']
    onChange: React.ComponentProps<typeof TextInput>['onChange']
    initialValue?: string
}> = ({ placeholder, onEnter, onChange, initialValue }) => {
    const [ref, setRef] = useRefTaker()

    useEffect(() => {
        ref?.focus()
    }, [ref])

    useEffect(() => {
        if (initialValue) onChange?.(undefined as any, initialValue)
    }, [initialValue, onChange])

    return (
        <Holder>
            <IconWrapper>
                <Icon type="searchIcon" />
            </IconWrapper>
            <Element
                cy="search-bar"
                initialValue={initialValue}
                overBackground="overlay.background"
                ref={setRef}
                placeholder={placeholder}
                onEnter={(e, val) => {
                    if (onChange) onChange?.(e, val)
                    onEnter?.(e, val)
                }}
                className="HOTKEYS_ESC_FORCES_EXIT"
                onChange={onChange}
            />
        </Holder>
    )
}

const Holder = styled.div`
    position: relative;
    width: 100%;
`

const IconWrapper = styled.div`
    position: absolute;
    top: -1px;
    left: 0px;
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    flex-direction: center;
    z-index: 10;
    justify-content: center;
    color: ${(p) => p.theme['front.text']};
`

const Element = styled(TextInput)`
    padding: 15px;
    padding-left: 50px !important;
    box-sizing: border-box;
    background-color: ${(p) => p.theme['overlay.background.strongerI']};
    width: 100%;
    color: ${(p) => p.theme['front.text']};

    &::placeholder {
        color: ${(p) => p.theme['overlay.text']};
    }
`
