import { Collection } from '../../utils'

export interface Gateway {
    active: boolean
    id: string
    name: string
    order: number
    referral: boolean
    support: boolean
    selfLink: string
}

export interface GatewaysState {
    gateways: Collection<Gateway>
}

export const InitialGatewaysState: GatewaysState = {
    gateways: {
        at: {},
        all: [],
        loadingStatus: 'not-started'
    }
}
