import { camelCase } from 'lodash'
import React, { useMemo } from 'react'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import { Expander } from '../layout/expander'

import { Tag, TagBubbleType } from '../general/tag'

export const TagsExpander: React.FC<{
    tagArray: string[]
    onSelect?: (e: any, t: string) => void
    bubbleType: TagBubbleType
}> = ({ bubbleType, onSelect, tagArray }) => {
    const isWide = useMedia('(min-width: 1500px)')
    const tags = useMemo(() => {
        const allTags = [...tagArray]
            .reverse()
            .sort((x, y) => {
                // eslint-disable-next-line no-nested-ternary
                return x === 'important' ? -1 : y === 'important' ? 1 : 0
            })
            .map((t: string, i: number) => ({
                shown: isWide ? i < 2 : i < 1,
                node: <Tag selectable={!!onSelect} bubbleType={bubbleType} key={camelCase(t)} name={t} />
            }))
        return {
            shown: allTags.filter((t) => t.shown).map((t) => t.node),
            hidden: allTags.filter((t) => !t.shown).map((t) => t.node)
        }
    }, [isWide, bubbleType, onSelect, tagArray])

    return (
        <Tags data-cy="tags-list">
            {tags.shown}
            {tags.hidden.length >= 1 && (
                <Expander
                    count={tags.hidden.length}
                    textColor="front.accent.color"
                    listMemo={tags.hidden}
                    insetButton={false}
                />
            )}
        </Tags>
    )
}
//<TaskMoreTags key="moreTags" tags={tags.hidden} />

const Tags = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: no-wrap;
`
