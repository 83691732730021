import { CollectionWithPagination, Pagination } from '../../utils'

type ExportStatus = 'done' | 'created' | 'running'
export type ExportFileFormats = 'pdf' | 'csv' | 'excel'

export const ExportFileFormatsStrings = {
    pdf: 'application/pdf',
    csv: 'text/csv',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const ExportFormatFileTypeToText = (format: ExportFileFormats): string => {
    switch (format) {
        case 'csv':
            return 'CSV'
        case 'excel':
            return 'Excel'
        case 'pdf':
            return 'PDF'
    }
}
export interface ExportsFilters {
    page: number
    per_page: number
}
export const ExportsFiltersParams: ExportsFilters = {
    page: 1,
    per_page: 20
}

export type Export = {
    createdAt: string
    id: string
    sources: string[]
    status: ExportStatus
    type: 'application/pdf'
    downloadLink?: string
    selfLink?: string
    statuses: {
        status: ExportStatus
        createdAt: string
    }
}

export interface ExportsState {
    exports: CollectionWithPagination<Export, Pagination>
}

export const InitialExportsState: ExportsState = {
    exports: {
        at: {},
        all: [],
        pagination: undefined,
        loadingStatus: 'not-started'
    }
}
