import { Account, Posting } from '@/services/reconciliation'
import React, { useState } from 'react'
import { HeaderTitle, Section } from '../../common'
import { Spacer } from '@/components/layout/spacer'
import { Header } from '../../common/section'
import { UnreconcilePosting } from './unreconcile-posting'
import { ForceReconcilePosting } from './force-reconcile-posting'
import { ShowBankData } from './show-bank-data'
import { SinglePostingTable } from './single-posting-table'
import { RawData } from './raw-data'

interface SinglePostingSectionProps {
    posting: Posting
    accounts: Account[]
}

export const SinglePostingSection: React.FC<SinglePostingSectionProps> = (props) => {
    const { posting, accounts } = props
    const [showRawData, setShowRawData] = useState(false)

    const handleOnShowClick = () => {
        setShowRawData((t) => !t)
    }

    return (
        <Section>
            <Section.Header>
                <Header.Title>
                    <HeaderTitle title="Posting details" id={posting.id} />
                </Header.Title>
                <Header.Actions>
                    <ShowBankData shown={showRawData} onClick={handleOnShowClick} />
                    {posting.reconciled && <UnreconcilePosting />}
                    {!posting.reconciled && <ForceReconcilePosting />}
                </Header.Actions>
            </Section.Header>
            <Section.Content>
                <SinglePostingTable posting={posting} accounts={accounts} />
                {showRawData && (
                    <>
                        <Spacer height={10} />
                        <RawData raw_type={posting.raw_type} raw={posting.raw} />
                    </>
                )}
            </Section.Content>
        </Section>
    )
}
