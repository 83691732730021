import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import { Classification } from '@/services/risk-classification'
import React from 'react'
import { DeleteAction } from './delete-action'
import { FinalizeAction } from './finalize-action'

interface IClassification {
    classification: Classification
}

export const ClassificationModalActions: React.FC<IClassification> = ({classification}) => {
    return (
        <Flex>
            <DeleteAction />
            <Spacer width={16} />
            <FinalizeAction classification={classification}/>
        </Flex>
    )
}
