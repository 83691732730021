import { RTKQuery } from '@/services/common'
import { MAPI_PARTNERS_ROOT, getIntegrations, getPartner, getSigningKey, getSigningKeys } from '@/services/merchant-api'

export const getPartnerData = async (id: string, request: Request) => {
    const partnerUrl = `${MAPI_PARTNERS_ROOT}/${id}`
    const partner = await RTKQuery.get(getPartner, request, partnerUrl)
    const integrationsUrl = partner.data?.relations.integrations ? partner.data?.relations.integrations : ''

    if (integrationsUrl) {
        const { data } = await RTKQuery.get(getIntegrations, request, integrationsUrl)

        const integrations = data?.embedded['ch:integrations']

        if (integrations && integrations.length > 0) {
            await Promise.all(
                integrations.map(async (integration) => {
                    const signingKeysUrl = integration.relations['signing-keys']
                    const { data } = await RTKQuery.get(getSigningKeys, request, signingKeysUrl)

                    const signingKeys = data?.embedded['ch:signing-keys']

                    if (signingKeys && signingKeys.length > 0) {
                        await Promise.all(
                            signingKeys.map(async (signingKey) => {
                                const signingKeyUrl = signingKey.relations.self
                                await RTKQuery.get(getSigningKey, request, signingKeyUrl)
                            })
                        )
                    }
                })
            )
        }
    }

    return null
}
