import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Icon } from '../../components/icons/icon'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { RootState } from '@/store'
import { Color } from '../../styles/colors'
import { InternalNotesParams } from './MerchantSidebarInternalNotes'
import { useMerchantSidebarDynamicPageId } from './useMerchantSidebarDynamicPageId'

export const MerchantSidebarNotes: React.FC<{
    applicationId: string
}> = ({ applicationId }) => {
    const pageId = useMerchantSidebarDynamicPageId()
    const [query, setQuery] = useQueryParams(InternalNotesParams, undefined)
    const areNotesStillLoading = useSelector((state: RootState) => {
        return state.applications?.applications?.at?.[applicationId]?.cutterInfoLoadingStatus !== 'done'
    })
    const interactions = useSelector(
        (state: RootState) => state.applications.applications.at[applicationId]?.internalInteractions
    )

    const internalNotesButtonNeedsAttention = useSelector(
        (state: RootState) =>
            state.applicationInternals?.needingAttention?.[applicationId]?.internalNotesNeedingAttention
    )

    const numberOfInteractions = useMemo(() => {
        return interactions?.comments?.length + interactions?.files?.length || 0
    }, [interactions])

    const notesButtonStyles = useMemo(() => {
        if (internalNotesButtonNeedsAttention)
            return {
                text: 'side.background' as Color,
                background: 'side.accent.color' as Color
            }
        return {
            text: 'side.subtleAccent.text' as Color,
            background: 'side.subtleAccent.background' as Color
        }
    }, [internalNotesButtonNeedsAttention])
    const handleInternalNotesClick = useCallback(() => {
        setQuery((q: any) => ({
            ...q,
            'internal-notes': 'everything'
        }))
    }, [setQuery])

    return (
        <SimpleButton
            background={notesButtonStyles.background}
            color={notesButtonStyles.text}
            hotkeysScope={pageId}
            hotkeys="alt+n"
            cy={`internal-notes-button ${internalNotesButtonNeedsAttention ? 'in-needs-attention' : ''}`}
            onClick={handleInternalNotesClick}
        >
            <ButtonInset noVerticalPadding>
                <IconHolder>
                    <Icon type="notes" />
                </IconHolder>
                {numberOfInteractions ? `Notes (${numberOfInteractions})` : 'Notes'}
            </ButtonInset>
        </SimpleButton>
    )
}

const IconHolder = styled.div`
    position: relative;
    top: 2px;
    margin-right: 4px;
`
