import { isTypo } from '../../utils/typosMatching/isTypo'
import { Person } from '../applications/types'

import { isSamePerson } from './sagas.conflictsFinder.utils'
import {
    CompanyInfoPotentialMatch,
    DataProviderOwnershipConflict,
    DataProviderOwnershipConflictUBOsMismatch,
    MutedConflictsPayload
} from './types'

const isOwner = (o: Person) => {
    return o.role === 'owner' || o.role === 'director-and-owner'
}
// sot - sourceOfTruth
export const scanForOwnershipConflicts = (
    dmp: any,
    appOwners: Person[],
    appOwnersAndDirectors: Person[],
    sotOwners: CompanyInfoPotentialMatch['ownership']['beneficial'] | undefined,
    sotUBOs: CompanyInfoPotentialMatch['ownership']['beneficial'],
    mutedConflicts?: Partial<MutedConflictsPayload>
): DataProviderOwnershipConflict => {
    const isOwnershipConflictMuted = mutedConflicts?.['ownership'] === true
    let type: DataProviderOwnershipConflict['type'] | undefined = undefined
    if (appOwners.length === 0) {
        type = 'application-zero-owners'
    }

    if (!sotOwners || sotOwners.length === 0) {
        type = 'data-provider-blank'
    }

    let ubosMatched = 0
    const conflict: DataProviderOwnershipConflictUBOsMismatch = {
        type: type || 'ubos-mismatch',
        perUBOConflicts: {},
        matches: [],
        muted: isOwnershipConflictMuted
    }

    sotUBOs.forEach((ubo) => {
        let isPresent = false

        appOwnersAndDirectors.forEach((o) => {
            if (!o?.name) return
            if (isSamePerson(ubo.name, o.name)) {
                if (isOwner(o)) isPresent = true

                conflict.matches.push({
                    type: isOwner(o) ? 'full' : 'wrong-role',
                    sot: {
                        name: ubo.name,
                        country: ubo.country
                    },
                    app: {
                        id: o.id,
                        name: o.name,
                        country: o.country,
                        role: o.role
                    }
                })
            }
        })

        if (!isPresent) {
            let hasBeenPartiallyMatched = false
            appOwnersAndDirectors.forEach((o) => {
                if (!o.name) return
                const diff = isTypo(dmp, ubo.name, o.name)
                if (diff) {
                    hasBeenPartiallyMatched = true
                    conflict.perUBOConflicts[ubo.name] = {
                        type: 'typo',
                        highlights: diff,
                        id: o.id,
                        applicationValue: o.name
                    }
                    conflict.matches.push({
                        type: 'partial',
                        highlights: diff,
                        sot: {
                            name: ubo.name,
                            country: ubo.name
                        },
                        app: {
                            id: o.id,
                            name: o.name,
                            country: o.country,
                            role: o.role
                        }
                    })
                }
            })
            if (!hasBeenPartiallyMatched) {
                conflict.perUBOConflicts[ubo.name] = {
                    type: 'not-found'
                }
            }
        } else {
            ubosMatched++
        }
    })

    if (ubosMatched == sotUBOs.length) {
        return {
            ...conflict,
            type: 'no-conflicts'
        }
    }

    return conflict
}
