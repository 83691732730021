import { getFileByLabel } from '../../../../../helpers'
import { Files } from '@/services/merchant-api'
import { FileLink } from '../../../common/file-link'
import { Section } from '../../../common/section'

interface WebsiteLinks {
    files: Files
}

export const WebsiteLinks: React.FC<WebsiteLinks> = (props) => {
    const { files } = props

    const screenshotsFile = getFileByLabel('home_page_capture', files)
    const whoisFile = getFileByLabel('dns_report', files)

    return (
        <>
            <FileLink file={screenshotsFile}>Screenshot</FileLink>
            <Section.Spacer size="s" />
            <FileLink file={whoisFile}>Whois report</FileLink>
        </>
    )
}
