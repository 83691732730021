import { Url } from '@/services/common'
import { api } from '../api'
import { Integration, IntegrationData, Integrations } from '../types/integrations'

export const MAPI_INTEGRATIONS_ROOT = `${import.meta.env.VITE_API_ROOT}/integrations`

export const integrationsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getIntegrations: build.query<Integrations, Url>({
            query: (url) => {
                return { url }
            },
            transformResponse: (response: Integrations) => {
                if (response.embedded['ch:integrations'] && !Array.isArray(response.embedded['ch:integrations'])) {
                    response.embedded['ch:integrations'] = [response.embedded['ch:integrations']]
                }

                return response
            },
            providesTags: ['integrations']
        }),
        getIntegration: build.query<Integration, Url>({
            query: (url) => {
                return { url }
            },
            providesTags: ['integration']
        }),
        createIntegration: build.mutation<Integration, { url: Url; data: Partial<IntegrationData> }>({
            query: ({ url, data }) => ({
                url,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['integrations']
        }),
        updateIntegration: build.mutation<Integration, { url: Url; data: Partial<Integration> }>({
            query: ({ url, data }) => ({
                url,
                body: data,
                method: 'PATCH'
            }),
            invalidatesTags: ['integrations', 'integration']
        }),
        deleteIntegration: build.mutation<Integration, Url>({
            query: (url) => ({
                url,
                method: 'DELETE'
            }),
            invalidatesTags: ['integrations', 'integration']
        })
    })
})

export const {
    useGetIntegrationQuery: useGetIntegration,
    useGetIntegrationsQuery: useGetIntegrations,
    useCreateIntegrationMutation: useCreateIntegration,
    useUpdateIntegrationMutation: useUpdateIntegration,
    useDeleteIntegrationMutation: useDeleteIntegration
} = integrationsApi

export const {
    endpoints: { getIntegrations, getIntegration, createIntegration, updateIntegration, deleteIntegration }
} = integrationsApi
