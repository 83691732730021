import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Flex } from '../../../components/layout/flex'
import { FormHandler } from '../../../components/forms/formHandler'
import { Spacer } from '../../../components/layout/spacer'
import { Icon } from '../../../components/icons/icon'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { MerchantAccountWithContract } from '../../../store/merchantAccounts/types'
import { WatcherID } from '../../../store/watcher/types'
import { MerchantAccountContractDownload } from './Accounts.ID.ContractDownload'
import { useChangesReviewer } from '../../../components/complexComponents/useChangesReviewer'
import { Text } from '../../../components/general/text'
import { useContractHistoryAnalyzer } from './useContractHistoryAnalyzer'
import { uppercaseFirstLetter } from '../../../utils'
import { useContractSectionHelper } from './useContractSectionHelper'
import { MerchantPageUISelectedContract } from './utils'
import { isObject } from 'lodash'
import {
    ContractTemplateFormFieldNaming,
    LocalContractPathsMappedToMAPIContractPaths
} from '../../../store/contractTemplates/helpers'
import { useContractFormHelpers } from './useContractFormHelpers'

const stampDictionary: any = {
    signature_request_resent: {
        color: 'front.background',
        label: 'Resend signature request'
    },
    approved: {
        color: 'front.accent.color',
        label: 'Effectuate'
    },
    signature_requested: {
        color: 'front.background',
        label: 'Request signature'
    },
    cancelled: {
        color: 'front.danger.color',
        label: 'Cancel'
    }
}

export const MerchantAccountContractsPageActions: React.FC<{
    contractsLink: string
    focusInvalidField: () => void
    resetForm: () => void
    submitHandler: any
    selectedContract?: MerchantPageUISelectedContract
    account: MerchantAccountWithContract
    watcherId: WatcherID
    form: any
    errors: any
}> = ({
    selectedContract,
    errors,
    form,
    focusInvalidField,
    watcherId,
    account,
    contractsLink,
    resetForm,
    submitHandler
}) => {
    const dispatch = useDispatch()
    const { effectiveContract } = useContractHistoryAnalyzer(account.contractHistory)
    const { textForChange } = useContractFormHelpers(account?.currency)
    const { getValue: getEffectiveContractValue } = useContractSectionHelper(effectiveContract, undefined)
    // eslint-disable-next-line max-len
    const { getValue: getSelectedContractValue, isEditable } = useContractSectionHelper(
        selectedContract?.isDraft ? undefined : selectedContract?.contract,
        selectedContract?.isDraft ? selectedContract?.contract : undefined
    )

    const currentContractDifferences = useMemo(() => {
        if (!effectiveContract) return {}
        return Object.keys(LocalContractPathsMappedToMAPIContractPaths).reduce((acc, key: any) => {
            const compare = (k) => {
                const before = getEffectiveContractValue(k)
                const now = getSelectedContractValue(k)

                if (before !== now)
                    acc[k] = {
                        from: before,
                        to: now
                    }
            }

            // 2 Level comparison
            if (isObject(LocalContractPathsMappedToMAPIContractPaths[key])) {
                Object.keys(LocalContractPathsMappedToMAPIContractPaths[key]).forEach((subKey) => {
                    compare(`${key}.${subKey}`)
                })
            } else {
                compare(`${key}`)
            }

            return acc
        }, {} as any)
    }, [getSelectedContractValue, getEffectiveContractValue, effectiveContract])

    const regularActions = useMemo(() => {
        if (!selectedContract) return null
        return (
            <>
                <LinkButton
                    background="front.background"
                    cy="preview"
                    hotkeysScope="Merchant.AccountsPage.Contract"
                    hotkeys="alt+p"
                    to={`${contractsLink}/${
                        selectedContract?.isDraft ? 'draft' : selectedContract?.contract.id
                    }/preview`}
                >
                    <ButtonInset equalPadding>
                        <IconHolder>
                            <Icon type="eye" size={14} color="front.accent.color" />
                        </IconHolder>
                    </ButtonInset>
                </LinkButton>
                <Spacer width={5} />
                <MerchantAccountContractDownload account={account} selectedContract={selectedContract} />
            </>
        )
    }, [account, selectedContract, contractsLink])

    const draftActions = useMemo(() => {
        if (!selectedContract?.isDraft) return <></>
        if (selectedContract?.contract?.state === 'needs_approval') {
            return (
                <Flex align="center">
                    <WatcherButton
                        background="front.accent.color"
                        predefinedWatcher={watcherId}
                        hotkeysScope="Merchant.AccountsPage.Contract"
                        hotkeys="alt+x"
                        onClick={(e, wId) => {
                            dispatch(
                                MerchantAccountsActions.STAMP_DRAFT(
                                    wId,
                                    account.id,
                                    selectedContract?.contract?.id,
                                    'refuse'
                                )
                            )
                        }}
                    >
                        <ButtonInset>Edit draft</ButtonInset>
                    </WatcherButton>
                    <Spacer width={5} />
                    <WatcherButton
                        background="front.success.color"
                        color="front.success.text"
                        hotkeysScope="Merchant.AccountsPage.Contract"
                        hotkeys="alt+a"
                        predefinedWatcher={watcherId}
                        onClick={(e, wId) => {
                            dispatch(
                                MerchantAccountsActions.STAMP_DRAFT(
                                    wId,
                                    account.id,
                                    selectedContract?.contract?.id,
                                    'approve'
                                )
                            )
                        }}
                    >
                        <ButtonInset>Approve</ButtonInset>
                    </WatcherButton>
                    <CustomSeparator />
                    {regularActions}
                </Flex>
            )
        }
        return (
            <Flex align="center">
                <WatcherButton
                    background="front.background"
                    hotkeysScope="Merchant.AccountsPage.Contract"
                    predefinedWatcher={watcherId}
                    hotkeys="alt+r"
                    onClick={submitHandler(() => {
                        if (selectedContract?.contract)
                            dispatch(
                                MerchantAccountsActions.STAMP_DRAFT(
                                    watcherId,
                                    account.id,
                                    selectedContract?.contract?.id,
                                    'ready'
                                )
                            )
                    })}
                >
                    <ButtonInset>Hold for approval</ButtonInset>
                </WatcherButton>
                <Spacer width={5} />
                <WatcherButton
                    background="front.accent.color"
                    predefinedWatcher={account.id + '_NEW_CONTRACT'}
                    hotkeysScope="Merchant.AccountsPage.Contract"
                    hotkeys="alt+a"
                    onClick={submitHandler(() => {
                        dispatch(
                            MerchantAccountsActions.STAMP_DRAFT(
                                account.id + '_NEW_CONTRACT',
                                account.id,
                                selectedContract?.contract?.id,
                                'ready-then-approve'
                            )
                        )
                    })}
                >
                    <ButtonInset>Create contract</ButtonInset>
                </WatcherButton>
                <CustomSeparator />
                {regularActions}
            </Flex>
        )
    }, [selectedContract, submitHandler, regularActions, dispatch, account.id, watcherId])

    const onSaveDraft = useCallback(
        (f: any) => {
            if (selectedContract?.isDraft) {
                dispatch(
                    MerchantAccountsActions.UPDATE_DRAFT(watcherId, account?.id, selectedContract?.contract, f.changes)
                )
            }
        },
        [account, selectedContract, dispatch, watcherId]
    )

    const { startReview: effectuateReview, UI: effectuateReviewUI } = useChangesReviewer(
        useMemo(
            () => ({
                changesArr: currentContractDifferences,
                onChangesApproved: (form) => {
                    if (selectedContract?.isDraft) return
                    if (selectedContract?.contract?.stampsLink?.length === 0) throw 'No stamp links found'
                    const stamp = selectedContract?.contract?.stampsLink?.filter((stamp) => {
                        if (stamp.name === 'approved') return true
                        return false
                    })[0]

                    if (stamp)
                        dispatch(
                            MerchantAccountsActions.STAMP_CONTRACT(
                                watcherId,
                                account.id,
                                selectedContract?.contract?.id,
                                stamp.href
                            )
                        )
                },
                valueFormatter: (key, from, to) => {
                    if (key === 'charges.interchange') {
                        return {
                            col: key,
                            from: from === true ? 'Interchange plus' : 'Pricing plus',
                            to: to === true ? 'Interchange plus' : 'Pricing plus'
                        }
                    }
                    if (key === 'paymentPeriod') {
                        return {
                            col: key,
                            from: uppercaseFirstLetter(from),
                            to: uppercaseFirstLetter(to)
                        }
                    }
                    return { col: key, from, to }
                },
                watcherId: watcherId,
                fieldFormatter: ContractTemplateFormFieldNaming,
                buttonText: stampDictionary['approved']?.label,
                title: 'Review the new contract changes',
                description: (
                    <MaxWidth>
                        <Text>
                            The following differences can be seen between the{' '}
                            <Text bold>contract prepared to be effectuated</Text> and the currently effective contract:
                        </Text>
                    </MaxWidth>
                )
            }),
            [watcherId, dispatch, currentContractDifferences, selectedContract, account]
        )
    )

    const { startReview: reviewSignatureRequest, UI: reviewSignatureUI } = useChangesReviewer(
        useMemo(
            () => ({
                changesArr: currentContractDifferences,
                onChangesApproved: (form) => {
                    if (!selectedContract) return
                    if (selectedContract?.isDraft) return
                    if (selectedContract?.contract?.stampsLink?.length === 0) throw 'No stamp links found'
                    const stamp = selectedContract?.contract?.stampsLink?.filter((stamp) => {
                        if (stamp.name === 'signature_requested') return true
                        return false
                    })[0]
                    dispatch(
                        MerchantAccountsActions.STAMP_CONTRACT(
                            watcherId,
                            account.id,
                            selectedContract.contract.id,
                            stamp.href
                        )
                    )
                },
                valueFormatter: (key, from, to) => {
                    if (key === 'charges.interchange') {
                        return {
                            col: key,
                            from: from === true ? 'Interchange plus' : 'Pricing plus',
                            to: to === true ? 'Interchange plus' : 'Pricing plus'
                        }
                    }
                    if (key === 'paymentPeriod') {
                        return {
                            col: key,
                            from: uppercaseFirstLetter(from),
                            to: uppercaseFirstLetter(to)
                        }
                    }
                    return { col: key, from, to }
                },
                watcherId: watcherId,
                fieldFormatter: ContractTemplateFormFieldNaming,
                buttonText: stampDictionary['signature_requested']?.label,
                title: 'Review the new contract changes',
                description: (
                    <MaxWidth>
                        <Text>
                            The following differences can be seen between the{' '}
                            <Text bold>contract prepared to be sent</Text> and the currently effective contract:
                        </Text>
                    </MaxWidth>
                )
            }),
            [watcherId, currentContractDifferences, dispatch, selectedContract, account]
        )
    )
    const generateStampCallback = useCallback(
        (stamp: { name: string; href: string }) => {
            if (currentContractDifferences && Object.keys(currentContractDifferences).length) {
                if (stamp.name === 'signature_requested') return submitHandler(reviewSignatureRequest)
                if (stamp.name === 'approved') return submitHandler(effectuateReview)
            }

            return submitHandler(() => {
                if (selectedContract?.contract)
                    dispatch(
                        MerchantAccountsActions.STAMP_CONTRACT(
                            watcherId,
                            account.id,
                            selectedContract?.contract.id,
                            stamp.href
                        )
                    )
            })
        },
        [
            dispatch,
            account,
            selectedContract,
            watcherId,
            submitHandler,
            currentContractDifferences,
            effectuateReview,
            reviewSignatureRequest
        ]
    )

    if (selectedContract?.isDraft) {
        const changes = Object.keys(form.changes)

        if (changes.length === 0) {
            return draftActions
        }

        return (
            <Flex align="center">
                <FormHandler
                    formSubmitLabel="Save draft contract changes"
                    changes={form.changes}
                    textForFieldName={ContractTemplateFormFieldNaming}
                    errors={errors}
                    focusInvalidField={focusInvalidField}
                    textForChange={textForChange}
                    submitForm={submitHandler(onSaveDraft)}
                    submitWatcherId={watcherId}
                    resetForm={resetForm}
                />
            </Flex>
        )
    }

    if (!selectedContract) return null
    return (
        <Flex align="center">
            {selectedContract?.contract?.stampsLink?.length !== undefined &&
            selectedContract?.contract?.stampsLink?.length > 0 ? (
                <>
                    {selectedContract?.contract?.metadata?.state == 'new' &&
                    currentContractDifferences &&
                    !Object.keys(currentContractDifferences).length ? (
                        <>
                            <Text>The current effective contract is the same as this one.</Text>
                            <Spacer width={10} />
                        </>
                    ) : null}
                    {selectedContract?.contract?.stampsLink.map((stamp) => {
                        return (
                            <>
                                <Spacer width={5} />
                                <WatcherButton
                                    background={stampDictionary[stamp.name]?.color || 'front.accent.color'}
                                    predefinedWatcher={watcherId}
                                    onClick={generateStampCallback(stamp)}
                                >
                                    <ButtonInset>{stampDictionary[stamp.name]?.label || stamp.name}</ButtonInset>
                                </WatcherButton>
                            </>
                        )
                    })}
                    <CustomSeparator />
                </>
            ) : null}
            {regularActions}
            {reviewSignatureUI}
            {effectuateReviewUI}
        </Flex>
    )
}

const CustomSeparator = styled.div`
    margin: 10px 0;
    background-color: ${(p) => p.theme['back.border.strongerI']};
    width: 1px;
    height: calc(100% - 10px);
    margin: 0 10px;
    flex-shrink: 0;
`

const MaxWidth = styled.div`
    max-width: 510px;
`

const IconHolder = styled.div`
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
`
