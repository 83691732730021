import { Icon } from '../icons/icon'
import { Flex } from '../layout/flex'
import { Offset } from '../layout/offset'
import { ButtonInset } from './buttonInset'
import { SimpleButton } from './simpleButton'

export const CollapseButton: React.FC<{ isCollapsed: boolean; onClick: () => void; section?: boolean }> = ({
    onClick,
    isCollapsed,
    section
}) => {
    return (
        <SimpleButton onClick={onClick}>
            <ButtonInset>
                <Flex align="center">
                    <Offset top={2} left={-5}>
                        {isCollapsed ? <Icon size={13} type="expand" /> : <Icon size={13} type="collapse" />}
                    </Offset>
                    {section
                        ? isCollapsed
                            ? 'Expand application changes'
                            : 'Collapse application changes'
                        : isCollapsed
                        ? 'Expand'
                        : 'Collapse'}
                </Flex>
            </ButtonInset>
        </SimpleButton>
    )
}
