import { LoadingStatus } from '../../utils'

export type MerchantApplicationTimelineSectionType =
    | 'application'
    | 'person'
    | 'businessModel'
    | 'bankAccount'
    | 'contact'
    | 'company'

export interface MercantApplicationTimelineFileHistoryItem {
    type: 'file-update'
    section: MerchantApplicationTimelineSectionType
    sectionIndex?: number
    field: string
    from: {
        id: string
        filename: string
        // size: string
    }
    to: {
        id: string
        filename: string
        // size: string
    }
    at: string
    by: string
}

export interface MerchantApplicationTimelineHistoryItem {
    type: 'state-change' | 'field-change'
    section: MerchantApplicationTimelineSectionType
    field: string
    from: string
    to: string
    at: string
    by: string
}

export type MerchantTimelineHistoryItem =
    | MerchantApplicationTimelineHistoryItem
    | MercantApplicationTimelineFileHistoryItem

export type MerchantTimelinePointLabel =
    | 'application'
    | 'state-changes'
    | 'edits'

export type MerchantTimelinePoint = {
    id: string
    type: 'creation' | 'field-edits' | 'state-change'
    labels: Array<MerchantTimelinePointLabel>
    at: string
    by: string
    edits: MerchantTimelineHistoryItem[]
}

export interface MerchantTimeline {
    at: {
        [key: string]: MerchantTimelinePoint
    }
    all: string[]
    byKind: {
        [key: string]: string[]
    }
    byDay: {
        [key: string]: string[]
    }
    loadingStatus: LoadingStatus
}

export type MerchantTimelineState = {
    [merchantId: string]: MerchantTimeline
}

export const InitialMerchantTimelineState: MerchantTimelineState = {}
