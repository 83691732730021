import { AgentBubble as AgentBubbleComp } from '@/components/general/agentBubble'
import React from 'react'
import { Text } from '@/components/general/text'

interface AgentBubbleProps {
    agentSub?: string
}

export const AgentBubble: React.FC<AgentBubbleProps> = (props) => {
    const { agentSub } = props

    if (!agentSub) {
        return (
            <Text oneLine color="back.text.subtlerI">
                -
            </Text>
        )
    }

    return <AgentBubbleComp filled={false} id={agentSub} slim />
}
