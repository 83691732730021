import numeral from 'numeral'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { DataPorviderStateAssignedAndReady } from '../../../store/applicationDataProviders/types'
import { ApplicationInternalsDetailsActions } from '../../../store/applicationInternals/details/actions'
import { MerchantApplicationInternalDetailValue } from '../../../store/applicationInternals/details/types'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../../../store/toasts/actions'
import { useApplicationInternalValue } from './Application.useInternalValue'

export const MerchantApplicationInternalValueDataProvider: React.FC<{
    type: MerchantApplicationInternalDetailValue
    applicationId: string
    indexPath: MerchantApplicationResourceIndexPath
}> = ({ type, applicationId, indexPath }) => {
    const personId = useSelector((state: RootState) => {
        if (indexPath.subsectionIndex !== undefined)
            return state.applicationResources.data.forApplication?.[applicationId]?.people?.fields?.[
                indexPath.subsectionIndex
            ]?.id
        return undefined
    })
    const data = useSelector((state: RootState) => {
        return state.dataProviders.forApplication?.[applicationId]
    })
    const { id, localValue, structure } = useApplicationInternalValue(applicationId, indexPath, type)
    const dispatch = useDispatch()

    const ownershipMatches = useMemo(() => {
        if (data.state !== 'assigned-and-ready') {
            return undefined
        }
        return (data as DataPorviderStateAssignedAndReady).computed.conflicts.ownership.matches
    }, [data])
    const ownershipList = useMemo(() => {
        if (data.state !== 'assigned-and-ready') {
            return undefined
        }
        return (data as DataPorviderStateAssignedAndReady).computed.ownershipShares.list
    }, [data])

    const ownership = useMemo(() => {
        const matchedOwner = ownershipMatches?.find((o) => o.app.id == personId)
        if (matchedOwner?.sot?.name) return ownershipList?.[matchedOwner?.sot?.name]
        return undefined
    }, [ownershipList, personId, ownershipMatches])

    if (ownership) {
        if (numeral(localValue).value() == numeral(ownership.fraction * 100).value())
            return (
                <Flex column>
                    <Spacer height={5} />
                    <Text color="back.text">
                        <Icon type="checkmark" /> Ownership matches
                    </Text>
                </Flex>
            )
    }

    return ownership ? (
        <WatcherButton
            onClick={(e, generatedId) => {
                const ownershipFormatted = ownership.fraction * 100
                if (ownershipFormatted > 100 || ownership.fraction < 0) {
                    dispatch(
                        ToastsDispatchPush(
                            'Detected incorrectly formatted ownership percentage. Inform the console team.'
                        )
                    )
                    throw new Error('Ownership percentage conflict')
                } else {
                    return dispatch(
                        ApplicationInternalsDetailsActions.UPDATE_VALUE(
                            type,
                            structure.path.replace('<ID>', id || ''),
                            applicationId,
                            `${ownershipFormatted}`,
                            generatedId
                        )
                    )
                }
            }}
        >
            Data provider suggestion: {ownership.fraction * 100}%
        </WatcherButton>
    ) : (
        <Text color="back.text">No ownership data found</Text>
    )
}
