import { Container, Flex, Grid, Loader, Page } from '@/design-system'
import { IntegrationSchema, useIntegrationForm } from '@/features/integrations'
import { RTKQuery } from '@/services/common'
import { MAPI_INTEGRATIONS_ROOT, getIntegration, updateIntegration, useGetIntegration } from '@/services/merchant-api'
import { PageHeader, SaveCard, FormInput, FormTextarea } from '@/ui'
import { FormProvider } from 'react-hook-form'
import { Form, redirect, useNavigate, useNavigation, useParams, useSubmit } from 'react-router-dom'

export const loader = async ({ request, params }) => {
    const { integrationId } = params

    const url = `${MAPI_INTEGRATIONS_ROOT}/${integrationId}`
    return await RTKQuery.get(getIntegration, request, url)
}

export const action = async ({ request, params }) => {
    const { partnerId, integrationId } = params
    const formData = await request.formData()

    const url = `${MAPI_INTEGRATIONS_ROOT}/${integrationId}`
    const data = Object.fromEntries(formData)
    const body = { url, data }
    await RTKQuery.patch(updateIntegration, body)

    return redirect(`/more/partners/${partnerId}`)
}

const IntegrationEdit = () => {
    const navigate = useNavigate()
    const navigation = useNavigation()
    const params = useParams()

    const url = `${MAPI_INTEGRATIONS_ROOT}/${params.integrationId}`
    const { data } = useGetIntegration(url)

    // This is a temporary work around until we have a proper way to get the support partner id
    const supportParnerId = data?.embedded?.['ch:partners']
        ?.find((partner) => partner.support)
        ?.relations?.self?.split('/')
        .pop()

    const form = useIntegrationForm({
        name: data?.name ? data?.name : '',
        support_partner_id: supportParnerId ? supportParnerId : '',
        description: data?.description ? data?.description : '',
        transaction_rules: data?.transaction_rules ? data?.transaction_rules : ''
    })
    const submit = useSubmit()

    const onBack = () => {
        navigate(-1)
    }

    const onSubmit = form.handleSubmit((values: IntegrationSchema) => {
        submit(values, { method: 'post' })
    })

    const isLoading = navigation.state === 'loading'
    const errorCount = Object.keys(form.formState.errors).length

    return (
        <Loader isLoading={isLoading}>
            <Page pb="normal">
                <PageHeader title="Edit integration" onBack={onBack} />
                <Flex justify="center" align="center" grow="1">
                    <Container size="2xl">
                        <FormProvider {...form}>
                            <Form onSubmit={onSubmit}>
                                <SaveCard>
                                    <SaveCard.Body>
                                        <SaveCard.Title>Integration details</SaveCard.Title>
                                        <SaveCard.Fields>
                                            <Grid columns="3" gap="3">
                                                <FormInput>
                                                    <FormInput.Label name="name">Name</FormInput.Label>
                                                    <FormInput.Input testid="name-input" name="name" />
                                                </FormInput>
                                                <FormInput>
                                                    <FormInput.Label name="support_partner_id">
                                                        Support partner id
                                                    </FormInput.Label>
                                                    <FormInput.Input
                                                        testid="support-partner-id-input"
                                                        name="support_partner_id"
                                                    />
                                                </FormInput>
                                                <FormTextarea>
                                                    <FormTextarea.Label name="description" margin="default">
                                                        Description
                                                    </FormTextarea.Label>
                                                    <FormTextarea.Textarea
                                                        testid="description-textarea"
                                                        name="description"
                                                        margin="default"
                                                    />
                                                </FormTextarea>
                                                <FormTextarea>
                                                    <FormTextarea.Label name="transaction_rules" margin="default">
                                                        Transaction rules
                                                    </FormTextarea.Label>
                                                    <FormTextarea.Textarea
                                                        testid="transaction-rules-textarea"
                                                        name="transaction_rules"
                                                        margin="default"
                                                    />
                                                </FormTextarea>
                                            </Grid>
                                        </SaveCard.Fields>
                                    </SaveCard.Body>
                                    <SaveCard.Footer testid="card-footer" errorCount={errorCount} />
                                </SaveCard>
                            </Form>
                        </FormProvider>
                    </Container>
                </Flex>
            </Page>
        </Loader>
    )
}

IntegrationEdit.loader = loader
IntegrationEdit.action = action

export { IntegrationEdit }
