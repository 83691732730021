import React from 'react'
import styled, { css } from 'styled-components'
import { CardStyles } from '../../components/cards/card'
import { SmartNavLink } from '../../components/tables/smartNavLink'

const SearchApplicationCardWithRef: React.ForwardRefRenderFunction<
    any,
    React.ComponentProps<typeof SmartNavLink> & { fullBorders?: boolean; children: React.ReactNode }
> = ({ ...props }, ref) => {
    const { ref: _, ...otherProps } = props

    return (
        <Holder {...otherProps}>
            <ApplicationCard fullBorders={props.fullBorders} ref={ref}>
                {props.children}
            </ApplicationCard>
        </Holder>
    )
}

export const SearchApplicationCard = React.forwardRef(SearchApplicationCardWithRef)

const Holder = styled(SmartNavLink)`
    display: contents;
    text-decoration: none;
`

const ApplicationCard = styled.div<{ fullBorders?: boolean }>`
    position: relative;
    ${CardStyles.higher};
    margin-bottom: 10px;
    color: ${(p) => p.theme['front.text']};

    user-select: text;
    overflow-x: auto;
    background-color: ${(p) => p.theme['front.background']};
    text-decoration: none;
    border-radius: 14px 14px 0 0;

    ${(p: any) =>
        p.fullBorders &&
        css`
            border-radius: 14px;
        `}
`
