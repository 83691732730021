import { returnWithout } from '../../utils'
import { TQLConverter } from '../../utils/tqlConverter'
import {
    ReducedTransactionsActions,
    TRANSACTION_LOAD,
    TRANSACTION_LOAD_EXTRA_DETAILS,
    TRANSACTION_STORE,
    TRANSACTION_STORE_EXTRA_DETAILS,
    TRANSACTION_UNSTORE,
    TRANSACTIONS_APPEND_SUMMARIES,
    TRANSACTIONS_CLEAR_SUMMARIES,
    TRANSACTIONS_LOAD_NEXT_SUMMARIES,
    TRANSACTIONS_LOAD_SUMMARIES,
    TRANSACTIONS_STORE_SUMMARIES,
    TRANSACTION_LOAD_APPENDIX,
    TRANSACTION_STORE_APPENDIX
} from './actions'
import { InitialTransactionsState, TransactionsFilters, TransactionsState } from './types'

export function TransactionsReducer(
    state = InitialTransactionsState,
    action: ReducedTransactionsActions
): TransactionsState {
    switch (action.type) {
        case TRANSACTIONS_LOAD_SUMMARIES: {
            return {
                ...state,
                summaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'started',
                    loadingNextStatus: 'not-started',
                    forQuery: `${import.meta.env.VITE_API_ROOT}/transactions${TQLConverter.transactions(
                        action.filters ? action.filters : ({} as TransactionsFilters)
                    )}`
                }
            }
        }
        case TRANSACTIONS_STORE_SUMMARIES: {
            if (state.summaries.loadingStatus === 'started')
                return {
                    ...state,
                    summaries: {
                        ...state.summaries,
                        at: action.summaries
                            ? {
                                  ...action.summaries.reduce((a, t) => {
                                      return {
                                          ...a,
                                          [t.id]: {
                                              ...state.summaries.at[t.id],
                                              ...reformatTransactionSummary(t)
                                          }
                                      }
                                  }, {})
                              }
                            : {},
                        error: action.error,
                        all: action.summaries ? [...action.summaries.map((t) => t.id)] : [],
                        nextLink: action.nextLink,
                        loadingStatus: 'done',
                        loadingNextStatus: 'not-started'
                    }
                }
            return state
        }
        case TRANSACTIONS_LOAD_NEXT_SUMMARIES: {
            return {
                ...state,
                summaries: {
                    ...state.summaries,
                    loadingNextStatus: 'started'
                }
            }
        }
        case TRANSACTIONS_APPEND_SUMMARIES: {
            return {
                ...state,
                summaries: {
                    ...state.summaries,
                    at: {
                        ...state.summaries.at,
                        ...action.summaries.reduce((a, t) => {
                            return {
                                ...a,
                                [t.id]: {
                                    ...state.summaries.at[t.id],
                                    ...reformatTransactionSummary(t)
                                }
                            }
                        }, {})
                    },
                    all: [...state.summaries.all, ...action.summaries.map((t) => t.id)],
                    nextLink: action.nextLink,
                    loadingNextStatus: 'done'
                }
            }
        }
        case TRANSACTION_LOAD: {
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    at: {
                        ...state.transactions.at,
                        [action.id]: {
                            ...state.transactions.at[action.id],
                            loadingStatus: 'started',
                            extraDetails: {
                                account: undefined,
                                application: undefined,
                                loadingStatus: 'not-started'
                            },
                            appendix: {
                                data: undefined,
                                loadingStatus: 'not-started'
                            }
                        }
                    }
                }
            }
        }
        case TRANSACTION_STORE: {
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    at: {
                        ...state.transactions.at,
                        [action.transaction.id]: {
                            ...state.transactions.at[action.transaction.id],
                            ...reformatTransaction(action.transaction),
                            loadingStatus: 'done'
                        }
                    }
                }
            }
        }
        case TRANSACTION_UNSTORE: {
            const newState = { ...state }
            delete newState.transactions.at[action.transactionId]
            newState.transactions.all = returnWithout(newState.transactions.all, action.transactionId)
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    at: {
                        ...state.transactions.at
                    },
                    all: [...state.transactions.all]
                }
            }
        }
        case TRANSACTION_LOAD_EXTRA_DETAILS: {
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    at: {
                        ...state.transactions.at,
                        [action.transaction.id]: {
                            ...state.transactions.at[action.transaction.id],
                            extraDetails: {
                                account: undefined,
                                application: undefined,
                                loadingStatus: 'started'
                            }
                        }
                    }
                }
            }
        }
        case TRANSACTIONS_CLEAR_SUMMARIES: {
            return {
                ...state,
                transactions: {
                    at: {},
                    all: []
                },
                summaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'not-started',
                    loadingNextStatus: 'not-started'
                }
            }
        }
        case TRANSACTION_STORE_EXTRA_DETAILS: {
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    at: {
                        ...state.transactions.at,
                        [action.transaction.id]: {
                            ...state.transactions.at[action.transaction.id],
                            extraDetails: {
                                account: action.transaction.extraDetails.account,
                                application: action.transaction.extraDetails.application,
                                loadingStatus: 'done'
                            }
                        }
                    }
                }
            }
        }
        case TRANSACTION_LOAD_APPENDIX: {
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    at: {
                        ...state.transactions.at,
                        [action.transaction.id]: {
                            ...state.transactions.at[action.transaction.id],
                            appendix: {
                                data: undefined,
                                loadingStatus: 'started'
                            }
                        }
                    }
                }
            }
        }
        case TRANSACTION_STORE_APPENDIX: {
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    at: {
                        ...state.transactions.at,
                        [action.transaction.id]: {
                            ...state.transactions.at[action.transaction.id],
                            appendix: {
                                data: action.transaction.appendix.data,
                                loadingStatus: 'done'
                            }
                        }
                    }
                }
            }
        }
        default:
            return state
    }
}

const reformatTransactionSummary = (t: any) => {
    if (t.accountLink) t.accountId = t.accountLink.substr(t.accountLink.lastIndexOf('/') + 1)
    t.reference = t.reference ? t.reference.substr(0, 23) : ''
    delete t.accountLink
    return t
}

const reformatTransaction = (t: any) => {
    if (t.accountLink) t.accountId = t.accountLink.substr(t.accountLink.lastIndexOf('/') + 1)

    return t
}
