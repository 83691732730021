import { SectionDocumentation } from '@/services/ongoing-due-diligence'
import React from 'react'
import { Section } from '../../../common/section'
import { useReportSection } from '../../../provider/report-provider/report-provider'
import { WebsiteMetadata } from './website-metadata'
import { WebsitesDataItems } from './websites-data-items'

export const Websites: React.FC = () => {
    const section = useReportSection<SectionDocumentation>('documentation')

    return (
        <Section>
            <Section.Content>
                <Section.Field name="websites" data={section.websites} />
                <Section.Spacer size="s" />
                <WebsitesDataItems />
            </Section.Content>
            <WebsiteMetadata />
        </Section>
    )
}
