import React, { useCallback, useMemo } from 'react'

import { SettlementsFilters } from '../../store/settlements/types'
import { TextInputSelect } from '../forms/textInputSelect'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

export const ListPageFilterSettlementsStatus = ({
    filters,
    isDark
}: {
    filters: FiltersController<SettlementsFilters>
    isDark: boolean
}): any => {
    const dictionary = useMemo(() => {
        return {
            'settled': 'Settled',
            'not-settled': 'Not settled'
        } as any
    }, [])

    const textForItem = useCallback(
        (key) => {
            return dictionary[key]
        },
        [dictionary]
    )

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                settlements_settled: items
            }))
        },
        [filters]
    )

    const items = useMemo(() => {
        return Object.keys(dictionary)
    }, [dictionary])

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelect
                selected={filters.get.settlements_settled}
                isHigher={isDark}
                isSeamless
                textForItem={textForItem}
                dropdownId="ListPage.Filters.SettlementStatus"
                onSelect={onSelect}
                placeholder="-"
                items={items}
                overBackground={isDark ? 'floating.background' : 'side.background'}
            />
            <SelectedListForListPageFilters<SettlementsFilters>
                filterKey="settlements_settled"
                filters={filters}
                noHolderEmphasize={isDark}
                isDark={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
