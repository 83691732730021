import { Flex, Text } from '@/design-system'
import React from 'react'

interface IntegrationsSectionProps {
    children: React.ReactNode
}

export const IntegrationsSection: React.FC<IntegrationsSectionProps> = (props) => {
    const { children } = props

    return (
        <Flex direction="column" gap="7">
            <Text weight="medium">Partner integrations</Text>
            {children}
        </Flex>
    )
}
