import { useBankAccountMismatch } from '@/hooks/application/useBankAccountMismatch/useBankAccountMismatch'
import { useApplicationValidation } from '@/hooks/application/useApplicationValidation'

const useApplicationHasWarnings = (applicationId: string) => {
    const hasMismatch = useBankAccountMismatch(applicationId)
    const [validationResults] = useApplicationValidation(applicationId)

    const hasValidationErrors = Array.isArray(validationResults)

    return {
        hasWarnings: hasMismatch || hasValidationErrors,
        warnings: { mismatch: hasMismatch, validation: hasValidationErrors }
    }
}

export { useApplicationHasWarnings }
