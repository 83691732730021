import React, { ReactNode, createContext, useContext } from 'react'
import { CardStyles, styles } from './styles'
import { cn } from '../utils'

interface Context {
    size: 'sm' | 'md' | 'lg'
}
const defaultValues: Context = { size: 'md' }
const Context = createContext<Context>(defaultValues)

interface CardProps extends CardStyles {
    testid?: string
    children: ReactNode
    size?: 'sm' | 'md' | 'lg'
}

const Card = (props: CardProps) => {
    const { testid, children, size = 'md', variant, color } = props

    const value = { size }

    const classnames = cn(styles.card({ variant, color }))

    return (
        <Context.Provider value={value}>
            <div data-cy={testid} className={classnames}>
                {children}
            </div>
        </Context.Provider>
    )
}

interface BodyProps {
    testid?: string
    children: ReactNode
}

const Body: React.FC<BodyProps> = (props) => {
    const { testid, children } = props
    const { size } = useContext(Context)

    const classnames = cn(styles.body({ size }))

    return (
        <div data-cy={testid} className={classnames}>
            {children}
        </div>
    )
}

interface FooterProps {
    testid?: string
    children: ReactNode
}

const Footer: React.FC<FooterProps> = (props) => {
    const { testid, children } = props

    const { size } = useContext(Context)
    const classnames = cn(styles.footer({ size }))

    return (
        <div data-cy={testid} className={classnames}>
            {children}
        </div>
    )
}

Card.Body = Body
Card.Footer = Footer

export { Card }
