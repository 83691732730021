import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { CollapseButton } from '../../../../../components/buttons/collapseButton'
import { CardInset } from '../../../../../components/cards/cardInset'
import { Flex } from '../../../../../components/layout/flex'
import { Spacer } from '../../../../../components/layout/spacer'
import { Text } from '../../../../../components/general/text'
import { Icon } from '../../../../../components/icons/icon'
import { Separator } from '../../../../../components/layout/separator'
import { Card } from '../../../../../components/cards/card'
import { IconArrowRight } from '../../../../../components/icons/iconArrowRight'
import { Offset } from '../../../../../components/layout/offset'
import { BubbledCheck } from '../../../../../components/icons/bubbledCheck'

type Type = 'main' | 'other'

export type Status = 'complete' | 'in-progress' | 'muted' | 'unknown'

interface ContextValue {
    isCollapsed: boolean
    setIsCollapsed: (collapsed: boolean) => void
    status: Status
    type?: Type
}

const Context = createContext<ContextValue>({
    isCollapsed: true,
    setIsCollapsed: () => undefined,
    status: 'in-progress',
    type: 'main'
})

interface MonitoringTemplateProps {
    children: React.ReactNode
    status: Status
    type: Type
    cy?: string
}

const MonitoringTemplate = (props: MonitoringTemplateProps) => {
    const { children, status, cy } = props

    const shouldCollapse = status === 'complete' || status === 'muted'

    const [isCollapsed, setIsCollapsed] = useState(shouldCollapse)

    const value = useMemo(() => ({ status, isCollapsed, setIsCollapsed }), [status, isCollapsed])

    useEffect(() => {
        if (shouldCollapse) {
            setIsCollapsed(true)
        } else {
            setIsCollapsed(false)
        }
    }, [shouldCollapse])

    return (
        <Flex column align="stretch" cy={cy}>
            <Separator type="solid" background="side.background" />
            <CardInset>
                <Context.Provider value={value}>{children}</Context.Provider>
            </CardInset>
        </Flex>
    )
}

interface ContainerProps {
    children: ReactNode
}

const Container: React.FC<ContainerProps> = (props) => {
    const { children } = props

    return <Flex justify="space-between">{children}</Flex>
}

interface MainProps {
    children: ReactNode
}

const Main: React.FC<MainProps> = (props) => {
    const { children } = props

    return (
        <Flex grow column style={{ marginLeft: 32 }}>
            {children}
        </Flex>
    )
}

interface ContentProps {
    children: ReactNode
}

const Content: React.FC<ContentProps> = (props) => {
    const { children } = props

    return <Flex justify="space-between">{children}</Flex>
}

const Status: React.FC = () => {
    const { status } = useContext(Context)

    if (status === 'complete') {
        return (
            <Flex column>
                <Text color="front.text.subtlerI">Status</Text>
                <Flex wrap={false}>
                    <Offset top={1} left={-1}>
                        <BubbledCheck />
                    </Offset>
                    <Text color="front.success.color" bold oneLine>
                        Completed
                    </Text>
                </Flex>
            </Flex>
        )
    }

    if (status === 'in-progress') {
        return (
            <Flex column>
                <Text color="front.text.subtlerI">Status</Text>
                <Flex align="center" wrap={false}>
                    <Icon type="ellipsis-filled" size={13} weight={2.5} />
                    <Spacer width={6} height={1} />
                    <Text bold>Not updated</Text>
                </Flex>
            </Flex>
        )
    }

    if (status === 'muted') {
        return (
            <Flex column>
                <Text color="front.text.subtlerI">Status</Text>
                <Flex align="center">
                    <Icon type="minus" size={16} weight={2} color="back.text" />
                    <Spacer width={6} height={1} />
                    <Text bold color="back.text">
                        Muted
                    </Text>
                </Flex>
            </Flex>
        )
    }

    return (
        <>
            <Flex column>
                <Text color="front.text.subtlerI">Status</Text>
                <Flex align="center">
                    <Icon type="alert" size={13} color="front.danger.color" />
                    <Spacer width={3} height={1} />
                    <Text bold color="front.danger.color">
                        Unknown
                    </Text>
                </Flex>
            </Flex>
        </>
    )
}

interface DescriptionProps {
    children: string | ReactNode
    type?: 'normal' | 'other-changes'
}

const Description: React.FC<DescriptionProps> = (props) => {
    const { children, type } = props

    return (
        <Flex column>
            <Text color="front.text.subtlerI">Description</Text>
            <Text oneLine color={type == 'other-changes' ? 'front.text.subtlerI' : undefined}>
                <span style={{ whiteSpace: 'pre-line' }}>{children}</span>
            </Text>
        </Flex>
    )
}

interface ActionsProps {
    children?: React.ReactNode
}

const Actions: React.FC<ActionsProps> = (props) => {
    const { children } = props

    const { isCollapsed, setIsCollapsed, status } = useContext(Context)

    const handleCollapseClick = () => {
        setIsCollapsed(!isCollapsed)
    }

    return (
        <Flex align="baseline">
            {status !== 'complete' && children}
            <CollapseButton isCollapsed={isCollapsed} onClick={handleCollapseClick} />
        </Flex>
    )
}

interface ActionProps {
    children: ReactNode
}

const Action: React.FC<ActionProps> = (props) => {
    const { children } = props

    return (
        <>
            {children}
            <Spacer width={16} />
        </>
    )
}

interface ChangeProps {
    children: ReactNode
}

const Change: React.FC<ChangeProps> = (props) => {
    const { children } = props
    const { isCollapsed } = useContext(Context)

    if (isCollapsed) {
        return null
    }

    return (
        <>
            <Spacer height={24} />
            <Card shadowLess background="front.highlights">
                <CardInset type="small">{children}</CardInset>
            </Card>
        </>
    )
}

interface InfoProps {
    label: string
    children: ReactNode | string
}

const Info: React.FC<InfoProps> = (props) => {
    const { label, children } = props

    return (
        <Flex column>
            <Text color="front.text.subtlerI">{label}</Text>
            <Text oneLine>
                <span style={{ whiteSpace: 'pre' }}>{children}</span>
            </Text>
        </Flex>
    )
}

const Arrow: React.FC = () => {
    return (
        <>
            <Spacer width={32} />
            <IconArrowRight />
            <Spacer width={32} />
        </>
    )
}

MonitoringTemplate.Container = Container
MonitoringTemplate.Main = Main
MonitoringTemplate.Content = Content
MonitoringTemplate.Status = Status
MonitoringTemplate.Actions = Actions
MonitoringTemplate.Action = Action
MonitoringTemplate.Description = Description
MonitoringTemplate.Change = Change
MonitoringTemplate.Info = Info
MonitoringTemplate.Arrow = Arrow

export { MonitoringTemplate }
