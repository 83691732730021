import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { RootState } from '@/store'
import moment from 'moment'
import { DateFormats } from '../../../utils/dateUtils'
import { Icon } from '../../../components/icons/icon'
import { Floater } from '../../../components/layout/floater'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { FloaterInset } from '../../../components/layout/floaterInset'
import { Color } from '../../../styles/colors'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Spacer } from '../../../components/layout/spacer'
import { useLocation, useNavigate } from 'react-router-dom'

export const MerchantAccountContractCard: React.FC<{
    accountId: string
    merchantId: string
    submitHandler: any
}> = ({ accountId, merchantId, submitHandler }) => {
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])
    const navigate = useNavigate()
    const location = useLocation()
    const [ref, setRef] = useRefTaker()

    const draftWarning =
        account?.draft?.state === 'needs_approval' ? (
            <DraftWarning>
                A draft contract is ready and is <Text bold>pending approval.</Text>
            </DraftWarning>
        ) : null

    const renderButton = useCallback(
        (to: string, background: Color, text: string, color?: Color) => {
            const onClick = () => {
                navigate({
                    pathname: to,
                    search: location.search
                })
            }

            return (
                <ButtonHolder ref={setRef}>
                    <SimpleButton background={background} color={color} onClick={submitHandler(onClick)}>
                        <ButtonInset>{text}</ButtonInset>
                    </SimpleButton>
                </ButtonHolder>
            )
        },
        [setRef, navigate, location, submitHandler]
    )

    const renderedContractCard = useMemo(() => {
        const draftLink = `/merchant/${merchantId}/accounts/${accountId}/contracts/draft`
        const contractsLink = `/merchant/${merchantId}/accounts/${accountId}/contracts`
        if (!account?.contract)
            return (
                <ContractCard noContract>
                    <CardInset type="small">
                        <Flex grow justify="space-between" align="center">
                            <Flex align="center" shrink>
                                <Spacer width={10} />
                                <Info noContract>i</Info>
                                <Text color="side.text.subtlerI">
                                    The merchant account has{' '}
                                    <Text bold color="side.text">
                                        no active contract
                                    </Text>
                                </Text>
                            </Flex>
                            {renderButton(draftLink, 'front.accent.color', 'Go to contracts', undefined)}
                        </Flex>
                    </CardInset>
                </ContractCard>
            )
        else if (account?.contract?.metadata?.state === 'cancelled')
            return (
                <ContractCard cancelled>
                    <CardInset type="small">
                        <Flex grow justify="space-between" align="center">
                            <Flex align="center" shrink>
                                <Spacer width={10} />
                                <Info cancelled>
                                    <Icon type="x" size={10} weight={1.5} />
                                </Info>
                                <Text color="front.text.subtlerI">
                                    This merchant account&apos;s contract has been{' '}
                                    <Text bold color="front.text">
                                        cancelled
                                    </Text>
                                </Text>
                            </Flex>
                            {renderButton(contractsLink, 'front.danger.color', 'See contract', undefined)}
                        </Flex>
                    </CardInset>
                </ContractCard>
            )
        else if (account?.contract?.metadata?.state === 'signed')
            return (
                <ContractCard signed>
                    <CardInset type="small">
                        <Flex grow justify="space-between" align="center">
                            <Flex align="center" shrink>
                                <Spacer width={10} />
                                <Info signed>
                                    <Icon type="checkmark" size={10} weight={1.5} />
                                </Info>
                                <Text color="front.success.text.subtlerI">
                                    The contract has been{' '}
                                    <Text bold color="front.success.text">
                                        signed
                                    </Text>{' '}
                                    on{' '}
                                    <Text bold color="front.success.text">
                                        {moment(account?.contract?.metadata?.signedAt).format(
                                            DateFormats.dayAndTime(moment(account?.contract?.metadata?.signedAt))
                                        )}
                                    </Text>
                                </Text>
                            </Flex>
                            <ButtonHolder ref={setRef}>
                                <LinkButton
                                    to={contractsLink}
                                    background="front.success.color.subtlerI"
                                    color="front.success.text"
                                >
                                    <ButtonInset>See contract</ButtonInset>
                                </LinkButton>
                            </ButtonHolder>
                        </Flex>
                    </CardInset>
                </ContractCard>
            )
        else if (account?.contract?.metadata?.state === 'needs_signature')
            return (
                <ContractCard sent>
                    <CardInset type="small">
                        <Flex grow justify="space-between" align="center">
                            <Flex align="center" shrink>
                                <Spacer width={10} />
                                <Info sent>
                                    <Icon type="checkmark" size={10} weight={1.5} />
                                </Info>
                                <Text color="front.info.text.subtlerI">
                                    Sent for signing to{' '}
                                    <Text bold color="front.info.text">
                                        {account?.contract?.signer?.email}
                                    </Text>
                                </Text>
                            </Flex>
                            {renderButton(contractsLink, 'front.accent.color', 'See contract', undefined)}
                        </Flex>
                    </CardInset>
                </ContractCard>
            )
        else if (account?.contract?.metadata?.state === 'new')
            return (
                <ContractCard new>
                    <CardInset type="small">
                        <Flex grow justify="space-between" align="center">
                            <Flex align="center" shrink>
                                <Spacer width={10} />
                                <Info new>i</Info>
                                <Text color="front.text">
                                    Contract ready,{' '}
                                    <Text bold>merchant&apos;s signature has not been requested yet.</Text>
                                </Text>
                            </Flex>
                            {renderButton(contractsLink, 'front.accent.color', 'See contract', undefined)}
                        </Flex>
                    </CardInset>
                </ContractCard>
            )
        else if (account?.contract?.metadata?.state === 'declined')
            return (
                <ContractCard declined>
                    <CardInset type="small">
                        <Flex grow justify="space-between" align="center">
                            <Flex align="center" shrink>
                                <Spacer width={10} />
                                <Info declined>
                                    <Icon color="front.danger.color" type="x" size={10} weight={1.5} />
                                </Info>
                                <Text>
                                    The contract has been <Text bold>declined by the merchant</Text>.
                                </Text>
                            </Flex>
                            {renderButton(contractsLink, 'front.background', 'See contract', 'front.danger.color')}
                        </Flex>
                    </CardInset>
                </ContractCard>
            )

        return (
            <ContractCard>
                <CardInset type="small">
                    <Flex grow justify="space-between" align="center">
                        <Text>Unknown contract state...</Text>
                        {renderButton(
                            contractsLink,
                            'front.accent.color.subtlerI',
                            'See contract',
                            'front.success.text'
                        )}
                    </Flex>
                </CardInset>
            </ContractCard>
        )
    }, [setRef, renderButton, account, accountId, merchantId])

    return (
        <Flex align="stretch" column>
            {draftWarning}
            {renderedContractCard}
            <Floater anchor={ref} shouldShow={false}>
                <FloaterInset>
                    <Text color="floating.text">MCC and Transaction rules are missing.</Text>
                </FloaterInset>
            </Floater>
        </Flex>
    )
}

const ButtonHolder = styled.div``

const DraftWarning = styled.div`
    padding: 10px 0 8px 0;
    background-color: ${(p) => p.theme['front.info.background']};
    color: ${(p) => p.theme['front.info.text']};
    text-align: center;

    box-shadow: ${(p) => p.theme['subtleButtonsShadow']};
    border-radius: 8px;
    margin-bottom: 10px;
`

const ContractCard = styled.div<{
    noContract?: boolean
    cancelled?: boolean
    declined?: boolean
    signed?: boolean
    sent?: boolean
    new?: boolean
}>`
    border-radius: 14px;
    background-color: ${(p) => p.theme['front.success.color']};
    box-shadow: ${(p) => p.theme.cardsShadow};

    ${(p) =>
        p.noContract &&
        css`
            background-color: ${(p) => p.theme['side.background']};
            color: ${(p) => p.theme['side.text']};
        `}
    ${(p) =>
        p.cancelled &&
        css`
            background-color: ${(p) => p.theme['front.background.subtlerII']};
            color: ${(p) => p.theme['front.text']};
        `}
    ${(p) =>
        p.new &&
        css`
            background-color: ${(p) => p.theme['front.background']};
            color: ${(p) => p.theme['front.text']};
        `}
    ${(p) =>
        p.signed &&
        css`
            background-color: ${(p) => p.theme['front.success.color']};
            color: ${(p) => p.theme['front.success.text']};
        `}
    ${(p) =>
        p.declined &&
        css`
            background-color: ${(p) => p.theme['front.danger.color']};
            color: ${(p) => p.theme['front.danger.text']};
        `}
    ${(p) =>
        p.sent &&
        css`
            background-color: ${(p) => p.theme['front.info.background']};
            color: ${(p) => p.theme['front.info.text']};
        `}
`

const Info = styled.div<{
    noContract?: boolean
    cancelled?: boolean
    signed?: boolean
    sent?: boolean
    new?: boolean
    signer?: boolean
    declined?: boolean
}>`
    width: 22px;
    height: 22px;
    border-radius: 999px;
    background-color: ${(p) => p.theme['front.background.subtlerI']};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-right: 14px;
    color: ${(p) => p.theme['front.success.color']};

    ${(p) =>
        p.declined &&
        css`
            background-color: ${(p) => p.theme['front.danger.text']};
            color: ${(p) => p.theme['front.danger.color']};
        `}

    ${(p) =>
        p.cancelled &&
        css`
            background-color: ${(p) => p.theme['front.danger.color']};
            color: ${(p) => p.theme['front.danger.text']};
        `}

    ${(p) =>
        p.noContract &&
        css`
            background-color: ${p.theme['front.background.strongerIII']};
            color: ${p.theme['side.background']};
        `}

    ${(p) =>
        p.signed &&
        css`
            color: ${(p) => p.theme['front.success.color']};
            background-color: ${(p) => p.theme['front.success.text']};
        `}
    
        ${(p) =>
        p.new &&
        css`
            color: ${(p) => p.theme['front.accent.text']};
            background-color: ${(p) => p.theme['front.accent.color']};
        `}
    ${(p) =>
        p.sent &&
        css`
            color: ${(p) => p.theme['front.accent.text']};
            background-color: ${(p) => p.theme['front.accent.color']};
        `}
`
