export const URLSearchParamsToDictionary = (params: URLSearchParams) => {
    const result = {}
    for (const [key, value] of params.entries()) {
        if (result[key]) {
            result[key].push(value)
        } else {
            result[key] = [value]
        }
    }

    for (const key in result) {
        if (result[key].length == 1) {
            result[key] = result[key][0]
        }
    }

    return result
}

export const DictionaryToURLSearchParams = (unrefinedDict: any): URLSearchParams => {
    if (!unrefinedDict) return new URLSearchParams()
    const dict = Object.keys(unrefinedDict).reduce((acc, k) => {
        if (unrefinedDict[k]) {
            acc[k] = unrefinedDict[k]
        }
        return acc
    }, {})

    const params = new URLSearchParams()
    for (const key in dict) {
        if (typeof dict[key] == 'object') {
            for (const i of dict[key]) {
                params.append(key, i)
            }
        } else {
            params.append(key, dict[key])
        }
    }
    return params
}

export const urlWithQueryString = (url: string, params: any) => {
    const searchString = DictionaryToURLSearchParams(params).toString()
    if (searchString == '?') return url
    return url + (searchString.includes('?') ? searchString : `?${searchString}`)
}

export const dictionaryToSearchString = (params: any) => {
    const str = DictionaryToURLSearchParams(params).toString()
    if (str.length) return '?' + str
    else return ''
}
