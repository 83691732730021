import { useMemo } from 'react'
import { Text } from '../../components/general/text'
import { TyposHighlighter } from '../../components/general/typosHighlighter'
import { Flag } from '../../components/icons/flag'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import {
    DataPorviderStateAssignedAndReady,
    DataProviderOwnershipConflict
} from '../../store/applicationDataProviders/types'
import countries from 'i18n-iso-countries'
import { UBO_OWNERSHIP_LOWER_LIMIT } from '../../store/applicationDataProviders/sagas.conflictsFinder'
import styled, { css, useTheme } from 'styled-components'
import { Piechart } from '../../components/charts/piechart'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export const MerchantApplicationConflictsOwnershipSOTTable: React.FC<{
    applicationId: string
    conflict?: DataProviderOwnershipConflict
}> = ({ applicationId, conflict }) => {
    const theme = useTheme()
    const ownership = useSelector((state: RootState) => {
        const s = state.dataProviders.forApplication[applicationId] as DataPorviderStateAssignedAndReady
        return s.state == 'assigned-and-ready' ? s.computed.ownershipShares : undefined
    })

    const tableRows = useMemo(() => {
        if (!ownership) return []
        if (!conflict) return []
        if (conflict?.type === 'no-conflicts') return []

        const rows = Object.keys(ownership.list).map((name) => {
            const o = ownership.list[name]
            const c = conflict.perUBOConflicts[o.name]
            const color = o.fraction < UBO_OWNERSHIP_LOWER_LIMIT ? 'front.text.subtlerI' : 'front.text'
            return {
                key: o.name,
                type: 'normal' as const,
                noAction: true,
                items: [
                    {
                        node: (
                            <Flex column>
                                <Text noWrap color={color}>
                                    {c?.type == 'typo' ? (
                                        <TyposHighlighter
                                            highlights={c.highlights}
                                            fallbackText={c.applicationValue}
                                            isSourceOfTruth
                                        />
                                    ) : (
                                        <Text>{o.name}</Text>
                                    )}
                                </Text>
                                {o.country ? (
                                    <Flex>
                                        <Flag code={o?.country} smaller />
                                        <Spacer width={5} />
                                        <Text color="front.text.subtlerI" noWrap>
                                            {countries.getName(o.country.toUpperCase(), 'en')}
                                        </Text>
                                    </Flex>
                                ) : null}
                            </Flex>
                        )
                    },
                    {
                        node: (
                            <Text noWrap color={color}>
                                {o.formatted}
                            </Text>
                        )
                    },
                    {
                        node: c ? (
                            <Flex justify="flex-start">
                                <ConflictPill type={c?.type}>
                                    {c?.type == 'not-found' && 'Not found'}
                                    {c?.type == 'typo' && 'Typo'}
                                </ConflictPill>
                            </Flex>
                        ) : (
                            <span />
                        )
                    }
                ]
            }
        })

        if (ownership.missing.status === 'fraction')
            rows.push({
                key: 'missing-stake',
                type: 'normal',
                noAction: true,
                items: [
                    {
                        node: (
                            <Text italic color="front.text.subtlerI">
                                Missing information
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text italic color="front.text.subtlerI">
                                {ownership.missing.formatted}
                            </Text>
                        )
                    },
                    { node: <span /> }
                ]
            })
        else if (ownership.missing.status === 'over-limit')
            rows.push({
                key: 'stake-overhead',
                type: 'normal',
                noAction: true,
                items: [
                    {
                        node: (
                            <Text italic color="front.text.subtlerI">
                                The reported ownership exceeds 100%. Please consider that some the reported entries
                                might be companies and not UBOs.
                            </Text>
                        )
                    }
                ]
            })
        return rows
    }, [ownership, conflict])

    const pieChartData = useMemo(() => {
        let failedToParse = false

        if (!ownership) {
            return 'failed-to-parse'
        }

        const data = Object.keys(ownership.list).reduce((acc: any, k) => {
            const v = ownership.list[k]
            if (v.formatted == 'Unknown') failedToParse = true
            acc[v.name] = v.fraction * 100
            return acc
        }, {} as any)

        data['Missing information'] = ownership.missing.fraction * 100

        return failedToParse ? 'failed-to-parse' : data
    }, [ownership])

    if (!ownership) return null

    return (
        <>
            {pieChartData !== 'failed-to-parse' ? (
                <>
                    <Flex grow justify="center">
                        <Piechart
                            data={pieChartData}
                            overrideColors={{ 'Missing information': theme['front.highlights'] }}
                        />
                    </Flex>
                    <Spacer height={40} />
                </>
            ) : null}
            <Table
                overrideText="No owners found in the application"
                background="front.background"
                cols={[
                    {
                        text: 'Name'
                    },
                    {
                        text: 'Shares'
                    },
                    {
                        text: 'Conflict',
                        alignRight: true
                    }
                ]}
                rows={tableRows}
                columnLayout="auto min-content min-content"
            />
        </>
    )
}

export type ConflictPillType = 'not-found' | 'typo' | 'unlisted'
export const ConflictPill = styled.div<{ type: ConflictPillType }>`
    padding: 3px 5px 3px 6px;
    background-color: red;
    border-radius: 999px;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 9px;

    ${(p) =>
        p.type == 'typo' &&
        css`
            background-color: ${(p) => p.theme['front.info.background']};
            color: ${(p) => p.theme['front.info.text']};
            border: 1px solid ${(p) => p.theme['front.info.background.strongerII']};
        `}

    ${(p) =>
        p.type == 'unlisted' &&
        css`
            background-color: ${(p) => p.theme['front.background']};
            color: ${(p) => p.theme['front.text.subtlerI']};
            border: 1px solid ${(p) => p.theme['front.border']};
        `}

    ${(p) =>
        p.type == 'not-found' &&
        css`
            background-color: ${(p) => p.theme['front.danger.color']};
            color: ${(p) => p.theme['front.danger.text']};
            border: 1px solid ${(p) => p.theme['front.danger.color.strongerI']};
        `}
`
