import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { Spacer } from '../../components/layout/spacer'
import { Text } from '../../components/general/text'
import { FullscreenModalBackButton } from '../../components/modals/fullscreenModalBackButton'
import { RootState } from '@/store'
import { MerchantAssignAgent } from './MerchantAssignAgent'
import { MerchantSidebarNavLink } from './MerchantSidebarNavLink'
import { Theme } from '../../hooks/general/useTheme'
import { Color } from '../../styles/colors'
import { useMerchantSidebarDynamicPageId } from './useMerchantSidebarDynamicPageId'
import { Flex } from '../../components/layout/flex'
import { MerchantSidebarNotes } from './MerchantSidebarNotes'
import { MerchantSidebarInteraction } from './MerchantSidebarButtonInteractions'
import { useHasApplicationSidebarLoaded } from './Application/useHasApplicationSidebarLoaded'
import { LoadingShaper } from '../../components/loaders/loadingShaper'
import { TextLineLoader } from '../../components/loaders/textLineLoader'
import { startCase } from 'lodash'
import { LinkButton } from '../../components/buttons/linkButton'
import { MerchantAccountsSidebar } from './Accounts/AccountsSidebar'
import { useMedia } from 'react-use'
import { MerchantSidebarDetails } from './MerchantSidebarDetails'
import { BVDUISize } from '../../store/applicationInternals/bvd/types'
import { ListMerchantTags } from './MerchantSidebarButtonTags'
import { BIG_SMALL_SCREEN_SWAP } from './Accounts/utils'
import { MERCHANT_SIDEBAR_WIDTH } from './MerchantSidebarWidth'
import { MerchantSidebarLinks } from './MerchantSidebarLinks'

export const MerchantSidebar: React.FC<{
    width: number
    applicationId: string
    backButtonQuery?: string
    location: any
    accountId?: string
}> = ({ applicationId, accountId, width, location }) => {
    const companyName = useSelector(
        (state: RootState) => state.applicationResources.data.forApplication[applicationId]?.company?.fields?.[0].name
    )
    const hasCompanyLoaded = useSelector(
        (state: RootState) =>
            state.applicationResources.data.forApplication[applicationId]?.company?.loadingStatus === 'done'
    )

    const pageId = useMerchantSidebarDynamicPageId()
    const application = useSelector((state: RootState) => state.applications.applications.at[applicationId])
    const accounts = useSelector((state: RootState) => state.merchantAccounts)
    const tasksCount = useSelector((state: RootState) => state.applicationLoading?.at?.[applicationId]?.tasksCount)
    const merchantAccounts = accounts.forApplication[applicationId]?.all?.map((id: string) => accounts.at[id])
    const merchantTags = useSelector((state: RootState) => {
        return state.applicationInternals.tags.forApplication?.[applicationId]?.selected || []
    })
    const bvdSize = useSelector((state: RootState) => {
        return state.applicationInternals.bvd?.[applicationId]?.size
    })
    const hasSidebarLoaded = useHasApplicationSidebarLoaded(applicationId)
    const shouldHide = useMedia(`(min-width: ${BIG_SMALL_SCREEN_SWAP + 1}px)`)

    const borderColor = useMemo(() => {
        if (location.pathname.includes('accounts')) return 'side.border' as Color
        return 'back.background.strongerI' as Color
    }, [location])

    const midgaardLink = useMemo(() => {
        return `${import.meta.env.VITE_MIDGAARD_LINK}/applications/${btoa(application?.selfLink)}`.replace('==', '')
    }, [application])

    const renderIfExists = useCallback(
        (x: string, maxWidth?: number, forceLoading?: boolean) => {
            if (!hasSidebarLoaded || forceLoading)
                return (
                    <Text>
                        &nbsp; &nbsp;
                        <TextLineLoader sidebar topOffset={2} minWidth={15} maxWidth={maxWidth || 30} />
                    </Text>
                )

            if (x) return <Text>&nbsp;·&nbsp;{x}</Text>
            return ''
        },
        [hasSidebarLoaded]
    )

    const merchantAccountsCount = useMemo(() => {
        if (!merchantAccounts?.length || !hasSidebarLoaded) return ''
        const merchantAccountWithPaymentsHeld = merchantAccounts.filter((m) => m.paymentsHeld).length
        if (merchantAccountWithPaymentsHeld) return `${merchantAccounts?.length} ⋅ ${merchantAccountWithPaymentsHeld}PH`
        return `${merchantAccounts?.length}`
    }, [merchantAccounts, hasSidebarLoaded])

    return (
        <>
            <Holder
                width={width}
                borderColor={borderColor}
                data-cy="merchant-sidebar"
                data-floater-boundary
                bvdSize={bvdSize}
            >
                <SiderbarHeader>
                    <FullscreenModalBackButton
                        hotkeyScope={pageId}
                        backTo="/applications"
                        overBackground="side.background"
                    />
                    <Mask hide={hasSidebarLoaded}>
                        <MerchantAssignAgent applicationId={applicationId} pageId={pageId} alignCoverRight />
                    </Mask>
                    {!hasSidebarLoaded ? <AssignAgentLoader /> : null}
                </SiderbarHeader>
                <Header>
                    {hasCompanyLoaded ? (
                        <PageInfo key="title" data-cy="merchant-name">
                            <Text size="xxl" key="title" color="side.text" bold>
                                {companyName || 'Unnamed merchant'}
                            </Text>
                        </PageInfo>
                    ) : null}
                    {hasSidebarLoaded ? (
                        <>
                            <Flex>
                                {merchantTags.length ? (
                                    <ListMerchantTags
                                        inSidebar
                                        applicationId={applicationId}
                                        pageId={pageId}
                                        tagsIds={merchantTags}
                                    />
                                ) : (
                                    <NoTagsHolder>
                                        <Text color="side.text.subtlerI">Merchant has no tags&nbsp;&nbsp;</Text>
                                        <LinkButton
                                            inline
                                            to={`/merchant/${applicationId}/tags`}
                                            color="side.subtleAccent.text"
                                            hotkeys="alt+t"
                                            keepQuery
                                            cy="tags"
                                            hotkeysScope={pageId}
                                        >
                                            Edit tags
                                        </LinkButton>
                                    </NoTagsHolder>
                                )}
                            </Flex>
                            <MerchantSidebarDetails id={applicationId} />
                            <Flex>
                                <MerchantSidebarInteraction applicationId={applicationId} />
                                <Spacer width={5} />
                                <MerchantSidebarNotes applicationId={applicationId} />
                            </Flex>
                        </>
                    ) : null}
                    {!hasCompanyLoaded || !hasSidebarLoaded ? (
                        <SidebarLoader key="loader" skipTitle={hasCompanyLoaded} />
                    ) : null}
                    <HeaderBorder />
                </Header>
                <Navigation>
                    <MerchantSidebarNavLink
                        to={`/merchant/${applicationId}/summary`}
                        searchQuery={location.search}
                        exact
                        label="Summary"
                        hotkeys="alt+1"
                        hotkeysScope={pageId}
                        icon="merchantSummary"
                    />
                    <MerchantSidebarNavLink
                        to={`/merchant/${applicationId}/application`}
                        searchQuery={location.search}
                        label={
                            <Flex>
                                <Text>Application</Text>
                                {renderIfExists(startCase(application?.metadata?.state))}
                            </Flex>
                        }
                        hotkeys="alt+2"
                        hotkeysScope={pageId}
                        icon="merchantApplication"
                    />
                    <MerchantSidebarNavLink
                        to={`/merchant/${applicationId}/accounts`}
                        searchQuery={location.search}
                        hotkeys="alt+3"
                        hotkeysScope={pageId}
                        label={
                            <Flex>
                                <Text>Accounts</Text>
                                {renderIfExists(merchantAccountsCount, 15)}
                            </Flex>
                        }
                        icon="merchantAccounts"
                    />
                    {/* location.pathname.includes('/accounts') &&  */}
                    {!shouldHide && hasSidebarLoaded && (
                        <MerchantAccountsSidebar id={applicationId} accountId={accountId} skipSidebar />
                    )}
                    <MerchantSidebarNavLink
                        to={`/merchant/${applicationId}/tasks`}
                        searchQuery={location.search}
                        hotkeys="alt+4"
                        hotkeysScope={pageId}
                        label={
                            <Flex>
                                <Text>Tasks</Text>
                                {renderIfExists(hasSidebarLoaded ? `${tasksCount}` : '', 15, tasksCount === undefined)}
                            </Flex>
                        }
                        icon="merchantTasks"
                    />
                    <MerchantSidebarNavLink
                        to={`/merchant/${applicationId}/more/risk-tab`}
                        searchQuery={location.search}
                        hotkeys="alt+5"
                        hotkeysScope={pageId}
                        label={`More`}
                        icon="merchantMore"
                    />
                    <Spacer height={20} />
                    <MerchantSidebarNavLink
                        to={`/merchant/${applicationId}/transactions`}
                        hotkeys="alt+6"
                        hotkeysScope={pageId}
                        smaller
                        searchQuery={location.search}
                        label="All transactions"
                    />
                    <MerchantSidebarNavLink
                        smaller
                        to={`/merchant/${applicationId}/disputes`}
                        searchQuery={location.search}
                        hotkeys="alt+7"
                        hotkeysScope={pageId}
                        label="All disputes"
                    />
                    <MerchantSidebarNavLink
                        smaller
                        to={`/merchant/${applicationId}/settlements`}
                        searchQuery={location.search}
                        hotkeys="alt+8"
                        hotkeysScope={pageId}
                        label="All settlements"
                    />

                    <Flex column grow justify="flex-end">
                        <MerchantSidebarLinks application={application} accounts={merchantAccounts} />
                    </Flex>
                </Navigation>
            </Holder>
            <Placeholder />
        </>
    )
}

const Placeholder = styled.div`
    width: ${MERCHANT_SIDEBAR_WIDTH}px;
    height: 100%;
    flex-shrink: 0;
`

const Header = styled.div`
    flex-shrink: 0;
    margin-top: 25px;
    margin-left: 1px;
    width: 100%;
`

const Holder = styled.div<{ width: number; theme: Theme; borderColor: Color; bvdSize?: BVDUISize }>`
    position: fixed;
    z-index: 100;
    width: ${(p) => p.width}px;
    padding: 10px 25px 20px 25px;
    align-items: stretch;
    background-color: ${(p) => p.theme['side.background']};
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
    transform: translate3d(0, 0, 0);
    border-right: 1px solid ${(p) => p.theme[p.borderColor]};
    height: calc(50vh);
    top: 0;
    left: 0;

    ${(p) => {
        switch (p.bvdSize) {
            case 'half':
                return css`
                    height: 50vh;
                `
            case 'mini':
                return css`
                    height: calc(100vh);
                `
            default:
                return css`
                    height: 100vh;
                `
        }
    }}
`

const Navigation = styled.div`
    flex-grow: 1;
    width: calc(100% + 2px);
    margin-left: -1px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`

const SiderbarHeader = styled.div`
    height: 34px;
    display: flex;
    margin-top: 3px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const PageInfo = styled.div`
    color: ${(p) => p.theme['front.text']};
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
`

const Mask = styled.div<{
    hide?: boolean
}>`
    display: none;

    ${(p) =>
        p.hide &&
        css`
            display: block;
        `}
`

const OffsetLoader = styled.div`
    position: relative;
    top: 2px;
    left: 1px;
`

const NoTagsHolder = styled.div`
    min-height: 22px;
`

const HeaderBorder = styled.div`
    width: calc(100%);
    margin-top: 23px;
    margin-bottom: 15px;
    height: 1px;
    background-color: ${(p) => p.theme['side.border']};
`

const SidebarLoader: React.FC<{ skipTitle?: boolean }> = ({ skipTitle }) => {
    const newY = (n: number) => {
        if (skipTitle) return n - 49
        else return n
    }
    return (
        <SidebarLoaderOffset>
            <LoadingShaper width={400} height={newY(213)} sidebar>
                {/* TITLE */}
                {skipTitle ? null : <rect x="0" y="6" rx="2" ry="2" width="180" height="25" />}
                {/* TAGS */}
                <rect x="0" y={newY(53)} rx="2" ry="2" width="140" height="13" />
                {/* SMALL LABELS */}
                <rect x="0" y={newY(87)} rx="2" ry="2" width="60" height="7" />
                <rect x="120" y={newY(87)} rx="2" ry="2" width="60" height="7" />
                <rect x="0" y={newY(130)} rx="2" ry="2" width="60" height="7" />
                {/* VALUES */}
                <rect x="0" y={newY(99)} rx="2" ry="2" width="90" height="13" />
                <rect x="120" y={newY(99)} rx="2" ry="2" width="110" height="13" />
                <rect x="0" y={newY(142)} rx="2" ry="2" width="190" height="13" />
                {/* BUTTONS */}
                <rect x="0" y={newY(180)} rx="8" ry="8" width="105" height="28" />
                <rect x="110" y={newY(180)} rx="8" ry="8" width="70" height="28" />
            </LoadingShaper>
        </SidebarLoaderOffset>
    )
}

const SidebarLoaderOffset = styled.div`
    margin-bottom: -10px;
`

const AssignAgentLoader: React.FC = () => {
    const circleOff = 77
    return (
        <OffsetLoader>
            <LoadingShaper width={circleOff + 32} height={32} sidebar>
                <circle cx={circleOff + 16} cy={0 + 16} r="16" />
                <rect x="0" y="9.5" rx="2" ry="2" width={circleOff - 5} height="13" />
            </LoadingShaper>
        </OffsetLoader>
    )
}
