import { camelCase } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Account } from '../../../components/general/account'
import { LinkButton } from '../../../components/buttons/linkButton'
import { Text } from '../../../components/general/text'
import { RootState } from '@/store'
import { uppercaseFirstLetter, uppercaseFirstLetterIfLowercase } from '../../../utils'
import { Flex } from '../../../components/layout/flex'
import { Table } from '../../../components/tables/table'
import { Spacer } from '../../../components/layout/spacer'

export const MerchantSummaryTimelineCard: React.FC<{ merchantId: string }> = ({ merchantId }) => {
    const timeline = useSelector((state: RootState) => state.merchantTimeline[merchantId])

    const rightSideMemo = useMemo(() => {
        return (
            <Flex grow justify="flex-end">
                <LinkButton
                    keepQuery
                    to={`/merchant/${merchantId}/summary/timeline`}
                    color="front.accent.color"
                    hotkeysScope="Merchant.Summary"
                    hotkeys="alt+t"
                >
                    Full timeline ({timeline.all.length})
                </LinkButton>
            </Flex>
        )
    }, [merchantId, timeline])

    const cols = useMemo(() => {
        return [{ text: 'Event' }, { text: 'Time' }]
    }, [])

    const rows = useMemo(() => {
        if (!timeline || !timeline.all) return []
        return (
            timeline?.all
                ?.filter((item, i) => i < 5)
                .map((id, index, arr) => {
                    const point = timeline.at[id]
                    let text = ''

                    switch (point.type) {
                        case 'creation':
                            text = 'created the application'
                            break
                        case 'field-edits':
                            text = `made ${point.edits.length} edits`
                            break
                        case 'state-change':
                            text = `stamped as «${
                                point.edits[0].to ? uppercaseFirstLetter(point.edits[0].to as string) : 'Unknown state'
                            }»`
                            break
                        default:
                            text = 'TO-DO'
                            break
                    }
                    return {
                        type: 'normal' as const,
                        noAction: true,
                        key: camelCase(text + point.at),
                        items: [
                            {
                                node: (
                                    <Flex column>
                                        <Account id={point.by} />
                                        <Text color="back.text.strongerIII">
                                            <Text color="back.text.strongerI">
                                                {uppercaseFirstLetterIfLowercase(text)}
                                            </Text>
                                        </Text>
                                    </Flex>
                                )
                            },
                            {
                                node: (
                                    <Flex column justify="flex-start" grow>
                                        <Text color="back.text.strongerI" oneLine>
                                            {moment(point.at).fromNow()}
                                        </Text>
                                    </Flex>
                                )
                            }
                        ]
                    }
                }) || []
        )
    }, [timeline])

    return (
        <Wrapper>
            <Flex column>
                <Spacer height={15} />
                <Flex>
                    <Text color="back.text">Edits timeline</Text>

                    {rightSideMemo}
                </Flex>
                <Spacer height={20} />
                <Table
                    noHeader
                    bordered
                    type="narrow"
                    background="back.background.strongerI"
                    cols={cols}
                    columnLayout="auto min-content"
                    rows={rows}
                />
            </Flex>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    color: ${(p) => p.theme['front.text']};
`
