import { ActionType } from 'deox'
import { put } from 'redux-saga/effects'

import { GET, PUT } from '../../../generators/networking'
import { AutocompleteDispatchStoreAgents } from '../../autocomplete/actions'
import { ToastsDispatchPush } from '../../toasts/actions'
import { ApplicationInternalsAgentsActions } from './actions'

export const ApplicationInternalsAgentsSagas = {
    *FETCH({ payload: p }: ActionType<typeof ApplicationInternalsAgentsActions.FETCH>) {
        const response: ActionType<typeof ApplicationInternalsAgentsActions.STORE>['payload']['response'] = yield* GET({
            url: p.link,
            include: ['self', 'selectable', 'selection', 'agents'],
            onSuccessDispatch: (
                response: ActionType<typeof ApplicationInternalsAgentsActions.STORE>['payload']['response']
            ) => {
                return ApplicationInternalsAgentsActions.STORE(p.applicationId, response)
            },
            errorText: "Failed to fetch applications' assigned agents"
        })

        const allRelevantAgents = [...(response?.selectable || [])]

        yield put(AutocompleteDispatchStoreAgents([], allRelevantAgents))
    },
    *ASSIGN_AGENT({ payload: p }: ActionType<typeof ApplicationInternalsAgentsActions.ASSIGN_AGENT>) {
        const { success, cleanedResponse } = yield PUT({
            watcher: p.watcherID,
            url: p.agent.selectionLink,
            successCode: 200,
            include: ['self', 'selectable', 'selection', 'agents'],
            successText: 'Agent assigned successfully.',
            errorText: 'Failed to assign agent.'
        })

        if (success) {
            yield put(ApplicationInternalsAgentsActions.STORE(p.applicationId, cleanedResponse))
        } else {
            yield put(ToastsDispatchPush('Failed to assign the agent', 'error'))
        }
    }
}
