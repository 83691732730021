import copy from 'copy-to-clipboard'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ToastsDispatchPush } from '../../store/toasts/actions'

export const useCopyToClipboard = () => {
    const dispatch = useDispatch()
    const copyToClipboard = useCallback(
        (text: string) => {
            try {
                copy(text)
                dispatch(ToastsDispatchPush('Copied successfully.', 'success'))
            } catch (e) {
                dispatch(ToastsDispatchPush('Failed to copy.', 'error'))
            }
        },
        [dispatch]
    )
    return copyToClipboard
}
