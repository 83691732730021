/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from 'deox'
import dotProp from 'dot-prop'
import { put, select } from 'typed-redux-saga'
// eslint-disable-next-line max-len
import { disabledBankAccountSectionStates } from '../../../pages/Merchant/Application/Application.BankAccountSection'
import { ApplicationStatus, CutterCommentInfo } from '../../applications/types'
import { RootState } from '@/store'
import { ApplicationInternalsNeedingAttentionActions } from './actions'

// const NUMBER_OF_APPLICATION_RESOURCES = 9
// function* waitUntilAllCommentsHaveBeenLoadedAndReturnTheResources(applicationId: string) {
//     const areThereSectionsLeftToLoad = (resources: any) => {
//         if (!resources) return null
//         return (
//             resources &&
//             Object.keys(resources).filter((rId) => resources[rId].loadingStatus !== 'done').length !== 0 &&
//             resources.length !== NUMBER_OF_APPLICATION_RESOURCES
//         )
//     }

//     let resources = yield select(
//         (state: RootState) => state.applicationResources.comments.forApplication[applicationId]
//     )

//     if (!areThereSectionsLeftToLoad(resources)) return resources

//     let action
//     do {
//         action = yield take(getType(ApplicationResourceActions.STORE_COMMENTS))
//         resources = yield select(
//             (state: RootState) => state.applicationResources.comments.forApplication[applicationId]
//         )
//     } while (areThereSectionsLeftToLoad(resources))
//     return resources
// }

export const ApplicationInternalsNeedingAttentionSagas = {
    *SCAN_COMMENTS({ payload: p }: ActionType<typeof ApplicationInternalsNeedingAttentionActions.SCAN_COMMENTS>) {
        const commentsReadState: {
            [key: string]: CutterCommentInfo
        } = yield* select((state: RootState) => state.applications.cutterCommentsInfo.forApplication[p.applicationId])
        const applicationState: ApplicationStatus = yield* select(
            (state: RootState) => state.applications?.applications?.at?.[p.applicationId]?.metadata?.state
        )
        const unreadComments = Object.keys(commentsReadState || {}).filter(
            (id) => commentsReadState?.[id]?.readAt === null
        )
        const resources: { [key: string]: any } = yield* select(
            (state: RootState) => state.applicationResources.comments.forApplication[p.applicationId]
        )
        let unreadCommentsArray = unreadComments

        const unreadFields: string[] = []
        const internalNotesUnread = unreadComments
            .map((cId) => {
                return commentsReadState[cId]
            })
            .filter((c) => c?.private === true).length
            ? true
            : false
        if (resources)
            unreadComments?.forEach((cId) => {
                Object.keys(resources || {}).forEach((rKey: string) => {
                    Object.keys(resources[rKey]?.fields || {}).forEach((sIndex: string) => {
                        let fieldPaths = Object.keys(resources[rKey]?.fields?.[sIndex]).map(
                            (c) => `${rKey}.fields.${sIndex}` + '.' + c
                        )
                        if (rKey === 'businessModel')
                            fieldPaths = Object.keys(resources[rKey]?.fields?.[sIndex].businessModel || {}).map(
                                (c) => `${rKey}.fields.${sIndex}` + '.businessModel.' + c
                            )
                        if (rKey === 'signer')
                            fieldPaths = Object.keys(resources[rKey]?.fields?.[sIndex].signer || {}).map(
                                (c) => `${rKey}.fields.${sIndex}` + '.signer.' + c
                            )
                        if (rKey === 'gateway')
                            fieldPaths = Object.keys(resources[rKey]?.fields?.[sIndex].gateways['0'] || {}).map(
                                (c) => `${rKey}.fields.${sIndex}` + '.gateways.0.' + c
                            )

                        fieldPaths.forEach((fPath: string) => {
                            ;(dotProp.get(resources, fPath + '.comments') as any)?.forEach((c: any) => {
                                if (c.id === cId) {
                                    // bank account section in certain states dissapears from the application
                                    if (
                                        rKey === 'bankAccount' &&
                                        disabledBankAccountSectionStates.includes(applicationState)
                                    ) {
                                        unreadCommentsArray = unreadCommentsArray.filter((c) => c !== cId)
                                    } else {
                                        unreadFields.push(fPath.replace('.fields', ''))
                                    }
                                }
                            })
                        })
                    })
                })
            })

        yield put(
            ApplicationInternalsNeedingAttentionActions.STORE_COMMENTS(
                p.applicationId,
                unreadCommentsArray,
                unreadFields,
                internalNotesUnread
            )
        )
    }
}
