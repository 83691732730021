import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'

export const CompanyMcc: React.FC = () => {
    const merchantData = useReportMerchantData()

    const mccs = merchantData.accounts.map((account) => account.mcc).filter(Boolean)

    const value = mccs.length !== 0 ? mccs.join(', ') : '-'

    return <Section.DataItem title="Mcc(s)" value={value} />
}
