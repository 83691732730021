import Dexie from 'dexie'

export class DB extends Dexie {
    identity: Dexie.Table<{ id: string; data: string }, string>
    constructor() {
        super('db')

        this.version(1).stores({
            identity: 'id'
        })

        this.identity = this.table('identity')
    }
}

export const db = new DB()
