import { CardInset } from '../../../components/cards/cardInset'
import { ContractCard } from '../../../components/cards/contractCard'
import { List } from '../../../components/layout/list'
import { Separator } from '../../../components/layout/separator'
import { Spacer } from '../../../components/layout/spacer'
import { FormFieldWithFormattedNumber } from '../../../utils'
import { MerchantAccountContractsProps } from './Accounts.ID.ContractProps'
import { useContractSectionHelper } from './useContractSectionHelper'
import { ContractValidationRules } from './utils'

export const MerchantAccountContractsSectionFees: React.FC<
    MerchantAccountContractsProps & { shouldDisableSetupFee?: boolean }
> = ({ contract, formErrors, draftOrTemplate, formRef, shouldDisableSetupFee, isDisabled, currency: suffix }) => {
    const { getValue, isEditable } = useContractSectionHelper(contract, draftOrTemplate)

    return (
        <>
            <Spacer width={20} />
            <ContractCard title="Account Fees" showEditable={isEditable}>
                <CardInset>
                    <List
                        background={'front.background'}
                        rowGap="tiny"
                        items={{
                            'Setup fee': {
                                key: 'setup-fee',
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('charges.setupFee'),
                                isDisabled: isDisabled || !isEditable || shouldDisableSetupFee,
                                validation: formErrors['charges.setupFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.setupFee',
                                        ContractValidationRules.formattedNumber,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix,
                                overBackground: 'front.background'
                            },
                            'Payout fee': {
                                key: 'payout-fee',
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('charges.payoutFee'),
                                isDisabled: isDisabled || !isEditable,
                                validation: formErrors['charges.payoutFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.payoutFee',
                                        ContractValidationRules.limitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix,
                                overBackground: 'front.background'
                            },
                            'Monthly fee': {
                                key: 'monthly-fee',
                                type: 'input',
                                initialValue: getValue('charges.monthlyFee'),
                                isDisabled: isDisabled || !isEditable,
                                rightAlign: true,
                                suffix,
                                validation: formErrors['charges.monthlyFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.monthlyFee',
                                        ContractValidationRules.formattedNumber,
                                        FormFieldWithFormattedNumber
                                    ),

                                overBackground: 'front.background'
                            },
                            'Annual fee': {
                                key: 'annual-fee',
                                type: 'input',
                                initialValue: getValue('charges.yearlyFee'),
                                isDisabled: isDisabled || !isEditable,
                                validation: formErrors['charges.yearlyFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.yearlyFee',
                                        ContractValidationRules.formattedNumber,
                                        FormFieldWithFormattedNumber
                                    ),
                                rightAlign: true,
                                suffix,
                                overBackground: 'front.background'
                            },
                            'Separator 1': {
                                key: 'separator-1',
                                type: 'separator',
                                node: <Separator moreSpacing />
                            },
                            'Chargeback fee': {
                                key: 'chargeback-fee',
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('charges.chargebackFee'),
                                isDisabled: isDisabled || !isEditable,
                                suffix,

                                validation: formErrors['charges.chargebackFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.chargebackFee',
                                        ContractValidationRules.higherLimitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                overBackground: 'front.background'
                            },
                            'Retrieval fee': {
                                key: 'retrieval-fee',
                                type: 'input',
                                initialValue: getValue('charges.retrievalFee'),
                                isDisabled: isDisabled || !isEditable,
                                rightAlign: true,

                                validation: formErrors['charges.retrievalFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.retrievalFee',
                                        ContractValidationRules.higherLimitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix,
                                overBackground: 'front.background'
                            }
                        }}
                        alignRight
                        switchToRowsAt={10000}
                        cellHorizontalTemplate="min-content auto"
                    />
                </CardInset>
            </ContractCard>
            <Spacer width={20} />
            <ContractCard title="Transactions fees" showEditable={isEditable}>
                <CardInset>
                    <List
                        background="front.background"
                        alignRight
                        rowGap="tiny"
                        items={{
                            'Authorisation fee': {
                                key: 'authorisation-fee',
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('charges.authorizationFee'),
                                isDisabled: isDisabled || !isEditable,
                                suffix,

                                validation: formErrors['charges.authorizationFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.authorizationFee',
                                        ContractValidationRules.limitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                overBackground: 'front.background'
                            },
                            'Capture fee': {
                                key: 'capture-fee',
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('charges.captureFee'),
                                isDisabled: isDisabled || !isEditable,
                                suffix,

                                validation: formErrors['charges.captureFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.captureFee',
                                        ContractValidationRules.limitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                overBackground: 'front.background'
                            },
                            'Refund fee': {
                                key: 'refund-fee',
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('charges.refundFee'),
                                isDisabled: isDisabled || !isEditable,
                                suffix,
                                validation: formErrors['charges.refundFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.refundFee',
                                        ContractValidationRules.limitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                overBackground: 'front.background'
                            },
                            'Credit fee': {
                                key: 'credit-fee',
                                type: 'input',
                                initialValue: getValue('charges.creditFee'),
                                isDisabled: isDisabled || !isEditable,
                                rightAlign: true,

                                validation: formErrors['charges.creditFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.creditFee',
                                        ContractValidationRules.limitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix,
                                overBackground: 'front.background'
                            },
                            'Debit fee': {
                                key: 'debit-fee',
                                type: 'input',
                                initialValue: getValue('charges.debitFee'),
                                isDisabled: isDisabled || !isEditable,
                                rightAlign: true,

                                validation: formErrors['charges.debitFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.debitFee',
                                        ContractValidationRules.limitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix,
                                overBackground: 'front.background'
                            },
                            'Series fee': {
                                key: 'series-fee',
                                type: 'input',
                                initialValue: getValue('charges.seriesFee'),
                                isDisabled: isDisabled || !isEditable,
                                rightAlign: true,

                                validation: formErrors['charges.seriesFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.seriesFee',
                                        ContractValidationRules.limitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix,
                                overBackground: 'front.background'
                            },
                            '3-D Secure fee': {
                                key: 'threeds-fee',
                                cy: 'threedsFee',
                                type: 'input',
                                initialValue: getValue('charges.threedsFee'),
                                isDisabled: isDisabled || !isEditable,
                                rightAlign: true,
                                validation: formErrors['charges.threedsFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.threedsFee',
                                        ContractValidationRules.limitedFee,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix,
                                overBackground: 'front.background'
                            },
                            'Late capture fee': {
                                key:'late-capture-fee',
                                cy: 'captureLateFee',
                                type: 'input',
                                initialValue: getValue('charges.captureLateFee'),
                                isDisabled: isDisabled || !isEditable,
                                rightAlign: true,
                                validation: formErrors['charges.captureLateFee'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.captureLateFee',
                                        ContractValidationRules.limitedPercentage,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix: '%',
                                overBackground: 'front.background'
                            }
                        }}
                        switchToRowsAt={10000}
                        cellHorizontalTemplate="min-content auto"
                    />
                </CardInset>
            </ContractCard>
        </>
    )
}
