import { createReducer } from 'deox'
import { produce } from 'immer'
import { InitialApplicationInternalRecordState } from './types'
import { ApplicationInternalsRecordActions } from './actions'

export const ApplicationInternalsRecordReducer = createReducer(
    InitialApplicationInternalRecordState,
    (handleAction) => [
        handleAction(ApplicationInternalsRecordActions.FETCH, (state, { payload }) => {
            return produce(state, (draft) => {
                draft[payload.id] = {
                    loadingStatus: 'started',
                    notifications: {
                        at: {},
                        all: [],
                        loadingStatus: 'not-started'
                    }
                }
            })
        }),
        handleAction(ApplicationInternalsRecordActions.STORE, (state, { payload }) => {
            return produce(state, (draft: any) => {
                draft[payload.id] = {
                    loadingStatus: 'done',
                    ...(payload.body || {}),
                    notifications: {
                        ...(draft.at?.[payload.id]?.notifications || {}),
                        loadingStatus: 'started'
                    }
                }
            })
        }),
        handleAction(ApplicationInternalsRecordActions.STORE_NOTIFICATIONS, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft[p.applicationId].notifications.at = p?.notifications?.reduce?.((acc, f) => {
                    acc[f.id] = { ...f }
                    return acc
                }, {} as any)
                draft[p.applicationId].notifications.all = p.notifications?.map?.((f) => f.id)
                draft[p.applicationId].notifications.loadingStatus = 'done'
            })
        }),
        handleAction(ApplicationInternalsRecordActions.STORE_NOTIFICATION, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft[p.applicationId].notifications.at[p.notification.id] = { ...p.notification }
                draft[p.applicationId].notifications.all = draft[p.applicationId]?.notifications?.all
                    ? [
                          ...draft[p.applicationId]?.notifications?.all.filter((f) => f !== p.notification.id),
                          p.notification.id
                      ]
                    : [p.notification.id]
                draft[p.applicationId].notifications.loadingStatus = 'done'
            })
        }),
        handleAction(ApplicationInternalsRecordActions.UNSTORE_NOTIFICATION, (state, { payload: p }) => {
            return produce(state, (draft) => {
                //
                delete draft[p.applicationId].notifications.at[p.notificationId]
                draft[p.applicationId].notifications.all = draft[p.applicationId].notifications.all.filter(
                    (a: string) => a != p.notificationId
                )
            })
        })
    ]
)
