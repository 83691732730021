import { TaskState } from '@/services/taskr'

export const mapTaskState = (state: TaskState) => {
    switch (state) {
        case 'new':
            return 'unprocessed'
        case 'open':
            return 'in_progress'
        default:
            return state
    }
}
