import { startCase } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WebsiteFileTagsExcludingScreenshots } from '../../../store/applicationResources/types'
import { RootState } from '@/store'

export const IMPORTANT_WEBSITE_SCREENSHOTS = [
    'termsAndConditionsCapture',
    'productInformationCapture',
    'contactInformationCapture',
    'homePageCapture',
    'privacyPolicyCapture'
]

export const useRelevantWebsites = (applicationId: string, subsectionIndex: number) => {
    const websiteFiles = useSelector(
        (state: RootState) =>
            state.applicationResources?.data?.forApplication?.[applicationId]?.websites?.fields?.[subsectionIndex]
    )
    const files = useSelector((state: RootState) => state.files.at)

    const screensByCat = useMemo(() => {
        const category = {} as any

        Object.keys(websiteFiles).forEach((fileLabel) => {
            if (WebsiteFileTagsExcludingScreenshots.includes(fileLabel)) return
            const section = IMPORTANT_WEBSITE_SCREENSHOTS.find((i) => fileLabel.toLowerCase().includes(i.toLowerCase()))
            if (section) {
                const sectionKey = startCase(section.replace('Capture', ''))
                category[sectionKey] = [...(category[sectionKey] || []), ...(websiteFiles[fileLabel] || [])]
                return
            }
            category['Others'] = [...(category['Others'] || []), ...(websiteFiles[fileLabel] || [])]
        })

        const dictionary: any = {
            at: category,
            all: Object.keys(category).sort((a, b) => {
                if (a === 'Others') return -1
                if (b === 'Others') return -1
                return a > b ? 1 : -1
            })
        }

        dictionary.all.forEach((c: any) => {
            const newArray = dictionary.at[c].map((fileId: any) => {
                return files[fileId]
            })

            dictionary.at[c] = newArray
        })

        return dictionary
    }, [websiteFiles, files])

    return screensByCat
}
