import React from 'react'
import { Spacer } from '../../../../../../components/layout/spacer'
import { MonitoredChangesState } from '../../../../../../store/tasksCompanyChanges/types'
import { ApplicationCard } from '../ApplicationCard/ApplicationCard'

interface ApplicationCardsProps {
    changes: MonitoredChangesState
    taskId: string
}

export const ApplicationCards: React.FC<ApplicationCardsProps> = (props) => {
    const { changes, taskId } = props

    if (!changes) {
        return null
    }

    const applicationIds = Object.keys(changes)

    return (
        <>
            {applicationIds.map((id) => (
                <React.Fragment key={id}>
                    <ApplicationCard change={changes[id]} taskId={taskId} />
                    <Spacer height={32} />
                </React.Fragment>
            ))}
        </>
    )
}
