import React from 'react'
import { Spacer } from '../../../../../../../components/layout/spacer'
import { Table } from '../../../../../../../components/tables/table'
import { MonitoredChange, MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { useRows } from '../hooks/useRows'

interface ChangesProps {
    taskId: string
    changes: MonitoredChange[]
    applicationData: MonitoredFetchedApplicationData
}

export const Changes: React.FC<ChangesProps> = (props) => {
    const { taskId, changes, applicationData } = props

    const rows = useRows(taskId, changes, applicationData)

    return (
        <>
            <Spacer height={12} width={1} />
            <Table
                background="back.background.strongerI"
                noHighlights
                columnLayout="auto auto auto auto auto auto"
                type="narrow"
                columnsGap={32}
                cols={[
                    { text: 'Status' },
                    { text: 'Section' },
                    { text: 'Field' },
                    { text: 'From' },
                    { text: 'To' },
                    { text: 'Actions', alignRight: true }
                ]}
                rows={rows}
            />
        </>
    )
}
