import React, { ReactElement, useCallback, useMemo } from 'react'

import { SettlementsFilters } from '../../store/settlements/types'
import { TextInputSelectMultiple } from '../forms/textInputSelectMultiple'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'
import { AvailableSettlementCurrencies } from '../../store/settlements/types'

const options = AvailableSettlementCurrencies.reduce((acc, key) => {
    acc[key.toLowerCase()] = key
    return acc
}, {} as any)

export const ListPageFilterSettlementsCurrency = ({
    filters,
    isDark
}: {
    filters: FiltersController<SettlementsFilters>
    isDark: boolean
}): ReactElement => {
    const dictionary = useMemo(() => {
        return options
    }, [])

    const textForItem = useCallback(
        (key) => {
            return dictionary[key]
        },
        [dictionary]
    )

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                settlements_currency: items
            }))
        },
        [filters]
    )

    const optionsList = useMemo(() => {
        return Object.keys(options)
    }, [])

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelectMultiple
                preSelectedItems={filters.get.settlements_currency}
                isHigher={isDark}
                isSeamless
                textForItem={textForItem}
                dropdownId="ListPage.Filters.SettlementCurrency"
                onSelect={onSelect}
                placeholder="-"
                items={optionsList}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                selectionLabel="currency/currencies"
            />
            <SelectedListForListPageFilters<SettlementsFilters>
                filterKey="settlements_currency"
                filters={filters}
                noHolderEmphasize={isDark}
                isDark={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
