import React from 'react'
import { RootStyles, styles } from './styles'
import { cn } from '../utils'
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'

interface RootProps extends RootStyles {
    children: React.ReactNode
    width?: number
    height?: number
}

export const ScrollArea: React.FC<RootProps> = (props) => {
    const { children, width, height } = props

    return (
        <ScrollAreaPrimitive.Root className="overflow-hidden">
            <ScrollAreaPrimitive.Viewport style={{ width, height }}>{children}</ScrollAreaPrimitive.Viewport>
            <ScrollAreaPrimitive.Scrollbar />
            <ScrollAreaPrimitive.Corner />
        </ScrollAreaPrimitive.Root>
    )
}
