import { GATEWAYS_LOAD, GATEWAYS_STORE, GATEWAYS_STORE_NEW, ReducedGatewaysActions } from './actions'
import { GatewaysState, InitialGatewaysState } from './types'

export function GatewaysReducer(state = InitialGatewaysState, action: ReducedGatewaysActions): GatewaysState {
    switch (action.type) {
        case GATEWAYS_LOAD: {
            return {
                ...state,
                gateways: {
                    ...state.gateways,
                    loadingStatus: 'started'
                }
            }
        }
        case GATEWAYS_STORE: {
            return {
                ...state,
                gateways: {
                    at: action.gateways.reduce((acc, g) => {
                        return {
                            ...acc,
                            [g.id]: { ...g }
                        }
                    }, {}),
                    all: action.gateways.map((g) => g.id),
                    loadingStatus: 'done'
                }
            }
        }
        case GATEWAYS_STORE_NEW: {
            return {
                ...state,
                gateways: {
                    at: {
                        ...state.gateways.at,
                        [action.gateway.id]: action.gateway
                    },
                    all: [...state.gateways.all.filter((id) => id != action.gateway.id), action.gateway.id],
                    loadingStatus: 'done'
                }
            }
        }
        default: {
            return state
        }
    }
}
