import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { SchemesIcon } from '../../../components/icons/schemesIcon'
import { Flex } from '../../../components/layout/flex'

export const ApplicationSchemesScreeningPanelSchemeToggle: React.FC<{
    onChange?: (option: any) => void
    selected: any
    hasVisaResults?: boolean
    hasMastercardResults?: boolean
}> = ({ onChange, selected, hasVisaResults, hasMastercardResults }) => {
    return (
        <Card background="back.background.strongerII" shadowLess>
            <CardInset type="verySmall">
                <Flex>
                    {[
                        {
                            label: 'all',
                            value: 'All results'
                        },
                        hasVisaResults
                            ? {
                                  label: 'vmss',
                                  value: <SchemesIcon scheme="visa" />
                              }
                            : undefined,
                        hasMastercardResults
                            ? {
                                  label: 'match',
                                  value: <SchemesIcon scheme="mastercard" />
                              }
                            : undefined
                    ]
                        .filter((f) => f)
                        .map((f) => {
                            return (
                                <SimpleButton
                                    background={f!.label == selected ? 'front.background' : undefined}
                                    onClick={() => {
                                        onChange?.(f!.label)
                                    }}
                                >
                                    <ButtonInset>{f!.value}</ButtonInset>
                                </SimpleButton>
                            )
                        })}
                </Flex>
            </CardInset>
        </Card>
    )
}
