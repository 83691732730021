import { createActionCreator } from 'deox'
import { Inquiries, Inquiry } from './types'

const Prefix = 'APPLICATION_INTERNALS_STAMPING'

export const ApplicationInternalsSchemesScreeningActions = {
    SCREEN: createActionCreator(
        `${Prefix}/SCREEN`,
        (resolve) => (applicationId: string, watcherId: string) => resolve({ applicationId, watcherId })
    ),
    FETCH_INQUIRIES: createActionCreator(
        `${Prefix}/FETCH_INQUIRIES`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    STORE_INQUIRIES: createActionCreator(
        `${Prefix}/STORE_INQUIRIES`,
        (resolve) => (applicationId: string, inquiries: Inquiries) => resolve({ applicationId, inquiries })
    ),
    STORE_INQUIRIES_FAILED: createActionCreator(
        `${Prefix}/STORE_INQUIRIES_FAILED`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    STORE_NEW_INQUIRY: createActionCreator(
        `${Prefix}/STORE_NEW_INQUIRY`,
        (resolve) => (applicationId: string, newInquiry: Inquiry) => resolve({ applicationId, newInquiry })
    ),
    CONCLUDE_INQUIRY: createActionCreator(
        `${Prefix}/CONCLUDE_INQUIRY`,
        (resolve) =>
            (
                applicationId: string,
                inquiryId: string,
                watcherId: string,
                resultIds: string[],
                pastInquiryIds: string[],
                passed: boolean
            ) =>
                resolve({ applicationId, inquiryId, resultIds, watcherId, pastInquiryIds, passed })
    )
}
