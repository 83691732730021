import React, { useCallback, useState } from 'react'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { Icon } from '../../icons/icon'
import { Floater } from '../../layout/floater'
import { FloaterInset } from '../../layout/floaterInset'
import { List } from '../../layout/list'
import { SideBarLink } from './sidebarLink'
import { SidebarLinkHolder } from './sidebarLinkHolder'
import { ThemeType, AvailableThemes } from '../../../hooks/general/useTheme'
import { useDispatch, useSelector } from 'react-redux'
import { InterfaceActions } from '../../../store/interface/actions'
import { RootState } from '@/store'
import { useOnClickOutside } from '../../../hooks/general/useOnClickOutside'

const options = (theme: ThemeType): string => {
    if (theme == 'managerInspired') return 'Inspired by Manager'
    else if (theme === 'darkTheme') return '🌜 Dark Mode'
    else if (theme === 'whiteTheme') return '🌿 Minimalist'
    else throw 'Theme not found'
}
export const SidebarSettingsPanel = () => {
    const selectedTheme = useSelector((state: RootState) => state.interface.theme)
    const [showFloater, setShowFloater] = useState(false)
    const [ref, setRef] = useRefTaker()
    const [floaterRef, setFloaterRef] = useRefTaker()
    const dispatch = useDispatch()

    const handleClick = useCallback(() => {
        setShowFloater((s) => !s)
    }, [])

    const handleHide = useCallback(() => {
        setShowFloater(false)
    }, [])
    useOnClickOutside(floaterRef, handleHide)

    const handleThemeSelection = useCallback(
        (theme: string) => {
            dispatch(InterfaceActions.SWITCH_THEME(theme as ThemeType))
        },
        [dispatch]
    )

    return (
        <>
            <div ref={setFloaterRef}>
                <Floater
                    cardId="Sidebar.CallSettingsPanel"
                    anchor={ref}
                    shouldShow={showFloater}
                    onHide={handleHide}
                    placement="top"
                    cy="filters-menu"
                >
                    <FloaterInset equalPadding padding="medium">
                        <List
                            background="floating.background"
                            alignRight
                            items={{
                                'Interface theme': {
                                    type: 'select',
                                    textForItem: options,
                                    overBackground: 'floating.background',
                                    dropdownId: 'Merchant.AccountsPage.Contracts.ContractPeriod',
                                    items: AvailableThemes,
                                    onSelect: handleThemeSelection,
                                    placeholder: 'Select an interface theme',
                                    noClear: true,
                                    selected: selectedTheme,
                                    isHigher: true,
                                    rightAlign: true
                                }
                            }}
                            switchToRowsAt={10000}
                            rowGap="tiny"
                            cellHorizontalTemplate="auto 150px"
                        />
                    </FloaterInset>
                </Floater>
            </div>
            <SidebarLinkHolder label="Settings" cy="settings">
                <SideBarLink onClick={handleClick}>
                    <div ref={setRef}>
                        <Icon type="sidebarCog" size={16} />
                    </div>
                </SideBarLink>
            </SidebarLinkHolder>
        </>
    )
}
