import { isArray, isObject } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Flex } from '../../../components/layout/flex'
import { Floater } from '../../../components/layout/floater'
import { FloaterInset } from '../../../components/layout/floaterInset'
import { List } from '../../../components/layout/list'
import { useOnClickOutside } from '../../../hooks/general/useOnClickOutside'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { RootState } from '@/store'
import { numberWithCommas } from '../../../utils'
import { MerchantAccountContractsProps } from './Accounts.ID.ContractProps'
import { usePricingSchemes } from './usePricingSchemeDropdownConfig'

const NoDecimalFields = ['paymentPeriod', 'paymentDelay', 'rollingReserveDelay', 'charges.interchange', 'otherTerms']

export function templateToFieldPaths(body: any, acc: any = {}, previousKey?: string) {
    let newAcc: any = acc
    if (isObject(body)) {
        Object.keys(body).forEach((key: string) => {
            const path = `${previousKey ? previousKey + '.' : ''}${key}`
            if (isArray((body as any)[key]) || isObject((body as any)[key])) {
                newAcc = {
                    ...newAcc,
                    ...templateToFieldPaths((body as any)[key], newAcc, path)
                }
            } else {
                newAcc[path] = (body as any)[key]
            }
        })
    }
    return newAcc
}

export const MerchantAccountContractApplyTemplate: React.FC<
    MerchantAccountContractsProps & { form: any; shouldDisableSetupFee?: boolean }
> = ({ contract, draftOrTemplate, form, accountId, shouldDisableSetupFee, executeBatchChanges }) => {
    const [showFloater, setShowFloater] = useState(false)
    const [ref, setRef] = useRefTaker()
    const [anchor, setAnchor] = useRefTaker()
    const [holder, setHolder] = useRefTaker()
    const accountCurrency = useSelector((state: RootState) =>
        accountId ? state.merchantAccounts.at?.[accountId]?.currency : undefined
    )
    const [templates, config] = usePricingSchemes(accountCurrency)
    useOnClickOutside(
        holder,
        useCallback(() => {
            setShowFloater(false)
        }, [])
    )

    const handleApplyContract = useCallback(
        (id: string) => {
            if (!executeBatchChanges) return
            if (draftOrTemplate) {
                const templateChangesByFieldPath = templateToFieldPaths(templates.at[id] as any)
                const changes: any = {}
                const formDataKeys = Object.keys(form.data)

                formDataKeys.forEach((key) => {
                    if (shouldDisableSetupFee && key.includes('setupFee')) return
                    if (NoDecimalFields.includes(key)) changes[key] = templateChangesByFieldPath[key]
                    else changes[key] = numberWithCommas(templateChangesByFieldPath[key])
                })

                ref.value = ''
                setShowFloater(false)
                executeBatchChanges(changes)
            }
        },
        [draftOrTemplate, shouldDisableSetupFee, templates, form.data, ref, executeBatchChanges]
    )

    useEffect(() => {
        ref?.focus()
    }, [showFloater, ref])

    // can't apply to an agreement.. we need a draft contract or a template
    if (contract) return null
    if (!executeBatchChanges) return null

    return (
        <Holder ref={setHolder}>
            <div ref={setAnchor}>
                <SimpleButton
                    background="front.background"
                    onClick={() => {
                        setShowFloater((s) => !s)
                    }}
                >
                    <ButtonInset>Apply a template</ButtonInset>
                </SimpleButton>
            </div>
            <Floater anchor={anchor} shouldShow={showFloater}>
                <FloaterInset padding="small">
                    <Flex grow align="baseline">
                        <List
                            background="front.background"
                            alignRight
                            items={{
                                'Template:': {
                                    type: 'select',
                                    overBackground: 'floating.background',
                                    placeholder: 'Select template',
                                    isHigher: true,
                                    ref: setRef,
                                    onSelect(id) {
                                        handleApplyContract(id)
                                    },
                                    dropdownId: 'Merchant.AccountsPage.Contracts.NewContract',
                                    items: config.items,
                                    textForItem: config.textForItem,
                                    tableLike: config.tableLike
                                }
                            }}
                            switchToRowsAt={10000}
                            cellHorizontalTemplate="min-content auto"
                        />
                    </Flex>
                </FloaterInset>
            </Floater>
        </Holder>
    )
}
const Holder = styled.div`
    display: contents;
`
