import { Icon } from '../../../../../../../components/icons/icon'
import { Flex } from '../../../../../../../components/layout/flex'
import { Spacer } from '../../../../../../../components/layout/spacer'

interface StatusProps {
    status: string
}

export const Status: React.FC<StatusProps> = (props) => {
    const { status } = props

    if (status === 'complete') {
        return (
            <Flex align="center">
                <Icon type="checkmark" color="front.success.color" />
                <Spacer width={6} height={1} />
                Complete
            </Flex>
        )
    }

    if (status === 'in-progress') {
        return (
            <Flex align="center">
                <Icon type="ellipsis-filled" size={13} weight={2.5} color="back.text" />
                <Spacer width={6} height={1} />
                Not updated
            </Flex>
        )
    }

    if (status === 'muted') {
        return (
            <Flex align="center">
                <Icon type="minus" size={16} weight={2} color="back.text" />
                <Spacer width={6} height={1} />
                Muted
            </Flex>
        )
    }

    if (status === 'unknown') {
        return (
            <Flex align="center">
                <Icon type="alert" size={13} color="front.danger.color" />
                <Spacer width={3} height={1} />
                Unknown
            </Flex>
        )
    }

    return null
}
