import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { LabelButton } from '../../components/buttons/labelButton'
import { SyncdFile } from '../../components/files/syncdFile'
import { MessageBox, MessageBoxAction } from '../../components/forms/messageBox'
import { Icon } from '../../components/icons/icon'
import { Spacer } from '../../components/layout/spacer'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import {
    ApplicationsDispatchSendInternalComment,
    ApplicationsDispatchStoreInternalFiles,
    ApplicationsDispatchUnstoreInternalFiles
} from '../../store/applications/actions'
import { RootState } from '@/store'

export const MerchantInternalNotesAction: React.FC<{ selectedTab?: string; id: string }> = ({ selectedTab, id }) => {
    const dispatch = useDispatch()
    const [textarea, setTextarea] = useRefTaker()
    const interactions = useSelector((state: RootState) => state.applications.applications.at[id]?.internalInteractions)

    useEffect(() => {
        textarea?.focus()
    }, [textarea, selectedTab])

    const actions = useMemo(() => {
        const mainAction: MessageBoxAction = {
            label: selectedTab === 'call-notes' ? 'Add call note' : 'Add note',
            action: (watcher, value, reset) => {
                if (!value) return
                dispatch(
                    ApplicationsDispatchSendInternalComment(
                        value,
                        interactions.commentsLink,
                        id,
                        watcher,
                        selectedTab === 'call-notes' ? 'call' : 'message'
                    )
                )
                reset()
            }
        }
        return [mainAction]
    }, [dispatch, interactions, id, selectedTab])

    const fileHandlingMemo = useMemo(() => {
        return {
            withDocumentPrefix: false,
            uploadLink: `applications.applications.at.${id}.filesLink`,
            linkFiles: (files: any) => ApplicationsDispatchStoreInternalFiles(id, files),
            unlinkFiles: (files: any) => ApplicationsDispatchUnstoreInternalFiles(id, files),
            scope: 'private' as const
        }
    }, [id])

    const fileInput = useMemo(() => {
        if (!fileHandlingMemo) return null

        const renderButton = (ref: any) => {
            return (
                <LabelButton htmlFor={ref} background="front.accent.color">
                    <ButtonInset padding="small" noHorizontalPadding>
                        <Icon type="attachment" />
                        <Spacer width={5} />
                        Add internal files
                    </ButtonInset>
                </LabelButton>
            )
        }

        return (
            <SyncdFile
                withDocumentPrefix={fileHandlingMemo.withDocumentPrefix}
                fileLabel="attachment"
                customButton={renderButton}
                scope={fileHandlingMemo.scope}
                sourcePath={fileHandlingMemo.uploadLink}
                linkFiles={fileHandlingMemo.linkFiles}
                cutterBased={false}
                unlinkFiles={fileHandlingMemo.unlinkFiles}
                marginless
                multiple
                cy="file-upload"
            />
        )
    }, [fileHandlingMemo])

    if (selectedTab === 'files') return <BoxHolder>{fileInput}</BoxHolder>
    if (selectedTab === 'call-notes')
        return (
            <BoxHolder>
                <MessageBox
                    escKeyOnceIsEnough
                    placeholder="Add a call note comment"
                    pageId="Merchant.InternalNotes"
                    textareaRef={setTextarea}
                    actions={actions}
                />
            </BoxHolder>
        )

    return (
        <BoxHolder>
            <MessageBox
                escKeyOnceIsEnough
                placeholder="Add an internal note comment"
                pageId="Merchant.InternalNotes"
                textareaRef={setTextarea}
                actions={actions}
            />
        </BoxHolder>
    )
}

const BoxHolder = styled.div`
    position: sticky;
    bottom: 0;
    padding: 15px 10px 25px 10px;
    margin: 0 -10px;

    background-color:;

    background-size: 100px 70px;
    background: ${(p) => p.theme['grunge']};
    border-top: 1px solid rgba(0, 0, 0, 0.033);

    z-index: 1000;
    & textarea {
        box-shadow: ${(p) => p.theme['cardsShadow']} !important;
    }
`
