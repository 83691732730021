import { useReportParams, useReportSection } from '@/features/ongoing-due-diligence'
import { SectionCheck } from '@/services/ongoing-due-diligence'
import React from 'react'
import { Section } from '../../../common/section'

export const CompanyPaymentsAccounts: React.FC = () => {
    const { applicationId } = useReportParams()
    const checks = useReportSection<SectionCheck>('checks')

    const data = checks.company_payment_accounts

    const applicationLink = `/merchant/${applicationId}/application`

    return (
        <Section>
            <Section.Content>
                <Section.Field name="company_payment_accounts" data={data} />
            </Section.Content>
            <Section.Metadata>
                <Section.Link url={applicationLink}>Application</Section.Link>
            </Section.Metadata>
        </Section>
    )
}
