import React, { useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../general/text'
import { Icon } from '../icons/icon'

export const Checkbox: React.FC<{
    label: string
    initialChecked?: boolean
    noMarginBottom?: boolean
    disabled?: boolean
    overrideColor?: string
    onChange?: (e: React.MouseEvent<HTMLDivElement>, checked: boolean) => void
    readOnly?: boolean
    checked?: boolean
    cy?: string
}> = ({
    label,
    initialChecked,
    checked: inheritedCheck,
    disabled,
    overrideColor,
    onChange,
    readOnly,
    cy,
    noMarginBottom
}) => {
    const [checked, setChecked] = React.useState(initialChecked || false)

    const handleChanged = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (readOnly) return

            setChecked((c) => {
                onChange?.(e, !c)
                return !c
            })
        },
        [onChange, readOnly]
    )

    useEffect(() => {
        if (inheritedCheck !== undefined) {
            setChecked(inheritedCheck)
        }
    }, [inheritedCheck])

    return (
        <CheckboxWrapper
            disabled={disabled}
            noMarginBottom={noMarginBottom}
            data-cy="checkbox"
            tabIndex={0}
            isActive={checked}
            overrideColor={overrideColor}
            onClick={disabled ? undefined : handleChanged}
        >
            <Checked isActive={checked}>{checked ? <Icon type="checkmark" size={6} /> : null}</Checked>
            <Label data-cy={cy}>
                <Text>{label}</Text>
            </Label>
        </CheckboxWrapper>
    )
}

const Checked = styled.div<{ isActive?: boolean }>`
    width: 13px;
    height: 13px;
    margin-top: 1px;
    border-radius: 999px;
    flex-shrink: 0;
    border: 1px solid ${(p) => p.theme['front.background.strongerIII']};
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) =>
        p.isActive &&
        css`
            background-color: ${(p) => p.theme['front.accent.color']};
            border-color: ${(p) => p.theme['front.accent.color']};
            color: ${(p) => p.theme['front.background']};
        `}
`

const CheckboxWrapper = styled.div<{
    noMarginBottom?: boolean
    overrideColor?: string
    disabled?: boolean
    isActive?: boolean
}>`
    ${(p) =>
        !p.noMarginBottom &&
        css`
            margin-bottom: 5px;
        `}

    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${(p) => p.theme['front.text']};

    &:hover {
        color: ${(p) => p.theme['front.text.subtlerI']};
    }

    &:active {
        color: ${(p) => p.theme['front.text.subtlerII']};
    }

    ${(p) =>
        p.overrideColor &&
        css`
            ${p.disabled &&
            !p.isActive &&
            css`
                cursor: default !important;

                * {
                    cursor: default !important;
                }

                > *:first-child {
                    opacity: 0.3;
                }
            `}
        `}
    ${(p) =>
        p.overrideColor &&
        css`
            color: ${p.theme[p.overrideColor]} !important;
        `}
`

const Label = styled.span`
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
    span {
        display: block;
        line-height: 1.4;
        cursor: pointer;
    }
`
