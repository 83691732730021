import React from 'react'
import { Styles, styles } from './styles'
import { cn } from '../utils'

interface ContainerProps extends Styles {
    children: React.ReactNode
}

export const Container: React.FC<ContainerProps> = (props) => {
    const { children, size } = props

    const classnames = cn(styles({ size }))

    return <div className={classnames}>{children}</div>
}
