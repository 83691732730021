import React from 'react'
import { ChevronDownIcon as RadixChevronDownIcon } from '@radix-ui/react-icons'
import { Styles } from './styles'
import { Icon } from './Icon'

type IconProps = Styles

export const ChevronDownIcon: React.FC<IconProps> = (props) => {
    return (
        <Icon {...props}>
            <RadixChevronDownIcon />
        </Icon>
    )
}
