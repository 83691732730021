import React from 'react'
import { numberWithCurrency } from '@/utils'
import { Posting, Voucher } from '@/services/reconciliation'
import { Button } from '@/design-system'

interface LoadHigherAmountsButtonProps {
    data: Voucher | Posting
    loadHigherAmounts: boolean
    onClick: () => void
}

export const LoadHigherAmountsButton: React.FC<LoadHigherAmountsButtonProps> = (props) => {
    const { data, loadHigherAmounts, onClick } = props
    const amount = numberWithCurrency(data.currency, data.amount)
    const currency = data.currency
    const action = loadHigherAmounts ? 'Hide' : 'Load'

    return (
        <Button variant="solid" color="primary" onClick={onClick} width="full">
            {action} resources with amount higher than {amount} {currency}
        </Button>
    )
}
