import { useMemo, useRef } from 'react'
import { Flex } from '../components/layout/flex'
import { Text } from '../components/general/text'
import { PaymentCard } from '../store/transactions/types'
import { Tooltip } from '../components/general/tooltip'
import { TooltipTrigger } from '../pages/Transactions/TransactionsFlagWithTooltip'
import countries from 'i18n-iso-countries'

export const PaymentCardString: React.FC<{ card: PaymentCard }> = ({ card }) => {
    const cardType = card.type.substring(0, 1).toUpperCase()
    const countryRef = useRef<any>()

    const CAN = useMemo(() => {
        const first4 = `${card.bin}`.substring(0, 4)
        const next2 = `${card.bin}`.substring(4, 6)
        return `${first4} ${next2}** **** ${card.last4}`
    }, [card])

    return (
        <Flex>
            <Text mono noWrap color="front.text.subtlerI">
                {`${cardType}, `}
            </Text>
            <Text mono noWrap color="front.text.subtlerI">
                <TooltipTrigger ref={countryRef}>
                    {card.country ? `${card.country},\u00A0` : `\u00A0\u00A0\u00A0\u00A0`}
                </TooltipTrigger>
            </Text>
            {card.country ? (
                <Tooltip element={countryRef} tooltip tooltipTiming="instant">
                    {countries.getName(card.country.toUpperCase(), 'en')}
                </Tooltip>
            ) : undefined}
            <Text mono noWrap>
                {CAN}
            </Text>
        </Flex>
    )
}

export const getPaymentCardHumanized = (card: PaymentCard): string => {
    return `*** ${card.last4}`
}

export const RenderIfExists = (v: any): any => {
    return v || '-'
}

export const getWebsiteUrl = (websiteUrl: string): string => {
    return websiteUrl.includes('//') ? websiteUrl : `//${websiteUrl}`
}
export const getWebsiteLabel = (websiteUrl: string): string => {
    if (!websiteUrl) return 'Unspecified URL'
    let newUrl = websiteUrl
    if (websiteUrl[websiteUrl.length - 1] == '/') newUrl = newUrl.substring(0, websiteUrl.length - 1)
    return newUrl.toLowerCase().replace('www.', '').replace('http://', '').replace('https://', '')
}

export const getWebsiteFromFileLabel = (label: string): string | undefined => {
    return label.split(/\.(.*)/s)[1]
}

export const getCategoryFromFileLabel = (label: string): string => {
    return label.split(".")[0].replace('capture', '')
}

export function formatWebsites(websites?: (string | undefined)[]): any {
    return (websites || [])
        .map((websiteUrl) => {
            if (!websiteUrl) return null
            return {
                url: getWebsiteUrl(websiteUrl),
                label: getWebsiteLabel(websiteUrl)
            }
        })
        .filter((f) => f) as {
        url: string
        label: string
    }[]
}

export const withHttp = (url: string) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)

export const withoutSchemeAndSubdomain = (url: string) => {
    return url.replace(/^(https?:\/\/)?(www\.)?/, '')
}
