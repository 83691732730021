import React, { MutableRefObject, useCallback, useEffect } from 'react'

import { useNamedWatcherWithContext } from '../../hooks/general/useWatcher'
import { WatcherID } from '../../store/watcher/types'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { ClueDirection } from '../hotkeys/hotkeyClue'
import { ButtonLoadingCover } from './buttonLoadingCover'
import { SimpleButton } from './simpleButton'

export function WatcherButton({
    children,
    color,
    background,
    onClick,
    buttonRef,
    isDisabled,
    cy,
    predefinedWatcher,
    hotkeys,
    grow,
    tooltip,
    onSuccess,
    onFailure,
    hotkeysScope,
    hotkeysClueDirection
}: {
    children: any
    background?: React.ComponentProps<typeof SimpleButton>['background']
    color?: React.ComponentProps<typeof SimpleButton>['color']
    buttonRef?: (ref: MutableRefObject<any>) => void
    cy?: string
    isDisabled?: boolean
    predefinedWatcher?: WatcherID
    onClick: (e: any, generatedId: string) => void
    hotkeysScope?: MODAL_ID
    hotkeys?: string
    onSuccess?: (ctx?: any) => void
    onFailure?: () => void
    grow?: boolean
    tooltip?: string
    hotkeysClueDirection?: ClueDirection
}): JSX.Element {
    const [watcher, generatedID, ctx] = useNamedWatcherWithContext(predefinedWatcher)
    const [ref, setRef] = useRefTaker()

    useEffect(() => {
        buttonRef?.(ref)
    }, [buttonRef, ref])

    useEffect(() => {
        if (watcher === 'success') onSuccess?.(ctx)
        else if (watcher === 'fail') {
            onFailure?.()
        }
    }, [watcher, onSuccess, ctx, onFailure])

    const handleClick = useCallback(
        (e) => {
            if (watcher === 'started') return
            onClick(e, generatedID)
        },
        [generatedID, watcher, onClick]
    )

    return (
        <SimpleButton
            background={background}
            color={color}
            onClick={handleClick}
            buttonRef={setRef}
            isDisabled={isDisabled}
            grow={grow}
            tooltip={tooltip}
            cy={cy}
            hotkeysScope={hotkeysScope}
            hotkeys={hotkeys}
            hotkeysClueDirection={hotkeysClueDirection}
        >
            <ButtonLoadingCover watcher={watcher} background={background} color={color}>
                {children}
            </ButtonLoadingCover>
        </SimpleButton>
    )
}
