import React from 'react'
import { styles } from './styles'
import { DayPicker } from 'react-day-picker'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { da } from 'date-fns/locale'

const components = {
    Chevron: (props) => {
        if (props.orientation === 'left') {
            return <ChevronLeftIcon className="h-4 w-4" />
        } else {
            return <ChevronRightIcon className="h-4 w-4" />
        }
    }
}

interface CalendarProps {
    selected?: Date
    onSelect?: (date?: Date) => void
}

export const Calendar: React.FC<CalendarProps> = (props) => {
    const { selected, onSelect } = props

    const classnames = 'p-3'

    const handleOnSelect = (date?: Date) => {
        onSelect?.(date)
    }

    return (
        <DayPicker
            locale={da}
            showOutsideDays
            selected={selected}
            onSelect={handleOnSelect}
            mode="single"
            className={classnames}
            classNames={{
                months: styles.months,
                month: styles.month,
                month_caption: styles.monthCaption,
                caption_label: styles.captionLabel,
                nav: styles.nav,
                button_previous: styles.buttonPrevious,
                button_next: styles.buttonNext,
                month_grid: styles.monthGrid,
                weekdays: styles.weekdays,
                weekday: styles.weekday,
                week: styles.week,
                day: styles.day,
                selected: styles.selected,
                day_button: styles.dayButton,
                today: styles.today,
                outside: styles.outside,
                disabled: styles.disabled,
                hidden: styles.hidden
            }}
            components={components}
        />
    )
}
