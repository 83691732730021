import { Card, Text } from '@/design-system'
import { useBankAccountMismatch } from '@/hooks/application/useBankAccountMismatch/useBankAccountMismatch'
import React from 'react'
import { useTheme } from 'styled-components'

interface ApplicationBankAccountMismatchWarningProps {
    applicationId: string
}

const ApplicationBankAccountMismatchWarning: React.FC<ApplicationBankAccountMismatchWarningProps> = (props) => {
    const { applicationId } = props

    const theme = useTheme()

    const hasMismatch = useBankAccountMismatch(applicationId)

    const textColor = theme.name === 'darkTheme' ? 'white' : 'danger'
    const textShade = theme.name === 'darkTheme' ? undefined : '700'

    if (!hasMismatch) {
        return null
    }

    return (
        <Card color="danger" testid="bank-account-mismatch-card">
            <Card.Body>
                <Text lineHeight="loose" weight="medium" color={textColor} shade={textShade}>
                    Bank details in this application is not present on an account.
                </Text>
                <Text color={textColor} shade={textShade}>
                    This might result in settlements being payed out to the wrong bank account. Please investigate and
                    make the needed changes to the bank details.
                </Text>
            </Card.Body>
        </Card>
    )
}

export default ApplicationBankAccountMismatchWarning
