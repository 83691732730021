/* eslint-disable max-classes-per-file */
import moment from 'moment'
import { Action } from 'redux'
import { spawn } from 'redux-saga/effects'
import { delay, put, select } from 'typed-redux-saga'

import { GET } from '../../generators/networking'
import { db } from '../dexie'
import { RootState } from '@/store'
import {
    SagasForUserAccounts,
    UserAccountsActionGetAccounts,
    UserAccountsActionLoadAccount,
    UserAccountsActionQuery,
    UserAccountsDispatchLoadAccount,
    UserAccountsDispatchStoreAccount
} from './actions'

export class UserAccountsSagas implements SagasForUserAccounts {
    *getAccounts(action: UserAccountsActionGetAccounts) {
        const accounts: any = yield* select((state: RootState) => {
            return state.userAccounts.at
        })

        // eslint-disable-next-line no-restricted-syntax
        for (const id of action.ids) {
            const isMerchant = false

            if (!id) continue

            // If it's not already loaded into the store (store clear on browser refresh)
            if (!accounts[id]) {
                // Load it from the IndexedDB
                const cachedAccountData: { expires?: any } = yield db.identity.get(id)

                if (!cachedAccountData) {
                    // If we don't have it in cache, we load it from the server
                    yield put(UserAccountsDispatchLoadAccount(id, action.extras[id]?.isMerchant))
                } else if (
                    // If it's expired, we delete it from DB and then send a load request again
                    cachedAccountData?.expires &&
                    moment(cachedAccountData.expires).isBefore(moment())
                ) {
                    yield db.identity.delete(id)
                    yield put(UserAccountsDispatchLoadAccount(id, action.extras[id]?.isMerchant))
                } else {
                    // Otherwise we just store it from the local DB
                    yield put(UserAccountsDispatchStoreAccount(id, cachedAccountData))
                }
            } else if (accounts[id].loadingStatus != 'started') {
                yield put(UserAccountsDispatchStoreAccount(id, accounts[id].data))
            }
        }
    }

    *queryAccounts(action: UserAccountsActionQuery) {
        const queryString = Object.keys(action.query)
            .map((k: any) => `${k}:${(action.query as any)[k]}`)
            .join(' ')

        const response: { accounts: any[] } = yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/accounts?query=${encodeURIComponent(queryString)}`,
            include: ['self', 'application'],
            errorText: `Failed to query merchant accounts by MID.`
        })

        if (response.accounts[0])
            yield put(UserAccountsDispatchStoreAccount(response.accounts[0].id, response.accounts[0]))
    }

    *loadAccount(action: UserAccountsActionLoadAccount) {
        let url = `${import.meta.env.VITE_API_ROOT}/users`

        if (action.isMerchant) url = `${import.meta.env.VITE_API_ROOT}/accounts`

        const user: {
            id: string
        } = yield GET({
            url: `${url}/${action.id}`,
            include: ['self', 'application'],
            ignoreErrorCodes: [404],
            errorText: undefined
        })

        if (user && !user.id) {
            user.id = action.id
        }
        // We want the results to be cached for 2 weeks (1 month in the old manager)

        if (user) {
            const result = (yield db.identity.put({
                id: action.id,
                expires: moment().add(2, 'weeks').toDate(),
                ...(user as any)
            })) as boolean

            if (result) {
                return yield* put(UserAccountsDispatchStoreAccount(action.id, user))
            }
        }

        return yield* put(UserAccountsDispatchStoreAccount(action.id, null))
    }
}

interface BatchableAction {
    watcher: string
    id: string
}

interface BatcherState {
    dataForId: {
        [key: string]: {
            extras: any
        }
    }
    ids: Set<string>
    action?: any
    task?: any
}

export class RequestBatcher {
    public state: BatcherState

    constructor(action: (ids: string[], extras?: any) => Action) {
        this.state = {
            ids: new Set(),
            action,
            dataForId: {},
            task: undefined
        }
    }

    static *fetch(b: BatcherState) {
        yield delay(50)
        const idsArray = Array.from(b.ids)
        const extrasForId: { [key: string]: any } = {}

        for (const id in b.dataForId) {
            if (b.dataForId.hasOwnProperty(id)) {
                extrasForId[id] = { ...b.dataForId[id].extras }
            }
        }

        yield put(b.action(idsArray, extrasForId))
        b.ids.clear()
        b.dataForId = {}
        b.task = undefined
    }

    static *batchRequests(b: BatcherState, batchedAction: { type: string; id: string; extraData?: any }) {
        if (!b.dataForId[batchedAction.id]) {
            b.dataForId[batchedAction.id] = {
                extras: {}
            }
        }

        if (batchedAction.extraData) {
            b.dataForId[batchedAction.id].extras = batchedAction.extraData
        }

        if (!b.ids.has(batchedAction.id)) {
            b.ids.add(batchedAction.id)

            if (!b.task) {
                b.task = (yield spawn(RequestBatcher.fetch, b)) as { [key: string]: any }
            }
        }
    }
}
