import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'
import { PersonMerchantData } from '../common/person-merchant-data'

export const ManagementDataItems: React.FC = () => {
    const merchantData = useReportMerchantData()

    return (
        <>
            {merchantData.people.map((person, i) => {
                const last = i === merchantData.people.length - 1
                const title = `${i + 1}. Person`

                return (
                    <React.Fragment key={person.id}>
                        <PersonMerchantData title={title} person={person} />
                        {!last && <Section.Spacer size="l" />}
                    </React.Fragment>
                )
            })}
        </>
    )
}
