import { createReducer } from 'deox'
import { produce } from 'immer'
import { ApplicationDataProvidersActions } from './actions'
import {
    DataPorviderStateAssignedAndReady,
    DataProviderStateAssigned,
    InitialApplicationDataProvidersState
} from './types'

export const ApplicationDataProvidersReducer = createReducer(InitialApplicationDataProvidersState, (handleAction) => [
    handleAction(ApplicationDataProvidersActions.FETCH, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (!state.forApplication[p.applicationId])
                draft.forApplication[p.applicationId] = {
                    state: 'loading',
                    matches: {
                        loadingStatus: 'not-started',
                        list: []
                    }
                }
            else {
                draft.forApplication[p.applicationId] = {
                    ...draft.forApplication[p.applicationId]
                }
            }
        })
    ),
    handleAction(ApplicationDataProvidersActions.BACK_TO_SELECTION, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (draft.forApplication[p.applicationId].state == 'skipped')
                draft.forApplication[p.applicationId].state = 'unassigned'
            else throw new Error("Can't go back outside of the skipped state")
        })
    ),
    handleAction(ApplicationDataProvidersActions.SKIP_SELECTION, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (draft.forApplication[p.applicationId].state == 'unassigned') {
                if ((draft.forApplication[p.applicationId] as DataProviderStateAssigned).linked) {
                    if ((draft.forApplication[p.applicationId] as DataPorviderStateAssignedAndReady).computed) {
                        draft.forApplication[p.applicationId].state = 'assigned-and-ready'
                    } else {
                        draft.forApplication[p.applicationId].state = 'assigned'
                    }
                } else {
                    draft.forApplication[p.applicationId].state = 'skipped'
                }
            } else throw new Error("Can't skip outside of the unassigned state")
        })
    ),
    handleAction(ApplicationDataProvidersActions.SEARCH_FOR_MATCHES, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (!draft.forApplication?.[p.applicationId]) draft.forApplication[p.applicationId] = { state: 'loading' }
            ;(draft.forApplication[p.applicationId] as any).matches = {
                loadingStatus: 'started',
                list: []
            }
        })
    ),
    handleAction(ApplicationDataProvidersActions.STORE_SEARCH_RESULTS, (state, { payload: p }) =>
        produce(state, (draft) => {
            ;(draft.forApplication[p.applicationId] as any).matches = {
                loadingStatus: 'done',
                list: p.results
            }
        })
    ),
    handleAction(ApplicationDataProvidersActions.STORE, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (!draft.forApplication[p.applicationId]) draft.forApplication[p.applicationId] = {} as any

            if (p.data && p.entityId) {
                draft.forApplication[p.applicationId] = {
                    state: 'assigned',
                    linked: p.data,
                    entityId: p.entityId,
                    mutedConflicts: p.mutedConflicts
                }
            } else {
                draft.forApplication[p.applicationId].state = 'unassigned'
            }
        })
    ),
    handleAction(ApplicationDataProvidersActions.STORE_CONFLICTS_AND_OWNERSHIP_SHARES, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (
                draft.forApplication[p.applicationId].state !== 'assigned' &&
                draft.forApplication[p.applicationId].state !== 'assigned-and-ready'
            )
                return
            ;(draft.forApplication[p.applicationId] as DataPorviderStateAssignedAndReady) = {
                ...(draft.forApplication[p.applicationId] as DataPorviderStateAssignedAndReady),
                computed: p.conflictsAndOwnershipShares,
                state: 'assigned-and-ready'
            }
        })
    ),
    handleAction(ApplicationDataProvidersActions.STORE_MUTED_CONFLICTS, (state, { payload: p }) => {
        if (
            state.forApplication[p.applicationId].state !== 'assigned' &&
            state.forApplication[p.applicationId].state !== 'assigned-and-ready'
        )
            throw new Error("Can't store muted conflicts outside of assigned and assigned-and-ready states.")
        return produce(state, (draft) => {
            ;(draft.forApplication[p.applicationId] as DataProviderStateAssigned).mutedConflicts = p.mutedConflicts
        })
    }),
    handleAction(ApplicationDataProvidersActions.UNLINK, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.forApplication[p.applicationId].state = 'unassigned'
        })
    )
])
