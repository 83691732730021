import React, { useMemo, useCallback, useEffect, useRef, createRef, useState } from 'react'
import { useSelectable } from '../../hooks/general/useSelectable'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { CardStyles } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { List } from '../../components/layout/list'
import { PageHeader } from '../../components/layout/pageHeader'
import { CallStatus } from '../../components/general/callStatus'
import { ApplicationStatus } from '../../components/general/applicationStatus'
import { Table } from '../../components/tables/table'
import { Flex } from '../../components/layout/flex'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { Text } from '../../components/general/text'
import { LoaderView } from '../../components/loaders/loader'
import { Paginator } from '../../components/general/paginator'
import { Keyface } from '../../components/hotkeys/keyface'
import { SearchState } from '../../store/search/types'
import { CallResState, CallsState, CallsFilters, CallSummary } from '../../store/calls/types'
import { RootState } from '@/store'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { TextInputSearch } from '../../components/forms/textInputSearch'
import { ToastsDispatchPush } from '../../store/toasts/actions'
import {
    CallDispatchFindResources,
    CallDispatchLoadResources,
    CallDispatchAddResource,
    CallDispatchDeleteResource,
    CallDispatchClearResources,
    CallsDispatchLoadSummaries
} from '../../store/calls/actions'
import { SearchDispatchClearResults, SearchDispatchFindResults } from '../../store/search/actions'
import { useLocation, useNavigate } from 'react-router-dom'

const queryParams = ['search', 'search_page', 'search_per_page']

const clearResources = () => CallDispatchClearResources()

export const Call: React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const searchedForQuery = useRef<any>({})
    const clearedRes = useRef(false)
    const [inputValue, setInputValue] = useState<string>()

    const mercuryRes: CallResState['mercuryRes'] = useSelector((state: RootState) => state.callRes.mercuryRes)
    const mapiRes: CallResState['mapiRes'] = useSelector((state: RootState) => state.callRes.mapiRes)
    const results: SearchState['results'] = useSelector((state: RootState) => state.search.results)

    const activeCalls: CallsState['callsSummaries'] = useSelector((state: RootState) => state.calls.callsSummaries)
    const phone = location.pathname.split('/').pop()
    const [currentCall, setCurrentCall] = useState<CallSummary>()

    useEffect(() => {
        if (!phone) return
        if (!clearedRes.current) {
            clearedRes.current = true
            dispatch(clearResources?.())
            return
        }
        if (mapiRes?.loadingStatus === 'not-started') dispatch(CallDispatchFindResources(phone, 1, 20))
        if (mercuryRes?.loadingStatus === 'not-started') dispatch(CallDispatchLoadResources(phone))
        const filter: CallsFilters = { status: 'active' }
        if (activeCalls?.loadingStatus === 'not-started') dispatch(CallsDispatchLoadSummaries(filter))
    }, [mapiRes, activeCalls, phone, dispatch, clearedRes])

    useEffect(() => {
        activeCalls.all.map((id: string) => {
            const t = activeCalls.at[id]
            if (t.phoneNumber) {
                if (t.phoneNumber.toString() == phone) {
                    setCurrentCall(t)
                }
            }
        })
    }, [currentCall, setCurrentCall, activeCalls, phone])

    const rows = useMemo(() => {
        return results.all.reduce((acc, r) => {
            acc.push(r)
            if (results.at[r].accounts?.length > 0) acc.push(`${r}_ACCOUNTS`)
            return acc
        }, [] as string[])
    }, [results])
    const rows2 = useMemo(() => {
        return mapiRes.all.reduce((acc, r) => {
            acc.push(r)
            if (mapiRes.at[r].accounts?.length > 0) acc.push(`${r}_ACCOUNTS`)
            return acc
        }, [] as string[])
    }, [mapiRes])
    const rows3 = useMemo(() => {
        return mercuryRes.all.reduce((acc, r) => {
            acc.push(r)
            return acc
        }, [] as string[])
    }, [mercuryRes])

    const rowsRefs = useMemo(() => {
        return rows.map(() => createRef())
    }, [rows]) as any
    const rowsRefs2 = useMemo(() => {
        return rows2.map(() => createRef())
    }, [rows2]) as any
    const rowsRefs3 = useMemo(() => {
        return rows3.map(() => createRef())
    }, [rows3]) as any

    const [query, setQuery] = useQueryParams(queryParams, undefined)

    const shouldDisplayEnterToSearchText = useMemo(() => {
        if (!query) return false
        if (searchedForQuery.current.search !== inputValue) return true
        return false
    }, [inputValue, query])

    const onAccountClick = useCallback(
        (applicationId, isAccount?) => {
            if (isAccount) {
                navigate(`/merchant/${applicationId}/accounts`)
            } else {
                navigate(`/merchant/${applicationId}/summary`)
            }
        },
        [navigate]
    )

    const onSelectionMade = useCallback(
        (applicationId, isAccount?) => {
            if (currentCall) {
                dispatch(CallDispatchAddResource(currentCall.internalCallId, applicationId))
            }
            onAccountClick(applicationId, isAccount)
        },
        [rows, currentCall, phone, onAccountClick]
    )

    const { resetHighlight, onMouseClick, highlighted } = useSelectable(
        rows,
        'SearchPage',
        onSelectionMade,
        undefined,
        undefined
    )

    const search = useCallback(
        (newQuery) => {
            resetHighlight()
            searchedForQuery.current = newQuery
            if (newQuery?.search) {
                dispatch(SearchDispatchFindResults(newQuery?.search, newQuery?.search_page, newQuery?.search_per_page))
            } else {
                dispatch(SearchDispatchClearResults())
            }
        },
        [resetHighlight, dispatch]
    )

    useEffect(() => {
        if (JSON.stringify(query) !== JSON.stringify(searchedForQuery.current)) {
            search(query)
        }
    }, [query, search])

    const handleRemoveClick = useCallback(
        (resId: string) => {
            if (currentCall) {
                dispatch(CallDispatchDeleteResource(currentCall.internalCallId))
            } else {
                dispatch(
                    ToastsDispatchPush(
                        `Cannot remove the application as it was not added during ongoing call`,
                        'error',
                        `call-remove`
                    )
                )
            }
        },
        [activeCalls, phone, currentCall]
    )

    const renderedPagination = useMemo(() => {
        return (
            results.pagination && (
                <PaginatorHolder onClick={(e) => e.stopPropagation()}>
                    <Paginator
                        pagination={results.pagination}
                        onPageChanged={(page) => {
                            setQuery((q: any) => ({ ...q, search_page: page }))
                        }}
                    />
                </PaginatorHolder>
            )
        )
    }, [results.pagination, setQuery])

    const renderedPagination2 = useMemo(() => {
        return (
            mapiRes.pagination && (
                <PaginatorHolder onClick={(e) => e.stopPropagation()}>
                    <Paginator
                        pagination={mapiRes.pagination}
                        onPageChanged={(page) => {
                            setQuery((q: any) => ({ ...q, page: page }))
                        }}
                    />
                </PaginatorHolder>
            )
        )
    }, [mapiRes.pagination, setQuery])

    const cardStack = useMemo(() => {
        return rows.map((r, index) => {
            const applicationId = r.replace('_ACCOUNTS', '')
            const application = results.at[applicationId]

            if (mapiRes.all.includes(application.id)) return <></>
            if (mercuryRes.all.includes(application.id)) return <></>
            if (r.includes('_ACCOUNTS')) {
                return (
                    <AccountsCard
                        key={`${applicationId}-searched-accounts`}
                        ref={rowsRefs[index]}
                        onClick={() => onAccountClick(applicationId, true)}
                    >
                        <CardInset>
                            <Table
                                background="front.background"
                                cols={[{ text: 'Status' }, { text: 'MID' }, { text: 'Currency' }]}
                                columnLayout="160px 230px auto"
                                noHeaderSeparator
                                rows={application.accounts.map((account) => {
                                    return {
                                        type: 'normal',
                                        noAction: true,
                                        key: `${applicationId}-${account.id}`,
                                        items: [
                                            { node: <Flex align="center">{account.state}</Flex> },
                                            { node: <Flex align="center">{account.merchantId}</Flex> },
                                            { node: <Flex align="center">{account.currency}</Flex> }
                                        ]
                                    }
                                })}
                                type="narrow"
                                emptyText="No resources found matching the phone number."
                            />
                        </CardInset>
                    </AccountsCard>
                )
            }

            const items: any = {
                State: {
                    type: 'custom',
                    node: <ApplicationStatus status={application.publicState} />
                },
                Company: {
                    type: 'custom',
                    node: <b>{application.company.name}</b>
                },
                Country: <p>{application.company.country}</p>
            }

            let template = '120px 200px 120px'

            if (application.websites?.length) {
                template += ' minmax(auto, 180px)'
                items.Websites = {
                    type: 'custom',
                    node: <WebsitesHolder>{application.websites.map((w) => w.url).join(', ')}</WebsitesHolder>
                }
            }

            return (
                <ApplicationCard
                    ref={rowsRefs[index]}
                    onClick={() => onSelectionMade(applicationId)}
                    key={`${applicationId}-searched-application`}
                >
                    <CardInset>
                        <List background="front.background" items={items} switchToRowsAt={400} template={template} />
                    </CardInset>
                </ApplicationCard>
            )
        })
    }, [rows, onSelectionMade, rowsRefs, results, highlighted, mapiRes, mercuryRes])

    const searchBar = useMemo(() => {
        const handleSearchInputChange = (e: any, value: string) => {
            setInputValue(value)
        }

        return (
            <SearchInputHolder key="search-section">
                <SearchInputWrapper className="PREVENT_MODAL_CLOSE">
                    <TextInputSearch
                        placeholder="Find application by name, MID etc."
                        onChange={handleSearchInputChange}
                        onEnter={(e, val) => {
                            setQuery((q: any) => ({
                                ...q,
                                search: val,
                                search_page: 1
                            }))
                        }}
                    />
                </SearchInputWrapper>
            </SearchInputHolder>
        )
    }, [query.search, setQuery])

    const searchedList = useMemo(() => {
        if (results.loadingStatus === 'done') {
            if (shouldDisplayEnterToSearchText)
                return (
                    <HelpText>
                        Press <Keyface k="enter" /> to search.
                    </HelpText>
                )
            if (results.all.length === 0)
                return <HelpText>There are no applications matching these criterias.</HelpText>
            return (
                <ResultsWrapper>
                    <HelpText>
                        By selecting the application, you will add it to the list of resources associated with phone
                        number {phone}
                    </HelpText>
                    {cardStack}
                    {renderedPagination}
                </ResultsWrapper>
            )
        }
        if (results.loadingStatus === 'started') return <LoaderView type="l" overBackground="overlay.background" />
    }, [results, shouldDisplayEnterToSearchText, cardStack, renderedPagination])

    const mainCard = useMemo(() => {
        const template = '80px 200px 140px 400px'
        let status = 'inactive'
        activeCalls.all.map((id: string) => {
            const t = activeCalls.at[id]
            if (t.phoneNumber.toString() === phone) {
                if (t.startTime !== 'N/A' && t.pickedTime === 'N/A') status = 'incoming'
                if (t.pickedTime !== 'N/A' && t.endTime === 'N/A') status = 'answered'
            }
        })
        const items: any = {
            Phone: {
                type: 'custom',
                node: <b>{phone}</b>
            },
            Status: {
                type: 'custom',
                node: <CallStatus status={status} />
            }
        }
        return (
            <>
                <CallCard key={`${phone}-card`}>
                    <CardInset>
                        <List background="front.background" items={items} switchToRowsAt={400} template={template} />
                    </CardInset>
                </CallCard>
            </>
        )
    }, [activeCalls, phone])

    const accountsList = useMemo(() => {
        if (mapiRes.loadingStatus === 'done') {
            return rows2.map((r, index) => {
                const applicationId = r.replace('_ACCOUNTS', '')
                const application = mapiRes.at[applicationId]

                if (mercuryRes.all.includes(application.id)) return <></>
                if (r.includes('_ACCOUNTS')) {
                    return (
                        <AccountsCard
                            key={`${applicationId}-mapi-accounts`}
                            ref={rowsRefs2[index]}
                            onClick={() => onAccountClick(applicationId, true)}
                        >
                            <CardInset>
                                <Table
                                    background="front.background"
                                    cols={[{ text: 'Status' }, { text: 'MID' }, { text: 'Currency' }]}
                                    columnLayout="160px 230px auto"
                                    noHeaderSeparator
                                    rows={application.accounts.map((account) => {
                                        return {
                                            type: 'normal',
                                            noAction: true,
                                            key: `${applicationId}-${account.id}`,
                                            items: [
                                                {
                                                    node: <Flex align="center">{account.state}</Flex>
                                                },
                                                {
                                                    node: <Flex align="center">{account.merchantId}</Flex>
                                                },
                                                {
                                                    node: <Flex align="center">{account.currency}</Flex>
                                                }
                                            ]
                                        }
                                    })}
                                    type="narrow"
                                    emptyText="No resources found."
                                />
                            </CardInset>
                        </AccountsCard>
                    )
                }

                const items: any = {
                    State: {
                        type: 'custom',
                        node: <ApplicationStatus status={application.publicState} />
                    },
                    Company: {
                        type: 'custom',
                        node: <b>{application.company.name}</b>
                    },
                    Country: application.company.country
                }

                let template = '120px 200px 120px'

                if (application.websites?.length) {
                    template += ' minmax(auto, 180px)'
                    items.Websites = {
                        type: 'custom',
                        node: <WebsitesHolder>{application.websites.map((w) => w.url).join(', ')}</WebsitesHolder>
                    }
                }

                return (
                    <ApplicationCard
                        ref={rowsRefs2[index]}
                        onClick={() => onAccountClick(applicationId)}
                        key={`${applicationId}-mapi-application`}
                    >
                        <CardInset>
                            <List
                                background="front.background"
                                items={items}
                                switchToRowsAt={400}
                                template={template}
                            />
                        </CardInset>
                    </ApplicationCard>
                )
            })
        }
        if (mapiRes.loadingStatus === 'started') return <LoaderView type="m" overBackground="overlay.background" />
    }, [rows2, onAccountClick, rowsRefs2, mapiRes, highlighted, mercuryRes])

    const accountsList2 = useMemo(() => {
        if (mercuryRes.loadingStatus === 'done') {
            return rows3.map((r, index) => {
                const applicationId = r.replace('_ACCOUNTS', '')
                const application = mercuryRes.at[applicationId]

                const itemsX: any = {
                    State: {
                        type: 'custom',
                        node: <ApplicationStatus status={application.publicState} />
                    },
                    Company: {
                        type: 'custom',
                        node: <b>{application.company.name}</b>
                    },
                    Country: application.company.country
                }

                let template = '120px 200px 120px'

                if (application.websites?.length) {
                    template += ' minmax(auto, 180px)'
                    itemsX.Websites = {
                        type: 'custom',
                        node: <WebsitesHolder>{application.websites.map((w) => w.url).join(', ')}</WebsitesHolder>
                    }
                }
                const hasAccounts = application.accounts.length > 0

                return (
                    <ApplicationCardWrapper key={`${applicationId}-mercury-application-wrapper`}>
                        <ApplicationCard
                            ref={rowsRefs3[index]}
                            onClick={() => onAccountClick(applicationId)}
                            key={`${applicationId}-mercury-application`}
                        >
                            <CardInset>
                                <List
                                    background="front.background"
                                    items={itemsX}
                                    switchToRowsAt={400}
                                    template={template}
                                />
                            </CardInset>
                        </ApplicationCard>
                        {hasAccounts ? (
                            <AccountsCard
                                key={`${applicationId}-accounts`}
                                ref={rowsRefs3[index]}
                                onClick={() => onAccountClick(applicationId, true)}
                            >
                                <CardInset>
                                    <Table
                                        background="front.background"
                                        cols={[{ text: 'Status' }, { text: 'MID' }, { text: 'Currency' }]}
                                        columnLayout="160px 230px auto"
                                        noHeaderSeparator
                                        rows={application.accounts.map((account) => {
                                            return {
                                                type: 'normal',
                                                noAction: true,
                                                key: account.id,
                                                items: [
                                                    {
                                                        node: <Flex align="center">{account.state}</Flex>
                                                    },
                                                    {
                                                        node: <Flex align="center">{account.merchantId}</Flex>
                                                    },
                                                    {
                                                        node: <Flex align="center">{account.currency}</Flex>
                                                    }
                                                ]
                                            }
                                        })}
                                        type="narrow"
                                        emptyText="No tasks found matching the filtered criterias."
                                    />
                                </CardInset>
                            </AccountsCard>
                        ) : null}
                        <DeleteButton key={`${applicationId}-del-area`}>
                            <SimpleButton
                                background="front.danger.color"
                                key={`${applicationId}-del`}
                                onClick={() => {
                                    handleRemoveClick(applicationId)
                                }}
                            >
                                <ButtonInset>
                                    <Text oneLine>Remove</Text>
                                </ButtonInset>
                            </SimpleButton>
                        </DeleteButton>
                    </ApplicationCardWrapper>
                )
            })
        }
        if (mercuryRes.loadingStatus === 'started') return <LoaderView type="m" overBackground="overlay.background" />
    }, [rows3, onAccountClick, rowsRefs3, mercuryRes])

    return (
        <>
            <PageHeader
                noBottomBorder
                title="Related applications and accounts"
                subtitle=""
                key="related-applications-header"
            />
            {mainCard}
            {accountsList}
            {accountsList2}
            {renderedPagination2}
            {searchBar}
            <SearchContainer>{searchedList}</SearchContainer>
        </>
    )
}

const CallCard = styled.div`
    position: relative;
    cursor: pointer;
    ${CardStyles.higher};
    margin-bottom: 10px;
    color: ${(p) => p.theme['front.text']};
    background-color: ${(p) => p.theme['front.background']};
`

const ApplicationCard = styled.div`
    position: relative;
    cursor: pointer;
    ${CardStyles.higher};
    margin-bottom: 10px;
    color: ${(p) => p.theme['front.text']};
    background-color: ${(p) => p.theme['front.background']};
`

const AccountsCard = styled.div`
    position: relative;
    ${CardStyles.higher};
    background-color: ${(p) => p.theme['front.highlights']};
    color: ${(p) => p.theme['front.text.subtlerI']};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    margin-top: -16px;
    cursor: pointer;
`

const ApplicationCardWrapper = styled.div``

const HelpText = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    color: ${(p) => p.theme['overlay.text']};
`

const SearchInputWrapper = styled.div`
    max-width: 580px;
    width: 100%;
`

const SearchInputHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const ResultsWrapper = styled.div`
    max-width: 1080px;
    box-sizing: border-box;
`

const SearchContainer = styled.div`
    flex-grow: 1;
    margin: 30px;
    margin-top: 8vh;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    cursor: default;
`

const PaginatorHolder = styled.div`
    padding: 10px 15px;
    border-radius: 3px;
`

const WebsitesHolder = styled.div`
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const DeleteButton = styled.div`
    margin-bottom: 10px;
`
