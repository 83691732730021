import { camelCase } from 'lodash'
import { Action } from 'redux'
import { call, put } from 'redux-saga/effects'
import { select } from 'typed-redux-saga'

import { getJWT } from '../store/auth/sagas'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../store/toasts/actions'
import { WatcherDispatchFail, WatcherDispatchStart, WatcherDispatchSuccess } from '../store/watcher/actions'
import { WatcherID } from '../store/watcher/types'
import { cleanTemplatedLink } from '../utils'
import { BuildErrorToastContext } from '../components/toasts/toasts'
import { GlobalLinks } from '../store/global/types'

interface GETConfig {
    url?: string
    cutterUrl?: (links: GlobalLinks['cutter']) => string
    mapiUrl?: (links: GlobalLinks['mapi']) => string
    errorText?: string
    onSuccessDispatch?: (r: any) => Action
    onFailureDispatch?: (r: any) => Action
    include?: string[]
    rawResponse?: boolean
    skipRootLinksReadyCheck?: boolean
    returnStatusCode?: boolean
    disableContentType?: boolean
    returnHeader?: string
    ignoreErrorCodes?: number[]
    hintedHalJSON?: boolean
    customInclude?: {
        type: 'APPLICATION'
        fields: string[]
    }
}

interface PATCHConfig extends Omit<GETConfig, 'applicationInclude'> {
    watcher?: WatcherID
    successText?: string
    body?: any
    returnHeader?: string
    skipErrorCode?: number[]
    addWatcherContext?: (r: any) => any
    successCode?: number | number[]
}

type PUTConfig = PATCHConfig
type POSTConfig = PATCHConfig
type DELETEConfig = Omit<PATCHConfig, 'body'>

//------------
export function* GET(config: GETConfig) {
    try {
        const { token, url, successCode } = yield parseConfig(config, 200, config.skipRootLinksReadyCheck)
        const headers = new Headers({
            Authorization: `Bearer ${token}`,
            Accept: config.hintedHalJSON ? 'application/hal+json;q=1;hints=1' : 'application/json, text/plain, */*'
        })

        if (!config.disableContentType) {
            headers.append('Content-Type', 'application/json;charset=UTF-8')
        }

        let response: Response | null = null
        try {
            response = yield call(fetch, url, {
                method: 'GET',
                headers
            })
        } catch (e) {
            if (config.onFailureDispatch) {
                yield put(config.onFailureDispatch(response))
            }
            return null
        }
        if (response === null) return null

        let responseJSON: undefined | string = undefined
        try {
            responseJSON = yield response.json()
        } catch (e) {
            console.error(e)
        }

        if (response.status == successCode) {
            // let cleanedResponse
            // if (
            //     config.customInclude &&
            //     config.customInclude.type == "APPLICATION"
            // )
            //     cleanedResponse = yield cleanupApplicationRequest(
            //         responseJSON,
            //         config.customInclude.fields
            //     )
            // else
            const cleanedResponse = config.rawResponse
                ? responseJSON
                : cleanupRequest(responseJSON, config.include ? config.include : [])

            if (config.onSuccessDispatch) yield put(config.onSuccessDispatch(cleanedResponse))

            if (config.returnHeader) {
                return {
                    cleanedResponse: cleanedResponse || undefined,
                    header: response.headers.get(config.returnHeader)
                }
            }
            if (config.returnStatusCode) {
                return {
                    statusCode: response.status,
                    cleanedResponse: cleanedResponse || undefined
                }
            }
            return cleanedResponse
        } else if (config.returnStatusCode) {
            return {
                statusCode: response.status
            }
        }

        if (config.onFailureDispatch) {
            yield put(config.onFailureDispatch(response))
        }

        if (response.status && config.ignoreErrorCodes?.includes(response.status)) return

        yield put(
            ToastsDispatchPush(
                config.errorText || 'An error occurred',
                'error',
                undefined,
                undefined,
                BuildErrorToastContext(response.status, responseJSON)
            )
        )
    } catch (error) {
        console.log(error)
    }
}

export function* PATCH(config: PATCHConfig) {
    return (yield modifyRequest(config, 'PATCH', 200)) as { cleanedResponse?: any; success?: boolean; header?: string }
}

export function* POST(config: POSTConfig) {
    return (yield modifyRequest(config, 'POST', 201)) as { cleanedResponse?: any; success?: boolean; header?: string }
}

export function* DELETE(config: DELETEConfig) {
    return (yield modifyRequest(config, 'DELETE', [200, 204])) as { cleanedResponse?: any; success?: boolean; header?: string }
}

export function* PUT(config: PUTConfig) {
    return (yield modifyRequest(config, 'PUT', 200)) as { cleanedResponse?: any; success?: boolean; header?: string }
}

type ModifyRequestConfigType = PATCHConfig | POSTConfig | DELETEConfig
type ModifyRequestType = 'PATCH' | 'POST' | 'DELETE' | 'PUT'
function* modifyRequest(config: ModifyRequestConfigType, requestType: ModifyRequestType, defaultSuccessCode: number | number[]) {
    const { token, url, successCode } = yield parseConfig(config, defaultSuccessCode)
    if (config.watcher) yield put(WatcherDispatchStart([config.watcher]))

    let response: Response | undefined = undefined
    const commonRequestConfig = {
        method: requestType,
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            ...(config.hintedHalJSON
                ? { Accept: 'application/hal+json;q=1;hints=1' }
                : { Accept: 'application/json, text/plain, */*' })
        })
    }
    try {
        if (isDeleteRequest(config, requestType))
            response = (yield call(fetch, url, {
                ...commonRequestConfig
            })) as Response

        if (isPostPatchOrPut(config, requestType)) {
            let requestConfig: any = {
                ...commonRequestConfig
            }
            if (config.body)
                requestConfig = {
                    ...requestConfig,
                    body: JSON.stringify(config.body)
                }

            response = (yield call(fetch, url, requestConfig)) as Response
        }
    } catch (e) {
        if (config.watcher)
            WatcherDispatchFail(
                [config.watcher],
                config.errorText || 'An error occurred',
                BuildErrorToastContext(response ? (response as any).status : 'Error', e)
            )
        else ToastsDispatchPush(JSON.stringify(e), 'error')
    }

    let responseJSON

    try {
        responseJSON = response ? ((yield response.json()) as string) : undefined
    } catch (e) {
        responseJSON = undefined
    }

    const gotSuccessResponse = Array.isArray(successCode)
        ? successCode.includes(response?.status)
        : response?.status === successCode

    if (gotSuccessResponse) {
        const cleanedResponse = responseJSON
            ? cleanupRequest(responseJSON, config.include ? config.include : [])
            : undefined

        if (config.onSuccessDispatch) yield put(config.onSuccessDispatch(cleanedResponse))

        if (config.watcher)
            yield put(
                WatcherDispatchSuccess(
                    [config.watcher],
                    config.successText,
                    config.addWatcherContext?.(cleanedResponse)
                )
            )

        if (config.returnHeader) {
            return {
                cleanedResponse: cleanedResponse || undefined,
                success: true,
                header: response?.headers.get(config.returnHeader)
            }
        }
        return {
            cleanedResponse: cleanedResponse || undefined,
            success: true
        }
    }

    if (config.onFailureDispatch) yield put(config.onFailureDispatch(response))

    if (config.watcher) {
        if (response?.status && config.skipErrorCode?.includes(response?.status)) {
            yield put(WatcherDispatchFail([config.watcher]))
        } else
            yield put(
                WatcherDispatchFail(
                    [config.watcher],
                    config.errorText || 'An error occurred',
                    BuildErrorToastContext(response?.status, responseJSON)
                )
            )
    } else if (response?.status && !config.skipErrorCode?.includes(response?.status))
        yield put(
            ToastsDispatchPush(
                config.errorText || 'An error occurred',
                'error',
                undefined,
                undefined,
                BuildErrorToastContext(response?.status, responseJSON)
            )
        )
    return {
        success: false,
        code: response?.status,
        cleanedResponse: responseJSON ? cleanupRequest(responseJSON, config.include ? config.include : []) : undefined
    }
}

//------------

type ParsableConfigs = GETConfig | PATCHConfig | POSTConfig | DELETEConfig | PUTConfig

function* parseConfig(config: ParsableConfigs, defaultSuccessCode: number | number[], skipRootLinksReadyCheck?: boolean) {
    const token: string = yield getJWT(skipRootLinksReadyCheck)
    let requestURL = config.url

    if (config.cutterUrl) {
        const cutterLinks = yield* select((state: RootState) => {
            return state.global.links?.cutter
        })
        if (!cutterLinks) throw 'Cutter links have not been loaded'
        requestURL = config.cutterUrl(cutterLinks)
    }

    if (config.mapiUrl) {
        const mapiLinks = yield* select((state: RootState) => {
            return state.global.links?.mapi
        })
        if (!mapiLinks) throw 'Cutter links have not been loaded'
        requestURL = config.mapiUrl(mapiLinks)
    }

    if (!requestURL) throw 'Please provide either a URL, cutterURL, etc.'

    if (hasCustomSuccessCode(config)) return { url: requestURL, token, successCode: config.successCode }

    return { url: requestURL, token, successCode: defaultSuccessCode }
}

function isPostPatchOrPut(
    config: ModifyRequestConfigType,
    type: ModifyRequestType
): config is PATCHConfig | POSTConfig | PUTConfig {
    return type === 'PATCH' || type === 'POST' || type === 'PUT'
}

function isDeleteRequest(config: ModifyRequestConfigType, type: ModifyRequestType): config is DELETEConfig {
    return type === 'DELETE'
}

function hasCustomSuccessCode(config: ParsableConfigs): config is PATCHConfig {
    const c = config as PATCHConfig
    return c.successCode !== undefined
}

//-

// const cleanComments = (comments: any[]) => {
//     const fieldComments: Comment[] = []

//     if (comments)
//         comments.map((comment: any, index: number) => {
//             if (comment.field) {
//                 const fieldComment: Comment = {
//                     isChange: comment.content.includes("<field-update>"),
//                     content: comment.content,
//                     authorId: comment.author_id,
//                     createdAt: comment.created_at,
//                     readAt: comment.read_at,
//                     private: comment.private,
//                     field: comment.field,
//                 }

//                 if (fieldComment.isChange) {
//                     fieldComment.content = fieldComment.content.replace(
//                         "<field-update>",
//                         "→"
//                     )
//                 }

//                 fieldComments.push(fieldComment)
//             }
//         })
//     return fieldComments
// }

// export function* cleanupApplicationRequest(data: any, neededLinks?: string[]) {
//     let cleanedRequest = cleanupRequest(data, neededLinks, {
//         comments: cleanComments,
//     })
//     // const files: any[] = []
//     // cleanedRequest = cleanFilesAndStoreFilesInFilesModule(cleanedRequest, files)
//     // yield put(FilesActionDispatchStore(files))
//     return cleanedRequest
// }

export function cleanFilesAndStoreFilesInFilesModule(cleanedRequest: any, files: any[]): any {
    if (typeof cleanedRequest === 'object') {
        const newObject = { ...cleanedRequest }
        for (const key in cleanedRequest) {
            if (cleanedRequest.hasOwnProperty(key)) {
                if (key == 'applicationFiles') {
                    newObject[key] = cleanedRequest[key].map((f: any) => {
                        files.push(f)
                        return f.id
                    })
                } else if (Array.isArray(cleanedRequest[key])) {
                    newObject[key] = cleanedRequest[key].map((k: any) => {
                        return cleanFilesAndStoreFilesInFilesModule(k, files)
                    })
                } else {
                    newObject[key] = cleanFilesAndStoreFilesInFilesModule(cleanedRequest[key], files)
                }
            }
        }
        return newObject
    }
    return cleanedRequest
}

export const cleanupRequest = (data: any, neededLinks?: string[], extraRules?: { [key: string]: any }): any => {
    let cleanObject: any = {}

    if (data === null) return undefined
    if (typeof data === 'object') {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const element = data[key]
                const camelCasedKey = camelCase(key.replace('ch:', ''))

                if (extraRules && extraRules[key]) {
                    cleanObject[key] = extraRules[key](element)
                    continue
                }

                if (key == '_links') {
                    Object.keys(element).map((linkName) => {
                        const cleanedLinkName = linkName.replace('ch:', '')
                        if (neededLinks && (neededLinks.includes(cleanedLinkName) || neededLinks.includes('*'))) {
                            if (element[linkName].href)
                                cleanObject[`${camelCase(cleanedLinkName)}Link`] = cleanTemplatedLink(
                                    element[linkName].href
                                )
                            else if (Array.isArray(element[linkName])) {
                                cleanObject[`${camelCase(cleanedLinkName)}Link`] = []
                                element[linkName].map((v: any) => {
                                    cleanObject[`${camelCase(cleanedLinkName)}Link`].push(cleanupRequest(v))
                                })
                            } else cleanObject[`${camelCase(cleanedLinkName)}Link`] = cleanupRequest(element[linkName])
                        }
                    })
                    continue
                }

                if (key == '_embedded') {
                    cleanObject = {
                        ...cleanObject,
                        ...cleanupRequest(element, neededLinks, extraRules)
                    }
                    continue
                }

                if (Array.isArray(element)) {
                    cleanObject[camelCasedKey] = []
                    element.map((value) => {
                        cleanObject[camelCasedKey].push(cleanupRequest(value, neededLinks, extraRules))
                    })
                } else {
                    cleanObject[camelCasedKey] = cleanupRequest(element, neededLinks, extraRules)
                }
            }
        }
        return cleanObject
    }
    return data
}
