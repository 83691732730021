import React from 'react'
import { Section } from '../../../common/section'
import { FormField } from '../../../../../types'
import { useFormContext } from 'react-hook-form'

interface ToggleContentProps {
    dataName: string
    commentName: string
    data: Omit<FormField, 'name'>
    commentData: Omit<FormField, 'name'>
    visibleValue: 'Yes' | 'No'
}

export const ToggleContent: React.FC<ToggleContentProps> = (props) => {
    const { data, commentData, dataName, commentName, visibleValue } = props

    const methods = useFormContext()
    const isCommentVisible = methods.getValues(dataName) === visibleValue

    return (
        <>
            <Section.Field name={dataName} data={data} />
            <Section.Visible isVisible={isCommentVisible}>
                <Section.Spacer />
                <Section.Field name={commentName} data={commentData} />
            </Section.Visible>
        </>
    )
}
