import moment from 'moment'
import styled, { css } from 'styled-components'
import { SettlementSummary } from '../../store/settlements/types'
import { Text } from '../general/text'

export const SettlementStatus: React.FC<{ s: SettlementSummary }> = ({ s }) => {
    let allDone = false

    if (s.reserve) {
        if (moment(s.reserve.date).isAfter(moment())) {
            if (!s.reserve.cancelledDate)
                return (
                    <SettlementStatusBox state="reserves-held">
                        <Text noWrap>Reserve payout held</Text>
                    </SettlementStatusBox>
                )
        }
        allDone = true
    }

    if (s.payout) {
        if (moment(s.payout.date).isAfter(moment())) {
            if (!s.payout.cancelledDate)
                return (
                    <SettlementStatusBox state="payout-pending">
                        <Text noWrap>Upcoming payout</Text>
                    </SettlementStatusBox>
                )
        }
        allDone = true
    }

    if (allDone) {
        if (s.payout?.cancelledDate) {
            if (s.reserve?.cancelledDate)
                return (
                    <SettlementStatusBox state="completed">
                        <Text noWrap>Completed, payout &amp; reserve cancelled</Text>
                    </SettlementStatusBox>
                )
            return (
                <SettlementStatusBox state="completed">
                    <Text noWrap>Completed, payout cancelled</Text>
                </SettlementStatusBox>
            )
        }
        if (s.reserve?.cancelledDate)
            return (
                <SettlementStatusBox state="completed">
                    <Text noWrap>Completed, reserve cancelled</Text>
                </SettlementStatusBox>
            )
        return (
            <SettlementStatusBox state="completed">
                <Text noWrap>Completed</Text>
            </SettlementStatusBox>
        )
    }
    return (
        <SettlementStatusBox state="in-progress">
            <Text noWrap>In progress</Text>
        </SettlementStatusBox>
    )
}

export const SettlementStatusBox = styled.div<{
    state?: 'in-progress' | 'completed' | 'payout-pending' | 'reserves-held'
}>`
    padding: 1px 5px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: -2px 0;
    ${(p) => {
        switch (p.state) {
            case 'completed':
                return css`
                    background-color: transparent;
                    /* color: ${p.theme['front.text.subtlerII']}; */
                    font-style: italic;
                    padding: 0;
                    border-color: transparent;
                    margin: 0;
                `
            case 'in-progress':
                return css`
                    background-color: ${p.theme['front.subtleAccent.background']};
                    border-color: ${p.theme['front.subtleAccent.background.strongerI']};
                    /* color: ${p.theme['front.subtleAccent.text']}; */
                `
            case 'payout-pending':
                return css`
                    background-color: ${p.theme['front.subtleInfo.background']};
                    border-color: ${p.theme['front.subtleInfo.background.strongerII']};
                `

            case 'reserves-held':
                return css`
                    background-color: ${p.theme['front.subtleDanger.background']};
                    border-color: ${p.theme['front.subtleDanger.background.strongerI']};
                    /* color: ${p.theme['front.subtleDanger.text']}; */
                `
        }
    }}
`
