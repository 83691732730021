import { LoadingStatus } from '../../../utils'

export interface Collaborator {
    email: string
    name: string
    selfLink: string
}

export interface ApplicationInternalsCollaboratorsState {
    forApplication: {
        [key: string]: {
            collaborators?: Collaborator[]
            loadingStatus: LoadingStatus
        }
    }
}

export const InitialApplicationInternalsCollaboratorsState: ApplicationInternalsCollaboratorsState = {
    forApplication: {}
}
