import moment from 'moment'

import { returnUnique, returnWithout } from '../../utils'
import { File } from '../files/types'
import {
    ReducedTasksActions,
    TASK_LINK_FILES,
    TASK_LOAD_TIMELINE,
    TASK_STORE_COMMENT,
    TASK_UNLINK_FILES,
    TASK_UNSTORE_COMMENT,
    TASKS_LOAD_SUMMARIES,
    TASKS_LOAD_TAGS,
    TASKS_LOAD_TASK,
    TASKS_STORE_SUMMARIES,
    TASKS_STORE_TAGS,
    TASKS_STORE_TASK,
    TASKS_STORE_TIMELINE,
    TASKS_UNSTORE_TASK,
    TASKS_CLEAR_SUMMARIES
} from './actions'
import { InitialTasksState, TasksState } from './types'

export function TasksReducer(state = InitialTasksState, action: ReducedTasksActions): TasksState {
    switch (action.type) {
        case TASKS_LOAD_SUMMARIES: {
            return {
                ...state,
                taskSummaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'started'
                }
            }
        }
        case TASKS_STORE_SUMMARIES: {
            return {
                ...state,
                taskSummaries: {
                    at: {
                        ...action.tasks.reduce((a, t) => {
                            return {
                                ...a,
                                [t.id]: {
                                    ...state.taskSummaries.at[t.id],
                                    ...t,
                                    state: t.state,
                                    tags: [...t.tags]
                                }
                            }
                        }, {})
                    },
                    all: action.tasks.map((t) => t.id),
                    pagination: { ...action.pagination } as any,
                    loadingStatus: 'done'
                }
            }
        }
        case TASKS_CLEAR_SUMMARIES: {
            return {
                ...state,
                taskSummaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'not-started'
                }
            }
        }
        case TASKS_LOAD_TAGS: {
            return {
                ...state,
                tags: {
                    ...state.tags,
                    at: {},
                    all: [],
                    selectedLoadingStatus: 'started',
                    loadingStatus: 'started'
                }
            }
        }
        case TASKS_STORE_TAGS: {
            return {
                ...state,
                tags: {
                    ...state.tags,
                    at: {
                        ...action.tags.reduce((a, t) => {
                            return {
                                ...a,
                                [t.id]: {
                                    ...state.tags.at[t.id],
                                    ...t
                                }
                            }
                        }, {})
                    },
                    all: action.tags.map((t) => t.id),
                    selectedLoadingStatus: 'done',
                    loadingStatus: 'done'
                }
            }
        }
        case TASK_LOAD_TIMELINE: {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    at: {
                        ...state.tasks.at,
                        [action.taskId]: {
                            ...state.tasks.at[action.taskId],
                            timeline: {
                                loadingStatus: 'started'
                            }
                        } as any
                    }
                }
            }
        }
        case TASKS_STORE_TIMELINE: {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    at: {
                        ...state.tasks.at,
                        [action.taskId]: {
                            ...state.tasks.at[action.taskId],
                            timeline: {
                                timeline: action.timeline,
                                loadingStatus: 'done'
                            }
                        }
                    }
                }
            }
        }
        case TASKS_LOAD_TASK: {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    at: {
                        ...state.tasks.at,
                        [action.taskId]: {
                            loadingStatus: 'started',
                            timeline: {
                                timeline: {},
                                loadingStatus: 'not-started'
                            }
                        }
                    } as any,
                    all: returnUnique(state.tasks.all, action.taskId)
                }
            }
        }
        case TASKS_STORE_TASK: {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    at: {
                        ...state.tasks.at,
                        [action.task.id]: {
                            ...state.tasks.at[action.task.id],
                            ...action.task,
                            loadingStatus: 'done'
                        }
                    },
                    all: returnUnique(state.tasks.all, action.task.id)
                }
            }
        }
        case TASKS_UNSTORE_TASK: {
            const newState = { ...state }
            delete newState.tasks.at[action.taskId]
            newState.tasks.all = returnWithout(newState.tasks.all, action.taskId)
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    at: {
                        ...newState.tasks.at
                    },
                    all: [...newState.tasks.all]
                }
            }
        }
        case TASK_STORE_COMMENT: {
            let timeline = state.tasks.at[action.taskId]?.timeline?.timeline

            if (timeline) {
                if (action.commentLink) {
                    timeline.map((item: any, index: number) => {
                        if (item.type == 'comment' && item.data.selfLink == action.commentLink) {
                            timeline.splice(index, 1, ...action.commentItem)
                        }
                    })
                } else {
                    timeline = [...timeline, ...action.commentItem]
                }

                return {
                    ...state,
                    tasks: {
                        ...state.tasks,
                        at: {
                            ...state.tasks.at,
                            [action.taskId]: {
                                ...state.tasks.at[action.taskId],
                                timeline: {
                                    ...state.tasks.at[action.taskId].timeline,
                                    timeline
                                }
                            }
                        }
                    } as any
                }
            }
            return state
        }
        case TASK_UNSTORE_COMMENT: {
            const { timeline } = state.tasks.at[action.taskId].timeline

            timeline.map((item: any, index: number) => {
                if (item.type == 'comment' && item.data.selfLink == action.commentLink) {
                    timeline.splice(index, 1)
                }
            })

            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    at: {
                        ...state.tasks.at,
                        [action.taskId]: {
                            ...state.tasks.at[action.taskId],
                            timeline: {
                                ...state.tasks.at[action.taskId].timeline,
                                timeline: [...timeline]
                            }
                        }
                    }
                } as any
            }
        }
        case TASK_LINK_FILES: {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    at: {
                        ...state.tasks.at,
                        [action.taskId]: {
                            ...state.tasks.at[action.taskId],
                            timeline: {
                                ...state.tasks.at[action.taskId].timeline,
                                timeline: [
                                    ...state.tasks.at[action.taskId].timeline.timeline,
                                    ...action.files.map((f) => {
                                        return {
                                            type: 'file',
                                            at: moment(f.createdAt).unix(),
                                            data: f
                                        }
                                    })
                                ]
                            }
                        }
                    }
                } as any
            }
        }
        case TASK_UNLINK_FILES: {
            const { timeline } = state.tasks.at[action.taskId].timeline
            const fileIds = action.files.reduce((acc, f: File) => acc.concat(f.id), [] as string[])

            timeline.map((ti: any, i) => {
                if (ti.type == 'file' && fileIds.includes(ti.data.id)) {
                    timeline.splice(i, 1)
                }
            })

            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    at: {
                        ...state.tasks.at,
                        [action.taskId]: {
                            ...state.tasks.at[action.taskId],
                            timeline: {
                                ...state.tasks.at[action.taskId].timeline,
                                timeline: [...timeline]
                            }
                        }
                    }
                } as any
            }
        }
    }

    return state
}
