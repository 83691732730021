import { Button, ChevronRightIcon, Flex } from '@/design-system'
import React from 'react'
import { useTheme } from 'styled-components'

export const MerchantSidebarLinksTrigger: React.FC = () => {
    const theme = useTheme()

    const buttonVariant = theme.name === 'managerInspired' ? 'soft' : 'outline'

    return (
        <Button variant={buttonVariant} color="neutral" width="full">
            <Flex justify="between" align="center" grow="1">
                <span />
                <span>My Clearhaus</span>
                <ChevronRightIcon size="4" />
            </Flex>
        </Button>
    )
}
