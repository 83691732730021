import { useCreateCharts } from '@/services/ongoing-due-diligence'
import { useReportParams } from '../../../hooks'
import React, { useState } from 'react'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { Flex } from '@/components/layout/flex'
import { Floater } from '@/components/layout/floater'
import { useRefTaker } from '@/hooks/general/useRefTaker'
import { FloaterInset } from '@/components/layout/floaterInset'
import { Text } from '@/components/general/text'

export const CreateChartsButton: React.FC = () => {
    const { applicationId } = useReportParams()
    const [createCharts, chartsResult] = useCreateCharts()
    const [anchor, setAnchor] = useRefTaker()
    const [isTooltipShown, setIsTooltipShown] = useState(false)

    const watcher = chartsResult.isLoading ? 'started' : undefined

    const handleCreateClick = async () => {
        if (applicationId) {
            await createCharts(applicationId)
        }
    }

    const handleOnMouseEnter = () => {
        setIsTooltipShown(true)
    }

    const handleOnMouseLeave = () => {
        setIsTooltipShown(false)
    }

    return (
        <Flex grow justify="flex-end">
            <div ref={setAnchor} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                <SimpleButton onClick={handleCreateClick}>
                    <ButtonLoadingCover background="front.accent.color" watcher={watcher}>
                        <ButtonInset noHorizontalPadding noVerticalPadding>
                            Generate chart
                        </ButtonInset>
                    </ButtonLoadingCover>
                </SimpleButton>
            </div>
            <Floater anchor={anchor} shouldShow={isTooltipShown}>
                <FloaterInset>
                    <Flex style={{ maxWidth: 275 }} column>
                        <Text color="front.accent.text">
                            Generating a chart takes about 5 - 10 minutes on average before it shows up in the list.
                        </Text>
                    </Flex>
                </FloaterInset>
            </Floater>
        </Flex>
    )
}
