import { useEffect, useMemo } from 'react'
import { convertBackendQueriesToQueryParams } from '../../utils/apiHelpers'
import { useQueryParams } from './useQueryParam'
import { useNavigate } from 'react-router-dom'

export const useBackendToConsoleFilters = (api: 'tql' | 'dql' | 'sql') => {
    const navigate = useNavigate()
    const queryParam = useMemo(() => {
        return [api]
    }, [api])

    const entity = useMemo(() => {
        switch (api) {
            case 'sql':
                return 'settlements'
            case 'dql':
                return 'disputes'
            case 'tql':
                return 'transactions'
        }
    }, [api])

    const [query] = useQueryParams(queryParam, undefined)

    useEffect(() => {
        if (!query[api]) return
        const params = convertBackendQueriesToQueryParams(entity, '?' + query[api].replaceAll(' ', '&'))
        if (params) navigate(params, { replace: true })
    }, [query, navigate, api, entity])
}
