export const AUTH_INIT = 'AUTH_INIT'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_STORE_USER = 'AUTH_STORE_USER'
export const AUTH_SET_IN_PROGRESS = 'AUTH_SET_IN_PROGRESS'

//-

export type AuthPayloadLoginHandleSuccess = { useUrlCode: boolean } | undefined

//-
export interface AuthActionInit {
    type: typeof AUTH_INIT
}

export interface AuthActionLogin {
    type: typeof AUTH_LOGIN
    connection: 'google' | 'microsoft'
}

export interface AuthActionLogout {
    type: typeof AUTH_LOGOUT
}

export interface AuthActionStoreUser {
    type: typeof AUTH_STORE_USER
    token: string
    user: any
}

export interface AuthActionSetInProgress {
    type: typeof AUTH_SET_IN_PROGRESS
    inProgress: boolean
}

//-

export const AuthDispatchInit = (): AuthActionInit => {
    return { type: AUTH_INIT }
}

export const AuthDispatchLogin = (connection: 'google' | 'microsoft'): AuthActionLogin => {
    return { type: AUTH_LOGIN, connection }
}

export const AuthDispatchLogout = (): AuthActionLogout => {
    return { type: AUTH_LOGOUT }
}

export const AuthDispatchStoreUser = (user: any, token: string): AuthActionStoreUser => {
    return { type: AUTH_STORE_USER, user, token }
}

export const AuthDispatchSetInProgress = (inProgress: boolean): AuthActionSetInProgress => {
    return { type: AUTH_SET_IN_PROGRESS, inProgress }
}

export interface SagasForAuth {
    init(action: AuthActionInit): void
    login(action: AuthActionLogin): void
    logout(action: AuthActionLogout): void
    onUserStore(action: AuthActionStoreUser): void
}

export type AuthReducedActions = AuthActionStoreUser | AuthActionSetInProgress
