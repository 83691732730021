import { ReactElement, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

import { TransactionsFilters } from '../../store/transactions/types'
import { FiltersController } from '../../hooks/pages/useListPage'
import { FilterPageHolder } from './listPageFilterHolder'
import { TextInputSelect } from '../forms/textInputSelect'
import { SupportedCurrencies } from '../../store/transactions/types'
import { Flex } from '../layout/flex'
import { TextInput } from '../forms/textInput'
import { SelectedFiltersContainer, SelectedListForListPageFilters } from './selectedListForListPageFilters'
import styled from 'styled-components'

const buildQuery = (period?: PeriodType, amountA?: number, amountB?: number) => {
    if (!period) return undefined
    switch (period) {
        case '< Less':
            if (!amountA) return undefined
            return `<${amountA}`
        case '≤ Less or equal':
            if (!amountA) return undefined
            return `<=${amountA}`
        case '= Equal':
            if (!amountA) return undefined
            return `${amountA}`
        case '> More':
            if (!amountA) return undefined
            return `>${amountA}`
        case '≥ More or equal':
            if (!amountA) return undefined
            return `>=${amountA}`
        case 'Between (Incl.)':
            if (!amountA) return undefined
            if (!amountB) return undefined
            return `${amountA}..${amountB}`
    }
}
type PeriodType = 'Between (Incl.)' | '< Less' | '≤ Less or equal' | '> More' | '≥ More or equal' | '= Equal'
const initialLocalState: {
    period: PeriodType | undefined
    amountA: number | undefined
    amountB: number | undefined
} = {
    period: undefined,
    amountA: undefined,
    amountB: undefined
}
type Action =
    | { type: 'setPeriod'; period: typeof initialLocalState['period'] }
    | { type: 'setAmount'; field: 'A' | 'B'; amount: number }
    | { type: 'init'; state: typeof initialLocalState }

function reducer(state: typeof initialLocalState, action: Action) {
    switch (action.type) {
        case 'init':
            return { ...action.state }
        case 'setAmount':
            return { ...state, [`amount${action.field}`]: action.amount }
        case 'setPeriod':
            return { ...state, period: action.period }
    }
}
export function ListPageFilterCurrencyAndAmount<T extends TransactionsFilters>({
    filters,
    isDark
}: {
    filters: FiltersController<T>
    isDark: boolean
}): ReactElement {
    const textForItem = useCallback((val) => val, [])
    const cachedFiltersSetter = useRef<any>()
    const lastURLState = useRef<any>()
    const amountARef = useRef<any>()
    const amountBRef = useRef<any>()
    const [ls, ld] = useReducer(reducer, initialLocalState)

    const handleCurrencySelection = useCallback(
        (txt) => {
            filters.set((prevState) => ({
                ...prevState,
                transactions_currency: txt
            }))
        },
        [filters]
    )

    const periods = useMemo(() => {
        return ['= Equal', 'Between (Incl.)', '< Less', '≤ Less or equal', '> More', '≥ More or equal']
    }, [])

    const handlePeriodSelect = useCallback((item) => {
        ld({ type: 'setPeriod', period: item })
    }, [])

    useEffect(() => {
        cachedFiltersSetter.current = filters.set
    }, [filters])

    useEffect(() => {
        const query = buildQuery(ls.period, ls.amountA, ls.amountB)
        if (query)
            cachedFiltersSetter.current?.((prevState: any) => ({
                ...prevState,
                transactions_amount: query
            }))
    }, [ls])
    // Set Amounts and Period based on the URL filters
    useEffect(() => {
        const newState: typeof initialLocalState = {
            period: undefined,
            amountA: undefined,
            amountB: undefined
        }

        const amount = filters.get.transactions_amount
        if (amount) {
            if (amount.includes('<=')) {
                newState.period = '≤ Less or equal'
                newState.amountA = amount.split('<=')[1]
            } else if (amount.includes('>=')) {
                newState.period = '≥ More or equal'
                newState.amountA = amount.split('>=')[1]
            } else if (amount.includes('<')) {
                newState.period = '< Less'
                newState.amountA = amount.split('<')[1]
            } else if (amount.includes('>')) {
                newState.period = '> More'
                newState.amountA = amount.split('>')[1]
            } else if (amount.includes('..')) {
                newState.period = 'Between (Incl.)'
                newState.amountA = amount.split('..')[0]
                newState.amountB = amount.split('..')[1]
            } else if (
                !amount.includes('..') &&
                !amount.includes('<') &&
                !amount.includes('>') &&
                !amount.includes('=')
            ) {
                newState.period = '= Equal'
                newState.amountA = amount
            }
        } else {
            newState.amountA = undefined
            newState.amountB = undefined
        }

        if (lastURLState.current !== JSON.stringify(filters.get)) {
            if (JSON.stringify(ls) !== JSON.stringify(newState)) {
                ld({ type: 'init', state: { ...newState } })
            }
            lastURLState.current = JSON.stringify(filters.get)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const handleAmountATyped = useCallback(
        (e) => {
            if (ls.amountA !== e.target.value) ld({ type: 'setAmount', field: 'A', amount: e.target.value })
        },
        [ls]
    )
    const handleAmountBTyped = useCallback(
        (e) => {
            if (ls.amountB !== e.target.value) ld({ type: 'setAmount', field: 'B', amount: e.target.value })
        },
        [ls]
    )

    useEffect(() => {
        if (ls.period && ls.amountA === undefined) {
            amountARef.current?.focus?.()
            return
        }
        if (ls.period === 'Between (Incl.)' && ls.amountA && ls.amountB === undefined) {
            amountBRef.current?.focus?.()
            return
        }
    }, [ls])

    return (
        <FilterPageHolder isDark={isDark}>
            <Subfield>
                <TextInputSelect
                    selected={filters.get['transactions_currency']}
                    isHigher={isDark}
                    isSeamless
                    textForItem={textForItem}
                    dropdownId="ListPage.Filters.SettlementStatus"
                    onSelect={handleCurrencySelection}
                    placeholder="-"
                    items={SupportedCurrencies}
                    overBackground={isDark ? 'floating.background' : 'side.background'}
                />
            </Subfield>
            {filters.get['transactions_currency'] ? (
                <Subfield>
                    <TextInputSelect
                        selected={ls.period}
                        isHigher={isDark}
                        isSeamless
                        textForItem={textForItem}
                        dropdownId="ListPage.Filters.SettlementStatus"
                        onSelect={handlePeriodSelect}
                        placeholder="Select amount specifier"
                        items={periods}
                        overBackground={isDark ? 'floating.background' : 'side.background'}
                    />
                </Subfield>
            ) : null}
            {ls.period !== undefined ? (
                <Subfield>
                    <Flex>
                        {ls.period !== undefined ? (
                            <TextInput
                                isSeamless
                                initialValue={ls.amountA}
                                placeholder="Enter amount"
                                onBlur={handleAmountATyped}
                                onEnter={handleAmountATyped}
                                ref={amountARef}
                                overBackground={isDark ? 'floating.background' : 'side.background'}
                            />
                        ) : null}
                        {ls.period === 'Between (Incl.)' ? (
                            <TextInput
                                isSeamless
                                placeholder="-"
                                initialValue={ls.amountB}
                                onBlur={handleAmountBTyped}
                                onEnter={handleAmountBTyped}
                                ref={amountBRef}
                                overBackground={isDark ? 'floating.background' : 'side.background'}
                            />
                        ) : null}
                    </Flex>
                </Subfield>
            ) : null}
            {filters.get['transactions_currency'] || filters.get['transactions_amount'] ? (
                <SelectedFiltersContainer noEmphasize={isDark}>
                    <SelectedListForListPageFilters<T>
                        filterKey="transactions_currency"
                        filters={filters}
                        noHolderEmphasize
                        isDark={isDark}
                        prefix={'Currency '}
                        textForItem={textForItem}
                    />
                    <SelectedListForListPageFilters<T>
                        filterKey="transactions_amount"
                        noHolderEmphasize
                        filters={filters}
                        isDark={isDark}
                        prefix={'Amount '}
                        textForItem={textForItem}
                    />
                </SelectedFiltersContainer>
            ) : null}
        </FilterPageHolder>
    )
}

const Subfield = styled.div`
    margin: 5px 0;
    &:first-child {
        margin: 0;
    }
`
