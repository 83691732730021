import React from 'react'
import styled, { css } from 'styled-components'

const criticalTags = ['DO NOT INCREASE CAPTURE LIMIT', 'DO NOT SET LIVE', 'Payment Held', 'PROBLEMATIC']
const redTags = ['AML High Risk', 'Decline', 'High Risk']
const orangeTags = ['AML Medium Risk', 'Pay Attention']
const blueTags = ['instant-flow']
const greenTags = ['All Support']

type Colors = 'red' | 'orange' | 'critical' | 'blue' | 'green' | undefined

export const MerchantTag: React.FC<{ name: string; inSidebar?: boolean; suffix?: string }> = ({
    name,
    inSidebar,
    suffix
}) => {
    let style: Colors = undefined

    if (redTags.includes(name)) style = 'red'
    if (orangeTags.includes(name)) style = 'orange'
    if (blueTags.includes(name)) style = 'blue'
    if (greenTags.includes(name)) style = 'green'
    if (criticalTags.includes(name)) style = 'critical'

    return (
        <Tag key={name} s={style} inSidebar={inSidebar}>
            {name}
            {suffix}
        </Tag>
    )
}

const Tag = styled.span<{ s: Colors; inSidebar?: boolean }>`
    display: inline-block;
    margin-right: 6px;
    margin-top: 2px;

    ${(p) =>
        p.s &&
        css`
            padding: 0 5px;
            margin: 0;
            margin-right: 5px;
            margin-top: 2px;
            box-sizing: border-box;
            border-radius: 4px;
            margin-left: -2px;
        `}

    ${(p) =>
        p.s === 'critical' &&
        css`
            border-radius: 10px;
        `}

    color: ${(p) => {
        switch (p.s) {
            case 'red': {
                if (p.theme.name === 'darkTheme') return '#FF9292'
                if (p.theme.name === 'managerInspired') return p.inSidebar ? '#FF9292' : '#E31D1D'
                if (p.theme.name === 'whiteTheme') return '#E31D1D'
                return 'YELLOW'
            }
            case 'orange': {
                if (p.theme.name === 'darkTheme') return '#FFA959'
                if (p.theme.name === 'managerInspired') return p.inSidebar ? '#FFA858' : '#E86F00'
                if (p.theme.name === 'whiteTheme') return '#E86F00'
                return 'YELLOW'
            }
            case 'critical': {
                return p.theme['front.danger.text']
            }
            case 'blue': {
                if (p.theme.name === 'darkTheme') return '#9ECBFF'
                if (p.theme.name === 'managerInspired') return p.inSidebar ? '#A6CFFF' : '#3A84DA'
                if (p.theme.name === 'whiteTheme') return '#3A84DA'
                return 'YELLOW'
            }
            case 'green': {
                if (p.theme.name === 'darkTheme') return '#B2FF8E'
                if (p.theme.name === 'managerInspired') return p.inSidebar ? '#B9FF97' : '#00AF08'
                if (p.theme.name === 'whiteTheme') return '#00AF08'
                return 'YELLOW'
            }
            default: {
                return p.inSidebar ? p.theme['side.text'] : p.theme['front.text.subtlerI']
            }
        }
    }};

    background-color: ${(p) => {
        switch (p.s) {
            case 'red': {
                if (p.theme.name === 'darkTheme') return '#653838'
                if (p.theme.name === 'managerInspired') return p.inSidebar ? '#683B3B' : '#F9E5E7'
                if (p.theme.name === 'whiteTheme') return '#F9E5E7'
                return 'YELLOW'
            }
            case 'orange': {
                if (p.theme.name === 'darkTheme') return '#614F35'
                if (p.theme.name === 'managerInspired') return p.inSidebar ? '#5A482D' : '#F3F1E9'
                if (p.theme.name === 'whiteTheme') return '#F3F1E9'
                return 'YELLOW'
            }
            case 'critical': {
                return p.theme['front.danger.color']
            }
            case 'blue': {
                if (p.theme.name === 'darkTheme') return '#363B4A'
                if (p.theme.name === 'managerInspired') return p.inSidebar ? '#42537E' : '#E4F6FF'
                if (p.theme.name === 'whiteTheme') return '#E4F6FF'
                return 'YELLOW'
            }
            case 'green': {
                if (p.theme.name === 'darkTheme') return '#415644'
                if (p.theme.name === 'managerInspired') return p.inSidebar ? '#3E6C45' : '#D8F8DE'
                if (p.theme.name === 'whiteTheme') return '#D8F8DE'
                return 'YELLOW'
            }
            default: {
                return 'transparent'
            }
        }
    }};
`
