import dotProp from 'dot-prop'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { Flex } from '../../../components/layout/flex'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { RootState } from '@/store'
import {
    MerchantApplicationFieldCounter,
    MerchantApplicationFieldCounterButtonElement
} from './Application.FieldCounter'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { MerchantApplicationRowHolder } from './Application.RowHolder'
import { MerchantApplicationEditsModalInput } from './Application.Input'

export const MerchantApplicationSuperField: React.FC<{
    applicationId: string
    indexPath: MerchantApplicationResourceIndexPath
    isSelected?: boolean
    isDataLoading?: boolean
    executeBatchChanges?: any
    smaller?: boolean
    prefixMemo?: React.ReactNode
    highlighted?: 'top' | 'bottom' | 'both'
    formErrors: { [key: string]: ValidationStatus }
    formRef: MerchantApplicationFieldFormProps['formRef']
}> = ({
    applicationId,
    isDataLoading,
    executeBatchChanges,
    prefixMemo,
    formRef,
    smaller,
    indexPath,
    highlighted,
    formErrors
}) => {
    const rawValue = useSelector((state: RootState) => {
        return dotProp.get<string>(
            state,
            `applicationResources.data.forApplication.${applicationId}.${indexPath.resourceKey}.fields.${
                indexPath.subsectionIndex || 0
            }.${indexPath.fieldKey}`
        )
    })
    return (
        <Holder>
            <MerchantApplicationRowHolder indexPath={indexPath} label="Name">
                <Flex align="flex-start" grow>
                    {prefixMemo ? <PrefixHolder>{prefixMemo}</PrefixHolder> : null}
                    {isDataLoading ? (
                        <TextLineLoader height={27} />
                    ) : (
                        <MerchantApplicationEditsModalInput
                            initialValue={rawValue}
                            highlighted={highlighted}
                            formErrors={formErrors}
                            applicationId={applicationId}
                            indexPath={indexPath}
                            formRef={formRef}
                            superField={!smaller}
                            smallerSuperField={smaller}
                        />
                    )}
                    <MerchantApplicationFieldCounter
                        applicationId={applicationId}
                        type="edits"
                        indexPath={indexPath}
                        isPressed={false}
                        isActive={false}
                        sanitised={false}
                        highlighted={highlighted}
                        isLoading={false}
                        isLast
                    />
                </Flex>
            </MerchantApplicationRowHolder>
        </Holder>
    )
}

const Holder = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover ${MerchantApplicationFieldCounterButtonElement} > * {
        opacity: 1;
    }
`

const PrefixHolder = styled.div`
    margin-top: 1px;
    margin-right: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`
