import React, { useEffect, useMemo } from 'react'
import { Text } from '@/components/general/text'
import { LoaderView } from '@/components/loaders/loader'
import { useNavigate } from 'react-router-dom'
import { useClassificationParams, ClassificationProvider } from '@/features/risk-classification'
import { useGetClassification } from '@/services/risk-classification'
import { Card } from '@/components/cards/card'
import styled from 'styled-components'
import { getColor, getRiskLevelLocal } from '@/store/classifications/utils'
import { MerchantRiskLevel } from './RiskLevel'
import { CardInset } from '@/components/cards/cardInset'
import { Grid } from '@/components/layout/grid'
import { Flex } from '@/components/layout/flex'
import { Icon } from '@/components/icons/icon'
import { Spacer } from '@/components/layout/spacer'
import { CenteredScreen } from '@/components/layout/centeredScreen'
import { CenteredScreenHeader } from '@/components/layout/centeredScreenHeader'
import { InnerPageBackButton } from '@/components/buttons/innerPageBackButton'
import { Metadata } from './metadata'

export const Classification: React.FC = () => {
    const { classificationId, applicationId } = useClassificationParams()
    const { data, isLoading, isError } = useGetClassification(classificationId)

    const backButton = useMemo(() => {
        return <InnerPageBackButton backTo={`/merchant/${applicationId}/more`} suffix="More" />
    }, [applicationId])

    const navigate = useNavigate()

    useEffect(() => {
        if (isError) {
            navigate(-1)
        }
    }, [navigate, isError])

    if (isLoading) {
        return <LoaderView overBackground="front.background" type="l" minHeight="200" />
    }

    if (!data) {
        return <Text>No report data available</Text>
    }

    const categories: React.ReactElement[] = []

    const getOptions = (category) => {
        const options: React.ReactElement[] = []
        category = data.classification_data[category]
        for (const option in category) {
            if (category[option].value && category[option].value !== 'false') {
                options.push(
                    <Flex align="center" key={option}>
                        <Icon type="checkmark" size={14} />
                        <Spacer width={5} />
                        {category[option].label}
                        <Spacer width={5} />
                        {category[option].risk_score ? (
                            <MerchantRiskLevel color={getColor(getRiskLevelLocal(category[option].risk_score))}>
                                {category[option].risk_score}
                            </MerchantRiskLevel>
                        ) : (
                            ''
                        )}
                    </Flex>
                )
            }
        }

        if (options.length === 0) {
            return <span style={{ color: '#818D93' }}>-</span>
        }

        return options
    }

    const getRiskOptions = () => {
        for (const category in data.classification_data) {
            if (category !== 'Comment') {
                categories.push(
                    <div key={category}>
                        <LabelHolder>{category}</LabelHolder>
                        <ValueHolder>
                            <>{getOptions(category)}</>
                        </ValueHolder>
                    </div>
                )
            }
        }

        categories.some((category, i) => {
            if (category.key === 'Others') {
                return categories.push(categories.splice(i, 1)[0])
            }
        })

        return categories
    }

    return (
        <ClassificationProvider classification={data}>
            <CenteredScreen maxWidth={900}>
                <CenteredScreenHeader title="Risk Classifications" left={backButton} />
                <Container
                    title=" "
                    background="front.background"
                    leftSideMemo={
                        <MerchantRiskLevel color={getColor(data.risk_level)}>{data.risk_level}</MerchantRiskLevel>
                    }
                    rightSideMemo={<Metadata author={data.author} createdAt={data.createdAt} />}
                >
                    <CardInset>
                        <Grid horizontalTemplate="1fr">
                            <Grid horizontalTemplate="1fr">
                                <>
                                    {getRiskOptions()}
                                    <div>
                                        <LabelHolder>Comment</LabelHolder>
                                        {data.classification_data.Comment.comment.value ? (
                                            <ValueHolder>{data.classification_data.Comment.comment.value}</ValueHolder>
                                        ) : (
                                            <span style={{ color: '#818D93' }}>-</span>
                                        )}
                                    </div>
                                </>
                            </Grid>
                        </Grid>
                    </CardInset>
                </Container>
            </CenteredScreen>
        </ClassificationProvider>
    )
}

const Container = styled(Card)`
    margin-bottom: 20px;
`

const LabelHolder = styled.div`
    padding: 3px 0;
    font-size: 11px;
    color: ${(p) => p.theme['back.text.subtlerI']};

    > div {
        margin-left: 3px;
        opacity: 0.5;
    }
`
const ValueHolder = styled.div`
    padding: 3px 0;
`
