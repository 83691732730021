import React from 'react'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { CheckIcon, ChevronRightIcon, CircleIcon } from '../icon'
import { styles } from './styles'
import { cn } from '../utils'

interface DropdownProps {
    children: React.ReactNode
    defaultOpen?: boolean
    open?: boolean
    onOpenChange?: (open: boolean) => void
}

const Dropdown = (props: DropdownProps) => {
    const { children, defaultOpen, open, onOpenChange } = props

    return (
        <DropdownMenuPrimitive.Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
            {children}
        </DropdownMenuPrimitive.Root>
    )
}

interface TriggerProps {
    children: React.ReactNode
    asChild?: boolean
}

const Trigger = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.Trigger>, TriggerProps>((props, ref) => {
    const { children, asChild } = props

    return (
        <DropdownMenuPrimitive.Trigger asChild={asChild} ref={ref}>
            {children}
        </DropdownMenuPrimitive.Trigger>
    )
})

Trigger.displayName = 'Trigger'

interface GroupProps {
    children: React.ReactNode
    asChild?: boolean
}

const Group = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.Group>, GroupProps>((props, ref) => {
    const { children, asChild } = props

    return (
        <DropdownMenuPrimitive.Group asChild={asChild} ref={ref}>
            {children}
        </DropdownMenuPrimitive.Group>
    )
})

Group.displayName = 'Group'

interface PortalProps {
    children: React.ReactNode
    container?: HTMLElement
}

const Portal: React.FC<PortalProps> = (props) => {
    const { children, container } = props

    return <DropdownMenuPrimitive.Portal container={container}>{children}</DropdownMenuPrimitive.Portal>
}

Portal.displayName = 'Portal'

interface SubProps {
    children: React.ReactNode
    defaultOpen?: boolean
    open?: boolean
    onOpenChange?: (open: boolean) => void
}

const Sub: React.FC<SubProps> = (props) => {
    const { children, defaultOpen, open, onOpenChange } = props

    return (
        <DropdownMenuPrimitive.Sub defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
            {children}
        </DropdownMenuPrimitive.Sub>
    )
}

Sub.displayName = 'Sub'

interface RadioGroupProps {
    children: React.ReactNode
    value?: string
    onValueChange?: (value: string) => void
}

const RadioGroup = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.RadioGroup>, RadioGroupProps>(
    (props, ref) => {
        const { children, value, onValueChange } = props

        return (
            <DropdownMenuPrimitive.RadioGroup value={value} onValueChange={onValueChange} ref={ref}>
                {children}
            </DropdownMenuPrimitive.RadioGroup>
        )
    }
)

RadioGroup.displayName = 'RadioGroup'

interface SubTriggerProps {
    children: React.ReactNode
    asChild?: boolean
    disabled?: boolean
}

const SubTrigger = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>, SubTriggerProps>(
    (props, ref) => {
        const { children, asChild, disabled } = props

        const classnames = cn(styles.subTrigger())

        return (
            <DropdownMenuPrimitive.SubTrigger asChild={asChild} disabled={disabled} ref={ref} className={classnames}>
                {children}
                <ChevronRightIcon size="4" />
            </DropdownMenuPrimitive.SubTrigger>
        )
    }
)

SubTrigger.displayName = 'SubTrigger'

interface SubContent {
    children: React.ReactNode
    asChild?: boolean
    onEscapeKeyDown?: (event: KeyboardEvent) => void
    onPointerDownOutside?: React.ComponentProps<typeof DropdownMenuPrimitive.SubContent>['onPointerDownOutside']
    onFocusOutside?: React.ComponentProps<typeof DropdownMenuPrimitive.SubContent>['onFocusOutside']
    onInteractOutside?: React.ComponentProps<typeof DropdownMenuPrimitive.SubContent>['onInteractOutside']
}

const SubContent = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.SubContent>, SubContent>(
    (props, ref) => {
        const { children, asChild, onEscapeKeyDown, onPointerDownOutside, onFocusOutside, onInteractOutside } = props

        const classnames = cn(styles.subContent())

        return (
            <DropdownMenuPrimitive.SubContent
                asChild={asChild}
                ref={ref}
                onEscapeKeyDown={onEscapeKeyDown}
                onPointerDownOutside={onPointerDownOutside}
                onFocusOutside={onFocusOutside}
                onInteractOutside={onInteractOutside}
                className={classnames}
                sideOffset={20}
            >
                {children}
            </DropdownMenuPrimitive.SubContent>
        )
    }
)

SubContent.displayName = 'SubContent'

interface ContentProps {
    children: React.ReactNode
    asChild?: boolean
    side?: 'top' | 'right' | 'bottom' | 'left'
    onEscapeKeyDown?: (event: KeyboardEvent) => void
    onCloseAutoFocus?: React.ComponentProps<typeof DropdownMenuPrimitive.Content>['onCloseAutoFocus']
    onPointerDownOutside?: React.ComponentProps<typeof DropdownMenuPrimitive.SubContent>['onPointerDownOutside']
    onFocusOutside?: React.ComponentProps<typeof DropdownMenuPrimitive.SubContent>['onFocusOutside']
    onInteractOutside?: React.ComponentProps<typeof DropdownMenuPrimitive.SubContent>['onInteractOutside']
}

const Content = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.Content>, ContentProps>((props, ref) => {
    const { children, asChild, side, onEscapeKeyDown, onPointerDownOutside, onFocusOutside, onInteractOutside } = props

    const classnames = cn(styles.content())

    return (
        <DropdownMenuPrimitive.Portal>
            <DropdownMenuPrimitive.Content
                asChild={asChild}
                ref={ref}
                onEscapeKeyDown={onEscapeKeyDown}
                onPointerDownOutside={onPointerDownOutside}
                onFocusOutside={onFocusOutside}
                onInteractOutside={onInteractOutside}
                side={side}
                sideOffset={8}
                className={classnames}
            >
                {children}
            </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
    )
})

Content.displayName = 'Content'

interface ItemProps {
    children: React.ReactNode
    asChild?: boolean
    disabled?: boolean
    onSelect?: (event: Event) => void
}

const Item = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.Item>, ItemProps>((props, ref) => {
    const { children, asChild, disabled, onSelect } = props

    const classnames = cn(styles.item())

    return (
        <DropdownMenuPrimitive.Item
            asChild={asChild}
            disabled={disabled}
            onSelect={onSelect}
            ref={ref}
            className={classnames}
        >
            {children}
        </DropdownMenuPrimitive.Item>
    )
})

Item.displayName = 'Item'

interface CheckboxItemProps {
    children: React.ReactNode
    asChild?: boolean
    checked?: boolean
    onCheckedChange?: (checked: boolean) => void
    disabled?: boolean
    onSelect?: (event: Event) => void
}

const CheckboxItem = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>, CheckboxItemProps>(
    (props, ref) => {
        const { children, asChild, checked, onCheckedChange, disabled, onSelect } = props

        const classnames = cn(styles.checkboxItem())
        const indicatorClassnames = cn(styles.indicator())

        return (
            <DropdownMenuPrimitive.CheckboxItem
                asChild={asChild}
                checked={checked}
                onCheckedChange={onCheckedChange}
                disabled={disabled}
                onSelect={onSelect}
                ref={ref}
                className={classnames}
            >
                <DropdownMenuPrimitive.ItemIndicator className={indicatorClassnames}>
                    <CheckIcon color="white" size="4" />
                </DropdownMenuPrimitive.ItemIndicator>
                {children}
            </DropdownMenuPrimitive.CheckboxItem>
        )
    }
)

CheckboxItem.displayName = 'CheckboxItem'

interface RadioItemProps {
    children: React.ReactNode
    asChild?: boolean
    value: string
    disabled?: boolean
    onSelect?: (event: Event) => void
}

const RadioItem = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>, RadioItemProps>(
    (props, ref) => {
        const { children, asChild, value, disabled, onSelect } = props

        const classnames = cn(styles.radioItem())
        const indicatorClassnames = cn(styles.indicator())

        return (
            <DropdownMenuPrimitive.RadioItem
                asChild={asChild}
                value={value}
                disabled={disabled}
                onSelect={onSelect}
                ref={ref}
                className={classnames}
            >
                <DropdownMenuPrimitive.ItemIndicator className={indicatorClassnames}>
                    <CircleIcon size="4" />
                </DropdownMenuPrimitive.ItemIndicator>

                {children}
            </DropdownMenuPrimitive.RadioItem>
        )
    }
)

RadioItem.displayName = 'RadioItem'

interface LabelProps {
    children: React.ReactNode
    asChild?: boolean
}

const Label = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.Label>, LabelProps>((props, ref) => {
    const { children, asChild } = props

    const classnames = cn(styles.label())

    return (
        <DropdownMenuPrimitive.Label asChild={asChild} ref={ref} className={classnames}>
            {children}
        </DropdownMenuPrimitive.Label>
    )
})

Label.displayName = 'Label'

interface SeparatorProps {
    children: React.ReactNode
    asChild?: boolean
}

const Separator = React.forwardRef<React.ElementRef<typeof DropdownMenuPrimitive.Separator>, SeparatorProps>(
    (props, ref) => {
        const { children, asChild } = props

        const classnames = cn(styles.separator())

        return (
            <DropdownMenuPrimitive.Separator asChild={asChild} ref={ref} className={classnames}>
                {children}
            </DropdownMenuPrimitive.Separator>
        )
    }
)

Separator.displayName = 'Separator'

Dropdown.Trigger = Trigger
Dropdown.Group = Group
Dropdown.Portal = Portal
Dropdown.Sub = Sub
Dropdown.RadioGroup = RadioGroup
Dropdown.SubTrigger = SubTrigger
Dropdown.SubContent = SubContent
Dropdown.Content = Content
Dropdown.Item = Item
Dropdown.CheckboxItem = CheckboxItem
Dropdown.RadioItem = RadioItem
Dropdown.Label = Label
Dropdown.Separator = Separator

export { Dropdown }
