import { Box, Button, CrossIcon, Flex, Text } from '@/design-system'
import React, { useEffect } from 'react'

interface PageHeaderProps {
    title: string
    onBack: () => void
}

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
    const { title, onBack } = props

    const handleOnBack = () => {
        onBack()
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            onBack()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    return (
        <Box px="5" py="4">
            <Flex align="center" justify="between" asChild>
                <header>
                    <Button variant="ghost" color="neutral" onClick={handleOnBack}>
                        <CrossIcon />
                    </Button>
                    <Text weight="medium">{title}</Text>
                    <div />
                </header>
            </Flex>
        </Box>
    )
}
