import React, { useMemo, useCallback } from 'react'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { PageHeader } from '../../components/layout/pageHeader'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { LinkButton } from '../../components/buttons/linkButton'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { QueueFiltersParams, QueuesFilters } from '../../store/calls/types'
import { PopularFilters } from '../../components/taskPages/popularFilters'
import { CallsDispatchLoadQueues, CallDispatchRemoveQueueNumber } from '../../store/calls/actions'
import { RootState } from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import { useListPage } from '../../hooks/pages/useListPage'
import { useLocation } from 'react-router-dom'

const loadQueues = (filters: QueuesFilters) => CallsDispatchLoadQueues(filters)

export const Queues: React.FC = () => {
    const location = useLocation()

    const dispatch = useDispatch()

    const queuess = useSelector((state: RootState) => {
        return state.calls.queuePhones
    })
    const { filters, paginationNode } = useListPage<QueuesFilters>(
        'QueuesList',
        'Queues',
        loadQueues,
        QueueFiltersParams,
        queuess,
        '/queues/',
        location,
        { queue: '1' },
        undefined,
        undefined,
        undefined,
        undefined
    )

    const activeCallsLink = useMemo(() => {
        return <LinkButton to={`/calls/active-calls`}>See active calls</LinkButton>
    }, [])

    const popularFilters = useMemo(() => {
        return (
            <PopularFilters
                showHideController={filters.showHideController}
                key="filters"
                hotkeysScope="DisputesList"
                buttonLabel="Select line"
                config={[
                    'Line filters:',
                    {
                        label: 'Line 1',
                        action: () => {
                            filters.set({ queue: '1' })
                        }
                    },
                    {
                        label: 'Line 2',
                        action: () => {
                            filters.set({ queue: '2' })
                        }
                    },
                    {
                        label: 'Line 3',
                        action: () => {
                            filters.set({ queue: '3' })
                        }
                    }
                ]}
            />
        )
    }, [filters])

    const handleRemoveClick = useCallback((line_no?: any, queue?: any) => {
        dispatch(CallDispatchRemoveQueueNumber(queue, line_no))
    }, [])

    return (
        <>
            <PageHeader
                noBottomBorder
                title="Overview of local numbers"
                subtitle=""
                leftSideMemo={popularFilters}
                rightSideMemo={activeCallsLink}
            />
            <Card>
                <CardInset>
                    <Table
                        background="front.background"
                        cols={[{ text: 'Line' }, { text: 'Local number' }, { text: 'Remove' }]}
                        overrideText={`No phones are currently assigned in line ${filters.get.queue}.`}
                        columnLayout="80px auto 120px"
                        showLastRowBorder
                        displayLoader={queuess.loadingStatus === 'started'}
                        rows={queuess.all.map((q: string) => {
                            const t = queuess.at[q]
                            return {
                                type: 'normal' as const,
                                key: `phone${t}`,
                                items: [
                                    {
                                        node: <Text>{filters.get.queue}</Text>
                                    },
                                    {
                                        node: <Text>{t as any}</Text>
                                    },
                                    {
                                        node: (
                                            <SimpleButton
                                                background="front.danger.color"
                                                key={`${t}-del`}
                                                onClick={() => {
                                                    handleRemoveClick(t, filters.get.queue)
                                                }}
                                            >
                                                <ButtonInset>
                                                    <Text oneLine>Remove</Text>
                                                </ButtonInset>
                                            </SimpleButton>
                                        )
                                    }
                                ]
                            }
                        })}
                        emptyText={`No phones are currently assigned in line ${filters.get.queue}.`}
                    />
                </CardInset>
            </Card>
        </>
    )
}
