import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { useLayoutEffect, useMemo } from 'react'
import { usePopper } from 'react-popper'
import styled, { css } from 'styled-components'

import { zIndexes } from '../../styles/zIndexes'

export const Dropdown: React.FC<{
    anchor: HTMLElement
    children: any
    show?: boolean
    isHigher?: boolean
    cy?: string
    overrideContentMemo?: React.ReactChild
}> = ({ anchor, show, children, isHigher, cy, overrideContentMemo }) => {
    const [popperElement, setPopperElement] = React.useState<any>(null)
    const modifiers = useMemo(() => {
        return {
            strategy: 'fixed',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 0]
                    }
                },
                {
                    name: 'sameWidth',
                    enabled: true,
                    fn: ({ state }: { state: any }) => {
                        state.styles.popper.minWidth = `${state.rects.reference.width}px`
                    },
                    phase: 'beforeWrite',
                    requires: ['computeStyles'],
                    effect({ state }: any): any {
                        state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`
                    }
                },
                ...(anchor?.closest('[data-floater-boundary]')
                    ? [
                          {
                              name: 'preventOverflow',
                              options: {
                                  padding: 15,
                                  boundary: anchor?.closest('[data-floater-boundary]')
                              }
                          }
                      ]
                    : [])
            ]
        } as any
    }, [anchor])

    const { styles, attributes, update } = usePopper(anchor, popperElement, modifiers)

    useLayoutEffect(() => {
        update?.()
    }, [update, modifiers, children])

    if (!show) return null

    return (
        <Element
            isHigher={isHigher}
            ref={setPopperElement}
            className="PREVENT_CLOSING"
            tabIndex={-1}
            style={{ ...styles.popper }}
            {...attributes.popper}
        >
            <ScrollMask className="os-theme-dark">
                <ContentHolder data-cy={cy}>
                    <div
                        style={{
                            display: overrideContentMemo ? 'block' : 'none'
                        }}
                    >
                        {overrideContentMemo}
                    </div>
                    <div
                        style={{
                            display: overrideContentMemo ? 'none' : 'block'
                        }}
                    >
                        {children}
                    </div>
                </ContentHolder>
            </ScrollMask>
        </Element>
    )
}

const ContentHolder = styled.div`
    padding: 0 15px;
`

const ScrollMask = styled(OverlayScrollbarsComponent as any)`
    padding: 0;
    max-height: 40vh;
    overflow-y: scroll;
    overscroll-behavior-y: none;
`

const Element = styled.div<{ isHigher?: boolean }>`
    background-color: #000;
    color: #fff;
    box-sizing: border-box;

    background: ${(p) => p.theme['floating.background']};
    z-index: ${zIndexes.popoverLevel3};

    overflow: hidden;

    ${(p) =>
        p.isHigher &&
        css`
            background: ${(p) => p.theme['floating.background.strongerI']};
        `}

    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.07),
        0px 17px 11px rgba(27, 43, 98, 0.08) !important;

    &[data-popper-placement^='top'] {
        border-radius: 10px 10px 4px 4px;
    }
    &[data-popper-placement^='bottom'] {
        border-radius: 4px 4px 10px 10px;
    }
`
