import { createReducer } from 'deox'
import dotProp from 'dot-prop'
import { produce } from 'immer'
import { AvailableThemes } from '../../hooks/general/useTheme'

import { InterfaceActions } from './actions'
import { InitialInterfaceState } from './types'

export const InterfaceReducer = createReducer(InitialInterfaceState, (handleAction) => [
    handleAction(InterfaceActions.STORE_RECOMMENDED_MERCHANT_COMMENT_FIELD, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.applications[p.applicationId].merchantCommentField = {
                indexPathString: p.indexPathString,
                subsectionId: p.subsectionId
            }
        })
    ),
    handleAction(InterfaceActions.SET_APPLICATION_STEP_STATE, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.applications[p.applicationId].steps = {
                ...draft.applications[p.applicationId].steps,
                [p.stepId]: {
                    ...draft.applications[p.applicationId].steps[p.stepId],
                    isCancelled: p.cancelled
                }
            }
        })
    ),
    handleAction(InterfaceActions.FINISH_APPLICATION_REVIEW, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.applications[p.applicationId] = {
                shouldReviewChanges: false,
                merchantCommentField: undefined,
                changes: {},
                steps: {
                    'merchant-message': { isCancelled: true },
                    'internal-note': { isCancelled: true },
                    'remove-collaborator': { isSuggested: false, isCancelled: true },
                    'add-collaborator': { isSuggested: false, isCancelled: true }
                }
            }
        })
    ),
    handleAction(InterfaceActions.START_APPLICATION_REVIEW, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.applications[p.applicationId] = {
                ...(draft.applications[p.applicationId] || {}),
                shouldReviewChanges: true,
                changes: p.changes
            }

            const previousSignerEmail = p.changes['signer.0.signer.email']?.from
            const newSignerEmail = p.changes['signer.0.signer.email']?.to
            const newSignerName = p.changes['signer.0.signer.name']?.to
            const isChangingSignerEmail = p.changes['signer.0.signer.email']
            const previousSignerEmailCollaborator = p.collaborators?.find(
                (c) => c.email?.toLowerCase() == previousSignerEmail?.toLowerCase()
            )
            const newCollaboraatorAlreadyExist = p.collaborators?.find(
                (c) => c.email?.toLowerCase() == newSignerEmail?.toLowerCase()
            )

            if (!draft.applications[p.applicationId].steps)
                draft.applications[p.applicationId].steps = {
                    'merchant-message': { isCancelled: true },
                    'internal-note': { isCancelled: true },
                    'remove-collaborator': {
                        isSuggested: false,
                        isCancelled: true
                    },
                    'add-collaborator': {
                        isSuggested: false,
                        isCancelled: true
                    }
                }

            if (isChangingSignerEmail && !newCollaboraatorAlreadyExist) {
                draft.applications[p.applicationId].steps['add-collaborator'] = {
                    isSuggested: true,
                    isCancelled: false,
                    email: newSignerEmail,
                    name: newSignerName || p.previousSignerName
                }
            }

            if (previousSignerEmailCollaborator && newSignerEmail != previousSignerEmail)
                draft.applications[p.applicationId].steps['remove-collaborator'] = {
                    isSuggested: true,
                    isCancelled: true,
                    email: previousSignerEmailCollaborator.email,
                    selfLink: previousSignerEmailCollaborator.selfLink
                }
        })
    ),
    handleAction(InterfaceActions.SET_SHOW_INTERACTIONS_DATE, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.showInteractionsDate = p.show
        })
    ),
    handleAction(InterfaceActions.CANCEL_APPLICATION_REVIEW, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.applications[p.applicationId] = {
                ...(draft.applications[p.applicationId] || {}),
                shouldReviewChanges: false
            }
        })
    ),
    handleAction(InterfaceActions.SWITCH_THEME, (state, { payload: p }) =>
        produce(state, (draft) => {
            localStorage.setItem('previousTheme', state.theme)
            localStorage.setItem('theme', p.theme)

            if (p.theme === 'darkTheme') {
                document.documentElement.classList.add('dark')
            } else {
                document.documentElement.classList.remove('dark')
            }

            if (AvailableThemes.includes(p.theme)) draft.theme = p.theme
        })
    ),
    handleAction(InterfaceActions.CLEAR, (state) =>
        produce(state, (draft) => {
            dotProp.set(draft, `application`, {})
        })
    ),
    handleAction(InterfaceActions.UPDATE_NAME, (state, { payload: p }) =>
        produce(state, (draft) => {
            const modal = draft.modals.stack.find((m) => m.modalId === p.modalId)
            if (modal) modal.title = p.title
            if (p.modalId === state.modals.frontmost && p.title !== null) document.title = p.title
        })
    ),
    handleAction(InterfaceActions.PUSH_MODAL, (state, { payload: p }) =>
        produce(state, (draft) => {
            const alternatePriority = draft.modals?.stack?.[draft.modals?.stack?.length - 1]?.priority + 1 || 0
            draft.modals.stack.push({
                modalId: p.modalId,
                priority: p.priority !== undefined ? p.priority : alternatePriority,
                title: p.title || 'CLH Console'
            })
            if (p.title !== null) document.title = p.title || 'CLH Console'
            draft.modals.stack.sort((a, b) => (a.priority > b.priority ? 1 : -1))
            draft.modals.frontmost = draft.modals.stack[draft.modals.stack.length - 1]?.modalId
            draft.modals.size = draft.modals.stack.length
        })
    ),
    handleAction(InterfaceActions.POP_MODAL, (state, { payload: p }) =>
        produce(state, (draft) => {
            let lastIndex = -1
            draft.modals.stack.forEach((m, index) => {
                if (m.modalId === p.modalId) lastIndex = index
            })
            if (lastIndex >= 0) draft.modals.stack.splice(lastIndex, 1)
            draft.modals.frontmost = draft.modals.stack[draft.modals.stack.length - 1]?.modalId
            if (draft.modals.stack[draft.modals.stack.length - 1]?.title !== null)
                document.title = draft.modals.stack[draft.modals.stack.length - 1]?.title || 'CLH Console'

            draft.modals.size = draft.modals.stack.length
        })
    ),
    handleAction(InterfaceActions.SHOW_KEYTIPS, (state) =>
        produce(state, (draft) => {
            draft.showKeyTips = true
        })
    ),
    handleAction(InterfaceActions.HIDE_KEYTIPS, (state) =>
        produce(state, (draft) => {
            draft.showKeyTips = false
        })
    ),
    handleAction(InterfaceActions.SET_MARKDOWN_PREVIEW, (state, { payload }) =>
        produce(state, (draft) => {
            draft.markdownPreview[payload.inputId] = payload.markdown
        })
    ),
    handleAction(InterfaceActions.CLEAR_MARKDOWN_PREVIEW, (state, { payload }) =>
        produce(state, (draft) => {
            delete draft.markdownPreview[payload.inputId]
        })
    ),
    handleAction(InterfaceActions.START_CRITICAL_FLOW, (state, { payload }) =>
        produce(state, (draft) => {
            localStorage.setItem('criticalFlow', JSON.stringify(payload.flow))
            draft.criticalFlow = payload.flow
        })
    ),
    handleAction(InterfaceActions.COMPLETE_CRITICAL_FLOW, (state) =>
        produce(state, (draft) => {
            localStorage.removeItem('criticalFlow')
            delete draft.criticalFlow
        })
    ),
    handleAction(InterfaceActions.REPLACE_CRITICAL_FLOW, (state, { payload }) =>
        produce(state, (draft) => {
            if (!state.criticalFlow) return draft
            draft.criticalFlow = {
                ...draft.criticalFlow,
                ...payload.flow
            }
            localStorage.setItem('criticalFlow', JSON.stringify(draft.criticalFlow))
        })
    )
])
