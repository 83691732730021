import styled, { css } from 'styled-components'

import { IconExternalLink } from '../icons/iconExternalLink'
import { ButtonSize } from './types'
import { useCallback, useMemo, useState } from 'react'
import { Theme } from '../../hooks/general/useTheme'
import { safeWindowOpen } from '../../utils'
import { Text } from '../general/text'

export const GetExternalLinkTargetName = (t: ExternalLinkTargets) => {
    if (t === 'none') return undefined
    if (t === 'dashboard') return 'clrhsDashboard'
    if (t === 'tasks') return 'clrhsTasks'
    if (t === 'apply') return 'clrhsApplication'
    if (t === 'managerSettlements') return 'clrhsManagerSettlements'
    if (t === 'managerTransactions') return 'clrhsManagerTransactions'
    if (t === 'managerTransaction') return 'clrhsManagerTransactions'
    if (t === 'consoleApplication') return 'clrhsConsoleApplication'
    if (t === 'applicationRelatedWindow') return 'clrhsApplicationRelatedWindow'
    if (t === 'blank') return '_blank'
    if (t === 'krak') return 'krak'
    if (t === 'oldManagerApplication') return 'clrhsOldManagerApplication'
}

export type ExternalLinkTargets =
    | 'none'
    | 'dashboard'
    | 'tasks'
    | 'apply'
    | 'blank'
    | 'managerTransactions'
    | 'managerSettlements'
    | 'managerTransaction'
    | 'krak'
    | 'applicationRelatedWindow'
    | 'oldManagerApplication'
    | 'consoleApplication'
    | 'managerTransaction'
    | 'oldManagerApplication'
export const ExternalLink: React.FC<{
    label?: string
    children?: React.ReactNode
    onClick?: () => void
    target?: ExternalLinkTargets
    style?: React.CSSProperties
    url?: string
    accentColor?: keyof Theme
    size?: ButtonSize
    defaultColor?: keyof Theme
    underlineLess?: boolean
    scriptBased?: boolean
    className?: string
    alwaysShowArrow?: boolean
    arrowLess?: boolean
}> = ({
    url,
    className,
    accentColor,
    defaultColor,
    target,
    scriptBased,
    alwaysShowArrow,
    underlineLess,
    arrowLess,
    onClick,
    children,
    label
}) => {
    const [show, setShow] = useState(false)
    const handleClick = (e: React.MouseEvent) => {
        if (!url) {
            e.preventDefault()
        }

        if (onClick) {
            onClick()
        }
    }

    const onMouseIn = useCallback(() => {
        setShow(true)
    }, [])

    const onMouseOut = useCallback(() => {
        setShow(false)
    }, [])

    const renderedIcon = useMemo(() => {
        if (alwaysShowArrow) return <IconExternalLink size={13} />
        if (arrowLess) return null
        if (show) return <IconExternalLink size={13} />
        return null
    }, [show, alwaysShowArrow, arrowLess])

    const handleScriptBasedClick = useCallback(
        (e: any) => {
            if (!scriptBased) return
            if (!url) return
            e.preventDefault()
            e.stopPropagation()
            safeWindowOpen(url)
        },
        [url, scriptBased]
    )

    if (scriptBased)
        return (
            <ScriptElement
                onClick={handleScriptBasedClick}
                defaultColor={defaultColor}
                underlineLess={underlineLess}
                accentColor={accentColor}
                onMouseEnter={onMouseIn}
                onMouseLeave={onMouseOut}
            >
                <Holder>
                    <Text>{children || label}</Text>
                </Holder>
                <IconContainer>{renderedIcon}</IconContainer>
            </ScriptElement>
        )

    return (
        <Element
            target={target ? GetExternalLinkTargetName(target) : undefined}
            onClick={handleClick}
            href={url}
            className={className}
            defaultColor={defaultColor}
            rel="noopener noreferrer nofollow"
            onMouseEnter={onMouseIn}
            onMouseLeave={onMouseOut}
            underlineLess={underlineLess}
            accentColor={accentColor}
        >
            <Holder>{children || label}</Holder>
            <IconContainer>{renderedIcon}</IconContainer>
        </Element>
    )
}
const IconContainer = styled.div`
    position: relative;
    display: inline;
    top: 3px;
`

const Holder = styled.span`
    margin-right: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 27em;
    white-space: nowrap;
`

const ScriptElement = styled.div<{ underlineLess?: boolean; defaultColor?: keyof Theme; accentColor?: keyof Theme }>`
    color: ${(p) => p.theme[p.defaultColor || 'front.accent.color']};
    text-decoration: none;
    display: inline-flex;
    word-break: break-all;

    &:hover {
        text-decoration: underline;
    }
`

const Element = styled.a<{ underlineLess?: boolean; defaultColor?: keyof Theme; accentColor?: keyof Theme }>`
    color: ${(p) => p.theme[p.defaultColor || 'front.accent.color']};
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    word-break: break-all;

    &:hover {
        text-decoration: underline;
    }

    ${(p) =>
        p.accentColor &&
        css`
            &:hover {
                text-decoration: none;
                color: ${p.theme[p.accentColor as keyof Theme]};
            }

            &:active {
                color: ${p.theme[(p.accentColor + '.strongerI') as keyof Theme]};
            }
        `}
    ${(p) =>
        p.underlineLess &&
        css`
            color: ${p.theme[p.defaultColor || 'back.text']};

            &:hover {
                text-decoration: none;
                color: ${p.theme['front.accent.color.strongerI']};
            }

            &:active {
                color: ${p.theme['front.accent.color.strongerII']};
            }
        `}
`
