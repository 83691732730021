import { VariantProps, cva } from 'class-variance-authority'

export type CardStyles = VariantProps<typeof styles.card>

export const styles = {
    card: cva('w-full rounded-xl text-black dark:text-white', {
        variants: {
            variant: {
                default: 'shadow-md shadow-primary-900/5 dark:bg-gray-900 dark:shadow-gray-900/20',
                surface: ''
            },
            color: {
                primary: 'bg-primary-50 dark:bg-primary-50',
                neutral: 'bg-neutral-50 dark:bg-neutral-50/5',
                success: 'bg-success-50 dark:bg-success-900',
                warning: 'bg-warning-50 dark:bg-warning-900/50',
                notice: 'bg-notice-100 dark:bg-notice-900/50',
                danger: 'bg-danger-100 dark:bg-danger-900/50',
                white: 'bg-white',
                black: 'bg-black'
            }
        },
        defaultVariants: {
            variant: 'default',
            color: 'white'
        }
    }),
    body: cva('', {
        variants: {
            size: {
                sm: 'p-4',
                md: 'p-6',
                lg: 'p-8'
            }
        }
    }),
    footer: cva('rounded-b-xl bg-primary-50 border-t border-neutral-100 dark:bg-gray-800 dark:border-gray-700', {
        variants: {
            size: {
                sm: 'p-4',
                md: 'p-6',
                lg: 'p-8'
            }
        }
    })
}
