import { PartnerGuidelinesTooltip } from '@/features/partner-guidelines'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Table } from '../../../components/tables/table'
import { DialogContext } from '../../../contexts/dialogProvider'
import { ApplicationInternalsReferralActions } from '../../../store/applicationInternals/referral/actions'
import { RootState } from '@/store'
import { ApplicationDedicatedInternalSectionWrapper } from './Application.DedicatedInternalSectionWrapper'
import { DedicatedSectionProps, useDedicatedSection } from './Application.DedicatedSection'
import { MerchantApplicationInternalDetails } from './Application.InternalDetails'
import { MerchantApplicationSectionGrid } from './Application.SectionGrid'

export const MerchantApplicationDedicateSectionReferralPartner: React.FC<DedicatedSectionProps> = ({
    applicationId
}) => {
    const { structure } = useDedicatedSection(applicationId, 'Websites')
    const dialogContext = useContext(DialogContext)
    const referrals = useSelector((state: RootState) => state.applicationInternals.referral[applicationId])
    const dispatch = useDispatch()

    const handleRemoveClick = useCallback(
        (partnerId, generatedWatcherId) => {
            dialogContext?.setDialog({
                title: 'Confirm deletion',
                description: 'Are you sure you want to delete this section permanently?',
                action: {
                    label: 'Delete permanently',
                    buttonBackground: 'front.danger.color',
                    watcherId: generatedWatcherId,
                    action: () => {
                        dispatch(
                            ApplicationInternalsReferralActions.REMOVE(applicationId, partnerId, generatedWatcherId)
                        )
                    }
                }
            })
        },
        [dialogContext, dispatch, applicationId]
    )

    useEffect(() => {
        if (referrals?.loadingStatus !== 'started' && referrals?.loadingStatus !== 'done')
            dispatch(ApplicationInternalsReferralActions.FETCH(applicationId))
    }, [applicationId, dispatch, referrals])

    const addButton = useMemo(() => {
        return (
            <LinkButton
                color="front.accent.color"
                to={`/merchant/${applicationId}/more/add-referral`}
                hotkeysScope="Merchant.SettingsPage"
                hotkeys="alt+n"
                keepQuery
            >
                <ButtonInset leftAlign noVerticalPadding noHorizontalPadding>
                    + Add
                </ButtonInset>
            </LinkButton>
        )
    }, [applicationId])

    const referralPartners = useMemo(() => {
        return (
            <Table
                noHeader
                background="back.background.strongerI"
                cols={[
                    {
                        text: 'Partner Name',
                        loaderSize: {
                            min: 30,
                            max: 110
                        }
                    },
                    {
                        text: 'Actions',
                        alignRight: true,
                        loaderSize: {
                            min: 100,
                            max: 100
                        }
                    }
                ]}
                displayLoader={referrals?.loadingStatus !== 'done'}
                columnLayout="1fr min-content"
                overrideText="No referral partners have been assigned."
                loaderRows={2}
                type="narrow"
                noHighlights
                style={{ marginTop: 10 }}
                rows={referrals?.activeReferrals?.map((r) => {
                    return {
                        type: 'normal',
                        noAction: true,
                        key: r.id,
                        items: [
                            {
                                node: <Text>{r.name}</Text>
                            },
                            {
                                node: (
                                    <Text>
                                        <WatcherButton
                                            color="front.danger.color"
                                            onClick={(e, generatedId) => handleRemoveClick(r.id, generatedId)}
                                        >
                                            <ButtonInset noVerticalPadding noHorizontalPadding>
                                                Remove
                                            </ButtonInset>
                                        </WatcherButton>
                                    </Text>
                                )
                            }
                        ]
                    }
                })}
            />
        )
    }, [referrals, handleRemoveClick])

    const referralIds = useMemo(() => {
        if (!referrals || !referrals.activeReferrals) {
            return []
        }

        return referrals?.activeReferrals?.map((r) => r.id)
    }, [referrals])

    return (
        <>
            <Holder id="websites">
                <Card showEditable>
                    <CardInset>
                        <DynamicSectionHolder>
                            <MerchantApplicationSectionGrid key="grid">
                                <Flex>
                                    <Text color="front.text.subtlerI">Referral partner</Text>
                                    <PartnerGuidelinesTooltip gatewayIds={referralIds} />
                                </Flex>
                                {referralPartners}
                                <span></span>
                                <span></span>
                                {addButton}
                            </MerchantApplicationSectionGrid>
                        </DynamicSectionHolder>
                    </CardInset>
                </Card>
            </Holder>
            <Flex column>
                <ApplicationDedicatedInternalSectionWrapper>
                    <MerchantApplicationInternalDetails sectionStructure={structure} applicationId={applicationId} />
                </ApplicationDedicatedInternalSectionWrapper>
            </Flex>
        </>
    )
}

const Holder = styled.div`
    position: relative;
`
const DynamicSectionHolder = styled.div`
    display: contents;
`
