import { createActionCreator } from 'deox'

import { MCC } from './types'

const Prefix = 'AUTOCOMPLETE'

export const MCCActions = {
    MCC_FIND: createActionCreator(
        `${Prefix}/MCC_FIND`,
        (resolve) => (query: string) => resolve({ query })
    ),
    MCC_STORE: createActionCreator(
        `${Prefix}/MCC_STORE`,
        (resolve) => (list: MCC[]) => resolve({ list })
    ),
    MCC_CLEAR: createActionCreator(`${Prefix}/MCC_CLEAR`, (resolve) => () =>
        resolve()
    )
}
