import React from 'react'
import styled from 'styled-components'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flag } from '../../../components/icons/flag'
import { SchemesIcon } from '../../../components/icons/schemesIcon'
import { Flex } from '../../../components/layout/flex'
import { Separator } from '../../../components/layout/separator'
import { Spacer } from '../../../components/layout/spacer'

import countries from 'i18n-iso-countries'
import { PastInquiry } from '../../../store/applicationInternals/schemesScreening/types'
import { LinkButton } from '../../../components/buttons/linkButton'
import moment from 'moment'

export const ApplicationSchemesScreeningPanelPastInquiriesBlock: React.FC<{
    applicationId: string
    r: PastInquiry
    handleSelectClick?: (rId: string) => void
    isSelected?: boolean
}> = ({ r, isSelected, applicationId, handleSelectClick }) => {
    return (
        <React.Fragment key={r.pastInquiryId}>
            <Card background="front.background">
                <CardInset type="small">
                    <Flex fullWidth justify="space-between">
                        <Flex column align="stretch">
                            <Flex fullWidth>
                                <Flex noShrink>
                                    <Flag
                                        code={
                                            r.company?.country?.length == 3
                                                ? countries.alpha3ToAlpha2(r.company?.country?.toUpperCase())
                                                : countries.getAlpha2Code(r.company?.country?.toUpperCase(), 'en')
                                        }
                                    />
                                </Flex>
                                <Spacer width={10} />
                                <Flex column fullWidth>
                                    <Flex>
                                        <Text bold>{r.company?.name || 'Unnamed company'}</Text>
                                        <Text color="front.text.subtlerI">
                                            &nbsp;&middot;&nbsp;
                                            {r.company?.registrationId || 'No ID'}
                                        </Text>
                                    </Flex>
                                    <Spacer width={4} />
                                    <Flex>
                                        <LinkButton
                                            color="front.accent.color"
                                            // eslint-disable-next-line max-len
                                            to={`/merchant/${applicationId}/application/screening-results/${r.pastInquiryId}`}
                                        >
                                            See details
                                        </LinkButton>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Spacer height={15} />
                    <Separator />
                    <Spacer height={15} />
                    <Flex fullWidth>
                        <SchemeHolder>
                            <SchemesIcon scheme="mastercard" />
                        </SchemeHolder>
                        <Spacer width={10} />
                        <Flex column shrink>
                            <ResultDescription>
                                <Text color="front.danger.color" bold>
                                    #{r?.termination?.reasonCode}{' '}
                                    {r?.termination?.reasonDescription || ' Termination reason unknown'}
                                </Text>
                            </ResultDescription>
                            <Text color="front.text.subtlerI">
                                Reported by {r.acquirer.acquirerId} on{' '}
                                {moment(r.acquirer.dateAdded).format('DD MMM, YYYY')}
                            </Text>
                        </Flex>
                    </Flex>
                </CardInset>
            </Card>
            <Spacer height={10} />
        </React.Fragment>
    )
}

const SchemeHolder = styled.div`
    min-width: 40px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    margin-top: 2px;
`

const ResultDescription = styled.span`
    margin-top: -1px;
`
