import { MerchantData } from '@/services/ongoing-due-diligence'
import { getCompanyRegister } from '@/utils/companyRegister'
import { googleSearch } from '@/utils/googleSearch'
import { trustpilotSearch } from '@/utils/trustpilotSearch'
import React from 'react'
import { Section } from '../../../common/section'

interface CompanyLinksProps {
    merchantData: MerchantData
}

export const CompanyLinks: React.FC<CompanyLinksProps> = (props) => {
    const { merchantData } = props

    const { name, country = '', registration_number = '' } = merchantData.company

    // Google link
    const googleLink = name && googleSearch(name)

    // Trustpilot link
    const trustpilotLink = name && trustpilotSearch(name)

    // Company reg link
    const { url } = getCompanyRegister(country, registration_number)

    // Periscope link
    const periscopeLink = 'https://app.periscopedata.com/app/clearhaus/425335'

    return (
        <>
            <Section.ExternalLink url={googleLink}>Google</Section.ExternalLink>
            <Section.Spacer size="s" />
            <Section.ExternalLink url={periscopeLink}>Periscope</Section.ExternalLink>
            <Section.Spacer size="s" />
            <Section.ExternalLink url={trustpilotLink}>Trustpilot</Section.ExternalLink>
            <Section.Spacer size="s" />
            <Section.ExternalLink url={url}>National company registration</Section.ExternalLink>
        </>
    )
}
