import React from 'react'
import { MonitoredChange, MonitoredFetchedApplicationData } from '../../../../../../store/tasksCompanyChanges/types'
import { MonitoringTemplate } from '../MonitoringTemplate'

import { Changes } from './components/Changes'
import { ApplicationButton } from '../../components/ApplicationButton'
import { camelCase, isNumber, isString } from 'lodash'
import { findKeyValueInObject } from '../../../../../../utils/objects'
import { CompanyRegistrationLink } from '../../components/CompanyRegistrationLink'

interface MonitoringOtherChangesProps {
    taskId: string
    changes: MonitoredChange[]
    applicationData: MonitoredFetchedApplicationData
}

export const MonitoringOtherChanges: React.FC<MonitoringOtherChangesProps> = (props) => {
    const { taskId, changes, applicationData } = props

    const isAllComplete = changes.every((change) => {
        const pathSplit = change.field.split('/').filter((s) => s)
        const fieldCamelCase = camelCase(pathSplit[1])

        const after = isString(change.after) || isNumber(change.after) ? change.after.toString() : null

        const subsectionObject = findKeyValueInObject(applicationData, 'id', change.subsectionId)
        const applicationValue = subsectionObject ? subsectionObject[fieldCamelCase] : null

        const isComplete = applicationValue !== null && applicationValue === after

        return isComplete
    })

    const isAllMuted = changes.every((change) => {
        const pathSplit = change.field.split('/').filter((s) => s)
        const sectionCamelCase = camelCase(pathSplit[0])
        const fieldCamelCase = camelCase(pathSplit[1])

        const after = isString(change.after) || isNumber(change.after) ? change.after.toString() : null

        const mutedValues = applicationData?.muted?.[sectionCamelCase]?.[fieldCamelCase]
            ? applicationData?.muted[sectionCamelCase]?.[fieldCamelCase]
            : []

        const isMuted = after ? (mutedValues || []).includes(after) : false

        return isMuted
    })

    const status = isAllComplete ? 'complete' : isAllMuted ? 'muted' : 'in-progress'

    return (
        <MonitoringTemplate status={status} type="other">
            <MonitoringTemplate.Container>
                <MonitoringTemplate.Status />
                <MonitoringTemplate.Main>
                    <MonitoringTemplate.Content>
                        <MonitoringTemplate.Description>
                            Some resource fields have changed.
                        </MonitoringTemplate.Description>
                        <MonitoringTemplate.Actions>
                            <MonitoringTemplate.Action>
                                <ApplicationButton applicationId={applicationData?.application.id} />
                            </MonitoringTemplate.Action>
                            <MonitoringTemplate.Action>
                                <CompanyRegistrationLink company={applicationData?.company} />
                            </MonitoringTemplate.Action>
                        </MonitoringTemplate.Actions>
                    </MonitoringTemplate.Content>
                    <MonitoringTemplate.Change>
                        <Changes taskId={taskId} changes={changes} applicationData={applicationData} />
                    </MonitoringTemplate.Change>
                </MonitoringTemplate.Main>
            </MonitoringTemplate.Container>
        </MonitoringTemplate>
    )
}
