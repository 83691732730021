import { Card, Pre, Text } from '@/design-system'
import React from 'react'

interface TransactionRulesProps {
    transactionRules: string
}

export const TransactionRules: React.FC<TransactionRulesProps> = (props) => {
    const { transactionRules } = props

    return (
        <Card variant="surface" color="neutral">
            <Card.Body>
                <Pre whiteSpace="preWrap">
                    <Text family="mono" size="sm">
                        {transactionRules}
                    </Text>
                </Pre>
            </Card.Body>
        </Card>
    )
}
