import { Report, Reports, UpdateReport } from '../types/reports'

import { api } from '../api'

export const reportsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getReports: build.query<Reports, string>({
            query: (applicationId: string) => `/applications/${applicationId}/odd/reports`,
            providesTags: ['reports']
        }),
        getReport: build.query<Report, string>({
            query: (reportId: string) => `/reports/${reportId}`,
            providesTags: ['report']
        }),
        createReport: build.mutation<Report, { applicationId: string; author?: string }>({
            query: ({ applicationId, author }) => ({
                url: `/applications/${applicationId}/odd/reports`,
                method: 'POST',
                body: {
                    ...(author && { author })
                }
            }),
            invalidatesTags: ['reports']
        }),
        updateReport: build.mutation<Report, { reportId: string; data: UpdateReport }>({
            query: ({ reportId, data }) => ({
                url: `/reports/${reportId}`,
                method: 'PUT',
                body: {
                    ...data
                }
            }),
            async onQueryStarted({ reportId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedReport } = await queryFulfilled

                    dispatch(
                        reportsApi.util.updateQueryData('getReport', reportId, (draft) => {
                            Object.assign(draft, updatedReport)
                        })
                    )
                } catch {
                    console.error('onQueryStarted on endpoint updateReport failed')
                }
            },
            invalidatesTags: ['reports']
        }),
        deleteReport: build.mutation<Report, { reportId: string }>({
            query: ({ reportId }) => ({ url: `/reports/${reportId}`, method: 'DELETE' }),
            invalidatesTags: ['reports']
        }),
        finalizeReport: build.mutation<void, { reportId: string }>({
            query: ({ reportId }) => ({ url: `/reports/${reportId}/finalize`, method: 'POST' }),
            invalidatesTags: ['reports', 'report']
        })
    })
})

export const {
    useGetReportsQuery: useGetReports,
    useGetReportQuery: useGetReport,
    useCreateReportMutation: useCreateReport,
    useUpdateReportMutation: useUpdateReport,
    useDeleteReportMutation: useDeleteReport,
    useFinalizeReportMutation: useFinalizeReport
} = reportsApi
