import styled, { css } from 'styled-components'

export const ToggleBar: React.FC<{
    activeKey?: any
    options: {
        [label: string]: any
    }
    onClick: (value: string) => void
}> = ({ activeKey, options, onClick }) => {
    return (
        <Holder active={activeKey}>
            {Object.keys(options).map((t) => {
                return (
                    <Item
                        key={t}
                        data-cy={activeKey === options[t] ? 'toggle-selected' : 'toggle-unselected'}
                        active={activeKey === t}
                        onClick={() => onClick(t)}
                    >
                        {options[t]}
                    </Item>
                )
            })}
        </Holder>
    )
}

const Holder = styled.div<{ active?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: ${(p) => p.theme['back.background']};
    color: ${(p) => p.theme['front.text']};
    border-radius: 10px;
    padding: 5px;
`

const Item = styled.div<{ active?: boolean }>`
    font-size: 12px;
    text-align: center;
    min-width: 30px;
    padding: 10px;
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 0;
    border-radius: 10px;

    &:hover {
        background-color: ${(p) => p.theme['back.background.strongerI']};
    }
    &:active {
        background-color: ${(p) => p.theme['back.background.strongerII']};
    }

    ${(p) =>
        p.active &&
        css`
            background-color: ${(p) => p.theme['front.background']} !important;
            box-shadow: 0px 3px 6px rgba(80, 80, 90, 0.2);
            font-weight: bold;
            cursor: default;
        `}
`
