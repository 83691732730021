import { Collection } from '../../utils'
import { DraftContract } from '../contracts/types'
import { LocallyStoredDraftContract } from '../merchantAccounts/types'

export interface ContractTemplate extends Omit<DraftContract, 'isDraft' | 'application'> {
    label: string
    standard: boolean
    gatewayId: string
}
export interface LocallyStoredContractTemplate extends LocallyStoredDraftContract {
    label: string
    standard: boolean
    gatewayId: string
}

export type ContractTemplatesState = Collection<LocallyStoredContractTemplate>

export const InitialContractTemplatesState: ContractTemplatesState = {
    at: {},
    all: [],
    loadingStatus: 'not-started'
}
