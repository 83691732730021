import { CutterCompany } from '@/store/applicationInternals/company/types'
import { ApplicationResourceData } from '@/store/applicationResources/types'
import { generateJWTSync } from '@/store/auth/sagas'

export const capture = (
    applicationId: string,
    company: CutterCompany,
    website: ApplicationResourceData,
    url?: string
    ) => {
    const extensionId = document.getElementsByTagName('html')[0].getAttribute('data-secretary-id')

    if (!(window as any).chrome || !extensionId) {
        if (url) window.open(url, '_blank')
        else window.alert('Chrome and the Secretary extension are required for this to work.')
        return
    }

    generateJWTSync((token: string) => {
        const data = {
            website: {
                ...website,
                application: {
                    id: applicationId,
                    companyName: company.name
                },
                ...(url && {direct_url: url})
            },
            jwt: token,
            scope: 'private'
        }

        ;(window as any).chrome.runtime.sendMessage(extensionId, data)
    })
}
