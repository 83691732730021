import { Icon } from './icon'
import styled, { css } from 'styled-components'

export const BubbledCheck: React.FC<{ inProgress?: boolean; blue?: boolean; red?: boolean; green?: boolean }> = ({
    inProgress,
    blue,
    red,
    green
}) => {
    return (
        <IconHolder inProgress={inProgress} blue={blue} red={red} green={green}>
            <Icon type="checkmark" size={7} />
        </IconHolder>
    )
}

const IconHolder = styled.div<{ inProgress?: boolean; blue?: boolean; red?: boolean; green?: boolean }>`
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    border-radius: 999px;
    justify-content: center;
    margin-right: 5px;
    background-color: ${(p) => p.theme['front.success.color']} !important;
    color: ${(p) => p.theme['front.success.text']} !important;

    ${(p) =>
        p.inProgress &&
        css`
            background-color: ${(p) => p.theme['front.background']} !important;
            border: 1px solid ${(p) => p.theme['front.border.strongerI']};
        `}

    ${(p) =>
        p.blue &&
        css`
            background-color: ${(p) => p.theme['front.subtleAccent.background']} !important;
            border: 0px solid ${(p) => p.theme['front.subtleAccent.text']};
            color: ${(p) => p.theme['front.subtleAccent.text']} !important;
        `}
    ${(p) =>
        p.red &&
        css`
            background-color: ${(p) => p.theme['front.danger.color']} !important;
            border: 0px solid ${(p) => p.theme['front.danger.text']};
            color: ${(p) => p.theme['front.danger.text']} !important;
        `}
    ${(p) =>
        p.green &&
        css`
            background-color: ${(p) => p.theme['front.success.background']} !important;
            border: 0px solid ${(p) => p.theme['front.success.text']};
            color: ${(p) => p.theme['front.success.text']} !important;
        `}
`
