import React, { useMemo } from 'react'

import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { LoaderView } from '../../../components/loaders/loader'
import { Text } from '../../../components/general/text'
import { ModalPage } from '../../../components/layout/modalPage'
import { ModalPageInset } from '../../../components/layout/modalPageInset'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { MerchantAccountContractName } from './Accounts.ID.ContractName'
import { useFetchContractPreview } from './useFetchContractPreview'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { shortenId } from '../../../components/general/idShortener'
import { useParams } from 'react-router-dom'

type Params = { id: string; contractId: string; accountId: string }

const redirectState = { noLatestRedirect: true }
export const MerchantAccountContractsPreview: React.FC = () => {
    const params = useParams() as Params

    const { id, contractId, accountId } = params
    const preview = useFetchContractPreview(accountId, contractId)

    const content = useMemo(() => {
        if (preview.loadingStatus === 'started') return <LoaderView overBackground="front.background" />

        if (preview.pdf)
            return (
                <object data={preview.pdf} width="100%" height="100%" type="application/pdf" style={{ flexGrow: 1 }}>
                    Contract
                </object>
            )
        return <Text>Couldn&apos;t load the contract preview.</Text>
    }, [preview.loadingStatus, preview.pdf])
    const name = useMerchantName(id, `Preview contract #${shortenId(contractId)}`)

    return (
        <ModalPage
            title={name}
            backTo={`/merchant/${id}/accounts/${accountId}/contracts/${contractId}`}
            pageId="FilePreviewer"
            backToState={redirectState}
        >
            <ModalHeader
                pageId="FilePreviewer"
                backTo={`/merchant/${id}/accounts/${accountId}/contracts/${contractId}`}
                backToState={redirectState}
                title={
                    contractId === 'draft' ? (
                        'Previewing draft contract'
                    ) : (
                        <Flex>
                            Previewing contract <MerchantAccountContractName id={contractId} />
                        </Flex>
                    )
                }
                rightSideMemo={<></>}
            />
            <ModalPageInset>
                <Card grow>
                    <CardInset>{content}</CardInset>
                </Card>
            </ModalPageInset>
        </ModalPage>
    )
}
