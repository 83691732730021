import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { RootState } from '@/store'
import { ToastsDispatchPush, ToastsDispatchPop } from '../../store/toasts/actions'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { IconCheckmark } from '../icons/iconCheckmark'
import { IconX } from '../icons/iconX'
import { uppercaseFirstLetter } from '../../utils'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { ButtonInset } from '../../components/buttons/buttonInset'
import * as YAML from 'js-yaml'
import { ExportsInformer } from './exportsInformer'
import { ToastBlock } from './toastBlock'
import { MERCHANT_SIDEBAR_SIZE } from '../../pages/Merchant/MerchantSidebars'

export const Toasts: React.FC = () => {
    const dispatch = useDispatch()
    const toasts = useSelector((state: RootState) => {
        return state.toasts
    })
    const activeModalId = useSelector((state: RootState) => state.interface.modals.frontmost)

    const goRelated = useCallback(
        (number, toastId) => {
            dispatch(ToastsDispatchPop(toastId))
            if (number == 'anonymous') {
                dispatch(
                    ToastsDispatchPush(
                        `Cannot open page for phone number: ${number}`,
                        'error',
                        `call-error-${number}`,
                        'longer'
                    )
                )
            } else {
                window.open(`/calls/${number}`, '_blank')
            }
        },
        [dispatch]
    )

    const rightOffset = useMemo(() => {
        if (activeModalId === 'Merchant.InternalNotes') return MERCHANT_SIDEBAR_SIZE
        return '0px'
    }, [activeModalId])

    return (
        <>
            <ToastsHolder rightOffset={rightOffset}>
                {toasts.all
                    .filter((id) => toasts.at[id].type != 'call')
                    .map((id: string) => {
                        const toast = toasts.at[id]
                        return (
                            <ToastBlock
                                key={id}
                                success={toast.type == 'success'}
                                context={toast.type == 'context'}
                                call={toast.type == 'call'}
                                data-cy="toastPill"
                            >
                                <Flex align="center">
                                    {toast.type !== 'context' ? (
                                        <Flex column justify="center">
                                            <Spacer height={10} />
                                            {toast.type == 'success' ? (
                                                <Circle success>
                                                    <IconCheckmark size={8} strokeWidth={2} />
                                                </Circle>
                                            ) : (
                                                <Circle>!</Circle>
                                            )}
                                            <Spacer height={10} />
                                        </Flex>
                                    ) : null}
                                    <Flex column grow justify="center" align="stretch">
                                        <Spacer height={10} />
                                        <Flex justify="space-between" align="center" grow>
                                            <Flex column align="stretch" grow>
                                                {toast.message}
                                            </Flex>
                                            <Flex>
                                                <CloseButton
                                                    data-cy="toast-pill-close"
                                                    onClick={() => {
                                                        dispatch(ToastsDispatchPop(toast.id))
                                                    }}
                                                >
                                                    <IconCircle>
                                                        <IconX size={8} />
                                                    </IconCircle>
                                                </CloseButton>
                                            </Flex>
                                        </Flex>
                                        <Spacer height={10} />
                                    </Flex>
                                </Flex>
                                {toast.context ? (
                                    <Context pre={typeof toast.context === 'string'}>{toast.context}</Context>
                                ) : null}
                            </ToastBlock>
                        )
                    })}
                <ExportsInformer />
            </ToastsHolder>
            <ToastsHolderTop rightOffset={rightOffset}>
                {toasts.all
                    .filter((id) => toasts.at[id].type == 'call')
                    .map((id: string) => {
                        const toast = toasts.at[id]
                        return (
                            <ToastBlock
                                key={id}
                                call={true}
                                success={toast.context?.type == 'picked' ? true : false}
                                data-cy="toastPillTop"
                            >
                                <Flex align="center">
                                    <Flex column justify="center">
                                        <Spacer height={10} />
                                        <Circle call success={toast.context?.type == 'picked' ? true : false}>
                                            !
                                        </Circle>
                                        <Spacer height={10} />
                                    </Flex>
                                    <Flex column grow justify="center" align="stretch">
                                        <Spacer height={10} />
                                        <Flex justify="space-between" align="center">
                                            <Flex column align="stretch" grow justify="center">
                                                {toast.message}
                                            </Flex>
                                            <Flex align="center">
                                                <SimpleButton
                                                    background="front.accent.text"
                                                    onClick={() => {
                                                        goRelated(toast.context.phone, toast.id)
                                                    }}
                                                >
                                                    <ButtonInset padding="small">Open page in new tab</ButtonInset>
                                                </SimpleButton>
                                                <CloseButton
                                                    data-cy="toast-pill-close"
                                                    onClick={() => {
                                                        dispatch(ToastsDispatchPop(toast.id))
                                                    }}
                                                >
                                                    <IconCircle>
                                                        <IconX size={8} />
                                                    </IconCircle>
                                                </CloseButton>
                                            </Flex>
                                        </Flex>
                                        <Spacer height={10} />
                                    </Flex>
                                </Flex>
                            </ToastBlock>
                        )
                    })}
            </ToastsHolderTop>
        </>
    )
}

const IconCircle = styled.div`
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 9999px;
`

const CloseButton = styled.div`
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    margin: -15px -15px -15px -15px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;

    &:hover ${IconCircle} {
        background-color: rgba(0, 0, 0, 0.35);
    }

    &:active ${IconCircle} {
        background-color: rgba(0, 0, 0, 0.5);
    }
`

export const BuildErrorToastContext = (statusCode: any, body: any) => {
    const valueBeenSeenBefore: any = {}

    return (
        <Grid>
            {body
                ? Object.keys(body).map((k) => {
                      if (valueBeenSeenBefore[body[k]]) return null
                      valueBeenSeenBefore[body[k]] = true
                      return (
                          <React.Fragment key={k}>
                              <ResponseField>{uppercaseFirstLetter(k)}:</ResponseField>
                              <span>
                                  {typeof body[k] === 'object' ? YAML.dump(body[k]).replace(/['`"]+/g, '') : body[k]}
                              </span>
                          </React.Fragment>
                      )
                  })
                : 'Undefined body'}
        </Grid>
    )
}

const ResponseField = styled.div`
    font-weight: 500;
`

const Grid = styled.div`
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 2px;
    grid-template-columns: minmax(min-content, auto) auto;
`

const Context = styled.div<{ pre?: boolean }>`
    margin-left: -14px;
    margin-right: -14px;
    margin-bottom: 0px;
    margin-top: 0px;
    background-color: ${(p) => p.theme['floating.background']};
    padding: 15px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.4em;
    color: ${(p) => p.theme['floating.text']};
    border-radius: 0 0 12px 12px;
    ${(p) =>
        p.pre &&
        css`
            display: pre;
        `}
`

const Circle = styled.div<{ success?: boolean; call?: boolean }>`
    width: 18px;
    height: 18px;
    background-color: ${(p) => p.theme['front.subtleDanger.background']};
    flex-grow: 0;
    color: #fff;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
    justify-content: center;
    border-radius: 999px;
    background-color: #fff;
    color: ${(p) => p.theme['front.subtleDanger.text']};
    cursor: pointer;

    ${(p) =>
        p.call &&
        css`
            color: ${(p) => p.theme['front.accent.color']};
            background-color: #fff;
        `}
    ${(p) =>
        p.success &&
        css`
            background-color: ${(p) => p.theme['front.success.color.strongerI']};
            color: #fff;
            border: none;
        `}
`

const ToastsHolder = styled.div<{ rightOffset?: string }>`
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 10px;
    z-index: 100000;

    @media print {
        display: none;
    }

    ${(p) =>
        p.rightOffset &&
        css`
            right: ${p.rightOffset};
        `}
`

const ToastsHolderTop = styled.div<{ rightOffset?: string }>`
    position: fixed;
    right: 0;
    top: 0;
    padding: 10px;
    z-index: 100000;
    ${(p) =>
        p.rightOffset &&
        css`
            right: ${p.rightOffset};
        `}
`
