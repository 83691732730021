import React from 'react'
import { WatcherButton } from '../../../../../../../components/buttons/watcherButton'

interface MuteButtonProps {
    isMuted: boolean
    onMute: (e, generatedId) => void
}

export const MuteButton: React.FC<MuteButtonProps> = (props) => {
    const { isMuted, onMute } = props

    return <WatcherButton onClick={onMute}>{isMuted ? 'Unmute' : 'Mute'}</WatcherButton>
}
