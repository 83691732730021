import React, { useRef, useEffect, ReactText } from 'react'
import {
    Chart,
    CategoryScale,
    PieController,
    LineController,
    LineElement,
    ArcElement,
    PointElement,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js'
import moment from 'moment'
import { numberWithCurrency } from '../../utils'
import { useIsPrint } from '../../hooks/general/useIsPrint'
import Color from 'color'

Chart.register(
    CategoryScale,
    Tooltip,
    ArcElement,
    PieController,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    Title
)

export const ChartJSTooltipsStyling = {
    titleFontFamily: 'Roboto',
    titleFontSize: 13,
    titleFontStyle: 'bold',
    titleMarginBottom: 5,
    caretSize: 9,
    bodyFontFamily: 'Roboto',
    bodyFontSize: 13,
    bodySpacing: 5,
    xPadding: 10,
    yPadding: 10,
    cornerRadius: 8,
    multiKeyBackground: 'rgba(0,0,0,0)',
    displayColors: false
}
export const Linechart: React.FC<{
    data: { [key: string]: any }
    currency: string
}> = (p) => {
    const isPrinting = useIsPrint()
    const canvasRef = useRef<any>()
    const chart = useRef<any>()

    useEffect(() => {
        if (!canvasRef || !canvasRef.current) return
        const ctx = canvasRef.current.getContext('2d')
        if (!ctx) return
        if (!chart.current)
            chart.current = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: [
                        {
                            data: [],
                            backgroundColor: undefined
                        }
                    ],
                    labels: []
                },
                options: {
                    responsive: false,
                    animation: {
                        duration: 0
                    },
                    maintainAspectRatio: false,
                    hover: {
                        mode: 'index',
                        intersect: false
                    },
                    plugins: {
                        tooltip: {
                            mode: 'index',
                            enabled: true,
                            intersect: false,
                            ...ChartJSTooltipsStyling,
                            callbacks: {
                                label: (context) => {
                                    const index = context.dataIndex
                                    const value: any = context.dataset.data[index]
                                    if (!context.dataset) return 'Unknown'
                                    if (Array.isArray(p.data)) {
                                        return `${p.data[context.datasetIndex].label}:  ${numberWithCurrency(
                                            p.currency,
                                            value,
                                            true
                                        )} ${p.currency}`
                                    }
                                    return `${numberWithCurrency(p.currency, value, true)} ${p.currency}`
                                }
                            }
                        }
                    },
                    elements: {
                        arc: {
                            borderWidth: 0
                        },
                        point: {
                            hitRadius: 6
                        }
                    },
                    scales: {
                        xAxes: {
                            ticks: {
                                font: {
                                    family: 'Roboto',
                                    size: isPrinting ? 10 : 12
                                },
                                padding: 20
                            }
                        },
                        yAxes: {
                            ticks: {
                                font: {
                                    family: 'Roboto',
                                    size: isPrinting ? 10 : 12
                                },
                                padding: 20,
                                callback(label: ReactText) {
                                    return `${numberWithCurrency(p.currency, `${label}`, true)} ${p.currency}`
                                }
                            }
                        }
                    }
                }
            })
    }, [canvasRef, isPrinting, p.currency, p.data])

    useEffect(() => {
        if (chart.current) {
            const chartOptions: any = {
                labels: [],
                datasets: []
            }
            if (Array.isArray(p.data)) {
                p.data.map((dataset, i) => {
                    const { labels, data } = getChartDataFromProps(dataset.data)
                    if (i == 0) {
                        chartOptions.labels = labels
                    }
                    const color = Color(dataset.color)
                    chartOptions.datasets.push({
                        data,
                        label: dataset.label,
                        backgroundColor: 'transparent',
                        borderColor: color.string(),
                        lineTension: 0,
                        borderWidth: 1,
                        pointRadius: isPrinting ? 1 : 5,
                        pointHoverRadius: 5,
                        pointBorderWidth: 5,
                        pointHoverBorderWidth: 5,
                        pointBackgroundColor: '#FFF',
                        pointHoverBackgroundColor: color.string(),
                        pointBorderColor: color.string()
                    })
                })
            } else {
                const { labels, data } = getChartDataFromProps(p.data)
                chartOptions.labels = labels
                chartOptions.datasets.push({ data })
            }
            chart.current.data.labels = chartOptions.labels
            chart.current.data.datasets = chartOptions.datasets
            chart.current.update()
        }
    }, [p.data, isPrinting])

    return (
        <canvas
            ref={canvasRef}
            style={{
                maxWidth: '100%',
                width: isPrinting ? '690px' : '100%',
                height: isPrinting ? 'auto' : '250px'
            }}
        />
    )
}

const getChartDataFromProps = (data: any) => {
    const labels = Object.keys(data)
    const formattedLabels = labels.map((label: string) => moment(label, 'YYYY_MM').format("'YY MMM"))
    const dataset: any = []

    labels.map((k) => {
        if (!data[k]) dataset.push(0)
        else dataset.push(data[k])
    })

    return {
        labels: [...formattedLabels],
        data: [...dataset]
    }
}
