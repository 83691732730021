import { LoadingStatus } from '../../../utils'

export type Referral = {
    active: boolean
    id: string
    name: string
    order: number
    referral: boolean
    selfLink: string
    support: boolean
}

export type MerchantApplicationsInternalsReferralState = {
    [applicationId: string]: {
        activeReferrals: Referral[]
        loadingStatus: LoadingStatus
    }
}

export const InitialApplicationInternalsReferralState: MerchantApplicationsInternalsReferralState = {}
