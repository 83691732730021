import { camelCase } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { WatcherButton } from '../../../../../../../components/buttons/watcherButton'
import { TasksCompanyChangesActions } from '../../../../../../../store/tasksCompanyChanges/actions'
import { MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { Text } from '../../../../../../../components/general/text'

interface MuteButtonProps {
    taskId: string
    section: string
    field: string
    after: string | null
    applicationData?: MonitoredFetchedApplicationData
    disabled?: boolean
}

export const MuteButton: React.FC<MuteButtonProps> = (props) => {
    const { taskId, section, field, applicationData, after, disabled } = props

    const dispatch = useDispatch()

    const sectionCamelCase = camelCase(section)
    const fieldCamelCase = camelCase(field)

    // Mute / Unmute
    const mutedValues = applicationData?.muted?.[sectionCamelCase]?.[fieldCamelCase]
        ? applicationData?.muted[sectionCamelCase]?.[fieldCamelCase]
        : []

    const isMuted = after ? (mutedValues || []).includes(after) : false

    if (disabled) {
        return (
            <Text color="front.text.subtlerI" strike>
                Apply
            </Text>
        )
    }

    return (
        <WatcherButton
            isDisabled={after === null}
            onClick={(_, watcherId) => {
                const payload = {}
                payload[section] = {
                    [field]: isMuted ? mutedValues.filter((f) => f !== after) : [...(mutedValues || []), after]
                }

                if (applicationData?.application.id) {
                    dispatch(
                        TasksCompanyChangesActions.CHANGE_MUTED_STATE(
                            applicationData?.application.id,
                            taskId,
                            watcherId,
                            payload
                        )
                    )
                }
            }}
        >
            {isMuted ? 'Unmute' : 'Mute'}
        </WatcherButton>
    )
}
