import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Spinner } from '../../../components/loaders/spinner'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { ApplicationResourceActions } from '../../../store/applicationResources/actions'
import { DedicatedSectionProps } from './Application.DedicatedSection'
import { useDedicatedSubsection, useSubsection } from './Application.DedicatedSubsection'
import { MerchantApplicationInternalDetails } from './Application.InternalDetails'
import { MerchantApplicationSectionCheckmark } from './Application.SectionCheckmark'
import { MerchantApplicationSubsection } from './Application.Subsection'
import { MerchantApplicationSuperField } from './Application.SuperField'
import { MerchantApplicationWebsiteLink } from './Application.WebsiteLink'
import { GenerateIndexPathForSubsection } from './Application.Structure'
import { useHasApplicationSectionLoaded } from './useHasApplicationSectionLoaded'
import { ApplicationNoSubsections } from './Application.NoSubsections'
import { ApplicationDedicatedInternalSectionWrapper } from './Application.DedicatedInternalSectionWrapper'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { ResourceHistory } from './Application.ResourceHistory'
import { RootState } from '@/store'

export const MerchantApplicationDedicatedSectionWebsites: React.FC<DedicatedSectionProps> = ({
    errors,
    executeBatchChanges,
    applicationId,
    formRef
}) => {
    const websiteHistory = useSelector((state: RootState) => {
        return state.applicationResources.data.forApplication[applicationId]?.websitesHistory
    })
    const { structure, field } = useDedicatedSubsection(applicationId, 'Websites')
    const { fields, resource } = useSubsection(applicationId, structure, formRef, executeBatchChanges, errors)
    const hasLoaded = useHasApplicationSectionLoaded(applicationId, 'websites', 'data')

    const [open, setOpen] = useState({})

    const prefixMemo = useMemo(() => {
        return <Icon type="websiteIcon" />
    }, [])

    const dispatch = useDispatch()

    const resultsFetcher = useMemo(() => {
        if (!resource?.nextLink) return null
        if (resource?.nextLink)
            return (
                <>
                    <Spacer width={10} />
                    <Flex align="center" column>
                        <SimpleButton
                            background="front.background"
                            onClick={() => {
                                dispatch(ApplicationResourceActions.
                                    FETCH_MORE_WEBSITES(applicationId, resource.fields, resource.nextLink ))
                            }}
                        >
                            <ButtonInset>
                                    Fetch more
                            </ButtonInset>
                        </SimpleButton>
                    </Flex>
                    <Spacer width={20} />
                </>
            )
        return null
    }, [resource, dispatch, applicationId])

    const handleAddWebsite = useCallback(
        (e, watcherId) => {
            dispatch(ApplicationResourceActions.ADD_SUBSECTION(applicationId, 'websites', structure.suffix, watcherId))
        },
        [dispatch, applicationId, structure]
    )

    if (!hasLoaded)
        return (
            <>
                <Flex justify="center">
                    <Spinner overBackground="back.background" />
                </Flex>
                <span />
            </>
        )
    const handleShowInternalCard = (i) =>{
        setOpen(open =>({
            ...open,
            [i]: !open[i]
        }))
    }  

    const RightSideCard = (i) => {
        return (
            <Flex column>
                <ApplicationDedicatedInternalSectionWrapper>
                    <MerchantApplicationInternalDetails
                        sectionStructure={{
                            ...structure,
                            internalDetails: "websiteInternalDetailsHistory"
                        }}
                        applicationId={applicationId}
                        subsection
                        subsectionIndex={i}
                    />
                </ApplicationDedicatedInternalSectionWrapper>
            </Flex>
        )
    }

    return (
        <>
            {fields.map((s: any, i: any) => {
                return (
                    <React.Fragment key={`websites-${i}`}>
                        <Holder data-cy={`websites-${i}`}>
                            {structure.reviewableResource && (
                                <MerchantApplicationSectionCheckmark
                                    applicationId={applicationId}
                                    resource={structure.reviewableResource}
                                    subsectionIndex={i}
                                />
                            )}
                            <Card grow loading={!hasLoaded} showEditable>
                                <CardInset>
                                    <MerchantApplicationSuperField
                                        applicationId={applicationId}
                                        indexPath={GenerateIndexPathForSubsection(structure, i, field)}
                                        executeBatchChanges={executeBatchChanges}
                                        formErrors={errors}
                                        formRef={formRef}
                                        prefixMemo={prefixMemo}
                                        smaller
                                    />
                                    <Spacer height={15} />
                                    <MerchantApplicationSubsection
                                        key={'key'}
                                        executeBatchChanges={executeBatchChanges}
                                        applicationId={applicationId}
                                        formErrors={errors}
                                        fieldIndex={i}
                                        formRef={formRef}
                                        subsection={s}
                                        subsectionStructure={structure}
                                    />
                                    <MerchantApplicationWebsiteLink
                                        applicationId={applicationId}
                                        indexPath={GenerateIndexPathForSubsection(structure, i, field)}
                                    />
                                </CardInset>
                            </Card>
                        </Holder>
                        <Flex column>
                            <ApplicationDedicatedInternalSectionWrapper>
                                <MerchantApplicationInternalDetails
                                    sectionStructure={structure}
                                    applicationId={applicationId}
                                    subsectionIndex={i}
                                    subsection
                                />
                            </ApplicationDedicatedInternalSectionWrapper>
                        </Flex>
                    </React.Fragment>
                )
            })}
            {fields.length === 0 ? <ApplicationNoSubsections type="websites" /> : undefined}
            {hasLoaded ? (
                <>
                    <WatcherButton
                        onClick={handleAddWebsite}
                        background="front.subtleAccent.background"
                        color="front.subtleAccent.text"
                        grow
                    >
                        <ButtonInset padding="medium">+ Add a website</ButtonInset>
                    </WatcherButton>
                    {resultsFetcher}
                    {resource.nextLink ? "" : <span />}
                </>
            ) : null}
            { 
                websiteHistory?.fields.map((website, i) => {
                    if(website.metadata.deleted)
                        return (
                            <React.Fragment key={website.id}>
                                <ResourceHistory
                                    prefixMemo={prefixMemo}
                                    applicationId={applicationId}
                                    fields={structure.fields}
                                    resource={website}
                                    resourceKeys={['comment', 'ownership']}
                                    title={website.url}
                                    subsectionIndex={i}
                                    showInternalCard={handleShowInternalCard}
                                />
                                {
                                    open[i] ?
                                        RightSideCard(i) : <span/>
                                }
                            </React.Fragment>
                        )
                })
            }
        </>
    )
}

const Holder = styled.div`
    position: relative;
    display: flex;
    align-items: stretch;
`

const ButtonHolder = styled.div`
    width: 100%;
    display: flex;
`
