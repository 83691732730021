import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { Offset } from '../../../components/layout/offset'
import { Spacer } from '../../../components/layout/spacer'
import { RootState } from '@/store'

export const ApplicationFieldWarnNotCollaborator: React.FC<{
    applicationId: string
    shownInSummary?: boolean
}> = ({ applicationId, shownInSummary }) => {
    const signerEmail = useSelector(
        (state: RootState) =>
            state.applicationResources.data?.forApplication?.[applicationId]?.signer?.fields?.[0]?.signer.email
    )
    const signerName = useSelector(
        (state: RootState) =>
            state.applicationResources.data?.forApplication?.[applicationId]?.signer?.fields?.[0]?.signer.name
    )
    const collaborators = useSelector(
        (state: RootState) => state.applicationInternals?.collaborators?.forApplication?.[applicationId]?.collaborators
    )
    const isMissing = !collaborators?.find((c) => {
        return c.email?.toLowerCase() == signerEmail?.toLowerCase()
    })

    const to = useMemo(() => {
        return {
            pathname: `/merchant/${applicationId}/summary/add-collaborator`,
            search: new URLSearchParams({
                email: signerEmail,
                name: signerName
            }).toString()
        }
    }, [applicationId, signerEmail, signerName])

    if (isMissing && signerEmail) {
        return (
            <>
                <span />
                <Warn shownInSummary>
                    <Flex align={shownInSummary ? 'flex-start' : 'center'}>
                        <Offset top={1}>
                            <Icon size={12} type="warning" />
                        </Offset>
                        <Spacer width={10} />
                        {shownInSummary ? (
                            <Text>Signer {signerEmail} not present as collaborator.</Text>
                        ) : (
                            <Text>Not present as collaborator</Text>
                        )}
                    </Flex>
                    <Spacer width={5} />
                    <LinkButton
                        // eslint-disable-next-line max-len
                        to={to}
                        keepQuery
                        passBackToState
                        background="front.danger.color"
                    >
                        <ButtonInset padding="tiny">Create invite</ButtonInset>
                    </LinkButton>
                </Warn>
            </>
        )
    }
    return null
}

const Warn = styled.div<{ shownInSummary?: boolean }>`
    background-color: ${(p) => p.theme['front.subtleDanger.background']};
    grid-column: span 2;
    color: ${(p) => p.theme['front.subtleDanger.text']};
    border-radius: 10px;
    padding: 6px 6px 6px 10px;
    margin: 0 -5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    ${(p) =>
        p.shownInSummary &&
        css`
            padding: 10px;
            display: block;
        `}
`
