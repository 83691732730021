import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Pagination } from '../../utils'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { CardInset } from '../cards/cardInset'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { Text } from './text'

export const Paginator: React.FC<{
    pagination?: Pagination
    onPageChanged: (page: number) => void
}> = ({ pagination, onPageChanged }) => {
    const renderButton = useCallback(
        (i: number, activePage: number, ellipsis: boolean) => {
            if (ellipsis)
                return (
                    <ButtonHolder key={`${i}-sparator`}>
                        <ButtonInset padding="tiny">
                            <Text color="back.text.subtlerI">...</Text>
                        </ButtonInset>
                    </ButtonHolder>
                )
            return (
                <ButtonHolder key={i}>
                    <SimpleButton
                        color="front.accent.color"
                        isPressed={activePage === i}
                        onClick={() => {
                            onPageChanged(i)
                        }}
                    >
                        <ButtonInset padding="tiny">{`${i}`}</ButtonInset>
                    </SimpleButton>
                </ButtonHolder>
            )
        },
        [onPageChanged]
    )

    if (!pagination) return null

    const tabs: React.ReactNode[] = []
    const pagesShownAtOnce = 10
    const page = typeof pagination.page === 'number' ? pagination.page : parseInt(pagination.page, 10)
    const numberOfPages = Math.ceil(pagination.total / pagination.perPage)

    const startIndex = page - Math.ceil(pagesShownAtOnce / 2)
    const endIndex = page + Math.ceil(pagesShownAtOnce / 2)
    let remainingAtStart = 0
    let remainingAtEnd = 0

    for (let i = startIndex; i < endIndex; i++) {
        if (i >= 1) {
            if (i <= numberOfPages) {
                tabs.push(renderButton(i, page, false))
            } else {
                remainingAtEnd++
            }
        } else {
            remainingAtStart++
        }
    }

    if (remainingAtStart) {
        for (let i = endIndex; i < endIndex + remainingAtStart + 1; i++) {
            if (i < numberOfPages) tabs.push(renderButton(i, page, false))
        }
    }

    if (remainingAtEnd) {
        for (let i = startIndex - 1; i > startIndex - remainingAtEnd - 2; i--) {
            if (i > 1) tabs.unshift(renderButton(i, page, false))
        }
    }

    if (endIndex < numberOfPages) {
        tabs.splice(tabs.length - 1, 1)
        tabs.push(renderButton(0, page, true))
        tabs.push(renderButton(numberOfPages, page, false))
    }

    if (startIndex > 1) {
        tabs.splice(0, 1)
        tabs.unshift(renderButton(0, page, true))
        tabs.unshift(renderButton(1, page, false))
    }

    if (tabs.length <= 1) return null

    return (
        <Flex column align="center">
            <Spacer height={10} />
            <Flex>
                <CardInset type="tiny">
                    <Flex>{tabs}</Flex>
                </CardInset>
            </Flex>
            <Spacer height={10} />
        </Flex>
    )
}

const ButtonHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
        border-right: none;
    }
`
