import React from 'react'
import { BatchOfMonitoredChanges, MonitoredChangeKind } from '../../../../../../store/tasksCompanyChanges/types'
import { CardDirectorAdded } from './components/CardDirectorAdded'
import { CardNameChange } from './components/CardNameChange'
import { CardOtherChanges } from './components/CardOtherChanges'
import { CardUboAdded } from './components/CardUboAdded'

type KnownChangesType = Omit<MonitoredChangeKind, 'other'>[]
const knownChanges: KnownChangesType = ['company-name-replace', 'director-name-replace', 'ownership-beneficial-add']

interface ApplicationCardTasksProps {
    taskId: string
    change?: BatchOfMonitoredChanges
}

export const ApplicationCardTasks: React.FC<ApplicationCardTasksProps> = (props) => {
    const { change, taskId } = props

    if (!change) {
        return null
    }

    const otherCardChanges = change
        ? change.changes.filter(
              (c) => {
                  return !knownChanges.includes(c.kind)
              },
              [change]
          )
        : []

    return (
        <>
            {change.changes.map((c, i) => {
                const { applicationId } = change
                const { data: applicationData } = change.fetchedApplicationData
                const changeKey = `monitoring-change-${i}`

                if (c.kind === 'company-name-replace') {
                    return (
                        <CardNameChange
                            taskId={taskId}
                            applicationId={applicationId}
                            applicationData={applicationData}
                            change={c}
                            key={changeKey}
                        />
                    )
                }

                if (c.kind === 'ownership-beneficial-add') {
                    return <CardUboAdded taskId={taskId} applicationData={applicationData} change={c} key={changeKey} />
                }

                if (c.kind === 'director-name-replace') {
                    return (
                        <CardDirectorAdded
                            taskId={taskId}
                            applicationData={applicationData}
                            change={c}
                            key={changeKey}
                        />
                    )
                }
            })}

            <CardOtherChanges
                taskId={taskId}
                applicationId={change.applicationId}
                applicationData={change.fetchedApplicationData.data}
                changes={otherCardChanges}
            />
        </>
    )
}
