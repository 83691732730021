import React from 'react'
import { Tooltip, Text, Button } from '@/design-system'

interface ReconcileActionProps {
    onClick: () => void
    isLoading: boolean
    isDisabled: boolean
}

export const ReconcileAction: React.FC<ReconcileActionProps> = (props) => {
    const { onClick, isLoading, isDisabled } = props

    if (!isDisabled) {
        return (
            <Button onClick={onClick} loading={isLoading} disabled={isDisabled}>
                Reconcile
            </Button>
        )
    }

    return (
        <Tooltip delayDuration={0}>
            <Tooltip.Trigger>
                <Button onClick={onClick} disabled={isDisabled} loading={isLoading}>
                    Reconcile
                </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>
                <Text lineHeight="none" align="center">
                    Make sure balance is zero before reconciliation.
                </Text>
            </Tooltip.Content>
        </Tooltip>
    )
}
