import { useCallback, useEffect, useMemo } from 'react'
import { useRefTaker } from '../../../hooks/general/useRefTaker'

export const useFieldSidebarScrollToLatest = (onShow?: boolean) => {
    const [lastPoint, setLastPoint] = useRefTaker()

    const anchor = useMemo(() => {
        return lastPoint?.closest('[data-field-sidebar]')
    }, [lastPoint])

    const scrollToBottom = useCallback(() => {
        if (!lastPoint) return
        anchor.scrollTop = lastPoint.offsetTop + window.innerHeight
    }, [anchor, lastPoint])

    useEffect(() => {
        if (anchor) {
            scrollToBottom()
        }
    }, [lastPoint, anchor, scrollToBottom, onShow])

    return {
        setLastPoint,
        scrollToBottom
    }
}
