import { ListPageSidebar } from '@/components/layout/listPageSidebar'
import { ListPageSidebarLink } from '@/components/layout/listPageSidebarLink'
import { ListPageSidebarTitle } from '@/components/layout/listPageSidebarTitle'
import React from 'react'
import { useRouterMatchFilter } from '../../../hooks'
import { TasksFilters } from '../../../types'
import { useNavigate } from 'react-router-dom'

export const ScheduledTasksSidebar: React.FC = () => {
    const state = useRouterMatchFilter()

    const navigate = useNavigate()

    const handleOnClick = (state: TasksFilters) => () => {
        navigate(`/odd-tasks/${state}`, { replace: true })
    }

    return (
        <ListPageSidebar>
            <ListPageSidebarTitle>Filters</ListPageSidebarTitle>
            <ListPageSidebarLink isActive={state === 'new'} onClick={handleOnClick('new')}>
                Unprocessed
            </ListPageSidebarLink>
            <ListPageSidebarLink isActive={state === 'open'} onClick={handleOnClick('open')}>
                In progress
            </ListPageSidebarLink>
            <ListPageSidebarLink isActive={state === 'assigned-to-me'} onClick={handleOnClick('assigned-to-me')}>
                Assigned to me
            </ListPageSidebarLink>
            <ListPageSidebarLink isActive={state === 'closed'} onClick={handleOnClick('closed')}>
                Closed
            </ListPageSidebarLink>
        </ListPageSidebar>
    )
}
