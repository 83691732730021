
import styled from 'styled-components'

export const PrintOnly: React.FC<{ children: any; inline?: boolean }> = ({
    children,
    inline
}) => {
    if (inline) return <PrintContentInline>{children}</PrintContentInline>
    return <PrintContent>{children}</PrintContent>
}

const PrintContent = styled.div`
    display: none;
    @media print {
        display: block !important;
    }
`

const PrintContentInline = styled.span`
    display: none;
    @media print {
        display: inline !important;
    }
`
