import { DateToWords } from '@/utils'
import React from 'react'
import { Text } from '@/components/general/text'

interface DateProps {
    children?: string
}

export const Date: React.FC<DateProps> = (props) => {
    const { children } = props

    return <Text oneLine>{children ? DateToWords(children, true) : '-'}</Text>
}
