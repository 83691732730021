/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from 'deox'
import { snakeCase } from 'lodash'
import { all, put } from 'typed-redux-saga'
import { Text } from '../../components/general/text'

import { DELETE, GET, PATCH, POST, PUT } from '../../generators/networking'
import { deoxWaitFor } from '../applications/utils'
import { LocalContractPathsStructure } from '../contractTemplates/helpers'
import { LocallyStoredContractTemplate } from '../contractTemplates/types'
import { FetchAllPages } from '../utils'
import { WatcherDispatchFail, WatcherDispatchStart, WatcherDispatchSuccess } from '../watcher/actions'
import { MerchantAccountsActions } from './actions'
import {
    LocallyStoredDraftContract,
    MerchantAccount,
    MerchantAccountContract,
    MerchantAccountDraftContract
} from './types'

export const ACCOUNTS_LOAD = 'ACCOUNTS_LOAD'

const appendPathToBackendContract = ['paymentPeriod', 'otherTerms', 'interchange']

export const resourceLinksForLocalFields = (
    contract: LocallyStoredDraftContract | LocallyStoredContractTemplate,
    changes: LocalContractPathsStructure<{ from: any; to: any }>
) => {
    const dictionary = {}

    Object.keys(changes).forEach((k) => {
        // 2 levels nesting
        if (k.includes('.')) {
            const mainResource = k.split('.')[0]
            dictionary[k] = contract[mainResource].selfLink
        } else {
            dictionary[k] = contract.selfLink
        }
    })

    return dictionary
}

export const MerchantAccountsSagas = {
    *CREATE_API_KEY({
        payload: { watcherId, expiresAt, accountId }
    }: ActionType<typeof MerchantAccountsActions.CREATE_API_KEY>) {
        yield POST({
            watcher: watcherId,
            url: `${import.meta.env.VITE_API_ROOT}/accounts/${accountId}/api_keys`,
            successCode: 200,
            body: {
                enabled: false,
                ...(expiresAt ? { expires_at: expiresAt } : {})
            },
            include: ['self'],
            errorText: 'Failed to create the API Key',
            onSuccessDispatch: (data) => MerchantAccountsActions.STORE_API_KEY(accountId, data),
            successText: 'API Key created successfully'
        })
    },
    *UPDATE_API_KEY({
        payload: { watcherId, accountId, expiresAt, enabled, link }
    }: ActionType<typeof MerchantAccountsActions.UPDATE_API_KEY>) {
        yield PATCH({
            watcher: watcherId,
            url: link,
            body: {
                ...(expiresAt ? { expires_at: expiresAt } : {}),
                ...(enabled !== undefined ? { enabled } : {})
            },
            include: ['self'],
            errorText: 'Failed to update the API Key',
            onSuccessDispatch: (data) => MerchantAccountsActions.STORE_API_KEY(accountId, data),
            successText: 'API Key updated successfully'
        })
    },
    *CREATE_ACCOUNT(p: ActionType<typeof MerchantAccountsActions.CREATE_ACCOUNT>) {
        yield put(WatcherDispatchStart([p.payload.watcherId]))

        const { success, cleanedResponse } = yield POST({
            url: `${import.meta.env.VITE_API_ROOT}/applications/${p.payload.applicationId}/accounts`,
            successCode: 200,
            body: Object.keys(p.payload.account).reduce((acc, key) => {
                acc[snakeCase(key)] = (p.payload.account as any)[key]
                return acc
            }, {} as any),
            include: ['self', 'download', 'data', 'application']
        })

        if (success) {
            yield put(MerchantAccountsActions.FETCH_ACCOUNT(cleanedResponse.id))
            yield deoxWaitFor(
                MerchantAccountsActions.STORE_ACCOUNT_WITH_CONTRACT,
                (payload) => payload.accountWithContract.id === cleanedResponse.id
            )

            yield put(
                WatcherDispatchSuccess([p.payload.watcherId], 'Account created successfully', {
                    accountId: cleanedResponse.id
                })
            )
        } else {
            if (p.payload.account.chBilling)
                yield put(
                    WatcherDispatchFail(
                        [p.payload.watcherId],
                        'Failed to create the account',
                        <Text>
                            <Text bold>⚠️ You&apos;ve tried to create a Clearhaus Billing powered account.</Text> Please
                            consider that there might be a bank holiday approaching.
                        </Text>
                    )
                )
            else yield put(WatcherDispatchFail([p.payload.watcherId], 'Failed to create the account'))
        }
    },
    *UPDATE_ACCOUNT(p: ActionType<typeof MerchantAccountsActions.UPDATE_ACCOUNT>) {
        yield put(WatcherDispatchStart([p.payload.watcherId]))

        const { success, cleanedResponse } = yield PATCH({
            url: p.payload.link,
            body: Object.keys(p.payload.account).reduce((acc, key) => {
                acc[snakeCase(key)] = (p.payload.account as any)[key]
                return acc
            }, {} as any),
            include: ['self', 'download', 'data']
        })

        if (success) {
            yield put(MerchantAccountsActions.FETCH_ACCOUNT(cleanedResponse.id))

            yield deoxWaitFor(
                MerchantAccountsActions.STORE_ACCOUNT_WITH_CONTRACT,
                (payload) => payload.accountWithContract.id === cleanedResponse.id
            )

            yield put(WatcherDispatchSuccess([p.payload.watcherId], 'Account updated successfully'))
        } else {
            yield put(WatcherDispatchFail([p.payload.watcherId], 'Failed to update the account'))
        }
    },
    *STAMP_ACCOUNT(p: ActionType<typeof MerchantAccountsActions.STAMP_ACCOUNT>) {
        yield put(WatcherDispatchStart([p.payload.watcherId]))
        yield PUT({
            url: p.payload.link,
            successCode: 200,
            include: ['self', 'stamps'],
            successText: 'Account stamp action succeeded.',
            onSuccessDispatch: (account) => {
                return MerchantAccountsActions.FETCH_ACCOUNT(
                    account.id,
                    p.payload.watcherId,
                    p.payload.successMessage || 'Account stamped successfully'
                )
            },
            errorText: `Failed to stamp account.`
        })
    },
    *STAMP_CONTRACT(p: ActionType<typeof MerchantAccountsActions.STAMP_CONTRACT>) {
        yield put(WatcherDispatchStart([p.payload.watcherId]))
        const success: { [key: string]: any } = yield PUT({
            url: p.payload.link,
            successCode: 200,
            include: ['self', 'stamps']
            // onSuccessDispatch: (contract) =>
            //     MerchantAccountsActions.STORE_CONTRACT_VERSION(p.payload.accountId, contract)
        })

        if (success) {
            try {
                const fetchSuccess = yield* put(MerchantAccountsActions.FETCH_ACCOUNT(p.payload.accountId))

                let fetchedAccount: any = undefined
                yield deoxWaitFor(MerchantAccountsActions.STORE_ACCOUNT_WITH_CONTRACT, (t) => {
                    fetchedAccount = t.accountWithContract
                    return t.accountWithContract.id === p.payload.accountId
                })

                if (!fetchSuccess || !fetchedAccount.contractLink) throw 'Failed to fetch account'

                yield put(WatcherDispatchSuccess([p.payload.watcherId], 'Contract stamped successfully'))
            } catch (e) {
                console.error(e)
                yield put(WatcherDispatchFail([p.payload.watcherId], "Failed to fetch stamped contract's account"))
            }
        } else {
            yield put(WatcherDispatchFail([p.payload.watcherId], 'Failed to stamp contract.'))
        }
    },
    *STAMP_DRAFT(p: ActionType<typeof MerchantAccountsActions.STAMP_DRAFT>) {
        yield put(WatcherDispatchStart([p.payload.watcherId]))
        let url = `${import.meta.env.VITE_CUTTER_ROOT}/agreements/${p.payload.draftId}/stamps`
        let message: string | undefined = 'Contract action succeeded.'
        switch (p.payload.type) {
            case 'ready-then-approve':
                message = undefined
                url += '/ready'
                break
            case 'approve':
                message = 'Contract has been created successfully.'
                url += '/approved'
                break
            case 'ready':
                message = 'Awaiting approval.'
                url += '/ready'
                break
            case 'refuse':
                message = 'Ready for changes.'
                url += '/refused'
                break
            default:
                break
        }

        const { success, cleanedResponse: newDraft } = yield PUT({
            url,
            successCode: 200,
            include: ['self', 'stamps'],
            errorText: `Failed to stamp draft contract`
        })

        if (!success || !newDraft) {
            yield put(WatcherDispatchFail([p.payload.watcherId], 'Action failed.'))
            return
        }

        if (p.payload.type === 'ready-then-approve') {
            const { success: creationSuccess, cleanedResponse: completelyNewDraft } = yield PUT({
                url: `${import.meta.env.VITE_CUTTER_ROOT}/agreements/${p.payload.draftId}/stamps/approved`,
                successCode: 200,
                include: ['self', 'stamps'],
                errorText: `Failed to stamp draft contract.`
            })

            yield put(MerchantAccountsActions.FETCH_ACCOUNT(p.payload.accountId))

            yield deoxWaitFor(
                MerchantAccountsActions.STORE_ACCOUNT_WITH_CONTRACT,
                (payload) => payload.accountWithContract.id === p.payload.accountId
            )

            if (creationSuccess) {
                yield put(MerchantAccountsActions.STORE_DRAFT(p.payload.accountId, completelyNewDraft))
                yield put(WatcherDispatchSuccess([p.payload.watcherId], 'Contract has been created successfully.'))
            } else {
                yield put(WatcherDispatchFail([p.payload.watcherId], 'Failed to create contact.'))
            }
        } else {
            if (p.payload.type === 'approve') {
                yield put(MerchantAccountsActions.FETCH_ACCOUNT(p.payload.accountId))
            }
            yield put(MerchantAccountsActions.STORE_DRAFT(p.payload.accountId, newDraft))
            if (message) yield put(WatcherDispatchSuccess([p.payload.watcherId], message))
        }
    },
    *FETCH_CONTRACT_HISTORY(p: ActionType<typeof MerchantAccountsActions.FETCH_CONTRACT_HISTORY>) {
        const contractsHistory: { contracts: MerchantAccountContract[] } = yield GET({
            url: `${p.payload.contractLink}/version-history?per_page=50`,
            errorText: "Failed to load application's accounts.",
            include: ['self', 'stamps']
        })

        yield put(
            MerchantAccountsActions.STORE_CONTRACT_HISTORY(p.payload.accountId, contractsHistory?.contracts || [])
        )
    },
    *UPDATE_DRAFT({ payload }: ActionType<typeof MerchantAccountsActions.UPDATE_DRAFT>) {
        yield put(WatcherDispatchStart([payload.watcherId]))
        const { draft, changes } = payload
        const FieldToLink = resourceLinksForLocalFields(draft, JSON.parse(JSON.stringify(changes)))
        const PostConfig: any = {}

        Object.keys(changes).forEach((key) => {
            const link = FieldToLink[key]
            if (!link) return

            const newKey = snakeCase(key.split('.').pop())
            PostConfig[link] = {
                ...(PostConfig[link] || {}),
                [newKey]: changes[key].to || 0
            }
        })

        let encounteredErrors = false

        // eslint-disable-next-line no-restricted-syntax
        const requests = Object.keys(PostConfig).map((link) => {
            return POST({
                url: link,
                successCode: 200,
                body: PostConfig[link],
                include: ['self'],
                errorText: 'Failed to update section'
            })
        })

        ;((yield all(requests)) as any[]).forEach(({ success }) => {
            if (!success) encounteredErrors = true
        })

        yield put(
            MerchantAccountsActions.REFETCH_UPDATED_DRAFT_CONTRACT(payload.accountId, payload.draft.selfLink, true)
        )
        yield deoxWaitFor(MerchantAccountsActions.STORE_DRAFT, (p) => p.accountId === payload.accountId)

        if (!encounteredErrors) {
            yield put(WatcherDispatchSuccess([payload.watcherId], 'Draft updated successfully'))
        } else {
            yield put(WatcherDispatchFail([payload.watcherId], 'Failed to update draft'))
        }
    },
    *REFETCH_UPDATED_DRAFT_CONTRACT(p: ActionType<typeof MerchantAccountsActions.REFETCH_UPDATED_DRAFT_CONTRACT>) {
        const draft: MerchantAccountDraftContract = yield GET({
            url: p.payload.draftLink,
            errorText: 'Failed to load account.',
            include: ['self', 'contract']
        })

        yield put(MerchantAccountsActions.STORE_DRAFT(p.payload.accountId, draft))
    },
    *FETCH_ACCOUNT(p: ActionType<typeof MerchantAccountsActions.FETCH_ACCOUNT>) {
        const account: { [key: string]: any } = yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/accounts/${p.payload.accountId}`,
            errorText: 'Failed to load account.',
            include: [
                'self',
                'contract',
                'api-keys',
                'members',
                'stamps',
                'draft-contract',
                'applicationId',
                'application'
            ]
        })

        const otherResources = yield* all([
            ...(account?.draftContractLink
                ? [
                      GET({
                          url: account.draftContractLink,
                          errorText: 'Failed to load account.',
                          include: ['self', 'contract']
                      })
                  ]
                : []),
            ...(account?.contractLink
                ? [
                      GET({
                          url: `${account.contractLink}/version-history?per_page=50`,
                          errorText: "Failed to load accounts's contract history.",
                          include: ['self', 'stamps']
                      })
                  ]
                : [])
        ])

        if (!account) return

        const contract: { [key: string]: any } = account?.contractLink
            ? yield GET({
                  url: account.contractLink,
                  errorText: "Failed to load account's contract.",
                  include: ['self', 'stamps']
              })
            : undefined

        const apiKeys: { [key: string]: any } = account?.apiKeysLink
            ? yield FetchAllPages<MerchantAccount['apiKeys'][]>(
                  account.apiKeysLink,
                  (acc, res) => {
                      return [...acc, ...res.apiKeys]
                  },
                  ['self']
              )
            : []

        const members = account?.membersLink
            ? yield* FetchAllPages<MerchantAccount['members']>(
                  account.membersLink,
                  (acc, res) => {
                      return [...acc, ...res.members]
                  },
                  ['self']
              )
            : []

        const applicationId = account.applicationLink?.substr(account.applicationLink?.lastIndexOf('/') + 1)

        yield put(
            MerchantAccountsActions.STORE_ACCOUNT_WITH_CONTRACT(applicationId, {
                ...(account as any),
                contract: contract as any,
                apiKeys: apiKeys as any,
                extraDetailsLoaded: true,
                members: members as any
            })
        )

        yield put(MerchantAccountsActions.STORE_DRAFT(p.payload.accountId, otherResources[0]))

        yield put(
            MerchantAccountsActions.STORE_CONTRACT_HISTORY(p.payload.accountId, otherResources[1]?.contracts || [])
        )
        if (p.payload.watcherId)
            yield put(
                WatcherDispatchSuccess(
                    [p.payload.watcherId],
                    p.payload.watcherMessage || 'Merchant account fetched successfully'
                )
            )
    },
    *INVITE_MEMBER(p: ActionType<typeof MerchantAccountsActions.INVITE_MEMBER>) {
        yield POST({
            watcher: p.payload.watcherId,
            url: p.payload.link,
            body: {
                email: p.payload.email,
                message: p.payload.invitationMessage || "You've been invited to join a merchant account at Clearhaus."
            },
            include: ['self'],
            successText: 'Account member invited successfully.',
            errorText: 'Failed to invite a new contract member.',
            onSuccessDispatch: (r) => MerchantAccountsActions.STORE_MEMBER(p.payload.accountId, r.id, r)
        })
    },
    *REMOVE_MEMBER(p: ActionType<typeof MerchantAccountsActions.REMOVE_MEMBER>) {
        yield DELETE({
            watcher: p.payload.watcherId,
            url: p.payload.link,
            successCode: 204,
            errorText: 'Failed to remove member from merchant account.',
            successText: 'Account member removed successfully.',
            onSuccessDispatch: (res) =>
                MerchantAccountsActions.STORE_MEMBER(p.payload.accountId, p.payload.id, undefined)
        })
    },
    *FETCH(p: ActionType<typeof MerchantAccountsActions.FETCH>) {
        const accounts = yield* FetchAllPages<MerchantAccount>(
            `${import.meta.env.VITE_API_ROOT}/applications/${p.payload.merchantId}/accounts`,
            (acc, res) => {
                return [...acc, ...res.accounts]
            },
            ['self', 'draft-contract']
        ) || []

        const accountRequests =
            accounts?.map((acc: any) => {
                return fetchAccountAndContract(acc.selfLink)
            }) || []

        const accountsWithContracts = yield* all(accountRequests)

        yield put(MerchantAccountsActions.STORE_ACCOUNTS_WITHOUT_CONTRACTS(p.payload.merchantId, accountsWithContracts))
    }
}

function* fetchAccountAndContract(link: string) {
    const account = yield* GET({
        url: link,
        errorText: 'Failed to load a specific application account.',
        include: ['self', 'contract', 'api-keys', 'members', 'stamps', 'draft-contract']
    })

    const contract: { [key: string]: any } = account?.contractLink
        ? yield GET({
              url: account.contractLink,
              errorText: "Failed to load account's contract.",
              include: ['self', 'stamps']
          })
        : undefined

    if (contract) {
        account.contract = contract
    }

    return account
}
