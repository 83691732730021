import React, { useState } from 'react'
import { Popover } from '../popover'
import { Calendar } from '../calendar'

interface DatePickerProps {
    children: React.ReactNode
    selected?: Date
    onSelect?: (date?: Date) => void
}

const DatePicker = (props: DatePickerProps) => {
    const { children, selected, onSelect } = props

    const [open, setOpen] = useState(false)

    const handleOnSelect = (date?: Date) => {
        setOpen(false)
        onSelect?.(date)
    }

    const onOpenChange = (open: boolean) => {
        setOpen(open)
    }

    return (
        <Popover open={open} onOpenChange={onOpenChange}>
            {children}
            <Popover.Content>
                <Calendar selected={selected} onSelect={handleOnSelect} />
            </Popover.Content>
        </Popover>
    )
}

interface TriggerProps {
    testid?: string
    children: React.ReactNode
}

const Trigger: React.FC<TriggerProps> = (props) => {
    const { testid, children } = props

    return <Popover.Trigger testid={testid}>{children}</Popover.Trigger>
}

DatePicker.Trigger = Trigger

export { DatePicker }
