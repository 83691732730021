import { useMemo } from 'react'
import { MerchantAccountContract } from '../../../store/merchantAccounts/types'

export const useLatestSignedContract = (contractsHistory?: {
    [key: string]: MerchantAccountContract
}): string | undefined => {
    return useMemo(() => {
        if (!contractsHistory) return undefined
        return Object.keys(contractsHistory).filter((key, id) => {
            const c = contractsHistory[key]
            return c.metadata.state === 'signed'
        })[0]
    }, [contractsHistory])
}
