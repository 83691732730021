import React from 'react'
import { FormProvider as ReactHookFormProvider, useForm } from 'react-hook-form'
import {
    ReconcilePostingData,
    ReconcileVoucherData
} from '@/services/reconciliation'

interface FormProviderProps {
    children: React.ReactNode
    data: "voucher" | "posting"
}

export const FormProvider: React.FC<FormProviderProps> = (props) => {
    const { children, data } = props

    const defaults = (data === "voucher")
        ?
            {
                posting_ids: [],
                note: ""
            }
        :
            {
                voucher_ids: [],
                note: ""
            }

    const methods = useForm<ReconcileVoucherData | ReconcilePostingData>({
        defaultValues: defaults
    })

    return <ReactHookFormProvider {...methods}>{children}</ReactHookFormProvider>
}
