import _, { camelCase } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { formatWebsites, getWebsiteLabel } from '../../utils/formatting'
import { Expander } from '../../components/layout/expander'

import { ExternalLink } from '../../components/buttons/externalLink'

export const MerchantWebsites: React.FC<{
    websitesString: string
    nonLinks?: boolean
}> = ({ websitesString, nonLinks }) => {
    const urls = useMemo(() => {
        return _.uniq(websitesString?.split(', ') || []).map((url: string) => url)
    }, [websitesString])

    const { shownWebsites, otherWebsites } = useMemo(() => {
        const shownWebsites: React.ReactNode[] = []
        const otherWebsites: React.ReactNode[] = []

        formatWebsites(urls).forEach((w: any, index: number) => {
            let pushTo = otherWebsites

            if (index < 1) pushTo = shownWebsites

            pushTo.push(
                <WebsiteWrapper key={w.url}>
                    {nonLinks ? (
                        <ExternalLink
                            url={w.url}
                            scriptBased
                            target="blank"
                            defaultColor={index < 1 ? undefined : 'floating.subtleAccent.text'}
                            key={camelCase(w.url)}
                            label={getWebsiteLabel(w.label)}
                        />
                    ) : (
                        <ExternalLink
                            url={w.url}
                            target="blank"
                            defaultColor={index < 1 ? undefined : 'floating.subtleAccent.text'}
                            key={camelCase(w.url)}
                            label={getWebsiteLabel(w.label)}
                        />
                    )}
                </WebsiteWrapper>
            )
        })

        return { shownWebsites, otherWebsites }
    }, [urls, nonLinks])

    if (!urls || shownWebsites.length == 0) return <span>-</span>

    return (
        <Holder>
            {shownWebsites}
            <Expander count={otherWebsites.length} listMemo={otherWebsites} />
        </Holder>
    )
}

const WebsiteWrapper = styled.div`
    margin-right: 5px;
    word-break: break-all;
    display: inline;
`

const Holder = styled.div`
    display: inline;
`
