import { throttle } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { MODAL_ID } from '../../components/modals/modalIds'
import { useHotkey } from '../hotkeys/useHotkey'
const getElementIndex = (el: any) => {
    if (!el) return undefined
    if (
        el.hasAttribute('data-list-index') ||
        (el.closest('[data-list-index]') !== undefined && el.closest('[data-list-index]') !== null)
    ) {
        return (
            parseInt(
                el.getAttribute('data-list-index') || el.closest('[data-list-index]')?.getAttribute('data-list-index')
            ) || 0
        )
    }
    return undefined
}
export const useSelectable = (
    items: string[],
    hotkeysScope: MODAL_ID,
    onSelectionMade: (item: string) => void,
    onBlankEnter?: (value?: string) => void,
    onSelectionChange?: (index?: number) => void,
    filteringValue?: string,
    noClickEvents?: boolean
) => {
    const [highlighted, setHighlighted] = useState<number | undefined>(undefined)
    const itemCount = useMemo(() => {
        return items?.length || 0
    }, [items])

    const lastBlankEnterFilteringValue = useRef<string | undefined>(undefined)

    useEffect(() => {
        setHighlighted(undefined)
    }, [items])

    useEffect(() => {
        if (!filteringValue) return
        setHighlighted(undefined)
    }, [filteringValue])

    useEffect(() => {
        return () => {
            setHighlighted(undefined)
        }
    }, [])

    const shouldBlockEnterKey = useRef(false)

    useEffect(() => {
        shouldBlockEnterKey.current = false
    }, [highlighted])

    const onEnterPressed = useCallback(
        (e: any) => {
            if (shouldBlockEnterKey.current === false) {
                shouldBlockEnterKey.current = true

                setTimeout(() => {
                    shouldBlockEnterKey.current = false
                }, 200)

                if (highlighted === undefined) {
                    if (lastBlankEnterFilteringValue.current !== filteringValue) {
                        onBlankEnter?.(filteringValue)
                        lastBlankEnterFilteringValue.current = filteringValue
                    }
                    return
                }
                onSelectionMade(items[highlighted])
            }
        },
        [highlighted, onSelectionMade, items, filteringValue, onBlankEnter]
    )

    const highlightPrev = useCallback(
        throttle((e: any) => {
            e.preventDefault()
            setHighlighted((i) => {
                if (i === undefined) return itemCount ? itemCount - 1 : undefined
                const newI = i - 1 < 0 ? undefined : i - 1
                onSelectionChange?.(newI)
                return newI
            })
        }, 50),
        [itemCount, setHighlighted, onSelectionChange]
    )

    const highlightNext = useCallback(
        throttle((e: any) => {
            e.preventDefault()
            setHighlighted((i) => {
                onSelectionChange?.(i)
                if (i === undefined) return itemCount ? 0 : undefined
                const newI = i + 1 >= itemCount ? undefined : i + 1
                onSelectionChange?.(newI)
                return newI
            })
        }, 50),
        [itemCount, setHighlighted, onSelectionChange]
    )

    useHotkey({
        keys: 'up',
        scope: hotkeysScope,
        clue: 'bottom-left',
        action: highlightPrev
    })

    useHotkey({
        keys: 'down',
        scope: hotkeysScope,
        clue: 'bottom-left',
        action: highlightNext
    })
    useHotkey({
        keys: 'enter',
        scope: hotkeysScope,
        clue: 'bottom-left',
        action: onEnterPressed
    })

    // const onMouseIn = useCallback(
    //     (e) => {
    //         const element = e.currentTarget
    //         const list = element.parentNode
    //         const index = Array.prototype.indexOf.call(list.children, element)
    //         setHighlighted(index)
    //     },
    //     [setHighlighted]
    // )

    const previousTarget = useRef<any>(undefined)

    useEffect(() => {
        const onMouseMove = (e: any) => {
            const target = e.target
            if (target !== previousTarget.current) {
                previousTarget.current = target
                const index = getElementIndex(target)
                if (index !== undefined) {
                    setHighlighted(index)
                }
            }
        }
        document.addEventListener('mousemove', onMouseMove)
        return () => {
            document.removeEventListener('mousemove', onMouseMove)
        }
    }, [])

    const onMouseClick = useCallback(
        (e) => {
            if (noClickEvents) return
            e.stopPropagation()
            const element = e.target
            const index = getElementIndex(element)
            if (index !== undefined) {
                setHighlighted(index)
                onSelectionMade(items[index])
            }
        },
        [onSelectionMade, items, noClickEvents]
    )

    const onMouseOut = useCallback((e) => {
        // setHighlighted(undefined)
    }, [])

    const resetHighlight = useCallback(() => {
        setHighlighted(undefined)
    }, [setHighlighted])

    return {
        onMouseOut,
        onMouseClick,
        highlighted,
        resetHighlight
    }
}
