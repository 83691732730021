import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FilePreviewer } from '../components/modals/filePreviewer'
import { FileActionDispatchFetchFile } from '../store/files/actions'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const PreviewFile: React.FC = () => {
    const params = useParams() as Params
    const id = params.id
    const dispatch = useDispatch()

    useEffect(() => {
        if (id) dispatch(FileActionDispatchFetchFile(id))
    }, [id, dispatch])

    return <FilePreviewer wholePage id={id} stackIndex={0} />
}
