import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { SimpleButton } from '../buttons/simpleButton'
import { Flex } from '../layout/flex'
import { PrintOnly } from '../general/printOnly'
import { Text } from '../general/text'
import { Spacer } from '../layout/spacer'

const MAX_LIMIT = 150
export const BusinessModelBlock: React.FC<{
    businessModel?: string
    shortBusinessModel?: string
}> = (p) => {
    const [showMoreBusinessModel, setShowMoreBusinessModel] = useState(false)
    const [processedBusinessModelText, setBusinessModel] = useState('')

    useEffect(() => {
        if (p.shortBusinessModel) {
            setBusinessModel(p.shortBusinessModel)
            return
        }
        if (p.businessModel) {
            if (p.businessModel.length > MAX_LIMIT && !showMoreBusinessModel) {
                setBusinessModel(`${p.businessModel.substr(0, MAX_LIMIT)}...`)
                return
            }
            setBusinessModel(p.businessModel)
        }
    }, [p.businessModel, p.shortBusinessModel, showMoreBusinessModel])

    if (!p.businessModel) return null

    const businessModelText = p.shortBusinessModel ? p.shortBusinessModel : p.businessModel

    return (
        <>
            <Holder style={{ marginTop: 15 }} column align="flex-start">
                <Text>{processedBusinessModelText}</Text>
                {businessModelText.length > MAX_LIMIT && (
                    <>
                        <Spacer height={10} />
                        <SimpleButton
                            color="side.accent.color"
                            onClick={() => {
                                setShowMoreBusinessModel((t) => !t)
                            }}
                        >
                            {showMoreBusinessModel ? 'See less' : 'See more'}
                        </SimpleButton>
                    </>
                )}
            </Holder>
            <PrintOnly>
                <BusinessPrintText>{businessModelText}</BusinessPrintText>
            </PrintOnly>
        </>
    )
}

const Holder = styled(Flex)`
    @media print {
        display: none !important;
    }
`
const BusinessPrintText = styled(Text)`
    max-width: 50%;
`
