import { isArray, isObject } from 'lodash'

import { MerchantApplicationsInternalsChecksState } from './types'

export function ParseChecksFromCutterRequest(
    body: any,
    acc: any = {},
    path: any = ''
) {
    if (isObject(body)) {
        Object.keys(body).forEach((key: string) => {
            const bodySection = (body as any)[key] as any
            if (key === 'checks' && !acc[bodySection]) {
                const p = path || 'application'
                if (path.includes('applicationFiles')) return
                if (!acc[p]) acc[p] = {}
                bodySection.forEach((item: any) => {
                    acc[p] = {
                        ...acc[p],
                        fields: {
                            ...acc[p].fields,
                            [item.field]: {
                                checkLink: item.selfLink,
                                isChecked: item.checked
                            }
                        }
                    }
                })
            } else if (key === 'checksLink' && !acc[bodySection]) {
                const p = path || 'application'
                if (path.includes('applicationFiles')) return
                if (!acc[p]) acc[p] = {}
                acc[p] = {
                    ...acc[p],
                    sectionLink: bodySection
                }
            } else if (isArray(bodySection) || isObject(bodySection)) {
                ParseChecksFromCutterRequest(
                    bodySection,
                    acc,
                    key ? `${path ? `${path}.` : ''}${key}` : path
                )
            }
        })
    }

    return acc as MerchantApplicationsInternalsChecksState['forApplication']['ID']
}
