import { createActionCreator } from 'deox'

const Prefix = 'NEEDING_ATTENTION'

export const ApplicationInternalsNeedingAttentionActions = {
    SCAN_COMMENTS: createActionCreator(`${Prefix}/SCAN_COMMENTS`, (resolve) => (applicationId: string) =>
        resolve({ applicationId })
    ),
    STORE_COMMENTS: createActionCreator(
        `${Prefix}/STORE_COMMENTS`,
        (resolve) => (
            applicationId: string,
            unreadComments: string[],
            unreadFields: string[],
            internalNotesNeedsAttention: boolean
        ) => resolve({ applicationId, unreadComments, unreadFields, internalNotesNeedsAttention })
    )
}
