import { getCurrentTheme } from '@/hooks/general/useTheme'
import React, { useEffect } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'
import styled, { ThemeProvider, css } from 'styled-components'
import { Text } from '@/components/general/text'
import { Icon } from '@/components/icons/icon'
import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import { Button } from '@/components/buttons/simpleButton'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { TrackJS } from 'trackjs'

export const ErrorPage: React.FC = () => {
    const theme = getCurrentTheme()

    return (
        <ThemeProvider theme={theme}>
            <Page />
        </ThemeProvider>
    )
}

const Page: React.FC = () => {
    return (
        <PageWrapper>
            <Content />
        </PageWrapper>
    )
}

const PageWrapper = styled.div`
    ${(p) => css`
        min-height: 100vh;
        background: ${p.theme['back.background']};
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`

const Content: React.FC = () => {
    const navigate = useNavigate()
    const error = useRouteError()
    const parsedError = getParsedError(error)

    useEffect(() => {
        if (import.meta.env.VITE_TRACK_JS_TOKEN) TrackJS.track(parsedError)
        else console.error(parsedError)
    }, [parsedError])

    return (
        <Flex justify="center" align="center" column style={{ maxWidth: 600 }}>
            <Icon type="sidebarDisputes" color="front.text.subtlerI" size={16} />
            <Spacer height={16} />
            <Text bold color="front.text" align="center">
                Error loading page
            </Text>
            <Spacer height={12} />
            <Text color="back.text.strongerII" align="center" style={{ lineHeight: '1.75' }}>
                An error occurred while loading the page. If you need help please copy the error message below and
                contact Console maintainers for help.
            </Text>
            <Spacer height={20} />
            {import.meta.env.VITE_AUTH0_REDIRECT && (
                <Button background="front.accent.color" onClick={() => navigate('/')} round>
                    <ButtonInset>Go back</ButtonInset>
                </Button>
            )}
            <Spacer height={42} />
            <Card background="back.background.strongerII">
                <CardInset>
                    <pre style={{ fontSize: 11, maxWidth: 600 }}>
                        {JSON.stringify(serializeError(parsedError), undefined, 2)}
                    </pre>
                </CardInset>
            </Card>
        </Flex>
    )
}

const getParsedError = (error: unknown) => {
    let parsedError = {}
    if (!error) {
        parsedError = { message: 'No error found' }
    } else if (typeof error === 'object') {
        parsedError = error
    } else if (typeof error === 'string') {
        parsedError = { message: error }
    } else {
        parsedError = { message: 'Unknown error' }
    }

    return parsedError
}

const serializeError = (error) => {
    const serializedError = {}

    Object.getOwnPropertyNames(error).forEach((key) => {
        serializedError[key] = error[key]
    })

    return serializedError
}
