// import { Spinner } from "react-bootstrap"
import { ComponentProps, ReactElement, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { HighlightableBackgrounds } from '../../hooks/general/useTheme'
import { Color } from '../../styles/colors'

import { SimpleButton } from '../buttons/simpleButton'
import { Spacer } from '../layout/spacer'
import { Spinner } from './spinner'

export function SlimLoader({
    background,
    color
}: {
    background?: ComponentProps<typeof SimpleButton>['background']
    color?: ComponentProps<typeof SimpleButton>['color']
}): ReactElement {
    const colors = useMemo((): {
        background?: Color
        fill: Color
    } => {
        if (background === 'front.accent.color') {
            return {
                background: 'front.accent.color.strongerII',
                fill: 'front.accent.text'
            }
        }
        if (background === 'front.danger.color') {
            return {
                background: 'front.danger.color.strongerII',
                fill: 'front.danger.text'
            }
        }
        if (background === 'front.success.color') {
            return {
                background: 'front.success.color.strongerII',
                fill: 'front.success.text'
            }
        }

        if (background === 'front.background') {
            return {
                background: 'front.highlights.subtlerI',
                fill: 'front.accent.color'
            }
        }

        if (background === 'floating.background') {
            return {
                background: 'floating.background.subtlerII',
                fill: 'front.accent.color'
            }
        }

        if (background === 'back.background') {
            return {
                background: 'back.background.strongerI',
                fill: 'back.background.strongerII'
            }
        }

        if (background === 'front.success.color') {
            return {
                background: 'front.success.color.strongerI',
                fill: 'front.success.text'
            }
        }

        if (background === 'side.background') {
            return {
                background: 'side.background.strongerI',
                fill: 'side.accent.color'
            }
        }

        return {
            background: background,
            fill: color || ('front.accent.color' as const)
        }
    }, [background, color])

    return (
        <BarLoader background={colors.background} color={colors.fill} data-cy="loader">
            <Progress background={colors.background} color={colors.fill} />
        </BarLoader>
    )
}

export const LoaderView: React.FC<{
    type?: 's' | 'm' | 'l'
    minHeight?: string
    minWidth?: string
    offsetTop?: number
    style?: React.CSSProperties
    overBackground: React.ComponentProps<typeof Spinner>['overBackground']
}> = ({ type = 'm', offsetTop, overBackground, minHeight, minWidth, style }) => {
    return (
        <View
            style={{
                marginTop: offsetTop ? `${offsetTop}px` : 0,
                boxSizing: 'border-box',
                ...style,
                ...(minHeight ? { minHeight } : {}),
                ...(minWidth ? { minWidth } : {})
            }}
        >
            <Spinner type={type} overBackground={overBackground} />
        </View>
    )
}

const View = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`

export const TableLoader: React.FC<{ colSpan: number; text: string; overBackground: HighlightableBackgrounds }> = ({
    colSpan,
    text,
    overBackground
}) => {
    return (
        <td colSpan={colSpan}>
            <Holder>
                <Spinner type="s" data-cy="loader" overBackground={overBackground} />
                <Spacer width={8} />
                {text}
            </Holder>
        </td>
    )
}

export const BlinkAnimation = keyframes`
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
`

const move = keyframes`
  0% {
    transform: translateX(-100%) translateZ(0);
  }

  80% {
    transform: translateX(251%) translateZ(0);
  }
  100% {
    transform: translateX(251%) translateZ(0);
  }
`

const Holder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: ${BlinkAnimation} 0.9s ease-in-out infinite alternate;
`

const BarLoader = styled.div<{
    background: ComponentProps<typeof SimpleButton>['background']
    color: ComponentProps<typeof SimpleButton>['color']
}>`
    height: 2px;
    overflow: hidden;
    position: relative;
    border-radius: 100px;
    width: 100%;
    min-width: 0;
    background-color: ${(p) => (p.background ? p.theme[p.background] : 'rgba(0,0,0,0.15)')};
`
/* background-color: ${(p) => (ButtonLoaderStyles as any)[p.background][p.color].backgroundColor}; */

const Progress = styled.div<{
    background: ComponentProps<typeof SimpleButton>['background']
    color: ComponentProps<typeof SimpleButton>['color']
}>`
    width: 40%;
    height: 100%;
    animation: ${move} 1s ease-in-out infinite;
    will-change: transform;
    background-color: ${(p) => p.theme[p.color || 'front.accent.color']};
` /* background-color: ${(p) => (ButtonLoaderStyles as any)[p.background][p.color].foregroundColor}; */
