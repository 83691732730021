import { checkBankAccountMismatch } from './checkBankAccountMismatch'
import { RootState } from '@/store'
import { useSelector } from 'react-redux'

const useApplicationState = (applicationId: string) => {
    const application = useSelector((state: RootState) => state.applications.applications.at[applicationId])

    const isLoading = application?.loadingStatus !== 'done'
    const state = application?.metadata?.state ? application.metadata.state : undefined

    return { isLoading, state }
}

const useApplicationIban = (applicationId: string) => {
    const bankAccount = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.bankAccount
    )

    const isLoading = bankAccount?.loadingStatus !== 'done'
    const iban: string | undefined = bankAccount?.fields[0]?.iban

    return { isLoading, iban }
}

const useAccountsIban = (applicationId: string) => {
    const applicationAccounts = useSelector((state: RootState) => state.merchantAccounts.forApplication[applicationId])
    const applicationAccountsData = useSelector((state: RootState) => state.merchantAccounts.at)

    let isLoading = applicationAccounts?.loadingStatus !== 'done'

    let ibans: string[] = []
    if (applicationAccounts?.all) {
        ibans = applicationAccounts.all
            .map((accountId) => applicationAccountsData[accountId]?.bankAccount?.iban)
            .filter((account) => account !== undefined)
    }

    return { isLoading, ibans }
}

const useBankAccountMismatch = (applicationId: string) => {
    const applicationState = useApplicationState(applicationId)
    const bankAccount = useApplicationIban(applicationId)
    const accounts = useAccountsIban(applicationId)

    return checkBankAccountMismatch(applicationState, bankAccount, accounts)
}

export { useBankAccountMismatch }
