import { Transaction } from '../../store/transactions/types'
import { uppercaseFirstLetter, uppercaseFirstLetterIfLowercase } from '../../utils'
import { List } from '../../components/layout/list'
import { TimelineCard } from '../../components/listPages/timelineCard'
import { IconPaymentScheme } from '../../components/icons/iconPaymentScheme'
import { IconPaymentMethod, PaymentDescriptionDictionary } from '../../components/icons/iconPaymentMethod'
import { Text } from '../../components/general/text'
import styled from 'styled-components'
import { Spacer } from '../../components/layout/spacer'

export const TransactionCard: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
    return (
        <TimelineCard>
            <List
                background="front.background"
                items={{
                    'Scheme': {
                        type: 'custom',
                        node: <IconPaymentScheme scheme={transaction.card.scheme} />
                    },
                    'Card number': `${transaction.card.bin} *** ${transaction.card.last4}`,
                    'Country': transaction.card.country || '-',
                    'Card segment': transaction.card.segment
                        ? uppercaseFirstLetterIfLowercase(transaction.card.segment)
                        : undefined,
                    'Expiry': `${transaction.card.expireYear}/${transaction.card.expireMonth}`,
                    ...(transaction.card.type === 'unknown'
                        ? {}
                        : {
                              'Card type': uppercaseFirstLetter(transaction.card.type) + ' card'
                          }),
                    'Payment method': {
                        type: 'custom',
                        node: (
                            <Row>
                                <IconPaymentMethod
                                    size={17}
                                    method={transaction.paymentMethod}
                                    card={{ scheme: transaction.card.scheme }}
                                />
                                <Spacer width={5} />
                                <Text oneLine>{PaymentDescriptionDictionary[transaction.paymentMethod]}</Text>
                            </Row>
                        )
                    }
                }}
                template={`min-content auto min-content min-content ${transaction.card.segment ? 'min-content' : ''} ${
                    transaction.card.type === 'unknown' ? '' : 'min-content'
                } minmax(min-content,140px)`}
                switchToRowsAt={500}
            />
        </TimelineCard>
    )
}

const Row = styled.div`
    display: flex;
    align-items: center;
`
