import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { ApplicationInternalsCollaboratorsActions } from '../../../store/applicationInternals/collaborators/actions'
import { RootState } from '@/store'
import { Table } from '../../../components/tables/table'
import { Text } from '../../../components/general/text'
import { ApplicationFieldWarnNotCollaborator } from '../Application/Application.FieldValueEditing.WarnNotCollaborator'

export const MerchantSummaryCollaboratorsCard: React.FC<{
    merchantId: string
    stillLoading?: boolean
}> = ({ merchantId, stillLoading }) => {
    const dispatch = useDispatch()

    const collaborators = useSelector(
        (state: RootState) => state.applicationInternals.collaborators.forApplication[merchantId]
    )

    const rightSideMemo = useMemo(() => {
        return (
            <Flex justify="flex-end" grow>
                <LinkButton
                    to={`/merchant/${merchantId}/summary/add-collaborator`}
                    color="front.accent.color"
                    passBackToState
                    hotkeysScope="Merchant.Summary"
                    hotkeys="alt+i"
                >
                    + Invite
                </LinkButton>
            </Flex>
        )
    }, [merchantId])

    const cols = useMemo(() => {
        return [
            { text: 'Email', loaderSize: { min: 30, max: 60 } },
            { text: 'Actions', loaderSize: { min: 30, max: 60 } }
        ]
    }, [])
    const rows = useMemo(() => {
        if (!collaborators?.collaborators || collaborators?.collaborators?.length == 0) return []
        return collaborators.collaborators
            ?.filter((item, i) => i < 5)
            .map((collaborator, index, arr) => {
                return {
                    type: 'normal' as const,
                    noAction: true,
                    key: collaborator.email,
                    items: [
                        {
                            node: <Text color="back.text.strongerIII">{collaborator.email}</Text>
                        },
                        {
                            node: (
                                <Flex noShrink>
                                    <WatcherButton
                                        color="front.accent.color"
                                        cy={`${collaborator.email}-revoke`}
                                        onClick={(event, watcherId) => {
                                            dispatch(
                                                ApplicationInternalsCollaboratorsActions.REVOKE(
                                                    watcherId,
                                                    merchantId,
                                                    collaborator.selfLink,
                                                    collaborator.email
                                                )
                                            )
                                        }}
                                    >
                                        <ButtonInset noHorizontalPadding noVerticalPadding>
                                            Revoke
                                        </ButtonInset>
                                    </WatcherButton>
                                    <Spacer width={10} />
                                    <WatcherButton
                                        color="front.accent.color"
                                        cy={`${collaborator.email}-re-invite`}
                                        onClick={(event, watcherId) => {
                                            dispatch(
                                                ApplicationInternalsCollaboratorsActions.RESEND_INVITATION(
                                                    watcherId,
                                                    merchantId,
                                                    collaborator.email
                                                )
                                            )
                                        }}
                                    >
                                        <ButtonInset noHorizontalPadding noVerticalPadding>
                                            Re-invite
                                        </ButtonInset>
                                    </WatcherButton>
                                </Flex>
                            )
                        }
                    ]
                }
            })
    }, [collaborators, dispatch, merchantId])

    return (
        <Flex column cy="summary-collabs">
            <Flex>
                <Text color="front.text.subtlerI">Collaborators</Text>
                {rightSideMemo}
            </Flex>
            <Spacer height={15} />
            <Table
                noHeader
                bordered
                background="back.background.strongerI"
                cols={cols}
                displayLoader={stillLoading}
                columnLayout="1fr min-content"
                overrideText="No collaborators found."
                rows={rows}
                loaderRows={2}
                type="narrow"
                noHighlights
            />
            <Flex grow />
            <ApplicationFieldWarnNotCollaborator applicationId={merchantId} shownInSummary />
        </Flex>
    )
}
