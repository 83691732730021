import { TableRow } from '@/components/tables/table'
import { useGetAccounts, useGetPostings, useGetVoucher } from '@/services/reconciliation'
import React, { useMemo, useState } from 'react'
import { getPostingCols, getPostingRows, prependChecks } from '../../postings/postings-table/helpers'
import { Entries } from '../../common/entries'
import { LoadHigherAmountsButton } from '../common/load-higher-amounts-button'
import { Box, Flex, Separator, Text } from '@/design-system'
import { useReconciliationParams } from '@/features/reconciliation/hooks'

export const ListPostingSection: React.FC = () => {
    const [filteredRows, setFilteredRows] = useState<TableRow[]>([])
    const [loadHigherAmounts, setLoadHigherAmounts] = useState(false)

    const { id } = useReconciliationParams()
    const { data: voucher } = useGetVoucher(id)
    const { data: postingsData } = useGetPostings()
    const { data: accountsData } = useGetAccounts()

    const postings = useMemo(() => {
        if (!voucher || !postingsData) {
            return []
        }

        return postingsData.items
            .filter((p) =>
                loadHigherAmounts
                    ? p.currency === voucher.currency
                    : p.currency === voucher.currency && p.amount <= voucher.amount
            )
            .sort((a, b) => {
                return b.amount - a.amount
            })
    }, [postingsData, voucher, loadHigherAmounts])

    const accounts = useMemo(() => accountsData?.items || [], [accountsData])

    const { cols, rows } = useMemo(() => {
        return prependChecks(getPostingCols(), getPostingRows(postings, accounts))
    }, [postings, accounts])

    const onLoadHigherAmounts = () => {
        setLoadHigherAmounts((t) => !t)
    }

    if (!voucher) {
        return null
    }

    return (
        <Entries>
            <Entries.Title>Postings</Entries.Title>
            <Entries.Search cols={cols} rows={rows} onFilteredRowsChange={setFilteredRows} />
            <Entries.TableActions>
                <LoadHigherAmountsButton
                    data={voucher}
                    loadHigherAmounts={loadHigherAmounts}
                    onClick={onLoadHigherAmounts}
                />
            </Entries.TableActions>
            <Entries.Table
                cols={cols}
                rows={filteredRows}
                columnLayout="56px min-content min-content min-content minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) min-content min-content"
                emptyText="No unreconciled postings found."
            />
        </Entries>
    )
}
