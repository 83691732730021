import { TableRow } from '@/components/tables/table'
import { useGetAccounts, useGetPosting, useGetVouchers } from '@/services/reconciliation'
import React, { useMemo, useState } from 'react'
import { getVoucherCols, getVoucherRows, prependChecks } from '../../vouchers/vouchers-table/helpers'
import { useReconciliationParams } from '../../../hooks'
import { Entries } from '../../common/entries'
import { LoadHigherAmountsButton } from '../common/load-higher-amounts-button'

export const ListVoucherSection: React.FC = (props) => {
    const [filteredRows, setFilteredRows] = useState<TableRow[]>([])
    const [loadHigherAmounts, setLoadHigherAmounts] = useState(false)

    const { id } = useReconciliationParams()
    const { data: posting } = useGetPosting(id)
    const { data: vouchersData } = useGetVouchers()
    const { data: accountsData } = useGetAccounts()

    const vouchers = useMemo(() => {
        if (!posting || !vouchersData) {
            return []
        }

        return vouchersData.items
            .filter((p) =>
                loadHigherAmounts
                    ? p.currency === posting.currency
                    : p.currency === posting.currency && p.amount <= posting.amount
            )
            .sort((a, b) => {
                return b.amount - a.amount
            })
    }, [vouchersData, posting, loadHigherAmounts])

    const accounts = useMemo(() => accountsData?.items || [], [accountsData])

    const { cols, rows } = useMemo(() => {
        return prependChecks(getVoucherCols(), getVoucherRows(vouchers, accounts))
    }, [vouchers, accounts])

    const onLoadHigherAmounts = () => {
        setLoadHigherAmounts((t) => !t)
    }

    if (!posting) {
        return null
    }

    return (
        <Entries>
            <Entries.Title>Vouchers</Entries.Title>
            <Entries.Search cols={cols} rows={rows} onFilteredRowsChange={setFilteredRows} />
            <Entries.TableActions>
                <LoadHigherAmountsButton
                    data={posting}
                    loadHigherAmounts={loadHigherAmounts}
                    onClick={onLoadHigherAmounts}
                />
            </Entries.TableActions>
            <Entries.Table
                cols={cols}
                rows={filteredRows}
                columnLayout="56px min-content min-content min-content minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) min-content"
                emptyText="No unreconciled vouchers found."
            />
        </Entries>
    )
}
