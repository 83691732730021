import { Text } from '../../components/general/text'
import { Flag } from '../../components/icons/flag'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { Person } from '../../store/applications/types'
import countries from 'i18n-iso-countries'
import styled from 'styled-components'
import { TextInputSelect } from '../../components/forms/textInputSelect'
import { ApplicationPersonRoles } from './Application/Application.Navigation'
import { uppercaseFirstLetter } from '../../utils'
import { useCallback } from 'react'
import { ApplicationDataProvidersActions } from '../../store/applicationDataProviders/actions'
import { useDialog } from '../../hooks/general/useDialog'

export const PersonCountryRole: React.FC<{
    personId: string
    person: Person
    applicationId: string
}> = ({ personId, person: { country, role, name }, applicationId }) => {
    const textForItem = useCallback((k) => uppercaseFirstLetter(k), [])

    const confirmRoleChange = useDialog<{ selected: string }>(
        useCallback(
            (buttonWatcherId, { selected }) => {
                return ApplicationDataProvidersActions.EDIT_ROLE(buttonWatcherId, personId, applicationId, selected)
            },
            [applicationId, personId]
        ),
        'Confirm role change',
        useCallback(
            ({ selected }) => {
                return (
                    <Flex column key={selected}>
                        {role ? (
                            <Text>
                                Are you sure you want to change {name || 'Unnamed person'}'s role from{' '}
                                <Text bold>{uppercaseFirstLetter(role)}</Text> to{' '}
                                <Text bold>{uppercaseFirstLetter(selected)}</Text>?
                            </Text>
                        ) : (
                            <Text>
                                Are you sure you want to set {name || 'Unnamed person'}'s role to{' '}
                                <Text bold>{uppercaseFirstLetter(selected)}?</Text>
                            </Text>
                        )}
                    </Flex>
                )
            },
            [role, name]
        ),
        'Yes, change the role',
        'front.accent.color'
    )

    if (country || role)
        return (
            <Flex>
                {country && (
                    <>
                        <Flag code={country} smaller />
                        <Spacer width={5} />
                    </>
                )}
                <Text color="front.text.subtlerI" noWrap>
                    {[...(country ? [countries.getName(country.toUpperCase(), 'en')] : []), ...(role ? [''] : [])].join(
                        ' · '
                    )}
                </Text>
                {country && <Spacer width={3} />}
                <Bubble>
                    <TextInputSelect
                        placeholder="Select role"
                        isSeamless
                        cy="select-role"
                        items={ApplicationPersonRoles}
                        selected={role}
                        dropdownId="Merchant.ApplicationPage.SelectRole"
                        textForItem={textForItem}
                        onSelect={(selected) => {
                            confirmRoleChange({ selected: selected })
                        }}
                        skipFieldSelection
                        noClear
                        overBackground="front.background"
                    />
                </Bubble>
            </Flex>
        )
    return (
        <Text color="front.text.subtlerI" noWrap>
            Role and country not defined...
        </Text>
    )
}

const Bubble = styled.div`
    padding: 1px 6px;
    margin: -1px 0;
    border: 1px solid ${(p) => p.theme['front.border']};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    width: 80px;

    * {
        cursor: pointer;
        color: ${(p) => p.theme['front.text.subtlerI']};
    }

    &:hover {
        background-color: ${(p) => p.theme['front.highlights']};
    }
`

const Holder = styled.div`
    padding: 3px;
`
