import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { LocallyStoredDraftContract, MerchantAccountContract } from '../../../store/merchantAccounts/types'
import { uppercaseFirstLetter } from '../../../utils'
import { MerchantAccountsContractStatus } from './Accounts.ID.ContractStatus'
import { useContractHistoryAnalyzer } from './useContractHistoryAnalyzer'
import { DateFormats } from '../../../utils/dateUtils'
import { HeaderText } from '../../../components/tables/table'
import moment from 'moment'
import { ContractEffectivePeriod } from './Accounts.ID.ContractEffectivePeriod'
import { MerchantPageUISelectedContract } from './utils'
import { useLocation, useNavigate } from 'react-router-dom'

export const MerchantAccountContractsHistory: React.FC<{
    baseLink?: string
    draft?: LocallyStoredDraftContract
    history?: { [key: string]: MerchantAccountContract }
    selectedContract?: MerchantPageUISelectedContract
}> = ({ baseLink, selectedContract, history: contractsHistory, draft }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const onLinkClick = useCallback(
        (contract: MerchantAccountContract) => {
            if (!contractsHistory) return
            navigate(
                {
                    pathname: `${baseLink}/${contract.id}`,
                    search: location.search
                },
                {
                    replace: true,
                    state: {
                        noLatestRedirect: true
                    }
                }
            )
        },
        [baseLink, contractsHistory, navigate, location]
    )
    const { effectivePeriods } = useContractHistoryAnalyzer(contractsHistory)

    const draftRow = useMemo(() => {
        const status = draft?.state === 'new' ? 'draft' : draft?.state
        if (!status) throw "Couldn't find status"
        return (
            <>
                <Row
                    onClick={() => onLinkClick({ id: 'draft' } as any)}
                    active={selectedContract?.contract?.id === draft?.id}
                >
                    <Cell></Cell>
                    <Cell>
                        <Text>Draft</Text>
                    </Cell>
                    <Cell>
                        <DraftTag emphasized={status !== 'draft'}>
                            {status == 'draft' ? 'DRAFT' : uppercaseFirstLetter(status)}
                        </DraftTag>
                    </Cell>
                    <Cell />
                </Row>
            </>
        )
    }, [selectedContract, draft, onLinkClick])

    const renderContractPeriod = useCallback((c: MerchantAccountContract) => {
        if (!c?.metadata?.signedAt) return null
        if (c?.metadata?.state === 'cancelled' && !c?.metadata?.effectiveAt) return null
        return (
            <Text oneLine>
                {c?.signer?.email ? 'Signed by' : 'Effectuated by'}{' '}
                <Text color="floating.text" bold>
                    {c?.signer?.email || 'an agent'}
                </Text>{' '}
                at{' '}
                <Text color="floating.text">
                    {c?.metadata?.signedAt
                        ? moment(c.metadata.signedAt).format(DateFormats.dayAndTime(moment(c.metadata.signedAt)))
                        : '-'}
                </Text>{' '}
            </Text>
        )
    }, [])

    if (!contractsHistory || Object.keys(contractsHistory)?.length === 0)
        if (!draftRow)
            return (
                <Flex column>
                    <Spacer height={10} />
                    <Text color="side.text">No contracts have been found</Text>
                </Flex>
            )

    if (!selectedContract) return null

    return (
        <Grid>
            <HeaderCell>
                <HeaderText noMargin>ID</HeaderText>
            </HeaderCell>
            <HeaderCell>
                <HeaderText noMargin>State</HeaderText>
            </HeaderCell>
            <HeaderCell>
                <HeaderText noMargin>Details</HeaderText>
            </HeaderCell>
            <HeaderCell>
                <HeaderText noMargin>Effective period</HeaderText>
            </HeaderCell>
            {draftRow}
            {contractsHistory &&
                Object.keys(contractsHistory).map((key, i, arr) => {
                    const c = contractsHistory[key]
                    const block = (
                        <React.Fragment key={c.id}>
                            <Row onClick={() => onLinkClick(c)} active={c.id === selectedContract?.contract?.id}>
                                <CellHolder>
                                    <Cell>
                                        <TextHolder>{arr.length - i}</TextHolder>
                                    </Cell>
                                    <Cell>
                                        <MerchantAccountsContractStatus contract={c} />
                                    </Cell>
                                    <Cell>{renderContractPeriod(c)}</Cell>
                                    {effectivePeriods?.[c.id] ? (
                                        <Cell>
                                            <ContractEffectivePeriod
                                                from={effectivePeriods[c.id].from}
                                                to={effectivePeriods[c.id].to}
                                                color="floating.text.subtlerI"
                                            />
                                        </Cell>
                                    ) : (
                                        <Cell>-</Cell>
                                    )}
                                </CellHolder>
                            </Row>
                        </React.Fragment>
                    )
                    if (c.metadata?.declinedAt || c.metadata) return block
                })}
        </Grid>
    )
}

const CellHolder = styled.div`
    display: contents;
`

const TextHolder = styled.div`
    white-space: nowrap;
`

const HeaderCell = styled.div`
    color: ${(p) => p.theme['floating.text.subtlerII']};
    padding-bottom: 5px;
    white-space: nowrap;
`

const Grid = styled.div`
    display: grid;
    margin: 20px 5px;
    grid-template-columns: minmax(10px, min-content) minmax(120px, min-content) minmax(350px, min-content) 1fr;
`

const Cell = styled.div<{ wholeRow?: boolean }>`
    display: flex;
    padding: 5px 0 5px 0;
    padding-right: 20px;
    cursor: pointer;
    align-items: center;

    & > div {
        white-space: nowrap;
    }

    &:first-child {
        border-radius: 8px 0 0 8px;
        margin-left: -10px;
        padding-left: 10px;
    }

    &:last-child {
        border-radius: 0 8px 8px 0;
    }

    ${(p) =>
        p.wholeRow &&
        css`
            grid-column: span 3;
        `}
`

const DraftTag = styled.div<{ emphasized?: boolean }>`
    padding: 1px 6px 0px 6px;
    border-radius: 5px;
    margin: -4px 0;
    width: auto;
    display: inline-block;
    flex-grow: 0;

    color: #000;
    font-size: 10px;
    min-height: 0;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-transform: uppercase;

    background-color: ${(p) => p.theme['side.accent.background']};
    color: ${(p) => p.theme['side.accent.color']};

    ${(p) =>
        p.emphasized &&
        css`
            background-color: ${(p) => p.theme['front.success.color']};
            color: ${(p) => p.theme['front.success.text']};
        `}
`

const Row = styled.div<{ active?: boolean }>`
    display: contents;
    color: ${(p) => p.theme['floating.text.subtlerII']};

    &:hover,
    &:hover ${Cell} {
        color: ${(p) => p.theme['floating.text.subtlerI']};
    }
    &:active,
    &:active ${Cell} {
        color: ${(p) => p.theme['floating.text.strongerI']};
    }

    ${(p) =>
        p.active &&
        css`
            cursor: default;
            ${Cell} {
                &:first-child:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -10px;
                    width: 2px;
                    height: 100%;
                    background-color: ${(p) => p.theme['front.accent.color']};
                }
            }
            ${Cell} , ${TextHolder} {
                color: ${(p) => p.theme['floating.text']};
                font-weight: 500;
                cursor: default;
                position: relative;
            }
            &:active ${Cell}, &:hover ${Cell}, &:active ${TextHolder}, &:hover ${TextHolder} {
                color: ${(p) => p.theme['floating.text']};
            }
        `}
`
