import { Box, Grid, Text } from '@/design-system'
import React from 'react'

const IntegrationRowHeader: React.FC = () => {
    return (
        <>
            <Grid.Item>
                <Box pb="4">
                    <Text size="xs" weight="medium" color="neutral" transform="uppercase">
                        Integration
                    </Text>
                </Box>
            </Grid.Item>
            <Grid.Item>
                <Box pb="4">
                    <Text size="xs" weight="medium" color="neutral" transform="uppercase">
                        Name
                    </Text>
                </Box>
            </Grid.Item>
            <Grid.Item>
                <Box pb="4">
                    <Text size="xs" weight="medium" color="neutral" transform="uppercase">
                        Description
                    </Text>
                </Box>
            </Grid.Item>
            <Grid.Item>
                <Box pb="4">
                    <Text size="xs" weight="medium" color="neutral" transform="uppercase" align="right">
                        Actions
                    </Text>
                </Box>
            </Grid.Item>
        </>
    )
}

export { IntegrationRowHeader }
