import { returnWithout } from '../../utils'
import {
    DISPUTE_CHANGE_DUEDATE,
    DISPUTE_LINK_FILES,
    DISPUTE_LOAD,
    DISPUTE_LOAD_EXTRA_DETAILS,
    DISPUTE_STORE,
    DISPUTE_STORE_COMMENT,
    DISPUTE_STORE_EXTRA_DETAILS,
    DISPUTE_UNLINK_FILES,
    DISPUTE_UNSTORE,
    DISPUTE_UNSTORE_COMMENT,
    DISPUTES_CLEAR_SUMMARIES,
    DISPUTES_LOAD_SUMMARIES,
    DISPUTES_STORE_SUMMARIES,
    ReducedDisputesActions,
    DISPUTE_LOAD_NEXT_RESULTS
} from './actions'
import { DisputesState, InitialDisputesState } from './types'

export function DisputesReducer(
    state = InitialDisputesState,
    action: ReducedDisputesActions
): DisputesState {
    switch (action.type) {
        case DISPUTES_LOAD_SUMMARIES: {
            return {
                ...state,
                disputesSummaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'started'
                }
            }
        }
        case DISPUTES_STORE_SUMMARIES: {
            if (state.disputesSummaries.loadingStatus === 'started')
                return {
                    ...state,
                    disputesSummaries: {
                        at: {
                            ...action.disputesSummaries.reduce((a, t) => {
                                return {
                                    ...a,
                                    [t.id]: {
                                        ...state.disputesSummaries.at[t.id],
                                        ...reformatDisputeSummary(t)
                                    }
                                }
                            }, {})
                        },
                        all: action.disputesSummaries.map((t) => t.id),
                        pagination: { ...action.pagination } as any,
                        loadingStatus: 'done'
                    }
                }
            return state
        }
        case DISPUTES_CLEAR_SUMMARIES: {
            return {
                ...state,
                disputesSummaries: {
                    at: {},
                    all: [],
                    pagination: undefined,
                    loadingStatus: 'done'
                }
            }
        }
        case DISPUTE_LOAD_EXTRA_DETAILS: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.dispute.id]: {
                            ...state.disputes.at[action.dispute.id],
                            extraDetails: {
                                account: undefined,
                                application: undefined,
                                loadingStatus: 'started'
                            }
                        }
                    }
                }
            }
        }
        case DISPUTE_STORE_EXTRA_DETAILS: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.dispute.id]: {
                            ...state.disputes.at[action.dispute.id],
                            extraDetails: {
                                account: action.dispute.extraDetails.account,
                                application:
                                    action.dispute.extraDetails.application,
                                loadingStatus: 'done'
                            }
                        }
                    }
                }
            }
        }
        case DISPUTE_LOAD: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.disputeId]: {
                            ...state.disputes.at[action.disputeId],
                            loadingStatus: 'started'
                        }
                    }
                }
            }
        }
        case DISPUTE_STORE: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.dispute.id]: {
                            ...state.disputes.at[action.dispute.id],
                            ...reformatDispute(action.dispute),
                            extraDetails: state.disputes.at?.[action.dispute.id]
                                ?.extraDetails
                                ? state.disputes.at[action.dispute.id]
                                      .extraDetails
                                : {
                                      account: undefined,
                                      application: undefined,
                                      loadingStatus: 'not-started'
                                  },
                            loadingStatus: 'done'
                        }
                    }
                }
            }
        }
        case DISPUTE_CHANGE_DUEDATE: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.dispute.id]: {
                            ...state.disputes.at[action.dispute.id],
                            dueAt: action.newDueDate
                        }
                    }
                }
            }
        }
        case DISPUTE_UNSTORE: {
            const newState = { ...state }
            delete newState.disputes.at[action.disputeId]
            newState.disputes.all = returnWithout(
                newState.disputes.all,
                action.disputeId
            )
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...newState.disputes.at
                    },
                    all: [...newState.disputes.all]
                }
            }
        }
        case DISPUTE_STORE_COMMENT: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.dispute.id]: {
                            ...state.disputes.at[action.dispute.id],
                            comments: [
                                ...state.disputes.at[action.dispute.id]
                                    .comments,
                                action.comment
                            ]
                        }
                    },
                    all: [...state.disputes.all]
                }
            }
        }
        case DISPUTE_LOAD_NEXT_RESULTS: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.dispute.id]: {
                            ...state.disputes.at[action.dispute.id],
                            loadingNextStatus: "started"
                        }
                    }
                }
            }
        }
        case DISPUTE_UNSTORE_COMMENT: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.dispute.id]: {
                            ...state.disputes.at[action.dispute.id],
                            comments: [
                                ...state.disputes.at[
                                    action.dispute.id
                                ].comments.filter(
                                    (c) => c.id != action.commentId
                                )
                            ]
                        }
                    },
                    all: [...state.disputes.all]
                }
            }
        }
        case DISPUTE_LINK_FILES: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.disputeId]: {
                            ...state.disputes.at[action.disputeId],
                            files: [
                                ...state.disputes.at[action.disputeId].files,
                                ...action.files
                            ]
                        }
                    }
                } as any
            }
        }
        case DISPUTE_UNLINK_FILES: {
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    at: {
                        ...state.disputes.at,
                        [action.disputeId]: {
                            ...state.disputes.at[action.disputeId],
                            files: state.disputes.at[
                                action.disputeId
                            ].files.filter(
                                (f) =>
                                    !action.files
                                        .map((af) => af.id)
                                        .includes(f.id)
                            )
                        }
                    }
                } as any
            }
        }
        default:
            return state
    }
}

const reformatDisputeSummary = (t: any) => {
    if (t.accountLink)
        t.accountId = t.accountLink.substr(t.accountLink.lastIndexOf('/') + 1)
    t.reference = t.reference.substr(0, 23)
    delete t.accountLink
    return t
}

const reformatDispute = (t: any) => {
    if (t.accountLink)
        t.accountId = t.accountLink.substr(t.accountLink.lastIndexOf('/') + 1)
    t.reference = t.reference.substr(0, 23)
    return t
}
