import { kebabCase } from 'lodash'
import React from 'react'
import { Card } from '../../../../../../components/cards/card'
import { CardInset } from '../../../../../../components/cards/cardInset'
import { BatchOfMonitoredChanges } from '../../../../../../store/tasksCompanyChanges/types'
import { ApplicationCardHeader } from '../ApplicationCardHeader'
import { ApplicationCardTasks } from '../ApplicationCardTasks'

interface ApplicationCardProps {
    taskId: string
    change?: BatchOfMonitoredChanges
}

export const ApplicationCard: React.FC<ApplicationCardProps> = (props) => {
    const { taskId, change } = props

    if (!change) {
        return null
    }

    return (
        <Card cy={`${kebabCase(change.fetchedApplicationData.data?.company.name)}-card`}>
            <CardInset>
                <ApplicationCardHeader taskId={taskId} change={change} />
            </CardInset>
            <ApplicationCardTasks taskId={taskId} change={change} />
        </Card>
    )
}
