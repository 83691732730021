import { WatchersInitialState, WatchersState } from './types'
import { WatchersNeedingReducing, WATCHER_COMPLETE, WATCHER_FAIL, WATCHER_SUCCESS, WATCHER_START } from './actions'

export function WatchersReducer(state = WatchersInitialState, action: WatchersNeedingReducing): WatchersState {
    switch (action.type) {
        case WATCHER_COMPLETE: {
            const newState = { ...state }
            action.watchers.map((id: string) => {
                delete newState[id]
            })
            return newState
        }
        case WATCHER_FAIL: {
            const newState = { ...state }
            action.watchers.map((id: string) => {
                if (!newState[id]) newState[id] = { state: 'fail' }
                newState[id].state = 'fail'
            })
            return newState
        }
        case WATCHER_SUCCESS: {
            const newState = { ...state }
            action.watchers.map((id: string) => {
                if (!newState[id]) newState[id] = { state: 'success' }
                newState[id].state = 'success'
                newState[id].context = action.context
            })
            return newState
        }
        case WATCHER_START: {
            const newState = { ...state }
            action.watchers.map((id: string) => {
                if (!newState[id]) newState[id] = { state: 'started' }
                newState[id].state = 'started'
            })
            return newState
        }
    }
    return state
}
