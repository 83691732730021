import React from 'react'
import { Styles, styles } from './styles'
import { cn } from '../utils'

interface TextareaProps extends Styles {
    testid?: string
    name?: string
    placeholder?: string
    rows?: number
    defaultValue?: string
    value?: string
    wrap?: 'hard' | 'soft'
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
    const { testid, name, placeholder, rows = 3, defaultValue, value, wrap, onChange, onBlur, variant } = props

    const classnames = cn(styles({ variant }))

    return (
        <textarea
            data-cy={testid}
            wrap={wrap}
            name={name}
            placeholder={placeholder}
            rows={rows}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={classnames}
            ref={ref}
        />
    )
})

Textarea.displayName = 'Textarea'
