import { useGetSigningKeys } from '@/services/merchant-api'

export const useEmbeddedSigningKeys = (url?: string) => {
    const link = url ? url : ''

    const { data } = useGetSigningKeys(link)

    const embeddedSigningKeys = data?.embedded['ch:signing-keys']
    const hasEmbeddedSigningKeys = embeddedSigningKeys && embeddedSigningKeys.length > 0

    const embeddedIntegrationsArray = hasEmbeddedSigningKeys ? embeddedSigningKeys : []

    return { embeddedSigningKeys: embeddedIntegrationsArray }
}
