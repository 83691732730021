import React, { useCallback, useEffect, useMemo } from 'react'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { ModalPage } from '../../../components/layout/modalPage'
import { PageWrapper } from '../../../components/layout/pageWrapper'
import { Spacer } from '../../../components/layout/spacer'
import { useDispatch, useSelector } from 'react-redux'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { ApplicationInternalsDetailsActions } from '../../../store/applicationInternals/details/actions'
import { useForm } from '../../../hooks/general/useForm'
import * as yup from 'yup'
import { RootState } from '@/store'
import { useNamedWatcherWithContext } from '../../../hooks/general/useWatcher'
import { uppercaseFirstLetter } from '../../../utils'
import { CardSection } from '../../../components/cards/cardSection'
import { FormHandler } from '../../../components/forms/formHandler'
import { TextInputSelect } from '../../../components/forms/textInputSelect'
import { LoaderView } from '../../../components/loaders/loader'
import { useDialog } from '../../../hooks/general/useDialog'
import { ApplicationStatus } from '../../../components/general/applicationStatus'
import { WatcherDispatchSuccess } from '../../../store/watcher/actions'
import { useNavigate } from 'react-router-dom'

const textForItem = (key: string) => {
    return uppercaseFirstLetter(key)
}
export const MerchantApplicationCloneModal: React.FC<{
    id: string
}> = ({ id }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [watcherState, watcherId, watcherContext] = useNamedWatcherWithContext(`CLONE_APPLICATION_${id}`)
    const { formRef, errors, submitHandler, focusInvalidField, resetForm, form } = useForm(
        watcherState,
        undefined,
        undefined,
        undefined,
        true
    )
    const options = useSelector((state: RootState) => state.applicationInternals.details[id].clonableStates)

    const handleOnBack = useCallback(() => {
        dispatch(ApplicationInternalsDetailsActions.STOP_CLONING(id))
    }, [dispatch, id])

    const confirmCloning = useDialog<{ cloneToState: string }>(
        useCallback(
            (buttonWatcherId, { cloneToState }) => {
                dispatch(WatcherDispatchSuccess([buttonWatcherId]))
                return ApplicationInternalsDetailsActions.CLONE(id, watcherId, cloneToState)
            },
            [dispatch, watcherId, id]
        ),
        'Confirm cloning',
        useCallback(() => {
            return (
                <Flex column>
                    <Text>
                        Are you sure you want to clone this application directly into the&nbsp;
                        <ApplicationStatus status={form.data.cloneToState} />
                        &nbsp;state?
                    </Text>
                    <Spacer height={10} />
                    <Text color="front.text.subtlerII">
                        This will create a new application and clone all the related information (except comments and
                        checks)
                    </Text>
                </Flex>
            )
        }, [form]),
        'Yes, clone the application',
        'front.accent.color'
    )

    const onSubmit = useCallback(
        ({ data }) => {
            confirmCloning({ cloneToState: data.cloneToState })
        },
        [confirmCloning]
    )
    useEffect(() => {
        if (watcherState === 'success') navigate(`/merchant/${watcherContext}/application`)
    }, [watcherState, navigate, watcherContext])

    const content = useMemo(() => {
        if (!options?.length)
            return (
                <CardInset>
                    <Text>This application cannot be cloned</Text>
                </CardInset>
            )

        if (watcherState === 'started')
            return (
                <CardInset>
                    <Flex column grow>
                        <Text>Cloning, and redirecting you to the new application...</Text>
                        <Spacer height={20} />
                        <LoaderView overBackground="front.background" />
                    </Flex>
                </CardInset>
            )

        return (
            <CardInset>
                <Text>
                    <Text bold>Which state</Text> would you like to <Text bold>clone the application into?</Text>
                </Text>
                <Spacer height={20} />
                <TextInputSelect
                    key="clone-state"
                    placeholder="-"
                    overBackground="front.background"
                    validation={errors.cloneToState}
                    dropdownId="Merchant.ApplicationPage.Clone.State"
                    textForItem={textForItem}
                    items={options}
                    isSimple
                    ref={(ref) => formRef(ref, 'cloneToState', yup.string().required())}
                />
                <CardSection background="subtleBlue">
                    <CardInset>
                        <Flex column align="stretch">
                            <Flex justify="flex-end" align="center">
                                <FormHandler
                                    brandNewForm={true}
                                    formSubmitLabel={'Clone application'}
                                    textForFieldName={(k) => k}
                                    changes={form.changes}
                                    errors={errors}
                                    focusInvalidField={focusInvalidField}
                                    textForChange={(k) => k}
                                    submitForm={submitHandler(onSubmit)}
                                    submitWatcherId={watcherId}
                                    resetForm={resetForm}
                                />
                            </Flex>
                        </Flex>
                    </CardInset>
                </CardSection>
            </CardInset>
        )
    }, [options, watcherState, errors, formRef, form, resetForm, watcherId, submitHandler, onSubmit, focusInvalidField])

    return (
        <ModalPage title="Clone merchant application" onBack={handleOnBack} pageId="Merchant.ApplicationPage.Clone">
            <ModalHeader
                onBack={handleOnBack}
                pageId="Merchant.ApplicationPage.Clone"
                title="Clone merchant application"
            />
            <PageWrapper>
                <Flex justify="center" align="center" column grow>
                    <Card higher>{content}</Card>
                    <Spacer height={80} />
                </Flex>
            </PageWrapper>
        </ModalPage>
    )
}
