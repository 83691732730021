import { uppercaseFirstLetter } from '../../utils'
import { LocallyStoredDraftContract, MerchantAccountDraftContract } from '../merchantAccounts/types'
import { ContractTemplate, LocallyStoredContractTemplate } from './types'

type ContractFieldTypes = 'number' | 'uppercased-string' | 'unchanged' | 'pricing-plan'

type LocalContractRootKeys<T> = {
    [key in keyof Omit<
        LocallyStoredDraftContract,
        | 'charges'
        | 'rates_visa_business'
        | 'rates_visa_consumer'
        | 'rates_mastercard_business'
        | 'rates_mastercard_consumer'
        | 'id'
        | 'applicationId'
        | 'state'
        | 'currency'
        | 'loadingStatus'
        | 'interchange'
        | 'selfLink'
    >]: T
}
export interface LocalContractPathsStructure<T> extends LocalContractRootKeys<T> {
    charges: { [key in Exclude<keyof LocallyStoredDraftContract['charges'], 'id' | 'selfLink'>]: T }
    rates_visa_business: {
        [key in Exclude<keyof LocallyStoredDraftContract['rates_visa_business'], 'id' | 'selfLink'>]: T
    }
    rates_visa_consumer: {
        [key in Exclude<keyof LocallyStoredDraftContract['rates_visa_consumer'], 'id' | 'selfLink'>]: T
    }
    rates_mastercard_business: {
        [key in Exclude<keyof LocallyStoredDraftContract['rates_mastercard_business'], 'id' | 'selfLink'>]: T
    }
    rates_mastercard_consumer: {
        [key in Exclude<keyof LocallyStoredDraftContract['rates_mastercard_consumer'], 'id' | 'selfLink'>]: T
    }
}

// This is used so we know which fields to render in the contract form, based on the localPath
export const LocalContractPathsMappedToMAPIContractPaths: LocalContractPathsStructure<string> = {
    charges: {
        authorizationFee: 'transactionFees.base.authorization',
        captureFee: 'transactionFees.base.capture',
        chargebackFee: 'accountFees.base.chargeback',
        creditFee: 'transactionFees.base.credit',
        debitFee: 'transactionFees.base.debit',
        seriesFee: 'transactionFees.base.series',
        threedsFee: 'transactionFees.base.3Dsecure',
        exchangeFee: 'accountFees.percentage.exchange',
        interchange: 'plan',
        captureLateFee: 'transactionFees.percentage.lateCapture',
        minimumDomesticTransactionFee: 'transactionFees.minimum.domestic',
        minimumIntraTransactionFee: 'transactionFees.minimum.intra',
        minimumInterTransactionFee: 'transactionFees.minimum.inter',
        minimumPayoutAmount: 'payment.minimumAmount',
        minimumRollingReservePayoutAmount: 'rollingReserve.minimumAmount',
        monthlyFee: 'accountFees.base.monthly',
        payoutFee: 'accountFees.base.payout',
        refundFee: 'transactionFees.base.refund',
        retrievalFee: 'accountFees.base.retrieval',
        setupFee: 'accountFees.base.setup',
        yearlyFee: 'accountFees.base.yearly'
    },
    rates_visa_consumer: {
        domesticCredit: 'transactionFees.percentage.domestic.visa.consumer.credit',
        domesticDebit: 'transactionFees.percentage.domestic.visa.consumer.debit',
        intraRegionCredit: 'transactionFees.percentage.intra.visa.consumer.credit',
        intraRegionDebit: 'transactionFees.percentage.intra.visa.consumer.debit',
        interRegionCredit: 'transactionFees.percentage.inter.visa.consumer.credit',
        interRegionDebit: 'transactionFees.percentage.inter.visa.consumer.debit'
    },
    rates_visa_business: {
        domesticCredit: 'transactionFees.percentage.domestic.visa.business.credit',
        domesticDebit: 'transactionFees.percentage.domestic.visa.business.debit',
        intraRegionCredit: 'transactionFees.percentage.intra.visa.business.credit',
        intraRegionDebit: 'transactionFees.percentage.intra.visa.business.debit',
        interRegionCredit: 'transactionFees.percentage.inter.visa.business.credit',
        interRegionDebit: 'transactionFees.percentage.inter.visa.business.debit'
    },
    rates_mastercard_consumer: {
        domesticCredit: 'transactionFees.percentage.domestic.mastercard.consumer.credit',
        domesticDebit: 'transactionFees.percentage.domestic.mastercard.consumer.debit',
        intraRegionCredit: 'transactionFees.percentage.intra.mastercard.consumer.credit',
        intraRegionDebit: 'transactionFees.percentage.intra.mastercard.consumer.debit',
        interRegionCredit: 'transactionFees.percentage.inter.mastercard.consumer.credit',
        interRegionDebit: 'transactionFees.percentage.inter.mastercard.consumer.debit'
    },
    rates_mastercard_business: {
        domesticCredit: 'transactionFees.percentage.domestic.mastercard.business.credit',
        domesticDebit: 'transactionFees.percentage.domestic.mastercard.business.debit',
        intraRegionCredit: 'transactionFees.percentage.intra.mastercard.business.credit',
        intraRegionDebit: 'transactionFees.percentage.intra.mastercard.business.debit',
        interRegionCredit: 'transactionFees.percentage.inter.mastercard.business.credit',
        interRegionDebit: 'transactionFees.percentage.inter.mastercard.business.debit'
    },
    paymentDelay: 'payment.delay',
    paymentPeriod: 'payment.period',
    rollingReserveDelay: 'rollingReserve.delay',
    rollingReserveRate: 'rollingReserve.percentage',
    otherTerms: 'otherTerms'
}

// This is used to do some local formatting of the backend values, to ensure consistency
export const localContractFieldTypes: LocalContractPathsStructure<ContractFieldTypes> = {
    charges: {
        authorizationFee: 'number',
        captureFee: 'number',
        chargebackFee: 'number',
        creditFee: 'number',
        debitFee: 'number',
        seriesFee: 'number',
        exchangeFee: 'number',
        interchange: 'pricing-plan',
        captureLateFee: 'number',
        minimumDomesticTransactionFee: 'number',
        minimumIntraTransactionFee: 'number',
        minimumInterTransactionFee: 'number',
        minimumPayoutAmount: 'number',
        minimumRollingReservePayoutAmount: 'number',
        monthlyFee: 'number',
        payoutFee: 'number',
        refundFee: 'number',
        retrievalFee: 'number',
        setupFee: 'number',
        threedsFee: 'number',
        yearlyFee: 'number'
    },
    rates_visa_consumer: {
        domesticCredit: 'number',
        domesticDebit: 'number',
        intraRegionCredit: 'number',
        intraRegionDebit: 'number',
        interRegionCredit: 'number',
        interRegionDebit: 'number'
    },
    rates_visa_business: {
        domesticCredit: 'number',
        domesticDebit: 'number',
        intraRegionCredit: 'number',
        intraRegionDebit: 'number',
        interRegionCredit: 'number',
        interRegionDebit: 'number'
    },
    rates_mastercard_consumer: {
        domesticCredit: 'number',
        domesticDebit: 'number',
        intraRegionCredit: 'number',
        intraRegionDebit: 'number',
        interRegionCredit: 'number',
        interRegionDebit: 'number'
    },
    rates_mastercard_business: {
        domesticCredit: 'number',
        domesticDebit: 'number',
        intraRegionCredit: 'number',
        intraRegionDebit: 'number',
        interRegionCredit: 'number',
        interRegionDebit: 'number'
    },
    paymentDelay: 'unchanged',
    paymentPeriod: 'uppercased-string',
    rollingReserveDelay: 'unchanged',
    rollingReserveRate: 'unchanged',
    otherTerms: 'unchanged'
}

export const InlineLocalContractFormFields = (contract: LocallyStoredContractTemplate | LocallyStoredDraftContract) => {
    const acc: any = JSON.parse(JSON.stringify(contract))

    delete acc.label
    delete acc.selfLink
    delete acc.id

    Object.keys(contract.charges).forEach((k) => {
        if (['selfLink', 'id'].includes(k)) return
        acc[`charges.${k}`] = contract.charges[k]
    })
    delete acc.charges
    ;['rates_visa_consumer', 'rates_visa_business', 'rates_mastercard_consumer', 'rates_mastercard_business'].forEach(
        (schemeRate) => {
            Object.keys(contract[schemeRate]).forEach((k) => {
                if (['selfLink', 'id'].includes(k)) return
                acc[`${schemeRate}.${k}`] = contract[schemeRate][k]
            })
            delete acc[schemeRate]
        }
    )
    return acc
}

export const AdjustContractOrContractTemplateForLocalStorage = (
    template: ContractTemplate | MerchantAccountDraftContract
): LocallyStoredContractTemplate => {
    const local: any = {}

    Object.keys(template).forEach((k) => {
        if (k === 'charges') return
        local[k] = template[k]
    })

    local.charges = Object.keys(template.charges[0]).reduce((acc: any, k: any) => {
        if (k === 'schemeRates') return acc
        acc[k] = template.charges[0][k]
        return acc
    }, {} as any)

    template.charges[0].schemeRates.forEach((rate) => {
        local[`rates_${rate.scheme}_${rate.segment}`] = Object.keys(rate).reduce((acc: any, k: any) => {
            if (['segment', 'scheme'].includes(k)) return acc
            acc[k] = rate[k]
            return acc
        }, {} as any)
    })

    return local
}

export const LocalContractFieldPaths = InlineLocalContractFormFields(LocalContractPathsMappedToMAPIContractPaths as any)

export const ContractTemplateFormFieldNaming = (fieldPath: string) => {
    return uppercaseFirstLetter(fieldPath.replace('rates_', '').replace('charges.', ''))
        .replace('Visa ', 'Visa · ')
        .replace('Mastercard ', 'MasterCard · ')
        .replace('consumer ', 'Consumer · ')
        .replace('business ', 'Business · ')
        .replace('intra', 'Intra')
        .replace('inter', 'Inter')
        .replace('domestic', 'Domestic')
}
