import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { Icon } from '../icons/icon'
import { WatcherID } from '../../store/watcher/types'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { Dropdown } from '../forms/dropdown'
import { DropdownSelectableList } from '../forms/dropdownSelectableList'
import { Flex } from '../layout/flex'
import { ClueDirection } from '../hotkeys/hotkeyClue'
import { ButtonInset } from './buttonInset'
import { ButtonLoadingCover } from './buttonLoadingCover'
import { SimpleButton } from './simpleButton'

export function SimpleButtonWithDropdown({
    children,
    background,
    color,
    hotkeysScope,
    hotkeys,
    secondaryButtons,
    mainAction,
    hotkeysClueDirection,
    predefinedWatcher,
    cy
}: {
    children: any
    background: ComponentProps<typeof SimpleButton>['background']
    color?: ComponentProps<typeof SimpleButton>['color']
    grow?: boolean
    mainAction: () => void
    predefinedWatcher?: WatcherID
    cy?: string
    secondaryButtons: {
        id: string
        label: string
        action: () => void
    }[]
    hotkeysScope?: MODAL_ID
    hotkeys?: string
    hotkeysClueDirection?: ClueDirection
}): JSX.Element {
    const [isDropdownShown, setIsDropdownShown] = useState(false)
    const [containerRef, setContainerRef] = useRefTaker()
    const [ref, setRef] = useRefTaker()
    const [watcher] = useNamedWatcher(predefinedWatcher)

    useOnHoverOutside(
        containerRef,
        useCallback(() => {
            setIsDropdownShown(false)
        }, [setIsDropdownShown])
    )

    const handleTickClick = useCallback(() => {
        setIsDropdownShown((d) => !d)
    }, [setIsDropdownShown])

    const buttonsDictionary = useMemo(() => {
        return secondaryButtons.reduce((acc, item) => {
            acc[item.id] = {
                ...item
            }
            return acc
        }, {} as any)
    }, [secondaryButtons])

    const dropdownItems = useMemo(() => {
        return secondaryButtons.map((e) => e.id)
    }, [secondaryButtons])

    const textForItem = useCallback(
        (item: string) => {
            return buttonsDictionary[item].label
        },
        [buttonsDictionary]
    )

    const handleSelect = useCallback(
        (item: string) => {
            return buttonsDictionary[item].action()
        },
        [buttonsDictionary]
    )

    return (
        <Holder>
            <SimpleButton
                background={background}
                color={color}
                cy={cy}
                onClick={mainAction}
                hotkeys={hotkeys}
                hotkeysClueDirection={hotkeysClueDirection}
                hotkeysScope={hotkeysScope}
            >
                <ButtonLoadingCover watcher={watcher} background={background} color={color}>
                    <Flex>
                        {children}
                        <DropdownPlaceholder>
                            <ButtonInset padding="small"></ButtonInset>
                        </DropdownPlaceholder>
                    </Flex>
                </ButtonLoadingCover>
            </SimpleButton>
            {watcher !== 'started' && (
                <DropdownContainer ref={setContainerRef} data-cy={`${cy}-menu`}>
                    <DropdownPart
                        data-cy={`${cy}-tick`}
                        background={background}
                        color={color}
                        ref={setRef}
                        onClick={handleTickClick}
                    >
                        <IconPadding>
                            <IconHolder>
                                <Icon type="downTick" />
                            </IconHolder>
                        </IconPadding>
                    </DropdownPart>
                    <Dropdown anchor={ref} show={isDropdownShown}>
                        <DropdownSelectableList
                            items={dropdownItems}
                            dropdownId="-ANY-"
                            noBullets
                            textForItem={textForItem}
                            onSelect={handleSelect}
                        />
                    </Dropdown>
                </DropdownContainer>
            )}
        </Holder>
    )
}

const DropdownContainer = styled.div`
    display: contents;
`

const Holder = styled.div`
    position: relative;
    display: flex;
    align-items: stretch;
`

const DropdownPlaceholder = styled.div`
    margin-left: 5px;
`

const IconPadding = styled.div`
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const IconHolder = styled.div`
    position: relative;
    top: -2px;
`

const DropdownPart = styled.div<{ background: any; color: any }>`
    align-self: 'stretch';
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    text-shadow: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    &:active {
        background: rgba(0, 0, 0, 0.2);
    }
`
