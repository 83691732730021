import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { MerchantTimelinePoint } from '../../../store/merchantTimeline/types'
import { MerchantTimelineItemCreation } from './SummaryTimelineItemCreation'
import { MerchantTimelineItemEdits } from './SummaryTimelineItemEdits'
import { MerchantTimelineItemStateChange } from './SummaryTimelineItemStateChange'

export const MerchantTimelineItem: React.FC<{
    point: MerchantTimelinePoint
}> = ({ point }) => {
    const item = useMemo(() => {
        switch (point.type) {
            case 'creation':
                return <MerchantTimelineItemCreation point={point} />
            case 'field-edits':
                return <MerchantTimelineItemEdits point={point} />
            case 'state-change':
                return <MerchantTimelineItemStateChange point={point} />
            default:
                return <Text>{point.type}</Text>
        }
    }, [point])
    return (
        <Container>
            {item}
            <Spacer height={2} />
        </Container>
    )
}

const Container = styled.div`
    position: relative;
`
