import React from 'react'
import { Grid } from '@/components/layout/grid'
import { CenteredScreen } from '@/components/layout/centeredScreen'

interface ReportLayoutProps {
    children: React.ReactNode
}

export const ReportLayout: React.FC<ReportLayoutProps> = (props) => {
    const { children } = props

    return (
        <CenteredScreen paddingTop={0} maxWidth={1800}>
            <Grid horizontalTemplate="1fr 4fr" gridColumnGap="extra-large">
                {children}
            </Grid>
        </CenteredScreen>
    )
}
