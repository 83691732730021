import { Flex } from '@/components/layout/flex'
import { MerchantDataItems } from './merchant-data-items'
import React from 'react'
import { Spacer } from '@/components/layout/spacer'
import { Text } from '@/components/general/text'
import { getOrderedItems, OrderedItems, OrderedOrder } from '../../../../helpers'

interface MerchantDataCollectionProps {
    name: string
    data: OrderedItems[]
    order: OrderedOrder
}

export const MerchantDataCollection: React.FC<MerchantDataCollectionProps> = (props) => {
    const { name, data, order } = props

    return (
        <>
            {data.map((item, i) => {
                const items = getOrderedItems(item, order)

                return (
                    <React.Fragment key={name + i}>
                        <Flex marginLeft={25}>
                            <Text color="back.text.strongerII">
                                {i + 1}. {name}
                            </Text>
                        </Flex>
                        <Spacer height={4} />

                        <MerchantDataItems data={items} />
                        <Spacer height={8} />
                    </React.Fragment>
                )
            })}
        </>
    )
}
