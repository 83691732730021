import { Task } from '@/services/taskr'

export const getTaskUrls = (tasks?: Task[]) => {
    if (!tasks) {
        return []
    }

    const taskUrls = tasks.map((task) => `${import.meta.env.VITE_TASKR_ROOT}/tasks/${task.id}`)

    return taskUrls
}
