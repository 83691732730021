import React, { ReactElement, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { WatcherID } from '../../store/watcher/types'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { AgentBubble } from '../general/agentBubble'
import { ButtonLoadingCover } from '../buttons/buttonLoadingCover'
import { SimpleButton } from '../buttons/simpleButton'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { TextInputAutocomplete } from './textInputAutocomplete'

export type TextInputAgentSelectorCover = {
    background: React.ComponentProps<typeof SimpleButton>['background']
    selectedColor: React.ComponentProps<typeof SimpleButton>['background']
    unselectedColor: React.ComponentProps<typeof SimpleButton>['background']
}

export function TextInputAgentSelector({
    onSelect,
    selectedAgent,
    pageId,
    alignCoverRight,
    overBackground,
    noShortcut,
    searchPreloadedDataset
}: {
    onSelect: (agent: string, watcher: WatcherID) => void
    overBackground: 'front.background' | 'side.background' | 'back.background'
    selectedAgent: string
    pageId?: MODAL_ID
    alignCoverRight?: boolean
    noShortcut?: boolean
    searchPreloadedDataset?: boolean
}): ReactElement {
    const [ref, setRef] = useRefTaker()
    const [watcher, generatedID] = useNamedWatcher()

    const colors = useMemo((): TextInputAgentSelectorCover => {
        if (overBackground === 'back.background')
            return {
                background: 'front.background',
                selectedColor: 'front.accent.color',
                unselectedColor: 'front.accent.color'
            }

        if (overBackground === 'side.background')
            return {
                background: undefined,
                selectedColor: 'side.accent.color',
                unselectedColor: 'side.accent.color'
            }

        return {
            background: undefined,
            selectedColor: 'front.accent.color',
            unselectedColor: 'front.accent.color'
        }
    }, [overBackground])

    const cover = useMemo(() => {
        if (selectedAgent)
            return (
                <SimpleButton
                    background={colors.background}
                    color={colors.selectedColor}
                    onClick={() => {
                        ref.focus()
                    }}
                    hotkeysScope={noShortcut ? undefined : pageId}
                    hotkeys={noShortcut ? undefined : 'alt+a'}
                    hotkeysClueDirection={noShortcut ? undefined : 'bottom-right'}
                >
                    <ButtonLoadingCover watcher={watcher} background={colors.background} color={colors.selectedColor}>
                        <Flex align="center" justify='center'>
                            Assigned to <Spacer width={5} /> <AgentBubble id={selectedAgent} />
                        </Flex>
                    </ButtonLoadingCover>
                </SimpleButton>
            )
        return (
            <SimpleButton
                background={colors.background}
                color={colors.unselectedColor}
                onClick={() => {
                    ref.focus()
                }}
                hotkeysScope={noShortcut ? undefined : pageId}
                hotkeys={noShortcut ? undefined : 'alt+a'}
                hotkeysClueDirection={noShortcut ? undefined : 'bottom-right'}
            >
                <ButtonLoadingCover watcher={watcher} background={colors.background} color={colors.unselectedColor}>
                    Assign an agent
                </ButtonLoadingCover>
            </SimpleButton>
        )
    }, [selectedAgent, noShortcut, colors, pageId, watcher, ref])

    const handleSelect = useCallback(
        (agent) => {
            if (ref) ref.value = ''
            onSelect(agent, generatedID)
        },
        [generatedID, onSelect, ref]
    )

    return (
        <Holder>
            <TextInputAutocomplete
                ref={setRef}
                alignCoverRight={alignCoverRight}
                placeholder="Search for an agent"
                onSelect={handleSelect}
                inputCoverMemo={cover}
                selected={selectedAgent}
                dropdownId="TasksList.AgentFilter"
                searchDatasetOnly={searchPreloadedDataset}
                forEntity="agents"
                overBackground="front.background"
            />
        </Holder>
    )
}

const Holder = styled.div``
