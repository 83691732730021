export const IconCheckmark: React.FC<{ size?: number; color?: string; strokeWidth?: number }> = ({
    strokeWidth = 1,
    color,
    size = 12
}) => {
    return (
        <svg width={size} height={size * 0.78} viewBox="0 0 14 12" fill="none">
            <path
                d="M0.5 6.5L5 11L13.5 0.5"
                stroke={color || 'currentColor'}
                fill="none"
                strokeWidth={(14 / size) * strokeWidth}
            />
        </svg>
    )
}
