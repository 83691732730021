import { SectionDocumentation } from '@/services/ongoing-due-diligence'

import { Flex } from '@/components/layout/flex'
import React from 'react'
import { Section } from '../../../common/section'
import { Text } from '@/components/general/text'
import { useReportMerchantData, useReportSection } from '../../../provider/report-provider/report-provider'
import { css } from 'styled-components'

export const OwnershipStructure: React.FC = () => {
    const section = useReportSection<SectionDocumentation>('documentation')
    const merchantData = useReportMerchantData()

    return (
        <Section>
            <Section.Content>
                <Section.Field name="ownership_structure" data={section.ownership_structure} />
                <Section.Spacer size="s" />
                <Flex marginLeft={22}>
                    <Text
                        showSpaces
                        color="front.text.subtlerI"
                        cssOverrides={css`
                            line-height: 2;
                        `}
                    >
                        {merchantData.ownership_structure}
                    </Text>
                </Flex>
            </Section.Content>
        </Section>
    )
}
