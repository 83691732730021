/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { useForm } from '../../../hooks/general/useForm'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { RootState } from '@/store'
import { MerchantAccountAPIKeys } from './Accounts.IDApiKeys'
import { MerchantAccountDetails } from './Accounts.IDAccountDetails'
import { MerchantAccountMembers } from './Accounts.IDAccountMembers'
import { MerchantAccountContractCard } from './Accounts.IDContractCard'
import { MerchantAccountTransactionRules } from './Accounts.IDTransactionRules'
import { LoaderView } from '../../../components/loaders/loader'
import { FormHandler } from '../../../components/forms/formHandler'
import { useChangesReviewer } from '../../../components/complexComponents/useChangesReviewer'
import { MerchantAccountControls } from './Accounts.IDControls'
import dotProp from 'dot-prop'
import { uppercaseFirstLetter } from '../../../utils'
import { Text } from '../../../components/general/text'
import styled from 'styled-components'
import { useRouteGuard } from '../../../hooks/general/useRouteGuard'
import { CenteredScreenHeader } from '../../../components/layout/centeredScreenHeader'
import { MerchantAccountSettlementDetails } from './Accounts.IDSettlementDetails'
import { LinkButton } from '../../../components/buttons/linkButton'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { MerchantAccountLinks } from '../Merchant.AccountLinks'
import { useNewClassification } from '@/features/risk-classification'

const textForChange = (k: any, f: any) => {
    if (f === true) return 'Yes'
    if (f === false) return 'No'
    if (!f) return ''
    return f
}

const merchantAccountFormFieldName = (k: string) => {
    return k
        .split('.')
        .map((y) => uppercaseFirstLetter(y))
        .join(' - ')
}

interface MerchantAccountProps {
    accountId: string
    merchantId: string
}

export const MerchantAccount: React.FC<MerchantAccountProps> = (props) => {
    const { merchantId, accountId } = props
    const { redirect: redirectToClassification, isRedirecting: isRedirectingToClassification } = useNewClassification(
        merchantId,
        `/merchant/${merchantId}/accounts/${accountId}`
    )

    const [isIbanChanged, setIsIbanChanged] = useState<boolean>(false)

    const accounts = useSelector((state: RootState) => state.merchantAccounts.forApplication?.[merchantId])
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])
    const dispatch = useDispatch()
    const [watcher, watcherId] = useNamedWatcher(`${accountId}.Save`)
    const draft = useSelector((state: RootState) => state.merchantAccounts.at?.[accountId]?.draft)
    const { formRef, form, touched, submitHandler, hasChanges, errors, resetForm, focusInvalidField } = useForm(watcher)
    const { startReview, UI } = useChangesReviewer(
        useMemo(
            () => ({
                changesArr: form.changes,
                onChangesApproved: (form) => {
                    const payload: any = {}
                    Object.keys(form.changes).forEach((key: string) => {
                        if (form.changes[key].to === false || form.changes[key].to === 0)
                            dotProp.set(payload, key, form.changes[key].to)
                        else dotProp.set(payload, key, form.changes[key].to || null)
                    })

                    if (form.changes['bank_account.iban']) {
                        setIsIbanChanged(true)
                    }

                    dispatch(MerchantAccountsActions.UPDATE_ACCOUNT(watcherId, payload, account.selfLink))
                },
                valueFormatter: (key, from, to) => {
                    if (key === 'transactionRules')
                        return {
                            col: key,
                            from: <RulesContainer>{from}</RulesContainer>,
                            to: <RulesContainer>{to}</RulesContainer>
                        }
                    return { col: key, from: textForChange(key, from), to: textForChange(key, to) }
                },
                watcherId: watcherId,
                fieldFormatter: merchantAccountFormFieldName,
                buttonText: 'Save changes',
                title: 'Review merchant account changes',
                description: 'The following changes will be made on the merchant account:'
            }),
            [watcherId, form.changes, dispatch, account]
        )
    )

    useEffect(() => {
        if (watcher === 'success' && isIbanChanged) {
            redirectToClassification()
        }
    }, [watcher, isIbanChanged])

    useEffect(() => {
        if (draft?.loadingStatus !== 'started' && draft?.loadingStatus !== 'done' && account?.draftContractLink) {
            dispatch(MerchantAccountsActions.REFETCH_UPDATED_DRAFT_CONTRACT(accountId, account.draftContractLink))
        }
        if (
            account?.contractHistoryLoadingStatus !== 'started' &&
            account?.contractHistoryLoadingStatus !== 'done' &&
            account?.contract?.selfLink
        )
            dispatch(MerchantAccountsActions.FETCH_CONTRACT_HISTORY(accountId, account.contract.selfLink))
    }, [draft, dispatch, accountId, account])

    useEffect(() => {
        if (!accountId) return
        if (account?.loadingStatus === 'started') return
        if (account?.draft?.loadingStatus !== 'started' && account?.draft?.loadingStatus !== 'done') {
            dispatch(MerchantAccountsActions.FETCH_ACCOUNT(accountId))
        }
    }, [account, accountId, dispatch])

    const usefulLinks = useMemo(() => {
        if (!account?.metadata) return null
        return (
            <>
                <MerchantAccountLinks
                    appId={merchantId}
                    state={account.metadata.state}
                    accId={accountId}
                    MID={account.merchantId}
                />
                <ControlsSeparator />
            </>
        )
    }, [merchantId, accountId, account])

    const rightSideMemo = useMemo(() => {
        let mainAction: any = null
        if (Object.keys(form.changes).length)
            mainAction = (
                <FormHandler
                    formSubmitLabel="Save changes"
                    changes={form.changes}
                    textForFieldName={merchantAccountFormFieldName}
                    errors={errors}
                    focusInvalidField={focusInvalidField}
                    textForChange={textForChange}
                    submitForm={submitHandler(startReview)}
                    submitWatcherId={watcherId}
                    resetForm={resetForm}
                />
            )
        else mainAction = <MerchantAccountControls accountId={accountId} />

        if (!account) return mainAction

        return (
            <Flex>
                {usefulLinks}
                {mainAction}
            </Flex>
        )
    }, [
        form.changes,
        watcherId,
        submitHandler,
        resetForm,
        usefulLinks,
        errors,
        account,
        focusInvalidField,
        accountId,
        startReview
    ])

    useRouteGuard('The account has unsaved edits. Are your sure you want to leave?', hasChanges)

    if (accounts?.loadingStatus !== 'done' || isRedirectingToClassification)
        return (
            <Flex grow justify="center" align="center" column>
                <LoaderView overBackground="back.background" />
                <Spacer height={100} />
            </Flex>
        )
    if (accounts?.loadingStatus === 'done' && accounts?.all?.length === 0)
        return (
            <Flex grow justify="center" align="center" column>
                <Card shadowLess background="back.background.strongerI">
                    <CardInset>
                        <Text color="back.text.strongerI">Merchant has no accounts.</Text>
                        <Spacer height={10} />
                        <Flex justify="center">
                            <LinkButton
                                background="front.accent.color"
                                key={'new-account'}
                                to={{
                                    pathname: `/merchant/${merchantId}/accounts/new`,
                                    search: location.search
                                }}
                                state={{
                                    selectedAccount: accountId
                                }}
                                hotkeysScope="Merchant.AccountsPage"
                                hotkeys="alt+n"
                            >
                                <ButtonInset>Create an account</ButtonInset>
                            </LinkButton>
                        </Flex>
                    </CardInset>
                </Card>
                <Spacer height={100} />
            </Flex>
        )

    if (!accountId)
        return (
            <Centerer>
                <Text color="back.text">No account selected.</Text>
            </Centerer>
        )
    if (
        account?.loadingStatus !== 'done' ||
        account?.draft?.loadingStatus !== 'done' ||
        account?.contractHistoryLoadingStatus !== 'done'
    )
        return <LoaderView overBackground="back.background" />

    return (
        <Wrapper>
            <CenteredScreenHeader
                title=""
                left={
                    <Flex>
                        <Text bold>
                            Account {account?.merchantId} ({account.currency})
                        </Text>
                        {account?.paymentsHeld ? <PHTag>Payments Held</PHTag> : null}
                    </Flex>
                }
                right={rightSideMemo}
            ></CenteredScreenHeader>
            {Object.keys(form.changes).length ? null : (
                <MerchantAccountContractCard
                    accountId={accountId}
                    submitHandler={submitHandler}
                    merchantId={merchantId}
                />
            )}
            <Spacer height={10} />
            <MerchantAccountDetails accountId={accountId} formRef={formRef} errors={errors} data={form.data} />
            <Spacer height={10} />
            <MerchantAccountSettlementDetails
                accountId={accountId}
                formRef={formRef}
                errors={errors}
                data={form.data}
            />
            <Spacer height={10} />
            <MerchantAccountTransactionRules
                accountId={accountId}
                formRef={formRef}
                errors={errors}
                data={form.data}
                touched={touched}
            />
            <Spacer height={10} />
            <MerchantAccountAPIKeys
                touched={touched}
                merchantId={merchantId}
                accountId={accountId}
                formRef={formRef}
                errors={errors}
                data={form.data}
            />
            <Spacer height={10} />
            <MerchantAccountMembers
                merchantId={merchantId}
                accountId={accountId}
                formRef={formRef}
                errors={errors}
                data={form.data}
                touched={touched}
            />
            <Spacer height={20} />
            {UI}
        </Wrapper>
    )
}
const Wrapper = styled.div`
    min-width: 700px;
    padding: 0 30px;
`

const Centerer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`

const RulesContainer = styled.pre`
    margin: 0;
    padding: 10px;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-family: 'Roboto Mono';
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 12px;
`

const PHTag = styled.div`
    background-color: ${(p) => p.theme['front.danger.color']};
    color: ${(p) => p.theme['front.danger.text']};
    padding: 0 6px;
    border-radius: 6px;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 500;
`

const ControlsSeparator = styled.div`
    height: 10px;
    background: ${(p) => p.theme['back.border.strongerI']};
    width: 1px;
    margin: 10px 20px;
`
