import { createReducer } from 'deox'
import { produce } from 'immer'

import { ExportsActions } from './actions'
import { InitialExportsState } from './types'
import moment from 'moment'

export const ExportsReducer = createReducer(InitialExportsState, (handleAction) => [
    handleAction(ExportsActions.FETCH_EXPORTS, (state) =>
        produce(state, (draft) => {
            draft.exports = {
                ...draft.exports,
                loadingStatus: 'started'
            }
        })
    ),
    handleAction(ExportsActions.STORE_EXPORT, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.exports.at[p.e.id] = p.e
            draft.exports.all = state.exports.all.filter((id) => id !== p.e.id)
            draft.exports.all.push(p.e.id)
        })
    ),
    handleAction(ExportsActions.STORE_EXPORTS, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.exports = {
                at: p.exports.reduce((acc, i) => {
                    acc[i.id] = i
                    return acc
                }, {} as any),
                all: p.exports
                    .sort((a, b) => {
                        return moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1
                    })
                    .map((p) => p.id),
                pagination: { ...p.pagination } as any,
                loadingStatus: 'done'
            }
        })
    )
])
