import { returnUniqueIdsArray } from '../../utils'
import {
    CONTRACTS_LOAD_CONTRACTS,
    CONTRACTS_STORE_CONTRACTS,
    ReducedContractsActions
} from './actions'
import { ContractsState, InitialContractsState } from './types'

export function ContractsReducers(
    state = InitialContractsState,
    action: ReducedContractsActions
): ContractsState {
    switch (action.type) {
        case CONTRACTS_LOAD_CONTRACTS: {
            return {
                ...state,
                loadingStatus: 'started'
            }
        }
        case CONTRACTS_STORE_CONTRACTS: {
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    at: {
                        ...state.contracts.at,
                        ...action.contracts.reduce((a, c) => {
                            return {
                                ...a,
                                [c.id]: {
                                    ...c
                                }
                            }
                        }, {})
                    },
                    all: returnUniqueIdsArray(
                        state.contracts.all,
                        action.contracts
                    )
                },
                loadingStatus: 'done'
            }
        }
    }

    return state
}
