import { ActionType } from 'deox'

import { DELETE, GET, POST } from '../../../generators/networking'
import { ApplicationInternalsCollaboratorsActions } from './actions'

export const ApplicationInternalsCollaboratorsSagas = {
    *LOAD({ payload: p }: ActionType<typeof ApplicationInternalsCollaboratorsActions.LOAD>) {
        yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/applications/${p.applicationId}/collaborators`,
            include: ['self'],
            onSuccessDispatch: (r) => {
                return ApplicationInternalsCollaboratorsActions.STORE(p.applicationId, r.collaborators)
            },
            errorText: 'Failed to load collaborators'
        })
    },
    *INVITE({ payload: p }: ActionType<typeof ApplicationInternalsCollaboratorsActions.INVITE>) {
        yield POST({
            watcher: p.watcherId,
            url: `${import.meta.env.VITE_API_ROOT}/applications/${p.applicationId}/collaborators`,
            body: {
                email: p.toEmail,
                message: p.message,
                name: p.name
            },
            successCode: 200,
            include: ['self', 'comments', 'tags', 'download'],
            onSuccessDispatch: (r: any) => {
                return ApplicationInternalsCollaboratorsActions.STORE(p.applicationId, [r], true)
            },
            successText: `Collaborator invited successfully.`,
            errorText: `Failed to invite collaborator.`
        })
    },
    *RESEND_INVITATION({ payload: p }: ActionType<typeof ApplicationInternalsCollaboratorsActions.RESEND_INVITATION>) {
        yield POST({
            watcher: p.watcherId,
            successCode: 200,
            url: `${import.meta.env.VITE_API_ROOT}/applications/${p.applicationId}/collaborators`,
            body: {
                email: p.toEmail,
                message:
                    'This is a reminder that you have been invited to collaborate on a merchant account application.'
            },
            successText: `Collaborator invitation re-sent successfully, ${p.toEmail}.`,
            errorText: `Failed to re-invite the collaborator, ${p.toEmail}.`
        })
    },
    *REVOKE({ payload: p }: ActionType<typeof ApplicationInternalsCollaboratorsActions.REVOKE>) {
        yield DELETE({
            watcher: p.watcherId,
            url: p.collaboratorLink,
            successText: `Collaborator revoked successfully.`,
            errorText: `Revoking the collaborator has failed.`,
            onSuccessDispatch: () => {
                return ApplicationInternalsCollaboratorsActions.UNSTORE(p.applicationId, p.collaboratorEmail)
            }
        })
    }
}
