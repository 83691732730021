import { Charts } from '@/services/ongoing-due-diligence'
import { File, RelativeDate } from '../../common'
import { TableRow } from '@/components/tables/table'

export const getChartsRows = (data?: Charts) => {
    if (!data) {
        return []
    }

    const rows: TableRow[] = data.files.map((file) => {
        return {
            type: 'normal' as const,
            key: file.id,
            noAction: true,
            items: [
                {
                    node: <File fileId={file.id}>{file.name}</File>
                },
                {
                    node: <RelativeDate>{file.created_at}</RelativeDate>
                }
            ]
        }
    })

    return rows
}
