import React from 'react'
import { ToggleContent } from '../toggle-content/toggle-content'
import { useReportParams } from '../../../../../hooks'
import { SectionCheck } from '@/services/ongoing-due-diligence'
import { useReportSection } from '../../../provider/report-provider/report-provider'
import { Section } from '../../../common/section'

export const RiskClassification: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')

    const data = checks.risk_classification
    const commentData = checks.comment_risk_classification

    const { applicationId } = useReportParams()

    const riskClasificationLink = `/merchant/${applicationId}/more/risk-tab`
    return (
        <Section>
            <Section.Content>
                <ToggleContent
                    dataName="risk_classification"
                    data={data}
                    commentName="comment_risk_classification"
                    commentData={commentData}
                    visibleValue="No"
                />
            </Section.Content>
            <Section.Metadata>
                <Section.Link url={riskClasificationLink}>Risk classification</Section.Link>
            </Section.Metadata>
        </Section>
    )
}
