import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../../../components/buttons/externalLink'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Text } from '../../../components/general/text'
import { Website } from '../../../store/applications/types'
import { getWebsiteLabel, getWebsiteUrl } from '../../../utils/formatting'

const LIMIT = 3
export const MerchantSummaryCompanyInformationWebsites: React.FC<{ websites: Website[] }> = ({
    websites: allWebsites
}) => {
    const [seeAll, setSeeAll] = useState(false)
    const websites = useMemo(() => {
        return allWebsites?.filter((w) => w.url) || []
    }, [allWebsites])

    if (!websites?.length)
        return (
            <Holder>
                <Text>No websites provided</Text>
            </Holder>
        )

    return (
        <Holder>
            {seeAll
                ? websites
                      .filter((w) => w.url)
                      .map((w) => (
                          <RightSpacer title={getWebsiteUrl(w.url as string)}>
                              <ListItem key={w.url}>
                                  <ExternalLink target="blank" url={getWebsiteUrl(w.url as string)} alwaysShowArrow>
                                      {getWebsiteLabel(w.url as string)}
                                  </ExternalLink>
                              </ListItem>
                          </RightSpacer>
                      ))
                      .filter((w, i) => (seeAll ? true : i < LIMIT))
                : websites
                      .filter((w) => w.url)
                      .map((w) => (
                          <RightSpacer title={getWebsiteUrl(w.url as string)}>
                              <ExternalLink
                                  target="blank"
                                  url={getWebsiteUrl(w.url as string)}
                                  key={w.url}
                                  alwaysShowArrow
                              >
                                  {getWebsiteLabel(w.url as string)}
                              </ExternalLink>
                          </RightSpacer>
                      ))
                      .filter((w, i) => i < LIMIT)}
            {websites.length > LIMIT ? (
                <RightSpacer>
                    <SimpleButton onClick={() => setSeeAll((s) => !s)}>
                        {seeAll
                            ? 'See less'
                            : `See ${websites.length - LIMIT} ${websites.length - LIMIT == 1 ? 'other' : 'others'}`}
                    </SimpleButton>
                </RightSpacer>
            ) : null}
        </Holder>
    )
}

const RightSpacer = styled.div`
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
`

const Holder = styled.div`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
`
const ListItem = styled.span``
