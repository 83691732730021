import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useModalStackSync } from '../../../hooks/general/useModalStackSync'
import { useResetScrollOnMount } from '../../../hooks/pages/useResetScrollOnMount'
import { MerchantAccountsSidebar } from './AccountsSidebar'
import { useMerchantAccounts } from './useMerchantAccounts'
import { MerchantAccount } from './Accounts.ID'
import { useDispatch } from 'react-redux'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { useQueryParams } from '../../../hooks/general/useQueryParam'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { CenteredScreen } from '../../../components/layout/centeredScreen'
import { Spacer } from '../../../components/layout/spacer'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

type Params = {
    id: string
    accountId: string
}

export const AccountsParams = ['transactions_mid', 'disputes_mid', 'settlements_mid']
export const MerchantAccounts: React.FC = () => {
    const params = useParams() as Params

    const { id, accountId } = params || {}
    const navigate = useNavigate()
    const location = useLocation()
    const accounts = useMerchantAccounts(id)
    const dispatch = useDispatch()
    const [query, setQuery] = useQueryParams(AccountsParams, undefined)
    const name = useMerchantName(id, 'Accounts')
    useModalStackSync(name, 'Merchant.AccountsPage', 0)
    useResetScrollOnMount()

    useEffect(() => {
        if (accountId) {
            return
        }
        const shouldFocusLastAccount = location.state?.focusLast || false

        const sortedAccounts = (accounts?.accounts || [])
            ?.map((a) => ({
                mid: a.merchantId,
                id: a.id
            }))
            .sort((a, b) => (parseInt(a.mid) > parseInt(b.mid) ? 1 : -1))

        const mid =
            typeof query['transactions_mid'] === 'object'
                ? query['transactions_mid'].sort((a: string, b: string) => (parseInt(a) > parseInt(b) ? 1 : -1))[0]
                : query['transactions_mid']
        const { id: selectedAccountId } = sortedAccounts.find((a) => a.mid === mid) || {
            mid: undefined,
            id: undefined
        }

        if (!shouldFocusLastAccount && selectedAccountId && accountId !== selectedAccountId) {
            navigate(
                {
                    pathname: `/merchant/${id}/accounts/${selectedAccountId}`,
                    search: location.search
                },
                { replace: true }
            )
            return
        }

        if (sortedAccounts.length) {
            navigate(
                {
                    pathname: `/merchant/${id}/accounts/${
                        sortedAccounts[shouldFocusLastAccount ? sortedAccounts.length - 1 : 0].id
                    }`,
                    search: location.search
                },
                { replace: true }
            )
        }
    }, [navigate, setQuery, query, accounts, id, accountId, location])

    useEffect(() => {
        if (location.state?.refresh) dispatch(MerchantAccountsActions.FETCH(id))
    }, [location, id, dispatch])

    return (
        <Container>
            <MerchantAccountsSidebar id={id} accountId={accountId} />
            {/* <PageContent marginLeft={0} noVerticalPadding className="POPPER_PARENT"> */}
            <CenteredScreen maxWidth={900}>
                <MerchantAccount accountId={accountId} merchantId={id} key={accountId} />
                <Spacer height={60} />
            </CenteredScreen>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: stretch;
`
