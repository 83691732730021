import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationCards } from './application-changes/ApplicationCards/ApplicationCards'
import { EmptyChangesEvents } from './application-changes/EmptyStates/EmptyChangesEvents'
import { EmptyChanges } from './application-changes/EmptyStates/EmptyChanges'
import { RootState } from '@/store'
import { Task } from '../../../../store/tasks/types'
import { TasksCompanyChangesActions } from '../../../../store/tasksCompanyChanges/actions'
import { LoaderView } from '../../../../components/loaders/loader'

const useTaskCompanyChanges = (taskId: string) => {
    return useSelector((state: RootState) => state.tasksCompanyChanges[taskId])
}

const getTaskTimelineChangesEvents = (task: Task) => {
    return task.timeline.timeline.filter((t) => (t.data.type == 'changes' ? true : false))
}

interface TasksMonitoringProps {
    task: Task
}

export const TasksMonitoring: React.FC<TasksMonitoringProps> = (props) => {
    const { task } = props

    const dispatch = useDispatch()

    const taskCompanyChangesState = useTaskCompanyChanges(task.id)
    const taskChangesEvents = getTaskTimelineChangesEvents(task)

    const isStateDone = taskCompanyChangesState?.loadingStatus === 'done'
    const isStateStarted = taskCompanyChangesState?.loadingStatus === 'started'

    useEffect(() => {
        if (!isStateDone && !isStateStarted) {
            dispatch(TasksCompanyChangesActions.INIT(task.id, taskChangesEvents))
        }
    }, [task.id, taskChangesEvents, isStateDone, isStateStarted, dispatch])

    if (!isStateDone) return <LoaderView overBackground="back.background" />

    if (!taskChangesEvents?.length) return <EmptyChangesEvents />

    if (!taskCompanyChangesState?.changes) return <EmptyChanges />

    return <ApplicationCards taskId={task.id} changes={taskCompanyChangesState.changes} />
}
