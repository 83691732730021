import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { ButtonInset } from '../components/buttons/buttonInset'
import { SimpleButton } from '../components/buttons/simpleButton'
import { WatcherButton } from '../components/buttons/watcherButton'
import { Card } from '../components/cards/card'
import { CardInset } from '../components/cards/cardInset'
import { Flex } from '../components/layout/flex'
import { PageWrapper } from '../components/layout/pageWrapper'
import { Spacer } from '../components/layout/spacer'
import { Text } from '../components/general/text'
import { ModalPage } from '../components/layout/modalPage'
import { RootState } from '@/store'
import { zIndexes } from '../styles/zIndexes'
import { DialogContext } from './dialogProvider'
import { CardSection } from '../components/cards/cardSection'

export const Dialog: React.FC = () => {
    const { setDialog, dialog } = useContext(DialogContext)

    const watcher = useSelector((state: RootState) =>
        dialog?.action.watcherId ? state.watchers[dialog?.action.watcherId]?.state : undefined
    )

    useEffect(() => {
        if (watcher === 'success') setDialog(undefined)
    }, [watcher, setDialog])

    if (!dialog) return null

    return (
        <Holder data-prevent-sidebar-exit data-prevent-interactions-exit data-prevent-notes-exit>
            <ModalPage
                title={dialog?.title || 'Confirmation dialog'}
                pageId="GenericDialog"
                onBack={() => {
                    setDialog(undefined)
                }}
            >
                <PageWrapper maxWidth="tiny" shouldCenter>
                    <Card higher title={dialog?.title}>
                        <CardInset>
                            <Text>{dialog?.description}</Text>
                            <Spacer height={20} />
                            <CardSection background="subtleBlue">
                                <CardInset reduceVerticalPadding>
                                    <Flex justify={dialog?.action?.justify || 'space-between'} grow>
                                        <SimpleButton
                                            background="front.background"
                                            cy="dialog-cancel"
                                            onClick={() => setDialog(undefined)}
                                        >
                                            <ButtonInset>Cancel</ButtonInset>
                                        </SimpleButton>
                                        <Spacer width={10} />
                                        <WatcherButton
                                            background={dialog?.action?.buttonBackground}
                                            predefinedWatcher={dialog?.action?.watcherId}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dialog?.action?.action?.()
                                            }}
                                            cy="dialog-confirm"
                                        >
                                            <ButtonInset>{dialog?.action.label}</ButtonInset>
                                        </WatcherButton>
                                    </Flex>
                                </CardInset>
                            </CardSection>
                        </CardInset>
                    </Card>
                </PageWrapper>
            </ModalPage>
        </Holder>
    )
}

const Holder = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${zIndexes.modal};
`
