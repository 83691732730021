import { Moment } from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { ScrollRefIntoView } from '../../utils'
import { MODAL_ID } from '../modals/modalIds'

export interface AdaptedTimelineItem {
    tabIndex?: string
    date?: Moment
    node: React.ReactNode
}

export interface AdaptedTimelineItemWithIcon {
    tabIndex: string
    hotkey: string
    icon: React.ReactNode
    node: React.ReactNode
}
// sec * min
export const Timeline: React.FC<{
    items: (AdaptedTimelineItem | null)[]
    dedicatedItems?: AdaptedTimelineItemWithIcon[]
    noTabs?: boolean
    hotkeysScope: MODAL_ID
    preview?: string
}> = ({ items, dedicatedItems, preview }) => {
    const [show, setShow] = useState('Everything')
    const previewCommentRef = useRef<any>()

    useEffect(() => {
        if (preview) ScrollRefIntoView(previewCommentRef)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewCommentRef])

    const renderTimeline = useMemo(() => {
        let allItems: React.ReactNode[] = []
        const nonNullItems = items.filter((f) => f) as AdaptedTimelineItem[]

        if (show !== 'Everything')
            allItems = [
                ...nonNullItems
                    .filter((i) => i.tabIndex === show)
                    .sort((a, b) => {
                        if (a.date?.isBefore(b.date)) return -1
                        return 1
                    })
                    .map((i) => i.node)
            ]
        else
            allItems = [
                ...nonNullItems
                    .sort((a, b) => {
                        if (a.date?.isBefore(b.date)) return -1
                        return 1
                    })
                    .map((i) => i.node)
            ]
        allItems = [
            ...allItems,
            ...(dedicatedItems ? dedicatedItems.filter((i) => i.tabIndex === show).map((i) => i.node) : [])
        ]
        return allItems
    }, [show, items, dedicatedItems])

    return <Holder>{renderTimeline}</Holder>
}

const Holder = styled.div`
    padding: 0;
    position: relative;
`
