import styled from 'styled-components'
import { LoadingBlinker, LoadingBlinkerDeclaration } from './textLineLoader'

export const LoadingShaper: React.FC<{
    width?: number
    height?: number
    children?: any
    sidebar?: boolean
}> = ({ width, height, sidebar, children }) => {
    return (
        <>
            <SVG width={width} sidebar={sidebar} height={height}>
                {children}
            </SVG>
        </>
    )
}
const SVG = styled.svg<{ sidebar?: boolean }>`
    color: ${(p) => (p.sidebar ? p.theme['sidebarLoaders'] : p.theme['loaders'])};
    will-change: opacity;
    animation: ${LoadingBlinker} ${LoadingBlinkerDeclaration};
`
