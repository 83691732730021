import { useRef } from 'react'
import styled, { keyframes } from 'styled-components'

export const TextLineLoader: React.FC<{
    topOffset?: number
    minWidth?: number
    maxWidth?: number
    height?: number
    textHeight?: number
    padding?: number
    sidebar?: boolean
}> = ({ maxWidth = 200, sidebar, height = 13, padding = 0, minWidth = 70, topOffset = 0, textHeight = 13 }) => {
    const width = useRef(minWidth + Math.random() * Math.abs(minWidth - maxWidth))
    const rectHeight = textHeight

    return (
        <SVGHolder sidebar={sidebar} height={height} padding={padding} topOffset={topOffset} data-cy="loader">
            <svg width={width.current} height={rectHeight} viewBox={`0 0 ${width.current} ${rectHeight}`}>
                <rect width={width.current} height={rectHeight} rx={3} ry={3} />
            </svg>
        </SVGHolder>
    )
}

export const LoadingBlinker = keyframes`
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
`
export const LoadingBlinkerDeclaration = `0.8s cubic-bezier(0.45, 0, 0.55, 1) infinite alternate`

const SVGHolder = styled.div<{ height: number; padding: number; topOffset: number; sidebar?: boolean }>`
    color: ${(p) => (p.sidebar ? p.theme['sidebarLoaders'] : p.theme['loaders'])};
    height: ${(p) => p.height}px;
    padding-top: ${(p) => p.padding}px;
    padding-bottom: ${(p) => p.padding}px;
    position: relative;
    display: inline-flex;
    align-items: center;
    opacity: 0.5;
    will-change: opacity;
    animation: ${LoadingBlinker} ${LoadingBlinkerDeclaration};
    top: ${(p) => p.topOffset}px;
`
