import dotProp from 'dot-prop'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
    InternalDetailsStructure,
    MerchantApplicationInternalDetailValue
} from '../../../store/applicationInternals/details/types'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { RootState } from '@/store'

export const useApplicationInternalValue = (
    applicationId: string,
    indexPath: MerchantApplicationResourceIndexPath,
    type: MerchantApplicationInternalDetailValue
) => {
    const id = useSelector((state: RootState) => {
        return dotProp.get<string>(
            state,
            `applicationResources.data.forApplication.${applicationId}.${indexPath.resourceKey}.fields.${
                indexPath.subsectionIndex || 0
            }.id`
        )
    })
    const localValue = useSelector((state: RootState) => {
        if (type === 'ownershipPercentage' || type === 'notePerson')
            return dotProp.get(
                state,
                `applicationInternals.details.${applicationId}.${InternalDetailsStructure[type].resource}.${id}.${type}`
            )
        return dotProp.get(
            state,
            `applicationInternals.details.${applicationId}.${InternalDetailsStructure[type].resource}.${type}`
        )
    }) as any
    const structure = useMemo(() => {
        return InternalDetailsStructure[type]
    }, [type])

    return { id, localValue, structure }
}
