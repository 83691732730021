import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Sidebar } from '../../components/listPages/sidebarNavigation/sidebar'
import { Account } from '../../components/general/account'
import { AgentBubble } from '../../components/general/agentBubble'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { List } from '../../components/layout/list'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { RelativeDate } from '../../components/general/relativeDate'
import { Table, TableRowType } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { useListPage } from '../../hooks/pages/useListPage'
import { ListPageFilterAgent } from '../../components/listPageFilters/listPageFilterAgent'
import { ListPageFilterAlertStatus } from '../../components/listPageFilters/listPageFilterAlertStatus'
import { ListPageFilterAlertTags } from '../../components/listPageFilters/listPageFilterAlertTags'
import { ListPageFilterAlertType } from '../../components/listPageFilters/listPageFilterAlertType'
import { ListPageFilterDate } from '../../components/listPageFilters/listPageFilterDate'
import { ListPageFiltersSeparator } from '../../components/listPageFilters/listPageFiltersSeparator'
import { Filters } from '../../components/taskPages/filters'
import { PopularFilters } from '../../components/taskPages/popularFilters'
import { RootState } from '@/store'
import {
    TasksDispatchLoadSummaries,
    TasksDispatchLoadTags,
    TasksDispatchClearSummaries
} from '../../store/tasks/actions'
import { TaskFiltersParams, TasksFilters } from '../../store/tasks/types'
import { uppercaseFirstLetter } from '../../utils'
import { filterOnlyInstanttags } from '../../utils/tagFilters'
import { AlertModal } from '../Alerts/Alerts.ID'
import { ListPageFilterMerchant } from '../../components/listPageFilters/listPageFilterMerchant'
import { SideFilters } from '../../components/layout/sideFilters'
import { TagsLister } from '../Merchant/MerchantTagsLister'
import { useMerchantName } from '../../hooks/general/useMerchantName'
import { ListPageFilterPresetMIDs } from '../../components/listPageFilters/listPageFilterPresetMIDs'
import { ApplicationLoadingActions } from '../../store/applicationLoading/actions'
import { TableBubble } from '../../components/layout/tableBubble'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'

const paginationKeys = {
    pageKey: 'tasks_page',
    perPageKey: 'tasks_per_page'
}

const getRowType = (state: string): TableRowType => {
    if (state === 'new') return 'emphasized'
    if (state === 'open') return 'normal'
    return 'subtle'
}
const clearSummaries = () => TasksDispatchClearSummaries()

export const Tasks: React.FC<{
    inline?: boolean
    merchantId?: string
    forIds?: { tasks_subject_id: string[] }
    textForItem?: (key: any) => any
}> = ({ inline, merchantId, forIds, textForItem }) => {
    const params = useParams()
    const location = useLocation()

    const dispatch = useDispatch()
    const summaries = useSelector((state: RootState) => {
        return state.tasks.taskSummaries
    })
    const name = useMerchantName(merchantId, 'Tasks')

    useEffect(() => {
        dispatch(TasksDispatchLoadTags())
    }, [dispatch])
    const onLoad = useCallback((filters: TasksFilters) => {
        return TasksDispatchLoadSummaries(filters)
    }, [])

    useEffect(() => {
        if (!inline) return
        if (!merchantId) return
        dispatch(ApplicationLoadingActions.LOAD_TASKS_COUNT(merchantId, forIds?.tasks_subject_id))
    }, [inline, dispatch, forIds, merchantId])

    const defaultParams = useMemo(() => {
        if (!inline)
            return {
                tasks_status: ['open', 'new']
            }

        return {
            ...forIds,
            tasks_subject_type: 'account' as const,
            tasks_type: ['instant-signup', 'transaction-investigation', 'odd-reporting']
        }
    }, [inline, forIds])

    const { filters, paginationNode } = useListPage<TasksFilters>(
        'TasksList',
        merchantId ? name : 'Tasks',
        onLoad,
        TaskFiltersParams,
        summaries,
        '/tasks/',
        location,
        defaultParams,
        paginationKeys,
        undefined,
        inline ? 'tasks_subject_id' : undefined,
        clearSummaries
    )

    const renderedFilters = useMemo(() => {
        const items: any = (isDark = false) => ({
            'Status': {
                type: 'custom',
                node: <ListPageFilterAlertStatus isDark={isDark} filters={filters} />
            },
            'MID': forIds
                ? {
                      type: 'custom',
                      node: (
                          <ListPageFilterPresetMIDs<TasksFilters>
                              filters={filters}
                              isDark={isDark}
                              textForItem={textForItem}
                              options={forIds.tasks_subject_id}
                              fieldKey="tasks_subject_id"
                              secondFilterKey="tasks_subject_type"
                          />
                      )
                  }
                : {
                      type: 'custom',
                      node: (
                          <ListPageFilterMerchant<TasksFilters>
                              isDark={isDark}
                              filters={filters}
                              fieldKey="tasks_subject_id"
                              secondFilterKey="tasks_subject_type"
                          />
                      )
                  },
            'Type': {
                type: 'custom',
                node: <ListPageFilterAlertType isDark={isDark} filters={filters} />
            },
            'Agent': {
                type: 'custom',
                node: <ListPageFilterAgent<TasksFilters> isDark={isDark} filters={filters} fieldKey="tasks_agent" />
            },
            'Tags': {
                type: 'custom',
                node: <ListPageFilterAlertTags isDark={isDark} filter={filterOnlyInstanttags} filters={filters} />
            },
            ...(isDark
                ? {
                      'Separator 1': {
                          key: 'separator-1',
                          type: 'separator',
                          node: <ListPageFiltersSeparator isDark={isDark} />
                      }
                  }
                : {}),
            'Created after': {
                type: 'custom',
                node: (
                    <ListPageFilterDate<TasksFilters> isDark={isDark} filters={filters} filterKey="tasks_start_date" />
                )
            },
            'Created before': {
                type: 'custom',
                node: <ListPageFilterDate<TasksFilters> isDark={isDark} filters={filters} filterKey="tasks_end_date" />
            }
        })
        return {
            sideFilters: <SideFilters items={items()} />,
            headerFilters: (
                <Filters
                    showHideController={filters.showHideController}
                    filters={filters}
                    resultsCount={summaries.all.length}
                    entityName="tasks"
                    pagination={summaries.pagination}
                    key="allFilters"
                    inline={inline}
                    hotkeysScope="TasksList"
                >
                    <List
                        background="floating.background"
                        items={items(true)}
                        emphasizeLabels
                        template="auto"
                        switchToRowsAt={100000}
                        cellHorizontalTemplate="100px auto"
                    />
                </Filters>
            )
        }
    }, [summaries, inline, textForItem, forIds, filters])

    const renderedPopularFilters = useMemo(() => {
        return (
            <PopularFilters
                showHideController={filters.showHideController}
                key="popularFilters"
                hotkeysScope="TasksList"
                config={[
                    'General filters:',
                    {
                        label: 'All *Open* tasks',
                        action: () => {
                            filters.set({ tasks_status: ['open'] })
                        }
                    },
                    {
                        label: 'All *New* tasks',
                        action: () => {
                            filters.set({ tasks_status: ['new'] })
                        }
                    },
                    {
                        label: 'All *Closed* tasks',
                        action: () => {
                            filters.set({ tasks_status: ['closed'] })
                        }
                    }
                ]}
            />
        )
    }, [filters])

    return (
        <Flex align="stretch">
            {!inline && <Sidebar hotkeysScope="TasksList" />}
            {!inline && renderedFilters.sideFilters}
            <PageContent marginLeft={0} noVerticalPadding noPadding={inline}>
                <PageHeader
                    noBottomBorder
                    title="Tasks"
                    subtitle=""
                    rightSideMemo={renderedFilters.headerFilters}
                    leftSideMemo={renderedPopularFilters}
                />
                <Card>
                    <CardInset>
                        <Table
                            background="front.background"
                            keepQuery
                            cols={[
                                {
                                    text: 'State',
                                    loaderSize: {
                                        min: 15,
                                        max: 30
                                    }
                                },
                                {
                                    text: 'MID',
                                    loaderSize: {
                                        min: 55,
                                        max: 55
                                    }
                                },
                                {
                                    text: 'Merchant',
                                    loaderSize: {
                                        min: 50,
                                        max: 200
                                    }
                                },
                                {
                                    text: 'Created',
                                    loaderSize: {
                                        min: 60,
                                        max: 80
                                    }
                                },
                                {
                                    text: 'Type',
                                    loaderSize: {
                                        min: 30,
                                        max: 50
                                    }
                                },
                                {
                                    text: 'Title',
                                    loaderSize: {
                                        min: 80,
                                        max: 260
                                    }
                                },
                                {
                                    text: 'Assignee',
                                    loaderSize: {
                                        min: 30,
                                        max: 30
                                    }
                                },
                                {
                                    text: 'Tags',
                                    loaderSize: {
                                        min: 270,
                                        max: 320
                                    }
                                }
                            ]}
                            overrideText="No tasks have been found."
                            // eslint-disable-next-line max-len
                            columnLayout="min-content min-content minmax(200px, min-content) min-content min-content min-content minmax(100px, min-content) auto"
                            showLastRowBorder
                            loaderRows={20}
                            displayLoader={summaries.loadingStatus === 'started'}
                            rows={summaries.all.map((id: string) => {
                                const t = summaries.at[id]
                                return {
                                    type: getRowType(t.state),
                                    link: inline ? `/merchant/${merchantId}/tasks/${t.id}` : `/tasks/${t.id}`,
                                    key: t.id,
                                    items: [
                                        {
                                            node: (
                                                <Text
                                                    noWrap
                                                    bold={t.state !== 'closed'}
                                                    color={t.state !== 'closed' ? 'front.text' : 'front.text.subtlerI'}
                                                >
                                                    {uppercaseFirstLetter(t.state)}
                                                </Text>
                                            )
                                        },
                                        {
                                            node:
                                                t.type == 'company-change' ? (
                                                    <Text noWrap color="front.text.subtlerI">
                                                        -
                                                    </Text>
                                                ) : (
                                                    <TableBubble type="outlined">
                                                        <Account id={t.subjectId} merchant showOnlyMID />
                                                    </TableBubble>
                                                )
                                        },
                                        {
                                            node: (
                                                <Text oneLine>
                                                    {t.type == 'company-change' ? (
                                                        t.title.split('`')[1]
                                                    ) : (
                                                        <Account id={t.subjectId} merchant />
                                                    )}
                                                </Text>
                                            )
                                        },
                                        {
                                            node: (
                                                <Text noWrap>
                                                    <RelativeDate dateString={t.createdAt} />
                                                </Text>
                                            )
                                        },
                                        {
                                            node: (
                                                <Text noWrap>
                                                    {t.type ? uppercaseFirstLetter(t.type.replace(/-/g, ' ')) : ''}
                                                </Text>
                                            )
                                        },
                                        {
                                            node:
                                                t.type == 'company-change' ? (
                                                    <Text noWrap color="front.text.subtlerI">
                                                        -
                                                    </Text>
                                                ) : (
                                                    <Text noWrap>{t.title}</Text>
                                                )
                                        },
                                        {
                                            node: <AgentBubble id={t.assignee} slim filled={t.state !== 'closed'} />
                                        },
                                        {
                                            node: (
                                                <TagsLister overBackground="front.background" tags={t.tags} limit={2} />
                                            )
                                        }
                                    ]
                                }
                            })}
                            emptyText="No tasks found matching the filtered criterias."
                        />
                    </CardInset>
                </Card>
                {paginationNode}
                <Routes>
                    <Route
                        key="modal"
                        path={`${location.pathname}/:id`}
                        element={
                            <AlertModal key={params.id} type="task" backTo={`${location.pathname}${location.search}`} />
                        }
                    />
                </Routes>
            </PageContent>
        </Flex>
    )
}
