import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Sidebar } from '../../components/listPages/sidebarNavigation/sidebar'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { ButtonLoadingCover } from '../../components/buttons/buttonLoadingCover'
import { LinkButton } from '../../components/buttons/linkButton'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { IDShortener } from '../../components/general/idShortener'
import { List } from '../../components/layout/list'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { useListPage } from '../../hooks/pages/useListPage'
import { IconPaymentMethod } from '../../components/icons/iconPaymentMethod'
import { ListPageFilterDate } from '../../components/listPageFilters/listPageFilterDate'
import { ListPageFilterMerchant } from '../../components/listPageFilters/listPageFilterMerchant'
import { ListPageFiltersSeparator } from '../../components/listPageFilters/listPageFiltersSeparator'
import { ListPageFilterCustomQuery } from '../../components/listPageFilters/listPageFilterCustomQuery'
import { ListPageFilterTransactionsLastFour } from '../../components/listPageFilters/listPageFilterTransactionsLastFour'
import { ListPageFilterTransactionsStatus } from '../../components/listPageFilters/listPageFilterTransactionsStatus'
import { ListPageFilterTransactionsType } from '../../components/listPageFilters/listPageFilterTransactionsType'
import { Filters } from '../../components/taskPages/filters'
import { TransactionStatusCode } from '../../components/transactions/transactionStatusCode'
import { RootState } from '@/store'
import {
    TransactionsDispatchClearSummaries,
    TransactionsDispatchLoadNextSummaries,
    TransactionsDispatchLoadSummaries
} from '../../store/transactions/actions'
import { SideFilters } from './../../components/layout/sideFilters'
import { TransactionsFilters, TransactionsFiltersParams, TransactionSummary } from '../../store/transactions/types'
import { numberWithCurrency, uppercaseFirstLetter, uppercaseFirstLetterIfLowercase } from '../../utils'
import { DateFormats } from '../../utils/dateUtils'
import { useResetScrollOnMount } from '../../hooks/pages/useResetScrollOnMount'
import { useMerchantListPageDynamicTitle } from '../../hooks/pages/useMerchantListPageDynamicTitle'
import { useMerchantName } from '../../hooks/general/useMerchantName'
import { PaymentCardString } from '../../utils/formatting'
import { ListPageFilterPaymentMethod } from '../../components/listPageFilters/listPageFilterPaymentMethod'
import { ListPageFilterTransactionsScheme } from '../../components/listPageFilters/listPageFilterTransactionsScheme'
import { ExportButton } from '../../components/buttons/exportButton'
import { TransactionsFlagWithTooltip } from './TransactionsFlagWithTooltip'
import { ListPageFilterCurrencyAndAmount } from '../../components/listPageFilters/listPageFilterCurrencyAndAmount'
import { ListPageFullPageBackButton } from '../../components/buttons/listPageFullPageBackButton'
import { TableBubble } from '../../components/layout/tableBubble'
import { useBackendToConsoleFilters } from '../../hooks/general/useBackendToConsoleFilters'
import { useLocation, useParams } from 'react-router-dom'

type Params = { id: string }

const clearSummaries = () => TransactionsDispatchClearSummaries()
const pagination = {
    pageKey: 'transactions_page',
    perPageKey: 'transactions_per_page'
}
const disallowMIDFilter = { transactions_mid: null }
export const Transactions: React.FC<{ inline?: boolean; fullPage?: boolean; forMids?: any }> = ({
    inline,
    fullPage,
    forMids
}) => {
    const params = useParams() as Params
    const merchantId = params.id

    const location = useLocation()

    useBackendToConsoleFilters('tql')
    useResetScrollOnMount()
    const dispatch = useDispatch()
    const summaries = useSelector((state: RootState) => {
        return state.transactions.summaries
    })
    const name = useMerchantName(merchantId, 'Transactions')
    const loadSummaries = useCallback(
        (filters: TransactionsFilters) => {
            if (forMids) {
                return TransactionsDispatchLoadSummaries({ ...filters, transactions_mid: forMids.transactions_mid })
            }
            return TransactionsDispatchLoadSummaries(filters)
        },
        [forMids]
    )

    const { filters } = useListPage<TransactionsFilters>(
        'TransactionsList',
        merchantId ? name : 'Transactions',
        loadSummaries,
        TransactionsFiltersParams,
        summaries,
        '/transactions/',
        location,
        undefined,
        pagination,
        forMids ? disallowMIDFilter : undefined,
        undefined,
        clearSummaries
    )

    const renderedFilters = useMemo(() => {
        const items: any = (isDark = false) => ({
            'Query': {
                type: 'custom',
                node: (
                    <ListPageFilterCustomQuery<TransactionsFilters>
                        key="custom-filters"
                        fieldKey="transactions_custom_filters"
                        filters={filters}
                        isDark={isDark}
                    />
                )
            },
            ...(isDark
                ? {
                      'Separator 1': {
                          key: 'separator-1',
                          type: 'separator',
                          node: <ListPageFiltersSeparator isDark={isDark} />
                      }
                  }
                : {}),
            'Currency · Amount': {
                type: 'custom',
                node: (
                    <ListPageFilterCurrencyAndAmount<TransactionsFilters>
                        key="currency-amount-filters"
                        filters={filters}
                        isDark={isDark}
                    />
                )
            },
            'MID': forMids
                ? null
                : fullPage
                ? null
                : {
                      type: 'custom',
                      node: (
                          <ListPageFilterMerchant<TransactionsFilters>
                              isDark={isDark}
                              filters={filters}
                              fieldKey="transactions_mid"
                          />
                      )
                  },
            'Type': {
                type: 'custom',
                node: <ListPageFilterTransactionsType isDark={isDark} filters={filters} />
            },
            'Scheme': {
                type: 'custom',
                node: <ListPageFilterTransactionsScheme isDark={isDark} filters={filters} />
            },
            'Payment method': {
                type: 'custom',
                node: <ListPageFilterPaymentMethod isDark={isDark} filters={filters} />
            },
            'Status code': {
                type: 'custom',
                node: <ListPageFilterTransactionsStatus isDark={isDark} filters={filters} />
            },
            'Last4': {
                type: 'custom',
                node: <ListPageFilterTransactionsLastFour isDark={isDark} filters={filters} />
            },
            ...(isDark
                ? {
                      'Separator 2': {
                          key: 'separator-2',
                          type: 'separator',
                          node: <ListPageFiltersSeparator isDark={isDark} />
                      }
                  }
                : {}),
            'Processed after': {
                type: 'custom',
                node: (
                    <ListPageFilterDate<TransactionsFilters>
                        isDark={isDark}
                        timeSupport
                        filters={filters}
                        filterKey="transactions_after"
                    />
                )
            },
            'Processed before': {
                type: 'custom',
                node: (
                    <ListPageFilterDate<TransactionsFilters>
                        isDark={isDark}
                        filters={filters}
                        timeSupport
                        filterKey="transactions_before"
                    />
                )
            }
        })

        return {
            sideFilters: (
                <SideFilters
                    items={items()}
                    shouldAlwaysDisplay={fullPage}
                    before={
                        fullPage ? (
                            <ListPageFullPageBackButton hotkeysScope="TransactionsList" fullPage={fullPage} />
                        ) : undefined
                    }
                />
            ),
            headerFilters: (
                <>
                    <Filters
                        showHideController={filters.showHideController}
                        filters={filters}
                        resultsCount={summaries.all.length}
                        entityName="transactions"
                        pagination={undefined}
                        key="allFilters"
                        shouldAlwaysHide={fullPage}
                        noClearButton={fullPage}
                        hotkeysScope="TransactionsList"
                        inline={inline}
                    >
                        <List
                            background="floating.background"
                            items={items(true)}
                            emphasizeLabels
                            template="auto"
                            switchToRowsAt={100000}
                            cellHorizontalTemplate="150px auto"
                        />
                    </Filters>
                    {filters.get.transactions_mid && !inline && !fullPage && (
                        <>
                            <Spacer width={10} />
                            <LinkButton
                                background="front.subtleAccent.background"
                                to={{
                                    pathname: `/settlements`,
                                    search: `?settlements_mid=${filters.get.transactions_mid}`
                                }}
                            >
                                <ButtonInset>See MID&apos;s settlements</ButtonInset>
                            </LinkButton>
                        </>
                    )}
                </>
            )
        }
    }, [filters, forMids, inline, summaries.all.length, fullPage])

    const rows = useMemo(() => {
        return summaries.all.map((id: string) => {
            const t = summaries.at[id]

            let link = `/transactions/${t.id}${location.search}`
            if (merchantId) {
                link = `/merchant/${merchantId}/transactions/${t.id}`
            }

            return {
                type: 'normal',
                key: t.id ? t.id : t.account.name,
                link,
                items: [
                    {
                        node: (
                            <Text mono color="front.text.subtlerI" noWrap>
                                <IDShortener id={t.id} />
                            </Text>
                        )
                    },
                    {
                        node: <TableBubble type="outlined">{t.account.merchantId}</TableBubble>
                    },
                    {
                        node: (
                            <Text bold oneLine>
                                {t.account.name}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <TableBubble type="outlined">
                                {moment(t.processedAt).format(DateFormats.completeStamp)}
                            </TableBubble>
                        )
                    },
                    {
                        node: <Text>{t.textOnStatement}</Text>
                    },
                    {
                        node: <Text noWrap>{uppercaseFirstLetter(t.type)}</Text>
                    },
                    {
                        node: (
                            <TableBubble>
                                <TransactionStatusCode status={t.status} />
                            </TableBubble>
                        )
                    },
                    {
                        node: (
                            <Flex align="center">
                                <IconPaymentMethod
                                    method={t.paymentMethod}
                                    card={t.card}
                                    color="front.text.subtlerI"
                                    size={17}
                                />
                                <Spacer width={5} />
                                <PaymentCardString card={t.card} />
                            </Flex>
                        )
                    },
                    {
                        node: (
                            <TableBubble type="important">
                                {numberWithCurrency(t.currency, `${t.amount}`, t.processedAt)} {t.currency}
                            </TableBubble>
                        )
                    },
                    {
                        node: (
                            <Text mono noWrap>
                                {renderFlags(t)}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text mono color="front.text.subtlerI" noWrap>
                                {t.reference}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text color="front.text.subtlerI" noWrap>
                                {t.card.segment ? uppercaseFirstLetterIfLowercase(t.card.segment) : '-'}
                            </Text>
                        )
                    }
                ]
            }
        }) as any
    }, [location, summaries.all, merchantId, summaries.at])

    const cols = useMemo(() => {
        return [
            {
                text: '#',
                key: 'id',
                loaderSize: {
                    min: 30,
                    max: 60
                }
            },
            {
                text: 'MID',
                loaderSize: {
                    min: 45,
                    max: 45
                }
            },
            {
                text: 'Merchant',
                loaderSize: {
                    min: 30,
                    max: 140
                }
            },
            {
                text: 'Processed',
                loaderSize: {
                    min: 90,
                    max: 120
                }
            },
            {
                text: 'Text on statement',
                loaderSize: {
                    min: 40,
                    max: 90
                }
            },
            {
                text: 'Type',
                loaderSize: {
                    min: 30,
                    max: 65
                }
            },
            {
                text: 'Status',
                loaderSize: {
                    min: 35,
                    max: 45
                }
            },
            {
                text: 'Card',
                loaderSize: {
                    min: 130,
                    max: 200
                }
            },
            {
                text: 'Amount',
                alignRight: true,
                loaderSize: {
                    min: 20,
                    max: 40
                }
            },
            {
                text: 'Flags',
                loaderSize: {
                    min: 30,
                    max: 60
                }
            },
            {
                text: 'Reference',
                loaderSize: {
                    min: 120,
                    max: 150
                }
            },
            {
                text: 'Card segment',
                loaderSize: {
                    min: 50,
                    max: 90
                }
            }
        ]
    }, [])

    const resultsFetcher = useMemo(() => {
        if (!rows.length) return null
        if (summaries.nextLink)
            return (
                <>
                    <Spacer width={20} />
                    <Flex align="center" column>
                        <SimpleButton
                            background="front.background"
                            onClick={() => {
                                dispatch(TransactionsDispatchLoadNextSummaries())
                            }}
                        >
                            <ButtonInset>
                                <ButtonLoadingCover
                                    background="front.background"
                                    shouldDisplayDirectly={summaries.loadingNextStatus === 'started'}
                                >
                                    Fetch more results
                                </ButtonLoadingCover>
                            </ButtonInset>
                        </SimpleButton>
                    </Flex>
                    <Spacer width={20} />
                </>
            )
        return null
    }, [rows.length, summaries.nextLink, summaries.loadingNextStatus, dispatch])

    const title = useMerchantListPageDynamicTitle(filters, forMids, 'transactions_mid')

    const rightSideMemo = useMemo(() => {
        if (!summaries.forQuery) return renderedFilters.headerFilters
        return (
            <Flex align="center">
                <Text color="back.text.subtlerI">Generate an export of up to 10,000 transactions</Text>
                <Spacer width={10} />
                <ExportButton resourceLink={summaries.forQuery} availableFormats={['csv', 'excel']} />
                {renderedFilters.headerFilters}
            </Flex>
        )
    }, [summaries, renderedFilters.headerFilters])

    if (forMids && !forMids.transactions_mid)
        return (
            <Flex column>
                <Spacer height={21} />
                <Text color="front.text.subtlerI">Merchant has no accounts</Text>
            </Flex>
        )

    return (
        <Flex align="stretch">
            {!(inline || fullPage) && <Sidebar hotkeysScope="TransactionsList" />}
            {!inline && renderedFilters.sideFilters}
            <PageContent marginLeft={0} noVerticalPadding noPadding={inline}>
                <PageHeader
                    noBottomBorder
                    title={title}
                    subtitle=""
                    // insetTop={inline}
                    rightSideMemo={rightSideMemo}
                />
                <Card overflowX>
                    <CardInset>
                        <Table
                            background="front.background"
                            cols={cols}
                            keepQuery
                            // eslint-disable-next-line max-len
                            columnLayout="minmax(min-content, 80px) min-content 1fr min-content 1fr min-content min-content min-content min-content minmax(min-content, 120px) min-content min-content"
                            showLastRowBorder
                            displayLoader={
                                summaries.loadingStatus !== 'done' && summaries.loadingStatus !== 'not-started'
                            }
                            loaderStyles={{ minHeight: '90vh' }}
                            minRowHeight={26}
                            loaderRows={20}
                            rows={rows}
                            overrideText={
                                forMids
                                    ? `None of this merchant's accounts have received any transactions`
                                    : 'No transactions found matching the filtered criterias.'
                            }
                        />
                    </CardInset>
                </Card>
                {resultsFetcher}
            </PageContent>
        </Flex>
    )
}

const renderFlags = (t: TransactionSummary) => {
    const flags: React.ReactNode[] = []
    if (t.threedSecure !== 'false' && t.threedSecure) {
        if (t.threedSecure == 'attempt') flags.push(<TransactionsFlagWithTooltip flag="3ds-attempt" />)
        else {
            if (t['3Dsecure']?.version)
                flags.push(<TransactionsFlagWithTooltip flag="3ds" version={t['3Dsecure'].version} />)
            // `3DS${}`
            else flags.push(<TransactionsFlagWithTooltip flag="3ds" version={'1.0.2'} />)
        }
    } else {
        flags.push(<TransactionsFlagWithTooltip flag="no-3ds" />)
    }
    if (t.settlement) flags.push(<TransactionsFlagWithTooltip flag="settled" />)
    else flags.push(<TransactionsFlagWithTooltip flag="empty" />)
    if (t.fraud) flags.push(<TransactionsFlagWithTooltip flag="fraud" />)
    else flags.push(<TransactionsFlagWithTooltip flag="empty" />)
    if (t.recurring) flags.push(<TransactionsFlagWithTooltip flag="recurring" />)
    else flags.push(<TransactionsFlagWithTooltip flag="empty" />)
    return <div>{flags}</div>
}
