import { Box, Card, Collapsible, Flex, Separator } from '@/design-system'
import React from 'react'
import { ResourceHistoryHeader } from './Application.ResourceHistoryHeader'
import { ResourceHistoryBody } from './Application.ResourceHistoryBody'
import { useTheme } from 'styled-components'

export const ResourceHistory: React.FC<any> = (props) => {
    const { title, fields, resource, prefixMemo, resourceKeys, applicationId, subsectionIndex, showInternalCard } = props

    const theme = useTheme()

    const textColor = theme.name === 'darkTheme' ? 'white' : 'danger'
    const labelColor = theme.name === 'darkTheme' ? 'neutral' : 'danger'
    const textShade = theme.name === 'darkTheme' ? undefined : '700'
    const separatorShade = theme.name === 'darkTheme' ? '100' : '700'
    const chevronColor = theme.name === 'darkTheme' ? 'white' : 'danger'

    return (
        <Collapsible>
            <Card size='lg' color='danger'>
                <Card.Body>
                    <Flex>
                        <Collapsible.Trigger>
                            <span onClick={()=> showInternalCard ? showInternalCard(subsectionIndex) : null}>
                                <Flex align="center" justify="between">
                                    <ResourceHistoryHeader 
                                        prefixMemo={prefixMemo} 
                                        title={title} 
                                        resource={resource}
                                        textColor={textColor}
                                        textShade={textShade}
                                    />
                                    <Collapsible.Chevron color={chevronColor} />
                                </Flex>
                            </span>
                        </Collapsible.Trigger>
                    </Flex>
                    <Collapsible.Content>
                        <Box my="3">
                            <Separator color="danger" shade={separatorShade} opacity="5" />
                        </Box>
                        <ResourceHistoryBody
                            applicationId={applicationId}
                            subsectionIndex={subsectionIndex}
                            fields={fields}
                            resource={resource}
                            resourceKeys={resourceKeys}
                            textColor={textColor}
                            textShade={textShade}
                            labelColor={labelColor}
                        />
                    </Collapsible.Content>
                </Card.Body>
            </Card>
        </Collapsible>
    )
}
