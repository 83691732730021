import { Box, Button, Card, Flex, Popover, Text } from '@/design-system'
import React from 'react'
import { useTheme } from 'styled-components'

interface ActionPopoverProps {
    children: React.ReactNode
}

const ActionPopover = (props: ActionPopoverProps) => {
    const { children } = props

    return <Popover>{children}</Popover>
}

interface ActionPopoverProps {
    children: React.ReactNode
}

const Trigger: React.FC<ActionPopoverProps> = (props) => {
    const { children } = props

    return <Popover.Trigger asChild>{children}</Popover.Trigger>
}

interface ContentProps {
    children: React.ReactNode
}

const Content: React.FC<ContentProps> = (props) => {
    const { children } = props

    return (
        <Popover.Content size="md" collisionPadding={25}>
            <Box m="2" width="auto" maxWidth="sm">
                <Flex direction="column" gap="6">
                    {children}
                </Flex>
            </Box>
        </Popover.Content>
    )
}

interface HeaderProps {
    children: React.ReactNode
}

const Header: React.FC<HeaderProps> = (props) => {
    const { children } = props

    return (
        <Flex direction="column" gap="3">
            {children}
        </Flex>
    )
}

interface TitleProps {
    children: React.ReactNode
}

const Title: React.FC<TitleProps> = (props) => {
    const { children } = props

    return (
        <Text weight="medium" lineHeight="none">
            {children}
        </Text>
    )
}

interface SubtitleProps {
    children: React.ReactNode
}

const Subtitle: React.FC<SubtitleProps> = (props) => {
    const { children } = props

    return <Text color="neutral">{children}</Text>
}

interface FieldsProps {
    children: React.ReactNode
}

const Fields: React.FC<FieldsProps> = (props) => {
    const { children } = props

    return <Flex>{children}</Flex>
}

interface WarningProps {
    children: string
}

const Warning: React.FC<WarningProps> = (props) => {
    const { children } = props

    const theme = useTheme()
    const isDark = theme.name === 'darkTheme'

    const textShade = isDark ? '400' : '700'

    return (
        <Card variant="surface" color="danger" size="sm">
            <Card.Body>
                <Text color="danger" shade={textShade}>
                    {children}
                </Text>
            </Card.Body>
        </Card>
    )
}

interface FooterProps {
    children: React.ReactNode
}

const Footer: React.FC<FooterProps> = (props) => {
    const { children } = props

    return (
        <Popover.Footer>
            <Flex gap="6">
                <Popover.Close asChild>
                    <Button color="neutral" width="full">
                        Cancel
                    </Button>
                </Popover.Close>
                {children}
            </Flex>
        </Popover.Footer>
    )
}

ActionPopover.Trigger = Trigger
ActionPopover.Content = Content
ActionPopover.Header = Header
ActionPopover.Title = Title
ActionPopover.Subtitle = Subtitle
ActionPopover.Fields = Fields
ActionPopover.Warning = Warning
ActionPopover.Footer = Footer

export { ActionPopover }
