import { useCallback, useEffect, useRef } from 'react'
import { falseOrUndefined } from '../../utils'

export const useFormField = (
    setFieldValue: (val: any, ref: any) => void,
    parseFieldValue: (ref: React.MutableRefObject<HTMLInputElement>) => string | undefined,
    formFieldRefReceiver?: (
        ref: React.MutableRefObject<HTMLInputElement>
    ) => void | React.MutableRefObject<HTMLInputElement>,
    noFormFieldBehaviour?: boolean,
    initialValue?: string | number
): {
    refInterceptor: null | ((ref: React.MutableRefObject<HTMLInputElement>) => void)
    onFieldUpdate: (value?: string) => void
    fieldRef: React.MutableRefObject<HTMLInputElement>
} => {
    const fieldRef = useRef<any>(null)

    // As soon as the form receives the ref
    // it starts listening for the following 2 events:
    // and response to them accordingly
    const onFieldUpdate = useCallback(
        (value) => {
            if (noFormFieldBehaviour) return
            if (!fieldRef.current) return

            const fieldUpdateEvent = new CustomEvent('fieldUpdate', {
                bubbles: true,
                detail: {
                    value
                }
            })

            fieldRef.current.dispatchEvent(fieldUpdateEvent)
        },
        [noFormFieldBehaviour, fieldRef]
    )

    useEffect(() => {
        if (noFormFieldBehaviour) return
        if (!fieldRef.current) return

        const fieldMountedEvent = new CustomEvent('fieldMounted', {
            bubbles: true,
            detail: {
                value: falseOrUndefined(parseFieldValue(fieldRef))
            }
        })

        fieldRef.current.dispatchEvent(fieldMountedEvent)
    }, [noFormFieldBehaviour, initialValue, parseFieldValue, fieldRef])

    // This function is called directly from the HTML element, as soon as the element reference
    // becomes available
    // we add the setField function to the reference and
    // and call formFieldRefReceiver(fieldRef.current) at the end, in order for the form
    // to receive the ref we just mutated above
    const refInterceptor = useCallback(
        (r: any) => {
            if (!r) return
            if (fieldRef.current === r) return

            fieldRef.current = r
            if (r && !noFormFieldBehaviour) {
                // eslint-disable-next-line no-param-reassign
                r.setFieldValue = (value: any, skipGlowing?: boolean) => {
                    setFieldValue(value, fieldRef.current)

                    if (skipGlowing) return

                    fieldRef.current.classList.add('glow')
                    setTimeout(() => {
                        fieldRef.current.classList.remove('glow')
                    }, 1000)
                }
            }

            if (typeof formFieldRefReceiver === 'function') formFieldRefReceiver(fieldRef.current)
            else if (formFieldRefReceiver) (formFieldRefReceiver as any).current = fieldRef.current
        },
        [noFormFieldBehaviour, setFieldValue, formFieldRefReceiver, fieldRef]
    )

    return {
        refInterceptor: noFormFieldBehaviour ? null : refInterceptor,
        onFieldUpdate,
        fieldRef
    }
}
