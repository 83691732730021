import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useRowCheckmarkSelector } from '../components/tables/useRowCheckmarkSelector'
import { RootState } from '@/store'
import { SettlementsReservesActions } from '../store/settlementsReserves/actions'
import { CriticalFlowSettlementsTableRow } from './CriticalFlowSettlementsTableRow'

export const CriticalFlowSettlementsTable: React.FC<{ mid: string }> = ({ mid }) => {
    const dispatch = useDispatch()
    const phase = useSelector((state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.phase)
    const remoteReserves = useSelector((state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.reserves)
    const cachedReserves = useSelector(
        (state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.cachedReserves
    )
    const executionStarted = useSelector(
        (state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.phase === 'processing'
    )
    const sort = useSelector((state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.sort)
    const onSelection = useCallback(
        (id, isSelected) => {
            if (isSelected === 'false') {
                dispatch(SettlementsReservesActions.SELECT_SETTLEMENTS(mid, [id]))
            } else {
                dispatch(SettlementsReservesActions.DESELECT_SETTLEMENTS(mid, [id]))
            }
        },
        [dispatch, mid]
    )
    const callbackRef = useRowCheckmarkSelector(onSelection, executionStarted)

    const reserves = useMemo(() => {
        if (phase !== 'selection' && cachedReserves) return cachedReserves
        return remoteReserves
    }, [remoteReserves, cachedReserves, phase])

    const handleSort = useCallback(
        (e) => {
            const sortType = e.target?.getAttribute('data-by')
            dispatch(SettlementsReservesActions.SET_RESERVES_SORT(mid, sortType))
        },
        [dispatch, mid]
    )

    const rowTypesCache = {}
    return (
        <Grid>
            <HeaderRow>
                <Header></Header>
                <Header
                    span={3}
                    onClick={handleSort}
                    sortable
                    data-by="date"
                    sorted={sort?.type === 'date' && sort?.dir}
                >
                    Period
                </Header>
                <Header
                    onClick={handleSort}
                    sortable
                    right
                    data-by="settled_amount"
                    sorted={sort?.type === 'settled_amount' && sort?.dir}
                >
                    Settlement
                </Header>
                <Header onClick={handleSort} sortable data-by="type" sorted={sort?.type === 'type' && sort?.dir}>
                    Type
                </Header>
                <Header
                    onClick={handleSort}
                    sortable
                    right
                    data-by="paidOn"
                    sorted={sort?.type === 'paidOn' && sort?.dir}
                >
                    Release
                </Header>
                <Header
                    right
                    sortable
                    onClick={handleSort}
                    data-by="amount"
                    sorted={sort?.type === 'amount' && sort?.dir}
                >
                    Amount
                </Header>
                <Header right onClick={handleSort}>
                    Actions
                </Header>
            </HeaderRow>
            {reserves?.all
                .filter((rId) => reserves?.at[rId])
                .map((rId: string, index: number) => {
                    return (
                        <CriticalFlowSettlementsTableRow
                            key={rId}
                            id={rId}
                            r={reserves.at[rId]}
                            ref={callbackRef}
                            mid={mid}
                        />
                    )
                })}
        </Grid>
    )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: 60px min-content min-content 1.5fr 1fr min-content 1.5fr 1fr 1fr;
`

const HeaderRow = styled.div`
    display: contents;
`
const Header = styled.div<{ sortable?: boolean; selectable?: boolean; span?: number; right?: boolean; sorted?: any }>`
    padding: 0 10px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: ${(p) => p.theme['front.text.subtlerI']};
    margin-bottom: 5px;
    user-select: none;

    ${(p) =>
        p.sortable &&
        css`
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            &:active {
                opacity: 0.6;
            }
        `}

    ${(p) =>
        p.span &&
        css`
            grid-column: span ${p.span};
        `}
    ${(p) =>
        p.right &&
        css`
            justify-content: flex-end;
            text-align: right;
        `}
    
    &:first-child {
        border-radius: 10px 0 0 10px;
        padding-left: 20px;
    }

    &:last-child {
        border-radius: 0 10px 10px 0;
        padding-right: 20px;
    }

    ${(p) =>
        p.sorted &&
        css`
            font-weight: 600;
            color: ${(p) => p.theme['front.text']};
        `}
    &:after {
        border-bottom: 1px solid currentColor;
        border-right: 1px solid currentColor;
        height: 3px;
        width: 3px;
        transform: rotateZ(45deg);
        content: '';
        display: inline-block;
        position: relative;
        margin-left: 4px;
        top: -3px;

        ${(p) =>
            !p.sorted &&
            css`
                display: none;
            `}

        ${(p) =>
            p.sorted == 'asc' &&
            css`
                transform: rotateZ(-135deg);
                top: 0px;
            `}
    }
`
