import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export const useMerchantApplicationStateChange = (applicationId: string) => {
    const stateChanges = useSelector(
        (state: RootState) => state.applicationResources?.history?.forApplication?.[applicationId]?.state
    )
    const allChanges = useMemo(() => {
        return stateChanges?.fields?.[0]?.[''] || []
    }, [stateChanges])

    return allChanges
}
