import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useOnBlurOutside } from '../../hooks/general/useOnBlurOutside'
import { useOnClickOutside } from '../../hooks/general/useOnClickOutside'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { Color } from '../../styles/colors'
import { MODAL_ID } from '../modals/modalIds'
import { Dropdown } from './dropdown'
import { DropdownMultiSelectableList } from './dropdownMultiSelectableList'
import { TextInput } from './textInput'

export function TextInputSelectMultiple({
    placeholder,
    items,
    selectionLabel,
    textForItem,
    nodeForItem,
    overBackground,
    preSelectedItems,
    dropdownId,
    onSelect,
    onBlankEnter,
    isSeamless,
    isSimple,
    cy,
    isHigher
}: {
    placeholder: string
    items: string[]
    textForItem: (item?: string) => string
    nodeForItem?: (item?: string) => React.ReactNode
    overBackground: Color
    dropdownId: MODAL_ID
    onSelect: (items: string[]) => void
    preSelectedItems?: string[]
    selectionLabel?: string
    onBlankEnter?: () => void
    isHigher?: boolean
    isSimple?: boolean
    isSeamless?: boolean
    cy?: string
}): ReactElement {
    const { blurBoundaryClassName, isBlurOutsideBounds } = useOnBlurOutside()
    const [filteringValue, setFilteringValue] = useState<string | undefined>(undefined)
    const [anchor, setAnchor] = useRefTaker()
    const [shouldShowDropdown, setShouldShowDropdown] = useState(false)
    const [outsideDiv, setOutsideDiv] = useRefTaker()
    const onClickOutside = useCallback(() => {
        setShouldShowDropdown(false)
    }, [setShouldShowDropdown])
    useOnClickOutside(outsideDiv, onClickOutside)

    const selectedItems = useMemo(() => {
        if (typeof preSelectedItems === 'string') return [preSelectedItems]
        return preSelectedItems
    }, [preSelectedItems])

    const onChanged = useCallback(
        (e, value) => {
            setFilteringValue(value)
        },
        [setFilteringValue]
    )

    const onFieldFocus = useCallback(() => {
        setShouldShowDropdown(true)
    }, [setShouldShowDropdown])

    const onFieldBlur = useCallback(
        (e: any) => {
            if (isBlurOutsideBounds(e)) {
                setShouldShowDropdown(false)
            } else {
                anchor?.focus()
            }
        },
        [anchor, isBlurOutsideBounds]
    )

    const onSelectionMade = useCallback(
        (items) => {
            onSelect(items)
        },
        [onSelect]
    )

    const placeholderText = useMemo(() => {
        if (!selectionLabel) return placeholder
        const itemCount = selectedItems?.length || 0

        if (itemCount === 0) return placeholder

        if (!selectionLabel.includes('/')) return `${selectedItems?.length} ${selectionLabel.split('/')[0]} selected`

        if (itemCount === 1) return `${selectedItems?.length} ${selectionLabel.split('/')[0]} selected`

        return `${selectedItems?.length} ${selectionLabel.split('/')[1]} selected`
    }, [selectedItems, placeholder, selectionLabel])

    return (
        <Holder className={blurBoundaryClassName} ref={setOutsideDiv}>
            <TextInputHolder>
                <TextInput
                    cy={cy}
                    overBackground={overBackground}
                    ref={setAnchor}
                    placeholder={placeholderText}
                    onChange={onChanged}
                    onFocus={onFieldFocus}
                    hideInputIndicator={isSeamless}
                    onBlur={onFieldBlur}
                    isSimple={isSimple}
                    isSeamless={isSeamless}
                />
            </TextInputHolder>
            <Dropdown anchor={anchor} show={shouldShowDropdown} isHigher={isHigher} cy={`${cy}-dropdown`}>
                <DropdownMultiSelectableList
                    items={items}
                    onBlankEnter={onBlankEnter}
                    selectedItems={selectedItems}
                    dropdownId={dropdownId}
                    textForItem={textForItem}
                    nodeForItem={nodeForItem}
                    filteringValue={filteringValue}
                    onSelect={onSelectionMade}
                />
            </Dropdown>
        </Holder>
    )
}

const TextInputHolder = styled.div`
    display: flex;
    align-self: stretch;
    flex-grow: 1;
    align-items: stretch;
    position: relative;
`

const Holder = styled.div`
    display: contents;
    position: relative;
`
