import { NavigationConfig, TabNavigation } from '@/components/general/tabNavigation'
import React, { useMemo } from 'react'
import { CenteredScreen } from '../../../components/layout/centeredScreen'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { useModalStackSync } from '../../../hooks/general/useModalStackSync'
import { useResetScrollOnMount } from '../../../hooks/pages/useResetScrollOnMount'
import { Outlet, useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantMore: React.FC = () => {
    const params = useParams() as Params

    const { id } = params || {}
    const name = useMerchantName(id, 'More')
    useModalStackSync(name, 'Merchant.MorePage', 0)
    useResetScrollOnMount()

    const tabNavigationConfig = useMemo((): NavigationConfig => {
        return [
            {
                title: 'Risk',
                to: 'risk-tab'
            },
            {
                title: 'Ongoing due diligence',
                to: 'ongoing-due-diligence'
            },
            {
                title: 'Subscriptions',
                to: 'subscriptions-tab'
            }
        ]
    }, [])

    return (
        <CenteredScreen maxWidth={900}>
            <TabNavigation baseUrl="" config={tabNavigationConfig} />
            <Outlet />
        </CenteredScreen>
    )
}
