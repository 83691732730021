import { SimpleButton } from '@/components/buttons/simpleButton'
import React from 'react'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { useReportParams } from '../../../../hooks'
import { getApplicationUrl } from '../../../../helpers'
import { useFinalizeActions } from './use-finalize-actions'
import { useNavigate } from 'react-router-dom'

export const FinalizeAction: React.FC = () => {
    const navigate = useNavigate()
    const { applicationId } = useReportParams()
    const { isLoading, finalize } = useFinalizeActions()

    const watcher = isLoading ? 'started' : undefined

    const handleFinalizeReport = async () => {
        await finalize()

        const reportUrl = getApplicationUrl(applicationId)
        navigate(reportUrl)
    }
    return (
        <SimpleButton background="front.accent.color" onClick={handleFinalizeReport}>
            <ButtonLoadingCover background="front.background" watcher={watcher}>
                <ButtonInset>Finalize</ButtonInset>
            </ButtonLoadingCover>
        </SimpleButton>
    )
}
