import { MerchantDataAccount } from '@/services/ongoing-due-diligence'
import React from 'react'
import { Section } from '../../../common/section'

const order = [
    { key: 'currency' },
    { key: 'merchant_id', title: 'MID' },
    { key: 'mcc', title: 'MCC' },
    { key: 'first_contract_signed' }
]

interface AccountDataItemsProps {
    accounts: MerchantDataAccount[]
}

export const AccountDataItems: React.FC<AccountDataItemsProps> = (props) => {
    const { accounts } = props

    return (
        <>
            {accounts.map((account, i) => {
                const last = i === accounts.length - 1

                return (
                    <React.Fragment key={account.id}>
                        <Section.IndentetContent>
                            <Section.Title color="normal">{`${i + 1}. Account`}</Section.Title>
                        </Section.IndentetContent>
                        <Section.Spacer size="s" />
                        <Section.DataItems order={order} data={account} />
                        <Section.ExpandableDataItem
                            title="Transaction rules"
                            value={account.transaction_rules}
                            fontStyle="mono"
                        />
                        {!last && <Section.Spacer size="l" />}
                    </React.Fragment>
                )
            })}
        </>
    )
}
