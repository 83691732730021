import { Color } from '../../styles/colors'
import { RiskLevels, RiskLevel } from './types'

export const findLevelKey = (risk: string, riskLevels: RiskLevels): string | undefined => {
    const riskKey = riskLevels.all.find((level) => {
        const riskLevel = riskLevels.at[level]

        return riskLevel.fields.key === risk
    })

    return riskKey
}

export const findLevel = (risk: string, riskLevels: RiskLevels): RiskLevel => {
    return riskLevels.at[findLevelKey(risk, riskLevels) || 0]
}

export const getColor = (risk: string): Color => {
    if (risk === 'High risk') {
        return 'front.danger.color'
    }
    if (risk === 'Medium risk') {
        return 'front.accent.color'
    }
    return 'back.background.strongerIII'
}

export const getRiskLevel = (points: number, riskLevels: RiskLevels): string => {
    if (points >= findLevel('high_risk', riskLevels)?.fields.threshholdDescription) {
        return 'high_risk'
    }
    if (points <= findLevel('low_risk', riskLevels)?.fields.threshholdDescription) {
        return 'low_risk'
    }
    return 'medium_risk'
}

export const getRiskLevelLocal = (points: number): string => {
    if (points >= 2) return "High risk"
    if (points <= -2) return "Low risk"
    return "Medium risk"
}
