import React, { useEffect } from 'react'
import { CardHeaderSeparator } from './card'

interface ExpandableCardContentProps {
    children: React.ReactNode
    isOpen: boolean
    onExpand?: () => void
}

export const ExpandableCardContent: React.FC<ExpandableCardContentProps> = (props) => {
    const { children, isOpen, onExpand } = props


    useEffect(() => {
        if (!isOpen) {
            return
        }

        if (onExpand) {
            onExpand()
        }
    }, [onExpand, isOpen])

    if (!isOpen) {
        return null
    }

    return (
        <>
            <CardHeaderSeparator />
            {children}
        </>
    )
}
