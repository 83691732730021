interface MerchantAccountWarning {
    id: string
    type: 'merchant-contract-no-signature'
    title?: string
    relatedToMID?: string
}

interface TransactionsWarning {
    id: string
    type: 'merchant-account-no-transaction'
    title?: string
    relatedToMID?: string
}
interface GeneralWarning {
    id: string
    type: 'general'
    title?: string
    subtitle?: string
}

export type MerchantWarning = TransactionsWarning | MerchantAccountWarning | GeneralWarning

export const MerchantAccountWarningChecks = ['transactionInconsistencies', 'staleContract'] as const
export type MerchantAccountWarningCheckTypes = typeof MerchantAccountWarningChecks[number]

type HaveWarningsBeenGenerated = {
    [key in MerchantAccountWarningCheckTypes | 'all']: boolean
}

export interface ApplicationInternalsWarningsState {
    forApplication: {
        [key: string]: {
            isReady: HaveWarningsBeenGenerated
            each: MerchantWarning[]
        }
    }
}

export const InitialApplicationInternalsWarningsState: ApplicationInternalsWarningsState = {
    forApplication: {}
}
