import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ButtonInset } from '../../components/buttons/buttonInset'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { Separator } from '../../components/layout/separator'
import { Spacer } from '../../components/layout/spacer'
import { TextInputSelect } from '../../components/forms/textInputSelect'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { ModalPage } from '../../components/layout/modalPage'
import { ModalPageInset } from '../../components/layout/modalPageInset'
import { ModalHeader } from '../../components/modals/modalHeader'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { ApplicationInternalsReferralActions } from '../../store/applicationInternals/referral/actions'
import { RootState } from '@/store'
import { useMerchantName } from '../../hooks/general/useMerchantName'
import { useGateways } from '../../hooks/general/useGateways'
import { useGoBackHook } from '../../hooks/general/useGoBackHook'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantReferralModal: React.FC = () => {
    const { id } = useParams() as Params
    const dispatch = useDispatch()
    const [selected, setSelected] = useState<string | undefined>()
    const [inputRef, setInputRef] = useRefTaker()
    const shouldFocus = useRef(true)
    const gateways = useGateways()
    const referralPartners = useSelector((state: RootState) => state.applicationInternals.referral?.[id])
    const [watcher, watcherId] = useNamedWatcher()
    const name = useMerchantName(id, 'Add referral')

    useEffect(() => {
        if (referralPartners?.loadingStatus !== 'started' && referralPartners?.loadingStatus !== 'done') {
            dispatch(ApplicationInternalsReferralActions.FETCH(id))
        }
    }, [dispatch, id, referralPartners])

    const selectablePartners = useMemo(() => {
        const partners = gateways.all
            .filter((partnerId) => gateways.at[partnerId].referral)
            .filter((partnerId) => !(referralPartners.activeReferrals || []).map((p) => p.id).includes(partnerId))
            .reduce((acc, partnerId) => {
                acc[partnerId] = { ...gateways.at[partnerId] }
                return acc
            }, {} as any)

        return {
            at: partners,
            all: Object.keys(partners)
        }
    }, [gateways, referralPartners])

    const onSelect = useCallback(
        (partnerId: string) => {
            setSelected(partnerId)
        },
        [setSelected]
    )

    const textForItem = useCallback(
        (partnerId) => {
            return selectablePartners?.at?.[partnerId]?.name
        },
        [selectablePartners]
    )

    const handleAdd = useCallback(
        (item, watcherId) => {
            dispatch(ApplicationInternalsReferralActions.ADD(id, item, watcherId))
        },
        [dispatch, id]
    )

    useEffect(() => {
        if (inputRef && shouldFocus.current) {
            setTimeout(() => {
                shouldFocus.current = false
                inputRef?.focus()
            }, 16)
        }
    }, [inputRef])

    const { goBack } = useGoBackHook(`/merchant/${id}/summary`, undefined, false, true)
    useEffect(() => {
        if (watcher === 'success') goBack()
    }, [watcher, dispatch, id, goBack])

    return (
        <ModalPage title={name} pageId="Merchant.SettingsPage.AddReferral" backTo={`/merchants/${id}/summary`}>
            <ModalHeader
                title="Add referral"
                pageId="Merchant.SettingsPage.AddReferral"
                backTo={`/merchant/${id}/summary`}
                keepBackTo
            />
            <ModalPageInset>
                <PageWrapper>
                    <Flex justify="center" align="center" column>
                        <Card higher title="Add a new referral partner">
                            <CardInset>
                                <Holder>
                                    <TextInputSelect
                                        selected={selected}
                                        key="dropdown"
                                        ref={setInputRef}
                                        isSimple
                                        textForItem={textForItem}
                                        dropdownId="Merchant.SettingsPage.AddReferral.SelectReferral"
                                        onSelect={onSelect}
                                        placeholder="Select a new referral partner"
                                        items={selectablePartners.all}
                                        lazyLoaded
                                        overBackground="front.background"
                                    />
                                    <Spacer width={15} />
                                    <Separator />
                                    <Spacer width={20} />
                                    <Flex justify="flex-end">
                                        <SimpleButton background="front.subtleAccent.background" onClick={goBack}>
                                            <ButtonInset>Cancel</ButtonInset>
                                        </SimpleButton>
                                        <Spacer width={10} />
                                        <WatcherButton
                                            background="front.accent.color"
                                            isDisabled={!selected}
                                            predefinedWatcher={watcherId}
                                            onClick={(e, watcherId) => {
                                                handleAdd(selected, watcherId)
                                            }}
                                        >
                                            <ButtonInset>Add partner</ButtonInset>
                                        </WatcherButton>
                                    </Flex>
                                </Holder>
                            </CardInset>
                        </Card>
                        <Spacer width={30} />
                    </Flex>
                </PageWrapper>
            </ModalPageInset>
        </ModalPage>
    )
}
const Holder = styled.div`
    min-width: 300px;
`
