import { useDispatch } from 'react-redux'
import { WatcherButton } from '../../../../../../../components/buttons/watcherButton'
import { TasksCompanyChangesActions } from '../../../../../../../store/tasksCompanyChanges/actions'

interface MuteButtonProps {
    taskId: string
    applicationId: string
    applicationData: any
    isMuted: boolean
    after: string
}

export const MuteButton: React.FC<MuteButtonProps> = (props) => {
    const { taskId, applicationId, applicationData, isMuted, after } = props

    const dispatch = useDispatch()

    const handleOnMute = (_, watcherId) => {
        dispatch(
            TasksCompanyChangesActions.CHANGE_MUTED_STATE(applicationId, taskId, watcherId, {
                company: {
                    name: isMuted
                        ? applicationData?.muted?.company?.name.filter((n) => n != after)
                        : [...(applicationData?.muted?.company?.name || []), after]
                }
            })
        )
    }

    return <WatcherButton onClick={handleOnMute}>{isMuted ? 'Unmute' : 'Mute'}</WatcherButton>
}
