import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TasksDispatchLoadTags, TasksDispatchLoadTask, TasksDispatchUnstoreTask } from '../../store/tasks/actions'
import { Outlet, useParams } from 'react-router-dom'

type Params = { id: string; merchantId: string }

export const AlertRouter: React.FC = () => {
    const params = useParams() as Params

    const dispatch = useDispatch()
    const taskId = params.id

    useEffect(() => {
        dispatch(TasksDispatchLoadTask(taskId))
        dispatch(TasksDispatchLoadTags())
        return () => {
            dispatch(TasksDispatchUnstoreTask(taskId))
        }
    }, [dispatch, taskId])

    return <Outlet />
}
