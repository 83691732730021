import React from 'react'
import { ExternalLink } from '../../../../../../../components/buttons/externalLink'
import { IconExternalLink } from '../../../../../../../components/icons/iconExternalLink'
import { Flex } from '../../../../../../../components/layout/flex'
import { Offset } from '../../../../../../../components/layout/offset'

interface MerchantApplicationLinkProps {
    applicationId?: string
}

export const MerchantApplicationLink: React.FC<MerchantApplicationLinkProps> = (props) => {
    const { applicationId } = props

    if (!applicationId) {
        return null
    }

    return (
        <ExternalLink target="blank" arrowLess url={`/merchant/${applicationId}/application`}>
            <Flex align="center">
                <Offset left={-5} top={3}>
                    <IconExternalLink size={14} />
                </Offset>
                Open Application
            </Flex>
        </ExternalLink>
    )
}
