import { SectionDocumentation } from '@/services/ongoing-due-diligence'

import React from 'react'
import { Section } from '../../../common/section'
import { CompanyRiskClasification } from './company-risk-clasification'
import { CompanyTags } from './company-tags'
import { CompanyBusinessModel } from './company-business-model'
import { CompanyMetadata } from './company-metadata'
import { CompanyMcc } from './company-mcc'
import { CompanyMid } from './company-mid'
import { useReportSection } from '../../../provider/report-provider/report-provider'
import { CompanyGeneralInfo } from './company-general-info'

export const Company: React.FC = () => {
    const section = useReportSection<SectionDocumentation>('documentation')

    return (
        <Section>
            <Section.Content>
                <Section.Field name="company" data={section.company} />
                <Section.Spacer size="s" />
                <CompanyMid />
                <Section.Spacer size="s" />
                <CompanyGeneralInfo />
                <CompanyRiskClasification />
                <Section.Spacer size="s" />
                <CompanyTags />
                <Section.Spacer size="s" />
                <CompanyBusinessModel />
                <Section.Spacer size="s" />
                <CompanyMcc />
            </Section.Content>
            <CompanyMetadata />
        </Section>
    )
}
