import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'
import { CompanyLinks } from './company-links'
import { CompanyReports } from './company-reports'

export const CompanyMetadata: React.FC = () => {
    const merchantData = useReportMerchantData()

    return (
        <Section.Metadata>
            <CompanyReports />
            <Section.Spacer size="s" />
            <CompanyLinks merchantData={merchantData} />
        </Section.Metadata>
    )
}
