/* eslint-disable no-param-reassign */
import { createReducer } from 'deox'

import { MerchantTimelineActions } from './actions'
import { InitialMerchantTimelineState } from './types'

export const MerchantTimelineReducer = createReducer(InitialMerchantTimelineState, (handleAction) => [
    handleAction(MerchantTimelineActions.STORE, (state, { payload: p }) => {
        return {
            ...state,
            [p.merchantId]: {
                ...p.timeline,
                loadingStatus: 'started' as const
            }
        }
    }),
    handleAction(MerchantTimelineActions.MARK_AS_COMPLETE, (state, { payload: p }) => {
        return {
            ...state,
            [p.merchantId]: {
                ...state[p.merchantId],
                loadingStatus: 'done' as const
            }
        }
    }),
    handleAction(MerchantTimelineActions.CLEAR, (state, { payload: p }) => {
        const newState = JSON.parse(JSON.stringify(state))
        if (newState[p.merchantId]) delete newState[p.merchantId]
        return newState
    })
])
