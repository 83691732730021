import React, { useMemo } from 'react'

import { MarkdownHolder } from '../../../components/general/markdownHolder'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { ApplicationDeliveryDelay } from '../../../store/applications/types'
import { MerchantApplicationFieldValueCompanyForm } from './Application.FieldValueCompanyForm'
import { MerchantApplicationFieldValueCountry } from './Application.FieldValueCountry'
import { MerchantApplicationFieldValueDeliveryDelay } from './Application.FieldValueDeliveryDelay'
import { MerchantApplicationFieldValueGateway } from './Application.FieldValueGateway'
import { MerchantApplicationFieldValueNumber } from './Application.FieldValueNumber'
// eslint-disable-next-line max-len
import { MerchantApplicationFieldValueOwnershipStructure } from './Application.FieldValueOwnershipStructure'
import { MerchantApplicationFieldValueQuestion } from './Application.FieldValueQuestion'
import { MerchantApplicationFieldValueRole } from './Application.FieldValueRole'
import { ConvertIndexPathToFieldDetails } from './Application.Structure'

export const MerchantApplicationEditsModalValue: React.FC<{
    applicationId: string
    indexPath: MerchantApplicationResourceIndexPath
    value: string
}> = ({ indexPath, value }) => {
    const fieldDetails = useMemo(() => ConvertIndexPathToFieldDetails(indexPath), [indexPath])

    if (fieldDetails.field.type === 'number') return <MerchantApplicationFieldValueNumber number={value} />
    if (indexPath.fieldKey === 'businessModel.deliveryDelay' && indexPath.resourceKey === 'businessModel')
        return <MerchantApplicationFieldValueDeliveryDelay value={value as ApplicationDeliveryDelay} />
    if (indexPath.fieldKey === 'form' && indexPath.resourceKey === 'company')
        return <span>{MerchantApplicationFieldValueCompanyForm(value)}</span>
    if (indexPath.fieldKey === 'role') return <MerchantApplicationFieldValueRole value={value} />

    if (indexPath.fieldKey === 'ownershipStructure')
        return <MerchantApplicationFieldValueOwnershipStructure value={value} />

    if (fieldDetails.field.type === 'question') {
        return <MerchantApplicationFieldValueQuestion value={value} />
    }

    if (indexPath.fieldKey === 'country') return <MerchantApplicationFieldValueCountry value={value} />

    if (indexPath.fieldKey === 'ownershipStructure')
        return <MerchantApplicationFieldValueOwnershipStructure value={value} />

    if (indexPath.resourceKey === 'gateway' && indexPath.fieldKey === 'gateways.0.id') {
        return <MerchantApplicationFieldValueGateway gatewayId={value} />
    }
    return <MarkdownHolder>{value}</MarkdownHolder>
}
