import { useNavigate } from 'react-router-dom'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { Flex } from '@/components/layout/flex'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { useCreateActions } from './hooks'
import { getClassificationUrl } from '../../../helpers/get-classification-url'
import { Spacer } from '@/components/layout/spacer'
import { useClassificationParams } from '../../../hooks/use-classification-params'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'

export const CreateClassificationButton: React.FC = () => {
    const navigate = useNavigate()
    const { applicationId } = useClassificationParams()
    const { isLoading, create } = useCreateActions()

    const watcher = isLoading ? 'started' : undefined

    const handleCreateClick = async () => {
        const { classification } = await create()

        const classificationUrl = getClassificationUrl(applicationId, classification.id)

        navigate(classificationUrl)
    }

    return (
        <Flex grow justify="flex-end">
            <div>
                <SimpleButton
                    background="front.accent.color"
                    onClick={handleCreateClick}
                    cy="risk-button-new-classification"
                >
                    <ButtonLoadingCover background="front.accent.color" watcher={watcher}>
                        <ButtonInset>New classification</ButtonInset>
                    </ButtonLoadingCover>
                </SimpleButton>
                <Spacer height={6} />
            </div>
        </Flex>
    )
}
