import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RootState } from '@/store'

const reducerPath = 'ongoing-due-diligence-api'
const tagTypes = ['reports', 'report', 'charts']

export const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_ODD_ROOT,
    prepareHeaders: (headers, { getState }) => {
        const { auth } = getState() as RootState
        const { token } = auth

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }

        return headers
    }
})

export const api = createApi({
    reducerPath,
    baseQuery: baseQuery,
    tagTypes,
    endpoints: () => ({})
})
