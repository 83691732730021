import { ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { BIG_SMALL_SCREEN_SWAP } from '../../pages/Merchant/Accounts/utils'
import { InnerPageBackButton } from '../buttons/innerPageBackButton'

export const CenteredScreenHeader: React.FC<{
    title?: string | ReactNode
    left?: ReactNode
    right?: ReactNode
    backTo?: string
    backToSuffix?: string
    offset?: number
    sticky?: boolean
}> = ({ title, left, sticky, right, offset, backTo, backToSuffix }) => {
    const backButton = useMemo(() => {
        if (backTo && backToSuffix) return <InnerPageBackButton backTo={backTo} suffix={backToSuffix} />
        return null
    }, [backTo, backToSuffix])

    return (
        <Header offset={offset} sticky={sticky}>
            {left}
            {backButton}
            <Title>{title}</Title>
            {right}
        </Header>
    )
}

const Header = styled.div<{ offset?: number; sticky?: boolean }>`
    border-bottom: 1px solid ${(p) => p.theme['back.border']};
    width: calc(100% - ${(p) => (p.offset ? p.offset * 2 : 0)}px);
    display: flex;
    min-height: 60px;
    margin-bottom: 20px;
    margin-top: -30px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: ${(p) => p.theme['front.text']};

    @media (max-width: ${BIG_SMALL_SCREEN_SWAP}px) {
        margin-top: -0px;
    }

    ${(p) => css`
        margin-left: ${p.offset}px;
        margin-right: ${p.offset}px;
    `}

    ${(p) =>
        p.sticky &&
        css`
            background-color: ${(p) => p.theme['back.background']};
            position: sticky;
            top: 50px;
            z-index: 10000;
        `}
`

const Title = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
`
