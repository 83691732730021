import { startCase } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ConvertURLPartsToFieldDetails, IMerchantApplicationFieldDetails } from './Application.Structure'
import {
    IMerchantApplicationSectionItemDetails,
    IMerchantApplicationSubsectionStructure,
    TMerchantApplicationResourceKey
} from './Application.StructureTypes'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { camelCaseWithDots } from '../../../store/applications/utils'
import { RootState } from '@/store'

export interface IFieldEditsSelectorPayload {
    fieldDetails: IMerchantApplicationFieldDetails
    editsCount: number
    indexPath: MerchantApplicationResourceIndexPath
}

export const useMerchantApplicationNameSelector = (urlParams: any) => {
    const {
        id,
        sectionLabel,
        fieldLabelOrID,
        fieldLabel
    }: {
        id: string
        sectionLabel: TMerchantApplicationResourceKey
        fieldLabelOrID: string
        fieldLabel: string
    } = urlParams

    const fieldDetails = useMemo(() => {
        return ConvertURLPartsToFieldDetails(sectionLabel, fieldLabelOrID, fieldLabel)
    }, [sectionLabel, fieldLabelOrID, fieldLabel])

    const subsectionIndex = useSelector((state: RootState) => {
        if (!fieldDetails) return null
        const applicationResources = state.applicationResources.data.forApplication[id]

        if (!applicationResources) return null

        const section = fieldDetails.section as IMerchantApplicationSubsectionStructure
        const subsections = applicationResources[section.resource]?.fields
        let subsectionIndex = -1

        if (!subsections) return null

        subsections.forEach((subsection, i) => {
            if (subsection.id === fieldLabelOrID) subsectionIndex = i
        })
        if (subsectionIndex == -1) return null
        return subsectionIndex
    })

    const fieldName = useMemo(() => {
        if (fieldDetails?.section?.type === 'static')
            return {
                sectionName: startCase(sectionLabel),
                fieldName: startCase(fieldLabelOrID)
            }

        if (subsectionIndex === undefined || subsectionIndex === null) return null
        return {
            sectionName: `${startCase(sectionLabel)} ${subsectionIndex + 1}`,
            fieldName: startCase(fieldLabel)
        }
    }, [fieldDetails, fieldLabel, fieldLabelOrID, sectionLabel, subsectionIndex])

    const indexPath = useMemo(() => {
        if (!fieldDetails) return undefined
        if (fieldDetails.section.type === 'static')
            return {
                resourceKey: (fieldDetails.field as IMerchantApplicationSectionItemDetails).resource,
                subsectionIndex: 0,
                fieldKey: camelCaseWithDots(fieldDetails.field.id.mapi)
            }
        return {
            resourceKey: (fieldDetails.section as IMerchantApplicationSubsectionStructure).resource,
            subsectionIndex: subsectionIndex || 0,
            fieldKey: camelCaseWithDots(fieldDetails.field.id.mapi)
        }
    }, [fieldDetails, subsectionIndex])

    return {
        fieldName,
        indexPath
    }
}
