import { WatcherID } from '../watcher/types'
import { DashboardStats } from './types'

export const DASHBOARD_LOAD_STATS = 'DASHBOARD_LOAD_STATS'
export const DASHBOARD_STORE_STATS = 'DASHBOARD_STORE_STATS'
export const DASHBOARD_CLEAR_STATS = 'DASHBOARD_CLEAR_STATS'

//--
export interface DashboardActionLoadStats {
    type: typeof DASHBOARD_LOAD_STATS
    watcher: WatcherID
}
export interface DashboardActionStoreStats {
    type: typeof DASHBOARD_STORE_STATS
    payload: DashboardStats[]
    watcher: WatcherID
}
export interface DashboardActionClearStats {
    type: typeof DASHBOARD_CLEAR_STATS
}

//--
export const DashboardDispatchLoadStats = (
    watcher: WatcherID
): DashboardActionLoadStats => {
    return { type: DASHBOARD_LOAD_STATS, watcher }
}
export const DashboardDispatchStoreStats = (
    watcher: WatcherID,
    payload: DashboardStats[]
): DashboardActionStoreStats => {
    return { type: DASHBOARD_STORE_STATS, payload, watcher }
}

export const DashboardDispatchClearStats = (): DashboardActionClearStats => {
    return { type: DASHBOARD_CLEAR_STATS }
}

export interface SagasForDashboard {
    loadStats(action: DashboardActionLoadStats): void
}

export type ReducedDashboardActions =
    | DashboardActionStoreStats
    | DashboardActionLoadStats
    | DashboardActionClearStats
