import { LoadingStatus } from '../../../utils'

export interface ApplicationInternalTag {
    color: string
    id: string
    name: string
    visible: boolean
    selfLink: string
    selectionLink: string
}
export type ApplicationInternalTagEditableDetailsPayload = Omit<
    ApplicationInternalTag,
    'selfLink' | 'selectionLink' | 'id'
>

export interface ApplicationInternalTagWithCount extends ApplicationInternalTag {
    count: string
}

export interface ApplicationInternalTagsState {
    forApplication: {
        [key: string]: {
            selected: string[]
            selectable: string[]
            all: string[]
            at: { [key: string]: ApplicationInternalTagWithCount }
            loadingStatus: LoadingStatus
        }
    }
    globalTags: {
        at: { [key: string]: ApplicationInternalTagWithCount }
        all: string[]
        loadingStatus: LoadingStatus
    }
}

export const InitialApplicationInternalTagsState: ApplicationInternalTagsState = {
    forApplication: {},
    globalTags: {
        at: {},
        all: [],
        loadingStatus: 'not-started'
    }
}
