import styled, { css } from 'styled-components'

export const MERCHANT_SIDEBAR_SIZE = '500px'
export const MerchantSidebarShadow = styled.div<{ flip?: boolean; show?: boolean }>`
    height: 100vh;
    position: absolute;
    top: 0;
    width: 400px;
    opacity: 0.4;
    right: ${MERCHANT_SIDEBAR_SIZE};
    pointer-events: none;
    z-index: 100;
    background: ${(p) => p.theme['sidebarShadow'](false)};
    display: none;

    ${(p) =>
        p.flip &&
        css`
            left: ${MERCHANT_SIDEBAR_SIZE};
            right: auto;
            background: ${(p) => p.theme['sidebarShadow'](true)};
        `}
    ${(p) =>
        p.show &&
        css`
            display: block;
        `}
`
