import { forwardRef, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { Color } from '../../styles/colors'
import ColorHash from 'color-hash'
import { Spacer } from '../layout/spacer'

export const colorHash = new ColorHash({ lightness: 0.45 })
export const regularColorHash = new ColorHash({ lightness: 0.5 })

export const ColorTagWithoutRef: React.ForwardRefRenderFunction<
    any,
    {
        color?: string
        name: string
        light?: boolean
        subtle?: boolean
        onClick?: (e: any, tagName: string) => void
        onRemove?: () => void
        block?: boolean
        extensive?: boolean
        spotless?: boolean
        selectable?: boolean
        overBackground: 'front.background' | 'side.background' | 'floating.background'
        hideColor?: boolean
    }
> = (
    {
        color,
        name,
        overBackground,
        extensive,
        light = false,
        block,
        spotless,
        selectable,
        onClick,
        subtle,
        onRemove,
        hideColor = false
    },
    ref
) => {
    let spotColor = color

    if (!color) {
        spotColor = regularColorHash.hex(name)
    }

    const textColor = useMemo(() => {
        if (overBackground === 'front.background') {
            if (subtle) return 'front.text.subtlerI'
            return 'front.text'
        } else if (overBackground === 'side.background') {
            if (subtle) return 'side.text.subtlerI'
            return 'side.text'
        } else if (overBackground === 'floating.background') {
            return 'floating.text'
        }
        return 'front.text'
    }, [overBackground, subtle])

    return (
        <TagHolder
            light={light}
            block={block}
            selectable={selectable}
            removable={!!onRemove}
            ref={ref}
            onClick={(e: any) => {
                if (name && onClick) onClick(e, name)
            }}
        >
            {!hideColor && !spotless && (
                <>
                    <ColorSpot
                        extensive={extensive}
                        color={spotColor}
                        selectable={selectable}
                        style={{ marginTop: 2 }}
                    />
                    <Spacer width={4} />
                </>
            )}
            <TagName textColor={textColor} hex={spotless ? spotColor : undefined} light={light} selectable={selectable}>
                {name}
            </TagName>
        </TagHolder>
    )
}

export const ColorTag = forwardRef(ColorTagWithoutRef)

const TagHolder = styled.div<{
    light: boolean
    block?: boolean
    selectable?: boolean
    removable: boolean
}>`
    max-width: initial;
    border-radius: 9999px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 3px;

    ${(p) =>
        p.removable &&
        css`
            padding: 3px 3px 3px 7px;
        `}

    ${(p) =>
        p.selectable &&
        css`
            padding: 6px 8px;
            margin: 4px 2px;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }

            &:active {
                background-color: rgba(0, 0, 0, 0.15);
            }
        `}

    ${(p) =>
        p.block &&
        css`
            display: flex;
        `}
`

const TagName = styled.div<{
    hex?: string
    light: boolean
    textColor: Color
    selectable?: boolean
}>`
    color: ${(p) => (p.hex ? p.hex : p.theme[p.textColor])};
    margin-left: 1px !important;
    margin-top: 0px;
    margin-right: 1px !important;
    white-space: nowrap;
    overflow: initial;
    text-overflow: ellipsis;

    ${(p) =>
        p.selectable &&
        css`
            font-weight: 500;
        `}

    ${(p) =>
        p.light &&
        css`
            color: #fff;
        `}
`

const ColorSpot = styled.div<{ selectable?: boolean; extensive?: boolean }>`
    width: 4px;
    height: 4px;
    flex-shrink: 0;
    border-radius: 999px;
    position: relative;
    top: -0.33px;

    ${(p) =>
        p.extensive &&
        css`
            height: 9px;
            width: 9px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            position: relative;
            top: 0px;

            &:before {
                content: '';
                display: block;
                background-color: rgba(255, 255, 255, 1);
                background-blend-mode: lighten;
                position: absolute;
                top: 3px;
                left: 3px;
                border-radius: 999px;
                width: 3px;
                height: 3px;
            }
        `}
    ${(p) =>
        p.selectable &&
        css`
            width: 9px;
            height: 9px;
        `}

    ${(p) => css`
        background-color: ${p.color};
    `}
`
