import React from 'react'
import { Spacer } from '@/components/layout/spacer'
import styled from 'styled-components'
import { useClassificationChecks } from '../provider/classification-provider/classification-provider'
import { BusinessModel, Comment, Location, Others, OwnershipManagement } from '@/services/risk-classification'
import { Field } from '../fields/field'

export const ClassificationChecks: React.FC = () => {
  const ownershipChecks = useClassificationChecks<OwnershipManagement>('ownership')
  const businessChecks = useClassificationChecks<BusinessModel>('business')
  const comment = useClassificationChecks<Comment>('comment')
  const locationChecks = useClassificationChecks<Location>('location')
  const othersChecks = useClassificationChecks<Others>('others')

  return (
      <>
        <FieldGrid>
          <Field title="Ownership / management" checks={ownershipChecks}/>
          <Field title="Others" checks={othersChecks}/>
          <Field title="Business model" checks={businessChecks}/>
          <Field title="Location" checks={locationChecks}/>
        </FieldGrid>
        <Spacer height={16} />
        <Field title="" checks={comment}/>
      </>
  )
}

const FieldGrid = styled.div`
    display: grid;
    width: 100%;
    grid-column-gap: 40px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0;
`
