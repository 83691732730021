import React from 'react'
import { Button, Flex, Textarea } from '@/design-system'
import { useDeleteVoucherActions } from '../../../hooks'
import { ActionPopover } from '../common/action-popover'

const DeleteVoucher: React.FC = () => {
    const { isLoading, deleteVoucher } = useDeleteVoucherActions()
    const [reason, setReason] = React.useState('')

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReason(e.target.value)
    }

    const handleOnDelete = async () => {
        await deleteVoucher(reason)
    }

    return (
        <ActionPopover>
            <ActionPopover.Trigger>
                <Button color="danger" variant="link">
                    Delete
                </Button>
            </ActionPopover.Trigger>
            <ActionPopover.Content>
                <ActionPopover.Header>
                    <ActionPopover.Title>Are you sure you want to delete this voucher?</ActionPopover.Title>
                    <ActionPopover.Subtitle>
                        Deleting this voucher will remove it from the reconciliation process.
                    </ActionPopover.Subtitle>
                </ActionPopover.Header>
                <ActionPopover.Fields>
                    <Textarea
                        variant="surface"
                        name="reason"
                        value={reason}
                        onChange={handleOnChange}
                        placeholder="Write a reason here..."
                    />
                </ActionPopover.Fields>
                <ActionPopover.Warning>
                    Make sure this is a conscious decision and likely do one or more misc posting(s) to offset the
                    mistake.
                </ActionPopover.Warning>
                <ActionPopover.Footer>
                    <Button color="danger" onClick={handleOnDelete} loading={isLoading} disabled={!reason} width="full">
                        Delete
                    </Button>
                </ActionPopover.Footer>
            </ActionPopover.Content>
        </ActionPopover>
    )
}

export { DeleteVoucher }
