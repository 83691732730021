import { useMemo } from 'react'
import { LinkButton } from '../../../../components/buttons/linkButton'
import { TextInput } from '../../../../components/forms/textInput'
import { Card } from '../../../../components/cards/card'
import { CardInset } from '../../../../components/cards/cardInset'
import { Text } from '../../../../components/general/text'
import { BubbledCheck } from '../../../../components/icons/bubbledCheck'
import { Flex } from '../../../../components/layout/flex'
import { Spacer } from '../../../../components/layout/spacer'
import { Table } from '../../../../components/tables/table'
import { uppercaseFirstLetter } from '../../../../utils'
import { MoreNotificationsRevokeButton } from './More.NotificationsRevokeButton'
import { Icon } from '../../../../components/icons/icon'
import { useNotificationsFetching } from './More.Notifications.useNotificationsFetching'
import styled from 'styled-components'
import { MoreNotificationsWebhooks } from './More.Notifications.Webhooks'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const MoreNotificationsManagement: React.FC<{ forHooks?: boolean }> = ({ forHooks }) => {
    const { id } = useParams() as Params

    const { notifications, filter, emptyTableText } = useNotificationsFetching(id, forHooks)
    const cols = useMemo(() => {
        return [
            {
                text: forHooks ? 'Endpoint' : 'Recipient',
                loaderSize: {
                    min: 90,
                    max: 170
                }
            },
            {
                text: 'Events',
                loaderSize: {
                    min: 50,
                    max: 120
                }
            },
            {
                text: 'Status',
                alignRight: true,
                loaderSize: {
                    min: 50,
                    max: 150
                }
            },
            {
                text: 'Actions',
                alignRight: true,
                loaderSize: {
                    min: 60,
                    max: 60
                }
            }
        ]
    }, [forHooks])

    const rows = useMemo(() => {
        return notifications.all.map((nId) => {
            const notification = notifications.at[nId]

            return {
                type: 'normal' as const,
                noAction: true,
                key: notification.id,
                items: [
                    {
                        node: (
                            <Text oneLine={forHooks ? false : true} mono={forHooks}>
                                {notification.endpoint}
                            </Text>
                        )
                    },
                    {
                        node: forHooks ? (
                            <Flex wrap>
                                <MoreNotificationsWebhooks events={notification.events} />
                            </Flex>
                        ) : (
                            <Flex wrap>
                                {notification.events.map((k) => (
                                    <EventPill>{uppercaseFirstLetter(k)}</EventPill>
                                ))}
                            </Flex>
                        )
                    },
                    {
                        node: (
                            <Text>
                                {notification.enabled ? (
                                    <Flex align="center">
                                        <BubbledCheck />
                                        <Text color="front.success.color" oneLine>
                                            Enabled
                                        </Text>
                                    </Flex>
                                ) : (
                                    <Text italic color="front.text.subtlerI" noWrap>
                                        Pending confirmation
                                    </Text>
                                )}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Flex>
                                <MoreNotificationsRevokeButton notification={notification} applicationId={id} />
                                <Spacer width={10} />
                                <LinkButton
                                    to={`/merchant/${id}/more/edit-${forHooks ? 'webhook' : 'notification'}/${
                                        notification.id
                                    }`}
                                >
                                    Edit
                                </LinkButton>
                            </Flex>
                        )
                    }
                ]
            }
        })
    }, [notifications, forHooks, id])

    const rightSideMemo = useMemo(() => {
        return (
            <Flex grow justify="flex-end" align="baseline">
                <TextInput
                    placeholder="Filter by recipient or type"
                    overBackground="front.background"
                    rightAlign
                    isSeamless
                    onChange={(val) => filter(val.currentTarget.value)}
                />
                <Flex marginLeft={7} top={1}>
                    <Icon type="searchIcon" size={13} color="front.text.subtlerI" />
                </Flex>
                <Spacer width={25} height={1} />
                <LinkButton
                    noShrink
                    cy="create-notification"
                    to={`/merchant/${id}/more/new-${forHooks ? 'webhook' : 'notification'}`}
                >
                    {forHooks ? 'Register a webhook' : 'Register an email notification'}
                </LinkButton>
            </Flex>
        )
    }, [id, filter, forHooks])

    return (
        <Card
            title={forHooks ? 'Webhooks' : 'E-mail notifications'}
            cy={forHooks ? 'webhooks-config' : 'notif-config'}
            rightSideMemo={rightSideMemo}
        >
            <CardInset>
                <Table
                    verticalAlignTop
                    rows={rows}
                    cols={cols}
                    columnLayout={
                        forHooks ? 'auto min-content min-content 110px' : 'min-content auto min-content 110px'
                    }
                    loaderRows={2}
                    displayLoader={notifications?.loadingStatus !== 'done'}
                    overrideText={emptyTableText}
                    background="front.background"
                />
            </CardInset>
        </Card>
    )
}

const EventPill = styled.div`
    background-color: ${(p) => p.theme['front.subtleAccent.background']};
    border: 1px solid ${(p) => p.theme['front.subtleAccent.background.strongerI']};
    margin-right: 3px;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 600;
    padding: 0px 8px;
    border-radius: 999px;
`
