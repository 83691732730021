import { Checkbox as DSCheckbox, Flex, Grid, Label } from '@/design-system'
import React from 'react'

interface CheckboxGroupProps {
    children: React.ReactNode
    label: string
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
    const { children, label } = props

    return (
        <>
            <Grid.Item>
                <Label>{label}</Label>
            </Grid.Item>
            <Grid.Item columnSpan="2">
                <Flex direction="column" gap="3">
                    {children}
                </Flex>
            </Grid.Item>
        </>
    )
}

interface CheckboxProps {
    testid?: string
    name: string
    label: string
    defaultChecked?: boolean
    onChange: (checked: boolean) => void
}

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => {
    const { testid, name, label, defaultChecked, onChange } = props

    return (
        <Flex gap="3" align="center">
            <DSCheckbox
                testid={testid}
                id={name}
                name={name}
                defaultChecked={defaultChecked}
                ref={ref}
                onCheckedChange={onChange}
            />
            <Label color="black" htmlFor={name}>
                {label}
            </Label>
        </Flex>
    )
})

Checkbox.displayName = 'CheckboxGroup.Checkbox'

CheckboxGroup.Checkbox = Checkbox

export { CheckboxGroup }
