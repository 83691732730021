import React from 'react'
import { ErrorBoundary } from './trackjs/errorBoundary'
import App from './App'

export const Main: React.FC = () => {
    return (
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    )
}
