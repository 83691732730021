import { MerchantDataPerson } from '@/services/ongoing-due-diligence'
import React from 'react'
import { Section } from '../../../common/section'
import { PersonMerchantData } from '../common/person-merchant-data'

interface UboMerchantDataProps {
    owners: MerchantDataPerson[]
}

export const UboMerchantData: React.FC<UboMerchantDataProps> = (props) => {
    const { owners } = props

    return (
        <>
            {owners.map((person, i) => {
                const last = i === owners.length - 1
                const title = `${i + 1}. Person`

                return (
                    <React.Fragment key={person.id}>
                        <PersonMerchantData title={title} person={person} />
                        {!last && <Section.Spacer size="l" />}
                    </React.Fragment>
                )
            })}
        </>
    )
}
