// import { Form, OverlayTrigger, Overlay } from "react-bootstrap"
import { kebabCase } from 'lodash'
import moment from 'moment'
import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { DialogContext } from '../../contexts/dialogProvider'
import { useFileDownloader } from '../../hooks/files/useFileDownloader'
import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { Icon } from '../icons/icon'
import { File } from '../../store/files/types'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../../store/toasts/actions'
import { WatcherID } from '../../store/watcher/types'
import { DateFormats } from '../../utils/dateUtils'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { Flex } from '../layout/flex'
import { Floater } from '../layout/floater'
import { FloaterInset } from '../layout/floaterInset'
import { ProgressBar } from '../general/progressBar'
import { Separator } from '../layout/separator'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { safeWindowOpen } from '../../utils'
import { Color } from '../../styles/colors'

const queryParams = ['preview']
export const FileBox: React.FC<{
    fileId: string
    first?: boolean
    last?: boolean
    background?: Color
    onNewFileClicked?: () => void
    onRemoveClicked?: (watcherId: WatcherID, file: File) => void
    setIsReplacing?: (i: File | undefined) => void
    onRefetchClicked?: (file: File) => void
    inputId?: string
    cy?: string
    noDate?: boolean
    outlined?: boolean
    showFileMenu?: boolean
}> = ({ fileId, noDate, onNewFileClicked, background, cy, onRemoveClicked, setIsReplacing, 
        onRefetchClicked, showFileMenu }) => { 
    const [isHovered, setHovered] = useState(false)
    const [showFloater, setShowFloater] = useState(false)
    const [holder, setHolder] = useRefTaker()
    const [anchor, setAnchor] = useRefTaker()
    const [query, setQuery] = useQueryParams(queryParams, undefined)
    const file = useSelector((state: RootState) => {
        return state.files.at[fileId]
    })
    const { setDialog } = useContext(DialogContext)
    const dispatch = useDispatch()
    const { downloadFile } = useFileDownloader(file, () => {
        setShowFloater(false)
    })

    const tickClick = useCallback(() => {
        setShowFloater((s) => !s)
    }, [setShowFloater])

    useOnHoverOutside(
        holder,
        useCallback(() => {
            setShowFloater(false)
        }, [setShowFloater])
    )

    const handleRemoveFile = useCallback(() => {
        setDialog?.({
            title: 'Confirm Deletion',
            description: 'Are you sure you want to delete this file permanently?',
            action: {
                label: 'Delete permanently',
                buttonBackground: 'front.danger.color',
                watcherId: `${file.id}.DeleteFile`,
                action: () => {
                    if (!file) return dispatch(ToastsDispatchPush('File not found'))
                    if (onRemoveClicked) onRemoveClicked(`${file.id}.DeleteFile`, file)
                    setShowFloater(false)
                }
            }
        })
    }, [dispatch, file, onRemoveClicked, setDialog])

    const fileMenu = useMemo(() => {
        const items: ReactNode[] = []

        if (onNewFileClicked)
            items.push(
                <SimpleButton color="floating.text" key="upload-another" onClick={onNewFileClicked}>
                    <ButtonInset leftAlign noHorizontalPadding>
                        Upload new files
                    </ButtonInset>
                </SimpleButton>
            )

        if (setIsReplacing) {
            items.push(
                <SimpleButton
                    color="floating.text"
                    cy="replace"
                    key="replace"
                    onClick={() => {
                        setIsReplacing(file)
                    }}
                >
                    <ButtonInset leftAlign noHorizontalPadding>
                        Replace file
                    </ButtonInset>
                </SimpleButton>
            )
        }

        items.push(
            <SimpleButton
                color="floating.text"
                key="download"
                onClick={() => {
                    downloadFile()
                }}
            >
                <ButtonInset leftAlign noHorizontalPadding>
                    Download
                </ButtonInset>
            </SimpleButton>
        )

        if (onRefetchClicked && file?.refetchLink) {
            items.push(
                <SimpleButton
                    color="floating.text"
                    key="refetch"
                    onClick={ () => {
                        onRefetchClicked(file)
                        setShowFloater(false)
                    }}
                >
                    <ButtonInset leftAlign noHorizontalPadding>
                        Refetch file
                    </ButtonInset>
                </SimpleButton>
            )
        }

        if (onRemoveClicked)
            items.push(
                <SimpleButton color="front.danger.color.subtlerII" cy="delete" key="delete" onClick={handleRemoveFile}>
                    <ButtonInset leftAlign noHorizontalPadding>
                        Delete
                    </ButtonInset>
                </SimpleButton>
            )

        return items.reduce((acc: ReactNode[], item: ReactNode, index: number) => {
            const newItems = [...acc, item]
            if (index < items.length - 1)
                newItems.push(<Separator key={`separator-${index}`} background="overlay.background" />)
            return newItems
        }, [] as ReactNode[])
    }, [
        downloadFile,
        file,
        handleRemoveFile,
        onRemoveClicked,
        onNewFileClicked,
        setIsReplacing,
        onRefetchClicked
    ]) as JSX.Element[]

    const handleFileClick = useCallback(
        (e) => {
            if (e.shiftKey || e.ctrlKey || e.metaKey) {
                safeWindowOpen(`/preview-file/${file.id}`)
            } else if (e.altKey)
                setQuery((q: any) => {
                    if (q.preview)
                        return {
                            preview: [...(typeof q.preview === 'object' ? q.preview : [q.preview]), file.id]
                        }
                    else
                        return {
                            preview: file.id
                        }
                })
            else
                setQuery({
                    preview: file.id
                })
        },
        [file, setQuery]
    )

    const popup = useCallback(() => {
        setQuery((q: any) => ({
            preview:
                typeof q.preview === 'object'
                    ? [...(q.preview || []), file.id]
                    : q.preview
                    ? [q.preview, file.id]
                    : [file.id]
        }))
    }, [setQuery, file])

    if (!file) return null

    if (file.progress !== undefined)
        return (
            <Flex align="stretch" column>
                <ProgressBar progress={file.progress} />
            </Flex>
        )

    return (
        <Holder
            ref={setHolder}
            data-cy={cy}
            onMouseEnter={() => {
                setHovered(true)
            }}
            onMouseLeave={() => {
                setHovered(false)
            }}
        >
            <ButtonHolder>
                <SimpleButton onClick={handleFileClick} background={background}>
                    <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                        <Icon type="attachment" />
                        <Spacer width={5} />
                        <TextHolder>
                            <Text cy="file-box-name" noWrap oneLine>
                                {file.name}
                            </Text>
                        </TextHolder>
                        <Spacer width={15} />
                    </ButtonInset>
                </SimpleButton>
            </ButtonHolder>
            {
                showFileMenu ? 
                    <FileMenuTick data-cy="menu-tick" isHovered={isHovered}>
                        <SimpleButton
                            background={'front.subtleAccent.background'}
                            onClick={tickClick}
                            cy={file.name ? `${kebabCase(file.name)}-tick` : undefined}
                            round
                        >
                            <ButtonInset padding="tiny" equalPadding>
                                <TickHolder ref={setAnchor}>
                                    <Icon type="downTick" />
                                </TickHolder>
                            </ButtonInset>
                        </SimpleButton>
                    </FileMenuTick> : null
            }
            {isHovered && query.preview && (
                <>
                    <Spacer width={5} />
                    <SimpleButton
                        background={'front.subtleAccent.background'}
                        onClick={popup}
                        isDisabled={query.preview?.includes?.(file.id)}
                        cy={file.name ? `${kebabCase(file.name)}-tick` : undefined}
                        round
                    >
                        <ButtonInset padding="tiny" equalPadding>
                            <TickHolder>
                                <Icon type="popup" />
                            </TickHolder>
                        </ButtonInset>
                    </SimpleButton>
                </>
            )}
            <Floater
                cardId="GenericFileBox"
                shouldShow={showFloater}
                anchor={anchor}
                cy={file.name ? `${kebabCase(file.name)}-menu` : undefined}
                onHide={() => {
                    setShowFloater(false)
                }}
            >
                <FloaterInset>{fileMenu}</FloaterInset>
            </Floater>
            <Spacer width={10} />
                {!noDate && (
                    <Flex grow justify="flex-end" noShrink>
                            <Text color="front.text.subtlerI">
                                {moment(file.createdAt).format(DateFormats.dayAndTime(moment(file.createdAt)))}
                            </Text>
                    </Flex>
                )}
        </Holder>
    )
}

const FileMenu = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-basis: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 0px 3px 3px 0;
    cursor: pointer;
    background-color: #fff;
    margin-right: -20px;
    margin-left: 20px;

    &:hover {
        background-color: ${(p) => p.theme['front.text.subtlerI']};
    }

    &:active {
        background-color: ${(p) => p.theme['front.text.subtlerI']};
    }

    @media print {
        display: none;
    }
`

const FileElement = styled.div<{ first?: boolean; last?: boolean }>`
    border-radius: 0;
    padding: 10px 20px;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.2s ease all;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background-color: #fff;
    color: ${(p) => p.theme['front.accent.color']};
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;

    ${(p) =>
        p.first &&
        css`
            border-top-right-radius: 0px;
            border-top-left-radius: 6px;
            border-radius: 4px 0px 0 0;
        `}

    ${(p) =>
        p.last &&
        css`
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 0px;
        `}

    &:hover {
        background-color: ${(p) => p.theme['front.accent.color.subtlerI']};
    }

    &:active {
        background-color: ${(p) => p.theme['front.accent.color.subtlerII']};
    }
`
const FileMenuTick = styled.div<{ isHovered?: boolean }>`
    opacity: 0;
    ${(p) =>
        p.isHovered &&
        css`
            opacity: 1;
        `}
`

const TickHolder = styled.div`
    width: 8px;
    height: 14px;
    line-height: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Holder = styled.div<{ background?: Color }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &:last-child ${FileMenu} {
        border-bottom-right-radius: 8px;
    }
    &:last-child ${FileElement} {
        border-bottom-left-radius: 8px;
    }
    background-color: transparent;
    @media print {
        border-left: 3px solid #efefef;
        padding: 5px 10px;
        box-sizing: border-box;
        align-items: center;
    }
`

const TextHolder = styled.div`
    flex-grow: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ButtonHolder = styled.div`
    flex-shrink: 1;
    min-width: 0;
    @media print {
        margin-top: 2px;
    }
`
