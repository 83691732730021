import React from 'react'
import { Section } from '../../../common/section'
import { SectionCheck } from '@/services/ongoing-due-diligence'
import { ToggleContent } from '../toggle-content/toggle-content'
import { withoutSchemeAndSubdomain } from '@/utils/formatting'
import { useReportMerchantData, useReportSection } from '../../../provider/report-provider/report-provider'

export const WebsiteSatisfyingBusinessModel: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')
    const merchantData = useReportMerchantData()

    const data = checks.website_satisfying_business_model
    const commentData = checks.comment_website_satisfying_business_model

    const hasWebsites = merchantData.websites && merchantData.websites.length > 0

    return (
        <Section>
            <Section.Content>
                <ToggleContent
                    dataName="website_satisfying_business_model"
                    data={data}
                    commentName="comment_website_satisfying_business_model"
                    commentData={commentData}
                    visibleValue="No"
                />
            </Section.Content>
            <Section.Metadata>
                <Section.Title>{hasWebsites ? 'Website(s)' : 'No websites found'}</Section.Title>
                {hasWebsites && <Section.Spacer size="s" />}
                {hasWebsites &&
                    merchantData.websites.map((website, i) => {
                        const last = i === merchantData.websites.length - 1

                        const shortenedUrl = withoutSchemeAndSubdomain(website.url)

                        return (
                            <React.Fragment key={website.id}>
                                <Section.ExternalLink url={website.url}>{shortenedUrl}</Section.ExternalLink>
                                {!last && <Section.Spacer size="s" />}
                            </React.Fragment>
                        )
                    })}
            </Section.Metadata>
        </Section>
    )
}
