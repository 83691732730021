import React from 'react'
import { Styles, styles } from './styles'

interface PageProps extends Styles {
    children: React.ReactNode
}

export const Page: React.FC<PageProps> = (props) => {
    const { children, pt, pb } = props

    const classnames = styles({ pt, pb })

    return <div className={classnames}>{children}</div>
}
