import React, { useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { ModalPage } from '../../../components/layout/modalPage'
import { PageWrapper } from '../../../components/layout/pageWrapper'
import { Spacer } from '../../../components/layout/spacer'
import { CardSection } from '../../../components/cards/cardSection'
import { Table } from '../../../components/tables/table'
import { MerchantApplicationReviewChangesModalInput } from './Application.ReviewChangesInput'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { MerchantApplicationReviewChangesModalSection } from './Application.ReviewChangesSection'
import { InterfaceActions } from '../../../store/interface/actions'
import { InterfaceState } from '../../../store/interface/types'
import { useResetScrollOnMount } from '../../../hooks/pages/useResetScrollOnMount'
import { ApplicationResourceActions } from '../../../store/applicationResources/actions'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { ConvertIndexPathStringToIndexPath } from '../../../store/applicationResources/utils'
import { useMerchantApplicationFormChangesBuilder } from './useMerchantApplicationFormChangesBuilder'
import { useLocation, useNavigate } from 'react-router-dom'

type StepID = keyof InterfaceState['applications']['id']['steps']
const nonSendMessageSteps = ['remove-collaborator', 'add-collaborator']
const nonMessageSteps = ['remove-collaborator']
export const MerchantApplicationReviewChangesModal: React.FC<{
    id: string
}> = ({ id }) => {
    useResetScrollOnMount()
    const dispatch = useDispatch()
    const { merchantCommentField, changes, steps } = useSelector(
        (state: RootState) => state.interface.applications?.[id]
    )
    const [messages, setMessages] = useState<{
        [key in Exclude<StepID, 'remove-collaborator'>]: string | undefined
    }>({
        'merchant-message': undefined,
        'internal-note': undefined,
        'add-collaborator': undefined
    })
    const { merchantApplicationReviewFormattedChange, formatChangesForReviewInput, changeFormatter } =
        useMerchantApplicationFormChangesBuilder(id)
    if (!changes || !steps) throw 'Something went wrong while reviewing the changes'

    const changesArr = useMemo(() => {
        return Object.keys(changes).map((k) => {
            return {
                key: k,
                ...changes[k]
            }
        })
    }, [changes])

    const rows = useMemo(() => {
        return changesArr?.map((c) => {
            const { col, from, to } = merchantApplicationReviewFormattedChange(c)

            return {
                type: 'normal' as const,
                noAction: true,
                key: c.key,
                items: [
                    {
                        node: (
                            <Flex align="center" justify="flex-start">
                                <Text noWrap cy="change-label">
                                    {col}
                                </Text>
                                <Spacer width={20} />
                            </Flex>
                        )
                    },
                    {
                        node: (
                            <TextHolder>
                                <Text cy="change-from">{from}</Text>
                            </TextHolder>
                        )
                    },
                    {
                        node: (
                            <Flex column justify="center">
                                <Spacer height={4} />
                                <Icon type="arrow" />
                            </Flex>
                        )
                    },
                    {
                        node: (
                            <TextHolder>
                                <Text cy="change-to">{to}</Text>
                            </TextHolder>
                        )
                    }
                ]
            }
        })
    }, [changesArr, merchantApplicationReviewFormattedChange])

    const cols = useMemo(() => {
        return [
            {
                text: 'Field'
            },
            {
                text: 'From'
            },
            {
                text: ''
            },
            {
                text: 'To'
            }
        ]
    }, [])

    const handleOnBack = useCallback(() => {
        dispatch(InterfaceActions.CANCEL_APPLICATION_REVIEW(id))
    }, [dispatch, id])

    const stepPrefixes = useMemo(() => {
        const getPrefix = (stepId: StepID) => {
            switch (stepId) {
                case 'merchant-message':
                    return ''
                case 'internal-note':
                    return ''
                case 'add-collaborator':
                    return ''
                case 'remove-collaborator':
                    return ''
            }
        }

        return (Object.keys(steps) as Array<StepID>).reduce(
            (acc, stepId) => {
                acc[stepId] = getPrefix(stepId)
                return acc
            },
            {} as { [key in StepID]: string }
        )
    }, [steps])

    const handleMessageChanged = useCallback(
        (stepId: StepID, value: string) => {
            setMessages((m) => ({
                ...m,
                [stepId]: value
            }))
        },
        [setMessages]
    )

    const stepsMemo = useMemo(() => {
        let activeStepCount = 1

        if (!merchantCommentField) return null

        const getSectionContent = (stepId: StepID) => {
            switch (stepId) {
                case 'merchant-message':
                    return (
                        <>
                            <Text>
                                A message will be sent<Text bold> to the merchant</Text>, in the{' '}
                                <Field>
                                    {
                                        // eslint-disable-next-line max-len
                                        changeFormatter.textForFieldName(merchantCommentField.indexPathString)
                                    }
                                </Field>{' '}
                                field:
                            </Text>
                            <Spacer height={12} />
                            <MerchantApplicationReviewChangesModalInput
                                id={id}
                                placeholder="« Merchant message contents »"
                                prefix={stepPrefixes[stepId]}
                                skipSanitisedChanges
                                stepId={stepId}
                                onMessageChanged={handleMessageChanged}
                                initialMessage={messages[stepId]}
                                changesArr={changesArr}
                            />
                        </>
                    )
                case 'internal-note':
                    return (
                        <>
                            <Text>
                                <Text bold>An internal note</Text> will be added:
                            </Text>
                            <Spacer height={12} />
                            <MerchantApplicationReviewChangesModalInput
                                placeholder="« Internal note contents »"
                                id={id}
                                prefix={stepPrefixes[stepId]}
                                stepId={stepId}
                                onMessageChanged={handleMessageChanged}
                                changesArr={changesArr}
                                initialMessage={messages[stepId]}
                            />
                        </>
                    )
                case 'remove-collaborator':
                    return (
                        <>
                            <Text>
                                Existing collaborator <Text bold>{steps['remove-collaborator'].email}</Text> will be
                                revoked.
                            </Text>
                        </>
                    )
                case 'add-collaborator':
                    return (
                        <>
                            <Text>
                                <Text bold>A new collaborator</Text> will be added{' '}
                                <Text bold>
                                    {steps['add-collaborator'].name == steps['add-collaborator'].email
                                        ? steps['add-collaborator'].email
                                        : `${steps['add-collaborator'].name} (${steps['add-collaborator'].email})`}
                                </Text>
                            </Text>
                            <Spacer height={12} />
                            <MerchantApplicationReviewChangesModalInput
                                placeholder="Invitation message"
                                id={id}
                                prefix=""
                                stepId={'add-collaborator'}
                                onMessageChanged={handleMessageChanged}
                                changesArr={undefined}
                                initialMessage={messages[stepId]}
                            />
                        </>
                    )
            }
        }

        return (Object.keys(steps) as Array<StepID>).map((stepid, i, arr) => {
            const isCancelled = steps[stepid].isCancelled
            if (!isCancelled) activeStepCount++
            if ((stepid == 'add-collaborator' || stepid == 'remove-collaborator') && !steps[stepid].isSuggested)
                return null

            return (
                <MerchantApplicationReviewChangesModalSection
                    id={id}
                    key={stepid}
                    isNonSend={nonSendMessageSteps.includes(stepid)}
                    isNonMessage={nonMessageSteps.includes(stepid)}
                    number={activeStepCount}
                    isCancelled={isCancelled}
                    stepId={stepid}
                    isLast={i === arr.length - 1}
                >
                    {getSectionContent(stepid)}
                </MerchantApplicationReviewChangesModalSection>
            )
        })
    }, [steps, changesArr, id, stepPrefixes, changeFormatter, merchantCommentField, handleMessageChanged, messages])

    const handleReviewDone = useCallback(
        (e, watcherId) => {
            dispatch(ApplicationResourceActions.MAKE_EDITS(watcherId, id, changesArr, messages))
        },
        [dispatch, changesArr, id, messages]
    )

    const navigate = useNavigate()
    const location = useLocation()
    const handleWatcherSuccess = useCallback(() => {
        if (!merchantCommentField) throw new Error('Failed to handle review success. No merchant comment field found.')

        const indexPath = ConvertIndexPathStringToIndexPath(merchantCommentField.indexPathString)
        if (!indexPath) throw new Error('Failed to find indexPath for reviewed field')

        navigate(
            {
                pathname: `/merchant/${id}/application`,
                search: location.search
            },
            { replace: true }
        )
        dispatch(InterfaceActions.FINISH_APPLICATION_REVIEW(id))
    }, [id, merchantCommentField, dispatch, navigate, location])

    const handleWatcherFailure = useCallback(() => {
        dispatch(InterfaceActions.FINISH_APPLICATION_REVIEW(id))
    }, [id, dispatch])

    const submitButtonMemo = useMemo(() => {
        const allSteps = Object.keys(steps) as Array<StepID>
        const approvedSteps = allSteps.filter((sId) => !steps[sId].isCancelled)
        const goodStepsWithNonModifiedMessages = approvedSteps.filter((stepId) =>
            nonMessageSteps.includes(stepId)
                ? false
                : formatChangesForReviewInput(stepPrefixes[stepId], changesArr) === messages[stepId] ||
                  messages[stepId] === undefined
        ).length
        const isDisabled = !!goodStepsWithNonModifiedMessages

        let message = 'Save changes'
        if (approvedSteps.includes('merchant-message')) message += ' + Inform the merchant'
        if (approvedSteps.includes('internal-note')) message += ' + Add the internal note'
        if (approvedSteps.includes('remove-collaborator')) message += ' + Remove collaborator'
        if (approvedSteps.includes('add-collaborator')) message += ' + Add collaborator'

        return (
            <WatcherButton
                tooltip={
                    isDisabled ? 'Adjust the message/note or skip the steps before saving your changes.' : undefined
                }
                background="front.accent.color"
                onClick={handleReviewDone}
                onSuccess={handleWatcherSuccess}
                onFailure={handleWatcherFailure}
                isDisabled={isDisabled}
            >
                <ButtonInset>{message}</ButtonInset>
            </WatcherButton>
        )
    }, [
        steps,
        messages,
        formatChangesForReviewInput,
        handleWatcherFailure,
        stepPrefixes,
        handleReviewDone,
        changesArr,
        handleWatcherSuccess
    ])

    if (!merchantCommentField) return null
    return (
        <ModalPage
            title="Confirm application changes"
            onBack={handleOnBack}
            pageId="Merchant.ApplicationPage.ReviewChanges"
        >
            <ModalHeader
                onBack={handleOnBack}
                pageId="Merchant.ApplicationPage.ReviewChanges"
                title="Application edits review"
            />
            <PageWrapper>
                <Flex justify="center" align="center" column grow>
                    <Card higher>
                        <CardInset>
                            <CardHolder>
                                <Flex align="stretch" grow column>
                                    <MerchantApplicationReviewChangesModalSection id={id} number={1}>
                                        <Text>
                                            The following&nbsp;
                                            <Text bold>
                                                {changesArr.length === 1 ? 'change' : `${changesArr.length} changes`}
                                            </Text>{' '}
                                            will be made to the application:
                                        </Text>
                                        <Spacer height={20} />
                                        <Table
                                            background="front.background"
                                            cols={cols}
                                            // eslint-disable-next-line max-len
                                            columnLayout="min-content 1fr min-content 1.1fr"
                                            showLastRowBorder
                                            overrideText="No referral partners have been assigned."
                                            // displayLoader={summaries.loadingStatus == 'started'}
                                            rows={rows}
                                            emptyText="No tasks found matching the filtered criterias."
                                        />
                                    </MerchantApplicationReviewChangesModalSection>
                                    {stepsMemo}
                                </Flex>
                                <CardSection background="subtleBlue">
                                    <CardInset>
                                        <Flex grow justify="space-between">
                                            <SimpleButton background="front.background" onClick={handleOnBack}>
                                                <ButtonInset>Cancel</ButtonInset>
                                            </SimpleButton>
                                            <Spacer width={10} />
                                            {submitButtonMemo}
                                        </Flex>
                                    </CardInset>
                                </CardSection>
                            </CardHolder>
                        </CardInset>
                    </Card>
                    <Spacer height={80} />
                </Flex>
            </PageWrapper>
        </ModalPage>
    )
}

const CardHolder = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* min-height: 80vh; */
`

const TextHolder = styled.div<{ highlight?: boolean }>`
    max-width: 300px;

    white-space: nowrap;
    padding: 2px 8px;
    margin: -2px 0px -2px -8px;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;

    ${(p) =>
        p.highlight &&
        css`
            border: 1px solid ${p.theme['front.subtleInfo.background.strongerII']};
            background-color: ${p.theme['front.subtleInfo.background']};
            color: ${p.theme['front.subtleInfo.text']};
        `}
`

const Field = styled.span`
    font-style: italic;
    color: ${(p) => p.theme['front.text']};
    margin-right: 2px;
    padding-bottom: 2px;
    border-bottom: 1px solid ${(p) => p.theme['front.text.subtlerIII']};
    border-bottom-style: dotted;
`
