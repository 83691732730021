import { ButtonInset } from '@/components/buttons/buttonInset'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { Flex } from '@/components/layout/flex'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { useCreateActions } from './hooks'
import { getReportUrl } from '../../../helpers'
import { useReportParams } from '../../../hooks'
import { useRefTaker } from '@/hooks/general/useRefTaker'
import { Text } from '@/components/general/text'
import { Spacer } from '@/components/layout/spacer'
import { Floater } from '@/components/layout/floater'
import { FloaterInset } from '@/components/layout/floaterInset'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const CreateReportButton: React.FC = () => {
    const navigate = useNavigate()
    const { applicationId } = useReportParams()
    const { isLoading, create } = useCreateActions()
    const [anchor, setAnchor] = useRefTaker()
    const [isConfirmationShown, setIsConfirmationShown] = useState(false)

    const watcher = isLoading ? 'started' : undefined

    const handleToggleConfirmation = () => {
        setIsConfirmationShown(!isConfirmationShown)
    }

    const handleCloseConfirmation = () => {
        setIsConfirmationShown(false)
    }

    const handleCreateClick = async () => {
        const { report } = await create()

        const reportUrl = getReportUrl(applicationId, report.id)

        navigate(reportUrl)
    }

    return (
        <Flex grow justify="flex-end">
            <div ref={setAnchor}>
                <SimpleButton background="front.accent.color" onClick={handleToggleConfirmation}>
                    <ButtonInset>New report</ButtonInset>
                </SimpleButton>
                <Spacer height={6} />
            </div>
            <Floater
                anchor={anchor}
                shouldShow={isConfirmationShown}
                placement="bottom"
                onHide={handleCloseConfirmation}
            >
                <FloaterInset padding="medium">
                    <Flex style={{ maxWidth: 300 }} column>
                        <Text bold color="front.accent.text">
                            Did you look through the application?
                        </Text>
                        <Spacer height={6} />
                        <Text color="front.accent.text">
                            Make sure to confirm the current information and resolve any conflicts on the application,
                            before creating the report.
                        </Text>
                        <Spacer height={12} />
                        <Flex>
                            <SimpleButton background="front.success.color" onClick={handleCreateClick}>
                                <ButtonLoadingCover background="front.success.color" watcher={watcher}>
                                    <ButtonInset>Create report</ButtonInset>
                                </ButtonLoadingCover>
                            </SimpleButton>
                        </Flex>
                    </Flex>
                </FloaterInset>
            </Floater>
        </Flex>
    )
}
