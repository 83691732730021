import moment from 'moment'
import React from 'react'

import { Dispute } from '../../store/disputes/types'
import { numberWithCurrency, uppercaseFirstLetter } from '../../utils'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { LinkButton } from '../../components/buttons/linkButton'
import { LinkButtonArrow } from '../../components/buttons/linkButtonArrow'
import { shortenId } from '../../components/general/idShortener'
import { List } from '../../components/layout/list'
import { Spacer } from '../../components/layout/spacer'
import { Text } from '../../components/general/text'
import { TimelineCard } from '../../components/listPages/timelineCard'
import styled from 'styled-components'
import { DateFormats } from '../../utils/dateUtils'

export const DisputeTransactionTimelineCard: React.FC<{ dispute: Dispute }> = ({ dispute }) => {
    if (!dispute.transaction) return null

    return (
        <TimelineCard title={<Text bold>Transaction</Text>}>
            <List
                background="front.subtleAccent.background"
                items={{
                    'Text on statement': dispute.transaction.textOnStatement,
                    'Processed': dispute.transaction?.processedAt
                        ? moment(dispute.transaction.processedAt).format(
                              DateFormats.dayAndTime(moment(dispute.transaction.processedAt))
                          )
                        : '-',
                    'Amount': `${numberWithCurrency(dispute.transaction.currency, `${dispute.transaction.amount}`,
                    dispute.transaction.processedAt)} ${dispute.transaction.currency}`,
                    '3-D Secure': uppercaseFirstLetter(dispute.transaction.threedSecure),
                    'Reference': dispute.transaction.reference,
                    'Transaction': {
                        type: 'custom',
                        node: (
                            <ButtonOutset>
                                <LinkButton target="managerTransaction" to={`/transactions/${dispute.transaction.id}`}>
                                    <ButtonInset noVerticalPadding>
                                        <LinkButtonArrow background="front.subtleAccent.background" shouldBeBordered />
                                        <Spacer width={5} />
                                        {`#${shortenId(dispute.transaction.id)}`}
                                    </ButtonInset>
                                </LinkButton>
                            </ButtonOutset>
                        )
                    }
                }}
                switchToRowsAt={500}
                template="auto min-content min-content min-content min-content min-content"
            />
        </TimelineCard>
    )
}

const ButtonOutset = styled.div`
    margin-left: -10px;
`
