import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ValidationStatus } from '../../hooks/general/useForm'
import {
    AutocompleteDispatchClearMerchants,
    AutocompleteDispatchFindMerchants,
    AutocompleteDispatchRemoveAgents,
    AutocompleteDispatchSearchAgents
} from '../../store/autocomplete/actions'
import { MCCActions } from '../../store/mcc/actions'
import { RootState } from '@/store'
import { MODAL_ID } from '../modals/modalIds'
import { ButtonInset } from '../buttons/buttonInset'
import { Keyface } from '../hotkeys/keyface'
import { SlimLoader } from '../loaders/loader'
import { TextInputSelect } from './textInputSelect'
import { Color } from '../../styles/colors'

const prefix = '_/'
export function TextInputAutocompleteWithRef(
    {
        placeholder,
        selected,
        onSelect,
        noClear,
        dropdownId,
        forEntity,
        isSeamless,
        isSimple,
        isHigher,
        onBlur,
        alignCoverRight,
        noBullets,
        validation,
        allowAnyInput,
        maintainList,
        overBackground,
        cy,
        searchDatasetOnly,
        inputCoverMemo
    }: {
        placeholder: string
        inputCoverMemo?: React.ReactChild
        selected?: string
        overBackground: Color
        dropdownId: MODAL_ID
        noBullets?: boolean
        alignCoverRight?: boolean
        isSimple?: boolean
        isSeamless?: boolean
        noClear?: boolean
        validation?: ValidationStatus
        isHigher?: boolean
        allowAnyInput?: boolean
        maintainList?: boolean
        cy?: string
        searchDatasetOnly?: boolean
        onBlur?: (e?: React.KeyboardEvent<HTMLInputElement>) => void
        onSelect?: (item: string) => void
        forEntity: 'merchants' | 'merchantsByMID' | 'agents' | 'mcc'
    },
    ref: any
): ReactElement {
    const [searchedForValue, setSearchedForValue] = useState(undefined)
    const [value, setValue] = useState(selected)
    const dispatch = useDispatch()
    const isInitiallySelectedValueLoaded = useRef(false)
    const results = useSelector((state: RootState) => {
        if (forEntity === 'merchants' || forEntity == 'merchantsByMID') return state.autocomplete.merchants as any
        if (forEntity === 'agents') {
            return state.autocomplete.agents as any
        }
        if (forEntity === 'mcc') return state.mcc as any
        return null
    })
    const dataset = useSelector((state: RootState) => state.autocomplete.agents.dataset as any)

    const selectedItemId = useMemo(() => {
        if (!selected) return undefined
        if (forEntity === 'merchantsByMID') return results.at?.[results.atMid?.[selected]]?.id
        return selected
    }, [selected, forEntity, results.at, results.atMid])

    const actions = useMemo(() => {
        if (forEntity === 'merchants' || forEntity === 'merchantsByMID')
            return {
                handleInitialLoad: (selected: string) => {
                    dispatch(
                        AutocompleteDispatchFindMerchants(
                            forEntity === 'merchants' ? `id:${selected}` : selected,
                            selected ? [selected] : undefined
                        )
                    )
                },
                handleEnter: (val: string) => {
                    dispatch(AutocompleteDispatchFindMerchants(val, selected ? [selected] : undefined))
                },
                handleClear: () => dispatch(AutocompleteDispatchClearMerchants())
            }
        if (forEntity === 'agents')
            return {
                handleInitialLoad: (selected: string) => {
                    dispatch(
                        AutocompleteDispatchSearchAgents(
                            '',
                            selected,
                            selected ? [selected] : undefined,
                            maintainList,
                            searchDatasetOnly
                        )
                    )
                },
                handleEnter: (val: string) => {
                    dispatch(
                        AutocompleteDispatchSearchAgents(
                            val,
                            undefined,
                            selected ? [selected] : undefined,
                            maintainList,
                            searchDatasetOnly
                        )
                    )
                },
                handleClear: () => dispatch(AutocompleteDispatchRemoveAgents())
            }
        if (forEntity === 'mcc')
            return {
                handleInitialLoad: (selected: string) => {
                    dispatch(MCCActions.MCC_FIND(''))
                },
                handleEnter: (val: string) => {
                    dispatch(MCCActions.MCC_FIND(val))
                },
                handleClear: () => dispatch(MCCActions.MCC_CLEAR())
            }
        return undefined
    }, [dispatch, selected, maintainList, searchDatasetOnly, forEntity])

    useEffect(() => {
        if (selected && isInitiallySelectedValueLoaded.current === false) {
            if (forEntity === 'merchantsByMID') {
                if (results.atMid[selected] === undefined) {
                    actions?.handleInitialLoad(selected)
                }
            } else if (forEntity === 'merchants' || forEntity === 'agents' || forEntity === 'mcc') {
                if (results.at[selected] === undefined) {
                    actions?.handleInitialLoad(selected)
                }
            }
            isInitiallySelectedValueLoaded.current = true
        }
    }, [actions, selected, forEntity, results.atMid, results.at])

    const textForItem = useCallback(
        (id, shouldExpand) => {
            if (!id) return undefined
            if (allowAnyInput && id === prefix + searchedForValue) return `Use «${searchedForValue}»`
            if (forEntity === 'merchants' || forEntity === 'merchantsByMID') {
                const name = results.at[id]?.name || 'Unknown merchant'
                const mid = results.at[id]?.merchantId || 'No MID'
                return name || mid ? `${name} · ${mid}` : ''
            }
            if (forEntity === 'agents') {
                if (searchDatasetOnly) return dataset.find((d: any) => d.id === id)?.email || ''
                const email = results.at[id]?.email || 'Unknown agent'
                return `${email}` || ''
            }
            if (forEntity === 'mcc') {
                const mcc = results.at[id]
                if (!mcc) return undefined
                if (shouldExpand) return `${mcc.value} (${mcc.label})`
                return mcc.value || ''
            }
            return ''
        },
        [results, searchDatasetOnly, forEntity, searchedForValue, dataset, allowAnyInput]
    )

    const overrideContentMemo = useMemo(() => {
        if (results.loadingStatus === 'started')
            return (
                <ButtonInset>
                    <SlimLoader background="front.background" color="front.accent.color" />
                </ButtonInset>
            )

        if (value !== searchedForValue)
            return (
                <ButtonInset>
                    Press <Keyface k="enter" /> to search
                </ButtonInset>
            )

        return undefined
    }, [value, searchedForValue, results.loadingStatus])

    const handleBlankEnter = useCallback(
        (value) => {
            if (results.loadingStatus === 'started') return null
            setSearchedForValue(value)
            actions?.handleEnter(value)
        },
        [actions, setSearchedForValue, results]
    )

    const handleChange = useCallback((e, val) => {
        setValue(val)
    }, [])

    const handleSelect = useCallback(
        (val) => {
            if (!onSelect) return
            if (forEntity === 'merchantsByMID') onSelect(results.at[val]?.merchantId)
            else onSelect(results.at[val]?.id)
        },
        [results, forEntity, onSelect]
    )

    const items = useMemo(() => {
        let list: any = undefined
        if (forEntity === 'mcc') list = results.all
        else list = results.forCurrentQuery

        if (allowAnyInput && !list.includes(searchedForValue)) {
            list = [prefix + searchedForValue, ...list]
        }
        return list
    }, [results, forEntity, allowAnyInput, searchedForValue])

    const adjustSelection = useCallback(
        (val) => {
            if (!allowAnyInput) return val
            if (val === prefix + searchedForValue) return searchedForValue
            return val
        },
        [searchedForValue, allowAnyInput]
    )

    if (!actions) throw '`forEntity` not defined.'

    return (
        <>
            <TextInputSelect
                placeholder={placeholder}
                lazyLoaded={true}
                items={items}
                overBackground={overBackground}
                cy={cy}
                noClear={noClear}
                textForItem={textForItem}
                onChange={handleChange}
                selected={selectedItemId}
                onBlur={onBlur}
                adjustSelection={adjustSelection}
                ref={ref}
                onBlankEnter={handleBlankEnter}
                alignCoverRight={alignCoverRight}
                dropdownId={dropdownId}
                shouldFilter={false}
                noBullets={noBullets}
                inputCoverMemo={inputCoverMemo}
                isSimple={isSimple}
                isSeamless={isSeamless}
                validation={validation}
                overrideContentMemo={overrideContentMemo}
                isHigher={isHigher}
                onSelect={handleSelect}
            />
        </>
    )
}

export const TextInputAutocomplete = React.forwardRef(TextInputAutocompleteWithRef)
