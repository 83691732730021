import { createActionCreator } from 'deox'

import { WatcherID } from '../../watcher/types'
import { Referral } from './types'

// --

const Prefix = 'APPLICATION_INTERNALS_REFERRAL'

export const ApplicationInternalsReferralActions = {
    UPDATE_IDS: createActionCreator(
        `${Prefix}/STORE_CHECKS_STATUS`,
        (resolve) => (applicationId: string, ids: string[]) =>
            resolve({ applicationId, ids })
    ),
    REMOVE: createActionCreator(
        `${Prefix}/REMOVE`,
        (resolve) => (id: string, partnerId: string, watcherId: WatcherID) =>
            resolve({ id, partnerId, watcherId })
    ),
    ADD: createActionCreator(
        `${Prefix}/ADD`,
        (resolve) => (id: string, partnerId: string, watcherId: WatcherID) =>
            resolve({ id, partnerId, watcherId })
    ),
    FETCH: createActionCreator(
        `${Prefix}/FETCH`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (applicationId: string, ids: Referral[]) =>
            resolve({ applicationId, ids })
    )
}
