import { Dropdown, Flex } from '@/design-system'
import { Application } from '@/store/applications/types'
import { MerchantAccount } from '@/store/merchantAccounts/types'
import React from 'react'
import { MerchantSidebarLinksTrigger } from './MerchantSidebarLinksTrigger'
import { MerchantSidebarLinksAccounts } from './MerchantSidebarLinksAccounts'
import { MerchantSidebarLinksApplication } from './MerchantSidebarLinksApplication'
import { useTheme } from 'styled-components'

interface MerchantSidebarLinksProps {
    application: Application
    accounts: MerchantAccount[]
}

export const MerchantSidebarLinks: React.FC<MerchantSidebarLinksProps> = (props) => {
    const { application, accounts } = props

    const theme = useTheme()

    const darkMode = theme.name === 'managerInspired' ? 'dark' : undefined

    return (
        <Dropdown>
            <Dropdown.Trigger asChild>
                <span className={darkMode}>
                    <Flex>
                        <MerchantSidebarLinksTrigger />
                    </Flex>
                </span>
            </Dropdown.Trigger>
            <Dropdown.Portal>
                <Dropdown.Content side="right">
                    <MerchantSidebarLinksApplication application={application} />
                    <MerchantSidebarLinksAccounts accounts={accounts} />
                </Dropdown.Content>
            </Dropdown.Portal>
        </Dropdown>
    )
}
