import { Flex } from '@/components/layout/flex'
import React from 'react'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { Text } from '@/components/general/text'
import { useQueryParams } from '@/hooks/general/useQueryParam'

interface FileProps {
    fileId?: string
    children: string
}

const queryParams = ['preview']
export const File: React.FC<FileProps> = (props) => {
    const { children, fileId } = props

    const [, setQuery] = useQueryParams(queryParams)

    const handleOnClick = async () => {
        if (fileId) {
            setQuery({ preview: fileId })
        }
    }

    if (!fileId) {
        return (
            <Text color="front.text.subtlerI" strike>
                {children}
            </Text>
        )
    }

    return (
        <Flex>
            <SimpleButton onClick={handleOnClick}>{children}</SimpleButton>
        </Flex>
    )
}
