import { forwardRef } from 'react'
import styled, { css } from 'styled-components'

const RowCheckmarkWithRef: React.ForwardRefRenderFunction<
    any,
    { selected?: boolean; disabled?: boolean; id?: string; alreadySelected?: boolean; cy?: string }
> = ({ selected, id, disabled, alreadySelected, cy }, ref) => {
    return (
        <Checkholder
            key={id}
            disabled={disabled}
            selected={selected}
            data-cy={cy}
            alreadySelected={alreadySelected}
            data-id={id}
            data-selected={selected}
            className="rowCheckmark"
            ref={ref}
        >
            <Check disabled={disabled} selected={selected}>
                {selected && <Bubble />}
            </Check>
        </Checkholder>
    )
}

export const RowCheckmark = forwardRef(RowCheckmarkWithRef)

export const TableCircleStyles = {
    circle: css`
        flex-grow: 0;
        border: 1px solid ${(p) => p.theme['back.border.strongerI']};
        border-radius: 99px;
        width: 24px;
        height: 24px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
    `,
    holder: css`
        margin: 0 -10px;
        padding: 5px;
        padding-left: 0px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
    `
}

const Bubble = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 999px;
    background-color: currentColor;
`
const Check = styled.div<{ selected?: boolean; disabled?: boolean }>`
    ${TableCircleStyles.circle};
    ${(p) =>
        p.selected &&
        css`
            border: 1px solid ${(p) => p.theme['front.accent.color']};
            color: ${(p) => p.theme[`front.accent.color`]};
            background-color: ${(p) => p.theme['front.subtleAccent.background']};
        `};
    ${(p) =>
        p.disabled &&
        css`
            border: 1px solid ${(p) => p.theme['front.subtleAccent.background.strongerIII']};
            color: ${(p) => p.theme[`front.subtleAccent.background.strongerIII`]};
            background-color: ${(p) => p.theme['front.subtleAccent.background']};
        `};
`

const Checkholder = styled.div<{ selected?: boolean; disabled?: boolean; alreadySelected?: boolean }>`
    ${TableCircleStyles.holder};
    cursor: pointer;

    ${(p) =>
        p.disabled &&
        css`
            cursor: default;
            background-color: transparent !important;

            &:hover ${Check} {
                background-color: transparent !important;
            }
            &:active ${Check} {
                background-color: transparent !important;
            }
        `}
    ${(p) =>
        p.selected &&
        css`
            &:hover ${Check} {
                background-color: ${(p) => p.theme['front.subtleAccent.background.strongerII']};
            }
            &:active ${Check} {
                background-color: ${(p) => p.theme['front.subtleAccent.background.strongerIII']};
            }
        `}

    ${(p) =>
        !p.selected &&
        css`
            &:hover ${Check} {
                background-color: ${(p) => p.theme['back.background.strongerII']};
            }
            &:active ${Check} {
                background-color: ${(p) => p.theme['back.background.strongerIII']};
            }
        `}
    
    ${(p) =>
        p.alreadySelected &&
        css`
            cursor: default;
            ${Check} {
                color: ${p.theme['front.subtleAccent.background.strongerIII']} !important;
                border-color: ${p.theme['front.subtleAccent.background.strongerIII']} !important;
            }

            &:hover ${Check} {
                background-color: transparent !important;
            }

            &:active ${Check} {
                background-color: transparent !important;
            }
        `}
`
