import React, { ReactElement, useCallback, useMemo } from 'react'

import { DisputeReasons, DisputesFilters } from '../../store/disputes/types'
import { uppercaseFirstLetter } from '../../utils'
import { TextInputSelectMultiple } from '../forms/textInputSelectMultiple'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

export const ListPageFilterDisputeReason = ({
    filters,
    isDark
}: {
    filters: FiltersController<DisputesFilters>
    isDark: boolean
}): ReactElement => {
    const dictionary = useMemo(() => {
        return DisputeReasons.reduce((acc, disputeReasonKey) => {
            acc[disputeReasonKey] = uppercaseFirstLetter(disputeReasonKey)
            return acc
        }, {} as any)
    }, [])

    const textForItem = useCallback(
        (key) => {
            return dictionary[key]
        },
        [dictionary]
    )

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                disputes_reason: items
            }))
        },
        [filters]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelectMultiple
                preSelectedItems={filters.get.disputes_reason}
                isHigher={isDark}
                isSeamless
                textForItem={textForItem}
                dropdownId="ListPage.Filters.DisputeReason"
                onSelect={onSelect}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                placeholder="-"
                cy="reason-filter"
                items={DisputeReasons}
                selectionLabel="reason/reasons"
            />
            <SelectedListForListPageFilters<DisputesFilters>
                filterKey="disputes_reason"
                filters={filters}
                noHolderEmphasize={isDark}
                isDark={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
