import { LoadingStatus } from '../../utils'
import { Person } from '../applications/types'
import { TypoHighlight } from '../utils'

export type UBOOwnershipInfo = {
    name: string
    country?: string
    fraction: number
    formatted: string
}

export type ComputedOwnershipShares = {
    missing: {
        status: 'over-limit' | 'under-limit' | 'ok' | 'fraction'
        fraction: number
        formatted: string
    }
    list: {
        [name: string]: UBOOwnershipInfo
    }
}

export type DataProviderStateAssigned = {
    entityId: string
    state: 'assigned' | 'assigned-and-ready'
    linked: CompanyInfoPotentialMatch
    mutedConflicts?: MutedConflictsPayload
}

export type ConflictsStatus = 'all-muted' | 'no-conflicts' | 'has-conflicts' | 'has-conflicts-some-muted'
export type ConflictsAndOwnershipShares = {
    conflicts: ConflictsPayload
    conflictsStatus: ConflictsStatus
    ownershipShares: ComputedOwnershipShares
}

export interface DataPorviderStateAssignedAndReady extends DataProviderStateAssigned {
    state: 'assigned-and-ready'
    computed: ConflictsAndOwnershipShares
}

export interface DataPorviderStateUnassigned {
    state: 'unassigned'
    matches?: {
        loadingStatus: LoadingStatus
        list: CompanyInfoPotentialMatch[]
    }
}

export type DataProviderState =
    | {
          state: 'loading'
          matches?: {
              loadingStatus: LoadingStatus
              list: CompanyInfoPotentialMatch[]
          }
      }
    | {
          state: 'skipped'
          matches?: {
              loadingStatus: LoadingStatus
              list: CompanyInfoPotentialMatch[]
          }
      }
    | DataProviderStateAssigned
    | DataPorviderStateAssignedAndReady
    | DataPorviderStateUnassigned

export type ApplicationDataProvidersState = {
    forApplication: {
        [id: string]: DataProviderState
    }
}

export type MutedConflictsPayload = Partial<{
    name: string[]
    registrationNumbers: string[]
    email: string[]
    phone: string[]
    address_line_1: string[]
    address_line_2: string[]
    zipcode: string[]
    city: string[]
    country: string[]
    form: string[]
    director: {
        name: string[]
        date_of_birth: string[]
        address_line_1: string[]
        address_line_2: string[]
        zipcode: string[]
        city: string[]
        country: string[]
    }
    ownership: boolean
}>

export type CompanyInfoPotentialMatch = {
    company: {
        name: string
        registrationNumbers: string[]
        email: string
        addressLine1: string
        city: string
        country: string
        form: string
        phone: string
        zipcode: string
    }
    director: {
        name: string
        country?: string
    }
    id: string
    ownership: {
        beneficial: {
            name: string
            country?: string
            ownershipPercentage: number
        }[]
    }
}

export const InitialApplicationDataProvidersState: ApplicationDataProvidersState = {
    forApplication: {}
}

export type DataProvider = 'Bisnode' | 'Virk'

export type DataProviderOwnershipShares = {
    [personId: string]: number
}

export type DataProviderCompanyInfoFieldConflicts = {
    conflictsCount: number
    mutedConflictsCount: number
    forField: {
        [fieldKey: string]: Array<
            | {
                  type: 'mismatch'
                  application: string
                  dataProvider: string
                  isMuted: boolean
              }
            | {
                  type: 'typo'
                  application: string
                  dataProvider: string
                  highlights: TypoHighlight[]
                  isMuted: boolean
              }
        >
    }
}
export type ConflictsPayload = {
    company: DataProviderCompanyInfoFieldConflicts
    director: DataProviderDirectorConflict
    ownership: DataProviderOwnershipConflict
}
export type DataProviderDirectorConflictContext = {
    sot: {
        name: string
        country?: string
    }
}
export type DataProviderDirectorConflict =
    | {
          type: 'application-zero-directors'
          muted: boolean
      }
    | {
          type: 'not-found'
          muted: boolean
          ctx: DataProviderDirectorConflictContext
      }
    | {
          type: 'data-provider-blank'
      }
    | {
          type: 'country-mismatch'
          muted: boolean
          ctx: DataProviderDirectorConflictContext
      }
    | {
          type: 'no-conflicts'
      }
    | {
          type: 'wrong-role'
          muted: boolean
          ctx: DataProviderDirectorConflictContext
          applicationDirectorMatch: string
      }
    | {
          type: 'typo'
          muted: boolean
          ctx: DataProviderDirectorConflictContext & { highlights: TypoHighlight[] }
          applicationDirectorMatch: string
      }

export type DataProviderOwnershipConflictUBOsMismatch = {
    type: 'ubos-mismatch' | 'data-provider-blank' | 'application-zero-owners' | 'no-conflicts'
    muted: boolean
    matches: {
        type: 'partial' | 'full' | 'wrong-role'
        highlights?: TypoHighlight[]
        app: {
            id: string
            name: string
            country?: string
            role?: Person['role']
        }
        sot: {
            name: string
            country?: string
        }
    }[]
    perUBOConflicts: {
        [uboSotName: string]:
            | {
                  type: 'not-found'
              }
            | {
                  type: 'typo'
                  highlights: TypoHighlight[]
                  id: string
                  applicationValue: string
              }
    }
}

export type DataProviderOwnershipConflict = DataProviderOwnershipConflictUBOsMismatch
