import React, { ReactElement, useState } from 'react'

import { SimpleButton } from '../components/buttons/simpleButton'

export interface DialogState {
    title: string
    description: string | ReactElement
    action: {
        label?: string
        buttonBackground?: React.ComponentProps<typeof SimpleButton>['background']
        watcherId?: string
        justify?: 'flex-start' | 'space-between'
        action?: () => void
    }
}

export const DialogContext = React.createContext<{
    dialog?: DialogState
    setDialog: React.Dispatch<React.SetStateAction<DialogState | undefined>>
}>({
    dialog: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDialog: () => {}
})

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const dialogState = useState<DialogState | undefined>(undefined)

    return (
        <DialogContext.Provider value={{ dialog: dialogState[0], setDialog: dialogState[1] }}>
            {children}
        </DialogContext.Provider>
    )
}
