import moment from 'moment'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { CardInset } from '../../components/cards/cardInset'
import { ExpandableCard } from '../../components/cards/expandableCard'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { Text } from '../../components/general/text'
import { Timeline } from '../../components/taskPages/timeline'
import { Icon } from '../../components/icons/icon'
import { Transaction } from '../../store/transactions/types'
import { uppercaseFirstLetter } from '../../utils'
import { DateFormats } from '../../utils/dateUtils'
import { TransactionGridView } from './Transactions.IDGridView'
import { TransactionSettlement } from './Transactions.IDSettlement'

export const TransactionModalTrail: React.FC<{
    transaction: Transaction
}> = ({ transaction }) => {
    const timelineNodes = useMemo(() => {
        const trail = transaction.trail ? transaction.trail : [transaction]

        return trail.map((t) => {
            const icon = (() => {
                switch (t.type) {
                    case 'authorization':
                        return <Icon type="transactionAuthorisation" />
                    case 'capture':
                        return <Icon type="transactionCapture" />
                    case 'credit':
                        return <Icon type="transactionCredit" />
                    case 'debit':
                        return <Icon type="transactionDebit" />
                    case 'refund':
                        return <Icon type="transactionRefund" />
                }
            })()

            return {
                tabIndex: t.type,
                date: moment(t.processedAt),
                node: (
                    <React.Fragment key={t.id}>
                        <ExpandableCard
                            isInitiallyExpanded={transaction.id == t.id}
                            headerMemo={
                                <>
                                    <Flex align="center" grow justify="space-between">
                                        <Flex align="center">
                                            {icon}
                                            <Spacer width={10} />
                                            <Text bold>{uppercaseFirstLetter(t.type)}</Text>
                                        </Flex>
                                        <Flex align="center">
                                            <Text color="front.text.subtlerI">
                                                {moment(t.processedAt).format(DateFormats.completeStamp)}
                                            </Text>
                                            <StatusCircle isSuccess={t.status.code === 20000} />
                                        </Flex>
                                    </Flex>
                                    <Spacer width={10} />
                                </>
                            }
                        >
                            <CardInset>
                                <TransactionGridView t={t} />
                                {t.settlement && (
                                    <TransactionSettlement
                                        s={t.settlement}
                                        link={`/settlements/${t.settlementLink.substr(
                                            t.settlementLink.lastIndexOf('/') + 1
                                        )}`}
                                    />
                                )}
                            </CardInset>
                        </ExpandableCard>
                        <Spacer height={10} />
                    </React.Fragment>
                )
            }
        })
    }, [transaction])

    return <Timeline items={timelineNodes} noTabs hotkeysScope="TransactionPage" />
}

const StatusCircle = styled.div<{ isSuccess?: boolean }>`
    width: 4px;
    height: 4px;
    border-radius: 999px;
    margin-left: 10px;
    background-color: ${(p) => (p.isSuccess ? p.theme['front.accent.color'] : p.theme['front.danger.color'])};
`
