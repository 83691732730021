import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { RootState } from '@/store'

export const ConditionallyShowInteractionDate: React.FC<{ children: any; isAgent?: boolean; right: number }> = ({
    children,
    right,
    isAgent
}) => {
    const shouldShow = useSelector((state: RootState) => state.interface.showInteractionsDate)
    return (
        <Shower shouldShow={shouldShow} right={right} isAgent={isAgent}>
            {children}
        </Shower>
    )
}

const Shower = styled.div<{ shouldShow?: boolean; right?: number; isAgent?: boolean }>`
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: 0.2s ease all;
    transform: translateX(-4px);
    position: absolute;
    height: 14px;
    color: ${(p) => (p.isAgent ? p.theme['front.text.subtlerI'] : p.theme['front.accent.text.subtlerI'])};

    ${(p) =>
        p.shouldShow &&
        css`
            transform: translateX(0);
            opacity: 1;
        `}

    ${(p) =>
        p.right !== undefined &&
        css`
            right: ${p.right}px;
        `}
`
