import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const ApplicationDedicatedInternalSectionWrapper: React.FC<{ children: any }> = ({ children }) => {
    const params = useParams() as Params
    const { id } = params || {}
    const dataProviderState = useSelector((state: RootState) => state.dataProviders.forApplication?.[id]?.state)
    if (
        dataProviderState !== 'assigned' &&
        dataProviderState !== 'assigned-and-ready' &&
        dataProviderState !== 'skipped'
    )
        return null
    return <span>{children}</span>
}
