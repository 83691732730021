import React, { useState } from 'react'
import { Table } from '@/components/tables/table'
import { getApplicationTasksRows } from './get-application-tasks-rows'
import { useTasks } from '../../../api'
import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { TasksPagination } from '../tasks-pagination'

const cols = [{ text: 'Due' }, { text: 'Assignee' }, { text: 'State' }, { text: 'Actions' }]

interface ApplicationTasksTableProps {
    applicationId: string
}

export const ApplicationTasksTable: React.FC<ApplicationTasksTableProps> = (props) => {
    const { applicationId } = props
    const [page, setPage] = useState(1)

    const { data, isLoading } = useTasks({ applicationId, page, per_page: 10 })

    const rows = getApplicationTasksRows(data)

    const handleOnPageChanged = (page: number) => {
        setPage(page)
    }

    return (
        <>
            <Card title="Tasks">
                <CardInset>
                    <Table
                        displayLoader={isLoading}
                        loaderRows={20}
                        rows={rows}
                        cols={cols}
                        background="front.background"
                        columnLayout="1fr 1fr 1fr auto"
                    />
                </CardInset>
            </Card>
            <TasksPagination tasks={data} onPageChanged={handleOnPageChanged} />
        </>
    )
}
