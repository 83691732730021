import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Flex } from '../../components/layout/flex'
import { LoaderView } from '../../components/loaders/loader'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { Spacer } from '../../components/layout/spacer'
import { MerchantDetailsSidebar } from '../../components/navigation/merchantDetailsSidebar'
import { ModalHeader } from '../../components/modals/modalHeader'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { RootState } from '@/store'
import { SettlementsDispatchFetchSettlement, SettlementDispatchUnstore } from '../../store/settlements/actions'
import { SettlementCardFees } from './Settlements.IDCardFees'
import { SettlementCardOtherPostings } from './Settlements.IDCardOtherPostings'
import { SettlementCardSummary } from './Settlements.IDCardSummary'
import { SettlementCardTransactions } from './Settlements.IDCardTransactions'
import { DateToWords } from '../../utils'
import { SettlementCardFeeDetails } from './Settlements.IDCardFeeDetails'
import { useParams } from 'react-router-dom'

type Params = { id: string; settlementId: string }

export const SettlementModal: React.FC = () => {
    const dispatch = useDispatch()
    const params = useParams() as Params

    const merchantId = params.id
    const settlementId = params.settlementId
    const settlement = useSelector((state: RootState) => {
        return state.settlements.settlements.at[settlementId]
    })
    const title = useMemo(() => {
        if (settlement?.merchantDetails?.account?.name)
            return `Settlement • ${settlement?.merchantDetails.account.name} → ${DateToWords(
                settlement.period?.startDate,
                true
            )}`
        return 'Loading…'
    }, [settlement])
    useModalStackSync(title, 'SettlementPage', 0)

    useEffect(() => {
        dispatch(SettlementsDispatchFetchSettlement(settlementId))
        return () => {
            dispatch(SettlementDispatchUnstore(settlementId))
        }
    }, [settlementId, dispatch])

    const pageContent = useMemo(() => {
        let backLink = '/settlements'
        if (merchantId) {
            backLink = `/merchant/${merchantId}/settlements`
        }

        if (!settlement || settlement.loadingStatus != 'done')
            return <LoaderView key="timelineLoader" overBackground="back.background" />
        return (
            <>
                <ModalHeader pageId="SettlementPage" backTo={backLink}>
                    <PageWrapper>
                        <PageHeader title="Settlement" subtitle="" noBottomBorder />
                    </PageWrapper>
                </ModalHeader>
                <PageWrapper>
                    <SettlementCardSummary settlement={settlement} />
                    <SettlementCardOtherPostings settlement={settlement} />
                    <SettlementCardFees settlement={settlement} />
                    <SettlementCardFeeDetails settlement={settlement} />
                    <SettlementCardTransactions settlement={settlement} />
                    <Spacer height={20} />
                </PageWrapper>
            </>
        )
    }, [settlement, merchantId])

    return (
        <Flex align="stretch">
            <PageContent marginLeft={0} noPadding>
                {pageContent}
            </PageContent>
            <MerchantDetailsSidebar
                isLoading={!settlement || settlement.merchantDetails?.loadingStatus != 'done'}
                merchantId={settlement?.merchantDetails?.account?.id}
                account={settlement?.merchantDetails?.account}
                application={settlement?.merchantDetails?.application}
            />
        </Flex>
    )
}
