import React, { ReactElement, useCallback, useMemo } from 'react'

import { TasksFilters, TaskTypes } from '../../store/tasks/types'
import { uppercaseFirstLetter } from '../../utils'
import { TextInputSelectMultiple } from '../forms/textInputSelectMultiple'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

export const ListPageFilterAlertType = ({
    filters,
    isDark
}: {
    filters: FiltersController<TasksFilters>
    isDark: boolean
}): ReactElement => {
    const dictionary = useMemo(() => {
        return TaskTypes.reduce((acc, taskReasonKey) => {
            acc[taskReasonKey] = uppercaseFirstLetter(taskReasonKey)
            return acc
        }, {} as any)
    }, [])

    const textForItem = useCallback(
        (key) => {
            return dictionary[key]
        },
        [dictionary]
    )

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                tasks_type: items
            }))
        },
        [filters]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelectMultiple
                preSelectedItems={filters.get.tasks_type}
                isHigher={isDark}
                isSeamless
                textForItem={textForItem}
                dropdownId="ListPage.Filters.AlertType"
                cy="type-filter"
                onSelect={onSelect}
                placeholder="-"
                items={TaskTypes}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                selectionLabel="type/types"
            />
            <SelectedListForListPageFilters<TasksFilters>
                filterKey="tasks_type"
                filters={filters}
                isDark={isDark}
                noHolderEmphasize={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
