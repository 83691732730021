import { put, select, take } from 'typed-redux-saga'

import { GET } from '../../generators/networking'
import { RootState } from '@/store'
import { USER_ACCOUNTS_STORE_ACCOUNT, UserAccountsDispatchGetAccount } from '../userAccounts/actions'
import {
    AutocompleteActionsFetchSelectedAgent,
    AutocompleteActionsFindMerchants,
    AutocompleteActionsSearchAgents,
    AutocompleteDispatchStoreAgents,
    AutocompleteDispatchStoreMerchants,
    AutocompleteDispatchStoreSelectedAgent,
    SagasForAutocomplete
} from './actions'

export class AutocompleteSagas implements SagasForAutocomplete {
    *searchAgents(action: AutocompleteActionsSearchAgents) {
        if (action.searchDatasetOnly) {
            const dataset = yield* select((state: RootState) => state?.autocomplete?.agents?.dataset)
            const filteredAgents = dataset.filter((p) => p.email.toLowerCase().includes(action.query.toLowerCase()))

            yield put(AutocompleteDispatchStoreAgents([...filteredAgents]))
            return
        }

        let agentsKept = yield* select((state: RootState) =>
            (action.keepIds || [])
                .filter((id) => state?.autocomplete?.agents?.all?.includes?.(id))
                .map((id) => state?.autocomplete?.agents?.at?.[id])
        )

        if (action.maintainList)
            agentsKept = yield* select((state: RootState) =>
                Object.keys(state?.autocomplete?.agents?.at).map((id) => state?.autocomplete?.agents?.at?.[id])
            )

        if (action.selectedId)
            yield GET({
                url: `${import.meta.env.VITE_API_ROOT}/users/${action.selectedId}`,
                onSuccessDispatch: (r) => {
                    return AutocompleteDispatchStoreAgents([
                        ...agentsKept.filter((a) => a.id !== action.selectedId),
                        {
                            id: action.selectedId,
                            ...r
                        }
                    ])
                },
                errorText: 'Failed to load the agents search.'
            })
        else
            yield GET({
                url: `${import.meta.env.VITE_API_ROOT}/users?query=${action.query}+role:admin`,
                onSuccessDispatch: (r) => {
                    const newUserIds = r.users.map((u: any) => u.id)
                    return AutocompleteDispatchStoreAgents([
                        ...agentsKept.filter((a) => !newUserIds.includes(a.id)),
                        ...r.users
                    ])
                },
                errorText: 'Failed to load the agents search.'
            })
    }

    *fetchSelectedAgent(action: AutocompleteActionsFetchSelectedAgent) {
        yield put(UserAccountsDispatchGetAccount(action.id, { isMerchant: false }))

        let result: { id: string }
        do {
            result = yield take(USER_ACCOUNTS_STORE_ACCOUNT)
        } while (result.id != action.id)

        const account = yield* select((state: RootState) => {
            return state.userAccounts.at[action.id]
        })

        yield put(AutocompleteDispatchStoreSelectedAgent(account.data))
        yield put(AutocompleteDispatchStoreAgents([account.data]))
    }

    *findMerchants(action: AutocompleteActionsFindMerchants) {
        const keptMids = yield* select((state: RootState) => {
            return (action.keepIds || [])
                .filter((id) => state?.autocomplete?.merchants?.all?.includes?.(id))
                .map((id) => state?.autocomplete?.merchants?.at?.[id])
        })

        yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/accounts?query=${action.query}`,
            onSuccessDispatch: (r) => {
                return AutocompleteDispatchStoreMerchants([...(keptMids || []), ...(r.accounts || [])])
            },
            errorText: 'Failed to load the MIDs search.'
        })
    }
}
