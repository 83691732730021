import { Url } from '@/services/common'
import { api } from '../api'
import { Record, Records } from '../types/records'

export const recordsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRecords: build.query<Records, Url>({
            query: (url) => {
                return { url }
            }
        }),
        getRecord: build.query<Record, Url>({
            query: (url) => {
                return { url }
            }
        })
    })
})

export const { useGetRecordQuery: useGetRecord, useGetRecordsQuery: useGetRecords } = recordsApi
