import { createActionCreator } from 'deox'

const Prefix = 'MERCHANT'

export const MerchantActions = {
    LOAD: createActionCreator(`${Prefix}/LOAD`, (resolve) => (id: string) =>
        resolve({ id })
    ),
    CLEANUP: createActionCreator(
        `${Prefix}/CLEANUP`,
        (resolve) => (id: string) => resolve({ id })
    )
}
