import { Checkbox } from '@/components/forms/checkbox'
import { Controller, useFormContext } from 'react-hook-form'

interface ItemCheckBoxProps {
    name: string
    value: string
}

export const ItemCheckBox: React.FC<ItemCheckBoxProps> = (props) => {
    const { name, value: passedValue } = props
    const { control } = useFormContext()
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => {
                const isChecked = value.includes(passedValue)

                const handleOnChange = (_, checked) => {
                    let values: string[] = []

                    if (checked) {
                        values = [...value, passedValue]
                    } else {
                        values = value.filter((option) => option !== passedValue)
                    }

                    onChange(values)
                }

                return <Checkbox label="" checked={isChecked} onChange={handleOnChange} />
            }}
        />
    )
}
