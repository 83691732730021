import styled, { css } from 'styled-components'

type TMaxWidth = 'medium' | 'small' | 'tiny' | 'large' | 'full' | 'larger'

export const PageWrapper: React.FC<{
    children: any
    maxWidth?: TMaxWidth
    noPadding?: boolean
    shouldCenter?: boolean
}> = ({ children, maxWidth = 'medium', noPadding, shouldCenter }) => {
    return (
        <ContainerPadding noPadding={noPadding}>
            <Holder maxWidth={maxWidth} shouldCenter={shouldCenter} className="PREVENT_MODAL_CLOSE">
                {children}
            </Holder>
        </ContainerPadding>
    )
}

const ContainerPadding = styled.div<{ noPadding?: boolean }>`
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;

    ${(p) =>
        p.noPadding &&
        css`
            padding: 0;
        `}

    @media print {
        padding: 0;
    }
`

const Holder = styled.div<{ maxWidth: TMaxWidth; shouldCenter?: boolean }>`
    width: 100%;
    align-self: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${(p) =>
        p.shouldCenter &&
        css`
            justify-content: center;
        `}

    ${(p) => {
        switch (p.maxWidth) {
            case 'tiny':
                return css`
                    max-width: 370px;
                `
            case 'small':
                return css`
                    max-width: 640px;
                `
            case 'large':
                return css`
                    max-width: 1000px;
                `
            case 'larger':
                return css`
                    max-width: 1080px;
                `
            case 'full':
                return css`
                    max-width: 100%;
                `
            default:
                return css`
                    max-width: 940px;
                `
        }
    }}

    @media print {
        max-width: 100%;
    }
`
