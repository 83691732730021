import { Link, Tooltip } from '@/design-system'
import _ from 'lodash'
import React from 'react'

interface HoverCardTokenProps {
    token: string
}

export const HoverCardToken: React.FC<HoverCardTokenProps> = (props) => {
    const { token } = props

    const truncatedToken = _.truncate(token, { length: 11, omission: '...' })

    return (
        <Tooltip delayDuration={0}>
            <Tooltip.Trigger>
                <Link weight="medium" asChild>
                    <span>{truncatedToken}</span>
                </Link>
            </Tooltip.Trigger>
            <Tooltip.Content>{token}</Tooltip.Content>
        </Tooltip>
    )
}
