import React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from '../utils'
import { ContentStyles, styles } from './styles'
import { Flex } from '../flex'
import { Separator } from '../separator'
import { Box } from '../box'

interface PopoverProps {
    children: React.ReactNode
    defaultOpen?: boolean
    open?: boolean
    onOpenChange?: (open: boolean) => void
}

const Popover = (props: PopoverProps) => {
    const { children, defaultOpen, open, onOpenChange } = props

    return (
        <PopoverPrimitive.Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
            {children}
        </PopoverPrimitive.Root>
    )
}

interface TriggerProps {
    testid?: string
    children: React.ReactNode
    asChild?: boolean
}

const Trigger = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Trigger>, TriggerProps>((props, ref) => {
    const { testid, children, asChild } = props

    return (
        <PopoverPrimitive.Trigger data-cy={testid} asChild={asChild} ref={ref}>
            {children}
        </PopoverPrimitive.Trigger>
    )
})

Trigger.displayName = 'Trigger'

interface ContentProps extends ContentStyles {
    children: React.ReactNode
    align?: 'start' | 'center' | 'end'
    side?: 'top' | 'right' | 'bottom' | 'left'
    collisionPadding?: number
}

const Content = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, ContentProps>((props, ref) => {
    const { children, align, side, collisionPadding, size } = props

    const classnames = cn(styles.content({ size }))

    return (
        <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content
                ref={ref}
                align={align}
                side={side}
                collisionPadding={collisionPadding}
                sideOffset={4}
                className={classnames}
            >
                {children}
            </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
    )
})

Content.displayName = 'Content'

interface FooterProps {
    children: React.ReactNode
}

const Footer: React.FC<FooterProps> = (props) => {
    const { children } = props

    return (
        <Flex direction="column" gap="6">
            <Separator />
            <Box>{children}</Box>
        </Flex>
    )
}

Footer.displayName = 'Footer'

interface CloseProps {
    children: React.ReactNode
    asChild?: boolean
}

const Close = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Close>, CloseProps>((props, ref) => {
    const { children, asChild } = props

    return (
        <PopoverPrimitive.Close asChild={asChild} ref={ref}>
            {children}
        </PopoverPrimitive.Close>
    )
})

Close.displayName = 'Close'

Popover.Trigger = Trigger
Popover.Content = Content
Popover.Footer = Footer
Popover.Close = Close

export { Popover }
