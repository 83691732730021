import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox } from '@/components/forms/checkbox'
import React from 'react'

interface FormCheckBoxProps {
    name: string
    label: string
    disabled?: boolean
}

export const FormCheckBox: React.FC<FormCheckBoxProps> = (props) => {
    const { name, label, disabled = false } = props

    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => (
                <Checkbox
                    disabled={disabled}
                    initialChecked={value === 'true'}
                    label={label}
                    onChange={(_, checked) => {
                        onChange(checked.toString())
                    }}
                />
            )}
        />
    )
}
