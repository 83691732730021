import { useDispatch } from 'react-redux'
import { ButtonInset } from '../../../../../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../../../../../components/buttons/watcherButton'
import { TasksCompanyChangesActions } from '../../../../../../../store/tasksCompanyChanges/actions'
import { MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'

interface ConfirmButtonProps {
    taskId: string
    applicationData?: MonitoredFetchedApplicationData
    after: string
}

export const ConfirmButton: React.FC<ConfirmButtonProps> = (props) => {
    const { taskId, applicationData, after } = props

    const dispatch = useDispatch()

    const handleOnConfirmAndUpdate = (_, watcherId) => {
        const applicationId = applicationData?.application.id
        const link = applicationData?.company.selfLink

        if (applicationId && link) {
            dispatch(
                TasksCompanyChangesActions.APPLY_CHANGE(watcherId, taskId, applicationId, link, {
                    name: after
                })
            )
        }
    }

    return (
        <WatcherButton background="front.subtleAccent.background" onClick={handleOnConfirmAndUpdate}>
            <ButtonInset>Confirm &amp; Update</ButtonInset>
        </WatcherButton>
    )
}
