import React, { useCallback, useMemo } from 'react'

import { AuthDispatchLogout } from '../../../store/auth/actions'
import { Flex } from '../../layout/flex'
import { Icon } from '../../icons/icon'
import { MODAL_ID } from '../../modals/modalIds'
import { SideBarLink } from './sidebarLink'
import { SidebarCallSettingsPanel } from './sidebarCallSettingsPanel'
import { SidebarLinkHolder } from './sidebarLinkHolder'
import { SidebarSettingsPanel } from './sidebarSettingsPanel'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useQueryParams } from '../../../hooks/general/useQueryParam'
import { zIndexes } from '../../../styles/zIndexes'

const queryParams = ['show-search']

export const Sidebar: React.FC<{
    hotkeysScope: MODAL_ID
}> = ({ hotkeysScope }) => {
    const dispatch = useDispatch()
    const [, setQuery] = useQueryParams(queryParams, undefined)

    const handleSearchIconClick = useCallback(() => {
        setQuery({
            'show-search': 'yes'
        })
    }, [setQuery])

    const renderedLinks = useMemo(() => {
        return [
            <SidebarLinkHolder label="Applications" key="applications" cy="applications-page-link" hotkey="alt+1">
                <SideBarLink
                    to="/applications"
                    query="?applications_status=submitted"
                    hotkey="alt+1"
                    hotkeysScope={hotkeysScope}
                >
                    <Icon type="sidebarMerchants" size={17} />
                </SideBarLink>
            </SidebarLinkHolder>,
            <SidebarLinkHolder label="Disputes" key="disputes" cy="disputes-page-link" hotkey="alt+2">
                <SideBarLink to="/disputes" query="?disputes_status=open" hotkey="alt+2" hotkeysScope={hotkeysScope}>
                    <Icon type="sidebarDisputes" size={16} />
                </SideBarLink>
            </SidebarLinkHolder>,
            <SidebarLinkHolder label="Alerts" key="alerts" cy="alerts-page-link" hotkey="alt+3">
                <SideBarLink to="/alerts" hotkey="alt+3" hotkeysScope={hotkeysScope}>
                    <Icon type="sidebarAlerts" size={16} />
                </SideBarLink>
            </SidebarLinkHolder>,
            <SidebarLinkHolder label="Transactions" key="transactions" cy="transactions-page-link" hotkey="alt+4">
                <SideBarLink to="/transactions" hotkey="alt+4" hotkeysScope={hotkeysScope}>
                    <Icon type="sidebarTransactions" size={16} />
                </SideBarLink>
            </SidebarLinkHolder>,
            <SidebarLinkHolder label="Settlements" key="settlements" cy="settlements-page-link" hotkey="alt+5">
                <SideBarLink to="/settlements" hotkey="alt+5" hotkeysScope={hotkeysScope}>
                    <Icon type="sidebarSettlements" size={17} />
                </SideBarLink>
            </SidebarLinkHolder>,
            <SidebarLinkHolder label="Tasks" key="tasks" cy="tasks-page-link" hotkey="alt+6">
                <SideBarLink to="/tasks" hotkey="alt+6" hotkeysScope={hotkeysScope}>
                    <Icon type="sidebarTasks" size={16} />
                </SideBarLink>
            </SidebarLinkHolder>,
            <SidebarLinkHolder label="ODD tasks" key="odd-tasks" cy="odd-tasks-page-link" hotkey="alt+7">
                <SideBarLink to="/odd-tasks/new" hotkey="alt+7" hotkeysScope={hotkeysScope}>
                    <Icon type="sidebarUpcomingODD" size={14} />
                </SideBarLink>
            </SidebarLinkHolder>,
            <SidebarLinkHolder label="More" key="management" cy="management-link" hotkey="alt+8">
                <SideBarLink to="/more/gateways" hotkey="alt+8" hotkeysScope={hotkeysScope}>
                    <Icon type="sidebarMore" size={16} />
                </SideBarLink>
            </SidebarLinkHolder>
        ]
    }, [hotkeysScope])

    return (
        <>
            <Bar>
                <Links>{renderedLinks}</Links>
                <Flex column align="stretch" style={{ width: '100%' }}>
                    <SidebarLinkHolder key="search" label="Search" cy="search">
                        <SideBarLink onClick={handleSearchIconClick}>
                            <Icon type="sidebarSearch" size={14} />
                        </SideBarLink>
                    </SidebarLinkHolder>
                    <SidebarCallSettingsPanel />
                    <SidebarSettingsPanel />
                    <SidebarLinkHolder label="Logout" cy="logout">
                        <SideBarLink
                            onClick={() => {
                                dispatch(AuthDispatchLogout())
                            }}
                        >
                            <Icon type="sidebarLogout" size={16} />
                        </SideBarLink>
                    </SidebarLinkHolder>
                    <Version>
                        <VersionNumber>{import.meta.env.VITE_VERSION}</VersionNumber>
                    </Version>
                </Flex>
            </Bar>
            <BarSpaceHolder />
        </>
    )
}

const Version = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
`

const VersionNumber = styled.div`
    padding: 0 6px;
    border-radius: 6px;
    font-size: 10px;
    color: ${(p) => p.theme['side.text']};
    background-color: ${(p) => p.theme['loaders']};
`

const BarSpaceHolder = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 49px;
    flex-shrink: 0;
`

const Bar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${(p) => p.theme['side.background']};
    border-right: 1px solid ${(p) => p.theme['side.border']};
    z-index: ${zIndexes.modals.megamodal};

    @media print {
        display: none !important;
    }
`

const Links = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    &:focus {
        outline: none;
    }
`
