import dotProp from 'dot-prop'
import { useSelector } from 'react-redux'
import { ExternalLink } from '../../../components/buttons/externalLink'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { RootState } from '@/store'
import { getWebsiteUrl } from '../../../utils/formatting'

export const MerchantApplicationWebsiteLink: React.FC<{
    applicationId?: string
    indexPath: MerchantApplicationResourceIndexPath
}> = ({ applicationId, indexPath }) => {
    const rawValue = useSelector((state: RootState) => {
        return dotProp.get<string>(
            state,
            `applicationResources.data.forApplication.${applicationId}.${indexPath.resourceKey}.fields.${
                indexPath.subsectionIndex || 0
            }.${indexPath.fieldKey}`
        )
    })

    if (!rawValue) return null
    return (
        <ExternalLink key={rawValue} target="blank" url={getWebsiteUrl(rawValue)}>
            Visit this website
        </ExternalLink>
    )
}
