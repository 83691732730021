import React, { useCallback, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import moment, { Moment } from 'moment'

import { MerchantSidebarShadow, MERCHANT_SIDEBAR_SIZE } from './MerchantSidebars'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { useOnPropagatingDocumentClick } from '../../hooks/general/useOnPropagatingDocumentClick'
import { FloatingSidebarHeader } from '../../components/layout/floatingSidebarHeader'
import { useMerchantApplicationAllComments } from './Application/useMerchantApplicationAllComments'
import { MerchantInteractionComment } from './MerchantSidebarInteractionsComment'
import { useMerchantApplicationStateChange } from './Application/useMerchantApplicationStateChanges'
import { MerchantInteractionStateChange } from './MerchantSidebarInteractionsStateChange'
// eslint-disable-next-line max-len
import { MerchantApplicationCommentsModalMessageBox } from './MerchantSidebarInteractionsCommentBox'
import { CardInset } from '../../components/cards/cardInset'
import { useMerchantApplicationEditsMade } from './Application/useMerchantApplicationEditsMade'
import { MerchantInteractionMadeEdits } from './MerchantSidebarInteractionsMadeEdits'
import { MerchantInteractionDateSeparator } from './MerchantSidebarInteractionsDateSeparator'
import { Spacer } from '../../components/layout/spacer'
import { Flex } from '../../components/layout/flex'
import { useDispatch, useSelector } from 'react-redux'
import { InterfaceActions } from '../../store/interface/actions'
import { useHasApplicationSidebarLoaded } from './Application/useHasApplicationSidebarLoaded'
import { LoaderView } from '../../components/loaders/loader'
import { RootState } from '@/store'
import { Text } from '../../components/general/text'

// eslint-disable-next-line max-len
import { useMerchantApplicationFormChangesBuilder } from './Application/useMerchantApplicationFormChangesBuilder'
import { useFieldSidebarScrollToLatest } from './Application/useFieldSidebarScrollToLatest'
import { MarkdownPreviewItem } from '../../components/general/markdownPreviewItem'
import { LinkButton } from '../../components/buttons/linkButton'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { LinkButtonArrow } from '../../components/buttons/linkButtonArrow'
import { ConvertIndexPathStringToIndexPath } from '../../store/applicationResources/utils'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const InteractionsParams = ['interactions']
export const MerchantInteractions: React.FC = () => {
    const params = useParams() as Params
    const { id } = params
    const [query, setQuery] = useQueryParams(InteractionsParams, undefined)
    const dispatch = useDispatch()
    const { changeFormatter } = useMerchantApplicationFormChangesBuilder(id)
    const mostCommentedField = useSelector(
        (state: RootState) => state.applicationLoading.at?.[id]?.fieldWithMostComments
    )

    const comments = useMerchantApplicationAllComments(id)
    const notes = useMerchantApplicationStateChange(id)
    const edits = useMerchantApplicationEditsMade(id)
    const hasSidebarLoaded = useHasApplicationSidebarLoaded(id)
    const shouldShow = !!query['interactions']
    const { setLastPoint, scrollToBottom } = useFieldSidebarScrollToLatest(shouldShow)
    const unreadComments = useSelector(
        (state: RootState) => state.applicationInternals.needingAttention?.[id]?.unreadComments
    )

    const [push, pop] = useModalStackSync(null, 'Merchant.Interactions', undefined, true)
    useEffect(() => {
        if (shouldShow) {
            push()
            return () => {
                pop()
            }
        }
    }, [shouldShow, pop, push])
    const handleExitClick = useCallback(() => {
        setQuery((q: any) => ({
            interactions: undefined
        }))
    }, [setQuery])
    useHotkey({
        keys: 'esc, alt+esc',
        action: handleExitClick,
        scope: 'Merchant.Interactions',
        clue: 'right'
    })

    useOnPropagatingDocumentClick(
        useCallback(() => {
            setQuery((q: any) => ({
                interactions: undefined
            }))
        }, [setQuery]),
        'data-prevent-interactions-exit',
        shouldShow
    )

    const timeline = useMemo(() => {
        const getDate = (a: any): Moment | undefined => {
            let date: Moment | undefined = undefined
            if (a.at) {
                date = moment(a.at)
            } else if (a.comment.createdAt) {
                date = moment(a.comment.createdAt)
            } else {
                throw "Couldn't find a date on the timeline item"
            }
            return date
        }

        const items = [...comments, ...edits, ...notes].sort((a, b) => {
            return getDate(a)?.isBefore(getDate(b)) ? -1 : 1
        })

        let newItems = [...items]
        if (items.length) {
            const format = 'DD MMMM, YYYY'
            newItems.push({
                date: getDate(items[0])?.format(format),
                at: getDate(items[0])?.subtract(1, 'day').toISOString()
            })
            for (let i = 0; i < items.length - 1; i++) {
                if (getDate(items[i])?.format(format) != getDate(items[i + 1])?.format(format)) {
                    newItems.push({
                        date: getDate(items[i + 1])?.format(format),
                        at: getDate(items[i])?.toISOString()
                    })
                }
            }
        }

        newItems = newItems.sort((a, b) => {
            const itemDate: Moment | undefined = getDate(a)
            if (!itemDate) return -1
            return itemDate.isBefore(getDate(b)) ? -1 : 1
        })

        return newItems
    }, [comments, edits, notes])

    const handleMouseEnter = useCallback(() => {
        dispatch(InterfaceActions.SET_SHOW_INTERACTIONS_DATE(true))
    }, [dispatch])

    const handleMouseLeave = useCallback(() => {
        dispatch(InterfaceActions.SET_SHOW_INTERACTIONS_DATE(false))
    }, [dispatch])

    const messageBoxAfter = useMemo(() => {
        if (!mostCommentedField?.fieldKey) return null
        return (
            <Flex column>
                <Spacer height={10} />
                <Text color="front.text.subtlerI" cy="comment-left-on">
                    <Flex align="center">
                        <Spacer height={0} width={15} />
                        <Text>Message will be left on&nbsp;</Text>
                        <Field>{changeFormatter.textForFieldName(mostCommentedField?.fieldKey)}</Field>
                    </Flex>
                </Text>
            </Flex>
        )
    }, [changeFormatter, mostCommentedField])

    const previewBoxRenderer = useCallback((child) => {
        return (
            <PreviewBox>
                <PreviewText>
                    <Text bold>Preview your message:</Text>
                    <LinkButton
                        color="front.accent.color"
                        to="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                        target="blank"
                    >
                        <ButtonInset noHorizontalPadding>
                            Formatting guide
                            <Spacer width={5} />
                            <LinkButtonArrow background="front.background" shouldBeBordered />
                        </ButtonInset>
                    </LinkButton>
                </PreviewText>
                {child}
            </PreviewBox>
        )
    }, [])

    return (
        <>
            <Sidebar
                data-prevent-interactions-exit
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                data-prevent-notes-exit
                data-field-sidebar
                data-cy="merchant-interactions"
                show={shouldShow}
            >
                <BoxHolder>
                    <FloatingSidebarHeader handleExitClick={handleExitClick} title={`Merchant interactions`} />
                </BoxHolder>
                <Spacer height={10} />
                {hasSidebarLoaded ? (
                    <CardInset type="regular" noVerticalPadding>
                        {timeline.map((c: any) => {
                            if (c.comment)
                                return (
                                    <MerchantInteractionComment
                                        key={c.comment.id}
                                        comment={c.comment}
                                        applicationId={id}
                                        isUnread={unreadComments?.[c.comment.id]}
                                        indexPath={c.path}
                                    />
                                )
                            else if (c.edits) return <MerchantInteractionMadeEdits edit={c} key={JSON.stringify(c)} />
                            else if (c.date)
                                return (
                                    <MerchantInteractionDateSeparator key={'date-sep' + c.date.toString()} date={c} />
                                )
                            return <MerchantInteractionStateChange interaction={c} key={c.id} />
                        })}
                        <MarkdownPreviewItem
                            renderer={previewBoxRenderer}
                            pageId="Merchant.ApplicationPage.FieldComments"
                        />
                        <Spacer height={250} />
                        <div ref={setLastPoint} key={JSON.stringify(params)} />
                        <Flex grow />
                        {mostCommentedField?.fieldKey && (
                            <MerchantApplicationCommentsModalMessageBox
                                onTemplateSelection={scrollToBottom}
                                applicationId={id}
                                focusOn={shouldShow}
                                indexPath={ConvertIndexPathStringToIndexPath(mostCommentedField?.fieldKey)}
                                after={messageBoxAfter}
                            />
                        )}
                    </CardInset>
                ) : (
                    <LoaderView overBackground="front.background" offsetTop={-120} />
                )}
            </Sidebar>
            <MerchantSidebarShadow flip show={shouldShow} />
        </>
    )
}

const Sidebar = styled.div<{ show?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: 0;
    width: ${MERCHANT_SIDEBAR_SIZE};
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;
    background-color: ${(p) => p.theme['front.background']};
    z-index: 1000;
    display: none;

    ${(p) =>
        p.show &&
        css`
            display: block;
        `}
`

const Field = styled.div`
    padding: 2px 6px;
    border: 1px dotted ${(p) => p.theme['front.border.strongerI']};
    margin: -2px 6px;
    margin-left: 3px;
    border-radius: 12px;
`

const PreviewBox = styled.div`
    display: block;
    border: 1px solid ${(p) => p.theme['front.border']};
    padding: 20px;
    border-radius: 14px;
    margin-top: 10px;
    width: 70%;
    align-self: flex-end;
    box-shadow: ${(p) => p.theme['cardsShadow']};
    color: ${(p) => p.theme['front.text']};
    background-color: ${(p) => p.theme['front.background']};
`

const PreviewText = styled.div`
    width: 100%;
    padding-bottom: 5px;
    margin-top: -5px;
    border-bottom: 1px solid ${(p) => p.theme['front.border']};
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const BoxHolder = styled.div`
    position: sticky;
    top: 0;
    box-shadow: 0px 2px 13px -11px #111;
    z-index: 1000;
    background: ${(p) => p.theme['front.background']};
`
