import { createActionCreator } from 'deox'
import {
    ApplicationInternalTag,
    ApplicationInternalTagEditableDetailsPayload,
    ApplicationInternalTagWithCount
} from './types'

const Prefix = 'APPLICATION_INTERNALS_TAGS'

export const ApplicationInternalsTagsActions = {
    FETCH: createActionCreator(`${Prefix}/FETCH`, (resolve) => () => resolve({})),
    FETCH_APPLICATION_TAGS: createActionCreator(
        `${Prefix}/FETCH_APPLICATION_TAGS`,
        (resolve) => (applicationId: string, tagsLink: string) => resolve({ applicationId, tagsLink })
    ),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (tags: ApplicationInternalTagWithCount[]) => resolve({ tags })
    ),
    STORE_APPLICATION_TAGS: createActionCreator(
        `${Prefix}/STORE_APPLICATION_TAGS`,
        (resolve) =>
            (applicationId: string, selectable: ApplicationInternalTag[], selected: ApplicationInternalTag[]) =>
                resolve({ applicationId, selectable, selected })
    ),
    ASSIGN: createActionCreator(
        `${Prefix}/ASSIGN`,
        (resolve) => (watcherId: string, applicationId: string, tag: ApplicationInternalTag) =>
            resolve({
                watcherId,
                applicationId,
                tag
            })
    ),
    UNASSIGN: createActionCreator(
        `${Prefix}/UNASSIGN`,
        (resolve) => (watcherId: string, applicationId: string, tag: ApplicationInternalTag) =>
            resolve({
                watcherId,
                applicationId,
                tag
            })
    ),
    CREATE: createActionCreator(
        `${Prefix}/CREATE`,
        (resolve) => (watcherId: string, applicationId: string, newTag: ApplicationInternalTagEditableDetailsPayload) =>
            resolve({
                watcherId,
                applicationId,
                newTag
            })
    ),
    STORE_SINGLE: createActionCreator(
        `${Prefix}/STORE_SINGLE`,
        (resolve) => (applicationId: string, tag: ApplicationInternalTag) =>
            resolve({
                tag,
                applicationId
            })
    ),
    MODIFY: createActionCreator(
        `${Prefix}/MODIFY`,
        (resolve) =>
            (
                watcherId: string,
                applicationId: string,
                tagLink: string,
                newTag: ApplicationInternalTagEditableDetailsPayload
            ) =>
                resolve({
                    watcherId,
                    applicationId,
                    tagLink,
                    newTag
                })
    )
}
