import React, { ReactElement, useCallback } from 'react'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'

export const MerchantApplicationInternalDetailsShowMore: React.FC<{
    visibilityState: { [key: string]: boolean }
    setVisibilityState: any
    visibilitySection: string
    count: number
    rightSide?: ReactElement
}> = ({ visibilitySection, visibilityState, setVisibilityState, count, rightSide }) => {
    const handleShow = useCallback(() => {
        setVisibilityState((s: any) => {
            s[visibilitySection] = true
            return { ...s }
        })
    }, [setVisibilityState, visibilitySection])

    const handleHide = useCallback(() => {
        setVisibilityState((s: any) => {
            s[visibilitySection] = false
            return { ...s }
        })
    }, [setVisibilityState, visibilitySection])

    return (
        <>
            {/* <Spacer /> */}
            {rightSide}
            <div />
            <div />
            <Holder>
                <SimpleButton
                    onClick={visibilityState[visibilitySection] ? handleHide : handleShow}
                    color="front.accent.color"
                >
                    <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                        {visibilityState[visibilitySection] ? 'Hide Extra' : `Show ${count} others`}
                    </ButtonInset>
                </SimpleButton>
            </Holder>
        </>
    )
}
const Holder = styled.div`
    display: flex;
`

const Spacer = styled.div`
    height: 2px;
    grid-column: span 3;
`
