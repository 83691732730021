import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Text } from '../../components/general/text'
import { FiltersController } from '../../hooks/pages/useListPage'
import { ApplicationsDefaultSortForDashboardEntryState, ApplicationsFilters } from '../../store/applications/types'
import { DashboardEntriesTypes } from '../../store/dashboard/types'
import { RootState } from '@/store'
import { ApplicationsSidebarStat } from './ApplicationsSidebarStat'
import { ListPageSidebarTitle } from '../../components/layout/listPageSidebarTitle'

export const ApplicationsSidebar: React.FC<{
    filters: FiltersController<ApplicationsFilters>
}> = ({ filters }) => {
    const dashboard = useSelector((state: RootState) => state.dashboard)

    const renderTitleRow = useCallback((title: string) => {
        return (
            <ListPageSidebarTitle>
                <Text bold>{title}</Text>
            </ListPageSidebarTitle>
        )
    }, [])

    const onStatClick = useCallback(
        (stat: DashboardEntriesTypes) => {
            if (stat === filters.get.applications_status) return
            filters.set((s) => ({
                ...s,
                ...(
                    ['submitted', 'new'].includes(stat) && filters.get.applications_agent && {applications_agent: ''}
                ),
                ...(
                    ['new'].includes(stat) && 
                        (filters.get.applications_start_date || filters.get.applications_end_date) && 
                        {applications_start_date: '', applications_end_date: ''}
                ),
                applications_status: stat,
                applications_page: 1,
                applications_sort: ApplicationsDefaultSortForDashboardEntryState[stat].column,
                applications_direction: ApplicationsDefaultSortForDashboardEntryState[stat].direction
            }))
        },
        [filters]
    )

    return (
        <Holder>
            {renderTitleRow('Applications')}
            {[
                ['new', 'alt+q'],
                ['submitted', 'alt+w'],
                ['under_review', 'alt+e'],
                ['more_information', 'alt+r'],
                ['pre_accepted', 'alt+y'],
                ['needs_approval', 'alt+u'],
                ['declined', 'alt+t'],
                ['archived', 'alt+i']
            ].map((t: any) => (
                <ApplicationsSidebarStat
                    onClick={onStatClick}
                    dashboard={dashboard}
                    isBeta={t[2]}
                    stat={t[0]}
                    key={t[0]}
                    hotkey={t[1]}
                    isActive={t[0] === filters.get.applications_status}
                />
            ))}
            {renderTitleRow('Contracts')}
            {[
                ['agreements.new', 'alt+a'],
                ['agreements.needs_approval', 'alt+d'],
                ['contracts.needs_signature', 'alt+f'],
                ['contracts.declined', 'alt+g']
            ].map((t: any) => (
                <ApplicationsSidebarStat
                    onClick={onStatClick}
                    dashboard={dashboard}
                    stat={t[0]}
                    key={t[0]}
                    hotkey={t[1]}
                    isActive={t[0] === filters.get.applications_status}
                />
            ))}
            {renderTitleRow('Accounts')}
            {[
                ['accounts.in_progress', 'alt+z'],
                ['accounts.live', 'alt+x'],
                ['accounts.closed', 'alt+c']
            ].map((t: any) => (
                <ApplicationsSidebarStat
                    onClick={onStatClick}
                    dashboard={dashboard}
                    stat={t[0]}
                    key={t[0]}
                    hotkey={t[1]}
                    isActive={t[0] === filters.get.applications_status}
                />
            ))}
        </Holder>
    )
}

const Holder = styled.div`
    background-color: ${(p) => p.theme['side.background']};
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    width: 250px;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 15px 20px;
    z-index: 10000;

    &:focus {
        outline: none;
    }
`
