import { Paginator } from '@/components/general/paginator'
import { Tasks } from '@/services/taskr'
import React from 'react'

interface TasksPaginationProps {
    tasks?: Tasks
    onPageChanged: (number) => void
}

export const TasksPagination: React.FC<TasksPaginationProps> = (props) => {
    const { tasks, onPageChanged } = props

    const pagination = tasks ? { page: tasks.page, perPage: tasks.per_page, total: tasks.total } : undefined

    return <Paginator pagination={pagination} onPageChanged={onPageChanged} />
}
