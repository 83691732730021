import Color from 'color'
import React, { ReactElement, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Spacer } from '../../../components/layout/spacer'
import { useOnPropagatingDocumentClick } from '../../../hooks/general/useOnPropagatingDocumentClick'
import { zIndexes } from '../../../styles/zIndexes'
import { uppercaseFirstLetter } from '../../../utils'
import { MerchantApplicationEditsModal } from './Application.Edits'
import { useMerchantApplicationNameSelector } from './useMerchantApplicationFieldNameSelector'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

type Params = { id: string; sidebar: string }

export const MerchantApplicationFieldSidebar = (): ReactElement | null => {
    const params = useParams() as Params
    const { fieldName } = useMerchantApplicationNameSelector(params)
    const location = useLocation()
    const navigate = useNavigate()

    const backTo = useMemo(() => {
        return {
            pathname: `/merchant/${params.id}/application`,
            search: location.search
        }
    }, [location, params])

    useOnPropagatingDocumentClick(
        useCallback(() => {
            navigate(backTo, { replace: true })
        }, [navigate, backTo]),
        'data-prevent-edits-exit',
        params.sidebar === 'edits'
    )
    const header = useMemo(() => {
        return (
            <TopBar>
                <Info>
                    <Text bold oneLine>
                        {uppercaseFirstLetter(params.sidebar)}
                    </Text>
                    <Spacer width={10} />
                    <Icon type="arrow" />
                    <Spacer width={10} />
                    <Text oneLine>{fieldName?.sectionName}</Text>
                    <Spacer width={10} />
                    <Icon type="arrow" />
                    <Spacer width={10} />
                    <Text oneLine>{fieldName?.fieldName}</Text>
                </Info>
                <InfoButton>
                    <LinkButton color="front.background" to={backTo}>
                        <ButtonInset>
                            <Icon type="x" />
                        </ButtonInset>
                    </LinkButton>
                </InfoButton>
            </TopBar>
        )
    }, [fieldName, params, backTo])

    if (params.sidebar === 'edits')
        return (
            <Bar data-prevent-edits-exit>
                <div data-cy="edits-sidebar">
                    {header}
                    <MerchantApplicationEditsModal />
                </div>
            </Bar>
        )
    return null
}

const Bar = styled.div`
    background-color: ${(p) => p.theme['front.background']};
    border-left: 1px solid ${(p) => p.theme['back.background.strongerI']};

    height: calc(100vh);

    width: 420px;
    flex-shrink: 0;
    overflow-y: auto;
    position: sticky;
    top: 0px;
    overscroll-behavior-y: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media (max-width: 1750px) {
        box-shadow:
            0px 15px 20px rgba(0, 0, 0, 0.1),
            0px 0px 10px 10px rgba(0, 0, 0, 0.005);
        position: fixed;
        right: 0;
        width: 400px;
        border-left: none;
        z-index: ${zIndexes.popoverLevel2};
    }
`

const TopBar = styled.div`
    height: 32px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 10;
    left: 0;
    background-color: ${(p) => Color(p.theme['front.background']).toString()};
    align-items: center;
    padding: 10px 20px;
    margin: 0;
    justify-content: space-between;
`

const Info = styled.div`
    display: flex;
    flex-basis: 100%;
    flex-shrink: 1;
    min-width: 0;
    align-items: center;
    color: ${(p) => p.theme['front.text']};
`

const InfoButton = styled.div`
    margin-right: -10px;
`
