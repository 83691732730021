import { Flex } from '../../layout/flex'
import { Text } from '../../general/text'

export const ContextCardsNoDataFound: React.FC = ({}) => {
    return (
        <Flex style={{ minHeight: 70 }} align="center" justify="center">
            <Text size="s" color="front.text.subtlerI">
                No data found.
            </Text>
        </Flex>
    )
}
