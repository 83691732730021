import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { ValidationStatus } from '../../hooks/general/useForm'
import { useFormField } from '../../hooks/general/useFormField'
import { useOnBlurOutside } from '../../hooks/general/useOnBlurOutside'
import { useOnClickOutside } from '../../hooks/general/useOnClickOutside'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { Dropdown } from './dropdown'
import { DropdownSelectableList, TableLike } from './dropdownSelectableList'
import { TextInput } from './textInput'
import { TextareaInput } from './textareaInput'
import { Color } from '../../styles/colors'
import { falseOrUndefined } from '../../utils'

export function TextInputSelectWithRef(
    {
        placeholder,
        items,
        textForItem,
        selected,
        dropdownId,
        onSelect,
        onEnter,
        isSeamless,
        shouldRemainOpen,
        shouldFilter = true,
        onBlankEnter,
        overBackground,
        isDisabled,
        onChange,
        isSimple,
        skipFieldSelection,
        onBlur,
        isHigher,
        rightAlign,
        overrideContentMemo,
        noBullets,
        noClear,
        validation,
        cy,
        textareaBased,
        inputCoverMemo,
        alignCoverRight,
        tableLike,
        adjustSelection,
        isQuestion,
        itemForText,
        lazyLoaded
    }: {
        placeholder: string
        items: any[]
        textForItem: (item?: any, shouldExpand?: boolean) => string | undefined
        itemForText?: (item?: any) => string | undefined
        isSimple?: boolean
        isSeamless?: boolean
        overBackground: Color
        selected?: any
        textareaBased?: boolean
        dropdownId: MODAL_ID
        onSelect?: (item: string) => void
        onChange?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        onEnter?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        onBlur?: (e: React.KeyboardEvent<HTMLInputElement>) => void
        onBlankEnter?: (val?: string) => void
        cy?: string
        isDisabled?: boolean
        rightAlign?: true
        lazyLoaded?: boolean
        noClear?: boolean
        shouldRemainOpen?: boolean
        alignCoverRight?: boolean
        shouldFilter?: boolean
        validation?: ValidationStatus
        isHigher?: boolean
        skipFieldSelection?: boolean
        noBullets?: boolean
        overrideContentMemo?: React.ReactChild
        inputCoverMemo?: React.ReactChild
        tableLike?: TableLike
        isQuestion?: boolean
        adjustSelection?: (val: any) => void
    },
    ref: any
): ReactElement {
    if (typeof ref === 'function' && onSelect) {
        if (!lazyLoaded) {
            console.log(`Are you sure you don't want to add the lazyLoad prop? «${dropdownId}»`)
        }
    }

    const { blurBoundaryClassName, isBlurOutsideBounds } = useOnBlurOutside()
    const [filteringValue, setFilteringValue] = useState<string | undefined>(undefined)
    const [anchor, setAnchor] = useRefTaker()
    const [shouldShowDropdown, setShouldShowDropdown] = useState(false)
    const [outsideDiv, setOutsideDiv] = useRefTaker()
    const [selection, setSelection] = useState(falseOrUndefined(selected))
    const [isFocused, setIsFocused] = useState(false)
    const persistentSelection = useRef<any>()
    const valueToText = useRef<any>()

    const onClickOutside = useCallback(() => {
        setShouldShowDropdown(false)
    }, [setShouldShowDropdown])
    useOnClickOutside(outsideDiv, onClickOutside)

    const handleSelection = useCallback(
        (i) => {
            const item = adjustSelection ? adjustSelection(i) : i
            if (!shouldRemainOpen) {
                setShouldShowDropdown(false)
            }
            if (onSelect) onSelect?.(item)
            if (!skipFieldSelection) setSelection(item)
        },
        [shouldRemainOpen, adjustSelection, onSelect, setSelection, skipFieldSelection]
    )

    useEffect(() => {
        persistentSelection.current = selection
    }, [selection])

    useEffect(() => {
        valueToText.current = items.reduce((acc, i) => {
            const text = textForItem(i)
            if (typeof text !== 'string') acc[i] = i
            else acc[text] = i
            return acc
        }, {} as any)
    }, [items, textForItem])

    const { refInterceptor, onFieldUpdate, fieldRef } = useFormField(
        useCallback(
            (value) => {
                if (isQuestion) {
                    if (!value) {
                        setSelection(false)
                        return
                    }
                }
                setSelection(value)
            },
            [setSelection, isQuestion]
        ),
        // might need refactoring at a later point... could cause performance issues for
        // dropdowns with many items that are part of a form
        // the way props work now offers no way to go from the text field value to the actual form value
        // without looping and calling textForItem on each option then comparing it with the field value
        useCallback(
            (ref) => {
                if (!ref) return undefined
                if (valueToText.current?.[ref.current?.value]) return valueToText.current?.[ref.current?.value]
                if (lazyLoaded) return ref.current?.value
                if (itemForText) return itemForText(ref.current?.value)
                return undefined
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [lazyLoaded]
        ),
        useCallback((r: any) => {
            if (ref) {
                if (typeof ref === 'function') ref(r)
                else ref.current = r
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []),
        false,
        falseOrUndefined(selected)
    )

    useEffect(() => {
        onFieldUpdate(selected)
        setSelection(selected)
    }, [selected, onFieldUpdate])

    useEffect(() => {
        fieldRef?.current?.blur?.()
    }, [fieldRef, selection])

    useEffect(() => {
        onFieldUpdate(selection)
    }, [onFieldUpdate, selection])

    const handleChange = useCallback(
        (e, value) => {
            setFilteringValue(value)
            onChange?.(e, value)
        },
        [onChange]
    )

    const onFieldFocus = useCallback(() => {
        setFilteringValue('')
        setIsFocused(true)
        setShouldShowDropdown(true)
    }, [])

    const handleBlur = useCallback(
        (e: any) => {
            onBlur?.(e)
            setIsFocused(false)
            if (isBlurOutsideBounds(e)) {
                setShouldShowDropdown(false)
            } else {
                anchor?.focus()
            }
        },
        [anchor, isBlurOutsideBounds, onBlur]
    )

    const shouldShowCover = inputCoverMemo ? !shouldShowDropdown : false

    const inputValue = useMemo(() => {
        if (textForItem(selection) !== undefined) return textForItem(selection)
        return selection
    }, [textForItem, selection])

    const clearButton = useMemo(() => {
        if (noClear) return null
        if (selection && !isDisabled && !isFocused)
            return (
                <ClearButtonHolder rightAlign={rightAlign} color={overBackground}>
                    <SimpleButton
                        cy="clear-value"
                        onClick={() => {
                            setSelection(undefined)
                        }}
                    >
                        <ButtonInset padding="tiny" noHorizontalPadding noVerticalPadding>
                            <DeselectText background={overBackground}>CLEAR</DeselectText>
                        </ButtonInset>
                    </SimpleButton>
                </ClearButtonHolder>
            )
        return null
    }, [noClear, selection, isDisabled, isFocused, rightAlign, overBackground])

    const Input = useMemo(() => {
        return textareaBased ? TextareaInput : TextInput
    }, [textareaBased])

    return (
        <Holder className={blurBoundaryClassName} ref={setOutsideDiv} data-prevent-input-focus-loss>
            <TextInputHolder ref={setAnchor}>
                <TextInputMasker shouldHide={isSeamless ? false : shouldShowCover}>
                    <Input
                        cy={cy}
                        isDisabled={isDisabled}
                        placeholder={placeholder}
                        onChange={handleChange}
                        {...(textareaBased ? { noNewlines: true } : {})}
                        onFocus={onFieldFocus}
                        isSimple={isSimple}
                        isSeamless={isSeamless}
                        refInterceptor={refInterceptor}
                        onBlur={handleBlur}
                        rightAlign={rightAlign}
                        hideInputIndicator={!!clearButton}
                        onEnter={onEnter}
                        initialValue={tableLike?.labelForItem(selected) || textForItem(selected) || selected}
                        forceValue={inputValue}
                        noFormFieldBehaviour
                        validation={validation}
                        overBackground={overBackground}
                    />
                </TextInputMasker>
                {shouldShowCover && <CoverHolder alignCoverRight={alignCoverRight}>{inputCoverMemo}</CoverHolder>}
                {shouldShowCover ? null : clearButton}
            </TextInputHolder>
            <Dropdown
                anchor={anchor}
                show={shouldShowDropdown}
                cy={`${cy}-dropdown`}
                isHigher={isHigher}
                overrideContentMemo={overrideContentMemo}
            >
                <DropdownSelectableList
                    items={items}
                    onBlankEnter={onBlankEnter}
                    isHigher={isHigher}
                    selected={selection}
                    dropdownId={dropdownId}
                    noBullets={noBullets}
                    shouldFilter={shouldFilter}
                    textForItem={textForItem}
                    filteringValue={filteringValue}
                    onSelect={handleSelection}
                    tableLike={tableLike}
                />
            </Dropdown>
        </Holder>
    )
}

export const TextInputSelect = React.forwardRef(TextInputSelectWithRef)

const ClearButtonHolder = styled.div<{
    rightAlign?: boolean
    color: Color
}>`
    position: absolute;
    right: 0px;
    font-size: 8px;
    text-transform: uppercase;
    border-radius: 3px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    top: -2px;
    z-index: 0;
    background: ${(p) => p.theme[p.color]};

    ${(p) =>
        p.rightAlign &&
        css`
            padding-left: 0;
            right: -10px;
            transform: translateX(100%);
        `};
`

const DeselectText = styled.div<{
    background: Color
}>`
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 500;
    color: ${(p) => p.theme['front.accent.color']};
    padding-left: 5px;

    &:hover {
        color: inherit;
    }
`

const TextInputMasker = styled.div<{ shouldHide?: boolean }>`
    opacity: 1;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    width: 100%;

    ${(p) =>
        p.shouldHide &&
        css`
            opacity: 0;
        `}
`

const CoverHolder = styled.div<{ alignCoverRight?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 1;
    width: 100%;
    height: 100%;

    & > *,
    & > * > * {
        flex-grow: 1;
    }

    ${(p) =>
        p.alignCoverRight &&
        css`
            justify-content: flex-end;
            & > *,
            & > * > * {
                justify-content: flex-end;
            }
        `}
`

const TextInputHolder = styled.div`
    display: flex;
    align-self: stretch;
    flex-grow: 1;
    align-items: stretch;
    position: relative;
    width: 100%;
`

const Holder = styled.div`
    display: contents;
    position: relative;
`
