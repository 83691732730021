import { useState, useEffect } from 'react'

export const useIsPrint = () => {
    const [isPrinting, toggleStatus] = useState(false)

    useEffect(() => {
        const printMq: any = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print')
        const mqEvent = (mqList: any) => toggleStatus(!!mqList.matches)

        printMq.addListener(mqEvent)
        return () => printMq.removeListener(mqEvent)
    }, [])

    return isPrinting
}
