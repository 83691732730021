import React, { ReactElement, useCallback } from 'react'

import { DisputesFilters } from '../../store/disputes/types'
import { TextInput } from '../forms/textInput'
import { FiltersController } from '../../hooks/pages/useListPage'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

export const ListPageFilterDisputeReference = ({
    filters,
    isDark
}: {
    filters: FiltersController<DisputesFilters>
    isDark: boolean
}): ReactElement => {
    const [fieldRef, setFieldRef] = useRefTaker()

    const onEnter = useCallback(
        (e, value) => {
            fieldRef.value = ''
            filters.set((prevState) => ({
                ...prevState,
                disputes_reference: value
            }))
        },
        [fieldRef, filters]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInput
                ref={setFieldRef}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                placeholder="-"
                cy="reference-filter"
                isSeamless
                onEnter={onEnter}
            />
            <SelectedListForListPageFilters<DisputesFilters>
                filterKey="disputes_reference"
                filters={filters}
                noHolderEmphasize={isDark}
                isDark={isDark}
                textForItem={(item: any) => item}
            />
        </FilterPageHolder>
    )
}
