import { ModalHeader } from '@/components/modals/modalHeader'
import { ModalPage } from '@/components/layout/modalPage'
import { ModalPageInset } from '@/components/layout/modalPageInset'
import React from 'react'
import { Box, Container, Flex } from '@/design-system'

interface ReconciliationModalProps {
    children: React.ReactNode
    type: 'posting' | 'voucher'
}

export const ReconciliationModal: React.FC<ReconciliationModalProps> = (props) => {
    const { children, type } = props

    return (
        <ModalPage title="Reconcile" pageId="Reconcile">
            <Box height="full">
                <Flex direction="column" align="center">
                    <ModalHeader
                        title={`Reconcile ${type}`}
                        pageId="Reconcile"
                        backTo={`/more/reconciliation-${type}s`}
                    />
                    <Container size="screen-2xl">
                        <ModalPageInset>{children}</ModalPageInset>
                    </Container>
                </Flex>
            </Box>
        </ModalPage>
    )
}
