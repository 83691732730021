import React from 'react'
import { ReportStatusItem } from '../../common/report-status-item'
import { useChecksStatus } from './use-checks-status'

export const ChecksStatus: React.FC = () => {
    const completed = useChecksStatus()

    return (
        <ReportStatusItem completed={completed} of={15}>
            Checks
        </ReportStatusItem>
    )
}
