import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ValidationError } from 'yup'
import { applicationValidation } from './applicationValidation'
import { RootState } from '@/store'
import { ApplicationResourceData } from '@/store/applicationResources/types'
import { produce } from 'immer'
import { snakeCase } from 'lodash'

interface Field {
    [key: string]: any
}

const normalizeField = (field: Field) => {
    const ignoredFieldKeys = [
        'filesLoadingStatus',
        'commentsLink',
        'selfLink',
        'peopleLink',
        'filesLink',
        'comments',
        'sanitised',
        'id'
    ]
    const normalizedField = {}
    Object.keys(field).reduce((pv, cv) => {
        if (ignoredFieldKeys.indexOf(cv) > -1) {
            return normalizedField
        }

        Object.assign(normalizedField, {
            [snakeCase(cv)]: field[cv]
        })

        return normalizedField
    }, {})

    return normalizedField
}

function filterDeletedFiles(data: ApplicationResourceData, state: RootState, fieldNames: string[]) {
    // Use Immer to filter the fields in the specified data structure
    return produce(data, (draft) => {
        // Return early if there are no fields or it's not an array
        if (!draft.fields || !Array.isArray(draft.fields)) {
            return
        }

        // Iterate through each field in the draft
        draft.fields.forEach((field) => {
            // Iterate through each field name to filter
            fieldNames.forEach((fieldName) => {
                const fieldToFilter = field[fieldName]

                // Skip if the field does not exist
                if (!fieldToFilter) {
                    return
                }

                // Filter the array to remove items based on criteria
                field[fieldName] = fieldToFilter.filter((item) => {
                    const itemMetadata = state.files?.at?.[item]?.metadata

                    // Return true if item's metadata does not exist
                    if (!itemMetadata) {
                        return true
                    }

                    // Return true if item's metadata is not marked as deleted
                    return itemMetadata && !itemMetadata.deleted
                })
            })
        })
    })
}

export const useApplicationValidation = (applicationId: string) => {
    const businessModel = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.businessModel
    )
    const company = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.company
    )
    const bankAccount = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.bankAccount
    )
    const contact = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.contact
    )
    const gateway = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.gateway
    )
    const people = useSelector((state: RootState) => {
        const people = state.applicationResources?.data?.forApplication?.[applicationId]?.people

        if (!people) return

        const noDeletedFiles = filterDeletedFiles(people, state, ['addressLegitimation', 'pictureLegitimation'])

        return noDeletedFiles
    })

    const signer = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.signer
    )
    const websites = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.websites
    )
    const additionalInformation = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[applicationId]?.additionalInformation
    )

    const fullApplicationData = useMemo(() => {
        if (
            businessModel?.loadingStatus === 'done' &&
            company?.loadingStatus === 'done' &&
            bankAccount?.loadingStatus === 'done' &&
            contact?.loadingStatus === 'done' &&
            gateway?.loadingStatus === 'done' &&
            people?.loadingStatus === 'done' &&
            signer?.loadingStatus === 'done' &&
            websites?.loadingStatus === 'done' &&
            additionalInformation?.loadingStatus === 'done' &&
            people.fields.every((f) => f.filesLoadingStatus === 'done' || f.filesLoadingStatus === undefined) &&
            company.fields.every((f) => f.filesLoadingStatus === 'done' || !f.filesLoadingStatus === undefined)
        ) {
            const applicationData = {
                business_model: {},
                company: {},
                bank_account: {},
                contact: {},
                people: [] as Array<object>,
                signer: {},
                websites: [] as Array<object>
            }

            for (const field of businessModel.fields) {
                Object.assign(applicationData['business_model'], normalizeField(field['businessModel']))
            }

            for (const field of company.fields) {
                Object.assign(applicationData['company'], normalizeField(field))
            }

            for (const field of bankAccount.fields) {
                Object.assign(applicationData['bank_account'], normalizeField(field))
            }

            for (const field of contact.fields) {
                Object.assign(applicationData['contact'], normalizeField(field))
            }

            if (gateway.fields[0]?.gateways?.[0]) {
                applicationData['gateway'] = gateway.fields[0].gateways[0].id
            }

            for (const field of people.fields) {
                applicationData['people'].push(normalizeField(field))
            }

            for (const field of signer.fields) {
                Object.assign(applicationData['signer'], normalizeField(field['signer']))
            }

            for (const field of websites.fields) {
                applicationData['websites'].push(normalizeField(field))
            }

            if (additionalInformation.fields[0]?.additionalInformation) {
                applicationData['additional_information'] = additionalInformation.fields[0]?.additionalInformation
            }

            return applicationData
        }
        return null
    }, [businessModel, company, bankAccount, contact, gateway, people, signer, websites, additionalInformation])

    return useMemo(() => {
        let result: boolean | ValidationError[] = false
        if (fullApplicationData) {
            try {
                applicationValidation.validateSync(fullApplicationData, { abortEarly: false })
                result = true
            } catch (e) {
                if (e instanceof ValidationError) {
                    for (const validationError of e.inner) {
                        if (!Array.isArray(result)) {
                            result = []
                        }

                        result.push(validationError)
                    }
                }
            }
        }

        return [result, fullApplicationData] as [boolean | ValidationError[], typeof fullApplicationData]
    }, [fullApplicationData])
}
