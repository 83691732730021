import styled from 'styled-components'

export const ApplicationNoSubsections: React.FC<{ type: 'websites' | 'people' }> = ({ type }) => {
    return (
        <>
            <Block>This application has no provided {type}</Block>
            <span />
        </>
    )
}

const Block = styled.div`
    background-color: ${(p) => p.theme['front.subtleDanger.background']};
    color: ${(p) => p.theme['front.subtleDanger.text']};
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`
