import { File, useLazyGetFile } from '@/services/merchant-api'
import { Section } from './section'
import { useSectionsContext } from './sections'

interface FileLink {
    file?: File
    children: string
}

export const FileLink: React.FC<FileLink> = (props) => {
    const { children, file } = props
    const { openWindow } = useSectionsContext()

    const [trigger, result] = useLazyGetFile()

    const state = file?.relations.data.download && !result.isLoading ? 'enabled' : 'disabled'

    const handleOnClick = async () => {
        if (file && file.relations.data.download) {
            const { data } = await trigger(file.relations.data.download)

            if (data?.download) {
                try {
                    const blob = await fetch(data.download).then((r) => r.blob())
                    const blobUrl = URL.createObjectURL(blob)

                    openWindow(blobUrl)
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }

    return (
        <Section.File onClick={handleOnClick} state={state}>
            {children}
        </Section.File>
    )
}
