import { AgentBubble, Date, Tag } from '../../common'
import { Flex } from '@/components/layout/flex'
import { Reports } from '@/services/ongoing-due-diligence'
import { TableRow } from '@/components/tables/table'

export const getReportRows = (data?: Reports) => {
    if (!data) {
        return []
    }

    const rows: TableRow[] = data.reports.map((report) => {
        return {
            type: 'normal' as const,
            key: report.id,
            link: `/merchant/${report.application_id}/more/ongoing-due-diligence/reports/${report.id}`,
            items: [
                {
                    node: <Date>{report.report_detail.date_started}</Date>
                },
                {
                    node: <AgentBubble agentSub={report.report_detail.author_id} />
                },
                {
                    node: (
                        <Flex justify="flex-end">
                            <Tag>{report.report_detail.report_status}</Tag>
                        </Flex>
                    )
                }
            ]
        }
    })

    return rows
}
