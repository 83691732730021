import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { TextInputAgentSelector } from '../../components/forms/textInputAgentSelector'

import { Text } from '../../components/general/text'
import { MODAL_ID } from '../../components/modals/modalIds'
import { ApplicationInternalsAgentsActions } from '../../store/applicationInternals/agents/actions'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../../store/toasts/actions'

export const MerchantAssignAgent: React.FC<{
    applicationId: string
    pageId?: MODAL_ID
    noPadding?: boolean
    alignCoverRight?: boolean
}> = ({ applicationId, pageId, alignCoverRight }) => {
    const dispatch = useDispatch()
    const agents = useSelector((state: RootState) => {
        return state.applicationInternals.agents?.forApplication?.[applicationId]
    })

    if (!agents) return <Text color="side.text">Start assessment to assign agent</Text>

    if (!agents?.selectable) return <Text color="side.text">Start assessment to assign agent</Text>

    return (
        <TextInputAgentSelector
            pageId={pageId}
            overBackground="side.background"
            alignCoverRight={alignCoverRight}
            searchPreloadedDataset
            onSelect={(agentId, watcher) => {
                const agent = agents.selectable?.find((s) => s.id === agentId)

                if (!agent) {
                    dispatch(ToastsDispatchPush('Agent not selectable', 'error'))
                    return
                }

                dispatch(ApplicationInternalsAgentsActions.ASSIGN_AGENT(watcher, applicationId, agent))
            }}
            selectedAgent={agents?.agent?.id || ''}
        />
    )
}

const LoaderHolder = styled.div`
    max-width: 150px;
    width: 100%;
`
