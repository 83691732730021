import { createActionCreator } from 'deox'

import { Collaborator } from './types'

const Prefix = 'APPLICATION_COLLABORATORS'

export const ApplicationInternalsCollaboratorsActions = {
    LOAD: createActionCreator(`${Prefix}/LOAD`, (resolve) => (applicationId: string) => resolve({ applicationId })),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (applicationId: string, collaborators: Collaborator[], keepsExisting?: boolean) =>
            resolve({ applicationId, collaborators, keepsExisting })
    ),
    RESEND_INVITATION: createActionCreator(
        `${Prefix}/RESEND_INVITATION`,
        (resolve) => (watcherId: string, applicationId: string, toEmail: string) =>
            resolve({ watcherId, applicationId, toEmail })
    ),
    INVITE: createActionCreator(
        `${Prefix}/INVITE`,
        (resolve) =>
            (
                watcherId: string,
                applicationId: string,
                toEmail: string,
                name: string,
                message: string,
                keepsExisting?: boolean
            ) =>
                resolve({
                    watcherId,
                    applicationId,
                    toEmail,
                    name,
                    message,
                    keepsExisting
                })
    ),
    UNSTORE: createActionCreator(
        `${Prefix}/UNSTORE`,
        (resolve) => (applicationId: string, email: string) =>
            resolve({
                applicationId,
                email
            })
    ),
    REVOKE: createActionCreator(
        `${Prefix}/REVOKE`,
        (resolve) => (watcherId: string, applicationId: string, collaboratorLink: string, collaboratorEmail: string) =>
            resolve({
                watcherId,
                applicationId,
                collaboratorLink,
                collaboratorEmail
            })
    )
}
