import React, { forwardRef, useCallback, useMemo } from 'react'

import { TextInputSelect } from '../../../components/forms/textInputSelect'
import * as yup from 'yup'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { ValidationStatus } from '../../../hooks/general/useForm'

import { Color } from '../../../styles/colors'
import { AvailableSettlementCurrencies } from '../../../store/settlements/types'

const MerchantApplicationEditsModalInputCurrencyWithRef: React.ForwardRefRenderFunction<
    any,
    {
        preSelected?: string
        onBlur: (e: any) => void
        indexPath: MerchantApplicationResourceIndexPath
        validation: ValidationStatus
        overBackground: Color
        formRef: MerchantApplicationFieldFormProps['formRef']
    }
> = ({ preSelected, onBlur, validation, overBackground, formRef, indexPath }) => {
    const itemsDictionary = useMemo(() => {
        return AvailableSettlementCurrencies.reduce((acc, i) => {
            acc[i] = i
            return acc
        }, {} as any)
    }, [])

    const textForItem = useCallback(
        (item) => {
            return itemsDictionary[item]
        },
        [itemsDictionary]
    )

    return (
        <TextInputSelect
            placeholder="Select a currency"
            selected={preSelected}
            validation={validation}
            textareaBased
            onBlur={onBlur}
            ref={(ref) => {
                formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
            }}
            lazyLoaded
            isSeamless
            noClear
            overBackground={overBackground}
            dropdownId="GenericDropdown"
            textForItem={textForItem}
            items={AvailableSettlementCurrencies}
        />
    )
}

export const MerchantApplicationEditsModalInputCurrency = forwardRef(MerchantApplicationEditsModalInputCurrencyWithRef)
