import styled from 'styled-components'
import { LinkButton } from './linkButton'
import { Icon } from '../icons/icon'
import { Text } from '../general/text'

export const InnerPageBackButton: React.FC<{
    backTo: string
    suffix: string
}> = ({ backTo, suffix }) => {
    return (
        <>
            <LinkButton to={backTo} color="front.text">
                <Holder>
                    <IconHolder>
                        <Icon type="arrow" />
                    </IconHolder>
                    <Text>
                        Back to <Text>{suffix}</Text>
                    </Text>
                </Holder>
            </LinkButton>
        </>
    )
}

const Holder = styled.div`
    display: flex;
    align-items: center;
`

const IconHolder = styled.div`
    transform: rotateZ(180deg);
    margin-right: 10px;
`
