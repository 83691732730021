import { Collection } from '../../utils'

export interface MCC {
    value: string
    label: string
}

export type MCCState = Collection<MCC>

export const InitialMCCState: MCCState = {
    at: {},
    all: [],
    loadingStatus: 'not-started'
}
