export interface CompanyRegisterLink {
    name: string
    url: string
}

export const getCompanyRegister = (country: string, companyNumber: string): CompanyRegisterLink => {
    let name = ''
    let url = ''

    if (country === 'DK') {
        name = 'CVR'
        // eslint-disable-next-line max-len
        url = `https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${companyNumber}&soeg=${companyNumber}`
    } else if (country === 'SE') {
        name = 'Allabolag'
        url = `https://www.allabolag.se/${companyNumber?.replace(/-/g, '')}`
    } else if (country === 'NO') {
        name = 'Brønnøysund'
        url = `https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${companyNumber}`
    } else if (country === 'GB') {
        name = 'Companies House'
        url = `https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`
    }

    return {
        name,
        url
    }
}
