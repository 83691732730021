import { startCase } from 'lodash'

import styled from 'styled-components'
import { Text } from './text'

const statusToColor: { [key: string]: string } = {
    new: '#DBF8DC',
    unsubmitted: '#FFE3BA',
    submitted: '#00D26D',
    accepted: '#B6C6FF',
    pre_accepted: '#8E9CCF',
    account_in_progress: '#FCD6FF',
    contract_in_progress: '#98F9FF',
    account_live: '#98ff98',
    ready_for_approval: '#DBDBF8',
    signed: '#F8DBE9',
    needs_signature: '#DBF8E6',
    under_review: '#A8FFE0',
    needs_information: '#D9FFDA',
    more_information: '#D2E121',
    declined: '#ED2727',
    archived: '#E7E7E7'
}

export const ApplicationStatus: React.FC<{ status: string }> = ({ status }) => {
    return (
        <Holder>
            <StatusBox backgroundColor={statusToColor[status]} />
            <Text noWrap bold>
                {startCase(status)}
            </Text>
        </Holder>
    )
}

const Holder = styled.div`
    display: inline-flex;
    align-items: center;
`

const StatusBox = styled.div<{
    backgroundColor: string
}>`
    margin: 0 1px;
    margin-top: -2px;
    margin-right: 8px;
    background-color: ${(p) => p.backgroundColor};
    color: #000;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transform: rotateZ(45deg);
    width: 8px;
    height: 8px;
`
