import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { Settlement } from '../../store/settlements/types'
import { DateToWords, numberWithCurrency } from '../../utils'
import { Flex } from '../../components/layout/flex'
import { LoaderView } from '../../components/loaders/loader'
import { useMemo } from 'react'
import { TimelineCard } from '../../components/listPages/timelineCard'

export const SettlementCardOtherPostings: React.FC<{
    settlement: Settlement
}> = ({ settlement }) => {
    const otherPostings = useMemo(() => {
        if (!settlement?.otherPostings?.length) return []
        const rows =
            settlement.otherPostings.map((op: any) => {
                return {
                    type: 'normal' as const,
                    key: 'total',
                    noAction: true,
                    items: [
                        {
                            node: <Text>{op.text}</Text>
                        },
                        {
                            node: <Text>{op.date ? DateToWords(op.date, true) : 'Unknown date'}</Text>
                        },
                        {
                            node: (
                                <Flex>
                                    <Text noWrap>{numberWithCurrency(settlement.currency, op.amount)}</Text>
                                    <Spacer width={10} />
                                    <Text noWrap>{settlement.currency}</Text>
                                </Flex>
                            )
                        }
                    ]
                }
            }, []) || []
        rows.push({
            type: 'normal' as const,
            key: 'total',
            noAction: true,
            items: [
                {
                    node: <b>Total</b>
                },
                {
                    node: <b></b>
                },
                {
                    node: (
                        <Flex>
                            <Text noWrap bold>
                                {numberWithCurrency(settlement.currency, settlement.summary.otherPostings)}
                            </Text>
                            <Spacer width={10} />
                            <Text noWrap bold>
                                {settlement.currency}
                            </Text>
                        </Flex>
                    )
                }
            ]
        })
        return rows
    }, [settlement])

    if (settlement.otherPostingsLoadingStatus !== 'done')
        return (
            <TimelineCard title="Other postings">
                <LoaderView overBackground="front.background" />
            </TimelineCard>
        )

    if (!otherPostings?.length)
        return (
            <TimelineCard title="Other postings">
                <Text color="front.text.subtlerI">No other postings were present</Text>
            </TimelineCard>
        )

    return (
        <TimelineCard title={`Other postings (${otherPostings.length - 1})`} overflowX>
            <Table
                background="front.background"
                cols={[
                    {
                        text: 'Description'
                    },
                    {
                        alignRight: true,
                        text: 'Date'
                    },
                    {
                        alignRight: true,
                        text: 'Amount'
                    }
                ]}
                noHeader
                rows={otherPostings}
                columnLayout="minmax(auto, 80%) 1fr min-content"
            />
        </TimelineCard>
    )
}
