import styled, { css } from 'styled-components'

export type CardInsetType = 'tiny' | 'small' | 'regular' | 'none' | 'verySmall'
export const CardInset: React.FC<{
    type?: CardInsetType
    children?: any
    isForHeader?: boolean
    noHorizontalPadding?: boolean
    noVerticalPadding?: boolean
    maxWidth?: string
    reduceVerticalPadding?: boolean
    reduceHorizontalPadding?: boolean
}> = ({
    type = 'regular',
    children,
    reduceVerticalPadding = false,
    isForHeader = false,
    maxWidth,
    noHorizontalPadding = false,
    noVerticalPadding = false,
    reduceHorizontalPadding = false
}) => {
    return (
        <Holder
            isForHeader={isForHeader}
            type={type}
            maxWidth={maxWidth}
            noHorizontalPadding={noHorizontalPadding}
            reduceVerticalPadding={reduceVerticalPadding}
            noVerticalPadding={noVerticalPadding}
            reduceHorizontalPadding={reduceHorizontalPadding}
        >
            {children}
        </Holder>
    )
}

const Holder = styled.div<{
    type?: CardInsetType
    isForHeader: boolean
    noHorizontalPadding?: boolean
    noVerticalPadding?: boolean
    reduceVerticalPadding?: boolean
    reduceHorizontalPadding?: boolean
    maxWidth?: string
}>`
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${(p) =>
        p.maxWidth &&
        css`
            max-width: ${p.maxWidth};
        `}
    ${(p) => {
        switch (p.type) {
            case 'tiny':
                return css`
                    padding: 2px 2px;
                `
            case 'verySmall':
                return css`
                    padding: 9px;
                `
            case 'small':
                return css`
                    padding: 15px;
                `
            case 'regular':
                return css`
                    padding: 30px;
                `
            case 'none':
                return css`
                    padding: 0;
                `
        }
    }}

    ${(p) => {
        if (!p.isForHeader) return null
        switch (p.type) {
            case 'tiny':
                return css`
                    margin-bottom: -2px;
                `
            case 'verySmall':
                return css`
                    margin-bottom: -4px;
                `
            case 'small':
                return css`
                    margin-bottom: -15px;
                `
            case 'regular':
                return css`
                    margin-bottom: -30px;
                `
        }
    }}

    ${(p) =>
        p.noHorizontalPadding &&
        css`
            padding-left: 0;
            padding-right: 0;
        `}
    
    ${(p) =>
        p.noVerticalPadding &&
        css`
            padding-top: 0;
            padding-bottom: 0;
        `}
    
    ${(p) =>
        p.reduceVerticalPadding &&
        css`
            padding-top: 10px;
            padding-bottom: 10px;
        `}
        ${(p) =>
        p.reduceHorizontalPadding &&
        css`
            padding-left: 10px;
            padding-right: 10px;
        `}
    @media print {
        padding: 0 !important;
    }
`
