import React from 'react'
import styled from 'styled-components'
import { Icon } from '../components/icons/icon'
import { LoadingSpinner } from '../components/loaders/spinner'
import { RowCheckmark, TableCircleStyles } from '../components/tables/rowCheckmark'
import { ReserveCancellationStatus } from '../store/settlementsReserves/types'

const cy = 'table-check'
const CriticalFlowSettlementsCheckWithRef: React.ForwardRefRenderFunction<
    any,
    {
        mid: string
        executing: ReserveCancellationStatus
        isNext?: boolean
        executionStarted?: boolean
        id: string
        selected?: boolean
    }
> = ({ selected, executing, isNext, executionStarted, id }, ref) => {
    if (isNext && executionStarted)
        return (
            <RowCheckmark disabled={true} alreadySelected={true} id={id} ref={ref} selected={true} cy={`${cy} next`} />
        )

    if (!executionStarted || executing === 'pending')
        return <RowCheckmark disabled={executionStarted} id={id} ref={ref} selected={selected} cy={cy} />

    if (executing === 'in-progress')
        return (
            <Holder data-cy={`${cy} in-progress`}>
                <LoadingCircle>
                    <LoadingSpinner overBackground="front.subtleAccent.background" />
                </LoadingCircle>
            </Holder>
        )
    if (executing === 'failed')
        return (
            <Holder data-cy={`${cy} failed`}>
                <FailedCircle>
                    <WarningIconFix>
                        <Icon type="warning" weight={1.33} size={17} />
                    </WarningIconFix>
                    <Failed>Fail</Failed>
                </FailedCircle>
            </Holder>
        )
    if (executing === 'done')
        return (
            <Holder data-cy={`${cy} done`}>
                <SuccessCircle>
                    <WarningIconFix>
                        <Icon type="checkmark" weight={1.33} />
                    </WarningIconFix>
                </SuccessCircle>
            </Holder>
        )
    return (
        <Holder data-cy={cy}>
            <EmptyCircle></EmptyCircle>
        </Holder>
    )
}

const Holder = styled.div`
    ${TableCircleStyles.holder};
`

const LoadingCircle = styled.div`
    ${TableCircleStyles.circle};
    border: 1px solid transparent;
`

const SuccessCircle = styled.div`
    ${TableCircleStyles.circle};
    background-color: ${(p) => p.theme['front.success.color']};
    border-color: ${(p) => p.theme['front.success.color']};
    color: ${(p) => p.theme['front.success.text']};
`
const FailedCircle = styled.div`
    ${TableCircleStyles.circle};
    background-color: ${(p) => p.theme['front.danger.color']};
    border-color: ${(p) => p.theme['front.danger.color.strongerI']};
    color: ${(p) => p.theme['front.danger.text']};
    position: relative;
`

const EmptyCircle = styled.div`
    ${TableCircleStyles.circle};
    background-color: transparent;
    color: transparent;
`

const WarningIconFix = styled.div`
    position: relative;
    top: 1px;
`

const Failed = styled.div`
    position: absolute;
    transform: translateX(110%);
    padding: 2px 7px;
    border-radius: 5px;
    background-color: ${(p) => p.theme['floating.background']};
    &::after {
        background-color: ${(p) => p.theme['floating.background']};
        display: inline-block;
        padding: 3px;
        content: '';
        position: absolute;
        left: -1px;
        top: 50%;
        transform: rotate(-45deg) translateY(-50%);
    }
`

export const CriticalFlowSettlementsCheck = React.forwardRef(CriticalFlowSettlementsCheckWithRef)
