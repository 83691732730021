import styled from 'styled-components'
import { Flex } from '../../layout/flex'
import { Text } from '../../general/text'
import { Spacer } from '../../layout/spacer'
import { Task } from '../../../store/tasks/types'
import { numberWithCommas } from '../../../utils'
import { ContextCardHolder } from './commons'

export const CurrencyContextCard: React.FC<{ data?: any; task: Task }> = ({ data, task }) => {
    return (
        <ContextCardHolder justify="flex-start" align="baseline">
            <Flex align="baseline">
                <Text size="l" bold>
                    {numberWithCommas(data.data.value, true)}
                </Text>
                <Spacer width={3} />
                {task.account?.data?.currency ? <Text size="s">{task.account.data.currency}</Text> : null}
            </Flex>
            <Holder align="flex-start">{data.name}</Holder>
        </ContextCardHolder>
    )
}

const Holder = styled(Flex)`
    margin-left: 25px;
    padding-left: 25px;
    @media print {
        margin-left: 10px !important;
        padding: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 10px !important;
    }
`
