/* eslint-disable max-len */
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { useNamedWatcher } from '../../hooks/general/useWatcher'

import { RootState } from '@/store'
import { MerchantInternalNotesItem } from './MerchantSidebarInternalNotes.Item'
import { Text } from '../../components/general/text'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { LoaderView } from '../../components/loaders/loader'
import { zIndexes } from '../../styles/zIndexes'
import { MarkdownPreviewItem } from '../../components/general/markdownPreviewItem'
import { Color } from '../../styles/colors'
import { MerchantSidebarShadow, MERCHANT_SIDEBAR_SIZE } from './MerchantSidebars'
import { useOnPropagatingDocumentClick } from '../../hooks/general/useOnPropagatingDocumentClick'
import { FloatingSidebarHeader } from '../../components/layout/floatingSidebarHeader'
import { LinkButton } from '../../components/buttons/linkButton'
import { LinkButtonArrow } from '../../components/buttons/linkButtonArrow'
import { MerchantInternalNotesAction } from './MerchantSidebarInternalNotes.Action'
import { camelCase } from 'lodash'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const InternalNotesParams = ['internal-notes']
export const MerchantInternalNotes: React.FC<{
    hideMessageBox?: boolean
    shouldShow?: boolean
}> = ({ shouldShow }) => {
    const params = useParams() as Params
    const { id } = params || {}
    const [query, setQuery] = useQueryParams(InternalNotesParams, undefined)
    const [push, pop] = useModalStackSync(null, 'Merchant.InternalNotes', undefined, true)

    useOnPropagatingDocumentClick(
        useCallback(() => {
            setQuery({
                'internal-notes': undefined
            })
        }, [setQuery]),
        'data-prevent-notes-exit',
        shouldShow
    )
    const interactions = useSelector((state: RootState) => state.applications.applications.at[id]?.internalInteractions)
    const lastItemRef = useRef<any>()
    const sendButtonRef = useRef<any>()

    const selectedTab = useMemo(() => {
        return query['internal-notes'] === 'everything' ? null : query['internal-notes']
    }, [query])

    const [sendCommentWatcher] = useNamedWatcher(`${id}.Comment`)

    useEffect(() => {
        if (interactions?.loadingStatus === 'done') {
            lastItemRef?.current?.scrollIntoView({
                block: 'center',
                inline: 'center'
            })
        }
    }, [interactions, selectedTab])

    useEffect(() => {
        if (sendCommentWatcher === 'success') {
            sendButtonRef?.current?.scrollIntoView({
                block: 'center',
                inline: 'center'
            })
        }
    }, [sendCommentWatcher])

    useEffect(() => {
        if (shouldShow) {
            push()
            return () => {
                pop()
            }
        }
    }, [shouldShow, pop, push])

    const itemNumbers = useMemo(
        () => ({
            total:
                (interactions?.comments ? interactions.comments.length : 0) +
                (interactions?.files ? interactions.files.length : 0),
            comments: interactions?.comments ? interactions.comments.filter((x) => x.field === 'message').length : 0,
            callNotes: interactions?.comments ? interactions.comments.filter((x) => x.field === 'call').length : 0,
            files: interactions?.files ? interactions.files.length : 0
        }),
        [interactions]
    )

    const items = useMemo(() => {
        let stack: any[] = []

        if (selectedTab === 'comments' || !selectedTab)
            stack = [
                ...stack,
                ...(interactions?.comments
                    ? interactions.comments
                          .filter((x) => x.field === 'message')
                          .map((c: any) => ({
                              ...c,
                              type: 'comment'
                          }))
                    : [])
            ]

        if (selectedTab === 'call-notes' || !selectedTab)
            stack = [
                ...stack,
                ...(interactions?.comments
                    ? interactions.comments
                          .filter((x) => x.field === 'call')
                          .map((c: any) => ({
                              ...c,
                              type: 'comment'
                          }))
                    : [])
            ]

        if (selectedTab === 'files' || !selectedTab)
            stack = [
                ...stack,
                ...(interactions?.files
                    ? interactions.files.map((f: any) => ({
                          ...f,
                          type: 'file'
                      }))
                    : [])
            ]

        stack.sort((a: any, b: any) => {
            if (moment(a.createdAt).isBefore(moment(b.createdAt))) return -1
            return 1
        })
        return stack
    }, [interactions, selectedTab])

    const renderTabNavigationLink = useCallback(
        (label: string, count: number, to: string) => {
            let background: Color | undefined = undefined
            let color: Color | undefined = undefined

            if (query['internal-notes'] === to) {
                background = 'front.accent.color'
                color = 'front.accent.text'
            }

            return (
                <SimpleButton
                    background={background}
                    color={color}
                    cy={camelCase('tab-' + label)}
                    onClick={() =>
                        setQuery((q: any) => {
                            return {
                                ...q,
                                'internal-notes': to
                            }
                        })
                    }
                >
                    <ButtonInset padding="medium">
                        {label} {count ? `(${count})` : ''}
                    </ButtonInset>
                </SimpleButton>
            )
        },
        [setQuery, query]
    )

    const renderedTabNavigation = useMemo(() => {
        return (
            <Flex justify="center">
                <LinksHolder>
                    {renderTabNavigationLink('Everything', itemNumbers.total, 'everything')}
                    {renderTabNavigationLink('Comments', itemNumbers.comments, 'comments')}
                    {renderTabNavigationLink('Call notes', itemNumbers.callNotes, 'call-notes')}
                    {renderTabNavigationLink('Files', itemNumbers.files, 'files')}
                </LinksHolder>
            </Flex>
        )
    }, [itemNumbers, renderTabNavigationLink])

    const handleExitClick = useCallback(() => {
        setQuery((q: any) => ({
            ...q,
            'internal-notes': undefined
        }))
    }, [setQuery])

    const handleCardClick = useCallback((e) => {
        e.stopPropagation()
        // e.preventDefault()
    }, [])

    useHotkey({
        keys: 'esc, alt+esc',
        action: handleExitClick,
        scope: 'Merchant.InternalNotes',
        clue: 'right'
    })

    const renderPreviewBox = useCallback(
        (element) => {
            return (
                <NoteCard>
                    <CardInset type="small">
                        <Header>
                            <Text bold>{selectedTab !== 'call-notes' ? 'Note preview:' : 'Call note preview'}</Text>
                            <LinkButton
                                color="front.accent.color"
                                to="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                                target="blank"
                            >
                                <ButtonInset noHorizontalPadding>
                                    Formatting guide
                                    <Spacer width={5} />
                                    <LinkButtonArrow background="front.background" shouldBeBordered />
                                </ButtonInset>
                            </LinkButton>
                        </Header>
                        {element}
                    </CardInset>
                </NoteCard>
            )
        },
        [selectedTab]
    )

    return (
        <Holder
            onClick={handleExitClick}
            data-prevent-sidebar-exit
            data-cy="internal-notes-sidebar"
            data-prevent-notes-exit
            data-prevent-interactions-exit
            shouldShow={shouldShow}
        >
            <CardHolder onClick={handleCardClick}>
                {interactions?.loadingStatus !== 'done' ? (
                    <>
                        <FloatingSidebarHeader
                            handleExitClick={handleExitClick}
                            title={`Internal ${selectedTab ? selectedTab : 'notes'} (${items.length})`}
                        />
                        <LoaderView overBackground="front.background" offsetTop={-150} />
                    </>
                ) : (
                    <>
                        <BoxHolder>
                            <FloatingSidebarHeader
                                handleExitClick={handleExitClick}
                                title={`Internal ${selectedTab ? selectedTab : 'notes'} (${items.length})`}
                            />
                            {renderedTabNavigation}
                        </BoxHolder>
                        <Spacer height={20} />
                        <Flex column style={{ flexGrow: 1 }} justify="flex-start">
                            <CardInset noVerticalPadding>
                                <Flex column style={{ flexGrow: 1 }} justify="flex-start">
                                    {items.length > 0 ? (
                                        <Flex grow column>
                                            {items.map((i: any, index, arr) => {
                                                return (
                                                    <NoteCard key={i.id}>
                                                        <MerchantInternalNotesItem
                                                            applicationId={id}
                                                            item={i}
                                                            type={i.type}
                                                        />
                                                    </NoteCard>
                                                )
                                            })}
                                            <MarkdownPreviewItem
                                                pageId="Merchant.InternalNotes"
                                                renderer={renderPreviewBox}
                                            />
                                        </Flex>
                                    ) : (
                                        <Flex align="stretch" grow column>
                                            <MarkdownPreviewItem
                                                pageId="Merchant.InternalNotes"
                                                renderer={renderPreviewBox}
                                            />
                                            <Spacer height={20} />
                                            <TextHolder>
                                                <Text color="front.text.subtlerI">
                                                    No internal notes have been found
                                                </Text>
                                            </TextHolder>
                                        </Flex>
                                    )}
                                    <Spacer height={100} />
                                    <div ref={lastItemRef} />
                                    <MerchantInternalNotesAction id={id} selectedTab={selectedTab} />
                                </Flex>
                            </CardInset>
                        </Flex>
                    </>
                )}
            </CardHolder>
            <MerchantSidebarShadow show={true} />
        </Holder>
    )
}

export const NoteCard = styled.div`
    padding: 10px;
    background: ${(p) => p.theme['front.background']};
    color: ${(p) => p.theme['front.text']};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`

const CardHolder = styled.div`
    position: relative;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: ${MERCHANT_SIDEBAR_SIZE};
    z-index: 10;
    overflow-y: scroll;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;
    background-size: 100px 70px;
    background: ${(p) => p.theme['grunge']};
`

const Holder = styled.div<{ shouldShow?: boolean }>`
    position: fixed;
    top: 0px;
    right: 0px;
    border-radius: 0px;
    height: 100vh;
    z-index: ${zIndexes.modal};
    display: none;

    ${(p) =>
        p.shouldShow &&
        css`
            display: block;
        `}
`

const TextHolder = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
`

const LinksHolder = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: sticky;
    top: 51px;
    left: 0;
    margin-left: 20px;
    width: auto;
    box-sizing: border-box;
    z-index: ${zIndexes.popoverLevel3};
    background-color: ${(p) => p.theme['loaders']};
    /* rgba(0, 0, 0, 0.04); */
    box-shadow:
        0px 1px 2px rgba(255, 255, 255, 0.2),
        inset 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
`

const Header = styled.div`
    border-bottom: 1px solid ${(p) => p.theme['front.border']};
    margin-top: -10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const BoxHolder = styled.div`
    position: sticky;
    top: 0;
    background: ${(p) => p.theme['grunge']};
    box-shadow: 0px 6px 12px -12px #111;
    z-index: 1000;
`
