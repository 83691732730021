import dotProp from 'dot-prop'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { SmartNavLink } from '../../../components/tables/smartNavLink'
import {
    ApplicationResourceCommentsResource,
    ApplicationResourceHistory,
    MerchantApplicationResourceIndexPath
} from '../../../store/applicationResources/types'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { RootState } from '@/store'
import { ConvertIndexPathToFieldDetails } from './Application.Structure'
import {
    MerchantApplicationFieldInnerStyles,
    MerchantApplicationSelectedBarStyles
} from './Application.FieldInnerStyles'
import { generateHighlightedFieldComponentStyles } from './Application.Utils'
import { NavLinkProps, useLocation } from 'react-router-dom'

export const MerchantApplicationFieldCounter: React.FC<{
    isLoading: boolean
    applicationId: string
    indexPath: MerchantApplicationResourceIndexPath
    highlighted?: 'top' | 'bottom' | 'both'
    type: 'edits' | 'comments'
    sanitised?: boolean
    isActive?: boolean
    isPressed?: boolean
    isLast?: boolean
}> = ({ type, applicationId, isPressed, isActive, indexPath, highlighted, isLast }) => {
    const commentsLength = useSelector((state: RootState) => {
        return (
            dotProp.get<ApplicationResourceCommentsResource>(
                state,
                `applicationResources.comments.forApplication.${applicationId}.${indexPath.resourceKey}.fields.${
                    indexPath.subsectionIndex || 0
                }.${indexPath.fieldKey}`
            )?.comments?.length || 0
        )
    })
    const commentsNeedAttention = useSelector(
        (state: RootState) =>
            state.applicationInternals.needingAttention?.[applicationId]?.fieldsWithUnreadComments?.[
                ConvertIndexPathToIndexPathString(indexPath)
            ]
    )
    const fieldDetails = useMemo(() => ConvertIndexPathToFieldDetails(indexPath), [indexPath])
    const editsLength = useSelector((state: RootState) => {
        return (
            dotProp.get<ApplicationResourceHistory[]>(
                state,
                `applicationResources.history.forApplication.${applicationId}.${indexPath.resourceKey}.fields.${
                    indexPath.subsectionIndex || 0
                }.${indexPath.fieldKey}`
            )?.length || 0
        )
    })
    const subSectionId = useSelector((state: RootState) => {
        return state.applicationResources.data.forApplication[applicationId]?.[indexPath.resourceKey]?.fields?.[
            indexPath.subsectionIndex || 0
        ]?.id
    })

    const numberOfItems = useMemo(() => {
        if (type === 'comments') return commentsLength || 0
        if (type === 'edits') return editsLength || 0
        return 0
    }, [type, commentsLength, editsLength])

    const icon = useMemo((): JSX.Element => {
        switch (type) {
            case 'edits': {
                return <Icon type="history" size={11} />
            }
            case 'comments': {
                return <Icon type="comment" size={10} />
            }
            default:
                return <span />
        }
    }, [type])

    const shouldDisplay = useMemo(() => {
        if (isActive) return true
        switch (type) {
            case 'edits': {
                if (numberOfItems > 1) return true
                return false
            }
            case 'comments': {
                if (numberOfItems > 0) return true
                return false
            }
            default:
                return false
        }
    }, [type, numberOfItems, isActive])

    const isABlankFile = useSelector((state: RootState) => {
        if (!fieldDetails) return false
        if (fieldDetails.field.type !== 'file') return false
        return !state.applicationResources.data.forApplication[applicationId]?.[indexPath.resourceKey]?.fields?.[
            indexPath?.subsectionIndex || 0
        ]?.[indexPath.fieldKey]?.length
    })

    const shouldHideCompletely = useMemo(() => {
        if (!fieldDetails) return false
        switch (type) {
            case 'edits': {
                if (fieldDetails.field.type === 'file') return true
                return false
            }
            case 'comments': {
                if (fieldDetails.field.sanitised) return true
                if (indexPath.resourceKey === 'contact' && indexPath.fieldKey === 'email') {
                    return true
                }
                if (indexPath.resourceKey === 'people' && indexPath.fieldKey === 'role') {
                    return true
                }
                if (isABlankFile) return true
                return false
            }
            default:
                return false
        }
    }, [type, isABlankFile, fieldDetails, indexPath.resourceKey, indexPath.fieldKey])

    const location = useLocation()
    const to = useMemo(() => {
        return {
            pathname: `/merchant/${applicationId}/application/${type}/${ConvertIndexPathToFieldDetails(
                indexPath
            ).urlPath.replace('<ID>', subSectionId)}`,
            search: location.search
        }
    }, [applicationId, type, indexPath, location, subSectionId])

    if (shouldHideCompletely) return <EmptySpace highlighted={highlighted} isSelected={isActive} />

    return (
        <MerchantApplicationFieldCounterButtonElement
            data-cy={type}
            isLast={isLast}
            $highlighted={highlighted}
            $isSelected={isActive}
            $needsAttention={commentsNeedAttention && type === 'comments'}
            $isPressed={isPressed}
        >
            <Bubble
                tabIndex={-1}
                $needsAttention={commentsNeedAttention && type === 'comments'}
                $isPressed={isPressed}
                $shouldDisplay={shouldDisplay}
                $isSelected={isActive}
                data-needs-attention={commentsNeedAttention && type === 'comments' && 'yes'}
                to={to}
            >
                <IconHolder>{icon}</IconHolder>
                <Text noWrap>{numberOfItems}</Text>
            </Bubble>
        </MerchantApplicationFieldCounterButtonElement>
    )
}

interface BubbleProps extends NavLinkProps {
    $isPressed?: boolean
    $shouldDisplay?: boolean
    $isSelected?: boolean
    $needsAttention?: boolean
    // Add other custom props if needed
}

export const Bubble = styled(SmartNavLink).attrs<BubbleProps>((p: any) => ({
    $isPressed: p.isPressed,
    $shouldDisplay: p.shouldDisplay,
    $isSelected: p.isSelected,
    $needsAttention: p.needsAttention
}))`
    display: flex;
    border-radius: 999px;
    box-sizing: border-box;
    height: 18px;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    color: inherit;
    text-decoration: none;
    opacity: 0;
    ${(p: any) =>
        p.$shouldDisplay &&
        css`
            opacity: 1;
        `}
    ${(p) =>
        p.$isPressed &&
        css`
            background-color: ${p.theme['front.background.strongerI']};
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            padding: 0 10px;
            margin: 0 -10px;
            width: calc(100% + 20px);
        `}
    ${(p) =>
        p.$needsAttention &&
        css`
            background-color: ${p.theme['front.info.background']} !important;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            padding: 0 10px;
            margin: 0 -10px;
            width: calc(100% + 20px);
            color: ${p.theme['front.info.text']};
            &:hover {
                background-color: ${p.theme['front.info.background.strongerI']} !important;
            }

            &:active {
                background-color: ${p.theme['front.info.background.strongerII']} !important;
            }
        `};
`

const EmptySpace = styled.div<{ highlighted?: 'top' | 'bottom' | 'both'; isSelected?: boolean }>`
    ${MerchantApplicationFieldInnerStyles};
    ${(p) => generateHighlightedFieldComponentStyles(p.highlighted, undefined)};
    margin-left: -1px;
    ${MerchantApplicationSelectedBarStyles()};
`

export const MerchantApplicationFieldCounterButtonElement = styled.div<{
    $highlighted?: 'top' | 'bottom' | 'both'
    $isSelected?: boolean
    $isPressed?: boolean
    $needsAttention?: boolean
}>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    right: 0;
    top: 0;
    flex-wrap: no-wrap;
    text-decoration: none;
    color: ${(p) => p.theme['front.text.subtlerI']};
    z-index: 1;

    /* ${MerchantApplicationFieldInnerStyles};
    ${(p) => generateHighlightedFieldComponentStyles(p.$highlighted, undefined)}; */

    box-sizing: border-box;
    /* ${MerchantApplicationSelectedBarStyles(undefined, true)}; */

    &:hover {
        color: ${(p) => p.theme['front.text.subtlerII']};
    }

    &:active {
        color: ${(p) => p.theme['front.text.subtlerIII']};
    }
    ${(p) =>
        p.$isSelected &&
        css`
            color: ${p.theme['floating.text']};

            &:hover {
                color: ${p.theme['floating.text.subtlerI']};
            }

            &:active {
                color: ${p.theme['floating.text.subtlerII']};
            }
        `};

    ${(p) =>
        p.$needsAttention &&
        css`
            z-index: 2;
        `}
    ${(p) =>
        p.$isPressed &&
        css`
            color: ${p.theme['front.text']} !important;
        `};
` as any

const IconHolder = styled.div`
    position: relative;
    margin-right: 4px;
`
