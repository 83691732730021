import { createActionCreator } from 'deox'

import { Related } from './types'

const Prefix = 'APPLICATION_RELATED'

export const ApplicationInternalsRelatedActions = {
    LOAD: createActionCreator(
        `${Prefix}/LOAD`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),

    LOAD_NEXT: createActionCreator(
        `${Prefix}/LOAD_NEXT`,
        (resolve) => (applicationId: string, related?: Related[], nextLink?: string, count?: number) => 
            resolve({applicationId, related, nextLink, count })
    ),

    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (applicationId: string, related?: Related[], nextLink?: string, count?: number) =>
            resolve({ applicationId, related, nextLink, count })
    )
}
