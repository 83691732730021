import { camelCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ButtonInset } from '../../components/buttons/buttonInset'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { ColorTag } from '../../components/general/colorTag'
import { Flex } from '../../components/layout/flex'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { TextInput } from '../../components/forms/textInput'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { ModalPage } from '../../components/layout/modalPage'
import { ModalHeader } from '../../components/modals/modalHeader'
import { RootState } from '@/store'
import { TaskDispatchAssignTag, TaskDispatchRemoveTag } from '../../store/tasks/actions'
import { WatcherID } from '../../store/watcher/types'
import { AlertTagsManagerCreateTag } from './Alerts.ID.TagsCreate'
import { useParams } from 'react-router-dom'

export const AlertTagsManager: React.FC = () => {
    const dispatch = useDispatch()

    const params = useParams()
    const taskId = params.id
    const task = useSelector((state: RootState) => {
        if (taskId) {
            return state.tasks.tasks.at[taskId]
        }
    })
    const tags = useSelector((state: RootState) => state.tasks.tags)
    const [input, setInput] = useRefTaker()
    const [filterBy, setFilterBy] = useState('')

    const tagRows = useMemo(() => {
        if (!task || !task.tags) return []

        return tags.all.map((t) => ({
            name: tags.at[t].name,
            id: tags.at[t].id,
            assigned: task.tags.includes(tags.at[t].name)
        }))
    }, [tags, task])

    useEffect(() => {
        input?.focus()
    }, [input])

    const handleAssignClick = useCallback(
        (tag: (typeof tagRows)[0], watcherId: WatcherID) => {
            if (task?.selfLink) {
                if (tag.assigned) {
                    dispatch(TaskDispatchRemoveTag(watcherId, task.selfLink, tag.name))
                } else {
                    dispatch(TaskDispatchAssignTag(watcherId, task.selfLink, tag.name))
                }
            }
        },
        [dispatch, task]
    )

    return (
        <ModalPage
            title="Alert - Manage tags"
            pageId="AlertTagsManager"
            backTo={`/alerts/${taskId}`}
            isLoading={task?.loadingStatus !== 'done'}
        >
            <ModalHeader backTo={`/alerts/${taskId}`} pageId="AlertTagsManager" title="Alert tag management" />
            <PageWrapper shouldCenter>
                <Card higher>
                    <CardInset>
                        <Content>
                            <Flex>
                                <TextInput
                                    overBackground="front.background"
                                    placeholder="Filter tags"
                                    ref={setInput}
                                    cy="filter-tags"
                                    onChange={(e, value) => {
                                        setFilterBy(value)
                                    }}
                                />
                                <Spacer width={10} />
                                {task && <AlertTagsManagerCreateTag task={task} />}
                            </Flex>
                            <Spacer height={20} />
                            <Table
                                background="front.background"
                                type="narrow"
                                rows={tagRows
                                    .filter((t: any) => t.name.toLowerCase().includes(filterBy.toLowerCase()))
                                    .map((t: any) => ({
                                        type: 'normal',
                                        key: camelCase(t.name),
                                        noAction: true,
                                        items: [
                                            {
                                                node: <ColorTag overBackground="front.background" name={t.name} />
                                            },
                                            {
                                                node: (
                                                    <WatcherButton
                                                        background={
                                                            t.assigned ? 'front.subtleAccent.background' : undefined
                                                        }
                                                        color={'front.accent.color'}
                                                        cy="assign"
                                                        onClick={(e, generatedId) => {
                                                            handleAssignClick(t, generatedId)
                                                        }}
                                                    >
                                                        <ButtonInset padding="small">
                                                            {t.assigned ? 'Unassign' : 'Assign'}
                                                        </ButtonInset>
                                                    </WatcherButton>
                                                )
                                            }
                                        ]
                                    }))}
                                cols={[{ text: 'Tag name' }, { text: 'Actions' }]}
                                columnLayout="auto min-content"
                            />
                        </Content>
                    </CardInset>
                </Card>
                <Spacer height={20} />
            </PageWrapper>
        </ModalPage>
    )
}

const Content = styled.div`
    min-height: 70vh;
`
