import React from 'react'
import { MonitoredChange, MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { MonitoringNameChange } from '../../../cards/MonitoringNameChange/MonitoringNameChange'

interface CardNameChangeProps {
    taskId: string
    applicationId: string
    applicationData: MonitoredFetchedApplicationData
    change: MonitoredChange
}

export const CardNameChange: React.FC<CardNameChangeProps> = (props) => {
    const { taskId, applicationId, applicationData, change } = props

    return (
        <MonitoringNameChange
            taskId={taskId}
            applicationId={applicationId}
            applicationData={applicationData}
            before={change.before}
            after={change.after as string}
        />
    )
}
