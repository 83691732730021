import { Action } from 'redux'
import { SubmitFileData, File, CutterSubmitFileData } from './types'
import { WatcherID } from '../watcher/types'

export const FILES_UPLOAD = 'FILES_UPLOAD'
export const FILES_STORE = 'FILES_STORE'
export const FILES_CLEAR_ALL = 'FILES_CLEAR_ALL'
export const FILE_DELETE = 'FILE_DELETE'
export const FILE_LOOKOUT_FETCH = 'FILE_LOOKOUT_FETCH'
export const FILE_LOOKOUT_REFETCH = 'FILE_LOOKOUT_REFETCH'
export const FILE_UNSTORE = 'FILE_UNSTORE'
export const FILE_ADD_METADATA = 'FILE_ADD_METADATA'
export const FILE_STORE = 'FILE_STORE'
export const FILE_STORE_PROGRESS = 'FILE_STORE_PROGRESS'
export const FILE_GENERATE_DOWNLOAD_LINK = 'FILE_GENERATE_DOWNLOAD_LINK'
export const FILE_REMOVE_DOWNLOAD_LINK = 'FILE_REMOVE_DOWNLOAD_LINK'
export const FILE_STORE_DOWNLOAD_LINK = 'FILE_STORE_DOWNLOAD_LINK'
export const FILE_FETCH = 'FILE_FETCH'
export const FILE_REPLACE = 'FILE_REPLACE'

//-
export interface FilesActionUpload {
    type: typeof FILES_UPLOAD
    source: string
    fileData: Array<SubmitFileData | CutterSubmitFileData>
    linkFiles: (files: any) => Action
    withDocumentPrefix?: boolean
    fileToReplace?: File
    watcherId?: string
    shouldStallProgress?: boolean
}
export interface FilesActionStore {
    type: typeof FILES_STORE
    files: File[]
}
export interface FileActionStore {
    type: typeof FILE_STORE
    file: File
}
export interface FileActionUnstore {
    type: typeof FILE_UNSTORE
    file: File
}
export interface FileActionAddMetadata {
    type: typeof FILE_ADD_METADATA
    file: File
}
export interface FileActionDelete {
    type: typeof FILE_DELETE
    watcherId: WatcherID
    file: File
    resourceKey?: string
    unlinkFiles: (files: any) => Action
    byPost?: boolean
}
export interface FileActionLookoutFetch {
    type: typeof FILE_LOOKOUT_FETCH
    applicationId: string
    reportType: string
}
export interface FileActionLookoutRefetch {
    type: typeof FILE_LOOKOUT_REFETCH
    file: File
}
export interface FileActionStoreProgress {
    type: typeof FILE_STORE_PROGRESS
    fileId: string
    progress: number
}
export interface FileActionGenerateDownloadLink {
    type: typeof FILE_GENERATE_DOWNLOAD_LINK
    file: File
}
export interface FileActionRemoveDownloadLink {
    type: typeof FILE_REMOVE_DOWNLOAD_LINK
    file?: File
}
export interface FileActionFetch {
    type: typeof FILE_FETCH
    id: string
    watcherId?: string
    extraContext?: any
}
export interface FileActionStoreDownloadLink {
    type: typeof FILE_STORE_DOWNLOAD_LINK
    fileId: string
    link: string
}
export interface FilesActionClearAll {
    type: typeof FILES_CLEAR_ALL
}
export interface FileActionReplace {
    type: typeof FILE_REPLACE
    watcherId: string
    uploadTo: string
    newFileData: Array<SubmitFileData | CutterSubmitFileData>
    replacingFile: File
    linkFiles: (files: any) => Action
    unlinkFiles: (files: any) => Action
    withDocumentPrefix?: boolean
}

export const FilesActionDispatchUpload = (
    source: string,
    fileData: Array<SubmitFileData | CutterSubmitFileData>,
    linkFiles: (files: any) => Action,
    withDocumentPrefix?: boolean,
    fileToReplace?: File,
    watcherId?: WatcherID,
    shouldStallProgress?: boolean
): FilesActionUpload => {
    return {
        type: FILES_UPLOAD,
        source,
        fileData,
        linkFiles,
        withDocumentPrefix,
        fileToReplace,
        watcherId,
        shouldStallProgress
    }
}

export const FilesActionDispatchStore = (files: File[]): FilesActionStore => {
    return { type: FILES_STORE, files }
}

export const FilesActionDispatchClearAll = (): FilesActionClearAll => {
    return { type: FILES_CLEAR_ALL }
}
export const FileActionDispatchDelete = (
    watcherId: string,
    file: File,
    unlinkFiles: (files: any) => Action,
    byPost?: boolean,
    resourceKey?: string
): FileActionDelete => {
    return { type: FILE_DELETE, file, watcherId, resourceKey, unlinkFiles, byPost }
}

export const FileActionDispatchLookoutFetch = (
    applicationId: string,
    reportType: string
): FileActionLookoutFetch => {
    return { type: FILE_LOOKOUT_FETCH, applicationId, reportType }
}

export const FileActionDispatchLookoutRefetch = (
    file: File
): FileActionLookoutRefetch => {
    return { type: FILE_LOOKOUT_REFETCH, file }
}

export const FileActionDispatchReplace = (
    watcherId: string,
    uploadTo: string,
    newFileData: Array<SubmitFileData | CutterSubmitFileData>,
    replacingFile: File,
    linkFiles: (files: any) => Action,
    unlinkFiles: (files: any) => Action,
    withDocumentPrefix?: boolean
): FileActionReplace => {
    return {
        type: FILE_REPLACE,
        watcherId,
        uploadTo,
        newFileData,
        replacingFile,
        linkFiles,
        unlinkFiles,
        withDocumentPrefix
    }
}
export const FileActionDispatchUnstore = (file: File): FileActionUnstore => {
    return { type: FILE_UNSTORE, file }
}

export const FileActionDispatchAddMetadata = (file: File): FileActionAddMetadata => {
    return { type: FILE_ADD_METADATA, file }
}

export const FileActionDispatchStore = (file: File): FileActionStore => {
    return { type: FILE_STORE, file }
}

export const FileActionDispatchStoreProgress = (fileId: string, progress: number): FileActionStoreProgress => {
    return { type: FILE_STORE_PROGRESS, fileId, progress }
}

export const FileActionDispatchGenerateDownloadLink = (file: File): FileActionGenerateDownloadLink => {
    return { type: FILE_GENERATE_DOWNLOAD_LINK, file }
}

export const FileActionDispatchStoreDownloadLink = (fileId: string, link: string): FileActionStoreDownloadLink => {
    return { type: FILE_STORE_DOWNLOAD_LINK, fileId, link }
}

export const FileActionDispatchRemoveDownloadLink = (file?: File): FileActionRemoveDownloadLink => {
    return { type: FILE_REMOVE_DOWNLOAD_LINK, file }
}

export const FileActionDispatchFetchFile = (id: string, watcherId?: string, extraContext?: any): FileActionFetch => {
    return { type: FILE_FETCH, id, watcherId, extraContext }
}

export interface SagasForFiles {
    uploadFiles(action: FilesActionUpload): void
    deleteFile(action: FileActionDelete): void
    fetchLookoutFile(action: FileActionLookoutFetch): void
    refetchLookoutFile(action: FileActionLookoutRefetch): void
    replaceFile(action: FileActionReplace): void
    fetchFile(action: FileActionFetch): void
    generateDownloadLink(action: FileActionGenerateDownloadLink): void
}

export type ReducedFilesActions =
    | FilesActionStore
    | FilesActionUpload
    | FileActionStoreProgress
    | FileActionUnstore
    | FileActionAddMetadata
    | FileActionStore
    | FileActionGenerateDownloadLink
    | FileActionStoreDownloadLink
    | FilesActionClearAll
    | FileActionRemoveDownloadLink
