import { createActionCreator } from 'deox'
import { WatcherID } from '../../watcher/types'

import { MerchantApplicationInternalDetailValue, MerchantApplicationsInternalsDetailsState } from './types'

// --

const Prefix = 'APPLICATION_INTERNALS_DETAILS'

export const ApplicationInternalsDetailsActions = {
    UPDATE_VALUE: createActionCreator(
        `${Prefix}/UPDATE_VALUE`,
        (resolve) => (
            type: MerchantApplicationInternalDetailValue,
            fieldPath: string,
            applicationId: string,
            value: string,
            watcherId: string
        ) => resolve({ watcherId, applicationId, fieldPath, value, type })
    ),
    STORE_VALUES: createActionCreator(`${Prefix}/STORE_VALUES`, (resolve) => (data: any) => resolve({ data })),
    STORE_FROM_ENTITY: createActionCreator(
        `${Prefix}/STORE_FROM_ENTITY`,
        (resolve) => (entity: keyof MerchantApplicationsInternalsDetailsState['ID'], id: string, data: any) =>
            resolve({ entity, id, data })
    ),
    START_CLONING: createActionCreator(`${Prefix}/START_CLONING`, (resolve) => (id: string) => resolve({ id })),
    STOP_CLONING: createActionCreator(`${Prefix}/STOP_CLONING`, (resolve) => (id: string) => resolve({ id })),
    CLONE: createActionCreator(`${Prefix}/CLONE`, (resolve) => (id: string, watcherId: WatcherID, toState: string) =>
        resolve({ watcherId, toState, id })
    )
}
