import React from 'react'
import { TagsLister as TagsListerComp } from '@/pages/Merchant/MerchantTagsLister'

interface TagsListerProps {
    tags: string[]
}

export const TagsLister: React.FC<TagsListerProps> = (props) => {
    const { tags } = props

    return <TagsListerComp overBackground="front.background" tags={tags} limit={2} />
}
