import { startCase } from 'lodash'

import styled from 'styled-components'

const statusToColor: { [key: string]: string } = {
    answered: '#FFE3BA',
    incoming: '#FFCBCB',
    inactive: '#DDDDDD'
}

export const CallStatus: React.FC<{ status: string }> = ({ status }) => {
    return <StatusBox backgroundColor={statusToColor[status]}>{startCase(status)}</StatusBox>
}

const StatusBox = styled.div<{
    backgroundColor: string
}>`
    padding: 2px 8px;
    margin: 0 1px;
    background-color: ${(p) => p.backgroundColor};
    color: #000;
    border-radius: 4px;
`
