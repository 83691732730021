import { createApi } from '@reduxjs/toolkit/query/react'
import { halBaseQuery } from '../common/hal-base-query'

const reducerPath = 'merchant-api'
const tagTypes = ['partners', 'partner', 'integrations', 'integration', 'signing-keys', 'signing-key']

const baseQuery = halBaseQuery()

export const api = createApi({
    reducerPath,
    baseQuery,
    tagTypes,
    endpoints: () => ({})
})
