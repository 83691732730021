import { VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn } from '../utils'
import { styles } from './styles'

interface SpinnerProps extends VariantProps<typeof styles.spinner> {
    testid?: string
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
    const { testid, color } = props

    const classnames = cn(styles.spinner({ color }))

    return (
        <Container color={color}>
            <div data-cy={testid} className={classnames} />
        </Container>
    )
}

interface ContainerProps extends VariantProps<typeof styles.container> {
    children: React.ReactNode
}

const Container: React.FC<ContainerProps> = (props) => {
    const { children, color } = props

    const classnames = cn(styles.container({ color }))

    return <div className={classnames}>{children}</div>
}
