import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { Flex } from '../../../components/layout/flex'
import { ModalPage } from '../../../components/layout/modalPage'
import { Spacer } from '../../../components/layout/spacer'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { useQueryParams } from '../../../hooks/general/useQueryParam'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { ApplicationInternalsDetailsActions } from '../../../store/applicationInternals/details/actions'
import { useApplicationInternalValue } from './Application.useInternalValue'
import { useHaveApplicationInternalDetailsLoaded } from './useHaveApplicationInternalDetailsLoaded'

const params = ['edit-ownership-note']
export const ApplicationFieldModalInput: React.FC<{
    applicationId: string
}> = ({ applicationId }) => {
    const [query, setQuery] = useQueryParams(params)
    const dispatch = useDispatch()
    const [ref, setRef] = useRefTaker()
    const [value, setValue] = useState<string>('')
    const isSectionLoaded = useHaveApplicationInternalDetailsLoaded(applicationId)
    const { localValue, structure } = useApplicationInternalValue(
        applicationId,
        { resourceKey: 'company', subsectionIndex: 0, fieldKey: 'name' },
        'noteOwnershipStructure'
    )

    useEffect(() => {
        setValue(localValue)
    }, [localValue])

    const handleBack = useCallback(() => {
        setQuery({ 'edit-ownership-note': undefined })
    }, [setQuery])

    const handleSave = useCallback(
        (e, generatedId) => {
            dispatch(
                ApplicationInternalsDetailsActions.UPDATE_VALUE(
                    'noteOwnershipStructure',
                    structure.path.replace('<ID>', ''),
                    applicationId,
                    value,
                    generatedId
                )
            )
        },
        [dispatch, applicationId, value, structure]
    )

    const handleChange = useCallback((e: any, v: string) => setValue(v), [setValue])

    const handleSuccess = useCallback(() => {
        handleBack()
    }, [handleBack])

    useEffect(() => {
        ref?.focus()
    }, [ref])

    if (!query['edit-ownership-note']) return null

    return (
        <ModalPage
            overlay
            title="Edit ownership note"
            pageId="Merchant.ApplicationModal.EditOwnership"
            onBack={handleBack}
            isLoading={!isSectionLoaded}
        >
            <ModalHeader
                title="Edit ownership note"
                pageId="Merchant.ApplicationModal.EditOwnership"
                onBack={handleBack}
            />
            <Holder>
                <Card higher grow>
                    <CardInset>
                        <Flex align="stretch" grow>
                            <TextareaInput
                                monotype
                                ref={setRef}
                                cy="ownership-textarea"
                                placeholder="Enter ownership structure (Name, ID, Shares percentage, Country)"
                                overBackground="front.background"
                                initialValue={localValue}
                                escKeyOnceIsEnough
                                noMaxHeight
                                onChange={handleChange}
                            />
                        </Flex>
                        <Spacer height={20} />
                        <Flex justify="flex-end">
                            <SimpleButton background="front.subtleAccent.background" onClick={handleBack}>
                                <ButtonInset>Back</ButtonInset>
                            </SimpleButton>
                            <Spacer width={10} />
                            <WatcherButton
                                background="front.accent.color"
                                isDisabled={value === localValue}
                                onClick={handleSave}
                                hotkeysScope="Merchant.ApplicationModal.EditOwnership"
                                hotkeys="alt+enter"
                                onSuccess={handleSuccess}
                            >
                                <ButtonInset>Save ownership structure</ButtonInset>
                            </WatcherButton>
                        </Flex>
                    </CardInset>
                </Card>
            </Holder>
        </ModalPage>
    )
}

const Holder = styled.div`
    max-width: 900px;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
`
