import styled from 'styled-components'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { Icon } from '../../../components/icons/icon'
import { capture } from '@/utils/secretaryCapture'
import { useNavigate } from 'react-router-dom'

export const MerchantApplicationAddScreenshot: React.FC<{
    applicationId: string
    subsectionIndex: number
    redirectToWebsites?: boolean
}> = ({ applicationId, subsectionIndex, redirectToWebsites }) => {
    const navigate = useNavigate()
    const application = useSelector((state: RootState) => state.applicationResources.data.forApplication[applicationId])

    const company = useSelector((state: RootState) => state.applicationInternals.company.forApplication[applicationId])

    const website = application.websites.fields[subsectionIndex]

    return (
        <Holder>
            <div />
            <div />
            <SimpleButton
                onClick={() => {
                    capture(applicationId, company, website)
                    if (redirectToWebsites) navigate(`/merchant/${applicationId}/application/websites`)
                }}
            >
                <ButtonInset noHorizontalPadding noVerticalPadding padding="small" leftAlign>
                    <IconHolder>
                        <Icon type="plus" size={6} />
                    </IconHolder>
                    Add screenshot
                </ButtonInset>
            </SimpleButton>
        </Holder>
    )
}

const IconHolder = styled.div`
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 999px;
    display: flex;
    border: 1px solid currentColor;
    align-items: center;
    justify-content: center;
`

const Holder = styled.div`
    display: contents;
`
