import { startCase } from 'lodash'
import { ApplicationResourceCompanyOwnershipStructure } from '../../../store/applicationResources/types'

import { ConvertIndexPathStringToIndexPath } from '../../../store/applicationResources/utils'
import { ApplicationDeliveryDelayDictionary } from '../../../store/applications/types'
import { numberWithCommas } from '../../../utils'
import { getWebsiteLabel } from '../../../utils/formatting'
import { ConvertIndexPathToFieldDetails } from './Application.Structure'
import { MerchantApplicationFieldValueCompanyForm } from './Application.FieldValueCompanyForm'
import countries from 'i18n-iso-countries'
import moment from 'moment'

export const MerchantApplicationFieldFormattedTextValue = (
    gateways: { at: { [id: string]: { name: string } } },
    indexPathString: string,
    v: string
): string => {
    const indexPath = ConvertIndexPathStringToIndexPath(indexPathString)
    const fieldDetails = indexPath ? ConvertIndexPathToFieldDetails(indexPath) : undefined

    if (!indexPath || !fieldDetails || !fieldDetails.field) throw 'Failed to establish field value'
    if (fieldDetails.field.type === 'file') throw "Can't establish field value of a file"

    if (indexPath.resourceKey === 'businessModel' && indexPath.fieldKey === 'businessModel.description') return v

    switch (fieldDetails.field.type) {
        case 'question': {
            if (v === undefined) return '-'
            if (v) return 'Yes'
            return 'No'
        }
        case 'number': {
            return numberWithCommas(v, true)
        }
        case 'dropdown': {
            if (indexPath.fieldKey === 'businessModel.deliveryDelay' && indexPath.resourceKey === 'businessModel') {
                if (v === undefined) return '-'
                return (ApplicationDeliveryDelayDictionary as any)[v]
            }
            if (indexPath.fieldKey === 'form' && indexPath.resourceKey === 'company')
                return MerchantApplicationFieldValueCompanyForm(v)
            if (indexPath.fieldKey === 'role' && indexPath.resourceKey === 'people') {
                if (v === undefined) return '-'
                return startCase(v).replace(' And ', ' and ')
            }
            if (indexPath.fieldKey === 'ownershipStructure') {
                if (v === undefined) return '-'
                return (ApplicationResourceCompanyOwnershipStructure as any)[v]
            }
            if (indexPath.fieldKey === 'country') {
                if (v === undefined) return '-'

                const country = countries.getName(v.toUpperCase(), 'en')

                if (country === undefined) return '-'

                return country
            }
            if (indexPath.fieldKey === 'gateways.0.id' && indexPath.resourceKey === 'gateway') {
                if (v === undefined) return '-'
                return gateways.at?.[v]?.name
            }
            return v
        }
        case 'date':
            return moment(v).format('DD MMM, YYYY')
        case 'url': {
            if (v === undefined) return '-'
            return getWebsiteLabel(v)
        }
        default: {
            return v
        }
    }
}
