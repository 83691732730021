const checkBankAccountMismatch = (applicationState, bankAccount, accounts) => {
    if (applicationState.isLoading || bankAccount.isLoading || accounts.isLoading) {
        return false
    }

    if (applicationState.state === 'accepted') {
        return false
    }

    if (accounts.ibans.length === 0) {
        return false
    }

    if (!bankAccount.iban) {
        return false
    }

    const ibanMatchExists = accounts.ibans.some((accountIban) => accountIban === bankAccount.iban)

    if (ibanMatchExists) {
        return false
    }

    return true
}

export { checkBankAccountMismatch }
