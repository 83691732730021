import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { Spinner } from '../../../components/loaders/spinner'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { ApplicationInternalsChecksActions } from '../../../store/applicationInternals/checks/actions'
import { RootState } from '@/store'
import { MerchantApplicationReviewableResource } from './Application.StructureTypes'
import { Icon } from '../../../components/icons/icon'
import { useHasApplicationSidebarLoaded } from './useHasApplicationSidebarLoaded'

export const MerchantApplicationSectionCheckmark: React.FC<{
    applicationId: string
    resource: MerchantApplicationReviewableResource
    subsectionIndex?: number
    offset?: 'superfield' | 'smallerSuperField' | 'normalField'
}> = ({ subsectionIndex, resource, applicationId, offset }) => {
    const dispatch = useDispatch()
    const [watcher, watcherID] = useNamedWatcher()
    const hasSidebarLoaded = useHasApplicationSidebarLoaded(applicationId)

    const shouldShow = useSelector((state: RootState) => {
        return state.applications.applications?.at?.[applicationId]?.metadata?.state === 'under_review'
    })
    const checksState = useSelector((state: RootState) => {
        return state.applicationInternals.checks.forApplication[applicationId]
    })

    const checkStatePath = useMemo(() => {
        if (!resource) return ''
        if (subsectionIndex !== undefined) return `${resource.cutterPath}.${subsectionIndex}`
        return resource.cutterPath
    }, [resource, subsectionIndex])

    const handleButtonClick = useCallback(() => {
        if (!resource) return
        dispatch(
            ApplicationInternalsChecksActions.TOGGLE_SECTION_CHECK(
                watcherID,
                applicationId,
                checkStatePath,
                resource.field
            )
        )
    }, [dispatch, checkStatePath, watcherID, applicationId, resource])

    const isChecked = useMemo(() => {
        if (checksState?.[checkStatePath]?.fields?.[resource.field]?.isChecked === true) return true
        return false
    }, [checkStatePath, checksState, resource])

    const dataCy = useMemo(() => {
        if (isChecked) return 'checked-section-check'
        return 'unchecked-section-check'
    }, [isChecked])

    if (!hasSidebarLoaded) return null

    if (!shouldShow) return null
    return (
        <Positioner>
            {watcher === 'started' ? (
                <Spinner type="s" overBackground="back.background" />
            ) : (
                <CheckMarkButton isChecked={isChecked} data-cy={dataCy} onClick={handleButtonClick}>
                    {isChecked && <Icon type="checkmark" size={7} weight={1} />}
                </CheckMarkButton>
            )}
        </Positioner>
    )
}

const Positioner = styled.div`
    position: absolute;
    top: 25px;
    left: -35px;
    width: 16px;
    height: 16px;
`

const CheckMarkButton = styled.div<{ isChecked?: boolean; minimal?: boolean }>`
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 1.5px solid ${(p) => p.theme['front.accent.color']};
    color: ${(p) => p.theme['front.accent.color']};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    &:hover {
        background-color: ${(p) => p.theme['loaders']};
    }

    &:active {
        opacity: 0.8;
    }

    ${(p) =>
        p.isChecked &&
        css`
            border-color: ${(p) => p.theme['front.success.color']};
            color: ${(p) => p.theme['front.success.color']};
        `}
`
