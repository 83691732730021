import { useDispatch, useSelector } from 'react-redux'

import { AuthDispatchInit } from '../../store/auth/actions'
import { AuthUser } from '@/store/auth/types'
import { RootState } from '@/store'
import { useEffect } from 'react'

export const useAuth = (): {
    isAuthenticated?: boolean
    isLoading: boolean
    user?: AuthUser
} => {
    const dispatch = useDispatch()
    const auth = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        dispatch(AuthDispatchInit())
    }, [dispatch])

    return {
        isLoading: auth.inProgress,
        isAuthenticated: auth.user !== undefined,
        user: auth.user
    }
}
