import { Collection } from '../../../utils'

export interface Voucher {
    code: string
    gatewayId: string
    gatewayName: string
    referralPartnerId: string
    referralPartnerName: string
    id: string
}

export type VouchersState = Collection<Voucher>

export const InitialVouchersState: VouchersState = {
    at: {},
    all: [],
    loadingStatus: 'not-started'
}
