import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { FileActionDispatchGenerateDownloadLink, FileActionDispatchRemoveDownloadLink } from '../../store/files/actions'
import { File } from '../../store/files/types'
import { ToastsDispatchPush } from '../../store/toasts/actions'
import { safeWindowOpen } from '../../utils'

export const useFileDownloader: (file?: File, onDownload?: () => void) => { downloadFile: () => void } = (
    file,
    onDownload
) => {
    const dispatch = useDispatch()
    const awaitDownload = useRef<boolean>(false)

    useEffect(() => {
        if (file) {
            if (
                file.generatedDownloadLink &&
                file.generatingDownloadLink === 'done' &&
                awaitDownload.current === true
            ) {
                awaitDownload.current = false
                safeWindowOpen(file.generatedDownloadLink)
                dispatch(FileActionDispatchRemoveDownloadLink(file))
                onDownload?.()
            }
        }
    }, [dispatch, file, onDownload])

    return {
        downloadFile: () => {
            if (!file) return dispatch(ToastsDispatchPush('File not found'))
            awaitDownload.current = true
            dispatch(FileActionDispatchGenerateDownloadLink(file))
        }
    }
}
