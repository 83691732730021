import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export const useWarningByMID = (merchantId: string) => {
    const warnings = useSelector(
        (state: RootState) => state.applicationInternals.warnings?.forApplication?.[merchantId]
    )
    const [hasWarnings, setHasWarnings] = useState(false)

    const byMID = useMemo(() => {
        const at = {}
        warnings?.each?.forEach((w) => {
            if ('relatedToMID' in w) {
                if (!w.relatedToMID) return
                if (!at[w.relatedToMID]) at[w.relatedToMID] = []
                at[w.relatedToMID].push(w)
            }
        })
        if (warnings?.isReady?.all && warnings?.each?.length) {
            setHasWarnings(true)
        }
        return at
    }, [warnings])

    return { byMID, hasWarnings }
}
