import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { MarkdownHolder } from '../../../components/general/markdownHolder'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { RootState } from '@/store'
import { MerchantWarning } from '../../../store/applicationInternals/warning/types'
import { WARNING_NO_TRANSACTIONS_AT_ALL } from '../../../store/applicationInternals/warning/sagas'

const SHOW_LIMIT = 2
export const MerchantSummaryWarnings: React.FC<{ merchantId: string }> = ({ merchantId }) => {
    const [showAll, setShowAll] = useState(false)
    const warnings = useSelector(
        (state: RootState) => state.applicationInternals.warnings?.forApplication?.[merchantId]
    )

    const icon = useMemo(() => {
        return (
            <Flex>
                <IconHolder>
                    <Icon type="warning" />
                </IconHolder>
                <Spacer width={8} />
            </Flex>
        )
    }, [])

    const warningsCount = useMemo(() => {
        const types: any = {}
        warnings?.each.forEach((w) => {
            if (!types[w.type]) types[w.type] = 0
            types[w.type]++
        })
        return types
    }, [warnings])

    const filteredWarning = useMemo(() => {
        const withoutMultipleWarnings =
            warnings?.each?.filter((w) => {
                return warningsCount[w.type] > 1 ? false : true
            }) || []

        const hasGeneralNoTransactionsYetWarning = warnings?.each?.find(
            (w) => w.title?.includes(WARNING_NO_TRANSACTIONS_AT_ALL)
        )
        Object.keys(warningsCount).forEach((t) => {
            const type = t as MerchantWarning['type']
            if (warningsCount[type] > 1)
                switch (type) {
                    case 'merchant-contract-no-signature':
                        withoutMultipleWarnings.push({
                            type: 'general',
                            id: 'mult-accs-miss-sign',
                            title: '**Multiple contracts** are still missing signatures.'
                        })
                        return
                    case 'general':
                        return
                    case 'merchant-account-no-transaction':
                        if (hasGeneralNoTransactionsYetWarning) return
                        withoutMultipleWarnings.push({
                            type: 'general',
                            id: 'mult-accs-miss-trans',
                            // eslint-disable-next-line max-len
                            title: '**Multiple accounts** have been created a long time ago, but have **no transactions.**'
                        })
                        return
                }
        })

        return withoutMultipleWarnings
    }, [warnings, warningsCount])

    if (!warnings || !warnings.each.length) return null

    if (!warnings.isReady.all) return null
    return (
        <>
            <Spacer height={20} />
            <Holder>
                <CardInset type="small">
                    <Flex>
                        {icon}
                        <Text bold>{warnings.each.length > 1 ? `Warnings` : `Warning`}</Text>
                    </Flex>
                    <Spacer height={6} />
                    {filteredWarning
                        // ?.filter((w, i, arr) => (i >= 2 && !showAll ? false : true))
                        .map((w, i, arr) => {
                            return (
                                <Item key={w.id}>
                                    <Flex column>
                                        <Spacer height={2} />
                                        <Flex justify="space-between">
                                            <MarkdownHolder>{w.title || ''}</MarkdownHolder>
                                            {'relatedToMID' in w ? (
                                                <Text color="front.subtleInfo.text">MID: {w.relatedToMID}</Text>
                                            ) : null}
                                        </Flex>
                                    </Flex>
                                </Item>
                            )
                        })}
                    {/* {hasMore ? (
                        <>
                            <Spacer height={10} />
                            <SimpleButton
                                background="front.subtleInfo.background"
                                color="front.subtleInfo.text.strongerI"
                                onClick={() => setShowAll((s) => !s)}
                            >
                                <ButtonInset>Show {showAll ? 'less' : 'all'}</ButtonInset>
                            </SimpleButton>
                            <Spacer height={5} />
                        </>
                    ) : null} */}
                </CardInset>
            </Holder>

            <Spacer height={10} />
        </>
    )
}

const IconHolder = styled.div`
    position: relative;
    top: 1px;
    color: ${(p) => p.theme['front.text']};
`

const Item = styled.div``

const Holder = styled.div`
    border-radius: 10px;
    background-color: ${(p) => p.theme['front.subtleInfo.background']};
    border: 1px solid ${(p) => p.theme['front.subtleInfo.border']};
`
