import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { PageHeader } from '../../components/layout/pageHeader'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { useListPage } from '../../hooks/pages/useListPage'
import { RootState } from '@/store'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { Card } from '../../components/cards/card'
import { ExportsActions } from '../../store/exports/actions'
import { uppercaseFirstLetter } from '../../utils'
import { DateFormats } from '../../utils/dateUtils'
import moment from 'moment'
import {
    ExportFileFormatsStrings,
    ExportFormatFileTypeToText,
    ExportsFilters,
    ExportsFiltersParams
} from '../../store/exports/types'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { ExportsNamer } from '../../components/general/exportsNamer'
import { TableBubble } from '../../components/layout/tableBubble'

const paginationKeys = {
    pageKey: 'page',
    perPageKey: 'per_page'
}

const loadSummaries = (filters: ExportsFilters) => {
    return ExportsActions.FETCH_EXPORTS(filters)
}

export const ManageExports: React.FC = () => {
    const location = useLocation()

    const summaries = useSelector((state: RootState) => {
        return state.exports.exports
    })

    const { paginationNode } = useListPage<ExportsFilters>(
        'ExportsList',
        'Exports',
        loadSummaries,
        ExportsFiltersParams,
        summaries,
        '/more/exports/',
        location,
        undefined,
        paginationKeys
    )

    const dispatch = useDispatch()
    const handleDownloadClick = useCallback(
        (ex) => {
            return (e: any, watcherId: any) => dispatch(ExportsActions.DOWNLOAD(watcherId, ex))
        },
        [dispatch]
    )

    return (
        <>
            <PageHeader noBottomBorder title="Exports" subtitle="" />
            <Flex align="stretch" justify="flex-start" grow column>
                <Card>
                    <CardInset>
                        {' '}
                        <Table
                            background="front.background"
                            cols={[
                                {
                                    text: 'For',
                                    loaderSize: {
                                        min: 35,
                                        max: 60
                                    }
                                },
                                {
                                    text: 'Created on',
                                    loaderSize: {
                                        min: 35,
                                        max: 60
                                    }
                                },
                                {
                                    text: 'Format',
                                    loaderSize: {
                                        min: 45,
                                        max: 45
                                    }
                                },
                                {
                                    text: 'Status',
                                    loaderSize: {
                                        min: 50,
                                        max: 120
                                    }
                                },
                                {
                                    text: 'Actions',
                                    alignRight: true,
                                    loaderSize: {
                                        min: 120,
                                        max: 240
                                    }
                                }
                            ]}
                            overrideText="No exports have been found"
                            // eslint-disable-next-line max-len
                            columnLayout="3fr 2fr 1fr 1fr 1fr"
                            showLastRowBorder
                            loaderRows={20}
                            displayLoader={summaries.loadingStatus === 'started'}
                            rows={summaries.all.map((id: string) => {
                                const t = summaries.at[id]
                                return {
                                    type: 'normal',
                                    noAction: true,
                                    key: t.id,
                                    items: [
                                        {
                                            node: <ExportsNamer e={t} />
                                        },
                                        {
                                            node: (
                                                <Text noWrap>
                                                    {moment(t.createdAt).format(
                                                        DateFormats.dayAndTimeWithSeconds(moment(t.createdAt))
                                                    )}
                                                </Text>
                                            )
                                        },
                                        {
                                            node: (
                                                <TableBubble>
                                                    {ExportFormatFileTypeToText(
                                                        Object.keys(ExportFileFormatsStrings)[
                                                            Object.values(ExportFileFormatsStrings).findIndex(
                                                                (v) => v === t.type
                                                            )
                                                        ] as any
                                                    )}
                                                </TableBubble>
                                            )
                                        },
                                        {
                                            node: <Text noWrap>{uppercaseFirstLetter(t.status)}</Text>
                                        },
                                        {
                                            node: (
                                                <Flex>
                                                    <WatcherButton onClick={handleDownloadClick(t)}>
                                                        <ButtonInset noVerticalPadding>Download</ButtonInset>
                                                    </WatcherButton>
                                                </Flex>
                                            )
                                        }
                                    ]
                                }
                            })}
                            emptyText="No exports found"
                        />
                    </CardInset>
                </Card>
                {summaries.loadingStatus === 'done' && paginationNode}
            </Flex>
        </>
    )
}
