import { isValidIBAN } from 'ibantools'

import { addMethod, string, setLocale, mixed, MixedSchema, StringSchema } from 'yup'
import { numberFromFormattedString } from '../utils'
import { parser } from '../suez/suez'

// https://github.com/jquense/yup/blob/master/src/locale.js#L8
setLocale({
    mixed: {
        default: 'Invalid field',
        required: 'This field is required',
        oneOf: 'Must be one of: ${values}',
        notType: ({ type, value }: { path: string; type: string; value: string; originalValue: string }) => {
            let msg = `Must be a ${type}`
            if (value === null) {
                msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``
            }
            return msg
        }
    },
    string: {
        email: 'Must be a valid email',
        url: 'Must be a valid web address'
    },
    number: {
        integer: 'Must be a number',
        max: 'Must be less than or equal to ${max}'
    }
})

addMethod(string, 'iban', function () {
    return this.test('iban', 'Invalid IBAN', function (value: any) {
        if (!value) return true
        if (value.includes(' ')) {
            return this.createError({
                // path: 'iban',
                message: 'Should contain no spaces'
            })
        }
        return isValidIBAN(value)
    })
})

addMethod(string, 'descriptor', function () {
    return this.test('descriptor', 'Contains invalid characters', function (value: any) {
        if (!value) return true
        if (value.length > 22)
            return this.createError({
                message: 'Should be less than 22 characters'
            })
        if (/^[\x20-\x7E]*$/.test(value)) return true
        return this.createError({
            message: 'Contains invalid characters'
        })
    })
})

addMethod(string, 'suez', function () {
    return this.test('suez', 'Invalid Suez', function (value) {
        try {
            //@ts-ignore
            parser.parse(value)
            return true
        } catch (error) {
            return this.createError({
                // path: 'suez',
                message: (error as any).message
            })
        }
    })
})

addMethod(string, 'swift', function () {
    return this.test('swift', 'Invalid SWIFT', function (value: any) {
        if (!value) return true
        return /^([A-Z]{4})([A-Z]{2})([2-9A-Z][0-9A-NP-Z])(X{3}|[0-9A-WY-Z][0-9A-Z][0-9A-Z])?$/.test(value)
    })
})

addMethod(string, 'numberMin', function (minimumNumberString: string, minimumNumber: number) {
    return this.test('numberMin', 'Should be minimum', function (value?: string) {
        if (value) {
            const numberFromString = numberFromFormattedString(value)
            if (numberFromString === undefined || numberFromString === null)
                return this.createError({
                    message: `Invalid number`
                })
            if (numberFromString < minimumNumber)
                return this.createError({
                    message: `Minimum value is ${minimumNumberString}`
                })
        }
        return true
    })
})

addMethod(string, 'numberWithCommas', function (needsDecimals?: boolean) {
    return this.test(
        'numberWithCommas',
        `Should follow the format ${needsDecimals ? '1,234.00' : '1,234'}`,
        function (value?: string) {
            if (!value) return true
            if (needsDecimals) return /^([0-9]{0,3})(?:,[0-9]{3})*(\.[0-9]{2,}){1}$/.test(value)
            else return /^([0-9]{0,3})(?:,[0-9]{3})*$/.test(value)
        }
    )
})

addMethod(string, 'numberMax', function (maximumNumberString: string, maximumNumber: number) {
    return this.test('numberMax', 'Should be maximum', function (value?: string) {
        if (value) {
            const numberFromString = numberFromFormattedString(value)
            if (numberFromString === undefined || numberFromString === null)
                return this.createError({
                    message: `Invalid number`
                })
            if (numberFromString > maximumNumber)
                return this.createError({
                    message: `Maximum value is ${maximumNumberString}`
                })
        }
        return true
    })
})
