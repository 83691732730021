import React, { useCallback } from 'react'

import { TextInputSelect } from '../../../components/forms/textInputSelect'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { ApplicationDeliveryDelay, ApplicationDeliveryDelayDictionary } from '../../../store/applications/types'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import * as yup from 'yup'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { Color } from '../../../styles/colors'

const items: ApplicationDeliveryDelay[] = ['less5days', '5to10days', '10to20days', 'more20days']

export const MerchantApplicationEditsModalInputDeliveryDelay: React.FC<{
    preSelected?: ApplicationDeliveryDelay
    onSelect?: (item: string) => void
    onBlur: (e: any) => void
    indexPath: MerchantApplicationResourceIndexPath
    formRef: MerchantApplicationFieldFormProps['formRef']
    validation: ValidationStatus
    overBackground: Color
}> = ({ preSelected, onBlur, onSelect, indexPath, overBackground, formRef, validation }) => {
    const [ref, setRef] = useRefTaker()

    // useEffect(() => {
    //     if (selectedDeliveryDelay) onSelect(selectedDeliveryDelay)
    // }, [onSelect, selectedDeliveryDelay])

    const textForItem = useCallback((item?: any) => {
        return (ApplicationDeliveryDelayDictionary as any)[item]
    }, [])

    return (
        <TextInputSelect
            placeholder="Select a delivery delay"
            ref={(ref) => {
                formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
            }}
            onBlur={onBlur}
            textareaBased
            validation={validation}
            selected={preSelected || ''}
            noClear
            isSeamless
            overBackground={overBackground}
            dropdownId="GenericDropdown"
            textForItem={textForItem}
            items={items}
        />
    )
}
