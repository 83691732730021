/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { put } from '@redux-saga/core/effects'
import { ActionType } from 'deox'
import { InterfaceActions } from '../interface/actions'
import { RootState } from '@/store'
import { SettlementSummary } from '../settlements/types'
import { SettlementsReservesActions } from './actions'
import { SettlementsReserve } from './types'
import { all, delay, select } from 'typed-redux-saga'
import { WatcherDispatchStart, WatcherDispatchSuccess } from '../watcher/actions'
import { ToastsDispatchPush } from '../toasts/actions'
import { getSettlementPayoutStatus, getSettlementReserveStatus } from '../../pages/Settlements/utils'
import moment from 'moment'
import { DateFormats } from '../../utils/dateUtils'
import { fetchOpenSettlementForDate } from '../settlements/sagas'
import { IS_CYPRESS, IS_DEVELOPMENT_OR_CYPRESS, ONLY_IF_REAL_PRODUCTION } from '../../utils'
import { consecutiveSumHeuristic, makeCombinations, pickAndReturnIndexes } from './utils'
import { deoxWaitFor } from '../applications/utils'
import { FetchAllPages } from '../utils'
import { uniqBy } from 'lodash'
import { PUT } from '../../generators/networking'
import { RelativizeDates } from './generateMocks'
import { SettlementsDispatchFetchSettlement } from '../settlements/actions'
import settlementsReservesMocks from './mockedSettlementsReserves.json'

// We're removing the reserve from the local storage mocks in order
// to simulate the settlement not containing the API stamp
const removeReserveFromLocalStorageMocks = (reserveId: string) => {
    let mocks: any = undefined
    const localMocksState = localStorage.getItem('mockedSettlementsReserves')
    if (localMocksState) mocks = JSON.parse(localMocksState)
    if (mocks && mocks.length) {
        const newMocks = mocks.map((m: any) => {
            const newM = { ...m }
            if (m.id === reserveId.replace('_payout', '').replace('_reserve', '')) {
                if (reserveId.includes('_payout')) {
                    newM.stampsLink = m.stampsLink.filter((s: any) => s.name !== 'cancelled_payout')
                } else if (reserveId.includes('_reserve')) {
                    newM.stampsLink = m.stampsLink.filter((s: any) => s.name !== 'cancelled_reserve')
                }
            }
            return newM
        })
        localStorage.setItem('mockedSettlementsReserves', JSON.stringify(newMocks))
    } else {
        localStorage.removeItem('mockedSettlementsReserves')
    }
}

function* cancelReserve(merchantId: string, reserveId: string) {
    const shouldStop = yield* select(
        (state: RootState) => state?.settlementsReserves?.forMerchantId?.[merchantId]?.shouldGracefullyStop
    )
    const shouldStopWatcher = yield* select((state: RootState) => state?.watchers?.[merchantId + '_GRACEFUL_STOP'])
    const stamp = yield* select(
        (state: RootState) =>
            state?.settlementsReserves?.forMerchantId[merchantId]?.reserves?.at?.[reserveId]?.stampsLink
    )

    if (!stamp) throw new Error(`Reserve's / Payout's stamp not found`)

    if (shouldStop) {
        if (shouldStopWatcher?.state === 'started') {
            yield put(WatcherDispatchSuccess([merchantId + '_GRACEFUL_STOP']))
            yield put(SettlementsReservesActions.ABORT_PROCESS(merchantId))
            return false
        }
    }

    yield put(SettlementsReservesActions.SET_RESERVE_STATUS(merchantId, reserveId, 'in-progress'))

    if (ONLY_IF_REAL_PRODUCTION()) {
        // If production, cancel the reserve
        const { success } = yield PUT({
            url: stamp,
            successCode: 200,
            errorText: 'Failed to cancel the reserve'
        })

        if (success) {
            yield put(SettlementsReservesActions.SET_RESERVE_STATUS(merchantId, reserveId, 'done'))
            return true
        } else {
            yield put(SettlementsReservesActions.SET_RESERVE_STATUS(merchantId, reserveId, 'failed'))
            return false
        }
    } else {
        if (IS_CYPRESS()) {
            // Return error only for this merchant
            if (merchantId === '2001015' && reserveId == '72a935c5-3af1-5078-910b-9964118d962e_payout') {
                yield put(SettlementsReservesActions.SET_RESERVE_STATUS(merchantId, reserveId, 'failed'))
                return false
                // Works fine for others
            } else {
                yield delay(900)
                removeReserveFromLocalStorageMocks(reserveId)
                yield put(SettlementsReservesActions.SET_RESERVE_STATUS(merchantId, reserveId, 'done'))
                return true
            }
        } else {
            // If development, fake throw error in 5% of the cases
            try {
                if (Math.random() > 0.95) throw 'Fake error'
                removeReserveFromLocalStorageMocks(reserveId)
                yield delay(750)
                yield put(SettlementsReservesActions.SET_RESERVE_STATUS(merchantId, reserveId, 'done'))
                return true
            } catch (e) {
                yield put(SettlementsReservesActions.SET_RESERVE_STATUS(merchantId, reserveId, 'failed'))
                return false
            }
        }
    }
}

export const SettlementsReservesSagas = {
    *ON_FLOW_INIT({ payload: p }: ActionType<typeof InterfaceActions.START_CRITICAL_FLOW>) {
        if (p.flow.type !== 'settlements-reserve-cancellations') return

        const mid = Object.keys(p.flow.context.forMerchantId)[0]
        if (!mid) return

        yield put(SettlementsReservesActions.INFUSE_STATE(mid, p.flow.context))
    },
    *ON_STATE_INFUSION({ payload: p }: ActionType<typeof SettlementsReservesActions.INFUSE_STATE>) {
        const mid = Object.keys(p.state.forMerchantId)[0]
        if (p.state.forMerchantId[mid].phase === 'processing') {
            yield put(SettlementsReservesActions.INTERRUPT_PROCESS(mid))
        }
        if (p.state.forMerchantId[mid].shouldGracefullyStop) {
            yield put(SettlementsReservesActions.ABORT_PROCESS(mid))
        }
    },
    *RETRY_FROM_FAILED({ payload: p }: ActionType<typeof SettlementsReservesActions.RETRY_FROM_FAILED>) {
        const execution = yield* select(
            (state: RootState) => state.settlementsReserves.forMerchantId[p.merchantId].executing
        )
        for (const rId in execution) {
            const status = execution[rId]
            if (status === 'failed') {
                const worked: boolean = yield cancelReserve(p.merchantId, rId)
                if (worked) yield put(SettlementsReservesActions.START_CANCELLING_RESERVES(p.merchantId))
                else console.error('Reserve cancellation failed')
            }
        }
    },
    *START_FLOW({ payload: p }: ActionType<typeof SettlementsReservesActions.START_FLOW>) {
        const flowAlreadyExists: boolean = yield select((state: RootState) => !!state.interface.criticalFlow)

        if (flowAlreadyExists) {
            yield put(
                ToastsDispatchPush(
                    'A critical flow is already in progress. Remove skip-critical-flows from the URL to see it.',
                    'error'
                )
            )
            return
        }

        yield put(
            InterfaceActions.START_CRITICAL_FLOW({
                type: 'settlements-reserve-cancellations',
                context: {
                    forMerchantId: {
                        [p.merchantId]: {
                            phase: 'selection'
                        }
                    }
                }
            })
        )

        if (p.preselected?.settlementId && p.preselected?.type)
            yield put(
                SettlementsReservesActions.SELECT_SETTLEMENTS(p.merchantId, [
                    `${p.preselected.settlementId}_${p.preselected.type}`
                ])
            )
    },
    *PERSIST() {
        yield delay(300)
        const state = yield* select((state: RootState) => state.settlementsReserves)
        // Refresh settlements if we're at the selection phase
        const merchantId = Object.keys(state.forMerchantId)[0]
        const newState = JSON.parse(JSON.stringify(state))
        if (!merchantId) return

        // Refetch reserves if we're at the selection screen
        delete newState.forMerchantId[merchantId].reserves

        yield put(
            InterfaceActions.REPLACE_CRITICAL_FLOW({ type: 'settlements-reserve-cancellations', context: newState })
        )
    },
    *PREPARE_FOR_GRACEFUL_STOP({
        payload: p
    }: ActionType<typeof SettlementsReservesActions.PREPARE_FOR_GRACEFUL_STOP>) {
        yield put(WatcherDispatchStart([p.watcherId]))
    },
    *FETCH_SETTLEMENTS_WITH_RESERVES({
        payload: p
    }: ActionType<typeof SettlementsReservesActions.FETCH_SETTLEMENTS_WITH_RESERVES>) {
        let responses: [SettlementSummary[], SettlementSummary[], string | undefined] | undefined = undefined
        if (IS_DEVELOPMENT_OR_CYPRESS()) {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            let mocks = settlementsReservesMocks
            yield RelativizeDates(mocks)
            yield delay(2000)
            const localMocksState = localStorage.getItem('mockedSettlementsReserves')
            if (localMocksState) mocks = JSON.parse(localMocksState)
            else {
                localStorage.setItem('mockedSettlementsReserves', JSON.stringify(mocks))
            }
            const openSettlementId = yield* fetchOpenSettlementForDate(
                p.merchantId,
                moment().format(DateFormats.dayStamp)
            )

            responses = [mocks as any, [], openSettlementId]
        } else {
            responses = yield all([
                FetchAllPages<SettlementSummary>(
                    `${import.meta.env.VITE_API_ROOT}/settlements?query=${encodeURIComponent(
                        `mid:${p.merchantId} reserve.date:${moment().format('YYYY-MM-DD')}..*`
                    )}`,
                    (acc, res) => {
                        return [...acc, ...res.settlements]
                    },
                    ['self', 'settlements', 'stamps']
                ),
                FetchAllPages<SettlementSummary>(
                    `${import.meta.env.VITE_API_ROOT}/settlements?query=${encodeURIComponent(
                        `mid:${p.merchantId} payout.date:${moment().format('YYYY-MM-DD')}..*`
                    )}`,
                    (acc, res) => {
                        return [...acc, ...res.settlements]
                    },
                    ['self', 'settlements', 'stamps']
                ),
                fetchOpenSettlementForDate(p.merchantId, moment().format(DateFormats.dayStamp))
            ])
        }
        if (responses === undefined)
            throw new Error('Something went wrong with fetching the upcoming reserves and payouts')
        const [futureReserves, futurePayouts, openSettlementId] = responses

        const settlements = uniqBy([...futureReserves, ...futurePayouts], 'id')

        if (settlements) {
            const reserves: SettlementsReserve[] = []
            for (const s of settlements) {
                if (
                    getSettlementPayoutStatus(s) === 'Upcoming' &&
                    s.stampsLink?.filter((s) => s.name === 'cancelled_payout')?.length
                ) {
                    reserves.push({
                        id: s.id,
                        type: 'payout',
                        descriptor: s.payout.descriptor,
                        settlementAmount: s.payout.amount,
                        settlementStart: s.period.startDate,
                        settlementEnd: s.period.endDate,
                        currency: s.currency,
                        amount: s.payout.amount,
                        paidOn: s.payout.date,
                        hasBeenCancelled: !!s.payout.cancelledDate,
                        stampsLink: s.stampsLink?.find((s) => s?.name === 'cancelled_payout')?.href
                    })
                }
                if (
                    getSettlementReserveStatus(s) === 'Upcoming' &&
                    s.stampsLink?.filter((s) => s.name === 'cancelled_reserve')?.length
                )
                    reserves.push({
                        id: s.id,
                        type: 'reserve',
                        descriptor: s.reserve.descriptor,
                        currency: s.currency,
                        settlementAmount: s.payout.amount,
                        settlementStart: s.period.startDate,
                        settlementEnd: s.period.endDate,
                        amount: s.reserve.amount,
                        paidOn: s.reserve.date,
                        hasBeenCancelled: !!s.reserve.cancelledDate,
                        stampsLink: s.stampsLink?.find((s) => s?.name === 'cancelled_reserve')?.href
                    })
            }

            if (!openSettlementId) {
                yield put(ToastsDispatchPush("Couldn't find an Open settlement for this MID", 'error'))
            } else {
                yield put(SettlementsDispatchFetchSettlement(openSettlementId, undefined, true))
            }

            yield put(
                SettlementsReservesActions.STORE_SETTLEMENTS_WITH_RESERVES(p.merchantId, reserves, openSettlementId)
            )
        }
    },
    *CANCEL_FLOW({}: ActionType<typeof SettlementsReservesActions.CANCEL_FLOW>) {
        yield put(InterfaceActions.COMPLETE_CRITICAL_FLOW())
    },
    *START_CANCELLING_RESERVES({ payload }: ActionType<typeof SettlementsReservesActions.START_CANCELLING_RESERVES>) {
        const allReserves = yield* select(
            (state: RootState) => state.settlementsReserves?.forMerchantId?.[payload.merchantId]?.cachedReserves?.all
        )
        const selectedReserves = yield* select(
            (state: RootState) => state.settlementsReserves?.forMerchantId?.[payload.merchantId]?.selection
        )
        const reservesLoadingStatus = yield* select(
            (state: RootState) =>
                state.settlementsReserves?.forMerchantId?.[payload.merchantId]?.reserves?.loadingStatus
        )

        if (reservesLoadingStatus !== 'done') {
            yield deoxWaitFor(
                SettlementsReservesActions.STORE_SETTLEMENTS_WITH_RESERVES,
                (p) => p.merchantId === payload.merchantId
            )
        }

        // Eliminate selected reserves, but that are not displayed in the list
        const allSelectedReserves = Object.keys(selectedReserves).filter((sId) => allReserves.includes(sId))
        const execution = yield* select(
            (state: RootState) => state.settlementsReserves?.forMerchantId?.[payload.merchantId]?.executing
        )

        for (let i = 0; i < allSelectedReserves.length; i++) {
            const reserveId = allSelectedReserves[i]
            if (execution[reserveId]) continue
            yield put(SettlementsReservesActions.SET_RESERVE_STATUS(payload.merchantId, reserveId, 'pending'))
        }

        for (let i = 0; i < allReserves.length; i++) {
            const reserveId = allReserves[i]
            if (!selectedReserves[reserveId]) continue
            if (!execution[reserveId] || execution[reserveId] === 'pending') {
                const cancelledCorrectly: boolean = yield cancelReserve(payload.merchantId, reserveId)
                if (!cancelledCorrectly) {
                    console.error('Reserve cancellation failed')
                    return
                }
            }
        }

        yield put(SettlementsReservesActions.CANCELLING_RESERVES_SUCCESS(payload.merchantId))
    },
    *ON_RESERVE_STATUS_CHANGE({ payload }: ActionType<typeof SettlementsReservesActions.SET_RESERVE_STATUS>) {
        if (payload.status === 'done')
            yield put(ToastsDispatchPush('Reserve cancelled successfully', 'success', undefined, 'shorter'))
        else if (payload.status === 'failed') {
            yield put(ToastsDispatchPush('Failed to cancel the reserve', 'error', undefined, 'shorter'))
        }
    },

    // Sory by payout
    // Do N / 21 calls
    // Select first 21 * i Reserves
    // Sort by amount
    // Batch into boundaries
    // Try to combine
    // Give it a confidence level based on the amount (e.g 900 out of 1,000 = 90%)

    // Sway the confidence interval by:
    // 20        - 20%
    // 40        - 15%
    // 60        - 10%
    // 80        - 5%
    // 80 -> 180 - 0%

    // Do the heuristics, give a confidence interval, but no bonus.

    *AUTOSELECT({ payload }: ActionType<typeof SettlementsReservesActions.AUTOSELECT>) {
        yield put(WatcherDispatchStart([payload.watcherId]))
        yield delay(15)
        const reservesAndPayouts = yield* select(
            (state: RootState) => state.settlementsReserves.forMerchantId[payload.merchantId].reserves
        )
        const getDaySpanIndex = (paidOn?: number, target?: number) => {
            if (!paidOn) return 0

            if (!target) return 0

            return moment(paidOn).diff(moment(), 'days')
        }

        // Select only the reserves
        const allReserves = reservesAndPayouts.all.filter((id) => reservesAndPayouts.at[id].type === 'reserve')
        const reserves = {
            at: allReserves.reduce((acc, id) => {
                acc[id] = reservesAndPayouts.at[id]
                return acc
            }, {} as any),
            all: allReserves
        }

        // Filter reserves that are bigger than the amount
        const sortedReserves = [...reserves.all]

        // Sort the reserves by the earliest that will be paid
        sortedReserves.sort((a, b) => {
            if (!reserves.at?.[a]?.paidOn) return -1
            if (!reserves.at?.[b]?.paidOn) return 1
            return moment(reserves.at[a].paidOn).isBefore(reserves.at[b].paidOn) ? -1 : 1
        })
        const MAX_PICKS = sortedReserves.length < 17 ? sortedReserves.length : 17

        const solutions: {
            indexes: string[]
            amount: number
            timeSpan: number
        }[] = []

        // MAIN ALGORITHM
        for (let i = 1; i <= Math.ceil(sortedReserves.length / MAX_PICKS); i++) {
            const sortedByPrice = sortedReserves.filter((item, j) => (j >= i * MAX_PICKS ? false : true))

            const hardPickLimit = i * MAX_PICKS > sortedByPrice.length ? sortedByPrice.length : i * MAX_PICKS
            const pickedIndexes: number[][] =
                i === 1
                    ? yield all([pickAndReturnIndexes(hardPickLimit, MAX_PICKS, 'first')])
                    : yield all([
                          pickAndReturnIndexes(hardPickLimit, MAX_PICKS, 'even'),
                          pickAndReturnIndexes(hardPickLimit, MAX_PICKS, 'last')
                      ])

            for (let k = 0; k < pickedIndexes.length; k++) {
                const indexes = pickedIndexes[k]

                const pickedItems = indexes.map((j) => {
                    return {
                        index: j,
                        value: reserves.at[sortedByPrice[j]].amount,
                        timeUntil: getDaySpanIndex(
                            reserves.at[sortedByPrice[j]].paidOn,
                            reserves.at[sortedByPrice[j]].amount
                        )
                    }
                })

                const possibleSolutions: any[] = yield makeCombinations(pickedItems, payload.upTo)
                const addReservesStoredID = (j: any) => `${reserves.at[sortedByPrice[j]].id}_reserve`

                for (let i = 0; i < possibleSolutions.length; i++) {
                    const solutionSum = possibleSolutions[i].reduce((acc: any, j: number) => {
                        acc += reserves.at[sortedByPrice[j]].amount
                        return acc
                    }, 0)
                    const solutionTimeSpan = possibleSolutions[i].reduce((acc: any, j: number) => {
                        acc += getDaySpanIndex(
                            reserves.at[sortedByPrice[j]].paidOn,
                            reserves.at[sortedByPrice[j]].amount
                        )
                        return acc
                    }, 0)

                    solutions.push({
                        indexes: possibleSolutions[i].map(addReservesStoredID),
                        amount: solutionSum,
                        timeSpan: solutionTimeSpan / possibleSolutions[i].length
                    })
                }
            }
        }

        // Do a consecutive sum heuristic, for really high numbers, and consider it a solution
        const heuristicSolution = consecutiveSumHeuristic(
            sortedReserves.map((k) => reserves.at[k].amount),
            sortedReserves.map((k) => getDaySpanIndex(reserves.at[k].paidOn, reserves.at[k].amount)),
            payload.upTo
        )

        solutions.push({
            indexes: heuristicSolution.map((a, i) => `${reserves.at[sortedReserves[a]].id}_reserve`),
            amount: heuristicSolution.reduce((acc, i) => {
                acc += reserves.at[sortedReserves[i]].amount
                return acc
            }, 0),
            timeSpan:
                heuristicSolution.reduce((acc, i) => {
                    acc += getDaySpanIndex(reserves.at[sortedReserves[i]].paidOn, reserves.at[sortedReserves[i]].amount)
                    return acc
                }, 0) / heuristicSolution.length
        })

        let optimalSolution: any = undefined
        let minSpan = Number.MAX_SAFE_INTEGER
        solutions.forEach((s) => {
            if (s.timeSpan < minSpan) {
                minSpan = s.timeSpan
                optimalSolution = s
            }
        })

        if (optimalSolution !== undefined) {
            yield put(
                SettlementsReservesActions.SAVE_AUTOSELECT_SOLUTION(payload.merchantId, {
                    for: payload.upTo,
                    solution: optimalSolution.indexes
                })
            )
        }
        yield put(WatcherDispatchSuccess([payload.watcherId]))
    }
}
