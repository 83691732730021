import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Text } from '../../../../../../../components/general/text'
import { Icon } from '../../../../../../../components/icons/icon'
import { Flex } from '../../../../../../../components/layout/flex'
import { Floater } from '../../../../../../../components/layout/floater'
import { FloaterInset } from '../../../../../../../components/layout/floaterInset'
import { Spacer } from '../../../../../../../components/layout/spacer'
import { useOnHoverOutside } from '../../../../../../../hooks/general/useOnHoverOutside'
import { useRefTaker } from '../../../../../../../hooks/general/useRefTaker'
import { uppercaseFirstLetter } from '../../../../../../../utils'

export const MissingInfoDropdown: React.FC<{
    children: ReactNode
    requiredInfo: string[]
    missingInfo: string[]
}> = ({ children, missingInfo, requiredInfo }) => {
    const [anchor, setAnchor] = useRefTaker()
    const [shouldShowFloater, setShouldShowFloater] = useState(false)
    useOnHoverOutside(anchor, () => {
        setShouldShowFloater(false)
    })

    return (
        <>
            <Hook
                ref={setAnchor}
                onMouseEnter={() => {
                    setShouldShowFloater(true)
                }}
                onMouseLeave={() => {
                    setShouldShowFloater(false)
                }}
            >
                {children}
            </Hook>
            <Floater
                cardId="DisputePage.DueDateSelector"
                shouldShow={shouldShowFloater}
                anchor={anchor}
                onHide={() => {
                    setShouldShowFloater(false)
                }}
            >
                <FloaterInset equalPadding>
                    <Flex column>
                        {requiredInfo.map((f) => {
                            const isValid = missingInfo.includes(f) ? false : true
                            return (
                                <React.Fragment key={f}>
                                    <Flex>
                                        <CheckmarkHolder isValid={isValid}>
                                            {isValid ? <Icon type="checkmark" size={7} /> : null}
                                        </CheckmarkHolder>
                                        <Text color="floating.text">{uppercaseFirstLetter(f)}</Text>
                                    </Flex>
                                    <Spacer height={3} />
                                </React.Fragment>
                            )
                        })}
                        <Note>
                            <Text color="floating.text.subtlerI" size="s">
                                Some internal documents, not being checked here, might be worth checking.
                                <br />
                                Please consider asking the console team to add those checks to the list above, or to
                                remove this message completely.
                            </Text>
                        </Note>
                    </Flex>
                </FloaterInset>
            </Floater>
        </>
    )
}

const Note = styled.div`
    width: 100%;
    max-width: 200px;
    border-top: 1px solid ${(p) => p.theme['floating.border.subtlerI']};
    padding-top: 10px;
    margin-top: 10px;
`

const CheckmarkHolder = styled.div<{ isValid?: boolean }>`
    width: 13px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    position: relative;
    top: 1.5px;
    border: 1px solid
        ${(p) => (p.isValid ? p.theme['front.subtleSuccess.background.strongerII'] : p.theme['floating.border'])};
    background-color: ${(p) =>
        p.isValid ? p.theme['front.subtleSuccess.background.strongerI'] : p.theme['floating.background']};
    color: ${(p) => p.theme['front.subtleSuccess.text']};
    border-radius: 999px;
`

const Hook = styled.span`
    cursor: help;
`
