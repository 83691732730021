import { useEffect, useRef, forwardRef, useMemo } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import { Text } from '../components/general/text'
import { IconArrowRight } from '../components/icons/iconArrowRight'
import { Flex } from '../components/layout/flex'
import { numberWithCurrency } from '../utils'
import { DateFormats } from '../utils/dateUtils'
import { CriticalFlowSettlementsCheck } from './CriticalFlowSettlementsCheck'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../store/toasts/actions'
import { SettlementStatusBox } from '../components/complexComponents/settlementStatus'
import { LinkButtonArrow } from '../components/buttons/linkButtonArrow'
import { Spacer } from '../components/layout/spacer'
import { ButtonInset } from '../components/buttons/buttonInset'
import { LinkButton } from '../components/buttons/linkButton'

const scrollTimeout: any = undefined
export const CriticalFlowSettlementsTableRowWRef: React.ForwardRefRenderFunction<
    any,
    {
        id: string
        mid: string
        r: any
    }
> = ({ id, mid, r }, ref) => {
    const rowRef = useRef<HTMLDivElement>(null)
    const executionStarted = useSelector(
        (state: RootState) => state.settlementsReserves.forMerchantId[mid]?.phase !== 'selection'
    )
    const dispatch = useDispatch()
    const selection = useSelector((state: RootState) => state.settlementsReserves.forMerchantId[mid]?.selection)
    const allExecutions = useSelector((state: RootState) => state.settlementsReserves.forMerchantId[mid]?.executing)
    const executing = useSelector((state: RootState) => state.settlementsReserves.forMerchantId[mid]?.executing?.[id])
    const isSelected = useSelector(
        (state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.selection?.[id]
    )
    const cachedReserves = useSelector(
        (state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.cachedReserves?.all
    )
    const reserves = useSelector((state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.reserves?.all)

    const isNext = useMemo(() => {
        if (executionStarted) {
            for (let i = 0; i < cachedReserves?.length; i++) {
                if (allExecutions[cachedReserves[i]] === 'pending') {
                    if (cachedReserves[i] === id) return true
                    else return false
                }
            }
        } else {
            for (let i = 0; i < reserves?.length; i++) {
                if (selection[reserves[i]]) {
                    if (reserves[i] === id) return true
                    else return false
                }
            }
        }
        return false
    }, [cachedReserves, id, executionStarted, allExecutions, selection, reserves])

    const status = useMemo(() => {
        let status: any = 'normal'

        if (isSelected) {
            status = 'selected'
        }

        if (executionStarted) {
            if (executing) status = executing
        }

        return status
    }, [isSelected, executing, executionStarted])

    useEffect(() => {
        if (isNext)
            rowRef.current?.scrollIntoView({
                inline: 'center',
                block: 'center'
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!executionStarted) return
        if (isNext) {
            rowRef.current?.scrollIntoView({
                inline: 'center',
                block: 'center',
                behavior: 'smooth'
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNext, executionStarted])

    const payoutType = useMemo(() => {
        if (!r?.type) {
            dispatch(ToastsDispatchPush('Unknown payout type detected', 'error'))
            return 'Unknown Type'
        }
        if (r?.type === 'payout') return <SettlementStatusBox state="payout-pending">Payout</SettlementStatusBox>
        return <SettlementStatusBox state="reserves-held">Reserve</SettlementStatusBox>
    }, [r, dispatch])

    return (
        <Row status={status} data-cy="table-row">
            <Cell ref={rowRef}>
                <CriticalFlowSettlementsCheck
                    executing={executing}
                    isNext={isNext}
                    executionStarted={executionStarted}
                    mid={mid}
                    key={id}
                    selected={status === 'selected'}
                    ref={ref}
                    id={id}
                />
            </Cell>
            <Cell left>
                <Text noWrap>
                    {moment(r?.settlementStart).format(DateFormats.day(moment(r?.settlementStart), true))}
                </Text>
            </Cell>
            <Cell>
                <Flex
                    style={{
                        marginRight: 0,
                        marginTop: -2
                    }}
                    align="center"
                    grow
                >
                    <IconArrowRight size={12} />
                </Flex>
            </Cell>
            <Cell left>
                <Text noWrap>{moment(r?.settlementEnd).format(DateFormats.day(moment(r?.settlementEnd), true))}</Text>
            </Cell>
            <Cell right>
                <Text color="front.text.subtlerI">
                    {numberWithCurrency(r?.currency, `${r?.settlementAmount}`)} {r?.currency}
                </Text>
            </Cell>
            <Cell left>
                <Text noWrap>{payoutType}</Text>
            </Cell>
            <Cell right>
                <Text oneLine>
                    {moment(r?.paidOn).format(DateFormats.day(moment(r?.paidOn)))}{' '}
                    <Text color="front.text.subtlerI">({moment(r?.paidOn).fromNow()})</Text>
                </Text>
            </Cell>
            <Cell right>
                <Flex>
                    <Text bold>
                        {numberWithCurrency(r?.currency, r?.amount)} {r?.currency}
                    </Text>
                </Flex>
            </Cell>
            <Cell right>
                <LinkButton target="blank" to={`/settlements/${r.id}?skip-critical-flows=true`}>
                    <ButtonInset noHorizontalPadding>
                        Settlement
                        <Spacer width={5} />
                        <LinkButtonArrow shouldBeBordered background="front.subtleAccent.background" />
                    </ButtonInset>
                </LinkButton>
            </Cell>
        </Row>
    )
}
export const CriticalFlowSettlementsTableRow = forwardRef(CriticalFlowSettlementsTableRowWRef)

const Cell = styled.div<{ right?: boolean; left?: boolean }>`
    display: flex;
    align-items: center;

    justify-content: center;
    padding: 0 10px;
    border-bottom: 1px dotted ${(p) => p.theme['back.border']};

    &:first-child {
        border-radius: 10px 0 0 10px;
        padding-left: 20px;
    }

    &:last-child {
        border-radius: 0 10px 10px 0;
        padding-right: 20px;
    }

    ${(p) =>
        p.right &&
        css`
            justify-content: flex-end;
            text-align: right;
        `}
    ${(p) =>
        p.left &&
        css`
            justify-content: flex-start;
            text-align: left;
        `}
`

const Row = styled.div<{ status: any }>`
    display: contents;

    &:hover ${Cell} {
        background: ${(p) => p.theme['back.background.strongerI']};
    }

    ${(p) =>
        p.status === 'pending' &&
        css`
            ${Cell} {
                background: ${(p) => p.theme['front.subtleAccent.background']};
            }
            &:hover ${Cell} {
                background: ${(p) => p.theme['front.subtleAccent.background']};
            }
        `}
    ${(p) =>
        p.status === 'failed' &&
        css`
            ${Cell} {
                background: ${(p) => p.theme['front.subtleDanger.background.strongerI']};
            }
            &:hover ${Cell} {
                background: ${(p) => p.theme['front.subtleDanger.background.strongerI']};
            }
        `}
    ${(p) =>
        p.status === 'in-progress' &&
        css`
            ${Cell} {
                background: ${(p) => p.theme['front.subtleAccent.background.strongerI']};
            }
            &:hover ${Cell} {
                background: ${(p) => p.theme['front.subtleAccent.background.strongerI']};
            }
        `}
    ${(p) =>
        p.status === 'done' &&
        css`
            ${Cell} {
                background: ${(p) => p.theme['front.subtleSuccess.background']};
            }
            &:hover ${Cell} {
                background: ${(p) => p.theme['front.subtleSuccess.background']};
            }
        `}
`
