import React from 'react'
import { ClassificationsTable } from '@/features/risk-classification'
import { useParams } from 'react-router-dom'

type Params = {
    id: string
}

export const RiskCard: React.FC = () => {
    const { id: applicationId } = useParams() as Params

    return <ClassificationsTable applicationId={applicationId} />
}
