import { uniq } from 'lodash'
import { Collection, LoadingStatus, uppercaseFirstLetter } from '../../../utils'

export interface ApplicationInternalRecordState {
    [key: string]: {
        notifications: Collection<MerchantNotification>
        subscriptionsLink?: string
        loadingStatus: LoadingStatus
    }
}

export const InitialApplicationInternalRecordState: ApplicationInternalRecordState = {}

export type MerchantNotificationType = 'weekly.disputes' | 'daily.disputes' | 'summary.settlements'
export type MerchantWebhookEvents =
    | 'application.collaborator.created'
    | 'application.submitted'
    | 'application.needs_information'
    | 'application.declined'
    | 'account.contract.signed'
    | 'account.contract.declined'
    | 'account.contract.needs_signature'
    | 'account.contract.needs_signature_reminder'
    | 'account.live'
    | 'account.closed'
    | 'account.member.created'
    | 'account.api-key.created'
    | 'account.api-key.enabled'
    | 'account.api-key.disabled'

export const MerchantWebhookTypeToTitle: {
    [key in MerchantWebhookEvents]: string
} = {
    'application.collaborator.created': 'Application',
    'application.submitted': 'Application',
    'application.needs_information': 'Application',
    'application.declined': 'Application',
    'account.contract.signed': 'Contract',
    'account.contract.declined': 'Contract',
    'account.contract.needs_signature': 'Contract',
    'account.contract.needs_signature_reminder': 'Contract',
    'account.live': 'Account',
    'account.closed': 'Account',
    'account.member.created': 'Account',
    'account.api-key.created': 'Account',
    'account.api-key.enabled': 'Account',
    'account.api-key.disabled': 'Account'
}
export const WebhooksConfig = (function () {
    const sections = uniq(Object.values(MerchantWebhookTypeToTitle))
    const events = Object.keys(MerchantWebhookTypeToTitle)
    let otherEvents = JSON.parse(JSON.stringify(events))

    const eventsBySection = sections.reduce((acc, k) => {
        if (!acc[k]) {
            acc[k] = events.filter((e) => MerchantWebhookTypeToTitle[e] == k)
            otherEvents = otherEvents.filter((e) => MerchantWebhookTypeToTitle[e] !== k)
        }
        return acc
    }, {} as any)

    if (otherEvents.length) {
        eventsBySection['Others'] = otherEvents
    }

    return {
        sections,
        eventsBySection
    }
})()

export const TextForWebhookEvent = (item) => {
    return uppercaseFirstLetter(
        item.replace('account.contract.', '').replace('account.', '').replace('application.', '')
    ).replace('Api key', 'API Key')
}

export const MerchantNotificationTypes: MerchantNotificationType[] = [
    'weekly.disputes',
    'daily.disputes',
    'summary.settlements'
]

export interface MerchantNotification {
    enabled: boolean
    endpoint: string
    events: MerchantNotificationType[]
    id: string
    protocol: 'email' | 'https'
    selfLink: string
}
