import { useEffect } from 'react'

export const useOnClickOutside = (ref: any, cb: (e: any) => void, remount?: boolean) => {
    useEffect(() => {
        const clickOutside = (event: any) => {
            if (!ref?.contains(event.target)) {
                cb(event)
            }
        }

        window.addEventListener('mouseup', clickOutside)
        return () => {
            window.removeEventListener('mouseup', clickOutside)
        }
    }, [cb, ref, remount])
}
