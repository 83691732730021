import React, { useCallback } from 'react'

import { Flex } from '../layout/flex'
import { Text } from '../general/text'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

export const Radio: React.FC<{
    initialValue?: string
    options: string[]
    label: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void
    readOnly?: boolean
    marginBottom?: number
}> = ({ initialValue, label, onChange, options, readOnly, marginBottom = 15 }) => {
    const [value, setValue] = React.useState(initialValue || '')

    const handleChanged = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
            if (readOnly) return

            setValue(newValue)

            onChange?.(e, newValue)
        },
        [onChange, readOnly]
    )

    return (
        <Flex justify="space-between" style={{ marginBottom }}>
            <Text color="front.text.subtlerI">{label}</Text>

            <OptionsWrapper>
                {options.map((option) => {
                    const id = uuid()

                    return (
                        <RadioWrapper key={option}>
                            <input
                                readOnly={readOnly}
                                id={id}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e, option)}
                                checked={value === option}
                                type="radio"
                            />
                            <Label htmlFor={id}>{option}</Label>
                        </RadioWrapper>
                    )
                })}
            </OptionsWrapper>
        </Flex>
    )
}

const RadioWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 30px;
`

const Label = styled.label`
    margin-left: 3px;
    white-space: nowrap;
`

const OptionsWrapper = styled.div`
    display: flex;
    align-items: center;
`
