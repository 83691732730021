import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ExternalLink } from '../../../components/buttons/externalLink'
import { Text } from '../../../components/general/text'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { Person, Website } from '../../../store/applications/types'
import { RootState } from '@/store'
import { getCompanyRegister } from '../../../utils/companyRegister'
import countries from 'i18n-iso-countries'

const encodeParams = (query: string) => {
    return encodeURIComponent(query).toLowerCase()
}

export const MerchantApplicationInternalLink: React.FC<{
    applicationId: string
    indexPath: MerchantApplicationResourceIndexPath
    label: string
    isLoading?: boolean
}> = ({ indexPath, isLoading, applicationId, label }) => {
    const resource = useSelector((state: RootState) => {
        return state.applicationResources.data.forApplication[applicationId]?.[indexPath.resourceKey]?.fields?.[
            indexPath.subsectionIndex || 0
        ]
    })

    const renderLink = useCallback(
        (label: string, link: string, spanOtherColumns?: boolean) => {
            if (!label) return null
            const memo = isLoading ? (
                <TextLineLoader height={19} topOffset={4} />
            ) : (
                <ExternalLink url={link} target="blank" underlineLess>
                    <Text>{label}</Text>
                </ExternalLink>
            )

            if (spanOtherColumns)
                return (
                    <>
                        {memo}
                        <div />
                        <span />
                    </>
                )
            return memo
        },
        [isLoading]
    )

    if (indexPath.resourceKey === 'company' && resource) {
        const company = resource
        const companyRegisterLink = getCompanyRegister(company.country, company.registrationNumber)
        switch (label.toLowerCase()) {
            case 'google':
                return renderLink(label, `https://www.google.com/search?q=${encodeParams(company.name)}`)
            case 'register link':
                return renderLink(companyRegisterLink.name, companyRegisterLink.url, true)
            case 'google maps':
                return renderLink(
                    label,
                    `https://www.google.com/maps/search/${encodeParams(
                        `${company.addressLine1}${company.addressLine2 ? ', ' + company.addressLine2 : ''}${
                            company.zipcode ? ', ' + company.zipcode : ''
                        }${company.city ? ', ' + company.city : ''}${
                            company.country ? ', ' + countries.getName(company.country.toUpperCase(), 'en') : ''
                        }` || ''
                    )}`,
                    true
                )
        }
        // if ( == 'trustpilot')
        // else if(lareturn <Text color="front.text.subtlerI">{label}</Text>
        // return (
        //     <Flex>
        //             {companyRegisterLink.name && (
        //                 <ExternalLink target="blank" url={companyRegisterLink.url}>
        //                     {companyRegisterLink.name}
        //                 </ExternalLink>
        //             )}
        //     </Flex>
        // )
    }

    if (indexPath.resourceKey === 'people') {
        const person = resource as Person
        if (person && person.name && label.toLowerCase() === 'google')
            return renderLink(label, `https://www.google.com/search?q=${encodeParams(person.name)}`)
    }

    if (indexPath.resourceKey === 'websites') {
        const website = resource as Website
        if (website && website.url && label.toLowerCase() === 'trustpilot')
            return renderLink(label, `https://www.trustpilot.com/search?query=${encodeParams(website.url)}`, true)
    }

    if (isLoading)
        return (
            <>
                <TextLineLoader height={19} topOffset={4} />
                <div />
                <span />
            </>
        )
    return <Text color="back.text">{label}</Text>
}

const IconHolder = styled.div`
    position: relative;
    top: 2px;
    left: 4px;
`
