import { startCase } from 'lodash'

import * as yup from 'yup'

import { CardInset } from '../../../components/cards/cardInset'
import { List } from '../../../components/layout/list'
import { FormFieldWithFormattedNoDecimalsNumber, FormFieldWithFormattedNumber } from '../../../utils'
import { MerchantAccountContractsProps } from './Accounts.ID.ContractProps'
import { ContractValidationRules } from './utils'
import { Separator } from '../../../components/layout/separator'
import { ContractCard } from '../../../components/cards/contractCard'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { useContractSectionHelper } from './useContractSectionHelper'

const textForItem = (a?: string) => {
    return `${startCase(a?.toLowerCase())}`
}
const YESNO = [true, false]
const items = ['WEEKLY', 'DAILY', 'MONTHLY']
// background: 'front.background'
export const MerchantAccountContractsSectionPayout: React.FC<MerchantAccountContractsProps> = ({
    contract,
    formErrors,
    formRef,
    draftOrTemplate,
    currency,
    isDisabled
}) => {
    const { getValue, isEditable } = useContractSectionHelper(contract, draftOrTemplate)

    return (
        <Flex column>
            <ContractCard showEditable={isEditable}>
                <CardInset>
                    <List
                        background="front.background"
                        items={{
                            'Pricing principle': {
                                type: 'select',
                                textForItem: (k) => {
                                    if (k === true) return 'Interchange plus'
                                    if (k === false) return 'Pricing plus'
                                    return undefined
                                },
                                shouldHide: false,
                                selected: getValue('charges.interchange'),
                                overBackground: 'front.background',
                                rightAlign: true,
                                isDisabled: isDisabled || !isEditable,
                                dropdownId: 'Merchant.AccountsPage.Contracts.NewContract.PricingPlan',
                                items: YESNO,
                                isQuestion: true,
                                noClear: true,
                                validation: formErrors['charges.interchange'],
                                ref: (ref) =>
                                    formRef(ref, 'charges.interchange', yup.boolean(), {
                                        fromServer: (s) => {
                                            if (s == true) return true
                                            return false
                                        },
                                        toServer: {
                                            beforeSending: (s): number | string => {
                                                return s
                                            }
                                        }
                                    })
                            }
                        }}
                        rowGap="tiny"
                        alignRight
                        switchToRowsAt={10000}
                        cellHorizontalTemplate="min-content auto"
                    />
                </CardInset>
            </ContractCard>
            <Spacer height={10} />
            <ContractCard title="Payout settings" showEditable={isEditable}>
                <CardInset>
                    <List
                        background="front.background"
                        items={{
                            'Payout frequency': {
                                type: 'select',
                                textForItem,
                                overBackground: 'front.background',
                                dropdownId: 'Merchant.AccountsPage.Contracts.ContractPeriod',
                                items,
                                noClear: true,
                                rightAlign: true,
                                selected: getValue('paymentPeriod'),
                                isDisabled: isDisabled || !isEditable,
                                validation: formErrors.paymentPeriod,
                                ref: (ref) => formRef(ref, 'paymentPeriod', yup.string().required().oneOf(items))
                            },
                            'Payout delay': {
                                type: 'input',
                                initialValue: getValue('paymentDelay'),
                                isDisabled: isDisabled || !isEditable,

                                rightAlign: true,
                                validation: formErrors.paymentDelay,
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'paymentDelay',
                                        ContractValidationRules.payoutDelay,
                                        FormFieldWithFormattedNoDecimalsNumber
                                    ),
                                suffix: getValue('paymentDelay') <= 1 ? 'day' : 'days',
                                overBackground: 'front.background'
                            },
                            'Separator 1': {
                                key: 'separator-1',
                                type: 'separator',
                                node: <Separator moreSpacing />
                            },
                            'Rolling reserve': {
                                type: 'input',
                                placeholder: '-',
                                initialValue: getValue('rollingReserveRate'),
                                isDisabled: isDisabled || !isEditable,
                                rightAlign: true,

                                validation: formErrors.rollingReserveRate,
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'rollingReserveRate',
                                        ContractValidationRules.limitedPercentage,
                                        FormFieldWithFormattedNumber
                                    ),
                                suffix: '%',
                                overBackground: 'front.background'
                            },
                            'Rolling reserve delay': {
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('rollingReserveDelay'),
                                isDisabled: isDisabled || !isEditable,

                                validation: formErrors.rollingReserveDelay,
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'rollingReserveDelay',
                                        ContractValidationRules.rollingReserveDelay,
                                        FormFieldWithFormattedNoDecimalsNumber
                                    ),
                                suffix: getValue('rollingReserveDelay') === 1 ? 'day' : 'days',
                                overBackground: 'front.background'
                            },
                            'Separator 2': {
                                key: 'separator-2',
                                type: 'separator',
                                node: <Separator moreSpacing />
                            },
                            'Minimum payout (regular)': {
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('charges.minimumPayoutAmount'),
                                isDisabled: isDisabled || !isEditable,

                                suffix: currency,
                                overBackground: 'front.background',
                                validation: formErrors['charges.minimumPayoutAmount'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.minimumPayoutAmount',
                                        ContractValidationRules.payoutAmount,
                                        FormFieldWithFormattedNumber
                                    )
                            },
                            'Minimum payout (reserve)': {
                                type: 'input',
                                rightAlign: true,
                                initialValue: getValue('charges.minimumRollingReservePayoutAmount'),
                                isDisabled: isDisabled || !isEditable,
                                suffix: currency,
                                overBackground: 'front.background',
                                validation: formErrors['charges.minimumRollingReservePayoutAmount'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'charges.minimumRollingReservePayoutAmount',
                                        ContractValidationRules.payoutAmount,
                                        FormFieldWithFormattedNumber
                                    )
                            }
                        }}
                        rowGap="tiny"
                        alignRight
                        switchToRowsAt={10000}
                        cellHorizontalTemplate="min-content auto"
                    />
                </CardInset>
            </ContractCard>
        </Flex>
    )
}
