import { LoadingStatus } from '../../utils'
import { MerchantAccountDraftContract } from '../merchantAccounts/types'

export interface SchemeRate {
    scheme: 'visa'
    domesticCredit: string
    domesticDebit: string
    id: string
    interRegionCredit: string
    interRegionDebit: string
    intraRegionCredit: string
    intraRegionDebit: string
    selfLink: string
}

export interface Charges {
    id: string
    authorizationFee: string
    captureFee: string
    chargebackFee: string
    creditFee: string
    debitFee: string
    seriesFee: string
    exchangeFee: string
    interchange: boolean
    minimumDomesticTransactionFee: string
    minimumInterTransactionFee: string
    minimumIntraTransactionFee: string
    monthlyFee: string
    payoutFee: string
    refundFee: string
    retrievalFee: string
    schemeRates: SchemeRate[]
    selfLink: string
    setupFee: string
    threedsFee: string
    yearlyFee: string
}

export type DraftContract = MerchantAccountDraftContract

export interface Contract {
    id: string
    applicationId: string
    accountFees: {
        base: string
        payout: string
        monthly: string
        yearly: string
        retrieval: string
    }
    chargeback: string
    monthly: string
    payout: string
    retrieval: string
    setup: string
    yearly: string
    percentage: {
        exchange: string
    }
    currency: string
    descriptor: string
    gateway: string
    mcc: string
    metadata: {
        state: string
        createdAt: string
    }
    otherTerms: string
    payment: {
        period: string
        delay: number
    }
    delay: number
    period: string
    plan: string
    recordId: string
    rollingReserve: {
        percentage: string
        delay: number
    }
    threedSecureMandatory: true
    signer?: {
        name: string
        email: string
    }
    transactionFees: {
        base: {
            authorization: string
            capture: string
            credit: string
            debit: string
            refund: string
            minimum: {
                domestic: string
                intra: string
                inter: string
            }
        }
        percantage: {
            lateCapture: string
            domestic: {
                visa: { debit: string; credit: string }
                mastercard: { debit: string; credit: string }
            }
            inter: {
                visa: { debit: string; credit: string }
                mastercard: { debit: string; credit: string }
            }
            intra: {
                visa: { debit: string; credit: string }
                mastercard: { debit: string; credit: string }
            }
        }
    }
}

export interface ContractsState {
    contracts: {
        at: {
            [key: string]: Contract
        }
        all: string[]
    }
    loadingStatus: LoadingStatus
}

export const InitialContractsState: ContractsState = {
    contracts: {
        at: {},
        all: []
    },
    loadingStatus: 'not-started'
}
