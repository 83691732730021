import countries from 'i18n-iso-countries'
import React, { forwardRef, useCallback, useMemo } from 'react'

import { TextInputSelect } from '../../../components/forms/textInputSelect'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import * as yup from 'yup'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { Color } from '../../../styles/colors'

const MerchantApplicationEditsModalInputCountryWithRef: React.ForwardRefRenderFunction<
    any,
    {
        preSelected?: string
        onSelect?: (item: string) => void
        onBlur: (e: any) => void
        indexPath: MerchantApplicationResourceIndexPath
        formRef: MerchantApplicationFieldFormProps['formRef']
        overBackground: Color
        validation: ValidationStatus
    }
> = ({ preSelected, onSelect, validation, overBackground, onBlur, formRef, indexPath }) => {
    const { shortcodes, countriesDictionary } = useMemo(() => {
        const c = countries.getNames('en')
        delete c.XK
        return {
            shortcodes: Object.keys(c).sort((a, b) => {
                return a > b ? -1 : 1
            }),
            countriesDictionary: c
        }
    }, [])

    const textForItem = useCallback(
        (shortcode) => {
            return countriesDictionary[shortcode]
        },
        [countriesDictionary]
    )

    // useEffect(() => {
    //     if (selectedCountry) onSelect(selectedCountry)
    // }, [onSelect, selectedCountry])

    return (
        <TextInputSelect
            overBackground={overBackground}
            placeholder="Select a country"
            textareaBased
            ref={(ref) => {
                formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
            }}
            validation={validation}
            isSeamless
            lazyLoaded
            onBlur={onBlur}
            noClear
            selected={preSelected || ''}
            dropdownId="GenericDropdown"
            textForItem={textForItem}
            items={shortcodes}
        />
    )
}

export const MerchantApplicationEditsModalInputCountry = forwardRef(MerchantApplicationEditsModalInputCountryWithRef)
