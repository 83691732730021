import React, { useCallback, useMemo } from 'react'

import { TextInputSelect } from '../../../components/forms/textInputSelect'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import * as yup from 'yup'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { Color } from '../../../styles/colors'

const roles: any = {
    'director': 'Director',
    'owner': 'Owner',
    'director-and-owner': 'Director & Owner'
}

export const MerchantApplicationEditsModalInputRole: React.FC<{
    preSelected?: string
    onSelect?: (item: string) => void
    onBlur: (e: any) => void
    overBackground: Color
    indexPath: MerchantApplicationResourceIndexPath
    formRef: MerchantApplicationFieldFormProps['formRef']
    validation: ValidationStatus
}> = ({ preSelected, validation, overBackground, onBlur, formRef, indexPath }) => {
    const textForItem = useCallback((item) => {
        return roles[item]
    }, [])

    const items = useMemo(() => {
        return Object.keys(roles)
    }, [])

    return (
        <TextInputSelect
            placeholder="Select a role"
            ref={(ref) => {
                formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
            }}
            overBackground={overBackground}
            textareaBased
            selected={preSelected}
            validation={validation}
            noClear
            isSeamless
            lazyLoaded
            dropdownId="GenericDropdown"
            textForItem={textForItem}
            onBlur={onBlur}
            items={items}
        />
    )
}
