import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { FileBox } from '../../components/files/fileBox'
import { UserComment } from '../../components/complexComponents/userComment'
import {
    ApplicationsDispatchRemoveInternalComment,
    ApplicationsDispatchUnstoreInternalFiles
} from '../../store/applications/actions'
import { FileActionDispatchDelete } from '../../store/files/actions'
import { RootState } from '@/store'

export const MerchantInternalNotesItem: React.FC<{
    applicationId: string
    item: any
    type: 'file' | 'comment'
}> = ({ item, type, applicationId }) => {
    const dispatch = useDispatch()
    const unreadComments = useSelector(
        (state: RootState) => state.applicationInternals.needingAttention?.[applicationId]?.unreadComments
    )

    const handleCommentDelete = useCallback(
        (id, watcherId) => {
            dispatch(ApplicationsDispatchRemoveInternalComment(applicationId, item, watcherId))
        },
        [dispatch, applicationId, item]
    )

    if (type === 'comment')
        return (
            <div data-cy="internal-note-item">
                <UserComment
                    date={item.createdAt}
                    authorType="id"
                    onDelete={handleCommentDelete}
                    id={item.id}
                    isUnread={unreadComments?.[item.id]}
                    rightSide={item.field === 'call' ? <CallNote>Call note</CallNote> : undefined}
                    author={item.authorId}
                    deletableAtAnyPoint
                    body={item.content}
                />
            </div>
        )

    return (
        <div data-cy="internal-note-item">
            <FileBox
                fileId={item.id}
                onRemoveClicked={(watcherId) => {
                    dispatch(
                        FileActionDispatchDelete(
                            watcherId,
                            item,
                            (files) => ApplicationsDispatchUnstoreInternalFiles(applicationId, files),
                            item.API === 'mapi' ? false : true
                        )
                    )
                }}
                showFileMenu
            />
        </div>
    )
}

const CallNote = styled.div`
    padding: 1px 5px;
    border: 1px solid ${(p) => p.theme['front.background.strongerIII']};
    border-radius: 6px;
    margin-right: 10px;
    color: ${(p) => p.theme['front.text.subtlerII']};
`
