import React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '../icon'
import { styles } from './styles'
import { cn } from '../utils'

interface SelectProps {
    children: React.ReactNode
    value?: string
    onValueChange?: (value: string) => void
    variant?: 'default' | 'surface'
    placeholder?: string
}

const Select = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Root>, SelectProps>((props, ref) => {
    const { children, value, onValueChange, variant = 'default', placeholder } = props

    const triggerClassnames = cn(styles.trigger({ variant }))
    const contentClassnames = cn(styles.content())
    const viewportClassnames = cn(styles.viewport())

    return (
        <SelectPrimitive.Root value={value} onValueChange={onValueChange}>
            <SelectPrimitive.Trigger ref={ref} className={triggerClassnames}>
                <SelectPrimitive.Value placeholder={placeholder} />
                <SelectPrimitive.Icon>
                    <ChevronDownIcon color="neutral" />
                </SelectPrimitive.Icon>
            </SelectPrimitive.Trigger>
            <SelectPrimitive.Portal>
                <SelectPrimitive.Content position="popper" sideOffset={6} ref={ref} className={contentClassnames}>
                    <ScrollUpButton />
                    <SelectPrimitive.Viewport className={viewportClassnames}>{children}</SelectPrimitive.Viewport>
                    <ScrollDownButton />
                </SelectPrimitive.Content>
            </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
    )
})

const ScrollUpButton = React.forwardRef<React.ElementRef<typeof SelectPrimitive.ScrollUpButton>, {}>((_, ref) => {
    const classnames = cn(styles.scrollUpButton())

    return (
        <SelectPrimitive.ScrollUpButton ref={ref} className={classnames}>
            <ChevronUpIcon color="neutral" />
        </SelectPrimitive.ScrollUpButton>
    )
})

ScrollUpButton.displayName = 'ScrollUpButton'

const ScrollDownButton = React.forwardRef<React.ElementRef<typeof SelectPrimitive.ScrollDownButton>, {}>((_, ref) => {
    const classnames = cn(styles.scrollDownButton())

    return (
        <SelectPrimitive.ScrollDownButton ref={ref} className={classnames}>
            <ChevronDownIcon color="neutral" />
        </SelectPrimitive.ScrollDownButton>
    )
})

ScrollDownButton.displayName = 'ScrollDownButton'

interface ItemProps {
    children: string
    value: string
}

const Item = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, ItemProps>((props, ref) => {
    const { children, value } = props

    const itemClassnames = cn(styles.item())
    const indicatorClassnames = cn(styles.itemIndicator())

    return (
        <SelectPrimitive.Item value={value} ref={ref} className={itemClassnames}>
            <span className={indicatorClassnames}>
                <SelectPrimitive.ItemIndicator>
                    <CheckIcon color="primary" />
                </SelectPrimitive.ItemIndicator>
            </span>
            <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
        </SelectPrimitive.Item>
    )
})

Item.displayName = 'Item'

const SelectCompound = Object.assign(Select, { Item })

export { SelectCompound as Select }
