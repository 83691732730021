import { createActionCreator } from 'deox'
import { WatcherID } from '../watcher/types'
import { ContractTemplate, LocallyStoredContractTemplate } from './types'

const Prefix = 'CONTRACT_TEMPLATES'
export const ContractTemplateActions = {
    BOOTSTRAP: createActionCreator(
        `${Prefix}/BOOTSTRAP`,
        (resolve) => (watcherId: WatcherID, currency: string) => resolve({ watcherId, currency })
    ),
    UPDATE: createActionCreator(
        `${Prefix}/UPDATE`,
        (resolve) =>
            (
                watcherId: WatcherID,
                id: string,
                template: LocallyStoredContractTemplate,
                changes: {
                    [key: string]: {
                        from: string
                        to: string
                    }
                }
            ) =>
                resolve({ watcherId, id, template, changes })
    ),
    FETCH_ALL: createActionCreator(`${Prefix}/FETCH_ALL`, (resolve) => () => resolve()),
    FETCH_SINGLE: createActionCreator(
        `${Prefix}/FETCH_SINGLE`,
        (resolve) => (templateId: string) => resolve({ templateId })
    ),
    STORE_ALL: createActionCreator(
        `${Prefix}/STORE_ALL`,
        (resolve) => (templates: ContractTemplate[]) => resolve({ templates })
    ),
    STORE_SINGLE: createActionCreator(
        `${Prefix}/STORE_SINGLE`,
        (resolve) => (template: ContractTemplate) => resolve({ template })
    ),
    UNSTORE: createActionCreator(`${Prefix}/UNSTORE`, (resolve) => (templateId: string) => resolve({ templateId })),
    DELETE: createActionCreator(
        `${Prefix}/DELETE`,
        (resolve) => (watcherId: string, selfLink: string) => resolve({ watcherId, selfLink })
    )
}
