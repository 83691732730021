import { MAPI_PARTNERS_ROOT, getPartners, useGetPartners } from '@/services/merchant-api'
import { Box, Button, Card, Flex, Loader, Text } from '@/design-system'
import React from 'react'
import { useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { RTKQuery } from '@/services/common'
import { PartnersTable } from '@/features/partners'

export const loader = async ({ request }) => {
    return await RTKQuery.get(getPartners, request, MAPI_PARTNERS_ROOT)
}

const Partners = () => {
    const navigate = useNavigate()
    const navigation = useNavigation()
    const location = useLocation()

    const { data } = useGetPartners(MAPI_PARTNERS_ROOT)

    const partners = data?.embedded['ch:partners']
    const hasPartners = partners && partners.length > 0
    const cardSize = hasPartners ? 'sm' : 'md'
    const isLoading = navigation.state === 'loading'

    const handleOnRowClick = (id) => {
        navigate(`/more/partners/${id}`)
    }

    const handleOnEditClick = (id) => {
        navigate(`/more/partners/${id}/edit`, { state: { referrer: location.pathname } })
    }

    const handleAddPartner = () => {
        navigate('./add')
    }

    return (
        <Loader isLoading={isLoading}>
            <Box my="3">
                <Flex justify="between" align="center">
                    <Text weight="medium" lineHeight="none">
                        Partners
                    </Text>
                    <Button testid="partner-overview-add-button" onClick={handleAddPartner}>
                        Add partner
                    </Button>
                </Flex>
            </Box>
            <Box mb="8">
                <Card size={cardSize}>
                    <Card.Body>
                        {hasPartners ? (
                            <PartnersTable
                                partners={partners}
                                onRowClick={handleOnRowClick}
                                onEditClick={handleOnEditClick}
                            />
                        ) : (
                            <Text color="neutral" align="center">
                                No partners available
                            </Text>
                        )}
                    </Card.Body>
                </Card>
            </Box>
        </Loader>
    )
}

Partners.loader = loader

export { Partners }
