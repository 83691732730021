import React, { useMemo } from 'react'

import { ConvertIndexPathStringToIndexPath } from '../../../store/applicationResources/utils'
import { ApplicationDeliveryDelay } from '../../../store/applications/types'
import { getWebsiteLabel, getWebsiteUrl } from '../../../utils/formatting'
import { ConvertIndexPathToFieldDetails } from './Application.Structure'
import { MerchantApplicationFieldValueCompanyForm } from './Application.FieldValueCompanyForm'
import { MerchantApplicationFieldValueCountry } from './Application.FieldValueCountry'
import { MerchantApplicationFieldValueDeliveryDelay } from './Application.FieldValueDeliveryDelay'
import { MerchantApplicationFieldValueGateway } from './Application.FieldValueGateway'
import { MerchantApplicationFieldValueLongText } from './Application.FieldValueLongText'
import { MerchantApplicationFieldValueNumber } from './Application.FieldValueNumber'
import { MerchantApplicationFieldValueOwnershipStructure } from './Application.FieldValueOwnershipStructure'
import { MerchantApplicationFieldValueQuestion } from './Application.FieldValueQuestion'
import { MerchantApplicationFieldValueRole } from './Application.FieldValueRole'
import moment from 'moment'
import { ExternalLink } from '../../../components/buttons/externalLink'
import { Spacer } from '../../../components/layout/spacer'
import { LinkButtonArrow } from '../../../components/buttons/linkButtonArrow'

export const MerchantApplicationFieldValue: React.FC<{
    rawValue: string
    indexPathString: string
}> = ({ indexPathString, rawValue }) => {
    const { indexPath, fieldDetails } = useMemo(() => {
        const iP = ConvertIndexPathStringToIndexPath(indexPathString)
        const fieldDetails = iP ? ConvertIndexPathToFieldDetails(iP) : undefined
        return { indexPath: iP, fieldDetails }
    }, [indexPathString])

    if (!indexPath || !fieldDetails || !fieldDetails.field) throw 'Failed to establish field value'

    switch (fieldDetails.field.type) {
        case 'multilineText':
            return <MerchantApplicationFieldValueLongText text={rawValue} />
        case 'question':
            return <MerchantApplicationFieldValueQuestion value={rawValue} />

        case 'number':
            return <MerchantApplicationFieldValueNumber number={rawValue as any} />

        case 'dropdown': {
            if (indexPath.fieldKey === 'businessModel.deliveryDelay' && indexPath.resourceKey === 'businessModel')
                return <MerchantApplicationFieldValueDeliveryDelay value={rawValue as ApplicationDeliveryDelay} />
            if (indexPath.fieldKey === 'form' && indexPath.resourceKey === 'company')
                return <span>{MerchantApplicationFieldValueCompanyForm(rawValue)}</span>
            if (indexPath.fieldKey === 'role' && indexPath.resourceKey === 'people')
                return <MerchantApplicationFieldValueRole value={rawValue} />
            if (indexPath.fieldKey === 'ownershipStructure')
                return <MerchantApplicationFieldValueOwnershipStructure value={rawValue} />
            if (indexPath.fieldKey === 'country') {
                if (rawValue === undefined) return <MerchantApplicationFieldValueRole value={rawValue} />
                return <MerchantApplicationFieldValueCountry value={rawValue} />
            }
            if (indexPath.fieldKey === 'gateways.0.id' && indexPath.resourceKey === 'gateway')
                return <MerchantApplicationFieldValueGateway gatewayId={rawValue} />
            return <span>{rawValue}</span>
        }

        case 'date':
            return <span>{moment(rawValue).format('DD MMM, YYYY')}</span>

        case 'url': {
            if (!rawValue) return <span>-</span>
            return (
                <ExternalLink key={rawValue} target="blank" url={getWebsiteUrl(rawValue)} arrowLess>
                    {getWebsiteLabel(rawValue)}
                    <Spacer width={5} />
                    <LinkButtonArrow background="front.background" shouldBeBordered />
                </ExternalLink>
            )
        }
    }

    return <span>{rawValue}</span>
}
