import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Table } from '../../../components/tables/table'
import { Text } from '../../../components/general/text'
import { RootState } from '@/store'
import { LinkButton } from '../../../components/buttons/linkButton'
import { Flex } from '../../../components/layout/flex'
import { TableBubble } from '../../../components/layout/tableBubble'
import { MerchantAccountLinks } from '../Merchant.AccountLinks'
import { AccountState } from '../../../components/general/accountState'
import { useWarningByMID } from '../../../hooks/pages/useWarningsByMID'
import { SummaryBriefWarning } from './SummaryBriefWarning'

export const MerchantSummaryAccountsCard: React.FC<{ merchantId: string }> = ({ merchantId }) => {
    const accounts = useSelector((state: RootState) => state.merchantAccounts)
    const { byMID: warnings, hasWarnings } = useWarningByMID(merchantId)
    const columns = useMemo(() => {
        return [
            {
                text: 'Status',
                loaderSize: {
                    min: 30,
                    max: 100
                }
            },
            {
                text: 'MID',
                loaderSize: {
                    min: 50,
                    max: 50
                }
            },
            {
                text: 'Currency',
                loaderSize: {
                    min: 30,
                    max: 30
                }
            },
            {
                text: 'Name',
                loaderSize: {
                    min: 30,
                    max: 130
                }
            },
            {
                text: 'Descriptor',
                loaderSize: {
                    min: 30,
                    max: 130
                }
            },
            ...(hasWarnings
                ? [
                      {
                          text: 'Warnings',
                          alignRight: true,
                          loaderSize: {
                              min: 30,
                              max: 100
                          }
                      }
                  ]
                : []),
            {
                text: 'Actions',
                alignRight: true,
                loaderSize: {
                    min: 30,
                    max: 100
                }
            }
        ]
    }, [hasWarnings])

    const accIds = useMemo(() => {
        const newAccs = accounts.forApplication[merchantId]?.all
            ? JSON.parse(JSON.stringify(accounts.forApplication[merchantId]?.all)) || []
            : []
        newAccs.sort((a, b) => {
            const Aacc = accounts.at[a]
            const Bacc = accounts.at[b]
            if (Aacc.metadata.state == 'live') return -1
            else {
                if (Aacc.metadata.state > Bacc.metadata.state) {
                    return -1
                } else {
                    return 1
                }
            }
        })
        return newAccs
    }, [accounts, merchantId])

    const rows = useMemo(() => {
        return (
            accIds.map((id: string) => {
                const account = accounts.at[id]
                return {
                    type: 'normal' as const,
                    key: account.id,
                    link: `/merchant/${merchantId}/accounts/${account.id}`,
                    items: [
                        {
                            node: <AccountState state={account.metadata.state} />
                        },
                        {
                            node: <TableBubble type="outlined">{account.merchantId}</TableBubble>
                        },
                        {
                            node: <TableBubble type="important">{account.currency}</TableBubble>
                        },
                        {
                            node: <Text>{account.name}</Text>
                        },
                        {
                            node: <Text>{account.descriptor}</Text>
                        },
                        ...(hasWarnings
                            ? warnings[account.merchantId]
                                ? [
                                      {
                                          node: (
                                              <Flex>
                                                  {warnings[account.merchantId].map((w) => (
                                                      <SummaryBriefWarning warning={w} />
                                                  ))}
                                              </Flex>
                                          )
                                      }
                                  ]
                                : [{ node: <span /> }]
                            : []),
                        {
                            node: (
                                <Flex>
                                    <MerchantAccountLinks
                                        appId={merchantId}
                                        state={account.metadata.state}
                                        accId={account.id}
                                        MID={account.merchantId}
                                    />
                                </Flex>
                            )
                        }
                    ]
                }
            }) || []
        )
    }, [accounts.at, accIds, merchantId, hasWarnings, warnings])

    const rightSideMemo = useMemo(() => {
        return (
            <Flex grow justify="flex-end">
                <LinkButton keepQuery to={`/merchant/${merchantId}/accounts`}>
                    Go to Accounts
                </LinkButton>
            </Flex>
        )
    }, [merchantId])

    const isLoading = accounts.forApplication?.[merchantId]?.loadingStatus !== 'done'

    return (
        <Card
            title={isLoading ? `Merchant Accounts` : `Merchant Accounts (${rows.length})`}
            rightSideMemo={rightSideMemo}
        >
            <CardInset>
                <Table
                    background="front.background"
                    overrideText="Merchant has no accounts"
                    cols={columns}
                    columnLayout={`130px 80px 80px auto auto ${hasWarnings ? 'min-content' : ''} min-content`}
                    rows={rows}
                    displayLoader={isLoading}
                    loaderRows={4}
                />
            </CardInset>
        </Card>
    )
}
