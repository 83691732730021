import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { List } from '../../../components/layout/list'
import { Spacer } from '../../../components/layout/spacer'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { ModalPage } from '../../../components/layout/modalPage'
import { ModalPageInset } from '../../../components/layout/modalPageInset'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { useForm } from '../../../hooks/general/useForm'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { ApplicationInternalsCollaboratorsActions } from '../../../store/applicationInternals/collaborators/actions'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { useQueryParams } from '../../../hooks/general/useQueryParam'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

type Params = { id: string }

const PARAMS = ['email', 'name']
export const MerchantAddCollaborator: React.FC = () => {
    const params = useParams() as Params

    const merchantId = params.id
    const [query] = useQueryParams(PARAMS)

    const location = useLocation()
    const navigate = useNavigate()
    const [watcher, watcherId] = useNamedWatcher()
    const dispatch = useDispatch()
    const name = useMerchantName(merchantId, 'Add collaborator')
    const { errors, formRef, submitHandler } = useForm(watcher)

    useEffect(() => {
        if (watcher === 'success')
            navigate({
                pathname: `/merchant/${merchantId}/summary`,
                search: location.search
            })
    }, [navigate, merchantId, watcher, location.search])

    const sendMessage = useCallback(
        (form) => {
            if (!merchantId) return

            dispatch(
                ApplicationInternalsCollaboratorsActions.INVITE(
                    watcherId,
                    merchantId,
                    form.data.email,
                    form.data.name,
                    form.data.message
                )
            )
        },
        [dispatch, merchantId, watcherId]
    )

    return (
        <ModalPage title={name} pageId="Merchant.Summary.AddCollaborator">
            <ModalHeader title="Invite collaborator" pageId="Merchant.Summary.AddCollaborator" />
            <ModalPageInset>
                <Flex justify="center" align="center" grow column>
                    <Card higher title="Invite collaborator">
                        <CardInset>
                            <List
                                background="front.background"
                                items={{
                                    Name: {
                                        type: 'input',
                                        placeholder: '-',
                                        initialValue: query['name'],
                                        overBackground: 'front.background',
                                        validation: errors.name,
                                        ref: (ref) => formRef(ref, 'name', yup.string().required() as any)
                                    },
                                    Email: {
                                        type: 'input',
                                        initialValue: query['email'],
                                        placeholder: '-',
                                        overBackground: 'front.background',
                                        validation: errors.email,
                                        ref: (ref) => formRef(ref, 'email', (yup.string().required() as any).email())
                                    },
                                    Message: {
                                        type: 'custom',
                                        node: (
                                            <TextareaInput
                                                overBackground="front.background"
                                                placeholder="-"
                                                validation={errors.message}
                                                cy="message"
                                                isSeamless
                                                ref={(ref) => formRef(ref, 'message', yup.string().required())}
                                            />
                                        )
                                    }
                                }}
                                switchToRowsAt={10000}
                                cellHorizontalTemplate="120px minmax(auto, 200px)"
                            />
                            <Spacer height={20} />
                            <WatcherButton
                                background="front.accent.color"
                                predefinedWatcher={watcherId}
                                isDisabled={!!Object.keys(errors || {})?.length}
                                onClick={submitHandler(sendMessage)}
                            >
                                <ButtonInset>Send invite</ButtonInset>
                            </WatcherButton>
                        </CardInset>
                    </Card>
                </Flex>
            </ModalPageInset>
        </ModalPage>
    )
}
