import {
    Report,
    SectionCheck,
    SectionComment,
    SectionDetails,
    SectionDocumentation
} from '@/services/ongoing-due-diligence'
import React, { createContext, useContext, useMemo } from 'react'
import { defaultReport } from './default-report'

interface Context {
    report: Report
}

const ReportContext = createContext<Context>({ report: defaultReport })

interface ReportProps {
    children: React.ReactNode
    report: Report
}

export const ReportProvider: React.FC<ReportProps> = (props) => {
    const { children, report } = props

    const value = useMemo(() => {
        return { report }
    }, [report])

    return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
}

export const useReportContext = () => {
    return useContext(ReportContext)
}

type SectionName = 'checks' | 'comments' | 'details' | 'documentation'
type Section = SectionCheck | SectionComment | SectionDetails | SectionDocumentation

export const useReportSection = <T extends Section>(name: SectionName) => {
    const { report } = useContext(ReportContext)

    let section
    switch (name) {
        case 'checks': {
            section = report.report_data.sections.Checks.check
            break
        }
        case 'comments': {
            section = report.report_data.sections.Comments.comment
            break
        }
        case 'details': {
            section = report.report_data.sections.Details
            break
        }
        case 'documentation': {
            section = report.report_data.sections.Documentation.check_doc
            break
        }
        default: {
            throw Error('Section name not provided')
        }
    }

    const result: T = useMemo(() => {
        return section
    }, [section])

    return result
}

export const useReportDetails = () => {
    const { report } = useContext(ReportContext)

    const reportDetail = report.report_detail

    const result = useMemo(() => reportDetail, [reportDetail])

    return result
}

export const useReportMerchantData = () => {
    const { report } = useContext(ReportContext)

    const merchantData = report.merchant_data

    const result = useMemo(() => merchantData, [merchantData])

    return result
}
