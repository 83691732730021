import React from 'react'
import { Styles, styles } from './styles'

interface BadgeProps extends Styles {
    testid?: string
    children: React.ReactNode
}

export const Badge: React.FC<BadgeProps> = (props) => {
    const { testid, children, variant, color } = props

    const classnames = styles({ variant, color })

    return (
        <div data-cy={testid} className={classnames}>
            {children}
        </div>
    )
}
