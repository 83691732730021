import { Classification, Classifications, UpdateClassification } from '../types/classifications'

import { api } from '../api'

export const classificationsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getClassifications: build.query<Classifications, string>({
            query: (applicationId: string) => `/applications/${applicationId}/classifications`,
            providesTags: ['classifications']
        }),
        getClassification: build.query<Classification, string>({
            query: (classificationId: string) => `/classifications/${classificationId}`,
            providesTags: ['classification']
        }),
        createClassification: build.mutation<Classification, { applicationId: string; author?: string }>({
            query: ({ applicationId, author }) => ({
                url: `/applications/${applicationId}/classifications`,
                method: 'POST',
                body: {
                    ...(author && { author })
                }
            }),
            invalidatesTags: ['classifications']
        }),
        updateClassification: build.mutation<Classification, { classificationId: string; data: UpdateClassification }>({
            query: ({ classificationId, data }) => ({
                url: `/classifications/${classificationId}`,
                method: 'PUT',
                body: {
                    ...data
                }
            }),
            async onQueryStarted({ classificationId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPost } = await queryFulfilled

                    dispatch(
                        classificationsApi.util.updateQueryData('getClassification', classificationId, (draft) => {
                            Object.assign(draft, updatedPost)
                        })
                    )
                } catch {
                    console.error('onQueryStarted on endpoint updateReport failed')
                }
            },
            invalidatesTags: ['classifications']
        }),
        deleteClassification: build.mutation<Classification, { classificationId: string }>({
            query: ({ classificationId }) => ({ url: `/classifications/${classificationId}`, method: 'DELETE' }),
            invalidatesTags: ['classifications']
        }),
        finalizeClassification: build.mutation<void, { classificationId: string }>({
            query: ({ classificationId }) => ({ url: `/classifications/${classificationId}/finalize`, method: 'POST' }),
            invalidatesTags: ['classifications', 'classification']
        })
    })
})

export const {
    useGetClassificationsQuery: useGetClassifications,
    useGetClassificationQuery: useGetClassification,
    useCreateClassificationMutation: useCreateClassification,
    useUpdateClassificationMutation: useUpdateClassification,
    useDeleteClassificationMutation: useDeleteClassification,
    useFinalizeClassificationMutation: useFinalizeClassification
} = classificationsApi
