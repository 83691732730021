import React, { useCallback } from 'react'
import styled from 'styled-components'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Icon } from '../../components/icons/icon'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { useCopyToClipboard } from '../../hooks/general/useCopyToClipboard'

const websiteLink = `${import.meta.env.VITE_MIDGAARD_LINK}/partner/`
export function useCopyVoucherNodes(): [websiteLink: string, renderNodes: (voucher: string) => any] {
    const copyToClipboard = useCopyToClipboard()

    const renderNodes = useCallback(
        (voucher: string) => {
            return (
                <>
                    <SimpleButton
                        onClick={() => {
                            copyToClipboard(voucher)
                        }}
                    >
                        <Flex align="center">
                            <IconHolder>
                                <Icon type="voucher" />
                            </IconHolder>
                            <Spacer width={5} />
                            Copy Voucher
                        </Flex>
                    </SimpleButton>
                    <Spacer width={20} />
                    <SimpleButton
                        onClick={() => {
                            copyToClipboard(websiteLink + voucher)
                        }}
                    >
                        <Flex align="center">
                            <IconHolder>
                                <Icon type="link" />
                            </IconHolder>
                            <Spacer width={5} />
                            Copy Link
                        </Flex>
                    </SimpleButton>
                </>
            )
        },
        [copyToClipboard]
    )

    return [websiteLink, renderNodes]
}

const IconHolder = styled.div`
    height: 18px;
`
