import { ReactNode } from 'react'
import { ColorKey } from '../../../styles/colors'

export type MerchantApplicationStatusAction = string
export const MerchantApplicationStateControlsTypes: {
    [key in MerchantApplicationStatusAction]: {
        label: string
        color: ColorKey
        floatRight?: boolean
        hotkeys?: string
        textColor?: ColorKey
        icon?: ReactNode
        shouldHaveUnderReviewStamp?: boolean
        shouldHaveAcceptedStamp?: boolean
    }
} = {
    PUT_needs_information: {
        label: 'Request information',
        hotkeys: 'alt+i',
        color: 'front.background',
        shouldHaveAcceptedStamp: true
    },
    PUT_more_information: {
        label: 'Request information',
        hotkeys: 'alt+i',
        color: 'front.background',
        shouldHaveAcceptedStamp: true
    },
    PUT_submitted: {
        label: 'Submit',
        hotkeys: 'alt+s',
        color: 'front.background',
        shouldHaveUnderReviewStamp: true
    },
    PUT_archived: {
        label: 'Archive',
        color: 'front.background'
    },
    DELETE_archived: {
        label: 'Restore',
        color: 'front.accent.color'
    },
    clone: {
        label: 'Clone',
        color: 'front.background'
    },
    PUT_under_review: {
        label: 'Start review',
        hotkeys: 'alt+r',
        color: 'front.accent.color',
        shouldHaveAcceptedStamp: true
    },
    PUT_pre_accepted: {
        label: 'Pre-accept',
        hotkeys: 'alt+p',
        color: 'front.accent.color',
        shouldHaveAcceptedStamp: true
    },
    PUT_needs_approval: {
        label: 'Needs approval',
        hotkeys: 'alt+n',
        color: 'front.accent.color'
    },
    PUT_declined: {
        label: 'Decline',
        floatRight: true,
        hotkeys: 'alt+d',
        color: 'front.subtleDanger.background',
        textColor: 'front.subtleDanger.text',
        icon: 'x'
    },
    PUT_accepted: {
        label: 'Accept',
        color: 'front.accent.color',
        hotkeys: 'alt+y',
        floatRight: true,
        icon: 'checkmark'
    }
}
