import React, { useMemo, useState } from 'react'

import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { TimelineCard } from '../../components/listPages/timelineCard'
import { IconPaymentMethod } from '../../components/icons/iconPaymentMethod'
import { Settlement } from '../../store/settlements/types'
import { DateToWords, numberWithCurrency, uppercaseFirstLetter } from '../../utils'
import { getPaymentCardHumanized } from '../../utils/formatting'
import { ExportButton } from '../../components/buttons/exportButton'
import { Paginator } from '../../components/general/paginator'
import { useDispatch } from 'react-redux'
import { SettlementDispatchLoadTransactions } from '../../store/settlements/actions'
import { RelativeDate } from '@/components/general/relativeDate'

export const SettlementCardTransactions: React.FC<{
    settlement: Settlement
}> = ({ settlement }) => {
    const [showAll, setShowAll] = useState(false)
    const dispatch = useDispatch()

    const rightSideMemo = useMemo(() => {
        if (!settlement?.transactionsLink) return undefined
        if (!settlement?.transactions?.all) return undefined
        return (
            <Flex grow justify="flex-end">
                <ExportButton
                    resourceLink={settlement.transactionsLink}
                    availableFormats={['csv', 'excel']}
                    overrideText="Export transactions"
                />
            </Flex>
        )
    }, [settlement])

    return (
        <>
            <TimelineCard title="Transactions" rightSideMemo={rightSideMemo}>
                <Table
                    displayLoader={settlement?.transactions?.loadingStatus !== 'done'}
                    background="front.background"
                    showLastRowBorder={false}
                    overrideText="No transactions found"
                    loaderRows={20}
                    rows={settlement?.transactions?.all?.map((t) => {
                        return {
                            type: 'normal',
                            key: t.id,
                            link: `/transactions/${t.id}`,
                            linkTarget: 'managerTransactions',
                            items: [
                                {
                                    node: <RelativeDate dateString={t.processedAt} relativeFormat="DD MMM, YYYY" withFormat="YYYY-MM-DDTHH:mm:ss ZZ" />
                                },
                                {
                                    node: <Text color="front.text.subtlerI">{t.reference}</Text>
                                },
                                {
                                    node: <Text noWrap>{uppercaseFirstLetter(t.type)}</Text>
                                },
                                {
                                    node: (
                                        <Flex align="center">
                                            <IconPaymentMethod
                                                method={t.paymentMethod}
                                                card={t.card}
                                                color={'front.text.subtlerI'}
                                                size={17}
                                            />
                                            <Spacer width={8} />
                                            <Text noWrap>{getPaymentCardHumanized(t.card)}</Text>
                                        </Flex>
                                    )
                                },
                                {
                                    node: (
                                        <Text noWrap>
                                            {numberWithCurrency(t.settlement.currency, `${t.settlement.amountGross}`)}
                                        </Text>
                                    )
                                },
                                {
                                    node: (
                                        <Text noWrap>
                                            {numberWithCurrency(t.settlement.currency, `${t.settlement.fees}`)}
                                        </Text>
                                    )
                                },
                                {
                                    node: (
                                        <Flex align="baseline">
                                            <Text noWrap>
                                                {numberWithCurrency(t.settlement.currency, `${t.settlement.amountNet}`)}
                                            </Text>
                                            <Text noWrap style={{ marginLeft: 10 }} color="front.text.subtlerI">
                                                {t.settlement.currency}
                                            </Text>
                                        </Flex>
                                    )
                                }
                            ]
                        }
                    })}
                    cols={[
                        {
                            text: 'Date',
                            loaderSize: {
                                min: 60,
                                max: 70
                            }
                        },
                        {
                            text: 'Reference',
                            loaderSize: {
                                min: 50,
                                max: 50
                            }
                        },
                        {
                            text: 'Type',
                            loaderSize: {
                                min: 50,
                                max: 60
                            }
                        },
                        {
                            text: 'Card',
                            loaderSize: {
                                min: 100,
                                max: 120
                            }
                        },
                        {
                            text: 'Amount Gross',
                            alignRight: true,
                            loaderSize: {
                                min: 20,
                                max: 20
                            }
                        },
                        {
                            text: 'Fee',
                            alignRight: true,
                            loaderSize: {
                                min: 20,
                                max: 20
                            }
                        },
                        {
                            text: 'Amount Net',
                            alignRight: true,
                            loaderSize: {
                                min: 20,
                                max: 20
                            }
                        }
                    ]}
                    columnLayout="min-content auto auto auto min-content min-content min-content"
                />
            </TimelineCard>
            {settlement?.transactions?.loadingStatus === 'done' && settlement?.transactions?.pagination && (
                <Paginator
                    pagination={settlement?.transactions?.pagination}
                    onPageChanged={(page) => {
                        dispatch(SettlementDispatchLoadTransactions(settlement.id, settlement.transactionsLink, page))
                    }}
                />
            )}
        </>
    )
}
