import { delay, put, select } from 'typed-redux-saga'

import { createAuth0Client, Auth0Client, User } from '@auth0/auth0-spa-js'

import { getParameter } from '../../utils'
import {
    AuthActionInit,
    AuthActionLogin,
    AuthActionLogout,
    AuthActionStoreUser,
    AuthDispatchSetInProgress,
    AuthDispatchStoreUser,
    SagasForAuth
} from './actions'
import { jwtDecode } from 'jwt-decode'
import { scopes } from './scopesES'
import { ToastsDispatchPush } from '../toasts/actions'
import { RootState } from '@/store'
import { GlobalActions } from '../global/actions'

let client
let E2E_access_token: string | null = null

function* E2E_auth() {
    const source = window.localStorage.getItem('cypress')!

    E2E_access_token = getParameter(source, 'E2E_access_token')
    const id_token = getParameter(source, 'E2E_id_token')

    if (!id_token) throw "Couldn't find E2E_id_token"

    if (!E2E_access_token) {
        throw "Couldn't find E2E_access_token"
    }

    const userProfile = jwtDecode(id_token)

    yield put(AuthDispatchStoreUser(userProfile, E2E_access_token))
}

export function* waitForCriticalResources(skipRootLinksReadyCheck?: boolean) {
    let haveCriticalResourcesLoaded = false
    do {
        haveCriticalResourcesLoaded = yield select((state: RootState) =>
            skipRootLinksReadyCheck ? true : state.global?.loadingStatus == 'done' && state.auth.user
        )
        yield delay(16)
    } while (!haveCriticalResourcesLoaded || !client || !client.getTokenSilently)
    return (yield client) as Auth0Client
}

export function* getJWT(skipRootLinksReadyCheck?: boolean) {
    const c: Auth0Client = yield waitForCriticalResources(skipRootLinksReadyCheck)

    if (E2E_access_token) return E2E_access_token
    if (!c?.getTokenSilently) throw 'Auth0 - getToken not initialised'
    return (yield c.getTokenSilently()) as string
}

export async function generateJWTSync(callback: (token: string) => void) {
    try {
        while (!client?.getTokenSilently) {
            await new Promise((r) => setTimeout(r, 16))
        }
        const token = await client?.getTokenSilently()
        callback(token)
    } catch (e) {
        throw e
    }
}

const config = {
    domain: import.meta.env.VITE_AUTH_PROVIDER_DOMAIN!,
    clientId: import.meta.env.VITE_AUTH_PROVIDER_CLIENT_ID!,
    authorizationParams: {
        scope: scopes.join(' '),
        audience: import.meta.env.VITE_API_ROOT,
        redirect_uri: import.meta.env.VITE_AUTH0_REDIRECT
    }
}

function* forceLogout(client?: any) {
    if (client) {
        yield client.logout({
            logoutParams: {
                returnTo: import.meta.env.VITE_AUTH0_REDIRECT
            }
        })
    } else {
        const temporaryClient = (yield new Auth0Client(config)) as Auth0Client
        yield temporaryClient.logout()
    }
}

export class AuthSagas implements SagasForAuth {
    *init(action: AuthActionInit) {
        try {
            client = yield createAuth0Client(config)
        } catch (e) {
            yield put(ToastsDispatchPush('Failed to initialize the Auth0 client', 'error'))
            yield forceLogout()
            throw 'Failed to initialize the Auth0 client ' + e
            return
        }

        try {
            if (!client) {
                yield put(ToastsDispatchPush('Failed to init Auth0 client', 'error'))
                yield forceLogout()
                return
            } else {
                if (import.meta.env.VITE_ENV === 'cypress') {
                    yield E2E_auth()
                    return
                }

                const query = window.location.search
                if (query.includes('code=') && query.includes('state=')) {
                    const { appState } = yield client.handleRedirectCallback()
                    if (appState?.targetUrl) {
                        window.location.replace(appState.targetUrl)
                        return
                    }
                }

                if ((yield client.isAuthenticated()) as boolean) {
                    const user: User = yield client.getUser()
                    const token: string = yield client.getTokenSilently()

                    yield put(AuthDispatchStoreUser(user, token))
                } else {
                    yield put(AuthDispatchSetInProgress(false))
                }
            }
        } catch (e) {
            yield put(ToastsDispatchPush('Auth0 error encountered', 'error'))
            yield put(AuthDispatchSetInProgress(false))
            throw 'Auth0 error encountered: ' + e
        }
    }

    *login(action: AuthActionLogin) {
        if (!client) {
            yield put(ToastsDispatchPush('Auth0 Client not initialized', 'error'))
            yield forceLogout()
            return
        }
        try {
            yield client.loginWithRedirect({
                appState: {
                    targetUrl: window.location.pathname + window.location.search
                },
                authorizationParams: {
                    scope: scopes.join(' '),
                    redirect_uri: window.location.origin,
                    connection: action.connection === 'google' ? 'google-oauth2' : 'microsoft-identity'
                }
            })
            const user: User = yield client.getUser()
            const token: string = yield client.getTokenSilently()
            yield put(AuthDispatchStoreUser(user, token))
        } catch (e) {
            throw 'Login error encountered: ' + e
        }
    }

    *onUserStore(action: AuthActionStoreUser) {
        yield put(GlobalActions.FETCH_LINKS())
    }

    *logout(action: AuthActionLogout) {
        if (!client) {
            yield put(ToastsDispatchPush('Auth0 Client not initialized', 'error'))
            throw 'Auth0 Client not initialized'
        }
        yield forceLogout(client)
    }
}
