import { SimpleButton } from '@/components/buttons/simpleButton'
import React from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { useClassificationParams } from '../../../hooks/use-classification-params'
import { useFinalizeActions } from './use-finalize-actions'
import { Classification } from '@/services/risk-classification'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store'
import { ApplicationInternalTag } from '@/store/applicationInternals/tags/types'
import { startCase } from 'lodash'

type Params = {
    id: string
}

import { ApplicationInternalsTagsActions } from '@/store/applicationInternals/tags/actions'

interface IClassification {
    classification: Classification
}

export const FinalizeAction: React.FC<IClassification> = ({ classification }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams<Params>()
    const dispatch = useDispatch()
    const { applicationId } = useClassificationParams()
    const { isLoading, finalize } = useFinalizeActions()

    const tags = useSelector((state: RootState) => {
        return state.applicationInternals.tags
    })

    const applicationTags = tags.forApplication[applicationId]

    const currentAMLTags: ApplicationInternalTag[] = applicationTags.selected
        .map((key: string) => applicationTags.at[key])
        .filter((tag: ApplicationInternalTag) => tag.name.includes('AML '))

    const riskName = startCase(classification?.risk_level)

    const selectableMatchingTag = applicationTags.all
        .map((key) => applicationTags.at[key])
        .find((tag) => tag.name === `AML ${riskName}`)

    const removeTags = currentAMLTags.filter((tag) => tag.id !== selectableMatchingTag?.id)

    const watcher = isLoading ? 'started' : undefined

    const handleFinalizeReport = async () => {
        await finalize()

        if (selectableMatchingTag && !currentAMLTags.find((tag) => tag.id === selectableMatchingTag.id)) {
            dispatch(
                ApplicationInternalsTagsActions.ASSIGN(
                    `${applicationId}_CLASSIFICATION_TAGGING_ASSIGN`,
                    applicationId,
                    selectableMatchingTag
                )
            )
        }

        removeTags.map((tag) =>
            dispatch(
                ApplicationInternalsTagsActions.UNASSIGN(
                    `${applicationId}_CLASSIFICATION_TAGGING_UNASSIGN`,
                    applicationId,
                    tag
                )
            )
        )

        if (location?.state?.nextPath) {
            navigate(
                {
                    pathname: location.state.nextPath,
                    search: location.search
                },
                {
                    state: { refresh: true, focusLast: true }
                }
            )
        } else {
            navigate({
                pathname: `/merchant/${params.id}/more/risk-tab`,
                search: location.search
            })
        }
    }
    return (
        <SimpleButton
            background="front.accent.color"
            onClick={handleFinalizeReport}
            cy="risk-classification-button-finalize"
        >
            <ButtonLoadingCover background="front.background" watcher={watcher}>
                <ButtonInset>Finalize</ButtonInset>
            </ButtonLoadingCover>
        </SimpleButton>
    )
}
