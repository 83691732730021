import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { ConvertIndexPathToFieldDetails } from './Application.Structure'
import { TMerchantApplicationFieldType } from './Application.StructureTypes'
import { MerchantApplicationFieldConditionalRender } from './Application.FieldConditionalRender'
import { MerchantApplicationEditableFieldValue } from './Application.FieldValueEditing'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { MerchantApplicationFieldCounterButtonElement } from './Application.FieldCounter'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import dotProp from 'dot-prop'
import { MerchantApplicationRowHolder } from './Application.RowHolder'

export const MerchantApplicationField: React.FC<{
    type: TMerchantApplicationFieldType
    highlighted?: 'top' | 'bottom' | 'both'
    label?: string
    isFirst: boolean
    isLoading?: boolean
    sanitised?: boolean
    formRef: MerchantApplicationFieldFormProps['formRef']
    executeBatchChanges: MerchantApplicationFieldFormProps['executeBatchChanges']
    indexPath: MerchantApplicationResourceIndexPath
    formErrors: { [key: string]: ValidationStatus }
    applicationId: string
}> = ({
    indexPath,
    isLoading,
    sanitised,
    highlighted,
    type,
    label,
    executeBatchChanges,
    formErrors,
    formRef,
    applicationId
}) => {
    const fieldDetails = useMemo(() => {
        return ConvertIndexPathToFieldDetails(indexPath)
    }, [indexPath])

    const rawValue = useSelector((state: RootState) => {
        return dotProp.get<string>(
            state,
            `applicationResources.data.forApplication.${applicationId}.${indexPath.resourceKey}.fields.${
                indexPath.subsectionIndex || 0
            }.${indexPath.fieldKey}`
        )
    })

    const content = useMemo(() => {
        if (!label) return <span />

        const content = (
            <MerchantApplicationEditableFieldValue
                key={ConvertIndexPathToIndexPathString(indexPath)}
                type={type}
                formErrors={formErrors}
                highlighted={highlighted}
                isLoading={isLoading}
                label={label}
                executeBatchChanges={executeBatchChanges}
                fieldDetails={fieldDetails}
                sanitised={sanitised}
                applicationId={applicationId}
                formRef={formRef}
                indexPath={indexPath}
                initialValue={rawValue}
            />
        )
        if (fieldDetails.field.showOnlyIfSiblingFieldExists)
            return (
                <MerchantApplicationFieldConditionalRender
                    showOnlyIfSiblingFieldExists={fieldDetails.field.showOnlyIfSiblingFieldExists}
                >
                    <MerchantApplicationRowHolder indexPath={indexPath} label={label}>
                        <ItemRow>{content}</ItemRow>
                    </MerchantApplicationRowHolder>
                </MerchantApplicationFieldConditionalRender>
            )

        return (
            <Holder>
                <MerchantApplicationRowHolder indexPath={indexPath} label={label}>
                    <ItemRow>{content}</ItemRow>
                </MerchantApplicationRowHolder>
            </Holder>
        )
    }, [
        fieldDetails,
        indexPath,
        formErrors,
        formRef,
        rawValue,
        sanitised,
        type,
        label,
        isLoading,
        highlighted,
        executeBatchChanges,
        applicationId
    ])

    return content
}

const ItemRow = styled.div<{ isActive?: boolean }>`
    display: contents;

    &:hover ${MerchantApplicationFieldCounterButtonElement} {
        opacity: 1;

        & * {
            opacity: 1;
        }
    }

    ${(p) =>
        p.isActive &&
        css`
            & > * {
                position: relative;
                z-index: 30;
            }
        `}
`

const Holder = styled.div`
    display: contents;
`
