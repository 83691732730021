export const defaultClassification = {
    id: '',
    application_id: '',
    classification_data: {
        "Ownership / Management": {
            public_listed_company: {
                label: "",
                type: "checkbox" as const,
                risk_score: -1 as const,
                value: "",
            },
            pep_management: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
            pep_ownership: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
            ownership_structure_with_more_than_three_layers: {
                label: "",
                type: "checkbox" as const,
                risk_score: 1 as const,
                value: "",
            },
            public_authority: {
                label: "",
                type: "checkbox" as const,
                risk_score: -2 as const,
                value: "",
            },
        },
        "Business model": {
            high_value_goods: {
                label: "",
                type: "checkbox" as const,
                risk_score: 1 as const,
                value: "",
            },
            financial_institution: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
            gambling: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
            adult: {
                label: "",
                type: "checkbox" as const,
                risk_score: 1 as const,
                value: "",
            },
            crypto: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
            religious_charity: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
            political_party: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
            investment_scheme: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
            religious_membership_organisation: {
                label: "",
                type: "checkbox" as const,
                risk_score: 2 as const,
                value: "",
            },
        },
        Location: {
            based_on_malta_or_cyprus: {
                label: "",
                type: "checkbox" as const,
                risk_score: 1 as const,
                value: "",
            },
        },
        Others: {
            cross_border_bank_account: {
                label: "",
                type: "checkbox" as const,
                risk_score: 1 as const,
                value: "",
            },
            usd_account: {
                label: "",
                type: "checkbox" as const,
                risk_score: 1 as const,
                value: "",
            },
        },
        Comment: {
            comment: {
                label: "",
                placeholder: "",
                type: "textarea" as const,
                value: "",
            },
        },
    },
    createdAt: '',
    author: '',
    status: 'draft' as const,
    total_risk_score: 0 as const,
    risk_level: 'Medium risk' as const
}