import { kebabCase } from 'lodash'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/general/useOnClickOutside'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { ExportsActions } from '../../store/exports/actions'
import { ExportFileFormats, ExportFormatFileTypeToText } from '../../store/exports/types'
import { Icon } from '../icons/icon'
import { Flex } from '../layout/flex'
import { Floater } from '../layout/floater'
import { FloaterInset } from '../layout/floaterInset'
import { Spacer } from '../layout/spacer'
import { ButtonInset } from './buttonInset'
import { SimpleButton } from './simpleButton'

export const ExportButton: React.FC<{
    resourceLink: string
    availableFormats: ExportFileFormats[]
    overrideText?: string
}> = ({ availableFormats, overrideText, resourceLink }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [ref, setRef] = useRefTaker()
    const [holderRef, setHolderRef] = useRefTaker()
    useOnClickOutside(
        holderRef,
        useCallback(() => {
            setShow(false)
        }, [])
    )

    const onClick = useCallback(
        (format) => {
            return () => {
                dispatch(ExportsActions.TRIGGER_EXPORT(resourceLink, format))
                setShow(false)
            }
        },
        [resourceLink, dispatch]
    )

    return (
        <Holder ref={setHolderRef}>
            <div ref={setRef}>
                <SimpleButton
                    cy={kebabCase(overrideText?.toLowerCase() || 'export')}
                    color="front.accent.color"
                    onClick={() => {
                        setShow(true)
                    }}
                >
                    <Flex align="center">
                        {overrideText || 'Export'}
                        <Spacer width={7} />
                        <Icon type="export" size={15} />
                    </Flex>
                </SimpleButton>
            </div>
            <Floater anchor={ref} shouldShow={show}>
                <FloaterInset equalPadding>
                    <Flex>
                        {availableFormats?.map((f) => (
                            <ButtonHolder key={f}>
                                <SimpleButton
                                    key={f}
                                    cy={kebabCase(ExportFormatFileTypeToText(f))}
                                    background="floating.subtleAccent.background"
                                    onClick={onClick(f)}
                                >
                                    <ButtonInset>{ExportFormatFileTypeToText(f)}</ButtonInset>
                                </SimpleButton>
                            </ButtonHolder>
                        ))}
                    </Flex>
                </FloaterInset>
            </Floater>
        </Holder>
    )
}

const Holder = styled.div`
    position: relative;
`

const ButtonHolder = styled.div`
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }
`
