import React, { useEffect } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { TableLike } from '../../../components/forms/dropdownSelectableList'
import { Spacer } from '../../../components/layout/spacer'
import { useGateways } from '../../../hooks/general/useGateways'
import { ContractTemplateActions } from '../../../store/contractTemplates/actions'
import { RootState } from '@/store'

export const usePricingSchemes = (
    currency?: string
): [
    templates: RootState['contractTemplates'],
    config: {
        items: string[]
        textForItem: any
        tableLike: TableLike
    }
] => {
    const dispatch = useDispatch()
    const templates = useSelector((state: RootState) => state.contractTemplates)
    const gateways = useGateways()

    useEffect(() => {
        if (templates.loadingStatus !== 'started' && templates.loadingStatus !== 'done')
            dispatch(ContractTemplateActions.FETCH_ALL())
    }, [dispatch, templates])

    const config = useMemo(() => {
        const allItems = templates.all
        const filteredItems = allItems.filter((id) => {
            if (!currency) return true
            if (!templates.at[id].currency) return true
            if (templates.at[id].currency === currency) return true
            return false
        })

        return {
            items: filteredItems,
            textForItem: (id: string, shouldExpand?: boolean) => {
                if (!id) return undefined
                if (shouldExpand)
                    return (
                        <React.Fragment key={id}>
                            <SelectionTableCell>
                                {templates.at[id].label}
                                <Spacer width={10} />
                            </SelectionTableCell>
                            <SelectionTableCell>
                                {templates.at[id].gatewayId ? gateways.at?.[templates.at[id].gatewayId!]?.name : '-'}
                                <Spacer width={10} />
                            </SelectionTableCell>
                            <SelectionTableCell>
                                {templates.at[id].currency || '-'}
                                <Spacer width={10} />
                            </SelectionTableCell>
                            <SelectionTableCell>-</SelectionTableCell>
                        </React.Fragment>
                    ) as any

                if (templates.at?.[id || '']?.label) return templates.at?.[id || '']?.label
                return undefined
            },
            tableLike: {
                fixedHeader: (
                    <span>
                        Showing only <b>{currency}</b> templates ({filteredItems.length}/{allItems.length})
                    </span>
                ),
                headers: [' ', 'Name', 'Gateway', 'Currency', 'Standard'],
                layout: 'min-content min-content auto min-content min-content',
                labelForItem: (id: any) => templates.at?.[id]?.label || id
            }
        }
    }, [templates, currency, gateways])

    return [templates, config]
}

const DoubleCell = styled.div`
    grid-column: span 3;
`

const SelectionTableCell = styled.div`
    padding: 6px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
`
