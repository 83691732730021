import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { Text } from '../../../components/general/text'
import { Flag } from '../../../components/icons/flag'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { ApplicationDataProvidersActions } from '../../../store/applicationDataProviders/actions'
import { DataPorviderStateAssignedAndReady } from '../../../store/applicationDataProviders/types'
import { WatcherButton } from '@/components/buttons/watcherButton'
import { RootState } from '@/store'

export const ApplicationDataProviderPanelAssigned: React.FC<{ applicationId: string }> = ({ applicationId }) => {
    const data = useSelector(
        (state: RootState) => state.dataProviders.forApplication?.[applicationId] as DataPorviderStateAssignedAndReady
    )
    const dispatch = useDispatch()

    if (data.state !== 'assigned-and-ready') {
        return <span>Loading</span>
    }

    const sotData = data.linked.company
    const conflictsStatus = data.computed.conflictsStatus

    return (
        <Holder hasConflicts={conflictsStatus === 'has-conflicts' || conflictsStatus === 'has-conflicts-some-muted'}>
            <Content>
                <Flex grow justify="space-between" column>
                    <Flex>
                        <Flag code={sotData.country} />
                        <Spacer width={10} />
                        <Text oneLine bold>
                            {sotData.name}
                        </Text>
                    </Flex>
                    <Spacer width={4} />
                    <Flex>
                        <Spacer width={25} />
                        <Text
                            color={
                                conflictsStatus === 'has-conflicts' || conflictsStatus === 'has-conflicts-some-muted'
                                    ? 'front.subtleDanger.text'
                                    : 'back.text.strongerI'
                            }
                        >
                            {sotData.registrationNumbers?.join(', ')}
                        </Text>
                    </Flex>
                </Flex>
                {conflictsStatus !== 'no-conflicts' ? (
                    <>
                        <Spacer height={5} />
                        <Text
                            color={
                                conflictsStatus === 'has-conflicts' || conflictsStatus === 'has-conflicts-some-muted'
                                    ? 'front.subtleDanger.text'
                                    : 'back.text.strongerI'
                            }
                        >
                            {sotData.addressLine1}
                        </Text>
                    </>
                ) : null}
                <Spacer height={10} />
                {data.state === 'assigned-and-ready' ? (
                    <>
                        {conflictsStatus !== 'no-conflicts' ? (
                            <WatcherButton
                                background={'front.background'}
                                color={conflictsStatus !== 'all-muted' ? 'front.danger.color' : 'front.accent.color'}
                                onClick={(e, watcherId) => {
                                    dispatch(ApplicationDataProvidersActions.UNLINK(applicationId, watcherId))
                                }}
                            >
                                <ButtonInset>Link another company</ButtonInset>
                            </WatcherButton>
                        ) : (
                            <Flex justify="space-between" grow>
                                <Text color="back.text">No conflicts found</Text>

                                <WatcherButton
                                    onClick={(e, watcherId) => {
                                        dispatch(ApplicationDataProvidersActions.UNLINK(applicationId, watcherId))
                                    }}
                                >
                                    Link another company
                                </WatcherButton>
                            </Flex>
                        )}
                    </>
                ) : (
                    <TextLineLoader />
                )}
            </Content>
            {conflictsStatus === 'has-conflicts' || conflictsStatus === 'has-conflicts-some-muted' ? (
                <ConflictsBar hasConflicts={true}>
                    <Flex>
                        <Icon type="warning" />
                        <Spacer width={5} />
                        Conflicts found
                    </Flex>
                    <LinkButton
                        background="front.danger.color.subtlerII"
                        to={`/merchant/${applicationId}/application/conflicts`}
                    >
                        <ButtonInset>See all conflicts</ButtonInset>
                    </LinkButton>
                </ConflictsBar>
            ) : undefined}
            {conflictsStatus === 'all-muted' ? (
                <ConflictsBar hasConflicts={false}>
                    <Flex>
                        <Icon type="warning" />
                        <Spacer width={5} />
                        <Text>Conflicts exist, but have been muted</Text>
                    </Flex>
                    <LinkButton background="front.background" to={`/merchant/${applicationId}/application/conflicts`}>
                        <ButtonInset>See all conflicts</ButtonInset>
                    </LinkButton>
                </ConflictsBar>
            ) : undefined}
        </Holder>
    )
}

const Holder = styled.div<{ hasConflicts: boolean }>`
    margin: -25px;
    background-color: ${(p) => p.theme['front.subtleInfo.background']};
    overflow: hidden;
    border-radius: 10px;
    background-color: ${(p) => p.theme['back.background.strongerI']};

    ${(p) =>
        p.hasConflicts
            ? css`
                  background-color: ${(p) => p.theme['front.subtleDanger.background']};
              `
            : ''}
`

const ConflictsBar = styled.div<{ hasConflicts: boolean }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;
    align-items: center;
    background-color: ${(p) => p.theme['front.subtleAccent.background.strongerI']};
    color: ${(p) => p.theme['front.subtleAccent.text']};

    ${(p) =>
        p.hasConflicts
            ? css`
                  background-color: ${(p) => p.theme['front.danger.color']};
                  color: ${(p) => p.theme['front.danger.text']} !important;
              `
            : ''}
`

const Content = styled.div`
    padding: 20px;
`
