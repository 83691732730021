import { all, spawn } from '@redux-saga/core/effects'
import { ActionType, getType } from 'deox'
import { put, take } from 'typed-redux-saga'

import { ApplicationInternalsCollaboratorsActions } from '../applicationInternals/collaborators/actions'
import { ApplicationInternalsRelatedActions } from '../applicationInternals/related/actions'
import { ApplicationInternalsSchemesScreeningActions } from '../applicationInternals/schemesScreening/actions'
import { ApplicationInternalsWarningsActions } from '../applicationInternals/warning/actions'
import { ApplicationLoadingActions } from '../applicationLoading/actions'
import { ApplicationsDispatchCleanup, ApplicationsDispatchLoadApplication } from '../applications/actions'
import { GatewaysDispatchLoad } from '../gateways/actions'
import { MerchantAccountsActions } from '../merchantAccounts/actions'
import { MerchantActions } from './actions'

export const MerchantSagas = {
    *LOAD({ payload: p }: ActionType<typeof MerchantActions.LOAD>) {
        const actions = [
            // Load available gateways
            put(GatewaysDispatchLoad()),

            // Called before, as it needs to wait for the loading of other resources
            put(ApplicationInternalsWarningsActions.GENERATE(p.id)),

            put(ApplicationsDispatchLoadApplication(p.id, 'full-application')),

            put(MerchantAccountsActions.FETCH(p.id)),
            put(ApplicationInternalsCollaboratorsActions.LOAD(p.id)),
            put(ApplicationInternalsRelatedActions.LOAD(p.id)),
            put(ApplicationInternalsSchemesScreeningActions.FETCH_INQUIRIES(p.id)),
            spawn(function* () {
                const accountsRequest = yield* take(getType(MerchantAccountsActions.STORE_ACCOUNTS_WITHOUT_CONTRACTS))
                const accounts = (accountsRequest as any).payload.accountsWithContracts
                const accountIds = accounts.map((acc: any) => acc.id)
                yield put(ApplicationLoadingActions.LOAD_TASKS_COUNT(p.id, accountIds))
            })
        ]

        yield all(actions)
    },
    *CLEANUP({ payload: p }: ActionType<typeof MerchantActions.CLEANUP>) {
        yield put(ApplicationsDispatchCleanup(p.id))
    }
}
