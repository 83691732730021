import React, { useMemo, useEffect } from 'react'
import { FormProvider as ReactHookFormProvider, useForm } from 'react-hook-form'
import { Classification, UpdateClassification, useUpdateClassification } from '@/services/risk-classification'
import { debounce } from 'lodash'
import { getDefaultValues } from './default-values'

interface FormProviderProps {
    children: React.ReactNode
    data: Classification
}

export const FormProvider: React.FC<FormProviderProps> = (props) => {
    const { children, data } = props

    const [updateClassification] = useUpdateClassification()

    const defaultValues = getDefaultValues(data)

    const methods = useForm<UpdateClassification>({
        defaultValues: { ...defaultValues }
    })

    const { watch, getValues } = methods

    const debounceUpdate = useMemo(
        () =>
            debounce(() => {
                updateClassification({ classificationId: data.id, data: getValues() })
            }, 500),
        [updateClassification, data.id, getValues]
    )

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name === 'comment') {
                debounceUpdate()
                return
            }

            updateClassification({ classificationId: data.id, data: getValues() })
        })

        return () => subscription.unsubscribe()
    }, [watch, debounceUpdate, updateClassification, data.id, getValues])

    return <ReactHookFormProvider {...methods}>{children}</ReactHookFormProvider>
}
