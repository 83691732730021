import React from 'react'
import { useDispatch } from 'react-redux'
import { WatcherButton } from '../../../../../../../components/buttons/watcherButton'
import { Icon } from '../../../../../../../components/icons/icon'
import { Flex } from '../../../../../../../components/layout/flex'
import { Offset } from '../../../../../../../components/layout/offset'
import { TasksCompanyChangesActions } from '../../../../../../../store/tasksCompanyChanges/actions'

interface RefreshApplicationButtonProps {
    taskId: string
    applicationId?: string
}

export const RefreshApplicationButton: React.FC<RefreshApplicationButtonProps> = (props) => {
    const { taskId, applicationId } = props

    const dispatch = useDispatch()

    const onRefreshApplicationClick = (_, watcherId) => {
        if (!applicationId) {
            return
        }

        dispatch(TasksCompanyChangesActions.REFRESH_APPLICATION(watcherId, taskId, applicationId))
    }

    if (!applicationId) {
        return null
    }

    return (
        <WatcherButton onClick={onRefreshApplicationClick}>
            <Flex>
                <Offset left={-5} top={2}>
                    <Icon type="refresh" size={14} />
                </Offset>
                Refresh application data
            </Flex>
        </WatcherButton>
    )
}
