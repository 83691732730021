import moment from 'moment'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Account } from '../../../components/general/account'
import { Flex } from '../../../components/layout/flex'

import { useMerchantApplicationNameSelector } from './useMerchantApplicationFieldNameSelector'
import { UseMerchantApplicationFieldResourceSelector } from './useMerchantApplicationFieldResourceSelector'
import { MerchantApplicationEditsModalValue } from './Application.EditsValue'
import { CardInset } from '../../../components/cards/cardInset'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { LoaderView } from '../../../components/loaders/loader'
import { useFieldSidebarScrollToLatest } from './useFieldSidebarScrollToLatest'
import { useModalStackSync } from '../../../hooks/general/useModalStackSync'
import { useLocation } from 'react-use'
import { useHotkey } from '../../../hooks/hotkeys/useHotkey'
import { useNavigate, useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantApplicationEditsModal: React.FC = () => {
    const params = useParams() as Params
    const { id } = params
    const { setLastPoint } = useFieldSidebarScrollToLatest()

    const { indexPath } = useMerchantApplicationNameSelector(params)
    const { loadingStatus, resources: edits } = UseMerchantApplicationFieldResourceSelector(params, 'edits')
    useModalStackSync(null, 'Merchant.ApplicationPage.FieldEdits', undefined)

    const navigate = useNavigate()
    const location = useLocation()

    useHotkey({
        keys: 'escape, alt+escape',
        scope: 'Merchant.ApplicationPage.FieldEdits',
        action: useCallback(() => {
            navigate({
                pathname: `/merchant/${id}/application`,
                search: location.search
            })
        }, [navigate, id, location])
    })

    if (loadingStatus !== 'done') return <LoaderView overBackground="front.background" />

    if (!edits) return null
    if (!indexPath) return null

    return (
        <Holder>
            <CardInset noVerticalPadding>
                {edits.map?.((c: any) => {
                    return (
                        <>
                            <Spacer height={20} />
                            <Flex key={c.id} column>
                                <ValueHolder data-cy="value-to">
                                    <MerchantApplicationEditsModalValue
                                        applicationId={id}
                                        indexPath={indexPath}
                                        value={c.to}
                                    />
                                </ValueHolder>
                                <Spacer height={3} />
                                <Flex justify="space-between" wrap>
                                    <Text color="front.text">{moment(c.at).format('D MMM YYYY, HH:mm:ss')}</Text>
                                    <Text color="front.text">
                                        <Text color="front.text.subtlerI">made by</Text> <Account id={c.by} />
                                    </Text>
                                </Flex>
                            </Flex>
                        </>
                    )
                })}
                <Spacer height={100} />
                <div ref={setLastPoint} key={JSON.stringify(params)} />
            </CardInset>
        </Holder>
    )
}

const Holder = styled.div`
    flex-grow: 1;
`

const ValueHolder = styled.div`
    padding: 0;
    font-weight: 500;
    color: ${(p) => p.theme['front.text']};
`
