import { createActionCreator } from 'deox'
import { BVDUISize } from './types'

const Prefix = 'BVD'

export const BVDActions = {
    FETCH_BVD: createActionCreator(
        `${Prefix}/FETCH_BVD`,
        (resolve) => (watcherId: string, applicationId: string, bvdId: string) =>
            resolve({ watcherId, applicationId, bvdId })
    ),
    STORE_BVD: createActionCreator(`${Prefix}/STORE_BVD`, (resolve) => (applicationId: string, bvd: string) =>
        resolve({ applicationId, bvd })
    ),
    UNSTORE_BVD: createActionCreator(`${Prefix}/UNSTORE_BVD`, (resolve) => (applicationId: string) =>
        resolve({ applicationId })
    ),
    SWITCH_UI: createActionCreator(`${Prefix}/SWITCH_UI`, (resolve) => (applicationId: string, to: BVDUISize) =>
        resolve({ applicationId, to })
    )
}
