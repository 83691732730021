import { Card, Grid } from '@/design-system'
import React from 'react'

interface IntegrationsCardProps {
    children: React.ReactNode
}

const IntegrationsCard: React.FC<IntegrationsCardProps> = (props) => {
    const { children } = props

    return (
        <Card>
            <Card.Body>
                <Grid
                    customColumns="0.5fr minmax(20px, auto) minmax(20px, auto) 0.5fr"
                    gap="x-24"
                    overflow="y-scroll"
                    className="scrollbar-hidden"
                >
                    {children}
                </Grid>
            </Card.Body>
        </Card>
    )
}

export { IntegrationsCard }
