import moment, { Moment } from 'moment'
import React, { ReactElement, useCallback } from 'react'

import { DateFormats } from '../../utils/dateUtils'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'
import { findTimeZone, getUnixTime, getZonedTime } from 'timezone-support'
import { DateSelector } from '../forms/dateSelector'

const utc = findTimeZone('Etc/UTC')

export function ListPageFilterDate<T>({
    filters,
    filterKey,
    isDark,
    timeSupport
}: {
    filters: FiltersController<T>
    filterKey: keyof T
    isDark: boolean
    timeSupport?: boolean
}): ReactElement {
    const toUTC = useCallback((date: Moment) => {
        return moment.utc(getUnixTime(getZonedTime(date.toDate(), utc)))
    }, [])
    const onSelect = useCallback(
        (date?: Moment) => {
            if (!date || !toUTC(date)) return
            let resultString = ''
            if (timeSupport) {
                resultString = toUTC(date).format('YYYY-MM-DDTHH:mm:ss')
            } else {
                resultString = toUTC(date).format('YYYY-MM-DD')
            }
            filters.set((prevState) => ({
                ...prevState,
                [filterKey]: resultString
            }))
        },
        [filters, filterKey, timeSupport, toUTC]
    )

    const textForItem = useCallback(
        (item: any) => {
            if (timeSupport) return moment.utc(item).format()
            return moment.utc(item).format(DateFormats.fullDay(moment(item)))
        },
        [timeSupport]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <DateSelector
                overBackground={isDark ? 'floating.background' : 'side.background'}
                preselectedDate={filters.get[filterKey]}
                hasTimeSupport={timeSupport}
                onSelect={onSelect}
                isSeamless
            />
            <SelectedListForListPageFilters<T>
                isDark={isDark}
                filterKey={filterKey}
                noHolderEmphasize={isDark}
                filters={filters}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
