import React, { useCallback, useEffect, useState } from 'react'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { WatcherButton } from '../buttons/watcherButton'
import { Flex } from '../layout/flex'
import { MarkdownHolder } from '../general/markdownHolder'
import { Separator } from '../layout/separator'
import { Spacer } from '../layout/spacer'
import { TextareaInput } from '../forms/textareaInput'

export const UserCommentEdit: React.FC<{
    editedCommentBody: string
    onEdit?: (val: string) => void
    watcherId?: string
    onCancel?: () => void
}> = ({ editedCommentBody, onEdit, watcherId, onCancel }) => {
    const [textarea, setTextarea] = useRefTaker()
    const [body, setBody] = useState(editedCommentBody)
    const [watcher] = useNamedWatcher(watcherId)

    useEffect(() => {
        if (watcher === 'success') onCancel?.()
    }, [watcher, onCancel])

    useEffect(() => {
        textarea?.focus()
    }, [textarea])

    const handleCancelClicked = useCallback(() => {
        onCancel?.()
    }, [onCancel])

    const handleEditClicked = useCallback(() => {
        onEdit?.(body)
    }, [onEdit, body])

    return (
        <Flex align="stretch" column>
            <b>Previous comment:</b>
            <MarkdownHolder>{editedCommentBody}</MarkdownHolder>
            <Spacer height={20} />
            <Separator />
            <Spacer height={20} />
            <b>New comment (Preview):</b>
            <MarkdownHolder>{body}</MarkdownHolder>
            <Spacer height={20} />
            <TextareaInput
                overBackground="front.background"
                textareaRef={setTextarea}
                cy="user-comment-edit-input"
                placeholder="Enter your edited comment"
                onChange={(e, v) => setBody(v)}
            />
            <Spacer height={20} />
            <Flex justify="flex-end" align="center">
                <SimpleButton background="front.subtleAccent.text" onClick={handleCancelClicked}>
                    <ButtonInset>Cancel</ButtonInset>
                </SimpleButton>
                <Spacer width={10} />
                <WatcherButton
                    background="front.accent.color"
                    cy="save-edits"
                    predefinedWatcher={watcherId}
                    onClick={handleEditClicked}
                >
                    <ButtonInset>Save</ButtonInset>
                </WatcherButton>
            </Flex>
        </Flex>
    )
}
