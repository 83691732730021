import { createReducer } from 'deox'
import { produce } from 'immer'
import dotProp from 'dot-prop'
import { InitialApplicationInternalHarvesterState } from './types'
import { ApplicationInternalsHarvesterActions } from './actions'
import { ApplicationInternalsActions } from '../actions'

export const ApplicationInternalsHarvesterReducer = createReducer(
    InitialApplicationInternalHarvesterState,
    (handleAction) => [
        handleAction(ApplicationInternalsHarvesterActions.BOOTSTRAP, (state, { payload: p }: any) => {
            return produce(state, (data: any) => {
                dotProp.set(data, `forApplication.${p.applicationId}.loadingStatus`, 'started')
            })
        }),
        handleAction(ApplicationInternalsHarvesterActions.SET_ALL_DONE, (state, { payload: p }: any) => {
            return produce(state, (data: any) => {
                dotProp.set(data, `forApplication.${p.applicationId}.loadingStatus`, 'done')
            })
        }),
        handleAction(ApplicationInternalsHarvesterActions.STORE_ISSUES, (state, { payload: p }: any) => {
            return produce(state, (data: any) => {
                dotProp.set(
                    data,
                    `forApplication.${p.applicationId}.issuesBySection.${p.sectionKey}.${p.subsectionIndex}`,
                    p.issues
                )
            })
        }),
        handleAction(ApplicationInternalsHarvesterActions.STORE_ADDITIONAL_INFO, (state, { payload: p }: any) => {
            return produce(state, (data: any) => {
                dotProp.set(
                    data,
                    `forApplication.${p.applicationId}.additionalInfoBySection.${p.sectionKey}.${p.subsectionIndex}`,
                    p.info
                )
            })
        }),
        handleAction(ApplicationInternalsHarvesterActions.STORE_CHECKS, (state, { payload: p }: any) => {
            return produce(state, (data: any) => {
                dotProp.set(
                    data,
                    `forApplication.${p.applicationId}.checksBySection.${p.sectionKey}.${p.subsectionIndex}`,
                    p.checks
                )
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = { ...InitialApplicationInternalHarvesterState }
                return draft
            })
        })
    ]
)
