import styled, { css } from 'styled-components'
import { ButtonInset } from '../buttons/buttonInset'
import { Text } from '../general/text'

export const DropdownListNotFoundItem: React.FC<{ span?: number }> = ({ span }): JSX.Element => {
    return (
        <Holder span={span}>
            <ButtonInset padding="small">
                <Text color="floating.text.subtlerI">No items found</Text>
            </ButtonInset>
        </Holder>
    )
}

const Holder = styled.div<{ span?: number }>`
    ${(p) =>
        p.span
            ? css`
                  grid-column: span ${p.span};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 10px;
              `
            : css`
                  display: contents;
              `}
`
