import { createActionCreator } from 'deox'

import { MerchantWarning, MerchantAccountWarningCheckTypes } from './types'

const Prefix = 'WARNINGS'

export const ApplicationInternalsWarningsActions = {
    GENERATE: createActionCreator(`${Prefix}/GENERATE`, (resolve) => (merchantId: string) => resolve({ merchantId })),
    CLEAR: createActionCreator(`${Prefix}/CLEAR`, (resolve) => (merchantId: string) => resolve({ merchantId })),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (merchantId: string, check: MerchantAccountWarningCheckTypes, warnings: MerchantWarning[]) =>
            resolve({ merchantId, check, warnings })
    )
}
