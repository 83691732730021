import { Controller, useFormContext } from 'react-hook-form'

import React from 'react'
import { Text } from '@/components/general/text'
import { TextareaInput } from '@/components/forms/textareaInput'

interface FormTextareaProps {
    name: string
    placeholder: string
    label?: string
    disabled?: boolean
}

export const FormTextarea: React.FC<FormTextareaProps> = (props) => {
    const { name, placeholder, label, disabled = false } = props

    const { control } = useFormContext()

    return (
        <>
            {label && <Text>{label}</Text>}
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, onBlur, value } }) => (
                    <TextareaInput
                        isDisabled={disabled}
                        initialValue={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        overBackground="front.background"
                        minHeight={150}
                    />
                )}
            />
        </>
    )
}
