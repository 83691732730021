import { HALClientRequest } from '@/config/ketting'
import { RootState } from '@/store'
import { Url } from './types'
import UrlParse from 'url-parse'

type Method = 'GET' | 'POST' | 'PATCH' | 'DELETE'

interface Params {
    query?: string
    page?: number
    per_page?: number
}

interface Args {
    url: Url
    params?: Params
    method?: Method
    body?: object
}

export const halBaseQuery =
    () =>
    async <TData, TRelations, TEmbedded>(args: Args, api) => {
        const { url, params = {}, method, body } = args

        try {
            const { auth } = api.getState() as RootState
            const { token } = auth

            const uri = new UrlParse(url, true)

            uri.set('query', { ...params })

            const request = new HALClientRequest<TData, TRelations, TEmbedded>(uri.toString(), {
                token
            })

            let response
            switch (method) {
                case 'GET':
                    response = await request.get()
                    break
                case 'POST':
                    response = await request.post(body)
                    break
                case 'PATCH':
                    response = await request.patch(body)
                    break
                case 'DELETE':
                    response = await request.delete()
                    break
                default:
                    response = await request.get()
            }

            return {
                data: {
                    ...response.repr,
                    relations: response.relations,
                    embedded: response.embeddedResources
                }
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            const status = {
                status: e.status
            }

            const data = {
                error: e.name,
                statusCode: e.status,
                message: e.message
            }

            return { error: { status, data } }
        }
    }
