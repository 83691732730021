import { Contract } from './types'

export const CONTRACTS_LOAD_CONTRACTS = 'CONTRACTS_LOAD_CONTRACTS'
export const CONTRACTS_STORE_CONTRACTS = 'CONTRACTS_STORE_CONTRACTS'

//--
export interface ContractsActionLoadContracts {
    type: typeof CONTRACTS_LOAD_CONTRACTS
    applicationId: string
}
export interface ContractsActionStoreContracts {
    type: typeof CONTRACTS_STORE_CONTRACTS
    applicationId: string
    contracts: Contract[]
}

//---

export const ContractsDispatchLoadContracts = (
    applicationId: string
): ContractsActionLoadContracts => {
    return { type: CONTRACTS_LOAD_CONTRACTS, applicationId }
}
export const ContractsDispatchStoreContracts = (
    applicationId: string,
    contracts: Contract[]
): ContractsActionStoreContracts => {
    return { type: CONTRACTS_STORE_CONTRACTS, applicationId, contracts }
}

export interface SagasForContracts {
    loadContracts(action: ContractsActionLoadContracts): void
}

export type ReducedContractsActions =
    | ContractsActionLoadContracts
    | ContractsActionStoreContracts
