import countries from 'i18n-iso-countries'
import { camelCase } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Application } from '../../store/applications/types'
import { formatWebsites } from '../../utils/formatting'
import { Account } from '../general/account'
import { ButtonInset } from '../buttons/buttonInset'
import { LinkButton } from '../buttons/linkButton'
import { LinkButtonArrow } from '../buttons/linkButtonArrow'
import { Expander } from '../layout/expander'
import { Flex } from '../layout/flex'
import { List, ListCustomNodeProps } from '../layout/list'
import { LoaderView } from '../loaders/loader'
import { Separator } from '../layout/separator'
import { SpaceMaker } from '../layout/spaceMaker'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { BusinessModelBlock } from '../taskPages/businessModelBlock'
import { ListMerchantTags } from '../../pages/Merchant/MerchantSidebarButtonTags'
import { ExternalLink } from '../buttons/externalLink'
import { useGateways } from '../../hooks/general/useGateways'

interface MerchantDetailsProps {
    merchantId: string
    isLoading?: boolean
    application?: Application
    account?: any
}

export const MerchantDetailsSidebar: React.FC<MerchantDetailsProps> = ({
    merchantId,
    application,
    account,
    isLoading
}) => {
    const shouldStillShowLoader = !merchantId || !account || !application || isLoading
    const gateways = useGateways()
    const gatewaysIndex = gateways.at

    const renderedRelatedLinks = useMemo(() => {
        const items: React.ReactNode[] = []

        if (application) {
            items.push(
                <LinkButton
                    color="side.accent.color"
                    target="apply"
                    cy="application"
                    key="application"
                    to={`/merchant/${application.id}`}
                >
                    <ButtonInset noVerticalPadding leftAlign negativeMargin="horizontal">
                        <LinkButtonArrow background="side.background" shouldBeBordered />
                        <Spacer width={5} />
                        Merchant's Summary
                    </ButtonInset>
                </LinkButton>
            )

            if (account) {
                items.push(
                    <LinkButton
                        color="side.accent.color"
                        target="blank"
                        key="transaction-rules"
                        to={`/merchant/${application.id}/accounts/${account.id}`}
                        cy="transaction-rules"
                    >
                        <ButtonInset leftAlign noVerticalPadding negativeMargin="horizontal">
                            <LinkButtonArrow background="side.background" shouldBeBordered />
                            <Spacer width={5} />
                            Transaction rules (Account)
                        </ButtonInset>
                    </LinkButton>
                )
            }
        }
        if (account?.merchantId) {
            items.push(
                <LinkButton
                    color="side.accent.color"
                    target="managerTransactions"
                    key="transactions"
                    to={`/transactions?transactions_mid=${account.merchantId}`}
                    cy="transactions"
                >
                    <ButtonInset noVerticalPadding leftAlign negativeMargin="horizontal">
                        <LinkButtonArrow background="side.background" shouldBeBordered />
                        <Spacer width={5} />
                        Transactions
                    </ButtonInset>
                </LinkButton>
            )
            items.push(
                <LinkButton
                    color="side.accent.color"
                    key="settlements"
                    target="managerSettlements"
                    to={`/settlements?settlements_mid=${account.merchantId}`}
                    cy="settlements"
                >
                    <ButtonInset leftAlign noVerticalPadding negativeMargin="horizontal">
                        <LinkButtonArrow background="side.background" shouldBeBordered />
                        <Spacer width={5} />
                        Settlements
                    </ButtonInset>
                </LinkButton>
            )
        }

        items.push(
            <LinkButton
                color="side.accent.color"
                target="tasks"
                key="tasks"
                to={`/alerts?tasks_subject_id=${merchantId}&tasks_subject_type=account`}
                cy="tasks-link"
            >
                <ButtonInset noVerticalPadding leftAlign negativeMargin="horizontal">
                    <LinkButtonArrow background="side.background" shouldBeBordered />
                    <Spacer width={5} />
                    Tasks
                </ButtonInset>
            </LinkButton>
        )

        return (
            <Flex column>
                <Spacer height={30} />
                <SpaceMaker>{items}</SpaceMaker>
            </Flex>
        )
    }, [merchantId, account, application])

    const renderedMerchantDetails = useMemo(() => {
        if (!account) return null

        const items = {
            'MID': {
                type: 'custom',
                node: <Account id={merchantId} merchant showOnlyMID />
            } as ListCustomNodeProps,
            'Merchant': account.name,
            ...(application?.tags
                ? {
                      Tags: {
                          type: 'custom',
                          node: <ListMerchantTags inSidebar tagsIds={application.tags.map((t) => t.id)} />
                      } as ListCustomNodeProps
                  }
                : {}),
            'Country': countries?.getName(account?.country?.toUpperCase(), 'en') || '',
            'Currency': account.currency,
            'Gateway': application?.gatewayId ? gatewaysIndex[application.gatewayId]?.name : '',
            'MCC': account.mcc,
            'MCC Brief': account.mccDescription,
            ' ': {
                type: 'custom',
                node: (
                    <Flex column align="stretch" grow>
                        <Spacer height={10} />
                        <Separator background="side.background" />
                        <Spacer height={10} />
                    </Flex>
                )
            } as ListCustomNodeProps,
            'Contact Name': application?.contact?.name,
            'Contact Phone': application?.contact?.phone,
            'Contact Email': application?.contact?.email
        }

        return (
            <List
                switchToRowsAt={10000}
                cellHorizontalTemplate="100px auto"
                wrap
                background="side.background"
                rowGap="tiny"
                items={items}
            />
        )
    }, [account, merchantId, application, gatewaysIndex])

    const websites = useMemo(() => {
        return formatWebsites(application?.websites?.map((w) => w.url))
    }, [application])

    const renderedApplicationDetails = useMemo(() => {
        if (!application) return null

        const getBusinessModel = () => {
            if (application.businessModel?.description) return application.businessModel?.description
            if (typeof application.businessModel === 'string') return application.businessModel as any
            return undefined
        }

        const shownWebsites: React.ReactNode[] = []
        const otherWebsites: React.ReactNode[] = []

        websites?.forEach((w: any, index: number) => {
            if (index < 5)
                shownWebsites.push(
                    <WebsiteWrapper key={camelCase(w.url)}>
                        <ExternalLink
                            url={w.url}
                            target="blank"
                            alwaysShowArrow
                            key={camelCase(w.url)}
                        >
                            {w.label}
                        </ExternalLink>
                    </WebsiteWrapper>
                )
            else
                otherWebsites.push(
                    <React.Fragment key={camelCase(w.url)}>
                        <ExternalLink
                            url={w.url}
                            defaultColor="floating.subtleAccent.text"
                            target="blank"
                            key={camelCase(w.url)}
                            alwaysShowArrow
                        >
                            <ButtonInset leftAlign>
                                {w.label}
                            </ButtonInset>
                        </ExternalLink>
                        <Separator background="overlay.background" />
                    </React.Fragment>
                )
        })

        const websitesList = (
            <>
                {shownWebsites}
                <WebsiteWrapper>
                    <Expander count={otherWebsites.length} listMemo={otherWebsites} insetButton />
                </WebsiteWrapper>
            </>
        )

        return (
            <>
                <Text color="side.text.subtlerI">
                    <BusinessModelBlock
                        businessModel={getBusinessModel()}
                        shortBusinessModel={application.shortBusinessModel}
                    />
                </Text>
                <Spacer width={15} />
                {application.websites && <TaskWebsites>{websitesList}</TaskWebsites>}
                <Spacer height={20} />
            </>
        )
    }, [application, websites])

    return (
        <>
            <Holder data-cy="merchant-sidebar">
                {shouldStillShowLoader ? (
                    <LoaderView overBackground="side.background" />
                ) : (
                    <Flex column align="stretch" style={{ flexGrow: 1 }}>
                        {(() => {
                            return (
                                <Flex column align="stretch">
                                    <HeaderHolder>
                                        <Text bold>
                                            <Account id={merchantId} merchant />
                                        </Text>
                                    </HeaderHolder>
                                    {renderedApplicationDetails}
                                    <Separator background="side.background" />
                                    <Spacer height={30} />
                                    {renderedMerchantDetails}
                                </Flex>
                            )
                        })()}
                        {renderedRelatedLinks}
                    </Flex>
                )}
            </Holder>
        </>
    )
}

const TagsHolder = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

const Holder = styled.div`
    width: 350px;
    height: 100vh;
    background-color: ${(p) => p.theme['side.background']};
    color: ${(p) => p.theme['side.text']};
    padding: 30px;
    position: sticky;
    padding-top: 23px;
    box-sizing: border-box;
    right: 0;
    flex-shrink: 0;
    top: 0;
    @media print {
        display: none;
    }
`

const HeaderHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    padding-top: 10px;
    padding-bottom: 0px;
`

const TaskWebsites = styled.div`
    display: inline-block;
    line-height: 21px;
`

const WebsiteWrapper = styled.div`
    margin-right: 8px;
    display: inline;
`
