import { Theme, getBorderForBackground } from '../../hooks/general/useTheme'
import styled, { css } from 'styled-components'

import { useMedia } from 'react-use'

export const Separator: React.FC<{
    type?: 'solid' | 'dashed'
    background?:
        | 'overlay.background'
        | 'front.background'
        | 'back.background'
        | 'back.background.strongerI'
        | 'side.background'
        | 'front.subtleInfo.background'
        | 'front.subtleSuccess.background'
        | 'front.subtleDanger.background'
        | 'floating.background'
        | 'side.border'
    vertical?: boolean
    switchToHorizontalAt?: number
    moreSpacing?: boolean
    offsetTop?: boolean
    hideAt?: number
}> = ({
    type = 'dashed',
    vertical,
    background = 'front.background',
    offsetTop,
    switchToHorizontalAt,
    hideAt,
    moreSpacing
}) => {
    const shouldBeHorizontal = useMedia(switchToHorizontalAt ? `(max-width: ${switchToHorizontalAt}px)` : '')
    const shouldHide = useMedia(hideAt ? `(max-width: ${hideAt}px)` : '(max-width: 0px)')

    return (
        <Holder moreSpacing={moreSpacing} vertical={vertical} offsetTop={offsetTop}>
            <Element
                type={type}
                offsetTop={offsetTop}
                vertical={vertical}
                background={background}
                shouldHide={shouldHide}
                moreSpacing={moreSpacing}
                shouldBeHorizontal={shouldBeHorizontal}
            />
        </Holder>
    )
}

const Holder = styled.div<{ vertical?: boolean; moreSpacing?: boolean; offsetTop?: boolean }>`
    height: 1px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    ${(p) =>
        p.moreSpacing &&
        css`
            height: 21px;
        `}

    ${(p) =>
        p.vertical &&
        css`
            height: 100%;
            width: 1px;
            flex-direction: row;

            ${p.moreSpacing &&
            css`
                width: 31px;
            `}
        `}
    ${(p) =>
        p.offsetTop &&
        css`
            position: relative;
            overflow: visible;
            z-index: 1;
            height: 0;
            ${p.moreSpacing &&
            css`
                height: 20px;
            `}
        `}
`

const Element = styled.div<{
    type?: 'solid' | 'dashed'
    vertical?: boolean
    shouldHide?: boolean
    moreSpacing?: boolean
    background: keyof Theme
    shouldBeHorizontal?: boolean
    offsetTop?: boolean
}>`
    height: 1px;
    width: 100%;

    ${(p) =>
        css`
            background: linear-gradient(
                90deg,
                ${p.theme[getBorderForBackground(p.background)]},
                ${p.theme[getBorderForBackground(p.background)]} 40%,
                transparent 40%,
                transparent 100%
            );
            background-size: 5px 1px;
        `}

    ${(p) =>
        p.type === 'solid' &&
        css`
            background: ${p.theme[getBorderForBackground(p.background)]};
        `}

    ${(p) =>
        p.background === 'overlay.background' &&
        css`
            background: rgba(255, 255, 255, 0.1);
        `}

    ${(p) =>
        p.shouldHide &&
        css`
            display: none;
        `}
    ${(p) =>
        p.shouldBeHorizontal &&
        css`
            height: 1px;
            width: 100%;
        `}
        
    ${(p) =>
        p.vertical &&
        css`
            height: 100%;
            width: 1px;
        `}

    ${(p) =>
        p.offsetTop &&
        css`
            top: 0px;
            position: absolute;
            ${p.moreSpacing &&
            css`
                top: 10px;
            `}
        `}
`
