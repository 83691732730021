import { ComponentProps } from 'react'
import styled, { css } from 'styled-components'
import { Color } from '../../styles/colors'
import { generateButtonStyles } from '../../utils/buttonStyles'

import { SimpleButton } from './simpleButton'

export function LabelButton({
    children,
    background,
    grow,
    color,
    htmlFor,
    cy
}: {
    children: any
    background?: ComponentProps<typeof SimpleButton>['background']
    htmlFor?: string
    color?: ComponentProps<typeof SimpleButton>['color']
    grow?: boolean
    cy?: string
}): JSX.Element {
    return (
        <Button data-cy={cy} grow={grow} htmlFor={htmlFor} background={background} color={color}>
            {children}
        </Button>
    )
}

const Button = styled.label<{
    grow?: boolean
    isPressed?: boolean
    background?: Color
    disabled?: boolean
    color?: Color
}>`
    cursor: pointer;
    ${(p) => generateButtonStyles(p.background, p.color, p.disabled, p.isPressed)}

    ${(p) =>
        p.grow &&
        css`
            flex-grow: 1;
            flex-basis: 0;
        `}
`
