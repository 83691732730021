import React, { useCallback, useEffect, useMemo } from 'react'
import { ModalPage } from '../../components/layout/modalPage'
import { ModalPageInset } from '../../components/layout/modalPageInset'
import { ModalHeader } from '../../components/modals/modalHeader'
import { useForm } from '../../hooks/general/useForm'
import { useNamedWatcherWithContext } from '../../hooks/general/useWatcher'
import { Spacer } from '../../components/layout/spacer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { LoaderView } from '../../components/loaders/loader'
import { FormHandler } from '../../components/forms/formHandler'
import { ContractTemplateActions } from '../../store/contractTemplates/actions'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { DedicatedSectionSeparator } from '../Merchant/Application/Application.DedicatedSection'
import { TextInput } from '../../components/forms/textInput'
import * as yup from 'yup'
import { useRouteGuard } from '../../hooks/general/useRouteGuard'
// eslint-disable-next-line max-len
import { MerchantAccountContractsSectionServiceCharges } from '../Merchant/Accounts/Accounts.ID.ContractSectionServiceCharges'
import { useLabelFocuser } from './More.ContractTemplates.Edit.useLabelFocuser'
import { MerchantAccountContractsSectionFees } from '../Merchant/Accounts/Accounts.ID.ContractSectionFees'
import { MerchantAccountContractsSectionPayout } from '../Merchant/Accounts/Accounts.ID.ContractSectionPayout'
import { MerchantAccountSectionExchangeFees } from '../Merchant/Accounts/Accounts.ID.ContractSectionExchangeFees'
import { MerchantAccountContractsSectionAdditional } from '../Merchant/Accounts/Accounts.ID.ContractSectionAdditional'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { ManageNewContractTemplateSectionGeneral } from './More.ContractTemplates.NewSectionGeneral'
import { CardInset } from '../../components/cards/cardInset'
import { Card } from '../../components/cards/card'
import styled from 'styled-components'
import { ContractTemplateFormFieldNaming } from '../../store/contractTemplates/helpers'
import { useContractFormHelpers } from '../Merchant/Accounts/useContractFormHelpers'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

type Params = { id: string }

export const ManageNewContractTemplate: React.FC = () => {
    const [watcher, watcherId] = useNamedWatcherWithContext(`SaveContractTemplate`)
    const {
        formRef,
        hasChanges,
        resetForm,
        fields,
        form,
        submitHandler,
        focusInvalidField,
        errors,
        executeBatchChanges
    } = useForm(watcher)

    const params = useParams() as Params
    const { id } = params
    const templates = useSelector((state: RootState) => state.contractTemplates)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { textForChange } = useContractFormHelpers(form.data.currency)
    useLabelFocuser(fields, form)

    useRouteGuard(
        'This contract template has NOT been saved. Are you sure you want to leave?',
        hasChanges && watcher !== 'success'
    )

    useEffect(() => {
        if (watcher === 'success')
            navigate({
                pathname: `/more/contract-templates`,
                search: location.search
            })
    }, [watcher, navigate, location])

    useEffect(() => {
        if (templates.loadingStatus === 'not-started') dispatch(ContractTemplateActions.FETCH_ALL())
    }, [templates, dispatch])

    const isEditing = useMemo(() => {
        if (!id) return false
        if (id === 'new') return false
        return true
    }, [id])

    const t = useMemo(() => {
        if (id && templates.at?.[id]) return templates.at?.[id]
        return undefined
    }, [templates, id])

    const suffix = useMemo(() => {
        if (form.data.currency) return form.data.currency
        return undefined
    }, [form])

    const onSubmit = useCallback(
        ({ changes, data }) => {
            if (isEditing) {
                if (!t) return
                if (!t.id) throw 'Contract template id not found.'
                dispatch(ContractTemplateActions.UPDATE(watcherId, t.id, t, changes))
            }
        },
        [dispatch, isEditing, watcherId, t]
    )

    const rightSideMemo = useMemo(() => {
        return (
            <FormHandler
                formSubmitLabel={isEditing ? 'Update contract template' : 'Save as a new contract template'}
                changes={form.changes}
                errors={errors}
                textForFieldName={ContractTemplateFormFieldNaming}
                focusInvalidField={focusInvalidField}
                textForChange={textForChange}
                submitForm={submitHandler(onSubmit)}
                submitWatcherId={watcherId}
                resetForm={resetForm}
            />
        )
    }, [isEditing, onSubmit, errors, textForChange, focusInvalidField, resetForm, form, watcherId, submitHandler])

    const backToUrl = useMemo(() => {
        if (location.search) {
            return `/more/contract-templates${location.search}`
        }

        return `/more/contract-templates`
    }, [location.search])

    if (!t) return null
    return (
        <ModalPage
            title="Edit contract template"
            pageId="Manage.ContractTemplates.New"
            backTo={backToUrl}
            noExitOnBackgroundClick
        >
            <ModalHeader
                title="Edit contract template"
                pageId="Manage.ContractTemplates.New"
                backTo={backToUrl}
                rightSideMemo={rightSideMemo}
            />
            <ModalPageInset>
                {templates.loadingStatus === 'done' ? (
                    <PageWrapper maxWidth="large">
                        <Flex align="center">
                            <Flex noShrink>
                                <Text noWrap color="overlay.text">
                                    Template label
                                </Text>
                            </Flex>
                            <Spacer width={10} />
                            <TextInput
                                validation={errors['label']}
                                ref={(ref) => formRef(ref, 'label', yup.string().required())}
                                overBackground="overlay.background"
                                initialValue={t.label}
                                cy="templateLabel"
                                placeholder="Contract template name"
                            />
                        </Flex>
                        <Spacer height={30} />
                        <Card title="Template settings">
                            <CardInset>
                                <ManageNewContractTemplateSectionGeneral
                                    t={t}
                                    errors={errors}
                                    executeBatchChanges={executeBatchChanges}
                                    formRef={formRef}
                                    suffix={suffix}
                                    currency={form.data.currency || t.currency}
                                />
                            </CardInset>
                        </Card>
                        <TemplateDefiner>- TEMPLATE DEFINED BELOW - </TemplateDefiner>
                        <DedicatedSectionSeparator title="PAYOUT & FEES" alignLeft />
                        <Flex align="stretch">
                            <MerchantAccountContractsSectionPayout
                                draftOrTemplate={t}
                                formErrors={errors}
                                formRef={formRef}
                                currency={form.data.currency || t.currency}
                            />
                            <MerchantAccountContractsSectionFees
                                draftOrTemplate={t}
                                formErrors={errors}
                                formRef={formRef}
                                currency={form.data.currency || t.currency}
                            />
                        </Flex>
                        <DedicatedSectionSeparator title="SERVICE CHARGES" alignLeft />
                        <MerchantAccountContractsSectionServiceCharges
                            draftOrTemplate={t}
                            formErrors={errors}
                            formRef={formRef}
                            currency={form.data.currency || t.currency}
                        />
                        <DedicatedSectionSeparator title="OTHER INFORMATION" alignLeft />
                        <Flex align="stretch">
                            <MerchantAccountSectionExchangeFees
                                draftOrTemplate={t}
                                formErrors={errors}
                                formRef={formRef}
                                currency={form.data.currency || t.currency}
                            />
                            <Spacer width={20} />
                            <MerchantAccountContractsSectionAdditional
                                draftOrTemplate={t}
                                formErrors={errors}
                                formRef={formRef}
                                currency={form.data.currency || t.currency}
                            />
                        </Flex>
                        <Spacer height={50} />
                    </PageWrapper>
                ) : (
                    <LoaderView overBackground="back.background" />
                )}
            </ModalPageInset>
        </ModalPage>
    )
}

const TemplateDefiner = styled.div`
    background-color: ${(p) => p.theme['overlay.background.strongerI']};
    color: ${(p) => p.theme['overlay.text.subtlerII']};
    padding: 5px 20px;
    margin-top: 30px;
    margin-bottom: 0px;
    border-radius: 10px 10px 0 0;
    display: flex;
    font-size: 10px;
    letter-spacing: 1px;
    align-items: center;
    justify-content: center;
    opacity: 0.66;
`
