import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Flex } from '../../components/layout/flex'
import { Floater } from '../../components/layout/floater'
import { Text } from '../../components/general/text'
import { FloaterInset } from '../../components/layout/floaterInset'
import { Table } from '../../components/tables/table'
import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { LinkButtonArrow } from '../../components/buttons/linkButtonArrow'
import { Spacer } from '../../components/layout/spacer'
import { ExternalLink } from '../../components/buttons/externalLink'

export const SearchHelpItem: React.FC<{
    label: string
    modifiers: { [key: string]: string | string[] }
}> = ({ label, modifiers }) => {
    const [holder, setHolder] = useRefTaker()
    const [ref, setRef] = useRefTaker()
    const [shouldShow, setShouldShow] = useState(false)
    const handleClick = useCallback(() => {
        setShouldShow((t) => !t)
    }, [setShouldShow])
    const handleHoverOut = useCallback(() => {
        setShouldShow((t) => false)
    }, [setShouldShow])

    useOnHoverOutside(holder, handleHoverOut)

    const rows = useMemo(() => {
        const renderNode = (k: string) => {
            if (modifiers[k].includes('ISO'))
                return (
                    <Flex justify="flex-start" align="center">
                        <ValueHolder>
                            <Value key={k}>{modifiers[k]}</Value>
                            <Spacer width={10} />
                            <ExternalLink
                                target="blank"
                                url="https://en.wikipedia.org/wiki/ISO_3166-1"
                                alwaysShowArrow
                            >
                                <Text size="s">See all the country codes</Text>
                            </ExternalLink>
                        </ValueHolder>
                    </Flex>
                )
            if ((modifiers?.[k] as any)?.map)
                return (
                    <ValueHolder>
                        {(modifiers[k] as string[]).map((t) => (
                            <Value key={t}>{t}</Value>
                        ))}
                    </ValueHolder>
                )
            return (
                <ValueHolder>
                    <Value key={k}>{modifiers[k]}</Value>
                </ValueHolder>
            )
        }

        return Object.keys(modifiers).map((k) => ({
            type: 'normal' as const,
            key: 'k',
            noAction: true,
            items: [
                {
                    node: <Label color="floating.text">{k}</Label>
                },
                {
                    node: renderNode(k)
                }
            ]
        }))
    }, [modifiers])

    return (
        <Holder ref={setHolder} key={label}>
            <SimpleButton onClick={handleClick} color="front.accent.color" buttonRef={setRef}>
                <ButtonInset noHorizontalPadding>{label}</ButtonInset>
            </SimpleButton>
            <Floater anchor={ref} shouldShow={shouldShow}>
                <FloaterInset>
                    <Table
                        background="floating.background"
                        cols={[
                            {
                                text: 'Modifier'
                            },
                            {
                                text: 'Input'
                            }
                        ]}
                        rows={rows}
                        type="narrow"
                        columnLayout="min-content min-content"
                    />
                </FloaterInset>
            </Floater>
        </Holder>
    )
}

const Holder = styled.div``
const Value = styled.div`
    background-color: ${(p) => p.theme['floating.background.strongerI']};
    padding: 0px 3px;
    margin: 2px;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
`
const ValueHolder = styled.div`
    width: 250px;
    display: flex;
    margin-left: -3px;
    flex-wrap: wrap;
`

const Label = styled.div`
    font-family: monospace;
    margin-top: 2px;
    color: ${(p) => p.theme['floating.text']};
    font-size: 12px;
    letter-spacing: -0.3px;
`
