import { RTKQuery } from '@/services/common'
import { MAPI_PARTNERS_ROOT, deletePartner } from '@/services/merchant-api'
import { redirect } from 'react-router-dom'

const action = async ({ params }) => {
    const { id } = params

    await RTKQuery.delete(deletePartner, `${MAPI_PARTNERS_ROOT}/${id}`)

    return redirect('/more/partners')
}

const PartnerRemove = { action }

export { PartnerRemove }
