import { MODAL_ID } from '../../components/modals/modalIds'
import { AvailableThemes, ThemeType } from '../../hooks/general/useTheme'

type SettlementsReserveCancellations = {
    type: 'settlements-reserve-cancellations' | 'ubo-added-manual-application-changes'
    context: any
}

export type CriticalFlow = SettlementsReserveCancellations
export interface InterfaceState {
    theme: ThemeType
    applications: {
        [applicationId: string]: {
            shouldReviewChanges?: boolean
            merchantCommentField?: {
                indexPathString: string
                subsectionId?: string
            }
            changes: {
                [fieldKey: string]: {
                    from: string
                    to: string
                }
            }
            steps: {
                'merchant-message': { isCancelled: boolean }
                'internal-note': { isCancelled: boolean }
                'remove-collaborator': { isSuggested: boolean; isCancelled: boolean; email?: string; selfLink?: string }
                'add-collaborator': { isSuggested: boolean; isCancelled: boolean; email?: string; name?: string }
            }
        }
    }
    modals: {
        stack: {
            modalId: MODAL_ID
            priority: number
            title: string
        }[]
        frontmost?: MODAL_ID
        size: number
    }
    criticalFlow?: CriticalFlow
    markdownPreview: {
        [key: string]: string
    }
    showInteractionsDate: boolean
    showKeyTips: boolean
}

export const InitialInterfaceState: InterfaceState = {
    theme: AvailableThemes.includes((localStorage.getItem('theme') as any) || '')
        ? (localStorage.getItem('theme') as ThemeType)
        : 'whiteTheme',
    applications: {},
    modals: {
        stack: [],
        frontmost: undefined,
        size: 0
    },
    markdownPreview: {},
    showInteractionsDate: false,
    showKeyTips: false
}
