import React, { useRef } from 'react'
import styled from 'styled-components'
import { Tooltip } from '../../components/general/tooltip'
import { Icon } from '../../components/icons/icon'

export const SettlementsCompletedWarning: React.FC = () => {
    const ref = useRef<any>(null)
    return (
        <Holder>
            <div ref={ref}>
                <Icon type="info" />
            </div>
            <Tooltip element={ref}>
                <TextHolder>
                    While the payout appears as completed on our side, it might still be
                    <br /> affected by bank holidays or other factors that are outside of our control.
                </TextHolder>
            </Tooltip>
        </Holder>
    )
}

const Holder = styled.div`
    color: ${(p) => p.theme['front.text.subtlerII']};
    cursor: help;
    margin-top: 2px;
    margin-left: 5px;
    transition: 0.2s ease color;

    &:hover {
        color: ${(p) => p.theme['front.text']};
    }
`

const TextHolder = styled.div`
    font-weight: 400;
    font-size: 12px;
`
