import { TransactionStatus } from './commonTypes'
import { Collection, CollectionWSeparateLoading, LoadingStatus } from '../../utils'

export const TransactionTypes = ['authorization', 'capture', 'credit', 'debit', 'refund', 'void']
export type TransactionType = (typeof TransactionTypes)[number]

export interface TransactionsFilters {
    transactions_type: TransactionType[]
    transactions_mid: string[]
    transactions_method: string[]
    transactions_scheme: string[]
    transactions_before: string
    transactions_last4: string
    transactions_after: string
    transactions_status: string[]
    transactions_custom_filters: string
    transactions_currency: string
    transactions_amount: string
    tql: string
}

export const TransactionsFiltersParams: TransactionsFilters = {
    transactions_custom_filters: '',
    transactions_mid: [],
    transactions_method: [],
    transactions_scheme: [],
    transactions_before: '',
    transactions_after: '',
    transactions_last4: '',
    transactions_type: [],
    transactions_status: [],
    transactions_currency: '',
    transactions_amount: '',
    tql: ''
}

export type PaymentMethod =
    | 'card'
    | 'token'
    | 'tokenized_card'
    | 'applepay'
    | 'googlepay'
    | 'mobilepayonline'
    | 'vipps'
    | 'moto'
    | 'samsungpay'
export const PaymentMethods = [
    'card',
    'applepay',
    'googlepay',
    'mobilepayonline',
    'vipps',
    'moto',
    'samsungpay',
    'token'
]

export interface TransactionSummary {
    'id': string
    'type': TransactionType
    'processedAt': string
    'amount': number
    'currency': string
    'textOnStatement': string
    'reference': string
    'threedSecure': string
    'accountId': string
    'recurring': boolean
    'fraud': boolean
    'settlement': boolean
    '3Dsecure': {
        status: string
        version: string
    }
    'paymentMethod': PaymentMethod
    'status': TransactionStatus
    'account': {
        merchantId: string
        name: string
        country: string
        mcc: string
        currency: string
        descriptor: string
    }
    'card': PaymentCard
}

export interface Transaction {
    'id': string
    'type': TransactionType
    'processedAt': string
    'amount': number
    'currency': string
    'textOnStatement': string
    'reference': string
    'threedSecure': string
    '3Dsecure': {
        status?: string
        version?: string
    }
    'accountId': string
    'recurring': boolean
    'arn': string
    'rrn': string
    'region': string
    'fraud': boolean
    'settlementLink': string
    'settlement': {
        id: string
        amountGross: number
        amountNet: number
        fees: number
        currency: string
    }
    'series': {
        type: 'recurring' | 'unscheduled'
    }
    'paymentMethod': PaymentMethod
    'status': TransactionStatus
    'account': {
        merchantId: string
        name: string
        country: string
        mcc: string
        currency: string
        descriptor: string
    }
    'trail': Transaction[]
    'extraDetails': {
        account: any
        application: any
        loadingStatus: LoadingStatus
    }
    'appendixLink': string
    'appendix': {
        data: any
        loadingStatus: LoadingStatus
    }
    'loadingStatus': LoadingStatus
    'card': PaymentCard
}

export interface PaymentCard {
    bin: number
    last4: number
    scheme: string
    type: string
    expireYear: number
    expireMonth: number
    country?: string
    segment?: string
}

export interface TransactionsState {
    transactions: CollectionWSeparateLoading<Transaction>
    summaries: Collection<TransactionSummary> & {
        nextLink?: string
        loadingNextStatus: LoadingStatus
        error?: string
        forQuery?: string
    }
}

export const InitialTransactionsState: TransactionsState = {
    transactions: {
        at: {},
        all: []
    },
    summaries: {
        at: {},
        all: [],
        nextLink: undefined,
        error: undefined,
        loadingStatus: 'not-started',
        loadingNextStatus: 'not-started'
    }
}

export const SupportedCurrencies = [
    'AED',
    'AFN',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BIF',
    'BMD',
    'BND',
    'BOB',
    'BRL',
    'BSD',
    'BTN',
    'BWP',
    'BYR',
    'BZD',
    'CAD',
    'CDF',
    'CHF',
    'CLP',
    'CNH',
    'CNY',
    'COP',
    'CRC',
    'CUP',
    'CVE',
    'CZK',
    'DJF',
    'DKK',
    'DOP',
    'DZD',
    'EEK',
    'EGP',
    'ERN',
    'ETB',
    'EUR',
    'FJD',
    'FKP',
    'GBP',
    'GEL',
    'GHS',
    'GIP',
    'GMD',
    'GNF',
    'GTQ',
    'GYD',
    'HKD',
    'HNL',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KMF',
    'KPW',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LAK',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRO',
    'MRU',
    'MUR',
    'MVR',
    'MWK',
    'MXN',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NIO',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SHP',
    'SLE',
    'SOS',
    'SRD',
    'SSP',
    'STD',
    'STN',
    'SVC',
    'SYP',
    'SZL',
    'THB',
    'TJS',
    'TMT',
    'TND',
    'TOP',
    'TRY',
    'TTD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'USD',
    'UYU',
    'UZS',
    'VEF',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XCD',
    'XOF',
    'XPF',
    'YER',
    'ZAR',
    'ZMK',
    'ZMW',
    'ZWG'
]
