import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '@/store'
import { MODAL_ID } from '../modals/modalIds'
import { Flex } from '../layout/flex'
import { MarkdownHolder } from './markdownHolder'
import { TimelineCard } from '../listPages/timelineCard'
import { LinkButton } from '../buttons/linkButton'
import { ButtonInset } from '../buttons/buttonInset'
import { Spacer } from '../layout/spacer'
import { LinkButtonArrow } from '../buttons/linkButtonArrow'
import { Text } from './text'

export const MarkdownPreviewItem: React.FC<{
    pageId: MODAL_ID
    renderer?: (child: any) => any
    showContainerCard?: boolean
    showHeader?: boolean
}> = ({ pageId, showContainerCard, renderer, showHeader }) => {
    const markdown = useSelector((state: RootState) => state.interface.markdownPreview[pageId])

    const content = useMemo(() => {
        if (renderer)
            return renderer(
                <Holder>
                    {showHeader && <Header>Message preview:</Header>}
                    <MarkdownHolder>{markdown}</MarkdownHolder>
                </Holder>
            )
        return (
            <Holder>
                {showHeader && (
                    <Header>
                        <Text bold>Message preview</Text>
                        <LinkButton
                            color="front.accent.color"
                            to="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                            target="blank"
                        >
                            <ButtonInset noHorizontalPadding>
                                Formatting guide
                                <Spacer width={5} />
                                <LinkButtonArrow background="front.background" shouldBeBordered />
                            </ButtonInset>
                        </LinkButton>
                    </Header>
                )}
                <MarkdownHolder>{markdown}</MarkdownHolder>
            </Holder>
        )
    }, [markdown, renderer, showHeader])

    if (!markdown) return null

    if (showContainerCard) return <TimelineCard key="markdown-preview">{content}</TimelineCard>

    return <Flex column>{content}</Flex>
}

const Header = styled.div`
    padding: 1px solid;
    border-bottom: 1px solid ${(p) => p.theme['front.border']};
    padding-bottom: 7px;
    margin-bottom: 10px;
    margin-top: -8px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-grow: 1;
`

const PreviewLabel = styled.div`
    color: ${(p) => p.theme['front.accent.text']};
    background-color: ${(p) => p.theme['front.accent.color']};
    padding: 1px 10px;
    font-size: 11px;
    top: 0;
    position: absolute;
    left: 0;
    border-bottom-right-radius: 3px;
`

const Holder = styled.div`
    display: contents;
`
