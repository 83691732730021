import { MerchantDataItem } from './merchant-data-item'
import { MerchantDataItem as MerchantDataItemType } from '../../../../types'
import React from 'react'
import { Spacer } from '@/components/layout/spacer'

interface MerchantDataItemsProps {
    data: MerchantDataItemType[]
}

export const MerchantDataItems: React.FC<MerchantDataItemsProps> = (props) => {
    const { data } = props

    return (
        <>
            {data.map((a) => {
                return (
                    <React.Fragment key={a.key}>
                        <MerchantDataItem title={a.title} value={a.value} />
                        <Spacer height={8} />
                    </React.Fragment>
                )
            })}
        </>
    )
}
