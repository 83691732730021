import { Text } from '../../../components/general/text'
import { DateFormats } from '@/utils/dateUtils'
import moment from 'moment'
import React from 'react'

interface ExpiresAtProps {
    expiresAt: string
}

export const ExpiresAt: React.FC<ExpiresAtProps> = (props) => {
    let { expiresAt } = props
    let date = expiresAt ? moment(expiresAt).format(DateFormats.day(moment(expiresAt))): '-'
    let isSubtle = expiresAt ? false : true
    if (moment(expiresAt).isBefore(moment(new Date()), 'D')) {
        date = `Expired (${date})`
        isSubtle = true
    }

    return <Text oneLine color={isSubtle ? 'front.text.subtlerI' : undefined}>{date}</Text>
}
