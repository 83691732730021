import { Flex, Text } from "@/design-system"
import { RootState } from "@/store"
import { UserAccount as AccountDatatype } from '../../../store/userAccounts/types'
import { UserAccountsDispatchGetAccount } from "@/store/userAccounts/actions"
import moment from "moment"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

export const ResourceHistoryHeader: React.FC<any> = (props) =>{
    const { title, resource, prefixMemo, textColor, textShade } = props
    const dispatch = useDispatch()
    const account: AccountDatatype = useSelector((state: RootState) => 
        state.userAccounts.at[resource.metadata.updatedBy]
    )

    useEffect(() => {
        if (resource.metadata.updatedBy && (!account || account.loadingStatus === 'not-started'))
            dispatch(UserAccountsDispatchGetAccount(resource.metadata.updatedBy, { isMerchant: false }))
    }, [resource, account, dispatch])

    return(
        <Flex gap="1.5" direction="column">
            <Flex align="center" gap="3.5">
                {prefixMemo}
                <Text weight="medium" size="lg" color={textColor} shade={textShade}>{title ? title : "-"}</Text>
            </Flex>
            <Text color={textColor} shade={textShade}>Deleted {account?.data.name ? 
                `· last updated ${moment(resource.metadata.updatedAt)
                    .format('D MMM, HH:mm')} by ${account?.data.name}` : ""}</Text>
        </Flex>
    )
}
