import { BusinessModel, Comment, Location, Others, OwnershipManagement } from '@/services/risk-classification'
import React from 'react'
import styled from 'styled-components'
import { FormField } from './form-field'

interface CheckFields {
    title: string
    checks: OwnershipManagement | BusinessModel | Comment | Location | Others
}

export const Field: React.FC<CheckFields> = (props) => {
    const { title, checks } = props

    const getOptions = (category) =>{
        const options : React.ReactElement [] = []
        
        for (const option in category) {
          options.push(
              <FormField
                name={option} 
                type={category[option].type} 
                placeholder={category[option].placeholder} 
                label={category[option].label}
              />
          )            
        }
  
        return options
      }

    return (
        <div>
            <LabelHolder>{title}</LabelHolder>
            <>
                {
                    getOptions(checks)
                }
            </>
        </div>
    )
}

const LabelHolder = styled.div`
    font-weight: 500;
    margin-bottom: 5px;
`
