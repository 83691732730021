import React from 'react'
import { ButtonInset } from '../../../../../components/buttons/buttonInset'
import { LinkButton } from '../../../../../components/buttons/linkButton'

interface ApplicationButtonProps {
    applicationId?: string
    applicationSection?: string
}

export const ApplicationButton: React.FC<ApplicationButtonProps> = (props) => {
    const { applicationId, applicationSection } = props

    if (!applicationId) {
        return null
    }

    const link = applicationSection
        ? `/merchant/${applicationId}/application#${applicationSection}`
        : `/merchant/${applicationId}/application`

    return (
        <LinkButton background="front.subtleAccent.background" target="managerApplications" to={link}>
            <ButtonInset>Open application</ButtonInset>
        </LinkButton>
    )
}
