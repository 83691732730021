import React from 'react'
import { Checkbox, Flex, Label } from '@/design-system'

interface AddNoteProps {
    onCheckedChange: (checked: boolean) => void
}

const AddNote: React.FC<AddNoteProps> = (props) => {
    const { onCheckedChange } = props

    return (
        <Flex gap="2" align="center">
            <Checkbox onCheckedChange={onCheckedChange} />
            <Label>Add note</Label>
        </Flex>
    )
}

export { AddNote }
