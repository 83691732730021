import { useEffect } from 'react'

export const useOnPropagatingDocumentClick = (action: () => void, tag: string, mount?: boolean): void => {
    useEffect(() => {
        if (!mount) return

        const click = (e: any) => {
            if (e.target.getAttribute('data-grammarly-part') || e.target.closest(`[data-grammarly-part]`)) return
            if (e.target.getAttribute('data-grammarly-shadow-root') || e.target.closest(`[data-grammarly-shadow-root]`))
                return
            if (e.target.getAttribute(`${tag}`) || e.target.closest(`[${tag}]`)) return
            action()
        }

        document.addEventListener('mouseup', click)
        return () => {
            document.removeEventListener('mouseup', click)
        }
    }, [action, tag, mount])
}
