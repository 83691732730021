import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { DisputeDispatchChangeDueDate } from '../../store/disputes/actions'
import { Dispute } from '../../store/disputes/types'
import { DateFormats } from '../../utils/dateUtils'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { ButtonLoadingCover } from '../../components/buttons/buttonLoadingCover'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Floater } from '../../components/layout/floater'
import { FloaterInset } from '../../components/layout/floaterInset'
import { List } from '../../components/layout/list'
import { Text } from '../../components/general/text'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { DateSelector } from '../../components/forms/dateSelector'

export const DisputesDueDateButton: React.FC<{ dispute: Dispute }> = ({ dispute }) => {
    const dispatch = useDispatch()
    const [shouldShowFloater, setShouldShowFloater] = useState(false)
    const [holder, setHolder] = useRefTaker()
    const [anchor, setAnchor] = useRefTaker()
    const [dateSelector, setDateSelector] = useRefTaker()
    const [date, setDate] = useState<Date | undefined>()
    const [dueDateChangeWatcher] = useNamedWatcher(`${dispute?.id}.ChangeDueDate`)
    const handleButtonClick = useCallback(() => {
        setShouldShowFloater(true)
    }, [setShouldShowFloater])

    useEffect(() => {
        if (dispute?.dueAt) setDate(moment(dispute.dueAt).toDate())
    }, [dispute])

    useOnHoverOutside(
        holder,
        useCallback(() => {
            setShouldShowFloater(false)
        }, [setShouldShowFloater])
    )

    useEffect(() => {
        setTimeout(() => {
            dateSelector?.focus?.()
        }, 1)
    }, [dateSelector])

    useEffect(() => {
        if (dueDateChangeWatcher === 'success') setShouldShowFloater(false)
    }, [dueDateChangeWatcher])

    const handleDateChange = useCallback(
        (momentDate?: Moment) => {
            dispatch(
                DisputeDispatchChangeDueDate(
                    dispute,
                    `${dispute?.id}.ChangeDueDate`,
                    momentDate ? momentDate.toISOString() : undefined
                )
            )
        },
        [dispatch, dispute]
    )

    const items = useMemo(() => {
        return {
            'Due date:': {
                type: 'custom' as const,
                node: (
                    <DateSelector
                        overBackground="floating.background"
                        onSelect={handleDateChange}
                        preselectedDate={date}
                        refTaker={setDateSelector}
                    />
                )
            }
        }
    }, [date, handleDateChange, setDateSelector])

    return (
        <Holder ref={setHolder}>
            <div ref={setAnchor}>
                <SimpleButton
                    background="front.danger.color"
                    hotkeysScope="DisputePage"
                    hotkeys="alt+d"
                    onClick={handleButtonClick}
                >
                    <ButtonInset>
                        <ButtonLoadingCover
                            background="front.danger.color"
                            shouldDisplayDirectly={dueDateChangeWatcher === 'started'}
                        >
                            <Text oneLine>
                                {date
                                    ? `Due on ${moment(date).format(DateFormats.fullDay(moment(date)))}`
                                    : 'Set due date'}
                            </Text>
                        </ButtonLoadingCover>
                    </ButtonInset>
                </SimpleButton>
            </div>
            <Floater
                cardId="DisputePage.DueDateSelector"
                shouldShow={shouldShowFloater}
                anchor={anchor}
                onHide={() => {
                    setShouldShowFloater(false)
                }}
            >
                <FloaterInset equalPadding>
                    <List items={items} background="floating.background" />
                </FloaterInset>
            </Floater>
        </Holder>
    )
}

const Holder = styled.div`
    position: relative;
`
