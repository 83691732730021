import React, { ComponentProps, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { WatcherState } from '../../store/watcher/types'
import { SlimLoader } from '../loaders/loader'
import { SimpleButton } from './simpleButton'

export function ButtonLoadingCover({
    watcher,
    shouldDisplayDirectly,
    children,
    background,
    color
}: {
    watcher?: WatcherState
    shouldDisplayDirectly?: boolean
    children: any
    background: ComponentProps<typeof SimpleButton>['background']
    color?: ComponentProps<typeof SimpleButton>['color']
}): JSX.Element {
    const loader = useMemo(() => {
        const dynamicLoader = (
            <LoaderMask>
                <SlimLoader background={background} color={color} />
            </LoaderMask>
        )

        if (shouldDisplayDirectly) return dynamicLoader
        if (watcher !== 'started') return null
        return dynamicLoader
    }, [watcher, shouldDisplayDirectly, background, color])

    const content = useMemo(() => {
        return <ContentHolder shouldHide={loader !== null}>{children}</ContentHolder>
    }, [loader, children])

    return (
        <Holder>
            {content}
            {loader}
        </Holder>
    )
}

const Holder = styled.div`
    position: relative;
    width: 100%;
`

const ContentHolder = styled.div<{ shouldHide?: boolean }>`
    opacity: 1;
    ${(p) =>
        p.shouldHide &&
        css`
            opacity: 0;
        `}
`

const LoaderMask = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px 10px;
    width: calc(100% - 20px);
    height: 100%;
    min-height: 2px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
`
