import { ActionType } from 'deox'
import { GET, POST, PUT } from '../../../generators/networking'
import { ApplicationInternalsSchemesScreeningActions } from './actions'

export const ApplicationInternalsSchemesScreeningSagas = {
    *SCREEN({ payload: p }: ActionType<typeof ApplicationInternalsSchemesScreeningActions.SCREEN>) {
        yield* POST({
            url: `${import.meta.env.VITE_SCHEMES_SCREENING}/applications/${p.applicationId}/inquiries`,
            watcher: p.watcherId,
            successCode: 201,
            successText: 'Inquiry completed successfully.',
            errorText: 'Failed to screen the merchant.',
            onSuccessDispatch: (r) => {
                return ApplicationInternalsSchemesScreeningActions.STORE_NEW_INQUIRY(p.applicationId, r)
            }
        })
    },
    *FETCH_INQUIRIES({ payload: p }: ActionType<typeof ApplicationInternalsSchemesScreeningActions.FETCH_INQUIRIES>) {
        yield* GET({
            url: `${import.meta.env.VITE_SCHEMES_SCREENING}/applications/${p.applicationId}/inquiries`,
            errorText: 'Failed to fetch schemes screening status.',
            onSuccessDispatch: (r) => {
                return ApplicationInternalsSchemesScreeningActions.STORE_INQUIRIES(p.applicationId, r.inquiries)
            },
            onFailureDispatch: (r) => {
                return ApplicationInternalsSchemesScreeningActions.STORE_INQUIRIES_FAILED(p.applicationId)
            }
        })
    },
    *CONCLUDE_INQUIRY({ payload: p }: ActionType<typeof ApplicationInternalsSchemesScreeningActions.CONCLUDE_INQUIRY>) {
        yield* PUT({
            // eslint-disable-next-line max-len
            url: `${import.meta.env.VITE_SCHEMES_SCREENING}/applications/${p.applicationId}/inquiries/${p.inquiryId}/conclude`,
            errorText: 'Failed to conclude inquiry.',
            watcher: p.watcherId,
            successCode: 200,
            successText: 'Inquiry concluded successfully.',
            body: {
                result_ids: p.resultIds,
                past_inquiry_ids: p.pastInquiryIds,
                passed: p.passed
            },
            onSuccessDispatch: (r) => {
                return ApplicationInternalsSchemesScreeningActions.STORE_NEW_INQUIRY(p.applicationId, r)
            }
        })
    }
}
