import { createActionCreator } from 'deox'
import { MerchantNotification, MerchantNotificationType, MerchantWebhookEvents } from './types'

const Prefix = 'APPLICATION_INTERNALS_RECORD'

export const ApplicationInternalsRecordActions = {
    FETCH: createActionCreator(
        `${Prefix}/FETCH`,
        (resolve) => (id: string, recordLink: string) => resolve({ id, recordLink })
    ),
    STORE: createActionCreator(`${Prefix}/STORE`, (resolve) => (id: string, body: any) => resolve({ id, body })),
    CLEAR: createActionCreator(`${Prefix}/CLEAR`, (resolve) => () => resolve()),
    STORE_NOTIFICATIONS: createActionCreator(
        `${Prefix}/STORE_NOTIFICATIONS`,
        (resolve) => (applicationId: string, notifications: MerchantNotification[]) =>
            resolve({ applicationId, notifications })
    ),
    STORE_NOTIFICATION: createActionCreator(
        `${Prefix}/STORE_NOTIFICATION`,
        (resolve) => (applicationId: string, notification: MerchantNotification) =>
            resolve({ applicationId, notification })
    ),
    UNSTORE_NOTIFICATION: createActionCreator(
        `${Prefix}/UNSTORE_NOTIFICATION`,
        (resolve) => (applicationId: string, notificationId: string) => resolve({ applicationId, notificationId })
    ),
    UPDATE_NOTIFICATION: createActionCreator(
        `${Prefix}/UPDATE_NOTIFICATION`,
        (resolve) =>
            (
                watcherId: string,
                applicationId: string,
                notificationLink: string,
                types: MerchantNotificationType[] | MerchantWebhookEvents[],
                protocol: MerchantNotification['protocol'],
                endpoint: string
            ) =>
                resolve({ watcherId, applicationId, notificationLink, types, protocol, endpoint })
    ),
    CREATE_NOTIFICATION: createActionCreator(
        `${Prefix}/CREATE_NOTIFICATION`,
        (resolve) =>
            (
                watcherId: string,
                applicationId: string,
                subscriptionsLink: string,
                types: MerchantNotificationType[] | MerchantWebhookEvents[],
                protocol: MerchantNotification['protocol'],
                endpoint: string
            ) =>
                resolve({ watcherId, applicationId, subscriptionsLink, types, protocol, endpoint })
    ),
    REVOKE_NOTIFICATION: createActionCreator(
        `${Prefix}/REVOKE_NOTIFICATION`,
        (resolve) => (watcherId: string, applicationId: string, notificationId: string, selfLink: string) =>
            resolve({ watcherId, applicationId, selfLink, notificationId })
    )
}
