import React, { ReactElement, useCallback } from 'react'
import { Settlement } from '../../store/settlements/types'
import styled, { css } from 'styled-components'
import { List } from '../../components/layout/list'
import { Icon } from '../../components/icons/icon'
import { useMemo } from 'react'
import moment from 'moment'
import { Theme } from '../../hooks/general/useTheme'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { DateFormats } from '../../utils/dateUtils'
import { LinkButton } from '../../components/buttons/linkButton'
import { useDispatch } from 'react-redux'
import { SettlementsReservesActions } from '../../store/settlementsReserves/actions'
import { getSettlementPayoutStatus, getSettlementReserveStatus } from './utils'
import { LinkButtonArrow } from '../../components/buttons/linkButtonArrow'
import { numberWithCurrency } from '../../utils'
import { SettlementsCompletedWarning } from './Settlements.CompletedWarning'

export const SettlementCardPayoutDetails: React.FC<{ s: Settlement }> = ({ s }) => {
    const dispatch = useDispatch()
    const payoutStatus = useMemo(() => getSettlementPayoutStatus(s), [s])
    const reserveStatus = useMemo(() => getSettlementReserveStatus(s), [s])

    const payoutDetails = useMemo((): {
        color: keyof Theme
        icon: ReactElement
        actions: null | any
    } => {
        switch (payoutStatus) {
            case 'Completed':
                return {
                    color: 'front.subtleAccent.background.subtlerI' as const,
                    icon: <Icon type="payoutDone" />,
                    actions: null
                }
            case 'Cancelled':
                return {
                    color: 'front.subtleAccent.background.subtlerI' as const,
                    icon: <Icon type="payoutDone" />,
                    actions: null
                }
            default:
                return {
                    color: 'front.background' as const,
                    icon: <Icon type="payout" />,
                    actions: s?.account?.merchantId
                        ? {
                              type: 'custom',
                              node: (
                                  <SimpleButton
                                      onClick={() => {
                                          dispatch(
                                              SettlementsReservesActions.START_FLOW(s.account.merchantId, {
                                                  settlementId: s.id,
                                                  type: 'payout'
                                              })
                                          )
                                      }}
                                      color="front.accent.color"
                                  >
                                      Cancel payout
                                  </SimpleButton>
                              )
                          }
                        : undefined
                }
        }
    }, [payoutStatus, dispatch, s])

    const reserveDetails = useMemo((): {
        color: keyof Theme
        icon: ReactElement
        actions: null | any
    } => {
        switch (reserveStatus) {
            case 'Released':
                return {
                    color: 'front.subtleAccent.background.subtlerI' as const,
                    icon: <Icon type="reserveDone" />,
                    actions: null
                }
            case 'Cancelled':
                return {
                    color: 'front.subtleAccent.background.subtlerI' as const,
                    icon: <Icon type="reserveDone" />,
                    actions: s.reserve.linkedSettlement
                        ? {
                              type: 'custom',
                              node: (
                                  <LinkButton
                                      target="blank"
                                      to={`/settlements/${s.reserve.linkedSettlement}`}
                                      color="front.accent.color"
                                  >
                                      See containing settlement
                                      <Spacer width={5} />
                                      <LinkButtonArrow shouldBeBordered background="overlay.background" />
                                  </LinkButton>
                              )
                          }
                        : {}
                }
            default:
                return {
                    color: 'front.background' as const,
                    icon: <Icon type="reserve" />,
                    actions: {
                        type: 'custom',
                        node: (
                            <SimpleButton
                                color="front.accent.color"
                                onClick={() => {
                                    dispatch(
                                        SettlementsReservesActions.START_FLOW(s.account.merchantId, {
                                            settlementId: s.id,
                                            type: 'reserve'
                                        })
                                    )
                                }}
                            >
                                Cancel reserve
                            </SimpleButton>
                        )
                    }
                }
        }
    }, [reserveStatus, dispatch, s])

    const statusWithCheckmark = useCallback((status) => {
        if (status !== 'Upcoming')
            return {
                type: 'custom' as const,
                node: (
                    <Flex>
                        <CheckmarkHolder>
                            <Icon type="checkmark" size={7} />
                        </CheckmarkHolder>
                        <Text noWrap>{status}</Text>
                        {status === 'Completed' && <SettlementsCompletedWarning />}
                    </Flex>
                )
            }
        return {
            type: 'custom' as const,
            node: (
                <Flex>
                    <CheckPlaceholder />
                    <Text noWrap>{status}</Text>
                </Flex>
            )
        }
    }, [])

    const template = '80px 120px 150px 120px auto'
    const payoutDate = payoutStatus === 'Cancelled' ? s?.payout?.cancelledDate : s?.payout?.date
    const reservePayoutDate = reserveStatus === 'Cancelled' ? s?.reserve?.cancelledDate : s?.reserve?.date
    let payoutLabel = 'Will be paid on'
    if (payoutStatus == 'Cancelled') payoutLabel = 'Has been cancelled on'
    if (payoutStatus == 'Completed') payoutLabel = 'Has been paid on'

    return (
        <Holder>
            {payoutStatus ? (
                <Item bg={payoutDetails.color} data-cy="payout-status">
                    <Check>{payoutDetails.icon}</Check>
                    <Flex column align="stretch">
                        <List
                            items={{
                                Type: {
                                    type: 'custom',
                                    node: <Text bold>Payout</Text>
                                },
                                Status: statusWithCheckmark(payoutStatus),
                                [payoutLabel]: payoutDate
                                    ? moment(payoutDate).format(DateFormats.day(moment(payoutDate)))
                                    : '-',
                                Amount: `${numberWithCurrency(s.currency, s.payout.amount)} ${s.currency}`,
                                Actions: payoutDetails.actions
                            }}
                            background={payoutDetails.color as any}
                            template={template}
                            switchToRowsAt={400}
                        />
                        {s.payout.referenceNumber || s.payout.descriptor ? (
                            <>
                                <Spacer height={20} />
                                <List
                                    items={{
                                        ' ': ' ',
                                        'Reference': s.payout.referenceNumber,
                                        'Descriptor': s.payout.descriptor
                                    }}
                                    background={payoutDetails.color as any}
                                    template={template}
                                    switchToRowsAt={400}
                                />
                            </>
                        ) : null}
                    </Flex>
                </Item>
            ) : (
                <PayoutHolder>
                    <Text>No payout has been generated</Text>
                </PayoutHolder>
            )}
            {reserveStatus && (
                <Item bg={reserveDetails.color} data-cy="reserve-status">
                    <Check>{reserveDetails.icon}</Check>
                    <Flex column align="stretch">
                        <List
                            items={{
                                Type: {
                                    type: 'custom',
                                    node: <Text bold>Reserve</Text>
                                },
                                Status: statusWithCheckmark(reserveStatus),
                                [reserveStatus === 'Upcoming'
                                    ? 'Will be released on'
                                    : reserveStatus == 'Cancelled'
                                    ? 'Has been cancelled on'
                                    : 'Has been released on']: reservePayoutDate
                                    ? moment(reservePayoutDate).format(DateFormats.day(moment(reservePayoutDate)))
                                    : '-',
                                Amount: `${numberWithCurrency(s.currency, s.reserve.amount)} ${s.currency}`,
                                Actions: reserveDetails.actions
                            }}
                            background={reserveDetails.color as any}
                            template={template}
                            switchToRowsAt={400}
                        />
                        <Spacer height={20} />
                        <List
                            items={{
                                ' ': ' ',
                                'Reference': s.reserve.referenceNumber,
                                'Descriptor': s.reserve.descriptor
                            }}
                            background={reserveDetails.color as any}
                            template={template}
                            switchToRowsAt={400}
                        />
                    </Flex>
                </Item>
            )}
        </Holder>
    )
}

const CheckPlaceholder = styled.div`
    width: 13px;
    height: 13px;
    border: 1px dotted #ccc;
    border-radius: 999px;
    margin-right: 7px;
    position: relative;
    top: 1px;
`

const CheckmarkHolder = styled.div`
    width: 13px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    position: relative;
    top: 1.5px;
    border: 1px solid ${(p) => p.theme['front.subtleSuccess.background.strongerII']};
    background-color: ${(p) => p.theme['front.subtleSuccess.background.strongerI']};
    color: ${(p) => p.theme['front.subtleSuccess.text']};
    border-radius: 999px;
`

const Check = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 999px;
    margin-left: -2px;
    flex-shrink: 0;
    margin-top: 1px;
    margin-right: 20px;
    color: ${(p) => p.theme['front.text.subtlerI']};
    border: 1px solid rgba(0, 0, 0, 0.033);
    background-color: rgba(0, 0, 0, 0.033);
`

const Item = styled.div<{ bg: keyof Theme }>`
    border-top: 1px dashed ${(p) => p.theme['front.border']};
    display: flex;
    align-items: flex-start;
    padding: 25px 25px;
    margin: 0 -25px;

    ${(p: any) =>
        css`
            background-color: ${p.theme[p.bg]};
        `}

    &:last-child {
        border-radius: 0 0 12px 12px;
    }
`

const Holder = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: -25px;
`

const Bullet = styled.div`
    width: 25px;
    height: 25px;
    background-color: ${(p) => p.theme['front.accent.color']};
`

const PayoutHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: ${(p) => p.theme['front.highlights']};
    color: ${(p) => p.theme['front.text.subtlerI']};
    text-align: center;
    margin-bottom: 20px;
`
