import { LinkButton } from '../buttons/linkButton'
import { LinkButtonArrow } from '../buttons/linkButtonArrow'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { TimelineCard } from '../listPages/timelineCard'

export const TaskTimelineApplicationLink: React.FC<{
    last?: boolean
    applicationId: string
}> = ({ applicationId }) => {
    return (
        <TimelineCard title="Application is ready for reviewing">
            <Flex column align="flex-start">
                <Text>
                    You can now visit the application and <Text bold>start reviewing the merchant.</Text>
                </Text>
                <Spacer height={10} />
                <LinkButton
                    color="front.accent.color"
                    to={`${import.meta.env.VITE_AUTH0_REDIRECT}/merchant/${applicationId}/application`}
                    target="oldManagerApplication"
                >
                    <LinkButtonArrow background="front.background" shouldBeBordered />
                    <Spacer width={10} />
                    Go to application
                </LinkButton>
            </Flex>
        </TimelineCard>
    )
}
