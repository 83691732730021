import { useCallback, useEffect, useMemo, useState } from 'react'

const LIMIT = 80
export function useValueShortener(value: string) {
    const [showMore, setShowMore] = useState(false)
    const [text, setText] = useState('')

    const shouldShowMore = useMemo(() => {
        if (!value) return false
        if (value.length > LIMIT) return true
        return false
    }, [value])

    useEffect(() => {
        if (!shouldShowMore) return setText(value)

        if (!showMore) return setText(`${value.substr(0, LIMIT)}...`)

        return setText(value)
    }, [shouldShowMore, showMore, value])

    return {
        text,
        showMore,
        shouldEvenShowMore: shouldShowMore,
        toggleShowMore: useCallback(() => {
            setShowMore((s) => !s)
        }, [setShowMore])
    }
}
