/* eslint-disable max-len */
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { useQueryParams } from '../../hooks/general/useQueryParam'
import { useSelectable } from '../../hooks/general/useSelectable'
import { RootState } from '@/store'
import { SearchDispatchClearResults, SearchDispatchFindResults } from '../../store/search/actions'
import { SearchState } from '../../store/search/types'
import { ApplicationStatus } from '../../components/general/applicationStatus'
import { ArrowIndicator } from './SearchArrowIndicator'
import { CardStyles } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Keyface } from '../../components/hotkeys/keyface'
import { List } from '../../components/layout/list'
import { LoaderView } from '../../components/loaders/loader'
import { Paginator } from '../../components/general/paginator'
import { TextInputSearch } from '../../components/forms/textInputSearch'
import { ModalPage } from '../../components/layout/modalPage'
import { ModalHeader } from '../../components/modals/modalHeader'
import { SearchHelp } from './SearchHelp'
import { Flex } from '../../components/layout/flex'
import { Table } from '../../components/tables/table'
import { uppercaseFirstLetter } from '../../utils'
import { Text } from '../../components/general/text'
import { getFastLinksForValue, FastLink, FastLinks } from './SearchFastLinks'
import { Icon } from '../../components/icons/icon'
import { ListMerchantTags } from '../Merchant/MerchantSidebarButtonTags'
import { SearchApplicationCard } from './SearchApplicationCard'
import { useNavigate } from 'react-router-dom'

const queryParams = ['search', 'show-search', 'search_page', 'search_per_page']
export const Search: React.FC = () => {
    const dispatch = useDispatch()
    const searchedForQuery = useRef<any>({})
    const [inputValue, setInputValue] = useState<string>()
    const navigate = useNavigate()
    const results: SearchState['results'] = useSelector((state: RootState) => state.search.results)
    const [y, setY] = useState<string | undefined>(undefined)
    const [fastLinks, setFastLinks] = useState<any>([])

    const rows = useMemo(() => {
        const fastLinkEntries = fastLinks.map((k: FastLink) => 'FAST_LINK_' + k.title)
        const entries = results.all.reduce((acc: any, r: any) => {
            acc.push(r)
            const accounts = results.at[r]?.accounts.sort((a, b) =>
                parseInt(a.merchantId) > parseInt(b.merchantId) ? -1 : 1
            )
            if (accounts.length > 0) {
                for (const i of accounts) {
                    acc.push(`${r}_ACCOUNTS_${i.id}`)
                }
            }
            return acc
        }, [] as string[])
        return [...fastLinkEntries, ...entries]
    }, [results, fastLinks])

    const cards = useMemo(() => {
        return rows.reduce((acc, r, i) => {
            if (r.includes('FAST_LINK_')) return acc
            if (r.includes('_ACCOUNTS_')) {
                if (rows[i - 1].includes('_ACCOUNTS_')) return acc
                acc.push(r.split('ACCOUNTS_')[0] + 'ACCOUNTS')
            } else acc.push(r)
            return acc
        }, [] as any)
    }, [rows])

    const rowsRefs = useMemo(() => {
        return rows.map(() => createRef())
    }, [rows]) as any

    const onSelectionChange = useCallback(
        (index) => {
            rowsRefs?.[index]?.current?.scrollIntoView?.({
                block: 'center',
                inline: 'center'
            })
        },
        [rowsRefs]
    )

    const [query, setQuery] = useQueryParams(queryParams, undefined)

    const shouldDisplayEnterToSearchText = useMemo(() => {
        if (!query) return false
        if (query.search !== inputValue) return true
        return false
    }, [inputValue, query])

    const isSearchFieldEmpty = useMemo(() => {
        if (!inputValue) return true
        return false
    }, [inputValue])

    const onSelectionMade = useCallback(
        (id) => {
            if (id?.includes('FAST_LINK_')) {
                const linkId = id.replace('FAST_LINK_', '')
                const options = FastLinks[linkId]
                navigate(options.url)
                return
            }
            if (shouldDisplayEnterToSearchText) return
            if (!id) return

            const applicationId = id.split('_ACCOUNTS_')[0]

            navigate(
                id.includes('_ACCOUNTS_')
                    ? `/merchant/${applicationId}/accounts/${id.split('_ACCOUNTS_')[1]}`
                    : `/merchant/${applicationId}/summary`
            )
        },
        [shouldDisplayEnterToSearchText, navigate]
    )

    const { resetHighlight, onMouseClick, highlighted } = useSelectable(
        rows,
        'SearchPage',
        onSelectionMade,
        undefined,
        onSelectionChange,
        undefined,
        true
    )

    useEffect(() => {
        if (highlighted !== undefined) {
            const elem = rowsRefs?.[highlighted]?.current
            if (!elem) {
                setY(undefined)
                return
            }
            if (getComputedStyle(elem).display === 'contents') {
                const rect = elem?.firstChild.getBoundingClientRect()
                setY(`${rect?.top + rect?.height / 2 + window.scrollY}`)
            } else if (elem?.getBoundingClientRect()?.top) {
                const rect = elem?.getBoundingClientRect()
                setY(`${rect?.top + rect?.height / 2 + window.scrollY}`)
            } else setY(undefined)
        } else setY(undefined)
    }, [rowsRefs, highlighted])

    const search = useCallback(
        (newQuery) => {
            resetHighlight()

            searchedForQuery.current = query

            if (newQuery?.search) {
                dispatch(
                    SearchDispatchFindResults(
                        newQuery?.search,
                        newQuery?.search_page ? parseInt(newQuery?.search_page) : 1,
                        newQuery?.search_per_page
                    )
                )
            } else {
                dispatch(SearchDispatchClearResults())
            }
        },
        [resetHighlight, query, dispatch]
    )

    useEffect(() => {
        if (!query.search || query['show-search'] !== 'yes') return

        if (JSON.stringify(query) !== JSON.stringify(searchedForQuery.current)) {
            search(query)
        }
    }, [query, search])

    const renderedPagination = useMemo(() => {
        return (
            results.pagination && (
                <PaginatorHolder onClick={(e) => e.stopPropagation()}>
                    <Paginator
                        pagination={results.pagination}
                        onPageChanged={(page) => {
                            setQuery((q: any) => ({ ...q, search_page: page }))
                        }}
                    />
                </PaginatorHolder>
            )
        )
    }, [results.pagination, setQuery])

    const goBack = useCallback(() => {
        setQuery((q: any) => ({
            ...q,
            'show-search': undefined
        }))
    }, [setQuery])

    const fastLinksStack = useMemo(() => {
        return fastLinks.map((f: FastLink, index: number) => {
            return (
                <SearchApplicationCard
                    to={f.url}
                    ref={rowsRefs[index]}
                    key={f.title}
                    data-list-index={index}
                    onClick={onMouseClick}
                    fullBorders
                >
                    <CardInset>
                        <Flex align="center">
                            <FastLinkIcon>
                                <Icon type="arrow" size={8} weight={1.33} />
                            </FastLinkIcon>
                            <Flex align="baseline">
                                <Text size="l" bold>
                                    {f.title}
                                </Text>
                                <Separator />
                                {f.description}
                            </Flex>
                        </Flex>
                    </CardInset>
                </SearchApplicationCard>
            )
        })
    }, [fastLinks, rowsRefs, onMouseClick])

    const cardStack = useMemo(() => {
        let iterator = fastLinks?.length || 0
        return cards.map((r: string, index: number) => {
            const applicationId = r.replace('_ACCOUNTS', '')
            const application = results.at[applicationId]

            if (r.includes('_ACCOUNTS')) {
                const accountsTable = (
                    <AccountsCard key={`${applicationId}-accounts`} onClick={onMouseClick}>
                        <CardInset>
                            <Table
                                background="front.background"
                                cols={[{ text: 'Currency' }, { text: 'State' }, { text: 'MID' }]}
                                columnLayout="min-content min-content auto"
                                noHeaderSeparator
                                rows={application.accounts.map((account, j) => {
                                    return {
                                        type: 'normal',
                                        noAction: true,
                                        key: account.id,
                                        ref: rowsRefs[iterator + j],
                                        attributes: { 'data-list-index': iterator + j },
                                        link: `/merchant/${applicationId}/accounts/${account.id}`,
                                        items: [
                                            {
                                                node: <Flex align="center">{account.currency}</Flex>
                                            },
                                            {
                                                node: (
                                                    <Flex align="center">
                                                        {account?.state &&
                                                            uppercaseFirstLetter(account.state as any as string)}
                                                    </Flex>
                                                )
                                            },
                                            {
                                                node: <Flex align="center">{account.merchantId}</Flex>
                                            }
                                        ]
                                    }
                                })}
                                type="narrow"
                                emptyText="No tasks found matching the filtered criterias."
                            />
                        </CardInset>
                    </AccountsCard>
                )
                iterator += application.accounts.length
                return accountsTable
            }

            const items: any = {
                State: {
                    type: 'custom',
                    node: (
                        <Text oneLine noWrap>
                            <ApplicationStatus status={application.publicState} />
                        </Text>
                    )
                },
                Company: {
                    type: 'custom',
                    node: (
                        <Text>
                            <b>{application.company.name}</b>
                        </Text>
                    )
                },
                Country: {
                    type: 'custom',
                    node: <Text noWrap>{application.company.country}</Text>
                },
                Gateway: {
                    type: 'custom',
                    node: <Text noWrap>{application.gateway?.name || '-'}</Text>
                }
            }

            let template =
                'minmax(140px, min-content) minmax(160px, 1fr) minmax(100px, min-content) minmax(120px, 0.5fr)'

            if (application.websites?.length) {
                template += ' minmax(250px, 1.5fr)'
                items.Websites = {
                    type: 'custom',
                    node: <WebsitesHolder>{application.websites.map((w) => w.url).join(', ')}</WebsitesHolder>
                }
            }
            if (application.tags?.length) {
                template += ' auto'
                const tags = application.tags.map((t) => {
                    return t.id
                })
                items.Tags = {
                    type: 'custom',
                    node: <ListMerchantTags tagsIds={tags} />
                }
            }

            const card = (
                <SearchApplicationCard
                    data-list-index={iterator}
                    ref={rowsRefs[iterator]}
                    // onMouseMove={onMouseIn}
                    to={`/merchant/${applicationId}/summary`}
                    onClick={onMouseClick}
                    key={applicationId}
                    fullBorders={!cards?.[index + 1]?.includes('_ACCOUNTS')}
                    data-search-entry="true"
                >
                    <CardInset>
                        <List background="front.background" items={items} switchToRowsAt={400} template={template} />
                    </CardInset>
                </SearchApplicationCard>
            )
            iterator += 1
            return card
        })
    }, [cards, fastLinks, onMouseClick, rowsRefs, results])

    const searchBar = useMemo(() => {
        const handleSearchInputChange = (e: any, value: string) => {
            const fastLinks = getFastLinksForValue(value)
            if (!value) setFastLinks([])
            else setFastLinks(fastLinks)
            setInputValue(value)
        }

        return (
            <SearchInputHolder>
                <SearchInputWrapper className="PREVENT_MODAL_CLOSE">
                    <TextInputSearch
                        placeholder="Find customer by name, descriptor, MID, phone etc."
                        onChange={handleSearchInputChange}
                        onEnter={(e, val) => {
                            setQuery((q: any) => ({
                                ...q,
                                search: val,
                                search_page: '1',
                                search_per_page: undefined
                            }))
                        }}
                        initialValue={query.search}
                    />
                </SearchInputWrapper>
            </SearchInputHolder>
        )
    }, [query.search, setQuery])

    const content = useMemo(() => {
        if (results.loadingStatus === 'done') {
            if (shouldDisplayEnterToSearchText) {
                if (isSearchFieldEmpty) return <SearchHelp />
                if (fastLinksStack.length)
                    return (
                        <ResultsWrapper className="PREVENT_MODAL_CLOSE">
                            {fastLinksStack}
                            <HelpText>
                                Press <Keyface k="enter" /> to search for merchants.
                            </HelpText>
                        </ResultsWrapper>
                    )
                return (
                    <Centerer>
                        <ResultsWrapper className="PREVENT_MODAL_CLOSE">
                            <OffsetTop>
                                <HelpText>
                                    Press <Keyface k="enter" /> to search for merchants.
                                </HelpText>
                            </OffsetTop>
                        </ResultsWrapper>
                    </Centerer>
                )
            }
            if (results.all.length === 0) {
                if (fastLinksStack.length)
                    return (
                        <ResultsWrapper className="PREVENT_MODAL_CLOSE">
                            {fastLinksStack}
                            <HelpText>There are no applications matching the query.</HelpText>
                        </ResultsWrapper>
                    )
                return (
                    <Centerer>
                        <ResultsWrapper className="PREVENT_MODAL_CLOSE">
                            <OffsetTop>
                                <HelpText>There are no applications matching the query.</HelpText>
                            </OffsetTop>
                        </ResultsWrapper>
                    </Centerer>
                )
            }
            return (
                <ResultsWrapper className="PREVENT_MODAL_CLOSE">
                    {fastLinksStack}
                    {cardStack}
                    {renderedPagination}
                </ResultsWrapper>
            )
        }
        if (results.loadingStatus === 'started') {
            if (fastLinksStack.length)
                return (
                    <ResultsWrapper className="PREVENT_MODAL_CLOSE">
                        {fastLinksStack}
                        <LoaderInset>
                            <LoaderView type="m" overBackground="overlay.background" />
                        </LoaderInset>
                    </ResultsWrapper>
                )
            return (
                <Centerer>
                    <ResultsWrapper className="PREVENT_MODAL_CLOSE">
                        <LoaderInset>
                            <OffsetTop>
                                <LoaderView type="l" overBackground="overlay.background" />
                            </OffsetTop>
                        </LoaderInset>
                    </ResultsWrapper>
                </Centerer>
            )
        }
        return <SearchHelp />
    }, [results, isSearchFieldEmpty, shouldDisplayEnterToSearchText, fastLinksStack, cardStack, renderedPagination])

    return (
        <ModalPage
            title={query.search ? `Search - ${query.search}` : 'Search'}
            pageId="SearchPage"
            backTo=""
            onBack={goBack}
        >
            <ModalHeader pageId="SearchPage" backTo="" onBack={goBack}>
                {searchBar}
            </ModalHeader>
            <SearchContainer>{content}</SearchContainer>
            <ArrowIndicator y={y} />
        </ModalPage>
    )
}

const AccountsCard = styled.div`
    position: relative;
    ${CardStyles.higher};
    background-color: ${(p) => p.theme['front.highlights']};
    color: ${(p) => p.theme['front.text.subtlerI']};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    margin-top: -16px;
    cursor: pointer;
`

const SearchInputWrapper = styled.div`
    max-width: 580px;
    width: 100%;
`

const SearchInputHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const HelpText = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    color: ${(p) => p.theme['overlay.text']};
    margin-top: 20px;
    padding: 5px 10px;
    border-radius: 10px;
    align-self: center;
    border: 1px solid ${(p) => p.theme['overlay.background.strongerI']};
`

const ResultsWrapper = styled.div`
    max-width: 85vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
`

const SearchContainer = styled.div`
    flex-grow: 1;
    margin: 30px;
    margin-top: 8vh;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    cursor: default;
`

const PaginatorHolder = styled.div`
    padding: 10px 15px;
    border-radius: 3px;
`

const WebsitesHolder = styled.div`
    white-space: normal;
    word-break: break-all;
`

const Separator = styled.div`
    margin-left: 20px;
    margin-right: 20px;
    width: 1px;
    height: 10px;
    background-color: ${(p) => p.theme['front.background.strongerIII']};
`

const FastLinkIcon = styled.div`
    width: 22px;
    height: 22px;
    margin-right: 10px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(p) => p.theme['front.background.strongerIII']};
    color: ${(p) => p.theme['front.text']};
`

const LoaderInset = styled.div`
    margin-top: 25px;
`

const Centerer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`

const OffsetTop = styled.div`
    position: relative;
    top: -90px;
`
