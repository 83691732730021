import { useState } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Floater } from '../../../components/layout/floater'
import { FloaterInset } from '../../../components/layout/floaterInset'
import { List } from '../../../components/layout/list'
import { useOnHoverOutside } from '../../../hooks/general/useOnHoverOutside'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { Result } from '../../../store/applicationInternals/schemesScreening/types'
import { uppercaseFirstLetter } from '../../../utils'

export const ApplicationSchemesScreeningPanelMatchBlock: React.FC<{
    f: Result['matchingFields'][0]
    type: 'VMSS' | 'MATCH'
    expanded?: boolean
}> = ({ f, type, expanded }) => {
    const [show, setShow] = useState(false)
    const [ref, setRef] = useRefTaker()
    const [holderRef, setHolderRef] = useRefTaker()

    useOnHoverOutside(holderRef, () => {
        setShow(false)
    })

    return (
        <div ref={setHolderRef}>
            <MatchingBlock
                ref={setRef}
                notInteractive={expanded}
                onClick={() => {
                    if (expanded) return
                    setShow(true)
                }}
            >
                {expanded ? (
                    <Flex column>
                        «{uppercaseFirstLetter(f.fieldName)}»
                        <Text color="front.text">
                            {f.applicationValue?.toString()} &middot; {type}: <Text>{f.screenerValue?.toString()}</Text>
                        </Text>
                    </Flex>
                ) : (
                    uppercaseFirstLetter(f.fieldName)
                )}
            </MatchingBlock>
            {expanded ? null : (
                <Floater anchor={ref} shouldShow={show}>
                    <FloaterInset>
                        <List
                            items={{
                                Application: f.applicationValue,
                                [type]: f.screenerValue
                            }}
                            background={'floating.background'}
                            template={'1fr 1fr'}
                            switchToRowsAt={400}
                        />
                    </FloaterInset>
                </Floater>
            )}
        </div>
    )
}

const MatchingBlock = styled.div<{ notInteractive?: boolean }>`
    border-radius: 8px;
    color: ${(p) => p.theme['front.text.subtlerI']};
    margin: 2px;
    cursor: help;

    ${(p) =>
        p.notInteractive
            ? css`
                  & {
                      cursor: auto !important;
                  }
              `
            : css`
                  &:hover {
                      background-color: ${(p) => p.theme['back.background.strongerI']};
                  }

                  &:active {
                      background-color: ${(p) => p.theme['back.background.strongerII']};
                  }
              `}
`
