import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LinkButton } from '../../../components/buttons/linkButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Table } from '../../../components/tables/table'
import { useDialog } from '../../../hooks/general/useDialog'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { RootState } from '@/store'

export const MerchantAccountMembers: React.FC<{
    merchantId: string
    accountId: string
    formRef: any
    errors?: any
    data: any
    touched: any
}> = ({ accountId, merchantId }) => {
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])
    const confirmDeletion = useDialog<{ memberId: string; memberSelfLink: string }>(
        useCallback(
            (watcherId, { memberId, memberSelfLink }) => {
                return MerchantAccountsActions.REMOVE_MEMBER(watcherId, accountId, memberId, memberSelfLink)
            },
            [accountId]
        ),
        'Confirm member removal',
        'Are you sure you want to remove this merchant account member?'
    )

    const cols = useMemo(() => {
        return [
            {
                text: 'Name',
                loaderSize: {
                    min: 60,
                    max: 132
                }
            },
            {
                text: 'Email',
                loaderSize: {
                    min: 60,
                    max: 132
                }
            },
            {
                text: '',
                alignRight: true,
                loaderSize: {
                    min: 50,
                    max: 50
                }
            }
        ]
    }, [])

    const rows = useMemo(() => {
        return (
            account?.members?.map((m) => {
                return {
                    type: 'normal' as const,
                    noAction: true,
                    key: m.id,
                    items: [
                        {
                            node: <Text bold>{m.name}</Text>
                        },
                        {
                            node: <Text>{m.email}</Text>
                        },
                        {
                            node: (
                                <Text>
                                    <WatcherButton
                                        onClick={() => {
                                            confirmDeletion({ memberId: m.id, memberSelfLink: m.selfLink })
                                        }}
                                        color="front.danger.color"
                                    >
                                        Remove
                                    </WatcherButton>
                                </Text>
                            )
                        }
                    ]
                }
            }) || []
        )
    }, [confirmDeletion, account])

    const rightSideMemo = useMemo(() => {
        return (
            <Flex grow justify="flex-end">
                <LinkButton noShrink cy="new-member" to={`/merchant/${merchantId}/accounts/${accountId}/members/new`}>
                    Add member
                </LinkButton>
            </Flex>
        )
    }, [merchantId, accountId])

    return (
        <Card title="Members" rightSideMemo={rightSideMemo}>
            <CardInset>
                <Table
                    rows={rows}
                    cols={cols}
                    columnLayout="1fr 1fr 1fr"
                    overrideText="No members have been assigned to this account."
                    background="front.background"
                    loaderRows={2}
                    displayLoader={!account.extraDetailsLoaded}
                />
            </CardInset>
        </Card>
    )
}
