import styled, { css } from 'styled-components'

export const ToastBlock = styled.div<{ success?: boolean; call?: boolean; context?: boolean }>`
    display: flex;
    flex-direction: column;
    max-width: 480px;
    width: 100vw;
    padding: 0 14px;
    align-items: stretch;
    justify-content: flex-start;
    margin-top: 10px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    background-color: ${(p) => p.theme['front.danger.color']};
    color: ${(p) => p.theme['front.danger.text']};

    ${(p) =>
        p.call &&
        css`
            background-color: ${(p) => p.theme['front.accent.color']};
            color: ${(p) => p.theme['front.success.text']};
            cursor: default;
        `}
    ${(p) =>
        p.success &&
        css`
            background-color: ${(p) => p.theme['front.success.color']};
            color: ${(p) => p.theme['front.success.text']};
        `}
    ${(p) =>
        p.context &&
        css`
            background-color: ${(p) => p.theme['floating.background']};
            color: ${(p) => p.theme['floating.text']};
        `}
`
