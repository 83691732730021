import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { RootState } from '@/store'
import { zIndexes } from '../../styles/zIndexes'
import { MODAL_ID } from '../modals/modalIds'

export type ClueDirection = 'bottom-right' | 'bottom-left' | 'top-left' | 'top-right' | 'left' | 'right' | 'bottom'

interface HotkeyClueProps {
    children: React.ReactNode
    hotkeyScope: MODAL_ID
    cy?: string
    clueDirection?: ClueDirection
}
export const HotkeyClue: React.FC<HotkeyClueProps> = ({ hotkeyScope, clueDirection = 'bottom-right', ...p }) => {
    const activeScope = useSelector((state: RootState) => state.interface.modals.frontmost)
    const showKeyTips = useSelector((state: RootState) => state.interface.showKeyTips)
    const shouldShow = useMemo(() => {
        return showKeyTips && activeScope == hotkeyScope
    }, [showKeyTips, activeScope, hotkeyScope])

    if (!shouldShow) return null
    return (
        <Holder data-cy={p.cy} clueDirection={clueDirection}>
            {p.children}
        </Holder>
    )
}

const Holder = styled.div<{ clueDirection: ClueDirection }>`
    position: absolute;
    z-index: ${zIndexes.hotkey};
    overscroll-behavior-y: none;
    color: ${(p) => p.theme['floating.text']};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0 4px;
    height: 16px;
    font-size: 11px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: ${(p) => p.theme['floating.background.strongerIII']};
    backdrop-filter: blur(8px);
    line-height: 11px;
    text-transform: uppercase;

    @media print {
        display: none;
    }

    ${(p) => {
        switch (p.clueDirection) {
            case 'bottom-left': {
                return css`
                    bottom: 0;
                    left: 0;
                    transform: translateX(-50%) translateY(50%);
                `
            }
            case 'bottom-right': {
                return css`
                    bottom: 0;
                    right: 0;
                    transform: translateX(50%) translateY(50%);
                `
            }
            case 'bottom': {
                return css`
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                `
            }
            case 'top-left': {
                return css`
                    top: 0;
                    left: 0;
                    transform: translateX(-50%) translateY(-50%);
                `
            }
            case 'top-right': {
                return css`
                    top: 0;
                    right: 0;
                    transform: translateX(50%) translateY(-50%);
                `
            }
            case 'right': {
                return css`
                    top: 50%;
                    right: 0;
                    transform: translateX(calc(60% - 10px)) translateY(-50%);
                `
            }
            case 'left': {
                return css`
                    top: 50%;
                    left: 0;
                    transform: translateX(-100%) translateY(50%);
                `
            }
            default:
                return css``
        }
    }}
`
