import { select } from 'typed-redux-saga'
import { RootState } from '@/store'

export function* getRootLinks() {
    return yield* select((state: RootState) => state.global.links)
}

export function* getAuth() {
    return yield* select((state: RootState) => state.auth)
}

export function* getApplicationResourcesData() {
    return yield* select((state: RootState) => state.applicationResources.data)
}
