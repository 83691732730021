import { startCase } from 'lodash'

import styled from 'styled-components'
import { Flex } from '../../../components/layout/flex'
import { MerchantAccountContract } from '../../../store/merchantAccounts/types'

export const MerchantAccountsContractStatus: React.FC<{
    contract: MerchantAccountContract
}> = ({ contract }) => {
    // if (contract?.isDraft === true) return null

    if (contract?.metadata?.state === 'signed')
        return (
            <TextHolder>
                <Flex align="center" key={contract?.id}>
                    {contract?.signer?.email ? 'Signed' : 'Effectuated'}
                </Flex>
            </TextHolder>
        )
    return (
        <TextHolder>
            <Flex align="center" key={contract?.id}>
                {startCase(contract?.metadata?.state)}
            </Flex>
        </TextHolder>
    )
}

const TextHolder = styled.div`
    white-space: nowrap;
`
