import { Flex } from '../../../../../../components/layout/flex'
import { MonitoredFetchedApplicationData } from '../../../../../../store/tasksCompanyChanges/types'
import { EmptyApplicationData } from '../../application-changes/EmptyStates/EmptyApplicationData'
import { MonitoringTemplate } from '../MonitoringTemplate'
import { ConfirmButton } from './components/ConfirmButton'
import { MuteButton } from './components/MuteButton'
import { getCompanyReg } from '../../helpers/getCompanyRegLink'
import { CompanyRegistrationLink } from '../../components/CompanyRegistrationLink'

interface MonitoringNameChangeProps {
    taskId: string
    applicationId: string
    applicationData?: MonitoredFetchedApplicationData
    before: string
    after: string
}

export const MonitoringNameChange: React.FC<MonitoringNameChangeProps> = (props) => {
    const { taskId, applicationId, applicationData, before, after } = props

    if (!applicationData) {
        return <EmptyApplicationData />
    }

    const isMuted = applicationData.muted?.company?.name?.includes(after)
    const isCompleted = applicationData.company?.name === after
    const status = isCompleted ? 'complete' : isMuted ? 'muted' : 'in-progress'

    const companyRegInfo = getCompanyReg(applicationData.company)

    return (
        <MonitoringTemplate status={status} type="main">
            <MonitoringTemplate.Container>
                <MonitoringTemplate.Status />
                <MonitoringTemplate.Main>
                    <MonitoringTemplate.Content>
                        <MonitoringTemplate.Description>Company name has changed.</MonitoringTemplate.Description>
                        <MonitoringTemplate.Actions>
                            <MonitoringTemplate.Action>
                                <ConfirmButton taskId={taskId} applicationData={applicationData} after={after} />
                            </MonitoringTemplate.Action>
                            <MonitoringTemplate.Action>
                                <CompanyRegistrationLink company={applicationData.company} />
                            </MonitoringTemplate.Action>
                            <MonitoringTemplate.Action>
                                <MuteButton
                                    taskId={taskId}
                                    applicationId={applicationId}
                                    applicationData={applicationData}
                                    after={after}
                                    isMuted={isMuted}
                                />
                            </MonitoringTemplate.Action>
                        </MonitoringTemplate.Actions>
                    </MonitoringTemplate.Content>
                    <MonitoringTemplate.Change>
                        <Flex align="center">
                            <MonitoringTemplate.Info label="Before">{before}</MonitoringTemplate.Info>
                            <MonitoringTemplate.Arrow />
                            <MonitoringTemplate.Info label="After">{after}</MonitoringTemplate.Info>
                        </Flex>
                    </MonitoringTemplate.Change>
                </MonitoringTemplate.Main>
            </MonitoringTemplate.Container>
        </MonitoringTemplate>
    )
}
