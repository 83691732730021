import { useMemo } from 'react'
import { Table } from '../../../../../../../../components/tables/table'
import { MonitoredFetchedApplicationData } from '../../../../../../../../store/tasksCompanyChanges/types'
import { getRows } from './getRows'

const columns = [
    {
        text: 'Name'
    },
    {
        text: 'SSN'
    },
    {
        text: 'Role'
    }
]

interface ExistingUBOsListProps {
    applicationData?: MonitoredFetchedApplicationData
}

export const ExistingUBOsList: React.FC<ExistingUBOsListProps> = ({ applicationData }) => {
    const rows = useMemo(() => {
        const people = applicationData?.people

        if (!people) {
            return []
        }

        return getRows(people)
    }, [applicationData])

    if (!applicationData) return null

    return (
        <Table
            background="back.background.strongerI"
            cols={columns}
            noHighlights
            columnLayout="auto auto auto"
            overrideText={`No persons found in the application's ownership structure.`}
            type="narrow"
            columnsGap={32}
            rows={rows}
        />
    )
}
