import { AccountDetails, Date, ItemCheckBox } from '../../common'
import { Account, Voucher } from '@/services/reconciliation'
import { TableColHeader, TableRow } from '@/components/tables/table'
import { TableBubble } from '@/components/layout/tableBubble'
import { LinkButton } from '@/components/buttons/linkButton'
import { numberWithCurrency } from '@/utils'
import { getAccount } from '../../../helpers'
import { RecoverVoucher } from './recover-voucher'
import { TruncatedText } from '@/ui/truncated-text'

export const getVoucherCols = () => {
    return [
        { text: 'Account', loaderSize: { min: 120, max: 180 } },
        { text: 'Booking date', loaderSize: { min: 80, max: 80 } },
        { text: 'Payment date', loaderSize: { min: 80, max: 80 } },
        { text: 'Descriptor', loaderSize: { min: 0, max: 160 } },
        { text: 'Reference', loaderSize: { min: 0, max: 160 } },
        { text: 'Posting type', loaderSize: { min: 60, max: 60 } },
        { text: 'Voucher type', loaderSize: { min: 120, max: 160 } },
        { text: 'Amount', loaderSize: { min: 60, max: 80 }, alignRight: true }
    ]
}

export const appendActions = (cols: TableColHeader[], rows: TableRow[]) => {
    if (!cols.some((col) => col.text === 'Actions')) {
        cols.push({ text: 'Actions', loaderSize: { min: 60, max: 60 }, alignRight: true })
        rows.map((row) => {
            row.items.push({
                node: <LinkButton to={`/more/reconciliation-vouchers/${row.key}/reconcile`}>Reconcile</LinkButton>
            })
        })
    }

    return { cols, rows }
}

export const appendRecoverActions = (cols: TableColHeader[], rows: TableRow[]) => {
    if (!cols.some((col) => col.text === 'Actions')) {
        cols.push({ text: 'Actions', loaderSize: { min: 60, max: 60 }, alignRight: true })
        rows.map((row) => {
            row.items.push({
                node: <RecoverVoucher id={row.key} />
            })
        })
    }

    return { cols, rows }
}

export const prependChecks = (cols: TableColHeader[], rows: TableRow[]) => {
    const newCols = cols.slice()
    if (!cols.some((col) => col.text === 'Checks')) {
        newCols.unshift({ text: 'Checks' })
        rows.map((row) => {
            const newRowItems = row.items.slice()
            newRowItems.unshift({
                node: <ItemCheckBox name="voucher_ids" value={row.key} />
            })
            row.items = newRowItems
        })
    }

    return { cols: newCols, rows }
}

export const getVoucherRows = (vouchers?: Voucher[], accounts?: Account[]) => {
    if (!vouchers) return []

    const rows: TableRow[] = vouchers.map((voucher) => {
        return {
            type: 'normal' as const,
            key: voucher.id,
            noAction: true,
            items: [
                {
                    node: accounts && <AccountDetails account={getAccount(voucher.account_id, accounts)} />
                },
                {
                    node: <Date>{voucher.booking_date}</Date>
                },
                {
                    node: <Date>{voucher.payment_date}</Date>
                },
                {
                    node: <TruncatedText>{voucher.descriptor}</TruncatedText>
                },
                {
                    node: <TruncatedText>{voucher.reference}</TruncatedText>
                },
                {
                    node: <TruncatedText>{voucher.posting_type}</TruncatedText>
                },
                {
                    node: <TruncatedText>{voucher.voucher_type}</TruncatedText>
                },
                {
                    node: (
                        <TableBubble type="important">
                            {numberWithCurrency(voucher.currency, `${voucher.amount}`)} {voucher.currency}
                        </TableBubble>
                    )
                }
            ]
        }
    })

    return rows
}
