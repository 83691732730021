import { kebabCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

import { FileBox } from '../../../components/files/fileBox'
import { SyncdFile } from '../../../components/files/syncdFile'
import { LabelButton } from '../../../components/buttons/labelButton'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { ApplicationResourceActions } from '../../../store/applicationResources/actions'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { FileActionDispatchDelete, FileActionDispatchLookoutFetch, FileActionDispatchLookoutRefetch } from '../../../store/files/actions'
import { File } from '../../../store/files/types'

import { ConvertIndexPathToFieldDetails } from './Application.Structure'
import { useMerchantApplicationFilesReselector } from './useMerchantApplicationFilesReselector'
import { useActionSaverParent } from '../../../hooks/general/useActionSaverParent'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { Icon } from '@/components/icons/icon'
import { Spacer } from '@/components/layout/spacer'

export const MerchantApplicationFile: React.FC<{
    applicationId: string
    indexPath: MerchantApplicationResourceIndexPath
    background?: 'whiteBackground' | 'greyBackground'
    scope?: 'private' | 'public'
    acceptMultiple?: boolean
    preventUpload?: boolean
}> = ({
    applicationId,
    indexPath,
    acceptMultiple,
    background = 'whiteBackground',
    preventUpload,
    scope = 'public'
}) => {
    const [fileHasBeenDeleted, setFileHasBeenDeleted] = useState(false)
    const [, setShowFloater] = useState(false)
    const [visibilityState, setVisibilityState] = useState(false)
    const dispatch = useDispatch()
    const files = useMerchantApplicationFilesReselector(applicationId, indexPath)
    const [deletedFiles, setDeletedFiles] = useState<File[]>([])
    const [nonDeletedFiles, setNonDeletedFiles] = useState<File[]>([])
    const fileFieldDetails = useMemo(() => {
        return ConvertIndexPathToFieldDetails(indexPath)
    }, [indexPath])

    const [fileToReplace, setFileToReplace] = useState<File | undefined>(undefined)
    const [, setHolder] = useRefTaker()

    useEffect(()=>{
        const deleted: File[] = []
        const nonDeleted: File[] = []
        files?.map((file) =>{
            if(file && file?.metadata && file?.metadata.deleted) {
                deleted.push(file)
            } else if (file && ((file?.metadata && !file?.metadata.deleted) || !file?.metadata)){
                nonDeleted.push(file)
            }
        })

        setDeletedFiles(deleted)
        setNonDeletedFiles(nonDeleted)
    }, [files])

    useEffect(() => {
        setShowFloater(false)
    }, [files, setShowFloater])

    const cypressHelpers = useMemo(() => {
        let str = ''
        if (!fileHasBeenDeleted) str += 'file-not-yet-deleted'
        if (nonDeletedFiles.length && !fileHasBeenDeleted) str += 'initial-file'
        if (!nonDeletedFiles?.length && !fileHasBeenDeleted) str += 'file-to-upload'
        return str
    }, [nonDeletedFiles, fileHasBeenDeleted])

    const handleRemoveClicked = useCallback(
        (watcherId, file) => {
            setFileHasBeenDeleted(true)
            dispatch(
                FileActionDispatchDelete(
                    watcherId,
                    file,
                    (files: any) => {
                        return ApplicationResourceActions.UNLINK_FILES(
                            applicationId,
                            indexPath.resourceKey,
                            indexPath.subsectionIndex || 0,
                            files
                        )
                    },
                    false,
                    indexPath.resourceKey,
                )
            )
        },
        [dispatch, applicationId, indexPath]
    )

    const handleFetchClicked = (applicationId, reportType) => {
        dispatch(
            FileActionDispatchLookoutFetch(applicationId, reportType)
        )
    }

    const handleRefetchClicked = (file) => {
        dispatch(
            FileActionDispatchLookoutRefetch(file)
        )
    }

    const fileReplaceHandling = useMemo(() => {
        return {
            file: fileToReplace,
            onSuccess: () => {
                setFileToReplace(undefined)
            }
        }
    }, [fileToReplace])

    const handleShowFileMenu = useMemo(() => {
        return !["peopleHistory", "websitesHistory"].includes(indexPath.resourceKey)
    }, [indexPath])

    const [action, clickActionSaver] = useActionSaverParent()

    return (
        <Holder ref={setHolder} data-cy={cypressHelpers} internal={scope === 'private'}>
            {nonDeletedFiles.map(
                (f: any) =>
                    f && (
                        <FileBox
                            cy="application-file"
                            fileId={f.id}
                            key={f.id}
                            onRemoveClicked={handleRemoveClicked}
                            onRefetchClicked={handleRefetchClicked}
                            setIsReplacing={(file) => {
                                setFileToReplace(file)
                            }}
                            onNewFileClicked={action}
                            outlined={background === 'greyBackground'}
                            showFileMenu={handleShowFileMenu}
                        />
                    )
            )}
            {preventUpload ? "" : (
                <Mask shouldShow={!nonDeletedFiles[0]}>
                    <SyncdFile
                        handleFileReplace={fileReplaceHandling}
                        withDocumentPrefix={false}
                        multiple={true}
                        clickActionSaver={clickActionSaver}
                        fileLabel={fileFieldDetails?.field?.id?.mapi || kebabCase(indexPath.fieldKey)}
                        scope={scope}
                        sourcePath={`applicationResources.data.forApplication.${applicationId}.${
                            indexPath.resourceKey
                        }.fields.${indexPath.subsectionIndex || 0}.filesLink`}
                        linkFiles={(files) => {
                            return ApplicationResourceActions.LINK_FILES(
                                applicationId,
                                indexPath.resourceKey,
                                indexPath.subsectionIndex || 0,
                                files
                            )
                        }}
                        // replaceFile={replaceFile}
                        customButton={(forField) => {
                            return (
                                <>
                                    {indexPath.reportType && (
                                        <SimpleButton
                                            key={`fetch-${indexPath.reportType}`}
                                            onClick={ () => {
                                                handleFetchClicked(applicationId, indexPath.reportType)
                                            }}
                                        >
                                             <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                                                <Icon type="refresh" size={13} />
                                                <Spacer width={5} />
                                                Fetch report
                                             </ButtonInset>
                                        </SimpleButton>
                                    )}
                                    <LabelButton htmlFor={forField} cy={nonDeletedFiles[0] ? 'add-file' : 'add-new-file'}>
                                        <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                                            {scope === 'private' ? '+ Internal Files' : '+ Files'}
                                        </ButtonInset>
                                    </LabelButton>
                                </>
                            )
                        }}
                        unlinkFiles={(files) => {
                            return ApplicationResourceActions.UNLINK_FILES(
                                applicationId,
                                indexPath.resourceKey,
                                indexPath.subsectionIndex || 0,
                                files
                            )
                        }}
                        marginless
                        cy="file-upload"
                    />
                </Mask>
            )}
            {
                !['peopleHistory', 'websitesHistory'].includes(indexPath.resourceKey) && 
                ['people', 'websites', 'company'].includes(indexPath.resourceKey) && 
                deletedFiles.length? 
                    <SimpleButton
                        onClick={()=>{
                            setVisibilityState(!visibilityState)
                        }}
                        color="front.accent.color"
                    >
                        <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                            {visibilityState ? `Hide deleted files` : `Show ${deletedFiles.length} deleted files`}
                        </ButtonInset>
                    </SimpleButton> : null
            }
            <DeletedFilesHolder shouldShow={visibilityState}>
            {
                (visibilityState || 
                    ['peopleHistory', 'websitesHistory'].includes(indexPath.resourceKey)) &&
                    deletedFiles.map(
                        (f: any) =>
                            f && (
                                <FileBox
                                    cy="application-file"
                                    fileId={f.id}
                                    key={f.id}
                                    outlined={background === 'greyBackground'}
                                    showFileMenu={false}
                                />
                            )
                    )
            }
            </DeletedFilesHolder>
        </Holder>
    )
}

const Mask = styled.div<{ shouldShow?: boolean }>`
    display: none;
    ${(p) =>
        p.shouldShow &&
        css`
            display: block;
        `}
`

const Holder = styled.div<{ internal?: boolean }>`
    max-width: 190px;
    min-height: 22px;
    min-width: 100%;
    box-sizing: border-box;

    ${(p) =>
        p.internal &&
        css`
            min-height: 19px;
        `}
`

const CircleHolder = styled.div`
    width: 12px;
    border-radius: 999px;
    height: 11px;
    color: #fff;
    position: relative;
    display: flex;
    padding-top: 1px;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme['front.accent.color']}; ;
`

const MoreButton = styled.div<{ isHovered?: boolean }>`
    width: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 999px;
    opacity: 0;

    ${(p) =>
        p.isHovered &&
        css`
            opacity: 1;
        `}

    &:hover ${CircleHolder} {
        background-color: ${(p) => p.theme['front.accent.color.subtlerI']};
    }

    &:active ${CircleHolder} {
        background-color: ${(p) => p.theme['front.accent.color.subtlerII']};
    }
`

const DeletedFilesHolder = styled.div<{ shouldShow?: boolean }>`
${(p) =>
    p.shouldShow &&
    css`
        padding: 10px 20px;
        background-color: ${(p) => p.theme['back.background.strongerII']};
        display: flex;
        flex-direction: column;
        margin: 0 0 0 -20px;
        border-radius: 8px;
        text-decoration: none;
    `}
`
