import { useGetApplication, useGetWebsites } from '@/services/merchant-api'
import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'

export const useApplicationWebsites = (applicationId: string) => {
    const url = `${import.meta.env.VITE_API_ROOT}/applications/${applicationId}`

    // Fetch application
    const application = useGetApplication(url)

    // Fetch company
    const link = application.data?.relations.websites
    const websites = useGetWebsites(application.isSuccess && link ? link : skipToken)

    // Result
    const isLoading = application.isLoading || websites.isLoading
    const isError = application.isError || websites.isError
    const data = websites.data

    const result = useMemo(() => ({ data, isLoading, isError }), [data, isLoading, isError])

    return result
}
