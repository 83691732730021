import { useEffect, useRef } from 'react'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { useContractHistoryAnalyzer } from './useContractHistoryAnalyzer'
import { useLocation, useNavigate } from 'react-router-dom'

export const useMerchantAccountContractRedirecter = (
    account: any,
    accountId: string,
    contractId: string,
    id: string
) => {
    const navigate = useNavigate()
    const location = useLocation()
    const redirectedToTheCurrentContract = useRef(false)
    const newestContract = useRef<string | undefined>(undefined)
    const [watcher] = useNamedWatcher(accountId + '_NEW_CONTRACT')
    const { effectiveContract } = useContractHistoryAnalyzer(account?.contractHistory)

    useEffect(() => {
        const locationState = location.state as any
        if (account?.loadingStatus === 'started') {
            if (redirectedToTheCurrentContract.current === true) redirectedToTheCurrentContract.current = false
        }

        // Redirect to latest contract, if needed
        if (watcher === 'success') {
            if (!account?.contractHistory) return

            const latestContractId = Object.keys(account.contractHistory)[0]
            if (!latestContractId) return

            if (newestContract.current === latestContractId) return
            newestContract.current = latestContractId
            navigate(
                {
                    pathname: `/merchant/${id}/accounts/${accountId}/contracts/${latestContractId}`,
                    search: location.search
                },
                { replace: true }
            )
        } else if (account?.loadingStatus === 'done') {
            if (locationState?.noLatestRedirect) return
            // Else, redirect to the effective contract
            if (redirectedToTheCurrentContract.current === false) {
                redirectedToTheCurrentContract.current = true

                if (!contractId) {
                    if (account?.contract?.id) {
                        navigate(
                            {
                                pathname: `/merchant/${id}/accounts/${accountId}/contracts/${
                                    effectiveContract?.id ? effectiveContract.id : account.contract.id
                                }`,
                                search: location.search
                            },
                            { replace: true }
                        )
                    }
                }
            }
        }
    }, [account, watcher, accountId, location, contractId, effectiveContract, navigate, id])
}
