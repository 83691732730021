import { Badge, Box, Button, Flex, Separator, Text } from '@/design-system'
import React from 'react'

interface PartnerDetailsProps {
    name?: string
    merchantPaymentGatewayId?: string
    technical?: boolean
    support?: boolean
    referral?: boolean
    onEditClick?: () => void
}

export const PartnerDetails: React.FC<PartnerDetailsProps> = (props) => {
    const { name, merchantPaymentGatewayId, technical, support, referral, onEditClick } = props

    const nameString = name ? name : '-'
    const merchantPaymentGatewayIdString = merchantPaymentGatewayId ? merchantPaymentGatewayId : '-'
    const isTypeAvailable = technical || support || referral

    return (
        <Flex direction="column" gap="5">
            <Flex justify="between" align="center">
                <Flex align="center" gap="3">
                    <Box mr="3">
                        <Text weight="medium" lineHeight="none">
                            {nameString}
                        </Text>
                    </Box>
                    {isTypeAvailable ? (
                        <Flex gap="3">
                            {technical && <Badge>Technical</Badge>}
                            {support && <Badge>Support</Badge>}
                            {referral && <Badge>Referral</Badge>}
                        </Flex>
                    ) : (
                        <Text color="neutral">-</Text>
                    )}
                </Flex>
                <Button variant="link" onClick={onEditClick}>
                    Edit
                </Button>
            </Flex>
            <Separator />
            <Flex gap="3">
                <Text color="neutral">Merchant payment gateway id:</Text>
                <Text color="neutral">{merchantPaymentGatewayIdString}</Text>
            </Flex>
        </Flex>
    )
}
