import { Flex, Table, Text } from '@/design-system'
import React from 'react'
import { AccountDetails, Date } from '../../common'
import { getAccount } from '@/features/reconciliation/helpers'
import { TableBubble } from '@/components/layout/tableBubble'
import { numberWithCurrency } from '@/utils'
import { Account, Posting } from '@/services/reconciliation'
import { TruncatedText } from '@/ui/truncated-text'

interface SinglePostingTableProps {
    posting: Posting
    accounts: Account[]
}

const SinglePostingTable: React.FC<SinglePostingTableProps> = (props) => {
    const { posting, accounts } = props

    return (
        <Table variant="default" layout="fixed">
            <Table.Header>
                <Table.Row>
                    <Table.Head width="2/12">Account</Table.Head>
                    <Table.Head>Posting date</Table.Head>
                    <Table.Head>Serial</Table.Head>
                    <Table.Head>Description</Table.Head>
                    <Table.Head>Reference</Table.Head>
                    <Table.Head>Counterparty name</Table.Head>
                    <Table.Head>Counterparty account</Table.Head>
                    <Table.Head>Type</Table.Head>
                    <Table.Head justify="end">Amount</Table.Head>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <AccountDetails account={getAccount(posting.account_id, accounts)} />
                    </Table.Cell>
                    <Table.Cell>
                        <Date>{posting.posting_date ? posting.posting_date : '-'}</Date>
                    </Table.Cell>
                    <Table.Cell>
                        <TruncatedText>{posting.serial ? posting.serial : '-'}</TruncatedText>
                    </Table.Cell>
                    <Table.Cell>
                        <TruncatedText>{posting.description ? posting.description : '-'}</TruncatedText>
                    </Table.Cell>
                    <Table.Cell>
                        <TruncatedText>{posting.reference ? posting.reference : '-'}</TruncatedText>
                    </Table.Cell>
                    <Table.Cell>
                        <TruncatedText>{posting.counterparty_name ? posting.counterparty_name : '-'}</TruncatedText>
                    </Table.Cell>
                    <Table.Cell>
                        <TruncatedText>
                            {posting.counterparty_account ? posting.counterparty_account : '-'}
                        </TruncatedText>
                    </Table.Cell>
                    <Table.Cell>
                        <Text>{posting.posting_type ? posting.posting_type : '-'}</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <Flex justify="end">
                            <TableBubble type="important">
                                {numberWithCurrency(posting.currency, `${posting.amount}`)} {posting.currency}
                            </TableBubble>
                        </Flex>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}

export { SinglePostingTable }
