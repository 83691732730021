import { MerchantApplicationStatusAction } from '../../../pages/Merchant/Application/Application.StateControlsTypes'
import { LoadingStatus } from '../../../utils'

export type StampMethods = 'DELETE' | 'PUT'
export type ApplicationStamp = {
    name: MerchantApplicationStatusAction
    href: string
    hints: {
        allow: StampMethods[]
    }
}

export interface ApplicationInternalStampingState {
    forApplication: {
        [key: string]: {
            stamps: ApplicationStamp[]
            loadingStatus: LoadingStatus
        }
    }
}

export const InitialApplicationInternalStampingState: ApplicationInternalStampingState = {
    forApplication: {}
}
