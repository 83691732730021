import { Text } from '../../components/general/text'
import styled from 'styled-components'
import { MerchantApplicationEdits } from './Application/useMerchantApplicationEditsMade'
import { Account } from '../../components/general/account'
import moment from 'moment'
import { ConditionallyShowInteractionDate } from './MerchantSidebarInteractions.ConditionallyShowInteractionDate'

export const MerchantInteractionMadeEdits: React.FC<{ edit: MerchantApplicationEdits }> = ({ edit }) => {
    return (
        <Block>
            <Text color="front.text.subtlerI" align="center">
                <Account id={edit.by} />
                &nbsp;has made {edit.edits} edits.
            </Text>
            <ConditionallyShowInteractionDate right={0}>
                <Right>{moment(edit.at).format('HH:mm:ss')}</Right>
            </ConditionallyShowInteractionDate>
        </Block>
    )
}

const Block = styled.div`
    padding: 10px 70px;
    text-align: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: relative;
`

const Right = styled.div`
    position: absolute;
    right: 0;
    color: ${(p) => p.theme['front.text.subtlerII']};
`
