import { Page, Container, Flex, Grid } from '@/design-system'
import { RTKQuery } from '@/services/common'
import { MAPI_PARTNERS_ROOT, createPartner } from '@/services/merchant-api'
import { FormProvider } from 'react-hook-form'
import { Form, redirect, useNavigate, useSubmit } from 'react-router-dom'
import { PartnerSchema, usePartnerForm } from '@/features/partners'
import { CheckboxGroup, PageHeader, SaveCard, FormInput } from '@/ui'
import { omitBy } from 'lodash'

export const action = async ({ request }) => {
    const formData = await request.formData()
    const data = Object.fromEntries(formData)
    const body = { url: MAPI_PARTNERS_ROOT, data }
    await RTKQuery.post(createPartner, body)

    return redirect('/more/partners')
}

const PartnerAdd = () => {
    const navigate = useNavigate()
    const form = usePartnerForm()
    const submit = useSubmit()

    const technical = form.register('technical')
    const onTechnicalChange = (checked: boolean) => {
        form.setValue('technical', checked)
    }

    const support = form.register('support')
    const onSupportChange = (checked: boolean) => {
        form.setValue('support', checked)
    }

    const referral = form.register('support')
    const onReferralChange = (checked: boolean) => {
        form.setValue('referral', checked)
    }

    const onBack = () => {
        navigate('/more/partners')
    }

    const onSubmit = form.handleSubmit((values: PartnerSchema) => {
        const valuesWithoutEmpty = omitBy(values, (value) => value === '')

        submit(valuesWithoutEmpty, { method: 'post' })
    })

    const errorCount = Object.keys(form.formState.errors).length

    return (
        <Page pb="normal">
            <PageHeader title="Add partner" onBack={onBack} />
            <Flex justify="center" align="center" grow="1">
                <Container size="lg">
                    <FormProvider {...form}>
                        <Form onSubmit={onSubmit}>
                            <SaveCard>
                                <SaveCard.Body>
                                    <SaveCard.Title>Partner details</SaveCard.Title>
                                    <SaveCard.Fields>
                                        <Grid columns="3" gap="3">
                                            <FormInput>
                                                <FormInput.Label name="name">Name</FormInput.Label>
                                                <FormInput.Input testid="input-partner-name" name="name" />
                                            </FormInput>
                                            <FormInput>
                                                <FormInput.Label name="name">
                                                    Merchant payment gateway id
                                                </FormInput.Label>
                                                <FormInput.Input
                                                    testid="input-merchant-payment-gateway-id"
                                                    name="merchant_payment_gateway_id"
                                                />
                                            </FormInput>
                                            <CheckboxGroup label="Type">
                                                <CheckboxGroup.Checkbox
                                                    testid="checkbox-partner-technical"
                                                    name="technical"
                                                    label="Technical"
                                                    ref={technical.ref}
                                                    onChange={onTechnicalChange}
                                                />
                                                <CheckboxGroup.Checkbox
                                                    testid="checkbox-partner-support"
                                                    name="support"
                                                    label="Support"
                                                    ref={support.ref}
                                                    onChange={onSupportChange}
                                                />
                                                <CheckboxGroup.Checkbox
                                                    testid="checkbox-partner-referral"
                                                    name="referral"
                                                    label="Referral"
                                                    ref={referral.ref}
                                                    onChange={onReferralChange}
                                                />
                                            </CheckboxGroup>
                                        </Grid>
                                    </SaveCard.Fields>
                                </SaveCard.Body>
                                <SaveCard.Footer testid="card-partner-footer" errorCount={errorCount} />
                            </SaveCard>
                        </Form>
                    </FormProvider>
                </Container>
            </Flex>
        </Page>
    )
}

PartnerAdd.action = action

export { PartnerAdd }
