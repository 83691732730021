import React from 'react'
import { Section } from '../../../common/section'
import { ToggleContent } from '../toggle-content/toggle-content'
import { SectionCheck } from '@/services/ongoing-due-diligence'
import { useReportSection } from '../../../provider/report-provider/report-provider'

export const WebsiteAvailableContactInfo: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')

    const data = checks.website_available_contact_info
    const commentData = checks.comment_website_available_contact_info

    return (
        <Section>
            <Section.Content>
                <ToggleContent
                    dataName="website_available_contact_info"
                    data={data}
                    commentName="comment_website_available_contact_info"
                    commentData={commentData}
                    visibleValue="No"
                />
            </Section.Content>
        </Section>
    )
}
