import { put, take } from 'typed-redux-saga'

import { cleanupRequest, GET, PUT } from '../../generators/networking'
import { IS_DEVELOPMENT_OR_CYPRESS } from '../../utils'
import { TQLConverter } from '../../utils/tqlConverter'
import { Application } from '../applications/types'
import { GatewaysDispatchLoad } from '../gateways/actions'
import { MerchantAccount } from '../merchantAccounts/types'
import { ToastsDispatchPush } from '../toasts/actions'
import { USER_ACCOUNTS_STORE_ACCOUNT, UserAccountsDispatchGetAccount } from '../userAccounts/actions'
import {
    SagasForSettlements,
    SettlementActionLoadMerchantDetails,
    SettlementActionLoadTransactions,
    SettlementDispatchLoadMerchantDetails,
    SettlementDispatchLoadTransactions,
    SettlementDispatchStore,
    SettlementDispatchStoreMerchantDetails,
    SettlementDispatchStoreTransactions,
    SettlementsActionLoadSummaries,
    SettlementsDispatchStoreSummaries,
    SettlementActionReserveCancel,
    SettlementDispatchStoreRelatedLink,
    SettlementActionFetchSettlement,
    SettlementsDispatchFetchSettlement
} from './actions'
import { Settlement } from './types'
import mocks from './mockedSettlements.json'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchOpenSettlementForDate(mid: string, date: string) {
    const query = TQLConverter.settlements({
        settlements_startDate: date,
        settlements_endDate: date,
        settlements_mid: mid,
        settlements_page: 1,
        settlements_per_page: 1
    } as any)

    const response: { settlements: Settlement[] } = yield GET({
        url: `${import.meta.env.VITE_API_ROOT}/settlements${query}`,
        include: ['account']
    })

    if (!response) {
        yield put(ToastsDispatchPush('Failed to query the open settlement', 'error'))
    } else if (response.settlements.length > 1) {
        yield put(ToastsDispatchPush('Open settlement mapped to 2+ settlements', 'error'))
        throw 'Open settlement mapped to 2+ settlements'
    } else {
        return response.settlements?.[0]?.id
    }
}

function* handleEarlyReleaseFetching(settlements: Settlement[]) {
    if (!settlements?.length) return
    for (const settlement of settlements) {
        if (settlement?.reserve?.cancelledDate) {
            const openSettlementId: string | undefined = yield fetchOpenSettlementForDate(
                settlement.account.merchantId,
                settlement.reserve.cancelledDate
            )
            if (openSettlementId) yield put(SettlementDispatchStoreRelatedLink(settlement.id, openSettlementId))
        }
    }
}
export class SettlementsSagas implements SagasForSettlements {
    *loadSummaries(action: SettlementsActionLoadSummaries) {
        const query = TQLConverter.settlements(action.filters)
        const cleanedResponse: {
            settlements: Settlement[]
        } = yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/settlements${query}`,
            include: ['settlements', 'account', 'transactions', 'self'],
            onSuccessDispatch: (r) => {
                if (IS_DEVELOPMENT_OR_CYPRESS()) {
                    const parsedMocks = returnLocallyMockedSettlementsAsCleanedRequest()

                    parsedMocks?.forEach((k: any) => {
                        r.settlements[r.settlements.findIndex((s: any) => s.id === k.id)] = k
                    })

                    return SettlementsDispatchStoreSummaries([...r.settlements], {
                        page: action.filters.settlements_page,
                        perPage: action.filters.settlements_per_page,
                        total: r.count
                    })
                }
                return SettlementsDispatchStoreSummaries(r.settlements, {
                    page: action.filters.settlements_page,
                    perPage: action.filters.settlements_per_page,
                    total: r.count
                })
            },
            errorText: 'Failed to load settlements summaries.'
        })

        yield handleEarlyReleaseFetching(cleanedResponse?.settlements)
    }

    *fetchSettlement(action: SettlementActionFetchSettlement) {
        const settlement: Settlement | undefined = yield fetchSettlementOrLoadMock(action.settlementId)

        if (!settlement) {
            yield put(ToastsDispatchPush('Failed to load settlement', 'error'))
            return
        }
        if (!action.skipSubrequests) yield handleEarlyReleaseFetching([settlement])
        yield put(SettlementDispatchStore({ ...settlement, otherPostingsLoadingStatus: 'done' }))
        if (!action.skipSubrequests)
            yield put(SettlementDispatchLoadTransactions(settlement.id, settlement.transactionsLink))
        yield put(SettlementDispatchLoadMerchantDetails(settlement))
    }

    *loadMerchantDetails(action: SettlementActionLoadMerchantDetails) {
        const merchantDetails: {
            account: MerchantAccount
            application: Application
        } = yield loadMerchantDetails(
            action.settlement.accountLink.substr(action.settlement.accountLink.lastIndexOf('/') + 1)
        )
        yield put(
            SettlementDispatchStoreMerchantDetails({
                settlementId: action.settlement.id,
                ...merchantDetails
            })
        )
    }

    *loadTransactions(action: SettlementActionLoadTransactions) {
        yield* GET({
            url: `${action.transactionsLink}?page=${action.page}`,
            onSuccessDispatch: (r) => {
                return SettlementDispatchStoreTransactions(action.settlementId, {
                    transactions: r.transactions,
                    pagination: {
                        total: r.count,
                        perPage: r.perPage,
                        page: r.page || 1
                    }
                })
            },
            errorText: 'Failed to load settlement transactions.'
        })
    }

    *reserveCancel(action: SettlementActionReserveCancel) {
        yield PUT({
            url: `${import.meta.env.VITE_API_ROOT}/settlements/${action.settlementId}/stamps/cancelled_reserve`,
            onSuccessDispatch: (r) => {
                return SettlementsDispatchFetchSettlement(action.settlementId, true)
            },
            errorText: 'Failed to cancel reserve'
        })
    }
}

function returnLocallyMockedSettlementsAsCleanedRequest() {
    if (!IS_DEVELOPMENT_OR_CYPRESS()) {
        throw new Error('returnLocallyMockedSettlementsAsCleanedRequest() - only available in testing environments')
    }
    
    const parsedMocks = cleanupRequest({ arr: mocks }, ['settlements', 'account', 'transactions', 'self']).arr
    return parsedMocks
}

function* fetchSettlementOrLoadMock(id: string) {
    if (IS_DEVELOPMENT_OR_CYPRESS()) {
        const parsedMocks = returnLocallyMockedSettlementsAsCleanedRequest()
        const settlementOverwrittenByMock = parsedMocks.find((settlement) => settlement.id == id)
        if (settlementOverwrittenByMock) {
            return settlementOverwrittenByMock
        }
    }
    return yield GET({
        url: `${import.meta.env.VITE_API_ROOT}/settlements/${id}`,
        include: ['settlements', 'account', 'transactions', 'self'],
        errorText: 'Failed to load settlements summaries.'
    })
}

function* loadMerchantDetails(accountId: string) {
    yield put(GatewaysDispatchLoad())

    yield put(
        UserAccountsDispatchGetAccount(accountId, {
            isMerchant: true
        })
    )

    let rawMerchant: any
    do {
        rawMerchant = yield* take(USER_ACCOUNTS_STORE_ACCOUNT)
    } while (rawMerchant.id !== accountId)

    const data = {
        account: rawMerchant.data,
        application: undefined
    }

    if (data.account?.mcc) {
        const applicationId = data.account.applicationLink.split('/').pop()
        data.application = yield GET({
            cutterUrl: (l) => `${l.applicationLink + applicationId}?expand=websites,contact,tags`,
            errorText: "Failed to load merchant's application details."
        })
    }

    if (data.account?.mcc) {
        const mccDescription = (
            (yield GET({
                url: `${import.meta.env.VITE_MCC_ENDPOINT}/?limit=1&q=${data.account.mcc}`,
                errorText: "Failed to load task's merchant MCC description."
            })) as any[]
        )[0]

        data.account.mccDescription = mccDescription ? mccDescription.label : undefined
    }

    return data
}
