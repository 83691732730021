import styled from 'styled-components'
import { Text } from '../../components/general/text'

export const MerchantInteractionDateSeparator: React.FC<{
    date: {
        date: string
        at: string
    }
}> = ({ date }) => {
    return (
        <Block>
            <TextHolder>
                <Text color="front.text" bold>
                    {date.date}
                </Text>
            </TextHolder>
        </Block>
    )
}
const Block = styled.div`
    padding: 10px 0;
    width: 100%;
    text-align: center;
    align-self: stretch;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    z-index: 1;

    &:first-child {
        margin-top: 0;
    }

    &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${(p) => p.theme['front.border']};
    }
`

const TextHolder = styled.div`
    position: relative;
    background-color: ${(p) => p.theme['front.background']};
    padding: 2px 20px;
    z-index: 2;
`
