import React from 'react'
import { Styles, styles } from './styles'
import { cn } from '../utils'

interface PreProps extends Styles {
    children: React.ReactNode
}

export const Pre: React.FC<PreProps> = (props) => {
    const { children, whiteSpace } = props

    const classnames = cn(styles({ whiteSpace }))

    return <pre className={classnames}>{children}</pre>
}
