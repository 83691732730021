import { api } from '../api'
import { Account, Accounts } from '../types/accounts'

export const accountsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAccounts: build.query<Accounts, void>({
            query: () => `/accounts`,
            providesTags: ['accounts']
        }),
        getAccount: build.query<Account, string>({
            query: (accountId: string) => `/accounts/${accountId}`,
            providesTags: ['account']
        })
    })
})

export const {
    useGetAccountsQuery: useGetAccounts,
    useGetAccountQuery: useGetAccount,
} = accountsApi
