import { createReducer } from 'deox'
import { produce } from 'immer'
import { Collection } from '../../utils'
import { RootState } from '@/store'
import { ContractTemplateActions } from './actions'
import { AdjustContractOrContractTemplateForLocalStorage } from './helpers'
import { InitialContractTemplatesState } from './types'

const fixWrongBackendForamttingResponse = (draft: RootState['contractTemplates']) => {
    draft.all.forEach((id) => {
        Object.keys(draft.at[id]).forEach((k: string) => {
            const value = (draft.at[id] as any)[k]
            if (value?.endsWith?.('.0')) {
                ;(draft.at[id] as any)[k] = (draft.at[id] as any)[k].replace('.0', '.00')
            }
        })
    })
}

const sortTemplates = (draft: Collection<any>) => {
    draft.all.sort((a, b) => {
        const itemA = draft.at[a]?.label || ''
        const itemB = draft.at[b]?.label || ''

        if (itemA < itemB) {
            return -1
        }
        if (itemA > itemB) {
            return 1
        }
        return 0
    })
}

export const ContractTemplatesReducer = createReducer(InitialContractTemplatesState, (handleAction) => [
    handleAction(ContractTemplateActions.FETCH_ALL, (state) => {
        return produce(state, (draft) => {
            draft.loadingStatus = 'started'
        })
    }),

    handleAction(ContractTemplateActions.STORE_SINGLE, (state, { payload }) => {
        return produce(state, (draft) => {
            if (!payload.template.id) return
            draft.at[payload.template.id] = {
                ...AdjustContractOrContractTemplateForLocalStorage(payload.template)
            }
            draft.all = draft.all.filter((i) => i !== payload.template.id)
            draft.all.push(payload.template.id)
            sortTemplates(draft)
            fixWrongBackendForamttingResponse(draft)
        })
    }),
    handleAction(ContractTemplateActions.STORE_ALL, (state, { payload }) => {
        return produce(state, (draft) => {
            draft.loadingStatus = 'done'
            payload.templates?.forEach((t) => {
                if (!t.id) return
                draft.at[t.id] = {
                    ...AdjustContractOrContractTemplateForLocalStorage(t)
                }
                draft.all = draft.all.filter((i) => i !== t.id)
                draft.all.push(t.id)
            })
            sortTemplates(draft)
            fixWrongBackendForamttingResponse(draft)
        })
    }),
    handleAction(ContractTemplateActions.UNSTORE, (state, { payload }) => {
        return produce(state, (draft) => {
            delete draft.at[payload.templateId]
            draft.all = [...draft.all].filter((id) => id !== payload.templateId)
        })
    })
])
