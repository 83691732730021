import { ActionType, getType } from 'deox'
import { all, call, take } from 'redux-saga/effects'
import { put, select } from 'typed-redux-saga'
import { GET, PATCH } from '../../generators/networking'
import { WatcherDispatchFail, WatcherDispatchStart, WatcherDispatchSuccess } from '../watcher/actions'
import { TasksCompanyChangesActions } from './actions'
import {
    CompanyChangePayload,
    CompanyChangesFromBackend,
    MonitoredChangeKind,
    MonitoredChangesState,
    NeededExtraRequestsPayload
} from './types'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../toasts/actions'
import { cleanMAPIPeopleResponse } from '../applicationResources/sagas'
import { WaitForWatcher } from '../utils'
import { ApplicationDataProvidersActions } from '../applicationDataProviders/actions'

export const TasksCompanyChangesSagas = {
    *REFRESH_APPLICATION({ payload: p }: ActionType<typeof TasksCompanyChangesActions.REFRESH_APPLICATION>) {
        yield put(WatcherDispatchStart([p.watcherId]))
        const resources = yield* fetchNeededApplicationResources([p.applicationId])
        yield put(
            TasksCompanyChangesActions.REFRESH_APPLICATION_STORE_DATA(
                p.taskId,
                p.applicationId,
                resources[p.applicationId]
            )
        )

        yield put(
            WatcherDispatchSuccess([p.watcherId], p.skipNotification ? undefined : 'Application refreshed successfully')
        )
    },
    *APPLY_CHANGE({ payload: p }: ActionType<typeof TasksCompanyChangesActions.APPLY_CHANGE>) {
        if (!p.link) throw new Error("Couldn't edit the resource without it's own link.")

        const { success } = yield PATCH({
            url: p.link,
            successCode: 200,
            body: p.data,
            successText: 'The change was succesfully applied.',
            errorText: 'The change failed and was not applied.'
        })

        if (success) {
            yield put(TasksCompanyChangesActions.REFRESH_APPLICATION(p.watcherId, p.taskId, p.applicationId))
        }
    },
    *CHANGE_MUTED_STATE({ payload: p }: ActionType<typeof TasksCompanyChangesActions.CHANGE_MUTED_STATE>) {
        yield put(WatcherDispatchStart([p.watcherId]))

        const hasIgnoredTheChange = yield WaitForWatcher((id) =>
            put(ApplicationDataProvidersActions.IGNORE_CHANGES(p.applicationId, id, p.payload, true))
        )

        if (hasIgnoredTheChange) {
            const hasRefreshedTheApplication = yield WaitForWatcher((id) =>
                put(TasksCompanyChangesActions.REFRESH_APPLICATION(id, p.taskId, p.applicationId, true))
            )

            if (hasRefreshedTheApplication) {
                yield put(WatcherDispatchSuccess([p.watcherId], 'Value muted successfully'))
            } else {
                yield put(
                    WatcherDispatchFail(
                        [p.watcherId],
                        'Value muted successfully, but failed to refresh the application'
                    )
                )
            }
        } else {
            yield put(WatcherDispatchFail([p.watcherId], 'Failed to mute value'))
        }
    },
    *INIT({ payload: p }: ActionType<typeof TasksCompanyChangesActions.INIT>) {
        const appIds = yield* getApplicationIds(p.changes)
        const extraResources = yield* fetchNeededApplicationResources(appIds)

        const changesArray: CompanyChangePayload[] = []
        for (const c of p.changes) {
            const change = c.data.data.changes
            // eslint-disable-next-line max-len
            const taskNotReadySuffix =
                // eslint-disable-next-line max-len
                "This company info task doesn't look right, the non-PCI/console team is working on it. Please ignore this task for now and do not close/open it."
            if (!change) {
                yield put(
                    ToastsDispatchPush(`changes array is undefined`, 'error', undefined, 'infinite', taskNotReadySuffix)
                )
                continue
            }
            for (const item of change) {
                changesArray.push(item)
                if (!item?.recordValues) {
                    yield put(
                        ToastsDispatchPush(
                            `record_values is undefined`,
                            'error',
                            undefined,
                            'infinite',
                            taskNotReadySuffix
                        )
                    )
                    continue
                }
            }
        }

        const parsedChangesByCompanyId = yield parseReceivedChanges(changesArray, extraResources)

        yield put(TasksCompanyChangesActions.STORE(p.taskId, parsedChangesByCompanyId))
    }
}

function* parseReceivedChanges(
    items: CompanyChangesFromBackend[0]['data']['data']['changes'],
    extraResources: NeededExtraRequestsPayload
) {
    const changesByCompanyId: MonitoredChangesState = {}

    for (const item of items) {
        for (const app of item?.recordValues) {
            const id = app.recordId

            if (!changesByCompanyId[id]) {
                changesByCompanyId[id] = {
                    changes: [],
                    applicationId: app.recordId,
                    fetchedApplicationData: {
                        loadingStatus: 'not-started',
                        data: undefined
                    }
                }
            }

            const change = {
                applicationValue: app.value,
                before: app.value,
                field: item.path,
                subsectionId: app.resourceId,
                after: item.values,
                kind: yield inferMonitoredChangeKind(item.op, item.path)
            }

            changesByCompanyId[id].changes.push(change)

            changesByCompanyId[id].fetchedApplicationData = {
                loadingStatus: 'done',
                data: {
                    ...extraResources[id]
                }
            }
        }
    }
    return changesByCompanyId
}

function* getApplicationIds(changes: CompanyChangesFromBackend) {
    const acc: any = {}
    for (const c of changes) {
        const change = c.data.data.changes
        if (!change) continue
        for (const item of change) {
            if (!item?.recordValues) continue
            for (const app of item?.recordValues) {
                acc[app.recordId] = true
            }
        }
    }
    return Object.keys(acc)
}

function* inferMonitoredChangeKind(op: string, path: string) {
    const returnKind: () => MonitoredChangeKind = () => {
        if (path === '/company/name' && op === 'replace') {
            return 'company-name-replace'
        }
        if (path.includes('/ownership/beneficial')) {
            if (op === 'add') return 'ownership-beneficial-add'
        }

        if (path === '/director/name') {
            if (op === 'replace') return 'director-name-replace'
        }

        return 'other'
    }

    return returnKind()
}
function* fetchNeededApplicationResources(applicationIds: string[]) {
    const genericApplicationLink = yield* select((state: RootState) => state.global?.links?.cutter.applicationLink)

    const cutterRequests = applicationIds.map((id) =>
        GET({
            url: `${genericApplicationLink}${id}?expand=people`,
            errorText: 'Failed to load Application.',
            include: ['people', 'self']
        })
    )

    const applicationsRequests = applicationIds.map((id) =>
        GET({
            mapiUrl: (r) => r.applicationsLink + '/' + id,
            errorText: "Failed to fetch tasks' application data.",
            include: ['company', 'self', 'comments']
        })
    )

    const dataProvidersRequests = applicationIds.map((id) =>
        call(function* () {
            let response: any = undefined
            yield put(ApplicationDataProvidersActions.FETCH(id))
            do {
                const dataProvidersStore: any = yield take(getType(ApplicationDataProvidersActions.STORE))
                response = dataProvidersStore.payload
            } while (response.applicationId !== id)
            return response
        })
    )

    const [cutterResponses, applications, dataProvidersResponses] = yield all([
        all(cutterRequests),
        all(applicationsRequests),
        all(dataProvidersRequests)
    ])

    const companiesRequests = applications.map((app: any) =>
        GET({
            url: app?.companyLink,
            errorText: "Failed to fetch tasks' company application data.",
            include: ['people', 'application', 'self']
        })
    )
    const companies = yield all(companiesRequests)

    const peopleRequests = companies.map((app: any) =>
        GET({
            url: app.peopleLink,
            errorText: "Failed to fetch tasks' people application data.",
            include: ['self', 'comments', 'files']
        })
    )
    const peopleApplicationsRequests = (yield all(peopleRequests)).map((r) => cleanMAPIPeopleResponse(r)) as any

    const filesRequests = peopleApplicationsRequests.reduce(
        (acc, app) => {
            app.people.forEach((p: any) => {
                const filesRequest = GET({
                    url: p.filesLink,
                    errorText: "Failed to fetch tasks' people application data."
                })
                acc.requests.push(filesRequest)
                acc.ids.push(p.id)
            })
            return acc
        },
        { requests: [], ids: [] }
    )

    const files = yield all(filesRequests.requests)
    const filesByPersonId = filesRequests.ids.reduce((acc, userId, userIndex) => {
        if (!acc[userId]) acc[userId] = {}
        files?.[userIndex]?.files?.map?.((f) => {
            acc[userId][f.label] = f
        })
        return acc
    }, {} as any)

    const peopleWithCutterDetailsIncluded = applications.map((app, i) => {
        return peopleApplicationsRequests[i].people.map((p) => {
            const cutterDetails = cutterResponses[i].people.find((cp) => cp.id == p.id)
            // Convert cutter ownership string to a number
            cutterDetails.ownershipPercentage = cutterDetails.ownershipPercentage
                ? parseFloat(cutterDetails.ownershipPercentage)
                : undefined
            return {
                ...p,
                cutter: cutterDetails
            }
        })
    })

    return applications.reduce((acc, app, i) => {
        acc[app.id] = {
            application: app,
            company: companies[i],
            muted: dataProvidersResponses[i].mutedConflicts,
            providerOwnership: dataProvidersResponses[i].data?.ownership?.beneficial,
            people: peopleWithCutterDetailsIncluded[i],
            filesByPersonId
        }
        return acc
    }, {}) as NeededExtraRequestsPayload
}
