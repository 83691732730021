import type { ReactNode } from 'react'
import React, { createContext, useContext } from 'react'
import { cn, isTextSelected } from '../utils'
import { BodyStyles, CellStyles, HeadStyles, RowStyles, TableStyles, styles } from './styles'

interface Context {
    variant?: 'default' | 'striped' | null
    stripeShade?: '50' | '100' | null
}
const defaultValues: Context = { variant: undefined, stripeShade: undefined }
const Context = createContext<Context>(defaultValues)

interface TableProps extends TableStyles {
    testid?: string
    children: ReactNode
}

const Table = (props: TableProps) => {
    const { testid, children, variant, stripeShade, layout } = props

    const value = { variant, stripeShade }

    const classnames = cn(styles.table({ layout }))

    return (
        <Context.Provider value={value}>
            <table data-cy={testid} className={classnames}>
                {children}
            </table>
        </Context.Provider>
    )
}

interface RowProps extends RowStyles {
    testid?: string
    children: ReactNode
    onClick?: (_e: React.MouseEvent) => void
}

const Row: React.FC<RowProps> = (props) => {
    const { testid, children, onClick } = props
    const { variant, stripeShade } = useContext(Context)

    const handleOnClick = (e: React.MouseEvent) => {
        if (onClick) {
            if (!isTextSelected()) {
                onClick(e)
            }
        }
    }

    const classnames = cn(
        styles.row({ variant, stripeShade }),
        onClick,
        onClick && 'cursor-pointer hover:bg-neutral-100 dark:hover:bg-gray-700'
    )

    return (
        <tr data-cy={testid} onClick={handleOnClick} className={classnames}>
            {children}
        </tr>
    )
}

interface Header {
    testid?: string
    children: ReactNode
}

const Header: React.FC<Header> = (props) => {
    const { testid, children } = props

    return <thead data-cy={testid}>{children}</thead>
}

interface HeadProps extends HeadStyles {
    testid?: string
    children: ReactNode
    colSpan?: number
}

const Head: React.FC<HeadProps> = (props) => {
    const { testid, children, colSpan, justify, width } = props

    const classnames = cn(styles.head({ justify, width }))

    return (
        <th data-cy={testid} className={classnames} colSpan={colSpan}>
            {children}
        </th>
    )
}

interface BodyProps extends BodyStyles {
    testid?: string
    children: ReactNode
}

const Body: React.FC<BodyProps> = (props) => {
    const { testid, children } = props

    const classnames = cn(styles.body())

    return (
        <tbody data-cy={testid} className={classnames}>
            {children}
        </tbody>
    )
}

interface CellProps extends CellStyles {
    testid?: string
    children: ReactNode
    colSpan?: number
}

const Cell: React.FC<CellProps> = (props) => {
    const { testid, children, colSpan, justify, width } = props

    const classnames = cn(styles.cell({ justify, width }))

    return (
        <td data-cy={testid} className={classnames} colSpan={colSpan}>
            {children}
        </td>
    )
}

Table.Row = Row
Table.Header = Header
Table.Head = Head
Table.Body = Body
Table.Cell = Cell

export { Table }
