import {
    Classification, 
    OwnershipManagement,
    BusinessModel,
    Location,
    Others,
    Comment
} from '@/services/risk-classification'
import React, { createContext, useContext, useMemo } from 'react'
import { defaultClassification } from './default-classification'

interface Context {
    classification: Classification
}

const ClassificationContext = createContext<Context>({ classification: defaultClassification })

interface ClassificationProps {
    children: React.ReactNode
    classification: Classification
}

export const ClassificationProvider: React.FC<ClassificationProps> = (props) => {
    const { children, classification } = props

    const value = useMemo(() => {
        return { classification }
    }, [classification])

    return <ClassificationContext.Provider value={value}>{children}</ClassificationContext.Provider>
}

export const useClassificationContext = () => {
    return useContext(ClassificationContext)
}

type CheckName = 'ownership' | 'business' | 'location' | 'others' | 'comment'
type Checks = OwnershipManagement | BusinessModel | Location | Others | Comment

export const useClassificationChecks = <T extends Checks>(name: CheckName) => {
    const { classification } = useContext(ClassificationContext)

    let check
    switch (name) {
        case 'ownership': {
            check = classification.classification_data['Ownership / Management']
            break
        }
        case 'business': {
            check = classification.classification_data['Business model']
            break
        }
        case 'location': {
            check = classification.classification_data.Location
            break
        }
        case 'others': {
            check = classification.classification_data.Others
            break
        }
        case 'comment': {
            check = classification.classification_data.Comment
            break
        }
        default: {
            throw Error('Section name not provided')
        }
    }

    const result: T = useMemo(() => {
        return check
    }, [check])

    return result
}
