import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Text } from '../../../components/general/text'
import { Spacer } from '../../../components/layout/spacer'

const LIMIT = 160
export const MerchantSummaryCompanyInformationBusinessModel: React.FC<{ text?: string }> = ({ text }) => {
    const [showMore, setShowMore] = useState(false)

    const shortedText = useMemo(() => {
        if (!text) return 'No business model provided'
        if (text.length < LIMIT) return text
        if (showMore) return text
        return text.substring(0, LIMIT) + '…'
    }, [text, showMore])

    return (
        <Text>
            {shortedText}
            {(text?.length || 0) > LIMIT ? (
                <>
                    <Spacer width={5} inline />
                    <Plus onClick={() => setShowMore((s) => !s)}>{showMore ? '-' : '+'}</Plus>
                </>
            ) : null}
        </Text>
    )
}

const Plus = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 99px;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    justify-content: center;
    line-height: 12px;
    border: 1px solid ${(p) => p.theme['front.border']};

    &:hover {
        background-color: ${(p) => p.theme['front.background.strongerI']};
    }
    &:active {
        background-color: ${(p) => p.theme['front.background.strongerII']};
    }
`
