import { ApplicationFilterToHumanizedStates } from '../../store/applications/types'

export interface FastLink {
    title: string
    description: string
    tags: string
    url: string
}

const LINKS: { [key: string]: FastLink } = {}

const setFastLink = (opt: FastLink) => {
    LINKS[opt.title] = opt
}

const buildFastLinks = () => {
    setFastLink({
        title: 'All Applications',
        description: 'Go to all the applications',
        tags: 'global applications',
        url: '/applications'
    })

    Object.keys(ApplicationFilterToHumanizedStates).map((sId) => {
        const state = (ApplicationFilterToHumanizedStates as any)[sId as any]
        if (state.with) {
            setFastLink({
                title: `Applications with ${state.with} that are ${state.thatAre}`,
                // eslint-disable-next-line max-len
                description: `Go to the applications and list the ones that have  ${state.with} that are ${state.thatAre}.`,
                tags: `global ${state.with} ${state.thatAre}`,
                url: `/applications?applications_status=${sId}`
            })
        } else {
            setFastLink({
                title: `Applications that are ${state.thatAre}`,
                // eslint-disable-next-line max-len
                description: `Go to the applications and list the ones that are ${state.thatAre}.`,
                tags: `global ${state.thatAre} applications ${state.thatAre}`,
                url: `/applications?applications_status=${sId}`
            })
        }
    }, [])

    setFastLink({
        title: 'Disputes',
        description: 'Go to the global disputes page',
        tags: 'global disputes',
        url: '/disputes'
    })

    setFastLink({
        title: 'Alerts',
        description: 'Go to the global alerts page',
        tags: 'global alerts',
        url: '/alerts'
    })

    setFastLink({
        title: 'Transactions',
        description: 'Go to the global transactions page',
        tags: 'global transactions',
        url: '/transactions'
    })

    setFastLink({
        title: 'Settlements',
        description: 'Go to the global settlements page',
        tags: 'global settlements',
        url: '/settlements'
    })

    setFastLink({
        title: 'Tasks',
        description: 'Go to the global tasks page',
        tags: 'global tasks',
        url: '/tasks'
    })

    setFastLink({
        title: 'ODD tasks',
        description: 'Go to the global ODD tasks page',
        tags: 'global odd tasks',
        url: '/odd-tasks'
    })

    setFastLink({
        title: 'Manage gateways',
        description: 'Go to the manage gateways page',
        tags: 'manage gateways',
        url: '/more/gateways'
    })

    setFastLink({
        title: 'Manage vouchers',
        description: 'Go to the manage vouchers page',
        tags: 'manage vouchers',
        url: '/more/vouchers'
    })

    setFastLink({
        title: 'Manage contract templates',
        description: 'Go to the manage contract templates page',
        tags: 'manage contract templates pricing schemes',
        url: '/more/contract-templates'
    })
}
buildFastLinks()

export function getFastLinksForValue(value: string): FastLink[] {
    const matchingOptions: any = []

    Object.keys(LINKS).forEach((l) => {
        const option = LINKS[l]
        if ((' ' + option['tags'].toLowerCase()).includes(' ' + value.toLowerCase())) {
            matchingOptions.push(option)
        }
    })

    return matchingOptions
}

export const FastLinks = LINKS
