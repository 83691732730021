import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { WatcherButton } from '../buttons/watcherButton'
import { Card } from '../cards/card'
import { CardInset } from '../cards/cardInset'
import { CardSection } from '../cards/cardSection'
import { Text } from '../general/text'
import { Flex } from '../layout/flex'
import { ModalPage } from '../layout/modalPage'
import { PageWrapper } from '../layout/pageWrapper'
import { Spacer } from '../layout/spacer'
import { ModalHeader } from '../modals/modalHeader'
import { FormState } from '../../hooks/general/useForm'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { ReviewChangesTable } from './reviewChangesTable'

export const useChangesReviewer = ({
    changesArr,
    onChangesApproved,
    watcherId,
    fieldFormatter,
    buttonText,
    valueFormatter,
    title,
    description
}: {
    changesArr: {
        [key: string]: { from: string; to: string }
    }
    onChangesApproved: (data: FormState) => void
    watcherId: string
    fieldFormatter: (k: any) => any
    valueFormatter: (key: string, from: any, to: any) => { col: string; from: string; to: string }
    buttonText: string
    title?: string
    description?: string | ReactElement
}): {
    startReview: (form: any) => void
    UI: ReactElement
} => {
    const formData = useRef<FormState>({
        data: {},
        changes: {}
    })
    const [reviewStarted, setReviewStarted] = useState<boolean>(false)
    const [watcher] = useNamedWatcher(watcherId)
    const startReview = useCallback((form) => {
        setReviewStarted(true)
        formData.current = form
    }, [])

    const handleOnBack = useCallback(() => {
        setReviewStarted(false)
    }, [])

    useEffect(() => {
        if (watcher === 'success') handleOnBack()
    }, [watcher, handleOnBack])

    const makeTheChanges = useCallback(() => {
        if (formData?.current) onChangesApproved?.(formData.current)
    }, [onChangesApproved])

    const UI = useMemo(() => {
        if (!reviewStarted) return <></>
        return (
            <ModalPage
                title="Confirm changes"
                onBack={handleOnBack}
                pageId="Merchant.ApplicationPage.ReviewChanges"
                overlay
            >
                <ModalHeader
                    onBack={handleOnBack}
                    pageId="Merchant.ApplicationPage.ReviewChanges"
                    title={title}
                    overlay
                />
                <PageWrapper>
                    <Flex justify="center" align="center" column grow>
                        <Card higher title={title}>
                            <CardInset>
                                <Text>{description}</Text>
                                <Spacer height={30} />
                                <ReviewChangesTable
                                    formatChangeRow={valueFormatter}
                                    changesArr={changesArr}
                                    fieldFormatter={fieldFormatter}
                                />
                                <CardSection background="subtleBlue">
                                    <CardInset>
                                        <Flex grow justify="space-between">
                                            <SimpleButton
                                                background="front.background"
                                                onClick={handleOnBack}
                                                cy="cancel-review"
                                            >
                                                <ButtonInset>Cancel</ButtonInset>
                                            </SimpleButton>
                                            <Spacer width={10} />
                                            <WatcherButton
                                                predefinedWatcher={watcherId}
                                                background="front.accent.color"
                                                cy="confirm-review"
                                                onClick={makeTheChanges}
                                            >
                                                <ButtonInset>{buttonText}</ButtonInset>
                                            </WatcherButton>
                                        </Flex>
                                    </CardInset>
                                </CardSection>
                            </CardInset>
                        </Card>
                    </Flex>
                </PageWrapper>
            </ModalPage>
        )
    }, [
        reviewStarted,
        handleOnBack,
        description,
        title,
        makeTheChanges,
        fieldFormatter,
        valueFormatter,
        watcherId,
        changesArr,
        buttonText
    ])

    return {
        startReview,
        UI
    }
}
