import React, { ReactElement, useCallback } from 'react'

import { PaymentMethods, TransactionsFilters } from '../../store/transactions/types'
import { TextInputSelectMultiple } from '../forms/textInputSelectMultiple'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'
import { PaymentDescriptionDictionary } from '../icons/iconPaymentMethod'

export const ListPageFilterPaymentMethod = ({
    filters,
    isDark
}: {
    filters: FiltersController<TransactionsFilters>
    isDark: boolean
}): ReactElement => {
    const textForItem = useCallback((key: any) => {
        return (PaymentDescriptionDictionary as any)[key as any]
    }, [])

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                transactions_method: items
            }))
        },
        [filters]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelectMultiple
                preSelectedItems={filters.get.transactions_method}
                isHigher={isDark}
                isSeamless
                textForItem={textForItem}
                dropdownId="ListPage.Filters.TransactionPaymentMethod"
                onSelect={onSelect}
                placeholder="-"
                items={PaymentMethods}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                selectionLabel="method/methods"
            />
            <SelectedListForListPageFilters<TransactionsFilters>
                filterKey="transactions_method"
                filters={filters}
                isDark={isDark}
                noHolderEmphasize={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
