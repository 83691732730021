import { CollectionWithPagination, Pagination } from '../../utils'
import { DraftContract } from '../contracts/types'
import { File } from '../files/types'
import { WatcherID } from '../watcher/types'
import {
    Application,
    ApplicationsFilters,
    ApplicationsMainTableEntry,
    ApplicationStatus,
    CutterApplication,
    CutterComment,
    CutterCommentType
} from './types'

export const APPLICATIONS_LOAD_ENTRIES = 'APPLICATIONS_LOAD_ENTRIES'
export const APPLICATIONS_STORE_ENTRIES = 'APPLICATIONS_STORE_ENTRIES'
export const APPLICATIONS_LOAD_APPLICATION = 'APPLICATIONS_LOAD_APPLICATION'
export const APPLICATIONS_STORE_APPLICATION = 'APPLICATIONS_STORE_APPLICATION'
export const APPLICATIONS_LOAD_APPLICATION_RESOURCE = 'APPLICATIONS_LOAD_APPLICATION_RESOURCE'
export const APPLICATIONS_STORE_APPLICATION_RESOURCE = 'APPLICATIONS_STORE_APPLICATION_RESOURCE'
export const APPLICATIONS_EDIT_ENTITY = 'APPLICATIONS_EDIT_ENTITY'
export const APPLICATIONS_STORE_ENTITY = 'APPLICATIONS_STORE_ENTITY'
export const APPLICATIONS_SAVE_LAST_APPLICATION_ROUTE = 'APPLICATIONS_SAVE_LAST_APPLICATION_ROUTE'
export const APPLICATIONS_LOAD_CVR_INFO = 'APPLICATIONS_LOAD_CVR_INFO'
export const APPLICATIONS_STORE_CVR_INFO = 'APPLICATIONS_STORE_CVR_INFO'
export const APPLICATIONS_LOAD_BVD_INFO = 'APPLICATIONS_LOAD_BVD_INFO'
export const APPLICATIONS_STORE_BVD_INFO = 'APPLICATIONS_STORE_BVD_INFO'
export const APPLICATIONS_CLEANUP = 'APPLICATIONS_CLEANUP'
export const APPLICATIONS_UPDATE_DRAFT_AGREEMENT = 'APPLICATIONS_UPDATE_DRAFT_AGREEMENT'
export const APPLICATIONS_STORE_DRAFT_AGREEMENT = 'APPLICATIONS_STORE_DRAFT_AGREEMENT'
export const APPLICATIONS_CREATE_DRAFT_AGREEMENT = 'APPLICATIONS_CREATE_DRAFT_AGREEMENT'
export const APPLICATIONS_REFUSE_DRAFT_AGREEMENT = 'APPLICATIONS_REFUSE_DRAFT_AGREEMENT'
export const APPLICATIONS_APPROVE_DRAFT_AGREEMENT = 'APPLICATIONS_APPROVE_DRAFT_AGREEMENT'
export const APPLICATIONS_ADD_TAG = 'APPLICATIONS_ADD_TAG'
export const APPLICATIONS_REMOVE_TAG = 'APPLICATIONS_REMOVE_TAG'
export const APPLICATIONS_LINK_FILES = 'APPLICATIONS_LINK_FILES'
export const APPLICATIONS_UNLINK_FILES = 'APPLICATIONS_UNLINK_FILES'
export const APPLICATIONS_COLLABORATOR_RESEND = 'APPLICATIONS_COLLABORATOR_RESEND'
export const APPLICATIONS_COLLABORATOR_REVOKE = 'APPLICATIONS_COLLABORATOR_REVOKE'
export const APPLICATIONS_COLLABORATOR_INVITE = 'APPLICATIONS_COLLABORATOR_INVITE'
export const APPLICATIONS_FETCH_CUTTER_INFO = 'APPLICATIONS_FETCH_CUTTER_INFO'
export const APPLICATIONS_STORE_CUTTER_COMMENTS_INFO = 'APPLICATIONS_STORE_CUTTER_COMMENTS_INFO'
export const APPLICATIONS_UPDATE_CUTTER_COMMENTS_INFO = 'APPLICATIONS_UPDATE_CUTTER_COMMENTS_INFO'
export const APPLICATIONS_STORE_NEW_STATUS = 'APPLICATIONS_STORE_NEW_STATUS'
export const APPLICATIONS_STORE_INTERNAL_INTERACTIONS = 'APPLICATIONS_STORE_INTERNAL_INTERACTIONS'
export const APPLICATIONS_STORE_INTERNAL_COMMENT = 'APPLICATIONS_STORE_INTERNAL_COMMENT'
export const APPLICATIONS_STORE_INTERNAL_FILES = 'APPLICATIONS_STORE_INTERNAL_FILES'
export const APPLICATIONS_UNSTORE_INTERNAL_FILES = 'APPLICATIONS_UNSTORE_INTERNAL_FILES'
export const APPLICATIONS_SEND_INTERNAL_COMMENT = 'APPLICATIONS_SEND_INTERNAL_COMMENT'
export const APPLICATIONS_REMOVE_INTERNAL_COMMENT = 'APPLICATIONS_REMOVE_INTERNAL_COMMENT'
export const APPLICATIONS_UNSTORE_INTERNAL_COMMENT = 'APPLICATIONS_UNSTORE_INTERNAL_COMMENT'
export const APPLICATIONS_MARK_COMMENT_AS_READ = 'APPLICATIONS_MARK_COMMENT_AS_READ'
export const APPLICATIONS_STORE_READ_COMMENT = 'APPLICATIONS_STORE_READ_COMMENT'

type BVDInfoType = 'details' | 'ownership'
//--
export interface ApplicationsActionLoadEntries {
    type: typeof APPLICATIONS_LOAD_ENTRIES
    filters: ApplicationsFilters
}
export interface ApplicationsActionStoreEntries {
    type: typeof APPLICATIONS_STORE_ENTRIES
    summaries: CollectionWithPagination<ApplicationsMainTableEntry, Pagination>
}
export interface ApplicationsActionLoadApplication {
    type: typeof APPLICATIONS_LOAD_APPLICATION
    id: string
    fetch: 'full-application' | 'merchant-details'
}

export interface ApplicationsActionStoreApplicationResource {
    type: typeof APPLICATIONS_STORE_APPLICATION_RESOURCE
    applicationId: string
    name: string
    contents: any
}
export interface ApplicationsActionStoreApplication {
    type: typeof APPLICATIONS_STORE_APPLICATION
    application: Application
}

export interface ApplicationsActionEditEntity {
    type: typeof APPLICATIONS_EDIT_ENTITY
    watcher: WatcherID
    editLink: string
    path: string
    changes: { [key: string]: string }
}
export interface ApplicationsActionStoreEntity {
    type: typeof APPLICATIONS_STORE_ENTITY
    watcher: WatcherID
    path: string
    entity: any
}
export interface ApplicationsActionSaveLastRoute {
    type: typeof APPLICATIONS_SAVE_LAST_APPLICATION_ROUTE
    route: string
}
export interface ApplicationsActionLoadCVRInfo {
    type: typeof APPLICATIONS_LOAD_CVR_INFO
    applicationId: string
}
export interface ApplicationsActionStoreCVRInfo {
    type: typeof APPLICATIONS_STORE_CVR_INFO
    applicationId: string
    data: any
}
export interface ApplicationsActionLoadBVDInfo {
    type: typeof APPLICATIONS_LOAD_BVD_INFO
    applicationId: string
}
export interface ApplicationsActionStoreBVDInfo {
    type: typeof APPLICATIONS_STORE_BVD_INFO
    applicationId: string
    infoType: BVDInfoType
    data: any
}
export interface ApplicationsActionCleanup {
    applicationId: string
    type: typeof APPLICATIONS_CLEANUP
}
export interface ApplicationsActionUpdateDraftAgreement {
    type: typeof APPLICATIONS_UPDATE_DRAFT_AGREEMENT
    watcherId: string
    agreementLink: string
    payload: Partial<DraftContract>
    agreementPath: string
}
export interface ApplicationsActionStoreNewStatus {
    type: typeof APPLICATIONS_STORE_NEW_STATUS
    status: ApplicationStatus
    applicationId: string
}
export interface ApplicationsActionStoreDraftAgreement {
    type: typeof APPLICATIONS_STORE_DRAFT_AGREEMENT
    watcherId: string
    agreementPath: string
    payload: Partial<DraftContract>
}
export interface ApplicationsActionCreateDraftAgreement {
    type: typeof APPLICATIONS_CREATE_DRAFT_AGREEMENT
    watcherId: string
    agreementLink: string
    agreementPath: string
}
export interface ApplicationsActionRefuseDraftAgreement {
    type: typeof APPLICATIONS_REFUSE_DRAFT_AGREEMENT
    watcherId: string
    agreementLink: string
    agreementPath: string
}
export interface ApplicationsActionApproveDraftAgreement {
    type: typeof APPLICATIONS_APPROVE_DRAFT_AGREEMENT
    watcherId: string
    agreementLink: string
    agreementPath: string
}
export interface ApplicationsActionLinkFiles {
    type: typeof APPLICATIONS_LINK_FILES
    applicationId: string
    path: string
    files: File[]
}
export interface ApplicationsActionUnlinkFiles {
    type: typeof APPLICATIONS_UNLINK_FILES
    applicationId: string
    path: string
    files: File[]
}

export interface ApplicationsActionStoreCutterCommentsInfo {
    type: typeof APPLICATIONS_STORE_CUTTER_COMMENTS_INFO
    applicationId: string
    cutterCommentsInfo: any
}

export interface ApplicationsActionUpdateCutterCommentsInfo {
    type: typeof APPLICATIONS_UPDATE_CUTTER_COMMENTS_INFO
    applicationId: string
}

export interface ApplicationsActionFetchCutterInfo {
    type: typeof APPLICATIONS_FETCH_CUTTER_INFO
    applicationId: string
    watcherId?: string
}

export interface ApplicationsActionStoreInternalInteractions {
    type: typeof APPLICATIONS_STORE_INTERNAL_INTERACTIONS
    application: CutterApplication
}

export interface ApplicationsActionSendInternalComment {
    type: typeof APPLICATIONS_SEND_INTERNAL_COMMENT
    field: CutterCommentType
    commentsLink: string
    applicationId: string
    body: string
    watcherID: string
}

export interface ApplicationsActionStoreInternalComment {
    type: typeof APPLICATIONS_STORE_INTERNAL_COMMENT
    applicationId: string
    comment: any
}

export interface ApplicationsActionStoreInternalFiles {
    type: typeof APPLICATIONS_STORE_INTERNAL_FILES
    applicationId: string
    files: any
}
export interface ApplicationsActionUnstoreInternalFiles {
    type: typeof APPLICATIONS_UNSTORE_INTERNAL_FILES
    applicationId: string
    files: any
}
export interface ApplicationsActionRemoveInternalComment {
    type: typeof APPLICATIONS_REMOVE_INTERNAL_COMMENT
    watcherID: WatcherID
    applicationId: string
    comment: CutterComment
}

export interface ApplicationsActionUnstoreInternalComment {
    type: typeof APPLICATIONS_UNSTORE_INTERNAL_COMMENT
    applicationId: string
    comment: CutterComment
}

export interface ApplicationsActionMarkCommentAsRead {
    type: typeof APPLICATIONS_MARK_COMMENT_AS_READ
    applicationId: string
    commentId: string
    waitForCommentsInfo?: boolean
}

export interface ApplicationsActionStoreReadComment {
    type: typeof APPLICATIONS_STORE_READ_COMMENT
    applicationId: string
    commentId: string
}

//---

export const ApplicationsDispatchLoadEntries = (filters: ApplicationsFilters): ApplicationsActionLoadEntries => {
    return { type: APPLICATIONS_LOAD_ENTRIES, filters }
}

export const ApplicationsDispatchStoreNewStatus = (
    status: ApplicationStatus,
    applicationId: string
): ApplicationsActionStoreNewStatus => {
    return {
        type: APPLICATIONS_STORE_NEW_STATUS,
        status,
        applicationId
    }
}

export const ApplicationsDispatchStoreEntries = (
    summaries: CollectionWithPagination<ApplicationsMainTableEntry, Pagination>
): ApplicationsActionStoreEntries => {
    return { type: APPLICATIONS_STORE_ENTRIES, summaries }
}

export const ApplicationsDispatchLoadApplication = (
    id: string,
    fetch: 'full-application' | 'merchant-details'
): ApplicationsActionLoadApplication => {
    return { type: APPLICATIONS_LOAD_APPLICATION, id, fetch }
}

export const ApplicationsDispatchStoreApplication = (application: any): ApplicationsActionStoreApplication => {
    return { type: APPLICATIONS_STORE_APPLICATION, application }
}

export const ApplicationsDispatchStoreApplicationResource = (
    name: string,
    applicationId: string,
    contents: string
): ApplicationsActionStoreApplicationResource => {
    return {
        type: APPLICATIONS_STORE_APPLICATION_RESOURCE,
        name,
        contents,
        applicationId
    }
}

export const ApplicationsDispatchSaveLastRoute = (route: string): ApplicationsActionSaveLastRoute => {
    return { type: APPLICATIONS_SAVE_LAST_APPLICATION_ROUTE, route }
}

export const ApplicationsDispatchEditEntity = (
    watcher: WatcherID,
    editLink: string,
    path: string,
    changes: { [key: string]: string }
): ApplicationsActionEditEntity => {
    return { type: APPLICATIONS_EDIT_ENTITY, watcher, editLink, path, changes }
}

export const ApplicationsDispatchStoreEntity = (
    watcher: WatcherID,
    path: string,
    entity: any
): ApplicationsActionStoreEntity => {
    return { type: APPLICATIONS_STORE_ENTITY, watcher, path, entity }
}

export const ApplicationsDispatchLoadCVRInfo = (applicationId: string): ApplicationsActionLoadCVRInfo => {
    return { type: APPLICATIONS_LOAD_CVR_INFO, applicationId }
}

export const ApplicationsDispatchStoreCVRInfo = (applicationId: string, data: any): ApplicationsActionStoreCVRInfo => {
    return { type: APPLICATIONS_STORE_CVR_INFO, applicationId, data }
}

export const ApplicationsDispatchLoadBVDInfo = (applicationId: string): ApplicationsActionLoadBVDInfo => {
    return { type: APPLICATIONS_LOAD_BVD_INFO, applicationId }
}

export const ApplicationsDispatchStoreBVDInfo = (
    applicationId: string,
    infoType: BVDInfoType,
    data: any
): ApplicationsActionStoreBVDInfo => {
    return { type: APPLICATIONS_STORE_BVD_INFO, applicationId, infoType, data }
}

export const ApplicationsDispatchCleanup = (applicationId: string): ApplicationsActionCleanup => {
    return { type: APPLICATIONS_CLEANUP, applicationId }
}

export const ApplicationsDispatchUpdateDraftAgreement = (
    watcherId: string,
    agreementLink: string,
    payload: Partial<DraftContract>,
    agreementPath: string
): ApplicationsActionUpdateDraftAgreement => {
    return {
        type: APPLICATIONS_UPDATE_DRAFT_AGREEMENT,
        watcherId,
        agreementLink,
        payload,
        agreementPath
    }
}

export const ApplicationsDispatchStoreDraftAgreement = (
    watcherId: string,
    agreementPath: string,
    payload: Partial<DraftContract>
): ApplicationsActionStoreDraftAgreement => {
    return {
        type: APPLICATIONS_STORE_DRAFT_AGREEMENT,
        watcherId,
        agreementPath,
        payload
    }
}

export const ApplicationsDispatchCreateDraftAgreement = (
    watcherId: string,
    agreementLink: string,
    agreementPath: string
): ApplicationsActionCreateDraftAgreement => {
    return {
        type: APPLICATIONS_CREATE_DRAFT_AGREEMENT,
        watcherId,
        agreementLink,
        agreementPath
    }
}

export const ApplicationsDispatchApproveDraftAgreement = (
    watcherId: string,
    agreementLink: string,
    agreementPath: string
): ApplicationsActionApproveDraftAgreement => {
    return {
        type: APPLICATIONS_APPROVE_DRAFT_AGREEMENT,
        watcherId,
        agreementLink,
        agreementPath
    }
}

export const ApplicationsDispatchRefuseDraftAgreement = (
    watcherId: string,
    agreementLink: string,
    agreementPath: string
): ApplicationsActionRefuseDraftAgreement => {
    return {
        type: APPLICATIONS_REFUSE_DRAFT_AGREEMENT,
        watcherId,
        agreementLink,
        agreementPath
    }
}

export const ApplicationsDispatchLinkFiles = (
    applicationId: string,
    path: string,
    files: File[]
): ApplicationsActionLinkFiles => {
    return { type: APPLICATIONS_LINK_FILES, applicationId, path, files }
}

export const ApplicationsDispatchUnlinkFiles = (
    applicationId: string,
    path: string,
    files: File[]
): ApplicationsActionUnlinkFiles => {
    return { type: APPLICATIONS_UNLINK_FILES, applicationId, path, files }
}

export const ApplicationsDispatchStoreCutterCommentsInfo = (
    applicationId: string,
    cutterCommentsInfo: any
): ApplicationsActionStoreCutterCommentsInfo => {
    return {
        type: APPLICATIONS_STORE_CUTTER_COMMENTS_INFO,
        applicationId,
        cutterCommentsInfo
    }
}

export const ApplicationsDispatchUpdateCutterCommentsInfo = (
    applicationId: string
): ApplicationsActionUpdateCutterCommentsInfo => {
    return {
        type: APPLICATIONS_UPDATE_CUTTER_COMMENTS_INFO,
        applicationId
    }
}
export const ApplicationsDispatchFetchCutterInfo = (
    applicationId: string,
    watcherId?: string
): ApplicationsActionFetchCutterInfo => {
    return {
        type: APPLICATIONS_FETCH_CUTTER_INFO,
        applicationId,
        watcherId
    }
}

export const ApplicationsDispatchStoreInternalInteractions = (
    application: CutterApplication
): ApplicationsActionStoreInternalInteractions => {
    return {
        type: APPLICATIONS_STORE_INTERNAL_INTERACTIONS,
        application
    }
}

export const ApplicationsDispatchStoreInternalComment = (
    applicationId: string,
    comment: any
): ApplicationsActionStoreInternalComment => {
    return {
        type: APPLICATIONS_STORE_INTERNAL_COMMENT,
        applicationId,
        comment
    }
}

export const ApplicationsDispatchSendInternalComment = (
    body: string,
    commentsLink: string,
    applicationId: string,
    watcherID: string,
    field: CutterCommentType
): ApplicationsActionSendInternalComment => {
    return {
        type: APPLICATIONS_SEND_INTERNAL_COMMENT,
        body,
        commentsLink,
        applicationId,
        watcherID,
        field
    }
}

export const ApplicationsDispatchStoreInternalFiles = (
    applicationId: string,
    files: any
): ApplicationsActionStoreInternalFiles => {
    return {
        type: APPLICATIONS_STORE_INTERNAL_FILES,
        applicationId,
        files
    }
}

export const ApplicationsDispatchUnstoreInternalFiles = (
    applicationId: string,
    files: any
): ApplicationsActionUnstoreInternalFiles => {
    return {
        type: APPLICATIONS_UNSTORE_INTERNAL_FILES,
        applicationId,
        files
    }
}

export const ApplicationsDispatchRemoveInternalComment = (
    applicationId: string,
    comment: CutterComment,
    watcherID: WatcherID
): ApplicationsActionRemoveInternalComment => {
    return {
        type: APPLICATIONS_REMOVE_INTERNAL_COMMENT,
        applicationId,
        watcherID,
        comment
    }
}

export const ApplicationsDispatchUnstoreInternalComment = (
    applicationId: string,
    comment: CutterComment
): ApplicationsActionUnstoreInternalComment => {
    return {
        type: APPLICATIONS_UNSTORE_INTERNAL_COMMENT,
        applicationId,
        comment
    }
}

export const ApplicationsDispatchMarkCommentAsRead = (
    applicationId: string,
    commentId: string,
    waitForCommentsInfo?: boolean
): ApplicationsActionMarkCommentAsRead => {
    return {
        type: APPLICATIONS_MARK_COMMENT_AS_READ,
        applicationId,
        commentId,
        waitForCommentsInfo
    }
}
export const ApplicationsDispatchStoreReadComment = (
    applicationId: string,
    commentId: string
): ApplicationsActionStoreReadComment => {
    return {
        type: APPLICATIONS_STORE_READ_COMMENT,
        applicationId,
        commentId
    }
}
export interface SagasForApplications {
    loadApplication(action: ApplicationsActionLoadApplication): void
    updateCutterCommentsInfo(action: ApplicationsActionUpdateCutterCommentsInfo): void
    loadEntries(action: ApplicationsActionLoadEntries): void
    editEntity(action: ApplicationsActionEditEntity): void
    loadCVR(action: ApplicationsActionLoadCVRInfo): void
    loadBVD(action: ApplicationsActionLoadCVRInfo): void
    updateDraftAgreement(action: ApplicationsActionUpdateDraftAgreement): void
    createDraftAgreement(action: ApplicationsActionCreateDraftAgreement): void
    refuseDraftAgreement(action: ApplicationsActionRefuseDraftAgreement): void
    approveDraftAgreement(action: ApplicationsActionApproveDraftAgreement): void
    onStoreCutterCommentsInfo(action: ApplicationsActionStoreCutterCommentsInfo): void
    sendInternalComment(action: ApplicationsActionSendInternalComment): void
    onStoreReadComment(action: ApplicationsActionStoreReadComment): void
    onStoreInternalComment(action: ApplicationsActionStoreInternalComment): void
    removeInternalComment(actions: ApplicationsActionRemoveInternalComment): void
    fetchCutterInfo(actions: ApplicationsActionFetchCutterInfo): void
    cleanup(actions: ApplicationsActionCleanup): void
    markCommentAsRead(actions: ApplicationsActionMarkCommentAsRead): void
}

export type ReducedApplicationsActions =
    | ApplicationsActionStoreEntries
    | ApplicationsActionStoreApplication
    | ApplicationsActionLoadApplication
    | ApplicationsActionUpdateCutterCommentsInfo
    | ApplicationsActionStoreEntity
    | ApplicationsActionLoadEntries
    | ApplicationsActionSaveLastRoute
    | ApplicationsActionLoadCVRInfo
    | ApplicationsActionStoreCVRInfo
    | ApplicationsActionStoreNewStatus
    | ApplicationsActionLoadBVDInfo
    | ApplicationsActionStoreReadComment
    | ApplicationsActionStoreBVDInfo
    | ApplicationsActionUnstoreInternalComment
    | ApplicationsActionUnstoreInternalFiles
    | ApplicationsActionStoreDraftAgreement
    | ApplicationsActionLinkFiles
    | ApplicationsActionStoreInternalComment
    | ApplicationsActionStoreInternalFiles
    | ApplicationsActionCleanup
    | ApplicationsActionStoreApplicationResource
    | ApplicationsActionUnlinkFiles
    | ApplicationsActionStoreCutterCommentsInfo
    | ApplicationsActionStoreInternalInteractions

export interface ApplicationsLoadApplicationWatchers {
    application: WatcherID
    collaborators: WatcherID
}
