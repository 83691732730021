/* eslint-disable max-len */
import React, { useMemo } from 'react'
import styled from 'styled-components'

export const ArrowIndicator: React.FC<{ y?: string }> = ({ y }) => {
    const arrow = useMemo(() => {
        return (
            <svg width="10" height="14" viewBox="0 0 10 14" fill="none">
                <path
                    d="M10 11.7795V2.15385C10 0.493234 8.07034 -0.421191 6.7851 0.630372L0.891895 5.45209C-0.0861368 6.25229 -0.0861378 7.74771 0.891895 8.54791L6.73352 13.3274C8.03939 14.3959 10 13.4668 10 11.7795Z"
                    fill="currentColor"
                />
            </svg>
        )
    }, [])
    return (
        <Holder>
            <LeftArrow y={y}>
                <Flip>{arrow}</Flip>
            </LeftArrow>
            <RightArrow y={y}>{arrow}</RightArrow>
        </Holder>
    )
}

const Flip = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
`

const LeftArrow = styled.div<{ y?: string }>`
    position: absolute;
    top: 0;
    left: 50px;
    display: ${(p) => (p.y ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    margin-top: -7px;
    color: ${(p) => p.theme['front.text']};
    transform: translateY(${(p) => p.y}px);
`
const RightArrow = styled.div<{ y?: string }>`
    position: absolute;
    top: 0;
    right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -7px;
    color: ${(p) => p.theme['front.text']};
    transform: translateY(${(p) => p.y}px);
`

const Holder = styled.div`
    display: contents;
`
