import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { File } from '../../store/files/types'
import { RootState } from '@/store'
import { Text } from '../general/text'
import { useLocation, useWindowSize } from 'react-use'
import { Rnd } from 'react-rnd'
import { LoaderView } from '../loaders/loader'
import { SimpleButton } from '../buttons/simpleButton'
import { Icon } from '../icons/icon'
import { useFileDownloader } from '../../hooks/files/useFileDownloader'
import { ButtonInset } from '../buttons/buttonInset'
import { useFetchPDFPreview } from '../../hooks/files/useFetchPDFPreview'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { FileActionDispatchGenerateDownloadLink } from '../../store/files/actions'
import { isFileAPDF } from '../../utils'
import { zIndexes } from '../../styles/zIndexes'
import { Spacer } from '../layout/spacer'
import Viewer from 'react-viewer'
const queryParams = ['preview']

const isImage = (contentType: string) => {
    if (contentType === 'image/jpg' || contentType === 'image/jpeg' || contentType === 'image/png') return true
    return false
}

export const FilePreviewer: React.FC<{
    id: string
    stackIndex?: number
    wholePage?: boolean
    onFocus?: (e: React.MouseEvent<HTMLDivElement>) => void
}> = ({ id, stackIndex, wholePage, onFocus }) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const [, setQuery] = useQueryParams(queryParams, undefined)
    const file: File | undefined = useSelector((state: RootState) => {
        return state.files.at[id]
    })
    useModalStackSync(null, `FilePreviewer.${id || 'NoFile'}` as any)
    const [previewLink, setPreviewLink] = useState<string | undefined>(undefined)
    const [containerRef, setContainerRef] = useState<any>(null)
    const { downloadFile } = useFileDownloader(file)
    const { width, height } = useWindowSize()
    const pdfPreview = useFetchPDFPreview(file)
    const fetchedFile = useRef<any>(undefined)

    const [win, setWin] = useState<any>({
        x: window.innerWidth / 2 - (stackIndex ? stackIndex * 20 : 0),
        y: window.innerHeight / 2 - (stackIndex ? stackIndex * 20 : 0),
        width: window.innerWidth / 2 - 20,
        height: window.innerHeight / 2 - 20
    })

    const templates = useMemo(() => {
        const temps: {
            icon: React.ComponentProps<typeof Icon>['type']
            onClick: (e: any) => void
        }[] = [
            {
                icon: 'resize-left-bottom',
                onClick: (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setWin({
                        x: 20 + window.scrollX,
                        y: window.innerHeight / 2 + window.scrollY,
                        width: window.innerWidth / 3,
                        height: window.innerHeight / 2 - 20
                    })
                }
            },
            {
                icon: 'resize-big',
                onClick: (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setWin({
                        x: 20 + window.scrollX,
                        y: 20 + window.scrollY,
                        width: window.innerWidth - 40,
                        height: window.innerHeight - 40
                    })
                }
            },
            {
                icon: 'resize-left',
                onClick: (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setWin({
                        x: 20 + window.scrollX,
                        y: 20 + window.scrollY,
                        width: window.innerWidth / 3,
                        height: window.innerHeight - 40
                    })
                }
            }
        ]
        return temps
    }, [setWin])

    const container = useMemo(() => {
        return {
            size: wholePage ? undefined : { width: win.width, height: win.height },
            position: wholePage ? undefined : { x: win.x, y: win.y }
        }
    }, [win, wholePage])

    const onDragStop = useCallback(
        (e, d) => {
            setWin((s: any) => ({
                ...s,
                x: d.x,
                y: d.y
            }))
        },
        [setWin]
    )

    const onResizeStop = useCallback(
        (e: any, d: any, ref: any, delta: any, position: any) => {
            setWin((s: any) => ({
                ...s,
                ...position,
                width: ref.style.width,
                height: ref.style.height
            }))
        },
        [setWin]
    )

    const exitAll = useCallback(() => {
        setQuery({ preview: undefined })
    }, [setQuery])

    const exit = useCallback(
        (e: any) => {
            e.stopPropagation()
            e.preventDefault()
            setQuery((p: any) => {
                if (!p.preview) return p
                if (typeof p.preview === 'object') {
                    return {
                        preview: p.preview.filter((i: any) => i !== id)
                    }
                } else
                    return {
                        preview: undefined
                    }
            })
        },
        [setQuery, id]
    )

    useEffect(() => {
        return () => {
            setPreviewLink(undefined)
            // if (fetchedFile.current) dispatch(FileActionDispatchRemoveDownloadLink(fetchedFile.current))
        }
    }, [dispatch])

    const clue = useHotkey({
        keys: 'escape, alt+escape',
        action: exitAll,
        scope: `FilePreviewer.${id || 'NoFile'}` as any
    })

    useEffect(() => {
        if (!file) return

        if (file.generatingDownloadLink === 'started') return

        if (file.generatedDownloadLink) {
            fetchedFile.current = file
            setPreviewLink(() => {
                return file?.generatedDownloadLink
            })
        } else {
            fetchedFile.current = file
            dispatch(FileActionDispatchGenerateDownloadLink(file))
        }
    }, [dispatch, file])

    const previewer = useMemo(() => {
        if (!file) return 'File is either loading or not found.'
        if (isFileAPDF(file.contentType)) {
            if (pdfPreview.loadingStatus !== 'done') return <LoaderView overBackground="floating.background" />
            return (
                <ViewerHolder key="vh">
                    <Spacer height={55} />
                    <object
                        key="pdf"
                        data-cy="pdf-object"
                        data={pdfPreview.pdf}
                        width="100%"
                        height="100%"
                        type="application/pdf"
                        style={{ flexGrow: 1 }}
                    ></object>
                </ViewerHolder>
            )
        }
        if (isImage(file.contentType)) {
            if (!previewLink) return
            return (
                <Styles key="styles">
                    <ViewerHolder key="imageViewer" ref={setContainerRef}>
                        <Viewer
                            key="viewer"
                            container={containerRef}
                            visible
                            noClose
                            noNavbar
                            scalable={false}
                            minScale={0.1}
                            defaultScale={1}
                            changeable={false}
                            zoomSpeed={0.05}
                            noImgDetails
                            disableKeyboardSupport
                            images={[{ src: previewLink }]}
                        />
                    </ViewerHolder>
                </Styles>
            )
        }

        return (
            <Text size="s" bold>
                Unsupported content type. Please download the file instead.
            </Text>
        )
    }, [previewLink, file, setContainerRef, containerRef, pdfPreview])

    const rndDefault = {
        bounds: 'window'
    }

    const Container = useMemo(() => {
        if (wholePage) return FileFixedContainer
        else return FileContainer
    }, [wholePage])

    return (
        <Holder data-prevent-interactions-exit data-prevent-notes-exit>
            <Container
                stackIndex={stackIndex}
                default={rndDefault}
                onMouseDown={onFocus}
                onMouseUp={onFocus}
                data-id={file?.id}
                size={container.size}
                position={container.position}
                onDragStop={onDragStop}
                onResizeStop={onResizeStop}
                disableDragging={wholePage}
                wholePage={wholePage}
                isPDF={isFileAPDF(file?.contentType)}
            >
                <FileHeader wholePage={wholePage} onMouseDown={onFocus}>
                    <FileTitle>{file && `${file?.name || 'unknown file'}`}</FileTitle>
                    {!wholePage && (
                        <FileButton>
                            {templates.map((k) => {
                                return (
                                    <SimpleButton
                                        color="floating.text"
                                        cy="filepreview-resize"
                                        onClick={k.onClick}
                                        key={k.icon}
                                    >
                                        <ButtonInset>
                                            <Icon type={k.icon} />
                                        </ButtonInset>
                                    </SimpleButton>
                                )
                            })}
                            <SimpleButton color="floating.text" cy="filepreview-close" onClick={exit}>
                                <ButtonInset>
                                    <Icon type="x" />
                                    {clue}
                                </ButtonInset>
                            </SimpleButton>
                        </FileButton>
                    )}
                </FileHeader>
                <FileContent>
                    {!file ? <LoaderView overBackground="floating.background" type="l" /> : previewer}
                </FileContent>
                <Download>
                    <FileButton>
                        <SimpleButton background="front.accent.color" onClick={downloadFile} cy="download">
                            <ButtonInset equalPadding padding="medium">
                                <Icon type="download" />
                            </ButtonInset>
                        </SimpleButton>
                    </FileButton>
                </Download>
            </Container>
        </Holder>
    )
}

const Holder = styled.div`
    display: contents;
`

const Styles = styled.div`
    display: contents;
    .react-viewer-mask {
        background-color: ${(p) => p.theme['floating.background.strongerI']} !important;
    }
    .react-viewer-showTotal {
        display: none;
    }

    .loading-wrap {
        position: relative !important;
        top: -25% !important;
    }
`
const FileTitle = styled.div`
    color: ${(p) => p.theme['floating.text']};
`

const FileButton = styled.div`
    display: flex;
    justify-content: flex-end;
`
const Download = styled.div`
    position: absolute;
    left: 10px;
    right: auto;
    bottom: 10px;
`

const FileHeader = styled.div<{ wholePage?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    font-weight: 500;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    align-items: center;
    background-color: ${(p) => p.theme['floating.background']};
    position: absolute;
    width: calc(100% - 8px);
    border-radius: 6px;
    box-sizing: border-box;
    top: 4px;
    cursor: grab;
    left: 4px;
    z-index: 100;

    &:active {
        cursor: grabbing;
    }

    ${(p) =>
        p.wholePage &&
        css`
            left: 0;
            top: 0;
            width: 100%;
            border-radius: 0;
            cursor: default;
            &:active {
                cursor: default;
            }
        `}
`

const FileContent = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: auto;

    z-index: 0;

    .react-viewer-inline {
        min-height: 0 !important;
    }
`

const ViewerHolder = styled.div<{ grow?: boolean }>`
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const commonCss = css`
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    background-color: ${(p) => p.theme['floating.background']};
    display: flex !important;
    flex-direction: column;
    border-radius: 8px;
    position: fixed !important;
    border-bottom: 10px;
    overflow: hidden;
`
const FileContainer = styled(Rnd as any)`
    ${commonCss};
    z-index: ${(p) => zIndexes.modals.megamodal + p.stackIndex || 0};

    ${(p) =>
        !p.isPDF &&
        css`
            .react-viewer-toolbar {
                opacity: 0;
            }
            ${FileHeader} {
                opacity: 0;
            }
            ${Download} {
                opacity: 0;
            }

            &:hover {
                .react-viewer-toolbar {
                    opacity: 1;
                }

                ${FileHeader} {
                    opacity: 1;
                }
                ${Download} {
                    opacity: 1;
                }
            }
        `}
` as any

const FileFixedContainer = styled.div`
    ${commonCss};
    position: relative;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    border-radius: 0;
` as any
