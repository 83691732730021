import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { Settlement } from '../../store/settlements/types'
import { numberWithCurrency } from '../../utils'
import { Flex } from '../../components/layout/flex'
import { useMemo } from 'react'
import { TimelineCard } from '../../components/listPages/timelineCard'

export const SettlementCardFeeDetails: React.FC<{
    settlement: Settlement
}> = ({ settlement }) => {
    const feeDetails = useMemo(() => {
        if (!settlement?.feeDetails?.length) return []
        const rows =
            settlement.feeDetails.map((fd) => {
                return {
                    type: 'normal' as const,
                    key: 'total',
                    noAction: true,
                    items: [
                        {
                            node: <Text>{fd.type}</Text>
                        },
                        {
                            node: (
                                <Flex>
                                    <Text>{numberWithCurrency(settlement.currency, fd.type)}</Text>
                                    <Spacer width={10} />
                                    <Text>{settlement.currency}</Text>
                                </Flex>
                            )
                        }
                    ]
                }
            }, []) || []

        rows.push({
            type: 'normal' as const,
            key: 'total',
            noAction: true,
            items: [
                {
                    node: <b>Total</b>
                },
                {
                    node: (
                        <Flex>
                            <Text bold>{numberWithCurrency(settlement.currency, settlement.summary.fees)}</Text>
                            <Spacer width={10} />
                            <Text bold>{settlement.currency}</Text>
                        </Flex>
                    )
                }
            ]
        })
        return rows
    }, [settlement])

    if (settlement.loadingStatus !== 'done') return null

    if (!feeDetails?.length) return null

    return (
        <TimelineCard title={`Fee details ${feeDetails.length - 1})`} overflowX>
            <Table
                background="front.background"
                cols={[
                    {
                        text: 'Type'
                    },
                    {
                        alignRight: true,
                        text: 'Amount'
                    }
                ]}
                noHeader
                rows={feeDetails}
                columnLayout="auto min-content"
            />
        </TimelineCard>
    )
}
