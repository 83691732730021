import moment from 'moment'

import styled from 'styled-components'

import { Text } from '../../../components/general/text'
import { MerchantTimelinePoint } from '../../../store/merchantTimeline/types'
import { DateFormats } from '../../../utils/dateUtils'

export const MerchantTimelineItemDate: React.FC<{
    point: MerchantTimelinePoint
}> = ({ point }) => {
    return (
        <TextHolder>
            <Text color="front.text.subtlerI">
                {moment(point.at).format(DateFormats.dayAndTimeWithSeconds(moment(point.at)))}
            </Text>
        </TextHolder>
    )
}

const TextHolder = styled.div`
    position: absolute;
    top: 0px;
    right: 42px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`
