import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { RootState } from '@/store'
import { useLocation, useNavigate } from 'react-router-dom'

export const RelatedApplicationsWarning: React.FC<{ id: string }> = ({ id }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const related = useSelector((state: RootState) => state.applicationInternals.related.forApplication[id])

    const declinedApplications = useMemo(() => {
        return (
            related?.related?.reduce((acc, item) => {
                acc += item.state === 'declined' ? 1 : 0
                return acc
            }, 0) || 0
        )
    }, [related])

    const handleClick = useCallback(() => {
        navigate(
            {
                pathname: `/merchant/${id}/summary/related`,
                search: location.search
            },
            {
                state: {
                    backTo: location.pathname
                }
            }
        )
    }, [id, navigate, location])

    if (related?.loadingStatus !== 'done') return null
    return (
        <Flex justify="space-between">
            <Flex justify="space-between" align="center">
                <Text color="back.text">
                    <Text bold>{related?.related?.length} </Text>
                    related applications found
                    {declinedApplications ? (
                        <Text>
                            , <Text bold>{declinedApplications} DECLINED.</Text>
                        </Text>
                    ) : (
                        '.'
                    )}
                </Text>
            </Flex>
            <SimpleButton
                background={!!declinedApplications ? 'front.danger.color' : 'front.subtleAccent.background'}
                onClick={handleClick}
            >
                <ButtonInset>Explore</ButtonInset>
            </SimpleButton>
        </Flex>
    )
}
