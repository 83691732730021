import { createActionCreator } from 'deox'
import { ApplicationStamp } from './types'

const Prefix = 'APPLICATION_INTERNALS_STAMPING'

export const ApplicationInternalsStampingActions = {
    REFETCH: createActionCreator(
        `${Prefix}/REFETCH`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    STAMP: createActionCreator(
        `${Prefix}/STAMP`,
        (resolve) => (applicationId: string, link: string, method: 'PUT' | 'DELETE', watcherId: string, body?: any) =>
            resolve({ applicationId, link, watcherId, method, body })
    ),
    INIT: createActionCreator(`${Prefix}/INIT`, (resolve) => (applicationId: string) => resolve({ applicationId })),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (applicationId: string, stamps: ApplicationStamp[]) => resolve({ applicationId, stamps })
    )
}
