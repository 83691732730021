import styled from 'styled-components'
import * as yup from 'yup'

import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { MerchantAccountContractsProps } from './Accounts.ID.ContractProps'
import { ContractCard } from '../../../components/cards/contractCard'
import { useContractSectionHelper } from './useContractSectionHelper'

export const MerchantAccountContractsSectionAdditional: React.FC<MerchantAccountContractsProps> = ({
    contract,
    formRef,
    formErrors,
    draftOrTemplate,
    isDisabled
}) => {
    const { getValue, isEditable } = useContractSectionHelper(contract, draftOrTemplate)

    return (
        <ContractCard grow title="Additional terms" showEditable={isEditable}>
            <CardInset>
                <Holder>
                    {!isEditable ? (
                        <div data-cy="additionalTerms">{getValue('otherTerms')}</div> || (
                            <Text color="front.text.subtlerI" cy="additionalTerms">
                                No additional terms
                            </Text>
                        )
                    ) : (
                        <TextareaInput
                            overBackground="front.background"
                            placeholder="Add any additional terms"
                            initialValue={getValue('otherTerms')}
                            isDisabled={isDisabled || !isEditable}
                            tabIndex={501}
                            isSeamless
                            cy="additionalTerms"
                            validation={formErrors.otherTerms}
                            ref={(ref) => formRef(ref, `otherTerms`, yup.string())}
                        />
                    )}
                </Holder>
            </CardInset>
        </ContractCard>
    )
}

const Holder = styled.div`
    white-space: pre-wrap;
`
