import { put } from 'redux-saga/effects'
import { getJWT } from '../auth/sagas'
import { getAuth } from '../../generators/selectors'
import { AuthState } from '../auth/types'
import { CallsPrefActions } from './actions'
import axios from 'axios'

export const CallsPrefSaga = {
    *SET_IS_CALLING() {
        const previousIsCalling = localStorage.getItem('previousIsCalling') as any
        if (previousIsCalling) yield put(CallsPrefActions.SET_IS_CALLING(previousIsCalling))
    },
    *UPDATE_PREF() {
        const auth = (yield getAuth()) as AuthState
        const nickname = auth.user ? auth.user.nickname : 'unknown'

        const previousLine = localStorage.getItem('previousLine') as any
        if (previousLine) yield put(CallsPrefActions.SET_LINE(previousLine))

        const previousLocalNumber = localStorage.getItem('previousLocalNumber') as any
        if (previousLocalNumber) yield put(CallsPrefActions.SET_LOCAL_NUMBER(previousLocalNumber))

        const previousNotifications = localStorage.getItem('previousNotifications') as any
        if (previousNotifications) {
            yield put(CallsPrefActions.TOGGLE_NOTIFICATIONS(previousNotifications))
        }
        const line = localStorage.getItem('line')
        const localNumber = localStorage.getItem('localNumber')
        const notifications = localStorage.getItem('notifications')
        const setNickname = localStorage.getItem('nickname')

        const queues1 = ['1001', '1002', '1003']
        const queues2 = ['1002', '1003']
        const queues3 = ['1003']
        const printQueue1 = 'lines 1, 2 and 3'
        const printQueue2 = 'lines 2 and 3'
        const printQueue3 = 'line 3'
        let queues = queues3
        let printQueue = printQueue3
        if (line == '1') {
            queues = queues1
            printQueue = printQueue1
        }
        if (line == '2') {
            queues = queues2
            printQueue = printQueue2
        }

        const token: string = yield getJWT()
        axios.defaults.headers.common = { Authorization: `Bearer ${token}` }

        if (previousNotifications == 'on' && setNickname === nickname && notifications != 'on') {
            axios
                .delete(`${import.meta.env.VITE_MERCURY_ENDPOINT}/phones/${previousLocalNumber}/queues`, {
                    data: { queues: ['1001', '1002'] }
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })

            axios
                .post(`${import.meta.env.VITE_MERCURY_ENDPOINT}/phones/${previousLocalNumber}/names/%20`)
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        if (notifications == 'on') {
            axios
                .delete(`${import.meta.env.VITE_MERCURY_ENDPOINT}/phones/${localNumber}/queues`, {
                    data: { queues: queues1 }
                })
                .then((response) => {
                    return axios.post(`${import.meta.env.VITE_MERCURY_ENDPOINT}/phones/${localNumber}/queues`, {
                        queues: queues
                    })
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })

            axios
                .post(`${import.meta.env.VITE_MERCURY_ENDPOINT}/phones/${localNumber}/names/${nickname}`)
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
}
