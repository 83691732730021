import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { HighlightableBackgrounds } from '../../hooks/general/useTheme'
import { Color } from '../../styles/colors'

import { Icon } from '../icons/icon'

export const LinkButtonArrow: React.FC<{
    background: HighlightableBackgrounds
    shouldBeBordered?: boolean
}> = ({ background, shouldBeBordered }) => {
    const bgColor = useMemo(() => {
        if (background === 'side.background') return 'side.accent.color'
        return 'front.accent.color'
    }, [background])

    return (
        <IconHolder background={bgColor} color={background} shouldBeBordered={shouldBeBordered}>
            <Icon type="diagonalArrow" size={6} />
        </IconHolder>
    )
}

const IconHolder = styled.div<{
    background: Color
    color: Color
    shouldBeBordered?: boolean
}>`
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    border: 1px solid transparent;

    background-color: ${(p) => p.theme[p.background]};
    border-color: ${(p) => p.theme[p.background]};
    color: ${(p) => p.theme[p.color]};

    ${(p) =>
        p.shouldBeBordered &&
        css`
            background-color: transparent;
            border-color: currentColor;
            color: currentColor;
        `}
`
