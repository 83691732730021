import { createActionCreator } from 'deox'
import { MerchantApplicationResourceIndexPath } from '../applicationResources/types'
import { CompanyChangesFromBackend, MonitoredChangesState } from './types'

const Prefix = 'TASKS_COMPANY_CHANGES'
export const TasksCompanyChangesActions = {
    INIT: createActionCreator(
        `${Prefix}/INIT`,
        (resolve) => (taskId: string, changes: CompanyChangesFromBackend) => resolve({ taskId, changes })
    ),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (taskId: string, state: MonitoredChangesState) => resolve({ taskId, state })
    ),
    CHANGE_INFO: createActionCreator(
        `${Prefix}/CHANGE_INFO`,
        (resolve) =>
            (
                watcherId: string,
                taskId: string,
                applicationId: string,
                indexPath: {
                    indexPath: MerchantApplicationResourceIndexPath
                    to: string
                }
            ) =>
                resolve({ watcherId, taskId, applicationId, indexPath })
    ),
    REFRESH_APPLICATION: createActionCreator(
        `${Prefix}/REFRESH_APPLICATION`,
        (resolve) => (watcherId: string, taskId: string, applicationId: string, skipNotification?: boolean) =>
            resolve({ watcherId, taskId, applicationId, skipNotification })
    ),
    CHANGE_MUTED_STATE: createActionCreator(
        `${Prefix}/CHANGE_MUTED_STATE`,
        (resolve) => (applicationId: string, taskId: string, watcherId: string, payload: any) =>
            resolve({ applicationId, taskId, watcherId, payload })
    ),
    REFRESH_APPLICATION_STORE_DATA: createActionCreator(
        `${Prefix}/REFRESH_APPLICATION_STORE_DATA`,
        (resolve) =>
            (
                taskId: string,
                applicationId: string,
                newData: MonitoredChangesState['ID']['fetchedApplicationData']['data']
            ) =>
                resolve({ taskId, applicationId, newData })
    ),
    APPLY_CHANGE: createActionCreator(
        `${Prefix}/APPLY_CHANGE`,
        (resolve) =>
            (
                watcherId: string,
                taskId: string,
                applicationId: string,
                link: string,
                data: { [key: string]: string | number }
            ) =>
                resolve({ watcherId, taskId, applicationId, link, data })
    )
}
