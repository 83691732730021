import React from 'react'
import { getCompanyReg } from '../helpers/getCompanyRegLink'
import { Company } from '@/store/applications/types'
import { ExternalLink } from '@/components/buttons/externalLink'
import { Text } from '@/components/general/text'

interface CompanyRegistrationLinkProps {
    company?: Company
}

export const CompanyRegistrationLink: React.FC<CompanyRegistrationLinkProps> = (props) => {
    const { company } = props

    const companyRegInfo = getCompanyReg(company)

    if (!companyRegInfo) {
        return null
    }

    return (
        <ExternalLink url={companyRegInfo.url} target="blank" arrowLess underlineLess defaultColor="front.accent.color">
            <Text>{companyRegInfo.name}</Text>
        </ExternalLink>
    )

    return null
}
