import { CollectionWithPagination, LoadingStatus, Pagination, SortInfo } from '../../utils'
import { ApplicationInternalTag } from '../applicationInternals/tags/types'
import { DraftContract } from '../contracts/types'
import { DashboardEntriesTypes } from '../dashboard/types'
import { File } from '../files/types'
import { Gateway } from '../gateways/types'

export type ApplicationsEntryFields =
    | 'company'
    | 'website'
    | 'assignee'
    | 'country'
    | 'psp'
    | 'submittedAt'
    | 'enteredStateAt'
    | 'agreementUpdatedAt'
    | 'contractSignedAt'
    | 'email'
    | 'name'
    | 'phone'
    | 'createdAt'
    | 'merchantId'
    | 'parent'
    | 'tags'
export type ApplicationTypeNeedsSpecialColumns = Extract<
    DashboardEntriesTypes,
    'new' | 'submitted' | 'contracts.declined' | 'agreements.new' | 'accounts.in_progress' | 'accounts.closed'
>
export const ApplicationFilterToTitle: {
    [key in DashboardEntriesTypes]: string
} = {
    'new': 'Unsubmitted',
    'submitted': 'Submitted',
    'under_review': 'Under review',
    'more_information': 'Needs more information',
    'declined': 'Declined',
    'archived': 'Archived',
    'pre_accepted': 'Pre-accepted',
    'needs_approval': 'Needs approval',
    'agreements.new': 'with Agreements, In progress',
    'agreements.needs_approval': 'Agreements - Needs approval',
    'contracts.needs_signature': 'Agreements - Needs signature',
    'contracts.declined': 'Agreements - Declined',
    'accounts.in_progress': 'Accounts - In progress',
    'accounts.live': 'Accounts - Live',
    'accounts.closed': 'Accounts - Closed'
}
export const ApplicationFilterToHumanizedStates: {
    [key in DashboardEntriesTypes]: {
        with?: string
        thatAre: string
    }
} = {
    'new': {
        thatAre: 'unsubmitted'
    },
    'needs_approval': {
        thatAre: 'awaiting approval'
    },
    'pre_accepted': {
        thatAre: 'pre-accepted'
    },
    'submitted': {
        thatAre: 'submitted'
    },
    'under_review': {
        thatAre: 'under review'
    },
    'more_information': {
        thatAre: 'awaiting more information'
    },
    'declined': {
        thatAre: 'declined'
    },
    'archived': {
        thatAre: 'archived'
    },
    'agreements.new': {
        with: 'contracts',
        thatAre: 'in progress'
    },
    'agreements.needs_approval': {
        with: 'contracts',
        thatAre: 'awaiting approval'
    },
    'contracts.needs_signature': {
        with: 'contracts',
        thatAre: 'awaiting signature'
    },
    'contracts.declined': {
        with: 'contracts',
        thatAre: 'declined'
    },
    'accounts.in_progress': {
        with: 'accounts',
        thatAre: 'in progress'
    },
    'accounts.live': {
        with: 'accounts',
        thatAre: 'live'
    },
    'accounts.closed': {
        with: 'accounts',
        thatAre: 'closed'
    }
}

const defaultApplicationColumns: ApplicationsEntryFields[] = [
    'country',
    'company',
    'enteredStateAt',
    'submittedAt',
    'psp',
    'assignee',
    'website',
    'tags'
]
const defaultContractsColumns: ApplicationsEntryFields[] = [
    'merchantId',
    'country',
    'company',
    'agreementUpdatedAt',
    'submittedAt',
    'psp',
    'assignee',
    'website',
    'tags'
    // 'parent',
]
const defaultAccountsColumns: ApplicationsEntryFields[] = [
    'merchantId',
    'country',
    'company',
    'contractSignedAt',
    'psp',
    'assignee',
    'website',
    'tags'
    // 'parent',
]

export const ApplicationTypeToColumnsSetting: {
    [key in DashboardEntriesTypes]: ApplicationsEntryFields[]
} = {
    'under_review': defaultApplicationColumns,
    'more_information': defaultApplicationColumns,
    'declined': defaultApplicationColumns,
    'archived': defaultApplicationColumns,
    'pre_accepted': defaultApplicationColumns,
    'needs_approval': defaultApplicationColumns,
    'new': ['country','company','enteredStateAt','psp','website','tags'],
    'submitted': ['country', 'company', 'submittedAt', 'psp', 'website', 'tags'],
    'contracts.declined': defaultContractsColumns,
    // [
    //     'company',
    //     'country',
    //     'psp',
    //     'submittedAt',
    //     'tags',
    //     'website'
    // ],
    'contracts.needs_signature': defaultContractsColumns,
    'agreements.new': defaultContractsColumns,
    // [
    //     'company',
    //     'assignee',
    //     'createdAt',
    //     'country',
    //     'psp',
    //     'submittedAt',
    //     'enteredStateAt',
    //     'tags',
    //     'website'
    // ],
    'agreements.needs_approval': defaultContractsColumns,
    'accounts.live': defaultAccountsColumns,
    'accounts.in_progress': defaultAccountsColumns,
    // [
    //     'company',
    //     'assignee',
    //     'mids',
    //     'country',
    //     'psp',
    //     'submittedAt',
    //     'enteredStateAt',
    //     'tags',
    //     'website'
    // ],
    'accounts.closed': defaultAccountsColumns
    // [
    //     'company',
    //     'mids',
    //     'parent',
    //     'country',
    //     'psp',
    //     'enteredStateAt',
    //     'tags',
    //     'website'
    // ]
}

export const ApplicationsDefaultSortForDashboardEntryState: {
    [key in DashboardEntriesTypes]: SortInfo<string>
} = {
    'new': { column: 'created_at', direction: 'desc' },
    'submitted': { column: 'submitted_at', direction: 'desc' },
    'under_review': { column: 'entered_state_at', direction: 'desc' },
    'pre_accepted': { column: 'entered_state_at', direction: 'desc' },
    'needs_approval': { column: 'entered_state_at', direction: 'desc' },
    'more_information': { column: 'entered_state_at', direction: 'desc' },
    'declined': { column: 'entered_state_at', direction: 'asc' },
    'archived': { column: 'entered_state_at', direction: 'asc' },
    'agreements.new': { column: 'agreement_updated_at', direction: 'desc' },
    'agreements.needs_approval': {
        column: 'agreement_updated_at',
        direction: 'desc'
    },
    'contracts.needs_signature': {
        column: 'agreement_updated_at',
        direction: 'desc'
    },
    'contracts.declined': { column: 'agreement_updated_at', direction: 'desc' },
    'accounts.in_progress': { column: 'contract_signed_at', direction: 'asc' },
    'accounts.live': { column: 'contract_signed_at', direction: 'desc' },
    'accounts.closed': { column: 'contract_signed_at', direction: 'desc' }
}

export interface ApplicationsMainTableEntry {
    id: string
    company?: string
    website?: string
    assignee?: string
    country?: string
    psp?: string
    submittedAt?: string
    enteredStateAt?: string
    agreementUpdatedAt?: string
    contractSignedAt?: string
    email?: string
    name?: string
    phone?: string
    createdAt?: string
    mids?: {
        currency: string
        mid?: string
    }[]
    merchantId?: string
    parent?: string
    tags?: ApplicationInternalTag[]
}

export interface PaginationData {
    itemCount?: number
    perPage: number
    pagesCount: number
}

export type FieldsOf<T> = T[keyof T]

export interface CutterCommentInfo {
    id: string
    readAt: string
    cutterSelfLink: string
    private: boolean
    field: string
}
export interface ApplicationsState {
    table: CollectionWithPagination<ApplicationsMainTableEntry, Pagination>
    applications: ApplicationsStateApplications
    historyChanges: {
        [applicationId: string]: any
        loadingStatus: LoadingStatus
    }
    cutterCommentsInfo: {
        forApplication: {
            [applicationId: string]: {
                [key: string]: CutterCommentInfo
            }
        }
        loadingStatus: LoadingStatus
    }
    lastRoute: string
}

export interface ApplicationsStateApplications {
    at: {
        [id: string]: Application
    }
    all: string[]
}

export const InitialApplicationsState: ApplicationsState = {
    table: {
        at: {},
        all: [],
        loadingStatus: 'not-started'
    },
    historyChanges: {
        loadingStatus: 'not-started'
    },
    cutterCommentsInfo: {
        forApplication: {},
        loadingStatus: 'not-started'
    },
    applications: {
        at: {},
        all: []
    },
    lastRoute: ''
}

export type ApplicationStatus =
    | 'declined'
    | 'new'
    | 'submitted'
    | 'under_review'
    | 'archived'
    | 'more_information'
    | 'accepted'
    | 'unsubmitted'
    | 'pre_accepted'
    | 'needs_approval'

export interface ApplicationsFilters {
    applications_status: string
    applications_agent: string
    applications_gateway: string
    applications_end_date: string
    applications_start_date: string
    applications_sort: string
    applications_direction: string
    applications_page: number
    applications_per_page: number
}
export const ApplicationFiltersParams: ApplicationsFilters = {
    applications_status: '',
    applications_agent: '',
    applications_gateway: '',
    applications_end_date: '',
    applications_start_date: '',
    applications_sort: '',
    applications_direction: '',
    applications_page: 0,
    applications_per_page: 0
}

export interface ApplicationAction {
    name: string
    transitionsTo: ApplicationStatus
    action: any
    condition: any
    meta: {
        dashboardAction: boolean
        name: string
    }
}

export interface Manager {
    readComments: []
    newComments: []
}

export interface Version {
    author: string
    created_at: string
    id: string
    objectChanges: { [key: string]: [string, string] }
}

export interface BankAccount {
    id: string
    bank?: string
    currency?: string
    iban?: string
    mid?: string
    manager: Manager
    swiftCode?: string
    versions: Version[]
    comments: Comment[]
}

export interface Comment {
    tags: string[]
    id: string
    content: string
    authorId: string
    createdAt: string
    field: string
}
export interface CutterComment {
    selfLink: string | undefined
    id: any
    content: string
    createdAt: string
    authorId: string
    field: CutterCommentType
}

export type CutterCommentType = 'message' | 'call'

export interface CutterCommentInfo {
    id: string
    readAt: string
    selfCutterLink: string
}

export interface HistoryChange {
    by: string
    at: string
    id: string
    from: string
    to: string
}

export type ApplicationDeliveryDelay = 'less5days' | '5to10days' | '10to20days' | 'more20days'

export const ApplicationDeliveryDelayDictionary: {
    [key in ApplicationDeliveryDelay]: string
} = {
    'less5days': 'Less than 5 days',
    '5to10days': 'Between 5 to 10 days',
    '10to20days': 'Between 10 to 20 days',
    'more20days': 'More than 20 days'
}

export interface Company {
    id: string
    addressLine1?: string
    addressLine2?: string
    city?: string
    country?: string
    email?: string
    manager: Manager
    name?: string
    number?: string
    registrationNumber?: string
    ownershipStructure?: string
    ownershipStructureComment?: string
    phoneNumber?: string
    sanitisedAddress1?: string
    sanitisedCity?: string
    sanitisedName?: string
    bvdId?: string
    zipcode?: string
    selfLink?: string
    peopleLink: string
    versions: Version[]
    loadingStatus: LoadingStatus
}

export interface Contact {
    email?: string
    id?: string
    manager: Manager
    name?: string
    phone?: string
    versions: Version[]
}

export const PersonRoles = ['director', 'owner', 'director-and-owner']
export type PersonRole = typeof PersonRoles[number]

export type PossiblePersonsRole = 'director-and-owner' | 'director' | 'owner'
export interface Person {
    address1?: string
    city?: string
    country?: string
    dateOfBirth?: string
    id: string
    manager: Manager
    name?: string
    role?: 'director-and-owner' | 'director' | 'owner'
    socialSecurityNumber?: string
    email?: string
    phone?: string
    zipcode?: string
    selfLink: string
    commentsLink?: string
}

export type PersonVerificationFilesKeys = 'addressLegitimation' | 'pictureLegitimation'
export type PersonVerificationFiles = {
    [key in PersonVerificationFilesKeys]: File
}

export interface StateTransition {
    changed_at: string
    id: string
    previous: string
    state: string
    whodunnit: string
}

export interface Website {
    id: string
    manager: Manager
    url?: string
    urlComment?: string
    versions: Version
}

export interface Assessment {
    id: string
    applicationId: string
    company: string
    agreement: DraftContract
    country: string
    websites: string[]
    assignedTo: string
    selfLink: string
}

export interface ApplicationComment {
    author: {
        email: string
    }
    body: string
    createdAt: string
    id: string
    selfLink: string
    personLink: string
    tags: string[]
}

export interface BusinessModel
    extends Partial<{
        description: string
        dropShipping: boolean
        estimateCurrency: string
        estimatedAverageTransactionAmount: string
        estimatedMonthlyTurnover: string
        mcc: string
        tradingName: string
    }> {
    loadingStatus: LoadingStatus
}

export interface CutterApplication {
    commentsLink: any
    applicationFilesLink: any
    applicationFiles: any
    comments: any
    id: any
}

export interface Application {
    metadata: {
        state: ApplicationStatus
        declinedReason?: string
    }
    accountsLink: any
    gatewaysLink: any
    bankAccountLink: any
    websitesLink: any
    contactLink: any
    companyLink: string
    merchantApi: any
    id: string
    actions: ApplicationAction[]
    state: ApplicationStatus
    gatewayId?: string
    gateway?: Gateway
    manager: Manager
    additionalInfo?: string
    businessModel?: BusinessModel
    clonableStates?: ApplicationStatus[]
    shortBusinessModel?: string
    marketplaceId?: string
    createdAt?: string
    deliveryDelay?: string
    deliveryPhysical?: boolean
    dropShipping?: boolean
    estimateAverageTransactionAmount?: string
    estimateMonthlyTurnover?: string
    estimateMonthlyTurnoverCurrency?: string
    recurring?: boolean
    tradingName?: string
    userIds: string[]
    assessments?: Assessment[]
    bankAccounts?: BankAccount[]
    company: Company
    contact?: Contact
    people?: Person[]
    stateTransition?: StateTransition[]
    cutterInfoLoadingStatus?: LoadingStatus
    versions?: Version
    tags?: ApplicationInternalTag[]
    websites?: Website[]
    comments: Comment[]
    cvrInfo?: CVRInfo
    bvdInfo?: BVDInfo
    tagsLink: string
    selfLink: string
    commentsLink: string
    filesLink: string
    loadingStatus: LoadingStatus
    agentsLink?: string
    resourcesLoadingStatus?: {
        [key: string]: LoadingStatus
    }
    internalInteractions: {
        selfLink: string
        filesLink: string
        commentsLink: string
        comments: CutterComment[]
        files: any
        loadingStatus: LoadingStatus
    }
}

export interface CVRInfo {
    data: CVRData | undefined
    loadingStatus: LoadingStatus
}

export interface CVRData {
    cvr: number
    companyForm: string
    name: string
    address: string
    zipCode: string
    city: string
    country: string
    email: string
    phone: string
}

export interface BVDInfo {
    details?: {
        data: { status: number; body: BVDData } | undefined
        loadingStatus: LoadingStatus
    }
    ownership?: {
        data: { status: number; body: string } | undefined
        loadingStatus: LoadingStatus
    }
}

export interface BVDData {
    name: string
    incorporationDate: string
    bvdIdNumber: string
    bvdAccountNumber: string
    europeanVatNumber: string
    tin: string
    lei: string
    addressLine1: string
    street: string
    streetNumber: string
    postcode: string
    city: string
    country: string
    countryIsoCode: string
    latitude: string
    longitude: string
    nuts1: string
    nuts2: string
    nuts3: string
    worldRegion: string
    addressType: string
    additionalAddresses: any[]
    countryRegions: any[]
    additionalCountryRegions: any[]
    phoneNumbers: BVDPhone[]
    additionalPhoneNumbers: BVDPhone[]
    faxNumbers: any[]
    additionalFaxNumbers: any[]
    websiteAddresses: BVDWebsite[]
    emailAddresses: BVDEmail[]
    nationalIds: BVDNationalId[]
    tradeRegisterNumbers: BVDTradeRegisterNumber[]
    vatNumbers: any[]
    statisticalNumbers: any[]
    companyIdNumbers: BVDCompanyIdNumber[]
    previousNationalIds: any[]
    informationProviderIds: any[]
    leiStatus: string
    leiFirstAssignmentDate: string
    leiAnnualRenewalDate: string
    leiManagingLocalOfficeUnitStr: string
}

interface BVDCompanyIdNumber {
    id: string
}

interface BVDTradeRegisterNumber {
    number: string
}

interface BVDNationalId {
    id: string
    label: string
    type: string
}

interface BVDPhone {
    number: string
}

interface BVDWebsite {
    address: string
}

interface BVDEmail {
    address: string
}
