import styled from 'styled-components'
import { zIndexes } from '../../../styles/zIndexes'
import { ApplicationSchemesScreeningModalResults } from './Application.SchemesScreeningModalResults'
import { useGoBackHook } from '../../../hooks/general/useGoBackHook'
import { useParams, Params, useNavigate } from 'react-router-dom'
import { Flex } from '../../../components/layout/flex'
import { Text } from '../../../components/general/text'
import { ApplicationSchemesScreeningModalResultDetails } from './Application.SchemesScreeningModalResultDetails'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Icon } from '../../../components/icons/icon'
import { Spacer } from '../../../components/layout/spacer'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { Offset } from '../../../components/layout/offset'
import { CardInset } from '../../../components/cards/cardInset'
import { PastInquiry, Result } from '@/store/applicationInternals/schemesScreening/types'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useMemo } from 'react'

export const ApplicationSchemesScreeningModal: React.FC<{ isRootView?: boolean }> = ({ isRootView }) => {
    const params: any = useParams() as Params
    const id = params.id
    if (!id) return <></>
    const resultOrInquiryId = params.resultOrInquiryId
    const navigate = useNavigate()
    const { goBackConfig } = useGoBackHook(`/merchant/${id}/application`)
    const { goBackConfig: goBackToResultsConfig } = useGoBackHook(`/merchant/${id}/application/screening-results`)

    const latestInquiry = useSelector((state: RootState) => {
        return state.applicationInternals.schemesScreening?.forApplication?.[id]?.latestInquiry
    })
    const results: Result[] = []
    const pastInquiries: PastInquiry[] = []
    if (latestInquiry && latestInquiry.vmssResults) {
        results.push(...latestInquiry.vmssResults)
    }

    if (latestInquiry && latestInquiry.matchResults) {
        results.push(...latestInquiry.matchResults)
    }

    if (latestInquiry && latestInquiry.matchPastInquiries) {
        pastInquiries.push(...latestInquiry.matchPastInquiries)
    }

    const result = useMemo(() => {
        return results?.find((r) => r.resultId == resultOrInquiryId)
    }, [results, resultOrInquiryId])
    const pastInquiry = useMemo(() => {
        return pastInquiries?.find((r) => r.pastInquiryId == resultOrInquiryId)
    }, [pastInquiries, resultOrInquiryId])

    return (
        <Overlay
            onClick={() => {
                navigate(goBackConfig as any)
            }}
        >
            <CardHolder
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <Header>
                    {result ? (
                        <LeftButton>
                            <SimpleButton
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                <Icon type="navigationBackArrow" />
                                <Spacer width={10} />
                                Back
                            </SimpleButton>
                        </LeftButton>
                    ) : (
                        <LeftButton>
                            <Offset top={0} left={-8}>
                                <SimpleButton
                                    onClick={() => {
                                        navigate(goBackConfig as any)
                                    }}
                                >
                                    <ButtonInset>
                                        <Icon type="x" />
                                    </ButtonInset>
                                </SimpleButton>
                            </Offset>
                        </LeftButton>
                    )}
                    <Flex justify="center">
                        <Text bold color="front.text">
                            {result
                                ? 'Screening result details'
                                : pastInquiry
                                  ? 'Past inquiry details'
                                  : 'Inquiry screening results'}{' '}
                        </Text>
                    </Flex>
                </Header>
                <CardInset>
                    {!resultOrInquiryId ? (
                        <>
                            <ApplicationSchemesScreeningModalResults
                                applicationId={id}
                                handleGoBack={() => {
                                    navigate(goBackConfig as any)
                                }}
                            />
                            <Spacer height={20} />
                        </>
                    ) : (
                        <ApplicationSchemesScreeningModalResultDetails
                            latestInquiry={latestInquiry}
                            result={result}
                            pastInquiry={pastInquiry}
                        />
                    )}
                </CardInset>
            </CardHolder>
        </Overlay>
    )
}
const LeftButton = styled.div`
    position: absolute;
    top: 25px;
    left: 30px;
`

const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${zIndexes.modal};
    display: flex;
    align-items: center;
    justify-content: center;
`

const CardHolder = styled.div`
    max-width: 1100px;
    margin: 40px;
    max-height: 90vh;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    border-radius: 16px;
    background-color: ${(p) => p.theme['front.background']};
    color: ${(p) => p.theme['front.text']};
`

const Header = styled.div`
    padding: 25px;
    position: sticky;
    left: 0;
    top: 0;
    background-color: ${(p) => p.theme['front.background']};
    z-index: 1000;

    &:before {
        display: block;
        content: '';
        background-color: ${(p) => p.theme['back.border']};
        height: 1px;
        position: absolute;
        left: 30px;
        bottom: 0;
        width: calc(100% - 60px);
    }
`
