import React from 'react'
import { useDispatch } from 'react-redux'
import { WatcherButton } from '../../../../../../../components/buttons/watcherButton'
import { TasksCompanyChangesActions } from '../../../../../../../store/tasksCompanyChanges/actions'
import { MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { findKeyValueInObject } from '../../../../../../../utils/objects'
import { Text } from '../../../../../../../components/general/text'

interface ApplyButtonProps {
    taskId: string
    field: string
    after: string | null
    subsectionId?: string
    applicationData?: MonitoredFetchedApplicationData
    disabled?: boolean
}

export const ApplyButton: React.FC<ApplyButtonProps> = (props) => {
    const { taskId, field, after, subsectionId, applicationData, disabled } = props

    const dispatch = useDispatch()

    const subsectionObject = findKeyValueInObject(applicationData, 'id', subsectionId)

    const applicationId = applicationData?.application.id
    const selfLink = subsectionObject && subsectionObject.selfLink ? subsectionObject.selfLink : null

    const updateObject = after
        ? {
              [field]: after
          }
        : null

    if (disabled || !applicationId || !updateObject || !selfLink) {
        return (
            <Text color="front.text.subtlerI" strike>
                Apply
            </Text>
        )
    }

    return (
        <WatcherButton
            isDisabled={!after || disabled}
            onClick={(_, watcherId) => {
                if (applicationId && updateObject && selfLink) {
                    dispatch(
                        TasksCompanyChangesActions.APPLY_CHANGE(
                            watcherId,
                            taskId,
                            applicationId,
                            selfLink,
                            updateObject
                        )
                    )
                }
            }}
        >
            Apply
        </WatcherButton>
    )
}
