import { TableFuzzySearch } from '@/components/layout/tableFuzzySearch'
import { Table as TableComponent, TableColHeader, TableRow } from '@/components/tables/table'
import { Box, Card, Flex, ScrollArea, Separator, Text } from '@/design-system'
import React from 'react'

interface EntriesProps {
    children: React.ReactNode
}

const Entries = (props: EntriesProps) => {
    const { children } = props

    return <Card>{children}</Card>
}

interface TitleProps {
    children: React.ReactNode
}

const Title: React.FC<TitleProps> = (props) => {
    const { children } = props

    return (
        <Box pt="8" px="8" width="auto">
            <Flex align="center">
                <Text weight="medium">{children}</Text>
            </Flex>
            <Box py="4">
                <Separator />
            </Box>
        </Box>
    )
}

interface SearchProps {
    cols: TableColHeader[]
    rows: TableRow[]
    onFilteredRowsChange: (rows: TableRow[]) => void
}

const Search: React.FC<SearchProps> = (props) => {
    const { cols, rows, onFilteredRowsChange } = props

    return (
        <Box px="6" pb="4" width="auto">
            <Flex grow="0">
                <TableFuzzySearch
                    headers={cols}
                    queryParamName="reconciliation-query"
                    rows={rows}
                    setFilteredRows={onFilteredRowsChange}
                    ignoreHeaderWithText="Actions"
                />
            </Flex>
        </Box>
    )
}

interface TableActionsProps {
    children: React.ReactNode
}

const TableActions: React.FC<TableActionsProps> = (props) => {
    const { children } = props

    return (
        <Box pb="5" px="6" width="auto">
            <Flex>{children}</Flex>
        </Box>
    )
}

interface TableProps {
    cols: TableColHeader[]
    rows: TableRow[]
    columnLayout: React.CSSProperties['gridTemplateColumns']
    emptyText: string
}

const Table: React.FC<TableProps> = (props) => {
    const { rows, cols, columnLayout, emptyText } = props

    return (
        <>
            <Separator />
            <ScrollArea height={400}>
                <Card.Body>
                    <TableComponent
                        loaderRows={5}
                        rows={rows}
                        cols={cols}
                        background="front.background"
                        columnLayout={columnLayout}
                        emptyText={emptyText}
                    />
                </Card.Body>
            </ScrollArea>
        </>
    )
}

Entries.Title = Title
Entries.Search = Search
Entries.Table = Table
Entries.TableActions = TableActions

export { Entries }
