import { createActionCreator } from 'deox'

import { MerchantTimeline } from './types'

const Prefix = 'MERCHANT_TIMELINE'

export const MerchantTimelineActions = {
    PROCESS: createActionCreator(
        `${Prefix}/PROCESS`,
        (resolve) => (cutterResponse: any, merchantId: string) => resolve({ cutterResponse, merchantId })
    ),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (timeline: Omit<MerchantTimeline, 'loadingStatus'>, merchantId: string) =>
            resolve({ timeline, merchantId })
    ),
    MARK_AS_COMPLETE: createActionCreator(
        `${Prefix}/MARK_AS_COMPLETE`,
        (resolve) => (merchantId: string) => resolve({ merchantId })
    ),
    CLEAR: createActionCreator(`${Prefix}/CLEAR`, (resolve) => (merchantId: string) => resolve({ merchantId }))
}
