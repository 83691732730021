import styled, { css } from 'styled-components'
import { ApplicationComment } from '../../store/applications/types'
import moment from 'moment'
import { MarkdownHolder } from '../../components/general/markdownHolder'
import { Text } from '../../components/general/text'
import { ConditionallyShowInteractionDate } from './MerchantSidebarInteractions.ConditionallyShowInteractionDate'
import { Flex } from '../../components/layout/flex'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { useCallback, useContext, useState, useMemo } from 'react'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { Floater } from '../../components/layout/floater'
import { FloaterInset } from '../../components/layout/floaterInset'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { useOnClickOutside } from '../../hooks/general/useOnClickOutside'
import { DialogContext } from '../../contexts/dialogProvider'
import { useDispatch } from 'react-redux'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { ApplicationResourceActions } from '../../store/applicationResources/actions'
import { ConvertIndexPathStringToIndexPath } from '../../store/applicationResources/utils'
import { WatcherDispatchFail } from '../../store/watcher/actions'

// eslint-disable-next-line max-len
import { useMerchantApplicationFormChangesBuilder } from './Application/useMerchantApplicationFormChangesBuilder'
import { useReadCommentsOnScroll } from '../../hooks/general/useReadCommentsOnScroll'

export const MerchantInteractionComment: React.FC<{
    comment: ApplicationComment
    indexPath: string
    isUnread?: boolean
    applicationId: string
}> = ({ comment, indexPath, isUnread, applicationId }) => {
    const isAgent = comment?.author?.email.toLowerCase().includes('@clearhaus.')
    const [hovered, setHovered] = useState(false)
    const [anchor, setAnchor] = useRefTaker()
    const [holder, setHolder] = useRefTaker()
    const [displayMenu, setDisplayMenu] = useState(false)
    const dialogContext = useContext(DialogContext)
    const dispatch = useDispatch()
    const { changeFormatter } = useMerchantApplicationFormChangesBuilder(applicationId)
    const { setRef } = useReadCommentsOnScroll(applicationId, comment.id, isUnread)

    useOnClickOutside(holder, () => {
        setDisplayMenu(false)
    })

    const handleMouseIn = useCallback(() => {
        setHovered(true)
    }, [setHovered])

    const handleMouseOut = useCallback(() => {
        setHovered(false)
        setDisplayMenu(false)
    }, [setHovered])

    const handleMoreClick = useCallback(() => {
        setDisplayMenu(true)
    }, [setDisplayMenu])

    const handleDelete = useCallback(
        (e: any, generatedWatcherId: any) => {
            if (!isAgent) return
            dialogContext?.setDialog({
                title: 'Confirm comment deletion',
                description: 'Are you sure you want to delete this comment permanently?',
                action: {
                    label: 'Delete permanently',
                    buttonBackground: 'front.danger.color',
                    watcherId: generatedWatcherId,
                    action: () => {
                        // eslint-disable-next-line max-len
                        const ip = ConvertIndexPathStringToIndexPath(indexPath)
                        if (!ip) {
                            dispatch(
                                WatcherDispatchFail(
                                    [generatedWatcherId],
                                    `Couldn't conver indexPath for ${JSON.stringify(indexPath)}`
                                )
                            )
                            return
                        }
                        dispatch(
                            ApplicationResourceActions.REMOVE_COMMENT(comment.id, generatedWatcherId, ip, applicationId)
                        )
                    }
                }
            })
        },
        [dialogContext, comment, isAgent, dispatch, indexPath, applicationId]
    )

    const commentWithFormattedFieldUpdate = useMemo(() => {
        if (comment?.body) {
            if (comment.body.includes('<field-update>'))
                return 'Field change: ' + comment.body.replace('<field-update>', '→')
            return comment.body
        }
        return comment?.body
    }, [comment])
    return (
        <CommentBlock
            data-cy="interaction-comment"
            ref={setRef}
            isAgent={isAgent}
            onMouseEnter={handleMouseIn}
            onMouseLeave={handleMouseOut}
            isUnread={isUnread}
        >
            <Header>
                <Flex>
                    <Text bold>{comment?.author?.email}</Text>
                    {hovered ? (
                        <Hovered ref={setHolder}>
                            <ButtonOffset ref={setAnchor}>
                                <SimpleButton
                                    color={!isAgent ? 'front.accent.text' : undefined}
                                    background={
                                        !isAgent ? 'front.accent.color.subtlerI' : 'front.subtleAccent.background'
                                    }
                                    onClick={handleMoreClick}
                                >
                                    <ButtonInset padding="tiny">More</ButtonInset>
                                </SimpleButton>
                            </ButtonOffset>
                            <Floater anchor={anchor} shouldShow={displayMenu} placement="top" noFocusLock>
                                <FloaterInset padding="small">
                                    <DropcardHeader>
                                        <Text color="floating.text" cy="interaction-message-meta">
                                            Comment has been left on{' '}
                                            <Path>{changeFormatter.textForFieldName(indexPath)}</Path>
                                        </Text>
                                    </DropcardHeader>
                                    {isAgent && (
                                        <Flex>
                                            <WatcherButton
                                                predefinedWatcher={`${comment.id}.RemoveComment`}
                                                color={'front.danger.color'}
                                                onClick={handleDelete}
                                            >
                                                <ButtonInset noHorizontalPadding>Delete comment</ButtonInset>
                                            </WatcherButton>
                                        </Flex>
                                    )}
                                </FloaterInset>
                            </Floater>
                        </Hovered>
                    ) : null}
                </Flex>
                <ConditionallyShowInteractionDate isAgent={isAgent} right={20}>
                    {moment(comment?.createdAt).format('HH:mm:ss')}
                </ConditionallyShowInteractionDate>
            </Header>
            <MarkdownHolder>{commentWithFormattedFieldUpdate}</MarkdownHolder>
        </CommentBlock>
    )
}

const Hovered = styled.div`
    z-index: 1;
`

const ButtonOffset = styled.div`
    margin-left: 10px;
    margin-top: -5px;
    padding-top: 5px;
    margin-bottom: -5px;
`

const Header = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 10px;
`

const CommentBlock = styled.div<{ isAgent?: boolean; isUnread?: boolean }>`
    margin: 0px 0px 10px 0px;
    padding: 20px;
    position: relative;
    border-radius: 14px;
    width: 70%;
    align-self: flex-start;
    background-color: ${(p) => p.theme['front.accent.color']};
    color: ${(p) => p.theme['front.accent.text']};

    ${(p) =>
        !p.isAgent &&
        css`
            a {
                color: ${(p) => p.theme['front.accent.text.subtlerI']};
                text-decoration: underline;
                text-decoration-style: dotted;

                &:hover {
                    color: ${(p) => p.theme['front.accent.text']};
                }
            }
        `}

    ${(p) =>
        p.isAgent &&
        css`
            background-color: ${(p) => p.theme['back.background']};
            color: ${(p) => p.theme['front.text']};
            align-self: flex-end;
        `}

    ${(p) =>
        p.isUnread &&
        css`
            background-color: ${p.theme['front.subtleInfo.background']};
            color: ${p.theme['front.subtleInfo.text']};
        `}
`

const DropcardHeader = styled.div``

const Path = styled.div`
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    margin-left: 3px;
    padding: 2px 5px;
`
