export const AUTOCOMPLETE_SEARCH_AGENTS = 'AUTOCOMPLETE_SEARCH_AGENTS'
export const AUTOCOMPLETE_STORE_AGENTS = 'AUTOCOMPLETE_STORE_AGENTS'
export const AUTOCOMPLETE_REMOVE_AGENTS = 'AUTOCOMPLETE_REMOVE_AGENTS'
export const AUTOCOMPLETE_FETCH_SELECTED_AGENT = 'AUTOCOMPLETE_FETCH_SELECTED_AGENT'
export const AUTOCOMPLETE_STORE_SELECTED_AGENT = 'AUTOCOMPLETE_STORE_SELECTED_AGENT'
export const AUTOCOMPLETE_CLEAR_SELECTED_AGENT = 'AUTOCOMPLETE_CLEAR_SELECTED_AGENT'
export const AUTOCOMPLETE_FIND_MERCHANTS = 'AUTOCOMPLETE_FIND_MERCHANTS'
export const AUTOCOMPLETE_STORE_MERCHANTS = 'AUTOCOMPLETE_STORE_MERCHANTS'
export const AUTOCOMPLETE_CLEAR_MERCHANTS = 'AUTOCOMPLETE_CLEAR_MERCHANTS'

//-
export interface AutocompleteActionsSearchAgents {
    type: typeof AUTOCOMPLETE_SEARCH_AGENTS
    query: string
    selectedId?: string
    keepIds?: string[]
    maintainList?: boolean
    searchDatasetOnly?: boolean
}
export interface AutocompleteActionsStoreAgents {
    type: typeof AUTOCOMPLETE_STORE_AGENTS
    agents: any[]
    dataset: any[]
}
export interface AutocompleteActionsRemoveAgents {
    type: typeof AUTOCOMPLETE_REMOVE_AGENTS
}
export interface AutocompleteActionsFetchSelectedAgent {
    type: typeof AUTOCOMPLETE_FETCH_SELECTED_AGENT
    id: string
}
export interface AutocompleteActionsStoreSelectedAgent {
    type: typeof AUTOCOMPLETE_STORE_SELECTED_AGENT
    agent: any
}
export interface AutocompleteActionsClearSelectedAgent {
    type: typeof AUTOCOMPLETE_CLEAR_SELECTED_AGENT
}

export interface AutocompleteActionsFindMerchants {
    type: typeof AUTOCOMPLETE_FIND_MERCHANTS
    query: string
    keepIds?: string[]
}
export interface AutocompleteActionsStoreMerchants {
    type: typeof AUTOCOMPLETE_STORE_MERCHANTS
    merchants: any[]
}
export interface AutocompleteActionsClearMerchants {
    type: typeof AUTOCOMPLETE_CLEAR_MERCHANTS
}

//-
export const AutocompleteDispatchSearchAgents = (
    query: string,
    selectedId?: string,
    keepIds?: string[],
    maintainList?: boolean,
    searchDatasetOnly?: boolean
): AutocompleteActionsSearchAgents => {
    return { type: AUTOCOMPLETE_SEARCH_AGENTS, query, selectedId, keepIds, maintainList, searchDatasetOnly }
}

export const AutocompleteDispatchStoreAgents = (agents: any[], dataset?: any): AutocompleteActionsStoreAgents => {
    return { type: AUTOCOMPLETE_STORE_AGENTS, agents, dataset }
}

export const AutocompleteDispatchRemoveAgents = (): AutocompleteActionsRemoveAgents => {
    return { type: AUTOCOMPLETE_REMOVE_AGENTS }
}

export const AutocompleteDispatchFetchSelectedAgent = (id: string): AutocompleteActionsFetchSelectedAgent => {
    return { type: AUTOCOMPLETE_FETCH_SELECTED_AGENT, id }
}

export const AutocompleteDispatchStoreSelectedAgent = (agent: any): AutocompleteActionsStoreSelectedAgent => {
    return { type: AUTOCOMPLETE_STORE_SELECTED_AGENT, agent }
}

export const AutocompleteDispatchClearSelectedAgent = (): AutocompleteActionsClearSelectedAgent => {
    return { type: AUTOCOMPLETE_CLEAR_SELECTED_AGENT }
}

export const AutocompleteDispatchFindMerchants = (
    query: string,
    keepIds?: string[]
): AutocompleteActionsFindMerchants => {
    return { type: AUTOCOMPLETE_FIND_MERCHANTS, query, keepIds }
}

export const AutocompleteDispatchStoreMerchants = (merchants: any[]): AutocompleteActionsStoreMerchants => {
    return { type: AUTOCOMPLETE_STORE_MERCHANTS, merchants }
}

export const AutocompleteDispatchClearMerchants = (): AutocompleteActionsClearMerchants => {
    return { type: AUTOCOMPLETE_CLEAR_MERCHANTS }
}

export interface SagasForAutocomplete {
    searchAgents(action: AutocompleteActionsSearchAgents): void
    fetchSelectedAgent(action: AutocompleteActionsFetchSelectedAgent): void
    findMerchants(query: AutocompleteActionsFindMerchants): void
}

export type ReducedAutocompleteActions =
    | AutocompleteActionsSearchAgents
    | AutocompleteActionsStoreAgents
    | AutocompleteActionsRemoveAgents
    | AutocompleteActionsFetchSelectedAgent
    | AutocompleteActionsStoreSelectedAgent
    | AutocompleteActionsClearSelectedAgent
    | AutocompleteActionsFindMerchants
    | AutocompleteActionsStoreMerchants
    | AutocompleteActionsClearMerchants
