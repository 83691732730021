import { useForm as useHookForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export type IntegrationSchema = yup.InferType<typeof schema>

const schema = yup.object().shape({
    name: yup.string().max(20).required(),
    description: yup.string().required(),
    transaction_rules: (yup.string().required() as any).suez(),
    support_partner_id: yup.string().required()
}).required()

export const useIntegrationForm = (defaultValues?: IntegrationSchema) =>
    useHookForm<IntegrationSchema>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            name: defaultValues?.name,
            description: defaultValues?.description,
            transaction_rules: defaultValues?.transaction_rules,
            support_partner_id: defaultValues?.support_partner_id
        }
    })
