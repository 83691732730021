import React, { useMemo } from 'react'

import styled, { css, keyframes } from 'styled-components'
import { Theme } from '../../hooks/general/useTheme'
import { Color } from '../../styles/colors'
import { LoadingShaper } from './loadingShaper'

export const Spinner: React.FC<{
    type?: 'l' | 'm' | 's'
    overBackground: keyof Theme
}> = ({ type = 'm', overBackground = 'front.background' }) => {
    switch (type) {
        case 's':
            return (
                <LoadingShaper data-cy="loader" width={18} height={18} sidebar={overBackground === 'side.background'}>
                    <circle cx={18 / 2} cy={18 / 2} r={18 / 2} />
                </LoadingShaper>
            )
        default:
            return (
                <LoadingShaper data-cy="loader" width={52} height={52} sidebar={overBackground === 'side.background'}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        // eslint-disable-next-line max-len
                        d="M25.8141 50.6851C39.6212 50.6851 50.8141 39.4922 50.8141 25.6851C50.8141 11.8779 39.6212 0.685059 25.8141 0.685059C12.007 0.685059 0.814087 11.8779 0.814087 25.6851C0.814087 39.4922 12.007 50.6851 25.8141 50.6851ZM25.8141 35.685C31.337 35.685 35.8141 31.2078 35.8141 25.685C35.8141 20.1621 31.337 15.685 25.8141 15.685C20.2913 15.685 15.8141 20.1621 15.8141 25.685C15.8141 31.2078 20.2913 35.685 25.8141 35.685Z"
                    />
                </LoadingShaper>
            )
    }
}

export const LoadingSpinner: React.FC<{
    type?: 'l' | 'm' | 's'
    overBackground: keyof Theme
}> = ({ type = 'm', overBackground = 'front.background' }) => {
    const colors = useMemo((): { path: Color; color: Color } => {
        if (overBackground === 'front.background') {
            return {
                path: 'front.highlights.strongerIII',
                color: 'front.accent.color'
            }
        }

        if (overBackground === 'back.background') {
            return {
                path: 'front.accent.color',
                color: 'back.highlights.strongerIII'
            }
        }

        if (overBackground === 'front.accent.color') {
            return {
                path: 'front.accent.text',
                color: 'front.accent.color.subtlerI'
            }
        }

        if (overBackground === 'side.background') {
            return {
                path: 'side.background.strongerI',
                color: 'side.accent.color'
            }
        }

        if (overBackground === 'front.subtleAccent.background') {
            return {
                path: 'front.subtleAccent.text',
                color: 'front.subtleAccent.background.strongerI'
            }
        }

        if (overBackground === 'front.subtleDanger.background') {
            return {
                path: 'front.subtleDanger.background.subtlerI',
                color: 'front.subtleDanger.text'
            }
        }

        if (overBackground === 'overlay.background') {
            return {
                path: 'overlay.highlights.strongerII',
                color: 'overlay.highlights'
            }
        }

        if (overBackground === 'floating.background') {
            return {
                path: 'floating.background.subtlerI',
                color: 'floating.text'
            }
        }

        return {
            path: 'front.background.strongerI',
            color: 'front.accent.color'
        }
    }, [overBackground])

    return (
        <Holder type={type} data-cy="loader" background={colors.path} key="holder">
            <SVG
                type={type}
                width={`${P[type].size}px`}
                height={`${P[type].size}px`}
                viewBox={`0 0 ${P[type].size * 2 + P[type].weight} ${P[type].size * 2 + P[type].weight}`}
                key="loader"
                offset={P[type].offset}
                foreground={colors.color}
            >
                <circle
                    className="path"
                    fill="none"
                    strokeWidth={P[type].weight}
                    cx={P[type].size + P[type].weight / 2}
                    cy={P[type].size + P[type].weight / 2}
                    r={P[type].size}
                />
            </SVG>
        </Holder>
    )
}
const time = '0.5s'

const scale = keyframes`
	0% {
        opacity: 1;
		transform: scale(1) translateZ(0);
	}
	50% {
        opacity: 0.66;
		transform: scale(1) translateZ(0);
	}
	100% {
        opacity: 1;
		transform: scale(1) translateZ(0);
	}
`
const P = {
    s: {
        size: 14,
        weight: 4,
        offset: 35
    },
    m: {
        size: 24,
        weight: 4,
        offset: 114
    },
    l: {
        size: 48 * 1.3,
        weight: 6,
        offset: 179
    }
}
const Holder = styled.div<{
    background: Color
    type: 'l' | 'm' | 's'
}>`
    animation: ${scale} 2s ease-in-out infinite;
    ${(p) => css`
        width: ${P[p.type].size - P[p.type].weight}px;
        height: ${P[p.type].size - P[p.type].weight}px;
        border-radius: 999px;
        border: ${P[p.type].weight / 2}px solid ${p.theme[p.background]};
        position: relative;
        z-index: 100;
        ${p.type === 'l' &&
        css`
            top: -10px;
        `}
    `}
`
const rotator = keyframes`
	0% {
		transform: rotate(0deg) translateZ(0);
	}
	100% {
		transform: rotate(360deg) translateZ(0);
	}
`

const SVG = styled.svg<{
    type: 's' | 'm' | 'l'
    foreground?: Color
}>`
    animation: ${rotator} ${time} linear infinite;
    will-change: transform;
    position: absolute;
    stroke-dasharray: ${(p) => P[p.type].offset};
    stroke-dashoffset: 0;
    stroke: ${(p) => (p.foreground ? p.theme[p.foreground] : p.theme['front.accent.color'])};
    ${(p) => css`
        top: -${P[p.type].weight / 2}px;
        left: -${P[p.type].weight / 2}px;
    `}
    margin-top: -1px;
    .path {
        will-change: stroke-dashoffset;
        transform: translateZ(0);
        stroke-dasharray: ${(p) => P[p.type].offset};
        stroke-dashoffset: 0;
        transform-origin: center;
        stroke: ${(p) => (p.foreground ? p.theme[p.foreground] : p.theme['front.accent.color'])};
    }
`
