import { Collection, CollectionWithPagination, CollectionWithSelected, LoadingStatus } from '../../utils'
import { FiltersParams } from '../../utils/store'
import { Agent } from '../applicationInternals/agents/types'
import { Application } from '../applications/types'
import { Merchant } from '../autocomplete/types'

export type TaskPriority = 'normal'

export type TaskType =
    | 'instant-signup'
    | 'transaction-investigation'
    | 'odd-reporting'
    | 'alarm'
    | 'test'
    | 'company-change'

export const TaskTypes = ['instant-signup', 'alarm', 'company-change']

export type TaskSubject = 'account'

export const TaskStatuses = ['open', 'closed', 'new']
export type TaskStatus = typeof TaskStatuses[number]

export interface Task {
    assignee: string
    createdAt: string
    dueAt: string
    id: string
    priority: TaskPriority
    source: string
    state: TaskStatus
    tags: string[]
    title: string
    type: TaskType
    subjectId: string
    subjectType: TaskSubject
    application: Application
    account: any
    commentsLink?: string
    filesLink?: string
    eventsLink?: string
    addTagsLink: string
    removeTagsLink: string
    selfLink?: string
    timeline: {
        timeline: any[]
        loadingStatus: LoadingStatus
    }
    loadingStatus: LoadingStatus
}

export interface TaskComment {
    authorId: string
    body: string
    createdAt: string
    id: string
    markdownBody: string
    selfLink: string
    subjectId: string
    subjectType: string
    tags: string[]
    updatedAt: string
}

export interface TaskFiltersState {
    state?: string
    startDate?: Date
    endDate?: Date
    agent?: Agent
    mid?: Merchant
    tags: TaskTag[]
}

export interface TaskTag {
    id: string
    name: string
    taggingsCount: number
}

export const TaskFiltersParams: FiltersParams = {
    tasks_status: [],
    tasks_type: [],
    tasks_start_date: '',
    tasks_end_date: '',
    tasks_agent: '',
    tasks_subject_type: '',
    tasks_subject_id: '',
    tasks_tag: [],
    tasks_page: 1,
    tasks_per_page: 20
}

export interface TasksFilters {
    tasks_status: TaskStatus[]
    tasks_type: string[]
    tasks_start_date: string
    tasks_end_date: string
    tasks_agent: string
    tasks_subject_type: 'account'
    tasks_subject_id: string[]
    tasks_tag: string[]
    tasks_page: number
    tasks_per_page: number
}

export interface TasksState {
    tasks: Collection<Task>
    tags: CollectionWithSelected<TaskTag>
    taskSummaries: CollectionWithPagination<
        Task,
        {
            total: number
            page: number
            perPage: number
        }
    >
}

export const InitialTasksState: TasksState = {
    taskSummaries: {
        at: {},
        all: [],
        pagination: undefined,
        loadingStatus: 'not-started'
    },
    tasks: {
        at: {},
        all: [],
        loadingStatus: 'not-started'
    },
    tags: {
        at: {},
        all: [],
        selectedLoadingStatus: 'not-started',
        loadingStatus: 'not-started'
    }
}
