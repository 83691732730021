import { useEffect, useState } from 'react'
import { useRefTaker } from '../general/useRefTaker'

export const useHasScrolled = (overlay?: boolean) => {
    const [isMoving, setIsMoving] = useState(false)
    const [ref, setRef] = useRefTaker()

    useEffect(() => {
        let target: any = window
        if (overlay) {
            target = ref?.closest('#PAGE')
        } else {
            target = ref?.closest('#SCROLL-CONTAINER')
            if (!target) target = window
        }

        const shouldShowIfPassedOffset = () => {
            if (target === window ? target.scrollY : target.scrollTop >= 1) setIsMoving(true)
            else setIsMoving(false)
        }

        target?.addEventListener('scroll', shouldShowIfPassedOffset)
        return () => {
            target?.removeEventListener('scroll', shouldShowIfPassedOffset)
        }
    }, [setIsMoving, ref, overlay])

    return { isMoving, setRef }
}
