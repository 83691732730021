import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateJWTSync } from '../../../store/auth/sagas'

import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { RootState } from '@/store'
import { LoadingStatus } from '../../../utils'

export const useFetchContractPreview = (accountId: string, contractId: string) => {
    const dispatch = useDispatch()
    const account = useSelector((state: RootState) => state.merchantAccounts.at?.[accountId])
    const [state, setState] = useState<{
        loadingStatus: LoadingStatus
        pdf?: any
    }>({
        loadingStatus: 'not-started'
    })

    useEffect(() => {
        if (account?.loadingStatus !== 'started' && account?.loadingStatus !== 'done')
            dispatch(MerchantAccountsActions.FETCH_ACCOUNT(accountId))
    }, [account, accountId, dispatch])

    useEffect(() => {
        if (
            account?.loadingStatus === 'done' &&
            state?.loadingStatus !== 'done' &&
            state?.loadingStatus !== 'started'
        ) {
            setState({
                loadingStatus: 'started'
            })

            generateJWTSync((token: string) => {
                return fetch(
                    contractId === 'draft'
                        ? `${import.meta.env.VITE_CUTTER_ROOT}/agreements/${account.draftContractLink
                              ?.split('/')
                              .pop()}?format=pdf`
                        : `${import.meta.env.VITE_API_ROOT}/contracts/${contractId}?format=pdf`,
                    {
                        method: 'GET',
                        headers: new Headers({
                            Authorization: `Bearer ${token}`,
                            Accept: 'application/pdf'
                        })
                    }
                )
                    .then((pdf) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        pdf.blob().then((r: any) => {
                            setState({
                                loadingStatus: 'done',
                                pdf: window.URL.createObjectURL(r)
                            })
                        })
                    })
                    .catch(() => {
                        setState({
                            loadingStatus: 'done'
                        })
                    })
            })
        }
    }, [state, setState, account, contractId])

    return state
}
