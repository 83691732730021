import { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { Icon } from '../icons/icon'
import { MODAL_ID } from '../modals/modalIds'
import { ButtonInset } from './buttonInset'
import { LinkButton } from './linkButton'
import { useLocation, useParams } from 'react-router-dom'

type Params = {
    id: string
    account_id: string
    sole_mid: string
}

export const ListPageFullPageBackButton: React.FC<{ fullPage?: boolean; hotkeysScope: MODAL_ID }> = ({
    fullPage,
    hotkeysScope
}) => {
    const params = useParams() as Params
    const location = useLocation()
    const initialBackTo = useRef<string | undefined>()

    useEffect(() => {
        initialBackTo.current = (location.state as any)?.backTo as string
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const backTo = useMemo(() => {
        return `/merchant/${params['id']}/accounts/${params['account_id']}`
    }, [params])

    if (!fullPage) return null

    return (
        <Holder>
            <LinkButton hotkeys="esc, alt+esc" hotkeysScope={hotkeysScope} background="front.accent.color" to={backTo}>
                <ButtonInset>
                    <ArrowHolder>
                        <Icon type="arrow" />
                    </ArrowHolder>
                    Back
                </ButtonInset>
            </LinkButton>
        </Holder>
    )
}

const Holder = styled.div`
    margin: -10px;
    margin-bottom: 0px;
    margin-top: 0px;
`
const ArrowHolder = styled.div`
    position: relative;
    transform: rotateZ(-180deg);
    margin-right: 10px;
`
