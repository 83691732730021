import styled from 'styled-components'
import { MerchantRiskLevel } from '../../pages/Merchant/More/Risk/RiskLevel'
import { getColor } from '../../store/classifications/utils'
import { Color } from '../../styles/colors'
import { uppercaseFirstLetter } from '../../utils'

export const RiskSpeedometer: React.FC<{ points: number; risk: string }> = ({ points, risk }) => {
    const getTransform = () => {
        const degrees = 45
        const pointDegrees = points * 22.5
        const totalDegrees = degrees + pointDegrees

        if (totalDegrees > 134) {
            return 134
        }
        if (totalDegrees < -44) {
            return -44
        }

        return totalDegrees
    }

    return (
        <div>
            <SpeedoHeader>
                <MerchantRiskLevel data-cy="risk-level" color={getColor(risk)}>
                    {uppercaseFirstLetter(risk.split('_').join(' '))}
                </MerchantRiskLevel>
            </SpeedoHeader>
            <SpeedoHolder>
                <SpeedoDot />
                <Needle transform={getTransform()} />
                <SpeedoWrapper>
                    <Speedometer>
                        <Score transform={getTransform()} color={getColor(risk)} />
                        <Groove />
                    </Speedometer>
                </SpeedoWrapper>
            </SpeedoHolder>
        </div>
    )
}

const SpeedoHeader = styled.div`
    display: flex;
    justify-content: center;
`

const SpeedoHolder = styled.div`
    position: relative;
`

const SpeedoDot = styled.div`
    display: block;
    width: 6px;
    height: 6px;
    background: ${(p) => p.theme['front.text']};
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: -3px;
    left: 0;
    margin: auto;
`

const SpeedoWrapper = styled.div`
    height: 100px;
    overflow: hidden;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    position: relative;
`

const Speedometer = styled.div`
    height: 200px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`

const Needle = styled.div<{ transform: number }>`
    width: 50px;
    height: 1px;
    background: ${(p) => p.theme['front.text']};
    transform: rotate(${(p) => p.transform + 45}deg) translateX(-50%);
    transition: transform 0.3s ease;
    position: absolute;
    transform-origin: bottom;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
`

const Score = styled.div<{ transform: number; color: Color }>`
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(${(p) => p.transform}deg);
    height: 200px;
    width: 200px;
    background: transparent;
    border-radius: 50%;
    border: 25px solid;
    border-color: transparent transparent ${(p) => p.theme[p.color]} ${(p) => p.theme[p.color]};

    box-sizing: border-box;
    cursor: pointer;
    z-index: 1;
    transition: transform 0.3s ease, border-color 0.3s ease;
`

const Groove = styled.div`
    height: 100px;
    width: 200px;
    background: transparent;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border: 25px solid ${(p) => p.theme['back.background']};
    border-bottom: 0;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
`
