import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ExportsActions } from './actions'

export const ExportsWatcher: React.FC = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(ExportsActions.FETCH_EXPORTS({ page: 1, per_page: 20 }))
    }, [dispatch])
    return null
}
