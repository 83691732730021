import { Box, Card, Flex, Separator } from '@/design-system'
import React from 'react'

interface SectionProps {
    children: React.ReactNode
}

const Section = (props: SectionProps) => {
    const { children } = props

    return (
        <Card size="lg">
            <Card.Body>
                <Flex direction="column">{children}</Flex>
            </Card.Body>
        </Card>
    )
}

interface HeaderProps {
    children: React.ReactNode
}

const Header = (props: HeaderProps) => {
    return (
        <>
            <Flex align="center">{props.children}</Flex>
            <Box py="4">
                <Separator />
            </Box>
        </>
    )
}

interface TitleProps {
    children: React.ReactNode
}

const Title: React.FC<TitleProps> = (props) => {
    const { children } = props

    return <>{children}</>
}

interface ActionsProps {
    children: React.ReactNode
}

const Actions: React.FC<ActionsProps> = (props) => {
    const { children } = props

    return (
        <Flex grow="1" justify="end">
            {children}
        </Flex>
    )
}

interface ContentProps {
    children: React.ReactNode
}

const Content: React.FC<ContentProps> = (props) => {
    const { children } = props

    return <>{children}</>
}

Section.Header = Header
Section.Content = Content
Header.Title = Title
Header.Actions = Actions

export { Section, Header }
