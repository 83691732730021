import { FormFieldFormatting } from '../hooks/general/useForm'
import { SmartNavLink } from '../components/tables/smartNavLink'
import currencies from '@/assets/currencies.json'
import currenciesHistory from '@/assets/currencies_history.json'
import Dinero, { Currency } from 'dinero.js'
import moment from 'moment'
import normalizeUrl from 'normalize-url'
import numeral from 'numeral'
import { startCase } from 'lodash'
import styled from 'styled-components'
import moveDecimalPoint from 'move-decimal-point'
import { ISO4217, ISO8601DateTime } from '@/services/common'

export type LoadingStatus = 'not-started' | 'started' | 'done' | 'error'

export interface CollectionWSeparateLoading<T> {
    at: {
        [key: string]: T
    }
    all: string[]
}

export interface Collection<T> {
    at: {
        [key: string]: T
    }
    all: string[]
    loadingStatus: LoadingStatus
}

export const isMacOS = navigator.platform.toUpperCase().indexOf('MAC') >= 0
export interface HALLink {
    href: string
}

export interface CollectionWithPagination<T, P> {
    at: {
        [key: string]: T
    }
    all: string[]
    pagination?: P
    loadingStatus: LoadingStatus
}

export interface Pagination {
    total: number
    page: number
    perPage: number
}

export const uppercaseFirstLetter = (s: string) => {
    const newString = startCase(s).toLowerCase()
    return newString.charAt(0).toUpperCase() + newString.slice(1)
}

export const uppercaseFirstLetterIfLowercase = (s: string) => {
    if (new RegExp('^[a-z]+$').test(s.charAt(0))) return s.charAt(0).toUpperCase() + s.slice(1)
    return s
}

export const getListPageModalTitle = (
    name: 'Dispute' | 'Alert' | 'Transaction' | 'Settlement',
    loadingStatus: LoadingStatus,
    merchantName = 'Unknown',
    date?: string
): string => {
    if (loadingStatus !== 'done') return 'Loading…'
    if (!date) return `${name} - ${merchantName} - Unknown date`
    return `${name} - ${merchantName} - ${moment(date).format('YYYYMMDD')}`
}

export interface CollectionWithSelected<T> {
    at: {
        [key: string]: T
    }
    all: string[]
    loadingStatus: LoadingStatus
    selectedLoadingStatus: LoadingStatus
}

export interface HotkeysConfig {
    keys: { [key: string]: string }
    handlers: { [key: string]: (e: Event) => void }
}

const numeralFormat = '0,0.00'
export function numberWithCommas(x: number, noDecimals?: boolean): string
export function numberWithCommas(x: string, noDecimals?: boolean): string
export function numberWithCommas(number: number | string, noDecimals?: boolean): string {
    const formattedNumber = () => {
        if (typeof number === 'string') {
            return numeral(number).format(numeralFormat)
        }
        return numeral(number).format(numeralFormat)
    }

    if (noDecimals) return `${formattedNumber()}`.split('.', 1)[0]
    return formattedNumber()
}

export function numberFromFormattedString(n: string): number | undefined {
    return numeral(n)?.value() as number
}

export const FormFieldWithFormattedNoDecimalsNumber: FormFieldFormatting = {
    fromServer: (s) => {
        if ((s + '').includes('.')) return numberWithCommas(s)
        else return numberWithCommas(s, true)
    },
    toServer: {
        beforeSending: (s): number | undefined => {
            return numeral(s).value() as number
        }
    }
}

export const FormFieldWithFormattedNumber: FormFieldFormatting = {
    fromServer: (s) => numberWithCommas(s),
    toServer: {
        beforeSending: (s): number | string => {
            return numeral(s).value() || '0'
        }
    }
}

export function fixUrl(n: string): string {
    if (!n) return ''
    try {
        return normalizeUrl(n)
    } catch {
        return ''
    }
}
export function uppercasedString(n: string): string {
    return n.toUpperCase()
}
export function falseOrUndefined(t: any) {
    if (t) {
        return t
    }
    if (t === false) {
        return false
    }
    return undefined
}

const EXPONENT_0 = [
    'bif',
    'byr',
    'clp',
    'djf',
    'gnf',
    'jpy',
    'kmf',
    'krw',
    'pyg',
    'rwf',
    'ugx',
    'vnd',
    'vuv',
    'xaf',
    'xof',
    'xpf'
]
const EXPONENT_3 = ['bhd', 'iqd', 'jod', 'kwd', 'omr', 'tnd']
export const currencyFilter = (currency: string, val: number): number => {
    if (!currency) {
        return (val / 100) * 1.0
    }

    if (EXPONENT_0.includes(currency)) {
        return val * 1.0
    }
    if (EXPONENT_3.includes(currency)) {
        return (val / 10000) * 1.0
    }
    return (val / 100) * 1.0
}

export const inverseCurrencyFilter = (currency: string, val: number): number => {
    if (!currency) {
        return Math.round(val * 100)
    }

    if (EXPONENT_0.includes(currency)) {
        return Math.round(val * 1)
    }
    if (EXPONENT_3.includes(currency)) {
        return Math.round(val * 10000)
    }
    return Math.round(val * 100)
}

export const getCurrencyPrecision = (currency: any, dateTime: ISO8601DateTime) => {
    const currencyInfo = currencies[currency as ISO4217]
    const currencyInfoHistory = currenciesHistory[currency as keyof typeof currenciesHistory]

    let precision = 2

    if (currencyInfo) {
        precision = currencyInfo.exp as unknown as number
    }

    if (currencyInfoHistory) {
        const valueDateTime = new Date(dateTime)
        const beforeDateTime = new Date(currencyInfoHistory.before_date_time)

        if (valueDateTime < beforeDateTime) {
            precision = currencyInfoHistory.exp as unknown as number
        }
    }

    return precision
}

export const getAmount = (value: any, currency: any, date?: any) => {
    if (!date) date = new Date().toISOString()
    if (!value) value = 0

    const precision = getCurrencyPrecision(currency, date)

    const amount = Dinero({
        amount: value,
        currency: currency as Currency,
        precision
    })

    const formattedAmount = new Intl.NumberFormat('en-gb', { minimumFractionDigits: precision }).format(
        amount.toUnit() as number
    )

    return formattedAmount
}

export const numberWithCurrency = (curr: string, value: string | number, date?: any, noDecimals?: boolean): string => {
    if (noDecimals) {
        if (typeof value === 'string') return numberWithCommas(currencyFilter(curr, parseInt(value, 10)), noDecimals)
        return numberWithCommas(currencyFilter(curr, value), noDecimals)
    }
    const valueInt = typeof value === 'string' ? parseInt(moveDecimalPoint(value, 0)) : value
    return getAmount(valueInt, curr, date)
}

export const convertRatioToPercentage = (r: string): string => {
    return `${Math.round(parseFloat(r) * 100)}%`
}

export const DateToWords = (date: string, showYearAtAllTimes?: boolean): string => {
    if (showYearAtAllTimes) return moment(date).format('D MMM, YYYY')
    if (moment(date).isSame(moment(), 'year')) return moment(date).format('D MMM')
    return moment(date).format('D MMM, YYYY')
}

export const removeNumberDecimals = (n?: string): string => {
    if (!n) return ''
    if (n.includes('%')) {
        if (n.includes('.')) return `${n.split('.', 1)[0]}%`
    }
    return n
}
export const fixWrongNumberFormatComingFromBackend = (n?: string, noDecimals?: boolean): string => {
    if (!n) return ''
    const goodNumber = n.toString().replace(/\./g, 'x').replace(/,/g, '.').replace(/x/g, ',')
    if (noDecimals) {
        return goodNumber.split('.', 1)[0]
    }
    return goodNumber
}

export const ScrollRefIntoView = (ref?: React.MutableRefObject<HTMLElement>): void => {
    if (ref && ref.current) {
        ref.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
}

export const FocusAfterModalFocus = (ref?: React.MutableRefObject<HTMLInputElement>): void => {
    setTimeout(() => {
        if (ref && ref.current) {
            ref.current.focus()
        }
    }, 50)
}

export const LinkAsRow = styled(SmartNavLink)`
    display: table-row;
`

export function returnUnique<T>(array: T[], element: T): T[] {
    const acc = [...array]
    if (!array.includes(element)) {
        acc.push(element)
    }
    return acc
}

export function returnWithout<T>(array: T[], element: T): T[] {
    array.forEach((e, i) => {
        if (e === element) {
            array.splice(i, 1)
        }
    })
    return [...array]
}

export function returnWithoutIdsArray<T>(array: T[], elements: { id: T }[]): T[] {
    const ids = elements.map((e) => e.id)
    return array.filter((id: T) => !ids.includes(id))
}

export function returnUniqueIdsArray<T>(array: T[], elements: { id: T }[]): T[] {
    const newArr = [...(array || [])]
    const ids = elements.map((e) => e.id)
    ids.forEach((id) => {
        if (!newArr.includes(id)) {
            newArr.push(id)
        }
    })
    return newArr
}

export const cleanTemplatedLink = (link: string): string => {
    return link.replace(/ *\{[^)]*\} */g, '')
}

export type SortDirection = 'asc' | 'desc'

export const getParameter = (string: string, paramName: string) => {
    const searchString = string?.substring(0)
    let i
    let val
    const params = searchString?.split('&') || []

    for (i = 0; i < params.length; i += 1) {
        val = params[i].split('=')
        if (val[0] === paramName) {
            return val[1]
        }
    }
    return null
}

export interface SortInfo<T> {
    column: T
    direction: 'desc' | 'asc'
}

export function findKey(object: { [key: string]: string }, value: string): string | undefined {
    return Object.keys(object).find((key) => object[key] === value)
}

export type Spread<L, R> = Id<
    Partial<{ [P in keyof (L & R)]: SpreadProp<L, R, P> }> &
        Pick<L, Exclude<keyof L, keyof R>> &
        Pick<R, RequiredProps<R>>
>

type SpreadProp<L, R, P extends keyof (L & R)> = P extends keyof R
    ? undefined extends R[P]
        ? L[Extract<P, keyof L>] | R[P]
        : R[P]
    : L[Extract<P, keyof L>]

type RequiredProps<T> = {
    [P in keyof T]-?: undefined extends T[P] ? never : P
}[keyof T]

type Id<T> = { [P in keyof T]: T[P] }

export const isFileAPDF = (contentType: string) => {
    if (contentType === 'application/pdf') return true
    return false
}

export const safeWindowOpen = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const isAgent = (email: string): boolean => {
    return ['@clearhaus.com', '@quickpay.net', '@unzer.com'].find((e) => email?.includes(e)) ? true : false
}

export const IS_NOT_PRODUCTION_OR_PREPRODUCTION = (): boolean => {
    return import.meta.env.VITE_ENV !== 'production' && import.meta.env.VITE_ENV !== 'preproduction'
}

export const IS_DEVELOPMENT_OR_CYPRESS = (): boolean => {
    return import.meta.env.VITE_ENV === 'development' || import.meta.env.VITE_ENV === 'cypress'
}

export const IS_PRODUCTION_OR_PREPRODUCTION = (): boolean => {
    return import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'preproduction'
}

export const ONLY_IF_REAL_PRODUCTION = (): boolean => {
    return import.meta.env.VITE_ENV === 'production'
}

export const IS_CYPRESS = (): boolean => {
    return import.meta.env.VITE_ENV === 'cypress'
}
export const IS_DEVELOPMENT = (): boolean => {
    return import.meta.env.VITE_ENV === 'development'
}

export const removeFromArray = (arr: any[], elem: any) => {
    const index = arr.indexOf(elem)
    if (index !== -1) {
        arr.splice(index, 1)
    }
}
