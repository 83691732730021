import { takeEvery } from '@redux-saga/core/effects'
import { ActionType, getType } from 'deox'
import dotProp from 'dot-prop'
import { all, fork, put, select, take, takeLatest } from 'typed-redux-saga'
import { GET } from '../../generators/networking'
import {
    ConvertIndexPathToFieldDetails,
    MerchantApplicationStructure
} from '../../pages/Merchant/Application/Application.Structure'
import { removeFromArray } from '../../utils'
import { TQLConverter } from '../../utils/tqlConverter'
import { ApplicationDataProvidersActions } from '../applicationDataProviders/actions'
import { ApplicationInternalsAgentsActions } from '../applicationInternals/agents/actions'
import { ApplicationInternalsChecksActions } from '../applicationInternals/checks/actions'
import { ApplicationInternalsNeedingAttentionActions } from '../applicationInternals/needingAttention/actions'
import { ApplicationInternalsRelatedActions } from '../applicationInternals/related/actions'
import { ApplicationInternalsTagsActions } from '../applicationInternals/tags/actions'
import { ApplicationResourceActions } from '../applicationResources/actions'
import { ConvertIndexPathStringToIndexPath } from '../applicationResources/utils'
import { APPLICATIONS_CLEANUP } from '../applications/actions'
import { findMerchantApplicationFieldWithMostCommentsRecursively } from '../interface/utils'
import { MerchantAccountsActions } from '../merchantAccounts/actions'
import { RootState } from '@/store'
import { USER_ACCOUNTS_STORE_ACCOUNT } from '../userAccounts/actions'
import { ApplicationLoadingActions } from './actions'

function* waitForSidebar(id: string) {
    const requests: [
        any,
        any,
        any,
        {
            payload: {
                applicationId: string
                assesment: any
            }
        }
    ] = yield all([
        take(getType(ApplicationInternalsChecksActions.STORE_CHECKS)),
        take(getType(ApplicationInternalsTagsActions.STORE_APPLICATION_TAGS)),
        take(getType(MerchantAccountsActions.STORE_ACCOUNTS_WITHOUT_CONTRACTS)),
        take(getType(ApplicationInternalsAgentsActions.STORE))
    ])

    const assesment = requests[3]
    // Let's also wait til the agent has been fetched
    if (assesment.payload.assesment) yield take(USER_ACCOUNTS_STORE_ACCOUNT)
    yield put(ApplicationLoadingActions.SIDEBAR_LOADED(id))
}

function* waitForInternalDetails(id: string) {
    yield all([waitForSidebar(id), take(getType(ApplicationInternalsRelatedActions.STORE))])

    yield put(ApplicationLoadingActions.INTERNALS_LOADED(id))
}

function* waitForCompany(id: string) {
    yield takeEvery(getType(ApplicationResourceActions.STORE), function* ({ payload }: any) {
        yield put(ApplicationLoadingActions.SECTION_LOADED(id, payload.resourceKey, 'data'))
    })
}
function* waitForRelated(id: string) {
    yield take(ApplicationInternalsRelatedActions.STORE)
    yield put(ApplicationLoadingActions.RELATED_APPLICATIONS_LOADED(id))
}
function* waitForComments(id: string) {
    const dictionary: any = {}
    MerchantApplicationStructure.map((s: any) => {
        if (s.type === 'dynamic') {
            if (!s.resource) return
            dictionary[s.resource] = true
        } else if (s.type === 'static') {
            s.fields?.forEach((field: any) => {
                if (!field.resource) return
                dictionary[field.resource] = true
            })
        }
    })
    while (Object.keys(dictionary).length) {
        const { payload } = yield take(ApplicationResourceActions.STORE_COMMENTS)
        delete dictionary[payload.sectionKey]
    }
    const commentsResources = yield* select(
        (state: RootState) => state.applicationResources.comments.forApplication[id]
    )
    const mostCommentedField = findMerchantApplicationFieldWithMostCommentsRecursively(commentsResources)
    const defaultFieldKey = 'contact.0.name'
    let fieldKey = defaultFieldKey

    if (mostCommentedField.indexPathString) {
        if (mostCommentedField.count > 1) fieldKey = mostCommentedField.indexPathString
        else {
            // If there's only one change, but the field doesn't accept comments
            const ip = ConvertIndexPathStringToIndexPath(fieldKey)
            const fieldDetails = ip ? ConvertIndexPathToFieldDetails(ip) : undefined
            if (fieldDetails?.field?.hasCommentsDisabled) fieldKey = mostCommentedField.indexPathString
        }
    }

    const [section, index, ...rest] = fieldKey.split('.')
    const subsectionId = yield* select((state: RootState) =>
        dotProp.get(state, `applicationResources.data.forApplication.${id}.${section}.fields.${index}.id`)
    )

    yield put(ApplicationInternalsNeedingAttentionActions.SCAN_COMMENTS(id))
    yield put(ApplicationLoadingActions.STORE_MOST_COMMENTED_FIELD(id, fieldKey, subsectionId))
}
function* waitForCompanyAndPeopleThenScanForConflicts(id: string) {
    const fetchCount = ['people', 'company', 'dataProvider']
    let hasScanExecuted = false

    yield takeLatest(
        [getType(ApplicationLoadingActions.SECTION_LOADED), getType(ApplicationDataProvidersActions.STORE)],
        function* ({ payload, type }: any) {
            if (type === getType(ApplicationDataProvidersActions.STORE)) {
                if (fetchCount.includes('dataProvider')) removeFromArray(fetchCount, 'dataProvider')
                else hasScanExecuted = false
            } else {
                if (payload.section === 'people') removeFromArray(fetchCount, 'people')
                if (payload.section === 'company') {
                    yield put(ApplicationDataProvidersActions.SEARCH_FOR_MATCHES(id))
                    removeFromArray(fetchCount, 'company')
                }
            }
            if (hasScanExecuted) return
            if (fetchCount.length == 0) {
                hasScanExecuted = true
                yield put(ApplicationDataProvidersActions.SCAN_FOR_CONFLICTS(id))
            }
        }
    )
}
function* waitForApplicationUnloading(id: string) {
    let unloadAction: any
    do {
        unloadAction = yield* take([APPLICATIONS_CLEANUP])
    } while (unloadAction?.applicationId !== id)
    throw 'CANCEL_FORKS'
}

export const ApplicationLoadingSagas = {
    *APPLICATION_STARTED_LOADING({
        payload: p
    }: ActionType<typeof ApplicationLoadingActions.APPLICATION_STARTED_LOADING>) {
        try {
            yield* all([
                fork(waitForSidebar, p.applicationId),
                fork(waitForCompany, p.applicationId),
                fork(waitForRelated, p.applicationId),
                fork(waitForInternalDetails, p.applicationId),
                fork(waitForComments, p.applicationId),
                fork(waitForCompanyAndPeopleThenScanForConflicts, p.applicationId),
                fork(waitForApplicationUnloading, p.applicationId)
            ])
        } catch (e) {
            if (e !== 'CANCEL_FORKS') throw e
        }
    },
    *LOAD_TASKS_COUNT({ payload: p }: ActionType<typeof ApplicationLoadingActions.LOAD_TASKS_COUNT>) {
        if (!p.accountIds || p.accountIds.length == 0) {
            yield put(ApplicationLoadingActions.STORE_TASKS_COUNT(p.applicationId, 0))
            return
        }
        yield GET({
            url: `${import.meta.env.VITE_TASKR_ROOT}/tasks${TQLConverter.tasks({
                tasks_subject_id: p.accountIds,
                tasks_subject_type: 'account',
                tasks_page: 1,
                tasks_type: ['instant-signup', 'transaction-investigation', 'odd-reporting'],
                tasks_per_page: 30
            } as any)}`,
            onSuccessDispatch: (r) => {
                return ApplicationLoadingActions.STORE_TASKS_COUNT(p.applicationId, r.total)
            },
            errorText: 'Failed to calculate the number of tasks for this merchant'
        })
    }
}
