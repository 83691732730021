import { InitialRootState } from './types'
import { GlobalActions } from './actions'
import { createReducer } from 'deox'
import { produce } from 'immer'

export const GlobalReducer = createReducer(InitialRootState, (handleAction) => [
    handleAction(GlobalActions.STORE_LINKS, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft.links = {
                cutter: p.links.cutter,
                mapi: p.links.mapi
            }
            draft.loadingStatus = 'done'
        })
    })
])
