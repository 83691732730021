import { Box, Button, Card, Flex, Separator, Text } from '@/design-system'
import React from 'react'

interface ChildrenProps {
    children: React.ReactNode
}

interface SaveCardProps extends ChildrenProps {
    title?: string
    errorCount?: number
}

const SaveCard = (props: SaveCardProps) => {
    const { children } = props

    return <Card size="lg">{children}</Card>
}

const Title: React.FC<ChildrenProps> = (props) => {
    const { children } = props

    return (
        <>
            <Box mb="3">
                <Text weight="medium">{children}</Text>
            </Box>
            <Box mb="3">
                <Separator />
            </Box>
        </>
    )
}

const Body: React.FC<ChildrenProps> = (props) => {
    const { children } = props

    return <Card.Body>{children}</Card.Body>
}

const Fields: React.FC<ChildrenProps> = (props) => {
    const { children } = props

    return (
        <Flex direction="column" gap="5">
            {children}
        </Flex>
    )
}

interface FooterProps {
    testid?: string
    errorCount?: number
}

const Footer: React.FC<FooterProps> = (props) => {
    const { testid, errorCount } = props

    const hasErrors = errorCount && errorCount !== 0

    return (
        <Card.Footer testid={testid}>
            <Flex align="center" justify="between">
                {hasErrors ? <Text color="neutral">{`${errorCount} field(s) with error`}</Text> : <div />}
                <Button type="submit">Save</Button>
            </Flex>
        </Card.Footer>
    )
}

SaveCard.Title = Title
SaveCard.Body = Body
SaveCard.Fields = Fields
SaveCard.Footer = Footer

export { SaveCard }
