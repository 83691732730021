import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export const useMerchantName = (id: string | undefined, suffix: string): string => {
    const name = useSelector((state: RootState) =>
        id ? state.applicationResources.data?.forApplication?.[id]?.company?.fields?.[0]?.name : undefined
    )
    if (id === undefined) return 'Unknown merchant'
    return name ? `${name} • ${suffix}` : 'Loading…'
}
