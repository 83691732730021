import { GET } from '../../generators/networking'
import { ContractsActionLoadContracts, ContractsDispatchStoreContracts, SagasForContracts } from './actions'

export class ContractsSagas implements SagasForContracts {
    *loadContracts(action: ContractsActionLoadContracts) {
        yield GET({
            url: `${import.meta.env.VITE_CUTTER_ROOT}/applications/${action.applicationId}/contracts`,
            errorText: 'Failed to load contracts',
            onSuccessDispatch: (r) => ContractsDispatchStoreContracts(action.applicationId, r.contracts)
        })
    }
}
