import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export const useHasApplicationSectionLoaded = (
    id: string,
    section: string,
    type: keyof RootState['applicationLoading']['at']['any']['sections']['any']
) => {
    const state = useSelector((state: RootState) => state.applicationLoading.at?.[id]?.sections?.[section]?.[type])
    return state
}
