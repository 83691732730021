import {
    IMerchantApplicationSubsectionItemDetails,
    IMerchantApplicationSectionItemDetails,
    IMerchantApplicationSpacerItem
} from './Application.StructureTypes'

export const getHighlightTypeForField = (
    arr: Array<
        | IMerchantApplicationSubsectionItemDetails
        | IMerchantApplicationSectionItemDetails
        | IMerchantApplicationSpacerItem
    >,
    index: number,
    forceHighlight?: boolean
): 'top' | 'bottom' | 'both' | undefined => {
    if (
        arr[index + 1] &&
        arr[index - 1] &&
        (forceHighlight ? true : 'highlight' in arr[index - 1]) &&
        (forceHighlight ? true : 'highlight' in arr[index + 1])
    ) {
        return 'both'
    } else if (arr[index + 1] && (forceHighlight ? true : 'highlight' in arr[index + 1])) {
        return 'top'
    } else if (arr[index - 1] && (forceHighlight ? true : 'highlight' in arr[index - 1])) {
        return 'bottom'
    } else {
        return undefined
    }
}
