import { CollectionRequest, Url } from '@/services/common'
import { Task, TaskAction, Tasks } from '../types'

import { api } from '../api'

const taskrRoot = import.meta.env.VITE_TASKR_ROOT

export const tasksApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTasks: build.query<Tasks, CollectionRequest>({
            query: (params) => {
                return { url: `${taskrRoot}/tasks`, params }
            },
            transformResponse: (response: Tasks) => {
                if (response.embedded.tasks && !Array.isArray(response.embedded.tasks)) {
                    response.embedded.tasks = [response.embedded.tasks]
                }

                return response
            },
            providesTags: ['tasks']
        }),
        updateTask: build.mutation<Task, { url: Url; data: Partial<Task> | TaskAction }>({
            query: ({ url, data }) => ({
                url,
                body: data,
                method: 'PATCH'
            }),
            invalidatesTags: ['tasks']
        }),
        batchUpdateTask: build.mutation<Task[], { urls: Url[]; data: Partial<Task> | TaskAction }>({
            async queryFn(args, _queryApi, _extraOptions, baseQuery) {
                const { urls, data } = args
                const tasks: Task[] = []

                for (const url of urls) {
                    const task = await baseQuery({
                        url,
                        method: 'PATCH',
                        body: data
                    })

                    tasks.push(task.data)
                }
                return { data: tasks }
            },
            invalidatesTags: ['tasks']
        })
    })
})

export const {
    useGetTasksQuery: useGetTasks,
    useUpdateTaskMutation: useUpdateTask,
    useBatchUpdateTaskMutation: useBatchUpdateTask
} = tasksApi
