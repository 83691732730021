import React from 'react'
import { Checkbox } from '../../../../components/forms/checkbox'
import { Text } from '../../../../components/general/text'
import { Flex } from '../../../../components/layout/flex'
import { Spacer } from '../../../../components/layout/spacer'
import {
    MerchantNotificationType,
    TextForWebhookEvent,
    WebhooksConfig
} from '../../../../store/applicationInternals/record/types'
import { Color } from '../../../../styles/colors'

export const ListWebhooks: React.FC<{
    selectedEvents?: string[]
    overrideColor?: Color
    disabled?: boolean
    horizontal?: boolean
    onEventSelection?: (eventType: MerchantNotificationType) => void
}> = ({ onEventSelection, disabled, overrideColor, selectedEvents, horizontal }) => {
    return (
        <Flex align="flex-start" column={horizontal ? false : true}>
            {WebhooksConfig.sections.map((s, i, arr) => {
                return (
                    <React.Fragment key={s}>
                        <Flex column>
                            <Text
                                size="xs"
                                color={overrideColor ? 'floating.text.subtlerII' : 'front.text.subtlerI'}
                                bold
                            >
                                {s.toUpperCase()} EVENTS
                            </Text>
                            <Spacer height={8} />
                            {WebhooksConfig.eventsBySection[s].map((e: any) => {
                                const checked = selectedEvents?.includes(e)
                                return (
                                    <span data-cy={checked ? 'check-checked' : 'check-unchecked'}>
                                        <Checkbox
                                            overrideColor={overrideColor}
                                            disabled={disabled}
                                            label={TextForWebhookEvent(e)}
                                            onChange={() => onEventSelection?.(e)}
                                            checked={checked}
                                        />
                                    </span>
                                )
                            })}
                        </Flex>
                        {i !== arr.length - 1 && <Spacer width={horizontal ? 30 : 15} />}
                    </React.Fragment>
                )
            })}
        </Flex>
    )
}
