import React, { ReactElement, useCallback } from 'react'

import { TransactionsFilters } from '../../store/transactions/types'
import { uppercaseFirstLetter } from '../../utils'
import { TextInputSelectMultiple } from '../forms/textInputSelectMultiple'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

const schemes = ['visa', 'mastercard']
export const ListPageFilterTransactionsScheme = ({
    filters,
    isDark
}: {
    filters: FiltersController<TransactionsFilters>
    isDark: boolean
}): ReactElement => {
    const textForItem = useCallback((key) => {
        return uppercaseFirstLetter(key)
    }, [])

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                transactions_scheme: items
            }))
        },
        [filters]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelectMultiple
                preSelectedItems={filters.get.transactions_scheme}
                isHigher={isDark}
                isSeamless
                textForItem={textForItem}
                dropdownId="ListPage.Filters.TransactionScheme"
                onSelect={onSelect}
                placeholder="-"
                items={schemes}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                selectionLabel="transaction scheme/transaction schemes"
            />
            <SelectedListForListPageFilters<TransactionsFilters>
                filterKey="transactions_scheme"
                filters={filters}
                isDark={isDark}
                noHolderEmphasize={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
