import React from 'react'
import { Spacer } from '@/components/layout/spacer'
import { CompanyPaymentsAccounts } from './sections/company-payment-accounts'
import { TrustpilotSatisfying } from './sections/trustpilot-satisfying'
import { GoogleNegativeInfoOwners } from './sections/google-negative-info-owners'
import { GoogleNegativeInfoCompanyUrl } from './sections/google-negative-info-company-url'
import { SanctionListsOwnersListed } from './sections/sanction-lists-owners-listed'
import { WebsiteSatisfyingBusinessModel } from './sections/website-satisfying-business-model'
import { RiskClassification } from './sections/risk-classification'
import { WebsiteAvailableContactInfo } from './sections/website-available-contact-info'
import { SectionContainer } from '../section-container'
import { CompanyFundamentalChanges } from './sections/company-fundamental-changes'
import { WebsiteAvailableRefundPolicy } from './sections/website-available-refund-policy'
import { WebsiteAvailableTermsAndCondition } from './sections/website-available-terms-and-condition'
import { WebsiteCredibleCapturePattern } from './sections/website-credible-capture-pattern'
import { WebsiteCredibleRefundCreditPattern } from './sections/website-credible-refund-credit-pattern'
import { WebsiteLikelyChargebackPattern } from './sections/website-likely-chargeback-pattern'
import { WebsiteAverageExpectedTransactionSize } from './sections/website-average-expected-transaction-size'

export const ChecksSection: React.FC = () => {
    return (
        <SectionContainer title="Checks">
            <CompanyPaymentsAccounts />
            <Spacer height={24} />
            <CompanyFundamentalChanges />
            <Spacer height={24} />
            <TrustpilotSatisfying />
            <Spacer height={24} />
            <GoogleNegativeInfoOwners />
            <Spacer height={24} />
            <GoogleNegativeInfoCompanyUrl />
            <Spacer height={24} />
            <SanctionListsOwnersListed />
            <Spacer height={24} />
            <WebsiteSatisfyingBusinessModel />
            <Spacer height={24} />
            <WebsiteAvailableContactInfo />
            <Spacer height={24} />
            <WebsiteAvailableRefundPolicy />
            <Spacer height={24} />
            <WebsiteAvailableTermsAndCondition />
            <Spacer height={24} />
            <WebsiteCredibleCapturePattern />
            <Spacer height={24} />
            <WebsiteCredibleRefundCreditPattern />
            <Spacer height={24} />
            <WebsiteLikelyChargebackPattern />
            <Spacer height={24} />
            <WebsiteAverageExpectedTransactionSize />
            <Spacer height={24} />
            <RiskClassification />
        </SectionContainer>
    )
}
