import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card } from '../../../../components/cards/card'
import { CardInset } from '../../../../components/cards/cardInset'
import { Flex } from '../../../../components/layout/flex'
import { ModalPage } from '../../../../components/layout/modalPage'
import { ModalPageInset } from '../../../../components/layout/modalPageInset'
import { Spacer } from '../../../../components/layout/spacer'
import { ModalHeader } from '../../../../components/modals/modalHeader'
import { useNamedWatcher } from '../../../../hooks/general/useWatcher'
import * as yup from 'yup'
import { List } from '../../../../components/layout/list'
import { ButtonInset } from '../../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../../components/buttons/watcherButton'
import { useForm } from '../../../../hooks/general/useForm'
import { CardSection } from '../../../../components/cards/cardSection'
import { useDispatch, useSelector } from 'react-redux'
import { useMerchantName } from '../../../../hooks/general/useMerchantName'
import { uppercaseFirstLetter } from '../../../../utils'
import {
    MerchantNotificationType,
    MerchantNotificationTypes
} from '../../../../store/applicationInternals/record/types'
import { ApplicationInternalsRecordActions } from '../../../../store/applicationInternals/record/actions'
import { RootState } from '@/store'
import { Checkbox } from '../../../../components/forms/checkbox'
import { ListWebhooks } from './More.ListWebhooks'
import { put } from 'redux-saga/effects'
import { ApplicationsDispatchLoadApplication } from '../../../../store/applications/actions'
import { useGoBackHook } from '../../../../hooks/general/useGoBackHook'
import { useParams } from 'react-router-dom'

type Params = {
    id: string
    notification_id: string
}

export const MoreAddNotification: React.FC<{ forHooks?: boolean; isEditing?: boolean }> = ({ forHooks, isEditing }) => {
    const { id: applicationId, notification_id: notificationId } = useParams() as Params
    const [watcher, watcherId] = useNamedWatcher(`${applicationId}.NewNotification`)
    const { errors, formRef, submitHandler } = useForm(watcher, undefined, undefined, undefined, true)
    const merchantRecord = useSelector((state: RootState) => state.applicationInternals.record?.[applicationId])
    const applicationLoadingState = useSelector(
        (state: RootState) => state.applications.applications.at?.[applicationId]?.loadingStatus
    )
    const dispatch = useDispatch()
    const title = useMerchantName(applicationId, 'New notification')
    const { goBack } = useGoBackHook(`/merchant/${applicationId}/more/subscriptions-tab`)
    const notificationsState = useSelector(
        (state: RootState) => state.applicationInternals.record?.[applicationId]?.notifications
    )
    useEffect(() => {
        if (applicationLoadingState !== 'started' && applicationLoadingState !== 'done') {
            dispatch(put(ApplicationsDispatchLoadApplication(applicationId, 'full-application')))
        }
    }, [applicationLoadingState, dispatch, applicationId])

    const notification = useMemo(() => {
        if (!isEditing) return null
        return notificationsState?.at?.[notificationId]
    }, [notificationId, notificationsState, isEditing])
    const [selectedEvents, setSelectedEvents] = useState<MerchantNotificationType[]>(
        isEditing ? notification?.events || [] : []
    )
    useEffect(() => {
        if (notification) {
            setSelectedEvents([...notification.events])
        }
    }, [notification, setSelectedEvents])

    const onSubmit = useCallback(
        (form) => {
            if (!merchantRecord.subscriptionsLink) throw "Couldn't find merchant's record «subscriontionsLink»"

            if (isEditing) {
                if (notification?.selfLink)
                    dispatch(
                        ApplicationInternalsRecordActions.UPDATE_NOTIFICATION(
                            watcherId,
                            applicationId,
                            notification?.selfLink,
                            selectedEvents,
                            forHooks ? 'https' : 'email',
                            form.data.endpoint
                        )
                    )
            } else {
                dispatch(
                    ApplicationInternalsRecordActions.CREATE_NOTIFICATION(
                        watcherId,
                        applicationId,
                        merchantRecord.subscriptionsLink,
                        selectedEvents,
                        forHooks ? 'https' : 'email',
                        form.data.endpoint
                    )
                )
            }
        },
        [dispatch, watcherId, merchantRecord, notification, forHooks, applicationId, isEditing, selectedEvents]
    )

    useEffect(() => {
        if (watcher === 'success') {
            goBack()
        }
    }, [goBack, watcher])

    const textForItem = useCallback((item) => {
        return uppercaseFirstLetter(item)
    }, [])

    const isDisabled = useMemo(() => {
        if (!selectedEvents.length) return true
        if (Object.keys(errors || {}).length) return true
        return false
    }, [errors, selectedEvents])

    const selectEvent = useCallback((eventType: MerchantNotificationType) => {
        setSelectedEvents((e) => {
            if (e.includes(eventType)) return e.filter((j) => j !== eventType)
            return [...e, eventType]
        })
    }, [])

    return (
        <ModalPage
            title={title}
            pageId="Merchant.AccountsPage.Account.NewNotification"
            backTo={`/merchant/${applicationId}/more/subscriptions-tab`}
        >
            <ModalHeader
                title={forHooks ? `Create a webhook` : `Create a notification`}
                pageId="Merchant.AccountsPage.Account.NewNotification"
                backTo={`/merchant/${applicationId}/more/subscriptions-tab`}
            />
            <ModalPageInset>
                <Flex justify="center" align="center" grow column>
                    <Card higher title={forHooks ? 'Webhook details' : 'Notification details'}>
                        <CardInset>
                            <Spacer height={15} />
                            <List
                                background="front.background"
                                items={{
                                    [forHooks ? 'Webhook URL' : 'Email recipient']: {
                                        type: 'input',
                                        placeholder: forHooks
                                            ? 'URL that Webhook will call'
                                            : 'Notifications receiver email',
                                        validation: errors['endpoint'],
                                        initialValue: notification?.endpoint,
                                        overBackground: 'front.background',
                                        ref: (ref) =>
                                            formRef(
                                                ref,
                                                'endpoint',
                                                forHooks
                                                    ? yup.string().url().required()
                                                    : yup.string().email().required()
                                            )
                                    },
                                    'Events type': {
                                        type: 'custom',
                                        node: forHooks ? (
                                            <Flex column marginLeft={-1} align="stretch" grow>
                                                <Spacer height={2} />
                                                <ListWebhooks
                                                    selectedEvents={selectedEvents}
                                                    onEventSelection={selectEvent}
                                                />
                                            </Flex>
                                        ) : (
                                            <Flex column marginLeft={-1}>
                                                {MerchantNotificationTypes.map((e) => (
                                                    <Checkbox
                                                        label={textForItem(e)}
                                                        onChange={() => selectEvent(e)}
                                                        checked={selectedEvents.includes(e)}
                                                    />
                                                ))}
                                            </Flex>
                                        )
                                    }
                                }}
                                switchToRowsAt={10000}
                                cellHorizontalTemplate="100px minmax(auto, 240px)"
                            />
                            <Spacer height={20} />
                            <CardSection background="subtleBlue">
                                <CardInset type="small">
                                    <Flex justify="flex-end">
                                        <WatcherButton
                                            background="front.accent.color"
                                            predefinedWatcher={watcherId}
                                            hotkeysScope="Merchant.AccountsPage.Account.NewNotification"
                                            hotkeys="alt+enter"
                                            onClick={submitHandler(onSubmit)}
                                            isDisabled={isDisabled}
                                        >
                                            {isEditing ? (
                                                <ButtonInset>
                                                    {forHooks ? `Update Webhook` : `Update Notification`}
                                                </ButtonInset>
                                            ) : (
                                                <ButtonInset>
                                                    {forHooks ? `Create Webhook` : `Create Notification`}
                                                </ButtonInset>
                                            )}
                                        </WatcherButton>
                                    </Flex>
                                </CardInset>
                            </CardSection>
                        </CardInset>
                    </Card>
                    <Spacer height={120} />
                </Flex>
            </ModalPageInset>
        </ModalPage>
    )
}
