import React, { useEffect } from 'react'
import { Text } from '@/components/general/text'
import { LoaderView } from '@/components/loaders/loader'
import { ClassificationProvider, 
    useClassificationParams,
    FormProvider, ClassificationModalActions,
    ClassificationChecks } from '@/features/risk-classification'
import { useGetClassification } from '@/services/risk-classification'
import { ModalPage } from '@/components/layout/modalPage'
import { ModalHeader } from '@/components/modals/modalHeader'
import { ModalPageInset } from '@/components/layout/modalPageInset'
import { PageWrapper } from '@/components/layout/pageWrapper'
import { Grid } from '@/components/layout/grid'
import styled from 'styled-components'
import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { RiskSpeedometer } from '@/components/charts/riskspeedometer'
import { useNavigate } from 'react-router-dom'

export const Classification: React.FC = () => {
    const { classificationId, applicationId } = useClassificationParams()
    const { data, isLoading, isError } = useGetClassification(classificationId)

    const navigate = useNavigate()

    useEffect(() => {
        if (isError) {
            navigate(-1)
        }
    }, [navigate, isError])

    if (isLoading) {
        return <LoaderView overBackground="front.background" type="l" minHeight="200" />
    }

    if (!data) {
        return <Text>No classification data available</Text>
    }

    return (
        <FormProvider data={data}>
            <ClassificationProvider classification={data}>
                <ModalPage
                    title="New risk classification"
                    pageId="ClassificationManager"
                    key="merchantClassificationManager"
                >
                    <ModalHeader
                        pageId="ClassificationManager"
                        backTo={`/merchant/${applicationId}/more`}
                        title={'New Risk Classification'}
                        rightSideMemo={<ClassificationModalActions classification={data} />}
                    />
                    <ModalPageInset center>
                        <PageWrapper shouldCenter maxWidth="full">
                            <Grid
                                horizontalTemplate="auto"
                                verticalTemplate="auto"
                                switchToRowsAt={1300}
                            >
                                <CardWrapper>
                                    <Card>
                                        <CardInset>
                                            <RiskSpeedometer points={data.total_risk_score} risk={data.risk_level} />
                                            <ClassificationChecks />
                                        </CardInset>
                                    </Card>
                                </CardWrapper>
                            </Grid>
                        </PageWrapper>
                    </ModalPageInset>
                </ModalPage>
            </ClassificationProvider>
        </FormProvider>
    )
}

const CardWrapper = styled.div`
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
`
