import { Container, Flex, Grid, Page, Text } from '@/design-system'
import { SigningKeySchema, useSigningKeyForm } from '@/features/signing-keys'
import { RTKQuery } from '@/services/common'
import { MAPI_SIGNING_KEYS_ROOT, getSigningKey, updateSigningKey, useGetSigningKey } from '@/services/merchant-api'
import { PageHeader, SaveCard, FormCheckbox } from '@/ui'
import { FormProvider } from 'react-hook-form'
import { Form, redirect, useNavigate, useParams, useSubmit } from 'react-router-dom'

export const loader = async ({ request, params }) => {
    const { signingKeyId } = params

    const url = `${MAPI_SIGNING_KEYS_ROOT}/${signingKeyId}`

    return await RTKQuery.get(getSigningKey, request, url)
}

export const action = async ({ request, params }) => {
    const { partnerId, signingKeyId } = params
    const formData = await request.formData()

    const url = `${MAPI_SIGNING_KEYS_ROOT}/${signingKeyId}`
    const data = Object.fromEntries(formData)
    const body = { url, data }
    await RTKQuery.patch(updateSigningKey, body)

    return redirect(`/more/partners/${partnerId}`)
}

const SigningKeyEdit = () => {
    const navigate = useNavigate()
    const submit = useSubmit()

    const { signingKeyId } = useParams()
    const { data } = useGetSigningKey(`${MAPI_SIGNING_KEYS_ROOT}/${signingKeyId}`)

    const form = useSigningKeyForm({
        verification_key: data?.verification_key ? data?.verification_key : '',
        expires_at: data?.expires_at ? data?.expires_at : '',
        enabled: !!data?.enabled
    })
    const onBack = () => {
        navigate(-1)
    }

    const onSubmit = form.handleSubmit((values: SigningKeySchema) => {
        submit(values, { method: 'post' })
    })

    const errorCount = Object.keys(form.formState.errors).length

    return (
        <Page pb="normal">
            <PageHeader title="Edit signing key" onBack={onBack} />
            <Flex justify="center" align="center" grow="1">
                <Container size="lg">
                    <FormProvider {...form}>
                        <Form onSubmit={onSubmit}>
                            <SaveCard>
                                <SaveCard.Body>
                                    <SaveCard.Title>Signing key details</SaveCard.Title>
                                    <SaveCard.Fields>
                                        <Grid columns="3" gap="3">
                                            <FormCheckbox testid="signing-key-enabled" name="enabled" label="Enabled" />
                                        </Grid>
                                        <Text color="neutral">
                                            Remaining information on the signing key is not possible to edit.
                                        </Text>
                                    </SaveCard.Fields>
                                </SaveCard.Body>
                                <SaveCard.Footer testid="card-footer" errorCount={errorCount} />
                            </SaveCard>
                        </Form>
                    </FormProvider>
                </Container>
            </Flex>
        </Page>
    )
}

SigningKeyEdit.loader = loader
SigningKeyEdit.action = action

export { SigningKeyEdit }
