import { camelCase } from 'lodash'
import React, { useMemo } from 'react'

import { ValidationStatus } from '../../../hooks/general/useForm'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'

import { MerchantApplicationField } from './Application.Field'
import { MerchantApplicationSectionGrid } from './Application.SectionGrid'
import { MerchantApplicationSeparatorRow } from './Application.SeparatorRow'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { GenerateIndexPathForSection } from './Application.Structure'
import {
    IMerchantApplicationSectionStructure,
    IMerchantApplicationSubsectionStructure
} from './Application.StructureTypes'
import { getHighlightTypeForField } from './utils'

export interface MerchantApplicationSectionProps {
    applicationId: string
    executeBatchChanges: MerchantApplicationFieldFormProps['executeBatchChanges']
    isDataLoading?: boolean
    formRef: MerchantApplicationFieldFormProps['formRef']
    sectionStructure: IMerchantApplicationSectionStructure
    formErrors: { [key: string]: ValidationStatus }
    nextDynamicSection?: IMerchantApplicationSubsectionStructure
    sanitised?: boolean
}

export const MerchantApplicationSection: React.FC<MerchantApplicationSectionProps> = ({
    sectionStructure,
    executeBatchChanges,
    formRef,
    sanitised,
    isDataLoading,
    applicationId,
    formErrors
}) => {
    // const loadingStatus = useHasSectionLoaded(applicationId, sectionStructure)
    const renderedFields = useMemo(() => {
        if (!sectionStructure?.fields) return []

        const highlightIndices: number[] = []

        sectionStructure.fields.map((field, i) => {
            if (field.kind === 'spacer') highlightIndices.push(i)
        })

        return sectionStructure.fields.map((field, i) => {
            if (!field) return null
            if (field.kind === 'spacer') return <MerchantApplicationSeparatorRow key={`separator-${i}`} />

            if (field.skipRendering) return <React.Fragment key={`skip-rendering-${i}`} />

            const indexPath: MerchantApplicationResourceIndexPath = GenerateIndexPathForSection(field)

            return (
                <MerchantApplicationField
                    isLoading={isDataLoading}
                    indexPath={indexPath}
                    applicationId={applicationId}
                    formErrors={formErrors}
                    sanitised={sanitised}
                    highlighted={getHighlightTypeForField(sectionStructure.fields, i)}
                    isFirst={i === 0}
                    formRef={formRef}
                    type={field.type}
                    key={camelCase(field.label)}
                    label={field.label}
                    executeBatchChanges={executeBatchChanges}
                />
            )
        })
    }, [sectionStructure, sanitised, applicationId, formErrors, isDataLoading, formRef, executeBatchChanges])

    return <MerchantApplicationSectionGrid key="grid">{renderedFields}</MerchantApplicationSectionGrid>
}
