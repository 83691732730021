/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from 'deox'
import { delay, fork, put } from 'redux-saga/effects'
import { GET, POST } from '../../generators/networking'
import { ToastsDispatchPush } from '../toasts/actions'
import { WatcherDispatchStart, WatcherDispatchSuccess } from '../watcher/actions'
import { ExportsActions } from './actions'
import { ExportToastContent } from './exportToastContent'
import { Export, ExportFileFormatsStrings } from './types'
import moment from 'moment'

const waitForExportCache: any = {}
function* waitForExportToFinish(id: string) {
    if (waitForExportCache[id] == true) return
    waitForExportCache[id] = true
    while (true) {
        const {
            cleanedResponse,
            header
        }: {
            cleanedResponse: Export
            header: string
        } = yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/jobs/${id}`,
            returnHeader: 'location'
        })

        if (!cleanedResponse) throw 'Failed to generate the export'

        yield put(ExportsActions.STORE_EXPORT({ ...cleanedResponse, downloadLink: header }))

        if (cleanedResponse.status === 'done') {
            yield put(WatcherDispatchSuccess([cleanedResponse.id + cleanedResponse.type]))
            yield put(ToastsDispatchPush(<ExportToastContent eId={cleanedResponse.id} />, 'context', '', 'infinite'))
            delete waitForExportCache[id]
            break
        }

        // If it takes more than 5 seconds, let's make the requests less often
        if (moment().isAfter(moment(cleanedResponse.createdAt).add(5, 'seconds'))) {
            yield delay(5000)
        } else {
            yield delay(1000)
        }
    }
}

export const ExportsSagas = {
    *DOWNLOAD({ payload: p }: ActionType<typeof ExportsActions.DOWNLOAD>) {
        yield put(WatcherDispatchStart([p.watcherId]))

        let downloadLink = p.e.downloadLink
        if (!downloadLink) {
            if (!p.e.selfLink) throw new Error('Export has no selflink')

            const {
                cleanedResponse,
                header
            }: {
                cleanedResponse: Export
                header: string
            } = yield GET({
                url: p.e.selfLink,
                returnHeader: 'location'
            })

            yield put(ExportsActions.STORE_EXPORT({ ...cleanedResponse, downloadLink: header }))
            downloadLink = header
        }

        const response: { files: any[] } = yield GET({
            url: downloadLink,
            include: ['data']
        })

        for (const file of response.files) {
            for (const dl of file.dataLink) {
                const response: { [o: string]: any } = yield GET({
                    url: dl.href,
                    include: ['data']
                })
                window.location.assign(response.download)
            }
        }
        yield put(WatcherDispatchSuccess([p.watcherId], 'Export downloading'))
    },
    *FETCH_EXPORTS({ payload: p }: ActionType<typeof ExportsActions.FETCH_EXPORTS>) {
        const response: {
            cleanedResponse: {
                page: number
                perPage: number
                count: number
                jobs: Export[]
            }

            header: string
        } = yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/jobs?page=${p.filters.page || 1}&per_page=${
                p.filters.per_page || 20
            }`,
            errorText: 'Failed to load exports summaries.',
            include: ['self', 'jobs', 'statuses'],
            returnHeader: 'location'
        })
        if (!response) return

        const { cleanedResponse, header } = response

        if (!cleanedResponse) {
            yield put(ToastsDispatchPush('CleanedResponse has ont been returned', 'error'))
        }

        for (const r of cleanedResponse.jobs) {
            if (r.status === 'running' || r.id === p.triggeredExport) {
                yield put(WatcherDispatchStart([r.id + r.type]))
                yield fork(waitForExportToFinish, r.id)
            }
        }
        yield put(
            ExportsActions.STORE_EXPORTS(
                cleanedResponse.jobs.map((j) => {
                    j.downloadLink = header
                    return j
                }),
                {
                    page: cleanedResponse.page,
                    perPage: cleanedResponse.perPage,
                    total: cleanedResponse.count
                }
            )
        )
    },
    *TRIGGER_EXPORT({ payload: p }: ActionType<typeof ExportsActions.TRIGGER_EXPORT>) {
        const { success, cleanedResponse, header } = yield POST({
            url: `${import.meta.env.VITE_API_ROOT}/downloads`,
            successCode: 202,
            body: {
                sources: p.resourceLink,
                type: ExportFileFormatsStrings[p.format]
            },
            returnHeader: 'location'
        })

        if (!success) {
            yield put(ToastsDispatchPush('Failed to trigger the export', 'error'))
            return
        }

        const segments = header.split('/')
        yield put(ExportsActions.FETCH_EXPORTS({ page: 1, per_page: 20 }, segments[segments.length - 1]))
    }
}
