import React, { useCallback } from 'react'

import { TransactionsFilters } from '../../store/transactions/types'
import { TextInput } from '../forms/textInput'
import { FiltersController } from '../../hooks/pages/useListPage'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

export function ListPageFilterTransactionsLastFour({
    filters,
    isDark
}: {
    filters: FiltersController<TransactionsFilters>
    isDark: boolean
}) {
    const [fieldRef, setFieldRef] = useRefTaker()

    const handleEnter = useCallback(
        (e, value) => {
            if (fieldRef) fieldRef.value = ''
            filters.set((prevState) => ({
                ...prevState,
                transactions_last4: value
            }))
        },
        [fieldRef, filters]
    )

    const textForItem = useCallback((val) => val, [])

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInput
                overBackground={isDark ? 'floating.background' : 'side.background'}
                onEnter={handleEnter}
                cy="last4-filter"
                placeholder="-"
                ref={setFieldRef}
                isSeamless
            />
            <SelectedListForListPageFilters<TransactionsFilters>
                filterKey="transactions_last4"
                filters={filters}
                isDark={isDark}
                noHolderEmphasize={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
