import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { ToastsDispatchPush, ToastsDispatchPop } from './store/toasts/actions'
import { CallsDispatchUpdateSummaries } from './store/calls/actions'
import * as PusherPushNotifications from '@pusher/push-notifications-web'
import { generateJWTSync } from './store/auth/sagas'

export const PusherHelper = (): React.ReactElement => {
    const dispatch = useDispatch()
    const auth = useSelector((state: RootState) => state.auth)
    const line = useSelector((state: RootState) => state.callsPref.line)
    const local_number = useSelector((state: RootState) => state.callsPref.local_number)
    const notifications = useSelector((state: RootState) => state.callsPref.notifications)
    let calling = 'false'
    let initChannels = ['']
    let initInterests = ['']
    if (line == '1') {
        initChannels = ['1', '2', '3']
        initInterests = ['queue-1', 'queue-2', 'queue-3']
    }
    if (line == '2') {
        initChannels = ['2', '3']
        initInterests = ['queue-2', 'queue-3']
    }
    if (line == '3') {
        initChannels = ['3']
        initInterests = ['queue-3']
    }
    if (notifications == 'off') {
        initChannels = []
        initInterests = []
    }
    const [channels, setChannels] = useState(initChannels)
    const [interests, setInterests] = useState(initInterests)

    let beamsTokenProvider: any = null
    // Errors will be thrown in unsupported browsers, leading to crashes if uncaught
    let beamsClient: PusherPushNotifications.Client | undefined = undefined
    try {
        beamsClient = new PusherPushNotifications.Client({
            instanceId: `${import.meta.env.VITE_PUSHER_BEAMS_ID}`
        })
    } catch (e) {}
    let sw: SharedWorker | undefined = undefined
    try {
        sw = new SharedWorker(`${import.meta.env.VITE_AUTH0_REDIRECT}/shared_worker.js`)
    } catch (e) {}

    const [isPusherInited, setIsPusherInited] = useState(false)

    const handleNotification = (data: any) => {
        if (!sw) return
        if (!data.from) {
            dispatch(
                ToastsDispatchPush(`Error with call notifications - did not receive any data`, 'error', `call-error`)
            )
        } else {
            dispatch(CallsDispatchUpdateSummaries(data))
            if (notifications == 'off') return
            if (data.call_type == 'incoming' && data.from && calling != 'true') {
                dispatch(
                    ToastsDispatchPush(
                        `Incoming call: ${data.from}`,
                        'call',
                        `call-${data.provider_call_id}`,
                        'longer',
                        { phone: `${data.from}`, type: 'incoming' }
                    )
                )
            }
            if (data.call_type == 'picked' && calling != 'true') {
                if (data.to != local_number) dispatch(ToastsDispatchPop(`call-${data.provider_call_id}`))
                if (data.to == local_number && data.from) {
                    dispatch(
                        ToastsDispatchPush(
                            `Incoming call: ${data.from}`,
                            'call',
                            `call-${data.provider_call_id}`,
                            'longer',
                            { phone: `${data.from}`, type: 'picked' }
                        )
                    )
                    calling = 'true'
                    sw.port.postMessage({
                        isOnCall: calling
                    })
                }
            }
            if (data.call_type == 'ended') {
                dispatch(ToastsDispatchPop(`call-${data.provider_call_id}`))
                if (data.to == local_number) {
                    calling = 'false'
                    sw.port.postMessage({
                        isOnCall: calling
                    })
                }
            }
        }
    }

    const initPusherChannels = () => {
        if (!sw) return
        generateJWTSync((token: string) => {
            if (!sw) return
            sw.port.postMessage({
                pusherId: `${import.meta.env.VITE_PUSHER_ID}`,
                mercuryEndpoint: `${import.meta.env.VITE_MERCURY_ENDPOINT}`,
                mercuryToken: token,
                pusherChannels: channels
            })
            sw.port.onmessage = function (e: any) {
                if (e.data.isCalling) calling = e.data.isCalling
                handleNotification(e.data.data.message)
            }
        })
    }

    useEffect(() => {
        if (!isPusherInited) return
        if (notifications == 'on') {
            if (line == '1') {
                setChannels(['1', '2', '3'])
                setInterests(['queue-1', 'queue-2', 'queue-3'])
            }
            if (line == '2') {
                setChannels(['2', '3'])
                setInterests(['queue-2', 'queue-3'])
            }
            if (line == '3') {
                setChannels(['3'])
                setInterests(['queue-3'])
            }
        } else {
            setChannels([])
            setInterests([])
        }
    }, [line, notifications])

    useEffect(() => {
        if (notifications == 'on') {
            if (!isPusherInited) {
                initPusherChannels()
                setIsPusherInited(true)
            }
        }
    }, [notifications])

    useEffect(() => {
        if (!isPusherInited) return
        if (!sw) return
        sw.port.postMessage({
            pusherChannels: channels
        })
    }, [channels])

    useEffect(() => {
        if (!beamsClient) return
        if (interests.length == 0) {
            beamsClient
                .stop()
                .then(() => console.log('Beams stopped'))
                .catch(console.error)
        } else {
            generateJWTSync((token: string) => {
                if (!beamsClient) return
                beamsTokenProvider = new PusherPushNotifications.TokenProvider({
                    url: `${import.meta.env.VITE_MERCURY_ENDPOINT}/pusher/beams/authentication`,
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
                })

                beamsClient
                    .getUserId()
                    .then((userId: string) => {
                        if (userId !== auth.user?.sub) {
                            return beamsClient?.stop()
                        }
                    })
                    .catch(console.error)
                beamsClient
                    .start()
                    .then(() => {
                        if (auth.user) {
                            beamsClient?.setUserId(auth.user.sub, beamsTokenProvider)
                        }
                    })
                    .then(() => beamsClient?.setDeviceInterests(interests))
                    .catch(console.error)
            })
        }
    }, [interests])

    return <></>
}
