import { useMerchantApplicationFieldDependencies } from './useMerchantApplicationFieldDependencies'

export const MerchantApplicationFieldConditionalRender: React.FC<{
    children: React.ReactElement
    showOnlyIfSiblingFieldExists: string
}> = ({ children, showOnlyIfSiblingFieldExists }) => {
    const { dependenciesShowHideState, formMounted } = useMerchantApplicationFieldDependencies()

    if (formMounted) {
        if (dependenciesShowHideState[showOnlyIfSiblingFieldExists]) return children
        return <></>
    } else {
        return children
    }
}
