import { Text } from '../../components/general/text'
import { TyposHighlighter } from '../../components/general/typosHighlighter'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { DataProviderOwnershipConflict } from '../../store/applicationDataProviders/types'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { ConflictPill, ConflictPillType } from './MerchantApplicationConflicts.Ownership.SOTTable'
import { CardInset } from '../../components/cards/cardInset'
import { Person } from '../../store/applications/types'
import { PersonCountryRole } from './MerchantApplicationConflicts.PersonCountryRole'

const getMatch = (name: string, conflict?: DataProviderOwnershipConflict) => {
    if (conflict?.type !== 'ubos-mismatch') return undefined
    return conflict.matches.find((m) => m.app.name == name)
}

export const MerchantApplicationConflictsOwnershipTable: React.FC<{
    conflict?: DataProviderOwnershipConflict
    applicationId: string
}> = ({ conflict, applicationId }) => {
    const people = useSelector(
        (state: RootState) => state.applicationResources.data.forApplication?.[applicationId].people.fields as Person[]
    )

    if (conflict?.type == 'application-zero-owners') return null

    return (
        <>
            <Table
                overrideText="No owners found in the application"
                background="front.background"
                type="normal"
                cols={[
                    {
                        text: 'Name'
                    },
                    {
                        text: 'Conflict',
                        alignRight: true
                    }
                ]}
                rows={people
                    .map((p) => {
                        if (!p.name) return null as any
                        const match = p.name ? getMatch(p.name, conflict) : undefined
                        const color = match ? 'front.text' : 'front.text.subtlerI'
                        const renderConflictPill = (match?: DataProviderOwnershipConflict['matches'][0]) => {
                            const render = (type: ConflictPillType, label: string) => {
                                return <ConflictPill type={type}>{label}</ConflictPill>
                            }
                            if (!match) return render('unlisted', 'Extra')
                            switch (match.type) {
                                case 'full':
                                    return null
                                case 'partial':
                                    return render('typo', 'Typo')
                                case 'wrong-role':
                                    return render('typo', 'Wrong Role specified')
                            }
                        }
                        return {
                            key: p.name,
                            type: 'normal',
                            noAction: true,
                            items: [
                                {
                                    node: (
                                        <Flex column>
                                            <CardInset type="tiny">
                                                <CardInset type="tiny">
                                                    <Text color={color}>
                                                        {match?.type.includes('partial') ? (
                                                            <TyposHighlighter
                                                                highlights={match.highlights}
                                                                fallbackText={match.app.name}
                                                            />
                                                        ) : (
                                                            <Text>{p.name}</Text>
                                                        )}
                                                    </Text>
                                                    <Spacer height={2} />
                                                    <PersonCountryRole
                                                        applicationId={applicationId}
                                                        personId={p.id}
                                                        person={p}
                                                    />
                                                </CardInset>
                                            </CardInset>
                                        </Flex>
                                    )
                                },
                                {
                                    node:
                                        !match || match?.type !== 'full' ? (
                                            <Flex justify="flex-start">{renderConflictPill(match)}</Flex>
                                        ) : null
                                }
                            ]
                        }
                    })
                    .filter((f) => !!f)}
                columnLayout="auto auto"
            />
        </>
    )
}
