import { Url } from '@/services/common'
import { api } from '../api'
import { Application } from '../types/applications'

export const applicationsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getApplication: build.query<Application, Url>({
            query: (url) => {
                return { url }
            }
        })
    })
})

export const { useGetApplicationQuery: useGetApplication } = applicationsApi
