import React, { useMemo } from 'react'
import { useApplicationValidation } from '../../../hooks/application/useApplicationValidation'
import { ApplicationValidationSectionItem } from './Application.ValidationSection.Item'

type ApplicationValidationSectionBusinessModelProps = {
    validationResults: ReturnType<typeof useApplicationValidation>[0]
}

export const ApplicationValidationSectionBusinessModel: React.FC<ApplicationValidationSectionBusinessModelProps> = ({
    validationResults
}) => {
    const businessModelResults = useMemo(() => {
        if (validationResults === undefined || !Array.isArray(validationResults)) {
            return null
        }

        return validationResults.filter((validation) => validation.path && validation.path.startsWith('business_model'))
    }, [validationResults])

    if (!businessModelResults || businessModelResults.length === 0) {
        return null
    }

    return (
        <>
            {businessModelResults.map((result) => (
                <ApplicationValidationSectionItem
                    testid={`validation-${result.path?.replace(/[._]/g, '-')}`}
                    key={result.path}
                >
                    {result.message}
                </ApplicationValidationSectionItem>
            ))}
        </>
    )
}
