import { VariantProps, cva } from 'class-variance-authority'

export type GridStyles = VariantProps<typeof styles.grid>
export type ItemStyles = VariantProps<typeof styles.item>

export const styles = {
    grid: cva('', {
        variants: {
            display: {
                'grid': 'grid',
                'inline-grid': 'inline-grid'
            },
            overflow: {
                'auto': 'overflow-auto',
                'hidden': 'overflow-hidden',
                'scroll': 'overflow-scroll',
                'x-auto': 'overflow-x-auto',
                'x-hidden': 'overflow-x-hidden',
                'x-scroll': 'overflow-x-scroll',
                'y-auto': 'overflow-y-auto',
                'y-hidden': 'overflow-y-hidden',
                'y-scroll': 'overflow-y-scroll'
            },
            columns: {
                'none': 'grid-cols-none',
                '1': 'grid-cols-1',
                '2': 'grid-cols-2',
                '3': 'grid-cols-3',
                '4': 'grid-cols-4',
                '5': 'grid-cols-5',
                '6': 'grid-cols-6',
                '7': 'grid-cols-7',
                '8': 'grid-cols-8',
                '9': 'grid-cols-9',
                '10': 'grid-cols-10',
                '11': 'grid-cols-11',
                '12': 'grid-cols-12'
            },
            columnsAuto: {
                '1': 'grid-cols-auto-1',
                '2': 'grid-cols-auto-2',
                '3': 'grid-cols-auto-3',
                '4': 'grid-cols-auto-4',
                '5': 'grid-cols-auto-5',
                '6': 'grid-cols-auto-6',
                '7': 'grid-cols-auto-7',
                '8': 'grid-cols-auto-8',
                '9': 'grid-cols-auto-9',
                '10': 'grid-cols-auto-10',
                '11': 'grid-cols-auto-11',
                '12': 'grid-cols-auto-12'
            },
            rows: {
                'none': 'grid-rows-none',
                '1': 'grid-rows-1',
                '2': 'grid-rows-2',
                '3': 'grid-rows-3',
                '4': 'grid-rows-4',
                '5': 'grid-rows-5',
                '6': 'grid-rows-6'
            },
            align: {
                start: 'items-start',
                center: 'items-center',
                end: 'items-end',
                baseline: 'items-baseline',
                stretch: 'items-stretch'
            },
            justify: {
                normal: 'justify-normal',
                start: 'justify-start',
                end: 'justify-end',
                center: 'justify-center',
                between: 'justify-between',
                around: 'justify-around',
                evenly: 'justify-evenly',
                stretch: 'justify-stretch'
            },
            gap: {
                '0': 'gap-0',
                'x-0': 'gap-x-0',
                'y-0': 'gap-y-0',
                'px': 'gap-px',
                'x-px': 'gap-x-px',
                'y-px': 'gap-y-px',
                '0.5': 'gap-0.5',
                'x-0.5': 'gap-x-0.5',
                'y-0.5': 'gap-y-0.5',
                '1': 'gap-1',
                'x-1': 'gap-x-1',
                'y-1': 'gap-y-1',
                '1.5': 'gap-1.5',
                'x-1.5': 'gap-x-1.5',
                'y-1.5': 'gap-y-1.5',
                '2': 'gap-2',
                'x-2': 'gap-x-2',
                'y-2': 'gap-y-2',
                '2.5': 'gap-2.5',
                'x-2.5': 'gap-x-2.5',
                'y-2.5': 'gap-y-2.5',
                '3': 'gap-3',
                'x-3': 'gap-x-3',
                'y-3': 'gap-y-3',
                '3.5': 'gap-3.5',
                'x-3.5': 'gap-x-3.5',
                'y-3.5': 'gap-y-3.5',
                '4': 'gap-4',
                'x-4': 'gap-x-4',
                'y-4': 'gap-y-4',
                '5': 'gap-5',
                'x-5': 'gap-x-5',
                'y-5': 'gap-y-5',
                '6': 'gap-6',
                'x-6': 'gap-x-6',
                'y-6': 'gap-y-6',
                '7': 'gap-7',
                'x-7': 'gap-x-7',
                'y-7': 'gap-y-7',
                '8': 'gap-8',
                'x-8': 'gap-x-8',
                'y-8': 'gap-y-8',
                '9': 'gap-9',
                'x-9': 'gap-x-9',
                'y-9': 'gap-y-9',
                '10': 'gap-10',
                'x-10': 'gap-x-10',
                'y-10': 'gap-y-10',
                '11': 'gap-11',
                'x-11': 'gap-x-11',
                'y-11': 'gap-y-11',
                '12': 'gap-12',
                'x-12': 'gap-x-12',
                'y-12': 'gap-y-12',
                '14': 'gap-14',
                'x-14': 'gap-x-14',
                'y-14': 'gap-y-14',
                '16': 'gap-16',
                'x-16': 'gap-x-16',
                'y-16': 'gap-y-16',
                '20': 'gap-20',
                'x-20': 'gap-x-20',
                'y-20': 'gap-y-20',
                '24': 'gap-24',
                'x-24': 'gap-x-24',
                'y-24': 'gap-y-24',
                '28': 'gap-28',
                'x-28': 'gap-x-28',
                'y-28': 'gap-y-28',
                '32': 'gap-32',
                'x-32': 'gap-x-32',
                'y-32': 'gap-y-32',
                '36': 'gap-36',
                'x-36': 'gap-x-36',
                'y-36': 'gap-y-36',
                '40': 'gap-40',
                'x-40': 'gap-x-40',
                'y-40': 'gap-y-40',
                '44': 'gap-44',
                'x-44': 'gap-x-44',
                'y-44': 'gap-y-44',
                '48': 'gap-48',
                'x-48': 'gap-x-48',
                'y-48': 'gap-y-48',
                '52': 'gap-52',
                'x-52': 'gap-x-52',
                'y-52': 'gap-y-52',
                '56': 'gap-56',
                'x-56': 'gap-x-56',
                'y-56': 'gap-y-56',
                '60': 'gap-60',
                'x-60': 'gap-x-60',
                'y-60': 'gap-y-60',
                '64': 'gap-64',
                'x-64': 'gap-x-64',
                'y-64': 'gap-y-64',
                '72': 'gap-72',
                'x-72': 'gap-x-72',
                'y-72': 'gap-y-72',
                '80': 'gap-80',
                'x-80': 'gap-x-80',
                'y-80': 'gap-y-80',
                '96': 'gap-96',
                'x-96': 'gap-x-96',
                'y-96': 'gap-y-96'
            }
        },
        defaultVariants: {
            display: 'grid'
        }
    }),
    item: cva('', {
        variants: {
            columnSpan: {
                'auto': 'col-auto',
                '1': 'col-span-1',
                '2': 'col-span-2',
                '3': 'col-span-3',
                '4': 'col-span-4',
                '5': 'col-span-5',
                '6': 'col-span-6',
                '7': 'col-span-7',
                '8': 'col-span-8',
                '9': 'col-span-9',
                '10': 'col-span-10',
                '11': 'col-span-11',
                '12': 'col-span-12',
                'full': 'col-span-full'
            },
            columnStart: {
                'auto': 'col-start-auto',
                '1': 'col-start-1',
                '2': 'col-start-2',
                '3': 'col-start-3',
                '4': 'col-start-4',
                '5': 'col-start-5',
                '6': 'col-start-6',
                '7': 'col-start-7',
                '8': 'col-start-8',
                '9': 'col-start-9',
                '10': 'col-start-10',
                '11': 'col-start-11',
                '12': 'col-start-12',
                '13': 'col-start-13'
            },
            columnEnd: {
                'auto': 'col-end-auto',
                '1': 'col-end-1',
                '2': 'col-end-2',
                '3': 'col-end-3',
                '4': 'col-end-4',
                '5': 'col-end-5',
                '6': 'col-end-6',
                '7': 'col-end-7',
                '8': 'col-end-8',
                '9': 'col-end-9',
                '10': 'col-end-10',
                '11': 'col-end-11',
                '12': 'col-end-12',
                '13': 'col-end-13'
            },
            rowSpan: {
                'auto': 'row-auto',
                '1': 'row-span-1',
                '2': 'row-span-2',
                '3': 'row-span-3',
                '4': 'row-span-4',
                '5': 'row-span-5',
                '6': 'row-span-6',
                'full': 'row-span-full'
            },
            rowStart: {
                'auto': 'row-start-auto',
                '1': 'row-start-1',
                '2': 'row-start-2',
                '3': 'row-start-3',
                '4': 'row-start-4',
                '5': 'row-start-5',
                '6': 'row-start-6',
                '7': 'row-start-7'
            },
            rowEnd: {
                'auto': 'row-end-auto',
                '1': 'row-end-1',
                '2': 'row-end-2',
                '3': 'row-end-3',
                '4': 'row-end-4',
                '5': 'row-end-5',
                '6': 'row-end-6',
                '7': 'row-end-7'
            },
            align: {
                auto: 'self-auto',
                start: 'self-start',
                center: 'self-center',
                end: 'self-end',
                stretch: 'self-stretch',
                baseline: 'self-baseline'
            },
            justify: {
                auto: 'justify-self-auto',
                start: 'justify-self-start',
                center: 'justify-self-center',
                end: 'justify-self-end',
                stretch: 'justify-self-stretch',
                baseline: 'justify-self-baseline'
            }
        }
    })
}
