import React from 'react'
import { ContentStyles, styles } from './styles'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cn } from '../utils'

interface TooltipProps {
    children: React.ReactNode
    delayDuration?: number
}

const Tooltip = (props: TooltipProps) => {
    const { children, delayDuration } = props

    return (
        <TooltipPrimitive.Provider delayDuration={delayDuration}>
            <TooltipPrimitive.Root>{children}</TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    )
}

interface TriggerProps {
    testid?: string
    children: React.ReactNode
    asChild?: boolean
}

const Trigger = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Trigger>, TriggerProps>((props, ref) => {
    const { testid, children, asChild } = props

    return (
        <TooltipPrimitive.Trigger data-cy={testid} asChild={asChild} ref={ref}>
            {children}
        </TooltipPrimitive.Trigger>
    )
})

interface ContentProps extends ContentStyles {
    children: React.ReactNode
    align?: 'start' | 'center' | 'end'
}

const Content = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, ContentProps>((props, ref) => {
    const { children, align } = props

    const classnames = cn(styles.content())

    return (
        <TooltipPrimitive.Portal>
            <TooltipPrimitive.Content ref={ref} align={align} sideOffset={8} className={classnames}>
                {children}
            </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
    )
})

Content.displayName = 'Content'

Tooltip.Trigger = Trigger
Tooltip.Content = Content

export { Tooltip }
