import { useSelector } from 'react-redux'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Grid } from '../../../components/layout/grid'
import { List } from '../../../components/layout/list'
import { RootState } from '@/store'
import * as yup from 'yup'

export const MerchantAccountSettlementDetails: React.FC<{
    accountId: string
    formRef: any
    errors?: any
    data: any
}> = ({ accountId, formRef, errors, data }) => {
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])

    return (
        <Card showEditable>
            <CardInset>
                <Grid horizontalTemplate="1fr 1fr" verticalTemplate="auto" switchToRowsAt={1500}>
                    <List
                        background="front.background"
                        switchToRowsAt={10000}
                        cellHorizontalTemplate="140px minmax(auto, 1fr)"
                        items={{
                            IBAN: {
                                type: 'input',
                                placeholder: '-',
                                overBackground: 'front.background',
                                initialValue: account?.bankAccount?.iban,
                                validation: errors['bank_account.iban'],
                                ref: (ref) => formRef(ref, 'bank_account.iban', yup.string().required().iban())
                            },
                            SWIFT: {
                                type: 'input',
                                placeholder: '-',
                                overBackground: 'front.background',
                                initialValue: account?.bankAccount?.swiftCode,
                                validation: errors['bank_account.swift_code'],
                                ref: (ref) => formRef(ref, 'bank_account.swift_code', yup.string().swift())
                            }
                        }}
                    />
                    <List
                        background="front.background"
                        switchToRowsAt={10000}
                        cellHorizontalTemplate="140px minmax(auto, 1fr)"
                        items={{
                            'Collapsed IBAN': {
                                type: 'input',
                                placeholder: '-',
                                initialValue: account?.bankAccount?.collapsedIban,
                                overBackground: 'front.background',
                                validation: errors['bank_account.collapsed_iban'],
                                ref: (ref) =>
                                    formRef(
                                        ref,
                                        'bank_account.collapsed_iban',
                                        yup
                                            .string()
                                            .matches(/^[A-Z0-9 ]*$/, 'Only uppercase letters/numbers')
                                            .max(35, 'Max 35 chars.')
                                    )
                            },
                            'Notes': {
                                type: 'input',
                                placeholder: '-',
                                overBackground: 'front.background',
                                initialValue: account?.bankAccount?.note,
                                validation: errors['bank_account.note'],
                                ref: (ref) => formRef(ref, 'bank_account.note', yup.string())
                            }
                        }}
                    />
                </Grid>
            </CardInset>
        </Card>
    )
}
