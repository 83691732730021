import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Sidebar } from '../../components/listPages/sidebarNavigation/sidebar'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { LinkButton } from '../../components/buttons/linkButton'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { List } from '../../components/layout/list'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { useListPage } from '../../hooks/pages/useListPage'
import { ListPageFilterDate } from '../../components/listPageFilters/listPageFilterDate'
import { ListPageFilterMerchant } from '../../components/listPageFilters/listPageFilterMerchant'
import { ListPageFilterSettlementsCurrency } from '../../components/listPageFilters/listPageFilterSettlementsCurrency'
import { ListPageFilterSettlementsStatus } from '../../components/listPageFilters/listPageFilterSettlementsStatus'
import { ListPageFiltersSeparator } from '../../components/listPageFilters/listPageFiltersSeparator'
import { Filters } from '../../components/taskPages/filters'
import { RootState } from '@/store'
import { SettlementsDispatchClearSummaries, SettlementsDispatchLoadSummaries } from '../../store/settlements/actions'
import { SettlementsFilters, SettlementsFiltersParams } from '../../store/settlements/types'
import { DateToWords, numberWithCurrency } from '../../utils'
import { useResetScrollOnMount } from '../../hooks/pages/useResetScrollOnMount'
import { useMerchantListPageDynamicTitle } from '../../hooks/pages/useMerchantListPageDynamicTitle'
import { useMerchantName } from '../../hooks/general/useMerchantName'
import { SideFilters } from '../../components/layout/sideFilters'
import { ExportButton } from '../../components/buttons/exportButton'
import { SettlementStatus } from '../../components/complexComponents/settlementStatus'
import { ListPageFilterCustomQuery } from '../../components/listPageFilters/listPageFilterCustomQuery'
import { ListPageFullPageBackButton } from '../../components/buttons/listPageFullPageBackButton'
import { TableBubbleType, TableBubble } from '../../components/layout/tableBubble'
import { SettlementsReservesActions } from '../../store/settlementsReserves/actions'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { useBackendToConsoleFilters } from '../../hooks/general/useBackendToConsoleFilters'
import { useLocation, useParams } from 'react-router-dom'

type Params = { id: string }

const clearSummaries = () => SettlementsDispatchClearSummaries()

const paginationKeys = {
    pageKey: 'settlements_page',
    perPageKey: 'settlements_per_page'
}
const disallowMIDFilter = { settlements_mid: null }

export const Settlements: React.FC<{ inline?: boolean; forMids?: any; merchantId?: string; fullPage?: boolean }> = ({
    inline,
    forMids,
    fullPage
}) => {
    const params = useParams() as Params
    const merchantId = params.id

    const location = useLocation()

    useBackendToConsoleFilters('sql')
    useResetScrollOnMount()
    const summaries = useSelector((state: RootState) => {
        return state.settlements.settlementsSummaries
    })
    const name = useMerchantName(merchantId, 'Settlements')

    const loadSummaries = useCallback(
        (filters: SettlementsFilters) => {
            if (forMids) {
                return SettlementsDispatchLoadSummaries({ ...filters, settlements_mid: forMids.settlements_mid })
            }
            return SettlementsDispatchLoadSummaries(filters)
        },
        [forMids]
    )
    const { filters, paginationNode } = useListPage<SettlementsFilters>(
        'SettlementsList',
        merchantId ? name : 'Settlements',
        loadSummaries,
        SettlementsFiltersParams,
        summaries,
        '/disputes/',
        location,
        undefined,
        paginationKeys,
        forMids ? disallowMIDFilter : undefined,
        undefined,
        clearSummaries
    )

    const renderValueTextCell = (
        curr: string,
        value?: number,
        skipFormatting?: boolean,
        suffix?: string,
        type: TableBubbleType = 'subtle'
    ) => {
        if (!value) return <TableBubble isZero type={type} />
        const formattedValue = numberWithCurrency(curr, value)

        if (skipFormatting) return formattedValue

        if (value == 0)
            return (
                <TableBubble type={type}>
                    {formattedValue}
                    {suffix}
                </TableBubble>
            )

        return (
            <TableBubble type={type}>
                {formattedValue}
                {suffix}
            </TableBubble>
        )
    }

    const renderedFilters = useMemo(() => {
        const items: any = (isDark = false) => ({
            'Query': {
                type: 'custom',
                node: (
                    <ListPageFilterCustomQuery<SettlementsFilters>
                        key="custom-filters"
                        fieldKey="settlements_custom_filters"
                        filters={filters}
                        isDark={isDark}
                    />
                )
            },
            'MID': fullPage
                ? null
                : forMids
                ? null
                : {
                      type: 'custom' as const,
                      node: (
                          <ListPageFilterMerchant<SettlementsFilters>
                              isDark={isDark}
                              filters={filters}
                              fieldKey="settlements_mid"
                          />
                      )
                  },
            'Status': {
                type: 'custom' as const,
                node: <ListPageFilterSettlementsStatus isDark={isDark} filters={filters} />
            },
            'Currencies': {
                type: 'custom' as const,
                node: <ListPageFilterSettlementsCurrency isDark={isDark} filters={filters} />
            },
            ...(isDark
                ? {
                      'Separator 1': {
                          key: 'separator-1',
                          type: 'separator' as const,
                          node: <ListPageFiltersSeparator isDark={isDark} />
                      }
                  }
                : {}),
            'Started on': {
                type: 'custom' as const,
                node: (
                    <ListPageFilterDate<SettlementsFilters>
                        isDark={isDark}
                        filters={filters}
                        filterKey="settlements_startDate"
                    />
                )
            },
            'Ended before': {
                type: 'custom' as const,
                node: (
                    <ListPageFilterDate<SettlementsFilters>
                        isDark={isDark}
                        filters={filters}
                        filterKey="settlements_endDate"
                    />
                )
            },
            ...(isDark
                ? {
                      'Separator 2': {
                          key: 'separator-2',
                          type: 'separator' as const,
                          node: <ListPageFiltersSeparator isDark={isDark} />
                      }
                  }
                : {}),
            'Settled after': {
                type: 'custom' as const,
                node: (
                    <ListPageFilterDate<SettlementsFilters>
                        isDark={isDark}
                        filters={filters}
                        filterKey="settlements_payoutAfterDate"
                    />
                )
            },
            'Settled before': {
                type: 'custom' as const,
                node: (
                    <ListPageFilterDate<SettlementsFilters>
                        filters={filters}
                        isDark={isDark}
                        filterKey="settlements_payoutBeforeDate"
                    />
                )
            },
            ...(isDark
                ? {
                      'Separator 3': {
                          key: 'separator-3',
                          type: 'separator' as const,
                          node: <ListPageFiltersSeparator isDark={isDark} />
                      }
                  }
                : {}),
            'Reserved after': {
                type: 'custom' as const,
                node: (
                    <ListPageFilterDate<SettlementsFilters>
                        filters={filters}
                        isDark={isDark}
                        filterKey="settlements_reserveAfterDate"
                    />
                )
            },
            'Reserved before': {
                type: 'custom' as const,
                node: (
                    <ListPageFilterDate<SettlementsFilters>
                        filters={filters}
                        isDark={isDark}
                        filterKey="settlements_reserveBeforeDate"
                    />
                )
            }
        })

        return {
            sideFilters: (
                <SideFilters
                    shouldAlwaysDisplay={fullPage}
                    items={items()}
                    before={<ListPageFullPageBackButton hotkeysScope="SettlementsList" fullPage={fullPage} />}
                />
            ),
            headerFilters: (
                <>
                    <Filters
                        showHideController={filters.showHideController}
                        shouldAlwaysHide={fullPage}
                        filters={filters}
                        resultsCount={summaries.all.length}
                        entityName="settlements"
                        pagination={undefined}
                        key="allFilters"
                        inline={inline}
                        noClearButton={fullPage}
                        hotkeysScope="SettlementsList"
                    >
                        <List
                            background="floating.background"
                            items={items(true)}
                            emphasizeLabels
                            template="auto"
                            switchToRowsAt={100000}
                            cellHorizontalTemplate="minmax(100px,min-content) auto"
                        />
                    </Filters>
                    {filters.get.settlements_mid && !inline && (
                        <>
                            <Spacer width={10} />
                            <LinkButton
                                background="front.subtleAccent.background"
                                to={{
                                    pathname: `/transactions`,
                                    search: `?transactions_mid=${filters.get.settlements_mid}`
                                }}
                            >
                                <ButtonInset>See MID&apos;s transactions</ButtonInset>
                            </LinkButton>
                        </>
                    )}
                </>
            )
        }
    }, [filters, forMids, inline, summaries.all.length, fullPage])

    const extraColumns = useMemo(() => {
        const keys = ['sales', 'credits', 'refunds', 'chargebacks', 'fees', 'otherPostings']
        if (summaries.loadingStatus !== 'done')
            return [
                keys.map((id: string) => {
                    return {
                        key: id,
                        node: (
                            <TableBubble key={id} isZero={true}>
                                {'0.00'}
                            </TableBubble>
                        )
                    }
                })
            ]
        return summaries.all.map((id: string) => {
            const t = summaries.at[id] as any
            return keys.map((k: string) => {
                const sum = t.summary as any

                return {
                    key: k,
                    node: (
                        <TableBubble key={k} isZero={!sum[k]}>
                            {sum[k] ? renderValueTextCell(t.currency, sum[k], true) : '0.00'}
                        </TableBubble>
                    )
                }
            })
        })
    }, [summaries])

    const rows = useMemo(() => {
        return summaries.all.map((id: string, index) => {
            const t = summaries.at[id]

            let link = `/settlements/${t.id}${location.search}`
            if (merchantId) {
                link = `/merchant/${merchantId}/settlements/${t.id}`
            }

            return {
                type: 'normal' as const,
                link,
                key: t.id,
                items: [
                    {
                        node: <TableBubble type="outlined">{t.account.merchantId}</TableBubble>
                    },
                    {
                        node: t.period.startDate ? (
                            <Text noWrap bold>
                                {DateToWords(t.period.startDate, true)}
                            </Text>
                        ) : (
                            <Text noWrap color="front.text.subtlerI">
                                -
                            </Text>
                        )
                    },
                    {
                        node: t.period.endDate ? (
                            <Text noWrap bold>
                                {DateToWords(t.period.endDate, true)}
                            </Text>
                        ) : (
                            <Text noWrap color="front.text.subtlerI">
                                -
                            </Text>
                        )
                    },
                    ...(extraColumns?.[index]?.map?.((k) => ({
                        node: k.node
                    })) || []),
                    {
                        node: renderValueTextCell(t.currency, t.summary.net, undefined, ' ' + t.currency, 'important')
                    },
                    {
                        node: renderValueTextCell(t.currency, t.payout?.amount, undefined, undefined, 'outlined')
                    },
                    {
                        node: t.payout?.date ? (
                            <Text noWrap>{DateToWords(t.payout?.date, true)}</Text>
                        ) : (
                            <Text noWrap color="front.text.subtlerI">
                                -
                            </Text>
                        )
                    },
                    {
                        node: renderValueTextCell(t.currency, t.reserve?.amount, undefined, undefined, 'outlined')
                    },
                    {
                        node: (
                            <Flex justify="flex-end">
                                {!t.reserve?.cancelledDate && t.reserve?.date ? (
                                    <Text noWrap>{DateToWords(t.reserve?.date, true)}</Text>
                                ) : (
                                    <Text noWrap color={'front.text.subtlerI'}>
                                        -
                                    </Text>
                                )}
                            </Flex>
                        )
                    },
                    {
                        node: <SettlementStatus s={t} />
                    }
                ]
            }
        })
    }, [location, extraColumns, merchantId, summaries.all, summaries.at])

    const title = useMerchantListPageDynamicTitle(filters, forMids, 'settlements_mid')
    const dispatch = useDispatch()

    const rightSideMemo = useMemo(() => {
        return (
            <Flex align="center">
                {summaries.forQuery && (
                    <ExportButton resourceLink={summaries.forQuery} availableFormats={['csv', 'excel', 'pdf']} />
                )}
                {fullPage ? null : renderedFilters.headerFilters}
            </Flex>
        )
    }, [renderedFilters, summaries, fullPage])

    const cols = useMemo(() => {
        const keyForCol = {
            sales: 'sales',
            credits: 'creds',
            refunds: 'refnd',
            chargebacks: 'chrbk',
            fees: 'fees',
            otherPostings: 'otpos'
        }
        return [
            {
                text: 'MID',
                loaderSize: {
                    min: 50,
                    max: 50
                }
            },
            {
                text: 'Start',
                loaderSize: {
                    min: 10,
                    max: 50
                }
            },
            {
                text: 'End',
                loaderSize: {
                    min: 10,
                    max: 80
                }
            },
            ...(extraColumns?.[0]?.map((c) => ({
                text: (keyForCol as any)?.[c.key] || '?',
                alignRight: true,
                loaderSize: {
                    min: 20,
                    max: 20
                }
            })) || []),
            {
                text: 'Total',
                alignRight: true,
                loaderSize: {
                    min: 10,
                    max: 60
                }
            },
            {
                text: 'Payout',
                alignRight: true,
                loaderSize: {
                    min: 10,
                    max: 60
                }
            },
            {
                text: 'Payout Date',
                alignRight: true,
                loaderSize: {
                    min: 10,
                    max: 60
                }
            },
            {
                text: 'Reserve',
                alignRight: true,
                loaderSize: {
                    min: 10,
                    max: 60
                }
            },
            {
                text: 'Reserve Date',
                alignRight: true,
                loaderSize: {
                    min: 10,
                    max: 60
                }
            },
            {
                text: 'Status',
                alignRight: true,
                loaderSize: {
                    min: 10,
                    max: 60
                }
            }
        ]
    }, [extraColumns])

    const leftSideMemo = useMemo(() => {
        const MID = filters?.get['settlements_mid']
        const releaseFunds =
            typeof MID !== 'string' ? (
                <>
                    <Text color="back.text">Select a single account to cancel funds.</Text>
                </>
            ) : (
                <>
                    <SimpleButton
                        background="front.subtleAccent.background"
                        onClick={() => {
                            dispatch(SettlementsReservesActions.START_FLOW(MID))
                        }}
                    >
                        <ButtonInset padding="small">
                            Cancel funds for&nbsp;
                            <Text bold>{MID}</Text>
                        </ButtonInset>
                    </SimpleButton>
                </>
            )
        return MID ? releaseFunds : null
    }, [filters, dispatch])

    if (forMids && !forMids.settlements_mid)
        return (
            <Flex column>
                <Spacer height={21} />
                <Text color="front.text.subtlerI">Merchant has no accounts</Text>
            </Flex>
        )

    return (
        <Flex align="stretch">
            {!inline && !fullPage && <Sidebar hotkeysScope="SettlementsList" />}
            {!inline && renderedFilters.sideFilters}
            <PageContent marginLeft={0} noVerticalPadding noPadding={inline}>
                <PageHeader
                    noBottomBorder
                    title={title}
                    subtitle=""
                    // insetTop={inline}
                    leftSideMemo={leftSideMemo}
                    rightSideMemo={rightSideMemo}
                />
                <Card overflowX cy="accounts-lists">
                    <CardInset>
                        <Table
                            background="front.background"
                            cols={cols}
                            // eslint-disable-next-line max-len
                            columnLayout={`min-content min-content 2fr ${extraColumns?.[0]?.reduce((acc, k) => {
                                acc += 'min-content '
                                return acc
                            }, '')} 1fr 1fr 0.5fr 1fr 0.5fr 1fr`}
                            showLastRowBorder
                            keepQuery
                            displayLoader={summaries.loadingStatus == 'started'}
                            loaderRows={20}
                            rows={rows}
                            overrideText={
                                forMids
                                    ? `None of this merchant's accounts have received any settlements`
                                    : 'No settlements found matching the filtered criterias.'
                            }
                        />
                    </CardInset>
                </Card>
                {paginationNode}
            </PageContent>
        </Flex>
    )
}
