import styled, { css } from 'styled-components'

import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'

export const TransactionRulesValidator: React.FC<{ error?: string }> = ({ error }) => {
    return (
        <Box>
            <Flex align="flex-start" basis="0" grow>
                <IconCircle invalid={!!error}>
                    {error ? <Icon type="x" size={6} weight={1.5} /> : <Icon type="checkmark" size={6} weight={1.5} />}
                </IconCircle>
                <Spacer width={5} />
                <Flex column fullWidth>
                    <Spacer height={4} />
                    <Text color={error ? 'front.subtleDanger.text' : 'front.text'}>
                        <ErrorHolder>{error || 'All rules are valid'}</ErrorHolder>
                    </Text>
                </Flex>
            </Flex>
        </Box>
    )
}

const ErrorHolder = styled.div`
    white-space: pre-wrap;
`

const IconCircle = styled.div<{ invalid?: boolean }>`
    width: 18px;
    height: 18px;
    border-radius: 999px;
    color: #fff;
    display: flex;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme['front.success.color']};
    position: relative;
    top: 3px;
    flex-shrink: 0;

    ${(p) =>
        p.invalid &&
        css`
            background-color: ${(p) => p.theme['front.danger.color']};
        `}
`

const Box = styled.div`
    padding: 5px;
    margin: -5px;
    border-radius: 4px;
    margin-bottom: -10px;
`
