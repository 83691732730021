import { cva, VariantProps } from 'class-variance-authority'

export type ContainerStyles = VariantProps<typeof styles.container>
export type SpinnerStyles = VariantProps<typeof styles.spinner>

export const styles = {
    container: cva(['w-full', 'h-[2px]', 'overflow-hidden', 'rounded-full'], {
        variants: {
            color: {
                primary: 'bg-primary-200',
                neutral: 'bg-neutral-300',
                danger: 'bg-danger-300',
                white: 'bg-neutral-300'
            }
        }
    }),
    spinner: cva(['w-2/5', 'h-full', 'animate-move', 'bg-primary', 'rounded-full'], {
        variants: {
            color: {
                primary: 'bg-primary-800',
                neutral: 'bg-neutral-600',
                danger: 'bg-danger-700',
                white: 'bg-neutral-500'
            }
        }
    })
}
