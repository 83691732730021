import { ApplicationsFilters } from '@/store/applications/types'
import { DisputesFilters } from '../store/disputes/types'
import { SettlementsFilters } from '../store/settlements/types'
import { TasksFilters } from '../store/tasks/types'
import { TransactionsFilters } from '../store/transactions/types'
import { filtersToQuery } from './listPage'

export interface TQLConverterInterface {
    settlements: (filters: SettlementsFilters) => string
    disputes: (filters: DisputesFilters) => string
    transactions: (filters: TransactionsFilters) => string
    tasks: (filters: TasksFilters) => string
    applications: (filters: ApplicationsFilters) => string
}

export const TQLConverter: TQLConverterInterface = {
    settlements: (filters: SettlementsFilters) => {
        const presenceChecker = {
            dates: false,
            datesAdded: false,
            reserveDatesAdded: false
        }
        return filtersToQuery<SettlementsFilters>(
            filters,
            (t: string, f: SettlementsFilters) => {
                if (t === 'settlements_startDate' || t === 'settlements_endDate') {
                    if (presenceChecker.dates === false) {
                        presenceChecker.dates = true
                        return {
                            key: 'period',
                            val: `${f.settlements_startDate ? f.settlements_startDate : '*'}${
                                f.settlements_endDate ? `..${f.settlements_endDate}` : '..*'
                            }`
                        }
                    }
                } else if (t === 'settlements_payoutAfterDate' || t === 'settlements_payoutBeforeDate') {
                    if (presenceChecker.datesAdded === false) {
                        presenceChecker.datesAdded = true
                        return {
                            key: 'payout.date',
                            val: `${f.settlements_payoutAfterDate ? f.settlements_payoutAfterDate : '*'}${
                                f.settlements_payoutBeforeDate ? `..${f.settlements_payoutBeforeDate}` : '..*'
                            }`
                        }
                    }
                } else if (t === 'settlements_reserveAfterDate' || t === 'settlements_reserveBeforeDate') {
                    if (presenceChecker.reserveDatesAdded === false) {
                        presenceChecker.reserveDatesAdded = true
                        return {
                            key: 'reserve.date',
                            val: `${f.settlements_reserveAfterDate ? f.settlements_reserveAfterDate : '*'}${
                                f.settlements_reserveBeforeDate ? `..${f.settlements_reserveBeforeDate}` : '..*'
                            }`
                        }
                    }
                } else if (t === 'settlements_settled') {
                    if (f[t] === 'settled')
                        return {
                            key: 'settled',
                            val: 'true'
                        }
                    if (f[t] === 'not-settled')
                        return {
                            key: 'settled',
                            val: 'false'
                        }
                    return {
                        key: 'settled',
                        val: undefined
                    }
                } else if (t === 'settlements_currency') {
                    return {
                        key: 'currency',
                        val: typeof f[t] === 'object' ? f[t].join(',').toUpperCase() : (f[t] as any).toUpperCase()
                    }
                } else
                    return {
                        key: t.replace('settlements_', ''),
                        val: (f as any)[t]
                    }
            },
            {
                pageKey: 'settlements_page',
                perPageKey: 'settlements_per_page'
            }
        )
            .replace('query%3A', '')
            .replace('custom_filters%3A', '')
    },
    disputes: (filters: DisputesFilters) => {
        const presenceChecker = {
            date: false
        }
        return filtersToQuery<DisputesFilters>(
            filters,
            (t, f) => {
                if (t === 'disputes_after' || t === 'disputes_before') {
                    if (presenceChecker.date === false) {
                        presenceChecker.date = true
                        return {
                            key: 'opened',
                            val: `${f.disputes_after ? f.disputes_after : '*'}${
                                f.disputes_before ? `..${f.disputes_before}` : '..*'
                            }`
                        }
                    }
                }
                return { key: t.replace('disputes_', ''), val: (f as any)[t] }
            },
            { pageKey: 'disputes_page', perPageKey: 'disputes_per_page' }
        )
            .replace('query%3A', '')
            .replace('custom_filters%3A', '')
    },
    tasks: (filters: TasksFilters) => {
        const presenceChecker = {
            date: false
        }
        return filtersToQuery<TasksFilters>(
            filters,
            (t, f) => {
                if ((t === 'tasks_start_date' || t === 'tasks_end_date') && presenceChecker.date === false) {
                    presenceChecker.date = true
                    return {
                        key: 'opened_at',
                        val: `${f.tasks_start_date ? f.tasks_start_date : ''}${
                            f.tasks_end_date ? `..${f.tasks_end_date}` : '..*'
                        }`
                    }
                }
                if (t === 'tasks_tag') {
                    let tags = f.tasks_tag
                    if (typeof tags === 'string') tags = [tags]
                    return {
                        key: 'tag',
                        val: tags.map((t) => `"${t}"`).join('tag:')
                    }
                }
                if (t === 'tasks_type') {
                    let types = f.tasks_type
                    if (typeof types === 'string') types = [types]
                    return {
                        key: 'type',
                        val: types.map((t) => `"${t}"`).join('type:')
                    }
                }
                if (t === 'tasks_agent') return { key: 'assignee', val: (f as any)[t] }
                return { key: t.replace('tasks_', ''), val: (f as any)[t] }
            },
            {
                pageKey: 'tasks_page',
                perPageKey: 'tasks_per_page'
            }
        )
    },
    transactions: (filters: TransactionsFilters) => {
        const presenceChecker = {
            date: false
        }
        return filtersToQuery<TransactionsFilters>(
            filters,
            (t, f) => {
                if (t === 'transactions_before' || t === 'transactions_after') {
                    if (presenceChecker.date === false) {
                        presenceChecker.date = true
                        return {
                            key: 'date',
                            val: `${f.transactions_after ? f.transactions_after : '*'}${
                                f.transactions_before ? `..${f.transactions_before}` : '..*'
                            }`
                        }
                    }
                } else if (t === 'transactions_status') {
                    return {
                        key: 'status',
                        val: typeof f[t] === 'object' ? f[t].join(',') : f[t]
                    }
                } else
                    return {
                        key: t.replace('transactions_', ''),
                        val: (f as any)[t]
                    }
            },
            {
                pageKey: 'transactions_page',
                perPageKey: 'transactions_per_page'
            },
            true
        )
            .replace('type%3A', 'is%3A')
            .replace('custom_filters%3A', '')
    },
    applications: (filters: ApplicationsFilters) => {
        const presenceChecker = {
            date: false
        }
        return filtersToQuery<ApplicationsFilters>(
            filters,
            (t, f) => {
                if (t === 'applications_agent') return { key: 'assignee.id', val: (f as any)[t] }
                if (t === 'applications_gateway') {
                    return { key: 'gateway.name', val: `"${(f as any)[t]}"` }
                }
                if (t === 'applications_start_date' || t === 'applications_end_date') {
                    if (presenceChecker.date === false) {
                        presenceChecker.date = true
                        return {
                            key: 'submitted_at',
                            val: `${f.applications_start_date ? f.applications_start_date : '*'}${
                                f.applications_end_date ? `..${f.applications_end_date}` : '..*'
                            }`
                        }
                    }
                }
            }
        )
            .replace('type%3A', 'is%3A')
            .replace('custom_filters%3A', '')
    }
}
