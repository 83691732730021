import { Flex, Text, Separator, Box } from '@/design-system'
import { useTheme } from 'styled-components'

interface ApplicationSectionHeadingProps {
    children: React.ReactNode
}

const ApplicationSectionHeading: React.FC<ApplicationSectionHeadingProps> = (props) => {
    const { children } = props

    const theme = useTheme()
    const isDark = theme.name === 'darkTheme'

    const seperatorShade = isDark ? '700' : '200'

    return (
        <Box mb="1">
            <Flex gap="3" direction="column">
                <Text weight="medium" shade="500">
                    {children}
                </Text>
                <Separator color="neutral" shade={seperatorShade} />
            </Flex>
        </Box>
    )
}

export { ApplicationSectionHeading }
