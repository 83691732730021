import { SearchResult, SearchState } from './types'

export const SEARCH_SHOW = 'SEARCH_SHOW'
export const SEARCH_HIDE = 'SEARCH_HIDE'
export const SEARCH_CLEAR_RESULTS = 'SEARCH_CLEAR_RESULTS'
export const SEARCH_FIND_RESULTS = 'SEARCH_FIND_RESULTS'
export const SEARCH_STORE_RESULTS = 'SEARCH_STORE_RESULTS'
export const SEARCH_SET_DISABLED = 'SEARCH_SET_DISABLED'

//-

export interface SearchActionShow {
    type: typeof SEARCH_SHOW
}
export interface SearchActionHide {
    type: typeof SEARCH_HIDE
}
export interface SearchActionFindResults {
    type: typeof SEARCH_FIND_RESULTS
    query: string
    page?: number
    perPage?: number
}
export interface SearchActionClearResults {
    type: typeof SEARCH_CLEAR_RESULTS
}
export interface SearchActionStoreResults {
    type: typeof SEARCH_STORE_RESULTS
    results: SearchResult[]
    pagination: SearchState['results']['pagination']
}
export interface SearchActionSetDisabled {
    type: typeof SEARCH_SET_DISABLED
    disabled: boolean
}

//-

export const SearchDispatchShow = (): SearchActionShow => {
    return { type: SEARCH_SHOW }
}

export const SearchDispatchHide = (): SearchActionHide => {
    return { type: SEARCH_HIDE }
}

export const SearchDispatchFindResults = (
    query: string,
    page?: number,
    perPage?: number
): SearchActionFindResults => {
    return { type: SEARCH_FIND_RESULTS, query, page, perPage }
}

export const SearchDispatchStoreResults = (
    results: SearchResult[],
    pagination: SearchState['results']['pagination']
): SearchActionStoreResults => {
    return { type: SEARCH_STORE_RESULTS, results, pagination }
}

export const SearchDispatchClearResults = (): SearchActionClearResults => {
    return { type: SEARCH_CLEAR_RESULTS }
}

export const SearchDispatchSetDisabled = (
    disabled: boolean
): SearchActionSetDisabled => {
    return { type: SEARCH_SET_DISABLED, disabled }
}

export interface SagasForSearch {
    findResults(action: SearchActionFindResults): void
}

export type ReducedSearchActions =
    | SearchActionShow
    | SearchActionHide
    | SearchActionStoreResults
    | SearchActionClearResults
    | SearchActionFindResults
    | SearchActionSetDisabled
