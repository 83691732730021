import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { SimpleButton } from '../components/buttons/simpleButton'
import { Spacer } from '../components/layout/spacer'
import { AuthDispatchLogin } from '../store/auth/actions'
import { ButtonInset } from '../components/buttons/buttonInset'
import { RootState } from '@/store'
import { LoaderView } from '../components/loaders/loader'
import { Icon } from '../components/icons/icon'
import { Text } from '../components/general/text'
import { Flex } from '../components/layout/flex'
import { ButtonLoadingCover } from '../components/buttons/buttonLoadingCover'
import { useState } from 'react'

const Login: React.FC = () => {
    const dispatch = useDispatch()
    const isAuthInProgress = useSelector((state: RootState) => state.auth.inProgress)
    const [clicked, setClicked] = useState<string | undefined>(undefined)
    if (isAuthInProgress) return <LoaderView overBackground="front.background" />

    return (
        <WelcomeWrapper className="bg-secondary">
            <Positioner>
                <Deco>CNS</Deco>
                <Font>Clearhaus Console</Font>
                <Spacer width={30} />
                <Flex>
                    <SimpleButton
                        background="front.accent.color"
                        isDisabled={clicked === 'microsoft'}
                        onClick={() => {
                            setClicked('google')
                            dispatch(AuthDispatchLogin('google'))
                        }}
                    >
                        <ButtonInset padding="medium">
                            <ButtonLoadingCover
                                shouldDisplayDirectly={clicked === 'google'}
                                background="front.accent.color"
                            >
                                <Flex>
                                    <Text>Sign in with</Text>
                                    <IconHolder smaller>
                                        <Icon type="google" size={15} />
                                    </IconHolder>
                                    <Text bold>Google</Text>
                                </Flex>
                            </ButtonLoadingCover>
                        </ButtonInset>
                    </SimpleButton>
                    <Spacer width={10} />
                    <SimpleButton
                        background="front.danger.color"
                        onClick={() => {
                            setClicked('microsoft')
                            dispatch(AuthDispatchLogin('microsoft'))
                        }}
                        isDisabled={clicked === 'google'}
                    >
                        <ButtonInset padding="medium">
                            <ButtonLoadingCover
                                shouldDisplayDirectly={clicked === 'microsoft'}
                                background="front.danger.color"
                            >
                                <Flex>
                                    <Text>Sign in with</Text>
                                    <IconHolder>
                                        <Icon type="microsoft" size={14} />
                                    </IconHolder>
                                    <Text bold>Microsoft</Text>
                                </Flex>
                            </ButtonLoadingCover>
                        </ButtonInset>
                    </SimpleButton>
                </Flex>
            </Positioner>
        </WelcomeWrapper>
    )
}

const IconHolder = styled.div<{ smaller?: boolean }>`
    margin-left: 7px;
    margin-right: 4px;
    position: relative;
    top: 1.5px;

    ${(p) =>
        p.smaller &&
        css`
            margin-left: 6px;
            margin-right: 3px;
        `}
`

const WelcomeWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    background: ${(p) => p.theme['back.background']};
`

const Positioner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 22vh;
`

const Font = styled.div`
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.1px;
    color: ${(p) => p.theme['front.text']};
`

const Deco = styled.div`
    font-size: 30vw;
    position: absolute;
    top: 42vh;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    opacity: 0.15;
    -webkit-text-stroke-color: ${(p) => p.theme['front.text']};
    font-weight: bold;
    letter-spacing: -0.1px;
`

export default Login
