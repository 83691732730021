import { useReconcilePosting } from '@/services/reconciliation'
import { useFormContext } from 'react-hook-form'
import { useReconciliationParams } from '.'

export const useReconcilePostingActions = () => {
    const { id } = useReconciliationParams()

    const [reconcilePosting, reconciliationResult] = useReconcilePosting()

    const isLoading = reconciliationResult.isLoading
    const methods = useFormContext()

    const reconcile = async () => {
        // create reconcilication
        const createPostingPayload = {
            postingId: id,
            data: {
                voucher_ids: methods.getValues('voucher_ids'),
                note: methods.getValues('note')
            }
        }

        const reconciliation = await reconcilePosting(createPostingPayload).unwrap()

        // Reset form data
        methods.reset()

        return { reconciliation }
    }

    return { isLoading, reconcile }
}
