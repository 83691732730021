import { useDeleteVoucher } from '@/services/reconciliation'
import { useReconciliationParams } from '.'
import { useNavigate } from 'react-router-dom'

export const useDeleteVoucherActions = () => {
    const { id } = useReconciliationParams()
    const navigate = useNavigate()

    const [deleteVoucherAction, { isLoading }] = useDeleteVoucher()

    const deleteVoucher = async (reason: string) => {
        const createDeletionPayload = {
            voucherId: id,
            data: {
                reason
            }
        }

        await deleteVoucherAction(createDeletionPayload)

        navigate('/more/reconciliation-vouchers')
    }

    return { isLoading, deleteVoucher }
}
