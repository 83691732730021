import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { ColorTag, regularColorHash } from '../../components/general/colorTag'
import { Expander } from '../../components/layout/expander'

export const TagsLister: React.FC<{
    tags: string[]
    makeSubtle?: boolean
    colorForTag?: { [tag: string]: string }
    limit?: number
    shouldWrap?: boolean
    spotless?: boolean
    extensive?: boolean
    overBackground: React.ComponentProps<typeof ColorTag>['overBackground']
    insetButton?: boolean
}> = ({
    tags,
    overBackground,
    spotless,
    extensive,
    colorForTag,
    makeSubtle,
    limit = 2,
    shouldWrap = false,
    insetButton
}) => {
    const tagNodes = useMemo(() => {
        const newTags = tags
            .sort((a: string, b: string) => {
                if (a.toLowerCase() === 'important') return -1
                return 1
            })
            .map((tag: any, index) => (
                <TagWrapper shouldWrap={shouldWrap} key={`merchant-tag-${tag}`}>
                    {tag === 'important' ? (
                        <ImportantTag>Important</ImportantTag>
                    ) : (
                        <ColorTag
                            extensive={extensive}
                            spotless={spotless}
                            name={tag}
                            overBackground={index >= limit ? 'floating.background' : overBackground}
                            subtle={index < limit && makeSubtle}
                            light={index >= limit}
                            color={colorForTag?.[tag] || regularColorHash.hex(tag)}
                        />
                    )}
                </TagWrapper>
            ))

        return {
            shownTags: newTags.filter((t, i) => i < limit),
            otherTags: newTags.filter((t, i) => i >= limit)
        }
    }, [tags, spotless, colorForTag, limit, extensive, makeSubtle, overBackground, shouldWrap])

    const otherTags = useMemo(() => {
        return <Column>{tagNodes.otherTags}</Column>
    }, [tagNodes.otherTags])

    const buttonTextColor = useMemo(() => {
        return 'front.accent.color' as const
    }, [overBackground])

    if (!tagNodes.shownTags || tagNodes.shownTags.length === 0) return <></>

    return (
        <Holder shouldWrap={shouldWrap}>
            {tagNodes.shownTags}
            <TagWrapper shouldWrap={shouldWrap}>
                <Expander
                    count={tagNodes.otherTags.length}
                    textColor={buttonTextColor}
                    listMemo={otherTags}
                    insetButton={insetButton}
                />
            </TagWrapper>
        </Holder>
    )
}

const Column = styled.div`
    display: grid;
    grid-row-gap: 3px;
`

const Holder = styled.div<{ shouldWrap?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: -1px;

    ${(p) =>
        p.shouldWrap
            ? css`
                  flex-wrap: wrap;
              `
            : css`
                  flex-wrap: nowrap;
              `}
`

const TagWrapper = styled.div<{ shouldWrap?: boolean }>`
    ${(p) =>
        p.shouldWrap &&
        css`
            line-height: 15px;
            margin-top: 3px;
            margin-bottom: 3px;
        `}
`

const ImportantTag = styled.div`
    padding: 0 6px;
    background-color: ${(p) => p.theme['front.subtleDanger.background']};
    border-radius: 5px;
    margin-right: 6px;
    color: ${(p) => p.theme['front.danger.color']};
`
