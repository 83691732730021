import { snakeCase } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Spinner } from '../../../components/loaders/spinner'
import { Icon } from '../../../components/icons/icon'
import { MerchantAccount } from '../../../store/merchantAccounts/types'
import { useFetchContractPreview } from './useFetchContractPreview'
import { MerchantPageUISelectedContract } from './utils'

export const MerchantAccountContractDownloadMaker: React.FC<{
    selectedContract: MerchantPageUISelectedContract
    account: MerchantAccount
    onComplete: () => void
}> = ({ selectedContract, account, onComplete }) => {
    const preview = useFetchContractPreview(
        account.id,
        selectedContract?.isDraft ? 'draft' : selectedContract?.contract.id
    )

    useEffect(() => {
        if (preview.loadingStatus === 'done') {
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.style.display = 'none'
            a.href = preview.pdf
            a.download = `${snakeCase(account.name)}-${account.merchantId}-Clearhaus-Merchant-Agreement${
                selectedContract?.isDraft
                    ? '-DRAFT'
                    : `-${moment(selectedContract?.contract?.metadata?.createdAt).format('YYYY-MM-DD')}`
            }`
            a.click()
            window.URL.revokeObjectURL(preview.pdf)
            onComplete()
        }
    }, [preview, onComplete, account, selectedContract])

    return <></>
}

export const MerchantAccountContractDownload: React.FC<{
    account: MerchantAccount
    selectedContract: MerchantPageUISelectedContract
}> = ({ account, selectedContract }) => {
    const [shouldStartDownload, setShouldStartDownload] = useState(false)

    const onComplete = useCallback(() => {
        setShouldStartDownload(false)
    }, [])

    return (
        <>
            {shouldStartDownload && (
                <MerchantAccountContractDownloadMaker
                    onComplete={onComplete}
                    account={account}
                    selectedContract={selectedContract}
                />
            )}
            <SimpleButton
                background="front.background"
                cy="download"
                hotkeysScope="Merchant.AccountsPage.Contract"
                hotkeys="alt+d"
                onClick={() => {
                    setShouldStartDownload(true)
                }}
            >
                <ButtonInset equalPadding>
                    <IconHolder>
                        {shouldStartDownload ? (
                            <Spinner type="s" overBackground="front.background" />
                        ) : (
                            <Icon type="download" size={12} color="front.accent.color" />
                        )}
                    </IconHolder>
                </ButtonInset>
            </SimpleButton>
        </>
    )
}

const IconHolder = styled.div`
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
`
