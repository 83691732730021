import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { getNodeText } from '../../store/utils'
import { TextInput } from '../forms/textInput'
import { TableColHeader, TableRow } from '../tables/table'

export const TableFuzzySearch: React.FC<{
    headers: TableColHeader[]
    rows: TableRow[]
    ignoreHeaderWithText: string
    queryParamName: string
    setFilteredRows: (rows: TableRow[]) => void
}> = ({ headers, rows, queryParamName, ignoreHeaderWithText, setFilteredRows }) => {
    const ref = useRef<any>()
    const params = useMemo(() => {
        return [queryParamName]
    }, [queryParamName])
    const [query, setQuery] = useQueryParams(params, undefined)
    const [filteringValue, setFilteringValue] = useState(query[queryParamName])

    useEffect(() => {
        setFilteredRows(rows)
    }, [rows, setFilteredRows])

    useEffect(() => {
        setFilteringValue(query[queryParamName])
    }, [query, queryParamName])

    useEffect(() => {
        if (!filteringValue) {
            setFilteredRows(rows)
            return
        }
        setFilteredRows(
            rows.filter((r) => {
                const words = filteringValue.split(' ')
                let foundMatch = false

                for (const word of words) {
                    for (const [i, item] of r.items.entries()) {
                        if (ignoreHeaderWithText === headers[i].text) continue
                        if (getNodeText(item?.node)?.toLowerCase().includes(word?.toLowerCase())) foundMatch = true
                    }
                    if (foundMatch === false) return false
                    foundMatch = false
                }

                return true
            })
        )
    }, [rows, filteringValue, headers, ignoreHeaderWithText, setFilteredRows])

    const handleType = useCallback(
        (e, change) => {
            setQuery({
                [queryParamName]: change || undefined
            })
            setFilteringValue(change)
        },
        [setFilteringValue, setQuery, queryParamName]
    )

    useEffect(() => {
        ref.current.focus()
    }, [])

    return (
        <TextInput
            placeholder="Smart table search"
            initialValue={filteringValue}
            forceValue={filteringValue}
            overBackground="back.background"
            cy="fuzzy"
            onChange={handleType}
            ref={ref}
            showClearButton
        />
    )
}
