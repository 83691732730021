import { useFormContext } from 'react-hook-form'

export const useCommentsStatus = () => {
    const { watch } = useFormContext()

    const watchReviewConfirmation: string = watch('review_confirmation')
    const watchComment: string = watch('comment')

    const reviewConfirmationCompleted = watchReviewConfirmation === 'true' ? 1 : 0
    const commentCompleted = watchComment !== '' ? 1 : 0

    return reviewConfirmationCompleted + commentCompleted
}
