export type BVDUISize = 'half' | 'mini' | 'full'

export type BVDState = {
    [applicationId: string]: {
        bvd?: string
        size: BVDUISize
    }
}

export const InitialBVDState: BVDState = {}
