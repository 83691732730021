import { CollectionWithPagination, CollectionWSeparateLoading, LoadingStatus, Pagination } from '../../utils'
import { File } from '../files/types'
import { TransactionStatus } from '../transactions/commonTypes'

export const DisputeReasons = [
    'product_not_provided',
    'general',
    'unrecognised',
    'incorrect_amount_or_currency',
    'duplicate',
    'credit_not_processed',
    'product_unacceptable',
    'fraud',
    'subscription_cancelled'
]
export type DisputeReason = typeof DisputeReasons[number]

export const DisputeTypes = [
    'retrieval_request',
    'rapid_dispute_resolution',
    '1st_chargeback',
    '1st_chargeback_reversal',
    '2nd_chargeback'
]
export type DisputeType = typeof DisputeTypes[number]

export const DisputeStatuses = [
    'open',
    'refuted',
    'under_review_merchant',
    'under_review_issuer',
    'closed',
    'lost',
    'won'
]
export type DisputeStatus = typeof DisputeStatuses[number]

export interface DisputesFilters {
    disputes_custom_filters: string
    disputes_status: DisputeStatus[]
    disputes_reason: DisputeReason[]
    disputes_type: string[]
    disputes_mid: string
    disputes_account: string
    disputes_reference: string
    disputes_page: number
    disputes_per_page: number
    disputes_after: string
    disputes_before: string
    dql: string
}

export const DisputeFiltersParams: Partial<DisputesFilters> = {
    disputes_status: [],
    disputes_reason: [],
    disputes_type: [],
    disputes_mid: '',
    disputes_account: '',
    disputes_reference: '',
    disputes_after: '',
    disputes_before: '',
    disputes_page: 0,
    disputes_per_page: 0,
    disputes_custom_filters: '',
    dql: ''
}

export interface DisputeSummary {
    id: string
    reference: string
    amount: number
    currency: string
    partial: boolean
    openedAt: string
    dueAt?: string
    expiresAt?: string
    status: DisputeStatus
    reasonCode: string
    reason: DisputeReason
    type: DisputeType
    accountId: string
    account: {
        merchantId: string
        name: string
        country: string
        mcc: string
        currency: string
        descriptor: string
    }
    events: {
        date: string
        event: string
        reasonCode: string
        reason: DisputeReason
        amount?: number
        currency?: string
    }[]
    transaction: {
        id: string
        type: string
        processedAt: string
        amount: number
        currency: string
        textOnStatement: string
        reference: string
        threedSecure: string
        recurring: true
        paymentMethod: string
        status: TransactionStatus
        accountLink: string
    }
    loadingStatus: LoadingStatus
}

export interface DisputeStamp {
    href: string
    name: string
}

export interface Dispute extends DisputeSummary {
    selfLink: string
    filesLink: string
    commentsLink: string
    comments: DisputeComment[]
    commentsNextLink: string
    stampsLink: DisputeStamp[]
    files: File[]
    filesNextLink: string
    loadingNextStatus: LoadingStatus
    extraDetails: {
        account: any
        application: any
        loadingStatus: LoadingStatus
    }
}

export interface DisputesState {
    disputes: CollectionWSeparateLoading<Dispute>
    disputesSummaries: CollectionWithPagination<DisputeSummary, Pagination>
}

export interface DisputeComment {
    id: string
    createdAt: string
    tags: string[]
    body: string
    selfLink: string
    author: {
        name: string
        email: string
    }
}

export const InitialDisputesState: DisputesState = {
    disputes: {
        at: {},
        all: []
    },
    disputesSummaries: {
        at: {},
        all: [],
        pagination: undefined,
        loadingStatus: 'not-started'
    }
}
