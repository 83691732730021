export interface ApplicationLoadingState {
    at: {
        [key: string]: {
            hasSidebarLoaded?: boolean
            haveInternalsLoaded?: boolean
            relatedApplicationsLoaded?: boolean
            tasksCount?: number
            fieldWithMostComments?: {
                fieldKey: string
                subsectionId?: string
            }
            sections: {
                [key: string]: {
                    data?: boolean
                    files?: boolean
                    internal?: boolean
                }
            }
        }
    }
}

export const InitialApplicationLoadingState: ApplicationLoadingState = {
    at: {}
}
