import { Url } from '@/services/common'
import { api } from '../api'
import { Partner, PartnerData, Partners } from '../types/partners'

export const MAPI_PARTNERS_ROOT = `${import.meta.env.VITE_API_ROOT}/partners`

export const partnersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPartners: build.query<Partners, Url>({
            query: (url) => {
                return { url }
            },
            transformResponse: (response: Partners) => {
                if (response.embedded['ch:partners'] && !Array.isArray(response.embedded['ch:partners'])) {
                    response.embedded['ch:partners'] = [response.embedded['ch:partners']]
                }

                return response
            },
            providesTags: ['partners']
        }),
        getPartner: build.query<Partner, Url>({
            query: (url) => {
                return { url }
            },
            providesTags: ['partner']
        }),
        createPartner: build.mutation<Partner, { url: Url; data: Partial<PartnerData> }>({
            query: ({ url, data }) => ({
                url,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['partners']
        }),
        updatePartner: build.mutation<Partner, { url: Url; data: Partial<Partner> }>({
            query: ({ url, data }) => ({
                url,
                body: data,
                method: 'PATCH'
            }),
            invalidatesTags: ['partners', 'partner']
        }),
        deletePartner: build.mutation<Partner, Url>({
            query: (url) => ({
                url,
                method: 'DELETE'
            }),
            invalidatesTags: ['partners', 'partner']
        })
    })
})

export const {
    useGetPartnerQuery: useGetPartner,
    useGetPartnersQuery: useGetPartners,
    useCreatePartnerMutation: useCreatePartner,
    useUpdatePartnerMutation: useUpdatePartner,
    useDeletePartnerMutation: useDeletePartner
} = partnersApi

export const {
    endpoints: { getPartners, getPartner, createPartner, updatePartner, deletePartner }
} = partnersApi
