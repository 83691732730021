import React, { useCallback } from 'react'

import { TextInputSelect } from '../../../components/forms/textInputSelect'
import {
    ApplicationResourceCompanyOwnershipStructure,
    MerchantApplicationResourceIndexPath
} from '../../../store/applicationResources/types'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import * as yup from 'yup'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { Color } from '../../../styles/colors'

const options = Object.keys(ApplicationResourceCompanyOwnershipStructure) as Array<
    keyof typeof ApplicationResourceCompanyOwnershipStructure
>

export const MerchantApplicationEditsModalInputOwnershipStructure: React.FC<{
    preSelected?: string
    onSelect?: (item: string) => void
    onBlur: (e: any) => void
    indexPath: MerchantApplicationResourceIndexPath
    validation: ValidationStatus
    overBackground: Color
    formRef: MerchantApplicationFieldFormProps['formRef']
}> = ({ preSelected, validation, overBackground, onBlur, indexPath, formRef }) => {
    // useEffect(() => {
    //     if (selectedStructure) onSelect(selectedStructure)
    // }, [onSelect, selectedStructure])

    const textForItem = useCallback((item) => {
        return ApplicationResourceCompanyOwnershipStructure[
            item as keyof typeof ApplicationResourceCompanyOwnershipStructure
        ]
    }, [])

    return (
        <TextInputSelect
            placeholder="Select an ownership structure"
            ref={(ref) => {
                formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
            }}
            noClear
            textareaBased
            isSeamless
            validation={validation}
            onBlur={onBlur}
            overBackground={overBackground}
            selected={preSelected || ''}
            dropdownId="GenericDropdown"
            textForItem={textForItem}
            items={options}
        />
    )
}
