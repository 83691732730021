import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export interface MerchantApplicationEdits {
    at: string
    by: string
    edits: number
}

export const useMerchantApplicationEditsMade = (applicationId: string) => {
    const tl = useSelector((state: RootState) => state.merchantTimeline[applicationId])

    const edits = useMemo((): MerchantApplicationEdits[] => {
        if (!tl?.all?.length) return []
        return tl.all
            .filter((id) => tl.at[id].type === 'field-edits')
            .map((id: any) => {
                const t = tl.at[id]
                return {
                    at: t.at,
                    by: t.by,
                    edits: t.edits?.length
                }
            })
    }, [tl])

    return edits
}
