import countries from 'i18n-iso-countries'

export const SchemesScreeningCountry2LetterCode = (type: 'VMSS' | 'MATCH', country: string) => {
    return type == 'MATCH'
        ? country?.length == 3
            ? countries.alpha3ToAlpha2(country?.toUpperCase())
            : countries.getAlpha2Code(country?.toUpperCase(), 'en')
        : country?.toUpperCase()
}

export const SchemesScreeningCountryName = (type: 'VMSS' | 'MATCH', country: string) => {
    return SchemesScreeningCountry2LetterCode(type, country)
}
