import React from 'react'
import * as HoverCardPrimitive from '@radix-ui/react-hover-card'
import { styles } from './styles'
import { Card } from '../card'

interface HoverCardProps {
    children: React.ReactNode
    defaultOpen?: boolean
}

const HoverCard = (props: HoverCardProps) => {
    const { children, defaultOpen } = props

    return (
        <HoverCardPrimitive.Root defaultOpen={defaultOpen} openDelay={0} closeDelay={50}>
            {children}
        </HoverCardPrimitive.Root>
    )
}

interface TriggerProps {
    children: React.ReactNode
}

const Trigger: React.FC<TriggerProps> = (props) => {
    const { children } = props

    return <HoverCardPrimitive.Trigger>{children}</HoverCardPrimitive.Trigger>
}

interface ContentProps {
    children: React.ReactNode
    align?: 'start' | 'center' | 'end'
}

const Content = React.forwardRef<React.ElementRef<typeof HoverCardPrimitive.Content>, ContentProps>((props, ref) => {
    const { children, align = 'center' } = props

    const classnames = styles.content()

    return (
        <HoverCardPrimitive.Portal>
            <HoverCardPrimitive.Content ref={ref} className={classnames} align={align} sideOffset={4}>
                <Card size="sm">
                    <Card.Body>{children}</Card.Body>
                </Card>
            </HoverCardPrimitive.Content>
        </HoverCardPrimitive.Portal>
    )
})
Content.displayName = 'HoverCard.Content'

HoverCard.Trigger = Trigger
HoverCard.Content = Content

export { HoverCard }
