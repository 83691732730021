import { Flex, Table, Text } from '@/design-system'
import React from 'react'
import { AccountDetails, Date } from '../../common'
import { getAccount } from '../../../helpers'
import { Account, Voucher } from '@/services/reconciliation'
import { TableBubble } from '@/components/layout/tableBubble'
import { numberWithCurrency } from '@/utils'
import { TruncatedText } from '@/ui/truncated-text'

interface SingleVoucherTableProps {
    voucher: Voucher
    accounts: Account[]
}

const SingleVoucherTable: React.FC<SingleVoucherTableProps> = (props) => {
    const { voucher, accounts } = props

    return (
        <Table variant="default" layout="fixed">
            <Table.Header>
                <Table.Row>
                    <Table.Head width="2/12">Account</Table.Head>
                    <Table.Head>Booking date</Table.Head>
                    <Table.Head>Payment date</Table.Head>
                    <Table.Head width="2/12">Descriptor</Table.Head>
                    <Table.Head width="2/12">Reference</Table.Head>
                    <Table.Head>Posting type</Table.Head>
                    <Table.Head>Voucher type</Table.Head>
                    <Table.Head justify="end">Amount</Table.Head>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <AccountDetails account={getAccount(voucher.account_id, accounts)} />
                    </Table.Cell>
                    <Table.Cell>
                        <Date>{voucher.booking_date ? voucher.booking_date : '-'}</Date>
                    </Table.Cell>
                    <Table.Cell>
                        <Date>{voucher.payment_date ? voucher.payment_date : '-'}</Date>
                    </Table.Cell>
                    <Table.Cell>
                        <TruncatedText>{voucher.descriptor ? voucher.descriptor : '-'}</TruncatedText>
                    </Table.Cell>
                    <Table.Cell>
                        <TruncatedText>{voucher.reference ? voucher.reference : '-'}</TruncatedText>
                    </Table.Cell>
                    <Table.Cell>
                        <Text>{voucher.posting_type ? voucher.posting_type : '-'}</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <Text>{voucher.voucher_type ? voucher.voucher_type : '-'}</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <Flex justify="end">
                            <TableBubble type="important">
                                {numberWithCurrency(voucher.currency, `${voucher.amount}`)} {voucher.currency}
                            </TableBubble>
                        </Flex>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}

export { SingleVoucherTable }
