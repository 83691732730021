import { camelCase } from 'lodash'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { SubsectionRemover } from './Application.InternalSubsectionRemover'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { MerchantApplicationAddScreenshot } from './Application.AddScreenshotButton'
import { MerchantApplicationInternalFile } from './Application.InternalFile'
import { MerchantApplicationInternalDetailsGrid } from './Application.InternalDetailsGrid'
import { MerchantApplicationInternalDetailsShowMore } from './Application.InternalDetailsShowMore'
import { MerchantApplicationInternalLink } from './Application.InternalLink'
import { MerchantApplicationInternalValue } from './Application.InternalValue'
import { MerchantApplicationWebsiteScreenshotsButton } from './Application.WebsiteScreenshotsButton'
import {
    IMerchantApplicationSectionStructure,
    IMerchantApplicationSubsectionStructure,
    TMerchantApplicationResourceKey
} from './Application.StructureTypes'
import { useHaveApplicationInternalDetailsLoaded } from './useHaveApplicationInternalDetailsLoaded'
import { MerchantApplicationOwnershipReport } from '../MerchantApplicationOwnershipReport'
import { InternalDetailsSeparator } from './Application.InternalDetails.Separator'

export const MerchantApplicationInternalDetails: React.FC<{
    subsectionIndex?: number
    applicationId: string
    subsection?: boolean
    sectionStructure: IMerchantApplicationSectionStructure | IMerchantApplicationSubsectionStructure
    isFirstSubsection?: boolean
}> = ({ sectionStructure, subsectionIndex, applicationId, subsection, isFirstSubsection }) => {
    const [visibilityState, setVisibilityState] = useState({
        company: false,
        websites: false,
        people: false
    })
    const shouldShow = useHaveApplicationInternalDetailsLoaded(applicationId)
    const addScreenshotMemo = useMemo(() => {
        return <MerchantApplicationAddScreenshot subsectionIndex={subsectionIndex || 0} applicationId={applicationId} />
    }, [applicationId, subsectionIndex])
    const [hoverRef, setHoverRef] = useRefTaker()
    const isSectionLoaded = useHaveApplicationInternalDetailsLoaded(applicationId)

    const sectionContent = useMemo(() => {
        const getIndexPath = (
            resourceKey: TMerchantApplicationResourceKey,
            fieldKey: string
        ): MerchantApplicationResourceIndexPath => {
            let lookoutSuffix
            switch (fieldKey) {
                case 'extraReport1': {
                    lookoutSuffix = "number"
                    break
                }
                case 'googleReport': {
                    lookoutSuffix = resourceKey
                    break
                }
                case 'extraReport2': {
                    lookoutSuffix = "trustpilot"
                    break
                }
                case 'dnsReport': {
                    lookoutSuffix = "whois"
                    break
                }
                default: {
                    lookoutSuffix = undefined
                }
            }
            return {
                resourceKey,
                fieldKey,
                subsectionIndex: subsectionIndex || 0,
                reportType: lookoutSuffix,
            }
        }
        switch (sectionStructure.internalDetails) {
            case 'companyInternalDetails':
                return (
                    <Flex fullWidth column align="stretch">
                        <Card background="back.background.strongerI" shadowLess>
                            <CardInset>
                                <SectionHolder>
                                    {/* Old HARVESTER (commented out due to service being shut down) */}
                                    {/* <HarvesterBox
                                        sectionKey="company"
                                        subsectionIndex={subsectionIndex || 0}
                                        applicationId={applicationId}
                                    /> */}
                                    <MerchantApplicationInternalDetailsGrid>
                                        <MerchantApplicationInternalFile
                                            label="Company reg."
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('company', 'extraReport1')}
                                            isLoading={!isSectionLoaded}
                                        />

                                        <MerchantApplicationInternalFile
                                            label="Google"
                                            applicationId={applicationId}
                                            link
                                            indexPath={getIndexPath('company', 'googleReport')}
                                            isLoading={!isSectionLoaded}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="Trustpilot"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('company', 'extraReport2')}
                                        />

                                        <MerchantApplicationInternalFile
                                            label="VMSS report"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('company', 'vmasReport')}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="Match report"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('company', 'matchReport')}
                                        />
                                        <MerchantApplicationInternalLink
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('company', '-')}
                                            isLoading={!isSectionLoaded}
                                            label={'Google maps'}
                                        />
                                        <MerchantApplicationInternalLink
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('company', '-')}
                                            isLoading={!isSectionLoaded}
                                            label={'Register link'}
                                        />
                                        {/* <MerchantApplicationInternalFile
                                        label="Subscription terms"
                                        applicationId={applicationId}
                                        indexPath={getIndexPath('company', 'subscriptionTermsCapture')}
                                        visibilityState={visibilityState}
                                        visibilitySection="company"
                                    />
                                    <MerchantApplicationInternalFile
                                        label="Extra report"
                                        applicationId={applicationId}
                                        indexPath={getIndexPath('company', 'extraReport3')}
                                        visibilityState={visibilityState}
                                        visibilitySection="company"
                                    />
                                    <MerchantApplicationInternalFile
                                        label="G2 report"
                                        applicationId={applicationId}
                                        indexPath={getIndexPath('company', 'g2Report')}
                                        visibilityState={visibilityState}
                                        visibilitySection="company"
                                    />
                                    <MerchantApplicationInternalFile
                                        label="Credit report"
                                        applicationId={applicationId}
                                        indexPath={getIndexPath('company', 'creditReport')}
                                        visibilityState={visibilityState}
                                        visibilitySection="company"
                                    />
                                    <MerchantApplicationInternalFile
                                        label="World compliance"
                                        applicationId={applicationId}
                                        indexPath={getIndexPath('company', 'worldCompliance')}
                                        visibilityState={visibilityState}
                                        visibilitySection="company"
                                    />
                                    <MerchantApplicationInternalFile
                                        label="OFAC Report"
                                        applicationId={applicationId}
                                        indexPath={getIndexPath('company', 'ofacReport')}
                                        visibilityState={visibilityState}
                                        visibilitySection="company"
                                    />
                                    <MerchantApplicationInternalDetailsShowMore
                                        count={8}
                                        visibilityState={visibilityState}
                                        setVisibilityState={setVisibilityState}
                                        visibilitySection="company"
                                    /> */}
                                        {/* TO_DO */}
                                        {/* Old HARVESTER (commented out due to service being shut down) */}
                                        {/* {isSectionLoaded ? (
                                            <HarvesterExternalLinks
                                                applicationId={applicationId}
                                                sectionKey="company"
                                                subsectionIndex={subsectionIndex || 0}
                                            />
                                        ) : null} */}
                                    </MerchantApplicationInternalDetailsGrid>
                                </SectionHolder>
                            </CardInset>
                        </Card>
                    </Flex>
                )
            case 'businessModelInternalDetails':
                return (
                    <Flex fullWidth column align="stretch">
                        <Card background="back.background.strongerI" shadowLess>
                            <CardInset>
                                <SectionHolder>
                                    <MerchantApplicationInternalDetailsGrid>
                                        <MerchantApplicationInternalValue
                                            type="shortBusinessModel"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('businessModel', '')}
                                        />
                                        <MerchantApplicationInternalValue
                                            type="marketplaceId"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('businessModel', '')}
                                        />
                                        {/* <MerchantApplicationInternalValue
                                            type="longBusinessModel"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('businessModel', '')}
                                        /> */}
                                    </MerchantApplicationInternalDetailsGrid>
                                </SectionHolder>
                            </CardInset>
                        </Card>
                    </Flex>
                )
            case 'websiteInternalDetails':
                return (
                    <Flex fullWidth column align="stretch" ref={setHoverRef}>
                        <Card background="back.background.strongerI" shadowLess>
                            <CardInset>
                                <SectionHolder>
                                    {/* Old HARVESTER (commented out due to service being shut down) */}
                                    {/* {isSectionLoaded ? (
                                        <HarvesterBox
                                            sectionKey="websites"
                                            subsectionIndex={subsectionIndex || 0}
                                            applicationId={applicationId}
                                        />
                                    ) : null} */}

                                    <MerchantApplicationInternalDetailsGrid>
                                        <MerchantApplicationInternalFile
                                            label="WHOIS report"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('websites', 'dnsReport')}
                                        />
                                        <Text color="back.text">Screenshots</Text>
                                        <span></span>
                                        <MerchantApplicationWebsiteScreenshotsButton
                                            applicationId={applicationId}
                                            subsectionIndex={subsectionIndex || 0}
                                        />
                                        <MerchantApplicationAddScreenshot
                                            applicationId={applicationId}
                                            redirectToWebsites
                                            subsectionIndex={subsectionIndex || 0}
                                        />
                                        {/* <MerchantApplicationInternalFile
                                            label="Home page"
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('websites', 'homePageCapture')}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="Contact information"
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('websites', 'contactInformationCapture')}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="Product information"
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('websites', 'productInformationCapture')}
                                        />

                                        <MerchantApplicationInternalFile
                                            label="Terms and conditions"
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('websites', 'termsAndConditionsCapture')}
                                        /> */}
                                        <MerchantApplicationInternalLink
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('websites', '-')}
                                            isLoading={!isSectionLoaded}
                                            label={'Trustpilot'}
                                        />
                                        <SubsectionRemover
                                            hoverZoneRef={hoverRef}
                                            subsectionIndex={subsectionIndex || 0}
                                            suffix={'website'}
                                            subsectionStructure={
                                                sectionStructure as IMerchantApplicationSubsectionStructure
                                            }
                                            applicationId={applicationId}
                                        />
                                        {/* <MerchantApplicationInternalFile
                                label="Alex report"
                                applicationId={applicationId}
                                indexPath={getIndexPath('websites', 'alexaReport')}
                                visibilityState={visibilityState}
                                visibilitySection="websites"
                            />

                            <MerchantApplicationInternalFile
                                label="BuiltWith report"
                                applicationId={applicationId}
                                indexPath={getIndexPath('websites', 'builtwithReport')}
                                visibilityState={visibilityState}
                                visibilitySection="websites"
                            />

                            <MerchantApplicationInternalFile
                                label="Subscription terms"
                                applicationId={applicationId}
                                indexPath={getIndexPath('websites', 'subscriptionTermsCapture')}
                                visibilityState={visibilityState}
                                visibilitySection="websites"
                            />

                            <MerchantApplicationInternalFile
                                label="Refund policy"
                                applicationId={applicationId}
                                indexPath={getIndexPath('websites', 'refundPolicyCapture')}
                                visibilityState={visibilityState}
                                visibilitySection="websites"
                            />

                            <MerchantApplicationInternalFile
                                label="Privacy policy"
                                applicationId={applicationId}
                                indexPath={getIndexPath('websites', 'privacyPolicyCapture')}
                                visibilityState={visibilityState}
                                visibilitySection="websites"
                            />

                            <MerchantApplicationInternalFile
                                label="Shipping policy"
                                applicationId={applicationId}
                                indexPath={getIndexPath('websites', 'shippingPolicyCapture')}
                                visibilityState={visibilityState}
                                visibilitySection="websites"
                            />
                            <MerchantApplicationInternalDetailsShowMore
                                visibilityState={visibilityState}
                                count={6}
                                setVisibilityState={setVisibilityState}
                                visibilitySection="websites"
                                rightSide={addScreenshotMemo}
                            /> */}
                                    </MerchantApplicationInternalDetailsGrid>
                                </SectionHolder>
                            </CardInset>
                        </Card>
                    </Flex>
                )
            case 'websiteInternalDetailsHistory':
                return(
                    <Flex fullWidth column align="stretch" ref={setHoverRef}>
                        <Card background="back.background.strongerI" shadowLess>
                            <CardInset>
                                <SectionHolder>
                                    <MerchantApplicationInternalDetailsGrid>
                                        <MerchantApplicationInternalFile
                                            label="WHOIS report"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('websitesHistory', 'dnsReport')}
                                        />
                                    </MerchantApplicationInternalDetailsGrid>
                                </SectionHolder>
                            </CardInset>
                        </Card>
                    </Flex>
                )
            case 'bankAccountInternalDetails':
                return <></>
            case 'ownershipInternalDetails':
                return (
                    <Flex fullWidth column align="stretch">
                        <Card background="back.background.strongerI" shadowLess>
                            <CardInset>
                                <SectionHolder>
                                    <MerchantApplicationOwnershipReport applicationId={applicationId} />
                                    <MerchantApplicationInternalDetailsGrid>
                                        <MerchantApplicationInternalValue
                                            type="noteOwnershipStructure"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('businessModel', '')}
                                        />
                                        {/* {isSectionLoaded ? (
                                            <MerchantApplicationBvDInput applicationId={applicationId} />
                                        ) : null} */}
                                    </MerchantApplicationInternalDetailsGrid>
                                </SectionHolder>
                            </CardInset>
                        </Card>
                    </Flex>
                )
            case 'personInternalDetails':
                return (
                    <Flex fullWidth column align="stretch" ref={setHoverRef}>
                        <Card background="back.background.strongerI" shadowLess>
                            <CardInset>
                                <SectionHolder>
                                    <MerchantApplicationInternalDetailsGrid>
                                        <MerchantApplicationInternalValue
                                            type="notePerson"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('people', '')}
                                        />
                                        <MerchantApplicationInternalValue
                                            type="ownershipPercentage"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('people', '')}
                                        />
                                        <InternalDetailsSeparator />
                                        <MerchantApplicationInternalFile
                                            label="Google"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            link
                                            indexPath={getIndexPath('people', 'googleReport')}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="World compliance"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('people', 'worldCompliance')}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="Passport report"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('people', 'passportReport')}
                                            visibilityState={visibilityState}
                                            visibilitySection="people"
                                        />
                                        <MerchantApplicationInternalFile
                                            label="Credit report"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('people', 'creditReport')}
                                            visibilityState={visibilityState}
                                            visibilitySection="people"
                                        />
                                        <MerchantApplicationInternalFile
                                            label="OFAC report"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('people', 'ofacReport')}
                                            visibilityState={visibilityState}
                                            visibilitySection="people"
                                        />
                                        <MerchantApplicationInternalDetailsShowMore
                                            count={5}
                                            visibilityState={visibilityState}
                                            setVisibilityState={setVisibilityState}
                                            visibilitySection="people"
                                        />

                                        <SubsectionRemover
                                            hoverZoneRef={hoverRef}
                                            suffix={'person'}
                                            subsectionIndex={subsectionIndex || 0}
                                            subsectionStructure={
                                                sectionStructure as IMerchantApplicationSubsectionStructure
                                            }
                                            applicationId={applicationId}
                                        />
                                    </MerchantApplicationInternalDetailsGrid>
                                </SectionHolder>
                            </CardInset>
                        </Card>
                    </Flex>
                )
            case 'personInternalDetailsHistory':
                return (
                    <Flex fullWidth column align="stretch" ref={setHoverRef}>
                        <Card background="back.background.strongerI" shadowLess>
                            <CardInset>
                                <SectionHolder>
                                    <MerchantApplicationInternalDetailsGrid>
                                        <MerchantApplicationInternalFile
                                            label="Google"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            link
                                            indexPath={getIndexPath('peopleHistory', 'googleReport')}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="World compliance"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('peopleHistory', 'worldCompliance')}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="Passport report"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('peopleHistory', 'passportReport')}
                                            visibilityState={visibilityState}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="Credit report"
                                            applicationId={applicationId}
                                            isLoading={!isSectionLoaded}
                                            indexPath={getIndexPath('peopleHistory', 'creditReport')}
                                            visibilityState={visibilityState}
                                        />
                                        <MerchantApplicationInternalFile
                                            label="OFAC report"
                                            isLoading={!isSectionLoaded}
                                            applicationId={applicationId}
                                            indexPath={getIndexPath('peopleHistory', 'ofacReport')}
                                            visibilityState={visibilityState}
                                        />
                                    </MerchantApplicationInternalDetailsGrid>
                                </SectionHolder>
                            </CardInset>
                        </Card>
                    </Flex>
                )
            default:
                return <span />
        }
    }, [applicationId, subsectionIndex, hoverRef, setHoverRef, sectionStructure, isSectionLoaded, visibilityState])

    if (subsectionIndex !== undefined)
        return (
            <InternalSubsection
                data-cy={`${camelCase(sectionStructure.internalDetails)}-${subsectionIndex}`}
                isFirstSubsection={isFirstSubsection}
            >
                {sectionContent}
            </InternalSubsection>
        )
    return <Internal data-cy={camelCase(sectionStructure.internalDetails)}>{sectionContent}</Internal>
}

const Internal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
`

const SectionHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin-top: 2px;
`

const InternalSubsection = styled.div<{
    isFirstSubsection?: boolean
}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    position: relative;
`
