import React from 'react'
import styled, { css } from 'styled-components'
import { useHasScrolled } from '../../hooks/interface/useHasScrolled'

export const TopBar: React.FC<{
    leftSide?: any
    rightSide?: any
    title?: string
}> = ({ leftSide, rightSide, title }) => {
    const { isMoving, setRef } = useHasScrolled()

    return (
        <Bar isMoving={isMoving} ref={setRef}>
            {leftSide}
            <Title>{title}</Title>
            {rightSide}
        </Bar>
    )
}
const Title = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    color: ${(p) => p.theme['front.text']};
`

const Bar = styled.div<{ isMoving?: boolean }>`
    position: sticky;
    top: 0;
    left: 0;
    padding: 0px 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${(p) => p.theme['back.border']};
    box-sizing: border-box;
    background-color: transparent;
    z-index: 100;
    margin: 0;
    width: 100%;
    transition: 0.2s ease all;

    ${(p) =>
        p.isMoving &&
        css`
            background-color: ${p.theme['overlay.background']};
            border-bottom-color: ${p.theme['overlay.background.strongerI']};
        `}
`
