import React from 'react'
import styled, { css } from 'styled-components'

type PaddingType = 'regular' | 'smaller'
export const Highlight: React.FC<{
    children: React.ReactElement
    padding?: 'regular' | 'tiny' | 'smaller'
    verticalPadding?: PaddingType
    className?: string
}> = ({ children, padding = 'regular', verticalPadding = 'regular', className }) => {
    return (
        <HighlightZone padding={padding} verticalPadding={verticalPadding} className={className}>
            {children}
        </HighlightZone>
    )
}

const HighlightZone = styled.div<{
    padding?: 'regular' | 'tiny' | 'smaller'
    verticalPadding: PaddingType
}>`
    background-color: ${(p) => p.theme['front.highlights']};
    border-radius: 8px;
    padding: 20px 15px;
    margin: -20px -15px;

    ${(p) =>
        p.verticalPadding === 'smaller' &&
        css`
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: -10px;
            margin-bottom: -15px;
        `}

    ${(p) =>
        p.padding === 'tiny' &&
        css`
            padding: 5px 15px;
            margin: -5px -15px;
        `}
`
