import React from 'react'
import { SectionComment } from '@/services/ongoing-due-diligence'
import { Section } from '../../common/section'
import { Spacer } from '@/components/layout/spacer'
import { useFormContext } from 'react-hook-form'
import { SectionContainer } from '../section-container'
import { useReportSection } from '../../provider/report-provider/report-provider'

export const CommentsSection: React.FC = () => {
    const section = useReportSection<SectionComment>('comments')

    const methods = useFormContext()
    const values = methods.getValues(['review_confirmation', 'possibility_of_misconduct'])
    const [reviewConfirmation, possibilityOfMisconduct] = values

    const isReviewConfirmation = reviewConfirmation === 'true'
    const isPossibilityOfMisconduct = possibilityOfMisconduct === 'true'

    return (
        <SectionContainer title="Comments">
            <Section>
                <Section.Content>
                    <Section.Field name="review_confirmation" data={section.review_confirmation} />
                    <Section.Visible isVisible={isReviewConfirmation}>
                        <Section.Spacer />
                        <Section.Field name="no_suspecious_behaviour" data={section.no_suspecious_behaviour} />
                        <Section.Spacer />
                        <Section.Field name="possibility_of_misconduct" data={section.possibility_of_misconduct} />
                        <Section.Visible isVisible={isPossibilityOfMisconduct}>
                            <Section.Spacer />
                            <Section.Field name="aml_escalated" data={section.aml_escalated} />
                        </Section.Visible>
                    </Section.Visible>
                </Section.Content>
            </Section>
            <Spacer height={24} />
            <Section>
                <Section.Content>
                    <Section.Field name="comment" data={section.comment} />
                </Section.Content>
            </Section>
        </SectionContainer>
    )
}
