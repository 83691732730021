import { useState, useCallback } from 'react'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Text } from '../../components/general/text'
import { Floater } from '../../components/layout/floater'
import { FloaterInset } from '../../components/layout/floaterInset'
import { Grid } from '../../components/layout/grid'
import { Separator } from '../../components/layout/separator'
import { Spacer } from '../../components/layout/spacer'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { AvailableSettlementCurrencies } from '../../store/settlements/types'
import { useOnClickOutside } from '../../hooks/general/useOnClickOutside'
import { useDispatch } from 'react-redux'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { ContractTemplateActions } from '../../store/contractTemplates/actions'
import { useNavigate } from 'react-router-dom'

export const ContractTemplatesNewButton = () => {
    const [anchor, setAnchor] = useRefTaker()
    const [holder, setHolder] = useRefTaker()
    const [showFloater, setShowFloater] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSuccess = useCallback(
        (ctx) => {
            navigate({
                pathname: `/more/contract-templates/${ctx}`,
                search: location.search
            })
        },
        [navigate]
    )

    useOnClickOutside(
        holder,
        useCallback(() => {
            setShowFloater(false)
        }, [])
    )

    return (
        <div ref={setHolder}>
            <SimpleButton
                buttonRef={setAnchor}
                onClick={() => {
                    setShowFloater(true)
                }}
            >
                <ButtonInset>New contract template</ButtonInset>
            </SimpleButton>
            <Floater
                cardId="GenericFileBox"
                shouldShow={showFloater}
                anchor={anchor}
                onHide={() => {
                    setShowFloater(false)
                }}
            >
                <FloaterInset equalPadding>
                    <Text color="floating.text" bold>
                        New template's currency:
                    </Text>
                    <Spacer height={10} />
                    <Separator background="floating.background" />
                    <Spacer height={16} />
                    <Grid
                        horizontalTemplate="min-content min-content min-content"
                        gridRowGap="small"
                        gridColumnGap="small"
                    >
                        {AvailableSettlementCurrencies.map((curr) => {
                            return (
                                <WatcherButton
                                    background="floating.background.strongerI"
                                    onClick={(_, id) => {
                                        dispatch(ContractTemplateActions.BOOTSTRAP(id, curr))
                                    }}
                                    cy={`create-${curr}`}
                                    onSuccess={handleSuccess}
                                >
                                    <ButtonInset padding="tiny">{curr}</ButtonInset>
                                </WatcherButton>
                            )
                        })}
                    </Grid>
                </FloaterInset>
            </Floater>
        </div>
    )
}
