import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import React from 'react'
import { DeleteAction } from './delete-action'
import { FinalizeAction } from './finalize-action'

export const ReportModalActions: React.FC = () => {
    return (
        <Flex>
            <DeleteAction />
            <Spacer width={16} />
            <FinalizeAction />
        </Flex>
    )
}
