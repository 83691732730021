import { File } from '../files/types'
import { WatcherID } from '../watcher/types'
import { Dispute, DisputeComment, DisputesFilters, DisputesState, DisputeSummary } from './types'

export const DISPUTES_LOAD_SUMMARIES = 'DISPUTES_LOAD_SUMMARIES'
export const DISPUTES_STORE_SUMMARIES = 'DISPUTES_STORE_SUMMARIES'
export const DISPUTE_LOAD = 'DISPUTE_LOAD'
export const DISPUTE_STORE = 'DISPUTE_STORE'
export const DISPUTE_UNSTORE = 'DISPUTE_UNSTORE'
export const DISPUTE_LOAD_EXTRA_DETAILS = 'DISPUTE_LOAD_EXTRA_DETAILS'
export const DISPUTE_STORE_EXTRA_DETAILS = 'DISPUTE_STORE_EXTRA_DETAILS'
export const DISPUTE_SEND_COMMENT = 'DISPUTE_SEND_COMMENT'
export const DISPUTE_REMOVE_COMMENT = 'DISPUTE_REMOVE_COMMENT'
export const DISPUTE_STORE_COMMENT = 'DISPUTE_STORE_COMMENT'
export const DISPUTE_UNSTORE_COMMENT = 'DISPUTE_UNSTORE_COMMENT'
export const DISPUTE_CHANGE_DUEDATE = 'DISPUTE_CHANGE_DUEDATE'
export const DISPUTE_STAMP = 'DISPUTE_STAMP'
export const DISPUTE_LINK_FILES = 'DISPUTE_LINK_FILES'
export const DISPUTE_UNLINK_FILES = 'DISPUTE_UNLINK_FILES'
export const DISPUTES_CLEAR_SUMMARIES = 'DISPUTES_CLEAR_SUMMARIES'
export const DISPUTE_LOAD_NEXT_RESULTS = "DISPUTE_LOAD_NEXT_RESULTS"

export interface DisputesActionLoadSummaries {
    type: typeof DISPUTES_LOAD_SUMMARIES
    filters: DisputesFilters
}
export interface DisputesActionStoreSummaries {
    type: typeof DISPUTES_STORE_SUMMARIES
    disputesSummaries: DisputeSummary[]
    pagination: DisputesState['disputesSummaries']['pagination']
}

export interface DisputeActionLoad {
    type: typeof DISPUTE_LOAD
    disputeId: string
}

export interface DisputeActionStore {
    type: typeof DISPUTE_STORE
    dispute: Dispute
}

export interface DisputeActionStamp {
    type: typeof DISPUTE_STAMP
    disputeId: string
    stampsLink: string
}

export interface DisputeActionUnstore {
    type: typeof DISPUTE_UNSTORE
    disputeId: string
}

export interface DisputeActionLoadExtraDetails {
    type: typeof DISPUTE_LOAD_EXTRA_DETAILS
    dispute: Dispute
}

export interface DisputeActionStoreExtraDetails {
    type: typeof DISPUTE_STORE_EXTRA_DETAILS
    dispute: Dispute
}

export interface DisputeActionSendComment {
    type: typeof DISPUTE_SEND_COMMENT
    dispute: Dispute
    watcherId: WatcherID
    body: string
}

export interface DisputeActionStoreComment {
    type: typeof DISPUTE_STORE_COMMENT
    dispute: Dispute
    comment: DisputeComment
}

export interface DisputeActionUnstoreComment {
    type: typeof DISPUTE_UNSTORE_COMMENT
    dispute: Dispute
    commentId: string
}

export interface DisputeActionRemoveComment {
    type: typeof DISPUTE_REMOVE_COMMENT
    watcherId: WatcherID
    dispute: Dispute
    comment: DisputeComment
}

export interface DisputeActionChangeDueDate {
    type: typeof DISPUTE_CHANGE_DUEDATE
    dispute: Dispute
    watcherId: WatcherID
    newDueDate?: string
}

export interface DisputeActionLinkFiles {
    type: typeof DISPUTE_LINK_FILES
    disputeId: string
    files: File[]
}

export interface DisputeActionUnlinkFiles {
    type: typeof DISPUTE_UNLINK_FILES
    disputeId: string
    files: File[]
}

export interface DisputeActionClearSummaries {
    type: typeof DISPUTES_CLEAR_SUMMARIES
}

export interface DisputeActionLoadNextResults {
    type: typeof DISPUTE_LOAD_NEXT_RESULTS
    dispute: Dispute
    commentsNextLink: string
    filesNextLink: string
}

export const DisputesDispatchClearSummaries = (): DisputeActionClearSummaries => {
    return { type: DISPUTES_CLEAR_SUMMARIES }
}

export const DisputesDispatchLinkFiles = (disputeId: string, files: File[]): DisputeActionLinkFiles => {
    return { type: DISPUTE_LINK_FILES, disputeId, files }
}

export const DisputesDispatchStamp = (disputeId: string, stampsLink: string): DisputeActionStamp => {
    return { type: DISPUTE_STAMP, disputeId, stampsLink }
}

export const DisputesDispatchUnlinkFiles = (disputeId: string, files: File[]): DisputeActionUnlinkFiles => {
    return { type: DISPUTE_UNLINK_FILES, disputeId, files }
}

export const DisputesDispatchLoadSummaries = (filters: DisputesFilters): DisputesActionLoadSummaries => {
    return {
        type: DISPUTES_LOAD_SUMMARIES,
        filters
    }
}

export const DisputesDispatchStoreSummaries = (
    disputesSummaries: DisputeSummary[],
    pagination: DisputesState['disputesSummaries']['pagination']
): DisputesActionStoreSummaries => {
    return { type: DISPUTES_STORE_SUMMARIES, disputesSummaries, pagination }
}

export const DisputeDispatchLoad = (disputeId: string): DisputeActionLoad => {
    return { type: DISPUTE_LOAD, disputeId }
}

export const DisputeDispatchStore = (dispute: Dispute): DisputeActionStore => {
    return { type: DISPUTE_STORE, dispute }
}
export const DisputeDispatchUnstore = (disputeId: string): DisputeActionUnstore => {
    return { type: DISPUTE_UNSTORE, disputeId }
}

export const DisputeDispatchLoadExtraDetails = (dispute: Dispute): DisputeActionLoadExtraDetails => {
    return { type: DISPUTE_LOAD_EXTRA_DETAILS, dispute }
}

export const DisputeDispatchStoreExtraDetails = (dispute: Dispute): DisputeActionStoreExtraDetails => {
    return { type: DISPUTE_STORE_EXTRA_DETAILS, dispute }
}

export const DisputeDispatchSendComment = (
    watcherId: WatcherID,
    dispute: Dispute,
    body: string
): DisputeActionSendComment => {
    return { type: DISPUTE_SEND_COMMENT, watcherId, dispute, body }
}

export const DisputeDispatchRemoveComment = (
    watcherId: WatcherID,
    dispute: Dispute,
    comment: DisputeComment
): DisputeActionRemoveComment => {
    return { type: DISPUTE_REMOVE_COMMENT, dispute, comment, watcherId }
}

export const DisputeDispatchStoreComment = (dispute: Dispute, comment: DisputeComment): DisputeActionStoreComment => {
    return { type: DISPUTE_STORE_COMMENT, dispute, comment }
}

export const DisputeDispatchUnstoreComment = (dispute: Dispute, commentId: string): DisputeActionUnstoreComment => {
    return { type: DISPUTE_UNSTORE_COMMENT, dispute, commentId }
}

export const DisputeDispatchChangeDueDate = (
    dispute: Dispute,
    watcherId: WatcherID,
    newDueDate?: string
): DisputeActionChangeDueDate => {
    return { type: DISPUTE_CHANGE_DUEDATE, watcherId, dispute, newDueDate }
}

export const DisputeDispatchLoadNextResults = (dispute: Dispute, 
    commentsNextLink: string, filesNextLink: string): DisputeActionLoadNextResults => {
        return {
            type: DISPUTE_LOAD_NEXT_RESULTS,
            dispute,
            commentsNextLink,
            filesNextLink
        }
}

export interface SagasForDisputes {
    stamp(action: DisputeActionStamp): void
    sendComment(action: DisputeActionSendComment): void
    removeComment(action: DisputeActionRemoveComment): void
    changeDueDate(action: DisputeActionChangeDueDate): void
    loadSummaries(action: DisputesActionLoadSummaries): void
}

export type ReducedDisputesActions =
    | DisputesActionStoreSummaries
    | DisputesActionLoadSummaries
    | DisputeActionLoad
    | DisputeActionStore
    | DisputeActionStoreComment
    | DisputeActionUnstoreComment
    | DisputeActionUnstore
    | DisputeActionLoadExtraDetails
    | DisputeActionLinkFiles
    | DisputeActionUnlinkFiles
    | DisputeActionChangeDueDate
    | DisputeActionStoreExtraDetails
    | DisputeActionClearSummaries
    | DisputeActionLoadNextResults
