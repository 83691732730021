import styled, { css } from 'styled-components'
import { Color } from '../../styles/colors'

export const InputSuffix: React.FC<{
    suffix?: React.ReactElement | string
    id?: string
    hasValue?: boolean
    overBackground: Color
}> = ({ id, suffix, hasValue, overBackground }) => {
    if (!suffix) return null

    if (hasValue)
        return (
            <Label htmlFor={id} overBackground={overBackground}>
                {suffix}
            </Label>
        )
    return (
        <Label htmlFor={id} overBackground={overBackground}>
            {suffix}
        </Label>
    )
}

const Label = styled.label<{ overBackground: Color }>`
    margin-left: 5px;

    ${(p) =>
        css`
            color: ${p.theme[
                (p.overBackground || ('front.background' as const)).replace('.background', '.text') as Color
            ]};
        `}
`
