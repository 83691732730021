import React, { ReactNode, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { BoxShadows } from '../../../styles/boxShadows'
import { Text } from '../../general/text'

export const SidebarLinkHolder: React.FC<{
    children: ReactNode
    label: string
    cy: string
    hotkey?: string
}> = (p) => {
    const [show, setShow] = useState(false)

    const handleMouseIn = useCallback(() => {
        setShow(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        setShow(false)
    }, [])

    return (
        <Holder data-cy={p.cy}>
            <Label show={show} key="label">
                <Text size="s" noWrap>
                    {p.label}
                </Text>
            </Label>
            <div onMouseEnter={handleMouseIn} onMouseLeave={handleMouseLeave}>
                {p.children}
            </div>
        </Holder>
    )
}

const Holder = styled.div`
    position: relative;
    width: 100%;
`

const Label = styled.div<{ show: boolean }>`
    position: absolute;
    background-color: #000;
    border-radius: 7px;
    top: 50%;
    margin-top: -10px;
    padding: 4px 8px 4px 10px;
    white-space: nowrap;
    left: 70px;
    z-index: 10000;
    font-size: 11px;
    color: #fff;
    opacity: 0;
    transform: translateX(-5px);
    pointer-events: none;
    box-shadow: ${BoxShadows.dropdown};

    ${(p) =>
        p.show &&
        css`
            transform: translateX(0px) !important;
            opacity: 1 !important;
        `}

    &::after {
        content: '';
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-5px);
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #000;
    }
`
