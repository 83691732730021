import React, { useMemo } from 'react'
import { useApplicationValidation } from '../../../hooks/application/useApplicationValidation'
import { ApplicationValidationSectionItem } from './Application.ValidationSection.Item'

type ApplicationValidationSectionPeopleProps = {
    validationResults: ReturnType<typeof useApplicationValidation>[0]
}

export const ApplicationValidationSectionPeople: React.FC<ApplicationValidationSectionPeopleProps> = ({
    validationResults
}) => {
    const peopleResults = useMemo(() => {
        if (validationResults === undefined || !Array.isArray(validationResults)) {
            return null
        }

        return validationResults.filter((validation) => validation.path && validation.path.startsWith('people'))
    }, [validationResults])

    if (!peopleResults || peopleResults.length === 0) {
        return null
    }

    return (
        <ApplicationValidationSectionItem testid="validation-people">
            People section has missing data
        </ApplicationValidationSectionItem>
    )
}
