import { api } from '../api'
import { DeleteVoucherData, ReconcileVoucherData } from '../types/vouchers'
import { Voucher, Vouchers } from '../types/vouchers'

export const vouchersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getVouchers: build.query<Vouchers, void>({
            query: () => `/vouchers`,
            providesTags: ['vouchers']
        }),
        getDeletedVouchers: build.query<Vouchers, void>({
            query: () => `/vouchers?query=deleted:true`,
            providesTags: ['deleted-vouchers']
        }),
        getVoucher: build.query<Voucher, string>({
            query: (voucherId: string) => `/vouchers/${voucherId}`,
            providesTags: ['voucher']
        }),
        reconcileVoucher: build.mutation<Voucher, { voucherId: string; data: ReconcileVoucherData }>({
            query: ({ voucherId, data }) => ({
                url: `/vouchers/${voucherId}/reconcile`,
                method: 'PUT',
                body: {
                    ...data
                }
            }),
            invalidatesTags: ['vouchers', 'voucher']
        }),
        unreconcileVoucher: build.mutation<Voucher, { voucherId: string }>({
            query: ({ voucherId }) => ({ url: `/vouchers/${voucherId}/unreconcile`, method: 'PUT' }),
            invalidatesTags: ['vouchers', 'voucher']
        }),
        deleteVoucher: build.mutation<Voucher, { voucherId: string; data: DeleteVoucherData }>({
            query: ({ voucherId, data }) => ({
                url: `/vouchers/${voucherId}`,
                method: 'DELETE',
                body: {
                    ...data
                }
            }),
            invalidatesTags: ['vouchers', 'deleted-vouchers']
        }),
        recoverVoucher: build.mutation<Voucher, { voucherId: string }>({
            query: ({ voucherId }) => ({ url: `/vouchers/${voucherId}/recover`, method: 'PUT' }),
            invalidatesTags: ['vouchers', 'deleted-vouchers']
        })
    })
})

export const {
    useGetVouchersQuery: useGetVouchers,
    useGetDeletedVouchersQuery: useGetDeletedVouchers,
    useGetVoucherQuery: useGetVoucher,
    useReconcileVoucherMutation: useReconcileVoucher,
    useUnreconcileVoucherMutation: useUnreconcileVoucher,
    useDeleteVoucherMutation: useDeleteVoucher,
    useRecoverVoucherMutation: useRecoverVoucher
} = vouchersApi
