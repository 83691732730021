import { useCallback } from 'react'
import { Text } from '../../../components/general/text'
import { useGateways } from '../../../hooks/general/useGateways'

const SpecialSuffixes: any = {
    paymentPeriod: 'no-suffix',
    otherTerms: 'no-suffix',
    paymentDelay: 'days',
    rollingReserveDelay: 'days',
    domesticCredit: '%',
    domesticDebit: '%',
    interRegionCredit: '%',
    interRegionDebit: '%',
    intraRegionCredit: '%',
    intraRegionDebit: '%',
    exchangeFee: '%'
}

export const useContractFormHelpers = (currency: string) => {
    const gateways = useGateways()
    const textForChange = useCallback(
        (key: string, val: any) => {
            let suffix = 'currency'

            if (val === false) return 'No'
            if (val === true) return 'Yes'
            if (!val) return <Text color="front.text.subtlerI">-</Text>
            if (key === 'gatewayId') return gateways?.at?.[val]?.name

            Object.keys(SpecialSuffixes).forEach((k) => {
                if (key.includes(k)) suffix = SpecialSuffixes[k]
            })

            if (suffix === '%') return `${val}%`
            if (suffix === 'no-suffix') return val
            if (suffix === 'days') {
                if (parseInt(val, 10) <= 1) return `${val} day`
                return `${val} days`
            }

            return `${val} ${currency}`
        },
        [currency, gateways?.at]
    )

    return { textForChange }
}
