/* eslint-disable no-param-reassign */
import { createReducer } from 'deox'
import { produce } from 'immer'

import { MCCActions } from './actions'
import { InitialMCCState } from './types'

export const MCCReducer = createReducer(InitialMCCState, (handleAction) => [
    handleAction(MCCActions.MCC_STORE, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft.at) draft.at = {}
            draft.all = []
            p.list.map((mcc) => {
                draft.at[mcc.value] = { ...mcc }
                draft.all.push(mcc.value)
            })
            draft.loadingStatus = 'done'
        })
    }),
    handleAction(MCCActions.MCC_FIND, (state) => {
        return produce(state, (draft) => {
            draft.loadingStatus = 'started'
        })
    }),
    handleAction(MCCActions.MCC_CLEAR, (state) => {
        return produce(state, (draft) => {
            draft.at = {}
            draft.all = []
            draft.loadingStatus = 'not-started'
        })
    })
])
