import React from 'react'
import { NeededExtraRequestsPersonWithCutterInfo } from '../../../../../../../../store/tasksCompanyChanges/types'
import { Text } from '../../../../../../../../components/general/text'
import { MissingInfoDropdown } from '../MissingInfoDropdown'
import { MonitoringPersonType } from '../../MonitoringPersonAdded'
import { getTexts } from './getTexts'

interface DescriptionProps {
    type: MonitoringPersonType
    status: 'complete' | 'in-progress' | 'muted'
    requiredInfo: string[]
    missingInfo: string[]
    person?: NeededExtraRequestsPersonWithCutterInfo
}

export const Description: React.FC<DescriptionProps> = (props) => {
    const { type, status, requiredInfo, missingInfo, person } = props

    const texts = getTexts(type, person)

    if (status === 'complete') {
        return <>{texts.complete}</>
    }

    if (status === 'muted') {
        return <>{texts.muted}</>
    }

    if (status === 'in-progress' && missingInfo.length !== 0) {
        return (
            <>
                {texts.missingInfo}
                <MissingInfoDropdown requiredInfo={requiredInfo} missingInfo={missingInfo}>
                    <Text color="front.accent.color">What info is missing?</Text>
                </MissingInfoDropdown>
            </>
        )
    }

    if (status === 'in-progress' && !person?.id) {
        return <>{texts.missingPerson}</>
    }

    return <>{texts.general}</>
}
