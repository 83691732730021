export const USER_ACCOUNTS_STORE_ACCOUNT = 'USER_ACCOUNTS_STORE_ACCOUNT'
export const USER_ACCOUNTS_GET_ACCOUNT = 'USER_ACCOUNTS_GET_ACCOUNT'
export const USER_ACCOUNTS_QUERY = 'USER_ACCOUNTS_QUERY'
export const USER_ACCOUNTS_GET_ACCOUNTS = 'USER_ACCOUNTS_GET_ACCOUNTS'
export const USER_ACCOUNTS_LOAD_ACCOUNT = 'USER_ACCOUNTS_LOAD_ACCOUNT'

export interface UserAccountsActionQueryPayload {
    mid?: string
}

//--
export interface UserAccountsActionStoreAccount {
    type: typeof USER_ACCOUNTS_STORE_ACCOUNT
    id: string
    data: any
}
export interface UserAccountsActionGetAccount {
    type: typeof USER_ACCOUNTS_GET_ACCOUNT
    id: string
    extraData: { isMerchant: boolean }
}
export interface UserAccountsActionQuery {
    type: typeof USER_ACCOUNTS_QUERY
    query: UserAccountsActionQueryPayload
}
export interface UserAccountsActionGetAccounts {
    type: typeof USER_ACCOUNTS_GET_ACCOUNTS
    ids: string[]
    extras: any
}
export interface UserAccountsActionLoadAccount {
    type: typeof USER_ACCOUNTS_LOAD_ACCOUNT
    id: string
    isMerchant: boolean
}

//---

export const UserAccountsDispatchGetAccount = (
    id: string,
    extraData: { isMerchant: boolean }
): UserAccountsActionGetAccount => {
    return { type: USER_ACCOUNTS_GET_ACCOUNT, id, extraData }
}

export const UserAccountsDispatchGetAccounts = (
    ids: string[],
    extras: any
): UserAccountsActionGetAccounts => {
    return { type: USER_ACCOUNTS_GET_ACCOUNTS, ids, extras }
}

export const UserAccountsDispatchQuery = (
    query: UserAccountsActionQueryPayload
): UserAccountsActionQuery => {
    return { type: USER_ACCOUNTS_QUERY, query }
}

export const UserAccountsDispatchStoreAccount = (
    id: string,
    data: any
): UserAccountsActionStoreAccount => {
    return { type: USER_ACCOUNTS_STORE_ACCOUNT, id, data }
}

export const UserAccountsDispatchLoadAccount = (
    id: string,
    isMerchant: boolean
): UserAccountsActionLoadAccount => {
    return { type: USER_ACCOUNTS_LOAD_ACCOUNT, id, isMerchant }
}

export interface SagasForUserAccounts {
    loadAccount(action: UserAccountsActionLoadAccount): void
    queryAccounts(action: UserAccountsActionQuery): void
    getAccounts(action: UserAccountsActionGetAccounts): void
}
export type UserAccountsNeedingReducing =
    | UserAccountsActionStoreAccount
    | UserAccountsActionLoadAccount
