import { Url } from '@/services/common'
import { useGetFiles } from '@/services/merchant-api'
import React from 'react'
import { CompanyFileLinks } from './company-file-links'

interface CompanyFiles {
    filesUrl: Url
}

export const CompanyFiles: React.FC<CompanyFiles> = (props) => {
    const { filesUrl } = props

    const { isLoading, data, isError } = useGetFiles(filesUrl)

    if (isLoading || !data || isError) {
        return null
    }

    return <CompanyFileLinks files={data} />
}
