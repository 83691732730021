import { Diff } from 'diff-match-patch'

export const TYPO_LEVELSHTEIN_LEVEL_MINIMUM = 4

function entireWordsMissingTypo(diff: Diff[], source: string) {
    const sourceSplit = source.toLowerCase().split(/[\s-]+/g)
    let matchingWords = 0
    for (const w of diff) {
        // find at least 2 complete matching words
        if (w[0] == 0) {
            if (sourceSplit.includes(w[1].trim())) {
                matchingWords++
            }
        }
    }
    return matchingWords >= 2
}

export function isTypo(dmp: any, source: string, wannabe: string) {
    if (!source || !wannabe) return null
    if (source.toLowerCase() == wannabe.toLowerCase()) return null
    const diff = dmp.diff_main(source.toLowerCase(), wannabe.toLowerCase())
    dmp.diff_cleanupSemantic(diff)
    const level = dmp.diff_levenshtein(diff)

    if (entireWordsMissingTypo(diff, source)) {
        return diff
    }

    if (level < TYPO_LEVELSHTEIN_LEVEL_MINIMUM) {
        return diff
    }
    return null
}
