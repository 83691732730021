import React from 'react'
import { useApplicationWebsites } from '../../../../../api'
import { useReportParams } from '../../../../../hooks'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'
import { WebsiteFiles } from './website-files'

export const WebsiteMetadata: React.FC = () => {
    const merchantData = useReportMerchantData()

    const { applicationId } = useReportParams()

    const websites = useApplicationWebsites(applicationId)

    if (websites.isLoading || !websites.data || websites.isError) {
        return null
    }

    const embeddedWebsites = websites.data.embedded['ch:websites']

    if (!embeddedWebsites) {
        return null
    }

    const filteredWebsites = embeddedWebsites.filter((website) =>
        merchantData.websites.some((w) => w.id === website.id)
    )

    return (
        <Section.Metadata>
            {filteredWebsites.map((website, i) => {
                const last = i === filteredWebsites.length - 1

                return (
                    <React.Fragment key={website.id}>
                        <Section.Title color="normal">{`${i + 1}. Websites`}</Section.Title>
                        <Section.Spacer size="s" />
                        <WebsiteFiles filesUrl={website.relations.files} />
                        {!last && <Section.Spacer />}
                    </React.Fragment>
                )
            })}
        </Section.Metadata>
    )
}
