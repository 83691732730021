import { takeRight } from 'lodash'

import { MerchantAccountContract, MerchantAccountDraftContract } from '../../../store/merchantAccounts/types'

export const MerchantAccountContractName: React.FC<{
    contract?: MerchantAccountContract | MerchantAccountDraftContract
    id?: string
}> = ({ contract, id }) => {
    if (id) return <>{takeRight(id, 5).join('').toUpperCase()}</>
    if (contract) return <>#{takeRight(contract.id, 5).join('').toUpperCase()}</>
    return <></>
}
