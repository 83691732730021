import { css } from 'styled-components'

export const MerchantApplicationFieldInnerStyles = css`
    display: flex;
    margin: 0;
    height: auto;
    box-sizing: border-box;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1px;
    padding-right: 10px;
    padding-bottom: 1px;
`

export const MerchantApplicationSelectedBarStyles = (type?: 'last' | 'first', isHigher?: boolean) => css`
    ${(p: any) =>
        (p.isActive || p.isSelected) &&
        css`
            background: #3b4856;
            color: #fff;
            margin: -2px 0 -3px 0;
            padding: 5px 0 4px 0;

            ${type === 'last' &&
            css`
                margin-right: -15px;
                padding-right: 15px;
                border-top-right-radius: 8px !important;
                border-bottom-right-radius: 8px !important;
            `}
            ${type === 'first' &&
            css`
                margin-left: -15px;
                padding-left: 15px;
                border-top-left-radius: 8px !important;
                border-bottom-left-radius: 8px !important;
            `}

            z-index: 10 !important;
            ${isHigher &&
            css`
                z-index: 12 !important;
            `}
        `}
`
