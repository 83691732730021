import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { ValidationStatus } from '../../hooks/general/useForm'
import { useFormField } from '../../hooks/general/useFormField'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { MODAL_ID } from '../modals/modalIds'
import { ClueDirection } from '../hotkeys/hotkeyClue'
import { InputIndicator } from './inputIndicator'
import TextareaAutosize from 'react-textarea-autosize'
import { Color } from '../../styles/colors'
import C from 'color'

const TextareaInputWithRef: React.ForwardRefRenderFunction<
    any,
    {
        placeholder: string
        initialValue?: string
        onChange?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        onCommandEnter?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        onEnter?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        id?: string
        onFocus?: (e: any) => void
        onBlur?: (e: any) => void
        cy?: string
        hotkeysScope?: MODAL_ID
        hotkeys?: string
        hotkeysClueDirection?: ClueDirection
        skipAnimation?: boolean
        isDisabled?: boolean
        escKeyOnceIsEnough?: boolean
        rightAlign?: boolean
        textareaRef?: (ref: MutableRefObject<any>) => void
        validation?: ValidationStatus
        overBackground: Color
        isSimple?: boolean
        isSeamless?: boolean
        noFormFieldBehaviour?: boolean
        refInterceptor?: any
        monotype?: boolean
        noNewlines?: boolean
        minHeight?: number
        minWidth?: string
        customStyles?: string
        tabIndex?: number
        smallerSuperField?: boolean
        refTaker?: (ref: any) => void
        endless?: boolean
        forceValue?: string
        disableGrammarly?: boolean
        frameless?: boolean
        overflowHorizontally?: boolean
        paddingLess?: boolean
        maintainForcedValueOnBlur?: boolean
        readOnly?: boolean
        superField?: boolean
        noMaxHeight?: boolean
    }
> = (
    {
        id,
        placeholder,
        onChange,
        onEnter,
        skipAnimation,
        initialValue,
        smallerSuperField,
        cy,
        hotkeysScope,
        overBackground,
        overflowHorizontally,
        isDisabled,
        noMaxHeight,
        paddingLess,
        validation,
        escKeyOnceIsEnough,
        rightAlign,
        minHeight,
        minWidth,
        onCommandEnter,
        hotkeysClueDirection,
        maintainForcedValueOnBlur,
        hotkeys,
        onBlur,
        endless,
        onFocus,
        disableGrammarly,
        isSeamless,
        refInterceptor: rInterceptor,
        refTaker,
        noFormFieldBehaviour,
        monotype,
        isSimple,
        superField,
        frameless,
        noNewlines,
        tabIndex,
        forceValue,
        customStyles,
        readOnly
    },
    ref
) => {
    const [isFocused, setIsFocused] = useState(false)
    const focused = useRef(false)
    const [hasValue, setHasValue] = useState(!!initialValue)
    const [value, setValue] = useState(initialValue)

    const { refInterceptor, onFieldUpdate, fieldRef } = useFormField(
        useCallback(
            (value) => {
                setValue(value)
            },
            [setValue]
        ),
        useCallback((ref) => {
            return ref?.current?.value
        }, []),
        useCallback((r: any) => {
            if (ref) {
                if (typeof ref === 'function') ref(r)
                else ref.current = r
            }
            refTaker?.(r)
        }, []),
        noFormFieldBehaviour,
        initialValue
    )

    useEffect(() => {
        setValue((v) => {
            if (v !== forceValue) return forceValue || undefined
            return v
        })
    }, [forceValue])

    useEffect(() => {
        setValue((v) => {
            if (focused.current) return v
            if (v !== initialValue) return initialValue
            return v
        })
    }, [initialValue])

    const clueElement = useHotkey({
        scope: hotkeysScope,
        keys: hotkeys,
        clue: hotkeysClueDirection,
        action: useCallback(() => {
            fieldRef?.current?.focus()
        }, [fieldRef])
    })

    const handleKeyDown = useCallback(
        (e: any) => {
            if (e.key === 'Escape') {
                e.currentTarget.blur()
            }
            if (e.key === 'Enter') {
                if (noNewlines) e.preventDefault()
                if (e.ctrlKey || e.metaKey || e.altKey) onCommandEnter?.(e, e.currentTarget.value)
                else onEnter?.(e, e.currentTarget.value)
            }
            if (e.key === 's') {
                e.stopPropagation()
            }
        },
        [onEnter, noNewlines, onCommandEnter]
    )

    const handleChanged = useCallback(
        (e: any) => {
            onChange?.(e, e.currentTarget.value)
        },
        [onChange]
    )

    const handleFocus = useCallback(
        (e: any) => {
            focused.current = true
            onFocus?.(e)
            setIsFocused(true)
        },
        [setIsFocused, onFocus]
    )

    const handleBlur = useCallback(
        (e: any) => {
            if (maintainForcedValueOnBlur && e.relatedTarget?.closest('[data-prevent-input-focus-loss]')) {
                fieldRef?.current?.focus()
                return
            }
            focused.current = false
            onBlur?.(e)
            setIsFocused(false)
            if (maintainForcedValueOnBlur) setValue(forceValue)
        },
        [setIsFocused, fieldRef, onBlur, forceValue, maintainForcedValueOnBlur]
    )

    const handleKeyUp = useCallback(
        (e) => {
            // const fieldUpdateEvent = new CustomEvent('fieldUpdate', {
            //     bubbles: true,
            //     detail: {
            //         value: e.currentTarget.value
            //     }
            // })
            // e.currentTarget.dispatchEvent(fieldUpdateEvent)

            if (e.currentTarget.value) {
                setHasValue(true)
            } else {
                setHasValue(false)
            }
        },
        [setHasValue]
    )

    const handleInput = useCallback(
        (e) => {
            let value = e.target.value
            if (noNewlines) value = value.replace(/\r?\n|\r/g, '')

            onFieldUpdate?.(value)
            setValue(value)
        },
        [onFieldUpdate, noNewlines]
    )

    const inputIndicator = useMemo(() => {
        const indicator = <InputIndicator validationStatus={validation} hasValue={hasValue} isFocused={isFocused} />
        if (validation) return indicator
        if (!isSimple) return null
        if (isDisabled) return null
        return indicator
    }, [validation, hasValue, isDisabled, isSimple, isFocused])

    const disableGrammarlyAttributes = useMemo(() => {
        if (disableGrammarly) return {}
        return {
            'data-gramm': false,
            'data-gramm_editor': false,
            'data-enable-grammarly': false
        }
    }, [disableGrammarly])

    const Node = useMemo(() => {
        if (noMaxHeight) return SimpleTextArea
        return Textarea
    }, [noMaxHeight])

    return (
        <Holder>
            <Node
                ref={refInterceptor || rInterceptor}
                id={id}
                name={id}
                data-cy={cy}
                placeholder={placeholder}
                disabled={isDisabled}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                onInput={handleInput}
                tabIndex={tabIndex}
                $endless={endless}
                $smallerSuperField={smallerSuperField}
                autoComplete="off"
                onChange={handleChanged}
                value={value}
                onFocus={handleFocus}
                className={escKeyOnceIsEnough ? 'HOTKEYS_ESC_FORCES_EXIT' : ''}
                onBlur={handleBlur}
                readOnly={readOnly}
                $isSimple={isSimple}
                $customStyles={customStyles}
                $overBackground={overBackground}
                $frameless={frameless}
                $overflowHorizontally={overflowHorizontally}
                $skipAnimation={skipAnimation}
                $monotype={monotype}
                $rightAlign={rightAlign}
                $superField={superField}
                $noMaxHeight={noMaxHeight}
                $isSeamless={isSeamless}
                $minHeight={minHeight}
                $minWidth={minWidth}
                $paddingLess={paddingLess}
                {...disableGrammarlyAttributes}
            />
            {inputIndicator}
            {clueElement}
        </Holder>
    )
}

export const TextareaInput = React.forwardRef(TextareaInputWithRef)

const Holder = styled.div`
    position: relative;
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: nowrap;
`

const Textarea = styled(TextareaAutosize)<{
    $isSimple?: boolean
    $isSeamless?: boolean
    $skipAnimation?: boolean
    $rightAlign?: boolean
    $frameless?: boolean
    $minHeight?: number
    $minWidth?: string
    $superField?: number
    $monotype?: boolean
    $customStyles?: string
    $smallerSuperField?: boolean
    $overBackground: Color
    $overflowHorizontally?: boolean
    $endless?: boolean
    $paddingLess?: boolean
    $noMaxHeight?: boolean
}>`
    background-color: ${(p) =>
        p.theme[(p.$overBackground || 'front.background').replace('.background', '.highlights') as Color]};
    padding: ${(p) => (p.$paddingLess ? '0px' : '15px')};
    border-radius: 8px;
    border: none;
    height: 80px;
    flex-grow: 1;
    font-family: 'Roboto', sans-serif;
    will-change: height;
    max-height: 25vh;
    transition: background-color 0.35s ease;
    color: ${(p) => p.theme[(p.$overBackground || 'front.background').replace('.background', '.text') as Color]};
    caret-color: ${(p) => p.theme[(p.$overBackground || 'front.background').replace('.background', '.text') as Color]};

    &::placeholder {
        color: ${(p) =>
            p.theme[(p.$overBackground || 'front.background').replace('.background', '.text.subtlerI') as Color]};
    }

    ${(p) =>
        p.$rightAlign &&
        css`
            text-align: right;
        `}

    ${(p) =>
        p.$minWidth &&
        css`
            min-width: ${p.$minWidth};
        `}

    ${(p) =>
        p.$overflowHorizontally &&
        css`
            white-space: nowrap;
            overflow: auto;
        `}
    ${(p) =>
        (p.$isSimple || p.$isSeamless) &&
        css`
            width: 100%;
            color: ${p.theme[(p.$overBackground || 'front.background').replace('.background', '.text') as Color]};
            caret-color: ${p.theme[(p.$overBackground || 'front.background').replace('.background', '.text') as Color]};
            background-color: transparent;
            border-bottom: 1px solid transparent;
            min-height: 40px;
            border-radius: 5px;
            padding: 0;
            margin: 0;

            &:focus {
                background-color: transparent;
            }
        `}
    ${(p) =>
        p.$skipAnimation &&
        css`
            height: 140px;
            transition: none;
        `}

    ${(p) =>
        p.$monotype &&
        css`
            resize: vertical;
            font-family: 'Roboto Mono';
            font-weight: 500;
            letter-spacing: -0.5px;
            font-size: 12px;
        `}
    

    ${(p) =>
        p.$frameless &&
        css`
            background-color: transparent;
        `}
    ${(p) => css`
        &.glow {
            background-color: ${C(p.theme['front.accent.color']).alpha(0.3).string()};
        }
    `}

    ${(p) =>
        p.$customStyles &&
        css`
            ${p.$customStyles}
        `}
    &::-webkit-resizer {
        display: none;
    }
    ${(p) =>
        p.$isSeamless &&
        css`
            min-height: 0;
        `}
    ${(p) =>
        p.$minHeight &&
        css`
            min-height: ${p.$minHeight}px;
        `}
    
    ${(p) =>
        p.$superField &&
        css`
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
            line-height: 26px;
            border-radius: 2px;
        `}
    ${(p) =>
        p.$smallerSuperField &&
        css`
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            border-radius: 2px;
            letter-spacing: 0.01em;
        `}
    ${(p) =>
        p.$endless &&
        css`
            max-height: none;
        `}
    ${(p) =>
        p.$noMaxHeight &&
        css`
            max-height: none;
        `}

    ${(p) =>
        p.$paddingLess &&
        css`
            background-color: transparent;
        `}
` as any

const SimpleTextArea = styled(Textarea).attrs({
    as: 'textarea'
})`
    white-space: nowrap;
    overflow: auto;
    height: auto;
`
