import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantRedirect: React.FC = () => {
    const params = useParams<Params>()

    return <Navigate to={`/merchant/${params.id}/summary`} replace={true} />
}
