import { Action } from 'deox'
import { camelCase, throttle } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Icon } from '../icons/icon'
import { File } from '../../store/files/types'
import { InterfaceActions } from '../../store/interface/actions'
import { WatcherID } from '../../store/watcher/types'
import { MessageTemplate } from '../../utils/templates'
import { SyncdFile } from '../files/syncdFile'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { ButtonInset } from '../buttons/buttonInset'
import { LabelButton } from '../buttons/labelButton'
import { LinkButton } from '../buttons/linkButton'
import { LinkButtonArrow } from '../buttons/linkButtonArrow'
import { SimpleButtonWithDropdown } from '../buttons/simpleButtonWithDropdown'
import { WatcherButton } from '../buttons/watcherButton'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { TextareaInput } from './textareaInput'
import { MessageBoxTemplates } from './messageBoxTemplates'
import { v4 as uuid } from 'uuid'

export type MessageBoxAction = {
    label: string
    action: (watcherId: WatcherID, value: string | undefined, reset: () => void) => void
}
export const MessageBox: React.FC<{
    placeholder: string
    otherButtons?: React.ReactChild
    actions?: MessageBoxAction[]
    pageId: MODAL_ID
    noScrollOnReset?: boolean
    escKeyOnceIsEnough?: boolean
    onTemplateSelection?: () => void
    textareaRef?: (ref: React.MutableRefObject<any>) => void
    fileHandlingMemo?: {
        uploadLink: string
        linkFiles: (files: File[]) => Action<any>
        unlinkFiles: (files: File[]) => Action<any>
        scope: 'private' | 'public'
        withDocumentPrefix?: boolean
    }
    showFormattingGuide?: boolean
    templates?: MessageTemplate[]
    cyPrefix?: string
    paddingLess?: boolean
}> = ({
    fileHandlingMemo,
    otherButtons,
    paddingLess,
    placeholder,
    onTemplateSelection,
    escKeyOnceIsEnough,
    actions,
    showFormattingGuide,
    pageId,
    cyPrefix,
    noScrollOnReset,
    textareaRef,
    templates = []
}) => {
    const [textarea, setTextarea] = useRefTaker()
    const dispatch = useDispatch()
    const messageValue = useRef(undefined)
    const [forceValue, setForceValue] = useState('')
    const [key, refreshKey] = useState(uuid())
    const [waitForBlocksRemoval, setWaitForBlocksRemoval] = useState(false)

    useEffect(() => {
        textareaRef?.(textarea)
    }, [textarea, textareaRef])

    const renderedFileHandlingFunctionality = useMemo(() => {
        if (!fileHandlingMemo) return null

        const renderButton = (ref: any) => {
            return (
                <LabelButton htmlFor={ref}>
                    <ButtonInset padding="small" noHorizontalPadding>
                        <Icon type="attachment" />
                        <Spacer width={5} />
                        Add file
                    </ButtonInset>
                </LabelButton>
            )
        }

        return (
            <>
                <SyncdFile
                    withDocumentPrefix={fileHandlingMemo.withDocumentPrefix}
                    fileLabel="attachment"
                    customButton={renderButton}
                    scope={fileHandlingMemo.scope}
                    sourcePath={fileHandlingMemo.uploadLink}
                    linkFiles={fileHandlingMemo.linkFiles}
                    cutterBased={false}
                    unlinkFiles={fileHandlingMemo.unlinkFiles}
                    marginless
                    cy="file-upload"
                />
                <Spacer width={15} />
            </>
        )
    }, [fileHandlingMemo])

    const updateMarkdownPreview = useCallback(
        throttle((value: string) => {
            dispatch(InterfaceActions.SET_MARKDOWN_PREVIEW(pageId, value))
        }, 125),
        [dispatch]
    )

    const handleTextChange = useCallback(
        (e, value) => {
            setWaitForBlocksRemoval(false)
            updateMarkdownPreview(value)
            messageValue.current = value
        },
        [updateMarkdownPreview]
    )

    const reset = useCallback(() => {
        if (!textarea) return
        setForceValue('')
        refreshKey(uuid())
        updateMarkdownPreview('')
        textarea.focus()
        messageValue.current = undefined
    }, [textarea, updateMarkdownPreview])

    const mainAction = useCallback(() => {
        if (!actions?.[0]) return
        if(messageValue.current) actions[0].action(`${pageId}.SendComment`, messageValue.current, reset)
    }, [actions, pageId, reset])

    const renderedMainButton = useMemo(() => {
        if (!actions?.length) return <></>

        if (actions.length === 1)
            return (
                <WatcherButton
                    background="front.accent.color"
                    onClick={mainAction}
                    cy={`${cyPrefix ? `${cyPrefix}-` : ''}message-box-main`}
                    predefinedWatcher={`${pageId}.SendComment`}
                    isDisabled={waitForBlocksRemoval}
                    hotkeysScope={pageId}
                    hotkeys="alt+enter"
                >
                    <ButtonInset>{actions[0].label}</ButtonInset>
                </WatcherButton>
            )

        const secondaryButtons = actions
            .filter((l, i) => i !== 0)
            .map((a, i) => {
                return {
                    id: camelCase(a.label),
                    label: a.label,
                    action: () => {
                        a.action(`${pageId}.SendComment`, messageValue.current, reset)
                    }
                }
            })

        return (
            <SimpleButtonWithDropdown
                background="front.accent.color"
                predefinedWatcher={`${pageId}.SendComment`}
                mainAction={mainAction}
                secondaryButtons={secondaryButtons}
                hotkeysScope={pageId}
                hotkeys="alt+enter"
                cy={`${cyPrefix ? `${cyPrefix}-` : ''}message-box-main`}
            >
                <ButtonInset>{actions[0].label}</ButtonInset>
            </SimpleButtonWithDropdown>
        )
    }, [actions, mainAction, waitForBlocksRemoval, pageId, cyPrefix, reset])

    useEffect(() => {
        return () => {
            dispatch(InterfaceActions.CLEAR_MARKDOWN_PREVIEW(pageId))
        }
    }, [dispatch, pageId])

    const handleSelect = useCallback(
        (item: string) => {
            const template = templates.find((x) => x.name === item)
            if (!template) return

            const value = template?.value || ''
            const newValue = messageValue.current ? messageValue.current + `\n${value}` : value
            if (template.hasBlocks) setWaitForBlocksRemoval(true)
            setForceValue(newValue)
            updateMarkdownPreview(newValue)
        },
        [templates, updateMarkdownPreview]
    )
    useEffect(() => {
        if (onTemplateSelection) {
            onTemplateSelection?.()
            setTimeout(() => {
                textarea?.focus()
            }, 16)
        }
    }, [forceValue, textarea, onTemplateSelection])

    return (
        <Holder>
            <Header>
                <Flex align="center">
                    {renderedFileHandlingFunctionality}
                    <MessageBoxTemplates handleSelect={handleSelect} />
                    {showFormattingGuide ? (
                        <>
                            <Spacer width={15} />
                            <LinkButton
                                color="front.accent.color"
                                to="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                                target="blank"
                            >
                                <ButtonInset noHorizontalPadding>
                                    <LinkButtonArrow background="front.background" shouldBeBordered />
                                    <Spacer width={7} />
                                    Formatting guide
                                </ButtonInset>
                            </LinkButton>
                        </>
                    ) : null}
                </Flex>
                <Flex align="stretch">
                    {otherButtons}
                    {renderedMainButton}
                </Flex>
            </Header>

            <TextareaInput
                key={key}
                paddingLess={paddingLess}
                cy={`${cyPrefix ? `${cyPrefix}-` : ''}message-box-input`}
                hotkeys="alt+m"
                overBackground="front.background"
                escKeyOnceIsEnough={escKeyOnceIsEnough}
                hotkeysScope={pageId}
                forceValue={forceValue}
                ref={setTextarea}
                hotkeysClueDirection="bottom-right"
                skipAnimation
                minHeight={50}
                placeholder={placeholder}
                onChange={handleTextChange}
            />
        </Holder>
    )
}

const Header = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -5px;
    background-color: transparent;
`
const Holder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: sticky;
    bottom: 0;
    background-color: transparent;

    @media print {
        display: none;
    }
`
