import React from 'react'
import { Styles, styles } from './styles'
import { cn } from '../utils'

export const Separator: React.FC<Styles> = (props) => {
    const { color, shade, opacity } = props

    const classnames = cn(styles({ color, shade, opacity }))

    return <div className={classnames} />
}
