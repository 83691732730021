/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from 'deox'
import { snakeCase } from 'lodash'
import { put, all, select } from 'redux-saga/effects'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { DELETE, GET, PATCH, POST } from '../../generators/networking'
import { resourceLinksForLocalFields } from '../merchantAccounts/sagas'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../toasts/actions'
import { FetchAllPages } from '../utils'
import { WatcherDispatchFail, WatcherDispatchStart, WatcherDispatchSuccess } from '../watcher/actions'
import { ContractTemplateActions } from './actions'
import { ContractTemplate } from './types'
import moment from 'moment'
import { deoxWaitFor } from '../applications/utils'

export const NEW_CONTRACT_TEMPLATE_SUFFIX = '«Blank Template»'
export const ContractTemplatesSagas = {
    *BOOTSTRAP({ payload: { watcherId, currency } }: ActionType<typeof ContractTemplateActions.BOOTSTRAP>) {
        yield put(WatcherDispatchStart([watcherId]))
        const agentEmail = yield select((state: RootState) => state.auth.user?.email)

        const { success, code, cleanedResponse } = yield POST({
            url: `${import.meta.env.VITE_CUTTER_ROOT}/contract_templates`,
            body: {
                label: `${NEW_CONTRACT_TEMPLATE_SUFFIX} · ${agentEmail || 'unknown agent'} · ${moment().format(
                    'D MMM'
                )}`,
                currency: currency
            },
            include: ['self'],
            successText: 'Contract template created successfully.',
            skipErrorCode: [409],
            onSuccessDispatch: (r) => ContractTemplateActions.STORE_SINGLE(r)
        })

        if (!success) {
            if (code === 409) {
                yield put(
                    ToastsDispatchPush(
                        'Failed to create the template',
                        'error',
                        undefined,
                        'normal',
                        <Flex column>
                            <Text bold>Another default template exists for this particular gateway.</Text>
                            <Spacer height={10} />
                            <Text>
                                Please try to mark this template as non-default, or to update the other template to
                                non-default.
                            </Text>
                        </Flex>
                    )
                )
            } else {
                yield put(
                    ToastsDispatchPush(
                        'An error occured while creating the contract template.',
                        'error',
                        undefined,
                        'normal',
                        undefined
                    )
                )
            }
        } else {
            yield put(
                WatcherDispatchSuccess(
                    [watcherId],
                    'Template bootstrapped successfully. Can now be edited.',
                    cleanedResponse.id
                )
            )
        }
    },
    *UPDATE({ payload: { watcherId, id, template, changes } }: ActionType<typeof ContractTemplateActions.UPDATE>) {
        yield put(WatcherDispatchStart([watcherId]))

        const FieldToLink = yield resourceLinksForLocalFields(template, JSON.parse(JSON.stringify(changes)))
        const PostConfig: any = {}

        Object.keys(changes).forEach((key) => {
            const link = FieldToLink[key]
            if (!link) return

            const newKey = snakeCase(key.split('.').pop())

            PostConfig[link] = {
                ...(PostConfig[link] || {}),
                [newKey]: changes[key].to || 0
            }
        })

        let encounteredErrors = false

        // eslint-disable-next-line no-restricted-syntax
        const requests = Object.keys(PostConfig).map((link) => {
            const body = {
                url: link,
                successCode: 200,
                body: PostConfig[link],
                include: ['self'],
                errorText: 'Failed to update section'
            }
            return link.includes('contract_templates') ? PATCH(body) : POST(body)
        })

        ;((yield all(requests)) as any[]).forEach(({ success }) => {
            if (!success) encounteredErrors = true
        })

        yield put(ContractTemplateActions.FETCH_SINGLE(id))
        yield deoxWaitFor(ContractTemplateActions.STORE_SINGLE, (p) => p.template.id === id)

        if (!encounteredErrors) {
            yield put(WatcherDispatchSuccess([watcherId], 'Contract template updated successfully'))
        } else {
            yield put(WatcherDispatchFail([watcherId], 'Failed to update the contract template'))
        }
    },
    *DELETE({ payload: { watcherId, selfLink } }: ActionType<typeof ContractTemplateActions.DELETE>) {
        yield DELETE({
            watcher: watcherId,
            url: selfLink,
            successCode: 200,
            errorText: 'Failed to remove contract template.',
            successText: 'Contract template removed successfully.',
            onSuccessDispatch: (r) => ContractTemplateActions.UNSTORE(r.id)
        })
    },
    *FETCH_ALL({}: ActionType<typeof ContractTemplateActions.FETCH_ALL>) {
        try {
            const response = yield* FetchAllPages<ContractTemplate>(
                `${import.meta.env.VITE_CUTTER_ROOT}/contract_templates`,
                (acc, res) => {
                    return [...acc, ...res.contractTemplates]
                },
                ['self', 'contract_templates']
            )

            if (response) {
                yield put(ContractTemplateActions.STORE_ALL(response))
            } else {
                yield put(ToastsDispatchPush('Failed to load the contract templates.', 'error'))
            }
        } catch (e) {
            yield put(ToastsDispatchPush('Failed to load the contract templates.', 'error'))
        }
    },
    *FETCH_SINGLE({ payload }: ActionType<typeof ContractTemplateActions.FETCH_SINGLE>) {
        yield GET({
            url: `${import.meta.env.VITE_CUTTER_ROOT}/contract_templates/${payload.templateId}`,
            errorText: 'Failed to load contract template',
            onSuccessDispatch: (t) => ContractTemplateActions.STORE_SINGLE(t),
            include: ['self']
        })
    }
}
