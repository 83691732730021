import React from 'react'
import { MonitoredChange, MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { MonitoringOtherChanges } from '../../../cards/MonitoringOtherChanges/MonitoringOtherChanges'

interface CardOtherChangesProps {
    taskId: string
    applicationId: string
    applicationData: MonitoredFetchedApplicationData
    changes: MonitoredChange[]
}

export const CardOtherChanges: React.FC<CardOtherChangesProps> = (props) => {
    const { taskId, applicationData, changes } = props

    if (changes.length === 0) {
        return null
    }

    return <MonitoringOtherChanges taskId={taskId} changes={changes} applicationData={applicationData} />
}
