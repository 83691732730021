import { useSelector } from 'react-redux'
import { Text } from '../../components/general/text'
import { DataPorviderStateAssignedAndReady } from '../../store/applicationDataProviders/types'
import { RootState } from '@/store'
import { MerchantApplicationConflictsSection } from './MerchantApplicationConflictsSection'
import { SplitRepresenter } from './MerchantApplicationConflictsSplitRepresenter'

export const MerchantApplicationConflictsCompany: React.FC<{
    applicationId: string
    showMuted: boolean
}> = ({ applicationId, showMuted }) => {
    const data = useSelector((state: RootState) => state.dataProviders.forApplication[applicationId])

    if (data.state !== 'assigned-and-ready') return null
    const d = data as DataPorviderStateAssignedAndReady

    if (d.computed.conflicts.company.conflictsCount == 0) return null

    const company = (data as DataPorviderStateAssignedAndReady).computed.conflicts.company

    return (
        <MerchantApplicationConflictsSection
            title="Company information"
            isMuteable={false}
            showEvenIfMuted={showMuted}
            isMuted={company.conflictsCount <= company.mutedConflictsCount}
            subtitle={
                <Text>
                    We’ve found{' '}
                    <Text bold>
                        {company.conflictsCount} {company.conflictsCount === 1 ? 'conflict' : 'conflicts'}{' '}
                    </Text>
                    in the Company’s information section.
                </Text>
            }
            extraTopPadding
        >
            <SplitRepresenter showMuted={showMuted} merchantId={applicationId} conflicts={company} />
        </MerchantApplicationConflictsSection>
    )
}
