import { camelCase } from 'lodash'

import styled from 'styled-components'

import { Application } from '../../store/applications/types'
import { Account } from '../general/account'
import { Flex } from '../layout/flex'
import { PrintOnly } from '../general/printOnly'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { BusinessModelBlock } from './businessModelBlock'
import { useGateways } from '../../hooks/general/useGateways'

export const PrintMerchantDetails: React.FC<{
    merchantId: string
    application?: Application
    account?: any
}> = ({ merchantId, application, account }) => {
    const gateways = useGateways()
    const gatewaysIndex = gateways.at

    return (
        <PrintOnly>
            <Flex column align="stretch" justify="flex-start" style={{ marginBottom: 25 }}>
                <Text size="xxl" style={{ marginBottom: 20 }}>
                    <Account id={merchantId} merchant />
                </Text>
                <Flex align="flex-start">
                    <ItemsColumn>
                        <Text bold>Merchant: </Text>
                        <Spacer width={3} />
                        <Text>
                            <Account id={merchantId} merchant showOnlyMID />
                        </Text>
                        <Text>
                            <Account id={merchantId} merchant />
                        </Text>
                        <Text>
                            Gateway: {application?.gatewayId ? gatewaysIndex[application.gatewayId]?.name : null}
                        </Text>
                        {account && account.data && (
                            <>
                                <Text>Country: {account?.data?.country?.toUpperCase()}</Text>
                                <Text>Currency: {account?.data?.currency}</Text>
                                <Text>MCC: {account?.data?.mcc}</Text>
                                <Text color="front.text.subtlerI">{account.data.mccDescription}</Text>
                            </>
                        )}
                    </ItemsColumn>
                    <ItemsColumn>
                        <Text bold>Business Model: </Text>
                        <Spacer width={3} />
                        <Text>
                            <BusinessModelBlock
                                businessModel={(application as any)?.businessModel}
                                shortBusinessModel={application?.shortBusinessModel}
                            />
                        </Text>
                    </ItemsColumn>
                    <ItemsColumn>
                        <Text bold>Websites: </Text>
                        <Spacer width={3} />
                        {application && (
                            <Flex column align="flex-start">
                                {[...(application.websites || [])]
                                    .map((w) => {
                                        return w && w.url ? <Text key={camelCase(w.url)}>{w.url}</Text> : null
                                    })
                                    .filter((w) => w)}
                            </Flex>
                        )}
                    </ItemsColumn>
                    <ItemsColumn>
                        <Text bold>Contact information: </Text>
                        <Spacer width={3} />
                        <Text>{application?.contact?.name}</Text>
                        <Text>{application?.contact?.phone}</Text>
                        <Text>{application?.contact?.email}</Text>
                    </ItemsColumn>
                </Flex>
            </Flex>
        </PrintOnly>
    )
}

const ItemsColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 4%;
    flex-basis: 22%;
    &:last-child {
        margin-right: 0;
    }
`
