import { SectionDocumentation } from '@/services/ongoing-due-diligence'

import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData, useReportSection } from '../../../provider/report-provider/report-provider'
import { AccountDataItems } from './account-data-items'

export const Accounts: React.FC = () => {
    const section = useReportSection<SectionDocumentation>('documentation')
    const merchantData = useReportMerchantData()

    const { application, gateway, accounts } = merchantData

    return (
        <Section>
            <Section.Content>
                <Section.Field name="accounts" data={section.accounts} />
                <Section.Spacer size="s" />
                <Section.DataItem
                    title="Estimated average transaction"
                    value={application.business_model.estimated_average_transaction_amount}
                />
                <Section.Spacer size="s" />
                <Section.DataItem
                    title="Estimated monthly turnover"
                    value={application.business_model.estimated_monthly_turnover}
                />
                <Section.Spacer size="s" />
                <Section.DataItem title="Gateway" value={gateway.name} />
                <Section.Spacer size="l" />
                <AccountDataItems accounts={accounts} />
            </Section.Content>
        </Section>
    )
}
