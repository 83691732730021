import styled, { css } from 'styled-components'

export const DropdownListItem = styled.div<{
    tableLike?: any
    isHovered?: boolean
    isHigher?: boolean
    isActive?: boolean
}>`
    padding: 8px 15px;
    margin-left: -15px;
    margin-right: -15px;
    color: ${(p) => p.theme['floating.text.strongerI']};
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;

    ${(p) =>
        p.isHigher &&
        css`
            color: ${(p) => p.theme['floating.text.strongerII']};
        `}

    ${(p) =>
        p.tableLike &&
        css`
            display: contents;

            &:last-child {
                & > * {
                    border-bottom: none !important;
                }
            }
        `}

    ${(p) =>
        p.isHovered &&
        css`
            color: ${(p) => p.theme['floating.text.subtlerI']};
            background-color: rgba(0, 0, 0, 0.1);

            .circle {
                border-color: ${(p) => p.theme['floating.text.subtlerI']};
            }
        `}

    ${(p) =>
        p.isActive &&
        css`
            color: ${(p) => p.theme['floating.text.subtlerII']};
            .circle {
                border: 1px solid ${(p) => p.theme['floating.text.subtlerII']};
            }
        `}
`
