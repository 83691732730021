import { Classification } from '@/services/risk-classification'

export const getDefaultValues = (data: Classification) => {
    const ownershipData = data.classification_data['Ownership / Management']
    const businessData = data.classification_data['Business model']
    const locationData = data.classification_data['Location']
    const othersData = data.classification_data['Others']
    const commentData = data.classification_data['Comment']

    const ownership  = {
        public_listed_company: ownershipData.public_listed_company.value,
        pep_management: ownershipData.pep_management.value,
        pep_ownership: ownershipData.pep_ownership.value,
        ownership_structure_with_more_than_three_layers: 
            ownershipData.ownership_structure_with_more_than_three_layers.value,
        public_authority: ownershipData.public_authority.value
    }

    const business  = {
        high_value_goods: businessData.high_value_goods.value,
        financial_institution: businessData.financial_institution.value,
        gambling: businessData.gambling.value,
        adult: businessData.adult.value,
        crypto: businessData.crypto.value,
        religious_charity: businessData.religious_charity.value,
        political_party: businessData.political_party.value,
        investment_scheme: businessData.investment_scheme.value,
        religious_membership_organisation: businessData.religious_membership_organisation.value
    }

    const location = {
        based_on_malta_or_cyprus: locationData.based_on_malta_or_cyprus.value
    }


    const others = {
        cross_border_bank_account: othersData.cross_border_bank_account.value,
        usd_account: othersData.usd_account.value
        
    }

    const comments = {
    comment: commentData.comment.value
    }

    return {
        ...ownership,
        ...business,
        ...location,
        ...others,
        ...comments
    }
}
