import { Flex } from '@/components/layout/flex'
import React from 'react'
import { Text } from '@/components/general/text'

interface MerchantDataItemProps {
    title: string
    value: string | React.ReactNode
}

export const MerchantDataItem: React.FC<MerchantDataItemProps> = (props) => {
    const { title, value } = props

    const valueComponent = typeof value === 'string' ? <Text>{value}</Text> : value

    return (
        <Flex marginLeft={25} justify="space-between">
            <Text color="front.text.subtlerI">{title}</Text>
            {valueComponent}
        </Flex>
    )
}
