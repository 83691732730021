/* eslint-disable max-len */
import { NeededExtraRequestsPersonWithCutterInfo } from '../../../../../../../../store/tasksCompanyChanges/types'
import { MonitoringPersonType } from '../../MonitoringPersonAdded'

export const getTexts = (type: MonitoringPersonType, person?: NeededExtraRequestsPersonWithCutterInfo) => {
    const typeString = type === 'ownership' ? 'The owner' : 'The director'
    const personName = person?.name ? person.name : typeString

    if (type === 'ownership') {
        return {
            complete: 'Ownership · A new owner has been introduced and has been added to the application.',
            muted: 'Ownership · A new owner has been introduced, but task is muted.',
            missingInfo: `Ownership · A new owner has been introduced.
            ${personName} is present, but missing some of the required information. `,
            missingPerson: `Ownership · A new owner has been introduced. ${personName} is not present in the application.`,
            general:
                'Ownership · A new owner has been introduced. Check if information needs changes in the application.'
        }
    }

    return {
        complete: 'Management · The director has changed. Director is present in the application.',
        muted: 'Management · The director has changed, but task is muted.',
        missingInfo: `Management · The director has changed.
        ${personName} is present, but missing some of the required information. `,
        missingPerson: `Management · The director has changed. ${personName} is not present in the application.`,
        general: 'Management · The director has changed. Check if information needs changes in the application.'
    }
}
