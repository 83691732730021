import { useReconcileVoucher } from '@/services/reconciliation'
import { useFormContext } from 'react-hook-form'
import { useReconciliationParams } from '.'

export const useReconcileVoucherActions = () => {
    const { id } = useReconciliationParams()

    const [reconcileVoucher, reconciliationResult] = useReconcileVoucher()

    const isLoading = reconciliationResult.isLoading
    const methods = useFormContext()

    const reconcile = async () => {
        const createVoucherPayload = {
            voucherId: id,
            data: {
                posting_ids: methods.getValues('posting_ids'),
                note: methods.getValues('note')
            }
        }

        const reconciliation = await reconcileVoucher(createVoucherPayload).unwrap()

        methods.reset()

        return { reconciliation }
    }

    return { isLoading, reconcile }
}
