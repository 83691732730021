import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { RootState } from '@/store'
import { WatcherID, WatcherState } from '../../store/watcher/types'
import { watcherSelector, watcherSelectorWithContext } from '../../utils/watchers'

export const useWatcher = (): [WatcherState | undefined, () => string] => {
    const [watcherId, setWatcherId] = useState<WatcherID | undefined>(undefined)
    const watcher: WatcherState | undefined = useSelector((state: RootState) => {
        return watcherSelector(state, watcherId)
    })

    const createNewId = () => {
        const id = uuid()
        setWatcherId(id)
        return id
    }

    return [watcher, createNewId]
}

export function useNamedWatcherWithContext(name?: string): [WatcherState | undefined, WatcherID, any] {
    const generatedID = useRef<string>(uuid())
    const watcher = useSelector((state: RootState) => {
        return watcherSelectorWithContext(state, name || generatedID.current)
    })
    return [watcher?.state, name || generatedID.current, watcher?.context]
}

export function useNamedWatcher(name?: string): [WatcherState | undefined, WatcherID] {
    const generatedID = useRef<string>(uuid())
    const watcher = useSelector((state: RootState) => {
        return watcherSelector(state, name || generatedID.current)
    })
    return [watcher, name || generatedID.current]
}
