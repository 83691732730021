import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Floater } from '../../../components/layout/floater'
import { FloaterInset } from '../../../components/layout/floaterInset'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { useOnHoverOutside } from '../../../hooks/general/useOnHoverOutside'
import { Icon } from '../../../components/icons/icon'
import {
    MerchantApplicationStateControlsTypes,
    MerchantApplicationStatusAction
} from './Application.StateControlsTypes'
import { DropdownSelectableList } from '../../../components/forms/dropdownSelectableList'

export const MerchantApplicationStateControlsMoreButton: React.FC<{
    actions: {
        name: MerchantApplicationStatusAction
        link: string
    }[]
    onSelect?: (item: MerchantApplicationStatusAction) => void
}> = ({ actions, onSelect }) => {
    const [shouldShow, setShouldShow] = useState(false)
    const [anchorRef, setAnchorRef] = useRefTaker()
    const [containerRef, setContainerRef] = useRefTaker()

    useOnHoverOutside(
        containerRef,
        useCallback(() => {
            setShouldShow(false)
        }, [setShouldShow])
    )

    const handleSelect = useCallback(
        (item) => {
            setShouldShow(false)
            onSelect?.(item)
        },
        [onSelect]
    )

    return (
        <Container ref={setContainerRef}>
            <div ref={setAnchorRef}>
                <SimpleButton
                    background="front.background"
                    cy="controls-more"
                    onClick={() => {
                        setShouldShow((s) => !s)
                    }}
                    hotkeys="alt+m"
                    hotkeysScope="Merchant.ApplicationPage"
                    hotkeysClueDirection="bottom-left"
                >
                    <ButtonInset>
                        <IconHolder>
                            <Icon type="ellipsis" />
                        </IconHolder>
                    </ButtonInset>
                </SimpleButton>
            </div>
            <Floater
                cardId="Merchant.ApplicationPage.StateControlMoreButton"
                noStackSync
                shouldShow={shouldShow}
                anchor={anchorRef}
                onHide={() => {
                    setShouldShow(false)
                }}
            >
                <FloaterInset>
                    <DropdownSelectableList
                        noBullets
                        onSelect={handleSelect}
                        dropdownId="Merchant.ApplicationPage.StateControlMoreButton"
                        items={actions.map((i) => i.name)}
                        textForItem={(item: any) => {
                            return (MerchantApplicationStateControlsTypes as any)[item].label
                        }}
                    />
                </FloaterInset>
            </Floater>
        </Container>
    )
}

const Container = styled.div`
    display: contents;
`

const IconHolder = styled.div`
    min-height: 18px;
    display: flex;
    align-items: center;
`
