import { camelCase, snakeCase, startCase } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Sidebar } from '../../components/listPages/sidebarNavigation/sidebar'
import { AgentBubble } from '../../components/general/agentBubble'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { RelativeDate } from '../../components/general/relativeDate'
import { Table, TableRow } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { useListPage } from '../../hooks/pages/useListPage'
import { MerchantWebsites } from './ApplicationsEntryWebsite'
import { ApplicationsDispatchLoadEntries } from '../../store/applications/actions'
import {
    ApplicationFiltersParams,
    ApplicationFilterToHumanizedStates,
    ApplicationsFilters,
    ApplicationTypeToColumnsSetting
} from '../../store/applications/types'
import { DashboardEntriesTypes } from '../../store/dashboard/types'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../utils'
import { ListMerchantTags } from '../Merchant/MerchantSidebarButtonTags'
import { ApplicationsSidebar } from './ApplicationsSidebar'
import { useLocation } from 'react-router-dom'
import { ListPageFilterAgent } from '@/components/listPageFilters/listPageFilterAgent'
import { ListPageFilterDate } from '@/components/listPageFilters/listPageFilterDate'
import { ListPageFilterGateway } from '@/components/listPageFilters/listPageFilterGateway'
import { RenderFilters } from '@/components/taskPages/renderFilters'

const loadSummaries = (filters: ApplicationsFilters) => {
    return ApplicationsDispatchLoadEntries(filters)
}

export const Applications: React.FC = () => {
    const dashboard = useSelector((state: RootState) => state.dashboard)
    const summaries = useSelector((state: RootState) => {
        return state.applications.table
    })

    const location = useLocation()

    const { filters, paginationNode } = useListPage<ApplicationsFilters>(
        'ApplicationsList',
        'Applications',
        loadSummaries,
        ApplicationFiltersParams,
        summaries,
        '/disputes/',
        location,
        {
            applications_status: 'submitted',
            applications_page: 1
        },
        {
            pageKey: 'applications_page',
            perPageKey: 'applications_per_page'
        }
    )

    const type = (filters.get?.applications_status as DashboardEntriesTypes) || 'submitted'

    const details = useMemo(() => {
        if (!type) return undefined

        const structure = ApplicationFilterToHumanizedStates[type]

        return {
            title: uppercaseFirstLetter(structure.with ? structure.with : 'Applications'),
            subtitle: uppercaseFirstLetter(structure.thatAre)
        }
    }, [type])

    const columnKeys = useMemo(() => {
        if (!type) return []
        return ApplicationTypeToColumnsSetting[type]
    }, [type])

    const columns = useMemo(() => {
        return columnKeys.map((i) => ({
            text: getColumnTitle(i),
            key: camelCase(i),
            loaderSize: getColumnLoaderSize(i),
            alignRight: ['submittedAt', 'enteredStateAt'].includes(i)
        }))
    }, [columnKeys])

    const columnLayouts = useMemo(() => {
        return columnKeys
            .map((i) => {
                if (i === 'tags') return 'minmax(120px, 1fr)'
                if (i === 'website') return '1fr'
                if (i === 'company') return 'minmax(250px, 1.5fr)'
                return 'min-content'
            })
            .join(' ')
    }, [columnKeys])

    const results = useMemo(() => {
        return summaries.all.map((id: string) => {
            const a = summaries.at[id]

            return {
                type: 'normal',
                key: '-',
                link: `/merchant/${a.id}/summary`,
                items: columnKeys.map((c) => ({
                    node: renderCell(a[c], c)
                }))
            } as TableRow
        })
    }, [summaries.all, summaries.at, columnKeys])

    const rowsCount = useMemo(() => {
        if (dashboard.stats[type]?.count === 0) return 0
        if (!dashboard.stats[type]?.count) return 20
        if (dashboard.stats[type]?.count > 20) return 20
        return dashboard.stats[type]?.count
    }, [dashboard, type])

    const filterItems = {
        ...(
            !["submitted", "new"].includes(type) && 
            {'Agent': {
                type: 'custom',
                node: <ListPageFilterAgent<ApplicationsFilters> 
                    isDark={true} 
                    filters={filters} 
                    fieldKey="applications_agent" />
            }}
        ),
        'Gateway': {
            type: 'custom',
            node: <ListPageFilterGateway<ApplicationsFilters> 
                isDark={true} 
                filters={filters} 
                fieldKey="applications_gateway" />
        },
        ...(
            type !== 'new' && {
                'Submitted after': {
                    type: 'custom',
                    node: (
                        <ListPageFilterDate<ApplicationsFilters>
                            isDark={true}
                            filters={filters}
                            filterKey="applications_start_date" />
                    )
                },
                'Submitted before': {
                    type: 'custom',
                    node: <ListPageFilterDate<ApplicationsFilters> 
                        isDark={true} 
                        filters={filters} 
                        filterKey="applications_end_date" />
                }
            }
        ),
    }

    return (
        <Flex align="stretch">
            <Sidebar hotkeysScope="ApplicationsList" />
            <ApplicationsSidebar filters={filters} />
            <PageContent marginLeft={0} noVerticalPadding>
                {details && 
                    <PageHeader noBottomBorder title={details.title} 
                        rightSideMemo={
                            <RenderFilters
                                filters={filters}
                                summaries={summaries}
                                hotkeysScope="ApplicationsList"
                                items={filterItems}
                                entityName="applications"
                                cellHorizontalTemplate="110px auto"
                                inline
                            />
                        }
                        subtitle={details.subtitle}
                    />}
                <Card>
                    <CardInset>
                        <Table
                            background="front.background"
                            cols={columns}
                            columnLayout={columnLayouts}
                            showLastRowBorder
                            displayLoader={summaries.loadingStatus !== 'done'}
                            loaderStyles={{ minHeight: '90vh' }}
                            loaderRows={rowsCount}
                            keepQuery
                            onColumnHeaderClicked={(cKey) => {
                                filters.set((s: any) => {
                                    return activateColumn(s, snakeCase(cKey))
                                })
                            }}
                            sortedBy={{
                                column: filters.get.applications_sort,
                                direction: filters.get.applications_direction
                            }}
                            sortExceptedColumn={['website', 'tags']}
                            rows={results}
                            emptyText="No applications found."
                        />
                    </CardInset>
                </Card>
                {summaries.loadingStatus === 'done' && paginationNode}
            </PageContent>
        </Flex>
    )
}

const activateColumn = (s: any, cKey: string) => {
    if (s.applications_sort === cKey) {
        if (s.applications_direction === 'asc') {
            return {
                ...s,
                applications_sort: cKey,
                applications_direction: 'desc'
            }
        }
        const { applications_direction, applications_sort, ...rest } = s
        return rest
    }
    return {
        ...s,
        applications_sort: cKey,
        applications_direction: 'asc'
    }
}

const renderCell = (val: any, c: string) => {
    if (!val) return <Text color="front.text.subtlerI">-</Text>
    switch (c) {
        case 'createdAt': {
            if (moment(val).isSame(moment(), 'date'))
                return (
                    <Text noWrap>
                        <RelativeDate dateString={val} />
                    </Text>
                )
            if (moment(val).isSame(moment().subtract(1, 'day'), 'date')) return <Text>Yesterday</Text>
            return (
                <Text noWrap>
                    <RelativeDate dateString={val} relativeFormat="YYYY-MM-DD" />
                </Text>
            )
        }
        case 'assignee':
            return <AgentBubble filled id={val} slim />
        case 'contractSignedAt':
        case 'agreementUpdatedAt':
        case 'enteredStateAt':
        case 'submittedAt':
            return (
                <Text color={'front.text.subtlerI'} noWrap>
                    <RelativeDate dateString={val} />
                </Text>
            )
        case 'website': {
            return <MerchantWebsites nonLinks websitesString={val} />
        }
        case 'tags': {
            if (val.length === 0) return <span />
            const tags = val.map((t: any) => t.id)
            return <ListMerchantTags tagsIds={tags} />
        }
        case 'country':
        case 'psp': {
            return (
                <Text noWrap color={'front.text.subtlerI'}>
                    {val}
                </Text>
            )
        }
        default:
            return <Text color={'front.text'}>{val}</Text>
    }
}

const getColumnTitle = (name: string) => {
    if (name === 'createdAt') return 'Started'
    if (name === 'submittedAt') return 'Submitted'
    if (name === 'contractSignedAt') return 'Idle since'
    if (name === 'enteredStateAt') return 'Idle since'
    if (name === 'agreementUpdatedAt') return 'Idle since'
    if (name.toLowerCase() === 'psp') return 'Gateway'
    if (name.toLowerCase() === 'merchantId') return 'MID'
    return startCase(name).replace(' time', '')
}

const getColumnLoaderSize = (name: string) => {
    if (name.toLowerCase() === 'country') return { min: 20, max: 20 }
    if (['enteredStateAt', 'createdAt', 'submittedAt'].includes(name)) return { min: 30, max: 110 }
    if (name.toLowerCase() === 'psp') return { min: 60, max: 100 }
    if (name.toLowerCase() === 'website') return { min: 60, max: 300 }
    if (name.toLowerCase() === 'tags') return { min: 50, max: 200 }
    if (name === 'merchantId') return { min: 55, max: 55 }
    if (name.toLowerCase() === 'assignee') return { min: 30, max: 30 }
}
