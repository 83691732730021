import { useEffect } from 'react'

export const useDocumentScrollOverflowDisabler = (): void => {
    useEffect(() => {
        document.getElementsByTagName('html')[0].style.overflowY = 'initial'
        return () => {
            document.getElementsByTagName('html')[0].style.overflowY = 'scroll'
        }
    }, [])
}
