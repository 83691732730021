import React, { ReactElement, useCallback } from 'react'

import { TextInputSelectMultiple } from '../forms/textInputSelectMultiple'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

export function ListPageFilterPresetMIDs<T>({
    filters,
    fieldKey,
    options,
    isDark,
    secondFilterKey,
    textForItem
}: {
    filters: FiltersController<T>
    fieldKey: keyof FiltersController<T>['get']
    options: string[]
    secondFilterKey?: keyof FiltersController<T>['get']
    isDark: boolean
    textForItem?: (key: any) => any
}): ReactElement {
    const defaultTextForItem = useCallback((key) => {
        return key
    }, [])

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                [fieldKey]: items?.length ? items : undefined,
                ...(secondFilterKey ? { [secondFilterKey]: 'account' } : {})
            }))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldKey, secondFilterKey]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelectMultiple
                preSelectedItems={filters.get[fieldKey]}
                isHigher={isDark}
                isSeamless
                textForItem={textForItem || defaultTextForItem}
                dropdownId="ListPage.Filters.DisputeType"
                overBackground={isDark ? 'floating.background' : 'side.background'}
                onSelect={onSelect}
                cy="type-filter"
                placeholder="-"
                items={options}
                selectionLabel="type/types"
            />
            <SelectedListForListPageFilters<T>
                filterKey={fieldKey}
                isDark={isDark}
                secondFilterKey={secondFilterKey}
                filters={filters}
                noHolderEmphasize={isDark}
                textForItem={textForItem || defaultTextForItem}
            />
        </FilterPageHolder>
    )
}
