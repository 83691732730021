import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('leading-normal align-middle', {
    variants: {
        color: {
            black: 'text-black dark:text-neutral-200',
            neutral: 'text-neutral-500 dark:text-neutral-400'
        }
    },
    defaultVariants: {
        color: 'neutral'
    }
})
