import { Card, Pre, Text } from '@/design-system'
import { formatXml } from '@/utils/formatXml'
import React from 'react'

interface RawDataProps {
    raw_type: string
    raw: string
}

const RawData: React.FC<RawDataProps> = (props) => {
    const { raw_type, raw } = props

    const type = raw_type.toLowerCase()

    let content
    if (type === 'json') {
        content = JSON.stringify(JSON.parse(raw), null, 4)
    } else if (type === 'xml') {
        content = formatXml(raw)
    } else {
        content = raw
    }

    return (
        <Card variant="surface" color="neutral">
            <Card.Body>
                <Text family="mono" size="xs" weight="medium">
                    <Pre whiteSpace="preWrap">{content}</Pre>
                </Text>
            </Card.Body>
        </Card>
    )
}

export { RawData }
