import { GET } from '../../generators/networking'
import {
    SagasForSearch,
    SearchActionFindResults,
    SearchDispatchStoreResults
} from './actions'

export class SearchSagas implements SagasForSearch {
    *findResults(action: SearchActionFindResults) {
        yield GET({
            cutterUrl: (l) =>
                `${l.sherlockLink}?query=${encodeURIComponent(action.query)}${
                    action.page ? `&page=${action.page}` : ''
                }${
                    action.perPage
                        ? `&per_page=${action.perPage}`
                        : `&per_page=${10}`
                }`,
            errorText: 'Failed to load search results.',
            onSuccessDispatch: (r) =>
                SearchDispatchStoreResults(r.applications, {
                    total: r.total,
                    page: r.page,
                    perPage: r.perPage
                })
        })
    }
}
