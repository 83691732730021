import React from 'react'
import { Button } from '@/design-system'
import { useReconciliationParams, useUnreconcilePostingActions } from '../../../hooks'
import { useNavigate } from 'react-router-dom'

export const UnreconcilePosting: React.FC = () => {
    const { id } = useReconciliationParams()
    const navigate = useNavigate()
    const { isLoading, unreconcile } = useUnreconcilePostingActions()

    const handleUnreconcile = async () => {
        await unreconcile()

        navigate(`/more/reconciliation-postings/${id}/reconcile`)
    }

    return (
        <Button variant="link" loading={isLoading} onClick={handleUnreconcile}>
            Unreconcile
        </Button>
    )
}
