// eslint-disable-next-line max-len
import { useApplicationCompanyPeople } from '../../../../../api'
import React from 'react'
import { useReportParams } from '../../../../../hooks'
import { PersonFiles } from './person-files'

interface PersonFilesDataItemsProps {
    personId: string
}

export const PersonFilesDataItems: React.FC<PersonFilesDataItemsProps> = (props) => {
    const { personId } = props

    const { applicationId } = useReportParams()

    const { data, isLoading, isError } = useApplicationCompanyPeople(applicationId)

    if (!data || isLoading || isError) {
        return null
    }

    const person = data.embedded['ch:people'].find((person) => person.id === personId)
    const filesUrl = person?.relations?.files

    if (!filesUrl) {
        return null
    }

    return <PersonFiles filesUrl={filesUrl} />
}
