import React from 'react'
import { Flex } from '../../components/layout/flex'
import { PageContent } from '../../components/layout/pageContent'
import { Sidebar } from '../../components/listPages/sidebarNavigation/sidebar'
import { ManageSidebar } from './MoreSidebar'
import { Outlet } from 'react-router-dom'

export const Manage: React.FC = () => {
    return (
        <Flex align="stretch">
            <Sidebar hotkeysScope="ApplicationsList" />
            <ManageSidebar />
            <PageContent marginLeft={0} noVerticalPadding>
                <Outlet />
            </PageContent>
        </Flex>
    )
}
