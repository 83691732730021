import { ActionType } from 'deox'
import { put, select } from 'redux-saga/effects'

import { POST } from '../../../generators/networking'
import { RootState } from '@/store'
import { ApplicationInternalsStampingActions } from '../stamping/actions'
import { ApplicationInternalsChecksActions } from './actions'
import { MerchantApplicationsInternalsChecksState } from './types'

export const ApplicationInternalsSagas = {
    *TOGGLE_SECTION_CHECK({ payload: p }: ActionType<typeof ApplicationInternalsChecksActions.TOGGLE_SECTION_CHECK>) {
        const checks = (yield select((state: RootState) => {
            return state.applicationInternals.checks.forApplication?.[p.applicationId]?.[p.checkPath]
        })) as MerchantApplicationsInternalsChecksState['forApplication']['ID']['CHECK']
        const checkField = checks?.fields?.[p.fieldKey]

        if (checkField) {
            if (checkField.isChecked) {
                yield POST({
                    watcher: p.watcherId,
                    url: checkField.checkLink,
                    successCode: 200,
                    body: {
                        checked: false
                    },
                    onSuccessDispatch: (r) => {
                        return ApplicationInternalsChecksActions.STORE_CHECK(p.applicationId, p.checkPath, p.fieldKey, {
                            isChecked: false,
                            checkLink: r.selfLink
                        })
                    },
                    include: ['self'],
                    successText: 'Section marked as unchecked.',
                    errorText: 'Failed to marke section as unchecked.'
                })
            } else {
                const { success } = yield POST({
                    watcher: p.watcherId,
                    url: checkField.checkLink,
                    successCode: 200,
                    body: {
                        checked: true
                    },
                    onSuccessDispatch: (r) => {
                        return ApplicationInternalsChecksActions.STORE_CHECK(p.applicationId, p.checkPath, p.fieldKey, {
                            isChecked: true,
                            checkLink: r.selfLink
                        })
                    },
                    include: ['self'],
                    successText: 'Section marked as checked.',
                    errorText: 'Failed to marke section as checked.'
                })

                if (success && checkField.checkLink.includes('/companies/')) {
                    yield put(ApplicationInternalsStampingActions.REFETCH(p.applicationId))
                }
            }
        } else if (checks?.sectionLink) {
            yield POST({
                watcher: p.watcherId,
                url: checks.sectionLink,
                successCode: 201,
                body: {
                    checked: true,
                    field: p.fieldKey
                },
                onSuccessDispatch: (r) => {
                    return ApplicationInternalsChecksActions.STORE_CHECK(p.applicationId, p.checkPath, p.fieldKey, {
                        isChecked: true,
                        checkLink: r.selfLink
                    })
                },
                include: ['self'],
                successText: 'Section marked as checked.',
                errorText: 'Failed to marke section as checked.'
            })
        } else {
            throw new Error(`Could not find a section link for ${p.checkPath}.`)
        }
    }
}
