import styled, { css } from 'styled-components'

export type TButtonInsetPadding = 'small' | 'medium' | 'tiny' | 'larger'
export type TNegativeMarginProp = 'left' | 'horizontal'

export const ButtonInset: React.FC<{
    children?: any
    padding?: TButtonInsetPadding
    inversePadding?: boolean
    negativeMargin?: TNegativeMarginProp
    equalPadding?: boolean
    noHorizontalPadding?: boolean
    noVerticalPadding?: boolean
    leftAlign?: boolean
    fullWidth?: boolean
}> = ({
    padding = 'small',
    equalPadding,
    children,
    negativeMargin,
    fullWidth,
    inversePadding,
    leftAlign = false,
    noHorizontalPadding = false,
    noVerticalPadding = false
}) => {
    return (
        <Inset
            padding={padding}
            equalPadding={equalPadding}
            negativeMargin={negativeMargin}
            leftAlign={leftAlign}
            fullWidth={fullWidth}
            inversePadding={inversePadding}
            noHorizontalPadding={noHorizontalPadding}
            noVerticalPadding={noVerticalPadding}
        >
            {children}
        </Inset>
    )
}

const Inset = styled.div<{
    padding?: TButtonInsetPadding
    equalPadding?: boolean
    negativeMargin?: TNegativeMarginProp
    leftAlign?: boolean
    noHorizontalPadding?: boolean
    inversePadding?: boolean
    fullWidth?: boolean
    noVerticalPadding?: boolean
}>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    white-space: nowrap;
    ${(p) =>
        p.fullWidth &&
        css`
            width: 100%;
            align-self: stretch;
        `}
    ${(p) => {
        let padding = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
        const margin = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }

        if (p.equalPadding) {
            switch (p.padding) {
                case 'tiny':
                    padding = {
                        left: 3,
                        right: 3,
                        top: 0,
                        bottom: 0
                    }
                    break
                case 'small':
                    padding = {
                        left: 4,
                        right: 4,
                        top: 4,
                        bottom: 4
                    }
                    break
                case 'medium':
                    padding = {
                        left: 7,
                        right: 7,
                        top: 7,
                        bottom: 7
                    }
                    break
                case 'larger':
                    padding = {
                        left: 12,
                        right: 12,
                        top: 12,
                        bottom: 12
                    }
                    break
            }
        } else {
            switch (p.padding) {
                case 'tiny':
                    padding = {
                        left: 10,
                        right: 10,
                        top: 1,
                        bottom: 0
                    }
                    break
                case 'small':
                    padding = {
                        left: 10,
                        right: 10,
                        top: 5,
                        bottom: 4
                    }
                    break
                case 'medium':
                    padding = {
                        left: 20,
                        right: 20,
                        top: 8,
                        bottom: 7
                    }
                    break
                case 'larger':
                    padding = {
                        left: 25,
                        right: 25,
                        top: 12,
                        bottom: 11
                    }
                    break
            }
        }

        if (p.noVerticalPadding) {
            padding.top = 0
            padding.bottom = 0
        }

        if (p.noHorizontalPadding) {
            padding.left = 0
            padding.right = 0
        }

        switch (p.negativeMargin) {
            case 'horizontal':
                margin.left = 0 - padding.left
                margin.right = 0 - padding.right
                break
            case 'left':
                margin.left = 0 - padding.left
                break
        }

        return css`
            margin: ${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px;
            ${p.inversePadding
                ? `
                    margin: -${padding.top}px -${padding.right}px -${padding.bottom}px -${padding.left}px;
                    max-width: calc(100% - ${padding.right + padding.left});
                `
                : `
                    padding: ${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px;
                    max-width: 100%;
                `};
        `
    }}

    ${(p) =>
        p.leftAlign &&
        css`
            justify-content: flex-start;
            text-align: left;
        `}
`
