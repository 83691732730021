import { v4 as uuid } from 'uuid'

import { GET } from '../../generators/networking'
import { DashboardActionLoadStats, DashboardDispatchStoreStats, SagasForDashboard } from './actions'
import { DashboardStats } from './types'

export class DashboardSagas implements SagasForDashboard {
    *loadStats(action: DashboardActionLoadStats) {
        yield GET({
            cutterUrl: (l) => l.dashboardLink,
            include: ['dashboard', 'self'],
            onSuccessDispatch: (r) => {
                const stats = r.dashboard as []
                const storePayload: DashboardStats[] = []

                stats.map((v: any) => {
                    storePayload.push({
                        state: v.state,
                        count: v.count,
                        link: v.selfLink
                    })
                })

                return DashboardDispatchStoreStats(uuid(), storePayload)
            },
            errorText: 'Failed to load number of applications statistcs.'
        })
    }
}
