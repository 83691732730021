import { startCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { LinkButton } from '../../components/buttons/linkButton'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { List } from '../../components/layout/list'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { Spacer } from '../../components/layout/spacer'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { IconCheckmark } from '../../components/icons/iconCheckmark'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { ApplicationInternalsTagsActions } from '../../store/applicationInternals/tags/actions'
import { ApplicationInternalTag } from '../../store/applicationInternals/tags/types'
import { RootState } from '@/store'
import { LoaderView } from '../../components/loaders/loader'
import { useNavigate, useParams } from 'react-router-dom'

const SELECTABLE_COLORS = [
    'E6194B',
    '3CB44B',
    'FFE119',
    '4363D8',
    'F58231',
    '911EB4',
    '46F0F0',
    'F032E6',
    'BCF60C',
    'FABEBE',
    '008080',
    'E6BEFF',
    '9A6324',
    'FFFAC8',
    '800000',
    'AAFFC3',
    '808000',
    'FFD8B1',
    '000075',
    '808080',
    '000000'
]

export const MerchantTagsManagerEdit: React.FC = () => {
    useModalStackSync('Merchant tags Manager', 'Merchant.TagManager', undefined)
    const params = useParams()
    const { tagId, id } = params || {}
    const backTo = `/merchant/${id}/tags`
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [saveWatcher, saveWatcherId] = useNamedWatcher(`${tagId}.Save`)
    // Default new Tag
    const [modifiedTag, setModifiedTag] = useState<Partial<ApplicationInternalTag>>({
        name: '',
        visible: true,
        color: 'E6194B'
    })
    const [inputRef, setInputRef] = useRefTaker()
    const remoteTag = useSelector((state: RootState) => {
        if (id && tagId) {
            return state.applicationInternals.tags.forApplication?.[id]?.at[tagId]
        }
    })
    const tagsLoaded = useSelector((state: RootState) => {
        if (id) {
            return state.applicationInternals.tags.forApplication?.[id]?.loadingStatus === 'done'
        }
    })
    const isCreatingANewTag = tagId === 'new'

    useEffect(() => {
        if (remoteTag) setModifiedTag(remoteTag)
    }, [setModifiedTag, remoteTag])

    useEffect(() => {
        if (saveWatcher === 'success') {
            navigate(backTo)
        }
    }, [saveWatcher, backTo, navigate])

    useEffect(() => {
        inputRef?.focus()
    }, [inputRef])

    const colorPalette = useMemo(() => {
        return (
            <ColorHolder>
                <ColorsNegativeInset>
                    {SELECTABLE_COLORS.map((c) => {
                        return (
                            <Color
                                key={c}
                                data-cy="selectable-color"
                                color={c}
                                onClick={() => {
                                    setModifiedTag({
                                        ...modifiedTag,
                                        color: c
                                    })
                                }}
                                style={{
                                    backgroundColor: c
                                }}
                            >
                                {modifiedTag?.color?.toLowerCase() === c.toLowerCase() && (
                                    <Checker>
                                        <IconCheckmark size={6} />
                                    </Checker>
                                )}
                            </Color>
                        )
                    })}
                </ColorsNegativeInset>
            </ColorHolder>
        )
    }, [setModifiedTag, modifiedTag])

    const onSave = useCallback(
        (e, watcherId) => {
            if (modifiedTag?.name == undefined || modifiedTag.visible == undefined || modifiedTag.color == undefined)
                throw 'Failed to save tag. Some tag attribute are undefined.'
            if (remoteTag?.id) {
                if (!remoteTag.selfLink) throw 'Failed to save tag. Tag self link not provided.'
                if (id) {
                    dispatch(
                        ApplicationInternalsTagsActions.MODIFY(watcherId, id, remoteTag.selfLink, {
                            name: modifiedTag.name,
                            color: modifiedTag.color,
                            visible: modifiedTag.visible
                        })
                    )
                }
            } else {
                if (id) {
                    dispatch(
                        ApplicationInternalsTagsActions.CREATE(watcherId, id, {
                            name: modifiedTag.name,
                            visible: modifiedTag.visible,
                            color: modifiedTag.color
                        })
                    )
                }
            }
        },
        [modifiedTag, id, remoteTag, dispatch]
    )

    if (!isCreatingANewTag && !tagsLoaded) return <LoaderView overBackground="overlay.background" />

    return (
        <PageWrapper maxWidth="tiny" shouldCenter>
            <Card title={isCreatingANewTag ? 'Create a tag' : 'Edit tag'} backTo={backTo} higher>
                <CardInset>
                    <Spacer height={10} />
                    <List
                        background="front.background"
                        switchToRowsAt={10000}
                        cellHorizontalTemplate="100px auto"
                        items={{
                            'Tag name': {
                                key: 'tag-name',
                                type: 'input',
                                ref: setInputRef,
                                initialValue: remoteTag?.name,
                                overBackground: 'front.background',
                                onChange(e, val) {
                                    setModifiedTag({
                                        ...modifiedTag,
                                        name: val
                                    })
                                }
                            },
                            'Status': {
                                key: 'visibility',
                                type: 'select',
                                overBackground: 'front.background',
                                items: ['visible', 'invisible'],
                                cy: 'visibility',
                                selected: modifiedTag?.visible ? 'visible' : 'invisible',
                                onSelect(value) {
                                    setModifiedTag({
                                        ...modifiedTag,
                                        visible: value === 'visible'
                                    })
                                },
                                dropdownId: 'Merchant.TagManager.Edit.SelectVisibility',
                                textForItem(item) {
                                    if (item === 'invisible') return 'Hidden (Soft delete)'
                                    return startCase(item) || ''
                                }
                            },
                            'Color': {
                                type: 'custom',
                                node: colorPalette
                            }
                        }}
                    />
                    <Spacer height={40} />
                    <Flex>
                        <LinkButton to={backTo} background="front.accent.color" grow>
                            <ButtonInset>Cancel</ButtonInset>
                        </LinkButton>
                        <Spacer width={10} />
                        <WatcherButton
                            onClick={onSave}
                            background="front.accent.color"
                            hotkeys="alt+enter"
                            grow
                            predefinedWatcher={saveWatcherId}
                            hotkeysClueDirection="bottom-right"
                            hotkeysScope="Merchant.TagManager"
                        >
                            <ButtonInset>{modifiedTag ? 'Save' : 'Edit'}</ButtonInset>
                        </WatcherButton>
                    </Flex>
                </CardInset>
            </Card>
        </PageWrapper>
    )
}

const ColorHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: -4px;
    margin-right: -4px;
    padding: 0 2px;
    margin-top: 2px;
    flex-wrap: wrap;
`

const Color = styled.div<{ color: string }>`
    width: 18px;
    height: 18px;
    border-radius: 8px;
    position: relative;
    background-color: #${(p) => p.color};
    margin: 3px;
    cursor: pointer;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: 0.2s ease opacity;

    &:hover {
        opacity: 0.4;
    }
    &:active {
        opacity: 0.75;
    }
`

const ColorsNegativeInset = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    margin: 0 -6px;
    flex-wrap: wrap;
`

const Checker = styled.div`
    position: absolute;
    bottom: -2px;
    right: -2px;
    border-radius: 9999px;
    width: 12px;
    height: 12px;
    box-shadow: 0px 2px 2px rgba(10, 20, 40, 0.2);
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;
    color: #fff;
    background-color: ${(p) => p.theme['front.accent.color']};
`
