import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'

export const CompanyBusinessModel: React.FC = () => {
    const merchantData = useReportMerchantData()

    const { application } = merchantData

    const businessModel = application?.business_model?.description ? application.business_model.description : '-'

    return <Section.ExpandableDataItem title="Business model" value={businessModel} />
}
