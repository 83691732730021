import React, { useMemo } from 'react'
import moment from 'moment'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Spacer } from '../../../components/layout/spacer'
import { PresentBox } from './Accounts.ID.ContractVersionSelector'
import { Color } from '../../../styles/colors'

export const ContractEffectivePeriod: React.FC<{ to: string; from: string; color?: Color }> = ({
    to,
    from,
    color = 'back.text.strongerI'
}) => {
    const prefix = useMemo(
        () => (
            <>
                <Text color={color}>Effective between&nbsp;</Text>
                {from}
                <Spacer width={5} />
                <Icon type="arrow" />
                <Spacer width={5} />
            </>
        ),
        [from, color]
    )

    if (to === 'Present') {
        if (moment(from, 'DD MMM, YYYY').isAfter(moment())) {
            return <Text>Will be effective from {from}</Text>
        }
        return (
            <>
                {prefix}
                <PresentBox>Present</PresentBox>
            </>
        )
    }

    return (
        <>
            {prefix}
            {to}
        </>
    )
}
