import countries from 'i18n-iso-countries'
import { camelCase } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useIsPrint } from '../../../hooks/general/useIsPrint'
import { Task } from '../../../store/tasks/types'
import { numberWithCommas } from '../../../utils'
import { Piechart } from '../../charts/piechart'
import { ButtonInset } from '../../buttons/buttonInset'
import { SimpleButton } from '../../buttons/simpleButton'
import { ColorSpot } from '../../general/colorSpot'
import { regularColorHash } from '../../general/colorTag'
import { Flex } from '../../layout/flex'
import { PrintOnly } from '../../general/printOnly'
// import { Table, Button } from "react-bootstrap"
import { Spacer } from '../../layout/spacer'
import { Table } from '../../tables/table'
import { Text } from '../../general/text'
import { ContextCardHolder } from './commons'

const MAX_NO_OF_ITEMS_SHOWN = 7
export const DistributionContextCard: React.FC<{ data?: any; task: Task }> = ({ data }) => {
    const [showAll, setShowAll] = useState(false)
    const [list, setList] = useState<any>(undefined)
    const [chartData, setChartData] = useState<any>({})
    const isPrinting = useIsPrint()

    useEffect(() => {
        if (isPrinting) setShowAll(true)
        else setShowAll(false)
    }, [isPrinting])

    useEffect(() => {
        const dataList: any = []
        const items = data.data.value
        const labels = Object.keys(items)
        const total = labels.reduce((acc, k) => {
            return (acc += items[k])
        }, 0)

        labels.map((k: any) => {
            dataList.push({
                name: countries.getName(k.toUpperCase(), 'en'),
                value: items[k],
                percentage: numberWithCommas((items[k] / total) * 100)
            })
        })

        dataList.sort((a: any, b: any) => {
            return b.value - a.value
        })

        dataList.map((d: any, i: number) => {
            if (i < MAX_NO_OF_ITEMS_SHOWN || showAll) d.shown = true
            else d.shown = false
        })

        setList(dataList)
    }, [data, showAll])

    useEffect(() => {
        if (list) {
            const shown = list.filter((item: any) => item.shown)
            const others = list.filter((item: any) => !item.shown)

            setChartData({
                ...shown.reduce((data: any, item: any) => {
                    if (item.name && item.value) data[item.name] = item.value
                    return data
                }, {}),
                'Others - show more': others.reduce((acc: number, item: any) => (acc += item.value), 0)
            })
        }
    }, [list])

    const handleShowMoreClick = useCallback(() => {
        setShowAll((active) => !active)
    }, [])

    if (!list) return null

    return (
        <ContextCardHolder>
            <PieHolder>
                <Piechart data={chartData} />
            </PieHolder>
            <RightSide>
                <PrintOnly>
                    <Spacer width={5} />
                </PrintOnly>
                <Table
                    background="front.subtleAccent.background"
                    type="narrow"
                    cols={[
                        {
                            text: 'Country'
                        },
                        {
                            text: 'Transactions',
                            alignRight: true
                        },
                        {
                            alignRight: true,
                            text: 'Percentage'
                        }
                    ]}
                    columnLayout="auto 20% 20%"
                    rows={list
                        .map((label: any, i: any) => {
                            if (!label.shown) return null
                            return {
                                type: 'normal',
                                noAction: true,
                                key: camelCase(label.name ? label.name : `Unknown Country${i}`),
                                items: [
                                    {
                                        node: (
                                            <Flex justify="flex-start" align="center">
                                                {label.name && <ColorSpot hash={regularColorHash.hex(label.name)} />}
                                                <Text size="s" style={{ marginTop: -0.5 }}>
                                                    {label.name ? label.name : 'Unknown countries'}
                                                </Text>
                                            </Flex>
                                        )
                                    },
                                    {
                                        node: (
                                            <Text size="s" mono>
                                                {numberWithCommas(label.value, true)}
                                            </Text>
                                        )
                                    },
                                    {
                                        node: (
                                            <Text size="s" mono>
                                                {label.percentage}%
                                            </Text>
                                        )
                                    }
                                ]
                            }
                        })
                        .filter((r: any) => r)}
                />
                {list.length > MAX_NO_OF_ITEMS_SHOWN && <Spacer width={5} />}
                {list.length > MAX_NO_OF_ITEMS_SHOWN && (
                    <SimpleButton onClick={handleShowMoreClick}>
                        <ButtonInset>{showAll ? `Show less` : `Show more`}</ButtonInset>
                    </SimpleButton>
                )}
            </RightSide>
        </ContextCardHolder>
    )
}

const RightSide = styled.div`
    border-left: 1px solid $light-less-2;
    padding-left: 20px;
    margin-left: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`

const PieHolder = styled.div`
    width: 210px;
    height: 210px;
    margin-right: 20px;

    @media print {
        min-width: 170px;
        min-height: 170px;
        height: 170px;
        margin-top: 10px;
        page-break-inside: avoid !important;
    }
`
