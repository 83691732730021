import React from 'react'
import { Text } from '@/components/general/text'
import { useGetRecord } from '@/services/merchant-api'

interface CompanyNameProps {
    applicationId: string
}

export const CompanyName: React.FC<CompanyNameProps> = (props) => {
    const { applicationId } = props

    const url = `${import.meta.env.VITE_API_ROOT}/records/${applicationId}`
    const { data, isLoading, isError } = useGetRecord(url)

    const noData = !data || !data.name

    if (isLoading || isError || noData) {
        return (
            <Text noWrap bold>
                -
            </Text>
        )
    }

    return (
        <Text noWrap bold>
            {data.name}
        </Text>
    )
}
