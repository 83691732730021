import { LoadingStatus } from '../../utils'

export interface DashboardStat {
    count: number
    link: string
}

export interface DashboardStats {
    [key: string]: DashboardStat
}

export interface DashboardState {
    stats: DashboardStats
    loadingStatus: LoadingStatus
}

export const InitialDashboardState: DashboardState = {
    stats: {},
    loadingStatus: 'not-started'
}

export type DashboardEntriesTypes =
    | 'new'
    | 'submitted'
    | 'under_review'
    | 'more_information'
    | 'declined'
    | 'pre_accepted'
    | 'needs_approval'
    | 'archived'
    // ------------------
    | 'agreements.new'
    | 'agreements.needs_approval'
    | 'contracts.needs_signature'
    | 'contracts.declined'
    // ------------------
    | 'accounts.in_progress'
    | 'accounts.live'
    | 'accounts.closed'

export const DashboardStatsDictionary: { [key: string]: string } = {
    'new': 'Unsubmitted',
    'submitted': 'Submitted',
    'under_review': 'Under review',
    'more_information': 'Needs information',
    'declined': 'Declined',
    'pre_accepted': 'Pre-accepted',
    'needs_approval': 'Needs approval',
    'archived': 'Archived',
    // ------------------
    'agreements.new': 'In progress',
    'agreements.needs_approval': 'Needs approval',
    'contracts.needs_signature': 'Needs signature',
    'contracts.declined': 'Declined',
    // ------------------
    'accounts.in_progress': 'In progress',
    'accounts.live': 'Live',
    'accounts.closed': 'Closed'
}
