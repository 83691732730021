import { createReducer } from 'deox'

import { produce } from 'immer'
import { ApplicationInternalsActions } from '../actions'

import { ApplicationInternalsReferralActions } from './actions'
import { InitialApplicationInternalsReferralState } from './types'

export const ApplicationInternalsReferralReducer = createReducer(
    InitialApplicationInternalsReferralState,
    (handleAction) => [
        handleAction(ApplicationInternalsReferralActions.FETCH, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft[p.applicationId] = {
                    ...draft[p.applicationId],
                    loadingStatus: 'started'
                }
            })
        }),
        handleAction(ApplicationInternalsReferralActions.STORE, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft[p.applicationId] = {
                    activeReferrals: [...(p.ids || [])],
                    loadingStatus: 'done'
                }
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = { ...InitialApplicationInternalsReferralState }
                return draft
            })
        })
    ]
)
