import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Flex } from '../../components/layout/flex'
import { LoaderView } from '../../components/loaders/loader'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { MerchantDetailsSidebar } from '../../components/navigation/merchantDetailsSidebar'
import { ModalHeader } from '../../components/modals/modalHeader'
import { TransactionCard } from './Transactions.IDCard'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { RootState } from '@/store'
import {
    TransactionDispatchLoad,
    TransactionDispatchLoadExtraDetails,
    TransactionDispatchUnstore
} from '../../store/transactions/actions'
import { TransactionModalTrail } from './Transactions.IDTrail'
import { shortenId } from '../../components/general/idShortener'
import { TransactionAppendix } from './Transactions.IDAppendix'
import { useParams } from 'react-router-dom'

type Params = { id: string; transactionId: string }

export const TransactionModal: React.FC = () => {
    const params = useParams() as Params

    const dispatch = useDispatch()
    const transactionId = params.transactionId
    const transaction = useSelector((state: RootState) => {
        return state.transactions.transactions.at[transactionId]
    })
    const title = useMemo(() => {
        if (transaction?.extraDetails?.account?.name)
            return `Transaction #${shortenId(transaction.id)} • ${transaction?.extraDetails?.account?.name}`
        return `Loading…`
    }, [transaction])
    useModalStackSync(title, 'TransactionPage', 0)

    useEffect(() => {
        dispatch(TransactionDispatchLoad(transactionId))
        return () => {
            dispatch(TransactionDispatchUnstore(transactionId))
        }
    }, [dispatch, transactionId])

    useEffect(() => {
        if (transaction?.id && transaction?.extraDetails?.loadingStatus == 'not-started') {
            dispatch(TransactionDispatchLoadExtraDetails(transaction))
        }
    }, [dispatch, transaction])

    const pageContent = useMemo(() => {
        let backLink = '/transactions'
        if (params.id) {
            backLink = `/merchant/${params.id}/transactions`
        }

        if (!transaction || transaction.loadingStatus != 'done')
            return <LoaderView key="timelineLoader" overBackground="back.background" />
        return (
            <>
                <ModalHeader pageId="TransactionPage" backTo={backLink}>
                    <PageWrapper maxWidth="large">
                        <PageHeader insetLeft title="Transaction" subtitle="" noBottomBorder />
                    </PageWrapper>
                </ModalHeader>
                <PageWrapper maxWidth="large">
                    <TransactionCard transaction={transaction} />
                    <TransactionModalTrail transaction={transaction} />
                    <TransactionAppendix transaction={transaction} />
                </PageWrapper>
            </>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction])

    return (
        <Flex align="stretch">
            <PageContent marginLeft={0} noPadding>
                {pageContent}
            </PageContent>
            <MerchantDetailsSidebar
                isLoading={transaction?.extraDetails?.loadingStatus != 'done'}
                merchantId={transaction?.accountId}
                account={transaction?.extraDetails?.account}
                application={transaction?.extraDetails?.application}
            />
        </Flex>
    )
}
