import { SubPageHeader } from '@/ui'
import React from 'react'

interface HeaderSectionProps {
    showAction: boolean
    onBackClick: () => void
    onActionClick: () => void
}

export const HeaderSection: React.FC<HeaderSectionProps> = (props) => {
    const { showAction, onBackClick, onActionClick } = props

    return (
        <SubPageHeader>
            <SubPageHeader.BackButton onClick={onBackClick} />
            <SubPageHeader.Title>Partner</SubPageHeader.Title>
            {showAction ? (
                <SubPageHeader.Action testid="add-integration-button" onClick={onActionClick}>
                    Add integration
                </SubPageHeader.Action>
            ) : (
                <SubPageHeader.Empty />
            )}
        </SubPageHeader>
    )
}
