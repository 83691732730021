import { AnyAction } from 'deox'
import { ReactElement, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { DialogContext } from '../../contexts/dialogProvider'
import { Color } from '../../styles/colors'
import { v4 as uuid } from 'uuid'

export function useDialog<T>(
    action?: (watcherId: string, params: T) => AnyAction,
    title?: string,
    description?: string | ((params: T) => string | ReactElement),
    buttonText?: string,
    buttonBackground?: Color,
    justify?: 'flex-start' | 'space-between'
): (params: T) => void {
    const dialogContext = useContext(DialogContext)
    const dispatch = useDispatch()

    const onClick = useCallback(
        (params: T) => {
            if (!action) return
            const watcherId = uuid()
            dialogContext?.setDialog({
                title: title || 'Confirm deletion',
                description: description
                    ? typeof description == 'string'
                        ? description
                        : description?.(params)
                    : 'Are you sure you want to delete this resource permanently?',
                action: {
                    label: buttonText || 'Delete permanently',
                    buttonBackground: buttonBackground || 'front.danger.color',
                    justify: justify || 'space-between',
                    watcherId,
                    action: () => {
                        dispatch(action(watcherId, params))
                    }
                }
            })
        },
        [action, title, dispatch, dialogContext, description, buttonText, buttonBackground, justify]
    )

    return onClick
}
