import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva([
    'flex',
    'h-10',
    'w-full',
    'text-md',
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
    'dark:bg-gray-900',
    'dark:caret-white',
    'dark:text-neutral-200'
])
