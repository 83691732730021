import { useCallback, useMemo } from 'react'

import { uppercaseFirstLetter } from '../../utils'
import { useQueryParams } from '../general/useQueryParam'

export type PaginationConfig = {
    pageKey: string
    perPageKey: string
}

export function useFilters(
    params?: { [key: string]: any },
    defaultFilters: { [key: string]: any } = {},
    allowedValuesDictionary?: { [key: string]: string[] },
    paginationConfig?: PaginationConfig
) {
    // const [filtersState, setFiltersState] = useState<any>(undefined)
    const filterProprieties = useMemo(() => {
        return Object.keys(params || [])
    }, [params])

    const [filtersState, setFiltersState] = useQueryParams(filterProprieties, defaultFilters)

    const filters = useMemo(() => {
        if (allowedValuesDictionary) {
            const newFilters = { ...filtersState }

            Object.keys(allowedValuesDictionary).forEach((key) => {
                if (typeof newFilters[key] === 'object') {
                    newFilters[key] = newFilters[key].filter?.((i: string) => {
                        return allowedValuesDictionary[key]?.includes(i)
                    })
                }

                if (typeof newFilters[key] === 'string') {
                    if (!allowedValuesDictionary?.[key]?.includes(newFilters[key])) {
                        delete newFilters[key]
                    }
                }

                if (newFilters[key] === null) {
                    delete newFilters[key]
                }
            })

            return {
                ...newFilters
            }
        }
        return {
            ...filtersState
        }
    }, [filtersState, allowedValuesDictionary])

    const set = useCallback(
        (change: any, doNotResetPage?: boolean) => {
            setFiltersState((s: any) => {
                const key = paginationConfig?.pageKey || 'page'
                if (typeof change === 'object') {
                    return {
                        ...change,
                        [key]: change[key] === s[key] ? 1 : change[key]
                    }
                }
                const changes = change(s)

                if (JSON.stringify(s) === JSON.stringify(changes)) return s
                return {
                    ...changes,
                    [key]: changes[key] === s[key] ? 1 : changes[key]
                }
            })
        },
        [paginationConfig, setFiltersState]
    )

    return {
        filters: {
            get: filters, // filters as any,
            clearFilters: () => {
                if (filters.applications_status){
                    return setFiltersState({
                        applications_status: filters.applications_status
                    })
                }
                return setFiltersState({})
            },
            activeFilters: () => {
                return filters
                    ? (Object.keys(filters)
                          .filter((k) => (filters[k] ? filters[k].length : 0))
                          .map((k) => {
                              const initialKey = k
                              const a = k
                                  .replace('transactions_', '')
                                  .replace('applications_', '')
                                  .replace('disputes_', '')
                                  .replace('settlements_', '')
                                  .replace('tasks_', '')
                              if (a === 'page') return undefined
                              if (a === 'subject_type') return undefined
                              if (a === 'subject_id') return 'MID'
                              if (a === 'mid') return 'MID'
                              if (k.includes('applications_')) {
                                if (a === 'status' || a === 'sort' || a === 'direction') return undefined
                              }
                              if (a === 'custom_filters') {
                                  return `«${filters[initialKey]}»`
                              }
                              return uppercaseFirstLetter(a)
                          })
                          .filter((f) => f) as string[])
                    : []
            },
            set
        }
    }
}
