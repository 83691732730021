import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../store/toasts/actions'
import { CriticalFlowSettlements } from './CriticalFlowSettlements'

export const CriticalFlow: React.FC = () => {
    const dispatch = useDispatch()
    const flow = useSelector((state: RootState) => state.interface.criticalFlow)

    switch (flow?.type) {
        case 'settlements-reserve-cancellations':
            return <CriticalFlowSettlements />
        default: {
            dispatch(ToastsDispatchPush('Unknown critical flow found.', 'error'))
            throw 'Unknown critical flow found'
        }
    }
}
