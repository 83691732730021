import React, { useMemo } from 'react'
import styled from 'styled-components'

import { LoaderView } from '../../../components/loaders/loader'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { useForm } from '../../../hooks/general/useForm'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { LocallyStoredDraftContract, MerchantAccountWithContract } from '../../../store/merchantAccounts/types'
import { MerchantAccountContractsProps } from './Accounts.ID.ContractProps'
import { MerchantPageUISelectedContract } from './utils'
import { useContractHistoryAnalyzer } from './useContractHistoryAnalyzer'
import { useRouteGuard } from '../../../hooks/general/useRouteGuard'
import { MerchantAccountContractsSectionPayout } from './Accounts.ID.ContractSectionPayout'
import { MerchantAccountContractsSectionFees } from './Accounts.ID.ContractSectionFees'
import { Flex } from '../../../components/layout/flex'
import { DedicatedSectionSeparator } from '../Application/Application.DedicatedSection'
import { MerchantAccountContractsSectionServiceCharges } from './Accounts.ID.ContractSectionServiceCharges'
import { MerchantAccountSectionExchangeFees } from './Accounts.ID.ContractSectionExchangeFees'
import { MerchantAccountContractsSectionAdditional } from './Accounts.ID.ContractSectionAdditional'
import { CenteredScreenHeader } from '../../../components/layout/centeredScreenHeader'
import { MerchantAccountVersionSelector } from './Accounts.ID.ContractVersionSelector'
import { LinkButton } from '../../../components/buttons/linkButton'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { MerchantAccountContractsPageActions } from './Accounts.ID.ContractPageActions'
import { MerchantAccountContractApplyTemplate } from './Accounts.ID.ContractApplyTemplate'

export const MerchantAccountContractsPage: React.FC<{
    applicationId: string
    account: MerchantAccountWithContract
    draft: LocallyStoredDraftContract
    selectedContract?: MerchantPageUISelectedContract
}> = ({ applicationId, account, draft, selectedContract }) => {
    const [watcher, watcherId] = useNamedWatcher(`${selectedContract?.contract?.id}.Save`)
    const {
        formRef,
        resetForm,
        fields,
        form,
        hasChanges,
        submitHandler,
        focusInvalidField,
        errors,
        executeBatchChanges
    } = useForm(watcher, undefined, undefined, undefined, undefined)
    const { effectiveContract } = useContractHistoryAnalyzer(account?.contractHistory)

    const sectionProps = useMemo(() => {
        if (!selectedContract) return {} as any
        const props: MerchantAccountContractsProps = {
            formErrors: errors,
            formRef,
            contract: selectedContract.isDraft ? undefined : selectedContract.contract,
            draftOrTemplate: draft,
            isDisabled: !(draft?.state === 'draft' || draft?.state === 'new'),
            accountId: account?.id,
            executeBatchChanges,
            currency: account?.currency
        }
        return props
    }, [selectedContract, errors, draft, formRef, account, executeBatchChanges])

    const rightSideMemo = useMemo(() => {
        const needsApproval = draft?.state !== 'draft' && draft?.state !== 'new'
        if (selectedContract?.isDraft) {
            if (needsApproval) return null
            return (
                <MerchantAccountContractApplyTemplate
                    shouldDisableSetupFee={effectiveContract}
                    form={form}
                    {...sectionProps}
                />
            )
        }
        return (
            <>
                <LinkButton
                    to={`/merchant/${applicationId}/accounts/${account?.id}/contracts/draft`}
                    background={needsApproval ? 'front.success.color' : 'front.background'}
                >
                    <ButtonInset>{needsApproval ? 'Draft is pending approval' : 'Go to DRAFT'}</ButtonInset>
                </LinkButton>
            </>
        )
    }, [form, account, effectiveContract, selectedContract, draft, applicationId, sectionProps])

    const selectorActions = useMemo(() => {
        const catchMouseEvents = (e: any) => {
            e.stopPropagation()
        }

        if (!selectedContract) return null
        return (
            <div onClick={catchMouseEvents} onMouseDown={catchMouseEvents} onMouseUp={catchMouseEvents}>
                <MerchantAccountContractsPageActions
                    contractsLink={`/merchant/${applicationId}/accounts/${account?.id}/contracts`}
                    focusInvalidField={focusInvalidField}
                    selectedContract={selectedContract}
                    watcherId={watcherId}
                    account={account}
                    form={form}
                    errors={errors}
                    submitHandler={submitHandler}
                    resetForm={resetForm}
                />
            </div>
        )
    }, [applicationId, account, focusInvalidField, selectedContract, watcherId, form, errors, submitHandler, resetForm])

    const signBox = useMemo(() => {
        if (selectedContract?.isDraft) return null
        if (selectedContract?.contract?.metadata?.state !== 'needs_signature') return null
        return (
            <SignBox>
                Contract has been sent for signing to&nbsp;
                <Text bold>
                    {selectedContract?.contract?.signer.name} ({selectedContract?.contract?.signer.email})
                </Text>
                .
            </SignBox>
        )
    }, [selectedContract])

    useRouteGuard(
        'DRAFT has unsaved changed. Are your sure you want to leave?',
        hasChanges && selectedContract?.isDraft
    )

    if (selectedContract?.isDraft && selectedContract?.contract?.loadingStatus !== 'done')
        return <LoaderView overBackground="back.background" />

    if (!selectedContract)
        return (
            <Content>
                <Text color="back.text">No contract selected.</Text>
            </Content>
        )

    let pageTitle: any = 'Contract'

    if (selectedContract.isDraft) {
        if (selectedContract?.contract?.state === 'draft' || selectedContract?.contract?.state === 'new') {
            pageTitle = (
                <Flex align="center">
                    DRAFT Contract <Editable>EDITABLE</Editable>
                </Flex>
            )
        } else {
            pageTitle = 'Pending approval draft'
        }
    }

    return (
        <>
            <Sections>
                <CenteredScreenHeader
                    title={pageTitle}
                    backTo={`/merchant/${applicationId}/accounts/${account.id}`}
                    right={rightSideMemo}
                    backToSuffix="Account"
                ></CenteredScreenHeader>
                {signBox}
                <MerchantAccountVersionSelector
                    actions={selectorActions}
                    account={account}
                    draft={draft}
                    merchantId={applicationId}
                    selectedContract={selectedContract}
                />
                <Flex align="stretch">
                    <MerchantAccountContractsSectionPayout {...sectionProps} />
                    <MerchantAccountContractsSectionFees {...sectionProps} shouldDisableSetupFee={effectiveContract} />
                </Flex>
                <DedicatedSectionSeparator title="SERVICE CHARGES" alignLeft />
                <MerchantAccountContractsSectionServiceCharges form={form} {...sectionProps} />
                <DedicatedSectionSeparator title="OTHER INFORMATION" alignLeft />
                <Flex align="stretch">
                    <MerchantAccountSectionExchangeFees {...sectionProps} />
                    <Spacer width={20} />
                    <MerchantAccountContractsSectionAdditional {...sectionProps} />
                </Flex>
            </Sections>
        </>
    )
}
const Editable = styled.div`
    padding: 2px 6px;
    border-radius: 5px;
    background-color: ${(p) => p.theme['front.subtleAccent.background']};
    color: ${(p) => p.theme['front.subtleAccent.text']};
    margin-left: 5px;
    font-size: 10px;
    letter-spacing: 0.4px;
    font-weight: 500;
`

const SignBox = styled.div`
    padding: 20px 10px;
    background-color: ${(p) => p.theme['front.subtleSuccess.background']};
    color: ${(p) => p.theme['front.subtleSuccess.text']};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: ${(p) => p.theme['subtleButtonsShadow']};
`

const Sections = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1000px;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
`
