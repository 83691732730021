import { File } from '../files/types'
import { WatcherID } from '../watcher/types'
import { Task, TasksFilters, TasksState, TaskType } from './types'

export const TASKS_LOAD_SUMMARIES = 'TASKS_LOAD_SUMMARIES'
export const TASKS_STORE_SUMMARIES = 'TASKS_STORE_SUMMARIES'
export const TASKS_LOAD_TASK = 'TASKS_LOAD_TASK'
export const TASKS_STORE_TASK = 'TASKS_STORE_TASK'
export const TASKS_UNSTORE_TASK = 'TASKS_UNSTORE_TASK'
export const TASK_LOAD_TIMELINE = 'TASK_LOAD_TIMELINE'
export const TASKS_STORE_TIMELINE = 'TASKS_STORE_TIMELINE'
export const TASKS_LOAD_TAGS = 'TASKS_LOAD_TAGS'
export const TASKS_STORE_TAGS = 'TASKS_STORE_TAGS'
export const TASKS_STORE_FILTERS = 'TASKS_STORE_FILTERS'
export const TASK_ASSIGN_AGENT = 'TASK_ASSIGN_AGENT'
export const TASK_ASSIGN_TAG = 'TASK_ASSIGN_TAG'
export const TASK_CREATE_AND_ASSIGN_TAG = 'TASK_CREATE_AND_ASSIGN_TAG'
export const TASK_REMOVE_TAG = 'TASKS_REMOVE_TAG'
export const TASK_OPEN = 'TASK_OPEN'
export const TASK_CLOSE = 'TASK_CLOSE'
export const TASK_STORE_COMMENT = 'TASK_STORE_COMMENT'
export const TASK_UNSTORE_COMMENT = 'TASK_UNSTORE_COMMENT'
export const TASK_LINK_FILES = 'TASK_LINK_FILES'
export const TASK_UNLINK_FILES = 'TASK_UNLINK_FILES'
export const TASK_SEND_COMMENT = 'TASK_SEND_COMMENT'
export const TASK_EDIT_COMMENT = 'TASK_EDIT_COMMENT'
export const TASK_REMOVE_COMMENT = 'TASK_REMOVE_COMMENT'
export const TASKS_CLEAR_SUMMARIES = 'TASKS_CLEAR_SUMMARIES'
//-
interface TaskTimelineResources {
    comments: string
    files: string
    events: any
}
//-

export interface TasksActionLoadSummaries {
    type: typeof TASKS_LOAD_SUMMARIES
    filters: TasksFilters
    taskType?: TaskType
}
export interface TasksActionStore {
    type: typeof TASKS_STORE_SUMMARIES
    tasks: Task[]
    pagination: TasksState['taskSummaries']['pagination']
}
export interface TasksActionLoadTask {
    type: typeof TASKS_LOAD_TASK
    taskId: string
}
export interface TasksActionStoreTask {
    type: typeof TASKS_STORE_TASK
    task: Task
}
export interface TasksActionUnstoreTask {
    type: typeof TASKS_UNSTORE_TASK
    taskId: string
}
export interface TasksActionLoadTimeline {
    type: typeof TASK_LOAD_TIMELINE
    taskId: string
    resources: TaskTimelineResources
}
export interface TasksActionStoreTimeline {
    type: typeof TASKS_STORE_TIMELINE
    taskId: string
    timeline: any
}
export interface TasksActionLoadTags {
    type: typeof TASKS_LOAD_TAGS
}
export interface TasksActionStoreTags {
    type: typeof TASKS_STORE_TAGS
    tags: any[]
}
export interface TaskActionAssignAgent {
    type: typeof TASK_ASSIGN_AGENT
    watcher: WatcherID
    taskLink: string
    agent: string
}
export interface TaskActionAssignTag {
    type: typeof TASK_ASSIGN_TAG
    watcher: WatcherID
    taskLink: string
    tag: string
}
export interface TaskActionCreateAndAssignTag {
    type: typeof TASK_CREATE_AND_ASSIGN_TAG
    watcher: WatcherID
    taskAddTagLink: string
    tag: string
}
export interface TaskActionRemoveTag {
    type: typeof TASK_REMOVE_TAG
    watcher: WatcherID
    taskLink: string
    tag: string
}
export interface TaskActionOpen {
    type: typeof TASK_OPEN
    watcher: WatcherID
    taskLink: string
}
export interface TaskActionClose {
    type: typeof TASK_CLOSE
    watcher: WatcherID
    taskLink: string
}
export interface TaskActionLinkFiles {
    type: typeof TASK_LINK_FILES
    taskId: string
    files: File[]
}
export interface TaskActionUnlinkFiles {
    type: typeof TASK_UNLINK_FILES
    taskId: string
    files: File[]
}
export interface TaskActionSendComment {
    type: typeof TASK_SEND_COMMENT
    watcher: WatcherID
    commentsLink: string
    taskId: string
    body: string
}
export interface TaskActionUnstoreComment {
    type: typeof TASK_UNSTORE_COMMENT
    taskId: string
    commentLink: string
}

export interface TaskActionStoreComment {
    type: typeof TASK_STORE_COMMENT
    taskId: string
    commentItem: any[]
    commentLink?: string
}
export interface TaskActionEditComment {
    type: typeof TASK_EDIT_COMMENT
    watcher: WatcherID
    taskId: string
    commentLink: string
    newBody: string
}
export interface TaskActionRemoveComment {
    type: typeof TASK_REMOVE_COMMENT
    taskId: string
    watcher: WatcherID
    commentLink: string
}
export interface TasksActionClearSummaries {
    type: typeof TASKS_CLEAR_SUMMARIES
}
//-

export const TasksDispatchLoadSummaries = (filters: TasksFilters, taskType?: TaskType): TasksActionLoadSummaries => {
    return {
        type: TASKS_LOAD_SUMMARIES,
        filters,
        taskType
    }
}

export const TasksDispatchStore = (
    tasks: Task[],
    pagination: TasksState['taskSummaries']['pagination']
): TasksActionStore => {
    return { type: TASKS_STORE_SUMMARIES, tasks, pagination }
}

export const TasksDispatchLoadTask = (taskId: string): TasksActionLoadTask => {
    return { type: TASKS_LOAD_TASK, taskId }
}

export const TasksDispatchStoreTask = (task: Task): TasksActionStoreTask => {
    return { type: TASKS_STORE_TASK, task }
}
export const TasksDispatchUnstoreTask = (taskId: string): TasksActionUnstoreTask => {
    return { type: TASKS_UNSTORE_TASK, taskId }
}

export const TasksDispatchLoadTimeline = (
    taskId: string,
    resources: TaskTimelineResources
): TasksActionLoadTimeline => {
    return { type: TASK_LOAD_TIMELINE, taskId, resources }
}

export const TasksDispatchStoreTimeline = (taskId: string, timeline: any): TasksActionStoreTimeline => {
    return { type: TASKS_STORE_TIMELINE, taskId, timeline }
}

export const TasksDispatchClearSummaries = (): TasksActionClearSummaries => {
    return { type: TASKS_CLEAR_SUMMARIES }
}

export const TasksDispatchLoadTags = (): TasksActionLoadTags => {
    return { type: TASKS_LOAD_TAGS }
}

export const TasksDispatchStoreTags = (tags: any): TasksActionStoreTags => {
    return { type: TASKS_STORE_TAGS, tags }
}

export const TaskDispatchAssignAgent = (watcher: WatcherID, taskLink: string, agent: string): TaskActionAssignAgent => {
    return { type: TASK_ASSIGN_AGENT, watcher, taskLink, agent }
}

export const TaskDispatchCreateAndAssignTag = (
    watcher: WatcherID,
    taskAddTagLink: string,
    tag: string
): TaskActionCreateAndAssignTag => {
    return { type: TASK_CREATE_AND_ASSIGN_TAG, watcher, taskAddTagLink, tag }
}

export const TaskDispatchRemoveTag = (watcher: WatcherID, taskLink: string, tag: string): TaskActionRemoveTag => {
    return { type: TASK_REMOVE_TAG, watcher, taskLink, tag }
}

export const TaskDispatchAssignTag = (watcher: WatcherID, taskLink: string, tag: string): TaskActionAssignTag => {
    return { type: TASK_ASSIGN_TAG, watcher, taskLink, tag }
}

export const TaskDispatchClose = (watcher: WatcherID, taskLink: string): TaskActionClose => {
    return { type: TASK_CLOSE, watcher, taskLink }
}

export const TaskDispatchOpen = (watcher: WatcherID, taskLink: string): TaskActionOpen => {
    return { type: TASK_OPEN, watcher, taskLink }
}

export const TaskDispatchLinkFiles = (taskId: string, files: File[]): TaskActionLinkFiles => {
    return { type: TASK_LINK_FILES, taskId, files }
}

export const TaskDispatchUnlinkFiles = (taskId: string, files: File[]): TaskActionUnlinkFiles => {
    return { type: TASK_UNLINK_FILES, taskId, files }
}

export const TaskDispatchUnstoreComment = (taskId: string, commentLink: string): TaskActionUnstoreComment => {
    return { type: TASK_UNSTORE_COMMENT, taskId, commentLink }
}

export const TaskDispatchSendComment = (
    watcher: WatcherID,
    commentsLink: string,
    taskId: string,
    body: string
): TaskActionSendComment => {
    return { type: TASK_SEND_COMMENT, watcher, commentsLink, taskId, body }
}

export const TaskDispatchStoreComment = (
    taskId: string,
    commentItem: any[],
    commentLink?: string
): TaskActionStoreComment => {
    return { type: TASK_STORE_COMMENT, taskId, commentItem, commentLink }
}

export const TaskDispatchRemoveComment = (
    watcher: WatcherID,
    taskId: string,
    commentLink: string
): TaskActionRemoveComment => {
    return { type: TASK_REMOVE_COMMENT, watcher, taskId, commentLink }
}

export const TaskDispatchEditComment = (
    watcher: WatcherID,
    taskId: string,
    commentLink: string,
    newBody: string
): TaskActionEditComment => {
    return { type: TASK_EDIT_COMMENT, watcher, taskId, commentLink, newBody }
}

export interface SagasForTasks {
    loadSummaries(action: TasksActionLoadSummaries): void
    loadTask(action: TasksActionLoadTask): void
    loadTags(action: TasksActionLoadTags): void
    loadTaskTimeline(action: TasksActionLoadTimeline): void
    createTag(action: TaskActionCreateAndAssignTag): void
    removeTag(action: TaskActionRemoveTag): void
    assignTag(action: TaskActionAssignTag): void
    assignAgent(action: TaskActionAssignAgent): void
    open(action: TaskActionOpen): void
    close(action: TaskActionClose): void
    sendComment(action: TaskActionSendComment): void
    removeComment(action: TaskActionRemoveComment): void
    editComment(action: TaskActionEditComment): void
}

export type ReducedTasksActions =
    | TasksActionStore
    | TasksActionStoreTimeline
    | TasksActionStoreTask
    | TasksActionStoreTags
    | TasksActionLoadTags
    | TasksActionLoadTask
    | TasksActionUnstoreTask
    | TasksActionLoadSummaries
    | TasksActionLoadTimeline
    | TaskActionStoreComment
    | TaskActionUnstoreComment
    | TaskActionLinkFiles
    | TasksActionClearSummaries
    | TaskActionUnlinkFiles
    | TasksActionStoreTask
