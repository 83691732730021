import { Container, Flex, Grid, Page } from '@/design-system'
import { IntegrationSchema, useIntegrationForm } from '@/features/integrations'
import { RTKQuery } from '@/services/common'
import { MAPI_PARTNERS_ROOT, createIntegration } from '@/services/merchant-api'
import { PageHeader, SaveCard, FormInput, FormTextarea } from '@/ui'
import { FormProvider } from 'react-hook-form'
import { Form, redirect, useNavigate, useSubmit } from 'react-router-dom'

export const action = async ({ request, params }) => {
    const { id } = params

    const formData = await request.formData()
    const data = Object.fromEntries(formData)

    const url = `${MAPI_PARTNERS_ROOT}/${id}/integrations`

    const body = { url, data }
    await RTKQuery.post(createIntegration, body)

    return redirect(`/more/partners/${id}`)
}

const IntegrationAdd = () => {
    const navigate = useNavigate()
    const form = useIntegrationForm()
    const submit = useSubmit()

    const onBack = () => {
        navigate(-1)
    }

    const onSubmit = form.handleSubmit((values: IntegrationSchema) => {
        submit(values, { method: 'post' })
    })

    const errorCount = Object.keys(form.formState.errors).length

    return (
        <Page pb="normal">
            <PageHeader title="Add integration" onBack={onBack} />
            <Flex justify="center" align="center" grow="1">
                <Container size="2xl">
                    <FormProvider {...form}>
                        <Form onSubmit={onSubmit}>
                            <SaveCard>
                                <SaveCard.Body>
                                    <SaveCard.Title>Integration details</SaveCard.Title>
                                    <SaveCard.Fields>
                                        <Grid columns="3" gap="3">
                                            <FormInput>
                                                <FormInput.Label name="name">Name</FormInput.Label>
                                                <FormInput.Input testid="name-input" name="name" />
                                            </FormInput>
                                            <FormInput>
                                                <FormInput.Label name="support_partner_id">
                                                    Support partner id
                                                </FormInput.Label>
                                                <FormInput.Input
                                                    testid="support-partner-id-input"
                                                    name="support_partner_id"
                                                />
                                            </FormInput>
                                            <FormTextarea>
                                                <FormTextarea.Label name="description" margin="default">
                                                    Description
                                                </FormTextarea.Label>
                                                <FormTextarea.Textarea
                                                    testid="description-textarea"
                                                    name="description"
                                                    margin="default"
                                                />
                                            </FormTextarea>
                                            <FormTextarea>
                                                <FormTextarea.Label name="transaction_rules" margin="default">
                                                    Transaction rules
                                                </FormTextarea.Label>
                                                <FormTextarea.Textarea
                                                    testid="transaction-rules-textarea"
                                                    name="transaction_rules"
                                                    margin="default"
                                                />
                                            </FormTextarea>
                                        </Grid>
                                    </SaveCard.Fields>
                                </SaveCard.Body>
                                <SaveCard.Footer testid="card-footer" errorCount={errorCount} />
                            </SaveCard>
                        </Form>
                    </FormProvider>
                </Container>
            </Flex>
        </Page>
    )
}

IntegrationAdd.action = action

export { IntegrationAdd }
