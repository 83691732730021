import React, { ReactElement, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../general/text'
import { Icon } from '../icons/icon'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { Table } from '../tables/table'

export const ReviewChangesTable: React.FC<{
    formatChangeRow: (
        key: string,
        from: string,
        to: string
    ) => {
        col: string
        from: string | ReactElement
        to: string | ReactElement
    }
    changesArr?: {
        [key: string]: {
            from: string
            to: string
        }
    }
    fieldFormatter: (k: any) => any
}> = ({ fieldFormatter, formatChangeRow, changesArr }): ReactElement => {
    const rows = useMemo(() => {
        if (!changesArr) return []
        return Object.keys(changesArr)
            .sort((a, b) => {
                const changeA = changesArr[a]
                const { col: colA } = formatChangeRow(a, changeA.from, changeA.to)
                const changeB = changesArr[b]
                const { col: colB } = formatChangeRow(b, changeB.from, changeB.to)

                return fieldFormatter(colA) > fieldFormatter(colB) ? 1 : -1
            })
            .map((c: any) => {
                const change = changesArr[c]
                const { col, from, to } = formatChangeRow(c, change.from, change.to)

                return {
                    type: 'normal' as const,
                    noAction: true,
                    key: c.key,
                    items: [
                        {
                            node: (
                                <Flex align="center" justify="flex-start">
                                    <Text noWrap cy="change-label">
                                        {fieldFormatter(col)}
                                    </Text>
                                    <Spacer width={20} />
                                </Flex>
                            )
                        },
                        {
                            node: (
                                <TextHolder>
                                    <Text>{from}</Text>
                                </TextHolder>
                            )
                        },
                        {
                            node: (
                                <Flex column justify="center">
                                    <Spacer height={4} />
                                    <Icon type="arrow" />
                                </Flex>
                            )
                        },
                        {
                            node: (
                                <TextHolder>
                                    <Text>{to}</Text>
                                </TextHolder>
                            )
                        }
                    ]
                }
            })
    }, [changesArr, fieldFormatter, formatChangeRow])

    const cols = useMemo(() => {
        return [
            {
                text: 'Field'
            },
            {
                text: 'From'
            },
            {
                text: ''
            },
            {
                text: 'To'
            }
        ]
    }, [])

    return (
        <Table
            background="front.background"
            cols={cols}
            // eslint-disable-next-line max-len
            columnLayout="min-content 1fr min-content 1.1fr"
            showLastRowBorder
            overrideText="No referral partners have been assigned."
            // displayLoader={summaries.loadingStatus == 'started'}
            rows={rows}
            emptyText="No tasks found matching the filtered criterias."
        />
    )
}

const TextHolder = styled.div<{ highlight?: boolean }>`
    max-width: 300px;

    white-space: nowrap;
    padding: 2px 8px;
    margin: -2px 0px -2px -8px;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;

    ${(p) =>
        p.highlight &&
        css`
            border: 1px solid ${p.theme['front.subtleInfo.background.strongerII']};
            background-color: ${p.theme['front.subtleInfo.background']};
            color: ${p.theme['front.subtleInfo.text']};
        `}
`
