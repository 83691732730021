import React from 'react'
import { googleSearch } from '@/utils/googleSearch'
import { Section } from '../../../common/section'
import { SectionCheck } from '@/services/ongoing-due-diligence'
import { ToggleContent } from '../toggle-content/toggle-content'
import { useReportMerchantData, useReportSection } from '../../../provider/report-provider/report-provider'

export const GoogleNegativeInfoCompanyUrl: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')
    const merchantData = useReportMerchantData()

    const data = checks.google_negative_info_company_url
    const commentData = checks.comment_google_negative_info_company_url

    const { name: companyName } = merchantData.company

    const googleLink = companyName && googleSearch(companyName)

    return (
        <Section>
            <Section.Content>
                <ToggleContent
                    dataName="google_negative_info_company_url"
                    data={data}
                    commentName={'comment_google_negative_info_company_url'}
                    commentData={commentData}
                    visibleValue="Yes"
                />
            </Section.Content>
            <Section.Metadata>
                <Section.ExternalLink url={googleLink}>Google</Section.ExternalLink>
            </Section.Metadata>
        </Section>
    )
}
