import { Classifications } from '@/services/risk-classification'
import { TableRow } from '@/components/tables/table'
import { Text } from '@/components/general/text'
import { AgentBubble, Date } from '../common'
import { Flex } from '@/components/layout/flex'
import { Tag } from '../common'

export const getClassificationRows = (data?: Classifications) => {
    if (!data) {
        return []
    }

    const rows: TableRow[] = data.classifications.map((classification) => {
        let riskLevel = <Text>{classification.risk_level}</Text>
        
        if(classification.risk_level === "High risk"){
            riskLevel = <Text bold color="front.danger.color">{classification.risk_level}</Text>
        }

        return {
            type: 'normal' as const,
            key: classification.id,
            link: classification.status === "finalized" ?
                `/merchant/${classification.application_id}/more/risk/classifications/${classification.id}`: 
                `/merchant/${classification.application_id}/more/classifications/${classification.id}`,
            items: [
                {
                    node: <Date>{classification.createdAt}</Date>

                },
                {
                    node: <AgentBubble agentSub={classification.author} />
                },
                {
                    node: riskLevel
                },
                {
                    node: <Text 
                            noWrap 
                            style={{
                                width:"27em",
                                textOverflow: "ellipsis", 
                                overflow:"hidden"
                            }}>
                                {classification.comment || "-"}
                            </Text>
                },
                {
                    
                    node: (
                        <Flex justify="flex-end">
                            <Tag>{classification.status}</Tag>
                        </Flex>
                    )
                }
            ]
        }
    })

    return rows
}
