import styled from 'styled-components'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { Icon } from '../icons/icon'
import { Spacer } from './spacer'
import { Text } from '../general/text'

export const FloatingSidebarHeader: React.FC<{
    handleExitClick: () => void
    title?: string
}> = ({ handleExitClick, title }) => {
    return (
        <Header>
            <SimpleButton onClick={handleExitClick} color="front.text">
                <ButtonInset>
                    <IconHolder>
                        <Icon type="x" />
                    </IconHolder>
                </ButtonInset>
            </SimpleButton>
            <Title>
                <Text bold color={'front.text'}>
                    {title}
                </Text>
            </Title>
            <Spacer width={50} height={30} />
        </Header>
    )
}

const Title = styled.div`
    position: absolute;
    top: 3px;
    left: 50%;
    padding: 20px;
    transform: translateX(-50%);
`

const IconHolder = styled.div`
    height: 22px;
    display: flex;
    align-items: center;
`

const Header = styled.div`
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    padding: 15px 15px;
`
