import React from 'react'
import { cn } from '../utils'
import { Slot } from '@radix-ui/react-slot'
import { ButtonStyles, ContentStyles, SpinnerStyles, styles } from './styles'
import { Spinner as SpinnerComponent } from '../spinner'

interface ButtonProps extends ButtonStyles {
    testid?: string
    children: React.ReactNode
    asChild?: boolean
    type?: 'button' | 'submit' | 'reset'
    loading?: boolean
    disabled?: boolean
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        testid,
        children,
        asChild,
        type = 'button',
        loading,
        disabled,
        onClick,
        variant,
        size,
        color,
        width
    } = props

    const classnames = cn(styles.button({ variant, size, color, width }))
    const Component = asChild ? Slot : 'button'

    return (
        <Component data-cy={testid} className={classnames} type={type} onClick={onClick} disabled={disabled} ref={ref}>
            <>
                <Content loading={loading}>{children}</Content>
                {loading && <Spinner color={color} />}
            </>
        </Component>
    )
})

interface ContentProps extends ContentStyles {
    children: React.ReactNode
    loading?: boolean
}

export const Content: React.FC<ContentProps> = (props) => {
    const { children, loading } = props

    if (loading) {
        const classnames = cn(styles.content())

        return <span className={classnames}>{children}</span>
    }

    return children
}

interface SpinnerProps extends SpinnerStyles {
    color?: 'primary' | 'neutral' | 'danger' | 'white' | null
}

const Spinner: React.FC<SpinnerProps> = (props) => {
    const { color } = props

    const classnames = cn(styles.spinner())

    return (
        <div className={classnames}>
            <SpinnerComponent color={color} />
        </div>
    )
}
