import { useMemo } from 'react'

export const useMerchantListPageDynamicTitle = (
    filters: {
        get: { [filterKey: string]: string | string[] }
    },
    forMids: { [filterKey: string]: string | string[] },
    id: 'transactions_mid' | 'settlements_mid' | 'disputes_mid'
): string => {
    const prefix = useMemo(() => {
        switch (id) {
            case 'transactions_mid':
                return 'Transactions'
            case 'settlements_mid':
                return 'Settlements'
            case 'disputes_mid':
                return 'Disputes'
        }
    }, [id])

    return useMemo(() => {
        if (forMids) {
            return `${prefix} across all accounts`
        }
        const queryFilters = filters.get[id] as any
        if (!queryFilters) {
            return `${prefix}`
        } else {
            if (typeof queryFilters !== 'object') {
                return `${prefix} for ${queryFilters}`
            } else {
                return `${prefix} across ${queryFilters}`
            }
        }
    }, [filters, id, forMids, prefix])
}
