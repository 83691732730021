import { useMedia } from 'react-use'
import styled from 'styled-components'
import { ListPageSidebar } from './../layout/listPageSidebar'
import { ListPageSidebarTitle } from './../layout/listPageSidebarTitle'
import { HIDE_FILTERS_AT_WIDTH } from '../taskPages/filters'
import { List } from './list'
import { Spacer } from './spacer'
import React from 'react'

export const SideFilters: React.FC<{ items: any; before?: React.ReactNode; shouldAlwaysDisplay?: boolean }> = ({
    items,
    before,
    shouldAlwaysDisplay
}) => {
    const shouldDisplay = useMedia(`(min-width: ${HIDE_FILTERS_AT_WIDTH}px)`)
    if (shouldAlwaysDisplay ? true : shouldDisplay)
        return (
            <Capsule shouldAlwaysDisplay>
                <ListPageSidebar data-floater-boundary>
                    {before}
                    <ListPageSidebarTitle>Filters panel</ListPageSidebarTitle>
                    <Spacer height={6} />
                    <List
                        background="side.background"
                        items={items}
                        emphasizeLabels
                        template="auto"
                        switchToRowsAt={shouldAlwaysDisplay ? 100 : HIDE_FILTERS_AT_WIDTH}
                        cellHorizontalTemplate="150px auto"
                    />
                </ListPageSidebar>
            </Capsule>
        )
    return null
}

const Capsule = styled.div<{ shouldAlwaysDisplay?: boolean }>`
    display: contents;
    @media (max-width: ${HIDE_FILTERS_AT_WIDTH}px) {
        display: none;
    }

    .list-item:last-child .list-value > * {
        border-bottom: none;
    }
`
