import { ButtonInset } from '@/components/buttons/buttonInset'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { useClassificationParams } from '../../../hooks/use-classification-params'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getApplicationUrl } from '../../../helpers/get-application-url'
import { useDeleteClassification } from '@/services/risk-classification'

export const DeleteAction: React.FC = () => {
    const navigate = useNavigate()
    const { classificationId, applicationId } = useClassificationParams()

    const [deleteClassification, deleteClassificationResult] = useDeleteClassification()
    const watcher = deleteClassificationResult.isLoading ? 'started' : undefined

    const handleOnDeleteClick = async () => {
        await deleteClassification({ classificationId }).unwrap()

        const classificationUrl = getApplicationUrl(applicationId)

        navigate(classificationUrl)
    }

    return (
        <SimpleButton
            background="front.danger.color"
            onClick={handleOnDeleteClick}
            cy="risk-classification-button-delete"
        >
            <ButtonLoadingCover color="front.danger.color" background="front.subtleDanger.background" watcher={watcher}>
                <ButtonInset>Delete</ButtonInset>
            </ButtonLoadingCover>
        </SimpleButton>
    )
}
