import { useReportParams } from '../../../../../hooks/use-report-params'
import { useApplicationCompany } from '../../../../../api'
import React from 'react'
import { CompanyFiles } from './company-files'

export const CompanyReports: React.FC = () => {
    const { applicationId } = useReportParams()
    const company = useApplicationCompany(applicationId)

    if (company.isLoading || company.isError) {
        return null
    }

    if (!company.data || !company.data.relations.files) {
        return null
    }

    return (
        <>
            <CompanyFiles filesUrl={company.data.relations.files} />
        </>
    )
}
