import React from 'react'
import { DuplicatePeopleWarning } from '../../other/DuplicatePeopleWarning/DuplicatePeopleWarning'
import { RefreshApplicationButton } from './components/RefreshApplicationButton'
import { MerchantApplicationLink } from './components/MerchantApplicationLink'
import { MerchantMetadata } from './components/MerchantMetadata'
import { BatchOfMonitoredChanges } from '../../../../../../store/tasksCompanyChanges/types'
import { Flex } from '../../../../../../components/layout/flex'
import { Text } from '../../../../../../components/general/text'
import { Spacer } from '../../../../../../components/layout/spacer'

interface ApplicationCardHeaderProps {
    taskId: string
    change?: BatchOfMonitoredChanges
}

export const ApplicationCardHeader: React.FC<ApplicationCardHeaderProps> = (props) => {
    const { taskId, change } = props

    if (!change) {
        return null
    }

    const applicationId = change.applicationId

    return (
        <>
            <Flex align="baseline" justify="space-between">
                <Flex align="baseline">
                    <Text size="xl">{change.fetchedApplicationData.data?.company.name}</Text>
                </Flex>
                <Flex align="baseline">
                    <MerchantApplicationLink applicationId={applicationId} />
                    <Spacer width={24} height={1} />
                    <RefreshApplicationButton taskId={taskId} applicationId={applicationId} />
                    <Spacer width={15} />
                </Flex>
            </Flex>
            <Spacer height={10} />
            <Flex>
                <MerchantMetadata change={change} />
            </Flex>
            <DuplicatePeopleWarning change={change} applicationId={applicationId} />
        </>
    )
}
