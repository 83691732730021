import { useNavigate } from 'react-router-dom'
import { getClassificationUrl, useCreateActions } from '..'
import { useState } from 'react'

export const useNewClassification = (applicationId: string, nextPath?: string) => {
    const [isRedirecting, setIsRedirecting] = useState(false)

    const { create } = useCreateActions()
    const navigate = useNavigate()

    const redirect = async () => {
        setIsRedirecting(true)
        const { classification } = await create()
        const classificationUrl = getClassificationUrl(applicationId, classification.id)

        navigate(
            {
                pathname: classificationUrl
            },
            { state: { ...(nextPath && { nextPath }) } }
        )
    }

    return { redirect, isRedirecting }
}
