import { ActionType } from 'deox'
import { snakeCase } from 'lodash'
import { putResolve } from 'redux-saga/effects'
import { put, select } from 'typed-redux-saga'
import { DELETE, GET, POST, PUT } from '../../generators/networking'
import { ApplicationResourceActions } from '../applicationResources/actions'
import { ApplicationResource, ApplicationResourceData } from '../applicationResources/types'
import { RootState } from '@/store'
import { WatcherDispatchFail, WatcherDispatchStart, WatcherDispatchSuccess } from '../watcher/actions'
import { ApplicationDataProvidersActions } from './actions'
import { computeConflictsAndOwnershipShares } from './sagas.conflictsFinder'
import { CompanyInfoPotentialMatch, DataPorviderStateAssignedAndReady, MutedConflictsPayload } from './types'

export const ApplicationDataProvidersSagas = {
    *FETCH({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.FETCH>) {
        let entityId = p.entityId
        if (!p.entityId) {
            try {
                const { statusCode, cleanedResponse } = yield* GET({
                    url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/records/${p.applicationId}`,
                    include: ['self'],
                    returnStatusCode: true,
                    errorText: 'Failed to fetch application data providers state.'
                })

                if (statusCode === 404) {
                    yield put(ApplicationDataProvidersActions.STORE(p.applicationId))
                    return
                }

                entityId = cleanedResponse.entityId
            } catch (e) {
                yield put(ApplicationDataProvidersActions.STORE(p.applicationId))
                return
            }
        }

        const entity = yield* GET({
            url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/entities/${entityId}`,
            include: ['self'],
            returnStatusCode: true,
            errorText: 'Failed to fetch application data providers state.'
        })
        const mutedConflictsResponse: { cleanedResponse: Partial<MutedConflictsPayload> } = yield* GET({
            url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/entities/${entityId}/ignore`,
            returnStatusCode: true,
            errorText: 'Failed to fetch application data providers state.'
        })

        yield put(
            ApplicationDataProvidersActions.STORE(
                p.applicationId,
                entityId,
                'Virk',
                entity.cleanedResponse,
                mutedConflictsResponse.cleanedResponse
            )
        )
    },
    *SCAN_FOR_CONFLICTS({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.SCAN_FOR_CONFLICTS>) {
        const providerData = yield* select((state: RootState) => state.dataProviders.forApplication[p.applicationId])
        if (providerData.state !== 'assigned') {
            return
        }
        const { linked, mutedConflicts } = providerData
        const computedData = yield* computeConflictsAndOwnershipShares(p.applicationId, linked, mutedConflicts)
        if (!computedData) return

        yield put(ApplicationDataProvidersActions.STORE_CONFLICTS_AND_OWNERSHIP_SHARES(p.applicationId, computedData))
    },
    *SEARCH_FOR_MATCHES({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.SEARCH_FOR_MATCHES>) {
        const company: ApplicationResource<ApplicationResourceData>['forApplication']['x']['company'] = yield select(
            (state: RootState) => state.applicationResources.data.forApplication?.[p.applicationId]?.company
        )

        const { success, cleanedResponse }: { success?: boolean; cleanedResponse?: CompanyInfoPotentialMatch[] } =
            yield* POST({
                url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/records/${p.applicationId}/search`,
                include: ['self'],
                body: {
                    country: company.fields[0].country,
                    registration_number: company.fields[0].registrationNumber,
                    name: company.fields[0].name
                },
                successCode: 200,
                returnStatusCode: true,
                skipErrorCode: [404]
            })
        if (success && cleanedResponse) {
            const array = Object.keys(cleanedResponse).map((k: any) => cleanedResponse[k])
            yield put(ApplicationDataProvidersActions.STORE_SEARCH_RESULTS(array, p.applicationId))
        } else {
            yield put(ApplicationDataProvidersActions.STORE_SEARCH_RESULTS([], p.applicationId))
        }
    },
    *IGNORE_CHANGES({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.IGNORE_CHANGES>) {
        yield put(WatcherDispatchStart([p.watcherId]))
        const dataProvider = yield* select((state: RootState) => state.dataProviders?.forApplication?.[p.applicationId])
        if (dataProvider.state !== 'assigned' && dataProvider.state !== 'assigned-and-ready') return
        const { entityId, mutedConflicts } = dataProvider
        const { success, cleanedResponse } = yield PUT({
            url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/entities/${entityId}/ignore`,
            body: {
                ...(mutedConflicts
                    ? Object.keys(mutedConflicts).reduce((acc, key) => {
                          acc[snakeCase(key)] = (mutedConflicts as any)[key]
                          return acc
                      }, {} as any)
                    : {}),
                ...Object.keys(p.changes).reduce((acc, key) => {
                    if (p.changes[key] === undefined) acc[snakeCase(key)] = ['BLANK']
                    else acc[snakeCase(key)] = p.changes[key]
                    return acc
                }, {} as any)
            }
        })

        if (success) {
            yield putResolve(ApplicationDataProvidersActions.STORE_MUTED_CONFLICTS(p.applicationId, cleanedResponse))
            yield put(
                WatcherDispatchSuccess([p.watcherId], p.skipNotification ? undefined : 'Value ignored successfully')
            )
        } else {
            yield put(WatcherDispatchFail([p.watcherId], p.skipNotification ? undefined : 'Failed to ignore the value'))
        }
    },
    *UNIGNORE_CHANGES({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.UNIGNORE_CHANGES>) {
        const providerData = yield* select((state: RootState) => state.dataProviders?.forApplication?.[p.applicationId])
        if (providerData.state !== 'assigned' && providerData.state !== 'assigned-and-ready') return
        const { mutedConflicts, entityId } = providerData

        const newValues: any = {
            ...(mutedConflicts
                ? Object.keys(mutedConflicts).reduce((acc, key) => {
                      acc[snakeCase(key)] = (mutedConflicts as any)[key]
                      return acc
                  }, {} as any)
                : {})
        }

        Object.keys(p.changes).forEach((fieldKey: string) => {
            if (typeof p.changes[fieldKey] === 'object' && !p.changes[fieldKey].length) {
                Object.keys(p.changes[fieldKey]).forEach((subfieldKey) => {
                    const allValues = p.changes[fieldKey][subfieldKey]
                    const mutedValues = p.changes[fieldKey][subfieldKey]

                    if (mutedValues) {
                        const swappingValue = mutedValues.filter((fk: string) => !allValues.includes(fk)) || []
                        newValues[snakeCase(fieldKey)] = {
                            ...newValues[snakeCase(fieldKey)],
                            [snakeCase(subfieldKey)]: [...swappingValue]
                        }
                    }
                })
            } else if (typeof p.changes[fieldKey] === 'boolean') {
                newValues[snakeCase(fieldKey)] = false
            } else {
                const allValues = p.changes[fieldKey].map((v: any) => {
                    if (v) return v
                    return 'BLANK'
                })
                const mutedValues = (mutedConflicts as any)?.[fieldKey]

                if (mutedValues) {
                    const swappingValue = mutedValues.filter((fk: string) => !allValues.includes(fk)) || []
                    newValues[snakeCase(fieldKey)] = swappingValue
                }
            }
        })

        const { success, cleanedResponse } = yield PUT({
            watcher: p.watcherId,
            url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/entities/${entityId}/ignore`,
            body: newValues,
            successText: 'Value unignored successfully',
            errorText: 'Failed to unignore the value'
        })

        if (success) {
            yield put(ApplicationDataProvidersActions.STORE_MUTED_CONFLICTS(p.applicationId, cleanedResponse))
        }
    },
    *SELECT_RESULT({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.SELECT_RESULT>) {
        const { success, cleanedResponse } = yield PUT({
            watcher: p.watcherId,
            url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/records/${p.applicationId}`,
            body: {
                search_id: p.resultId
            },
            successText: 'Data provider link completed successfully',
            errorText: 'Failed to link data provider entity'
        })

        if (success) {
            yield put(ApplicationDataProvidersActions.FETCH(p.applicationId, cleanedResponse.entityId))
        }
    },
    *UNLINK({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.UNLINK>) {
        yield DELETE({
            watcher: p.watcherId,
            url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/records/${p.applicationId}`,
            successCode: 200,
            successText: 'Data provider entity unlinked successfully',
            errorText: 'Failed to unlink data provider entity',
            onSuccessDispatch: () => ApplicationDataProvidersActions.FETCH(p.applicationId)
        })
    },
    *EDIT_ROLE({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.EDIT_ROLE>) {
        yield put(WatcherDispatchStart([p.watcherId]))

        const index = yield* select((state: RootState) =>
            state.applicationResources.data.forApplication[p.applicationId].people.fields.findIndex(
                (person) => person.id == p.personId
            )
        )
        const key = `people.${index}.role`
        yield put(
            ApplicationResourceActions.MAKE_EDITS(
                p.watcherId,
                p.applicationId,
                [
                    {
                        key,
                        to: p.newRole
                    }
                ],
                undefined
            )
        )
    },
    *FIX_TYPOS({ payload: p }: ActionType<typeof ApplicationDataProvidersActions.FIX_TYPOS>) {
        yield put(WatcherDispatchStart([p.watcherId]))

        const providerData = yield* select(
            (state: RootState) =>
                state.dataProviders.forApplication[p.applicationId] as DataPorviderStateAssignedAndReady
        )
        if (providerData.state !== 'assigned-and-ready') return

        if (p.type == 'director') {
            const conflict = providerData.computed.conflicts.director
            if (conflict.type !== 'typo') return
            const matchId = conflict.applicationDirectorMatch
            // find the person's index, so we can build the path
            const index = yield* select((state: RootState) =>
                state.applicationResources.data.forApplication[p.applicationId].people.fields.findIndex(
                    (p) => p.id == matchId
                )
            )
            const key = `people.${index}.name`
            yield put(
                ApplicationResourceActions.MAKE_EDITS(
                    p.watcherId,
                    p.applicationId,
                    [
                        {
                            key,
                            to: conflict.ctx.sot.name
                        }
                    ],
                    undefined
                )
            )
        } else {
            const conflict = providerData.computed.conflicts.ownership
            if (conflict.type !== 'data-provider-blank' && conflict.type !== 'ubos-mismatch') return
            const matches = conflict.matches
            const edits: { key: string; to: string }[] = []
            const people = yield* select(
                (state: RootState) => state.applicationResources.data.forApplication[p.applicationId].people.fields
            )
            for (const match of matches) {
                if (match.type == 'partial') {
                    const index = people.findIndex((p) => p.id == match.app.id)
                    edits.push({
                        key: `people.${index}.name`,
                        to: match.sot.name
                    })
                }
            }
            yield put(ApplicationResourceActions.MAKE_EDITS(p.watcherId, p.applicationId, edits, undefined))
        }
    },
    *ON_MUTED_CONFLICTS_UPDATED({
        payload: p
    }: ActionType<typeof ApplicationDataProvidersActions.STORE_MUTED_CONFLICTS>) {
        const providerData = yield* select((state: RootState) => state.dataProviders.forApplication[p.applicationId])

        if (providerData.state !== 'assigned' && providerData.state !== 'assigned-and-ready') return

        const computedData = yield* computeConflictsAndOwnershipShares(
            p.applicationId,
            providerData.linked,
            p.mutedConflicts
        )
        if (!computedData) return

        yield put(ApplicationDataProvidersActions.STORE_CONFLICTS_AND_OWNERSHIP_SHARES(p.applicationId, computedData))
    }
}
