import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Offset } from '../../../components/layout/offset'
import { Spacer } from '../../../components/layout/spacer'
import { InterfaceActions } from '../../../store/interface/actions'
import { InterfaceState } from '../../../store/interface/types'
import { uppercaseFirstLetter } from '../../../utils'

export const MerchantApplicationReviewChangesModalSection: React.FC<{
    id: string
    number: number
    children: any
    steps?: InterfaceState['applications']['id']['steps']
    stepId?: keyof InterfaceState['applications']['id']['steps']
    isCancelled?: boolean
    isLast?: boolean
    isNonSend?: boolean
    isNonMessage?: boolean
}> = ({ id, number, children, isLast, isNonSend, isNonMessage, stepId, isCancelled }) => {
    const dispatch = useDispatch()

    const toggleState = useCallback(() => {
        if (!stepId) return
        dispatch(InterfaceActions.SET_APPLICATION_STEP_STATE(id, stepId, !isCancelled))
    }, [dispatch, id, stepId, isCancelled])

    if (isCancelled && stepId)
        return (
            <Section isLast={isLast}>
                <Number none>{number}</Number>
                <Content>
                    <Flex justify="flex-start">
                        <SimpleButton background="front.subtleAccent.background" onClick={toggleState}>
                            <ButtonInset>
                                + {isNonSend ? '' : 'Send'}&nbsp;<Text bold>{uppercaseFirstLetter(stepId)}</Text>
                                &nbsp;step
                            </ButtonInset>
                        </SimpleButton>
                    </Flex>
                </Content>
            </Section>
        )

    return (
        <Section isLast={isLast}>
            <Number>{number}</Number>
            <Content isNonMessage={isNonMessage}>
                {children}
                {stepId && (
                    <Flex column align="flex-start">
                        {isNonMessage ? null : <Spacer height={15} />}
                        {isNonMessage ? (
                            <Offset top={-6}>
                                <SimpleButton
                                    background="front.subtleAccent.background"
                                    cy={`cancel-${stepId}`}
                                    onClick={toggleState}
                                >
                                    <ButtonInset>{isNonMessage ? 'Skip' : 'Skip this step'}</ButtonInset>
                                </SimpleButton>
                            </Offset>
                        ) : (
                            <SimpleButton
                                background="front.subtleAccent.background"
                                cy={`cancel-${stepId}`}
                                onClick={toggleState}
                            >
                                <ButtonInset>{isNonMessage ? 'Skip' : 'Skip this step'}</ButtonInset>
                            </SimpleButton>
                        )}
                    </Flex>
                )}
            </Content>
        </Section>
    )
}

const Number = styled.div<{ none?: boolean }>`
    width: 30px;
    height: 30px;
    background-color: ${(p) => p.theme['front.subtleAccent.background']};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 9999px;

    ${(p) =>
        p.none &&
        css`
            opacity: 0;
        `}
`

const Section = styled.div<{ isLast?: boolean }>`
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
    ${(p) =>
        p.isLast &&
        css`
            border-bottom: none;
            margin-bottom: -5px;
        `}
`

const Content = styled.div<{ isNonMessage?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    padding: 6px 0;
    ${(p) =>
        p.isNonMessage &&
        css`
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        `}
`
