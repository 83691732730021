import styled, { css } from 'styled-components'

import ColorHash from 'color-hash'
import { Text } from './text'

const customHash = function (str: string) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        hash += str.charCodeAt(i) * 100
    }
    return hash
}

export const colorHash = new ColorHash({ hash: customHash })

export type TagBubbleType = 'full' | 'bordered' | 'hidden'

export interface TagStyleProps {
    name: string
    selectable?: boolean
    bubbleType: TagBubbleType
    important?: boolean
    marginRight?: number
}

export interface TagProps extends TagStyleProps {
    onClick?: (e: any, tagName: string) => void
}

export const Tag: React.FC<TagProps> = (p) => {
    const spotColor = colorHash.hex(p.name)

    // eslint-disable-next-line unused-imports/no-unused-vars-ts
    const { onClick, ...styleProps } = p
    return (
        <TagRow {...styleProps} important={p.name === 'important'}>
            {p.name != 'important' && <ColorSpot {...styleProps} color={spotColor} />}
            <TagName {...styleProps} important={p.name === 'important'}>
                <Text style={p.name === 'important' ? { fontSize: 10 } : undefined}>{p.name}</Text>
            </TagName>
        </TagRow>
    )
}

const TagRow = styled.div<TagProps>`
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    ${(p) =>
        p.marginRight
            ? css`
                  margin-right: ${p.marginRight}px;
              `
            : css`
                  margin-right: 0;
              `}
`

const TagName = styled.div<TagStyleProps>`
    margin-top: 0px;
    white-space: nowrap;
    overflow: initial;
    text-overflow: ellipsis;
    position: relative;
    ${(p) =>
        p.important &&
        css`
            background-color: ${(p) => p.theme['front.danger.color']};
            text-transform: uppercase;
            padding: 0px 5px 2px 5px;
            border-radius: 999px;
            color: ${(p) => p.theme['front.danger.text']};
            height: 14px;
            font-size: 9px;
            font-weight: 600;
            line-height: 17px;
            top: 0px;
        `}
`

const ColorSpot = styled.div<TagStyleProps>`
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    margin-right: 6px;
    border-radius: 999px;
    position: relative;
    border: 1px solid transparent;

    ${(p) =>
        p.important &&
        css`
            display: none;
        `}

    ${(p) => {
        if (p.bubbleType === 'hidden')
            return css`
                display: none;
            `

        if (p.bubbleType === 'bordered')
            return css`
                border: 1px solid ${p.color};
            `
        return css`
            background-color: ${p.color};
        `
    }}
`
