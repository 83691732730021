import { createActionCreator } from 'deox'
import { CutterCompany } from './types'

const Prefix = 'APPLICATION_INTERNALS_COMPANY'

export const ApplicationInternalsCompanyActions = {
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) => (applicationId: string, company: CutterCompany) =>
            resolve({ applicationId, company })
    )
}
