import { Text } from '@/components/general/text'
import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import { LoaderView } from '@/components/loaders/loader'
import { Box } from '@/design-system'
import {
    FormProvider,
    ListPostingSection,
    ReconciliationModal,
    SingleVoucherSection,
    useReconciliationParams,
    VoucherActions
} from '@/features/reconciliation'
import { useGetAccounts, useGetPostings, useGetVoucher } from '@/services/reconciliation'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ReconciliationVoucherReconcile: React.FC = () => {
    const { id } = useReconciliationParams()
    const navigate = useNavigate()

    const { data: voucherData, isLoading: voucherLoading, isError: voucherError } = useGetVoucher(id)
    const { data: postingsData, isLoading: postingsLoading, isError: postingsError } = useGetPostings()
    const { data: accountsData, isLoading: accountsLoading, isError: accountsError } = useGetAccounts()

    const isLoading = voucherLoading || postingsLoading || accountsLoading
    const hasNoData = !voucherData || !postingsData || !accountsData

    useEffect(() => {
        if (voucherError || postingsError || accountsError) {
            navigate(-1)
        }
    }, [accountsError, voucherError, postingsError])

    if (isLoading) {
        return <LoaderView overBackground="front.background" type="l" minHeight="200" />
    }

    if (hasNoData) {
        return <Text>No data available</Text>
    }

    return (
        <FormProvider data="voucher">
            <ReconciliationModal type="voucher">
                <Box mb="20">
                    <Flex column>
                        <SingleVoucherSection voucher={voucherData} accounts={accountsData.items} />
                        {!voucherData.reconciled && (
                            <>
                                <Spacer width={20} />
                                <ListPostingSection />
                                <Box mt="6">
                                    <VoucherActions />
                                </Box>
                            </>
                        )}
                    </Flex>
                </Box>
            </ReconciliationModal>
        </FormProvider>
    )
}
