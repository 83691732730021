import { Icon } from '@/components/icons/icon'
import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import { Text } from '@/components/general/text'
import React from 'react'

export const FinalizedLabel: React.FC = () => {
    return (
        <Flex align="center">
            <Icon type="checkmark" color="front.success.color" />
            <Spacer width={8} />
            <Text color="front.text">Report finalized</Text>
        </Flex>
    )
}
