import { Accounts } from './accounts/accounts'
import { Company } from './company/company'
import { Management } from './management/management'
import React from 'react'
import { Spacer } from '@/components/layout/spacer'
import { Ubo } from './ubo/ubo'
import { Websites } from './websites/websites'
import { SectionContainer } from '../section-container'
import { OwnershipStructure } from './ownership-structure/ownership-structure'

export const DocumentationSection: React.FC = () => {
    return (
        <SectionContainer title="Documentation">
            <Company />
            <Spacer height={24} />
            <Websites />
            <Spacer height={24} />
            <Accounts />
            <Spacer height={24} />
            <Management />
            <Spacer height={24} />
            <Ubo />
            <Spacer height={24} />
            <OwnershipStructure />
        </SectionContainer>
    )
}
