import { LinkButton } from '../../components/buttons/linkButton'
import { LinkButtonArrow } from '../../components/buttons/linkButtonArrow'
import { Spacer } from '../../components/layout/spacer'

export const MerchantAccountLinks: React.FC<{ appId: string; accId: string; MID: string; state: string }> = ({
    appId,
    state,
    accId,
    MID
}) => {
    if (state !== 'live' && state !== 'closed') return null
    return (
        <>
            <LinkButton to={`/merchant/${appId}/accounts/${accId}/transactions/${MID}`}>
                Transactions
                <Spacer width={5} />
                <LinkButtonArrow background="back.background" />
            </LinkButton>
            <Spacer width={20} />
            <LinkButton to={`/merchant/${appId}/accounts/${accId}/disputes/${MID}`}>
                Disputes
                <Spacer width={5} />
                <LinkButtonArrow background="back.background" />
            </LinkButton>
            <Spacer width={20} />
            <LinkButton to={`/merchant/${appId}/accounts/${accId}/settlements/${MID}`}>
                Settlements
                <Spacer width={5} />
                <LinkButtonArrow background="back.background" />
            </LinkButton>
        </>
    )
}
