import { useBatchUpdateTask } from '@/services/taskr'
import { useFinalizeReport } from '@/services/ongoing-due-diligence'
import { useTasks } from '../../../../api'
import { getTaskUrls } from '../../../../helpers'
import { useReportParams } from '../../../../hooks'

export const useOpenTasks = () => {
    const { applicationId } = useReportParams()

    return useTasks({ state: 'open', applicationId })
}

export const useFinalizeActions = () => {
    const { reportId } = useReportParams()

    const openTasks = useOpenTasks()
    const [finalizeReport, reportResult] = useFinalizeReport()
    const [batchUpdateTask, batchUpdateTaskResult] = useBatchUpdateTask()

    const isLoading = reportResult.isLoading || batchUpdateTaskResult.isLoading || openTasks.isLoading

    const finalize = async () => {
        // create report
        const createReportPayload = { reportId }
        const report = await finalizeReport(createReportPayload).unwrap()

        // set open tasks closed
        const taskUrls = getTaskUrls(openTasks.data?.embedded.tasks)
        await batchUpdateTask({ urls: taskUrls, data: { action: 'close_task' } })

        return { report }
    }

    return { isLoading, finalize }
}
