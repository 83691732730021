import React, { createRef, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useSelectable } from '../../hooks/general/useSelectable'
import { Icon } from '../icons/icon'
import { MODAL_ID } from '../modals/modalIds'
import { DropdownListItem } from './dropdownListItem'
import { DropdownListNotFoundItem } from './dropdownListNotFoundItem'

export const DropdownMultiSelectableList: React.FC<{
    items: string[]
    filteringValue?: string
    dropdownId: MODAL_ID
    textForItem: (item: string) => string
    nodeForItem?: (item: string) => React.ReactNode
    selectedItems?: string[]
    onBlankEnter?: (value?: string) => void
    onSelect: (items: string[]) => void
}> = ({ items, filteringValue, onSelect, textForItem, nodeForItem, onBlankEnter, dropdownId, selectedItems = [] }) => {
    const filteredItems = useMemo(() => {
        if (!filteringValue) return items
        return items.filter((i) => textForItem(i).toLowerCase().includes(filteringValue.toLowerCase()))
    }, [filteringValue, items, textForItem])

    useModalStackSync(null, dropdownId, undefined)
    const onSelectionMade = useCallback(
        (item: string) => {
            const newItems = selectedItems.includes(item)
                ? selectedItems.filter((i) => i !== item)
                : [...selectedItems, item]
            onSelect(newItems)
        },
        [selectedItems, onSelect]
    )

    const itemRefs = useMemo(() => {
        return filteredItems?.map?.(() => createRef())
    }, [filteredItems]) as any

    const onSelectionChange = useCallback(
        (index) => {
            itemRefs?.[index]?.current?.scrollIntoView?.({
                block: 'center',
                inline: 'center'
            })
        },
        [itemRefs]
    )

    const { onMouseClick, highlighted } = useSelectable(
        filteredItems,
        dropdownId,
        onSelectionMade,
        onBlankEnter,
        onSelectionChange
    )

    const checkedBullet = useMemo(() => {
        return (
            <Circle isActive className="circle">
                <IconOffset>
                    <Icon type="checkmark" size={7} weight={1.5} />
                </IconOffset>
            </Circle>
        )
    }, [])

    const uncheckedBullet = useMemo(() => {
        return <Circle className="circle" />
    }, [])

    return (
        <ListHolder>
            {filteredItems?.length > 0 ? (
                filteredItems.map((i, index) => {
                    return (
                        <DropdownListItem
                            ref={itemRefs[index]}
                            data-cy={`dropdown-item ${
                                selectedItems?.includes(i) ? 'dropdown-item-selected' : 'dropdown-item-selectable'
                            } ${index === highlighted ? 'dropdown-item-highlighted' : ''}`}
                            key={i}
                            data-list-index={index}
                            isHovered={index === highlighted}
                            isActive={selectedItems?.includes(i)}
                            onClick={onMouseClick}
                        >
                            {selectedItems?.includes(i) ? checkedBullet : uncheckedBullet}
                            <Label>{nodeForItem ? nodeForItem(i) : textForItem(i)}</Label>
                        </DropdownListItem>
                    )
                })
            ) : (
                <DropdownListNotFoundItem />
            )}
        </ListHolder>
    )
}

const Label = styled.div`
    position: relative;
    top: 2px;
`

const IconOffset = styled.div`
    position: relative;
    transform: translateY(-0.8px) translateX(0.2px);
`

const Circle = styled.div<{ isActive?: boolean }>`
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-right: 10px;
    width: 13px;
    position: relative;
    height: 13px;
    flex-shrink: 0;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) =>
        p.isActive &&
        css`
            background-color: ${(p) => p.theme['front.accent.color']};
            color: #fff;
            border-color: ${(p) => p.theme['front.accent.color']} !important;
        `}
`

const ListHolder = styled.div`
    display: block;
    flex-direction: column;
    align-items: stretch;
`
