import { PersonRoles } from '../../store/applications/types'
import * as yup from 'yup'
import { applicationValidationMessages } from './applicationValidationMessages'

const supportedCurrencies = ['DKK', 'EUR', 'SEK', 'NOK', 'GBP', 'USD', 'CZK', 'HUF', 'PLN', 'RON', 'CHF'] as const

export const applicationValidation = yup.object().shape({
    business_model: yup
        .object()
        .shape({
            delivery_delay: yup.string().when('physical_delivery', {
                is: true,
                then: (schema) => {
                    return schema
                        .oneOf(
                            ['less5days', '5to10days', '10to20days', 'more20days'],
                            applicationValidationMessages.business_model.delivery_delay
                        )
                        .required(applicationValidationMessages.business_model.delivery_delay)
                }
            }),
            description: yup.string().required(applicationValidationMessages.business_model.description),
            drop_shipping: yup.boolean().required(applicationValidationMessages.business_model.drop_shipping),
            estimate_currency: yup
                .string()
                .min(3, applicationValidationMessages.business_model.estimate_currency)
                .max(3, applicationValidationMessages.business_model.estimate_currency)
                .required(applicationValidationMessages.business_model.estimate_currency),
            estimated_average_transaction_amount: yup
                .number()
                .required(applicationValidationMessages.business_model.estimated_average_transaction_amount),
            estimated_monthly_turnover: yup
                .number()
                .min(1, applicationValidationMessages.business_model.estimate_currency)
                .required(applicationValidationMessages.business_model.estimated_monthly_turnover),
            physical_delivery: yup.boolean().required(applicationValidationMessages.business_model.physical_delivery),
            recurring: yup.boolean(),
            trading_name: yup.string().required(applicationValidationMessages.business_model.trading_name)
        })
        .required(),
    company: yup
        .object()
        .shape({
            address_line_1: yup.string().required(applicationValidationMessages.company.address_line_1),
            city: yup.string().required(applicationValidationMessages.company.city),
            country: yup
                .string()
                .min(2, applicationValidationMessages.company.country)
                .max(2, applicationValidationMessages.company.country)
                .required(applicationValidationMessages.company.country),
            email: yup
                .string()
                .email(applicationValidationMessages.company.email)
                .required(applicationValidationMessages.company.email),
            name: yup.string().required(applicationValidationMessages.company.name),
            phone: yup.string().required(applicationValidationMessages.company.phone),
            registration_number: yup.string().required(applicationValidationMessages.company.registration_number),
            zipcode: yup.string().required(applicationValidationMessages.company.zipcode),
            form: yup.string().notRequired(),
            documentation: yup.array().when('country', {
                is: (country) => country !== 'DK',
                then: (schema) => {
                    return schema
                        .min(1, applicationValidationMessages.company.documentation)
                        .required(applicationValidationMessages.company.documentation)
                },
                otherwise: (schema) => {
                    return schema.notRequired()
                }
            }),
            suretyship: yup.array().when('form', {
                is: (form) => form === 'Iværksætterselskab',
                then: (schema) => {
                    return schema
                        .min(1, applicationValidationMessages.company.suretyship)
                        .required(applicationValidationMessages.company.suretyship)
                },
                otherwise: (schema) => {
                    return schema.notRequired()
                }
            })
        })
        .required(),
    bank_account: yup
        .object()
        .shape({
            bank: yup.string().required(applicationValidationMessages.bank_account.bank),
            currency: yup
                .string()
                .oneOf(supportedCurrencies as any, applicationValidationMessages.bank_account.currency)
                .required(applicationValidationMessages.bank_account.currency),
            iban: yup.string().required(applicationValidationMessages.bank_account.iban),
            swift_code: yup.string().required(applicationValidationMessages.bank_account.swift_code)
        })
        .required(),
    contact: yup.object().shape({
        email: yup
            .string()
            .email(applicationValidationMessages.contact.email)
            .required(applicationValidationMessages.contact.email),
        name: yup.string().required(applicationValidationMessages.contact.name),
        phone: yup.string().required(applicationValidationMessages.contact.phone)
    }),
    people: yup
        .array()
        .min(1, applicationValidationMessages.people.no_persons_added)
        .of(
            yup.object().shape({
                address_line_1: yup.string().required(applicationValidationMessages.people.address_line_1),
                city: yup.string().required(applicationValidationMessages.people.city),
                country: yup.string().required(applicationValidationMessages.people.country),
                date_of_birth: yup.string().required(applicationValidationMessages.people.date_of_birth),
                name: yup.string().required(applicationValidationMessages.people.name),
                role: yup.string().oneOf(PersonRoles).required(applicationValidationMessages.people.role),
                social_security_number: yup
                    .string()
                    .required(applicationValidationMessages.people.social_security_number),
                zipcode: yup.string().required(applicationValidationMessages.people.zipcode),
                address_legitimation: yup
                    .array()
                    .min(1, applicationValidationMessages.people.address_legitimation)
                    .required(applicationValidationMessages.people.address_legitimation),
                picture_legitimation: yup
                    .array()
                    .min(1, applicationValidationMessages.people.picture_legitimation)
                    .required(applicationValidationMessages.people.picture_legitimation)
            })
        )
        .test('min-one-director', applicationValidationMessages.people.no_director, (value) => {
            // If there is no persons added, we should not test for a director
            if (!value || value.length === 0) {
                return true
            }

            return value ? value.some((p) => p.role === 'director' || p.role === 'director-and-owner') : false
        }),
    signer: yup
        .object()
        .shape({
            email: yup
                .string()
                .email(applicationValidationMessages.signer.email)
                .required(applicationValidationMessages.signer.email),
            name: yup.string().required(applicationValidationMessages.signer.name)
        })
        .required(),
    websites: yup
        .array()
        .min(1, applicationValidationMessages.websites.no_websites_added)
        .of(
            yup.object().shape({
                url: yup
                    .string()
                    .transform((value: string) => {
                        if (value.startsWith('http://') || value.startsWith('https://')) {
                            return value
                        } else {
                            return `http://${value}`
                        }
                    })
                    .required(applicationValidationMessages.websites.url)
            })
        )
        .required()
})
