import React from 'react'
import { MonitoredChange, MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { Text } from '../../../../../../../components/general/text'
import { MonitoringPersonAdded } from '../../../cards/MonitoringPersonAdded/MonitoringPersonAdded'
import { isObject } from 'lodash'
import { useDispatch } from 'react-redux'
import { TasksCompanyChangesActions } from '../../../../../../../store/tasksCompanyChanges/actions'

interface CardUboAddedProps {
    taskId: string
    applicationData: MonitoredFetchedApplicationData
    change: MonitoredChange
}

export const CardUboAdded: React.FC<CardUboAddedProps> = (props) => {
    const { taskId, applicationData, change } = props

    const dispatch = useDispatch()

    if (!applicationData) {
        return <Text>Failed to fetch application data, task is therefore not shown.</Text>
    }

    const after = isObject(change.after) ? change.after : null

    const isMuted = !!applicationData.muted?.ownership

    const handleOnMute = (_, watcherId) => {
        const applicationId = applicationData?.application.id

        if (applicationId) {
            dispatch(
                TasksCompanyChangesActions.CHANGE_MUTED_STATE(applicationId, taskId, watcherId, {
                    ownership: !isMuted
                })
            )
        }
    }

    return (
        <MonitoringPersonAdded
            type="ownership"
            applicationData={applicationData}
            subsectionId={change.subsectionId}
            after={after}
            onMute={handleOnMute}
            isMuted={isMuted}
        />
    )
}
