import moment from 'moment'
import { SettlementSummary } from '../../store/settlements/types'

export const getSettlementPayoutStatus = (s: SettlementSummary): undefined | 'Upcoming' | 'Completed' | 'Cancelled' => {
    if (s.payout) {
        if (moment(s.payout.date).isAfter(moment())) {
            if (!s.payout.cancelledDate) return 'Upcoming'
            else return 'Cancelled'
        } else {
            if (!s.payout.cancelledDate) return 'Completed'
            else return 'Cancelled'
        }
    }
    return undefined
}

export const getSettlementReserveStatus = (s: SettlementSummary): undefined | 'Upcoming' | 'Released' | 'Cancelled' => {
    if (s.reserve) {
        if (moment(s.reserve.date).isAfter(moment())) {
            if (!s.reserve.cancelledDate) return 'Upcoming'
            else return 'Cancelled'
        } else {
            if (!s.reserve.cancelledDate) return 'Released'
            else return 'Cancelled'
        }
    }
    return undefined
}
