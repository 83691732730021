import {
    MerchantApplicationTimelineSectionType,
    MerchantTimelineHistoryItem
} from './types'

function getSectionFromPath(path: string) {
    const section = path.replace('application.', '')

    const renameSection = (section: string) => {
        const sectionRenameScheme: {
            [key: string]: MerchantApplicationTimelineSectionType
        } = {
            bankAccounts: 'bankAccount',
            people: 'person'
        }

        return sectionRenameScheme[section] || section
    }

    if (section.includes('.'))
        return {
            section: renameSection(section.split('.')[0]),
            sectionIndex: section.split('.')[1]
        }

    return {
        section: renameSection(section),
        sectionIndex: undefined
    }
}

export function convertApplicationVersionsToMerchantApplicationTimelineItems(versions: {
    [id: string]: {
        author: string
        createdAt: string
        id: string
        objectChanges: {
            [field: string]: any[]
        }
        updatedAt: string[]
        resource: string
    }
}): Array<MerchantTimelineHistoryItem> {
    const items: Array<MerchantTimelineHistoryItem> = []
    Object.keys(versions).forEach((id) => {
        const version = versions[id]
        if (version.resource === 'application') {
            Object.keys(version.objectChanges).forEach((field: any) => {
                if (field === 'updatedAt') return
                if (field === 'state') {
                    items.push({
                        type: 'state-change',
                        section: 'application',
                        field,
                        from: version.objectChanges[field][0],
                        to: version.objectChanges[field][1],
                        at: version.createdAt,
                        by: version.author
                    })
                    return
                }
                items.push({
                    type: 'field-change',
                    section: 'application',
                    field,
                    from: version.objectChanges[field][0],
                    to: version.objectChanges[field][1],
                    at: version.createdAt,
                    by: version.author
                })
            })
            return
        }
        if (version.resource.includes('applicationFiles')) {
            if (version.resource.includes('company')) {
                items.push({
                    type: 'file-update',
                    section: 'company',
                    field: version.objectChanges.label?.[1],
                    from: {
                        id: version.objectChanges.id?.[0],
                        filename: version.objectChanges.documentFileName?.[0]
                    },
                    to: {
                        id: version.objectChanges.id?.[1],
                        filename: version.objectChanges.documentFileName?.[1]
                    },
                    at: version.createdAt,
                    by: version.author
                })
            }
            if (version.resource.includes('people')) {
                items.push({
                    type: 'file-update',
                    section: 'person',
                    field: version.objectChanges.label?.[1],
                    from: {
                        id: version.objectChanges.id?.[0],
                        filename: version.objectChanges.documentFileName?.[0]
                        // size: version.objectChanges.documentFileSize[0]
                    },
                    to: {
                        id: version.objectChanges.id?.[1],
                        filename: version.objectChanges.documentFileName?.[1]
                        // size: version.objectChanges.documentFileSize[1]
                    },
                    at: version.createdAt,
                    by: version.author
                })
                return
            }
            items.push({
                type: 'file-update',
                section: 'application',
                field: version.objectChanges.label?.[1],
                from: {
                    id: version.objectChanges.id?.[0],
                    filename: version.objectChanges.documentFileName?.[0]
                },
                to: {
                    id: version.objectChanges.id?.[1],
                    filename: version.objectChanges.documentFileName?.[1]
                },
                at: version.createdAt,
                by: version.author
            })
            return
        }
        Object.keys(version.objectChanges).forEach((field: any) => {
            if (field === 'updatedAt') return
            items.push({
                ...getSectionFromPath(version.resource),
                type: 'field-change',
                field,
                from: version.objectChanges[field][0],
                to: version.objectChanges[field][1],
                at: version.createdAt,
                by: version.author
            })
        })
    })
    return items
}
