import styled from 'styled-components'

import { CardSection } from '../../components/cards/cardSection'
import { LinkButton } from '../../components/buttons/linkButton'
import { LinkButtonArrow } from '../../components/buttons/linkButtonArrow'
import { List } from '../../components/layout/list'
import { Spacer } from '../../components/layout/spacer'
import { Transaction } from '../../store/transactions/types'
import { numberWithCurrency } from '../../utils'
import { CardInset } from '../../components/cards/cardInset'

export const TransactionSettlement: React.FC<{
    s: Transaction['settlement']
    link: string
}> = ({ s, link }) => {
    const currenciedValue = (v: number) => {
        return `${numberWithCurrency(s.currency, `${v}`)} ${s.currency}`
    }
    return (
        <Holder>
            <CardSection background="subtleBlue">
                <CardInset>
                    <Spacer height={5} />
                    <List
                        background="front.subtleAccent.background"
                        items={{
                            'Related settlement': {
                                type: 'custom',
                                node: (
                                    <LinkButton color="front.accent.color" target="dashboard" to={link}>
                                        <LinkButtonArrow shouldBeBordered background="front.background" />
                                        <Spacer width={5} />
                                        Settlement
                                    </LinkButton>
                                )
                            },
                            'Amount gross': currenciedValue(s.amountGross),
                            'Fees': currenciedValue(s.fees),
                            'Amount net': currenciedValue(s.amountNet)
                        }}
                        switchToRowsAt={800}
                        template="1fr 100px 100px 100px"
                        cellHorizontalTemplate="100px auto"
                    />
                </CardInset>
            </CardSection>
        </Holder>
    )
}

const Holder = styled.div`
    margin-top: 5px;
    width: 100%;
`
