import { createActionCreator } from 'deox'
import { Agent, SelectableAgent } from './types'

const Prefix = 'APPLICATION_INTERNALS_AGENTS'

export const ApplicationInternalsAgentsActions = {
    ASSIGN_AGENT: createActionCreator(
        `${Prefix}/ASSIGN_AGENT`,
        (resolve) => (watcherID: string, applicationId: string, agent: SelectableAgent) =>
            resolve({ watcherID, applicationId, agent })
    ),
    FETCH: createActionCreator(
        `${Prefix}/FETCH`,
        (resolve) => (applicationId: string, link: string) => resolve({ link, applicationId })
    ),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) =>
            (applicationId: string, response?: { agents?: [Agent]; count: number; selectable: SelectableAgent[] }) =>
                resolve({ applicationId, response })
    )
}
