export type MerchantApplicationInternalNeedingAttentionState = {
    [applicationId: string]: {
        processFinished?: true
        unreadComments?: {
            [id: string]: true
        }
        fieldsWithUnreadComments?: {
            [indexPath: string]: true
        }
        internalNotesNeedingAttention?: boolean
    }
}

// eslint-disable-next-line max-len
export const InitialMerchantApplicationInternalNeedingAttentionState: MerchantApplicationInternalNeedingAttentionState = {}
