import { FormCheckBox } from './form-checkbox'
import { FormField as FormFieldType } from '../../../types'
import { FormRadio } from './form-radio'
import { FormTextarea } from './form-textarea'
import React from 'react'
import { Spacer } from '@/components/layout/spacer'
import { Text } from '@/components/general/text'

interface FormFieldProps extends FormFieldType {
    disabled?: boolean
}

export const FormField: React.FC<FormFieldProps> = (props) => {
    const { name, type, label = '', placeholder = 'Write here...', disabled } = props

    if (type === 'textarea') {
        return (
            <>
                {label && (
                    <>
                        <Text color="front.text.subtlerI">{label}</Text>
                        <Spacer height={12} />
                    </>
                )}
                <FormTextarea name={name} placeholder={placeholder} disabled={disabled} />
            </>
        )
    }

    if (type === 'checkbox') {
        return <FormCheckBox name={name} label={label} disabled={disabled} />
    }

    if (type === 'radio') {
        return <FormRadio name={name} label={label} options={['Yes', 'No', 'Not checked']} disabled={disabled} />
    }

    return null
}
