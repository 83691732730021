import { ActionType } from 'deox'
import { put } from 'redux-saga/effects'

import { DELETE, GET, PATCH, POST } from '../../../generators/networking'
import { ApplicationInternalsRecordActions } from './actions'
import { MerchantNotification } from './types'

export const ApplicationInternalsRecordSagas = {
    *FETCH({ payload: p }: ActionType<typeof ApplicationInternalsRecordActions.FETCH>) {
        const response = yield* GET({
            url: p.recordLink,
            include: ['self', 'subscriptions'],
            onSuccessDispatch: (r) => ApplicationInternalsRecordActions.STORE(r.id, r),
            errorText: "Failed to fetch Application's Record."
        })
        const subscriptionsResponse: {
            subscriptions: MerchantNotification[]
        } = yield* GET({
            // eslint-disable-next-line max-len
            url: response.subscriptionsLink,
            errorText: "Failed to load account's notifications.",
            include: ['self']
        })

        yield put(ApplicationInternalsRecordActions.STORE_NOTIFICATIONS(p.id, subscriptionsResponse.subscriptions))
    },
    *UPDATE_NOTIFICATION(p: ActionType<typeof ApplicationInternalsRecordActions.UPDATE_NOTIFICATION>) {
        yield PATCH({
            successCode: 200,
            successText: 'Notification updated successfully',
            errorText: 'Failed to update notification',
            watcher: p.payload.watcherId,
            url: p.payload.notificationLink,
            include: ['self'],
            onSuccessDispatch: (r) => ApplicationInternalsRecordActions.STORE_NOTIFICATION(p.payload.applicationId, r),
            body: {
                protocol: p.payload.protocol,
                events: p.payload.types,
                endpoint: p.payload.endpoint
            }
        })
    },
    *CREATE_NOTIFICATION(p: ActionType<typeof ApplicationInternalsRecordActions.CREATE_NOTIFICATION>) {
        yield POST({
            successCode: 201,
            successText: 'Notification created successfully',
            errorText: 'Failed to create notification',
            watcher: p.payload.watcherId,
            url: p.payload.subscriptionsLink,
            include: ['self'],
            onSuccessDispatch: (r) => ApplicationInternalsRecordActions.STORE_NOTIFICATION(p.payload.applicationId, r),
            body: {
                protocol: p.payload.protocol,
                events: p.payload.types,
                endpoint: p.payload.endpoint
            }
        })
    },
    *REVOKE_NOTIFICATION(p: ActionType<typeof ApplicationInternalsRecordActions.REVOKE_NOTIFICATION>) {
        yield DELETE({
            successCode: 200,
            successText: 'Notification revoked successfully',
            errorText: 'Failed to revoke notification',
            watcher: p.payload.watcherId,
            url: `${p.payload.selfLink}`,
            include: ['self'],
            onSuccessDispatch: () =>
                ApplicationInternalsRecordActions.UNSTORE_NOTIFICATION(
                    p.payload.applicationId,
                    p.payload.notificationId
                )
        })
    }
}
