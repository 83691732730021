import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { RootState } from '@/store'
import { MerchantApplicationReviewableResource } from './Application.StructureTypes'

export const MerchantApplicationSectionStatusDot: React.FC<{
    applicationId: string
    resource: MerchantApplicationReviewableResource
    subsectionIndex?: number
}> = ({ subsectionIndex, resource, applicationId }) => {
    const shouldShow = useSelector((state: RootState) => {
        return state.applications.applications?.at?.[applicationId]?.metadata?.state === 'under_review'
    })
    const checksState = useSelector((state: RootState) => {
        return state.applicationInternals.checks.forApplication[applicationId]
    })

    const checkStatePath = useMemo(() => {
        if (!resource) return ''
        if (subsectionIndex !== undefined) return `${resource.cutterPath}.${subsectionIndex}`
        return resource.cutterPath
    }, [resource, subsectionIndex])

    const isChecked = useMemo(() => {
        if (resource.multi) {
            let iterated = 0
            let done = 0
            if (!checksState) return false
            Object.keys(checksState).forEach((key) => {
                if (key.includes(resource.cutterPath)) {
                    iterated++
                    if (checksState?.[key]?.fields?.[resource.field]?.isChecked === true) done++
                }
            })

            return done === iterated && iterated != 0
        } else {
            if (checksState?.[checkStatePath]?.fields?.[resource.field]?.isChecked === true) return true
            return false
        }
    }, [checkStatePath, checksState, resource])

    return shouldShow ? <Bullet done={isChecked} /> : null
}

const Bullet = styled.div<{ done?: boolean }>`
    width: 2px;
    height: 2px;
    border-radius: 99px;
    flex-shrink: 0;
    border: 1px solid ${(p) => p.theme['back.text.subtlerI']};
    margin-right: 10px;
    ${(p) =>
        p.done &&
        css`
            border-color: ${(p) => p.theme['front.accent.color']};
            background-color: ${(p) => p.theme['front.accent.color']};
        `};
`
