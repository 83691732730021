import { useUnreconcileVoucher } from '@/services/reconciliation'
import { useReconciliationParams } from './use-reconciliation-params'

export const useUnreconcileVoucherActions = () => {
    const { id: voucherId } = useReconciliationParams()

    const [unreconcileVoucher, unreconciliationResult] = useUnreconcileVoucher()
    const isLoading = unreconciliationResult.isLoading

    const unreconcile = async () => {
        const unreconciliation = await unreconcileVoucher({ voucherId }).unwrap()

        return { unreconciliation }
    }

    return { isLoading, unreconcile }
}
