import { kebabCase } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'

export const MerchantApplicationRowHolder: React.FC<{
    indexPath: MerchantApplicationResourceIndexPath
    label: string
    children: any
}> = ({ indexPath, children, label }) => {
    return (
        <Holder
            data-cy={`${indexPath.resourceKey}-${indexPath.subsectionIndex || 0}-field-row field-row ${`${
                indexPath.resourceKey
            }-${indexPath.subsectionIndex || 0}-field-row-${kebabCase(label)}-END`}`}
        >
            {children}
        </Holder>
    )
}

const Holder = styled.div`
    display: contents;
`
