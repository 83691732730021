import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { ToastsDispatchPush } from './toasts/actions'
import { AppDispatch } from './store'

interface MetaArg {
    endpointName?: string
}

interface Payload {
    data?: {
        statusCode?: number
    }
}

export const rtkQueryErrorLogger: Middleware = (store: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.warn(action)

        const { error, meta, payload } = action
        const message = error?.message ? error.message : 'An error occurred'
        const endpointName = (meta?.arg as MetaArg)?.endpointName

        const context = endpointName
            ? // eslint-disable-next-line max-len
              `An error occurred while trying to make a request to endpoint "${endpointName}". Reload to try again or contact tech if the issue persists.`
            : undefined

        const statusCode = (payload as Payload)?.data?.statusCode

        if (statusCode && statusCode >= 400) {
            ;(store.dispatch as AppDispatch)(ToastsDispatchPush(message, 'error', undefined, undefined, context))
        }
    }

    return next(action)
}
