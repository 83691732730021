/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from 'deox'
import { call, put } from 'redux-saga/effects'
import { getJWT } from './../../../store/auth/sagas'

import { WatcherDispatchFail, WatcherDispatchStart, WatcherDispatchSuccess } from '../../watcher/actions'
import { BVDActions } from './actions'
import { ToastsDispatchPush } from '../../toasts/actions'
import { v4 as uuid } from 'uuid'

export const BVDSagas = {
    *FETCH_BVD({ payload }: ActionType<typeof BVDActions.FETCH_BVD>) {
        yield put(WatcherDispatchStart([payload.watcherId]))

        const token: string = yield getJWT()
        const headers = new Headers({
            Authorization: `Bearer ${token}`,
            Accept: 'application/json, text/plain, */*'
        })

        const response: Response = yield call(
            fetch,
            `${import.meta.env.VITE_KVASIR_API}/companies/${payload.bvdId}/ownership-tree`,
            {
                method: 'GET',
                headers
            }
        )
        const r: Response = yield response.json()
        if (r.status === 200) {
            yield put(BVDActions.STORE_BVD(payload.applicationId, (r as any).body))
            yield put(WatcherDispatchSuccess([payload.watcherId], 'BvD Information fetched successfully'))
        } else if (r.status === 404) {
            yield put(
                ToastsDispatchPush(
                    'The entered BvD ID is incorrect. (The correct BvD ID be found in Orbis)',
                    'error',
                    uuid(),
                    'longer'
                )
            )
        } else if (r.status === 504) {
            yield put(
                ToastsDispatchPush(
                    'Correct BvD ID, but the structure was too large to fetch. [Timeout]',
                    'error',
                    uuid(),
                    'longer'
                )
            )
        } else {
            yield put(WatcherDispatchFail([payload.watcherId], 'Failed to fatch the BvD.'))
        }
    }
}
