import { MerchantDataPerson } from '@/services/ongoing-due-diligence'
import React from 'react'
import { Section } from '../../../common/section'
import { PersonFilesDataItems } from './person-files-data-items'

const order = [
    { key: 'name' },
    { key: 'social_security_number' },
    { key: 'role_owner', title: 'Owner' },
    { key: 'role_director', title: 'Director' },
    { key: 'date_of_birth' },
    { key: 'address_line_1', title: 'Address line 1' },
    { key: 'address_line_2', title: 'Address line 2' },
    { key: 'city' },
    { key: 'zipcode' },
    { key: 'country' }
]

interface PersonMerchantDataProps {
    title: string
    person: MerchantDataPerson
}

export const PersonMerchantData: React.FC<PersonMerchantDataProps> = (props) => {
    const { title, person } = props

    return (
        <>
            <Section.IndentetContent>
                <Section.Title color="normal">{title}</Section.Title>
            </Section.IndentetContent>
            <Section.Spacer size="s" />
            <Section.DataItems order={order} data={person} />
            <PersonFilesDataItems personId={person.id} />
        </>
    )
}
