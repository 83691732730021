import React, { forwardRef, useCallback, useMemo, useState } from 'react'
// eslint-disable-next-line max-len
import { MerchantApplicationFieldFormProps } from '../../pages/Merchant/Application/Application.FieldFormProps'
import { MerchantApplicationResourceIndexPath } from '../../store/applicationResources/types'
import { ConvertIndexPathToIndexPathString } from '../../store/applicationResources/utils'

import { TextInputSelect } from './textInputSelect'
import * as yup from 'yup'
import { ValidationStatus } from '../../hooks/general/useForm'
import { Color } from '../../styles/colors'

export const QuestionToggleWithRef: React.ForwardRefRenderFunction<
    any,
    {
        selectedOption: boolean
        onSelect?: (option: boolean) => void
        onBlur: (e: any) => void
        ref: any
        overBackground: Color
        validation: ValidationStatus
        indexPath: MerchantApplicationResourceIndexPath
        formRef: MerchantApplicationFieldFormProps['formRef']
    }
> = ({ selectedOption, overBackground, validation, onBlur, formRef, indexPath }) => {
    const [selected, setSelected] = useState<boolean>(selectedOption)

    const { list, dictionary } = useMemo(() => {
        return {
            list: [true, false],
            dictionary: { true: 'Yes', false: 'No' }
        }
    }, [])

    const textForItem = useCallback(
        (item: any) => {
            return (dictionary as any)[item]
        },
        [dictionary]
    )

    return (
        <TextInputSelect
            placeholder="-"
            overBackground={overBackground}
            selected={selected}
            noClear
            validation={validation}
            textareaBased
            ref={(ref) => {
                formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
            }}
            onBlur={onBlur}
            onSelect={(i: any) => setSelected(i)}
            dropdownId="GenericDropdown"
            isQuestion
            textForItem={textForItem}
            isSeamless
            items={list}
        />
    )
}

export const QuestionToggle = forwardRef(QuestionToggleWithRef)
