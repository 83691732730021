import { getFileByLabel } from '../../../../../helpers'
import { Files } from '@/services/merchant-api'
import { FileLink } from '../../../common/file-link'
import { Section } from '../../../common/section'

interface CompanyFileLinks {
    files: Files
}

export const CompanyFileLinks: React.FC<CompanyFileLinks> = (props) => {
    const { files } = props

    const googleReport = getFileByLabel('google_report', files)
    const trustpilotReport = getFileByLabel('extra_report2', files)

    return (
        <>
            <FileLink file={googleReport}>Google report</FileLink>
            <Section.Spacer size="s" />
            <FileLink file={trustpilotReport}>Trustpilot report</FileLink>
        </>
    )
}
