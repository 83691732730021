import React from 'react'
import { Section } from '../../../common/section'
import { ToggleContent } from '../toggle-content/toggle-content'
import { useReportSection } from '../../../provider/report-provider/report-provider'
import { SectionCheck } from '@/services/ongoing-due-diligence'

export const SanctionListsOwnersListed: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')

    const data = checks.sanction_lists_owners_listed
    const commentData = checks.comment_sanction_lists_owners_listed

    const worldComplianceLink = 'https://members.worldcompliance.com/SignIn.aspx'

    return (
        <Section>
            <Section.Content>
                <ToggleContent
                    dataName="sanction_lists_owners_listed"
                    data={data}
                    commentName={'comment_sanction_lists_owners_listed'}
                    commentData={commentData}
                    visibleValue="Yes"
                />
            </Section.Content>
            <Section.Metadata>
                <Section.ExternalLink url={worldComplianceLink}>World compliance</Section.ExternalLink>
            </Section.Metadata>
        </Section>
    )
}
