import { Spacer as SpacerComp } from '@/components/layout/spacer'
import { ExternalLink as ExternalLinkComp } from '@/components/buttons/externalLink'
import { OrderedItems, OrderedOrder, getOrderedItems } from '../../../helpers'
import { FormField as FormFieldType } from '../../../types'
import { FormField } from '../fields/form-field'
import { MerchantDataCollection } from './merchant-data/merchant-data-collection'
import { MerchantDataItem } from './merchant-data/merchant-data-item'
import { MerchantDataItems } from './merchant-data/merchant-data-items'
import React, { useState } from 'react'
import { Grid } from '@/components/layout/grid'
import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { Text } from '@/components/general/text'
import { withHttp } from '@/utils/formatting'
import { Url } from '@/services/common'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { Flex } from '@/components/layout/flex'
import { useTheme } from 'styled-components'
import { useSectionsContext } from './sections'

interface SectionProps {
    children: React.ReactNode
}

const Section = (props: SectionProps) => {
    const { children } = props

    return <Grid horizontalTemplate="2fr 1fr">{children}</Grid>
}

interface ContentProps {
    children: React.ReactNode
}

const Content: React.FC<ContentProps> = (props) => {
    const { children } = props

    return (
        <div>
            <Card background="front.background" shadowLess>
                <CardInset>{children}</CardInset>
            </Card>
        </div>
    )
}

interface MetadataProps {
    children: React.ReactNode
}

const Metadata: React.FC<MetadataProps> = (props) => {
    const { children } = props

    const theme = useTheme()

    const background = theme.name === 'whiteTheme' ? 'back.background.strongerIII' : 'back.background.strongerI'

    return (
        <div>
            <Card background={background} shadowLess>
                <CardInset type="small">{children}</CardInset>
            </Card>
        </div>
    )
}

interface FieldProps {
    name: string
    data: Omit<FormFieldType, 'name'>
}

const Field: React.FC<FieldProps> = (props) => {
    const { name, data } = props

    const { disabled } = useSectionsContext()

    return (
        <FormField name={name} type={data.type} label={data.label} placeholder={data.placeholder} disabled={disabled} />
    )
}

interface ButtonProps {
    children: string
    onClick: () => void
}

const Button: React.FC<ButtonProps> = (props) => {
    const { children, onClick } = props

    return (
        <Flex>
            <SimpleButton onClick={onClick}>{children}</SimpleButton>
        </Flex>
    )
}

interface DataItemProps {
    title: string
    value: string | React.ReactNode
}

const DataItem: React.FC<DataItemProps> = (props) => {
    return <MerchantDataItem {...props} />
}

interface TitleProps {
    children: string
    color?: 'subtle' | 'normal'
}

const Title: React.FC<TitleProps> = (props) => {
    const { children, color } = props

    const colorString = color === 'normal' ? 'back.text.strongerII' : 'front.text.subtlerI'

    return <Text color={colorString}>{children}</Text>
}

interface DataItemCardProps {
    children: string
    fontStyle?: 'regular' | 'mono'
}

const DataItemCard: React.FC<DataItemCardProps> = (props) => {
    const { children, fontStyle } = props

    const isMono = fontStyle === 'mono'

    return (
        <Card background="back.background.strongerIII" shadowLess>
            <CardInset type="small">
                <Text showSpaces mono={isMono}>
                    {children}
                </Text>
            </CardInset>
        </Card>
    )
}

interface IndentetContentProps {
    children: React.ReactNode
}

const IndentetContent: React.FC<IndentetContentProps> = (props) => {
    const { children } = props

    return (
        <Flex marginLeft={25} column>
            {children}
        </Flex>
    )
}

interface StackedDataItemProps {
    title: string
    value: string
    fontStyle?: 'regular' | 'mono'
}

const StackedDataItem: React.FC<StackedDataItemProps> = (props) => {
    const { title, value, fontStyle = 'regular' } = props

    return (
        <Flex justify="space-between" column>
            <Title>{title}</Title>
            <Section.Spacer size="m" />
            <DataItemCard fontStyle={fontStyle}>{value}</DataItemCard>
        </Flex>
    )
}

interface ExpandableDataItemProps {
    title: string
    value: string
    fontStyle?: 'regular' | 'mono'
}

const ExpandableDataItem: React.FC<ExpandableDataItemProps> = (props) => {
    const { title, value, fontStyle } = props
    const [isOpen, setIsOpen] = useState(false)
    const buttonLabel = !isOpen ? 'Expand' : 'Collapse'

    const handleOnClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Flex marginLeft={25} justify="space-between">
                <Title>{title}</Title>
                <Button onClick={handleOnClick}>{buttonLabel}</Button>
            </Flex>
            {isOpen && (
                <IndentetContent>
                    <Spacer size="m" />
                    <DataItemCard fontStyle={fontStyle}>{value}</DataItemCard>
                </IndentetContent>
            )}
        </>
    )
}

interface DataItemsProps {
    data: Record<string, string | boolean | object>
    order: OrderedOrder
}

const DataItems: React.FC<DataItemsProps> = (props) => {
    const { data, order } = props

    const items = getOrderedItems(data, order)

    return <MerchantDataItems data={items} />
}

interface DataCollectionProps {
    name: string
    data: OrderedItems[]
    order: OrderedOrder
}

const DataCollection: React.FC<DataCollectionProps> = (props) => {
    const { name, data } = props

    if (!data || data.length === 0) {
        return (
            <IndentetContent>
                <Title>{`No ${name} found`}</Title>
            </IndentetContent>
        )
    }

    return <MerchantDataCollection {...props} />
}

interface FileProps {
    children: string
    onClick: () => void
    state?: 'disabled' | 'enabled'
}

const File: React.FC<FileProps> = (props) => {
    const { children, state = 'enabled', onClick } = props

    if (state === 'disabled') {
        return (
            <Text color="front.text.subtlerI" strike>
                {children}
            </Text>
        )
    }

    return (
        <Flex>
            <SimpleButton onClick={onClick}>{children}</SimpleButton>
        </Flex>
    )
}

interface LinkProps {
    children: string
    url: string
}

const Link: React.FC<LinkProps> = (props) => {
    const { url, children } = props
    const { openWindow } = useSectionsContext()

    const handleOnClick = () => {
        openWindow(url)
    }

    return (
        <Flex>
            <SimpleButton onClick={handleOnClick}>{children}</SimpleButton>
        </Flex>
    )
}

interface ExternalLinkProps {
    children: string
    url?: Url
}

const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
    const { url, children } = props
    const { openWindow } = useSectionsContext()

    if (!url) {
        return (
            <Text color="front.text.subtlerI" strike>
                {children}
            </Text>
        )
    }

    const urlWithHttp = withHttp(url)

    const handleOnClick = () => {
        openWindow(urlWithHttp)
    }

    return <ExternalLinkComp onClick={handleOnClick}>{children}</ExternalLinkComp>
}

interface SpacerProps {
    size?: 's' | 'm' | 'l'
}

const Spacer: React.FC<SpacerProps> = (props) => {
    const { size = 'l' } = props

    let height
    switch (size) {
        case 's':
            height = 10
            break
        case 'm':
            height = 12
            break
        case 'l':
            height = 24
            break
        default:
            height = 24
    }

    return <SpacerComp height={height} />
}

const hiddenStyle = { display: 'none' }

interface VisibleProps {
    children
    isVisible?: boolean
}

const Visible: React.FC<VisibleProps> = (props) => {
    const { children, isVisible } = props

    const style = !isVisible ? hiddenStyle : undefined

    return <div style={style}>{children}</div>
}

Section.Content = Content
Section.IndentetContent = IndentetContent
Section.Metadata = Metadata
Section.Field = Field
Section.Spacer = Spacer
Section.File = File
Section.Title = Title
Section.Button = Button
Section.DataItem = DataItem
Section.DataItemCard = DataItemCard
Section.StackedDataItem = StackedDataItem
Section.ExpandableDataItem = ExpandableDataItem
Section.DataItems = DataItems
Section.DataCollection = DataCollection
Section.Link = Link
Section.ExternalLink = ExternalLink
Section.DataItemCard = DataItemCard
Section.Visible = Visible

export { Section }
