import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'
import styled from 'styled-components'
import Linkify from 'react-linkify'
import { useMemo } from 'react'
import rehypeSanitize from 'rehype-sanitize'

export const MarkdownHolder: React.FC<React.ComponentProps<typeof ReactMarkdown>> = ({ ...p }) => {
    const components = useMemo(() => {
        const parseLinks = ({ node, children, ...props }: any) => {
            const Tag = `${node.tagName}`
            return (
                <Tag {...props}>
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a rel="noreferrer noopener" target="_blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        {children}
                    </Linkify>
                </Tag>
            )
        }
        const adjustLinks = ({ children, href, key }: { children: any; href: any; key: any }) => {
            return (
                <a rel="noreferrer noopener" target="_blank" href={href} key={key}>
                    {children}
                </a>
            )
        }
        return {
            p: parseLinks,
            li: parseLinks,
            b: parseLinks,
            i: parseLinks,
            h1: parseLinks,
            h2: parseLinks,
            h3: parseLinks,
            h4: parseLinks,
            h5: parseLinks,
            h6: parseLinks,
            a: adjustLinks
        } as any
    }, [])

    return (
        <Holder data-cy="markdown-holder">
            <ReactMarkdown {...p} remarkPlugins={[breaks]} rehypePlugins={[rehypeSanitize]} components={components} />
        </Holder>
    )
}

const Holder = styled.div`
    border-radius: 3px;
    * {
        line-height: 20px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;

        @media print {
            font-size: 10px;
        }
    }
    h1 {
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 13px;
        letter-spacing: 0.2px;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        @media print {
            font-size: 11px;
            font-weight: 600;
        }
    }
    h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        letter-spacing: 0.3px;
        margin-top: 24px;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        @media print {
            font-size: 11px;
            font-weight: 600;
        }
    }
    h3 {
        font-size: 14px;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 5px;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        @media print {
            font-size: 11px;
            font-weight: 600;
        }
    }
    blockquote {
        padding-left: 15px;
        border-left: 3px solid ${(p) => p.theme['front.background.strongerIII']};
        margin: -10px 0 15px 0;
        color: ${(p) => p.theme['front.text.subtlerI']};
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0px;
        }
    }
    code {
        font-family: 'Roboto Mono', monospace;
        background-color: ${(p) => p.theme['back.background']};
        padding: 5px;
        font-size: 11px;
        border-radius: 4px;
    }
    pre {
        background-color: ${(p) => p.theme['back.background']};
        padding: 12px;
        border-radius: 5px;
        overflow: scroll;
        line-height: 18px;

        @media print {
            font-size: 8px;
            line-height: 12px;
            font-weight: 500;
            background-color: #fff;
        }
    }
    ul,
    ol {
        padding-left: 14px;
        margin: 20px 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0px;
        }
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    li {
        margin: 5px 0;
    }
    hr {
        opacity: 0.2;
    }
    pre code {
        padding: 0;
        background-color: transparent;
    }
    p {
        margin-top: 0;
        margin-bottom: 15px;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        @media print {
            font-size: 10px;
            line-height: 12px;
        }
    }
    strong {
        font-weight: 500;
    }
    a {
        color: ${(p) => p.theme['front.accent.color']};
        text-decoration: none;
        font-size: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
`
