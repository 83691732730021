import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationResourceActions } from '../../store/applicationResources/actions'
import { FileActionDispatchFetchFile } from '../../store/files/actions'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../../store/toasts/actions'
import { useQueryParams } from './useQueryParam'
import { useNamedWatcherWithContext } from './useWatcher'
import { sanitizeUrl } from '@braintree/sanitize-url'

const queryParams = ['preview']
export const useSecretaryFileRefresher = (id: string) => {
    const dispatch = useDispatch()
    const [query, setQuery] = useQueryParams(queryParams, undefined)
    const websites = useSelector(
        (state: RootState) => state.applicationResources.data?.forApplication?.[id]?.websites?.fields
    )
    const [watcherState, watcherId, watcherContext] = useNamedWatcherWithContext('SECRETARY_FILE')

    useEffect(() => {
        if (watcherState === 'success') {
            const { file, extraContext } = watcherContext
            if (file && extraContext?.websiteIndex !== undefined) {
                dispatch(ApplicationResourceActions.LINK_FILES(id, 'websites', extraContext.websiteIndex, [file]))
                // Disable opening a preivew for a new website screenshot automatically
                // setQuery((q: any) => ({
                //     preview: [...(typeof q.preview === 'object' ? q.preview : [q.preview]), file.id]
                // }))
            } else {
                dispatch(
                    ToastsDispatchPush(
                        'Failed to fetch the secretary file. Please reload the page and ping @console on Slack.',
                        'error'
                    )
                )
            }
        }
    }, [watcherState, watcherContext, id, dispatch, setQuery])

    useEffect(() => {
        const listenForMessage = (event: any) => {
            if (event.source != window) return
            if (event.data.action && event.data.action == 'REFRESH_FILE') {
                const filesLink = sanitizeUrl(event.data.filesLink)
                const fileId = sanitizeUrl(event.data.fileId)
                const websiteIndex = websites.findIndex((w) => w.filesLink === filesLink)
                if (websiteIndex !== -1) {
                    dispatch(FileActionDispatchFetchFile(fileId, watcherId, { websiteIndex }))
                }
            }
        }

        window.addEventListener('message', listenForMessage, false)
        return () => {
            window.removeEventListener('message', listenForMessage)
        }
    }, [dispatch, websites, watcherId])
}
