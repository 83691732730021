import styled from 'styled-components'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { AvailableSettlementCurrencies } from '../../store/settlements/types'
import { SearchHelpItem } from './SearchHelpItem'

const TEXT = 'text'
const COUNTRY = 'ISO 3166-1'
const resources = {
    'Application': {
        'id': TEXT,
        'signer.name': TEXT,
        'signer.email': TEXT,
        'business_model.trading_name': TEXT,
        'business_model.description': TEXT,
        'metadata.state': [
            'archived',
            'unsubmitted',
            'submitted',
            'more_information',
            'declined',
            'under_review',
            'accepted'
        ]
    },
    'Bank Accounts': {
        'bank_accounts.id': TEXT,
        'bank_accounts.currency': AvailableSettlementCurrencies,
        'bank_accounts.bank': TEXT,
        'bank_accounts.swift_code': TEXT,
        'bank_accounts.iban': TEXT
    },
    'Company': {
        'company.id': TEXT,
        'company.name': TEXT,
        'company.registration_number': TEXT,
        'company.email': TEXT,
        'company.phone': TEXT,
        'company.address_line_1': TEXT,
        'company.address_line_2': TEXT,
        'company.zipcode': TEXT,
        'company.city': TEXT,
        'company.country': COUNTRY
    },
    'Company - People': {
        'company.people.id': TEXT,
        'company.people.name': TEXT,
        'company.people.address_line_1': TEXT,
        'company.people.address_line_2': TEXT,
        'company.people.zipcode': TEXT,
        'company.people.city': TEXT,
        'company.people.country': COUNTRY
    },
    'Company - Contacts': {
        'contact.id': TEXT,
        'contact.name': TEXT,
        'contact.email': TEXT,
        'contact.phone': TEXT
    },
    'Websites': {
        'websites.id': TEXT,
        'websites.url': TEXT
    },
    'Gateways': {
        'gateways.id': TEXT,
        'gateways.name': TEXT
    },
    'Tags': {
        'tags.id': TEXT,
        'tags.name': TEXT
    },
    'Contract': {
        'contract.id': TEXT,
        'contract.metadata.state': ['needs_signature', 'signed', 'declined', 'cancelled']
    },
    'Merchant Account': {
        'account.id': TEXT,
        'account.merchant_id': TEXT,
        'account.mcc': TEXT,
        'account.metadata.state': ['in_progress', 'live', 'closed']
    }
}
export const SearchHelp: React.FC = () => {
    return (
        <Holder className="PREVENT_MODAL_CLOSE">
            <Flex align="stretch">
                <Info>
                    <Header>Search modifiers</Header>
                    <Text>Below you can find the available modifiers for the searchable resources:</Text>
                    <Spacer height={15} />
                    <Flex column align="flex-start">
                        {Object.keys(resources).map((key) => (
                            <SearchHelpItem key={key} label={key} modifiers={(resources as any)[key]} />
                        ))}
                    </Flex>
                </Info>
                <Spacer width={30} />
                <Examples>
                    <Header>Examples</Header>
                    <Key>
                        Merchants related to a <Text bold>Person</Text> with a known <Text bold>email</Text>
                    </Key>
                    <Val>person.email:john.doe</Val>
                    <Key>
                        <Text bold>Live merchants</Text> based in <Text bold>Switzerland</Text>
                    </Key>
                    <Val>company.country:CH account.metadata.state:live</Val>
                    <Key>
                        Merchants using a specific <Text bold>gateway</Text>
                    </Key>
                    <Val>gateways.id:226...337 </Val>
                    <Key>
                        <Text bold>Live merchants</Text> based in <Text bold>Switzerland</Text> using{' '}
                        <Text bold>GatewayName</Text>
                    </Key>
                    <Val>company.country:CH account.metadata.state:live gateways.name:Gate...way</Val>
                    <Key>
                        Merchants tagged with <Text bold>midgard</Text>
                    </Key>
                    <Val>tags.name:midgard</Val>
                </Examples>
            </Flex>
        </Holder>
    )
}

const Holder = styled.div`
    border-radius: 10px;
    flex-grow: 1;
    align-items: center;
    display: flex;
    color: ${(p) => p.theme['overlay.text']};
    padding-bottom: 100px;
    justify-content: center;
`

const Examples = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Key = styled.span`
    margin-bottom: 5px;
`
const Val = styled.span`
    padding: 5px 10px;
    background-color: ${(p) => p.theme['overlay.background.subtlerII']};

    border-radius: 5px;
    margin-bottom: 20px;
    font-family: monospace;
    letter-spacing: -0.3px;
    margin-left: -2px;
`

const Header = styled.div`
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const Info = styled.div`
    max-width: 250px;
`
