import { put } from 'typed-redux-saga'
import { WatcherDispatchFail, WatcherDispatchStart, WatcherDispatchSuccess } from '../../watcher/actions'
import { MAPI_APPLICATION_INCLUDES } from '../../applications/sagas'
import { ApplicationInternalsStampingActions } from './actions'
import { ActionType } from 'deox'
import { DELETE, GET, PUT } from '../../../generators/networking'
import { ApplicationInternalsAgentsActions } from '../agents/actions'
import { ApplicationsDispatchStoreApplication } from '../../applications/actions'

export const ApplicationInternalsStampingSagas = {
    *REFETCH({ payload: p }: ActionType<typeof ApplicationInternalsStampingActions.REFETCH>) {
        const response = yield* GET({
            url: `${import.meta.env.VITE_API_ROOT}/applications/${p.applicationId}`,
            errorText: "Failed to fetch application's stamps.",
            hintedHalJSON: true,
            include: ['stamps']
        })

        if (response.stampsLink)
            yield put(ApplicationInternalsStampingActions.STORE(p.applicationId, response.stampsLink))
        else yield put(ApplicationInternalsStampingActions.STORE(p.applicationId, []))
    },
    *STAMP({ payload: p }: ActionType<typeof ApplicationInternalsStampingActions.STAMP>) {
        yield put(WatcherDispatchStart([p.watcherId]))

        const requestConfig = {
            url: `${p.link}`,
            errorText: 'Failed to stamp the application',
            successText: 'Application updated successfully.',
            successCode: 200,
            hintedHalJSON: true,
            body: p.body,
            include: [...MAPI_APPLICATION_INCLUDES]
        }
        const { success, cleanedResponse } = yield p.method === 'PUT' ? PUT(requestConfig) : DELETE(requestConfig)

        if (p.link.includes('under_review')) {
            const agentsLink = cleanedResponse.agentsLink
            if (agentsLink) yield put(ApplicationInternalsAgentsActions.FETCH(p.applicationId, agentsLink))
            else yield put(ApplicationInternalsAgentsActions.STORE(p.applicationId, undefined))
        }

        if (success) {
            yield put(ApplicationsDispatchStoreApplication(cleanedResponse))
            yield put(WatcherDispatchSuccess([p.watcherId], 'Application stamped successfully'))
        } else {
            yield put(WatcherDispatchFail([p.watcherId]))
        }
    }
}
