import { SlimLoader } from '../../../components/loaders/loader'
import { useGateways } from '../../../hooks/general/useGateways'

export const MerchantApplicationFieldValueGateway: React.FC<{
    gatewayId?: string
}> = ({ gatewayId }) => {
    const gateways = useGateways()

    if (gatewayId === undefined) return <></>

    if (gateways?.loadingStatus === 'done') {
        if (gateways?.at?.[gatewayId] !== undefined) return <span>{gateways.at[gatewayId].name || ''}</span>
        return <span />
    }

    return <SlimLoader background="front.background" color="front.accent.color" />
}
