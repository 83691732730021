import { AuthState, InitialAuthState } from './types'
import { AuthReducedActions, AUTH_STORE_USER, AUTH_SET_IN_PROGRESS } from './actions'

export function AuthReducer(state = InitialAuthState, action: AuthReducedActions): AuthState {
    switch (action.type) {
        case AUTH_STORE_USER: {
            return {
                ...state,
                token: action.token,
                user: action.user,
                inProgress: false
            }
        }
        case AUTH_SET_IN_PROGRESS: {
            return {
                ...state,
                inProgress: action.inProgress
            }
        }
    }
    return state
}
