import moment from 'moment'
import { put, take } from 'redux-saga/effects'

import { DELETE, GET, PATCH, POST, PUT } from '../../generators/networking'
import { getRootLinks } from '../../generators/selectors'
import { IS_DEVELOPMENT_OR_CYPRESS } from '../../utils'
import { TQLConverter } from '../../utils/tqlConverter'
import { FilesActionDispatchStore } from '../files/actions'
import { GatewaysDispatchLoad } from '../gateways/actions'
import { USER_ACCOUNTS_STORE_ACCOUNT, UserAccountsDispatchGetAccount } from '../userAccounts/actions'
import {
    DisputeActionChangeDueDate,
    DisputeActionLoad,
    DisputeActionLoadExtraDetails,
    DisputeActionLoadNextResults,
    DisputeActionRemoveComment,
    DisputeActionSendComment,
    DisputeActionStamp,
    DisputeDispatchStore,
    DisputeDispatchStoreComment,
    DisputeDispatchStoreExtraDetails,
    DisputeDispatchUnstoreComment,
    DisputesActionLoadSummaries,
    DisputesDispatchStoreSummaries,
    SagasForDisputes
} from './actions'
import { Dispute } from './types'
import mocks from './mocks.json'

const COMMENT_INCLUDES = ['self', 'next']
const FILE_INCLUDES = ['self', 'data', 'next']
const DISPUTE_INCLUDES = ['events', 'transaction', 'account', 'self', 'comments', 'stamps', 'files']

export class DisputesSaga implements SagasForDisputes {
    *stamp(action: DisputeActionStamp) {
        yield PUT({
            watcher: `${action.disputeId}_STAMP`,
            url: action.stampsLink,
            successCode: 200,
            successText: 'Dispute stamped successfully.',
            include: DISPUTE_INCLUDES,
            onSuccessDispatch: (d) => DisputeDispatchStore(d),
            errorText: 'Failed to stamp dispute'
        })
    }

    *loadSummaries(action: DisputesActionLoadSummaries) {
        yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/disputes${TQLConverter.disputes(action.filters)}`,
            include: ['disputes', 'account'],
            onSuccessDispatch: (r) =>
                DisputesDispatchStoreSummaries(r.disputes, {
                    page: r.page || action.filters.disputes_page,
                    perPage: r.per_page || action.filters.disputes_per_page,
                    total: r.count
                }),
            errorText: 'Failed to load disputes summaries.'
        })
    }

    *load(action: DisputeActionLoad) {
        const dispute: Dispute = yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/disputes/${action.disputeId}`,
            include: DISPUTE_INCLUDES,
            errorText: 'Failed to load dispute.'
        })

        if (IS_DEVELOPMENT_OR_CYPRESS()) {
            dispute.events = [...dispute.events, ...mocks]
        }

        const { comments, nextLink: commentsNextLink } = dispute.commentsLink
            ? yield GET({
                  url: `${dispute.commentsLink}`,
                  include: COMMENT_INCLUDES,
                  errorText: "Failed to load disputes' comments."
              })
            : { comments: [], nextLink: "" }
        dispute.comments = comments
        dispute.commentsNextLink = commentsNextLink || ""   

        const { files, nextLink: filesNextLink } = dispute.filesLink
            ? yield GET({
                  url: `${dispute.filesLink}`,
                  include: FILE_INCLUDES,
                  errorText: "Failed to load disputes' files."
              })
            : { files: [], nextLink: "" }
        dispute.files = files.map((f: any) => ({
            ...f,
            id: f.selfLink.substr(f.selfLink.lastIndexOf('/') + 1),
            uploadLink: f.dataLink[0].href,
            downloadLink: f.dataLink[1].href
        }))
        dispute.filesNextLink = filesNextLink || ""

        yield put(FilesActionDispatchStore(dispute.files))
        yield put(DisputeDispatchStore(dispute))
    }

    *loadNextResults(action: DisputeActionLoadNextResults) {
        const {comments, nextLink: commentsNextLink} = action.commentsNextLink ?
            yield GET({
                url: action.commentsNextLink,
                include: COMMENT_INCLUDES,
                errorText: 'Failed to load the next comments'
            }) : {comments: [], nextLink: ""}
        action.dispute.comments = [...action.dispute?.comments, ...comments]
        action.dispute.comments = [...new Map(action.dispute.comments.map(item => [item['id'], item])).values()]
        action.dispute.commentsNextLink = commentsNextLink || ""

        const {files, nextLink: filesNextLink} = action.filesNextLink ?
            yield GET({
                url: action.filesNextLink,
                include: FILE_INCLUDES,
                errorText: 'Failed to load the next files'
            }) : {files: [], nextLink: ""}

        action.dispute.files = [...action.dispute?.files, ...(files.map((f: any) => ({
            ...f,
            id: f.selfLink.substr(f.selfLink.lastIndexOf('/') + 1),
            uploadLink: f.dataLink[0].href,
            downloadLink: f.dataLink[1].href
        })))]
        action.dispute.files = [...new Map(action.dispute.files.map(item => [item['id'], item])).values()]
        action.dispute.filesNextLink = filesNextLink || ""

        action.dispute.loadingNextStatus = "done"
    
        yield put(FilesActionDispatchStore(action.dispute.files))
        yield put(DisputeDispatchStore(action.dispute))
    }

    *loadExtraDetails(action: DisputeActionLoadExtraDetails) {
        yield put(GatewaysDispatchLoad())

        yield put(
            UserAccountsDispatchGetAccount(action.dispute.accountId, {
                isMerchant: true
            })
        )

        let rawMerchant: { id: string; data: any }
        do {
            rawMerchant = yield take(USER_ACCOUNTS_STORE_ACCOUNT)
        } while (rawMerchant.id !== action.dispute.accountId)

        const newDispute = { ...action.dispute }
        newDispute.extraDetails.account = rawMerchant.data
        newDispute.extraDetails.application = undefined

        if (newDispute?.extraDetails?.account?.applicationLink) {
            const rootLinks = yield* getRootLinks()

            const applicationId = newDispute.extraDetails.account.applicationLink.split('/').pop()
            const applicationLink = `${rootLinks?.cutter?.applicationLink}/${applicationId}`

            newDispute.extraDetails.application = (yield GET({
                url: `${applicationLink}?expand=websites,contact,tags`,
                errorText: "Failed to load disputes' merchant' application."
            })) as {
                [key: string]: any
            }
        }

        if (newDispute?.extraDetails?.account?.mcc) {
            const mccDescription = (
                (yield GET({
                    url: `${import.meta.env.VITE_MCC_ENDPOINT}/?limit=1&q=${newDispute.extraDetails.account.mcc}`,
                    errorText: "Failed to load task's merchant MCC description."
                })) as any[]
            )?.[0]

            newDispute.extraDetails.account.mccDescription = mccDescription ? mccDescription.label : undefined
        }

        yield put(DisputeDispatchStoreExtraDetails(newDispute))
    }

    *sendComment(action: DisputeActionSendComment) {
        yield POST({
            watcher: `${action.watcherId}`,
            url: action.dispute.commentsLink,
            body: {
                body: action.body
            },
            include: COMMENT_INCLUDES,
            errorText: 'Failed to send comment.',
            successText: 'Comment sent successfully.',
            onSuccessDispatch: (c) => DisputeDispatchStoreComment(action.dispute, c)
        })
    }

    *removeComment(action: DisputeActionRemoveComment) {
        yield DELETE({
            watcher: action.watcherId,
            url: action.comment.selfLink,
            errorText: 'Failed to remove comment.',
            successText: 'Comment removed successfully.',
            onSuccessDispatch: () => DisputeDispatchUnstoreComment(action.dispute, action.comment.id)
        })
    }

    *changeDueDate(action: DisputeActionChangeDueDate) {
        if (moment(action.newDueDate).isSame(moment(action.dispute.dueAt), 'date')) return

        yield PATCH({
            watcher: `${action.watcherId}`,
            url: action.dispute.selfLink,
            body: {
                due_at: `${moment(action.newDueDate).format('YYYY-MM-DDT00:00:00.000')}Z`
            },
            include: DISPUTE_INCLUDES,
            errorText: "Failed to update the disputes' due date.",
            successText: "Disputes' due date updated.",
            onSuccessDispatch: (d) => DisputeDispatchStore(d)
        })
    }
}
