import { startCase } from 'lodash'
import moment from 'moment'
import { all, put, select, take } from 'typed-redux-saga'

import { DELETE, GET, PATCH, POST } from '../../generators/networking'
import { getRootLinks } from '../../generators/selectors'
import { IS_DEVELOPMENT_OR_CYPRESS, uppercaseFirstLetter } from '../../utils'
import { TQLConverter } from '../../utils/tqlConverter'
import { FilesActionDispatchStore } from '../files/actions'
import { GatewaysDispatchLoad } from '../gateways/actions'
import { RootState } from '@/store'
import { USER_ACCOUNTS_STORE_ACCOUNT, UserAccountsDispatchGetAccount } from '../userAccounts/actions'
import {
    SagasForTasks,
    TaskActionAssignAgent,
    TaskActionAssignTag,
    TaskActionClose,
    TaskActionCreateAndAssignTag,
    TaskActionEditComment,
    TaskActionOpen,
    TaskActionRemoveComment,
    TaskActionRemoveTag,
    TaskActionSendComment,
    TaskDispatchStoreComment,
    TaskDispatchUnstoreComment,
    TasksActionLoadSummaries,
    TasksActionLoadTask,
    TasksActionLoadTimeline,
    TasksDispatchLoadTags,
    TasksDispatchLoadTimeline,
    TasksDispatchStore,
    TasksDispatchStoreTags,
    TasksDispatchStoreTask,
    TasksDispatchStoreTimeline
} from './actions'
import mocks from './mocks.json'

const spreadAs = (type: string, data: any[]) => {
    const toSpread: any[] = []
    data.forEach((item) => {
        toSpread.push({
            type,
            at: moment(item.createdAt).unix(),
            data: item
        })
    })
    return toSpread
}

// const createExtraPagesCalls = (
//     { count, perPage, page }: { count: number; perPage: number; page: number },
//     link: string,
//     headers: any
// ) => {
//     const calls = []

//     for (let i = page + 1; i <= Math.ceil(count / perPage); i++) {
//         calls.push(call(fetch, link + "&page=" + i, headers))
//     }
//     return calls
// }

const colorForChart = (text: string) => {
    if (text.includes('captures')) return 'red'
    if (text.includes('credits')) return '#61EE67'
    if (text.includes('debits')) return '#61BFEE'
    if (text.includes('refunds')) return '#E47552'
    return '#CCC'
}

const mergeCertainEventCharts = (allEvents: any[]) => {
    const mergedVolumeCharts: any = {}

    const events = allEvents.filter((e) => {
        if (!e.data) return false

        // return any events unrelated to the volume metrics
        if (e.data.valueType !== 'monthly-transaction-volume') return true

        // create an index for events in the same day
        const dayKey = moment(e.createdAt).format('YYYY-DDD')

        // assign first event in the day, and change it's name to Monthly volume
        // create the data.value.array where the other metrics will be added
        if (!mergedVolumeCharts[dayKey]) {
            mergedVolumeCharts[dayKey] = JSON.parse(JSON.stringify(e))
            if (!mergedVolumeCharts[dayKey]) mergedVolumeCharts[dayKey] = {}
            mergedVolumeCharts[dayKey].name = 'Monthly volume'
            if (!mergedVolumeCharts[dayKey].data) mergedVolumeCharts[dayKey].data = {}
            mergedVolumeCharts[dayKey].data.value = []
        }

        // add the first metric with the right color, label, etc
        mergedVolumeCharts[dayKey].data.value.push({
            label: uppercaseFirstLetter(startCase(e.name)),
            color: colorForChart(e.name),
            data: e.data.value
        })

        return false
    })

    // add the unrelated events + the events that we've just created, for the Monthly volume
    return [
        ...events,
        ...Object.keys(mergedVolumeCharts).map((dayKey) => {
            // sort the metrics for the chart based on name
            mergedVolumeCharts[dayKey].data.value.sort((a: any, b: any) => {
                const nameA = a.label.toUpperCase()
                const nameB = b.label.toUpperCase()

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }

                return 0
            })
            return mergedVolumeCharts[dayKey]
        })
    ]
}

export class TasksSaga implements SagasForTasks {
    *loadSummaries(action: TasksActionLoadSummaries) {
        if (action.taskType) action.filters.tasks_type = [action.taskType]
        const calculatedFilters = { ...action.filters }

        if (!calculatedFilters.tasks_type) {
            calculatedFilters.tasks_type = ['alarm', 'instant-signup', 'company-change']
        }
        if (!action.filters.tasks_page) {
            calculatedFilters.tasks_page = 1
        }
        if (!action.filters.tasks_per_page) {
            calculatedFilters.tasks_per_page = 20
        }

        yield GET({
            url: `${import.meta.env.VITE_TASKR_ROOT}/tasks${TQLConverter.tasks(calculatedFilters)}`,
            include: ['files', 'events', 'comments', 'add_tags', 'remove_tags'],
            onSuccessDispatch: (r) =>
                TasksDispatchStore(r.tasks, {
                    page: r.page,
                    perPage: r.perPage,
                    total: r.total
                }),
            errorText: 'Failed to load task summaries.'
        })
    }

    *loadTags() {
        yield GET({
            url: `${import.meta.env.VITE_TASKR_ROOT}/tags`,
            onSuccessDispatch: (r) => {
                return TasksDispatchStoreTags(r.tags)
            },
            errorText: 'Failed to load tags.'
        })
    }

    *loadTask(action: TasksActionLoadTask) {
        const includes = ['files', 'comments', 'self', 'add_tags', 'remove_tags']
        const task: { [key: string]: any } = yield GET({
            url: `${import.meta.env.VITE_TASKR_ROOT}/tasks/${action.taskId}?expand=events`,
            include: includes,
            errorText: 'Failed to load task.'
        })

        if (task?.subjectType === 'account') {
            yield put(
                UserAccountsDispatchGetAccount(task.subjectId, {
                    isMerchant: true
                })
            )

            // Wait til the account has been fetched
            let nextStoreAccountAction: any
            do {
                nextStoreAccountAction = yield* take(USER_ACCOUNTS_STORE_ACCOUNT)
            } while (nextStoreAccountAction.id !== task.subjectId)

            task.account = yield* select((state: RootState) => {
                return state.userAccounts.at[task.subjectId]
            })

            // Link to application
            if (task.account) {
                if (task.account.data?.applicationLink) {
                    const applicationId = task.account.data.applicationLink.split('/').pop()

                    const rootLinks = yield* getRootLinks()
                    const applicationLink = `${rootLinks?.cutter.applicationLink}/${applicationId}`

                    task.application = (yield GET({
                        url: `${applicationLink}?expand=websites,contact,tags`,
                        errorText: "Failed to load task's application."
                    })) as { [key: string]: any }
                }

                if (task.account.data?.mcc) {
                    const mccDescription = (
                        (yield GET({
                            url: `${import.meta.env.VITE_MCC_ENDPOINT}/?limit=1&q=${task.account.data.mcc}`,
                            errorText: "Failed to load task's merchant MCC description."
                        })) as any[]
                    )?.[0]

                    task.account.data.mccDescription = mccDescription ? mccDescription.label : undefined
                }
            }
        }

        yield put(GatewaysDispatchLoad())

        yield put(
            TasksDispatchLoadTimeline(action.taskId, {
                comments: task?.commentsLink,
                files: task?.filesLink,
                events: task?.events
            })
        )
        yield put(TasksDispatchStoreTask(task as any))
    }

    *loadTaskTimeline(action: TasksActionLoadTimeline) {
        const perPageLimitParam = '&per_page=50'
        const requests = [
            GET({
                url: `${action.resources.comments}${perPageLimitParam}`,
                errorText: "Failed to fetch task's comments.",
                include: ['self']
            }),
            GET({
                url: `${action.resources.files}${perPageLimitParam}`,
                errorText: "Failed to fetch task's files",
                include: ['self', 'download']
            })
        ]

        const responses = yield* all(requests)
        const commentsFirstPage = responses[0]
        const filesFirstPage = responses[1]

        let allComments = [...(commentsFirstPage?.comments || [])]
        let allFiles = [...(filesFirstPage?.attachments || [])]
        let allEvents = [...(action?.resources?.events || [])]

        // TO-DO
        if (IS_DEVELOPMENT_OR_CYPRESS()) {
            allEvents = [...allEvents, ...mocks.alerts, ...mocks.contexts]
        }

        const generateRestOfPagesFetchCalls = (
            { count, perPage, page }: { count: number; perPage: number; page: number },
            link: string,
            error: string
        ) => {
            const calls: any[] = []

            for (let i = page + 1; i <= Math.ceil(count / perPage); i += 1) {
                calls.push(
                    GET({
                        url: `${link}&page=${i}${perPageLimitParam}`,
                        errorText: `${error} page ${i}`,
                        include: ['self']
                    })
                )
            }

            return calls
        }

        const extraPageCalls = [
            ...generateRestOfPagesFetchCalls(
                commentsFirstPage,
                action.resources.comments,
                "Failed to fetch task's comments"
            ),
            ...generateRestOfPagesFetchCalls(filesFirstPage, action.resources.files, "Failed to fetch task's files")
        ]
        const extraPageCallsResponses = yield* all(extraPageCalls)
        extraPageCallsResponses.forEach((response: any) => {
            if (response.comments) {
                allComments = [...allComments, ...response.comments]
            }
            if (response.attachments) {
                allFiles = [...allFiles, ...response.attachments]
            }
        })

        allFiles = allFiles.map((f) => {
            return {
                ...f,
                fileName: f.documentFileName,
                fileSize: f.documentFileSize,
                contentType: f.documentContentType
            }
        })

        allEvents = mergeCertainEventCharts(allEvents)

        const timeline: any = [
            ...spreadAs('comment', allComments),
            ...spreadAs('file', allFiles),
            ...spreadAs('event', allEvents)
        ]
        timeline.sort((a: any, b: any) => (a.at < b.at ? -1 : 1))

        yield put(FilesActionDispatchStore(allFiles as any))
        yield put(TasksDispatchStoreTimeline(action.taskId, timeline))
    }

    *createTag(action: TaskActionCreateAndAssignTag) {
        const { success } = yield PATCH({
            watcher: action.watcher,
            url: action.taskAddTagLink,
            body: { tags: [action.tag] },
            include: ['files', 'comments', 'self', 'add_tags', 'remove_tags'],
            errorText: 'Failed to create and assign tag.',
            successText: 'Tag created and assigned successfully.',
            onSuccessDispatch: (r) => TasksDispatchStoreTask(r)
        })

        if (success) {
            yield put(TasksDispatchLoadTags())
        }
    }

    *removeTag(action: TaskActionRemoveTag) {
        yield PATCH({
            watcher: action.watcher,
            url: `${action.taskLink}/remove_tags`,
            body: { tags: [action.tag] },
            include: ['files', 'comments', 'self', 'add_tags', 'remove_tags'],
            errorText: 'Failed to remove Tag.',
            successText: 'Tag removed successfully.',
            onSuccessDispatch: (r) => TasksDispatchStoreTask(r)
        })
    }

    *assignAgent(action: TaskActionAssignAgent) {
        yield PATCH({
            watcher: action.watcher,
            url: action.taskLink,
            body: { assignee: action.agent },
            include: ['files', 'comments', 'self', 'add_tags', 'remove_tags'],
            errorText: 'Failed to assign agent.',
            successText: 'Agent assigned successfully.',
            onSuccessDispatch: (r) => TasksDispatchStoreTask(r)
        })
    }

    *assignTag(action: TaskActionAssignTag) {
        yield PATCH({
            watcher: action.watcher,
            url: `${action.taskLink}/add_tags`,
            body: { tags: [action.tag] },
            include: ['files', 'comments', 'self', 'add_tags', 'remove_tags'],
            errorText: 'Failed to assign Tag.',
            successText: 'Tag assigned successfully.',
            onSuccessDispatch: (r) => TasksDispatchStoreTask(r)
        })
    }

    *open(action: TaskActionOpen) {
        yield PATCH({
            watcher: action.watcher,
            url: action.taskLink,
            body: { action: 'open_task' },
            include: ['files', 'comments', 'self', 'add_tags', 'remove_tags'],
            errorText: 'Failed to mark task as OPEN.',
            successText: 'Alert marked as OPEN.',
            onSuccessDispatch: (r) => TasksDispatchStoreTask(r)
        })
    }

    *close(action: TaskActionClose) {
        yield PATCH({
            watcher: action.watcher,
            url: action.taskLink,
            body: { action: 'close_task' },
            include: ['files', 'comments', 'self', 'add_tags', 'remove_tags'],
            errorText: 'Failed to mark task as CLOSED.',
            successText: 'Alert marked as CLOSED.',
            onSuccessDispatch: (r) => TasksDispatchStoreTask(r)
        })
    }

    *sendComment(action: TaskActionSendComment) {
        const authorId: any = yield* select((state: RootState) => {
            return state.auth.user?.['https://clearhaus.com/app_metadata'].uuid
        })

        if (!authorId) return

        yield POST({
            watcher: action.watcher,
            url: action.commentsLink,
            body: {
                author_id: authorId,
                body: action.body
            },
            include: ['self'],
            errorText: 'Comment sending failed.',
            successText: 'Comment sent successfully.',
            onSuccessDispatch: (r: any) => {
                const commentItem = [...spreadAs('comment', [r])]
                return TaskDispatchStoreComment(action.taskId, commentItem)
            }
        })
    }

    *editComment(action: TaskActionEditComment) {
        const authorId = yield* select((state: RootState) => {
            return state.auth.user?.['https://clearhaus.com/app_metadata'].uuid
        })

        if (!authorId) return

        yield POST({
            watcher: action.watcher,
            url: action.commentLink,
            body: {
                author_id: authorId,
                body: action.newBody
            },
            include: ['self'],
            errorText: 'Failed to edit the comment.',
            successText: 'Comment edited successfully.',
            successCode: 200,
            onSuccessDispatch: (r: any) => {
                const commentItem = [...spreadAs('comment', [r])]
                return TaskDispatchStoreComment(action.taskId, commentItem, r.selfLink)
            }
        })
    }

    *removeComment(action: TaskActionRemoveComment) {
        yield DELETE({
            watcher: action.watcher,
            url: action.commentLink,
            errorText: 'Failed to delete the comment.',
            successText: 'Comment removed successfully.',
            successCode: 204,
            onSuccessDispatch: () => TaskDispatchUnstoreComment(action.taskId, action.commentLink)
        })
    }
}
