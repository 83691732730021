import styled from 'styled-components'

export const SpaceMaker: React.FC<{ children: React.ReactNode[] }> = ({ children }) => {
    return <Grid>{children}</Grid>
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 3px;
`
