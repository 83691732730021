import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { TasksDispatchLoadTags } from '../../store/tasks/actions'
import { TasksFilters } from '../../store/tasks/types'
import { TextInputSelectMultiple } from '../forms/textInputSelectMultiple'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { Flex } from '../layout/flex'
import { FilterPageHolder } from './listPageFilterHolder'
import { TextLineLoader } from '../loaders/textLineLoader'
import { Spacer } from '../layout/spacer'

export const ListPageFilterAlertTags = ({
    filters,
    filter,
    isDark
}: {
    filters: FiltersController<TasksFilters>
    filter?: (tag: string) => boolean
    isDark: boolean
}): ReactElement => {
    const tags = useSelector((state: RootState) => {
        return state.tasks.tags
    })
    const dispatch = useDispatch()

    useEffect(() => {
        if (!tags.loadingStatus || tags.loadingStatus === 'not-started') dispatch(TasksDispatchLoadTags())
    }, [dispatch, tags.loadingStatus])

    const dictionary = useMemo(() => {
        return tags.all.reduce((acc, tagId) => {
            acc[tagId] = tags.at[tagId].name
            return acc
        }, {} as any)
    }, [tags])

    const textForItem = useCallback(
        (key) => {
            return dictionary[key]
        },
        [dictionary]
    )

    const textForItemLabel = useCallback((key) => {
        return key
    }, [])

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                tasks_tag: items.map((id: string) => tags.at?.[id]?.name)
            }))
        },
        [filters, tags.at]
    )

    const runFilter = (tagIds: string[]) => {
        if (!filter) return []

        const newTagIds = [...tagIds]

        return newTagIds.filter((id) => {
            const tag = tags.at[id]
            return filter(tag.name)
        })
    }

    const selectedItems = useMemo(() => {
        if (typeof filters.get.tasks_tag === 'string')
            return [
                tags.all.filter((tagId) => {
                    if (tags.at[tagId].name === filters.get.tasks_tag) return true
                    return false
                })[0]
            ]

        return (
            filters.get.tasks_tag?.map((selectedTagName: any) => {
                return tags.all.filter((tagId) => {
                    if (tags.at[tagId].name === selectedTagName) return true
                    return false
                })[0]
            }) || []
        )
    }, [filters.get, tags])

    return tags.loadingStatus === 'started' ? (
        <FilterPageHolder isDark={isDark}>
            <Flex grow align="stretch" column justify="center">
                <Spacer height={5} />
                <TextLineLoader />
            </Flex>
        </FilterPageHolder>
    ) : (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelectMultiple
                preSelectedItems={selectedItems}
                isHigher={isDark}
                isSeamless
                cy="tags-filter"
                textForItem={textForItem}
                dropdownId="TasksList.TagFilter"
                onSelect={onSelect}
                placeholder="-"
                items={filter ? runFilter(tags.all) : tags.all}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                selectionLabel="tag/tags"
            />
            <SelectedListForListPageFilters<TasksFilters>
                filterKey="tasks_tag"
                filters={filters}
                isDark={isDark}
                noHolderEmphasize={isDark}
                textForItem={textForItemLabel}
            />
        </FilterPageHolder>
    )
}
