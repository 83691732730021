/* eslint-disable max-len */
import { useRef } from 'react'
import styled from 'styled-components'
import { Text } from '../../components/general/text'
import { Tooltip } from '../../components/general/tooltip'
import { Flex } from '../../components/layout/flex'

export const Transaction3DVersion: React.FC<{
    v: {
        version: string
        status: string
    }
}> = ({ v }) => {
    const ref = useRef<any>(null)
    if (!v) return <Text>1.0.2</Text>
    return (
        <Flex>
            <Text>{v.version}</Text>
            {v.status ? (
                <>
                    <Tooltip element={ref} tooltip tooltipTiming="instant">
                        {def[v.status]}
                    </Tooltip>
                    <StatusRectangle ref={ref}>{v.status}</StatusRectangle>
                </>
            ) : null}
        </Flex>
    )
}

const StatusRectangle = styled.div`
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    border: 1px solid ${(p) => p.theme['front.background.strongerIII']};
    cursor: pointer;
    border-radius: 3px;

    &:hover {
        background-color: ${(p) => p.theme['front.background.strongerIII']};
    }
`

const def: any = {
    Y: 'Authentication/ Account Verification Successful',
    N: 'Not Authenticated /Account Not Verified; Transaction denied',
    U: 'Authentication/ Account Verification Could Not Be Performed; Technical or other problem, as indicated in ARes or RReq',
    A: 'Attempts Processing Performed; Not Authenticated/Verified , but a proof of attempted authentication/verification is provided',
    C: 'Challenge Required; Additional authentication is required using the CReq/CRes',
    D: 'Challenge Required; Decoupled Authentication confirmed.',
    R: 'Authentication/ Account Verification Rejected; Issuer is rejecting authentication/verification and request that authorisation not be attempted.',
    I: 'Informational Only; 3DS Requestor challenge preference acknowledged.'
}
