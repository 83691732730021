import { Collection, CollectionWithPagination } from '../../utils'
import { SearchResult } from '../search/types'

export interface CallsState {
    callsSummaries: Collection<CallSummary>
    queuePhones: Collection<CallQueue>
    phoneName: PhoneName
}

export const InitialCallsState: CallsState = {
    callsSummaries: {
        at: {},
        all: [],
        loadingStatus: 'not-started'
    },
    queuePhones: {
        at: {},
        all: [],
        loadingStatus: 'not-started'
    },
    phoneName: {
        localNumber: "",
        name: "",
        loadingStatus: 'not-started'
    }
}

export interface CallsFilters {
    status: string
}

export const CallFiltersParams: Partial<CallsFilters> = {
    status: ''
}

export interface QueuesFilters {
    queue: string
}

export const QueueFiltersParams: Partial<QueuesFilters> = {
    queue: ''
}

export interface CallSummary {
    internalCallId: string
    providerCallId: string
    localNumber: string
    pickedTime: string
    phoneNumber: number
    endTime: string
    startTime: string
    resourceId?: string
}

export interface CallQueue {
    queuePhones: string[]
}

export interface PhoneName {
    localNumber: string
    name?: string
    loadingStatus: string
}

export interface Call extends CallSummary {
    providerCallId: string
    partitionKey: string
    resourceId: string
}

export interface CallResState {
    shown: boolean
    disabled: boolean
    mapiRes: CollectionWithPagination<
        SearchResult,
        {
            total: number
            page: number
            perPage: number
        }
    >
    mercuryRes: Collection<SearchResult>
}

export const InitialCallResState: CallResState = {
    shown: false,
    disabled: false,
    mapiRes: {
        at: {},
        all: [],
        pagination: undefined,
        loadingStatus: 'not-started'
    },
    mercuryRes: {
        at: {},
        all: [],
        loadingStatus: 'not-started'
    }
}
