import { createReducer } from 'deox'
import { produce } from 'immer'
import { VouchersActions } from './actions'
import { InitialVouchersState } from './types'

export const VouchersReducer = createReducer(InitialVouchersState, (handleAction) => [
    handleAction(VouchersActions.LOAD, (state) =>
        produce(state, () => ({
            at: {},
            all: [],
            loadingStatus: 'started' as const
        }))
    ),
    handleAction(VouchersActions.STORE, (state, { payload: p }) =>
        produce(state, () => ({
            at: p.vouchers.reduce((acc, g) => {
                return {
                    ...acc,
                    [g.id]: { ...g }
                }
            }, {}),
            all: p.vouchers.map((g) => g.id),
            loadingStatus: 'done' as const
        }))
    ),
    handleAction(VouchersActions.APPEND_NEW, (state, { payload: p }) =>
        produce(state, () => ({
            ...state,
            at: {
                ...state.at,
                [p.voucher.id]: p.voucher
            },
            all: [...state.all.filter((id) => id !== p.voucher.id), p.voucher.id]
        }))
    )
])
