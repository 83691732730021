import { createActionCreator } from 'deox'
// eslint-disable-next-line max-len
import { TMerchantApplicationResourceKey } from '../../../pages/Merchant/Application/Application.StructureTypes'
import { HarvesterCheckResult, HarvesterReport } from './types'

const Prefix = 'APPLICATION_INTERNALS_HARVESTER'

export const ApplicationInternalsHarvesterActions = {
    FETCH: createActionCreator(`${Prefix}/FETCH`, (resolve) => (applicationId: string) => resolve({ applicationId })),
    BOOTSTRAP: createActionCreator(
        `${Prefix}/BOOTSTRAP`,
        (resolve) => (applicationId: string, count: number) => resolve({ applicationId, count })
    ),
    SET_ALL_DONE: createActionCreator(
        `${Prefix}/SET_ALL_DONE`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    COMPUTE_ISSUES: createActionCreator(
        `${Prefix}/COMPUTE_ISSUES`,
        (resolve) => (applicationId: string, reports: HarvesterReport[]) => resolve({ applicationId, reports })
    ),
    STORE_ISSUES: createActionCreator(
        `${Prefix}/STORE_ISSUES`,
        (resolve) =>
            (
                applicationId: string,
                sectionKey: TMerchantApplicationResourceKey,
                subsectionIndex: number,
                issues: any[]
            ) =>
                resolve({ applicationId, issues, sectionKey, subsectionIndex })
    ),
    COMPUTE_ADDITIONAL_INFO: createActionCreator(
        `${Prefix}/COMPUTE_ADDITIONAL_INFO`,
        (resolve) => (applicationId: string, reports: HarvesterReport[]) => resolve({ applicationId, reports })
    ),
    STORE_ADDITIONAL_INFO: createActionCreator(
        `${Prefix}/STORE_ADDITIONAL_INFO`,
        (resolve) =>
            (
                applicationId: string,
                sectionKey: TMerchantApplicationResourceKey,
                subsectionIndex: number,
                info: any[]
            ) =>
                resolve({ applicationId, info, sectionKey, subsectionIndex })
    ),
    COMPUTE_CHECKS: createActionCreator(
        `${Prefix}/COMPUTE_CHECKS`,
        (resolve) => (applicationId: string, reports: HarvesterReport[]) => resolve({ applicationId, reports })
    ),
    STORE_CHECKS: createActionCreator(
        `${Prefix}/STORE_CHECKS`,
        (resolve) =>
            (
                applicationId: string,
                sectionKey: TMerchantApplicationResourceKey,
                subsectionIndex: number,
                checks: HarvesterCheckResult[]
            ) =>
                resolve({ applicationId, checks, sectionKey, subsectionIndex })
    )
}
