import { kebabCase } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { InterfaceState } from '../../../store/interface/types'
import { useMerchantApplicationFormChangesBuilder } from './useMerchantApplicationFormChangesBuilder'

type StepID = keyof InterfaceState['applications']['id']['steps']
export const MerchantApplicationReviewChangesModalInput: React.FC<{
    changesArr?: { key: string; from: string; to: string }[]
    prefix: string
    skipSanitisedChanges?: boolean
    id: string
    stepId: StepID
    initialMessage?: string
    onMessageChanged: (key: StepID, value: string) => void
    placeholder?: string
}> = ({ changesArr, id, stepId, skipSanitisedChanges, initialMessage, prefix, placeholder, onMessageChanged }) => {
    const [message, setMessage] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const { formatChangesForReviewInput } = useMerchantApplicationFormChangesBuilder(id)

    const internalMessageNotePrefill = useMemo(() => {
        if (skipSanitisedChanges && changesArr) {
            const changes = changesArr.filter((c) => !c.key.toLowerCase().includes('sanitised'))
            return formatChangesForReviewInput(prefix, changes)
        }
        return formatChangesForReviewInput(prefix, changesArr)
    }, [changesArr, prefix, formatChangesForReviewInput, skipSanitisedChanges])

    const handleFocus = useCallback(() => {
        setIsFocused(true)
    }, [])

    const handleBlur = useCallback(() => {
        setIsFocused(false)
    }, [])

    const messageHasBeenModified = useMemo(() => {
        return message !== internalMessageNotePrefill
    }, [message, internalMessageNotePrefill])

    useEffect(() => {
        setMessage(internalMessageNotePrefill)
    }, [internalMessageNotePrefill])

    const handleChange = useCallback(
        (e: any, value: string) => {
            setMessage(value)
            onMessageChanged(stepId, value)
        },
        [setMessage, stepId, onMessageChanged]
    )

    useEffect(() => {
        if (initialMessage) handleChange?.(undefined, initialMessage)
    }, [initialMessage, handleChange])

    const shouldBeGreyedOut = useMemo(() => {
        if (messageHasBeenModified) return false
        if (isFocused) return false
        return true
    }, [messageHasBeenModified, isFocused])

    return (
        <Container data-cy={`step-${stepId}`}>
            {shouldBeGreyedOut && (
                <CustomPlaceholder>
                    <label htmlFor={kebabCase(placeholder)}>{placeholder}</label>
                </CustomPlaceholder>
            )}
            <TextareaStyles shouldBeGreyedOut={shouldBeGreyedOut}>
                <TextareaInput
                    placeholder=""
                    overBackground="front.background"
                    id={kebabCase(placeholder)}
                    initialValue={initialMessage ? initialMessage : internalMessageNotePrefill}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
            </TextareaStyles>
        </Container>
    )
}

const CustomPlaceholder = styled.div`
    position: absolute;
    top: 14px;
    left: 14px;
    color: ${(p) => p.theme['front.text.subtlerI']};
    z-index: 1;
    cursor: text !important;
`

const Container = styled.div`
    position: relative;
`

const TextareaStyles = styled.div<{ shouldBeGreyedOut?: boolean }>`
    ${(p) =>
        p.shouldBeGreyedOut &&
        css`
            * {
                color: ${(p) => p.theme['front.text.subtlerI']};
            }
        `}
`
