import { Button, Flex, Text } from '@/design-system'
import React from 'react'

interface SigningKeysSectionProps {
    children: React.ReactNode
    onAddClick: () => void
}

export const SigningKeysSection: React.FC<SigningKeysSectionProps> = (props) => {
    const { children, onAddClick } = props

    return (
        <Flex direction="column" gap="4">
            <Flex justify="between" align="center">
                <Text color="neutral">Signing keys</Text>
                <Button testid="add-signing-key-button" variant="link" size="sm" onClick={onAddClick}>
                    Add signing key
                </Button>
            </Flex>
            {children}
        </Flex>
    )
}
