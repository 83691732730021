import { cva, VariantProps } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('w-full h-full flex flex-col grow', {
    variants: {
        loading: {
            yes: 'opacity-70 transition opacity duration-50 delay-200 pointer-events-none',
            no: ''
        }
    }
})
