import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Spinner } from '../../../components/loaders/spinner'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { ApplicationResourceActions } from '../../../store/applicationResources/actions'
import { DedicatedSectionProps } from './Application.DedicatedSection'
import { useDedicatedSubsection, useSubsection } from './Application.DedicatedSubsection'
import { MerchantApplicationInternalDetails } from './Application.InternalDetails'
import { MerchantApplicationSectionCheckmark } from './Application.SectionCheckmark'
import { MerchantApplicationSubsection } from './Application.Subsection'
import { MerchantApplicationSuperField } from './Application.SuperField'
import { GenerateIndexPathForSubsection } from './Application.Structure'
import { useHasApplicationSectionLoaded } from './useHasApplicationSectionLoaded'
import { ApplicationNoSubsections } from './Application.NoSubsections'
import { ApplicationDedicatedInternalSectionWrapper } from './Application.DedicatedInternalSectionWrapper'
import { ResourceHistory } from './Application.ResourceHistory'
import { RootState } from '@/store'

export const MerchantApplicationDedicatedSectionPeople: React.FC<DedicatedSectionProps> = ({
    errors,
    executeBatchChanges,
    applicationId,
    formRef
}) => {
    const peopleHistory = useSelector((state: RootState) => {
        return state.applicationResources.data.forApplication[applicationId]?.peopleHistory
    })
    const { structure, field } = useDedicatedSubsection(applicationId, 'People')
    const { fields } = useSubsection(applicationId, structure, formRef, executeBatchChanges, errors)
    const hasLoaded = useHasApplicationSectionLoaded(applicationId, 'people', 'data')

    const [open, setOpen] = useState({})
    
    const prefixMemo = useMemo(() => {
        return <Icon type="personIcon" />
    }, [])

    const dispatch = useDispatch()
    const handleAddPerson = useCallback(
        (e, watcherId) => {
            dispatch(ApplicationResourceActions.ADD_SUBSECTION(applicationId, 'people', structure.suffix, watcherId))
        },
        [dispatch, applicationId, structure]
    )

    if (!hasLoaded)
        return (
            <>
                <Flex justify="center">
                    <Spinner overBackground="back.background" />
                </Flex>
                <span />
            </>
        )

    const handleShowInternalCard = (i) =>{
        setOpen(open =>({
            ...open,
            [i]: !open[i]
        }))
    }  

    const RightSideCard = (i) => {
        return (
            <Flex column>
                <ApplicationDedicatedInternalSectionWrapper>
                    <MerchantApplicationInternalDetails
                        sectionStructure={{
                            ...structure,
                            internalDetails: "personInternalDetailsHistory"
                        }}
                        applicationId={applicationId}
                        subsection
                        subsectionIndex={i}
                    />
                </ApplicationDedicatedInternalSectionWrapper>
            </Flex>
        )
    }


    return (
        <>
            {fields.map((s: any, i: any) => {
                return (
                    <React.Fragment key={`people-${i}`}>
                        <Holder data-cy={`people-${i} person-section`}>
                            {structure.reviewableResource && (
                                <MerchantApplicationSectionCheckmark
                                    applicationId={applicationId}
                                    resource={structure.reviewableResource}
                                    subsectionIndex={i}
                                />
                            )}
                            <Card showEditable>
                                <CardInset>
                                    <MerchantApplicationSuperField
                                        prefixMemo={prefixMemo}
                                        applicationId={applicationId}
                                        indexPath={GenerateIndexPathForSubsection(structure, i, field)}
                                        executeBatchChanges={executeBatchChanges}
                                        formErrors={errors}
                                        smaller
                                        isDataLoading={!hasLoaded}
                                        formRef={formRef}
                                    />
                                    <Spacer height={15} />
                                    <MerchantApplicationSubsection
                                        key={'key'}
                                        executeBatchChanges={executeBatchChanges}
                                        applicationId={applicationId}
                                        formErrors={errors}
                                        formRef={formRef}
                                        fieldIndex={i}
                                        subsection={s}
                                        subsectionStructure={structure}
                                    />
                                </CardInset>
                            </Card>
                        </Holder>
                        <Flex column>
                            <ApplicationDedicatedInternalSectionWrapper>
                                <MerchantApplicationInternalDetails
                                    sectionStructure={structure}
                                    applicationId={applicationId}
                                    subsection
                                    subsectionIndex={i}
                                />
                            </ApplicationDedicatedInternalSectionWrapper>
                        </Flex>
                    </React.Fragment>
                )
            })}
            {fields.length === 0 ? <ApplicationNoSubsections type="people" /> : undefined}
            {hasLoaded ? (
                <>
                    <WatcherButton
                        onClick={handleAddPerson}
                        background="front.subtleAccent.background"
                        color="front.subtleAccent.text"
                        grow
                    >
                        <ButtonInset padding="medium">+ Add a person</ButtonInset>
                    </WatcherButton>
                    <span />
                </>
            ) : null}
             {
                peopleHistory?.fields.map((people, i) => {
                    if(people.metadata.deleted)
                        return (
                            <React.Fragment key={people.id}>
                                <ResourceHistory
                                    prefixMemo={prefixMemo}
                                    applicationId={applicationId}
                                    fields={structure.fields}
                                    resource={people}
                                    subsectionIndex={i}
                                    resourceKeys={['role', 'socialSecurityNumber', 
                                        'dateOfBirth', 'country', 'addressLine1', 'addressLine2', 'zipcode', 'city',
                                        'addressLegitimation','pictureLegitimation']}
                                    title={people.name}
                                    showInternalCard={handleShowInternalCard}
                                />
                                {
                                    open[i] ?
                                        RightSideCard(i) : <span/>
                                }
                            </React.Fragment>
                        ) 
                })
            }
        </>
    )
}

const Holder = styled.div`
    position: relative;
`
