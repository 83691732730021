export const applicationValidationMessages = {
    business_model: {
        delivery_delay: `Delivery delay</span> in business model section`,
        description: `Description in business model section (instant)`,
        drop_shipping: `Drop shipping in business model section`,
        estimate_currency: `Estimate currency in business model section`,
        // eslint-disable-next-line max-len
        estimated_average_transaction_amount: `Estimated average transaction amount in business model section`,
        estimated_monthly_turnover: `Estimated monthly turnover in business model section (instant)`,
        physical_delivery: `Physical delivery in business model section`,
        trading_name: `Trading name in business model section`
    },
    company: {
        address_line_1: `Street line 1 in company section (instant)`,
        city: `City in company section (instant)`,
        country: `Country in company section (instant)`,
        email: `Email in company section`,
        name: `Name in company section (instant)`,
        phone: `Phone in company section`,
        registration_number: `Registration number in company section (instant)`,
        registration_number_invalid: `Registration number in company section (instant)`,
        zipcode: `ZIP in company section (instant)`,
        form: `Form in company section`,
        documentation: `Documentation in company section`,
        suretyship: `Suretyship in company section`
    },
    bank_account: {
        bank: `Bank name in Bank account section`,
        currency: `Currency in Bank account section (instant)`,
        iban: `IBAN in Bank account section (instant)`,
        swift_code: `Swift in Bank account section`
    },
    contact: {
        email: `Contact email in contact section (instant)`,
        name: `Contact name in contact section (instant)`,
        phone: `Contact phone in contact section`
    },
    people: {
        no_persons_added: `No person is added in people section`,
        no_director: `No director is added in people section`,
        address_line_1: `Street line 1 in people section`,
        city: `City in people section`,
        country: `Country in people section`,
        date_of_birth: `Date of birth in people section`,
        name: `Name in people section`,
        zipcode: `ZIP in people section`,
        role: `Role in people section`,
        social_security_number: `Social security # in people section`,
        address_legitimation: `Address legit. in people section`,
        picture_legitimation: `Picture legit. in people section`
    },
    signer: {
        email: `Signer email in contact section`,
        name: `Signer name in contact section`
    },
    websites: {
        no_websites_added: `No website is added in websites section (instant)`,
        url: `Url in websites section (instant)`
    }
}
