import React from 'react'
import countries from 'i18n-iso-countries'
import { Text } from '../../../../../../../components/general/text'
import { Flag } from '../../../../../../../components/icons/flag'
import { Spacer } from '../../../../../../../components/layout/spacer'
import { BatchOfMonitoredChanges } from '../../../../../../../store/tasksCompanyChanges/types'
import { uppercaseFirstLetter } from '../../../../../../../utils'
import { ApplicationStatus } from '../../../../../../../store/applications/types'

interface MerchantMetadataProps {
    change?: BatchOfMonitoredChanges
}

export const MerchantMetadata: React.FC<MerchantMetadataProps> = (props) => {
    const { change } = props

    if (!change || !change.fetchedApplicationData.data) {
        return null
    }

    const status = change.fetchedApplicationData.data.application.metadata.state
    const country = change.fetchedApplicationData.data.company.country

    return (
        <>
            <Country country={country} />
            &nbsp;&nbsp; &middot; &nbsp;&nbsp;
            <Status status={status} />
        </>
    )
}

interface CountryProps {
    country?: string
}

const Country: React.FC<CountryProps> = (props) => {
    const { country } = props

    if (!country) {
        return null
    }

    return (
        <>
            <Flag code={country} smaller />
            <Spacer width={5} />
            <Text noWrap>{countries.getName(country.toUpperCase(), 'en')}</Text>
        </>
    )
}

interface StatusProps {
    status?: ApplicationStatus
}

const Status: React.FC<StatusProps> = (props) => {
    const { status } = props

    if (!status) {
        return null
    }

    return <Text noWrap>Status: {uppercaseFirstLetter(status ?? 'Unknown state')}</Text>
}
