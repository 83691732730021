import { ActionType, getType } from 'deox'
import moment from 'moment'
import { all, put, take } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'

import { GET } from '../../../generators/networking'
import { DateFormats } from '../../../utils/dateUtils'
import { APPLICATIONS_STORE_APPLICATION } from '../../applications/actions'
import { MerchantAccountsActions } from '../../merchantAccounts/actions'
import { ApplicationInternalsWarningsActions } from './actions'
import { MerchantWarning } from './types'

function* checkForStaleContract(merchantId: string, accs: any) {
    // eslint-disable-next-line no-restricted-syntax
    const generatedWarnings: MerchantWarning[] = []
    for (const account of accs) {
        if (account?.contract?.metadata?.state === 'needs_signature') {
            if (moment(account?.contract.metadata.createdAt).isBefore(moment().subtract(5, 'days'))) {
                generatedWarnings.push({
                    id: uuid(),
                    type: 'merchant-contract-no-signature',
                    title: `**Contract** is **missing a signature**, but was sent to signing ${moment(
                        account?.contract.metadata.createdAt
                    ).fromNow()}.`,
                    relatedToMID: account?.merchantId
                })
            }
        }
    }
    yield put(ApplicationInternalsWarningsActions.STORE(merchantId, 'staleContract', generatedWarnings))
}

export const WARNING_NO_TRANSACTIONS_AT_ALL = 'Merchant has not processed any transactions'
function* checkForTransactionsInconsistencies(merchantId: string, accs: any) {
    // Give up if no account is in a live state
    let earliestLiveAccount: any
    accs.forEach((acc: any) => {
        if (acc.metadata?.state === 'live') {
            if (moment(acc.metadata.createdAt).isBefore(earliestLiveAccount))
                earliestLiveAccount = acc.metadata.createdAt
        }
    })

    const fetchTransactionForEachAccount = accs.map((acc: any) => {
        return GET({
            url: `${import.meta.env.VITE_API_ROOT}/transactions?per_page=1&query=${encodeURIComponent(
                `mid:${acc.merchantId}`
            )}`
        })
    })

    const results: { transactions: any[] }[] = yield all(fetchTransactionForEachAccount)
    const generatedWarnings: MerchantWarning[] = []
    let noTransactionsProcessedAtAll = true
    // Check if each account has transactions
    for (let i = 0; i < results.length; i++) {
        const res = results[i]
        const acc = accs[i]
        if (!res.transactions?.length) {
            generatedWarnings.push({
                id: uuid(),
                type: 'merchant-account-no-transaction',
                title: `**Account has no processed** transactions, but has been created ${moment(
                    acc.metadata.createdAt
                ).fromNow()}.`,
                relatedToMID: acc.merchantId
            })
        } else {
            noTransactionsProcessedAtAll = false
        }
    }

    // Add only if we notice the warning above multiple times
    if (earliestLiveAccount && noTransactionsProcessedAtAll && generatedWarnings.length > 1) {
        generatedWarnings.push({
            id: uuid(),
            type: 'general',
            title: `**${WARNING_NO_TRANSACTIONS_AT_ALL}**, even though the first account has been created over ${moment(
                earliestLiveAccount
            ).fromNow()} (${moment(earliestLiveAccount).format(DateFormats.fullDay(moment(earliestLiveAccount)))}).`
        })
    }

    yield put(ApplicationInternalsWarningsActions.STORE(merchantId, 'transactionInconsistencies', generatedWarnings))
}

export const ApplicationInternalsWarningsSagas = {
    *GENERATE({ payload: p }: ActionType<typeof ApplicationInternalsWarningsActions.GENERATE>) {
        const waitFor: any = {
            application: {
                data: undefined,
                loaded: false
            },
            accounts: {
                data: undefined,
                loaded: false
            }
        }

        // Because the generate saga was called before the other required data,
        // we have to wait for those actions to load the required data before we can start
        // processing
        let result:
            | {
                  payload: {
                      applicationId: string
                  }
              }
            | {
                  application: {
                      id: string
                  }
              }
        do {
            result = yield take([
                getType(MerchantAccountsActions.STORE_ACCOUNTS_WITHOUT_CONTRACTS),
                APPLICATIONS_STORE_APPLICATION
            ])
            if ('payload' in result ? result.payload?.applicationId === p.merchantId : false) {
                waitFor.accounts = {
                    data: result,
                    loaded: true
                }
            }
            if ('application' in result ? result.application?.id === p.merchantId : false) {
                waitFor.application = {
                    data: result,
                    loaded: true
                }
            }
        } while (waitFor.application.loaded === false || waitFor.accounts.loaded === false)

        // This is where the processing starts
        const { accountsWithContracts } = waitFor.accounts.data.payload
        yield checkForStaleContract(p.merchantId, accountsWithContracts)
        yield checkForTransactionsInconsistencies(p.merchantId, accountsWithContracts)
    }
}
