import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../../components/general/text'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../../components/modals/modalIds'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { Icon, IconType } from '../../components/icons/icon'
import { zIndexes } from '../../styles/zIndexes'
import { SmartNavLink } from '../../components/tables/smartNavLink'

export const MerchantSidebarNavLink: React.FC<{
    to: string
    searchQuery: string
    icon?: IconType
    exact?: boolean
    label: any
    hotkeys?: string
    hotkeysScope?: MODAL_ID
    smaller?: boolean
}> = ({ icon, to, smaller, exact, searchQuery, label, hotkeysScope, hotkeys }) => {
    const [ref, setRef] = useRefTaker()

    const clue = useHotkey({
        scope: hotkeysScope,
        keys: hotkeys,
        clue: 'right',
        action: useCallback(() => {
            if (ref) ref.click()
        }, [ref])
    })

    return (
        <NavItem
            to={{
                pathname: to,
                search: searchQuery
            }}
            $smaller={smaller}
            exact={exact}
            ref={setRef}
        >
            <IconHolder noIcon={!icon}>
                <Icon type={icon || 'merchantOther'} size={17} />
            </IconHolder>
            <Text size="m">{label}</Text>
            {clue}
        </NavItem>
    )
}

const IconHolder = styled.div<{ noIcon?: boolean }>`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    margin-right: 8px;
    ${(p) => p.noIcon && css``}
`

const NavItem = styled(SmartNavLink)`
    padding: 2px 0;
    border-radius: 0px;
    flex-shrink: 0;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    z-index: ${zIndexes.popoverLevel3};
    color: ${(p) => p.theme['side.text.subtlerI']};
    border-radius: 8px;
    ${(p: any) =>
        p.$smaller &&
        css`
            height: 23px;
        `}

    ${IconHolder} {
        color: 'inherit';
    }

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        color: ${(p) => p.theme['side.text.subtlerII']};
    }

    &:active {
        color: ${(p) => p.theme['side.text']};
        background-color: ${(p) => p.theme['side.background.strongerII']};
    }

    &.active {
        color: ${(p) => p.theme['side.text.strongerII']};
        font-weight: 500;
        position: relative;
        background-color: ${(p) => p.theme['side.background.strongerI']};

        ${IconHolder} {
            color: ${(p) => p.theme['side.text.strongerII']};
        }

        &:hover,
        &:active {
            color: ${(p) => p.theme['side.text.strongerII']};
            font-weight: 500;
            cursor: default;
        }
    }
` as any
