import { useForm as useHookForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export type PartnerSchema = yup.InferType<typeof schema>

const schema = yup
    .object()
    .shape({
        name: yup.string().required(),
        merchant_payment_gateway_id: yup.string(),
        technical: yup.boolean(),
        support: yup.boolean(),
        referral: yup.boolean()
    })
    .required()

export const usePartnerForm = (defaultValues?: PartnerSchema) =>
    useHookForm<PartnerSchema>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            name: defaultValues?.name,
            merchant_payment_gateway_id: defaultValues?.merchant_payment_gateway_id,
            technical: defaultValues ? defaultValues.technical : false,
            support: defaultValues ? defaultValues.support : false,
            referral: defaultValues ? defaultValues.referral : false
        }
    })
