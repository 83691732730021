import Ketting, { bearerAuth } from 'ketting'
import { binaryStateFactory, htmlStateFactory } from 'ketting/dist/state'

import Resource from 'ketting/dist/resource'
import { Url } from '../../services/common/types'
import { factory as halStateFactory } from './halState'

export class HALClient {
    public static resourceCache: Map<string, Resource>
    private static _token: string | undefined

    public static go(uri: Url): Resource {
        return HALClient.getKettingClient.go(uri)
    }

    public static getResource(uri: Url): Resource | undefined {
        return HALClient.getKettingClient.resources.get(uri)
    }

    public static setToken(token: string) {
        this._token = token
    }
    private static instance: Ketting

    private static get getKettingClient(): Ketting {
        if (!HALClient.instance) {
            HALClient.instance = new Ketting('')
            HALClient.instance.fetcher.advertiseKetting = false
            HALClient.resourceCache = HALClient.instance.resources
            HALClient.setContentTypes()

            if (this._token) {
                HALClient.instance.use(bearerAuth(this._token))
            }
        }

        return HALClient.instance
    }
    private static setContentTypes() {
        HALClient.instance.contentTypeMap = {
            'application/json': [halStateFactory, '1.0'],
            'application/hal+json': [halStateFactory, '0.8'],
            'text/html': [htmlStateFactory, '0.7'],
            'application/pdf': [binaryStateFactory, '0.5']
        }
    }
}
