import { CrossIcon, Flex, Grid, Input as DSInput, Label as DSLabel, Text } from '@/design-system'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface FormInputProps {
    children: React.ReactNode
}

const FormInput = (props: FormInputProps) => {
    const { children } = props

    return <>{children}</>
}

interface LabelProps {
    testid?: string
    children: string
    name: string
}

const Label: React.FC<LabelProps> = (props) => {
    const { testid, children, name } = props

    return (
        <Grid.Item align="center">
            <DSLabel testid={testid} htmlFor={name}>
                {children}
            </DSLabel>
        </Grid.Item>
    )
}

interface InputProps {
    testid?: string
    name: string
    defaultValue?: string
}

const Input: React.FC<InputProps> = (props) => {
    const { testid, name, defaultValue } = props

    const {
        formState: { errors },
        register
    } = useFormContext<{ [x: string]: string }>()

    return (
        <>
            <Grid.Item columnSpan="2">
                <Flex gap="3" align="center">
                    <DSInput testid={testid} defaultValue={defaultValue} placeholder="-" {...register(name)} />
                    {errors[name] ? <CrossIcon color="danger" /> : <div />}
                </Flex>
            </Grid.Item>
            {errors[name] && (
                <Grid.Item columnSpan="2" columnStart="2">
                    <Text color="danger">{errors[name]?.message}</Text>
                </Grid.Item>
            )}
        </>
    )
}

FormInput.Label = Label
FormInput.Input = Input

export { FormInput }
