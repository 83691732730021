import { isTypo } from '../../utils/typosMatching/isTypo'
import { Company } from '../applications/types'
import { CompanyInfoPotentialMatch, DataProviderCompanyInfoFieldConflicts, MutedConflictsPayload } from './types'

export const ConvertCIFieldToApplication = (k: string) => {
    if (k == 'registrationNumber') {
        return undefined
    }
    if (k == 'registrationNumbers') {
        return 'registrationNumber'
    }
    return k
}
// sot - sourceOfTruth
export const scanForCompanyConflicts = (
    dmp: any,
    appCompany: Company,
    sotCompany: CompanyInfoPotentialMatch['company'],
    mutedConflicts?: Partial<MutedConflictsPayload>
) => {
    const companyConflicts: DataProviderCompanyInfoFieldConflicts = {
        conflictsCount: 0,
        mutedConflictsCount: 0,
        forField: {}
    }

    Object.keys(sotCompany).forEach((rawK) => {
        const k = ConvertCIFieldToApplication(rawK)
        if (!k) return
        if (k === 'form') return
        if (k === 'phone' && !sotCompany[k]) return
        const appVal = appCompany[k]
        const uncastedValue = sotCompany?.[rawK] as string | string[]
        const mutedVals = mutedConflicts?.[k]
        const sotVal: string[] | undefined = uncastedValue
            ? typeof uncastedValue == 'object'
                ? (uncastedValue as string[])
                : ([uncastedValue] as string[])
            : undefined
        if (!sotVal) return

        const matchesApplicationValue = sotVal.map((v) => v?.toLowerCase()).includes(appCompany[k]?.toLowerCase())

        if (!matchesApplicationValue) {
            const isMuted = !appVal ? mutedVals?.includes('BLANK') : mutedVals?.find?.((m) => m.includes(sotVal))

            if (isMuted) companyConflicts.mutedConflictsCount++
            companyConflicts.conflictsCount++

            for (const val of sotVal) {
                const diff = isTypo(dmp, val, appVal)

                if (!companyConflicts.forField[k]) companyConflicts.forField[k] = []

                if (diff) {
                    companyConflicts.forField[k].push({
                        type: 'typo',
                        highlights: diff,
                        application: appVal,
                        dataProvider: val,
                        isMuted
                    })
                } else {
                    companyConflicts.forField[k].push({
                        type: 'mismatch',
                        application: appVal,
                        dataProvider: val,
                        isMuted
                    })
                }
            }
        }
    })

    return companyConflicts
}
