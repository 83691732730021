import { TasksFilters } from '../types'
import { useParams } from 'react-router-dom'

type Params = {
    filter: TasksFilters
}

const allowed = ['new', 'open', 'closed', 'assigned-to-me']

export const useRouterMatchFilter = () => {
    const params = useParams<Params>()

    const isAllowedFilter = params?.filter ? allowed.includes(params.filter) : false

    if (!isAllowedFilter) {
        return 'new'
    }

    return params.filter
}
