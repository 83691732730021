import { useTasks } from '../../../api'
import { useAuth } from '@/hooks/auth/useAuth'
import { TaskState } from '@/services/taskr'
import { useRouterMatchFilter } from '../../../hooks'
import { useMemo } from 'react'

export const useScheduledTasksTable = (page: number) => {
    const filter = useRouterMatchFilter()
    const { user } = useAuth()

    const isState = filter === 'new' || filter === 'open' || filter === 'closed'
    const isAssignedToMe = filter === 'assigned-to-me'

    const query = {
        ...(isState && { state: filter as TaskState }),
        ...(isAssignedToMe && user && { assignee: user['https://clearhaus.com/app_metadata'].sub }),
        page,
        per_page: 50
    }
    const { data, isLoading } = useTasks(query)

    const result = useMemo(() => {
        return { data, isLoading }
    }, [data, isLoading])

    return result
}
