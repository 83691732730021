import { Pagination } from '../../utils'
import { Application } from '../applications/types'
import { MerchantAccount } from '../merchantAccounts/types'
import { Transaction } from '../transactions/types'
import { Settlement, SettlementsFilters, SettlementsState, SettlementSummary } from './types'

export const SETTLEMENTS_LOAD_SUMMARIES = 'SETTLEMENTS_LOAD_SUMMARIES'
export const SETTLEMENTS_STORE_SUMMARIES = 'SETTLEMENTS_STORE_SUMMARIES'
export const SETTLEMENTS_CLEAR_SUMMARIES = 'SETTLEMENTS_CLEAR_SUMMARIES'
export const SETTLEMENT_FETCH_SETTLEMENT = 'SETTLEMENT_FETCH_SETTLEMENT'
export const SETTLEMENT_STORE = 'SETTLEMENT_STORE'
export const SETTLEMENT_UNSTORE = 'SETTLEMENT_UNSTORE'
export const SETTLEMENT_STORE_RELATED_LINK = 'SETTLEMENT_STORE_RELATED_LINK'
export const SETTLEMENTS_LOAD_MERCHANT_DETAILS = 'SETTLEMENTS_LOAD_MERCHANT_DETAILS'
export const SETTLEMENTS_LOAD_TRANSACTIONS = 'SETTLEMENTS_LOAD_TRANSACTIONS'
export const SETTLEMENTS_STORE_TRANSACTIONS = 'SETTLEMENTS_STORE_TRANSACTIONS'
export const SETTLEMENTS_STORE_MERCHANT_DETAILS = 'SETTLEMENTS_STORE_MERCHANT_DETAILS'
export const SETTLEMENTS_RESERVE_CANCEL = 'SETTLEMENTS_RESERVE_CANCEL'

export interface SettlementsActionLoadSummaries {
    type: typeof SETTLEMENTS_LOAD_SUMMARIES
    filters: SettlementsFilters
}
export interface SettlementsActionStoreSummaries {
    type: typeof SETTLEMENTS_STORE_SUMMARIES
    settlementsSummaries: SettlementSummary[]
    pagination: SettlementsState['settlementsSummaries']['pagination']
}
export interface SettlementsActionClearSummaries {
    type: typeof SETTLEMENTS_CLEAR_SUMMARIES
}

export interface SettlementActionFetchSettlement {
    type: typeof SETTLEMENT_FETCH_SETTLEMENT
    settlementId: string
    forceLoad?: boolean
    skipSubrequests?: boolean
}
export interface SettlementActionStore {
    type: typeof SETTLEMENT_STORE
    settlement: Settlement
}
export interface SettlementActionUnstore {
    type: typeof SETTLEMENT_UNSTORE
    settlementId: string
}
export interface SettlementActionLoadMerchantDetails {
    type: typeof SETTLEMENTS_LOAD_MERCHANT_DETAILS
    settlement: Settlement
}
export interface SettlementActionStoreMerchantDetails {
    type: typeof SETTLEMENTS_STORE_MERCHANT_DETAILS
    payload: {
        settlementId: string
        account: MerchantAccount
        application: Application
    }
}
export interface SettlementActionLoadTransactions {
    type: typeof SETTLEMENTS_LOAD_TRANSACTIONS
    settlementId: string
    transactionsLink: string
    page: number
}
export interface SettlementActionStoreTransactions {
    type: typeof SETTLEMENTS_STORE_TRANSACTIONS
    settlementId: string
    body: {
        transactions: Transaction[]
        pagination: Pagination
    }
}

export interface SettlementActionReserveCancel {
    type: typeof SETTLEMENTS_RESERVE_CANCEL
    settlementId: string
}
// ---------- Reserves
export interface SettlementActionBatchCancelMultipleReserves {
    type: typeof SETTLEMENTS_RESERVE_CANCEL
    stamps: string[]
}

export interface SettlementActionBatchCancelMultipleReserves {
    type: typeof SETTLEMENTS_RESERVE_CANCEL
    stamps: string[]
}

export interface SettlementActionStoreRelatedLink {
    type: typeof SETTLEMENT_STORE_RELATED_LINK
    settlementId: string
    link: string
}

export const SettlementDispatchStoreRelatedLink = (
    settlementId: string,
    link: string
): SettlementActionStoreRelatedLink => {
    return {
        type: SETTLEMENT_STORE_RELATED_LINK,
        settlementId,
        link
    }
}

export const SettlementDispatchStoreTransactions = (
    settlementId: string,
    body: {
        transactions: Transaction[]
        pagination: Pagination
    }
): SettlementActionStoreTransactions => {
    return {
        type: SETTLEMENTS_STORE_TRANSACTIONS,
        settlementId,
        body
    }
}

export const SettlementsDispatchClearSummaries = (): SettlementsActionClearSummaries => {
    return {
        type: SETTLEMENTS_CLEAR_SUMMARIES
    }
}
export const SettlementDispatchLoadTransactions = (
    settlementId: string,
    transactionsLink: string,
    page?: number
): SettlementActionLoadTransactions => {
    return {
        type: SETTLEMENTS_LOAD_TRANSACTIONS,
        settlementId,
        transactionsLink,
        page: page || 1
    }
}
export const SettlementDispatchLoadMerchantDetails = (settlement: Settlement): SettlementActionLoadMerchantDetails => {
    return {
        type: SETTLEMENTS_LOAD_MERCHANT_DETAILS,
        settlement
    }
}
export const SettlementDispatchStoreMerchantDetails = (payload: {
    settlementId: string
    account: MerchantAccount
    application: Application
}): SettlementActionStoreMerchantDetails => {
    return {
        type: SETTLEMENTS_STORE_MERCHANT_DETAILS,
        payload
    }
}
export const SettlementsDispatchLoadSummaries = (filters: SettlementsFilters): SettlementsActionLoadSummaries => {
    return {
        type: SETTLEMENTS_LOAD_SUMMARIES,
        filters
    }
}
export const SettlementsDispatchFetchSettlement = (
    settlementId: string,
    forceLoad?: boolean,
    skipSubrequests?: boolean
): SettlementActionFetchSettlement => {
    return {
        type: SETTLEMENT_FETCH_SETTLEMENT,
        settlementId,
        skipSubrequests
    }
}
export const SettlementDispatchUnstore = (settlementId: string): SettlementActionUnstore => {
    return {
        type: SETTLEMENT_UNSTORE,
        settlementId
    }
}
export const SettlementDispatchStore = (settlement: Settlement): SettlementActionStore => {
    return {
        type: SETTLEMENT_STORE,
        settlement
    }
}

export const SettlementsDispatchStoreSummaries = (
    settlementsSummaries: SettlementSummary[],
    pagination: SettlementsState['settlementsSummaries']['pagination']
): SettlementsActionStoreSummaries => {
    return {
        type: SETTLEMENTS_STORE_SUMMARIES,
        settlementsSummaries,
        pagination
    }
}

export const SettlementsDispatchReserveCancel = (settlementId: string): SettlementActionReserveCancel => {
    return { type: SETTLEMENTS_RESERVE_CANCEL, settlementId }
}

export interface SagasForSettlements {
    loadSummaries(action: SettlementsActionLoadSummaries): void
    fetchSettlement(action: SettlementActionFetchSettlement): void
    loadMerchantDetails(action: SettlementActionLoadMerchantDetails): void
    loadTransactions(action: SettlementActionLoadTransactions): void
}

export type ReducedSettlementsActions =
    | SettlementsActionStoreSummaries
    | SettlementActionStoreMerchantDetails
    | SettlementsActionLoadSummaries
    | SettlementActionStore
    | SettlementsActionClearSummaries
    | SettlementActionUnstore
    | SettlementActionLoadTransactions
    | SettlementActionStoreTransactions
    | SettlementActionStoreRelatedLink
