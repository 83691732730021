import styled, { css } from 'styled-components'

export const FilterPageHolder: React.FC<{ isDark: boolean; children: any }> = ({ isDark, children }) => {
    return <Holder isDark={isDark}>{children}</Holder>
}
const Holder = styled.div<{ isDark?: boolean }>`
    width: ${(p) => (p.isDark ? '300px' : '100%')};
    ${(p) =>
        !p.isDark &&
        css`
            border-bottom: 1px dashed ${(p) => p.theme['side.border']};
            padding-bottom: 6px;
            margin-bottom: 2px;
        `}
`
