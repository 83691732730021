import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { TextInput } from './textInput'
import { Icon } from '../icons/icon'
import { Floater } from '../layout/floater'
import { Spacer } from '../layout/spacer'
import { merchantCommentTemplates } from '../../utils/templates'
import { DropdownSelectableList } from './dropdownSelectableList'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { FloaterInset } from '../layout/floaterInset'
import { useOnClickOutside } from '../../hooks/general/useOnClickOutside'

export const MessageBoxTemplates: React.FC<{
    handleSelect?: (id: string) => void
}> = ({ handleSelect }) => {
    const [show, setShow] = useState(false)
    const [anchor, setAnchor] = useRefTaker()
    const [input, setInput] = useRefTaker()
    const [holder, setHolder] = useRefTaker()

    useOnClickOutside(
        holder,
        useCallback(() => {
            setShow(false)
        }, [])
    )

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                input?.focus()
            }, 1)
        }
    }, [show, input])

    const handleInsertClick = useCallback(() => {
        setShow(true)
    }, [setShow])

    const items = useMemo(() => {
        return merchantCommentTemplates.map((t) => t.name)
    }, [])

    const textForItem = useCallback((k: string): string => {
        return k
    }, [])
    const [filteredItems, setFilteredItems] = useState(items)

    const onSelect = useCallback(
        (item) => {
            handleSelect?.(item)
            setShow(false)
        },
        [handleSelect, setShow]
    )

    return (
        <Holder ref={setHolder}>
            <Floater placement="top" anchor={anchor} shouldShow={show}>
                <Header>
                    <TextInput
                        ref={setInput}
                        placeholder="Filter templates"
                        isSeamless
                        overBackground="floating.background"
                        onChange={(e, val) => {
                            setFilteredItems(items.filter((k) => k.toLowerCase().includes(val.toLowerCase())))
                        }}
                    />
                </Header>
                <ScrollMask className="os-theme-dark">
                    <FloaterInset>
                        <DropdownSelectableList
                            dropdownId="MessageBox.Templates"
                            items={filteredItems}
                            noBullets
                            textForItem={textForItem}
                            onSelect={onSelect}
                        />
                    </FloaterInset>
                </ScrollMask>
            </Floater>
            <div ref={setAnchor}>
                <SimpleButton color="front.accent.color" onClick={handleInsertClick} cy="insert-template">
                    <ButtonInset noHorizontalPadding>
                        <Icon type="addTemplate" />
                        <Spacer width={7} />
                        Insert a template
                    </ButtonInset>
                </SimpleButton>
            </div>
        </Holder>
    )
}

const Template = styled.div`
    display: block;
    width: 100%;
    padding: 10px;
`

const Holder = styled.div`
    display: contents;
`

const Header = styled.div`
    border-bottom: 1px solid ${(p) => p.theme['floating.border']};
    input {
        padding: 10px 15px;
    }
`

const ScrollMask = styled(OverlayScrollbarsComponent)`
    max-height: 20vh;
    min-width: 400px;
    box-sizing: border-box;
    .os-viewport {
        overscroll-behavior: none;
    }
`
