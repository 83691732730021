import { Url } from '@/services/common'
import { api } from '../api'
import { SigningKey, SigningKeyData, SigningKeys } from '../types/signing-keys'

export const MAPI_SIGNING_KEYS_ROOT = `${import.meta.env.VITE_API_ROOT}/signing_keys`

export const signingKeysApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSigningKeys: build.query<SigningKeys, Url>({
            query: (url) => {
                return { url }
            },
            transformResponse: (response: SigningKeys) => {
                if (response.embedded['ch:signing-keys'] && !Array.isArray(response.embedded['ch:signing-keys'])) {
                    response.embedded['ch:signing-keys'] = [response.embedded['ch:signing-keys']]
                }

                return response
            },
            providesTags: ['signing-keys']
        }),
        getSigningKey: build.query<SigningKey, Url>({
            query: (url) => {
                return { url }
            },
            providesTags: ['signing-key']
        }),
        createSigningKey: build.mutation<SigningKey, { url: Url; data: Partial<SigningKeyData> }>({
            query: ({ url, data }) => ({
                url,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['signing-keys']
        }),
        updateSigningKey: build.mutation<SigningKey, { url: Url; data: Partial<SigningKey> }>({
            query: ({ url, data }) => ({
                url,
                body: data,
                method: 'PATCH'
            }),
            invalidatesTags: ['signing-keys', 'signing-key']
        }),
        deleteSigningKey: build.mutation<SigningKey, Url>({
            query: (url) => ({
                url,
                method: 'DELETE'
            }),
            invalidatesTags: ['signing-keys', 'signing-key']
        })
    })
})

export const {
    useGetSigningKeyQuery: useGetSigningKey,
    useGetSigningKeysQuery: useGetSigningKeys,
    useCreateSigningKeyMutation: useCreateSigningKey,
    useUpdateSigningKeyMutation: useUpdateSigningKey,
    useDeleteSigningKeyMutation: useDeleteSigningKey
} = signingKeysApi

export const {
    endpoints: { getSigningKeys, getSigningKey, createSigningKey, updateSigningKey, deleteSigningKey }
} = signingKeysApi
