import { camelCase } from 'lodash'

import styled from 'styled-components'

import { Task } from '../../../store/tasks/types'
import { uppercaseFirstLetter } from '../../../utils'
import { Linechart } from '../../charts/linechart'
import { ColorSpot } from '../../general/colorSpot'
import { Flex } from '../../layout/flex'
import { PrintOnly } from '../../general/printOnly'
import { Spacer } from '../../layout/spacer'
import { Text } from '../../general/text'
import { ContextCardHolder } from './commons'
import { ContextCardsNoDataFound } from './noDataFound'

export const VolumeContextCard: React.FC<{ data?: any; task: Task }> = ({ data, task }) => {
    if (!data.data.value || !Array.isArray(data.data.value)) return <ContextCardsNoDataFound />

    const dataset = JSON.parse(JSON.stringify(data.data.value)).filter(
        (f: any) => f.data && Object.keys(f.data).length > 0
    )

    if (dataset.length == 0) return <ContextCardsNoDataFound />

    return (
        <ContextCardHolder column>
            <PrintOnly>
                <Spacer width={12} />
            </PrintOnly>
            <Linechart data={dataset} currency={task?.account?.data?.currency ? task.account.data.currency : ''} />
            {Array.isArray(dataset) ? (
                <ChartFooter>
                    {dataset.map((item: any, index: number) => (
                        <Flex
                            key={camelCase(item.label)}
                            align="center"
                            style={{
                                marginRight: index == dataset.length - 1 ? 0 : 20
                            }}
                        >
                            <ColorSpot hash={item.color} />
                            <Text size="s">{uppercaseFirstLetter(item.label)}</Text>
                        </Flex>
                    ))}
                </ChartFooter>
            ) : null}
        </ContextCardHolder>
    )
}

const ChartFooter = styled(Flex)`
    margin-top: 10px;
    padding-top: 15px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;

    @media print {
        justify-content: flex-start !important;
        border-top: none !important;
    }
`
