import { range } from 'lodash'
import { all, call } from 'redux-saga/effects'
import { take } from 'typed-redux-saga'

import { GET } from '../generators/networking'
import { WATCHER_FAIL, WATCHER_SUCCESS } from './watcher/actions'
import { v4 as uuid } from 'uuid'

export const getNodeText = (node: any): any => {
    if (['string', 'number'].includes(typeof node)) {
        if (typeof node === 'string') return node
        return `${node}`
    }

    if (node instanceof Array) return node.map(getNodeText).join('')

    if (typeof node === 'object' && node?.props?.account) {
        return getNodeText(JSON.stringify(node.props.account))
    }

    if (typeof node === 'object' && node) return getNodeText(node.props.children)
}

export function* WaitForWatcher(action: (id: string) => Generator<any, any, any>) {
    const watcherId = uuid()
    let watcherAction: any

    const response = yield all([
        action(watcherId),
        call(function* () {
            do {
                watcherAction = yield* take([WATCHER_SUCCESS, WATCHER_FAIL])
            } while (!watcherAction?.watchers?.includes?.(watcherId))
        })
    ])

    if (watcherAction.type == WATCHER_SUCCESS) {
        return response
    } else {
        return null
    }
}
export function* FetchAllPages<T>(
    link: string | undefined,
    reducer: (previous: any, response: any) => any,
    includes?: string[],
    perPage = 20
): Generator<any, T[], any> {
    if (!link) return []
    const alreadyHasQueryParams = link.includes('?')

    const firstRequest: { [key: string]: any } = (yield GET({
        url: `${link}${`${alreadyHasQueryParams ? '&' : '?'}per_page=${link.endsWith('version-history') ? 50 : perPage}`}`,
        include: includes,
        errorText: 'Failed to fetch the pages recursively'
    })) as { [key: string]: any }

    if (!firstRequest) return []

    let allRequests: { [key: string]: any } = []
    if (!link.endsWith('version-history')) {
        const requestsCount = Math.ceil(firstRequest.count / (firstRequest.perPage || perPage))

        // Start with 2, as first request has been made before
        const requests =
            requestsCount > 1
                ? range(1, requestsCount).map((c) => {
                      return GET({
                          url: `${link}${alreadyHasQueryParams ? '&' : '?'}page=${c + 1}${`&per_page=${perPage}`}`,
                          include: includes,
                          errorText: 'Failed to fetch the pages recursively'
                      })
                  })
                : []

        allRequests = yield all(requests)
    }

    const everything: T[] = [
        ...reducer([], firstRequest),
        ...allRequests.reduce((acc: any, response: any) => {
            return [...acc, ...reducer([], response)]
        }, [])
    ]

    return everything
}

export type Optional<T> = T | undefined

export type TypoHighlight = [number, string]
