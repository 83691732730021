import moment from 'moment'
import React, { useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { Sidebar } from '../../components/listPages/sidebarNavigation/sidebar'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { List } from '../../components/layout/list'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { useListPage } from '../../hooks/pages/useListPage'
import { ListPageFilterDisputeReason } from '../../components/listPageFilters/listPageFilterDisputeReason'
import { ListPageFilterDisputeReference } from '../../components/listPageFilters/listPageFilterDisputeReference'
import { ListPageFilterDisputeStatus } from '../../components/listPageFilters/listPageFilterDisputeStatus'
import { ListPageFilterDisputeType } from '../../components/listPageFilters/listPageFilterDisputeType'
import { ListPageFilterMerchant } from '../../components/listPageFilters/listPageFilterMerchant'
import { Filters } from '../../components/taskPages/filters'
import { PopularFilters } from '../../components/taskPages/popularFilters'
import { DisputesDispatchClearSummaries, DisputesDispatchLoadSummaries } from '../../store/disputes/actions'
import { DisputeFiltersParams, DisputesFilters } from '../../store/disputes/types'
import { RootState } from '@/store'
import { numberWithCurrency, uppercaseFirstLetter } from '../../utils'
import { DateFormats } from '../../utils/dateUtils'
import { useResetScrollOnMount } from '../../hooks/pages/useResetScrollOnMount'

import { ListPageFilterDate } from '../../components/listPageFilters/listPageFilterDate'
import { ListPageFiltersSeparator } from '../../components/listPageFilters/listPageFiltersSeparator'
import { useMerchantListPageDynamicTitle } from '../../hooks/pages/useMerchantListPageDynamicTitle'
import { useMerchantName } from '../../hooks/general/useMerchantName'
import { Spacer } from '../../components/layout/spacer'
import { SideFilters } from '../../components/layout/sideFilters'
import { ListPageFullPageBackButton } from '../../components/buttons/listPageFullPageBackButton'
import { TableBubble } from '../../components/layout/tableBubble'
import { useBackendToConsoleFilters } from '../../hooks/general/useBackendToConsoleFilters'
import { ListPageFilterCustomQuery } from '../../components/listPageFilters/listPageFilterCustomQuery'
import { useLocation, useParams } from 'react-router-dom'

type Params = { id: string }

const clearSummaries = () => DisputesDispatchClearSummaries()
const disallowMIDFilter = { disputes_mid: null }

const pagination = { pageKey: 'disputes_page', perPageKey: 'disputes_per_page' }
export const Disputes: React.FC<{
    inline?: boolean
    forMids?: any
    fullPage?: boolean
}> = ({ inline, forMids, fullPage }) => {
    const params = useParams() as Params
    const merchantId = params.id

    const location = useLocation()

    useBackendToConsoleFilters('dql')
    useResetScrollOnMount()
    const summaries = useSelector((state: RootState) => {
        return state.disputes.disputesSummaries
    })
    const name = useMerchantName(merchantId, 'Disputes')
    const initialFilters = useRef({ ...(forMids || fullPage ? {} : { disputes_status: ['open'] }) })

    const loadSummaries = useCallback(
        (filters: DisputesFilters) => {
            if (forMids) {
                return DisputesDispatchLoadSummaries({ ...filters, disputes_mid: forMids.disputes_mid })
            }
            return DisputesDispatchLoadSummaries(filters)
        },
        [forMids]
    )
    const { filters, paginationNode } = useListPage<DisputesFilters>(
        'DisputesList',
        merchantId ? name : 'Disputes',
        loadSummaries,
        DisputeFiltersParams,
        summaries,
        '/disputes/',
        location,
        initialFilters.current,
        pagination,
        forMids ? disallowMIDFilter : undefined,
        undefined,
        clearSummaries
    )

    const popularFilters = useMemo(() => {
        return (
            <PopularFilters
                showHideController={filters.showHideController}
                key="popularFilters"
                hotkeysScope="DisputesList"
                config={[
                    'General filters:',
                    {
                        label: 'Disputes · *Open*',
                        action: () => {
                            filters.set({ disputes_status: ['open'] })
                        }
                    },
                    {
                        label: 'Disputes · *Closed*',
                        action: () => {
                            filters.set({ disputes_status: ['closed'] })
                        }
                    },
                    {
                        label: 'Disputes · *Refuted*',
                        action: () => {
                            filters.set({ disputes_status: ['refuted'] })
                        }
                    },
                    {
                        label: 'Disputes · *Under review by merchant*',
                        action: () => {
                            filters.set({
                                disputes_status: ['under_review_merchant']
                            })
                        }
                    },
                    {
                        label: 'Disputes · *Under review by issuer*',
                        action: () => {
                            filters.set({
                                disputes_status: ['under_review_issuer']
                            })
                        }
                    },
                    'Filters based on reason:',
                    {
                        label: 'Reason · *Fraud*',
                        action: () => {
                            filters.set({
                                disputes_reason: ['fraud']
                            })
                        }
                    },
                    {
                        label: 'Reason · *Product not provided*',
                        action: () => {
                            filters.set({
                                disputes_reason: ['product_not_provided']
                            })
                        }
                    },
                    {
                        label: 'Reason · *Product unacceptable*',
                        action: () => {
                            filters.set({
                                disputes_reason: ['product_unacceptable']
                            })
                        }
                    }
                ]}
            />
        )
    }, [filters])

    const renderedFilters = useMemo(() => {
        const items: any = (isDark = true) => ({
            'Query': {
                type: 'custom',
                node: (
                    <ListPageFilterCustomQuery<DisputesFilters>
                        key="custom-filters"
                        fieldKey="disputes_custom_filters"
                        filters={filters}
                        isDark={isDark}
                    />
                )
            },
            'Status': {
                type: 'custom',
                node: <ListPageFilterDisputeStatus isDark={isDark} filters={filters} />
            },
            'MID': fullPage
                ? null
                : forMids
                ? null
                : {
                      type: 'custom',
                      node: (
                          <ListPageFilterMerchant<DisputesFilters>
                              filters={filters}
                              isDark={isDark}
                              fieldKey="disputes_mid"
                          />
                      )
                  },

            'Reference': {
                type: 'custom',
                node: <ListPageFilterDisputeReference isDark={isDark} filters={filters} />
            },
            'Types': {
                type: 'custom',
                node: <ListPageFilterDisputeType isDark={isDark} filters={filters} />
            },
            'Reason': {
                type: 'custom',
                node: <ListPageFilterDisputeReason isDark={isDark} filters={filters} />
            },
            ...(isDark
                ? {
                      'Separator 2': {
                          key: 'separator-2',
                          type: 'separator',
                          node: <ListPageFiltersSeparator isDark={isDark} />
                      }
                  }
                : {}),
            'Opened after': {
                type: 'custom',
                node: (
                    <ListPageFilterDate<DisputesFilters> isDark={isDark} filters={filters} filterKey="disputes_after" />
                )
            },
            'Opened before': {
                type: 'custom',
                node: (
                    <ListPageFilterDate<DisputesFilters>
                        filters={filters}
                        isDark={isDark}
                        filterKey="disputes_before"
                    />
                )
            }
        })

        return {
            sideFilters: (
                <SideFilters
                    items={items(false)}
                    before={<ListPageFullPageBackButton hotkeysScope="DisputesList" fullPage={fullPage} />}
                    shouldAlwaysDisplay={fullPage}
                />
            ),
            headerFilters: (
                <Filters
                    showHideController={filters.showHideController}
                    filters={filters}
                    noClearButton={fullPage}
                    resultsCount={summaries.all.length}
                    entityName="disputes"
                    pagination={summaries.pagination}
                    key="allFilters"
                    shouldAlwaysHide={fullPage}
                    hotkeysScope="DisputesList"
                >
                    <List
                        background="floating.background"
                        items={items()}
                        emphasizeLabels
                        template="auto"
                        switchToRowsAt={100000}
                        cellHorizontalTemplate="100px auto"
                    />
                </Filters>
            )
        }
    }, [filters, forMids, summaries.all.length, fullPage, summaries.pagination])

    const rows = useMemo(() => {
        return summaries.all.map((id: string) => {
            const t = summaries.at[id]

            let link = `/disputes/${t.id}${location.search}`
            if (merchantId) {
                link = `/merchant/${merchantId}/disputes/${t.id}`
            }

            return {
                type: 'normal' as const,
                link,
                key: t.id,
                items: [
                    {
                        node: <TableBubble type="outlined">{t.account.merchantId}</TableBubble>
                    },
                    {
                        node: <Text>{t.account.name}</Text>
                    },
                    {
                        node: (
                            <Text noWrap mono color="front.text.subtlerI">
                                {t.reference}
                            </Text>
                        )
                    },
                    {
                        node: <Text noWrap>{formatStatus(t.status)}</Text>
                    },
                    {
                        node: <Text noWrap>{moment(t.openedAt).format('DD MMM, YYYY')}</Text>
                    },
                    {
                        node: (
                            <TableBubble type="important">
                                {numberWithCurrency(t.currency, `${t.amount}`, t.openedAt)} {t.currency}
                            </TableBubble>
                        )
                    },
                    {
                        node: <Text noWrap>{uppercaseFirstLetter(t.type)}</Text>
                    },
                    {
                        node: <TableBubble type="outlined">{t.reasonCode}</TableBubble>
                    },
                    {
                        node: <Text noWrap>{uppercaseFirstLetter(t.reason ? t.reason : '')}</Text>
                    },
                    {
                        node: <Text noWrap>{moment(t.dueAt).format(DateFormats.dayStamp)}</Text>
                    }
                ]
            }
        })
    }, [location, summaries.all, merchantId, summaries.at])

    const title = useMerchantListPageDynamicTitle(filters, forMids, 'disputes_mid')

    if (forMids && !forMids.disputes_mid)
        return (
            <Flex column>
                <Spacer height={21} />
                <Text color="front.text.subtlerI">Merchant has no accounts</Text>
            </Flex>
        )

    return (
        <Flex align="stretch">
            {inline || fullPage ? null : <Sidebar hotkeysScope="DisputesList" />}
            {inline ? null : renderedFilters.sideFilters}
            <PageContent noVerticalPadding marginLeft={0} noPadding={inline}>
                <PageHeader
                    noBottomBorder
                    title={title}
                    // insetTop={inline}
                    subtitle=""
                    leftSideMemo={fullPage ? null : popularFilters}
                    rightSideMemo={fullPage ? null : renderedFilters.headerFilters}
                />
                <Card overflowX>
                    <CardInset>
                        <Table
                            background="front.background"
                            loaderRows={20}
                            overrideText={
                                forMids
                                    ? `None of this merchant's accounts have received any disputes`
                                    : 'No disputes found matching the filtered criterias.'
                            }
                            keepQuery
                            cols={[
                                { text: 'MID', loaderSize: { min: 55, max: 5 } },
                                { text: 'Merchant', loaderSize: { min: 105, max: 305 } },
                                { text: 'Reference', loaderSize: { min: 170, max: 170 } },
                                {
                                    text: 'State',
                                    loaderSize: { min: 30, max: 40 }
                                },
                                { text: 'Since', loaderSize: { min: 75, max: 75 } },
                                {
                                    text: 'Amount',
                                    alignRight: true,
                                    loaderSize: { min: 30, max: 60 }
                                },
                                { text: 'Type', loaderSize: { min: 85, max: 100 } },
                                { text: 'RC', loaderSize: { min: 30, max: 40 } },
                                { text: 'Reason', loaderSize: { min: 32, max: 120 } },
                                { text: 'Due', loaderSize: { min: 75, max: 75 } }
                            ]}
                            // eslint-disable-next-line max-len
                            columnLayout="min-content 1fr min-content min-content 1fr min-content min-content min-content min-content min-content"
                            showLastRowBorder
                            displayLoader={summaries.loadingStatus === 'started'}
                            rows={rows}
                            emptyText="No tasks found matching the filtered criterias."
                        />
                    </CardInset>
                </Card>
                {paginationNode}
            </PageContent>
        </Flex>
    )
}

const formatStatus = (s: string) => {
    if (s == 'under_review_merchant') return 'Waiting'
    const formatted = uppercaseFirstLetter(s)
    return formatted
}
