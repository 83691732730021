import { InitialDashboardState, DashboardState } from './types'
import { DASHBOARD_STORE_STATS, ReducedDashboardActions, DASHBOARD_LOAD_STATS, DASHBOARD_CLEAR_STATS } from './actions'

export function DashboardReducers(state = InitialDashboardState, action: ReducedDashboardActions): DashboardState {
    switch (action.type) {
        case DASHBOARD_LOAD_STATS: {
            if (state.loadingStatus === 'done') return state
            return {
                ...state,
                loadingStatus: 'started'
            }
        }
        case DASHBOARD_STORE_STATS: {
            const statsDictionary: any = {}

            action.payload.map((v: any) => {
                statsDictionary[v.state] = {
                    count: v.count,
                    link: v.link
                }
            })

            return {
                ...state,
                stats: {
                    ...state.stats,
                    ...statsDictionary
                },
                loadingStatus: 'done'
            }
        }
        case DASHBOARD_CLEAR_STATS: {
            return {
                ...state,
                stats: {},
                loadingStatus: 'not-started'
            }
        }
        default:
            return state
    }
}
