import { LoadingStatus } from '../../../utils'

export interface CutterCompany {
    loadingStatus: LoadingStatus
    id: string
    bvdId: string
    number: string
    manager: {
        readComments: any[]
        newComments: any[]
    }
    name: string
    address1: string
    zipcode: string
    city: string
    country: string
    form: string
    phoneNumber: string
    email: string
    ownershipStructure: string
    ownershipStructureComment: string
    noteOwnershipStructure: string
    sanitisedName: string
    sanitisedAddress1: string
    sanitisedCity: string
    sanitisedZipcode: string
    applicationFiles: any[]
    versions: any[]
    selfLink: string
    applicationFilesLink: string
    commentsLink: string
    checksLink: string
}

export interface ApplicationInternalCompanyState {
    forApplication: {
        [key: string]: CutterCompany
    }
}

export const InitialApplicationInternalCompanyState: ApplicationInternalCompanyState = {
    forApplication: {}
}
