export type WatcherState = 'success' | 'fail' | 'started' | 'complete' | undefined

export interface WatchersState {
    [key: string]: {
        state: WatcherState
        context?: any
    }
}

export type WatcherID = string

export const WatchersInitialState: WatchersState = {}
