import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { LoaderView } from '../../components/loaders/loader'
import { Table } from '../../components/tables/table'
import { UBO_OWNERSHIP_LOWER_LIMIT } from '../../store/applicationDataProviders/sagas.conflictsFinder'
import { DataPorviderStateAssignedAndReady } from '../../store/applicationDataProviders/types'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../utils'
import { InternalDetailsSeparator } from './Application/Application.InternalDetails.Separator'

export const MerchantApplicationOwnershipReport: React.FC<{ applicationId: string }> = ({ applicationId }) => {
    const [showMore, setShowMore] = useState(false)
    const provider = useSelector((state: RootState) => {
        if (state?.dataProviders?.forApplication[applicationId]?.state === 'assigned-and-ready') {
            return state.dataProviders.forApplication[applicationId] as DataPorviderStateAssignedAndReady
        }
        return null
    })

    const rows = useMemo(() => {
        if (!provider) return undefined

        const arr = Object.keys(provider.computed.ownershipShares.list).map((k) => {
            const o = provider.computed.ownershipShares.list[k]
            return {
                type: 'normal' as const,
                key: o.name,
                noAction: true,
                moreThan10: o.fraction >= 0.1,
                items: [
                    {
                        node: (
                            <Text color={o.fraction < UBO_OWNERSHIP_LOWER_LIMIT ? 'front.text.subtlerI' : 'front.text'}>
                                {o.name}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text color={o.fraction < UBO_OWNERSHIP_LOWER_LIMIT ? 'front.text.subtlerI' : 'front.text'}>
                                {o.formatted}
                            </Text>
                        )
                    }
                ] as any
            }
        })

        if (provider.computed.ownershipShares.missing.status !== 'ok') {
            let label = uppercaseFirstLetter(provider.computed.ownershipShares.missing.status)
            if (provider.computed.ownershipShares.missing.status === 'under-limit') label = 'Below 0%'
            else if (provider.computed.ownershipShares.missing.status === 'over-limit') label = 'Above 100%'
            if (provider.computed.ownershipShares.missing.status === 'fraction')
                arr.push({
                    type: 'normal',
                    key: 'over-limit',
                    noAction: true,
                    moreThan10: true,
                    items: [
                        {
                            node: (
                                <Text color="front.text.subtlerI" italic>
                                    Missing information
                                </Text>
                            )
                        },
                        {
                            node: (
                                <Text color="front.text.subtlerI" italic>
                                    {label}
                                </Text>
                            )
                        }
                    ]
                })
            else
                arr.push({
                    type: 'normal',
                    key: 'over-limit',
                    noAction: true,
                    moreThan10: true,
                    items: [
                        {
                            node: <Text>{label}</Text>,
                            span: 2
                        }
                    ]
                })
        }

        return arr
    }, [provider])

    const form = provider?.linked.company.form

    if (!provider) return <LoaderView overBackground="back.background.strongerI" />

    if (!provider.computed.ownershipShares.list || !Object.keys(provider.computed.ownershipShares.list).length)
        return (
            <>
                <Text color="front.text.subtlerI">Ownership structure could not be found by the data provider.</Text>
                <Spacer height={10} />
            </>
        )

    return (
        <div data-cy="data-provider-ownership-details">
            <Flex justify="space-between" align="baseline">
                <Text color="front.text.subtlerI">Data provider ownership form:</Text>
                <Text>{form}</Text>
            </Flex>
            <InternalDetailsSeparator />
            <Text color="front.text.subtlerI">Data provider ownership structure:</Text>
            <Spacer height={25} />
            {rows && (
                <>
                    <Table
                        overrideText={'No owners with shares over 10%'}
                        background="back.background.strongerI"
                        cols={[
                            {
                                text: 'Name'
                            },
                            {
                                text: 'Shares'
                            }
                        ]}
                        rows={showMore ? rows : rows.filter((_: any) => _.moreThan10)}
                        type="narrow"
                        columnLayout="auto auto"
                    />

                    {rows.some((_: any) => _.moreThan10 === false) && (
                        <Flex align="center" column>
                            <SimpleButton onClick={() => setShowMore((s) => !s)}>
                                <ButtonInset>{showMore ? 'Show less' : 'Show all owners'}</ButtonInset>
                            </SimpleButton>
                        </Flex>
                    )}
                </>
            )}
            <Spacer height={25} />
        </div>
    )
}
