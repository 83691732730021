/* eslint-disable max-len */
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Text } from '../../components/general/text'
import { ModalPage } from '../../components/layout/modalPage'
import { ModalPageInset } from '../../components/layout/modalPageInset'
import { Spacer } from '../../components/layout/spacer'
import { ModalHeader } from '../../components/modals/modalHeader'
import { useMerchantName } from '../../hooks/general/useMerchantName'
import { RootState } from '@/store'
import React, { useEffect, useState } from 'react'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { DataPorviderStateAssignedAndReady } from '../../store/applicationDataProviders/types'
import { MerchantApplicationConflictsDirector } from './MerchantApplicationConflicts.Director'
import { MerchantApplicationConflictsOwnership } from './MerchantApplicationConflicts.Ownership'
import { MerchantApplicationConflictsCompany } from './MerchantApplicationConflicts.Company'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantApplicationConflicts: React.FC = () => {
    const params = useParams() as Params

    const { id } = params || {}
    const providerData = useSelector(
        (state: RootState) => state.dataProviders.forApplication?.[id] as DataPorviderStateAssignedAndReady
    )
    const name = useMerchantName(id, 'Application Conflicts')
    const [showMuted, setShowMuted] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!providerData || providerData.state != 'assigned-and-ready') return
        const conflictsStatus = providerData.computed.conflictsStatus
        if (conflictsStatus == 'no-conflicts')
            navigate(
                {
                    pathname: (location.state as any)?.backTo
                        ? (location.state as any).backTo
                        : `/merchant/${id}/application`,
                    search: location.search
                },
                { replace: true }
            )
    }, [providerData, navigate, location, id])

    if (!providerData || providerData.state != 'assigned-and-ready')
        return (
            <ModalPage
                key="mac-loading-modal-page"
                title={name}
                pageId="Merchant.ApplicationPage.Conflicts"
                backTo={`/merchant/${id}/application`}
                isLoading={true}
            />
        )

    const conflictsStatus = providerData.computed.conflictsStatus

    return (
        <ModalPage
            title={name}
            pageId="Merchant.ApplicationPage.Conflicts"
            backTo={`/merchant/${id}/application`}
            noExitOnBackgroundClick
        >
            <ModalHeader
                pageId="Merchant.ApplicationPage.Conflicts"
                backTo={`/merchant/${id}/application`}
                title={name}
                rightSideMemo={<></>}
            />
            <ModalPageInset center>
                {conflictsStatus === 'has-conflicts-some-muted' || conflictsStatus === 'all-muted' ? (
                    <>
                        {showMuted ? (
                            <ConflictsWarningHolder>
                                All the conflicts are now shown.{' '}
                                <SimpleButton onClick={() => setShowMuted(false)}>
                                    <ButtonInset>Hide muted conflicts</ButtonInset>
                                </SimpleButton>
                            </ConflictsWarningHolder>
                        ) : (
                            <ConflictsWarningHolder>
                                <Text>
                                    Some of the conflicts on this application <Text bold>have been muted.</Text>{' '}
                                </Text>
                                <SimpleButton background="front.accent.color" onClick={() => setShowMuted(true)}>
                                    <ButtonInset>Show muted conflicts</ButtonInset>
                                </SimpleButton>
                            </ConflictsWarningHolder>
                        )}
                    </>
                ) : null}
                {conflictsStatus === 'all-muted' && !showMuted ? (
                    <AllConflictsMuted>All conflicts have been muted</AllConflictsMuted>
                ) : null}
                <Holder className="PREVENT_MODAL_CLOSE">
                    <MerchantApplicationConflictsCompany applicationId={id} showMuted={showMuted} />
                    <Spacer height={10} />
                    <MerchantApplicationConflictsDirector applicationId={id} showMuted={showMuted} />
                    <Spacer height={10} />
                    <MerchantApplicationConflictsOwnership applicationId={id} showMuted={showMuted} />
                </Holder>
            </ModalPageInset>
        </ModalPage>
    )
}

const Holder = styled.div`
    display: flex;
    width: 100%;
    max-width: 925px;
    color: ${(p) => p.theme['front.text']};
    flex-direction: column;
`

const ConflictsWarningHolder = styled.div`
    padding: 20px;
    background-color: ${(p) => p.theme['overlay.background.strongerI']};
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    width: 100%;
    max-width: calc(900px + 30px);
    color: ${(p) => p.theme['front.text']};
    align-items: center;
`

const AllConflictsMuted = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 40px;
    color: ${(p) => p.theme['front.text']};
    width: 100%;
`
