import { createReducer } from 'deox'
/* eslint-disable no-param-reassign */
import { produce } from 'immer'

import { ApplicationInternalsWarningsActions } from './actions'
import { InitialApplicationInternalsWarningsState, MerchantAccountWarningChecks } from './types'
import { ApplicationInternalsActions } from '../actions'

export const ApplicationInternalsWarningsReducer = createReducer(
    InitialApplicationInternalsWarningsState,
    (handleAction) => [
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = JSON.parse(JSON.stringify(InitialApplicationInternalsWarningsState))
                return draft
            })
        }),
        handleAction(ApplicationInternalsWarningsActions.STORE, (state, { payload: p }) => {
            return produce(state, (draft) => {
                if (!draft.forApplication[p.merchantId]) draft.forApplication[p.merchantId] = {} as any
                if (!draft.forApplication[p.merchantId].isReady)
                    draft.forApplication[p.merchantId].isReady = {
                        staleContract: false,
                        all: false,
                        transactionInconsistencies: false
                    }
                draft.forApplication[p.merchantId].each = [
                    ...(draft.forApplication[p.merchantId].each || []),
                    ...p.warnings
                ]
                draft.forApplication[p.merchantId].isReady[p.check] = true

                let check = true
                for (const key of MerchantAccountWarningChecks) {
                    if (draft.forApplication[p.merchantId].isReady[key] !== true) {
                        check = false
                    }
                }
                draft.forApplication[p.merchantId].isReady.all = check
            })
        })
    ]
)
