import { kebabCase } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import styled, { css } from 'styled-components'
import { RootState } from '@/store'
import { MerchantApplicationStructure } from './Application.Structure'
import { Flex } from '../../../components/layout/flex'
import Color from 'color'
import { MerchantApplicationSectionStatusDot } from './Application.SectionStatusDot'
import { useHaveApplicationInternalDetailsLoaded } from './useHaveApplicationInternalDetailsLoaded'
import { MERCHANT_SIDEBAR_WIDTH } from '../MerchantSidebarWidth'

export const ApplicationPersonRoles = ['director', 'owner', 'director-and-owner']
export type ApplicationPersonRole = (typeof ApplicationPersonRoles)[number]

export const shortenRole = (role: ApplicationPersonRole) => {
    switch (role) {
        case 'director':
            return 'D'
        case 'owner':
            return 'O'
        default:
            return 'D&O'
    }
}
export const MerchantApplicationNavigation: React.FC<{ applicationId: string }> = ({ applicationId }) => {
    const [activeSession, setActiveSession] = useState<string | undefined>(undefined)
    const resources = useSelector((state: RootState) => state.applicationResources.data.forApplication[applicationId])
    const initializeAtHash = useRef<string | undefined>(undefined)
    const haveInternalDetailsLoaded = useHaveApplicationInternalDetailsLoaded(applicationId)
    const [shouldAnimate, setShouldAnimate] = useState(false)

    useEffect(() => {
        if (haveInternalDetailsLoaded) setShouldAnimate(true)
    }, [haveInternalDetailsLoaded])

    useEffect(() => {
        const handleHashChange = () => {
            if (window.location.hash === '#loading' && initializeAtHash.current)
                window.location.replace(initializeAtHash.current)
            setActiveSession(window.location.hash.replace('-scrolled', '').replace('#', ''))
        }

        window.addEventListener('hashchange', handleHashChange, false)
        return () => {
            window.removeEventListener('hashchange', handleHashChange)
        }
    }, [])

    useEffect(() => {
        if (haveInternalDetailsLoaded && initializeAtHash.current === undefined && window.location.hash) {
            initializeAtHash.current = window.location.hash.replace('-scrolled', '')
            window.location.replace('#loading')
        }
    }, [haveInternalDetailsLoaded])

    const handleClick = useCallback((e) => {
        window.location.replace('#' + kebabCase(e.currentTarget.getAttribute('data-section')))
    }, [])

    const renderSectionCheck = useCallback(
        (res) => {
            return <MerchantApplicationSectionStatusDot applicationId={applicationId} resource={res} />
        },
        [applicationId]
    )

    const renderLabel = useCallback((section: any) => {
        if (section.label === 'Additional information') return 'Additional Info'
        return section.label
    }, [])

    return (
        <Navigation shouldAnimate={shouldAnimate} key="navigation">
            {MerchantApplicationStructure?.map((section) => {
                if (section.type !== 'dynamic' && section.reviewableResource) {
                    return (
                        <Link
                            key={section.label}
                            onClick={handleClick}
                            data-section={kebabCase(section.label)}
                            isActive={activeSession === kebabCase(section.label)}
                        >
                            <Flex align="center">
                                {renderSectionCheck(section.reviewableResource)}
                                <Label>{renderLabel(section)}</Label>
                            </Flex>
                        </Link>
                    )
                }
                if (section.reviewableResource)
                    return (
                        <Link
                            key={section.reviewableResource.cutterPath}
                            onClick={handleClick}
                            data-section={kebabCase(section.reviewableResource.cutterPath)}
                            isActive={activeSession === kebabCase(section.reviewableResource.cutterPath)}
                        >
                            <Flex align="center">
                                {renderSectionCheck(section.reviewableResource)}
                                <Label>{section.reviewableResource.cutterPath}</Label>
                            </Flex>
                        </Link>
                    )
            })}
        </Navigation>
    )
}

const Label = styled.div`
    min-height: 25px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
`

const Navigation = styled.div<{ shouldAnimate?: boolean }>`
    padding: 0;
    position: sticky;
    top: 100px;
    flex-shrink: 0;
    flex-grow: 0;
    display: grid;
    left: ${MERCHANT_SIDEBAR_WIDTH}px;
    grid-template-columns: 1fr;
    grid-row-gap: 2px;
    margin-right: 20px;
    flex-direction: column;
    margin-left: 20px;
`

const Link = styled.div<{ isActive?: boolean }>`
    padding: 0px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(p) => p.theme['back.text']};

    &:hover {
        color: ${(p) => p.theme['back.text.subtlerI']};
    }

    &:active {
        color: ${(p) => p.theme['back.text.strongerI']};
    }

    &:first-child {
        margin-top: -5px;
    }

    ${(p) =>
        p.isActive &&
        css`
            color: ${(p) => p.theme['back.text.strongerIII']};
            font-weight: 500;
            cursor: default;

            &:hover {
                color: ${(p) => p.theme['back.text.strongerIII']};
            }

            &:active {
                color: ${(p) => p.theme['back.text.strongerIII']};
            }
        `}
    &:last-child {
        border-bottom: none;
    }
`
