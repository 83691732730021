import { uppercaseFirstLetter } from '@/utils'

export type OrderedItems = Record<string, string | boolean | object>
export type OrderedOrder = { key: string; title?: string }[]

export const getOrderedItems = (items: OrderedItems, order: OrderedOrder) => {
    return order.map((item) => {
        const key = item.key
        const title = item.title ? item.title : uppercaseFirstLetter(item.key.replace('_', ' '))

        let value = '-'
        if (typeof items[item.key] === 'boolean') {
            value = items[item.key] ? 'Yes' : 'No'
        } else if (items[item.key]) {
            value = items[item.key].toString()
        }

        return { key, value, title }
    })
}
