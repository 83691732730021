import styled, { css } from 'styled-components'
import { Offset } from '../layout/offset'
import { Icon } from './icon'

export const CircleCheck: React.FC<{
    selectable?: boolean
    checked?: boolean
    isDanger?: boolean
    isSuccess?: boolean
    larger?: boolean
}> = ({ selectable, checked, isDanger, isSuccess, larger }) => {
    return (
        <Circle selectable={selectable} checked={checked} isDanger={isDanger} isSuccess={isSuccess} larger={larger}>
            <Offset top={-1.5}>{checked ? <Icon type="checkmark" size={8} weight={1.5} /> : null}</Offset>
        </Circle>
    )
}
const Circle = styled.div<{
    checked?: boolean
    selectable?: boolean
    isDanger?: boolean
    isSuccess?: boolean
    larger?: boolean
}>`
    width: 13px;
    height: 13px;

    background-color: transparent;
    border-radius: 999px;
    margin-right: 5px;
    border: 1px solid ${(p) => p.theme['front.border']};
    color: ${(p) => p.theme['front.accent.text']};
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) =>
        p.selectable &&
        css`
            border: 1px solid ${p.isDanger ? p.theme['front.danger.color'] : p.theme['front.accent.color']};
        `}

    ${(p) =>
        p.checked &&
        css`
            border: 1px solid
                ${p.isDanger
                    ? p.theme['front.danger.color']
                    : p.isSuccess
                      ? p.theme['front.success.color']
                      : p.theme['front.accent.color']};
            background-color: ${p.isDanger
                ? p.theme['front.danger.color']
                : p.isSuccess
                  ? p.theme['front.success.color']
                  : p.theme['front.accent.color']};
        `}

    ${(p) =>
        p.larger &&
        css`
            width: 16px;
            height: 16px;

            ${!p.checked &&
            css`
                &:hover {
                    background-color: ${p.theme['front.subtleAccent.background']};
                }
            `}
        `}
`
