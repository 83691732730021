import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@/store'

export const useListPageMerchantFilter = (
    key: string,
    forMerchant?: string,
    returnIDsInstead?: boolean
): [{ [key: string]: string[] | undefined } | undefined, boolean | undefined] => {
    const midsCache = useRef<{ [key: string]: string[] | undefined }>({ [key]: undefined })

    const selector = useSelector((state: RootState) => {
        if (!forMerchant) return undefined
        let everythingLoaded = false
        if (state.merchantAccounts.forApplication?.[forMerchant]?.loadingStatus === 'done') {
            everythingLoaded = true
            state.merchantAccounts.forApplication?.[forMerchant].all.forEach((id) => {
                if (state.merchantAccounts.at[id]?.loadingStatus !== 'done') everythingLoaded = false
            })
        }

        if (!everythingLoaded)
            return {
                finishedLoading: false,
                mids: undefined
            }
        return {
            finishedLoading: true,
            mids: state.merchantAccounts.forApplication[forMerchant]?.all.map((id) =>
                returnIDsInstead ? state.merchantAccounts.at[id].id : state.merchantAccounts.at[id].merchantId
            )
        }
    })

    const MIDsCache: { [key: string]: string[] | undefined } | undefined = useMemo(() => {
        if (!forMerchant) return undefined
        if (!selector?.mids?.length) return midsCache.current
        if (!selector?.finishedLoading) return midsCache.current
        if (JSON.stringify(selector.mids) !== JSON.stringify(midsCache.current[key])) {
            midsCache.current = {
                [key]: selector.mids?.length ? selector.mids : undefined
            }
        }
        return midsCache.current
    }, [forMerchant, key, selector])

    return [MIDsCache, selector?.finishedLoading]
}
