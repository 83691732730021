import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Flex } from '../../components/layout/flex'
import { LoaderView } from '../../components/loaders/loader'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { Spacer } from '../../components/layout/spacer'
import { TextInputAgentSelector } from '../../components/forms/textInputAgentSelector'
import { TimelineMessageBox } from '../../components/listPages/timelineMessageBox'
import { MerchantDetailsSidebar } from '../../components/navigation/merchantDetailsSidebar'
import { ModalHeader } from '../../components/modals/modalHeader'
import { PrintMerchantDetails } from '../../components/taskPages/printMerchantDetails'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { RootState } from '@/store'
import {
    TaskDispatchAssignAgent,
    TaskDispatchClose,
    TaskDispatchLinkFiles,
    TaskDispatchOpen,
    TaskDispatchSendComment,
    TaskDispatchUnlinkFiles
} from '../../store/tasks/actions'
import { WatcherID } from '../../store/watcher/types'
import { uppercaseFirstLetter } from '../../utils'
import { TagsLister } from '../Merchant/MerchantTagsLister'
import { AlertActions } from './Alerts.IDHeaderActions'
import { AlertModalTimeline } from './Alerts.IDTimeline'
import { AlertModalType } from './types'
import { MessageBoxAction } from '../../components/forms/messageBox'
import { useGoBackHook } from '../../hooks/general/useGoBackHook'
import { TasksMonitoring } from '../Tasks/components/Tasks.Monitoring/Tasks.Monitoring'
import { useParams } from 'react-router-dom'

export const AlertModal: React.FC<{
    type: AlertModalType
    backTo?: string
}> = ({ type, backTo: inheritedBackTo }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const taskId = params.id
    const storedTask = useSelector((state: RootState) => {
        if (taskId) {
            return state.tasks.tasks.at[taskId]
        }
    })

    const { task, isLoading, merchant } = useMemo(() => {
        let merchant: any
        if (storedTask?.account && storedTask?.application) {
            merchant = {
                account: storedTask.account,
                application: storedTask.application
            }
        }
        if (storedTask?.timeline?.loadingStatus !== 'done') return { isLoading: true, merchant }
        if (storedTask?.loadingStatus !== 'done') return { isLoading: true, merchant }
        return {
            isLoading: false,
            merchant,
            task: storedTask
        }
    }, [storedTask])

    const [taskStatusWatcher] = useNamedWatcher(`AlertPage.ChangeAlertStatus`)

    const title = useMemo(() => {
        const t = type === 'alert' ? `Alert` : 'Task'
        if (merchant?.account?.data?.name && task?.title)
            return `${t} • ${merchant.account.data.name}${task?.title ? `, ${task.title}` : ''}`
        if (task?.type == 'company-change') {
            return `${task.title.split('`')[1]} · Company change task`
        }
        return `Loading…`
    }, [type, merchant, task])
    useModalStackSync(title, 'AlertPage', 0)

    const backTo = useMemo(() => {
        if (inheritedBackTo) return inheritedBackTo
        if (task?.type === 'odd-reporting') {
            return '/odd-tasks'
        }
        if (type === 'task') {
            return '/tasks'
        }

        return '/alerts'
    }, [task, type, inheritedBackTo])

    const { goBack } = useGoBackHook(backTo)

    useEffect(() => {
        if (taskStatusWatcher === 'success') {
            goBack()
        }
    }, [taskStatusWatcher, dispatch, goBack])

    const renderedRightSide = useMemo(() => {
        if (!task) return null
        return <AlertActions task={task} />
    }, [task])

    const fileHandlingMemo = useMemo(() => {
        if (!task) return undefined
        return {
            uploadLink: `tasks.tasks.at.${task.id}.filesLink`,
            linkFiles: (files: any) => TaskDispatchLinkFiles(task.id, files),
            unlinkFiles: (files: any) => TaskDispatchUnlinkFiles(task.id, files),
            scope: 'private' as const,
            withDocumentPrefix: true
        }
    }, [task])

    const handleAgentSelection = useCallback(
        (agent: string, watcherId: WatcherID) => {
            if (!task?.selfLink) return
            dispatch(TaskDispatchAssignAgent(watcherId, task.selfLink, agent))
        },
        [dispatch, task]
    )

    const otherButtons = useMemo(() => {
        if (!task) return undefined
        return (
            <>
                <TextInputAgentSelector
                    onSelect={handleAgentSelection}
                    noShortcut
                    overBackground="front.background"
                    selectedAgent={task.assignee}
                    pageId="AlertPage"
                />
                <Spacer width={10} />
            </>
        )
    }, [task, handleAgentSelection])

    const mainActions = useMemo(() => {
        if (!task) return []

        const mainAction = (watcher: WatcherID, value: string) => {
            dispatch(TaskDispatchSendComment(watcher, task.commentsLink!, task.id, value))
        }

        const buttons: { [key: string]: MessageBoxAction } = {
            comment: {
                label: 'Comment',
                action: (watcher: WatcherID, value: string | undefined, resetField: () => void) => {
                    if (!value) return
                    mainAction(watcher, value)
                    resetField()
                }
            },
            commentAndOpen: {
                label: 'Comment and Open',
                action: (watcher: WatcherID, value: string | undefined, resetField: () => void) => {
                    if (!value) return
                    mainAction(watcher, value)
                    dispatch(TaskDispatchOpen(`AlertPage.ChangeAlertStatus`, task.selfLink!))
                    resetField()
                }
            },
            commentAndClose: {
                label: 'Comment and Close',
                action: (watcher: WatcherID, value: string | undefined, resetField: () => void) => {
                    if (!value) return
                    mainAction(watcher, value)
                    dispatch(TaskDispatchClose(`AlertPage.ChangeAlertStatus`, task.selfLink!))
                    resetField()
                }
            },
            openWithoutComment: {
                label: 'Open',
                action: (watcher: WatcherID) => {
                    dispatch(TaskDispatchOpen(`AlertPage.ChangeAlertStatus`, task.selfLink!))
                }
            },
            closeWithoutComment: {
                label: 'Close',
                action: (watcher: WatcherID) => {
                    dispatch(TaskDispatchClose(`AlertPage.ChangeAlertStatus`, task.selfLink!))
                }
            }
        }

        if (task.state === 'closed') return [buttons.comment, buttons.commentAndOpen, buttons.openWithoutComment]
        if (task.state === 'open') return [buttons.commentAndClose, buttons.comment, buttons.closeWithoutComment]
        return [
            buttons.commentAndClose,
            buttons.commentAndOpen,
            buttons.comment,
            buttons.openWithoutComment,
            buttons.closeWithoutComment
        ]
    }, [task, dispatch])

    const pageContent = useMemo(() => {
        if (!task || isLoading) return <LoaderView overBackground="back.background" key="timelineLoader" />
        return (
            <>
                <PrintMerchantDetails
                    merchantId={task?.account?.data?.id}
                    account={task?.account?.data}
                    application={task?.application}
                />
                <ModalHeader pageId="AlertPage" onBack={goBack}>
                    <PageWrapper maxWidth="larger">
                        <PageHeader
                            insetLeft
                            title={type === 'alert' ? 'Alert' : 'Task'}
                            subtitle={
                                <Flex>
                                    <Spacer width={5} />
                                    <span data-cy="alert-state">{uppercaseFirstLetter(task.state)}</span>
                                    <Spacer width={12} />
                                    <TagsLister overBackground="front.background" tags={task.tags} limit={1} />
                                </Flex>
                            }
                            rightSideMemo={renderedRightSide}
                            noBottomBorder
                        />
                    </PageWrapper>
                </ModalHeader>
                <PageWrapper maxWidth="larger">
                    {task.type == 'company-change' && <TasksMonitoring task={task} />}
                    <AlertModalTimeline task={task} type={type} />
                    <TimelineMessageBox
                        placeholder="Leave a comment"
                        fileHandlingMemo={fileHandlingMemo}
                        pageId="AlertPage"
                        actions={mainActions}
                        otherButtons={otherButtons}
                    />
                </PageWrapper>
            </>
        )
    }, [task, isLoading, backTo, type, renderedRightSide, fileHandlingMemo, mainActions, otherButtons])

    return (
        <Flex align="stretch">
            <PageContent marginLeft={0} noPadding>
                {pageContent}
            </PageContent>
            {isLoading || task?.type == 'company-change' ? null : (
                <MerchantDetailsSidebar
                    isLoading={!task || isLoading}
                    merchantId={task?.account?.data?.id}
                    account={task?.account?.data}
                    application={task?.application}
                />
            )}
        </Flex>
    )
}
