import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CenteredScreen } from '../../../components/layout/centeredScreen'

import { Spacer } from '../../../components/layout/spacer'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { useModalStackSync } from '../../../hooks/general/useModalStackSync'
import { useResetScrollOnMount } from '../../../hooks/pages/useResetScrollOnMount'
import { RootState } from '@/store'
import { MerchantSummaryAccountsCard } from './SummaryAccountsCard'
import { MerchantSummaryApplicationCard } from './SummaryApplicationCard'
import { MerchantSummaryCompanyInformation } from './SummaryCompanyInformation'
import { useParams } from 'react-router-dom'

type Params = {
    id: string
}

export const MerchantSummary: React.FC = () => {
    const params = useParams() as Params
    const name = useMerchantName(params.id, 'Summary')
    useModalStackSync(name, 'Merchant.Summary', 0)
    useResetScrollOnMount()
    const merchantId = params.id
    const state = useSelector((state: RootState) => state.applications.applications.at[merchantId]?.metadata?.state)

    // TO-DO related applications

    return (
        <CenteredScreen>
            <ContentGrid>
                <SpanTwo>
                    <MerchantSummaryCompanyInformation merchantId={merchantId} />
                    <Spacer height={20} />
                    {state == 'accepted' ? (
                        <MerchantSummaryAccountsCard merchantId={merchantId} />
                    ) : (
                        <MerchantSummaryApplicationCard merchantId={merchantId} />
                    )}
                    <Spacer height={20} />
                    {state == 'accepted' ? (
                        <MerchantSummaryApplicationCard merchantId={merchantId} />
                    ) : (
                        <MerchantSummaryAccountsCard merchantId={merchantId} />
                    )}
                </SpanTwo>
            </ContentGrid>
            <Spacer height={80} />
        </CenteredScreen>
    )
}

const SpanTwo = styled.div`
    grid-column: span 2;
`

const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(600px, 600px) minmax(410px, 410px);
    grid-column-gap: 20px;

    @media (min-width: 1700px) {
        grid-template-columns: minmax(800px, 800px) minmax(410px, 410px);
    }
    @media (max-width: 1360px) {
        grid-template-columns: minmax(400px, 400px) minmax(410px, 410px);
    }
`

const Left = styled.div`
    display: flex;
    flex-direction: column;
`

const Right = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
