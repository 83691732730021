import hotkeys from 'hotkeys-js'
import { debounce } from 'lodash'
import queryString from 'query-string'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { InterfaceActions } from '../../store/interface/actions'
import { RootState } from '@/store'
import { isMacOS } from '../../utils'
import { useLocation, useNavigate } from 'react-router-dom'

let searchQueryPreceedingSearch = ''

const globalKeysCache: any = {}

// CTRL on Mac, ALT on Windows
export const BoundKey = isMacOS ? 17 : 18
export const InterruptKeys = [isMacOS ? 18 : 17, 16]
export const HotkeysObserver: React.FC = () => {
    const dispatch = useDispatch()
    const activeModalScope = useSelector((state: RootState) => state.interface.modals.frontmost)
    const location = useLocation()
    const navigate = useNavigate()
    const showKeysInterval = useRef<any>(null)

    const bindGlobalKeys = useCallback(
        (scope: string) => {
            if (globalKeysCache[scope]) return

            globalKeysCache[scope] = {
                search: debounce(
                    (e: any) => {
                        e.preventDefault()
                        if (e.repeat) return false
                        if (scope === 'SearchPage') {
                            navigate(
                                {
                                    search: searchQueryPreceedingSearch
                                },
                                { replace: true }
                            )
                        } else {
                            const qs: any = queryString.parse(location.search)
                            searchQueryPreceedingSearch = queryString.stringify(qs)
                            qs['show-search'] = 'yes'
                            navigate(
                                {
                                    search: queryString.stringify(qs)
                                },
                                { replace: true }
                            )
                        }
                    },
                    400,
                    { leading: true, trailing: false }
                ),
                toggleTheme: debounce(
                    (e: any) => {
                        dispatch(InterfaceActions.TOGGLE_THEME())
                    },
                    50,
                    { leading: true, trailing: false }
                )
            }

            hotkeys('s', scope, globalKeysCache[scope].search)
            if (isMacOS) {
                hotkeys('ctrl+s', scope, globalKeysCache[scope].search)
                hotkeys('ctrl+/', scope, globalKeysCache[scope].toggleTheme)
            } else {
                hotkeys('alt+s', scope, globalKeysCache[scope].search)
                hotkeys('alt+/', scope, globalKeysCache[scope].toggleTheme)
            }
        },
        [navigate, dispatch, location.search]
    )

    const showHotkeys = useCallback(() => {
        if (!showKeysInterval.current) {
            dispatch(InterfaceActions.SHOW_KEYTIPS())

            showKeysInterval.current = setInterval(() => {
                const pK = hotkeys.getPressedKeyCodes()
                const includesOtherKeys = pK.filter((k) => InterruptKeys.includes(k)).length > 0 ? true : false
                if (!pK.includes(BoundKey) || includesOtherKeys) {
                    if (showKeysInterval.current) {
                        dispatch(InterfaceActions.HIDE_KEYTIPS())
                        clearInterval(showKeysInterval.current)
                        showKeysInterval.current = null
                    }
                }
            }, 100)

            const previous = showKeysInterval.current
            return () => {
                if (previous) {
                    dispatch(InterfaceActions.HIDE_KEYTIPS())
                    clearInterval(previous)
                    showKeysInterval.current = null
                }
            }
        }
    }, [dispatch])

    useEffect(() => {
        const ensureHotkeysTipsNotVisible = () => {
            if (showKeysInterval.current) {
                dispatch(InterfaceActions.HIDE_KEYTIPS())
                clearInterval(showKeysInterval.current)
                showKeysInterval.current = null
            }
        }
        document.addEventListener('visibilitychange', ensureHotkeysTipsNotVisible)
        return () => {
            document.removeEventListener('visibilitychange', ensureHotkeysTipsNotVisible)
        }
    }, [dispatch])

    const bindAltKeyBehavior = useCallback(
        (scope: string) => {
            hotkeys(
                '*',
                {
                    scope,
                    keyup: false,
                    keydown: true
                },
                () => {
                    const pK = hotkeys.getPressedKeyCodes()
                    const includesOtherKeys = pK.filter((k) => InterruptKeys.includes(k)).length > 0 ? true : false
                    if (pK.includes(BoundKey) && !includesOtherKeys) {
                        showHotkeys()
                    }
                }
            )
        },
        [showHotkeys]
    )

    useEffect(() => {
        if (activeModalScope) {
            hotkeys.setScope(activeModalScope)
            bindAltKeyBehavior(activeModalScope)
            bindGlobalKeys(activeModalScope)
        } else {
            hotkeys.setScope('all')
        }
    }, [activeModalScope, bindAltKeyBehavior, bindGlobalKeys, dispatch])

    return <></>
}
