import { ReactNode } from 'react'
import styled from 'styled-components'

type FloaterInsetType = 'small' | 'medium' | 'tiny'
export const FloaterInset: React.FC<{
    children: ReactNode
    equalPadding?: boolean
    padding?: FloaterInsetType
}> = ({ children, equalPadding, padding = 'small' }) => {
    return (
        <Insets padding={padding} equalPadding={equalPadding}>
            {children}
        </Insets>
    )
}

const Insets = styled.div<{
    padding: FloaterInsetType
    equalPadding?: boolean
}>`
    padding: ${(p) => {
        if (p.padding === 'medium') {
            if (p.equalPadding) return '25px 25px'
            return '15px 25px'
        }
        if (p.padding === 'tiny') {
            if (p.equalPadding) return '5px 5px'
            return '5px 10px'
        }
        if (p.equalPadding) return '15px 15px'
        return '10px 20px'
    }};
    display: flex;
    flex-direction: column;
    align-items: stretch;
`
