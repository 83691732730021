import { LoaderView } from '../../../components/loaders/loader'
import { PageContent } from '../../../components/layout/pageContent'
import { useListPageMerchantFilter } from '../../../hooks/pages/useListPageMerchantFilter'
import { Disputes } from '../../Disputes/Disputes'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantDisputes: React.FC = () => {
    const params = useParams() as Params

    const merchantId = params.id

    const [mids, midsLoadingFinished] = useListPageMerchantFilter('disputes_mid', merchantId)

    if (!midsLoadingFinished) return <LoaderView overBackground="back.background" />

    return (
        <PageContent className="POPPER_PARENT">
            <Disputes inline forMids={mids} />
        </PageContent>
    )
}
