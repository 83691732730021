import { useFormContext } from 'react-hook-form'

export const useChecksStatus = () => {
    const { watch } = useFormContext()

    const watchFields: string[] = watch([
        'company_payment_accounts',
        'company_fundamental_changes',
        'trustpilot_satisfying',
        'google_negative_info_owners',
        'google_negative_info_company_url',
        'sanction_lists_owners_listed',
        'website_satisfying_business_model',
        'website_available_contact_info',
        'website_available_refund_policy',
        'website_available_terms_and_condition',
        'website_credible_capture_pattern',
        'website_credible_refund_credit_pattern',
        'website_likely_chargeback_pattern',
        'website_average_expected_transaction_size',
        'risk_classification'
    ])

    return watchFields.filter((check) => check === 'Yes' || check === 'No').length
}
