import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { CardStyles } from '../../components/cards/card'
import { LoaderView } from '../../components/loaders/loader'
import { CardInset } from '../../components/cards/cardInset'
import { List } from '../../components/layout/list'
import { PageHeader } from '../../components/layout/pageHeader'
import { CallStatus } from '../../components/general/callStatus'
import { LinkButton } from '../../components/buttons/linkButton'
import { useListPage } from '../../hooks/pages/useListPage'
import { CallFiltersParams, CallsFilters } from '../../store/calls/types'
import { RootState } from '@/store'
import { CallsDispatchLoadSummaries, CallsDispatchClearSummaries } from '../../store/calls/actions'
import { useLocation } from 'react-router-dom'

const loadSummaries = (filters: CallsFilters) => CallsDispatchLoadSummaries(filters)
const clearSummaries = () => CallsDispatchClearSummaries()

export const ActiveCalls: React.FC = () => {
    const location = useLocation()

    const template = '80px 200px auto'
    const summaries = useSelector((state: RootState) => {
        return state.calls.callsSummaries
    })
    const { filters, paginationNode } = useListPage<CallsFilters>(
        'CallsList',
        'Calls',
        loadSummaries,
        CallFiltersParams,
        summaries,
        '/calls/',
        location,
        { status: 'active' },
        undefined,
        undefined,
        undefined,
        clearSummaries
    )

    const queuesLink = useMemo(() => {
        return <LinkButton to={`/calls/queues`}>See overview of local numbers</LinkButton>
    }, [])

    const content = useMemo(() => {
        if (summaries.loadingStatus === 'started') {
            return <LoaderView type="l" overBackground="overlay.background" />
        }
        if (summaries.loadingStatus === 'done') {
            if (summaries.all.length === 0)
                return <HelpText>There are no incoming or active calls at the moment.</HelpText>
            return summaries.all.map((id: string) => {
                const t = summaries.at[id]
                let status = 'incoming'
                if (t.pickedTime !== 'N/A' && t.endTime === 'N/A') status = 'answered'
                if (t.endTime !== 'N/A') status = 'inactive'
                const items: any = {
                    Phone: {
                        type: 'custom',
                        node: <b>{t.phoneNumber}</b>
                    },
                    Status: {
                        type: 'custom',
                        node: <CallStatus status={status} />
                    },
                    Action: {
                        type: 'custom',
                        node: <LinkButton to={`/calls/${t.phoneNumber}`}>View accounts and applications</LinkButton>
                    }
                }
                return (
                    <CallCard key={t.internalCallId}>
                        <CardInset isForHeader={false}>
                            <RightSide>
                                <List
                                    background="front.background"
                                    items={items}
                                    switchToRowsAt={400}
                                    template={template}
                                />
                            </RightSide>
                        </CardInset>
                    </CallCard>
                )
            })
        }
    }, [summaries])

    return (
        <>
            <PageHeader noBottomBorder title="Active calls" subtitle="" rightSideMemo={queuesLink} />
            {content}
        </>
    )
}

const CallCard = styled.div`
    position: relative;
    ${CardStyles.higher};
    margin-bottom: 10px;
    color: ${(p) => p.theme['front.text']};
    background-color: ${(p) => p.theme['front.background']};
`

const RightSide = styled.div`
    div:nth-child(3) {
        margin-left: auto;
    }
`

const HelpText = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    color: ${(p) => p.theme['overlay.text']};
`
