import React, { ReactElement, useCallback, useMemo } from 'react'

import { TransactionsFilters } from '../../store/transactions/types'
import { TextInputSelectMultiple } from '../forms/textInputSelectMultiple'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

const options = [
    { label: '20000 · Approved', value: 'approved' },
    { label: '40XXX · All Declined', value: 'declined' },
    { label: '40000 · General input error', value: '40000' },
    { label: '40110 · Invalid card number', value: '40110' },
    { label: '40111 · Unsupported card scheme', value: '40111' },
    { label: '40120 · Invalid CSC', value: '40120' },
    { label: '40130 · Invalid expire date', value: '40130' },
    { label: '40135 · Card expired', value: '40135' },
    { label: '40140 · Invalid currency', value: '40140' },
    { label: '40150 · Invalid text on statement', value: '40150' },
    { label: '40190 · Invalid transaction', value: '40190' },
    { label: '40200 · Clearhaus rule violation', value: '40200' },
    { label: '40300 · 3-D Secure problem', value: '40300' },
    { label: '40310 · 3-D Secure authentication failure', value: '40310' },
    { label: '40400 · Backend problem', value: '40400' },
    { label: '40410 · Declined by issuer or card scheme', value: '40410' },
    { label: '40411 · Card restricted', value: '40411' },
    { label: '40412 · Card lost or stolen', value: '40412' },
    { label: '40413 · Insufficient funds', value: '40413' },
    { label: '40414 · Suspected fraud', value: '40414' },
    { label: '40415 · Amount limit exceeded', value: '40415' },
    { label: '40416 · Additional authentication required', value: '40416' },
    { label: '40420 · Merchant blocked by cardholder', value: '40420' },
    { label: '50000 · Clearhaus error', value: '50000' }
].reduce((acc, i) => {
    acc[i.value] = i.label
    return acc
}, {} as any)

export const ListPageFilterTransactionsStatus = ({
    filters,
    isDark
}: {
    filters: FiltersController<TransactionsFilters>
    isDark: boolean
}): ReactElement => {
    const textForItem = useCallback((key) => {
        return options[key]
    }, [])

    const onSelect = useCallback(
        (items) => {
            filters.set((prevState) => ({
                ...prevState,
                transactions_status: items
            }))
        },
        [filters]
    )

    const itemsList = useMemo(() => {
        return Object.keys(options)
            .sort((a, b) => {
                if (a === 'approved') return -1
                if (b === 'approved') return 1
                if (a === 'declined') return -1
                return 0
            })
            .map((v) => v)
    }, [])

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelectMultiple
                preSelectedItems={filters.get.transactions_status}
                isHigher={isDark}
                isSeamless
                cy="status-filter"
                textForItem={textForItem}
                dropdownId="ListPage.Filters.TransactionStatus"
                onSelect={onSelect}
                placeholder="-"
                items={itemsList}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                selectionLabel="transaction type/transaction types"
            />
            <SelectedListForListPageFilters<TransactionsFilters>
                filterKey="transactions_status"
                filters={filters}
                isDark={isDark}
                noHolderEmphasize={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
