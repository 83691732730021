export const IconExternalLink: React.FC<{
    size?: number
    strokeWidth?: number
}> = ({ strokeWidth = 1, size = 11 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 14 14">
            <path
                // eslint-disable-next-line max-len
                d="M8.5 5.5L5 9M8.5 5.5H5M8.5 5.5V9M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
                fill="transparent"
                stroke="currentColor"
                strokeWidth={strokeWidth / (size / 14)}
            />
        </svg>
    )
}
