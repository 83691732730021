import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'

export const WebsitesDataItems: React.FC = () => {
    const merchantData = useReportMerchantData()

    const websites = merchantData.websites

    if (!websites || websites.length === 0) {
        return null
    }

    return (
        <>
            {websites.map((website, i) => {
                const last = i === websites.length - 1

                const title = `${i + 1}. Website`
                const url = website.url ? website.url : '-'
                const comment = website.comment ? website.comment : '-'

                return (
                    <React.Fragment key={website.id}>
                        <Section.IndentetContent>
                            <Section.Title color="normal">{title}</Section.Title>
                        </Section.IndentetContent>
                        <Section.Spacer size="s" />
                        <Section.DataItem title="Url" value={url} />
                        <Section.Spacer size="s" />
                        <Section.ExpandableDataItem title="Comments" value={comment} />
                        {!last && <Section.Spacer />}
                    </React.Fragment>
                )
            })}
        </>
    )
}
