import moment, { Moment } from 'moment'
import { useMemo, useState } from 'react'
import { MerchantAccountContract, MerchantAccountWithContract } from '../../../store/merchantAccounts/types'
import { DateFormats } from '../../../utils/dateUtils'

export const useContractHistoryAnalyzer = (
    contractsHistory: MerchantAccountWithContract['contractHistory']
): {
    effectivePeriods: {
        [key: string]: {
            from: string
            to: string
        }
    }
    effectiveContract: undefined | MerchantAccountContract
} => {
    const [effectiveContract, setEffectiveContract] = useState<undefined | MerchantAccountContract>()

    const effectivePeriods = useMemo(() => {
        let previousPeriod: null | Moment = null
        const acc: any = {}

        if (!contractsHistory) return {}

        Object.keys(contractsHistory)
            .sort((aId, bId) => {
                const a = contractsHistory[aId]
                const b = contractsHistory[bId]
                return moment(a.metadata?.effectiveAt).isBefore(b.metadata?.effectiveAt) ? 1 : -1
            })
            .forEach((key, i) => {
                const c = contractsHistory[key]
                if (!c.metadata?.effectiveAt) return
                const effectiveAt = moment(c.metadata?.effectiveAt)

                if (c?.metadata?.state === 'signed') {
                    setEffectiveContract((eC) => {
                        if (!eC) return c
                        return eC
                    })
                }

                if (effectiveAt) {
                    setEffectiveContract((eC) => {
                        if (!eC) return c
                        return eC
                    })
                }

                acc[c.id] = {
                    from: effectiveAt.format(DateFormats.day(effectiveAt)),
                    to: previousPeriod ? previousPeriod.format(DateFormats.day(previousPeriod)) : 'Present'
                }

                previousPeriod = effectiveAt
            })
        return acc
    }, [contractsHistory])

    return { effectivePeriods, effectiveContract }
}
