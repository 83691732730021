import React, { createRef, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useSelectable } from '../../hooks/general/useSelectable'
import { MODAL_ID } from '../modals/modalIds'
import { DropdownListItem } from './dropdownListItem'
import { DropdownListNotFoundItem } from './dropdownListNotFoundItem'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { kebabCase } from 'lodash'

export interface TableLike {
    headers?: string[]
    layout?: string
    labelForItem: (item: any) => string
    fixedHeader?: React.ReactNode
}

export const DropdownSelectableList: React.FC<{
    items: string[]
    filteringValue?: string
    dropdownId: MODAL_ID
    isHigher?: boolean
    textForItem: (item: string, shouldExpand?: boolean) => undefined | string
    selected?: string
    onBlankEnter?: (value?: string) => void
    noBullets?: boolean
    onSelect: (item: string) => void
    shouldFilter?: boolean
    tableLike?: TableLike
}> = ({
    items,
    filteringValue,
    onSelect,
    isHigher,
    textForItem,
    dropdownId,
    shouldFilter,
    onBlankEnter,
    tableLike,
    selected,
    noBullets
}) => {
    const filteredItems = useMemo(() => {
        if (!filteringValue) return items || []

        if (shouldFilter)
            return items.filter((i) => textForItem(i)?.toLowerCase().includes(filteringValue.toLowerCase()))
        return items || []
    }, [filteringValue, items, shouldFilter, textForItem])

    useModalStackSync(null, dropdownId, undefined)
    const onSelectionMade = useCallback(
        (item: string) => {
            onSelect(item)
        },
        [onSelect]
    )

    const itemRefs = useMemo(() => {
        return filteredItems?.map(() => createRef())
    }, [filteredItems]) as any

    const onSelectionChange = useCallback(
        (index) => {
            itemRefs?.[index]?.current?.scrollIntoView?.({
                block: 'center',
                inline: 'center'
            })
        },
        [itemRefs]
    )

    const { onMouseClick, onMouseOut, highlighted } = useSelectable(
        filteredItems,
        dropdownId,
        onSelectionMade,
        onBlankEnter,
        onSelectionChange,
        filteringValue
    )

    const activeBullet = useMemo(() => {
        return (
            <Flex align="center" justify="center">
                <Circle className="circle" key="active">
                    <ActiveBullet isActive />
                </Circle>
            </Flex>
        )
    }, [])

    const inactiveBullet = useMemo(() => {
        return (
            <Flex align="center" justify="center">
                <Circle className="circle" key="inactive">
                    <ActiveBullet isActive={false} />
                </Circle>
            </Flex>
        )
    }, [])

    const renderBullet = useCallback(
        (i, selected) => {
            if (noBullets) return null
            if (i === selected) return activeBullet
            return inactiveBullet
        },
        [noBullets, activeBullet, inactiveBullet]
    )

    return (
        <Container tableLike={tableLike}>
            {tableLike?.fixedHeader ? <FixedHeader isHigher={isHigher}>{tableLike.fixedHeader}</FixedHeader> : null}
            {tableLike?.headers?.map((t) => (
                <HeaderCell key={t} isHigher={isHigher} hasFixedHeader={!!tableLike.fixedHeader}>
                    {t}
                    <Spacer width={10} />{' '}
                </HeaderCell>
            ))}
            <ListHolder tableLike={tableLike}>
                {tableLike?.headers?.length || tableLike?.fixedHeader ? <SpacingRow /> : null}
                {filteredItems.length > 0 ? (
                    filteredItems.map((i, index) => {
                        return (
                            <DropdownListItem
                                isHigher={isHigher}
                                data-cy={`dropdown-item ${
                                    i === selected ? 'dropdown-item-selected' : 'dropdown-item-selectable'
                                } ${index === highlighted ? 'dropdown-item-highlighted' : ''} dropdown-item-${kebabCase(
                                    textForItem(i, true)
                                )}`}
                                ref={itemRefs[index]}
                                key={i || textForItem(i) || index}
                                isHovered={index === highlighted}
                                isActive={i === selected}
                                // onMouseMove={onMouseIn}
                                data-list-index={index}
                                onMouseLeave={onMouseOut}
                                onClick={onMouseClick}
                                tableLike={tableLike}
                            >
                                {renderBullet(i, selected)}
                                {tableLike ? textForItem(i, true) : <Text oneLine>{textForItem(i, true)}</Text>}
                            </DropdownListItem>
                        )
                    })
                ) : (
                    <DropdownListNotFoundItem key="notfound" span={tableLike?.headers?.length || undefined} />
                )}
            </ListHolder>
        </Container>
    )
}

const SpacingRow = styled.div`
    grid-column: 1 / span 5;
    height: 20px;
`

const Circle = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-right: 10px;
    width: 11px;
    position: relative;
    height: 11px;
    border-radius: 999px;
    z-index: -1;
`

const ActiveBullet = styled.div<{ isActive?: boolean }>`
    position: absolute;
    width: 7px;
    top: 2px;
    border-radius: 9999px;
    height: 7px;
    left: 2px;
    box-sizing: border-box;
    opacity: 0;
    background-color: #fff;

    ${(p) =>
        p.isActive &&
        css`
            opacity: 1;
        `}
`

const ListHolder = styled.div<{ tableLike?: TableLike }>`
    display: block;
    flex-direction: column;
    align-items: stretch;
    ${(p) =>
        p.tableLike &&
        css`
            display: contents;
        `}
`

const Container = styled.div<{ tableLike?: TableLike }>`
    display: contents;
    ${(p) =>
        p.tableLike &&
        css`
            display: grid;
            grid-template-columns: ${p.tableLike.layout};
            padding-bottom: 20px;
        `}
`

const FixedHeader = styled.div<{ isHigher?: boolean }>`
    position: sticky;
    top: 0;
    background-color: ${(p) => p.theme['floating.background']};
    left: 0;
    grid-column: 1 / span 5;
    height: 15px;
    z-index: 100;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    padding-top: 10px;
    padding-left: 23px;

    * {
        font-size: 10px;
        letter-spacing: 0.25px;
    }

    b {
        background-color: rgba(255, 255, 255, 0.12);
        color: ${(p) => p.theme['floating.text']};
        padding: 2px 4px;
        margin: -2px 1px;
        border-radius: 4px;
    }
    ${(p) =>
        p.isHigher &&
        css`
            background-color: ${(p) => p.theme['floating.background.strongerI']};
        `}
`

const HeaderCell = styled.div<{ isHigher?: boolean; hasFixedHeader?: boolean }>`
    position: sticky;
    ${(p) =>
        p.hasFixedHeader
            ? css`
                  top: 25px;
              `
            : css`
                  top: 0px;
              `}
    left: 0;
    background-color: ${(p) => p.theme['floating.background']};
    border-bottom: 1px solid ${(p) => p.theme['floating.background.subtlerIII']};
    padding: 5px 0px;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.2px;
    font-weight: 500;
    display: flex;
    align-items: center;

    ${(p) =>
        p.isHigher &&
        css`
            background-color: ${(p) => p.theme['floating.background.strongerI']};
        `}
`
