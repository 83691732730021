import { TextareaInput } from '@/components/forms/textareaInput'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const NoteSection: React.FC = () => {
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name="note"
            render={({ field: { onChange, onBlur, value } }) => (
                <TextareaInput
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="Write a note..."
                    overBackground="front.background"
                    minHeight={50}
                />
            )}
        />
    )
}
