import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('fill-current', {
    variants: {
        color: {
            black: 'text-black',
            white: 'text-white',
            primary: 'text-primary',
            success: 'text-success',
            warning: 'text-warning',
            danger: 'text-danger',
            notice: 'text-notice',
            neutral: 'text-neutral-500 dark:text-neutral-400'
        },
        shade: {
            '50': 'text-neutral-50',
            '100': 'text-neutral-100',
            '200': 'text-neutral-200',
            '300': 'text-neutral-300',
            '400': 'text-neutral-400',
            '500': 'text-neutral-500',
            '600': 'text-neutral-600',
            '700': 'text-neutral-700',
            '800': 'text-neutral-800',
            '900': 'text-neutral-900',
            '950': 'text-neutral-950'
        },
        size: {
            'full': 'h-full w-full',
            'fit': 'h-fit w-fit',
            '0': 'h-0 w-0',
            'px': 'h-px w-px',
            '0.5': 'h-0.5 w-0.5',
            '1': 'h-1 w-1',
            '1.5': 'h-1.5 w-1.5',
            '2': 'h-2 w-2',
            '2.5': 'h-2.5 w-2.5',
            '3': 'h-3 w-3',
            '3.5': 'h-3.5 w-3.5',
            '4': 'h-4 w-4',
            '5': 'h-5 w-5',
            '6': 'h-6 w-6',
            '7': 'h-7 w-7',
            '8': 'h-8 w-8',
            '9': 'h-9 w-9',
            '10': 'h-10 w-10',
            '11': 'h-11 w-11',
            '12': 'h-12 w-12',
            '14': 'h-14 w-14',
            '16': 'h-16 w-16',
            '20': 'h-20 w-20',
            '24': 'h-24 w-24',
            '28': 'h-28 w-28',
            '32': 'h-32 w-32',
            '36': 'h-36 w-36',
            '40': 'h-40 w-40',
            '44': 'h-44 w-44',
            '48': 'h-48 w-48',
            '52': 'h-52 w-52',
            '56': 'h-56 w-56',
            '60': 'h-60 w-60',
            '64': 'h-64 w-64',
            '72': 'h-72 w-72',
            '80': 'h-80 w-80',
            '96': 'h-96 w-96'
        }
    },
    compoundVariants: [
        {
            color: 'neutral',
            shade: '50',
            class: 'text-neutral-50'
        },
        {
            color: 'neutral',
            shade: '100',
            class: 'text-neutral-100'
        },
        {
            color: 'neutral',
            shade: '200',
            class: 'text-neutral-200'
        },
        {
            color: 'neutral',
            shade: '300',
            class: 'text-neutral-300'
        },
        {
            color: 'neutral',
            shade: '400',
            class: 'text-neutral-400'
        },
        {
            color: 'neutral',
            shade: '500',
            class: 'text-neutral-500'
        },
        {
            color: 'neutral',
            shade: '600',
            class: 'text-neutral-600'
        },
        {
            color: 'neutral',
            shade: '700',
            class: 'text-neutral-700'
        },
        {
            color: 'neutral',
            shade: '800',
            class: 'text-neutral-800'
        },
        {
            color: 'neutral',
            shade: '900',
            class: 'text-neutral-900'
        },
        {
            color: 'neutral',
            shade: '950',
            class: 'text-neutral-950'
        },
        {
            color: 'danger',
            shade: '50',
            class: 'text-danger-50'
        },
        {
            color: 'danger',
            shade: '100',
            class: 'text-danger-100'
        },
        {
            color: 'danger',
            shade: '200',
            class: 'text-danger-200'
        },
        {
            color: 'danger',
            shade: '300',
            class: 'text-danger-300'
        },
        {
            color: 'danger',
            shade: '400',
            class: 'text-danger-400'
        },
        {
            color: 'danger',
            shade: '500',
            class: 'text-danger-500'
        },
        {
            color: 'danger',
            shade: '600',
            class: 'text-danger-600'
        },
        {
            color: 'danger',
            shade: '700',
            class: 'text-danger-700'
        },
        {
            color: 'danger',
            shade: '800',
            class: 'text-danger-800'
        },
        {
            color: 'danger',
            shade: '900',
            class: 'text-danger-900'
        },
        {
            color: 'danger',
            shade: '950',
            class: 'text-danger-950'
        },
        {
            color: 'notice',
            shade: '50',
            class: 'text-notice-50'
        },
        {
            color: 'notice',
            shade: '100',
            class: 'text-notice-100'
        },
        {
            color: 'notice',
            shade: '200',
            class: 'text-notice-200'
        },
        {
            color: 'notice',
            shade: '300',
            class: 'text-notice-300'
        },
        {
            color: 'notice',
            shade: '400',
            class: 'text-notice-400'
        },
        {
            color: 'notice',
            shade: '500',
            class: 'text-notice-500'
        },
        {
            color: 'notice',
            shade: '600',
            class: 'text-notice-600'
        },
        {
            color: 'notice',
            shade: '700',
            class: 'text-notice-700'
        },
        {
            color: 'notice',
            shade: '800',
            class: 'text-notice-800'
        },
        {
            color: 'notice',
            shade: '900',
            class: 'text-notice-900'
        },
        {
            color: 'notice',
            shade: '950',
            class: 'text-notice-950'
        },
        {
            color: 'warning',
            shade: '50',
            class: 'text-warning-50'
        },
        {
            color: 'warning',
            shade: '100',
            class: 'text-warning-100'
        },
        {
            color: 'warning',
            shade: '200',
            class: 'text-warning-200'
        },
        {
            color: 'warning',
            shade: '300',
            class: 'text-warning-300'
        },
        {
            color: 'warning',
            shade: '400',
            class: 'text-warning-400'
        },
        {
            color: 'warning',
            shade: '500',
            class: 'text-warning-500'
        },
        {
            color: 'warning',
            shade: '600',
            class: 'text-warning-600'
        },
        {
            color: 'warning',
            shade: '700',
            class: 'text-warning-700'
        },
        {
            color: 'warning',
            shade: '800',
            class: 'text-warning-800'
        },
        {
            color: 'warning',
            shade: '900',
            class: 'text-warning-900'
        },
        {
            color: 'warning',
            shade: '950',
            class: 'text-warning-950'
        }
    ]
})
