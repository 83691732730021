import React, { useMemo, useEffect } from 'react'
import { FormProvider as ReactHookFormProvider, useForm } from 'react-hook-form'

import { Report, UpdateReport, useUpdateReport } from '@/services/ongoing-due-diligence'
import { debounce } from 'lodash'
import { getDefaultValues } from './default-values'

interface FormProviderProps {
    children: React.ReactNode
    data: Report
}

export const FormProvider: React.FC<FormProviderProps> = (props) => {
    const { children, data } = props

    const [updateReport] = useUpdateReport()

    const defaultValues = getDefaultValues(data)

    const methods = useForm<UpdateReport>({
        defaultValues: { ...defaultValues }
    })

    const { watch, getValues } = methods

    const debounceUpdate = useMemo(
        () =>
            debounce(() => {
                updateReport({ reportId: data.id, data: getValues() })
            }, 500),
        [updateReport, data, getValues]
    )

    useEffect(() => {
        const subscription = watch(() => {
            debounceUpdate()
        })

        return () => subscription.unsubscribe()
    }, [watch, debounceUpdate])

    return <ReactHookFormProvider {...methods}>{children}</ReactHookFormProvider>
}
