import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { ValidationStatus } from '../../hooks/general/useForm'
import { Icon } from '../icons/icon'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { Floater } from '../layout/floater'
import { FloaterInset } from '../layout/floaterInset'
import { Color } from '../../styles/colors'

export const InputIndicator: React.FC<{
    validationStatus?: ValidationStatus
    isFocused?: boolean
    hasValue?: boolean
    isHigher?: boolean
    color?: Color
}> = ({ validationStatus, isHigher, color, isFocused, hasValue }) => {
    const [holder, setHolder] = useRefTaker()

    const icon = useMemo(() => {
        if (validationStatus === undefined) return <Icon type="editPen" color={color} />

        return <Icon type="x" color={'front.danger.color'} size={9} />
    }, [color, validationStatus])

    const tooltip = useMemo(() => {
        if (!isFocused) return null
        if (!validationStatus) return null
        return (
            <Floater anchor={holder} shouldShow={isFocused} placement="top" noFocusLock higher={isHigher}>
                <FloaterInset padding="tiny">
                    <TextHolder>{validationStatus}</TextHolder>
                </FloaterInset>
            </Floater>
        )
    }, [holder, isHigher, validationStatus, isFocused])

    return (
        <IconHolder
            isFocused={isFocused}
            hasValue={hasValue}
            data-cy={validationStatus ? 'wrong-indicator' : 'indicator'}
        >
            <div ref={setHolder}>{icon}</div>
            {tooltip}
        </IconHolder>
    )
}
const IconHolder = styled.div<{
    isFocused?: boolean
    hasValue?: boolean
}>`
    pointer-events: none;
    background-color: transparent;
    color: ${(p) => p.theme['front.accent.color']};
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) =>
        p.hasValue &&
        css`
            color: ${(p) => p.theme['front.accent.color']};
        `}

    ${(p) =>
        p.isFocused &&
        css`
            color: ${(p) => p.theme['front.accent.color.strongerI']};
        `}
`

const TextHolder = styled.div`
    color: #fff;
    text-transform: initial;
    white-space: nowrap;
`
