import { CrossIcon, DatePicker, Flex, Grid, Input, Label, Text } from '@/design-system'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

interface FormDatePickerProps {
    testid?: string
    name: string
    label: string
}

export const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {
    const { testid, name, label } = props

    const [selected, setSelected] = useState<Date | undefined>(undefined)

    const {
        formState: { errors },
        register,
        setValue
    } = useFormContext<{ [x: string]: string }>()

    const onSelect = (date?: Date) => {
        setSelected(date)
        if (!date) return

        setValue(name, date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }))
    }

    return (
        <>
            <Grid.Item align="center">
                <Label htmlFor="name">{label}</Label>
            </Grid.Item>
            <Grid.Item columnSpan="2">
                <DatePicker selected={selected} onSelect={onSelect}>
                    <DatePicker.Trigger testid={testid}>
                        <Flex gap="3" align="center">
                            <Input placeholder="-" {...register(name)} />
                            {errors[name] ? <CrossIcon color="danger" /> : <div />}
                        </Flex>
                    </DatePicker.Trigger>
                </DatePicker>
            </Grid.Item>
            {errors[name] && (
                <Grid.Item columnSpan="2" columnStart="2">
                    <Text color="danger">{errors[name]?.message}</Text>
                </Grid.Item>
            )}
        </>
    )
}
