import React, { useCallback } from 'react'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { List } from '../../../components/layout/list'
import { ModalPage } from '../../../components/layout/modalPage'
import { Spacer } from '../../../components/layout/spacer'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { useForm } from '../../../hooks/general/useForm'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import * as yup from 'yup'
import { CardSection } from '../../../components/cards/cardSection'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { useDispatch } from 'react-redux'
import { ApplicationInternalsStampingActions } from '../../../store/applicationInternals/stamping/actions'

export const MerchantApplicationStateControlDeclineModal: React.FC<{
    onCancel?: () => void
    link: string
    watcherId: string
    applicationId: string
    onComplete?: () => void
}> = ({ onCancel, link: stampLink, applicationId, watcherId, onComplete }) => {
    const [watcher] = useNamedWatcher(``)
    const { formRef, errors, submitHandler } = useForm(watcher, undefined, undefined, undefined, true)
    const dispatch = useDispatch()

    const handleSubmit = useCallback(
        ({ data }) => {
            dispatch(
                ApplicationInternalsStampingActions.STAMP(applicationId, stampLink, 'PUT', watcherId, {
                    note: data.reason
                })
            )
            onComplete?.()
        },
        [dispatch, stampLink, onComplete, watcherId, applicationId]
    )

    return (
        <ModalPage title="Decline reason" pageId="Merchant.ApplicationPage.DeclineModal" overlay onBack={onCancel}>
            <ModalHeader
                onBack={onCancel}
                title="Confirmation declination"
                pageId="Merchant.ApplicationPage.DeclineModal"
            />
            <Holder>
                <Card title="Refusal reason">
                    <CardInset>
                        <List
                            background="front.background"
                            items={{
                                Reason: {
                                    type: 'custom' as const,
                                    node: (
                                        <TextareaInput
                                            key="reason"
                                            placeholder="Enter a reason for declining the application"
                                            isSimple
                                            initialValue={undefined}
                                            overBackground={'front.background' as const}
                                            minHeight={80}
                                            validation={errors.reason}
                                            ref={(ref: any) => formRef(ref, 'reason', yup.string().required())}
                                        />
                                    )
                                }
                            }}
                            switchToRowsAt={10000}
                            cellHorizontalTemplate="80px minmax(auto, 300px)"
                        />
                        <Spacer height={20} />
                        <CardSection background="subtleBlue">
                            <CardInset reduceVerticalPadding>
                                <Flex justify="space-between" align="center">
                                    <SimpleButton background="front.background" onClick={onCancel}>
                                        <ButtonInset>Cancel</ButtonInset>
                                    </SimpleButton>
                                    <SimpleButton
                                        isDisabled={!!Object.keys(errors).length}
                                        background="front.danger.color"
                                        onClick={submitHandler(handleSubmit)}
                                    >
                                        <ButtonInset>Decline merchant</ButtonInset>
                                    </SimpleButton>
                                </Flex>
                            </CardInset>
                        </CardSection>
                    </CardInset>
                </Card>
            </Holder>
        </ModalPage>
    )
}

const Holder = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
`
