import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useReconcileVoucherActions, useVoucherBalance } from '../../../hooks'
import { ReconcileAction } from '../common/reconcile-action'

export const ReconcileVoucher: React.FC = () => {
    const navigate = useNavigate()
    const { isLoading, reconcile } = useReconcileVoucherActions()

    const balance = useVoucherBalance()
    const isDisabled = balance !== 0

    const handleReconcile = async () => {
        await reconcile()

        navigate('/more/reconciliation-vouchers')
    }

    return <ReconcileAction onClick={handleReconcile} isDisabled={isDisabled} isLoading={isLoading} />
}
