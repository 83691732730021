import { useGetPosting, useGetVouchers } from '@/services/reconciliation'
import { useReconciliationParams } from './use-reconciliation-params'
import { useFormContext } from 'react-hook-form'
import { ReconciliationBalance, filterReconciliationsById } from '../utils'

export const usePostingBalance = () => {
    const { id } = useReconciliationParams()

    const { data: posting } = useGetPosting(id)
    const { data: vouchers } = useGetVouchers()

    const ids = useFormContext().watch('voucher_ids')

    if (!posting) return

    const balance = { type: posting.posting_type, amount: posting.amount }
    const reconciliations = filterReconciliationsById(ids, vouchers?.items)

    const reconciliationBalance = new ReconciliationBalance(balance, reconciliations)

    return reconciliationBalance.calculateBalance()
}
