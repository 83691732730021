import {
    FormProvider,
    ReportLayout,
    ReportModal,
    ReportProvider,
    ReportSections,
    ReportStatus,
    useReportParams
} from '@/features/ongoing-due-diligence'

import React, { useEffect } from 'react'
import { Text } from '@/components/general/text'
import { LoaderView } from '@/components/loaders/loader'
import { useGetReport } from '@/services/ongoing-due-diligence'
import { useNavigate } from 'react-router-dom'

export const Report: React.FC = () => {
    const { reportId } = useReportParams()
    const { data, isLoading, isError } = useGetReport(reportId)

    const navigate = useNavigate()

    useEffect(() => {
        if (isError) {
            navigate(-1)
        }
    }, [navigate, isError])

    if (isLoading) {
        return <LoaderView overBackground="front.background" type="l" minHeight="200" />
    }

    if (!data) {
        return <Text>No report data available</Text>
    }

    return (
        <FormProvider data={data}>
            <ReportProvider report={data}>
                <ReportModal>
                    <ReportLayout>
                        <ReportStatus />
                        <ReportSections />
                    </ReportLayout>
                </ReportModal>
            </ReportProvider>
        </FormProvider>
    )
}
