import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ApplicationsDispatchMarkCommentAsRead } from '../../store/applications/actions'
import { useRefTaker } from './useRefTaker'

export const useReadCommentsOnScroll = (
    applicationId?: string,
    commentId?: string,
    isUnread?: boolean
): { setRef: (s: any) => any } => {
    const [ref, setRef] = useRefTaker()
    const dispatch = useDispatch()

    const markAsRead = useCallback(
        (commentId: string) => {
            if (!applicationId) return
            dispatch(ApplicationsDispatchMarkCommentAsRead(applicationId, commentId))
        },
        [dispatch, applicationId]
    )

    useEffect(() => {
        if (!commentId) return
        if (!isUnread) return
        if (ref) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (isUnread && entry.intersectionRatio > 0) {
                            markAsRead?.(commentId)
                        }
                    })
                },
                {
                    rootMargin: '0px 0px 0px 0px'
                }
            )

            observer.observe(ref)

            return () => {
                if (ref) observer.unobserve(ref)
            }
        }
    }, [isUnread, dispatch, ref, markAsRead, commentId])

    return { setRef }
}
