import { CompanyInfoPotentialMatch, ComputedOwnershipShares } from './types'

export const computeOwnershipShares = (
    sotOwners?: CompanyInfoPotentialMatch['ownership']['beneficial']
): ComputedOwnershipShares => {
    const final: ComputedOwnershipShares = {
        missing: {
            fraction: 1,
            status: 'fraction',
            formatted: '100%'
        },
        list: {}
    }

    sotOwners?.forEach((o) => {
        if (o.ownershipPercentage === undefined || o.ownershipPercentage === null) return
        const fr = typeof o.ownershipPercentage === 'string' ? parseFloat(o.ownershipPercentage) : o.ownershipPercentage

        final.missing.fraction -= fr
        final.list[o.name] = {
            name: o.name,
            country: o.country,
            fraction: fr,
            formatted: `${(fr * 100).toFixed(2)}%`
        }
    })

    if (final.missing.fraction > 1) {
        final.missing = {
            fraction: 0,
            status: 'over-limit',
            formatted: '0%'
        }
    } else if (final.missing.fraction < 0) {
        final.missing = {
            fraction: 1,
            status: 'under-limit',
            formatted: '100%'
        }
    } else if (final.missing.fraction === 0) {
        final.missing = {
            fraction: 0,
            status: 'ok',
            formatted: '0%'
        }
    } else {
        final.missing = {
            fraction: final.missing.fraction,
            status: 'ok',
            formatted: `${(final.missing.fraction * 100).toFixed(2)}%`
        }
    }

    return final
}
