import { createReducer } from 'deox'
import { produce } from 'immer'
import { InitialApplicationInternalStampingState } from './types'
import { ApplicationInternalsStampingActions } from './actions'
import { ApplicationInternalsActions } from '../actions'

export const ApplicationInternalsStampingReducer = createReducer(
    InitialApplicationInternalStampingState,
    (handleAction) => [
        handleAction(ApplicationInternalsStampingActions.STORE, (state, { payload: p }) => {
            return produce(state, (draft) => {
                if (!draft.forApplication[p.applicationId])
                    draft.forApplication[p.applicationId] = {
                        stamps: [...p.stamps],
                        loadingStatus: 'done'
                    }
                else {
                    draft.forApplication[p.applicationId].stamps = [...p.stamps]
                    draft.forApplication[p.applicationId].loadingStatus = 'done'
                }
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = JSON.parse(JSON.stringify(InitialApplicationInternalStampingState))
                return draft
            })
        }),
        handleAction(ApplicationInternalsStampingActions.REFETCH, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft.forApplication[p.applicationId].loadingStatus = 'started'
            })
        }),
        handleAction(ApplicationInternalsStampingActions.INIT, (state, { payload: p }) => {
            return produce(state, (draft) => {
                if (!draft.forApplication[p.applicationId])
                    draft.forApplication[p.applicationId] = {
                        stamps: [],
                        loadingStatus: 'not-started'
                    }
            })
        })
    ]
)
