import { LoadingStatus } from '../../../utils'

export type Conclusion = {
    by: string
    at: string
    matches: string[]
    pastInquiryMatches: string[]
    passed?: boolean
}

export type Inquiry = {
    applicationId: string
    inquiryId: string
    by: string
    at: string
    matchPastInquiries: PastInquiry[]
    vmssResults: Result[]
    matchResults: Result[]
    queryData: {
        vmss: string
        match: string
    }
    conclusion: Conclusion
}

export type PastInquiry = {
    pastInquiryId: string

    company: {
        name: string
        registrationId: string
        country: string
    }
    termination: {
        reasonCode?: string
        reasonDescription?: string
    }
    acquirer: {
        acquirerId: string
        dateAdded?: string
    }
    raw: {
        request: any
        response: any
    }
}

export type Result = {
    resultId: string
    type: 'VMSS' | 'MATCH'
    termination: {
        mainReasonCode: string
        mainReasonDescription: string
        secondaryReasonCode?: string
        secondaryReasonDescription?: string
        madeAt: string
        acquirerId: string
        acquirerName: string
        acquirerCountry: string
    }
    company: {
        name: string
        registrationId: string
        country: string
    }
    matchingScore: number
    matchingFields: {
        fieldName: string
        fieldType: string
        applicationValue: string
        screenerValue: string
    }[]
    raw: string
}

export type Inquiries = Inquiry[]

export type ScreeningStatus =
    | 'no-inquiry'
    | 'inconclusive-inquiry'
    | 'failed-inquiry'
    | 'successful-inquiry'
    | 'no-results-inquiry'

export interface ApplicationInternalSchemesScreeningState {
    forApplication: {
        [key: string]: {
            status: ScreeningStatus
            latestInquiry?: Inquiry
            inquiries: Inquiry[]
            loadingStatus: LoadingStatus
        }
    }
}

export const InitialApplicationInternalSchemesScreeningState: ApplicationInternalSchemesScreeningState = {
    forApplication: {}
}
