import React from 'react'
import { Tag } from '@/components/general/tag'
import { TaskState } from '@/services/taskr'
import { mapTaskState } from '../../helpers'
import { uppercaseFirstLetter } from '@/utils'

interface StateProps {
    children: TaskState
}

export const State: React.FC<StateProps> = (props) => {
    const { children } = props

    const mappedState = uppercaseFirstLetter(mapTaskState(children).replace('_', ' '))

    return <Tag name={mappedState} bubbleType="bordered" marginRight={0} />
}
