import styled, { css } from 'styled-components'

const activeStyles = css`
    color: ${(p) => p.theme['side.text.strongerIII']};
    background-color: ${(p) => p.theme['side.background.strongerI']};
    cursor: default;

    & * {
        font-weight: 500;
    }

    &:hover {
        color: ${(p) => p.theme['side.text.strongerIII']};
    }

    &:active {
        color: ${(p) => p.theme['side.text.strongerIII']};
    }
`

export const ListPageSidebarLinkStyles = css<{ isActive?: boolean }>`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    color: ${(p) => p.theme['side.text.subtlerI']};
    cursor: pointer;
    position: relative;
    text-decoration: none;
    padding: 3px 12px 3px 12px;
    margin: -1px -12px 0 -12px;
    border-radius: 8px;

    &:hover {
        color: ${(p) => p.theme['side.text.subtlerII']};
    }
    &:active {
        color: ${(p) => p.theme['side.text.subtlerIII']};
    }

    ${(p) => p.isActive && activeStyles}

    &.active {
        ${activeStyles}
    }
`

export const ListPageSidebarLink = styled.div<{ isActive: boolean }>`
    ${ListPageSidebarLinkStyles}
`
