import React from 'react'
import { Table } from '@/components/tables/table'
import { getChartsRows } from './charts-table-helpers'
import { useGetCharts } from '@/services/ongoing-due-diligence'
import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { CreateChartsButton } from '../create-charts-button'

const cols = [{ text: 'File' }, { text: 'Generated on', alignRight: true }]

const createChartButton = <CreateChartsButton />

interface IChartsTableProps {
    applicationId: string
}

export const ChartsTable: React.FC<IChartsTableProps> = (props) => {
    const { applicationId } = props
    const { isLoading, data } = useGetCharts(applicationId)

    const rows = getChartsRows(data)

    return (
        <Card title="Generated charts" rightSideMemo={createChartButton}>
            <CardInset>
                <Table
                    displayLoader={isLoading}
                    loaderRows={1}
                    rows={rows}
                    cols={cols}
                    background="front.background"
                    columnLayout="1fr auto"
                />
            </CardInset>
        </Card>
    )
}
