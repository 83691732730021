import { ReactElement, useCallback} from 'react'
import { FiltersController } from '../../hooks/pages/useListPage'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'
import { ApplicationsFilters } from '@/store/applications/types'
import { useGateways } from '@/hooks/general/useGateways'
import { TextInputSelect } from '../forms/textInputSelect'

export function ListPageFilterGateway<T extends ApplicationsFilters>({
    filters,
    fieldKey,
    isDark
}: {
    filters: FiltersController<T>
    isDark: boolean
    fieldKey: keyof FiltersController<T>['get']
}): ReactElement {
    const gateways = useGateways()

    const onSelect = useCallback(
        (item: string) => {
            filters.set((prevState: any) => ({
                ...prevState,
                [fieldKey]: prevState[fieldKey] === gateways.at[item].name ? undefined : gateways.at[item].name
            }))
        },
        [fieldKey, filters, gateways]
    )

    const textForItem = useCallback(
        (item) => {
            return gateways.at[item]?.name || item
        }, [gateways]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputSelect
                placeholder="-"
                selected={filters.get[fieldKey]}
                onSelect={onSelect}
                dropdownId="ApplicationsList.GatewayFilter"
                textForItem={textForItem}
                isSeamless
                noClear
                lazyLoaded
                items={gateways.all}
                cy="gateway-filter"
                overBackground={isDark ? 'floating.background' : 'side.background'}
            />
            <SelectedListForListPageFilters<T>
                isDark={isDark}
                noHolderEmphasize={isDark}
                filterKey={fieldKey}
                filters={filters}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
