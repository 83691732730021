import dotProp from 'dot-prop'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { IMerchantApplicationSectionStructure } from './Application.StructureTypes'

export type ApplicationLoadingStatus =
    | 'waiting-for-section'
    | 'waiting-for-comments'
    | 'waiting-for-files'
    | 'waiting-for-edits'
    | 'all-done'
export const useHasSectionLoaded = (
    id: string,
    sectionStructure: IMerchantApplicationSectionStructure
): ApplicationLoadingStatus => {
    const resources = useSelector((state: RootState) => {
        // eslint-disable-next-line no-restricted-syntax
        return state.applicationResources
    })

    return useMemo(() => {
        for (const resource of sectionStructure.waitForSections) {
            if (!dotProp.get(resources, `data.forApplication.${id}.${resource}`)) return 'waiting-for-section'

            const subsections: any = dotProp.get(resources, `data.forApplication.${id}.${resource}.fields`)

            for (const subsection of subsections) {
                if (subsection.filesLoadingStatus !== 'done') {
                    return 'waiting-for-files'
                }
            }

            if (dotProp.get(resources, `data.forApplication.${id}.${resource}.loadingStatus`) !== 'done')
                return 'waiting-for-section'

            if (dotProp.get(resources, `comments.forApplication.${id}.${resource}.loadingStatus`) !== 'done')
                return 'waiting-for-comments'

            if (dotProp.get(resources, `history.forApplication.${id}.${resource}.loadingStatus`) !== 'done')
                return 'waiting-for-edits'
        }
        return 'all-done'
    }, [resources, sectionStructure, id])
}
