import React, { useState } from 'react'
import { Table } from '@/components/tables/table'
import { getTasksRows } from './scheduled-tasks-table-helpers'
import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { TasksPagination } from '../tasks-pagination'
import { useScheduledTasksTable } from './use-scheduled-tasks-table'
import { Spacer } from '@/components/layout/spacer'

const cols = [{ text: 'Company' }, { text: 'Due' }, { text: 'Volume EUR (12 mth)' }, { text: 'Risk' }, { text: 'Tags' }]

export const ScheduledTasksTable: React.FC = () => {
    const [page, setPage] = useState(1)

    const { data, isLoading } = useScheduledTasksTable(page)

    const isPaginationShown = data && data.total > data.per_page

    const rows = getTasksRows(data)

    const handleOnPageChanged = (page: number) => {
        setPage(page)
    }

    return (
        <>
            <Card title="ODD Tasks">
                <CardInset>
                    <Table
                        displayLoader={isLoading}
                        loaderRows={20}
                        rows={rows}
                        cols={cols}
                        background="front.background"
                        columnLayout="auto auto auto auto auto"
                    />
                </CardInset>
            </Card>
            <TasksPagination tasks={data} onPageChanged={handleOnPageChanged} />
            {!isPaginationShown && <Spacer height={30} />}
        </>
    )
}
