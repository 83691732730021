export function filtersToQuery<T>(
    f: T,
    customHandler?: (k: keyof T, f: T) => { key: string; val: string } | undefined,
    paginationKeys?: {
        pageKey: string
        perPageKey: string
    },
    skipPagination?: boolean
): string {
    let query = ''

    const filters = f as any
    const activeFilters = Object.keys(filters).filter((k: any) =>
        Array.isArray(filters[k]) ? filters[k].length > 0 : filters[k]
    )

    if (activeFilters.length) {
        const filtersToQueryString = activeFilters
            .map((k: any) => {
                const merge = (p: { key: string; val: string } | undefined) => (p ? `${p.key}:${p.val}` : undefined)

                if (k === paginationKeys?.pageKey || k === paginationKeys?.perPageKey) return undefined
                return customHandler ? merge(customHandler(k, filters)) : merge({ key: k, val: filters[k] })
            })
            .filter((f) => f)
            .join(' ')
            .replace('reference:', '')

        let paginationString = ''

        if (paginationKeys && !skipPagination)
            paginationString = `page=${(f as any)[paginationKeys.pageKey]}&per_page=${
                (f as any)[paginationKeys.perPageKey]
            }`

        query = `?query=${encodeURIComponent(filtersToQueryString)}${paginationString ? `&${paginationString}` : ''}`
    }

    return query
}
