import { createReducer } from 'deox'
import { produce } from 'immer'
import dotProp from 'dot-prop'
import { InitialApplicationInternalTagsState } from './types'
import { ApplicationInternalsTagsActions } from './actions'
import { ApplicationInternalsActions } from '../actions'

export const ApplicationInternalsTagsReducer = createReducer(InitialApplicationInternalTagsState, (handleAction) => [
    handleAction(ApplicationInternalsTagsActions.FETCH, (state) => {
        return produce(state, (draft: any) => {
            dotProp.set(draft, `loadingStatus`, 'started')
        })
    }),
    handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft.forApplication = JSON.parse(JSON.stringify(InitialApplicationInternalTagsState.forApplication))
            return draft
        })
    }),
    handleAction(ApplicationInternalsTagsActions.STORE, (state, { payload: p }) => {
        return produce(state, (draft: any) => {
            p.tags.forEach((t) => {
                dotProp.set(draft.globalTags, `at.${t.id}`, t)
            })
            const newTagIds = p.tags.map((t) => t.id)
            const allTags = [...newTagIds]
            dotProp.set(draft.globalTags, `all`, allTags)
            dotProp.set(draft.globalTags, `loadingStatus`, 'done')
        })
    }),
    handleAction(ApplicationInternalsTagsActions.STORE_SINGLE, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft.forApplication[p.applicationId].at[p.tag.id] = {
                ...state.forApplication[p.applicationId].at[p.tag.id],
                ...p.tag
            }
            draft.forApplication[p.applicationId].all = draft.forApplication[p.applicationId].all.filter(
                (t) => t !== p.tag.id
            )
            draft.forApplication[p.applicationId].all.push(p.tag.id)

            if (!draft.forApplication[p.applicationId].selected.includes(p.tag.id)) {
                draft.forApplication[p.applicationId].selectable = draft.forApplication[
                    p.applicationId
                ].selectable.filter((t) => t !== p.tag.id)
                draft.forApplication[p.applicationId].selectable.push(p.tag.id)
            }
        })
    }),
    handleAction(ApplicationInternalsTagsActions.FETCH_APPLICATION_TAGS, (state, { payload: p }) => {
        return produce(state, (draft: any) => {
            if (!draft.forApplication[p.applicationId])
                draft.forApplication[p.applicationId] = {
                    at: {},
                    all: [],
                    selectable: [],
                    selected: [],
                    loadingStatus: 'loading'
                }
            else draft.forApplication[p.applicationId].loadingStatus = 'loading'
        })
    }),
    handleAction(ApplicationInternalsTagsActions.STORE_APPLICATION_TAGS, (state, { payload: p }) => {
        return produce(state, (draft: any) => {
            const all = [...(p?.selectable || []), ...(p?.selected || [])]
            dotProp.set(draft, `forApplication.${p.applicationId}`, {
                at: {
                    ...all.reduce((acc, i) => {
                        acc[i.id] = { ...i }
                        return acc
                    }, {} as any)
                },
                selectable: p.selectable?.map((t) => t.id) || [],
                selected: p.selected?.map((t) => t.id) || [],
                all: all?.map((t) => t.id) || [],
                loadingStatus: 'done'
            })
        })
    })
])
