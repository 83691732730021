import { CardInset } from '@/components/cards/cardInset'
import { Spacer } from '@/components/layout/spacer'
import { Flex } from '../../../components/layout/flex'
import { PastInquiry } from '@/store/applicationInternals/schemesScreening/types'
import { Text } from '../../../components/general/text'
import styled from 'styled-components'
import * as YAML from 'js-yaml'

export const ApplicationSchemesScreeningPastInquiriesBody: React.FC<{
    pastInquiry: PastInquiry
}> = ({ pastInquiry }) => {
    return (
        <CardInset type="tiny">
            <Flex>
                <Flex column fullWidth>
                    <Text color="front.text" bold>
                        API Request:{' '}
                    </Text>
                    <Spacer height={10} />
                    <Context>
                        <pre>{YAML.dump(pastInquiry.raw?.request)}</pre>
                    </Context>
                </Flex>
                <Spacer width={10} />
                <Flex column fullWidth>
                    <Text color="front.text" bold>
                        API Response:{' '}
                    </Text>
                    <Spacer height={10} />
                    <Text>
                        {' '}
                        {pastInquiry.raw?.response?.merchantMatch ? (
                            <Context>
                                <pre>
                                    {YAML.dump({
                                        ...pastInquiry.raw?.response,
                                        merchantMatch: undefined
                                    })}
                                </pre>
                            </Context>
                        ) : (
                            <Context>
                                <pre>{YAML.dump(pastInquiry.raw?.response)}</pre>
                            </Context>
                        )}
                    </Text>
                </Flex>
            </Flex>
        </CardInset>
    )
}

const Context = styled.div<{ pre?: boolean }>`
    margin-bottom: 0px;
    margin-top: 0px;
    background-color: ${(p) => p.theme['back.background']};
    color: ${(p) => p.theme['front.text']};
    padding: 15px;
    border-radius: 10px;
    display: pre;
    white-space: pre;
`
