import React from 'react'
import { Tag as TagComp } from '@/components/general/tag'
import { uppercaseFirstLetter } from '@/utils'

interface TagProps {
    children: string
}

export const Tag: React.FC<TagProps> = (props) => {
    const { children } = props

    const name = uppercaseFirstLetter(children?.replace('_', ' '))

    return <TagComp name={name} bubbleType="bordered" marginRight={0} />
}
