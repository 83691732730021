import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { RootState } from '@/store'
import { Spacer } from '../layout/spacer'
import { isMacOS } from '../../utils'

const key = isMacOS ? 'CTRL' : 'ALT'
export const GlobalHotkeysBar: React.FC = () => {
    const cancelIntro = useRef(false)
    const showKeytips = useSelector((state: RootState) => state.interface.showKeyTips)
    const [showIntro, setShowIntro] = useState(true)

    const state = useMemo(() => {
        if (showIntro)
            return {
                intro: true,
                globalTips: false
            }
        return {
            intro: false,
            globalTips: showKeytips
        }
    }, [showIntro, showKeytips])

    useEffect(() => {
        if (showKeytips) {
            cancelIntro.current = true
            setShowIntro(false)
        }
    }, [showKeytips])

    useEffect(() => {
        if (cancelIntro.current) return

        const timeout = setTimeout(() => {
            setShowIntro(false)
        }, 5000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    return (
        <>
            <Bar shouldShow={state.intro}>
                <KeyHolder data-cy="key-bar">
                    <Spacer width={5} />
                    {/* <IconInset>
                        <Icon type="alt" />
                    </IconInset> */}
                    {/* <Spacer width={5} /> */}Press {key} to show hotkeys
                </KeyHolder>
            </Bar>
            <Bar shouldShow={state.globalTips}>
                <KeyHolder>
                    {/* 
                    <IconInset>
                        <Icon type="alt" />
                    </IconInset>
                    <Spacer width={5} /> */}
                    S - Global search
                </KeyHolder>
                <Spacer width={5} />
                <KeyHolder>
                    {/* <IconInset>
                        <Icon type="alt" />
                    </IconInset>
                    <Spacer width={5} /> */}
                    {key + ' '}/ - Toggle last used theme
                </KeyHolder>
            </Bar>
        </>
    )
}

const Bar = styled.div<{ shouldShow?: boolean }>`
    position: fixed;
    bottom: 0;
    left: calc((100vw - 500px) / 2);
    width: 500px;
    height: 50px;
    z-index: 1000000000;
    transform: translateY(50px);
    transition: 0.2s ease transform;
    display: flex;
    align-items: center;
    justify-content: center;

    @media print {
        display: none;
    }

    ${(p) =>
        p.shouldShow &&
        css`
            opacity: 1;
            transform: translateY(0px);
        `}
`

const KeyHolder = styled.div`
    background-color: ${(p) => p.theme['floating.background.strongerIII']};
    color: ${(p) => p.theme['floating.text']};
    backdrop-filter: blur(8px);
    padding: 2px 5px;
    font-weight: 500;
    font-size: 12px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const IconInset = styled.div`
    position: relative;
    top: 1.5px;
    left: 2px;
`
