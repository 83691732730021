import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Text } from '../../components/general/text'
import { Flag } from '../../components/icons/flag'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { DataPorviderStateAssignedAndReady } from '../../store/applicationDataProviders/types'
import { Person } from '../../store/applications/types'
import { RootState } from '@/store'
import { MerchantApplicationConflictsSection } from './MerchantApplicationConflictsSection'
import countries from 'i18n-iso-countries'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { ApplicationDataProvidersActions } from '../../store/applicationDataProviders/actions'
import { GeneralConflictBox } from '../../components/general/generalConflictBox'
import { TyposHighlighter } from '../../components/general/typosHighlighter'
import { PersonCountryRole } from './MerchantApplicationConflicts.PersonCountryRole'
import { ConflictPill } from './MerchantApplicationConflicts.Ownership.SOTTable'

export const MerchantApplicationConflictsDirector: React.FC<{
    applicationId: string
    showMuted: boolean
}> = ({ applicationId, showMuted }) => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.dataProviders.forApplication[applicationId])
    const conflict =
        data.state == 'assigned-and-ready'
            ? (data as DataPorviderStateAssignedAndReady).computed.conflicts.director
            : null
    const isMuteable = (conflict as any).muted !== undefined
    const applicationDirectors = useSelector((state: RootState) =>
        (state.applicationResources.data.forApplication[applicationId].people?.fields as any).filter(
            (p: Person) => p.role == 'director' || 'owner-and-director'
        )
    )
    const onUnignoreClick = useCallback(
        (e, generatedId) => {
            if (conflict == null) return
            if (conflict.type == 'application-zero-directors') return
            if (conflict.type == 'data-provider-blank') return
            if (conflict.type == 'no-conflicts') return
            dispatch(
                ApplicationDataProvidersActions.UNIGNORE_CHANGES(applicationId, generatedId, {
                    director: {
                        name: conflict.ctx.sot.name ? [conflict.ctx.sot.name] : [],
                        country: conflict.ctx.sot.country ? [conflict.ctx.sot.country] : []
                    }
                })
            )
        },
        [applicationId, dispatch, conflict]
    )
    const onIgnoreClick = useCallback(
        (e, generatedId) => {
            if (conflict == null) return
            if (conflict.type == 'application-zero-directors') return
            if (conflict.type == 'data-provider-blank') return
            if (conflict.type == 'no-conflicts') return
            dispatch(
                ApplicationDataProvidersActions.IGNORE_CHANGES(applicationId, generatedId, {
                    director: {
                        name: conflict.ctx.sot.name ? [conflict.ctx.sot.name] : [],
                        country: conflict.ctx.sot.country ? [conflict.ctx.sot.country] : []
                    }
                })
            )
        },
        [applicationId, dispatch, conflict]
    )

    const props = useMemo((): Partial<React.ComponentProps<typeof MerchantApplicationConflictsSection>> => {
        if (conflict?.type === 'application-zero-directors') return { showEvenIfMuted: showMuted }
        if (conflict?.type === 'no-conflicts') return { showEvenIfMuted: showMuted }
        if (conflict?.type === 'data-provider-blank') return { showEvenIfMuted: showMuted }

        return {
            showEvenIfMuted: showMuted,
            isMuted: conflict?.muted,
            onUnignoreClick: onUnignoreClick,
            onIgnoreClick: onIgnoreClick,
            onTypoFix:
                conflict?.type == 'typo'
                    ? (e, watcherId) => {
                          dispatch(ApplicationDataProvidersActions.FIX_TYPOS(watcherId, applicationId, 'director'))
                      }
                    : undefined
        }
    }, [conflict, onIgnoreClick, onUnignoreClick, showMuted, applicationId, dispatch])

    if (conflict == null) return <></>
    if (conflict.type == 'no-conflicts') return <></>

    if (conflict.type == 'application-zero-directors')
        return (
            <MerchantApplicationConflictsSection
                title="Director"
                isMuteable={isMuteable}
                subtitle={"We've found an issue with the application's management structure:"}
                {...props}
            >
                <GeneralConflictBox>No director have been provided in the application</GeneralConflictBox>
            </MerchantApplicationConflictsSection>
        )

    if (conflict.type == 'data-provider-blank')
        return (
            <MerchantApplicationConflictsSection
                title="Director"
                isMuteable={isMuteable}
                subtitle={"There's an issue on the data provider side"}
                {...props}
            >
                <GeneralConflictBox>The data provider has returned no directors for this company.</GeneralConflictBox>
            </MerchantApplicationConflictsSection>
        )

    return (
        <MerchantApplicationConflictsSection
            title="Director"
            isMuteable={isMuteable}
            subtitle={
                <>
                    {conflict.type == 'country-mismatch' && (
                        <Text>
                            There seems to be a <Text bold>country mismatch</Text> for the company&apos;s director:
                        </Text>
                    )}
                    {conflict.type == 'not-found' && (
                        <Text>
                            The director returned by the data provider <Text bold>could not be found</Text> on the
                            application:
                        </Text>
                    )}
                    {conflict.type == 'wrong-role' && (
                        <Text>
                            The director <Text bold>has been found in the application</Text> but is not marked as
                            director:
                        </Text>
                    )}
                    {conflict.type == 'typo' && (
                        <Text>
                            There seems to be a <Text bold>typo in the director's name. </Text>
                            <Text>We've highlighted the issues below.</Text>
                        </Text>
                    )}
                </>
            }
            {...props}
        >
            <Flex grow>
                <Flex column grow basis="0" noShrink>
                    {applicationDirectors.map((p, i, len) => {
                        const shouldHighlight = conflict.type == 'typo' && p.id == conflict.applicationDirectorMatch
                        const shouldWarnRoleMissmatch =
                            conflict.type == 'wrong-role' && p.id == conflict.applicationDirectorMatch
                        return (
                            <React.Fragment key={p?.name}>
                                <Card>
                                    <CardInset>
                                        <Flex justify="space-between" align="flex-start">
                                            <Flex column>
                                                {shouldHighlight ? (
                                                    <TyposHighlighter
                                                        highlights={conflict.ctx.highlights}
                                                        fallbackText={p.name}
                                                    />
                                                ) : (
                                                    <Text noWrap>{p.name}</Text>
                                                )}
                                                <Spacer height={2} />
                                                <PersonCountryRole
                                                    applicationId={applicationId}
                                                    personId={p.id}
                                                    person={p}
                                                />
                                            </Flex>
                                            {shouldWarnRoleMissmatch ? (
                                                <ConflictPill type="typo">WRONG ROLE SPECIFIED</ConflictPill>
                                            ) : null}
                                        </Flex>
                                    </CardInset>
                                </Card>
                                {i === len - 1 ? null : <Spacer height={10} />}
                            </React.Fragment>
                        )
                    })}
                </Flex>
                <Spacer width={20} />
                <GeneralConflictBoxHalf>
                    <Text bold>Data Provider director</Text>
                    <Spacer height={10} />
                    <Card grow>
                        <CardInset>
                            {conflict.type == 'typo' ? (
                                <TyposHighlighter
                                    highlights={conflict.ctx.highlights}
                                    isSourceOfTruth
                                    fallbackText={conflict.ctx.sot.name}
                                />
                            ) : (
                                <Text noWrap>{conflict.ctx.sot.name}</Text>
                            )}
                            <Spacer width={5} />
                            {conflict.ctx.sot.country ? (
                                <Flex>
                                    <Flag
                                        // eslint-disable-next-line max-len
                                        code={conflict.ctx.sot.country.toUpperCase()}
                                    />
                                    <Spacer width={5} />
                                    <Text>
                                        {countries.getName(
                                            // eslint-disable-next-line max-len
                                            conflict.ctx.sot.country.toUpperCase(),
                                            'en'
                                        )}
                                    </Text>
                                </Flex>
                            ) : (
                                <Text color="front.text.subtlerI">No country data found</Text>
                            )}
                        </CardInset>
                    </Card>
                </GeneralConflictBoxHalf>
            </Flex>
        </MerchantApplicationConflictsSection>
    )
}

const GeneralConflictBoxHalf = styled(GeneralConflictBox)`
    flex-basis: 0;
    flex-grow: 1;
    margin-top: -10px;
`
