import styled from 'styled-components'

export const ListPageSidebarTitle = styled.div`
    color: ${(p) => p.theme['side.text']};
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${(p) => p.theme['side.border']};

    &:first-child {
        margin-top: 0;
    }
`
