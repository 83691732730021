/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Text } from '../../../components/general/text'
import { Flag } from '../../../components/icons/flag'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { LoaderView } from '../../../components/loaders/loader'
import { ApplicationDataProvidersActions } from '../../../store/applicationDataProviders/actions'
import { DataPorviderStateUnassigned } from '../../../store/applicationDataProviders/types'
import { RootState } from '@/store'
import { ApplicationDataProviderPanelAssigned } from './Application.DataProviderPanel.Assigned'

export const ApplicationDataProvidersPanel: React.FC<{ id: string }> = ({ id }) => {
    const dataProvider = useSelector((state: RootState) => state.dataProviders.forApplication?.[id])
    const dispatch = useDispatch()
    const state = dataProvider?.state

    if (!state) return null
    if (state === 'loading') return <LoaderView overBackground="back.background" />
    if (state === 'assigned') return <ApplicationDataProviderPanelAssigned applicationId={id} />
    if (state === 'assigned-and-ready') return <ApplicationDataProviderPanelAssigned applicationId={id} />
    if (state === 'skipped')
        return (
            <Flex grow justify="space-between" align="center">
                <Text>Merchant has no data provider linked.</Text>{' '}
                <SimpleButton
                    background="front.accent.color"
                    onClick={() => {
                        dispatch(ApplicationDataProvidersActions.BACK_TO_SELECTION(id))
                    }}
                >
                    <ButtonInset>Link now</ButtonInset>
                </SimpleButton>
            </Flex>
        )

    if (state !== 'unassigned') throw 'Unknown ApplicationDataProvidersPanel state detected.'
    const provider = dataProvider as DataPorviderStateUnassigned

    if (!provider.matches?.list?.length)
        return (
            <Flex column>
                <Text>We could not find a company matching these criterias.</Text>
                <Spacer height={10} />
                <Text>
                    We&apos;ve searched based on <Text bold>company name, registration number and country.</Text>
                </Text>
                <Spacer height={15} />
                <SimpleButton
                    onClick={() => {
                        dispatch(ApplicationDataProvidersActions.SKIP_SELECTION(id))
                    }}
                    background="front.subtleAccent.background"
                >
                    <ButtonInset>Do this later</ButtonInset>
                </SimpleButton>
            </Flex>
        )

    return (
        <Flex column>
            <Text bold>No data provider link found</Text>
            <Text>This application has no data-provider based link, here are our suggestions.</Text>
            <Spacer height={10} />
            <Text>Please select one below:</Text>
            <div>
                {provider.matches?.list?.map((r) => {
                    return (
                        <Item key={r.id} data-cy="application-data-provider-suggestion">
                            <Flex>
                                <Flag code={r.company.country} />
                                <Spacer width={10} />
                                <Text bold oneLine>
                                    {r.company.name}
                                </Text>
                            </Flex>
                            <Spacer height={6} />
                            <Flex justify="space-between">
                                <Text>{r.company.addressLine1}</Text>
                            </Flex>
                            <Spacer height={5} />
                            <Flex align="baseline" justify="space-between">
                                <Flex>
                                    <Text bold>Registration #:&nbsp;</Text>
                                    <Text>{r.company.registrationNumbers?.join(', ')}</Text>
                                </Flex>
                                <WatcherButton
                                    background="front.accent.color"
                                    onClick={(e, watcherid) => {
                                        dispatch(ApplicationDataProvidersActions.SELECT_RESULT(id, watcherid, r.id))
                                    }}
                                >
                                    <ButtonInset padding="tiny">Link this result</ButtonInset>
                                </WatcherButton>
                            </Flex>
                        </Item>
                    )
                }, [])}
            </div>
            <Spacer height={15} />
            <SimpleButton
                onClick={() => {
                    dispatch(ApplicationDataProvidersActions.SKIP_SELECTION(id))
                }}
                background="front.subtleAccent.background"
            >
                <ButtonInset>Do this later</ButtonInset>
            </SimpleButton>
        </Flex>
    )
}

const Item = styled.div`
    border-top: 1px dashed ${(p) => p.theme['back.border.strongerI']};
    border-bottom: 1px dashed ${(p) => p.theme['back.border.strongerI']};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px 0;

    &:first-child {
        border-top: 1px dashed ${(p) => p.theme['back.border.strongerI']};
        margin-top: 20px;
    }
`

const Option = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${(p) => p.theme['back.border.strongerI']};
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: none;
    }
`

const OptionsHolder = styled.div`
    margin: 30px 0 5px 0;
`

// if (provider === undefined)
//     return (
//         <Flex column>
//             <OptionsHolder>
//                 <Option>
//                     <DataProviderLogo provider="Bisnode" />
//                     <SimpleButton
//                         background="front.accent.color"
//                         onClick={() => {
//                             setProvider('Bisnode')
//                         }}
//                     >
//                         <ButtonInset>Find in Bisnode</ButtonInset>
//                     </SimpleButton>
//                 </Option>
//                 <Option>
//                     <DataProviderLogo provider="Virk" />
//                     <SimpleButton
//                         background="front.accent.color"
//                         onClick={() => {
//                             setProvider('Virk')
//                         }}
//                     >
//                         <ButtonInset>Find in Virk</ButtonInset>
//                     </SimpleButton>
//                 </Option>
//             </OptionsHolder>
//             <SimpleButton
//                 onClick={() => {
//                     dispatch(ApplicationDataProvidersActions.SKIP_SELECTION(id))
//                 }}
//                 background="front.subtleAccent.background"
//             >
//                 <ButtonInset>Skip for now</ButtonInset>
//             </SimpleButton>
//         </Flex>
//     )
