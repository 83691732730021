import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import React from 'react'
import { useTheme } from 'styled-components'

interface SectionContainerProps {
    children: React.ReactNode
    title: string
    metadata?: React.ReactNode
}

export const SectionContainer: React.FC<SectionContainerProps> = (props) => {
    const { children, title, metadata } = props

    const theme = useTheme()

    const background = theme.name !== 'darkTheme' ? 'back.background.strongerI' : undefined

    return (
        <Card title={title} frameless background={background} rightSideMemo={metadata}>
            <CardInset>{children}</CardInset>
        </Card>
    )
}
