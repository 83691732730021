import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { LinkButton } from '../../components/buttons/linkButton'
import { MODAL_ID } from '../../components/modals/modalIds'
import { RootState } from '@/store'

import { MerchantTag } from './MerchantTag'
export const ListMerchantTags: React.FC<{
    tagsIds: string[]
    applicationId?: string
    pageId?: MODAL_ID
    inSidebar?: boolean
}> = ({ tagsIds, inSidebar, applicationId, pageId }) => {
    const tagsAt = useSelector((state: RootState) => {
        if (!applicationId) return state.applicationInternals.tags.globalTags.at
        return state.applicationInternals.tags.forApplication[applicationId].at
    })

    const allTags = useMemo(() => {
        return tagsAt
            ? tagsIds
                  ?.filter?.((tagId) => tagsAt?.[tagId]?.visible && tagsAt?.[tagId].name)
                  ?.map((tagId: string) => {
                      return tagsAt[tagId].name
                  })
            : []
    }, [tagsIds, tagsAt])

    return (
        <Holder>
            {allTags.map((t, i, arr) => {
                return (
                    <MerchantTag
                        inSidebar={inSidebar}
                        key={t}
                        suffix={i == arr.length - 1 ? undefined : ', '}
                        name={t}
                    />
                )
            })}
            {applicationId ? (
                <LinkButton
                    inline
                    to={`/merchant/${applicationId}/tags`}
                    color="side.subtleAccent.text"
                    hotkeys="alt+t"
                    cy="tags"
                    hotkeysScope={pageId}
                >
                    Edit
                </LinkButton>
            ) : null}
        </Holder>
    )
}

const Holder = styled.div`
    display: block;
    overflow-wrap: anywhere;
    white-space: normal;
`
