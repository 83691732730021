import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { List } from '../../components/layout/list'
import { ModalPage } from '../../components/layout/modalPage'
import { ModalPageInset } from '../../components/layout/modalPageInset'
import { Spacer } from '../../components/layout/spacer'
import { ModalHeader } from '../../components/modals/modalHeader'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import * as yup from 'yup'
import { Separator } from '../../components/layout/separator'
import { useForm } from '../../hooks/general/useForm'
import { FormHandler } from '../../components/forms/formHandler'
import { Text } from '../../components/general/text'
import { TypeFromArray } from '../../utils/types'
import { GatewaysDispatchCreate, GatewaysDispatchEdit } from '../../store/gateways/actions'
import { LoaderView } from '../../components/loaders/loader'
import { Gateway } from '../../store/gateways/types'
import { uppercaseFirstLetter } from '../../utils'
import { CardSection } from '../../components/cards/cardSection'
import { useGateways } from '../../hooks/general/useGateways'
import { useGoBackHook } from '../../hooks/general/useGoBackHook'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

type Params = { id: string }

const textForChange = (f: any, val: any) => {
    if (f === 'isActive') {
        if (val) return 'Yes'
        return 'No'
    }
    if (!val) return <Text color="front.text.subtlerI">-</Text>
    if (f === 'type') {
        return uppercaseFirstLetter(val).replace('psp', 'PSP').replace('Psp', 'PSP')
    }
    return val
}
const types = ['referral', 'psp', 'psp-and-referral'] as const
export const NewGateway: React.FC = () => {
    const [watcher, watcherId] = useNamedWatcher(`CreateNewVoucher`)
    const { errors, formRef, focusInvalidField, form, submitHandler, resetForm } = useForm(watcher)
    const dispatch = useDispatch()
    const gateways = useGateways()

    const params = useParams() as Params
    const { id } = params

    const isEditing = useMemo(() => {
        if (id === 'new') return false
        return true
    }, [id])

    const editingGateway = useMemo(() => {
        if (!isEditing) return undefined

        const getType = (gateway: Gateway): TypeFromArray<typeof types> | undefined => {
            if (gateway.referral && gateway.support) return 'psp-and-referral'
            if (gateway.referral) return 'referral'
            if (gateway.support) return 'psp'
            return undefined
        }

        const gateway = gateways.at[id]
        if (!gateway) return undefined

        return {
            selfLink: gateway.selfLink,
            name: gateway.name,
            type: getType(gateway),
            order: gateway.order,
            isActive: gateway.active
        }
    }, [id, gateways, isEditing])

    const handleSubmit = useCallback(
        (form: any) => {
            const getType = (type: TypeFromArray<typeof types>) => {
                switch (type) {
                    case 'psp':
                        return {
                            support: true,
                            referral: false
                        }
                    case 'referral':
                        return {
                            support: false,
                            referral: true
                        }
                    case 'psp-and-referral':
                        return {
                            support: true,
                            referral: true
                        }
                    default:
                        return {
                            support: false,
                            referral: false
                        }
                }
            }
            if (isEditing && editingGateway)
                dispatch(
                    GatewaysDispatchEdit(watcherId, editingGateway.selfLink, {
                        name: form.data.name,
                        ...getType(form.data.type),
                        order: form.data.order,
                        active: form.data.isActive
                    })
                )
            else
                dispatch(
                    GatewaysDispatchCreate(watcherId, {
                        name: form.data.name,
                        ...getType(form.data.type),
                        order: form.data.order,
                        active: form.data.isActive
                    })
                )
        },
        [dispatch, watcherId, isEditing, editingGateway]
    )

    const location = useLocation()
    const backToUrl = useMemo(() => {
        if (location.search) {
            return `/more/gateways${location.search}`
        }

        return `/more/gateways`
    }, [location.search])

    const { goBack } = useGoBackHook(backToUrl)
    const navigate = useNavigate()
    useEffect(() => {
        if (watcher === 'success') goBack()
    }, [watcher, navigate, goBack])

    const type = useMemo(() => {
        return {
            options: types,
            textForItem: (t: TypeFromArray<typeof types>) => {
                switch (t) {
                    case 'referral':
                        return 'Referral'
                    case 'psp':
                        return 'PSP'
                    case 'psp-and-referral':
                        return 'PSP & Referral'
                }
            }
        }
    }, [])

    const isActive = useMemo(() => {
        return {
            options: [true, false],
            textForItem: (e: boolean) => {
                if (e === true) return 'Active'
                return 'Inactive'
            }
        }
    }, [])

    if (isEditing && gateways.loadingStatus !== 'done') return <LoaderView overBackground="overlay.background" />

    return (
        <ModalPage title="Add new gateway" pageId="Manage.Gateways.New" backTo={backToUrl}>
            <ModalHeader
                title={isEditing ? 'Edit gateway' : 'Add a gateway'}
                pageId="Manage.Gateways.New"
                backTo={backToUrl}
            />
            <ModalPageInset>
                <Flex justify="center" align="center" grow column>
                    <Card higher title="Gateway details">
                        <CardInset>
                            <List
                                background="front.background"
                                items={{
                                    'Name': {
                                        type: 'input',
                                        placeholder: '-',
                                        overBackground: 'front.background',
                                        validation: errors.name,
                                        initialValue: editingGateway?.name,
                                        ref: (ref) => formRef(ref, 'name', yup.string().required())
                                    },
                                    'Type': {
                                        type: 'select',
                                        placeholder: '-',
                                        overBackground: 'front.background',
                                        items: type.options as any,
                                        dropdownId: 'Manage.Gateways.New',
                                        selected: editingGateway?.type,
                                        textForItem: type.textForItem,
                                        validation: errors.type,
                                        ref: (ref) => formRef(ref, 'type', yup.string())
                                    },
                                    'Order': {
                                        type: 'input',
                                        placeholder: '-',
                                        overBackground: 'front.background',
                                        validation: errors.order,
                                        initialValue: editingGateway?.order || 9999,
                                        ref: (ref) => formRef(ref, 'order', yup.string().required())
                                    },
                                    'Separator 1': {
                                        key: 'separator-1',
                                        type: 'separator',
                                        node: <Separator />
                                    },
                                    'Status': {
                                        type: 'select',
                                        placeholder: '-',
                                        overBackground: 'front.background',
                                        items: isActive.options,
                                        isQuestion: true,
                                        noClear: true,
                                        dropdownId: 'Manage.Vouchers.New.InstantFlow',
                                        textForItem: isActive.textForItem,
                                        selected: editingGateway ? editingGateway.isActive : true,
                                        validation: errors.isActive,
                                        ref: (ref) => formRef(ref, 'isActive', yup.string())
                                    }
                                }}
                                switchToRowsAt={10000}
                                cellHorizontalTemplate="120px minmax(auto, 200px)"
                            />
                            <Spacer height={20} />
                            <CardSection background="subtleBlue">
                                <CardInset>
                                    <Flex column align="flex-end">
                                        <FormHandler
                                            errors={errors}
                                            textForFieldName={(t) => t}
                                            formSubmitLabel={isEditing ? 'Edit gateway' : 'Add gateway'}
                                            focusInvalidField={focusInvalidField}
                                            changes={form.changes}
                                            textForChange={textForChange}
                                            submitForm={submitHandler(handleSubmit)}
                                            submitWatcherId={watcherId}
                                            resetForm={resetForm}
                                        />
                                    </Flex>
                                </CardInset>
                            </CardSection>
                        </CardInset>
                    </Card>
                    <Spacer height={120} />
                </Flex>
            </ModalPageInset>
        </ModalPage>
    )
}
