import React, { useEffect, useState, createRef, useRef, MutableRefObject, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { createPopper } from '@popperjs/core'
import { zIndexes } from '../../styles/zIndexes'
import { Text } from './text'
import { BoxShadows } from '../../styles/boxShadows'

export type TooltipContent = React.ReactNode | string

interface TooltipProps {
    element: MutableRefObject<any>
    children: TooltipContent
    tooltip?: boolean
    title?: string
    show?: boolean
    keepShowing?: boolean
    selectable?: boolean
    style?: any
    tooltipTiming?: 'instant' | 'long'
    direction?: 'top' | 'auto'
}

export const Tooltip: React.FC<TooltipProps> = ({ tooltipTiming = 'instant', ...p }) => {
    const [showDescription, setShowDescription] = useState(false)
    const [delay, setDelay] = useState<any>()
    const tooltipRef = createRef<any>()
    const isMounted = useRef(true)

    const [isIn, setIsIn] = useState({
        tooltip: false,
        trigger: false
    })

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (p.show != undefined) setShowDescription(p.show)
    }, [p.show])

    useEffect(() => {
        if (!isIn.tooltip && !isIn.trigger)
            setDelay((p: any) => {
                clearTimeout(delay)
                if (isMounted.current)
                    return setTimeout(() => {
                        if (isMounted.current) setShowDescription(false)
                    }, 120)
            })
        else {
            let timing = 120
            if (tooltipTiming == 'long') timing = 800
            setDelay((p: any) => {
                clearTimeout(delay)
                if (isMounted.current)
                    return setTimeout(() => {
                        if (isMounted.current) setShowDescription(true)
                    }, timing)
            })
        }
    }, [isIn])

    useEffect(() => {
        if (!p.element?.current) return
        const show = () => {
            if (isMounted?.current)
                setIsIn((isIn: any) => {
                    return { ...isIn, trigger: true }
                })
        }

        const hide = () => {
            if (isMounted?.current)
                setIsIn((isIn: any) => {
                    return { ...isIn, trigger: false }
                })
        }

        const currentElement = p.element.current
        if (!p.keepShowing && p.element && currentElement) {
            currentElement.addEventListener('mouseenter', show)
            currentElement.addEventListener('mouseleave', hide)
        } else {
            hide()
        }
        return () => {
            currentElement.removeEventListener('mouseenter', show)
            currentElement.removeEventListener('mouseleave', hide)
        }
    }, [p.element])

    useLayoutEffect(() => {
        if (p.element && p.element.current && tooltipRef.current)
            createPopper(p.element.current, tooltipRef.current!, {
                strategy: 'fixed'
            })
    }, [p.element, tooltipRef])

    if (!showDescription) return null
    return (
        <Holder ref={tooltipRef}>
            <StyledTooltip
                data-cy="tooltip"
                onMouseEnter={() => {
                    if (!p.keepShowing && p.selectable) {
                        setIsIn((isIn: any) => {
                            return { ...isIn, tooltip: true }
                        })
                    }
                }}
                onMouseLeave={() => {
                    if (!p.keepShowing && p.selectable) {
                        setIsIn((isIn: any) => {
                            return { ...isIn, tooltip: false }
                        })
                    }
                }}
            >
                {React.isValidElement(p.children) ? (
                    p.children
                ) : (
                    <Text size="s" bold>
                        {p.children}
                    </Text>
                )}
            </StyledTooltip>
        </Holder>
    )
}

const Holder = styled.div<any>`
    position: absolute;
    z-index: ${zIndexes.popoverLevel3};
`

const StyledTooltip = styled.div`
    display: block;
    background-color: #000;
    border-radius: 6px;
    color: #fff;
    padding: 6px 9px;
    box-shadow: ${BoxShadows.dropdown};
    line-height: 0;

    a {
        color: #fff;
        text-decoration: underline;
    }

    a:hover {
        color: #fff;
        opacity: 0.7;
    }

    * {
        color: #fff;
    }
`
