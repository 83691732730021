import React, { useMemo } from 'react'

import { Flex } from '../../components/layout/flex'
import { List } from '../../components/layout/list'
import { Spacer } from '../../components/layout/spacer'
import { Table, TableRowType } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { TimelineCard } from '../../components/listPages/timelineCard'
import { Grid } from '../../components/layout/grid'
import { Settlement } from '../../store/settlements/types'
import { DateToWords, numberWithCurrency } from '../../utils'
import { useDispatch } from 'react-redux'
import { ExportButton } from '../../components/buttons/exportButton'
import { SettlementStatus } from '../../components/complexComponents/settlementStatus'
import { SettlementCardPayoutDetails } from './Settlements.IDCardPayoutDetails'

export const SettlementCardSummary: React.FC<{ settlement: Settlement }> = ({ settlement }) => {
    const dispatch = useDispatch()
    const c = useMemo(
        () => [
            {
                text: '',
                key: 'stat'
            },
            {
                text: '',
                alignRight: true,
                key: 'amount'
            }
        ],
        []
    )

    const r = useMemo(() => {
        const rows: any = {
            'Sales': settlement.summary.sales,
            'Credits': settlement.summary.credits,
            'Refunds': settlement.summary.refunds,
            'Chargebacks': settlement.summary.chargebacks,
            'Fees': settlement.summary.fees,
            'Other postings': settlement.summary.otherPostings
        }

        return [
            ...Object.keys(rows).map((key) => ({
                type: 'normal' as TableRowType,
                key,
                noAction: true,
                items: [
                    {
                        node: (
                            <Text noWrap color={rows[key] ? 'front.text' : 'front.text.strongerI'}>
                                {key}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Flex>
                                <Text noWrap color={rows[key] ? 'front.text' : 'front.text.strongerI'}>
                                    {numberWithCurrency(settlement.currency, rows[key])}
                                </Text>
                                <Spacer width={10} />
                                <Text noWrap color={rows[key] ? 'front.text' : 'front.text.strongerI'}>
                                    {settlement.currency}
                                </Text>
                            </Flex>
                        )
                    }
                ]
            })),
            {
                type: 'normal' as TableRowType,
                key: 'total',
                noAction: true,
                items: [
                    {
                        node: <b>Total</b>
                    },
                    {
                        node: (
                            <Flex>
                                <b>{numberWithCurrency(settlement.currency, settlement.summary.net)}</b>
                                <Spacer width={10} />
                                <b>{settlement.currency}</b>
                            </Flex>
                        )
                    }
                ]
            }
        ]
    }, [settlement])

    const exportMemo = useMemo(() => {
        return (
            <Flex grow justify="flex-end">
                <ExportButton resourceLink={settlement.selfLink} availableFormats={['pdf', 'excel', 'csv']} />
            </Flex>
        )
    }, [settlement])

    return (
        <TimelineCard title="Settlement summary" rightSideMemo={exportMemo}>
            <Spacer height={20} />
            <Grid horizontalTemplate="max-content 20px auto" verticalTemplate="auto auto auto" switchToRowsAt={900}>
                <Flex column>
                    <List
                        background="front.background"
                        items={{
                            'Merchant': {
                                type: 'custom',
                                node: <Text bold>{settlement.account.name}</Text>
                            },
                            'Account': settlement.account.merchantId,
                            'Currency': settlement.currency,
                            'Start date': DateToWords(settlement.period?.startDate, true),
                            'End date': settlement.period.endDate ? DateToWords(settlement.period.endDate, true) : '-',
                            'Status': {
                                type: 'custom',
                                node: <SettlementStatus s={settlement} />
                            }
                        }}
                        switchToRowsAt={10000}
                        cellHorizontalTemplate="140px auto"
                    />
                </Flex>
                <span />
                <Flex grow column align="stretch">
                    <Table background="front.background" cols={c} noHeader rows={r} columnLayout="auto min-content" />
                </Flex>
            </Grid>
            <SettlementCardPayoutDetails s={settlement} />
        </TimelineCard>
    )
}
