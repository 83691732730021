import React from 'react'
import { MerchantAccount } from '@/store/merchantAccounts/types'
import { safeWindowOpen } from '@/utils'
import { Dropdown, Flex, Text } from '@/design-system'
import { startCase } from 'lodash'

const MIDGARD_ROOT = import.meta.env.VITE_MIDGAARD_LINK

interface MerchantSidebarLinksAccountsProps {
    accounts: MerchantAccount[]
}

export const MerchantSidebarLinksAccounts: React.FC<MerchantSidebarLinksAccountsProps> = (props) => {
    const { accounts } = props

    const handleOnAccountSelect = (id: string) => () => {
        const link = `${MIDGARD_ROOT}/redirect/home/account/${id}`

        safeWindowOpen(link)
    }

    const hasAccounts = accounts?.length > 0
    const hasSingleAccount = accounts?.length === 1

    if (!hasAccounts) {
        return null
    }

    if (hasSingleAccount) {
        const account = accounts[0]

        return <Dropdown.Item onSelect={handleOnAccountSelect(account.id)}>Account</Dropdown.Item>
    }

    return (
        <Dropdown.Sub>
            <Dropdown.SubTrigger>Accounts</Dropdown.SubTrigger>
            <Dropdown.Portal>
                <Dropdown.SubContent>
                    {accounts.map((account) => (
                        <Dropdown.Item key={account.id} onSelect={handleOnAccountSelect(account.id)}>
                            <Flex direction="column">
                                <Text>{account.merchantId}</Text>
                                <Flex gap="1">
                                    <Text color="neutral">{account.currency}</Text>
                                    <Text color="neutral">·</Text>
                                    <Text color="neutral">{startCase(account.metadata.state)}</Text>
                                </Flex>
                            </Flex>
                        </Dropdown.Item>
                    ))}
                </Dropdown.SubContent>
            </Dropdown.Portal>
        </Dropdown.Sub>
    )
}
