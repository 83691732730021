import { VariantProps, cva } from 'class-variance-authority'

export type TriggerStyles = VariantProps<typeof styles.trigger>
export type ContentStyles = VariantProps<typeof styles.content>

export const styles = {
    trigger: cva(
        [
            'flex',
            'items-center',
            'justify-between',
            'h-5',
            'w-full',
            'text-md',
            'disabled:cursor-not-allowed',
            'disabled:opacity-50',
            'dark:bg-gray-900',
            'dark:caret-white',
            'dark:text-neutral-200'
        ],
        {
            variants: {
                variant: {
                    default: 'dark:bg-gray-900 dark:caret-white dark:text-neutral-200',
                    surface:
                        'shadow-sm focus:!shadow-sm focus:border-primary-600 dark:focus:border-primary-600 py-3.5 px-4 rounded-xl bg-transparent border border-neutral-200 dark:border-neutral-700 placeholder:text-neutral-500 dark:caret-white dark:text-neutral-200'
                }
            }
        }
    ),

    content: cva([
        'z-[2000000]',
        'relative',
        'min-w-40',
        'max-h-[var(--radix-select-content-available-height)]',
        'w-full',
        'overflow-hidden',
        'rounded-xl',
        'bg-white',
        'focus:bg-white',
        'border',
        'border-neutral-100',
        'shadow-sm',
        'outline-none',
        'dark:bg-gray-900',
        'dark:focus:bg-gray-900',
        'dark:border-gray-600'
    ]),
    viewport: cva(['w-full', 'h-[var(--radix-select-trigger-height)]', 'min-w-[var(--radix-select-trigger-width)]']),
    scrollDownButton: cva([
        'absolute',
        'bottom-0',
        'flex',
        'w-full',
        'items-center',
        'justify-center',
        'pt-1',
        'pb-2',
        'bg-white',
        'dark:bg-gray-900',
        'z-50'
    ]),
    scrollUpButton: cva([
        'absolute',
        'top-0',
        'flex',
        'w-full',
        'items-center',
        'justify-center',
        'pt-2',
        'pb-1',
        'bg-white',
        'dark:bg-gray-900',
        'z-50'
    ]),
    item: cva([
        'relative',
        'flex',
        'basis-full',
        'cursor-default',
        'select-none',
        'items-center',
        'rounded-xl',
        'outline-none',
        'focus:bg-neutral-50',
        'focus:dark:bg-neutral-700',
        'data-[disabled]:pointer-events-none',
        'data-[disabled]:opacity-50',
        'py-2',
        'pl-8',
        'pr-2',
        'm-2',
        'dark:text-white'
    ]),
    itemIndicator: cva(['absolute', 'left-2', 'flex', 'h-4', 'w-4', 'items-center', 'justify-center'])
}
