/* eslint-disable max-len */
export interface MessageTemplate {
    name: string
    hasBlocks: boolean
    value: string
}

export const merchantCommentTemplates: MessageTemplate[] = [
    {
        name: 'Contracts: English',
        hasBlocks: true,
        value: `*Note til supporteren: Remember to add relevant conditions (this can also be from different contract templates i.e. COVID-19 belov or from Special Merchants). Also please remember to change the amount and currency marked with \`>XX<\` in the text.*
**The contract is subject to the following conditions:**
1: Through the entire contract duration, the website has Terms & Conditions that are in accordance with EU consumer law and serves customers correspondingly. See the rules here: https://europa.eu/youreurope/citizens/consumers/shopping/index_en.htm. Terms & Conditions must be accepted by the customers before payment, and they must be clickable for the customers to read.

3-D Secure is required on all transactions from \`>315 NOK<\` and above.

As a start, there is a cap of \`>30000 NOK<\` on the monthly sale. This will have no impact on your customers' ability to place an order. The cap can be raised by contacting Clearhaus support.

Clearhaus support is always ready to answer your questions at tel. +45 82 82 22 00 or support@clearhaus.com.`
    },
    {
        name: 'Contracts: Dansk',
        hasBlocks: true,
        value: `*Note til supporteren: Remember to add relevant conditions (this can also be from different contract templates i.e. COVID-19 belov or from Special Merchants). Also please remember to change the amount and currency marked with \`>XX<\` in the text.

**Kontrakten er betinget af følgende:**
1: At der på siden findes link til handelsbetingelser, der overholder EU’s forbrugerlov. 
Se reglerne her https://europa.eu/youreurope/citizens/consumers/shopping/index_en.htm. Betingelserne skal accepteres af kunderne inden betaling, handelsbetingelserne skal være klikbare, så kunderne kan læse dem.

3-D Secure er påkrævet på alle transaktioner på \`>225 DKK<\` eller derover.

Der vil i første omgang være et loft på den månedlige omsætning på \`>30.000 DKK<\`. Dette har dog ingen indflydelse på kundernes muligheder for at lægge ordrer. Viser det sig på et senere tidspunkt, at loftet er sat for lavt, kontakter I os, hvorefter vi ser på aftalen igen. 

Clearhaus kan altid kontaktes med spørgsmål til aftalen på tlf. 82 82 22 00 eller på support@clearhaus.com.`
    },
    {
        name: 'COVID-19 addition: Dansk',
        hasBlocks: false,
        value: `Kontrakten er desuden betinget af følgende:

1: Under hele kontraktens varighed, må der ikke markedsføres med produkter kan helbrede corona.
2: Under hele kontraktens varighed, skal COVID-19 relaterede produkter overholde EU og lokale myndigheders krav.
Se evt. følgende link;
https://www.sik.dk/erhverv/produkter/personlige-vaernemidler/vejledninger-forbindelse-corona-covid-19/saelge-vaernemidler/vil-du-saelge-ansigtsmasker-din-butik-eller-webshop-tjek-dette-foerst#.

Grundet myndighedernes og card schemes indskærpede retningslinjer/regler for salg af produkter, der er relaterede til forebyggelse af eller beskyttelse mod coronavirus (COVID-19), vil vi bede dig indsende dokumentation for godkendelse af salg af COVID-19 relaterede produkter.
Den dokumentation er en kopi af CE mærket, NB nummeret som ses på produktet og klassifikation af produktet. Dokumentation sendes til: support@clearhaus.com.

Visa og Mastercard opererer med reprimander fra 25.000 EUR for salg af ovennævnte produkter, hvis de sælges den den fornødne godkendelse. Såfremt Clearhaus måtte blive ramt af en sådan reprimande, vil den i henhold til vores kontrakt blive viderefaktureret til virksomheden.`
    },
    {
        name: 'COVID-19 addition: English',
        hasBlocks: false,
        value: `In addition the contract is subject to the following conditions:

1: Through the entire contract duration, merchants can not market products saying that it can cure corona.
2: Through the entire contract duration, COVID-19 related products must comply with the requirements of the EU and local authorities.

Due to the authorities and card schemes tightened guidelines/rules for the sale of products related to the prevention or protection against coronavirus (COVID-19), we kindly ask you to submit documentation for approval of the sale of COVID-19 related products.
The documentation we ask for is a copy of the CE mark, the NB number as seen on the product and the classification of the product. 

Documentation should be sent to: support@clearhaus.com.

Visa and Mastercard operate with non-compliance fees from EUR 25,000 for the sale of the above products if they are sold without the necessary approval. Should Clearhaus be affected by such a fine, it will be re-invoiced to the company by our contract.`
    },
    {
        name: 'AHP: Dansk besked til merchant',
        hasBlocks: true,
        value: `*Note to support: This is used for applications that are incomplete, but we are still able to offer the merchant a contract. Remember to adjust the content, change currency and apply these TAG’s in Manager: Incomplete Application and Temporary AHP.
Remember to adjust the text in \`>XX<\` so it matches the merchant.*

Hej \`>NAVN<\`

Vi har i dag sendt en kontrakt ud til \`>DIG/JER<\`.
Vi mangler dog stadig lidt information fra \`>DIG/JER<\`. Derfor vil der ikke blive udbetalt penge fra jeres Clearhauskonto til jeres bankkonto, før \`>DU/I<\` har lavet de aftalte ændringer på hjemmesiden og kontaktet Clearhaus//følgende er sendt ind til os på support@clearhaus.com:

1: (Indsæt selv mangler, fx at der skal indsendes registreringsdokumenter, adresse ID osv)… 
2: (Besked om, at \`>DU/I<\` har lavet de relevante ændringer på hjemmesiden)
3: ...

Der er i første omgang et loft på den månedlige omsætning på 3.000 DKK. Dette har dog ingen indflydelse på kundernes muligheder for at lægge ordrer.

Clearhaus kan altid kontaktes med spørgsmål til ovenstående på tlf. 82 82 22 00 eller support@clearhaus.com.`
    },
    {
        name: 'AHP: English message to the Merchant',
        hasBlocks: true,
        value: `*Note to support: This is used for applications that are incomplete, but we are still able to offer the merchant a contract. Remember to adjust the content, change currency and apply these TAG’s in Manager: Incomplete Application and Temporary AHP.
Remember to adjust the text in \`>XX\`< so it matches the merchant.*

Hi \`>NAME<\`

We have now sent out your contract to you.
However, we still need some information from you. This is why no funds will be transferred from your Clearhaus account to your bank account before you have made the changes on your website and contacted//submitted the following to Clearhaus at support@clearhaus.com:

1: (Company registration documents incl. full list of shareholders)
2: (insert additional issues, e.g. company registration documents, address ID, the website is compliant etc.)
3: …

As a start, there is a cap of 1.000 EUR on the monthly sale. This will have no impact on your customers' ability to place an order.

Clearhaus support is always ready to answer any questions you might have at tel. +45 82 82 22 00 or support@clearhaus.com.`
    },
    {
        name: '3-D Secure: Dansk besked til merchant',
        hasBlocks: true,
        value: `*Note to support: Standard text for merchants who want us to change from 3-D Secure to Strong Authentication. Remember: Before you make the changes, please check if the merchant has had many chargebacks or fraud-markings.
Remember to adjust the text in \`>XX<\` so it matches the merchant.*

Hej \`>NAVN<\`

Vi har nu som aftalt ændret reglerne for \`>DIN/JERES<\` konto, således at det er muligt at køre jeres MobilePay Online transaktioner på beløb indtil \`>XXX<\` DKK.

Du skal i denne forbindelse være opmærksom på, at den økonomiske risiko i tilfælde af misbrug ikke længere overgår til kortudstedende bank, men i stedet ligger hos jer som webshop. Det gælder alle betalinger, der er processeret uden 3-D Secure, da MobilePay Online ikke svarer til 3-D Secure.

Clearhaus kan altid kontaktes med spørgsmål til ovenstående på tlf. 82 82 22 00 eller support@clearhaus.com.`
    },
    {
        name: '3-D Secure: English message to the merchant',
        hasBlocks: true,
        value: `*Note to support: Standard text for merchants who want us to change from 3-D Secure to Strong Authentication. Remember: Before you make the changes, please check if the merchant has had many chargebacks or fraud-markings.
Remember to adjust the text in \`>XX\`< so it matches the merchant.*

Hi \`>NAME\`<

As agreed, we have now changed the rules of your account so it is possible for you to run transactions without 3-D Secure up until the amount of \`>XXX\`< EUR.

Please be aware that this means that the financial risk passes from the issuing bank to your webshop on all transactions processed without 3-D Secure.

Clearhaus support is always ready to answer your questions at tel. +45 82 82 22 00 or support@clearhaus.com.`
    },
    {
        name: 'Inactive account: Dansk',
        hasBlocks: true,
        value: `**Makro i Zendesk:** *Din Clearhaus konto har været inaktiv*
**Note to support:** *Remember to adjust the text in >XX< so it matches the merchant.*

Hej \`>Navn<\`

Vi har forsøgt at kontakte \`>DIG/JER<\` pr. telefon uden held.

Årsagen til at vi har ringet er, at det er noget tid siden, jeres indløsningsaftale hos Clearhaus har været benyttet.
Vi vil derfor høre, om der er noget vi kan hjælpe med, så I kommer godt igang igen.

Clearhaus kan altid kontaktes i hverdage mellem kl. 9 og 21 og lørdage mellem kl 10 og 16 på tlf. 82 82 22 00, samt på support@clearhaus.com.

Vi glæder os til at høre fra jer igen.`
    },
    {
        name: 'Inactive account: English',
        hasBlocks: true,
        value: `**Makro i Zendesk:** *Your Clearhaus account has been inactive*
**Note til supporteren:** *Remember to adjust the text in >XX< so it matches the merchant.*
Hi \`>NAME\`<

We have tried reaching you via phone, unfortunately without luck.

The reason we are reaching out to you now is that it has been some time since you have used your Clearhaus account.
Please let us know if there is anything we can do to help you or your business.

You can always reach us by phone: Weekdays between 9 AM - 9 PM and Saturdays between 10 AM and 4 PM at +45 82 82 22 00. We are also reachable at support@clearhaus.com.

We are looking forward to hearing from you again.`
    },
    {
        name: 'Change of Gateway: Dansk',
        hasBlocks: true,
        value: `**Makro i Zendesk:** *Ændring af Gateway*
**Note til supporteren:** *Remember to adjust the text in >XX< so it matches the merchant.*

Hej \`>NAVN<\`

Tak for \`>DIN/JERES<\` henvendelse om, at I har skiftet gateway.
Vi har nu ændret gateway i \`>DIN/JERES<\` Clearhaus-aftale.

Husk, at \`>DU/I<\` altid selv skal kontakte den tidligere gateway og opsige aftalen med dem.`
    },
    {
        name: 'Change of Gateway: English',
        hasBlocks: true,
        value: `**Makro i Zendesk:** *Change of Gateway*
**Note til supporteren:** *Remember to adjust the text in >XX< so it matches the merchant.*

Hi \`>NAVN<\`

Thank you for informing us about the change of your Payment Gateway.
We have now applied this change to your Clearhaus account.

Please remember that you yourself always have to contact your old Payment Gateway to cancel the old agreement.`
    }
]
