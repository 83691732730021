/* eslint-disable max-len */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import moment from 'moment'

export const useMerchantApplicationAllComments = (applicationId: string) => {
    const commentsState = useSelector(
        (state: RootState) => state.applicationResources?.comments?.forApplication?.[applicationId]
    )

    return useMemo(() => {
        if (!commentsState) return []
        const comments: any = []
        Object.keys(commentsState).forEach((sectionKey: any) => {
            const subsections = (commentsState as any)?.[sectionKey]?.fields
            if (!subsections) return
            const arrayedSubsections = subsections.length
                ? subsections
                : Object.keys(subsections).map((k) => subsections[k])
            arrayedSubsections?.forEach?.((allFields: any, i: number) => {
                // Takes the comments from most of the application resources
                // except the ones that are a few levels deep
                Object.keys(allFields).forEach((fieldKey) => {
                    if (allFields?.[fieldKey]?.comments)
                        allFields?.[fieldKey]?.comments?.forEach?.((comment: any) => {
                            comments.push({
                                comment,
                                path: `${sectionKey}.${i}.${fieldKey}`
                            })
                        })
                    else {
                        // Takes the comments from the application.signer + application.businessModel + application.bankAccount
                        // as this one is stored 2 levels deep, to match the JSON+HAL structure
                        Object.keys(allFields?.[fieldKey]).forEach((fieldNestedKey) => {
                            if (allFields?.[fieldKey]?.[fieldNestedKey]?.comments)
                                allFields?.[fieldKey]?.[fieldNestedKey]?.comments?.forEach?.((comment: any) => {
                                    comments.push({
                                        comment,
                                        path: `${sectionKey}.${i}.${fieldKey}.${fieldNestedKey}`
                                    })
                                })
                            else {
                                // Takes the comments from the gateway resources
                                // as this one is stored 3 levels deep, to match the JSON+HAL structure
                                Object.keys(allFields?.[fieldKey]?.[fieldNestedKey]).forEach((fieldNestedKeyTwo) => {
                                    allFields?.[fieldKey]?.[fieldNestedKey]?.[fieldNestedKeyTwo]?.comments?.forEach?.(
                                        (comment: any) => {
                                            comments.push({
                                                comment,
                                                path: `${sectionKey}.${i}.${fieldKey}.${fieldNestedKey}.${fieldNestedKeyTwo}`
                                            })
                                        }
                                    )
                                })
                            }
                        })
                    }
                })
            })
        })

        return comments.sort((a: any, b: any) => {
            return moment(a.comment.createdAt).isBefore(moment(b.comment.createdAt))
        })
    }, [commentsState])
}
