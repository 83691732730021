import { Box, Flex } from '@/design-system'
import { BalanceCard } from '../reconcile/common/balance-card'

interface FooterProps {
    children: React.ReactNode
}

const Footer = (props: FooterProps) => {
    const { children } = props

    return <>{children}</>
}

interface SummaryProps {
    children: React.ReactNode
}

const Summary = (props: SummaryProps) => {
    const { children } = props

    return <Flex>{children}</Flex>
}

interface BalanceProps {
    balance: number
    currency: string
}

const Balance: React.FC<BalanceProps> = (props) => {
    const { balance, currency } = props

    return (
        <Flex grow="1">
            <Box width="auto">
                <BalanceCard balance={balance} currency={currency} />
            </Box>
        </Flex>
    )
}

interface ActionsProps {
    children: React.ReactNode
}

const Actions: React.FC<ActionsProps> = (props) => {
    const { children } = props

    return (
        <Flex align="center" gap="3">
            {children}
        </Flex>
    )
}

Footer.Summary = Summary
Summary.Balance = Balance
Summary.Actions = Actions

export { Footer }
