import { Flex, Text } from '@/design-system'
import React from 'react'

interface HeaderTitleProps {
    title: string
    id: string
}

const HeaderTitle: React.FC<HeaderTitleProps> = (props) => {
    const { title, id } = props

    return (
        <Flex gap="2">
            <Text weight="medium">{title}</Text>
            <Text weight="medium" color="neutral">
                ({id})
            </Text>
        </Flex>
    )
}

export { HeaderTitle }
