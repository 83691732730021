import {
    AUTOCOMPLETE_CLEAR_MERCHANTS,
    AUTOCOMPLETE_CLEAR_SELECTED_AGENT,
    AUTOCOMPLETE_FETCH_SELECTED_AGENT,
    AUTOCOMPLETE_FIND_MERCHANTS,
    AUTOCOMPLETE_REMOVE_AGENTS,
    AUTOCOMPLETE_SEARCH_AGENTS,
    AUTOCOMPLETE_STORE_AGENTS,
    AUTOCOMPLETE_STORE_MERCHANTS,
    AUTOCOMPLETE_STORE_SELECTED_AGENT,
    ReducedAutocompleteActions
} from './actions'
import { AutocompleteState, InitialAutocompleteState } from './types'

export function AutocompleteReducers(
    state = InitialAutocompleteState,
    action: ReducedAutocompleteActions
): AutocompleteState {
    switch (action.type) {
        case AUTOCOMPLETE_SEARCH_AGENTS: {
            return {
                ...state,
                agents: {
                    ...state.agents,
                    loadingStatus: 'started'
                }
            }
        }
        case AUTOCOMPLETE_STORE_AGENTS: {
            if (action.dataset)
                return {
                    ...state,
                    agents: {
                        at: {},
                        all: [],
                        loadingStatus: 'done',
                        forCurrentQuery: [],
                        selectedLoadingStatus: 'done',
                        dataset: action.dataset
                    }
                }
            return {
                ...state,
                agents: {
                    ...state.agents,
                    forCurrentQuery: action.agents.map((a) => a.id),
                    at: {
                        ...state.agents.at,
                        ...action.agents.reduce((a, i) => {
                            return {
                                ...a,
                                [i.id]: {
                                    ...state.agents.at[i.id],
                                    ...i
                                }
                            }
                        }, {})
                    },
                    all: [...state.agents.all, ...action.agents.map((i) => i.id)].reduce((a, i) => {
                        if (a.includes(i)) return a
                        return [...a, i]
                    }, []),
                    loadingStatus: 'done'
                }
            }
        }
        case AUTOCOMPLETE_REMOVE_AGENTS: {
            return {
                ...state,
                agents: {
                    ...state.agents,
                    at: {},
                    all: [],
                    loadingStatus: 'not-started'
                }
            }
        }
        case AUTOCOMPLETE_FETCH_SELECTED_AGENT: {
            const newState = JSON.parse(JSON.stringify(state))
            return {
                ...newState,
                agents: {
                    ...state.agents,
                    selectedLoadingStatus: 'started'
                }
            }
        }
        case AUTOCOMPLETE_STORE_SELECTED_AGENT: {
            const newState = JSON.parse(JSON.stringify(state))
            return {
                ...newState,
                agents: {
                    ...state.agents,
                    selectedLoadingStatus: 'done'
                }
            }
        }
        case AUTOCOMPLETE_CLEAR_SELECTED_AGENT: {
            const newState = JSON.parse(JSON.stringify(state))

            return {
                ...newState,
                agents: {
                    ...state.agents,
                    selectedLoadingStatus: 'not-started'
                }
            }
        }
        case AUTOCOMPLETE_FIND_MERCHANTS: {
            return {
                ...state,
                merchants: {
                    ...state.merchants,
                    loadingStatus: 'started'
                }
            }
        }
        case AUTOCOMPLETE_STORE_MERCHANTS: {
            return {
                ...state,
                merchants: {
                    ...state.merchants,
                    forCurrentQuery: action.merchants.map((a) => a.id),
                    at: {
                        ...state.merchants.at,
                        ...action.merchants.reduce((a, i) => {
                            return {
                                ...a,
                                [i.id]: {
                                    ...state.agents.at[i.id],
                                    ...i
                                }
                            }
                        }, {})
                    },
                    all: action.merchants.reduce(
                        (a, i) => {
                            if (a.includes(i.id)) return a
                            return [...a, i.id]
                        },
                        [...state.merchants.all]
                    ),
                    atMid: action.merchants.reduce((acc: any, i) => {
                        acc[i.merchantId] = i.id
                        return acc
                    }, {}),
                    loadingStatus: 'done'
                }
            }
        }
        case AUTOCOMPLETE_CLEAR_MERCHANTS: {
            return {
                ...state,
                merchants: {
                    ...state.merchants,
                    at: {},
                    all: [],
                    loadingStatus: 'not-started'
                }
            }
        }
        default:
            return state
    }
}
