import { Box, CrossIcon, Flex, Grid, Label as DSLabel, Textarea as DSTextarea, Text } from '@/design-system'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface FormTextareaProps {
    children: React.ReactNode
}

const FormTextarea = (props: FormTextareaProps) => {
    const { children } = props

    return <>{children}</>
}

interface LabelProps {
    testid?: string
    children: string
    name: string
    margin?: 'default' | 'none'
}

const Label: React.FC<LabelProps> = (props) => {
    const { testid, children, name, margin } = props

    const margins = margin === 'default' ? '3' : '0'

    return (
        <Grid.Item align="start">
            <Box mt={margins}>
                <DSLabel testid={testid} htmlFor={name}>
                    {children}
                </DSLabel>
            </Box>
        </Grid.Item>
    )
}

interface TextareaProps {
    testid?: string
    name: string
    margin?: 'default' | 'none'
    rows?: number
    defaultValue?: string
}

const Textarea: React.FC<TextareaProps> = (props) => {
    const { testid, name, margin, rows, defaultValue } = props

    const {
        formState: { errors },
        register
    } = useFormContext<{ [x: string]: string }>()

    const margins = margin === 'default' ? '3' : '0'

    return (
        <>
            <Grid.Item columnSpan="2">
                <Box mt={margins}>
                    <Flex gap="3">
                        <DSTextarea
                            testid={testid}
                            defaultValue={defaultValue}
                            rows={rows}
                            placeholder="-"
                            {...register(name)}
                        />
                        {errors[name] ? <CrossIcon color="danger" /> : <div />}
                    </Flex>
                </Box>
            </Grid.Item>
            {errors[name] && (
                <Grid.Item columnSpan="2" columnStart="2">
                    <Text color="danger">{errors[name]?.message}</Text>
                </Grid.Item>
            )}
        </>
    )
}

FormTextarea.Label = Label
FormTextarea.Error = Error
FormTextarea.Textarea = Textarea

export { FormTextarea }
