import { Box, Text } from '@/design-system'
import React from 'react'

interface EmptyStateProps {
    children: React.ReactNode
}

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
    const { children } = props

    return (
        <Box my="3">
            <Text color="neutral" align="center">
                {children}
            </Text>
        </Box>
    )
}
