import React, { useRef } from 'react'
import styled from 'styled-components'

import { TransactionStatus } from '../../store/transactions/commonTypes'
import { Flex } from '../layout/flex'
import { Text } from '../general/text'
import { Tooltip } from '../general/tooltip'
import { Color } from '../../styles/colors'
import { Icon } from '../icons/icon'

export const TransactionStatusCode: React.FC<{ status: TransactionStatus }> = ({ status: { code, message } }) => {
    const color = code == 20000 ? ('front.success.color' as Color) : ('front.danger.color' as Color)
    const ref = useRef<any>()

    return (
        <>
            <Flex align="center" ref={ref}>
                {code == 20000 ? (
                    <CheckHolder>
                        <Icon type="checkmark" size={10} color={'front.success.color'} />
                    </CheckHolder>
                ) : (
                    <Bubble color={color} />
                )}
                <Text noWrap color={code !== 20000 ? 'front.danger.color' : 'front.text'}>
                    {code}
                </Text>
            </Flex>
            <Tooltip element={ref} tooltipTiming="instant" tooltip>
                {message || TranslateTransactionCodeToDescription(code)}
            </Tooltip>
        </>
    )
}

const CheckHolder = styled.div`
    margin-left: -3px;
    margin-right: 3px;
`

const Bubble = styled.div<{ color: Color }>`
    width: 6px;
    height: 6px;
    border-radius: 999px;
    border: 1px solid ${(p) => p.theme[p.color]};
    margin-right: 4px;
    margin-left: -2px;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        width: 2px;
        height: 2px;
        left: 2px;
        border-radius: 999px;
        background-color: red;
    }
`

export const TranslateTransactionCodeToDescription = (status: number) => {
    switch (status) {
        case 20000:
            return 'Approved'
        case 40000:
            return 'General input error'
        case 40110:
            return 'Invalid card number'
        case 40111:
            return 'Unsupported card scheme'
        case 40120:
            return 'Invalid CSC'
        case 40130:
            return 'Invalid expire date'
        case 40135:
            return 'Card expired'
        case 40140:
            return 'Invalid currency'
        case 40150:
            return 'Invalid text on statement'
        case 40190:
            return 'Invalid transaction'
        case 40200:
            return 'Clearhaus rule violation'
        case 40300:
            return '3-D Secure problem'
        case 40310:
            return '3-D Secure authentication failure'
        case 40400:
            return 'Backend problem'
        case 40410:
            return 'Declined by issuer or card scheme'
        case 40411:
            return 'Card restricted'
        case 40412:
            return 'Card lost or stolen'
        case 40413:
            return 'Insufficient funds'
        case 40414:
            return 'Suspected fraud'
        case 40415:
            return 'Amount limit exceeded'
        case 40416:
            return 'Additional authentication required'
        case 40420:
            return 'Merchant blocked by cardholder'
        case 50000:
            return 'Clearhaus error'
        default:
            return 'Unknown'
    }
}
