import { LoadingStatus } from '../../utils'

export type GlobalLinks = {
    cutter: {
        selfLink: string
        companiesLink: string
        gatewaysLink: string
        applicationsLink: string
        applicationLink: string
        priceSchemesLink: string
        assessmentsLink: string
        dashboardLink: string
        validateVoucherLink: string
        unsubmittedApplicationsLink: string
        sherlockLink: string
        autocompleteLink: string
        tagsLink: string
        tagLink: string
        userLink: string
        vouchersLink: string
    }
    mapi: any
}

export interface GlobalState {
    links: GlobalLinks | undefined
    loadingStatus: LoadingStatus
}

export const InitialRootState: GlobalState = {
    links: undefined,
    loadingStatus: 'not-started'
}
