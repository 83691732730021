import { SectionDocumentation } from '@/services/ongoing-due-diligence'

import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData, useReportSection } from '../../../provider/report-provider/report-provider'
import { UboMerchantData } from './ubo-merchant-data'

const order = [
    { key: 'name' },
    { key: 'role_owner', title: 'Owner' },
    { key: 'role_director', title: 'Director' },
    { key: 'date_of_birth' },
    { key: 'address1', title: 'Address line 1' },
    { key: 'address2', title: 'Address line 2' },
    { key: 'city' },
    { key: 'zipcode' },
    { key: 'country' }
]

export const Ubo: React.FC = () => {
    const section = useReportSection<SectionDocumentation>('documentation')
    const merchantData = useReportMerchantData()

    const owners = merchantData.people.filter((person) => person.role_owner)

    return (
        <Section>
            <Section.Content>
                <Section.Field name="ownership_ubo" data={section.ownership_ubo} />
                <Section.Spacer size="s" />
                {owners.length === 0 && <Section.DataItems data={{}} order={order} />}
                {owners.length !== 0 && <UboMerchantData owners={owners} />}
            </Section.Content>
        </Section>
    )
}
