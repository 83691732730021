import { css } from 'styled-components'

export const generateHighlightedFieldComponentStyles = (
    highlighted?: 'top' | 'bottom' | 'both',
    type?: 'first' | 'last'
): any => css`
    border-radius: 0;
    min-height: 20px;
    padding-top: 3px;
    background-color: ${(p) => highlighted && p.theme['front.highlights']};
    position: relative;

    ${highlighted === 'top' &&
    css`
        margin-top: -5px;
        padding-top: 5px;
    `};

    ${highlighted === 'bottom' &&
    css`
        margin-bottom: -5px;
        padding-bottom: 5px;
    `};

    ${type === 'first' &&
    highlighted === 'top' &&
    css`
        border-top-left-radius: 8px;
    `};

    ${type === 'first' &&
    highlighted === 'bottom' &&
    css`
        border-bottom-left-radius: 8px;
    `};

    ${type === 'first' &&
    css`
        padding-left: 10px;
        margin-left: -10px;
    `}

    ${type === 'last' &&
    highlighted === 'top' &&
    css`
        border-top-right-radius: 8px;
    `};

    ${type === 'last' &&
    highlighted === 'bottom' &&
    css`
        border-bottom-right-radius: 8px;
    `};

    ${type === 'last' &&
    css`
        padding-left: 10px;
        margin-left: -10px;
    `}

    ${highlighted === 'both' &&
    type === 'first' &&
    css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    `};

    ${highlighted === 'both' &&
    type === 'last' &&
    css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    `};
`
