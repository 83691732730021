// eslint-disable-next-line max-len
import { TMerchantApplicationResourceKey } from '../../pages/Merchant/Application/Application.StructureTypes'
import { LoadingStatus } from '../../utils'

export interface ApplicationResourceComment {
    id: string
    author: {
        email: string
    }
    createdAt: string
    body: string
    tags: string[]
}

export interface ApplicationResourceCommentWithLink {
    id: string
    author: {
        email: string
    }
    createdAt: string
    body: string
    tags: string[]
    commentsLink: string
}

export interface ApplicationResourceData {
    [fieldKey: string]: any
}

export interface ApplicationResourceCommentsResource {
    comments: ApplicationResourceComment[]
    commentsLink: string
}

interface ApplicationResourceComments {
    [fieldKey: string]: ApplicationResourceCommentsResource
}

export type ApplicationSectionItemCommentsLoadingPayload =
    | {
          resourceKey: TMerchantApplicationResourceKey
          subsectionIndex: number
          loadingStatus: 'started'
      }
    | {
          resourceKey: TMerchantApplicationResourceKey
          subsectionIndex: number
          loadingStatus: 'done'
          commentsLink: string
          comments: ApplicationResourceComment[]
      }

export interface ApplicationResourceHistory {
    at: string
    by: string
    from: string
    id: string
    to: string
}

interface ApplicationResourceHistorySet {
    [fieldKey: string]: ApplicationResourceHistory[]
}

export type MerchantApplicationResourceIndexPathString = string

export interface MerchantApplicationResourceIndexPath {
    resourceKey: TMerchantApplicationResourceKey
    fieldKey: string
    subsectionIndex?: number
    reportType?: string
}

export interface ApplicationResource<T> {
    forApplication: {
        [applicationId: string]: {
            [resourceKey in TMerchantApplicationResourceKey]: {
                fields: T[]
                selfLink?: string
                loadingStatus: LoadingStatus
                filesLoadingStatus?: LoadingStatus
                nextLink?: string
            }
        }
    }
}

export type ApplicationResourcesState = {
    data: ApplicationResource<ApplicationResourceData>
    comments: ApplicationResource<ApplicationResourceComments>
    history: ApplicationResource<ApplicationResourceHistorySet>
}

export const InitialApplicationResourcesState: ApplicationResourcesState = {
    data: {
        forApplication: {}
    },
    comments: {
        forApplication: {}
    },
    history: {
        forApplication: {}
    }
}

export const ApplicationResourceCompanyOwnershipStructure = {
    sole_director: 'The merchant is a sole proprietorship owned by the director',
    sole_not_director: 'The merchant is a sole proprietorship NOT owned by the director',
    one_or_more_25: 'One or more owners are holding a direct or indirect share of more than 25%',
    none_25: 'No owners are holding a direct or indirect share of more than 25%'
}

export const WebsiteFileTagsExcludingScreenshots = [
    'selfLink',
    'nextLink',
    'url',
    'id',
    'comment',
    'filesLink',
    'commentsLink',
    'dnsReport',
    'contentDump',
    'filesLoadingStatus',
    'check'
]
