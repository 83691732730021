import { Url } from '@/services/common'
import { useGetFiles } from '@/services/merchant-api'
import React from 'react'
import { PersonDataItems } from './person-data-items'

interface PersonFilesProps {
    filesUrl: Url
}

export const PersonFiles: React.FC<PersonFilesProps> = (props) => {
    const { filesUrl } = props

    const { isLoading, data, isError } = useGetFiles(filesUrl)

    if (isLoading || !data || isError) {
        return null
    }

    return <PersonDataItems files={data} />
}
