import { LoadingStatus } from '../../../utils'
import {
    Application,
    ApplicationStatus,
    BankAccount,
    Company
} from '../../applications/types'

export interface Related {
    name?: string
    id?: string
    state?: ApplicationStatus
    mid?: string
    matchingOn: string[]
    closedAccounts: number
}

export interface RelatedApplication {
    application: Partial<Application>
    bankAccount: Partial<BankAccount>
    company: Partial<Company>
    id: string
    matchingOn: string[]
    closedAccounts: number
}

export interface ApplicationInternalsRelatedState {
    forApplication: {
        [key: string]: {
            related?: Related[]
            loadingStatus: LoadingStatus
            nextLink?: string
            loadingNextStatus: LoadingStatus
            count: number
        }
    }
}

export const InitialApplicationInternalsRelatedState: ApplicationInternalsRelatedState = {
    forApplication: {}
}
