/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from 'deox'
import { GET, POST } from '../../../generators/networking'
import { VouchersActions } from './actions'

export const VouchersSagas = {
    *LOAD({}: ActionType<typeof VouchersActions.LOAD>) {
        yield GET({
            cutterUrl: (l) => l.vouchersLink,
            onSuccessDispatch: (r: any) => VouchersActions.STORE(r.vouchers),
            errorText: 'Failed to load vouchers.'
        })
    },
    *NEW({ payload }: ActionType<typeof VouchersActions.NEW>) {
        const getPayload = () => {
            if (payload.instantFlow) {
                const code = {
                    instant: true,
                    iat: Date.now(),
                    psi: payload.templateId
                }
                return {
                    code: encodeURIComponent(btoa(JSON.stringify(code))),
                    gateway_id: payload.pspId,
                    referral_partner_id: payload.referralId,
                    price_scheme_id: payload.templateId
                }
            } else {
                return {
                    gateway_id: payload.pspId,
                    referral_partner_id: payload.referralId
                }
            }
        }

        yield POST({
            watcher: payload.watcherId,
            cutterUrl: (l) => l.vouchersLink,
            onSuccessDispatch: (r: any) => {
                return VouchersActions.APPEND_NEW(r)
            },
            addWatcherContext: (r) => r.id,
            errorText: 'Failed to create the voucher.',
            successText: 'Voucher created successfully.',
            body: getPayload()
        })
    }
}
