import { useEffect, useState } from 'react'
import { ConvertIndexPathStringToIndexPath } from '../../../store/applicationResources/utils'
import { ConvertIndexPathToFieldDetails } from './Application.Structure'
import * as React from 'react'

type State = {
    dependenciesShowHideState: {
        [key: string]: boolean
    }
    formMounted?: boolean
}
const DependenciesContext = React.createContext<State>({ dependenciesShowHideState: {}, formMounted: false })

export const MerchantApplicationFieldDependenciesProvider: React.FC<{
    formData: { [key: string]: string }
    formMounted?: boolean
    children: any
}> = ({ formData, children, formMounted }): any => {
    const [showHideState, setShowHideState] = useState<State>({ dependenciesShowHideState: {}, formMounted: false })

    useEffect(() => {
        const showHideTempState: { [key: string]: boolean } = {}

        Object.keys(formData).forEach((key) => {
            const ip = ConvertIndexPathStringToIndexPath(key)
            if (!ip) return

            const fd = ConvertIndexPathToFieldDetails(ip)
            if (!fd) return

            if (fd.field.showOnlyIfSiblingFieldExists) {
                showHideTempState[fd.field.showOnlyIfSiblingFieldExists] =
                    !!formData[fd.field.showOnlyIfSiblingFieldExists]

                setShowHideState((s) => {
                    if (
                        JSON.stringify(s.dependenciesShowHideState) !== JSON.stringify(showHideTempState) ||
                        s.formMounted !== formMounted
                    )
                        return {
                            dependenciesShowHideState: showHideTempState,
                            formMounted: formMounted
                        }
                    return s
                })
            }
        })
    }, [formData, formMounted])

    return <DependenciesContext.Provider value={showHideState}>{children}</DependenciesContext.Provider>
}

export function useMerchantApplicationFieldDependencies(): State {
    const context = React.useContext(DependenciesContext)
    if (context === undefined) {
        throw new Error(
            // eslint-disable-next-line max-len
            'useMerchantApplicationFieldDependencies must be used within a useMerchantApplicationFieldDependenciesManager context'
        )
    }
    return context
}
