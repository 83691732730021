import { VariantProps, cva } from 'class-variance-authority'

export type TableStyles = VariantProps<typeof styles.table>
export type RowStyles = VariantProps<typeof styles.row>
export type HeadStyles = VariantProps<typeof styles.head>
export type BodyStyles = VariantProps<typeof styles.body>
export type CellStyles = VariantProps<typeof styles.cell>

export const styles = {
    table: cva(
        [
            'min-w-full',
            'divide-y-2',
            'divide-neutral-200',
            'rounded-lg',
            'text-sm',
            'dark:text-neutral-200',
            'border-spacing-0',
            'bg-transparent'
        ],
        {
            variants: {
                variant: {
                    default: '',
                    striped: ''
                },
                stripeShade: {
                    '50': '',
                    '100': ''
                },
                layout: {
                    fixed: 'table-fixed w-full',
                    auto: 'table-auto'
                }
            },
            defaultVariants: {
                layout: 'auto'
            }
        }
    ),
    row: cva(['border-none', 'only:!bg-transparent'], {
        variants: {
            variant: {
                default: '',
                striped: ['odd:bg-neutral-50', 'dark:odd:bg-gray-800']
            },
            stripeShade: {
                '50': 'odd:bg-neutral-50 hover:bg-neutral-100',
                '100': 'odd:bg-neutral-100 hover:bg-neutral-200 dark:even:bg-transparent dark:odd:bg-gray-800 dark:hover:bg-gray-900'
            }
        },
        defaultVariants: {
            variant: 'striped'
        }
    }),
    head: cva(
        [
            'whitespace-nowrap',
            'px-4',
            'py-1',
            'text-left',
            'text-[9px]',
            'font-medium',
            'text-neutral-400',
            'bg-transparent',
            'uppercase'
        ],
        {
            variants: {
                backgroundColor: {
                    white: 'bg-white',
                    neutral: 'bg-neutral'
                },
                backgroundShade: {
                    '50': 'bg-neutral-50',
                    '100': 'bg-neutral-100'
                },
                justify: {
                    start: 'text-left',
                    end: 'text-right',
                    center: 'text-center'
                },
                width: {
                    'auto': 'w-auto',
                    'full': 'w-full',
                    '1/2': 'w-1/2',
                    '1/3': 'w-1/3',
                    '2/3': 'w-2/3',
                    '1/4': 'w-1/4',
                    '2/4': 'w-2/4',
                    '3/4': 'w-3/4',
                    '1/5': 'w-1/5',
                    '2/5': 'w-2/5',
                    '3/5': 'w-3/5',
                    '4/5': 'w-4/5',
                    '1/6': 'w-1/6',
                    '2/6': 'w-2/6',
                    '3/6': 'w-3/6',
                    '4/6': 'w-4/6',
                    '5/6': 'w-5/6',
                    '1/12': 'w-1/12',
                    '2/12': 'w-2/12',
                    '3/12': 'w-3/12',
                    '4/12': 'w-4/12',
                    '5/12': 'w-5/12',
                    '6/12': 'w-6/12',
                    '7/12': 'w-7/12',
                    '8/12': 'w-8/12',
                    '9/12': 'w-9/12',
                    '10/12': 'w-10/12',
                    '11/12': 'w-11/12'
                }
            }
        }
    ),
    body: cva('border-none divide-y'),
    cell: cva('whitespace-nowrap px-4 py-2 first:rounded-l-lg last:rounded-r-lg', {
        variants: {
            justify: {
                start: 'text-left',
                end: 'text-right',
                center: 'text-center'
            },
            width: {
                'auto': 'w-auto',
                'full': 'w-full',
                '1/2': 'w-1/2',
                '1/3': 'w-1/3',
                '2/3': 'w-2/3',
                '1/4': 'w-1/4',
                '2/4': 'w-2/4',
                '3/4': 'w-3/4',
                '1/5': 'w-1/5',
                '2/5': 'w-2/5',
                '3/5': 'w-3/5',
                '4/5': 'w-4/5',
                '1/6': 'w-1/6',
                '2/6': 'w-2/6',
                '3/6': 'w-3/6',
                '4/6': 'w-4/6',
                '5/6': 'w-5/6',
                '1/12': 'w-1/12',
                '2/12': 'w-2/12',
                '3/12': 'w-3/12',
                '4/12': 'w-4/12',
                '5/12': 'w-5/12',
                '6/12': 'w-6/12',
                '7/12': 'w-7/12',
                '8/12': 'w-8/12',
                '9/12': 'w-9/12',
                '10/12': 'w-10/12',
                '11/12': 'w-11/12'
            }
        }
    })
}
