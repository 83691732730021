import React, { useCallback, useMemo, useState } from 'react'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Flex } from '../../../components/layout/flex'
import styled from 'styled-components'

export const MerchantApplicationFieldValueLongText: React.FC<{
    text?: string
}> = ({ text }) => {
    const [shouldShowShortText, setShowShortText] = useState(true)
    const charactersToShow = 120
    const shortText = useMemo(() => {
        return `${text?.substr(0, charactersToShow)}...`
    }, [text])

    const handleClick = useCallback(() => {
        setShowShortText((s) => !s)
    }, [setShowShortText])

    if ((text?.length || 0) < charactersToShow) return <Value>{text}</Value>

    return (
        <Flex column align="flex-start">
            <Value>{shouldShowShortText ? shortText : text}</Value>
            <SimpleButton
                color="front.accent.color"
                onClick={handleClick}
                cy={shouldShowShortText ? 'task-expand' : 'task-collapse'}
            >
                <ButtonInset leftAlign noHorizontalPadding>
                    {shouldShowShortText ? 'Expand' : 'Collapse'}
                </ButtonInset>
            </SimpleButton>
        </Flex>
    )
}

const Value = styled.span`
    white-space: normal;
`
