import { ButtonInset } from "@/components/buttons/buttonInset"
import { ButtonLoadingCover } from "@/components/buttons/buttonLoadingCover"
import { SimpleButton } from "@/components/buttons/simpleButton"
import { Flex } from "@/components/layout/flex"
import { Spacer } from "@/components/layout/spacer"
import { useDispatch } from "react-redux"

export const FetchMoreButton: React.FC<{
    actions: any
    loadingStatus: string, 
}> = ({ actions, loadingStatus }) => {
    const dispatch = useDispatch()
    return (
        <>
            <Spacer width={10} />
            <Flex align="center" column>
                <SimpleButton
                    background="front.background"
                    onClick={() => {
                        dispatch(actions)
                    }}
                >
                    <ButtonInset>
                        <ButtonLoadingCover
                            background="front.background"
                            shouldDisplayDirectly={loadingStatus === 'started'}
                        >
                            Fetch more
                        </ButtonLoadingCover>
                    </ButtonInset>
                </SimpleButton>
            </Flex>
            <Spacer width={20} />
        </>
    )
}
