import { useUnreconcilePosting } from '@/services/reconciliation'
import { useReconciliationParams } from '.'

export const useUnreconcilePostingActions = () => {
    const { id: postingId } = useReconciliationParams()

    const [unreconcilePosting, unreconciliationResult] = useUnreconcilePosting()
    const isLoading = unreconciliationResult.isLoading

    const unreconcile = async () => {
        const unreconciliation = await unreconcilePosting({ postingId }).unwrap()

        return { unreconciliation }
    }

    return { isLoading, unreconcile }
}
