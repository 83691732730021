import { produce } from 'immer'
import { returnUnique, returnWithout } from '../../utils'
import { TQLConverter } from '../../utils/tqlConverter'
import {
    ReducedSettlementsActions,
    SETTLEMENT_STORE,
    SETTLEMENT_UNSTORE,
    SETTLEMENTS_CLEAR_SUMMARIES,
    SETTLEMENTS_LOAD_SUMMARIES,
    SETTLEMENTS_STORE_MERCHANT_DETAILS,
    SETTLEMENTS_LOAD_TRANSACTIONS,
    SETTLEMENTS_STORE_SUMMARIES,
    SETTLEMENTS_STORE_TRANSACTIONS,
    SETTLEMENT_STORE_RELATED_LINK
} from './actions'
import { InitialSettlementsState, SettlementsState } from './types'

export function SettlementsReducer(
    state = InitialSettlementsState,
    action: ReducedSettlementsActions
): SettlementsState {
    switch (action.type) {
        case SETTLEMENTS_LOAD_SUMMARIES: {
            const query = TQLConverter.settlements(action.filters)
            return {
                ...state,
                settlementsSummaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'started',
                    forQuery: import.meta.env.VITE_API_ROOT + '/settlements' + query
                }
            }
        }
        case SETTLEMENTS_CLEAR_SUMMARIES: {
            return {
                ...state,
                settlementsSummaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'not-started'
                }
            }
        }
        case SETTLEMENT_STORE: {
            return {
                ...state,
                settlements: {
                    ...state.settlements,
                    at: {
                        ...state.settlements.at,
                        [action.settlement.id]: {
                            ...state.settlements.at[action.settlement.id],
                            ...action.settlement,
                            loadingStatus: 'done'
                        }
                    },
                    all: returnUnique(state.settlements.all, action.settlement.id)
                }
            }
        }
        case SETTLEMENT_UNSTORE: {
            const newState = JSON.parse(JSON.stringify(state)) as SettlementsState
            delete newState.settlements.at[action.settlementId]
            newState.settlements.all = returnWithout(newState.settlements.all, action.settlementId)
            return newState
        }
        case SETTLEMENTS_STORE_MERCHANT_DETAILS: {
            return {
                ...state,
                settlements: {
                    ...state.settlements,
                    at: {
                        ...state.settlements.at,
                        [action.payload.settlementId]: {
                            ...state.settlements.at[action.payload.settlementId],
                            merchantDetails: {
                                account: { ...action.payload.account },
                                application: { ...action.payload.application },
                                loadingStatus: 'done'
                            }
                        }
                    }
                }
            }
        }
        case SETTLEMENTS_LOAD_TRANSACTIONS: {
            return {
                ...state,
                settlements: {
                    ...state.settlements,
                    at: {
                        ...state.settlements.at,
                        [action.settlementId]: {
                            ...state.settlements.at[action.settlementId],
                            transactions: {
                                all: [],
                                pagination: undefined,
                                loadingStatus: 'started'
                            }
                        }
                    }
                }
            }
        }
        case SETTLEMENTS_STORE_TRANSACTIONS: {
            return {
                ...state,
                settlements: {
                    ...state.settlements,
                    at: {
                        ...state.settlements.at,
                        [action.settlementId]: {
                            ...state.settlements.at[action.settlementId],
                            transactions: {
                                all: action.body.transactions,
                                pagination: action.body.pagination,
                                loadingStatus: 'done'
                            }
                        }
                    }
                }
            }
        }
        case SETTLEMENTS_STORE_SUMMARIES: {
            return {
                ...state,
                settlementsSummaries: {
                    ...state.settlementsSummaries,
                    at: {
                        ...action.settlementsSummaries.reduce((a, t) => {
                            return {
                                ...a,
                                [t.id]: {
                                    ...state.settlementsSummaries.at[t.id],
                                    ...t
                                }
                            }
                        }, {})
                    },
                    all: action.settlementsSummaries.map((t) => t.id),
                    pagination: { ...action.pagination } as any,
                    loadingStatus: 'done'
                }
            }
        }
        case SETTLEMENT_STORE_RELATED_LINK: {
            return produce(state, (draft) => {
                if (state.settlementsSummaries.at?.[action.settlementId]?.reserve) {
                    draft.settlementsSummaries.at[action.settlementId].reserve.linkedSettlement = action.link
                }
            })
        }
        default:
            return state
    }
}
