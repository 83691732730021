import { LoadingStatus } from '../../../utils'

export interface Agent {
    email: string
    id: string
    locale: string
    name: string
    selfLink: string
    type: 'user'
}

export interface SelectableAgent extends Agent {
    selectionLink: string
}

export interface ApplicationInternalAgentsState {
    forApplication: {
        [key: string]:
            | {
                  loadingStatus: LoadingStatus
                  agent?: Agent
                  selectable?: SelectableAgent[]
              }
            | undefined
    }
}

export const InitialApplicationInternalAgentsState: ApplicationInternalAgentsState = {
    forApplication: {}
}
