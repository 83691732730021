import { List } from '../../components/layout/list'
import { Grid } from '../../components/layout/grid'
import {
    TransactionStatusCode,
    TranslateTransactionCodeToDescription
} from '../../components/transactions/transactionStatusCode'
import { Transaction } from '../../store/transactions/types'
import { numberWithCurrency, uppercaseFirstLetter } from '../../utils'
import { Highlight } from '../../components/layout/highlight'
import { Spacer } from '../../components/layout/spacer'
import { useMemo } from 'react'
import { Transaction3DVersion } from './Transactions.ID3DVersion'

export const TransactionGridView: React.FC<{ t: Transaction }> = ({ t }) => {
    const level = useMemo(() => {
        if (!t.threedSecure) return '-'
        if (t.threedSecure === 'false') return 'No'
        return uppercaseFirstLetter(t.threedSecure)
    }, [t])

    return (
        <>
            <Grid horizontalTemplate="1fr 1fr 1fr" verticalTemplate="auto auto auto" switchToRowsAt={1100}>
                <List
                    background="front.background"
                    alignRight
                    items={{
                        'Amount': {
                            type: 'custom',
                            node: <b>{`${numberWithCurrency(t.currency, `${t.amount}`,
                                t.processedAt)} ${t.currency}`}</b>
                        },
                        'Status code': {
                            type: 'custom',
                            node: <TransactionStatusCode status={t.status} />
                        },
                        'Text on statement': `${t.textOnStatement}`
                    }}
                    switchToRowsAt={10000}
                    cellHorizontalTemplate="140px auto"
                />
                <List
                    background="front.background"
                    alignRight
                    items={{
                        'Recurring': `${t.recurring ? 'Yes' : 'No'}`,
                        ...(t?.series?.type
                            ? {
                                  'Series type': uppercaseFirstLetter(t.series.type)
                              }
                            : {}),
                        '3-D Secure': `${level}`,
                        ...(t?.threedSecure && t?.threedSecure !== 'false'
                            ? {
                                  '3-D Secure version': {
                                      type: 'custom',
                                      node: <Transaction3DVersion v={t?.['3Dsecure'] as any} />
                                  }
                              }
                            : {})
                    }}
                    switchToRowsAt={10000}
                    cellHorizontalTemplate="140px auto"
                />
                <List
                    background="front.background"
                    alignRight
                    items={{
                        Region: `${t.region ? uppercaseFirstLetter(t.region) : '-'}`,
                        ARN: `${t.arn ? t.arn : '-'}`,
                        RRN: `${t.rrn ? t.rrn : '-'}`
                    }}
                    switchToRowsAt={10000}
                    cellHorizontalTemplate="70px auto"
                />
            </Grid>
            <Spacer height={35} />
            <Highlight verticalPadding="smaller">
                <Grid
                    horizontalTemplate="minmax(0,1fr) 1fr 1fr"
                    verticalTemplate="auto auto auto"
                    switchToRowsAt={1100}
                >
                    <List
                        background="front.background"
                        items={{
                            'Status message': t.status.message
                                ? t.status.message
                                : TranslateTransactionCodeToDescription(t.status.code)
                        }}
                        switchToRowsAt={640}
                        wrap
                        cellHorizontalTemplate="110px auto"
                    />
                    <List
                        background="front.background"
                        items={{
                            Reference: t.reference ? t.reference : '-'
                        }}
                        wrap
                        switchToRowsAt={640}
                        cellHorizontalTemplate="140px auto"
                    />
                    <List
                        background="front.background"
                        items={{
                            'Transaction ID': t.id
                        }}
                        wrap
                        switchToRowsAt={640}
                        cellHorizontalTemplate="70px auto"
                    />
                </Grid>
            </Highlight>
        </>
    )
}
