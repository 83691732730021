import React from 'react'
import { Button } from '@/design-system'
import { useUnreconcileVoucherActions } from '../../../hooks'
import { useNavigate } from 'react-router-dom'

export const UnreconcileVoucher: React.FC = () => {
    const navigate = useNavigate()
    const { isLoading, unreconcile } = useUnreconcileVoucherActions()

    const handleUnreconcile = async () => {
        await unreconcile()

        navigate('/more/reconciliation-vouchers')
    }

    return (
        <Button variant="link" loading={isLoading} onClick={handleUnreconcile}>
            Unreconcile
        </Button>
    )
}
