/* eslint-disable max-len */
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Dispute } from '../../store/disputes/types'
import { numberWithCurrency, uppercaseFirstLetter } from '../../utils'
import { CardSection } from '../../components/cards/cardSection'
import { CardInset } from '../../components/cards/cardInset'
import { ExpandableCard } from '../../components/cards/expandableCard'
import { Flex } from '../../components/layout/flex'
import { MarkdownHolder } from '../../components/general/markdownHolder'
import { Text } from '../../components/general/text'
import { Card } from '../../components/cards/card'
import { Spacer } from '../../components/layout/spacer'

export const DisputeEventTimelineCard: React.FC<{
    event: Dispute['events'][0]
    shouldShowBody?: boolean
    dispute: Dispute
    hasBeenSeenBefore?: boolean
}> = ({ event, dispute, shouldShowBody, hasBeenSeenBefore }) => {
    const parseString = useCallback(
        (a: any, key: string, disputeAmount?: number, disputeCurrency?: string) => {
            if (!a[key]) return null
            return a[key].replace('<MERCHANT>', 'Merchant')
                        .replace('<DUE_AT>', dispute.dueAt)
                        .replace('<DISPUTE_AMOUNT>', !disputeCurrency || !disputeAmount ? '' : `(${numberWithCurrency(`${disputeCurrency}`, Math.abs(disputeAmount))}`)
                        .replace('<DISPUTE_CURRENCY>', !disputeCurrency || !disputeAmount ? '' : `${disputeCurrency})`)
        },
        [dispute]
    )

    const shouldExpand = useMemo(() => {
        if (hasBeenSeenBefore) return false
        const upperCasedReason = event.reason?.toUpperCase()
        if (event.event === 'rapid_dispute_resolution') return false
        if (!text.info[upperCasedReason]) return false
        return true
    }, [event, hasBeenSeenBefore])

    const headerMemo = useMemo(() => {
        return (
            <Flex justify="space-between" grow>
                <Title>{getEventReason(event, parseString, shouldShowBody)}</Title>
                <Flex>
                    <EventCode>{event.event}</EventCode>
                    <Text color="front.text.subtlerI">{event.date}</Text>
                    {shouldExpand && <Spacer width={10} />}
                </Flex>
            </Flex>
        )
    }, [event, parseString, shouldShowBody, shouldExpand])

    const renderedEventInfo = useMemo(() => {
        const upperCasedReason = event.reason?.toUpperCase()

        if (!text.info[upperCasedReason]) return null

        const explanation = parseString(text.info[upperCasedReason], 'EXPLANATION')
        const overturn = parseString(text.info[upperCasedReason], 'OVERTURN')

        return (
            <Flex column align="stretch">
                {explanation ? (
                    <>
                        <Text bold style={{ marginBottom: 10 }}>
                            {text.headings?.EXPLANATION}
                        </Text>
                        <Text>{explanation}</Text>
                    </>
                ) : null}
                {overturn ? (
                    <>
                        <Text bold style={{ marginBottom: 10, marginTop: 25 }}>
                            {text.headings?.OVERTURN}
                        </Text>
                        <Text>{overturn}</Text>
                        <Text style={{ marginTop: 15 }}>
                            Provide required documentation and refute no later than{' '}
                            <Emphasize bold>{moment(dispute.dueAt).format('DD MMM YYYY')}</Emphasize>. Otherwise this
                            dispute will be considered as accepted and marked as lost automatically.
                        </Text>
                    </>
                ) : null}
                {overturn ? (
                    <CardSection>
                        <RequiredDocs>
                            <Text size="m" bold>
                                Required documentation:
                            </Text>
                            <RequiredDocsContent>{getDocs(event.reason)}</RequiredDocsContent>
                        </RequiredDocs>
                    </CardSection>
                ) : null}
            </Flex>
        )
    }, [event, dispute, parseString])

    return (
        <CardHolder data-cy="timeline-card">
            {shouldExpand ? (
                <ExpandableCard headerMemo={headerMemo} insetSize="regular">
                    <CardInset>{renderedEventInfo}</CardInset>
                </ExpandableCard>
            ) : (
                <Card>
                    <CardInset>{headerMemo}</CardInset>
                </Card>
            )}
        </CardHolder>
    )
}

const CardHolder = styled.div`
    margin-bottom: 10px;
`

const getEventReason = (
    event: Dispute['events'][0],
    parseString: (a: any, key: string, disputeAmount?: number, disputeCurrency?: string) => any,
    shouldShowBody?: boolean
) => {
    if (shouldShowBody) {
        if (event.reason == 'unrecognised') return 'Unrecognised charge'
        return uppercaseFirstLetter(event.reason)
    }
    if (text.common[event.event.toUpperCase()]) {
        const description = parseString(text.common, event.event.toUpperCase(), event.amount, event.currency)
        return `${description.slice(0, -1)}`
    }
    return 'Unknown reason'
}

function getDocs(reason: any) {
    const docsMap = () => {
        if (reason === 'subscription_cancelled') {
            return [
                ...DocumentationSets.common,
                ...DocumentationSets.other.splice(2, 2),
                ...DocumentationSets.cancellation
            ]
        }
        if (reason === 'credit_not_processed') {
            return [...DocumentationSets.common, ...DocumentationSets.refund]
        }

        return [...DocumentationSets.common, ...DocumentationSets.other]
    }

    return docsMap()
        .map((e: any) => text.docs[e])
        .join('\n')
}

const Emphasize = styled(Text)`
    background-color: ${(p) => p.theme['front.subtleDanger.background']};
    border-radius: 4px;
    padding: 3px 5px;
    margin: 0;
    margin-right: 2px;
    color: ${(p) => p.theme['front.subtleDanger.text']};
`

const RequiredDocs = styled.div`
    background-color: rgba(0, 0, 0, 0.033);
    padding: 25px;
    align-self: stretch;
    flex-grow: 1;
    border-radius: 0 0 9px 9px;

    ul {
        padding-left: 0;
        padding-left: 16px;
        margin-bottom: 0;
    }
`

const RequiredDocsContent = styled(MarkdownHolder)`
    em {
        color: ${(p) => p.theme['back.text.subtlerI']};
    }
`

const DocumentationSets = {
    common: ['CUSTOMER_INFO', 'CUSTOMER_COMMUNICATION'],
    refund: ['REFUND'],
    cancellation: ['CANCELLATION'],
    other: ['INVOICE_COPY', 'SERVICE', 'SHIPPING', 'ACCESS_LOG']
}

const text: any = {
    docs: {
        CANCELLATION: '- Cancellation policy and proof of presenting proper cancellation terms to customer',
        REFUND: '- Refund policy and proof of refund',
        INVOICE_COPY: '- Invoice copy',
        CUSTOMER_INFO: '- Customer name and contact information _(e.g. email and phone number)_',
        CUSTOMER_COMMUNICATION: '- Customer communication _(e.g. emails, letters and order confirmation)_',
        SERVICE: '- Service date and documentation._ If an offline service was provided._',
        SHIPPING:
            '- Shipping address, date, carrier, tracking number and documentation._ If a physical product was shipped._',
        ACCESS_LOG: '- Access log._ If a digital product or service was provided._'
    },
    headings: {
        EXPLANATION: 'Explanation',
        OVERTURN: 'How to overturn dispute'
    },
    common: {
        '1ST_CHARGEBACK': 'The cardholder has **disputed this transaction**.',
        'ARBITRATION':
            "The cardholder's **bank has declined** <MERCHANT>'s response and has initiated the arbitration process.",
        'RETRIEVAL_REQUEST': "The cardholder's **bank requested documentation** about the related transaction.",
        '1ST_CHARGEBACK_REVERSAL': "The cardholder's **bank withdrew** the dispute.",
        '2ND_CHARGEBACK': "The cardholder\u2019s **bank replied** to <MERCHANT>'s documentation/evidence.",
        'REPRESENTMENT': 'The **documentation has been sent** to the cardholder\u2019s bank.',
        'DUE_WARNING':
            '<MERCHANT> has been **asked to provide required documentation** and refute no later than <DUE_AT>. Otherwise this dispute will be considered as accepted and marked as lost automatically.',
        'DISPUTE_AMOUNT_CREDIT': "The disputed amount <DISPUTE_AMOUNT> <DISPUTE_CURRENCY> **has been credited** to <MERCHANT>'s account.",
        'DISPUTE_AMOUNT_DEBIT': "The disputed amount <DISPUTE_AMOUNT> <DISPUTE_CURRENCY> **has been debited** from <MERCHANT>'s account.",
        'RAPID_DISPUTE_RESOLUTION':
            "The Pre-dispute was resolved by **VISA's Rapid Dispute Resolution System.** _(No action required)__"
    },
    info: {
        INCORRECT_AMOUNT_OR_CURRENCY: {
            TITLE: 'Incorrect amount/currency',
            EXPLANATION: 'The customer says they were presented with another amount or currency.',
            OVERTURN:
                '<MERCHANT> has been asked to demonstrate that the correct amount or currency was presented to the customer.'
        },
        DUPLICATE: {
            EXPLANATION:
                'The customer says that <MERCHANT> has charged their card multiple times for the same product or service.',
            OVERTURN:
                '<MERCHANT> has been asked to demonstrate that the charges were for separate products or services.',
            TITLE: 'Duplicate'
        },
        CREDIT_NOT_PROCESSED: {
            TITLE: 'Credit not processed',
            EXPLANATION:
                'The cardholder says that the purchased product was returned or the transaction was otherwise cancelled, but <MERCHANT> has not yet refunded or credited the cardholder.',
            OVERTURN:
                '<MERCHANT> has been asked to demonstrate that they in fact refunded the cardholder or that the cardholder is not entitled to a refund.'
        },
        GENERAL: {
            TITLE: 'General',
            EXPLANATION: 'This is an uncategorised dispute. See below comments for further explanation.'
        },
        SUBSCRIPTION_CANCELLED: {
            TITLE: 'Subscription cancelled',
            OVERTURN:
                '<MERCHANT> has been asked to demonstrate that the subscription was still active and that the cardholder was aware of, and did not follow, their cancellation procedure.',
            EXPLANATION:
                'The cardholder says that <MERCHANT> continued to charge them after a subscription was cancelled.'
        },
        PRODUCT_NOT_PROVIDED: {
            TITLE: 'Product not provided',
            EXPLANATION: 'The cardholder says they did not receive the product or service purchased.',
            OVERTURN:
                '<MERCHANT> has been asked to demonstrate that the cardholder received a physical product or offline service prior to the date the dispute was initiated, or made use of a digital product or online service prior to the date the dispute was initiated.'
        },
        PRODUCT_UNACCEPTABLE: {
            EXPLANATION:
                'The product or service was either not delivered, was defective, damaged, or not as described.',
            TITLE: 'Product unacceptable',
            OVERTURN:
                '<MERCHANT> has been asked to demonstrate that the product or service was delivered as described at the time of purchase. '
        },
        UNRECOGNISED: {
            TITLE: 'Unrecognised',
            EXPLANATION: 'The cardholder can\u2019t recognise the charge on their bank statement.',
            OVERTURN:
                '<MERCHANT> has been asked to get their customer to withdraw the dispute by helping them identify the charge.'
        },
        FRAUD: {
            TITLE: 'Fraud',
            EXPLANATION:
                'The cardholder states that they didn\u2019t authorise the charge. Typically, this happens when the card was lost/stolen or the cardholder cannot recognise the charge.',
            OVERTURN:
                '<MERCHANT> has been asked to get the cardholder to withdraw the dispute by helping them identify the charge or prove to the bank that the cardholder did authorise the charge.\r\n'
        },
        DOCUMENTATION: 'Required documentation:'
    }
}

const Title = styled(MarkdownHolder)`
    color: ${(p) => p.theme['front.text.subtlerI']};
    strong {
        font-weight: 500;
        color: ${(p) => p.theme['front.text']};
    }
    em {
        color: ${(p) => p.theme['front.text.subtlerII']};
    }
`

const EventCode = styled.div`
    padding: 1px 7px;
    background-color: ${(p) => p.theme['front.highlights']};
    border-radius: 8px;
    color: ${(p) => p.theme['front.text.subtlerI']};
    font-size: 11px;
    margin-top: 0px;
    margin-right: 7px;
`
