// This dictates the bounds we'll look for when selecting the reserves
// 0.4 -> means that 5000€, might select up until 7000€
// 0.3 -> means that 30.000€, might select up until  39.000€
// etc
const getConfidenceBoundary = (target: number) => {
    // Multiply with the decimals...
    if (target < 10000 * 100) {
        return 0.4
    }
    if (target < 50000 * 100) {
        return 0.3
    }
    if (target < 100000 * 100) {
        return 0.2
    }
    if (target < 500000 * 100) {
        return 0.1
    }
    return 0.05
}

export function* pickAndReturnIndexes(n: number, maxPicks: number, mode: 'even' | 'first' | 'last') {
    const indexes: number[] = []

    const batchSize = Math.ceil(n / maxPicks)

    switch (mode) {
        case 'first': {
            for (let i = 0; i < maxPicks && i < n; i++) {
                indexes.push(i)
            }
            return indexes
        }
        case 'last': {
            for (let i = 0; i < maxPicks && i < n; i++) {
                indexes.push(n - 1 - i)
            }
            return indexes
        }
        case 'even': {
            if (n <= maxPicks)
                for (let i = 0; i < maxPicks && i < n; i++) {
                    indexes.push(i)
                }
            else {
                for (let i = 0; i < n; i += batchSize) {
                    const randomAddup = Math.floor(Math.random() * batchSize)
                    if (i + randomAddup < n) indexes.push(i + randomAddup)
                    else indexes.push(n - 1)
                }
            }
            return indexes
        }
    }
}

export function consecutiveSumHeuristic(arr: number[], brr: number[], target: number) {
    let minRiskRatio = Number.MIN_SAFE_INTEGER
    let bestSolution: number[] = []
    for (let i = 0; i < arr.length; i++) {
        for (let j = 1; j <= arr.length; j++) {
            if (j + i > arr.length) break
            let amountSum = 0
            const solution: number[] = []
            let riskRatioSum = 0

            for (let k = 0; k < j; k++) {
                amountSum += arr[i + k]
                solution.push(i + k)
                const riskRatio = arr[i + k] / brr[i + k]
                riskRatioSum += riskRatio
                if (amountSum > target) break
            }
            const closeness = Math.abs(target - amountSum)
            if (
                closeness < target * getConfidenceBoundary(target) &&
                amountSum > target &&
                minRiskRatio < riskRatioSum
            ) {
                bestSolution = solution
                minRiskRatio = riskRatioSum
            }
        }
    }
    return bestSolution
}

export function* makeCombinations(
    array: {
        index: number
        value: number
        timeUntil: number
    }[],
    upToAmount: number
) {
    let upperBoundSolution: number[] = []
    let minRiskRatio = Number.MIN_SAFE_INTEGER

    const rec = (
        arr: {
            index: number
            value: number
            timeUntil: number
        }[],
        n: number,
        sum: number,
        riskRatioSum: number,
        selected: number[]
    ): number => {
        if (Math.abs(sum) < upToAmount * getConfidenceBoundary(upToAmount) && minRiskRatio < riskRatioSum && sum < 0) {
            minRiskRatio = riskRatioSum
            upperBoundSolution = [...selected]
        }

        if (n <= 0) return sum
        const a = rec(arr, n - 1, sum, riskRatioSum, [...selected])
        const b = rec(arr, n - 1, sum - arr[n - 1].value, riskRatioSum + arr[n - 1].value / arr[n - 1].timeUntil, [
            ...selected,
            arr[n - 1].index
        ])
        if (a < b) {
            return a
        } else {
            return b
        }
    }

    yield rec(array, array.length, upToAmount, 0, [])

    return [upperBoundSolution]
}
