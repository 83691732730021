import { Controller, useFormContext } from 'react-hook-form'

import { Radio } from '@/components/forms/radio'
import React from 'react'

interface FormRadioProps {
    name: string
    label: string
    options: string[]
    disabled?: boolean
}

export const FormRadio: React.FC<FormRadioProps> = (props) => {
    const { name, label, options, disabled = false } = props

    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => (
                <Radio
                    initialValue={value}
                    label={label}
                    options={options}
                    onChange={(_, value) => {
                        onChange(value)
                    }}
                    readOnly={disabled}
                    marginBottom={0}
                />
            )}
        />
    )
}
