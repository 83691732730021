import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { DialogContext } from '../../../contexts/dialogProvider'
// eslint-disable-next-line max-len
import { IMerchantApplicationSubsectionStructure } from './Application.StructureTypes'
import { ApplicationResourceActions } from '../../../store/applicationResources/actions'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'

export const SubsectionRemover: React.FC<{
    subsectionStructure: IMerchantApplicationSubsectionStructure
    applicationId: string
    subsectionIndex?: number
    suffix: 'person' | 'website'
    hoverZoneRef: HTMLElement
}> = ({ subsectionStructure, applicationId, subsectionIndex, suffix, hoverZoneRef }) => {
    const { setDialog } = useContext(DialogContext)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (!hoverZoneRef) return

        const show = () => {
            setShow(true)
        }
        const hide = () => {
            setShow(false)
        }

        hoverZoneRef.addEventListener('mouseenter', show)
        hoverZoneRef.addEventListener('mouseleave', hide)
        return () => {
            hoverZoneRef.removeEventListener('mouseenter', show)
            hoverZoneRef.removeEventListener('mouseleave', hide)
        }
    }, [hoverZoneRef])

    const handleRemoveClick = useCallback(
        (e, watcherId) => {
            setDialog({
                title: 'Confirm deletion',
                description: 'Are you sure you want to delete this section permanently?',
                action: {
                    label: 'Delete permanently',
                    buttonBackground: 'front.danger.color',
                    watcherId,
                    action: () => {
                        const indexPath: MerchantApplicationResourceIndexPath = {
                            resourceKey: subsectionStructure.resource,
                            subsectionIndex,
                            fieldKey: ''
                        }

                        dispatch(
                            ApplicationResourceActions.REMOVE_SUBSECTION(
                                applicationId,
                                indexPath,
                                subsectionStructure.suffix,
                                watcherId
                            )
                        )
                    }
                }
            })
        },
        [subsectionStructure, subsectionIndex, dispatch, applicationId, setDialog]
    )

    return (
        <>
            <span />
            <span />
            <Holder show={show}>
                <WatcherButton color="front.danger.color" cy="remove-section" onClick={handleRemoveClick}>
                    <ButtonInset noHorizontalPadding>Remove this {suffix}</ButtonInset>
                </WatcherButton>
            </Holder>
        </>
    )
}

const Holder = styled.div<{ show?: boolean }>`
    opacity: 0;

    ${(p) =>
        p.show &&
        css`
            opacity: 1;
        `}
`
