import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@/store'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { Card } from '../cards/card'
import { CardInset } from '../cards/cardInset'
import { Flex } from '../layout/flex'
import { MessageBox } from '../forms/messageBox'
import { Spacer } from '../layout/spacer'

export const TimelineMessageBox: React.FC<React.ComponentProps<typeof MessageBox>> = (p) => {
    const [inputRef, setInputRef] = useRefTaker()
    const markdown = useSelector((state: RootState) => state.interface.markdownPreview)

    useEffect(() => {
        if (markdown[p.pageId]) inputRef?.scrollIntoView()
    }, [markdown, p.pageId, inputRef])

    useEffect(() => {
        inputRef?.scrollIntoView()
        inputRef?.focus()
    }, [inputRef])

    return (
        <Flex grow justify="flex-end" column>
            <Spacer height={80} />
            <Card>
                <CardInset>
                    <MessageBox {...p} paddingLess textareaRef={setInputRef} />
                </CardInset>
            </Card>
            <Spacer height={20} />
        </Flex>
    )
}
