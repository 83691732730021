import * as yup from 'yup'
import { LocallyStoredDraftContract, MerchantAgreement } from '../../../store/merchantAccounts/types'

export type MerchantPageUISelectedContract =
    | {
          contract: MerchantAgreement
          isDraft: false
      }
    | {
          contract: LocallyStoredDraftContract
          isDraft: true
      }

export const BIG_SMALL_SCREEN_SWAP = 1550
export const ApplicationValidationRules = {
    formattedNumber: yup.string().required().numberWithCommas(),
    value: yup.string().required(),
    website: yup.string().required().url(),
    sanitisedValue: (length?: number) => {
        const value = yup.string().matches(/^[\x20-\x7E]*$/, 'Contains invalid characters')
        if (length) return value.test('len', `Length be less than ${length} characters`, (v: any) => v.length <= length)
        return value
    },
    optionalValue: yup.string()
}
export const ContractValidationRules = {
    formattedNumberUnrequired: yup.string().numberWithCommas(true),
    formattedNumber: yup.string().required().numberWithCommas(true),
    higherLimitedFee: yup.string().numberWithCommas(true).required().numberMax('99,999.00', 99999).numberMin('0', 0),
    higherLimitedFeeUnrequired: yup.string().numberWithCommas(true).numberMax('99,999.00', 99999).numberMin('0', 0),
    limitedFee: yup.string().numberWithCommas(true).required().numberMax('9,999.00', 9999).numberMin('0', 0),
    limitedFeeUnrequired: yup.string().numberWithCommas(true).numberMax('9,999.00', 9999).numberMin('0', 0),
    payoutDelay: yup.string().numberWithCommas().required().numberMax('90', 90).numberMin('1', 1),
    payoutDelayUnrequired: yup.string().numberWithCommas().numberMax('90', 90).numberMin('0', 0),
    limitedPercentage: yup.string().numberWithCommas(true).required().numberMax('99.99', 99.99).numberMin('0', 0),
    limitedPercentageUnrequired: yup.string().numberWithCommas(true).numberMax('99.99', 99.99).numberMin('0', 0),
    rollingReserveDelay: yup.string().numberWithCommas().required().numberMax('730', 730).numberMin('1', 1),
    rollingReserveDelayUnrequired: yup.string().numberWithCommas().numberMax('730', 730).numberMin('1', 1),
    payoutAmount: yup.string().numberWithCommas(true).required().numberMin('0.01', 0.01)
}
