import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Account } from '../../../components/general/account'
import { AgentBubble } from '../../../components/general/agentBubble'
import { CardInset } from '../../../components/cards/cardInset'
import { ExpandableCard } from '../../../components/cards/expandableCard'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { Table } from '../../../components/tables/table'
import { Text } from '../../../components/general/text'
import { MerchantTimelinePoint } from '../../../store/merchantTimeline/types'
import { uppercaseFirstLetter } from '../../../utils'
import { DateFormats } from '../../../utils/dateUtils'
import { MerchantTimelineItemDate } from './SummaryTimelineItemDate'

export const MerchantTimelineItemEdits: React.FC<{
    point: MerchantTimelinePoint
}> = ({ point }) => {
    const headerMemo = useMemo(() => {
        return (
            <Flex align="center">
                <AgentBubble id={point.by} slim />
                <Spacer width={10} />
                <Text bold>
                    <Account id={point.by} merchant />
                </Text>
                &nbsp; has made&nbsp;
                <Text bold>{point.edits.length} edits</Text>
                &nbsp;to the application.
                <MerchantTimelineItemDate point={point} />
            </Flex>
        )
    }, [point])

    const editsColumns = useMemo(() => {
        return [{ text: 'Section' }, { text: 'Field' }, { text: 'From' }, { text: 'To' }, { text: 'At' }]
    }, [])

    const formatChange = useCallback((text: any) => {
        if (!text) return '-'
        if (typeof text === 'string') return text
        if (typeof text === 'object' && Object.keys(text).length === 0) return '-'
        return JSON.stringify(text)
    }, [])

    const editsRows = useMemo(() => {
        const sortedEdits = [...point.edits]

        sortedEdits.sort((a, b) => {
            if (a.field > b.field) {
                return 1
            }
            return -1
        })

        sortedEdits.sort((a, b) => {
            if (a.section > b.section) {
                return -1
            }
            return 1
        })

        return sortedEdits.map((edit) => {
            return {
                type: 'normal' as const,
                key: edit.from,
                noAction: true,
                items: [
                    {
                        node: (
                            <Text size="s">
                                {uppercaseFirstLetter(edit.section)}{' '}
                                {(edit as any).sectionIndex ? parseInt((edit as any).sectionIndex) + 1 : ''}
                            </Text>
                        )
                    },
                    {
                        node: <Text size="s">{uppercaseFirstLetter(edit.field)}</Text>
                    },
                    {
                        node: (
                            <Text oneLine size="s">
                                {formatChange(edit.from)}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text oneLine size="s">
                                {formatChange(edit.to)}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text size="s">
                                {moment(edit.at).format(DateFormats.dayAndTimeWithSeconds(moment(edit.at)))}
                            </Text>
                        )
                    }
                ]
            } as any
        })
    }, [point])

    return (
        <ExpandableCard headerMemo={headerMemo}>
            <CardBackground>
                <CardInset>
                    <Table
                        background="back.background"
                        cols={editsColumns}
                        columnLayout="1fr 2fr 2fr 2fr 1fr"
                        type="narrow"
                        rows={editsRows}
                    />
                </CardInset>
            </CardBackground>
        </ExpandableCard>
    )
}

const CardBackground = styled.div`
    background-color: ${(p) => p.theme['back.background']};
    margin-top: -1px;
    border-radius: 0 0 8px 8px;
`
