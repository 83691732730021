export const CountriesDictionary: any = {
    '004': {
        'name': 'Afghanistan',
        'alpha-2': 'AF',
        'country-code': '004',
        'eu': 'non_eu',
        'timezone': 'Asia/Kabul'
    },
    '248': {
        'name': 'Åland Islands',
        'alpha-2': 'AX',
        'country-code': '248',
        'eu': 'non_eu',
        'timezone': 'Europe/Mariehamn'
    },
    '008': {
        'name': 'Albania',
        'alpha-2': 'AL',
        'country-code': '008',
        'eu': 'non_eu',
        'timezone': 'Europe/Tirane'
    },
    '012': {
        'name': 'Algeria',
        'alpha-2': 'DZ',
        'country-code': '012',
        'eu': 'non_eu',
        'timezone': 'Africa/Algiers'
    },
    '016': {
        'name': 'American Samoa',
        'alpha-2': 'AS',
        'country-code': '016',
        'eu': 'non_eu',
        'timezone': 'Pacific/Pago_Pago'
    },
    '020': {
        'name': 'Andorra',
        'alpha-2': 'AD',
        'country-code': '020',
        'eu': 'non_eu',
        'timezone': 'Europe/Andorra'
    },
    '024': {
        'name': 'Angola',
        'alpha-2': 'AO',
        'country-code': '024',
        'eu': 'non_eu',
        'timezone': 'Africa/Luanda'
    },
    '660': {
        'name': 'Anguilla',
        'alpha-2': 'AI',
        'country-code': '660',
        'eu': 'non_eu',
        'timezone': 'America/Anguilla'
    },
    '010': {
        'name': 'Antarctica',
        'alpha-2': 'AQ',
        'country-code': '010',
        'eu': 'non_eu',
        'timezone': 'Antarctica/McMurdo'
    },
    '028': {
        'name': 'Antigua and Barbuda',
        'alpha-2': 'AG',
        'country-code': '028',
        'eu': 'non_eu',
        'timezone': 'America/Antigua'
    },
    '032': {
        'name': 'Argentina',
        'alpha-2': 'AR',
        'country-code': '032',
        'eu': 'non_eu',
        'timezone': 'America/Argentina/Buenos_Aires'
    },
    '051': {
        'name': 'Armenia',
        'alpha-2': 'AM',
        'country-code': '051',
        'eu': 'non_eu',
        'timezone': 'Asia/Yerevan'
    },
    '553': {
        'name': 'Aruba',
        'alpha-2': 'AW',
        'country-code': '533',
        'eu': 'non_eu',
        'timezone': 'America/Aruba'
    },
    '036': {
        'name': 'Australia',
        'alpha-2': 'AU',
        'country-code': '036',
        'eu': 'non_eu',
        'timezone': 'Australia/Lord_Howe'
    },
    '040': {
        'name': 'Austria',
        'alpha-2': 'AT',
        'country-code': '040',
        'eu': 'eu',
        'timezone': 'Europe/Vienna'
    },
    '031': {
        'name': 'Azerbaijan',
        'alpha-2': 'AZ',
        'country-code': '031',
        'eu': 'non_eu',
        'timezone': 'Asia/Baku'
    },
    '044': {
        'name': 'Bahamas',
        'alpha-2': 'BS',
        'country-code': '044',
        'eu': 'non_eu',
        'timezone': 'America/Nassau'
    },
    '048': {
        'name': 'Bahrain',
        'alpha-2': 'BH',
        'country-code': '048',
        'eu': 'non_eu',
        'timezone': 'Asia/Bahrain'
    },
    '050': {
        'name': 'Bangladesh',
        'alpha-2': 'BD',
        'country-code': '050',
        'eu': 'non_eu',
        'timezone': 'Asia/Dhaka'
    },
    '052': {
        'name': 'Barbados',
        'alpha-2': 'BB',
        'country-code': '052',
        'eu': 'non_eu',
        'timezone': 'America/Barbados'
    },
    '112': {
        'name': 'Belarus',
        'alpha-2': 'BY',
        'country-code': '112',
        'eu': 'non_eu',
        'timezone': 'Europe/Minsk'
    },
    '056': {
        'name': 'Belgium',
        'alpha-2': 'BE',
        'country-code': '056',
        'eu': 'eu',
        'timezone': 'Europe/Brussels'
    },
    '084': {
        'name': 'Belize',
        'alpha-2': 'BZ',
        'country-code': '084',
        'eu': 'non_eu',
        'timezone': 'America/Belize'
    },
    '204': {
        'name': 'Benin',
        'alpha-2': 'BJ',
        'country-code': '204',
        'eu': 'non_eu',
        'timezone': 'Africa/Porto-Novo'
    },
    '060': {
        'name': 'Bermuda',
        'alpha-2': 'BM',
        'country-code': '060',
        'eu': 'non_eu',
        'timezone': 'Atlantic/Bermuda'
    },
    '064': {
        'name': 'Bhutan',
        'alpha-2': 'BT',
        'country-code': '064',
        'eu': 'non_eu',
        'timezone': 'Asia/Thimphu'
    },
    '068': {
        'name': 'Bolivia, Plurinational State of',
        'alpha-2': 'BO',
        'country-code': '068',
        'eu': 'non_eu',
        'timezone': 'America/La_Paz'
    },
    '535': {
        'name': 'Bonaire Sint Eustatius and Saba',
        'alpha-2': 'BQ',
        'country-code': '535',
        'eu': 'non_eu',
        'timezone': 'America/Kralendijk'
    },
    '070': {
        'name': 'Bosnia and Herzegovina',
        'alpha-2': 'BA',
        'country-code': '070',
        'eu': 'non_eu',
        'timezone': 'Europe/Sarajevo'
    },
    '072': {
        'name': 'Botswana',
        'alpha-2': 'BW',
        'country-code': '072',
        'eu': 'non_eu',
        'timezone': 'Africa/Gaborone'
    },
    '076': {
        'name': 'Brazil',
        'alpha-2': 'BR',
        'country-code': '076',
        'eu': 'non_eu',
        'timezone': 'America/Noronha'
    },
    '086': {
        'name': 'British Indian Ocean Territory',
        'alpha-2': 'IO',
        'country-code': '086',
        'eu': 'non_eu',
        'timezone': 'Indian/Chagos'
    },
    '096': {
        'name': 'Brunei Darussalam',
        'alpha-2': 'BN',
        'country-code': '096',
        'eu': 'non_eu',
        'timezone': 'Asia/Brunei'
    },
    '100': {
        'name': 'Bulgaria',
        'alpha-2': 'BG',
        'country-code': '100',
        'eu': 'eu',
        'timezone': 'Europe/Sofia'
    },
    '854': {
        'name': 'Burkina Faso',
        'alpha-2': 'BF',
        'country-code': '854',
        'eu': 'non_eu',
        'timezone': 'Africa/Ouagadougou'
    },
    '108': {
        'name': 'Burundi',
        'alpha-2': 'BI',
        'country-code': '108',
        'eu': 'non_eu',
        'timezone': 'Africa/Bujumbura'
    },
    '116': {
        'name': 'Cambodia',
        'alpha-2': 'KH',
        'country-code': '116',
        'eu': 'non_eu',
        'timezone': 'Asia/Phnom_Penh'
    },
    '120': {
        'name': 'Cameroon',
        'alpha-2': 'CM',
        'country-code': '120',
        'eu': 'non_eu',
        'timezone': 'Africa/Douala'
    },
    '124': {
        'name': 'Canada',
        'alpha-2': 'CA',
        'country-code': '124',
        'eu': 'non_eu',
        'timezone': 'America/St_Johns'
    },
    '132': {
        'name': 'Cape Verde',
        'alpha-2': 'CV',
        'country-code': '132',
        'eu': 'non_eu',
        'timezone': 'Atlantic/Cape_Verde'
    },
    '136': {
        'name': 'Cayman Islands',
        'alpha-2': 'KY',
        'country-code': '136',
        'eu': 'non_eu',
        'timezone': 'America/Cayman'
    },
    '140': {
        'name': 'Central African Republic',
        'alpha-2': 'CF',
        'country-code': '140',
        'eu': 'non_eu',
        'timezone': 'Africa/Bangui'
    },
    '148': {
        'name': 'Chad',
        'alpha-2': 'TD',
        'country-code': '148',
        'eu': 'non_eu',
        'timezone': 'Africa/Ndjamena'
    },
    '152': {
        'name': 'Chile',
        'alpha-2': 'CL',
        'country-code': '152',
        'eu': 'non_eu',
        'timezone': 'America/Santiago'
    },
    '156': {
        'name': 'China',
        'alpha-2': 'CN',
        'country-code': '156',
        'eu': 'non_eu',
        'timezone': 'Asia/Shanghai'
    },
    '162': {
        'name': 'Christmas Island',
        'alpha-2': 'CX',
        'country-code': '162',
        'eu': 'non_eu',
        'timezone': 'Indian/Christmas'
    },
    '166': {
        'name': 'Cocos (Keeling) Islands',
        'alpha-2': 'CC',
        'country-code': '166',
        'eu': 'non_eu',
        'timezone': 'Indian/Cocos'
    },
    '170': {
        'name': 'Colombia',
        'alpha-2': 'CO',
        'country-code': '170',
        'eu': 'non_eu',
        'timezone': 'America/Bogota'
    },
    '174': {
        'name': 'Comoros',
        'alpha-2': 'KM',
        'country-code': '174',
        'eu': 'non_eu',
        'timezone': 'Indian/Comoro'
    },
    '178': {
        'name': 'Congo',
        'alpha-2': 'CG',
        'country-code': '178',
        'eu': 'non_eu',
        'timezone': 'Africa/Brazzaville'
    },
    '180': {
        'name': 'Congo the Democratic Republic of the',
        'alpha-2': 'CD',
        'country-code': '180',
        'eu': 'non_eu',
        'timezone': 'Africa/Kinshasa'
    },
    '184': {
        'name': 'Cook Islands',
        'alpha-2': 'CK',
        'country-code': '184',
        'eu': 'non_eu',
        'timezone': 'Pacific/Rarotonga'
    },
    '188': {
        'name': 'Costa Rica',
        'alpha-2': 'CR',
        'country-code': '188',
        'eu': 'non_eu',
        'timezone': 'America/Costa_Rica'
    },
    '384': {
        'name': "Côte d'Ivoire",
        'alpha-2': 'CI',
        'country-code': '384',
        'eu': 'non_eu',
        'timezone': 'Africa/Abidjan'
    },
    '191': {
        'name': 'Croatia',
        'alpha-2': 'HR',
        'country-code': '191',
        'eu': 'eu',
        'timezone': 'Europe/Zagreb'
    },
    '192': {
        'name': 'Cuba',
        'alpha-2': 'CU',
        'country-code': '192',
        'eu': 'non_eu',
        'timezone': 'America/Havana'
    },
    '531': {
        'name': 'Curaçao',
        'alpha-2': 'CW',
        'country-code': '531',
        'eu': 'non_eu',
        'timezone': 'America/Curacao'
    },
    '196': {
        'name': 'Cyprus',
        'alpha-2': 'CY',
        'country-code': '196',
        'eu': 'eu',
        'timezone': 'Asia/Nicosia'
    },
    '203': {
        'name': 'Czech Republic',
        'alpha-2': 'CZ',
        'country-code': '203',
        'eu': 'eu',
        'timezone': 'Europe/Prague'
    },
    '208': {
        'name': 'Denmark',
        'alpha-2': 'DK',
        'country-code': '208',
        'eu': 'eu',
        'timezone': 'Europe/Copenhagen'
    },
    '262': {
        'name': 'Djibouti',
        'alpha-2': 'DJ',
        'country-code': '262',
        'eu': 'non_eu',
        'timezone': 'Africa/Djibouti'
    },
    '212': {
        'name': 'Dominica',
        'alpha-2': 'DM',
        'country-code': '212',
        'eu': 'non_eu',
        'timezone': 'America/Dominica'
    },
    '214': {
        'name': 'Dominican Republic',
        'alpha-2': 'DO',
        'country-code': '214',
        'eu': 'non_eu',
        'timezone': 'America/Santo_Domingo'
    },
    '218': {
        'name': 'Ecuador',
        'alpha-2': 'EC',
        'country-code': '218',
        'eu': 'non_eu',
        'timezone': 'America/Guayaquil'
    },
    '818': {
        'name': 'Egypt',
        'alpha-2': 'EG',
        'country-code': '818',
        'eu': 'non_eu',
        'timezone': 'Africa/Cairo'
    },
    '222': {
        'name': 'El Salvador',
        'alpha-2': 'SV',
        'country-code': '222',
        'eu': 'non_eu',
        'timezone': 'America/El_Salvador'
    },
    '226': {
        'name': 'Equatorial Guinea',
        'alpha-2': 'GQ',
        'country-code': '226',
        'eu': 'non_eu',
        'timezone': 'Africa/Malabo'
    },
    '232': {
        'name': 'Eritrea',
        'alpha-2': 'ER',
        'country-code': '232',
        'eu': 'non_eu',
        'timezone': 'Africa/Asmara'
    },
    '233': {
        'name': 'Estonia',
        'alpha-2': 'EE',
        'country-code': '233',
        'eu': 'eu',
        'timezone': 'Europe/Tallinn'
    },
    '231': {
        'name': 'Ethiopia',
        'alpha-2': 'ET',
        'country-code': '231',
        'eu': 'non_eu',
        'timezone': 'Africa/Addis_Ababa'
    },
    '238': {
        'name': 'Falkland Islands (Malvinas)',
        'alpha-2': 'FK',
        'country-code': '238',
        'eu': 'non_eu',
        'timezone': 'Atlantic/Stanley'
    },
    '234': {
        'name': 'Faroe Islands',
        'alpha-2': 'FO',
        'country-code': '234',
        'eu': 'non_eu',
        'timezone': 'Atlantic/Faroe'
    },
    '242': {
        'name': 'Fiji',
        'alpha-2': 'FJ',
        'country-code': '242',
        'eu': 'non_eu',
        'timezone': 'Pacific/Fiji'
    },
    '246': {
        'name': 'Finland',
        'alpha-2': 'FI',
        'country-code': '246',
        'eu': 'eu',
        'timezone': 'Europe/Helsinki'
    },
    '250': {
        'name': 'France',
        'alpha-2': 'FR',
        'country-code': '250',
        'eu': 'eu',
        'timezone': 'Europe/Paris'
    },
    '254': {
        'name': 'French Guiana',
        'alpha-2': 'GF',
        'country-code': '254',
        'eu': 'non_eu',
        'timezone': 'America/Cayenne'
    },
    '258': {
        'name': 'French Polynesia',
        'alpha-2': 'PF',
        'country-code': '258',
        'eu': 'non_eu',
        'timezone': 'Pacific/Tahiti'
    },
    '260': {
        'name': 'French Southern Territories',
        'alpha-2': 'TF',
        'country-code': '260',
        'eu': 'non_eu',
        'timezone': 'Indian/Kerguelen'
    },
    '266': {
        'name': 'Gabon',
        'alpha-2': 'GA',
        'country-code': '266',
        'eu': 'non_eu',
        'timezone': 'Africa/Libreville'
    },
    '270': {
        'name': 'Gambia',
        'alpha-2': 'GM',
        'country-code': '270',
        'eu': 'non_eu',
        'timezone': 'Africa/Banjul'
    },
    '268': {
        'name': 'Georgia',
        'alpha-2': 'GE',
        'country-code': '268',
        'eu': 'non_eu',
        'timezone': 'Asia/Tbilisi'
    },
    '276': {
        'name': 'Germany',
        'alpha-2': 'DE',
        'country-code': '276',
        'eu': 'eu',
        'timezone': 'Europe/Berlin'
    },
    '288': {
        'name': 'Ghana',
        'alpha-2': 'GH',
        'country-code': '288',
        'eu': 'non_eu',
        'timezone': 'Africa/Accra'
    },
    '292': {
        'name': 'Gibraltar',
        'alpha-2': 'GI',
        'country-code': '292',
        'eu': 'non_eu',
        'timezone': 'Europe/Gibraltar'
    },
    '300': {
        'name': 'Greece',
        'alpha-2': 'GR',
        'country-code': '300',
        'eu': 'eu',
        'timezone': 'Europe/Athens'
    },
    '304': {
        'name': 'Greenland',
        'alpha-2': 'GL',
        'country-code': '304',
        'eu': 'non_eu',
        'timezone': 'America/Godthab'
    },
    '308': {
        'name': 'Grenada',
        'alpha-2': 'GD',
        'country-code': '308',
        'eu': 'non_eu',
        'timezone': 'America/Grenada'
    },
    '312': {
        'name': 'Guadeloupe',
        'alpha-2': 'GP',
        'country-code': '312',
        'eu': 'non_eu',
        'timezone': 'America/Guadeloupe'
    },
    '316': {
        'name': 'Guam',
        'alpha-2': 'GU',
        'country-code': '316',
        'eu': 'non_eu',
        'timezone': 'Pacific/Guam'
    },
    '320': {
        'name': 'Guatemala',
        'alpha-2': 'GT',
        'country-code': '320',
        'eu': 'non_eu',
        'timezone': 'America/Guatemala'
    },
    '831': {
        'name': 'Guernsey',
        'alpha-2': 'GG',
        'country-code': '831',
        'eu': 'non_eu',
        'timezone': 'Europe/Guernsey'
    },
    '324': {
        'name': 'Guinea',
        'alpha-2': 'GN',
        'country-code': '324',
        'eu': 'non_eu',
        'timezone': 'Africa/Conakry'
    },
    '624': {
        'name': 'Guinea-Bissau',
        'alpha-2': 'GW',
        'country-code': '624',
        'eu': 'non_eu',
        'timezone': 'Africa/Bissau'
    },
    '328': {
        'name': 'Guyana',
        'alpha-2': 'GY',
        'country-code': '328',
        'eu': 'non_eu',
        'timezone': 'America/Guyana'
    },
    '332': {
        'name': 'Haiti',
        'alpha-2': 'HT',
        'country-code': '332',
        'eu': 'non_eu',
        'timezone': 'America/Port-au-Prince'
    },
    '336': {
        'name': 'Holy See (Vatican City State)',
        'alpha-2': 'VA',
        'country-code': '336',
        'eu': 'non_eu',
        'timezone': 'Europe/Vatican'
    },
    '340': {
        'name': 'Honduras',
        'alpha-2': 'HN',
        'country-code': '340',
        'eu': 'non_eu',
        'timezone': 'America/Tegucigalpa'
    },
    '344': {
        'name': 'Hong Kong',
        'alpha-2': 'HK',
        'country-code': '344',
        'eu': 'non_eu',
        'timezone': 'Asia/Hong_Kong'
    },
    '348': {
        'name': 'Hungary',
        'alpha-2': 'HU',
        'country-code': '348',
        'eu': 'eu',
        'timezone': 'Europe/Budapest'
    },
    '352': {
        'name': 'Iceland',
        'alpha-2': 'IS',
        'country-code': '352',
        'eu': 'non_eu',
        'timezone': 'Atlantic/Reykjavik'
    },
    '356': {
        'name': 'India',
        'alpha-2': 'IN',
        'country-code': '356',
        'eu': 'non_eu',
        'timezone': 'Asia/Kolkata'
    },
    '360': {
        'name': 'Indonesia',
        'alpha-2': 'ID',
        'country-code': '360',
        'eu': 'non_eu',
        'timezone': 'Asia/Jakarta'
    },
    '364': {
        'name': 'Iran, Islamic Republic of',
        'alpha-2': 'IR',
        'country-code': '364',
        'eu': 'non_eu',
        'timezone': 'Asia/Tehran'
    },
    '368': {
        'name': 'Iraq',
        'alpha-2': 'IQ',
        'country-code': '368',
        'eu': 'non_eu',
        'timezone': 'Asia/Baghdad'
    },
    '372': {
        'name': 'Ireland',
        'alpha-2': 'IE',
        'country-code': '372',
        'eu': 'eu',
        'timezone': 'Europe/Dublin'
    },
    '833': {
        'name': 'Isle of Man',
        'alpha-2': 'IM',
        'country-code': '833',
        'eu': 'non_eu',
        'timezone': 'Europe/Isle_of_Man'
    },
    '376': {
        'name': 'Israel',
        'alpha-2': 'IL',
        'country-code': '376',
        'eu': 'non_eu',
        'timezone': 'Asia/Jerusalem'
    },
    '380': {
        'name': 'Italy',
        'alpha-2': 'IT',
        'country-code': '380',
        'eu': 'eu',
        'timezone': 'Europe/Rome'
    },
    '388': {
        'name': 'Jamaica',
        'alpha-2': 'JM',
        'country-code': '388',
        'eu': 'non_eu',
        'timezone': 'America/Jamaica'
    },
    '392': {
        'name': 'Japan',
        'alpha-2': 'JP',
        'country-code': '392',
        'eu': 'non_eu',
        'timezone': 'Asia/Tokyo'
    },
    '832': {
        'name': 'Jersey',
        'alpha-2': 'JE',
        'country-code': '832',
        'eu': 'non_eu',
        'timezone': 'Europe/Jersey'
    },
    '400': {
        'name': 'Jordan',
        'alpha-2': 'JO',
        'country-code': '400',
        'eu': 'non_eu',
        'timezone': 'Asia/Amman'
    },
    '398': {
        'name': 'Kazakhstan',
        'alpha-2': 'KZ',
        'country-code': '398',
        'eu': 'non_eu',
        'timezone': 'Asia/Almaty'
    },
    '404': {
        'name': 'Kenya',
        'alpha-2': 'KE',
        'country-code': '404',
        'eu': 'non_eu',
        'timezone': 'Africa/Nairobi'
    },
    '296': {
        'name': 'Kiribati',
        'alpha-2': 'KI',
        'country-code': '296',
        'eu': 'non_eu',
        'timezone': 'Pacific/Tarawa'
    },
    '408': {
        'name': "Korea, Democratic People's Republic of",
        'alpha-2': 'KP',
        'country-code': '408',
        'eu': 'non_eu',
        'timezone': 'Asia/Pyongyang'
    },
    '410': {
        'name': 'Korea, Republic of',
        'alpha-2': 'KR',
        'country-code': '410',
        'eu': 'non_eu',
        'timezone': 'Asia/Seoul'
    },
    '414': {
        'name': 'Kuwait',
        'alpha-2': 'KW',
        'country-code': '414',
        'eu': 'non_eu',
        'timezone': 'Asia/Kuwait'
    },
    '417': {
        'name': 'Kyrgyzstan',
        'alpha-2': 'KG',
        'country-code': '417',
        'eu': 'non_eu',
        'timezone': 'Asia/Bishkek'
    },
    '418': {
        'name': "Lao People's Democratic Republic",
        'alpha-2': 'LA',
        'country-code': '418',
        'eu': 'non_eu',
        'timezone': 'Asia/Vientiane'
    },
    '428': {
        'name': 'Latvia',
        'alpha-2': 'LV',
        'country-code': '428',
        'eu': 'eu',
        'timezone': 'Europe/Riga'
    },
    '422': {
        'name': 'Lebanon',
        'alpha-2': 'LB',
        'country-code': '422',
        'eu': 'non_eu',
        'timezone': 'Asia/Beirut'
    },
    '426': {
        'name': 'Lesotho',
        'alpha-2': 'LS',
        'country-code': '426',
        'eu': 'non_eu',
        'timezone': 'Africa/Maseru'
    },
    '430': {
        'name': 'Liberia',
        'alpha-2': 'LR',
        'country-code': '430',
        'eu': 'non_eu',
        'timezone': 'Africa/Monrovia'
    },
    '434': {
        'name': 'Libya',
        'alpha-2': 'LY',
        'country-code': '434',
        'eu': 'non_eu',
        'timezone': 'Africa/Tripoli'
    },
    '438': {
        'name': 'Liechtenstein',
        'alpha-2': 'LI',
        'country-code': '438',
        'eu': 'non_eu',
        'timezone': 'Europe/Vaduz'
    },
    '440': {
        'name': 'Lithuania',
        'alpha-2': 'LT',
        'country-code': '440',
        'eu': 'eu',
        'timezone': 'Europe/Vilnius'
    },
    '442': {
        'name': 'Luxembourg',
        'alpha-2': 'LU',
        'country-code': '442',
        'eu': 'eu',
        'timezone': 'Europe/Luxembourg'
    },
    '446': {
        'name': 'Macao',
        'alpha-2': 'MO',
        'country-code': '446',
        'eu': 'non_eu',
        'timezone': 'Asia/Macau'
    },
    '807': {
        'name': 'Macedonia, the former Yugoslav Republic of',
        'alpha-2': 'MK',
        'country-code': '807',
        'eu': 'non_eu',
        'timezone': 'Europe/Skopje'
    },
    '450': {
        'name': 'Madagascar',
        'alpha-2': 'MG',
        'country-code': '450',
        'eu': 'non_eu',
        'timezone': 'Indian/Antananarivo'
    },
    '454': {
        'name': 'Malawi',
        'alpha-2': 'MW',
        'country-code': '454',
        'eu': 'non_eu',
        'timezone': 'Africa/Blantyre'
    },
    '458': {
        'name': 'Malaysia',
        'alpha-2': 'MY',
        'country-code': '458',
        'eu': 'non_eu',
        'timezone': 'Asia/Kuala_Lumpur'
    },
    '462': {
        'name': 'Maldives',
        'alpha-2': 'MV',
        'country-code': '462',
        'eu': 'non_eu',
        'timezone': 'Indian/Maldives'
    },
    '466': {
        'name': 'Mali',
        'alpha-2': 'ML',
        'country-code': '466',
        'eu': 'non_eu',
        'timezone': 'Africa/Bamako'
    },
    '470': {
        'name': 'Malta',
        'alpha-2': 'MT',
        'country-code': '470',
        'eu': 'eu',
        'timezone': 'Europe/Malta'
    },
    '584': {
        'name': 'Marshall Islands',
        'alpha-2': 'MH',
        'country-code': '584',
        'eu': 'non_eu',
        'timezone': 'Pacific/Majuro'
    },
    '474': {
        'name': 'Martinique',
        'alpha-2': 'MQ',
        'country-code': '474',
        'eu': 'non_eu',
        'timezone': 'America/Martinique'
    },
    '478': {
        'name': 'Mauritania',
        'alpha-2': 'MR',
        'country-code': '478',
        'eu': 'non_eu',
        'timezone': 'Africa/Nouakchott'
    },
    '480': {
        'name': 'Mauritius',
        'alpha-2': 'MU',
        'country-code': '480',
        'eu': 'non_eu',
        'timezone': 'Indian/Mauritius'
    },
    '175': {
        'name': 'Mayotte',
        'alpha-2': 'YT',
        'country-code': '175',
        'eu': 'non_eu',
        'timezone': 'Indian/Mayotte'
    },
    '484': {
        'name': 'Mexico',
        'alpha-2': 'MX',
        'country-code': '484',
        'eu': 'non_eu',
        'timezone': 'America/Mexico_City'
    },
    '583': {
        'name': 'Micronesia, Federated States of',
        'alpha-2': 'FM',
        'country-code': '583',
        'eu': 'non_eu',
        'timezone': 'Pacific/Chuuk'
    },
    '498': {
        'name': 'Moldova, Republic of',
        'alpha-2': 'MD',
        'country-code': '498',
        'eu': 'non_eu',
        'timezone': 'Europe/Chisinau'
    },
    '492': {
        'name': 'Monaco',
        'alpha-2': 'MC',
        'country-code': '492',
        'eu': 'non_eu',
        'timezone': 'Europe/Monaco'
    },
    '496': {
        'name': 'Mongolia',
        'alpha-2': 'MN',
        'country-code': '496',
        'eu': 'non_eu',
        'timezone': 'Asia/Ulaanbaatar'
    },
    '499': {
        'name': 'Montenegro',
        'alpha-2': 'ME',
        'country-code': '499',
        'eu': 'non_eu',
        'timezone': 'Europe/Podgorica'
    },
    '500': {
        'name': 'Montserrat',
        'alpha-2': 'MS',
        'country-code': '500',
        'eu': 'non_eu',
        'timezone': 'America/Montserrat'
    },
    '504': {
        'name': 'Morocco',
        'alpha-2': 'MA',
        'country-code': '504',
        'eu': 'non_eu',
        'timezone': 'Africa/Casablanca'
    },
    '508': {
        'name': 'Mozambique',
        'alpha-2': 'MZ',
        'country-code': '508',
        'eu': 'non_eu',
        'timezone': 'Africa/Maputo'
    },
    '104': {
        'name': 'Myanmar',
        'alpha-2': 'MM',
        'country-code': '104',
        'eu': 'non_eu',
        'timezone': 'Asia/Yangon'
    },
    '516': {
        'name': 'Namibia',
        'alpha-2': 'NA',
        'country-code': '516',
        'eu': 'non_eu',
        'timezone': 'Africa/Windhoek'
    },
    '520': {
        'name': 'Nauru',
        'alpha-2': 'NR',
        'country-code': '520',
        'eu': 'non_eu',
        'timezone': 'Pacific/Nauru'
    },
    '524': {
        'name': 'Nepal',
        'alpha-2': 'NP',
        'country-code': '524',
        'eu': 'non_eu',
        'timezone': 'Asia/Kathmandu'
    },
    '528': {
        'name': 'Netherlands',
        'alpha-2': 'NL',
        'country-code': '528',
        'eu': 'eu',
        'timezone': 'Europe/Amsterdam'
    },
    '540': {
        'name': 'New Caledonia',
        'alpha-2': 'NC',
        'country-code': '540',
        'eu': 'non_eu',
        'timezone': 'Pacific/Noumea'
    },
    '554': {
        'name': 'New Zealand',
        'alpha-2': 'NZ',
        'country-code': '554',
        'eu': 'non_eu',
        'timezone': 'Pacific/Auckland'
    },
    '558': {
        'name': 'Nicaragua',
        'alpha-2': 'NI',
        'country-code': '558',
        'eu': 'non_eu',
        'timezone': 'America/Managua'
    },
    '562': {
        'name': 'Niger',
        'alpha-2': 'NE',
        'country-code': '562',
        'eu': 'non_eu',
        'timezone': 'Africa/Niamey'
    },
    '556': {
        'name': 'Nigeria',
        'alpha-2': 'NG',
        'country-code': '566',
        'eu': 'non_eu',
        'timezone': 'Africa/Lagos'
    },
    '570': {
        'name': 'Niue',
        'alpha-2': 'NU',
        'country-code': '570',
        'eu': 'non_eu',
        'timezone': 'Pacific/Niue'
    },
    '574': {
        'name': 'Norfolk Island',
        'alpha-2': 'NF',
        'country-code': '574',
        'eu': 'non_eu',
        'timezone': 'Pacific/Norfolk'
    },
    '580': {
        'name': 'Northern Mariana Islands',
        'alpha-2': 'MP',
        'country-code': '580',
        'eu': 'non_eu',
        'timezone': 'Pacific/Saipan'
    },
    '578': {
        'name': 'Norway',
        'alpha-2': 'NO',
        'country-code': '578',
        'eu': 'non_eu',
        'timezone': 'Europe/Oslo'
    },
    '512': {
        'name': 'Oman',
        'alpha-2': 'OM',
        'country-code': '512',
        'eu': 'non_eu',
        'timezone': 'Asia/Muscat'
    },
    '586': {
        'name': 'Pakistan',
        'alpha-2': 'PK',
        'country-code': '586',
        'eu': 'non_eu',
        'timezone': 'Asia/Karachi'
    },
    '585': {
        'name': 'Palau',
        'alpha-2': 'PW',
        'country-code': '585',
        'eu': 'non_eu',
        'timezone': 'Pacific/Palau'
    },
    '275': {
        'name': 'Palestine, State of',
        'alpha-2': 'PS',
        'country-code': '275',
        'eu': 'non_eu',
        'timezone': 'Asia/Gaza'
    },
    '591': {
        'name': 'Panama',
        'alpha-2': 'PA',
        'country-code': '591',
        'eu': 'non_eu',
        'timezone': 'America/Panama'
    },
    '598': {
        'name': 'Papua New Guinea',
        'alpha-2': 'PG',
        'country-code': '598',
        'eu': 'non_eu',
        'timezone': 'Pacific/Port_Moresby'
    },
    '600': {
        'name': 'Paraguay',
        'alpha-2': 'PY',
        'country-code': '600',
        'eu': 'non_eu',
        'timezone': 'America/Asuncion'
    },
    '604': {
        'name': 'Peru',
        'alpha-2': 'PE',
        'country-code': '604',
        'eu': 'non_eu',
        'timezone': 'America/Lima'
    },
    '608': {
        'name': 'Philippines',
        'alpha-2': 'PH',
        'country-code': '608',
        'eu': 'non_eu',
        'timezone': 'Asia/Manila'
    },
    '612': {
        'name': 'Pitcairn',
        'alpha-2': 'PN',
        'country-code': '612',
        'eu': 'non_eu',
        'timezone': 'Pacific/Pitcairn'
    },
    '616': {
        'name': 'Poland',
        'alpha-2': 'PL',
        'country-code': '616',
        'eu': 'eu',
        'timezone': 'Europe/Warsaw'
    },
    '620': {
        'name': 'Portugal',
        'alpha-2': 'PT',
        'country-code': '620',
        'eu': 'eu',
        'timezone': 'Europe/Lisbon'
    },
    '630': {
        'name': 'Puerto Rico',
        'alpha-2': 'PR',
        'country-code': '630',
        'eu': 'non_eu',
        'timezone': 'America/Puerto_Rico'
    },
    '634': {
        'name': 'Qatar',
        'alpha-2': 'QA',
        'country-code': '634',
        'eu': 'non_eu',
        'timezone': 'Asia/Qatar'
    },
    '638': {
        'name': 'Réunion',
        'alpha-2': 'RE',
        'country-code': '638',
        'eu': 'non_eu',
        'timezone': 'Indian/Reunion'
    },
    '642': {
        'name': 'Romania',
        'alpha-2': 'RO',
        'country-code': '642',
        'eu': 'eu',
        'timezone': 'Europe/Bucharest'
    },
    '643': {
        'name': 'Russian Federation',
        'alpha-2': 'RU',
        'country-code': '643',
        'eu': 'non_eu',
        'timezone': 'Europe/Kaliningrad'
    },
    '646': {
        'name': 'Rwanda',
        'alpha-2': 'RW',
        'country-code': '646',
        'eu': 'non_eu',
        'timezone': 'Africa/Kigali'
    },
    '652': {
        'name': 'Saint Barthélemy',
        'alpha-2': 'BL',
        'country-code': '652',
        'eu': 'non_eu',
        'timezone': 'America/St_Barthelemy'
    },
    '654': {
        'name': 'Saint Helena, Ascension and Tristan da Cunha',
        'alpha-2': 'SH',
        'country-code': '654',
        'eu': 'non_eu',
        'timezone': 'Atlantic/St_Helena'
    },
    '659': {
        'name': 'Saint Kitts and Nevis',
        'alpha-2': 'KN',
        'country-code': '659',
        'eu': 'non_eu',
        'timezone': 'America/St_Kitts'
    },
    '662': {
        'name': 'Saint Lucia',
        'alpha-2': 'LC',
        'country-code': '662',
        'eu': 'non_eu',
        'timezone': 'America/St_Lucia'
    },
    '663': {
        'name': 'Saint Martin (French part)',
        'alpha-2': 'MF',
        'country-code': '663',
        'eu': 'non_eu',
        'timezone': 'America/Marigot'
    },
    '666': {
        'name': 'Saint Pierre and Miquelon',
        'alpha-2': 'PM',
        'country-code': '666',
        'eu': 'non_eu',
        'timezone': 'America/Miquelon'
    },
    '670': {
        'name': 'Saint Vincent and the Grenadines',
        'alpha-2': 'VC',
        'country-code': '670',
        'eu': 'non_eu',
        'timezone': 'America/St_Vincent'
    },
    '882': {
        'name': 'Samoa',
        'alpha-2': 'WS',
        'country-code': '882',
        'eu': 'non_eu',
        'timezone': 'Pacific/Apia'
    },
    '674': {
        'name': 'San Marino',
        'alpha-2': 'SM',
        'country-code': '674',
        'eu': 'non_eu',
        'timezone': 'Europe/San_Marino'
    },
    '678': {
        'name': 'Sao Tome and Principe',
        'alpha-2': 'ST',
        'country-code': '678',
        'eu': 'non_eu',
        'timezone': 'Africa/Sao_Tome'
    },
    '682': {
        'name': 'Saudi Arabia',
        'alpha-2': 'SA',
        'country-code': '682',
        'eu': 'non_eu',
        'timezone': 'Asia/Riyadh'
    },
    '686': {
        'name': 'Senegal',
        'alpha-2': 'SN',
        'country-code': '686',
        'eu': 'non_eu',
        'timezone': 'Africa/Dakar'
    },
    '688': {
        'name': 'Serbia',
        'alpha-2': 'RS',
        'country-code': '688',
        'eu': 'non_eu',
        'timezone': 'Europe/Belgrade'
    },
    '690': {
        'name': 'Seychelles',
        'alpha-2': 'SC',
        'country-code': '690',
        'eu': 'non_eu',
        'timezone': 'Indian/Mahe'
    },
    '925': {
        'name': 'Sierra Leone',
        'alpha-2': 'SL',
        'country-code': '925',
        'eu': 'non_eu',
        'timezone': 'Africa/Freetown'
    },
    '702': {
        'name': 'Singapore',
        'alpha-2': 'SG',
        'country-code': '702',
        'eu': 'non_eu',
        'timezone': 'Asia/Singapore'
    },
    '534': {
        'name': 'Sint Maarten (Dutch part)',
        'alpha-2': 'SX',
        'country-code': '534',
        'eu': 'non_eu',
        'timezone': 'America/Lower_Princes'
    },
    '703': {
        'name': 'Slovakia',
        'alpha-2': 'SK',
        'country-code': '703',
        'eu': 'eu',
        'timezone': 'Europe/Bratislava'
    },
    '705': {
        'name': 'Slovenia',
        'alpha-2': 'SI',
        'country-code': '705',
        'eu': 'eu',
        'timezone': 'Europe/Ljubljana'
    },
    '090': {
        'name': 'Solomon Islands',
        'alpha-2': 'SB',
        'country-code': '090',
        'eu': 'non_eu',
        'timezone': 'Pacific/Guadalcanal'
    },
    '706': {
        'name': 'Somalia',
        'alpha-2': 'SO',
        'country-code': '706',
        'eu': 'non_eu',
        'timezone': 'Africa/Mogadishu'
    },
    '710': {
        'name': 'South Africa',
        'alpha-2': 'ZA',
        'country-code': '710',
        'eu': 'non_eu',
        'timezone': 'Africa/Johannesburg'
    },
    '239': {
        'name': 'South Georgia and the South Sandwich Islands',
        'alpha-2': 'GS',
        'country-code': '239',
        'eu': 'non_eu',
        'timezone': 'Atlantic/South_Georgia'
    },
    '728': {
        'name': 'South Sudan',
        'alpha-2': 'SS',
        'country-code': '728',
        'eu': 'non_eu',
        'timezone': 'Africa/Juba'
    },
    '724': {
        'name': 'Spain',
        'alpha-2': 'ES',
        'country-code': '724',
        'eu': 'eu',
        'timezone': 'Europe/Madrid'
    },
    '144': {
        'name': 'Sri Lanka',
        'alpha-2': 'LK',
        'country-code': '144',
        'eu': 'non_eu',
        'timezone': 'Asia/Colombo'
    },
    '729': {
        'name': 'Sudan',
        'alpha-2': 'SD',
        'country-code': '729',
        'eu': 'non_eu',
        'timezone': 'Africa/Khartoum'
    },
    '740': {
        'name': 'Suriname',
        'alpha-2': 'SR',
        'country-code': '740',
        'eu': 'non_eu',
        'timezone': 'America/Paramaribo'
    },
    '744': {
        'name': 'Svalbard and Jan Mayen',
        'alpha-2': 'SJ',
        'country-code': '744',
        'eu': 'non_eu',
        'timezone': 'Arctic/Longyearbyen'
    },
    '748': {
        'name': 'Swaziland',
        'alpha-2': 'SZ',
        'country-code': '748',
        'eu': 'non_eu',
        'timezone': 'Africa/Mbabane'
    },
    '752': {
        'name': 'Sweden',
        'alpha-2': 'SE',
        'country-code': '752',
        'eu': 'eu',
        'timezone': 'Europe/Stockholm'
    },
    '756': {
        'name': 'Switzerland',
        'alpha-2': 'CH',
        'country-code': '756',
        'eu': 'non_eu',
        'timezone': 'Europe/Zurich'
    },
    '760': {
        'name': 'Syrian Arab Republic',
        'alpha-2': 'SY',
        'country-code': '760',
        'eu': 'non_eu',
        'timezone': 'Asia/Damascus'
    },
    '158': {
        'name': 'Taiwan, Province of China',
        'alpha-2': 'TW',
        'country-code': '158',
        'eu': 'non_eu',
        'timezone': 'Asia/Taipei'
    },
    '762': {
        'name': 'Tajikistan',
        'alpha-2': 'TJ',
        'country-code': '762',
        'eu': 'non_eu',
        'timezone': 'Asia/Dushanbe'
    },
    '834': {
        'name': 'Tanzania, United Republic of',
        'alpha-2': 'TZ',
        'country-code': '834',
        'eu': 'non_eu',
        'timezone': 'Africa/Dar_es_Salaam'
    },
    '764': {
        'name': 'Thailand',
        'alpha-2': 'TH',
        'country-code': '764',
        'eu': 'non_eu',
        'timezone': 'Asia/Bangkok'
    },
    '626': {
        'name': 'Timor-Leste',
        'alpha-2': 'TL',
        'country-code': '626',
        'eu': 'non_eu',
        'timezone': 'Asia/Dili'
    },
    '768': {
        'name': 'Togo',
        'alpha-2': 'TG',
        'country-code': '768',
        'eu': 'non_eu',
        'timezone': 'Africa/Lome'
    },
    '772': {
        'name': 'Tokelau',
        'alpha-2': 'TK',
        'country-code': '772',
        'eu': 'non_eu',
        'timezone': 'Pacific/Fakaofo'
    },
    '776': {
        'name': 'Tonga',
        'alpha-2': 'TO',
        'country-code': '776',
        'eu': 'non_eu',
        'timezone': 'Pacific/Tongatapu'
    },
    '780': {
        'name': 'Trinidad and Tobago',
        'alpha-2': 'TT',
        'country-code': '780',
        'eu': 'non_eu',
        'timezone': 'America/Port_of_Spain'
    },
    '788': {
        'name': 'Tunisia',
        'alpha-2': 'TN',
        'country-code': '788',
        'eu': 'non_eu',
        'timezone': 'Africa/Tunis'
    },
    '792': {
        'name': 'Turkey',
        'alpha-2': 'TR',
        'country-code': '792',
        'eu': 'non_eu',
        'timezone': 'Europe/Istanbul'
    },
    '795': {
        'name': 'Turkmenistan',
        'alpha-2': 'TM',
        'country-code': '795',
        'eu': 'non_eu',
        'timezone': 'Asia/Ashgabat'
    },
    '796': {
        'name': 'Turks and Caicos Islands',
        'alpha-2': 'TC',
        'country-code': '796',
        'eu': 'non_eu',
        'timezone': 'America/Grand_Turk'
    },
    '798': {
        'name': 'Tuvalu',
        'alpha-2': 'TV',
        'country-code': '798',
        'eu': 'non_eu',
        'timezone': 'Pacific/Funafuti'
    },
    '800': {
        'name': 'Uganda',
        'alpha-2': 'UG',
        'country-code': '800',
        'eu': 'non_eu',
        'timezone': 'Africa/Kampala'
    },
    '804': {
        'name': 'Ukraine',
        'alpha-2': 'UA',
        'country-code': '804',
        'eu': 'non_eu',
        'timezone': 'Europe/Kiev'
    },
    '784': {
        'name': 'United Arab Emirates',
        'alpha-2': 'AE',
        'country-code': '784',
        'eu': 'non_eu',
        'timezone': 'Asia/Dubai'
    },
    '826': {
        'name': 'United Kingdom',
        'alpha-2': 'GB',
        'country-code': '826',
        'eu': 'eu',
        'timezone': 'Europe/London'
    },
    '840': {
        'name': 'United States',
        'alpha-2': 'US',
        'country-code': '840',
        'eu': 'non_eu',
        'timezone': 'America/New_York'
    },
    '581': {
        'name': 'United States Minor Outlying Islands',
        'alpha-2': 'UM',
        'country-code': '581',
        'eu': 'non_eu',
        'timezone': 'Pacific/Midway'
    },
    '858': {
        'name': 'Uruguay',
        'alpha-2': 'UY',
        'country-code': '858',
        'eu': 'non_eu',
        'timezone': 'America/Montevideo'
    },
    '860': {
        'name': 'Uzbekistan',
        'alpha-2': 'UZ',
        'country-code': '860',
        'eu': 'non_eu',
        'timezone': 'Asia/Samarkand'
    },
    '548': {
        'name': 'Vanuatu',
        'alpha-2': 'VU',
        'country-code': '548',
        'eu': 'non_eu',
        'timezone': 'Pacific/Efate'
    },
    '862': {
        'name': 'Venezuela, Bolivarian Republic of',
        'alpha-2': 'VE',
        'country-code': '862',
        'eu': 'non_eu',
        'timezone': 'America/Caracas'
    },
    '704': {
        'name': 'Viet Nam',
        'alpha-2': 'VN',
        'country-code': '704',
        'eu': 'non_eu',
        'timezone': 'Asia/Ho_Chi_Minh'
    },
    '092': {
        'name': 'Virgin Islands, British',
        'alpha-2': 'VG',
        'country-code': '092',
        'eu': 'non_eu',
        'timezone': 'America/Tortola'
    },
    '850': {
        'name': 'Virgin Islands, U.S.',
        'alpha-2': 'VI',
        'country-code': '850',
        'eu': 'non_eu',
        'timezone': 'America/St_Thomas'
    },
    '876': {
        'name': 'Wallis and Futuna',
        'alpha-2': 'WF',
        'country-code': '876',
        'eu': 'non_eu',
        'timezone': 'Pacific/Wallis'
    },
    '732': {
        'name': 'Western Sahara',
        'alpha-2': 'EH',
        'country-code': '732',
        'eu': 'non_eu',
        'timezone': 'Africa/El_Aaiun'
    },
    '887': {
        'name': 'Yemen',
        'alpha-2': 'YE',
        'country-code': '887',
        'eu': 'non_eu',
        'timezone': 'Asia/Aden'
    },
    '894': {
        'name': 'Zambia',
        'alpha-2': 'ZM',
        'country-code': '894',
        'eu': 'non_eu',
        'timezone': 'Africa/Lusaka'
    },
    '716': {
        'name': 'Zimbabwe',
        'alpha-2': 'ZW',
        'country-code': '716',
        'eu': 'non_eu',
        'timezone': 'Africa/Harare'
    }
}

export const CountriesList = Object.keys(CountriesDictionary)

export const CountriesDictionaryByTimezone = Object.keys(CountriesDictionary).reduce((acc, key) => {
    const item = CountriesDictionary[key]
    acc[item.timezone] = {
        ...item
    }
    return acc
}, {} as any)

export const CountriesListByTimezone = Object.keys(CountriesDictionaryByTimezone)
