import { useRef, useCallback } from 'react'

export const useRowCheckmarkSelector = (
    onSelection: (id: string, shouldSelect: boolean) => void,
    shouldDisable?: boolean
): any => {
    const selecting = useRef(false)
    const shouldKeepSelecting = useRef(false)

    const calllbackRef = useCallback(
        (elem) => {
            if (shouldDisable) return
            const listeners: any = {}

            listeners.click = (e: any) => {
                const id = e.currentTarget.getAttribute('data-id')
                const isAlreadySelected = e.currentTarget.getAttribute('data-selected')

                if (!id) {
                    selecting.current = false
                    return
                }

                shouldKeepSelecting.current = isAlreadySelected
                onSelection?.(id, isAlreadySelected)
                selecting.current = true
            }

            listeners.mouseEnter = (e: any) => {
                const id = e.currentTarget.getAttribute('data-id')
                if (id && selecting.current) onSelection?.(id, shouldKeepSelecting.current)
            }

            elem?.addEventListener('mousedown', listeners.click)
            elem?.addEventListener('mouseenter', listeners.mouseEnter)

            const selectStart = (e: any) => {
                if (selecting.current == true) e.preventDefault()
            }
            const bodyUp = () => {
                selecting.current = false
            }

            window.addEventListener('selectstart', selectStart)
            document.body.addEventListener('mouseup', bodyUp)
            return () => {
                document.body.removeEventListener('mouseup', selectStart)
                window.removeEventListener('selectstart', selectStart)
                elem?.removeEventListener('selectstart', listeners.selectStart)
                elem?.removeEventListener('mousedown', listeners.click)
                elem?.removeEventListener('mouseenter', listeners.mouseEnter)
            }
        },
        [shouldDisable, onSelection]
    )

    return calllbackRef
}
