import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useGoBackHook = (goBackTo?: string, state?: any, keepCurrentSearch?: boolean, keepGoBackTo?: boolean) => {
    const navigate = useNavigate()
    const location = useLocation()

    const canGoBack = useMemo(() => {
        if (keepGoBackTo) {
            return goBackTo
        }

        return (location.state as any)?.backTo || goBackTo
    }, [goBackTo, location, keepGoBackTo])

    const goBackConfig = useMemo(() => {
        const url = keepGoBackTo ? goBackTo : (location.state as any)?.backTo || goBackTo
        if (!url) return

        const pathname = url.split('?')[0]
        let search = url.split('?')[1]

        if (keepCurrentSearch) search = location.search

        return {
            pathname,
            search: search,
            state: state
        }
    }, [location, goBackTo, state, keepCurrentSearch, keepGoBackTo])

    const goBack = useCallback(() => {
        if (!goBackConfig) return

        navigate(
            { pathname: goBackConfig.pathname, search: goBackConfig.search },
            { state: goBackConfig.state, replace: true }
        )
    }, [navigate, goBackConfig])

    return { goBack, canGoBack, goBackConfig }
}
