import { ActionType } from 'deox'
import { put, select } from 'redux-saga/effects'

import { GET, PUT } from '../../../generators/networking'
import { RootState } from '@/store'
import { ApplicationInternalsReferralActions } from './actions'
import { Referral } from './types'

export const ApplicationInternalsReferralSagas = {
    *REMOVE({ payload: p }: ActionType<typeof ApplicationInternalsReferralActions.REMOVE>) {
        const cutterLink: string = yield select((state: RootState) => {
            return `${state.global.links?.cutter?.applicationLink + p.id}/referral_partners`
        })

        const ids = (yield select((state: RootState) => {
            return state.applicationInternals.referral?.[p.id]?.activeReferrals
        }) || []) as Referral[]

        const { cleanedResponse } = yield PUT({
            watcher: p.watcherId,
            url: cutterLink,
            body: {
                partner_ids: ids.map((i) => i.id).filter((i) => i !== p.partnerId)
            },
            successCode: 200,
            successText: 'Referral partners updated.',
            errorText: 'Failed to update referral partners.'
        })

        yield put(ApplicationInternalsReferralActions.STORE(p.id, cleanedResponse.referralPartners))
    },
    *FETCH({ payload: p }: ActionType<typeof ApplicationInternalsReferralActions.FETCH>) {
        const response: {
            referralPartners: Referral[]
        } = yield GET({
            cutterUrl: (c) => c.applicationLink + p.applicationId + '/referral_partners',
            errorText: 'Failed to load referral details.',
            include: ['self']
        })

        yield put(ApplicationInternalsReferralActions.STORE(p.applicationId, response?.referralPartners))
    },
    *ADD({ payload: p }: ActionType<typeof ApplicationInternalsReferralActions.ADD>) {
        const cutterLink: string = yield select((state: RootState) => {
            return `${state.global.links?.cutter.applicationLink + p.id}/referral_partners`
        })

        const ids = (yield select((state: RootState) => {
            return state.applicationInternals.referral?.[p.id]?.activeReferrals
        }) || []) as Referral[]

        const { cleanedResponse } = yield PUT({
            watcher: p.watcherId,
            url: cutterLink,
            body: {
                partner_ids: [...ids.filter((i) => i.id !== p.partnerId).map((i) => i.id), p.partnerId]
            },
            successCode: 200,
            successText: 'Referral partner added successfully.',
            errorText: 'Failed to add referral partners.'
        })

        yield put(ApplicationInternalsReferralActions.STORE(p.id, cleanedResponse.referralPartners))
    }
}
