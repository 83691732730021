import { fork, cancel } from 'redux-saga/effects'
import { Task } from 'redux-saga'
import { TasksSaga } from './sagas'
import { TasksActionLoadTask, TasksActionLoadTimeline } from './actions'

export class TasksCancellableSagas {
    static taskSagas: TasksSaga

    static fetchTask: Task

    static fetchTimeline: Task

    constructor(sagas: TasksSaga) {
        TasksCancellableSagas.taskSagas = sagas
    }

    *cancelTasksLoadingTasks() {
        if (TasksCancellableSagas.fetchTask)
            yield cancel(TasksCancellableSagas.fetchTask)
        if (TasksCancellableSagas.fetchTimeline)
            yield cancel(TasksCancellableSagas.fetchTimeline)
    }

    *loadTask(action: TasksActionLoadTask) {
        TasksCancellableSagas.fetchTask = yield fork(
            TasksCancellableSagas.taskSagas.loadTask,
            action
        )
    }

    *loadTimeline(action: TasksActionLoadTimeline) {
        TasksCancellableSagas.fetchTimeline = yield fork(
            TasksCancellableSagas.taskSagas.loadTaskTimeline,
            action
        )
    }
}
