import React, { useEffect, useState, useRef } from 'react'
import { Chart } from 'chart.js'
import { regularColorHash } from '../general/colorTag'
import { ChartJSTooltipsStyling } from './linechart'

export const Piechart: React.FC<{ data: { [key: string]: any }; overrideColors?: { [key: string]: string } }> = ({
    data,
    overrideColors
}) => {
    const canvasRef = useRef<any>()
    const [chart, setChart] = useState<any>(undefined)

    useEffect(() => {
        if (!canvasRef || !canvasRef.current) return
        const ctx = canvasRef.current.getContext('2d')

        setChart((s: any) => {
            s?.destroy()
            return new Chart(ctx, {
                type: 'pie',
                data: {
                    datasets: [
                        {
                            data: [],
                            backgroundColor: []
                        }
                    ],
                    labels: []
                },
                options: {
                    animation: {
                        animateRotate: false,
                        duration: 0
                    },
                    // legend: {
                    //     display: false
                    // },
                    plugins: {
                        tooltip: {
                            enabled: true,
                            ...ChartJSTooltipsStyling
                        }
                    },
                    responsive: false,
                    maintainAspectRatio: false,
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    }
                }
            })
        })
    }, [canvasRef])

    useEffect(() => {
        if (!data) return
        if (chart) {
            const labels = Object.keys(data)
            const dataset: any = []

            labels.map((k) => {
                if (!data[k]) dataset.push(0)
                else dataset.push(data[k])
            })

            chart.data.labels = [...labels]
            chart.data.datasets.map((dset: any) => {
                dset.data = [...dataset]
                dset.backgroundColor = labels.map((c: any) =>
                    overrideColors?.[c] ? overrideColors[c] : regularColorHash.hex(c)
                )
            })

            chart.update()
        }
    }, [data, overrideColors, chart])

    return <canvas ref={canvasRef} style={{ width: 170, height: 170 }} />
}
