import moment, { Moment } from 'moment'

export const DateFormats = {
    dayAndTime: (date: Moment): string => {
        if (!date) return 'ERROR'
        if (date.isSame(moment(), 'year')) return 'D MMM, HH:mm'
        return 'D MMM YYYY, HH:mm'
    },
    dayAndTimeWithSeconds: (date: Moment): string => {
        if (!date) return 'ERROR'
        if (date.isSame(moment(), 'year')) return 'D MMM, HH:mm:ss'
        return 'D MMM YYYY, HH:mm:ss'
    },
    fullDate: (date: Moment): string => {
        if (!date) return 'ERROR'
        return 'DD MMMM YYYY · HH:mm'
    },
    day: (date: Moment, full?: boolean): string => {
        if (!date) return 'ERROR'
        let month = 'MMM'
        if (full) month = 'MMMM'
        if (date.isSame(moment(), 'year')) return `D ${month}`
        return `D ${month}, YYYY`
    },
    fullDay: (date: Moment): string => {
        if (!date) return 'ERROR'
        if (date.isSame(moment(), 'year')) return 'D MMMM'
        return 'D MMMM, YYYY'
    },
    dayStamp: 'YYYY-MM-DD',
    completeStamp: 'YYYY-MM-DD HH:mm:ss ZZ'
}
