import { returnWithout } from '../../utils'
import {
    ReducedCallsActions,
    CALLS_LOAD_SUMMARIES,
    CALLS_STORE_SUMMARIES,
    CALLS_CLEAR_SUMMARIES,
    CALL_CLEAR_RESOURCES,
    CALLS_UPDATE_SUMMARIES,
    CALL_LOAD_RESOURCES,
    CALL_FIND_RESOURCES,
    CALL_STORE_RESOURCES,
    CALL_STORE_FOUND_RESOURCES,
    CALLS_LOAD_QUEUES,
    CALLS_STORE_QUEUES,
    CALL_DELETE_RESOURCE,
    CALLS_LOAD_PHONE_NAME,
    CALLS_STORE_PHONE_NAME
} from './actions'
import { InitialCallsState, CallsState, InitialCallResState, CallResState } from './types'

export function CallsReducer(
    state = InitialCallsState,
    action: ReducedCallsActions
): CallsState {
    switch (action.type) {
        case CALLS_LOAD_SUMMARIES: {
            return {
                ...state,
                callsSummaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'started'
                }
            }
        }
        case CALLS_STORE_SUMMARIES: {
            if (state.callsSummaries.loadingStatus === 'started')
                return {
                    ...state,
                    callsSummaries: {
                        at: {
                            ...action.callsSummaries.reduce((a, t) => {
                                return {
                                    ...a,
                                    [t.providerCallId]: {
                                        ...state.callsSummaries.at[t.providerCallId],
                                        ...t
                                    }
                                }
                            }, {})
                        },
                        all: action.callsSummaries.map((t) => t.providerCallId),
                        loadingStatus: 'done'
                    }
                }
            return state
        }
        case CALLS_UPDATE_SUMMARIES: {
            const calls = state.callsSummaries
            const update = action.update
            const updateId = update.provider_call_id
            if (calls.all.includes(updateId)) {
                if (update.end_time != 'undefined') {
                    calls.all = calls.all.filter((id) => id != updateId)
                }
                calls.at[updateId].pickedTime = update.connect_time == 'undefined' ? 'N/A' : update.connect_time
                calls.at[updateId].endTime = update.end_time == 'undefined' ? 'N/A' : update.end_time
                calls.at[updateId].localNumber = update.to
            } else {
                if (update.call_type != 'ended') {
                    calls.all.push(updateId)
                    calls.at[updateId] = {
                        providerCallId: updateId,
                        internalCallId: update.internal_call_id,
                        localNumber: update.to,
                        pickedTime: update.connect_time == 'undefined' ? 'N/A' : update.connect_time,
                        phoneNumber: parseInt(update.from),
                        endTime: update.end_time == 'undefined' ? 'N/A' : update.end_time,
                        startTime: update.start_time
                    }
                }
            }
            return {
                ...state,
                callsSummaries: {
                    at: {
                        ...calls.all.reduce((a, t) => {
                            return {
                                ...a,
                                [t]: {
                                    ...calls.at[t]
                                }
                            }
                        }, {})
                    },
                    all: calls.all,
                    loadingStatus: 'done'
                }
            }
        }
        case CALLS_CLEAR_SUMMARIES: {
            return {
                ...state,
                callsSummaries: {
                    at: {},
                    all: [],
                    loadingStatus: 'done'
                }
            }
        }
        case CALLS_LOAD_QUEUES: {
            return {
                ...state,
                queuePhones: {
                    at: {},
                    all: [],
                    loadingStatus: 'started'
                }
            }
        }
        case CALLS_STORE_QUEUES: {
            if (state.queuePhones.loadingStatus === 'started')
                return {
                    ...state,
                    queuePhones: {
                        at: {

                            ...action.queuePhones.queuePhones.reduce((a, t) => {
                                return {
                                    ...a,
                                    [t]: t
                                }
                            }, {})

                        },
                        all: action.queuePhones.queuePhones,
                        loadingStatus: 'done'
                    }
                }
            return state
        }
        case CALLS_LOAD_PHONE_NAME: {
            return {
                ...state,
                phoneName: {
                    localNumber: "",
                    name: "",
                    loadingStatus: 'started'
                }
            }
        }
        case CALLS_STORE_PHONE_NAME: {
            if (state.phoneName.loadingStatus === 'started')
                return {
                    ...state,
                    phoneName: {
                        localNumber: action.phoneName.localNumber,
                        name: action.phoneName.name,
                        loadingStatus: 'done'
                    }
                }
            return state
        }
        default:
            return state
    }
}
export function CallResReducer(
    state = InitialCallResState,
    action: ReducedCallsActions
): CallResState {
    switch (action.type) {
        case CALL_FIND_RESOURCES: {
            return {
                ...state,
                mapiRes: { ...state.mapiRes, loadingStatus: 'started' }
            }
        }
        case CALL_LOAD_RESOURCES: {
            return {
                ...state,
                mercuryRes: { ...state.mercuryRes, loadingStatus: 'started' }
            }
        }
        case CALL_CLEAR_RESOURCES: {
            return {
                ...state,
                mapiRes: {
                    at: {},
                    all: [],
                    loadingStatus: 'not-started'
                },
                mercuryRes: {
                    at: {},
                    all: [],
                    loadingStatus: 'not-started'
                }
            }
        }
        case CALL_DELETE_RESOURCE: {
            const newState = { ...state }
            delete newState.mercuryRes.at[action.callId]
            newState.mercuryRes.all = returnWithout(
                newState.mercuryRes.all,
                action.callId
            )
            return {
                ...state,
                mercuryRes: {
                    ...state.mercuryRes,
                    at: {
                        ...newState.mercuryRes.at
                    },
                    all: [...newState.mercuryRes.all]
                }
            }
        }
        case CALL_STORE_RESOURCES: {
            const newState = {
                ...state,
                mercuryRes: {
                    at: {
                        ...action.mercuryRes.reduce((a, r) => {
                            return {
                                ...a,
                                [r.id]: r
                            }
                        }, {})
                    },
                    all: action.mercuryRes.map((r) => r.id),
                    loadingStatus: 'done'
                }
            }
            return { ...newState } as any
        }
        case CALL_STORE_FOUND_RESOURCES: {
            const newState = {
                ...state,
                mapiRes: {
                    at: {
                        ...action.mapiRes.reduce((a, r) => {
                            return {
                                ...a,
                                [r.id]: {
                                    ...state.mapiRes.at[r.id],
                                    ...r
                                }
                            }
                        }, {})
                    },
                    all: action.mapiRes.map((r) => r.id),
                    pagination: { ...action.pagination } as any,
                    loadingStatus: 'done'
                }
            }
            return { ...newState } as any
        }
        default:
            return state
    }
}
