import styled from 'styled-components'

export const ListPageSidebar = styled.div`
    background-color: ${(p) => p.theme['side.background']};
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: sticky;
    align-items: stretch;
    top: 0;
    left: 0;
    width: 250px;
    margin-left: -1px;
    overflow: auto;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 15px 20px;
    z-index: 1000;
    border-right: 1px solid ${(p) => p.theme['back.background.strongerI']};
    &:focus {
        outline: none;
    }
`
