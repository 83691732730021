import { ReactElement, useCallback, useMemo } from 'react'
import { ConvertIndexPathStringToIndexPath } from '../../../store/applicationResources/utils'
import { uppercaseFirstLetter } from '../../../utils'
import numeral from 'numeral'
import { MerchantApplicationFieldValue } from './Application.FieldValue'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { MerchantApplicationFieldFormattedTextValue } from './Application.FieldFormattedTextValue'
import { useGateways } from '../../../hooks/general/useGateways'

export const useMerchantApplicationFormChangesBuilder = (id: string) => {
    const people = useSelector((state: RootState) => state.applicationResources.data?.forApplication?.[id]?.people)
    const websites = useSelector((state: RootState) => state.applicationResources.data?.forApplication?.[id]?.websites)
    const gateways = useGateways()

    const changeFormatter = useMemo(
        () => ({
            textForFieldName: (e: any) => {
                const indexPath = ConvertIndexPathStringToIndexPath(e)
                const segments = e.split('.')
                if (indexPath?.resourceKey === 'websites') {
                    const url = websites.fields?.[indexPath.subsectionIndex || 0]?.url
                    const section = url || `${numeral(indexPath.subsectionIndex || 0 + 1).format('0o')} Website`
                    return `${section} • ${uppercaseFirstLetter(indexPath.fieldKey)}`
                }
                if (indexPath?.resourceKey === 'people') {
                    const name = people.fields?.[indexPath.subsectionIndex || 0]?.name
                    const section = name || `${numeral(indexPath.subsectionIndex || 0 + 1).format('0o')} Person`
                    return `${section} • ${uppercaseFirstLetter(indexPath.fieldKey)}`
                }

                if (segments[1]) {
                    let section = uppercaseFirstLetter(segments[2])
                    if (section === 'Gateways') section = uppercaseFirstLetter(segments[4])
                    if (section === 'Signer') section = uppercaseFirstLetter(segments[3])
                    if (section === 'Business model') section = uppercaseFirstLetter(segments[3])
                    if (section === 'Sanitised') section = 'Sanitised ' + uppercaseFirstLetter(segments[3])
                    if (parseInt(segments[1]) === 0) {
                        return `${uppercaseFirstLetter(segments[0])} • ${section}`
                    } else {
                        return `${segments[1]}. ${uppercaseFirstLetter(segments[0])} • ${section}`
                    }
                } else {
                    return uppercaseFirstLetter(segments[0])
                }
            },
            textForChange(f: any, val: any): ReactElement {
                return <MerchantApplicationFieldValue indexPathString={f} rawValue={val} />
            }
        }),
        [people, websites]
    )

    const merchantApplicationReviewFormattedChange = useCallback(
        (
            c: {
                key: string
                from: string
                to: string
            },
            textOnly?: boolean
        ): {
            col: string
            from: string | ReactElement
            to: string | ReactElement
        } => {
            const col = changeFormatter.textForFieldName(c.key)
            if (textOnly) {
                if (!gateways) throw 'No gateways found'
                const from = MerchantApplicationFieldFormattedTextValue(gateways, c.key, c.from)
                const to = MerchantApplicationFieldFormattedTextValue(gateways, c.key, c.to)
                return { col, from, to }
            } else {
                const from = changeFormatter.textForChange(c.key, c.from)
                const to = changeFormatter.textForChange(c.key, c.to)
                return { col, from, to }
            }
        },
        [gateways, changeFormatter]
    )

    const formatChangesForReviewInput = useCallback(
        (
            prefix: string,
            changesArr?: {
                key: string
                from: string
                to: string
            }[]
        ): string => {
            if (!changesArr) return ''
            if (changesArr.length == 0) return ''
            return `\n${prefix}${changesArr.reduce((acc, c) => {
                const change = merchantApplicationReviewFormattedChange(c, true)
                const text =
                    (change.to as string).length > 50
                        ? '>   ' + (change.to as string).match(/[\s\S]{50}/g)?.join('\n>   ')
                        : '>   ' + change.to

                acc += `\n${change.col}:\n${text}\n`
                return acc
            }, '')}`
        },
        [merchantApplicationReviewFormattedChange]
    )

    return {
        changeFormatter,
        formatChangesForReviewInput,
        merchantApplicationReviewFormattedChange
    }
}
