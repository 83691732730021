import countries from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import styled, { DefaultTheme, ThemeProvider } from 'styled-components'
import { HotkeysObserver } from './components/complexComponents/hotkeysObserver'
import { Toasts } from './components/toasts/toasts'
import { Dialog } from './contexts/dialog'
import { DialogProvider } from './contexts/dialogProvider'
import { Page } from './Page'
import { PusherHelper } from './PusherHelper'
import { FC } from 'react'
import { useTheme } from './hooks/general/useTheme'
import { useSelector } from 'react-redux'
import { kebabCase } from 'lodash'
import { ExportsWatcher } from './store/exports/exportsWatcher'
import { RootState } from '@/store'

countries.registerLocale(en)

const App: FC = () => {
    const theme = useTheme()
    const scope = useSelector((state: RootState) => state.interface.modals.frontmost)

    return (
        <ThemeProvider theme={theme as DefaultTheme}>
            <DialogProvider>
                <LoggedInPage>
                    <PageHolder data-cy={`modal-scope-${kebabCase(scope)}`}>
                        <Page />
                        <Toasts />
                        <Dialog />
                        <PusherHelper />
                        <ExportsWatcher />
                        <HotkeysObserver />
                    </PageHolder>
                </LoggedInPage>
            </DialogProvider>
        </ThemeProvider>
    )
}

const LoggedInPage = styled.div`
    @media print {
        padding-left: 0px !important;
    }
`

const PageHolder = styled.div`
    @media print {
        height: auto;
    }
`

export default App
