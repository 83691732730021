import { LoadingStatus } from '../../utils'

interface CommonSubmittedFiledData {
    label: string
    wholeFile?: any
    scope?: string
}
export interface SubmitFileData extends CommonSubmittedFiledData {
    API: 'mapi'
    contentType: string
    name: string
    size: string
}
export interface ConvertedSubmitFileData extends CommonSubmittedFiledData {
    content_type: string
    name: string
    label: string
    size: string
}

export interface ConvertedSubmitFileDataWithPrefix
    extends Omit<SubmitFileData, 'contentType' | 'name' | 'size' | 'API'> {
    document_content_type: string
    document_file_name: string
    document_file_size: string
}

export interface CutterSubmitFileData extends CommonSubmittedFiledData {
    API: 'cutter'
    document_content_type: string
    document_file_name: string
    document_file_size: string
}

export interface SubmitFileDataWithPrefix
    extends Omit<SubmitFileData, 'contentType' | 'name' | 'size'> {
    documentContentType: string
    documentFileName: string
    documentFileSize: string
}

export interface File {
    commentsLink: string
    id: string
    label?: string
    scope?: string
    name?: string
    size?: string
    createdAt?: string
    selfLink?: string
    downloadLink?: string
    refetchLink?: string
    generatedDownloadLink?: string
    contentType: string
    generatingDownloadLink?: LoadingStatus
    uploadLink?: string
    progress?: number
    metadata?: { deleted: boolean }
}

export interface FileWithPrefix
    extends Omit<File, 'contentType' | 'name' | 'size'> {
    documentContentType: string
    documentFileName: string
    documentFileSize: string
}

export interface FilesState {
    at: {
        [key: string]: File
    }
    all: string[]
}

export const InitialFilesState: FilesState = {
    at: {},
    all: []
}
