import { useEffect, useRef } from 'react'
import { NEW_CONTRACT_TEMPLATE_SUFFIX } from '../../store/contractTemplates/sagas'

export const useLabelFocuser = (fields: any, form: any) => {
    const hasFocusedOnMount = useRef(false)

    useEffect(() => {
        if (hasFocusedOnMount.current) return
        if (fields?.current?.label?.ref) {
            if (form.data?.label?.includes(NEW_CONTRACT_TEMPLATE_SUFFIX)) {
                const field = fields?.current?.label?.ref
                if (field.value?.length) {
                    field.focus?.()
                    field.setSelectionRange?.(0, field.value?.length)
                    hasFocusedOnMount.current = true
                }
            }
        }
    }, [fields, form, hasFocusedOnMount])
}
