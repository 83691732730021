import { createReducer } from 'deox'
import dotProp from 'dot-prop'
import { produce } from 'immer'
import { ApplicationInternalsActions } from '../actions'

import { ApplicationInternalsRelatedActions } from './actions'
import { InitialApplicationInternalsRelatedState } from './types'

export const ApplicationInternalsRelatedReducer = createReducer(
    InitialApplicationInternalsRelatedState,
    (handleAction) => [
        handleAction(ApplicationInternalsRelatedActions.STORE, (state, { payload: p }) => {
            return produce(state, (draft) => {
                dotProp.set(draft, `forApplication.${p.applicationId}`, {
                    related: p.related,
                    loadingStatus: 'done',
                    loadingNextStatus: 'done',
                    nextLink: p?.nextLink,
                    count: p.count
                })
            })
        }),
        handleAction(ApplicationInternalsRelatedActions.LOAD, (state, { payload: p }) => {
            return produce(state, (draft: any) => {
                dotProp.set(draft, `forApplication.${p.applicationId}`, {
                    loadingStatus: 'started'
                })
            })
        }),
        handleAction(ApplicationInternalsRelatedActions.LOAD_NEXT, (state, { payload: p }) => {
            return produce(state, (draft: any) => {
                dotProp.set(draft, `forApplication.${p.applicationId}`, {
                    ...state.forApplication[p.applicationId],
                    loadingNextStatus: 'started'
                })
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = { ...InitialApplicationInternalsRelatedState }
                return draft
            })
        })
    ]
)
