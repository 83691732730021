import { Dropdown } from '@/design-system'
import { Application } from '@/store/applications/types'
import { safeWindowOpen } from '@/utils'
import React from 'react'

const MIDGARD_ROOT = import.meta.env.VITE_MIDGAARD_LINK

interface MerchantSidebarLinksApplicationProps {
    application: Application
}

export const MerchantSidebarLinksApplication: React.FC<MerchantSidebarLinksApplicationProps> = (props) => {
    const { application } = props

    const handleOnApplicationSelect = () => {
        const link = `${MIDGARD_ROOT}/redirect/application/${application.id}`

        safeWindowOpen(link)
    }

    return <Dropdown.Item onSelect={handleOnApplicationSelect}>Application</Dropdown.Item>
}
