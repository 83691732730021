import { FormCheckBox } from './form-checkbox'
import { FormTextarea } from './form-textarea'
import React from 'react'

interface FormFieldType {
    name: string
    type: string
    label?: string
    placeholder?: string
}

interface FormFieldProps extends FormFieldType {
    disabled?: boolean
}

export const FormField: React.FC<FormFieldProps> = (props) => {
    const { name, type, label = '', placeholder = 'Write here...', disabled } = props

    if (type === 'textarea') {
        return (
            <FormTextarea name={name} placeholder={placeholder} disabled={disabled} />
        )
    }

    if (type === 'checkbox') {
        return <FormCheckBox name={name} label={label} disabled={disabled} />
    }

    return null
}
