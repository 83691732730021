import { useFormContext } from 'react-hook-form'

export const useDocumentationStatus = () => {
    const { watch } = useFormContext()

    const watchFields: string[] = watch([
        'company',
        'websites',
        'accounts',
        'management',
        'ownership_ubo',
        'ownership_structure'
    ])

    return watchFields.filter((documentation) => documentation === 'true').length
}
