import styled, { css } from 'styled-components'
import { BIG_SMALL_SCREEN_SWAP } from '../../pages/Merchant/Accounts/utils'

export const CenteredScreen = styled.div<{ maxWidth?: number; paddingTop?: number | string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    flex-grow: 1;
    min-height: calc(100vh);
    ${(p) =>
        p.paddingTop !== undefined
            ? css`
                  padding-top: ${p.paddingTop}px;
              `
            : css`
                  padding-top: 32px;
              `};

    @media (max-width: ${BIG_SMALL_SCREEN_SWAP}px) {
        padding-top: 20px;
    }
    ${(p) =>
        p.maxWidth &&
        css`
            width: 100%;
            max-width: ${p.maxWidth}px;
            margin: 0 auto;
            align-self: center;
            align-items: stretch;
        `}
`
