import {
    Call,
    CallsFilters,
    QueuesFilters,
    CallSummary,
    CallQueue,
    CallResState,
    PhoneName
} from './types'
import { SearchResult } from '../search/types'

export const CALLS_LOAD_SUMMARIES = 'CALLS_LOAD_SUMMARIES'
export const CALLS_STORE_SUMMARIES = 'CALLS_STORE_SUMMARIES'
export const CALL_LOAD = 'CALL_LOAD'
export const CALL_STORE = 'CALL_STORE'
export const CALLS_CLEAR_SUMMARIES = 'CALLS_CLEAR_SUMMARIES'
export const CALL_FIND_RESOURCES = 'CALL_FIND_RESOURCES'
export const CALL_LOAD_RESOURCES = 'CALL_LOAD_RESOURCES'
export const CALL_CLEAR_RESOURCES = 'CALL_CLEAR_RESOURCES'
export const CALL_ADD_RESOURCE = 'CALL_ADD_RESOURCE'
export const CALL_DELETE_RESOURCE = 'CALL_DELETE_RESOURCE'
export const CALL_STORE_FOUND_RESOURCES = 'CALL_STORE_FOUND_RESOURCES'
export const CALL_STORE_RESOURCES = 'CALL_STORE_RESOURCES'
export const CALLS_LOAD_QUEUES = 'CALLS_LOAD_QUEUES'
export const CALLS_STORE_QUEUES = 'CALLS_STORE_QUEUES'
export const CALLS_REMOVE_QUEUE_NUMBER = 'CALLS_REMOVE_QUEUE_NUMBER'
export const CALLS_UPDATE_SUMMARIES = 'CALLS_UPDATE_SUMMARIES'
export const CALLS_LOAD_PHONE_NAME = 'CALLS_LOAD_PHONE_NAME'
export const CALLS_STORE_PHONE_NAME = 'CALLS_STORE_PHONE_NAME'

export interface CallActionLoad {
    type: typeof CALL_LOAD
    internalCallId: string
}

export interface CallActionStore {
    type: typeof CALL_STORE
    call: Call
}

export interface CallsActionLoadSummaries {
    type: typeof CALLS_LOAD_SUMMARIES
    filters: CallsFilters
}

export interface CallsActionStoreSummaries {
    type: typeof CALLS_STORE_SUMMARIES
    callsSummaries: CallSummary[]
}

export interface CallsActionUpdateSummaries {
    type: typeof CALLS_UPDATE_SUMMARIES
    update: {
        internal_call_id: string
        provider_call_id: string
        from: string
        to: string
        start_time: string
        connect_time: string
        end_time: string
        queue_id?: string
        call_type: string
    }
}

export interface CallActionClearSummaries {
    type: typeof CALLS_CLEAR_SUMMARIES
}

export interface CallActionClearResources {
    type: typeof CALL_CLEAR_RESOURCES
}

export interface CallActionAddResource {
    type: typeof CALL_ADD_RESOURCE
    callId: string
    resourceId: string
}

export interface CallActionDeleteResource {
    type: typeof CALL_DELETE_RESOURCE
    callId: string
}

export interface CallActionFindResources {
    type: typeof CALL_FIND_RESOURCES
    query: string
    page?: number
    perPage?: number
}

export interface CallActionLoadResources {
    type: typeof CALL_LOAD_RESOURCES
    phone: string
}

export interface CallActionStoreResources {
    type: typeof CALL_STORE_RESOURCES
    mercuryRes: SearchResult[]
}

export interface CallActionStoreFoundResources {
    type: typeof CALL_STORE_FOUND_RESOURCES
    mapiRes: SearchResult[]
    pagination: CallResState['mapiRes']['pagination']
}

export interface CallsActionLoadQueues {
    type: typeof CALLS_LOAD_QUEUES
    filters: QueuesFilters
}

export interface CallsActionStoreQueues {
    type: typeof CALLS_STORE_QUEUES
    queuePhones: CallQueue
}

export interface CallsActionRemoveQueueNumber {
    type: typeof CALLS_REMOVE_QUEUE_NUMBER
    queue: string
    number: string
}

export interface CallsActionLoadPhoneName {
    type: typeof CALLS_LOAD_PHONE_NAME
    localNumber: string
}

export interface CallsActionStorePhoneName {
    type: typeof CALLS_STORE_PHONE_NAME
    phoneName: PhoneName
}

export const CallDispatchAddResource = (
    callId: string,
    resourceId: string
): CallActionAddResource => {
    return { type: CALL_ADD_RESOURCE, callId, resourceId }
}

export const CallDispatchDeleteResource = (
    callId: string
): CallActionDeleteResource => {
    return { type: CALL_DELETE_RESOURCE, callId }
}

export const CallDispatchFindResources = (
    query: string,
    page?: number,
    perPage?: number
): CallActionFindResources => {
    return { type: CALL_FIND_RESOURCES, query, page, perPage }
}

export const CallDispatchLoadResources = (
    phone: string
): CallActionLoadResources => {
    return { type: CALL_LOAD_RESOURCES, phone }
}

export const CallDispatchStoreResources = (
    mercuryRes: SearchResult[],
): CallActionStoreResources => {
    return { type: CALL_STORE_RESOURCES, mercuryRes }
}

export const CallDispatchStoreFoundResources = (
    mapiRes: SearchResult[],
    pagination: CallResState['mapiRes']['pagination']
): CallActionStoreFoundResources => {
    return { type: CALL_STORE_FOUND_RESOURCES, mapiRes, pagination }
}

export const CallsDispatchLoadSummaries = (
    filters: CallsFilters
): CallsActionLoadSummaries => {
    return {
        type: CALLS_LOAD_SUMMARIES,
        filters
    }
}

export const CallsDispatchStoreSummaries = (
    callsSummaries: CallSummary[]
): CallsActionStoreSummaries => {
    return { type: CALLS_STORE_SUMMARIES, callsSummaries}
}

export const CallsDispatchUpdateSummaries = (
    update: {
        internal_call_id: string
        provider_call_id: string
        from: string
        to: string
        start_time: string
        connect_time: string
        end_time: string
        queue_id?: string
        call_type: string
    }
): CallsActionUpdateSummaries => {
    return { type: CALLS_UPDATE_SUMMARIES, update }
}

export const CallsDispatchLoadQueues = (
    filters: QueuesFilters
): CallsActionLoadQueues => {
    return {
        type: CALLS_LOAD_QUEUES,
        filters
    }
}

export const CallsDispatchStoreQueues = (
    queuePhones: CallQueue
): CallsActionStoreQueues => {
    return { type: CALLS_STORE_QUEUES, queuePhones}
}

export const CallsDispatchLoadPhoneName = (
    localNumber: string
): CallsActionLoadPhoneName => {
    return {
        type: CALLS_LOAD_PHONE_NAME,
        localNumber
    }
}

export const CallsDispatchStorePhoneName = (
    phoneName: PhoneName
): CallsActionStorePhoneName => {
    return { type: CALLS_STORE_PHONE_NAME, phoneName}
}

export const CallDispatchRemoveQueueNumber = (
    queue: string,
    number: string
): CallsActionRemoveQueueNumber => {
    return { type: CALLS_REMOVE_QUEUE_NUMBER, queue, number }
}

export const CallsDispatchClearSummaries = (): CallActionClearSummaries => {
    return { type: CALLS_CLEAR_SUMMARIES }
}

export const CallDispatchClearResources = (): CallActionClearResources => {
    return { type: CALL_CLEAR_RESOURCES }
}

export const CallDispatchLoad = (internalCallId: string): CallActionLoad => {
    return { type: CALL_LOAD, internalCallId }
}

export const CallDispatchStore = (call: Call): CallActionStore => {
    return { type: CALL_STORE, call }
}

export interface SagasForCalls {
    loadSummaries(action: CallsActionLoadSummaries): void,
    findResources(action: CallActionFindResources) : void,
    loadResources(action: CallActionLoadResources) : void,
    loadQueues(action: CallsActionLoadQueues) : void,
    getPhoneName(action: CallsActionLoadPhoneName) : void,
    deleteResource(action: CallActionDeleteResource) : void,
    addResource(action: CallActionAddResource) : void
}

export type ReducedCallsActions =
    | CallsActionStoreSummaries
    | CallsActionLoadSummaries
    | CallsActionUpdateSummaries
    | CallActionLoad
    | CallActionStore
    | CallActionClearSummaries
    | CallActionClearResources
    | CallActionAddResource
    | CallActionDeleteResource
    | CallActionFindResources
    | CallActionLoadResources
    | CallActionStoreFoundResources
    | CallActionStoreResources
    | CallsActionStoreQueues
    | CallsActionLoadQueues
    | CallsActionRemoveQueueNumber
    | CallsActionLoadPhoneName
    | CallsActionStorePhoneName
