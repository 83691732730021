import React from 'react'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import { styles } from './styles'
import { cn } from '../utils'
import { Card } from '../card'
import { Flex } from '../flex'
import { Box } from '../box'
import { Separator } from '../separator'
import { Text } from '../text'

const AlertDialog = (props: AlertDialogPrimitive.AlertDialogProps) => {
    const { children } = props
    return <AlertDialogPrimitive.Root {...props}>{children}</AlertDialogPrimitive.Root>
}

const Trigger = AlertDialogPrimitive.Trigger
const Portal = AlertDialogPrimitive.Portal

const Overlay: React.FC<AlertDialogPrimitive.AlertDialogOverlayProps> = (props) => {
    const classnames = cn(styles.overlay())

    return <AlertDialogPrimitive.Overlay className={classnames} {...props} />
}

type ContentProps = React.ComponentProps<typeof AlertDialogPrimitive.Content>
const Content: React.FC<ContentProps> = (props) => {
    const { children } = props

    const classnames = cn(styles.content())

    return (
        <Portal>
            <Overlay>
                <AlertDialogPrimitive.Content className={classnames}>
                    <Card>{children}</Card>
                </AlertDialogPrimitive.Content>
            </Overlay>
        </Portal>
    )
}

const Body = Card.Body

interface FooterProps {
    children: React.ReactNode
}

const Footer: React.FC<FooterProps> = (props) => {
    const { children } = props

    return (
        <Card.Footer>
            <Flex justify="between">{children}</Flex>
        </Card.Footer>
    )
}

interface TitleProps {
    children: React.ReactNode
}

const Title: React.FC<TitleProps> = (props) => {
    const { children } = props

    return (
        <AlertDialogPrimitive.Title>
            <Box mb="3">
                <Text weight="medium">{children}</Text>
            </Box>
            <Box mb="3">
                <Separator />
            </Box>
        </AlertDialogPrimitive.Title>
    )
}

interface DescriptionProps {
    children: string
}

const Description: React.FC<DescriptionProps> = (props) => {
    const { children } = props

    return (
        <AlertDialogPrimitive.Description>
            <Box py="3">
                <Text>{children}</Text>
            </Box>
        </AlertDialogPrimitive.Description>
    )
}

interface ActionProps {
    children: React.ReactNode
    onClick?: () => void
}

const Action: React.FC<ActionProps> = (props) => {
    const { children, onClick } = props

    return <AlertDialogPrimitive.Action onClick={onClick}>{children}</AlertDialogPrimitive.Action>
}

interface CancelProps {
    children: React.ReactNode
}

const Cancel: React.FC<CancelProps> = (props) => {
    const { children } = props

    return <AlertDialogPrimitive.Cancel asChild>{children}</AlertDialogPrimitive.Cancel>
}

AlertDialog.Trigger = Trigger
AlertDialog.Portal = Portal
AlertDialog.Content = Content
AlertDialog.Title = Title
AlertDialog.Description = Description
AlertDialog.Action = Action
AlertDialog.Cancel = Cancel
AlertDialog.Body = Body
AlertDialog.Footer = Footer

export { AlertDialog }
