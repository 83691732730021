import React from 'react'
import { cn } from '../utils'
import { styles } from './styles'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckIcon } from '../icon'

interface CheckboxProps {
    testid?: string
    id?: string
    name?: string
    defaultChecked?: boolean
    disabled?: boolean
    onCheckedChange?: (checked: boolean) => void
    checked?: boolean
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>((props, ref) => {
    const { testid, id, name, defaultChecked, checked, onCheckedChange } = props

    const rootClassnames = cn(styles.root())
    const indicatorClassnames = cn(styles.indicator())

    return (
        <CheckboxPrimitive.Root
            data-cy={testid}
            className={rootClassnames}
            id={id}
            ref={ref}
            name={name}
            checked={checked}
            onCheckedChange={onCheckedChange}
            defaultChecked={defaultChecked}
        >
            <CheckboxPrimitive.CheckboxIndicator className={indicatorClassnames}>
                <CheckIcon color="white" size="4" />
            </CheckboxPrimitive.CheckboxIndicator>
        </CheckboxPrimitive.Root>
    )
})

Checkbox.displayName = 'Checkbox'

export { Checkbox }
