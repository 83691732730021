import { useSelector } from 'react-redux'
import { MODAL_ID } from '../../components/modals/modalIds'
import { RootState } from '@/store'

export const useMerchantSidebarDynamicPageId = () => {
    const pageId = useSelector((state: RootState) => {
        const currentActiveModal = state.interface.modals.frontmost
        const responsivePageIds: MODAL_ID[] = [
            'Merchant',
            'Merchant.Summary',
            'Merchant.SettingsPage',
            'Merchant.RiskPage',
            'Merchant.ApplicationPage',
            'Merchant.AccountsPage',
            'TransactionsList',
            'SettlementsList',
            'DisputesList'
        ]
        const ignorePageIds: MODAL_ID[] = [
            'Merchant.ApplicationPage.FieldEdits',
            'Merchant.ApplicationPage.FieldComments'
        ]

        if (!state.interface.modals.frontmost) return 'Merchant'

        if (ignorePageIds.includes(state.interface.modals.frontmost)) return 'Merchant'

        if (state.interface.modals.frontmost.includes('Merchant.ApplicationPage.')) return currentActiveModal

        if (responsivePageIds.includes(state.interface.modals.frontmost)) return currentActiveModal

        return 'Merchant'
    })

    return pageId
}
