import { ArrowLeftIcon, Box, Button, Flex, Text } from '@/design-system'
import React from 'react'

interface SubPageHeaderProps {
    testid?: string
    children: React.ReactNode
}

const SubPageHeader = (props: SubPageHeaderProps) => {
    const { testid, children } = props

    return (
        <Box testid={testid} my="4">
            <Flex align="center" justify="between">
                {children}
            </Flex>
        </Box>
    )
}

interface BackButtonProps {
    testid?: string
    onClick: () => void
}

const BackButton: React.FC<BackButtonProps> = (props) => {
    const { testid, onClick } = props

    return (
        <Box>
            <Button testid={testid} variant="solid" color="neutral" onClick={onClick}>
                <Flex align="center" gap="1.5">
                    <ArrowLeftIcon size="4" />
                    Back
                </Flex>
            </Button>
        </Box>
    )
}

interface TitleProps {
    testid?: string
    children: React.ReactNode
}

const Title: React.FC<TitleProps> = (props) => {
    const { testid, children } = props

    return (
        <Box>
            <Text testid={testid} weight="medium" lineHeight="none">
                {children}
            </Text>
        </Box>
    )
}

interface Action {
    testid?: string
    children: React.ReactNode
    onClick: () => void
}

const Action: React.FC<Action> = (props) => {
    const { testid, children, onClick } = props

    return (
        <Button testid={testid} onClick={onClick}>
            {children}
        </Button>
    )
}

const Empty: React.FC = () => {
    return <div />
}

SubPageHeader.BackButton = BackButton
SubPageHeader.Title = Title
SubPageHeader.Action = Action
SubPageHeader.Empty = Empty

export { SubPageHeader }
