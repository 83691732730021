import React from 'react'
import { ApplicationSectionHeading } from './Application.SectionHeading'
import ApplicationBankAccountMismatchWarning from './Application.BankAccountMismatchWarning'
import { ApplicationValidationSection } from './Application.ValidationSection'
import { useApplicationHasWarnings } from '@/hooks/application/useApplicationHasWarnings'

interface ApplicationWarningsProps {
    applicationId: string
}

const ApplicationWarnings: React.FC<ApplicationWarningsProps> = (props) => {
    const { applicationId } = props
    const { hasWarnings, warnings } = useApplicationHasWarnings(applicationId)

    if (!hasWarnings) {
        return null
    }

    return (
        <>
            <ApplicationSectionHeading>Warnings</ApplicationSectionHeading>
            {warnings.mismatch && <div />}
            <ApplicationBankAccountMismatchWarning applicationId={applicationId} />
            {warnings.validation && <div />}
            <ApplicationValidationSection applicationId={applicationId} />
        </>
    )
}

export { ApplicationWarnings }
