import { snakeCase } from 'lodash'
import { delay, put, select, take } from 'typed-redux-saga'

import { GET, POST, PUT } from '../../generators/networking'
import { CollectionWithPagination, Pagination } from '../../utils'
import { ApplicationInternalsChecksActions } from '../applicationInternals/checks/actions'
import { ParseChecksFromCutterRequest } from '../applicationInternals/checks/utils'
import { ApplicationInternalsCompanyActions } from '../applicationInternals/company/actions'
import { CutterCompany } from '../applicationInternals/company/types'
import { ApplicationInternalsDetailsActions } from '../applicationInternals/details/actions'
import { ApplicationInternalsNeedingAttentionActions } from '../applicationInternals/needingAttention/actions'
import { ApplicationResourceActions } from '../applicationResources/actions'
import { CutterFieldPathsToApplicationStructureFieldPaths } from '../applicationResources/utils'
import { DashboardDispatchLoadStats } from '../dashboard/actions'
import { DisputesDispatchClearSummaries } from '../disputes/actions'
import { MerchantTimelineActions } from '../merchantTimeline/actions'
import { RootState } from '@/store'
import { SettlementsDispatchClearSummaries } from '../settlements/actions'
import { ToastsDispatchPush } from '../toasts/actions'
import { TransactionsDispatchClearSummaries } from '../transactions/actions'
import { WatcherDispatchSuccess } from '../watcher/actions'
import { FilesActionDispatchClearAll } from '../files/actions'
import {
    ApplicationsActionApproveDraftAgreement,
    ApplicationsActionCleanup,
    ApplicationsActionCreateDraftAgreement,
    ApplicationsActionEditEntity,
    ApplicationsActionFetchCutterInfo,
    ApplicationsActionLoadApplication,
    ApplicationsActionLoadCVRInfo,
    ApplicationsActionLoadEntries,
    ApplicationsActionMarkCommentAsRead,
    ApplicationsActionRefuseDraftAgreement,
    ApplicationsActionRemoveInternalComment,
    ApplicationsActionSendInternalComment,
    ApplicationsActionStoreApplication,
    ApplicationsActionStoreCutterCommentsInfo,
    ApplicationsActionStoreInternalComment,
    ApplicationsActionStoreReadComment,
    ApplicationsActionUpdateCutterCommentsInfo,
    ApplicationsActionUpdateDraftAgreement,
    ApplicationsDispatchFetchCutterInfo,
    ApplicationsDispatchMarkCommentAsRead,
    ApplicationsDispatchStoreApplication,
    ApplicationsDispatchStoreBVDInfo,
    ApplicationsDispatchStoreCutterCommentsInfo,
    ApplicationsDispatchStoreCVRInfo,
    ApplicationsDispatchStoreDraftAgreement,
    ApplicationsDispatchStoreEntity,
    ApplicationsDispatchStoreEntries,
    ApplicationsDispatchStoreInternalComment,
    ApplicationsDispatchStoreInternalInteractions,
    ApplicationsDispatchStoreReadComment,
    ApplicationsDispatchUnstoreInternalComment,
    ApplicationsDispatchUpdateCutterCommentsInfo,
    APPLICATIONS_STORE_CUTTER_COMMENTS_INFO,
    SagasForApplications
} from './actions'
import { ApplicationsMainTableEntry } from './types'
import {
    ConvertPerSectionChangesToFieldPathChanges,
    GetPerSectionChangesHistory,
    ParseCommentsInfoFromCutterRequest
} from './utils'
import { ApplicationInternalsActions } from '../applicationInternals/actions'
import { MerchantAccountsActions } from '../merchantAccounts/actions'
import { ApplicationLoadingActions } from '../applicationLoading/actions'
import { waitForCriticalResources } from '../auth/sagas'
import { all, spawn } from '@redux-saga/core/effects'
import { ApplicationInternalsAgentsActions } from '../applicationInternals/agents/actions'
import { ApplicationInternalsTagsActions } from '../applicationInternals/tags/actions'
import { ApplicationInternalsStampingActions } from '../applicationInternals/stamping/actions'
import { ApplicationDataProvidersActions } from '../applicationDataProviders/actions'
import { ApplicationInternalsRecordActions } from '../applicationInternals/record/actions'
import { TQLConverter } from '@/utils/tqlConverter'

const CUTTER_INCLUDES = [
    'company',
    'contact',
    'websites',
    'bank_accounts',
    'people',
    'application_files',
    'comments',
    'self',
    'checks',
    'versions'
]
export const MAPI_APPLICATION_INCLUDES = [
    'self',
    'comments',
    'stamps',
    'files',
    'agents',
    'contact',
    'company',
    'record',
    'tags',
    'accounts',
    'bank-account',
    'websites',
    'gateways'
]

export class ApplicationsSagas implements SagasForApplications {
    *onStoreReadComment(action: ApplicationsActionStoreReadComment) {
        yield delay(300)
        yield put(ApplicationInternalsNeedingAttentionActions.SCAN_COMMENTS(action.applicationId))
    }
    *markCommentAsRead(action: ApplicationsActionMarkCommentAsRead) {
        if (action.waitForCommentsInfo) {
            yield take(APPLICATIONS_STORE_CUTTER_COMMENTS_INFO)
        }

        const link = yield* select(
            (state: RootState) =>
                state.applications.cutterCommentsInfo?.forApplication?.[action.applicationId]?.[action.commentId]
                    ?.cutterSelfLink
        )

        if (!link) return

        const { success, cleanedResponse } = yield* POST({
            successCode: 200,
            url: link,
            body: { read_at: new Date() }
        })

        if (success) {
            yield put(ApplicationsDispatchStoreReadComment(action.applicationId, cleanedResponse.id))
        }
    }
    *updateCutterCommentsInfo(action: ApplicationsActionUpdateCutterCommentsInfo) {
        const genericApplicationLink = yield* select((state: RootState) => state.global?.links?.cutter.applicationLink)

        const includes = [
            'company',
            'contact',
            'websites',
            'bank_accounts',
            'people',
            'comments',
            'application_files',
            'self'
        ]

        const request = yield* GET({
            url: `${genericApplicationLink}${action.applicationId}?expand=${includes.join(',')}`,
            errorText: 'Failed to update application comments.',
            include: includes
        })

        const cutterCommentsInfo = ParseCommentsInfoFromCutterRequest(request)

        yield put(ApplicationsDispatchStoreCutterCommentsInfo(action.applicationId, cutterCommentsInfo))
    }

    *onStoreCutterCommentsInfo(action: ApplicationsActionStoreCutterCommentsInfo) {
        yield put(ApplicationInternalsNeedingAttentionActions.SCAN_COMMENTS(action.applicationId))
    }
    *fetchCutterInfo(action: ApplicationsActionFetchCutterInfo) {
        yield waitForCriticalResources()
        const genericApplicationLink = yield* select((state: RootState) => state.global?.links?.cutter.applicationLink)

        if (!genericApplicationLink) return

        const request = yield* GET({
            url: `${genericApplicationLink}${action.applicationId}?expand=${CUTTER_INCLUDES.join(',')}`,
            errorText: 'Failed to load Application.',
            include: [...CUTTER_INCLUDES, 'self']
        })

        yield put(ApplicationInternalsCompanyActions.STORE(action.applicationId, request?.company))

        const checksState = ParseChecksFromCutterRequest(request) as any
        yield put(ApplicationInternalsChecksActions.STORE_CHECKS(action.applicationId, checksState))

        yield put(MerchantTimelineActions.PROCESS(request, action.applicationId))
        const changesPerSection = GetPerSectionChangesHistory(request)
        const changesForFieldPath = ConvertPerSectionChangesToFieldPathChanges(changesPerSection)
        const fixCutterPathsIconsitenciesForFieldsHistory =
            CutterFieldPathsToApplicationStructureFieldPaths(changesForFieldPath)

        const cutterCommentsInfo = ParseCommentsInfoFromCutterRequest(request)

        yield put(ApplicationsDispatchStoreCutterCommentsInfo(action.applicationId, cutterCommentsInfo))

        yield put(ApplicationsDispatchStoreInternalInteractions(request))
        yield put(ApplicationInternalsDetailsActions.STORE_VALUES(request))

        yield put(
            ApplicationResourceActions.STORE_FIELDS_HISTORY(
                action.applicationId,
                fixCutterPathsIconsitenciesForFieldsHistory
            )
        )

        yield put(ApplicationInternalsNeedingAttentionActions.SCAN_COMMENTS(action.applicationId))
        if (action.watcherId) {
            yield put(WatcherDispatchSuccess([action.watcherId], 'Application updated successfully.'))
        }
    }

    *cleanup(action: ApplicationsActionCleanup) {
        yield put(TransactionsDispatchClearSummaries())
        yield put(SettlementsDispatchClearSummaries())
        yield put(DisputesDispatchClearSummaries())
        yield put(ApplicationInternalsActions.CLEAR(action.applicationId))
        yield put(ApplicationLoadingActions.CLEAR(action.applicationId))
        yield put(FilesActionDispatchClearAll())
        yield put(MerchantTimelineActions.CLEAR(action.applicationId))
        yield put(ApplicationResourceActions.CLEANUP(action.applicationId))
        yield put(MerchantAccountsActions.CLEAR())
    }
    *storeApplication(action: ApplicationsActionStoreApplication) {
        yield put(
            ApplicationInternalsStampingActions.STORE(
                action.application.id,
                (action.application as any).stampsLink || []
            )
        )
    }
    *loadApplication(action: ApplicationsActionLoadApplication) {
        if (action.fetch === 'full-application') {
            yield put(ApplicationLoadingActions.APPLICATION_STARTED_LOADING(action.id))
            yield put(ApplicationInternalsStampingActions.INIT(action.id))
            yield put(ApplicationsDispatchFetchCutterInfo(action.id))
        }

        if (action.fetch === 'full-application') {
            const requests = [
                // Old HARVESTER (commented out due to service being shut down)
                // put(ApplicationInternalsHarvesterActions.FETCH(action.id)),
                put(ApplicationResourceActions.FETCH_WEBSITES(action.id)),
                put(ApplicationResourceActions.FETCH_WEBSITES_HISTORY(action.id)),
                put(ApplicationResourceActions.FETCH_GATEWAY(action.id)),
                put(ApplicationDataProvidersActions.FETCH(action.id)),
                put(
                    ApplicationInternalsTagsActions.FETCH_APPLICATION_TAGS(
                        action.id,
                        `${import.meta.env.VITE_API_ROOT}/applications/${action.id}/tags`
                    )
                ),
                spawn(function* () {
                    const application = yield* GET({
                        url: `${import.meta.env.VITE_API_ROOT}/applications/${action.id}`,
                        errorText: 'Failed to load Application.',
                        hintedHalJSON: true,
                        include: [...MAPI_APPLICATION_INCLUDES]
                    })

                    if (!application) {
                        yield put(ToastsDispatchPush('Failed to load application', 'error'))
                    }

                    yield all([
                        put(ApplicationsDispatchStoreApplication(application)),
                        ...(application?.agentsLink
                            ? [put(ApplicationInternalsAgentsActions.FETCH(action.id, application.agentsLink))]
                            : [put(ApplicationInternalsAgentsActions.STORE(action.id, undefined))]),
                        put(ApplicationInternalsRecordActions.FETCH(action.id, application.recordLink)),
                        put(ApplicationResourceActions.FETCH_CONTACT(action.id, application.contactLink)),
                        put(ApplicationResourceActions.FETCH_COMPANY(action.id, application.companyLink)),
                        put(ApplicationResourceActions.FETCH_PEOPLE(action.id, application.companyLink + '/people')),
                        put(ApplicationResourceActions.FETCH_PEOPLE_HISTORY(
                            action.id, application.companyLink + '/people/version-history')),
                        put(ApplicationResourceActions.FETCH_BANK_ACCOUNT(action.id, application.bankAccountLink)),
                        put(ApplicationResourceActions.STORE_SIGNER(application.id, application.signer, application)),
                        put(
                            ApplicationResourceActions.STORE_BUSINESS_MODEL(
                                application.id,
                                application.businessModel,
                                application
                            )
                        ),
                        put(
                            ApplicationResourceActions.STORE_ADDITIONAL(
                                application.id,
                                {
                                    additionalInformation: application.additionalInformation
                                },
                                application
                            )
                        )
                    ])
                })
            ]
            yield all(requests)
        }
    }

    *loadEntries(action: ApplicationsActionLoadEntries) {
        // Disabled status bar caching, on request by the agents
        // const shouldLoad = yield select((state: RootState) => state.dashboard.loadingStatus === 'not-started')
        // if (shouldLoad)
        yield put(DashboardDispatchLoadStats('DASHBOARD_STATS'))

        let fetchUrl = `/dashboard/${action.filters.applications_status}${
            TQLConverter.applications(action.filters)
        }&page=${action.filters.applications_page}&per_page=${action.filters.applications_per_page || 27}`
        if (action.filters.applications_sort) fetchUrl += `&sort_by=${snakeCase(action.filters.applications_sort)}`
        if (action.filters.applications_direction) fetchUrl += `&direction=${action.filters.applications_direction}`
        yield GET({
            url: import.meta.env.VITE_CUTTER_ROOT + fetchUrl,
            errorText: 'Failed to load Application.',
            onSuccessDispatch: (r: { items: ApplicationsMainTableEntry[]; count: number }) => {
                const summaries: CollectionWithPagination<ApplicationsMainTableEntry, Pagination> = {
                    at: {},
                    all: [],
                    loadingStatus: 'done',
                    pagination: {
                        total: r?.count,
                        page: action.filters.applications_page,
                        perPage: action.filters.applications_per_page || 27
                    }
                }

                r.items.forEach((value) => {
                    summaries.at[value.id] = { ...value }
                    summaries.all.push(value.id)
                })

                return ApplicationsDispatchStoreEntries(summaries)
            }
        })
    }

    *removeInternalComment(action: ApplicationsActionRemoveInternalComment) {
        yield POST({
            url: action.comment.selfLink,
            successCode: 200,
            watcher: action.watcherID,
            body: {
                deleted: true
            },
            onSuccessDispatch: (r) => ApplicationsDispatchUnstoreInternalComment(action.applicationId, r),
            successText: 'Internal comment removed successfully',
            errorText: 'Failed to remove internal comment'
        })
    }

    *editEntity(action: ApplicationsActionEditEntity) {
        const { success } = yield POST({
            url: action.editLink,
            watcher: action.watcher,
            body: action.changes,
            onSuccessDispatch: (r) => ApplicationsDispatchStoreEntity(action.watcher, action.path, r),
            successText: 'Application updated.',
            successCode: 200,
            errorText: 'Failed to update application field.'
        })

        if (!success) return

        yield GET({
            url: `${action.editLink}?expand=comments,versions`,
            onSuccessDispatch: (r) => ApplicationsDispatchStoreEntity(action.watcher, action.path, r),
            errorText: `Failed to update application entity after field update`
        })
    }

    *loadCVR(action: ApplicationsActionLoadCVRInfo) {
        const company: CutterCompany = yield select((state: RootState) => {
            return state.applicationInternals.company.forApplication[action.applicationId]
        })

        if (company && company.number) {
            yield GET({
                url: `${import.meta.env.VITE_VOYAGER_ENDPOINT}/companies/DK/${company.number}`,
                onSuccessDispatch: (r) => ApplicationsDispatchStoreCVRInfo(action.applicationId, r),
                errorText: `Failed to update application entity after field update`
            })
        }
    }

    *loadBVD(action: ApplicationsActionLoadCVRInfo) {
        const company: CutterCompany = yield select((state: RootState) => {
            return state.applicationInternals.company.forApplication[action.applicationId]
        })

        if (!company?.bvdId) {
            return
        }

        yield GET({
            url: `${import.meta.env.VITE_KVASIR_API}/companies/${company.bvdId}`,
            disableContentType: true,
            errorText: `Failed to update application entity after field update`,
            onSuccessDispatch: (r) => ApplicationsDispatchStoreBVDInfo(action.applicationId, 'details', r),
            onFailureDispatch: () => ApplicationsDispatchStoreBVDInfo(action.applicationId, 'details', undefined)
        })

        yield GET({
            url: `${import.meta.env.VITE_KVASIR_API}/companies/${company.bvdId}/ownership-tree`,
            disableContentType: true,
            errorText: `Failed to update application entity after field update`,
            onSuccessDispatch: (r) => ApplicationsDispatchStoreBVDInfo(action.applicationId, 'ownership', r),
            onFailureDispatch: () => ApplicationsDispatchStoreBVDInfo(action.applicationId, 'ownership', undefined)
        })
    }

    *updateDraftAgreement(action: ApplicationsActionUpdateDraftAgreement) {
        yield POST({
            watcher: action.watcherId,
            url: action.agreementLink,
            body: action.payload,
            include: ['self'],
            onSuccessDispatch: (r) =>
                ApplicationsDispatchStoreDraftAgreement(action.watcherId, action.agreementPath, r),
            successText: 'Agreement updated successfully.',
            errorText: `Failed to update the agreement`
        })
    }

    *createDraftAgreement(action: ApplicationsActionCreateDraftAgreement) {
        yield PUT({
            watcher: action.watcherId,
            url: `${action.agreementLink}/stamps/ready`,
            include: ['self'],
            successText: 'Draft agreement created successfully',
            errorText: 'Failed to create draft agreement.',
            onSuccessDispatch: (r) => ApplicationsDispatchStoreDraftAgreement(action.watcherId, action.agreementPath, r)
        })
    }

    *refuseDraftAgreement(action: ApplicationsActionRefuseDraftAgreement) {
        yield PUT({
            watcher: action.watcherId,
            url: `${action.agreementLink}/stamps/refused`,
            include: ['self'],
            successText: 'Draft agreement refused successfully.',
            errorText: 'Failed to refuse draft agreement.',
            onSuccessDispatch: (r) => ApplicationsDispatchStoreDraftAgreement(action.watcherId, action.agreementPath, r)
        })
    }

    *approveDraftAgreement(action: ApplicationsActionApproveDraftAgreement) {
        yield PUT({
            watcher: action.watcherId,
            url: `${action.agreementLink}/stamps/approved`,
            include: ['self'],
            successText: 'Draft agreement approved successfully.',
            errorText: 'Failed to approve draft agreement.',
            onSuccessDispatch: (r) => ApplicationsDispatchStoreDraftAgreement(action.watcherId, action.agreementPath, r)
        })
    }
    *onStoreInternalComment(action: ApplicationsActionStoreInternalComment) {
        yield put(ApplicationInternalsNeedingAttentionActions.SCAN_COMMENTS(action.applicationId))
    }
    *sendInternalComment(action: ApplicationsActionSendInternalComment) {
        const { success, cleanedResponse } = yield POST({
            watcher: action.watcherID,
            url: action.commentsLink,
            body: {
                content: action.body,
                field: action.field,
                private: true
            },
            include: ['self'],
            successText: `Internal note added successfully.`,
            errorText: `Internal comment sending failed.`
        })

        if (success) {
            yield put(ApplicationsDispatchStoreInternalComment(action.applicationId, cleanedResponse))
            yield put(ApplicationsDispatchMarkCommentAsRead(action.applicationId, cleanedResponse.id, true))
            yield put(ApplicationsDispatchUpdateCutterCommentsInfo(action.applicationId))
        }
    }
}
