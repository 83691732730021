
import styled, { css } from 'styled-components'

export const ModalPageInset: React.FC<{ children: any; center?: boolean }> = ({
    children,
    center
}) => {
    return <Holder center={center}>{children}</Holder>
}

const Holder = styled.div<{ center?: boolean }>`
    padding: 0 20px 0px 20px;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    ${(p) =>
        p.center &&
        css`
            align-items: center;
        `}
`
