import { isArray, isObject } from 'lodash'
import { ConvertIndexPathToFieldDetails } from '../../pages/Merchant/Application/Application.Structure'
import { ConvertIndexPathStringToIndexPath } from '../applicationResources/utils'

export function findMerchantApplicationFieldWithMostCommentsRecursively(
    body: any,
    acc?: any,
    path?: string
): {
    indexPathString: string
    count: number
    link: string
} {
    if (!acc) acc = {}
    if (isObject(body)) {
        Object.keys(body).forEach((key: string) => {
            if (key === 'comments') {
                if (!path) return
                acc[path] = {
                    comments: (body as any)['comments'].length,
                    commentsLink: (body as any)['commentsLink']
                }
            } else if (isArray((body as any)[key]) || isObject((body as any)[key])) {
                findMerchantApplicationFieldWithMostCommentsRecursively(
                    (body as any)[key],
                    acc,
                    `${path ? `${path}.` : ''}${key}`
                )
            }
        })
    }

    let result = {
        indexPathString: '',
        count: 0,
        link: ''
    }

    if (path) return result
    Object.keys(acc).forEach((k) => {
        const i: {
            comments: number
            commentsLink: string
        } = acc[k] as any

        const indexPathString = k.replace('.fields', '')
        const indexPath = ConvertIndexPathStringToIndexPath(indexPathString)
        const fieldDetails = indexPath ? ConvertIndexPathToFieldDetails(indexPath) : undefined

        if (i.comments > result.count && !fieldDetails?.field?.hasCommentsDisabled) {
            result.count = i.comments
            result = {
                count: i.comments,
                indexPathString: indexPathString,
                link: i.commentsLink
            }
        }
    })
    return result
}
