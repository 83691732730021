import { useForm as useHookForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export type SigningKeySchema = yup.InferType<typeof schema>

const schema = yup
    .object()
    .shape({
        enabled: yup.boolean().required(),
        expires_at: yup.string(),
        verification_key: yup.string().required()
    })
    .required()

export const useSigningKeyForm = (defaultValues?: SigningKeySchema) =>
    useHookForm<SigningKeySchema>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            enabled: defaultValues ? defaultValues.enabled : false,
            expires_at: defaultValues?.expires_at,
            verification_key: defaultValues?.verification_key
        }
    })
