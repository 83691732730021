import { LoaderView } from '../../../components/loaders/loader'
import { PageContent } from '../../../components/layout/pageContent'
import { useListPageMerchantFilter } from '../../../hooks/pages/useListPageMerchantFilter'
import { Settlements } from '../../Settlements/Settlements'
import { Flex } from '../../../components/layout/flex'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantSettlements: React.FC = () => {
    const params = useParams() as Params

    const merchantId = params.id

    const [mids, midsLoadingFinished] = useListPageMerchantFilter('settlements_mid', merchantId)

    if (!midsLoadingFinished)
        return (
            <Flex grow column justify="center">
                <LoaderView overBackground="back.background" />
            </Flex>
        )

    return (
        <PageContent className="POPPER_PARENT">
            <Settlements inline merchantId={merchantId} forMids={mids} />
        </PageContent>
    )
}
