import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ButtonInset } from '../../components/buttons/buttonInset'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { Flex } from '../../components/layout/flex'
import { Floater } from '../../components/layout/floater'
import { FloaterInset } from '../../components/layout/floaterInset'
import { Spacer } from '../../components/layout/spacer'
import { TextInput } from '../../components/forms/textInput'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { useNamedWatcher } from '../../hooks/general/useWatcher'
import { TaskDispatchCreateAndAssignTag } from '../../store/tasks/actions'
import { Task } from '../../store/tasks/types'

export const AlertTagsManagerCreateTag: React.FC<{ task: Task }> = ({ task }) => {
    const [container, setContainer] = useRefTaker()
    const [toggleButton, setToggleButton] = useRefTaker()
    const [showFloater, setShowFloater] = useState(false)
    const tagName = useRef()
    const dispatch = useDispatch()
    const [watcher] = useNamedWatcher(`AlertTagsManager.NewTagFloater.CreateTag`)

    useOnHoverOutside(
        container,
        useCallback(() => {
            setShowFloater(false)
        }, [setShowFloater])
    )

    useEffect(() => {
        if (watcher === 'success') setShowFloater(false)
    }, [watcher])

    const handleChange = useCallback((e, value) => {
        tagName.current = value
    }, [])

    const handleFloaterToggle = useCallback(() => {
        setShowFloater((t) => !t)
    }, [])

    const handleCreateTag = useCallback(
        (e) => {
            if (!task) throw 'Task does not exist'
            if (!tagName.current) return
            dispatch(
                TaskDispatchCreateAndAssignTag(
                    `AlertTagsManager.NewTagFloater.CreateTag`,
                    task.addTagsLink,
                    tagName.current!
                )
            )
        },
        [dispatch, task]
    )

    return (
        <div ref={setContainer}>
            <div ref={setToggleButton}>
                <SimpleButton background="front.background" onClick={handleFloaterToggle}>
                    <ButtonInset padding="medium">New tag</ButtonInset>
                </SimpleButton>
            </div>
            <Floater
                shouldShow={showFloater}
                cardId="AlertTagsManager.NewTagFloater"
                anchor={toggleButton}
                onHide={() => {
                    setShowFloater(false)
                }}
            >
                <FloaterInset equalPadding>
                    <Flex>
                        <TextInput
                            overBackground="floating.background"
                            placeholder="New tag name"
                            onChange={handleChange}
                        />
                        <Spacer width={10} />
                        <WatcherButton onClick={handleCreateTag}>
                            <ButtonInset padding="small">Create and assign Tag</ButtonInset>
                        </WatcherButton>
                    </Flex>
                </FloaterInset>
            </Floater>
        </div>
    )
}
