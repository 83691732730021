import React, { useCallback, useMemo, useState } from 'react'
import { Table, TableRow } from '../../components/tables/table'

import { BubbledCheck } from '../../components/icons/bubbledCheck'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { LinkButton } from '../../components/buttons/linkButton'
import { PageHeader } from '../../components/layout/pageHeader'
import { RootState } from '@/store'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Spacer } from '../../components/layout/spacer'
import { TableFuzzySearch } from '../../components/layout/tableFuzzySearch'
import { Text } from '../../components/general/text'
import { useGateways } from '../../hooks/general/useGateways'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { useLocation } from 'react-router-dom'

const queryParams = ['search', 'show-search']
export const Gateways: React.FC = () => {
    const [_, setQuery] = useQueryParams(queryParams, undefined)
    const [filteredRows, setFilteredRows] = useState<TableRow[]>([])
    const gateways = useGateways()
    useModalStackSync('Manage gateways', 'Manage.Gateways', 0)
    const location = useLocation()

    const columns = useMemo(() => {
        return [
            {
                text: 'Name',
                loaderSize: {
                    min: 34,
                    max: 125
                }
            },
            {
                text: 'Type',
                loaderSize: {
                    min: 30,
                    max: 90
                }
            },
            {
                text: 'Status',
                loaderSize: {
                    min: 65,
                    max: 65
                }
            },
            {
                text: 'Order',
                alignRight: true,
                loaderSize: {
                    min: 10,
                    max: 50
                }
            },
            {
                text: 'Actions',
                loaderSize: {
                    min: 180,
                    max: 180
                }
            }
        ]
    }, [])

    const handleSearchClick = useCallback(
        (id) => {
            setQuery({
                'search': 'gateways.id:' + id,
                'show-search': 'yes'
            })
        },
        [setQuery]
    )

    const rows = useMemo(() => {
        const getGatewayType = (gateway: RootState['gateways']['gateways']['at']['id']) => {
            if (gateway.referral) {
                if (gateway.support) return 'PSP & Referral'
                return 'Referral'
            }
            if (gateway.support) return 'PSP'
            return '-'
        }

        return gateways.all
            .sort((a, b) => {
                if (gateways.at[a].order > gateways.at[b].order) return -1
                else return 1
            })
            .sort((a, b) => {
                if (gateways.at[a].active) return -1
                return 0
            })
            .map((id: string) => {
                const gateway = gateways.at[id]
                return {
                    type: 'normal' as const,
                    key: gateway.id,
                    noAction: true,
                    items: [
                        {
                            node: (
                                <Text
                                    bold={gateway.active}
                                    oneLine
                                    color={gateway.active ? 'front.text' : 'front.text.subtlerI'}
                                >
                                    {gateway.name}
                                </Text>
                            )
                        },
                        {
                            node: (
                                <Text color={gateway.active ? 'front.text' : 'front.text.subtlerI'}>
                                    {getGatewayType(gateway)}
                                </Text>
                            )
                        },
                        {
                            node: (
                                <Text>
                                    {gateway.active ? (
                                        <Flex align="center">
                                            <BubbledCheck />
                                            <Text color="front.subtleSuccess.text.strongerI" bold>
                                                Active
                                            </Text>
                                        </Flex>
                                    ) : (
                                        <Text color="front.text.subtlerI">Inactive</Text>
                                    )}
                                </Text>
                            )
                        },
                        {
                            node: <Text color="front.text.subtlerI">{gateway.order}</Text>
                        },
                        {
                            node: (
                                <Flex>
                                    <LinkButton keepQuery to={`/more/gateways/${gateway.id}`}>
                                        Edit
                                    </LinkButton>
                                    <Spacer width={20} />
                                    <SimpleButton
                                        onClick={() => {
                                            handleSearchClick(gateway.id)
                                        }}
                                    >
                                        Find related applications
                                    </SimpleButton>
                                </Flex>
                            )
                        }
                    ]
                }
            })
    }, [gateways, handleSearchClick])

    const rightSideMemo = useMemo(() => {
        return (
            <>
                <Spacer width={10} />
                <LinkButton
                    background="front.accent.color"
                    to={`/more/gateways/new${location.search ? location.search : ''}`}
                >
                    <ButtonInset>Add a new gateway</ButtonInset>
                </LinkButton>
            </>
        )
    }, [location.search])

    const leftSideMemo = useMemo(() => {
        return (
            <TableFuzzySearch
                headers={columns}
                queryParamName="gateways-query"
                rows={rows}
                setFilteredRows={setFilteredRows}
                ignoreHeaderWithText="Actions"
            />
        )
    }, [rows, setFilteredRows, columns])

    return (
        <>
            <PageHeader
                noBottomBorder
                leftSideMemo={leftSideMemo}
                title="Gateways"
                subtitle=""
                rightSideMemo={rightSideMemo}
            />
            <Card>
                <CardInset>
                    <Table
                        background="front.background"
                        cols={columns}
                        columnLayout="1fr 1fr 4fr 1fr 1fr"
                        showLastRowBorder
                        displayLoader={gateways.loadingStatus !== 'done'}
                        loaderStyles={{ minHeight: '90vh' }}
                        loaderRows={50}
                        rows={filteredRows}
                        emptyText="No vouchers found."
                    />
                </CardInset>
            </Card>
        </>
    )
}
