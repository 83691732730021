import React from 'react'
import { MonitoredFetchedApplicationData } from '../../../../../../store/tasksCompanyChanges/types'
import { useApplicationPersonAndChecks } from '../../../../hooks/Tasks.CompanyChange.useApplicationPersonAndChecks'
import { MonitoringTemplate } from '../MonitoringTemplate'
import { Description } from './components/Description/Description'
import { ApplicationButton } from '../../components/ApplicationButton'
import { Change } from './components/Change'
import { MuteButton } from './components/MuteButton'
import { CompanyRegistrationLink } from '../../components/CompanyRegistrationLink'
import { getFormattedOwnershipPercentage } from '../../helpers/getFormattedOwnershipPercentage'

export type MonitoringPersonType = 'ownership' | 'management'
export type MonitoringAfterType = {
    [key: string]: string | number
}

interface MonitoringPersonAddedProps {
    type: MonitoringPersonType
    applicationData?: MonitoredFetchedApplicationData
    subsectionId?: string
    after: { [key: string]: string | number } | null
    onMute: (e, generatedId) => void
    isMuted: boolean
}

export const MonitoringPersonAdded: React.FC<MonitoringPersonAddedProps> = (props) => {
    const { type, applicationData, subsectionId, after, onMute, isMuted } = props

    const name = after?.name?.toString()
    const ownershipPercentage = getFormattedOwnershipPercentage(after?.ownershipPercentage)

    const modifiedAfter = {
        ...after,
        ...(ownershipPercentage && {
            ownershipPercentage
        })
    }

    const { person, allInfoPresent, requiredInfo, missingInfo } = useApplicationPersonAndChecks(
        applicationData,
        name,
        subsectionId
    )

    let hasCorrectRole = false
    if (type === 'ownership') {
        if (person?.role === 'owner' || person?.role === 'director-and-owner') {
            hasCorrectRole = true
        } else {
            hasCorrectRole = false
        }
    } else if (type === 'management') {
        if (person?.role === 'director' || person?.role === 'director-and-owner') {
            hasCorrectRole = true
        } else {
            hasCorrectRole = false
        }
    }

    const status = allInfoPresent && hasCorrectRole ? 'complete' : isMuted ? 'muted' : 'in-progress'

    const applicationId = applicationData?.application.id

    return (
        <MonitoringTemplate status={status} type="main">
            <MonitoringTemplate.Container>
                <MonitoringTemplate.Status />
                <MonitoringTemplate.Main>
                    <MonitoringTemplate.Content>
                        <MonitoringTemplate.Description>
                            <Description
                                type={type}
                                status={status}
                                missingInfo={missingInfo}
                                requiredInfo={requiredInfo}
                                person={person}
                            />
                        </MonitoringTemplate.Description>
                        <MonitoringTemplate.Actions>
                            <MonitoringTemplate.Action>
                                <ApplicationButton applicationId={applicationId} applicationSection="people" />
                            </MonitoringTemplate.Action>
                            <MonitoringTemplate.Action>
                                <CompanyRegistrationLink company={applicationData?.company} />
                            </MonitoringTemplate.Action>
                            <MonitoringTemplate.Action>
                                <MuteButton isMuted={isMuted} onMute={onMute} />
                            </MonitoringTemplate.Action>
                        </MonitoringTemplate.Actions>
                    </MonitoringTemplate.Content>
                    <MonitoringTemplate.Change>
                        <Change after={modifiedAfter} applicationData={applicationData} />
                    </MonitoringTemplate.Change>
                </MonitoringTemplate.Main>
            </MonitoringTemplate.Container>
        </MonitoringTemplate>
    )
}
