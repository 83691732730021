import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { ValidationStatus } from '../../hooks/general/useForm'
import { useFormField } from '../../hooks/general/useFormField'
import { Color } from '../../styles/colors'
import { InputIndicator } from './inputIndicator'
import { InputSuffix } from '../general/inputSuffix'
import { Spacer } from '../layout/spacer'
import C from 'color'
import { SimpleButton } from '../buttons/simpleButton'

const TextInputWithRef: React.ForwardRefRenderFunction<
    any,
    {
        placeholder: string
        initialValue?: string | number
        overBackground: Color
        shouldClearOnFocus?: boolean
        onChange?: (e: React.KeyboardEvent<HTMLInputElement>, value: any) => void
        onEnter?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        onCommandEnter?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void
        onFocus?: (e: any) => void
        onBlur?: (e: any) => void
        onInput?: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => boolean
        className?: string
        rightAlign?: boolean
        suffix?: React.ReactElement | string
        isDisabled?: boolean
        validation?: ValidationStatus
        id?: string
        hideInputIndicator?: boolean
        type?: React.ComponentProps<'input'>['type']
        refTaker?: any
        escKeyOnceIsEnough?: boolean
        cy?: string
        forceValue?: string
        tabIndex?: number
        isSeamless?: boolean
        refInterceptor?: any
        forceValueKey?: string
        pattern?: string
        maintainForcedValueOnBlur?: boolean
        noFormFieldBehaviour?: boolean
        dimension?: 'normal' | 'smaller'
        isSimple?: boolean
        showClearButton?: boolean
    }
> = (
    {
        placeholder,
        onChange,
        onFocus,
        onBlur,
        shouldClearOnFocus,
        onEnter,
        onKeyDown,
        overBackground,
        onKeyUp,
        isDisabled,
        initialValue,
        isSeamless,
        forceValueKey,
        onInput,
        validation,
        showClearButton,
        maintainForcedValueOnBlur,
        onCommandEnter,
        id,
        dimension = 'normal',
        refInterceptor: rIntercept,
        hideInputIndicator,
        suffix,
        rightAlign,
        type = 'text',
        className,
        tabIndex,
        forceValue,
        isSimple,
        escKeyOnceIsEnough,
        refTaker,
        pattern,
        cy,
        noFormFieldBehaviour
    },
    ref
) => {
    const prev = useRef()
    const focused = useRef(false)
    const [isFocused, setIsFocused] = useState(false)
    const [hasValue, setHasValue] = useState(!!initialValue)
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue((v) => {
            if (v !== forceValue) return forceValue || ''
            return v
        })
    }, [forceValue, forceValueKey])

    useEffect(() => {
        setValue((v) => {
            if (v !== initialValue) return initialValue
            return v
        })
    }, [initialValue])

    const { refInterceptor, onFieldUpdate } = useFormField(
        useCallback(
            (value, field) => {
                setValue(value)
            },
            [setValue]
        ),
        useCallback(
            (ref) => {
                refTaker?.(ref.current)
                return ref?.current?.value
            },
            [refTaker]
        ),
        useCallback(
            (r) => {
                if (ref) {
                    if (typeof ref === 'function') ref(r)
                    else ref.current = r
                }
            },
            [ref]
        ),
        noFormFieldBehaviour,
        initialValue
    )

    const handleChange = useCallback(
        (e) => {
            if (shouldClearOnFocus) {
                prev.current = undefined
            }
            onChange?.(e, e.currentTarget.value)
        },
        [onChange, shouldClearOnFocus]
    )

    const handleFocus = useCallback(
        (e) => {
            setIsFocused(true)
            focused.current = true
            onFocus?.(e)
        },
        [onFocus]
    )

    const handleBlur = useCallback(
        (e) => {
            setIsFocused(false)
            focused.current = false
            onBlur?.(e)
            if (maintainForcedValueOnBlur) setValue(forceValue)
        },
        [onBlur, maintainForcedValueOnBlur, forceValue]
    )

    const handleKeyUp = useCallback(
        (e) => {
            if (e.currentTarget.value) {
                setHasValue(true)
            } else {
                setHasValue(false)
            }

            onKeyUp?.(e, e.currentTarget.value)
        },
        [onKeyUp]
    )

    const handleKeyDown = useCallback(
        (e) => {
            if (shouldClearOnFocus) {
                prev.current = undefined
            }
            onKeyDown?.(e, e.currentTarget.value)
            if (e.key === 'Escape') {
                e.currentTarget.blur()
            }
            if (e.key === 'Enter') {
                if (e.ctrlKey || e.metaKey) onCommandEnter?.(e, e.currentTarget.value)
                else onEnter?.(e, e.currentTarget.value)
            }
            if (e.key === 's') {
                e.stopPropagation()
            }
        },
        [onCommandEnter, onEnter, onKeyDown, shouldClearOnFocus]
    )
    const handleInput = useCallback(
        (e) => {
            if (onInput?.(e, e.target.value) == false) return
            onFieldUpdate?.(e.target.value)
            setValue(e.target.value)
        },
        [onFieldUpdate, onInput]
    )

    const shouldShowIndicator = useMemo(() => {
        if (validation) return true
        if (isSimple && !isDisabled && !hideInputIndicator) return true
        return false
    }, [isSimple, isDisabled, hideInputIndicator, validation])

    const clearButton = useMemo(() => {
        if (!showClearButton) return null

        if (!value) return null

        return (
            <ButtonHolder>
                <SimpleButton
                    background="front.background"
                    onClick={() => {
                        handleChange({
                            target: {
                                value: ''
                            },
                            currentTarget: {
                                value: ''
                            }
                        })
                    }}
                >
                    CLEAR FILTER
                </SimpleButton>
            </ButtonHolder>
        )
    }, [handleChange, showClearButton, value])

    return (
        <InputHolder>
            <Input
                dimension={dimension}
                ref={refInterceptor || rIntercept}
                placeholder={placeholder}
                key="input"
                isSimple={isSimple}
                isSeamless={isSeamless}
                className={`${className} ${escKeyOnceIsEnough ? 'HOTKEYS_ESC_FORCES_EXIT' : ''}`}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                overBackground={overBackground}
                pattern={pattern}
                id={id}
                value={value || ''}
                disabled={isDisabled}
                data-cy={cy}
                tabIndex={tabIndex || 1}
                autoComplete="off"
                type={type}
                onInput={handleInput}
                onKeyUp={handleKeyUp}
                rightAlign={rightAlign}
                onKeyDown={handleKeyDown}
            />
            <InputSuffix suffix={suffix} id={id} hasValue={hasValue} overBackground={overBackground} />

            {shouldShowIndicator && (
                <>
                    <Spacer width={10} />
                    <InputIndicator validationStatus={validation} hasValue={hasValue} isFocused={isFocused} />
                </>
            )}
            {clearButton}
        </InputHolder>
    )
}

export const TextInput = React.forwardRef(TextInputWithRef)

const ButtonHolder = styled.div`
    position: absolute;
    top: 6px;
    right: 6px;
    * {
        padding: 2px 5px;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 500;
    }
`

const InputHolder = styled.div`
    position: relative;
    align-items: center;
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
`

const Input = styled.input<{
    isSimple?: boolean
    isSeamless?: boolean
    rightAlign?: boolean
    dimension: 'normal' | 'smaller'
    overBackground: Color
}>`
    background-color: ${(p) => p.theme[p.overBackground.replace('.background', '.highlights') as Color]};
    padding: 0px 15px;
    border-radius: 8px;
    min-height: 34px;
    border: none;
    flex-grow: 1;
    width: 100%;
    color: ${(p) => p.theme[p.overBackground.replace('.background', '.text') as Color]};

    ${(p) =>
        p.dimension === 'smaller' &&
        css`
            min-height: 30px;
            padding: 0 10px;
        `}
    &::placeholder {
        color: ${(p) => p.theme[p.overBackground.replace('.background', '.text.subtlerI') as Color]};
    }
    &:focus::placeholder {
        color: transparent;
    }

    ${(p) =>
        p.rightAlign &&
        css`
            text-align: right;
        `}
    ${(p) =>
        (p.isSimple || p.isSeamless) &&
        css`
            padding: 0;
            width: 100%;
            caret-color: ${(p) => p.theme['front.accent.color']};
            min-height: 0;
            border-radius: 0;
            box-sizing: border-box;
            background-color: transparent;
            padding: 0;
            margin: -1px 0;
            border-bottom: 1px solid transparent;
            border-radius: 5px ;
            transition: background-color 0.35s ease;
            
            ${
                p.rightAlign
                    ? css`
                          margin-left: 0;
                          padding-left: 0;
                      `
                    : css`
                          padding-right: 0;
                          margin-right: 0;
                      `
            }}
            &:focus {
                background-color: transparent;
            }

            &.glow {
                background-color: ${C(p.theme['front.accent.color']).alpha(0.3).string()};
            }
            `}
`
