import { store } from '@/store'
import watch from 'redux-watch'

class RTKQuery {
    public static instance: RTKQuery = new RTKQuery()

    private _store: typeof store | undefined

    constructor() {
        this._store = store
    }

    private _authenticate = async () => {
        if (this._store) {
            if (this._store.getState().auth.token) {
                return
            }

            const promise = new Promise((resolve) => {
                if (this._store) {
                    const w = watch(this._store.getState, 'auth.token')

                    const unsubscribe = this._store.subscribe(
                        w(async () => {
                            if (this._store) {
                                if (this._store.getState().auth.token) {
                                    unsubscribe()
                                    resolve(null)
                                }
                            }
                        })
                    )
                }
            })

            await promise
        }
    }

    public async get(endpoint, request, query?, queryOptions?) {
        if (this._store) {
            await this._authenticate()

            const promise = this._store.dispatch(endpoint.initiate(query, queryOptions))

            request.signal.onabort = promise.abort

            const result = await promise

            promise.unsubscribe()

            return result
        }
    }

    public async post(endpoint, body) {
        if (this._store) {
            await this._authenticate()

            const promise = this._store.dispatch(endpoint.initiate(body))
            const result = await promise

            return result
        }
    }

    public async patch(endpoint, body) {
        if (this._store) {
            await this._authenticate()

            const promise = this._store.dispatch(endpoint.initiate(body))
            const result = await promise

            return result
        }
    }

    public async delete(endpoint, url) {
        if (this._store) {
            await this._authenticate()

            const promise = this._store.dispatch(endpoint.initiate(url))

            const result = await promise
            promise.unsubscribe()

            return result
        }
    }
}

const instance = RTKQuery.instance
export { instance as RTKQuery }
