import styled from 'styled-components'
import { MerchantSidebar } from './MerchantSidebar'
import { MERCHANT_SIDEBAR_WIDTH } from './MerchantSidebarWidth'
import { Outlet, useLocation, useParams } from 'react-router-dom'

type Params = { id: string; accountId: string }

export const Merchant: React.FC = () => {
    const params = useParams() as Params
    const location = useLocation()

    const applicationId = params.id
    const accountId = params.accountId

    return (
        <Holder>
            <MerchantSidebar
                width={MERCHANT_SIDEBAR_WIDTH}
                location={location}
                applicationId={applicationId}
                accountId={accountId}
            />
            <Page>
                <Outlet />
            </Page>
        </Holder>
    )
}

const Holder = styled.div`
    display: flex;
    position: relative;
`

const Page = styled.div`
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
`
