import { startCase } from 'lodash'
import React, { useMemo } from 'react'
import { useMedia } from 'react-use'
import styled, { css } from 'styled-components'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { Table } from '../../../components/tables/table'
import { Theme } from '../../../hooks/general/useTheme'
import { MerchantAccount } from '../../../store/merchantAccounts/types'
import { MERCHANT_SIDEBAR_WIDTH } from '../MerchantSidebarWidth'
import { useMerchantAccounts } from './useMerchantAccounts'
import { BIG_SMALL_SCREEN_SWAP } from './utils'
import { useLocation } from 'react-router-dom'

export const AccountsParams = ['transactions_mid', 'settlements_mid', 'disputes_mid']
export const MerchantAccountsSidebar: React.FC<{ id: string; accountId?: string; skipSidebar?: boolean }> = ({
    id,
    accountId,
    skipSidebar
}) => {
    const accounts = useMerchantAccounts(id)
    const location = useLocation()
    const shouldHide = useMedia(`(max-width: ${BIG_SMALL_SCREEN_SWAP}px)`)

    const navigationTable = useMemo(() => {
        const color = (acc: MerchantAccount) => {
            switch (acc.metadata.state) {
                case 'new':
                    return {
                        fill: false,
                        color: 'side.text.subtlerIII' as const
                    }
                case 'live':
                    return {
                        fill: true,
                        color: 'side.accent.color' as const
                    }
                case 'in_progress':
                    return {
                        fill: true,
                        color: 'front.info.background' as const
                    }
                default:
                    return {
                        fill: false,
                        color: 'side.text.subtlerIII' as const
                    }
            }
        }

        const accs = accounts.accounts || []

        const cols = [
            { text: '', loaderSize: { min: 4, max: 4 } },
            { text: '', loaderSize: { min: 40, max: 40 } },
            { text: '', loaderSize: { min: 30, max: 30 } },
            { text: '', loaderSize: { min: 30, max: 50 } },
            ...(skipSidebar ? [] : [{ text: '', loaderSize: { min: 30, max: 50 } }])
        ]

        const rows = accs.map((acc) => {
            const c = color(acc)
            return {
                type: 'normal' as const,
                key: acc.id,
                link: `/merchant/${id}/accounts/${acc.id}`,
                items: [
                    {
                        node: (
                            <BulletHolder>
                                {acc.paymentsHeld ? <PHTag>Ph</PHTag> : <Bullet color={c.color} $fill={c.fill} />}
                            </BulletHolder>
                        )
                    },
                    {
                        node: (
                            <HoverMaker>
                                <Text noWrap>
                                    {skipSidebar
                                        ? `${acc.merchantId} · ${acc.currency} · ${startCase(acc.metadata.state)}`
                                        : acc.merchantId}
                                </Text>
                            </HoverMaker>
                        )
                    },
                    ...(skipSidebar
                        ? []
                        : [
                              {
                                  node: (
                                      <HoverMaker>
                                          <Text noWrap>({acc.currency})</Text>
                                      </HoverMaker>
                                  )
                              },
                              {
                                  node: (
                                      <HoverMaker>
                                          <Text noWrap>{startCase(acc.metadata.state)}</Text>
                                      </HoverMaker>
                                  )
                              },
                              {
                                  node: acc.contract?.metadata?.state ? (
                                      <HoverMaker>
                                          <Flex align="center" justify="flex-end">
                                              <Spacer width={20} height={0} />
                                              {acc.contract?.metadata?.state
                                                  ? `${startCase(acc.contract?.metadata?.state)}`
                                                  : ''}
                                              &nbsp;&nbsp;
                                              <Icon type="merchantApplication" size={13} />
                                          </Flex>
                                      </HoverMaker>
                                  ) : (
                                      <span />
                                  )
                              }
                          ])
                ]
            }
        })
        return (
            <Table
                background="front.background"
                cols={cols}
                columnLayout={`30px min-content ${skipSidebar ? '' : 'min-content min-content min-content'}`}
                showLastRowBorder
                type="narrow"
                keepQuery
                displayLoader={accounts?.loadingStatus !== 'done'}
                loaderStyles={{ minHeight: '90vh' }}
                loaderRows={1}
                noHighlights
                columnsGap={5}
                rows={rows}
                emptyText="No vouchers found."
            />
        )
    }, [id, skipSidebar, accounts])

    const content = useMemo(() => {
        return (
            <Flex column align="stretch" grow justify="space-between">
                {skipSidebar ? <MiniNav>{navigationTable}</MiniNav> : navigationTable}
                <ButtonHolder skippingSidebar={skipSidebar}>
                    <LinkButton
                        background={skipSidebar ? undefined : 'side.subtleAccent.background'}
                        color={skipSidebar ? 'side.accent.color' : 'side.subtleAccent.text'}
                        key={'new-account'}
                        to={{
                            pathname: `/merchant/${id}/accounts/new`,
                            search: location.search
                        }}
                        state={{ selectedAccount: accountId }}
                        hotkeysScope="Merchant.AccountsPage"
                        hotkeys="alt+n"
                    >
                        <ButtonInset padding={skipSidebar ? 'tiny' : 'medium'}>
                            {skipSidebar ? '+ New' : 'New account'}
                        </ButtonInset>
                    </LinkButton>
                </ButtonHolder>
            </Flex>
        )
    }, [navigationTable, accountId, id, skipSidebar, location])

    if (accounts.loadingStatus !== 'done') return null
    if (accounts.accounts?.length === 0) return null
    if (skipSidebar) return <MiniNavHolder>{content}</MiniNavHolder>
    if (shouldHide) return null
    return (
        <>
            <Sidebar>{content}</Sidebar>
            {/* <SidebarPlaceholder /> */}
        </>
    )
}
const ButtonHolder = styled.div<{ skippingSidebar?: boolean }>`
    ${(p) =>
        p.skippingSidebar &&
        css`
            position: absolute;
            top: -27px;
            z-index: 100000;
            right: 0;
        `}
`

const MiniNavHolder = styled.div`
    position: relative;
    overflow: visible;
`
const MiniNav = styled.div`
    border-radius: 10px;
    padding: 0;
    margin: 0;
    padding-left: 38px;
    margin-bottom: 0px;
    margin-top: -5px;
    max-height: 200px;
    box-sizing: border-box;
    overscroll-behavior-y: none;
    overflow: auto;
    overflow-x: hidden;
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(p) =>
        p.theme[p.theme.name === 'whiteTheme' ? 'side.background' : 'side.background.strongerI']};
    padding: 20px;
    position: sticky;
    box-sizing: border-box;
    left: ${MERCHANT_SIDEBAR_WIDTH}px;
    top: 0;
    min-width: 300px;
    height: 100vh;
    z-index: 10;
    border-top: none;
    border-right: 1px solid ${(p) => p.theme['back.background.strongerI']};
    overflow: auto;
    flex-shrink: 0;
    transform: translate3d(0, 0, 0);
    transform-style: preserver-3d;
    backface-visibility: hidden;
`

const Bullet = styled.div<{ color: keyof Theme; $fill: boolean }>`
    width: 2px;
    height: 2px;
    flex-shrink: 0;
    border-radius: 999px;
    position: relative;
    top: -1px;
    border: 1px solid ${(p) => p.theme[p.color]};
    background-color: ${(p) => (p.$fill ? p.theme[p.color] : 'transparent')};
`

const BulletHolder = styled.div`
    width: 4px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const HoverMaker = styled.div`
    color: ${(p) => p.theme['side.text.subtlerI']};

    &:hover {
        color: ${(p) => p.theme['side.text.subtlerII']};
    }
    &:active {
        color: ${(p) => p.theme['side.text.subtlerIII']};
    }
    .active & {
        color: ${(p) => p.theme['side.text']};
        font-weight: 500;
    }
`

const PHTag = styled.div`
    position: absolute;
    font-size: 8px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    background-color: ${(p) => p.theme['front.danger.color']};
    padding: 0 4px;
    height: 13px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
`
