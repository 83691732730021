import { useMemo } from 'react'
import { FormHandler } from '../../../components/forms/formHandler'
import { Flex } from '../../../components/layout/flex'
import { MerchantApplicationStateControls } from './Application.StateControls'
import { useMerchantApplicationFormChangesBuilder } from './useMerchantApplicationFormChangesBuilder'

export const MerchantApplicationControls: React.FC<{
    id: string
    form: any
    errors: any
    watcherId: string
    focusInvalidField: any
    resetForm: any
    submitHandler: any
}> = ({ id, form, resetForm, watcherId, errors, focusInvalidField, submitHandler }) => {
    const { changeFormatter } = useMerchantApplicationFormChangesBuilder(id)
    const rightSideMemo = useMemo(() => {
        return <MerchantApplicationStateControls applicationId={id} />
    }, [id])

    const formHandler = useMemo(() => {
        if (form.changes ? !Object.keys(form.changes).length : true) return null
        return (
            <FormHandler
                formSubmitLabel="Save changes"
                changes={form.changes}
                errors={errors}
                textForFieldName={changeFormatter.textForFieldName}
                focusInvalidField={focusInvalidField}
                textForChange={changeFormatter.textForChange}
                submitForm={submitHandler}
                submitWatcherId={watcherId}
                resetForm={resetForm}
            />
        )
    }, [form, errors, watcherId, focusInvalidField, changeFormatter, resetForm, submitHandler])

    return formHandler ? (
        <Flex justify="flex-end" align="center">
            {formHandler}
        </Flex>
    ) : (
        <Flex justify="flex-end" align="baseline">
            {rightSideMemo}
        </Flex>
    )
}
