import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { LinkButton } from '../../../components/buttons/linkButton'
import { Flex } from '../../../components/layout/flex'
import { Table, TableRow } from '../../../components/tables/table'
import { Text } from '../../../components/general/text'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../../utils'
import { Spacer } from '../../../components/layout/spacer'
import styled from 'styled-components'

export const MerchantSummaryRelatedCard: React.FC<{ merchantId: string }> = ({ merchantId }) => {
    const related = useSelector(
        (state: RootState) => state.applicationInternals.related.forApplication[merchantId]
    )
    const loaded = useSelector(
        (state: RootState) => state.applicationLoading.at?.[merchantId]?.relatedApplicationsLoaded
    )

    const items = useMemo(() => {
        const cols = [
            { text: 'Company', loaderSize: { min: 30, max: 120 } },
            { text: 'State', loaderSize: { min: 30, max: 60 }, alignRight: true }
        ]

        const rows = (related?.related || [])
            .filter((entry, index) => {
                if (index > 4) return false
                return true
            })
            .map((entry, index, arr) => {
                const blocks: any[] = []
                for (let i = 0; i < entry?.matchingOn?.length; i++) blocks.push(<Block />)
                return {
                    type: 'subtle',
                    key: entry?.id,
                    link: `/merchant/${entry.id}/summary`,
                    linkTarget: 'applicationRelatedWindow',
                    items: [
                        {
                            node: <Text oneLine>{entry.name || '-'}</Text>
                        },
                        {
                            node: (
                                <Flex align="center">
                                    {entry?.state ? uppercaseFirstLetter(entry.state) : '-'}
                                    {blocks.length >= 2 ? (
                                        <>
                                            <Spacer width={4} />
                                            {blocks}
                                        </>
                                    ) : null}
                                </Flex>
                            )
                        }
                    ]
                } as TableRow
            })

        return (
            <Table
                type="narrow"
                rows={rows}
                cols={cols}
                displayLoader={!loaded}
                loaderRows={5}
                overrideText="No related applications found"
                cy="related-table"
                background="front.background"
                noHeader
                bordered
                columnLayout="minmax(0, 1fr) min-content"
            />
        )
    }, [related, loaded])

    const rightSideMemo = useMemo(() => {
        if (!related?.count) return null
        return (
            <Flex justify="flex-end" grow>
                <LinkButton
                    to={`/merchant/${merchantId}/summary/related`}
                    color="front.accent.color"
                    hotkeysScope="Merchant.Summary"
                    hotkeys="alt+r"
                >
                    See all ({related?.count || 0})
                </LinkButton>
            </Flex>
        )
    }, [related, merchantId])

    return (
        <Flex column>
            <Flex>
                <Text color="front.text.subtlerI">Related applications</Text>
                {rightSideMemo}
            </Flex>
            <Spacer height={15} />
            {items}
        </Flex>
    )
}

const Block = styled.div`
    height: 9px;
    width: 1px;
    border-radius: 1px;
    display: inline-block;
    background-color: ${(p) => p.theme['front.text.subtlerI']};
    margin-left: 1px;
    position: relative;
    top: -1px;
`
