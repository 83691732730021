import styled from 'styled-components'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { ApplicationDedicatedInternalSectionWrapper } from './Application.DedicatedInternalSectionWrapper'
import { DedicatedSectionProps, useDedicatedSection } from './Application.DedicatedSection'
import { MerchantApplicationInternalDetails } from './Application.InternalDetails'
import { MerchantApplicationSection } from './Application.Section'
import { MerchantApplicationSectionCheckmark } from './Application.SectionCheckmark'
import { useHasApplicationSectionLoaded } from './useHasApplicationSectionLoaded'

export const MerchantApplicationDedicateSectionGateway: React.FC<DedicatedSectionProps> = ({
    errors,
    executeBatchChanges,
    applicationId,
    formRef
}) => {
    const { structure, field, elements } = useDedicatedSection(applicationId, 'Websites')
    const hasLoaded = useHasApplicationSectionLoaded(applicationId, 'gateway', 'data')

    return (
        <>
            <Holder id="websites">
                {structure.reviewableResource && (
                    <MerchantApplicationSectionCheckmark
                        applicationId={applicationId}
                        resource={structure.reviewableResource}
                    />
                )}
                <Card showEditable>
                    <CardInset>
                        <MerchantApplicationSection
                            key={'key'}
                            formErrors={errors}
                            isDataLoading={!hasLoaded}
                            nextDynamicSection={undefined}
                            executeBatchChanges={executeBatchChanges}
                            sectionStructure={structure}
                            formRef={formRef}
                            applicationId={applicationId}
                        />
                    </CardInset>
                </Card>
            </Holder>
            <Flex column>
                <ApplicationDedicatedInternalSectionWrapper>
                    <MerchantApplicationInternalDetails sectionStructure={structure} applicationId={applicationId} />
                </ApplicationDedicatedInternalSectionWrapper>
            </Flex>
        </>
    )
}

const Holder = styled.div`
    position: relative;
`
