import { isEqual, omit, pick, intersection } from 'lodash'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { DictionaryToURLSearchParams, URLSearchParamsToDictionary } from '../../utils/general'
import { useLocation, useNavigate } from 'react-router-dom'
import { router } from '../../router'

const buildQueryDictionary = (params: any, query: any, defaultQueryParams: any) => {
    return params.reduce((acc: any, pKey: string) => {
        if (query[pKey]) acc[pKey] = query[pKey]
        else if (defaultQueryParams?.[pKey]) acc[pKey] = defaultQueryParams[pKey]
        else acc[pKey] = undefined
        return acc
    }, {} as any)
}

export function useQueryParams<T extends string>(params: T[], defaultQueryParams?: { [key in T]: string }) {
    const navigate = useNavigate()
    const location = useLocation()

    const query = queryString.parse(location.search)

    const [state, setState] = useState<{ [key: string]: string }>(
        buildQueryDictionary(
            params,
            query,
            intersection(Object.keys(query), params).length > 0 ? {} : defaultQueryParams
        )
    ) as any

    useEffect(() => {
        const query = URLSearchParamsToDictionary(new URLSearchParams(location.search))
        const queryParams = queryString.parse(location.search)
        const newQuery = DictionaryToURLSearchParams({
            ...(omit(queryParams, params) || {}),
            ...(state || {})
        })

        if (!isEqual(URLSearchParamsToDictionary(newQuery), query)) {
            navigate(
                {
                    search: newQuery.toString()
                },
                { replace: true }
            )
        }
    }, [navigate, params, state, location.search])

    useEffect(() => {
        const unlisten = router.subscribe(({ location }) => {
            setState((s: any) => {
                const queryObject = queryString.parse(location.search)
                const keys = Object.keys(queryObject)
                keys.map((key) => {
                    if (key.endsWith('_page')) {
                        queryObject[key] = Number(queryObject[key]) as any
                    }
                })
                const queryState = pick(queryObject, params)

                if (JSON.stringify(s) !== JSON.stringify(queryState)) {
                    return { ...queryState }
                }
                return s
            })
        })

        return unlisten
    }, [params])

    const append = useCallback(
        (fields: any) => {
            setState((q: any) => ({
                ...q,
                fields
            }))
        },
        [setState]
    )

    return [state, setState, append]
}
