import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LoaderView } from '../../../components/loaders/loader'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { RootState } from '@/store'
import { MerchantApplicationField } from './Application.Field'
import { DedicatedSectionProps } from './Application.DedicatedSection'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { GenerateIndexPathForSubsection, MerchantApplicationStructure } from './Application.Structure'
import { IMerchantApplicationSubsectionStructure } from './Application.StructureTypes'

export type DedicatedSubsectionProps = DedicatedSectionProps

export const useDedicatedSubsection = (id: string, name: string) => {
    const sectionStructure = MerchantApplicationStructure.find(
        (s: any) => s?.resource === name.toLowerCase()
    ) as IMerchantApplicationSubsectionStructure
    if (!sectionStructure) throw `Couldn't find subsection ${name}`

    const loader = useMemo(() => ({ Loader: <LoaderView overBackground="back.background" /> }), [])
    const subsections = useSelector((state: RootState) => {
        return state?.applicationResources?.data?.forApplication[id]?.[sectionStructure.resource]?.fields
    })

    const shouldRenderFullCard = useMemo(() => {
        if (!subsections) return false
        for (const subsection of subsections) {
            if (subsection?.filesLoadingStatus !== 'done') return false
        }
        return true
    }, [subsections])

    const field = sectionStructure?.fields?.[0] as any

    return {
        elements: shouldRenderFullCard ? null : loader,
        structure: sectionStructure,
        field
    }
}

export const useSubsection = (
    applicationId: string,
    structure: IMerchantApplicationSubsectionStructure,
    formRef: MerchantApplicationFieldFormProps['formRef'],
    executeBatchChanges: MerchantApplicationFieldFormProps['executeBatchChanges'],
    formErrors: { [key: string]: ValidationStatus }
): any => {
    const section = useSelector((state: RootState) => {
        return state?.applicationResources?.data?.forApplication?.[applicationId]?.[structure.resource]
    })

    const subSections = useMemo(() => {
        if (section?.loadingStatus !== 'done') return []
        return section.fields.map((section, subsectionIndex) => {
            return structure.fields.map((field, i: number) => {
                if (!field) return null

                if (field.kind === 'spacer') return null

                const indexPath: MerchantApplicationResourceIndexPath = GenerateIndexPathForSubsection(
                    structure,
                    subsectionIndex,
                    field
                )

                return (
                    <React.Fragment key={field.label}>
                        <MerchantApplicationField
                            executeBatchChanges={executeBatchChanges}
                            indexPath={indexPath}
                            formErrors={formErrors}
                            applicationId={applicationId}
                            isFirst={i === 0}
                            formRef={formRef}
                            type={field.type}
                            label={field.label}
                        />
                    </React.Fragment>
                )
            })
        })
    }, [formRef, section, applicationId, structure, formErrors, executeBatchChanges])

    return {
        resource: section,
        fields: subSections
    }
}
