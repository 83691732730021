import { forwardRef } from 'react'
import { TextInput } from '../../../components/forms/textInput'
import { FormFieldWithFormattedNoDecimalsNumber, numberFromFormattedString, numberWithCommas } from '../../../utils'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { ApplicationValidationRules } from '../Accounts/utils'
import { ValidationStatus } from '../../../hooks/general/useForm'

const MerchantApplicationEditsModalInputNumberWithRef: React.ForwardRefRenderFunction<
    any,
    React.ComponentProps<typeof TextInput> & {
        indexPathString: string
        formRef: MerchantApplicationFieldFormProps['formRef']
        validation: ValidationStatus
    }
> = ({
    cy,
    initialValue,
    onChange,
    validation,
    onCommandEnter,
    placeholder,
    overBackground,
    indexPathString,
    formRef
}) => {
    return (
        <TextareaInput
            overBackground={overBackground}
            key={initialValue}
            placeholder={placeholder}
            initialValue={initialValue ? numberWithCommas(`${initialValue}`, true) : undefined}
            cy={cy}
            isSeamless
            validation={validation}
            ref={(ref) => {
                formRef(
                    ref,
                    indexPathString,
                    ApplicationValidationRules.formattedNumber,
                    FormFieldWithFormattedNoDecimalsNumber
                )
            }}
            escKeyOnceIsEnough
            onCommandEnter={onCommandEnter}
            onChange={(e, val) => {
                const value = numberFromFormattedString(val)
                onChange?.(e, value)
            }}
        />
    )
}

export const MerchantApplicationEditsModalInputNumber = forwardRef(MerchantApplicationEditsModalInputNumberWithRef)
