import { createReducer } from 'deox'
import { produce } from 'immer'
import { TasksCompanyChangesActions } from './actions'
import { InitialTasksCompanyChangesState } from './types'

export const TasksCompanyChangesReducer = createReducer(InitialTasksCompanyChangesState, (handleAction) => [
    handleAction(TasksCompanyChangesActions.INIT, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft[p.taskId] = {
                loadingStatus: 'started',
                changes: undefined
            }
        })
    ),
    handleAction(TasksCompanyChangesActions.STORE, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft[p.taskId] = {
                loadingStatus: 'done',
                changes: p.state
            }
        })
    ),
    handleAction(TasksCompanyChangesActions.REFRESH_APPLICATION_STORE_DATA, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (state?.[p.taskId]?.changes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                draft[p.taskId].changes![p.applicationId].fetchedApplicationData = {
                    loadingStatus: 'done',
                    data: p.newData
                }
            }
        })
    )
])
