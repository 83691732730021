import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { RootState } from '@/store'
import { MerchantApplicationInternalLabel } from './Application.InternalLabel'
import { MerchantApplicationInternalLink } from './Application.InternalLink'
import { MerchantApplicationFile } from './Application.File'

const encodeParams = (query: string) => {
    return encodeURIComponent(query).toLowerCase()
}
export const MerchantApplicationInternalFile: React.FC<
    {
        label?: string
        internalFileLabel?: string
        link?: boolean
        visibilityState?: { [key: string]: boolean }
        visibilitySection?: string
        isLoading?: boolean
    } & React.ComponentProps<typeof MerchantApplicationFile>
> = (p) => {
    const newP = { ...p }
    const fileIds = useSelector(
        (state: RootState) =>
            state.applicationResources?.data?.forApplication?.[p.applicationId]?.[p.indexPath.resourceKey]?.fields?.[
                p.indexPath.subsectionIndex || 0
            ]?.[p.indexPath.fieldKey] || []
    )

    newP.background = 'greyBackground'
    newP.scope = 'private'
    newP.preventUpload = ['peopleHistory', 'websitesHistory'].includes(newP.indexPath.resourceKey)

    if (p.visibilitySection) if (fileIds.length !== 0 ? false : !p.visibilityState?.[p.visibilitySection]) return null

    return (
        <Holder>
            <MerchantApplicationInternalLabel>
                <TextHolder>
                    <MerchantApplicationInternalLink
                        applicationId={p.applicationId}
                        indexPath={p.indexPath}
                        label={p.label || '-'}
                    />
                </TextHolder>
            </MerchantApplicationInternalLabel>
            <span />
            {p.isLoading ? <TextLineLoader height={18} /> : <MerchantApplicationFile {...newP} acceptMultiple />}
        </Holder>
    )
}

const Holder = styled.div`
    display: contents;
`

const TextHolder = styled.div`
    /* margin-top: 1px;
    margin-bottom: 1px; */
`
