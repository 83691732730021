/* eslint-disable @typescript-eslint/no-use-before-define */
import { ActionType } from 'deox'
import moment from 'moment'
import { put } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'

import { DateFormats } from '../../utils/dateUtils'
import { MerchantTimelineActions } from './actions'
import { convertApplicationVersionsToMerchantApplicationTimelineItems } from './applicationVersionToTimelineItems'
import { MerchantTimeline, MerchantTimelinePoint } from './types'
import { extractVersionsFromCutterRequestRecursively } from './utils'

export const MerchantTimelineSagas = {
    *PROCESS(p: ActionType<typeof MerchantTimelineActions.PROCESS>) {
        // eslint-disable-next-line no-use-before-define
        const versions: { [k: string]: any } = yield extractVersionsFromCutterRequestRecursively(
            p.payload.cutterResponse
        )

        const items = convertApplicationVersionsToMerchantApplicationTimelineItems(versions).sort((a, b) => {
            return moment(a.at).isAfter(moment(b.at)) ? -1 : 1
        })

        const timeline: Omit<MerchantTimeline, 'loadingStatus'> = {
            at: {},
            all: [],
            byKind: {},
            byDay: {}
        }

        const register = (point: MerchantTimelinePoint) => {
            timeline.at[point.id] = { ...point }
            timeline.all = [...timeline.all.filter((pId) => pId !== point.id), point.id]

            point.labels.forEach((label) => {
                if (!timeline.byKind[label]) timeline.byKind[label] = []
                timeline.byKind[label] = [...timeline.byKind[label].filter((pId) => pId !== point.id), point.id]
            })

            const dayStamp = moment(point.at).format(DateFormats.dayStamp)

            if (!timeline.byDay[dayStamp]) timeline.byDay[dayStamp] = []

            timeline.byDay[dayStamp] = [...timeline.byDay[dayStamp].filter((pId) => pId !== point.id), point.id]
        }

        let batchIndex = {
            key: '',
            pointId: ''
        }
        // Timeline Building
        items.forEach((item) => {
            if (item.section === 'application' && item.field === 'createdAt') {
                register({
                    type: 'creation',
                    id: uuid(),
                    labels: ['application', 'state-changes'],
                    edits: [],
                    by: item.by,
                    at: item.at
                })
                return
            }
            if (item.type === 'field-change') {
                const timeBatchKey =
                    Math.floor(moment(item.at).unix() / 300) * 300 + moment(item.at).format(DateFormats.dayStamp)
                const itemBatchKey = `field-change-${item.by}-${timeBatchKey}`
                if (itemBatchKey === batchIndex.key) {
                    timeline.at[batchIndex.pointId].edits.push(item)
                } else {
                    const id = uuid()

                    register({
                        id,
                        type: 'field-edits',
                        labels: ['application', 'edits'],
                        edits: [item],
                        by: item.by,
                        at: item.at
                    })

                    batchIndex = {
                        key: itemBatchKey,
                        pointId: id
                    }
                }
                return
            }
            if (item.type === 'state-change') {
                register({
                    type: 'state-change',
                    id: uuid(),
                    labels: ['application', 'state-changes'],
                    edits: [item],
                    by: item.by,
                    at: item.at
                })
            }
        })

        yield put(MerchantTimelineActions.STORE(timeline, p.payload.merchantId))
        yield put(MerchantTimelineActions.MARK_AS_COMPLETE(p.payload.merchantId))
    }
}
