import { createReducer } from 'deox'
import { produce } from 'immer'
import { ApplicationInternalsActions } from '../actions'
import { ApplicationInternalsNeedingAttentionActions } from './actions'
import { InitialMerchantApplicationInternalNeedingAttentionState } from './types'

export const ApplicationInternalsNeedingAttentionReducer = createReducer(
    InitialMerchantApplicationInternalNeedingAttentionState,
    (handleAction) => [
        handleAction(ApplicationInternalsNeedingAttentionActions.STORE_COMMENTS, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft[p.applicationId] = {
                    processFinished: true,
                    fieldsWithUnreadComments: p.unreadFields.reduce((acc: any, indexPathString: string) => {
                        acc[indexPathString] = true
                        return acc
                    }, {}),
                    unreadComments: p.unreadComments.reduce((acc: any, cId: string) => {
                        acc[cId] = true
                        return acc
                    }, {}),
                    internalNotesNeedingAttention: p.internalNotesNeedsAttention
                }
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = { ...InitialMerchantApplicationInternalNeedingAttentionState }
                return draft
            })
        })
    ]
)
