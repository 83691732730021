import React from 'react'

import { ButtonInset } from '@/components/buttons/buttonInset'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { Flex } from '@/components/layout/flex'
import { useUpdateTask } from '@/services/taskr'

interface ApplicationTaskActionProps {
    url: string
    action: 'open' | 'close'
}

export const ApplicationTaskAction: React.FC<ApplicationTaskActionProps> = (props) => {
    const { url, action } = props

    const [updateTask, updateTaskResult] = useUpdateTask()
    const watcher = updateTaskResult.isLoading ? 'started' : undefined
    const payload = action === 'open' ? 'open_task' : 'close_task'

    const onCloseClick = async () => {
        if (url) {
            await updateTask({ url, data: { action: payload } })
        }
    }

    const color = action === 'close' ? 'front.danger.color' : 'front.accent.color'

    return (
        <Flex justify="flex-end">
            <SimpleButton color={color} onClick={onCloseClick}>
                <ButtonLoadingCover background="front.background" watcher={watcher}>
                    <ButtonInset noVerticalPadding noHorizontalPadding>
                        {action === 'close' ? 'Close' : 'Open'}
                    </ButtonInset>
                </ButtonLoadingCover>
            </SimpleButton>
        </Flex>
    )
}
