import React, { useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useTheme } from '../../hooks/general/useTheme'
import { LoaderView } from '../loaders/loader'
import { MODAL_ID } from '../modals/modalIds'
import { zIndexes } from '../../styles/zIndexes'
import { useGoBackHook } from '../../hooks/general/useGoBackHook'

const ModalPageWithRef: React.ForwardRefRenderFunction<
    any,
    {
        title: string | null
        children?: any
        isLoading?: boolean
        pageId: MODAL_ID
        backTo?: string
        overlay?: boolean
        backToState?: any
        noExitOnBackgroundClick?: boolean
        onBack?: () => void
        topOffset?: number
    }
> = ({ children, topOffset, title, noExitOnBackgroundClick, isLoading, pageId, backTo, onBack, overlay }, ref) => {
    const theme = useTheme()
    useModalStackSync(title, pageId, undefined, false)
    const { goBack } = useGoBackHook(backTo)

    useEffect(() => {
        const html = document.getElementsByTagName('html')[0]
        html.style.backgroundColor = theme['overlay.background']
        return () => {
            html.style.backgroundColor = theme['back.background']
        }
    }, [theme])

    const handleBackButtonClick = useCallback(
        (e) => {
            if (e.target.closest('.PREVENT_MODAL_CLOSE')) return
            goBack()
            onBack?.()
        },
        [onBack, goBack]
    )

    return (
        <Holder
            overlay={overlay}
            onMouseDown={noExitOnBackgroundClick ? undefined : handleBackButtonClick}
            ref={ref}
            id="PAGE"
            topOffset={topOffset}
        >
            <Content>
                {isLoading ? (
                    <LoaderHolder>
                        <LoaderView overBackground="overlay.background" type="l" />
                    </LoaderHolder>
                ) : (
                    children
                )}
            </Content>
        </Holder>
    )
}

export const ModalPage = React.forwardRef(ModalPageWithRef)

const LoaderHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    align-self: stretch;
    position: relative;
`

const Holder = styled.div<{ overlay?: boolean; topOffset?: number }>`
    background-color: ${(p) => p.theme['overlay.background']};
    min-height: ${(p) => (p.topOffset ? `calc(100vh - ${p.topOffset}px)` : `100vh`)};
    display: flex;
    flex-direction: column;
    ${(p) =>
        p.overlay &&
        css`
            position: fixed;
            top: ${p.topOffset || 0}px;
            left: 0;
            z-index: ${zIndexes.modal};
            width: 100%;
            height: ${p.topOffset ? `calc(100vh - ${p.topOffset}px)` : `100vh`};
            overflow: auto;
        `}

    @media print {
        background-color: #fff !important;
    }
`

const Content = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    position: relative;
    flex-direction: column;
    z-index: 1;
`
