import React from 'react'

import { TextInputSelect } from '../../../components/forms/textInputSelect'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { MerchantApplicationFieldValueCompanyForm } from './Application.FieldValueCompanyForm'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import * as yup from 'yup'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { Color } from '../../../styles/colors'

const items = ['', 'Iværksætterselskab', 'Forening']

export const MerchantApplicationEditsModalInputCompanyForm: React.FC<{
    preSelected?: string
    indexPath: MerchantApplicationResourceIndexPath
    onSelect?: (item: string) => void
    overBackground: Color
    validation: ValidationStatus
    formRef: MerchantApplicationFieldFormProps['formRef']
    onBlur: (e: any) => void
}> = ({ preSelected, validation, onBlur, indexPath, formRef, overBackground }) => {
    return (
        <TextInputSelect
            placeholder="-"
            onBlur={onBlur}
            validation={validation}
            textareaBased
            ref={(ref) => {
                formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
            }}
            noClear
            overBackground={overBackground}
            selected={preSelected === 'Other' ? '' : preSelected}
            isSeamless
            dropdownId="GenericDropdown"
            textForItem={MerchantApplicationFieldValueCompanyForm as () => string}
            items={items}
        />
    )
}
