import { ReactElement } from 'react'
import styled, { css } from 'styled-components'

import { Icon } from '../icons/icon'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { Spacer } from '../layout/spacer'
import { FiltersController } from '../../hooks/pages/useListPage'
import { Text } from '../general/text'

export function SelectedListForListPageFilters<T>({
    filters,
    textForItem,
    filterKey,
    isDark,
    prefix,
    noHolderEmphasize,
    secondFilterKey
}: {
    filters: FiltersController<T>
    textForItem: (item?: string) => string | undefined
    filterKey: keyof T
    isDark: boolean
    prefix?: string
    noHolderEmphasize?: boolean
    secondFilterKey?: keyof T
}): ReactElement | null {
    const array =
        typeof filters.get[filterKey] === 'string' ? [filters.get[filterKey]] || [] : filters.get[filterKey] || []

    if (!(array.length > 0)) return null

    return (
        <SelectedFiltersContainer noEmphasize={noHolderEmphasize}>
            {array.map((id: any) => {
                const onClick = (e: any) => {
                    e.stopPropagation()
                    filters.set((prev) => {
                        const newValue = (prev[filterKey] || ([] as any)).filter?.((i: string) => i !== id)

                        if (typeof prev[filterKey] === 'string')
                            return {
                                ...prev,
                                [filterKey]: undefined,
                                ...(secondFilterKey ? { [secondFilterKey]: undefined } : {})
                            }
                        return {
                            ...prev,
                            ...(secondFilterKey ? { [secondFilterKey]: undefined } : {}),
                            [filterKey]: newValue.length ? newValue : undefined
                        }
                    })
                }
                return (
                    <Item data-cy="selected-filter" key={
                        filterKey !== 'applications_gateway' ? 
                            textForItem(id) : id} isDark={isDark}>
                        <ButtonHolder>
                            <SimpleButton
                                tabIndex={0}
                                background="front.accent.color"
                                cy="clear-filter"
                                onClick={onClick}
                                round
                            >
                                <ButtonInset equalPadding padding="small">
                                    <Icon type="x" size={6} weight={1} />
                                </ButtonInset>
                            </SimpleButton>
                        </ButtonHolder>
                        <Spacer width={8} />
                        <Text color={isDark ? 'floating.text' : 'side.text'}>
                            {prefix ? (
                                <Text color={isDark ? 'floating.text.subtlerI' : 'side.text.subtlerI'}>{prefix}</Text>
                            ) : undefined}
                            {filterKey !== 'applications_gateway' ? textForItem(id) : id}
                        </Text>
                    </Item>
                )
            })}
        </SelectedFiltersContainer>
    )
}

export const SelectedFiltersContainer = styled.div<{ noEmphasize?: boolean }>`
    display: column;
    ${(p) =>
        p.noEmphasize
            ? css``
            : css`
                  background-color: ${(p) => p.theme['side.background.subtlerI']};
                  padding: 6px;
                  border-radius: 10px;
                  margin: 5px -6px 5px -6px;
              `}
`

const Item = styled.div<{ isDark?: boolean }>`
    color: ${(p) => (p.isDark ? '#fff' : p.theme['front.text'])};
    margin-top: 2px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        border-bottom: none;
    }
`

const ButtonHolder = styled.div`
    margin-top: 1px;
`
