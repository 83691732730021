import React from 'react'
import { Styles, styles } from './styles'
import { cn } from '../utils'
import { Slot } from '@radix-ui/react-slot'

interface IconProps extends Styles {
    children: React.ReactNode
}

export const Icon: React.FC<IconProps> = (props) => {
    const { children, color, shade, size } = props

    const classnames = cn(styles({ color, shade, size }))

    return <Slot className={classnames}>{children}</Slot>
}
