import React, { useMemo } from 'react'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { LinkButton } from '../../components/buttons/linkButton'
import { ModalPage } from '../../components/layout/modalPage'
import { ModalPageInset } from '../../components/layout/modalPageInset'
import { ModalHeader } from '../../components/modals/modalHeader'
import { useMerchantName } from '../../hooks/general/useMerchantName'
import { Outlet, useLocation, useParams } from 'react-router-dom'

type Params = { id: string; subpage: string }

export const MerchantTagsManagerRouter: React.FC = () => {
    const params = useParams() as Params
    const { state, pathname } = useLocation()
    const { id } = params || {}

    const rightSide = useMemo(() => {
        return (
            <div className="PREVENT_MODAL_CLOSE">
                <LinkButton background="front.background" to={{ pathname: `${pathname}/new` }} state={state}>
                    <ButtonInset>Create a new tag</ButtonInset>
                </LinkButton>
            </div>
        )
    }, [pathname, state])

    const name = useMerchantName(id, 'Tags management')

    return (
        <ModalPage
            title={name}
            pageId="Merchant.ApplicationPage.TagsManager"
            backTo={`/merchant/${id}`}
            key="applicationTagsManager"
        >
            <ModalHeader
                pageId="Merchant.ApplicationPage.TagsManager"
                backTo={`/merchant/${id}`}
                title="Tags manager"
                rightSideMemo={rightSide}
            />
            <ModalPageInset center>
                <Outlet />
            </ModalPageInset>
        </ModalPage>
    )
}
