import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('underline hover:no-underline cursor-pointer', {
    variants: {
        weight: {
            normal: 'font-normal',
            medium: 'font-medium',
            bold: 'font-bold'
        },
        color: {
            primary: 'text-primary-500',
            neutral: 'text-neutral-500',
            success: 'text-success-500',
            warning: 'text-warning-500',
            notice: 'text-notice-500',
            danger: 'text-danger-500',
            white: 'text-white',
            black: 'text-black'
        }
    }
})
