import React, { ComponentProps, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { WatcherState } from '../../store/watcher/types'
import { MODAL_ID } from '../modals/modalIds'
import { ClueDirection } from '../hotkeys/hotkeyClue'
import { SimpleButton } from './simpleButton'
import { generateButtonStyles } from './../../utils/buttonStyles'
import { SmartNavLink } from '../tables/smartNavLink'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { dictionaryToSearchString, URLSearchParamsToDictionary, urlWithQueryString } from '../../utils/general'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export type ExternalLinkTargets =
    | 'none'
    | 'dashboard'
    | 'tasks'
    | 'apply'
    | 'blank'
    | 'managerTransactions'
    | 'managerApplications'
    | 'managerSettlements'
    | 'managerDisputes'
    | 'managerTransaction'
    | 'oldManagerApplication'

export function LinkButton({
    children,
    background,
    color,
    hotkeysScope,
    hotkeys,
    clueDirection,
    keepQuery,
    passBackToState,
    to: passedTo,
    target,
    noShrink,
    className,
    grow,
    cy,
    inline
}: {
    children: any
    background?: React.ComponentProps<typeof SimpleButton>['background']
    color?: React.ComponentProps<typeof SimpleButton>['color'] | 'currentColor'
    hotkeysScope?: MODAL_ID
    hotkeys?: string
    clueDirection?: ClueDirection
    watcher?: WatcherState
    grow?: boolean
    noShrink?: boolean
    tooltip?: string
    keepQuery?: boolean
    className?: string
    passBackToState?: boolean
    target?: ExternalLinkTargets
    to: ComponentProps<typeof Link>['to']
    state?: ComponentProps<typeof Link>['state']
    cy?: string
    inline?: boolean
}): JSX.Element {
    const navigate = useNavigate()

    const [isExternal, memoedTarget] = useMemo(() => {
        switch (target) {
            case 'dashboard':
                return [true, 'clrhsDashboard']
            case 'tasks':
                return [false, 'clrhsTasks']
            case 'apply':
                return [true, 'clrhsApplication']
            case 'managerSettlements':
                return [false, 'consoleSecondWindow']
            case 'managerTransactions':
                return [false, 'consoleSecondWindow']
            case 'managerApplications':
                return [false, 'consoleSecondWindow']
            case 'managerDisputes':
                return [false, 'consoleSecondWindow']
            case 'managerTransaction':
                return [false, 'consoleThirdWindow']
            case 'oldManagerApplication':
                return [false, 'clrhsOldManagerApplication']
            case 'blank':
                return [true, '_blank']
            default:
                return [false, undefined]
        }
    }, [target])

    // this is used in order to open the tab in the same 'new-window'
    const linkRel = useMemo(() => {
        if (typeof passedTo === 'string') {
            // if it's a relative link
            if (!passedTo.includes('.')) return undefined
            // if it links to the same system
            if (
                `${import.meta.env.VITE_AUTH0_REDIRECT}/` &&
                passedTo?.indexOf(`${import.meta.env.VITE_AUTH0_REDIRECT}/`) === 0
            ) {
                return undefined
            }
            return 'noreferrer'
        }
    }, [passedTo])

    const location = useLocation()

    const to = useMemo(() => {
        const searchParams = URLSearchParamsToDictionary(new URLSearchParams(location.search.replace('?', '')))
        const searchParamsString = dictionaryToSearchString(searchParams)

        if (typeof passedTo === 'string') {
            return `${passedTo}${keepQuery ? searchParamsString : ''}`
        } else {
            return {
                ...passedTo,
                search: passedTo.search
                    ? passedTo.search
                    : dictionaryToSearchString({
                          ...(keepQuery ? searchParams : {})
                      })
            }
        }
    }, [passedTo, keepQuery, location])

    const state = useMemo(() => {
        const searchParams = URLSearchParamsToDictionary(new URLSearchParams(location.search.replace('?', '')))

        if (passBackToState) {
            return {
                backTo: urlWithQueryString(location.pathname, searchParams)
            }
        } else {
            return location.state
        }
    }, [passBackToState, location])

    const clue = useHotkey({
        scope: hotkeysScope,
        keys: hotkeys,
        clue: clueDirection,
        action: useCallback(() => {
            navigate(to)
        }, [navigate, to])
    })

    if (isExternal) {
        let url: string | ComponentProps<typeof Link>['to'] = to
        if (typeof to !== 'string') {
            if (!to.pathname) throw 'Failed to redirect'
            url = to.pathname
        }
        return (
            <AnchorLink
                data-cy={cy}
                rel={linkRel}
                target={memoedTarget}
                href={url}
                grow={grow}
                className={className}
                background={background}
                color={color}
                inline={inline}
            >
                {children}
                {clue}
            </AnchorLink>
        )
    }

    return (
        <RouterLink
            data-cy={cy}
            exact
            to={to}
            state={state}
            grow={grow}
            target={memoedTarget}
            noShrink={noShrink}
            background={background}
            color={color}
            className={className}
            $inline={inline}
        >
            {children}
            {clue}
        </RouterLink>
    )
}

const AnchorLink = styled.a<{
    styles: any
    grow?: boolean
    background?: React.ComponentProps<typeof SimpleButton>['background']
    color?: React.ComponentProps<typeof SimpleButton>['color']
    noShrink?: boolean
    $inline?: boolean
}>`
    ${(p) => generateButtonStyles(p.background, p.color)}
    ${(p) => p.styles}
    ${(p) =>
        p.grow &&
        css`
            flex-grow: 1;
            flex-basis: 0;
        `}

    ${(p) =>
        p.noShrink &&
        css`
            flex-shrink: 0;
        `}
    text-decoration: none;
    position: relative;
    ${(p) =>
        p.$inline &&
        css`
            display: inline;
        `}
` as any

const RouterLink = styled(({ noShrink, ...p }) => <SmartNavLink to={p.to} {...p} />)<
    ComponentProps<typeof Link> & {
        styles?: any
        grow?: boolean
        background?: React.ComponentProps<typeof SimpleButton>['background']
        color?: React.ComponentProps<typeof SimpleButton>['color'] | 'currentColor'
        noShrink?: boolean
        inline?: boolean
    }
>`
    ${(p) => generateButtonStyles(p.background, p.color)}
    ${(p) => p.styles}
    ${(p) =>
        p.grow &&
        css`
            flex-grow: 1;
            flex-basis: 0;
        `}
    
    ${(p) =>
        p.noShrink &&
        css`
            flex-shrink: 0;
        `}
        
    text-decoration: none;
    position: relative;
    ${(p) =>
        p.inline &&
        css`
            display: inline;
        `}
    word-break: break-word;
`
