import { AccountDetails, Date, ItemCheckBox } from '../../common'
import { Account, Posting } from '@/services/reconciliation'
import { TableColHeader, TableRow } from '@/components/tables/table'
import { TableBubble } from '@/components/layout/tableBubble'
import { LinkButton } from '@/components/buttons/linkButton'
import { numberWithCurrency } from '@/utils'
import { getAccount } from '../../../helpers'
import { TruncatedText } from '@/ui/truncated-text'

export const getPostingCols = () => {
    return [
        { text: 'Account', loaderSize: { min: 120, max: 160 } },
        { text: 'Posting date', loaderSize: { min: 80, max: 80 } },
        { text: 'Serial', loaderSize: { min: 30, max: 40 } },
        { text: 'Description', loaderSize: { min: 0, max: 80 } },
        { text: 'Reference', loaderSize: { min: 0, max: 80 } },
        { text: 'Counterparty name', loaderSize: { min: 0, max: 60 } },
        { text: 'Counterparty account', loaderSize: { min: 0, max: 60 } },
        { text: 'Posting type', loaderSize: { min: 60, max: 60 } },
        { text: 'Amount', loaderSize: { min: 60, max: 80 }, alignRight: true }
    ]
}

export const appendActions = (cols: TableColHeader[], rows: TableRow[]) => {
    if (!cols.some((col) => col.text === 'Actions')) {
        cols.push({ text: 'Actions', loaderSize: { min: 60, max: 60 }, alignRight: true })
        rows.map((row) => {
            row.items.push({
                node: <LinkButton to={`/more/reconciliation-postings/${row.key}/reconcile`}>Reconcile</LinkButton>
            })
        })
    }

    return { cols, rows }
}

export const prependChecks = (cols: TableColHeader[], rows: TableRow[]) => {
    const newCols = cols.slice()
    if (!cols.some((col) => col.text === 'Checks')) {
        newCols.unshift({ text: 'Checks' })
        rows.map((row) => {
            const newRowItems = row.items.slice()
            newRowItems.unshift({
                node: <ItemCheckBox name="posting_ids" value={row.key} />
            })
            row.items = newRowItems
        })
    }

    return { cols: newCols, rows }
}

const findReference = (jsonObj: any, key: string) => {
    const foundObj = jsonObj[key]
    if (foundObj) return foundObj
    for (const pair in jsonObj) {
        const nestedObj = findReference(pair.valueOf(), key)
        if (nestedObj) return nestedObj
    }
    return
}

export const getPostingRows = (postings?: Posting[], accounts?: Account[]) => {
    if (!postings) return []

    const rows: TableRow[] = postings.map((posting) => {
        return {
            type: 'normal' as const,
            key: posting.id,
            noAction: true,
            items: [
                {
                    node: accounts && <AccountDetails account={getAccount(posting.account_id, accounts)} />
                },
                {
                    node: <Date>{posting.posting_date}</Date>
                },
                {
                    node: <TruncatedText>{posting.serial}</TruncatedText>
                },
                {
                    node: <TruncatedText>{posting.description}</TruncatedText>
                },
                {
                    node: <TruncatedText>{posting.reference}</TruncatedText>
                },
                {
                    node: <TruncatedText>{posting.counterparty_name}</TruncatedText>
                },
                {
                    node: <TruncatedText>{posting.counterparty_account}</TruncatedText>
                },
                {
                    node: <TruncatedText>{posting.posting_type}</TruncatedText>
                },
                {
                    node: (
                        <TableBubble type="important">
                            {numberWithCurrency(posting.currency, `${posting.amount}`)} {posting.currency}
                        </TableBubble>
                    )
                }
            ]
        }
    })

    return rows
}
