import { useMemo } from 'react'
import { Person, PersonVerificationFiles } from '../../../store/applications/types'
import { uppercaseFirstLetter } from '../../../utils'

export const usePersonInfoChecks = (info?: Person, files?: PersonVerificationFiles) => {
    return useMemo(() => {
        if (!info || !files) {
            return { allInfoPresent: false, missingInfo: [], requiredInfo: [] }
        }

        const requiredInfo = {
            person: {
                data: info,
                labels: ['name', 'socialSecurityNumber', 'addressLine1', 'zipcode', 'country']
                //, 'email', 'phone'
            },
            personsFiles: {
                data: files,
                labels: ['address_legitimation', 'picture_legitimation']
            }
        }

        const entityKeys: keyof typeof requiredInfo = Object.keys(requiredInfo) as any
        let allInfoPresent = true
        const requiredInfoStrings: string[] = []
        const missingInfo: string[] = []
        for (const entityKey of entityKeys) {
            for (const fieldKey of requiredInfo[entityKey].labels as string[]) {
                requiredInfoStrings.push(uppercaseFirstLetter(fieldKey))
                if (!requiredInfo[entityKey].data[fieldKey]) {
                    allInfoPresent = false
                    missingInfo.push(uppercaseFirstLetter(fieldKey))
                }
            }
        }

        return { allInfoPresent, missingInfo, requiredInfo: requiredInfoStrings }
    }, [info, files])
}
