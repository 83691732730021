import { VariantProps, cva } from 'class-variance-authority'

export type RootStyles = VariantProps<typeof styles.root>
export type IndicatorStyles = VariantProps<typeof styles.indicator>

export const styles = {
    root: cva([
        'peer',
        'h-5',
        'w-5',
        'shrink-0',
        'rounded-md',
        'border',
        'border-neutral-500',
        'focus-visible:outline-none',
        'focus-visible:ring-2',
        'focus-visible:ring-ring',
        'focus-visible:ring-offset-2',
        'disabled:cursor-not-allowed',
        'disabled:opacity-50',
        'data-[state=checked]:bg-primary',
        'data-[state=checked]:text-white',
        'data-[state=checked]:border-primary'
    ]),
    indicator: cva('flex items-center justify-center')
}
