import { CompanyName, Date as DateComponent, FilteredTags, TagsLister } from '../../common'
import { Tasks } from '@/services/taskr'

const getRemainingTags = (tags: string[]) => {
    return tags.filter((x) => !x.includes('AML') && !x.includes('Volume'))
}

export const getTasksRows = (data?: Tasks) => {
    if (!data || !data.embedded.tasks) {
        return []
    }

    const sortedTasks = [...data.embedded.tasks].sort((a, b) => {
        if (!a.due_at || !b.due_at) return 0

        if (a.due_at < b.due_at) {
            return -1
        } else if (a.due_at > b.due_at) {
            return 1
        } else {
            return 0
        }
    })

    const rows = sortedTasks.map((task) => {
        const { id: key, subject_id: applicationId, due_at, tags } = task

        const remainingTags = getRemainingTags(tags)

        return {
            type: 'normal' as const,
            key,
            link: `/merchant/${applicationId}/more/ongoing-due-diligence`,
            items: [
                {
                    node: <CompanyName applicationId={applicationId} />
                },
                {
                    node: <DateComponent>{due_at}</DateComponent>
                },
                {
                    node: <FilteredTags tags={tags} tagIncludes="Volume" />
                },
                {
                    node: <FilteredTags tags={tags} tagIncludes="AML" />
                },
                {
                    node: <TagsLister tags={remainingTags} />
                }
            ]
        }
    })

    return rows
}
