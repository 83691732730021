import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { CenteredScreen } from '../../../components/layout/centeredScreen'
import { Spacer } from '../../../components/layout/spacer'

import { useModalStackSync } from '../../../hooks/general/useModalStackSync'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { RootState } from '@/store'
import { MerchantAccountContractsPage } from './Accounts.ID.ContractPage'
import { MerchantAccountsSidebar } from './AccountsSidebar'
import { useMerchantAccountContractRedirecter } from './useMerchantAccountContractRedirecter'
import { MerchantPageUISelectedContract } from './utils'
import { useParams } from 'react-router-dom'

type Params = {
    id: string
    accountId: string
    contractId: string
}

export const MerchantAccountContracts: React.FC = () => {
    const dispatch = useDispatch()

    const params = useParams() as Params
    const { id, accountId, contractId } = params

    const account = useSelector((state: RootState) => state.merchantAccounts.at?.[accountId])
    const name = useMemo(() => {
        if (!account?.merchantId) return `Loading…`
        if (!account.contractHistory) return `Loading…`
        const suffix = `(${account.name})`
        if (contractId === 'draft') return `${account?.merchantId} • Draft contract ${suffix}`
        const contracts = Object.keys(account.contractHistory)
        return `${account?.merchantId} • Contract V${
            contracts.length - contracts.findIndex((id) => contractId == id)
        } ${suffix}`
    }, [account, contractId])
    useModalStackSync(name, 'Merchant.AccountsPage.Contract', 0)
    const draftContract = useSelector((state: RootState) => state.merchantAccounts.at?.[accountId]?.draft)
    useMerchantAccountContractRedirecter(account, accountId, contractId, id)

    const draft = useMemo(() => {
        return draftContract
    }, [draftContract])

    useEffect(() => {
        if (account?.loadingStatus !== 'started' && account?.loadingStatus !== 'done')
            dispatch(MerchantAccountsActions.FETCH_ACCOUNT(accountId))
    }, [accountId, account, dispatch, id])

    useEffect(() => {
        if (
            draft?.loadingStatus !== 'started' &&
            draft?.loadingStatus !== 'done' &&
            account?.draftContractLink &&
            accountId
        ) {
            dispatch(MerchantAccountsActions.REFETCH_UPDATED_DRAFT_CONTRACT(accountId, account.draftContractLink))
        }
    }, [draft, dispatch, accountId, account])

    useEffect(() => {
        if (!accountId) return
        if (!account || !account?.contract?.selfLink) {
            if (account?.contractHistoryLoadingStatus !== 'done')
                dispatch(MerchantAccountsActions.STORE_CONTRACT_HISTORY(accountId, []))
        } else if (
            account?.contractHistoryLoadingStatus !== 'started' &&
            account?.contractHistoryLoadingStatus !== 'done' &&
            account?.contract?.selfLink
        )
            dispatch(MerchantAccountsActions.FETCH_CONTRACT_HISTORY(accountId, account.contract.selfLink))
    }, [accountId, account, dispatch])

    const activeContract: MerchantPageUISelectedContract | undefined = useMemo(() => {
        if (contractId === 'draft')
            return {
                contract: draft,
                isDraft: true
            }

        if (!account?.contractHistory) return undefined

        const matchingContract = account.contractHistory?.[contractId]
        const currentContract: any = matchingContract || account.contractHistory[0]

        if (currentContract)
            return {
                contract: currentContract,
                isDraft: false
            }

        return undefined
    }, [account, contractId, draft])

    return (
        <Container>
            <MerchantAccountsSidebar id={id} accountId={accountId} />
            <CenteredScreen>
                <MerchantAccountContractsPage
                    key={activeContract?.contract?.id}
                    account={account}
                    draft={draft}
                    selectedContract={activeContract}
                    applicationId={id}
                />
                <Spacer height={100} />
            </CenteredScreen>
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    position: relative;
`
