import {
    AvailableLineOptions,
    LineOption,
    NotificationStatus,
    AvailableNotificaitonOptions
} from '../../components/listPages/sidebarNavigation/sidebarCallSettingsPanel'

export interface CallsPrefState {
    nickname: string
    line: LineOption
    local_number: string
    notifications: NotificationStatus
    isCalling: string
}

export const InitialCallsPrefState: CallsPrefState = {
    nickname: (localStorage.getItem('nickname') as string || ''),
    line: AvailableLineOptions.includes((localStorage.getItem('line') as any) || '')
        ? (localStorage.getItem('line') as LineOption)
        : '1',
    local_number: (localStorage.getItem('localNumber') as string) || '',
    notifications: AvailableNotificaitonOptions.includes((localStorage.getItem('notifications') as any) || '')
        ? (localStorage.getItem('notifications') as NotificationStatus)
        : 'off',
    isCalling: (localStorage.getItem('isCalling') as string) || 'false'
}
