import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { ApplicationFilterToTitle } from '../../../store/applications/types'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../../utils'

export const SummaryApplicationState: React.FC<{ applicationId: string }> = ({ applicationId }) => {
    const state = useSelector((state: RootState) => state.applications.applications.at[applicationId]?.metadata?.state)
    const application = useSelector((state: RootState) => state.applications.applications.at[applicationId])
    const hasApplicationLoaded = application?.loadingStatus === 'done'

    return (
        <Flex column align="stretch" grow style={{ width: '100%' }}>
            {hasApplicationLoaded ? (
                <>
                    <Text>Current state:</Text>
                    <StateText>
                        {state === 'accepted' ? (
                            <AcceptedIcon>
                                <Icon type="checkmark" size={20} weight={1.5} />
                            </AcceptedIcon>
                        ) : null}
                        {(ApplicationFilterToTitle as any)[state] || uppercaseFirstLetter(state)}
                    </StateText>
                    {state == 'declined' && (
                        <>
                            <DeclinedReason>
                                <Text bold>Reason:</Text>
                                <Text>{application?.metadata?.declinedReason}</Text>
                            </DeclinedReason>
                        </>
                    )}
                </>
            ) : (
                <>
                    <TextLineLoader maxWidth={100} topOffset={3} />
                    <TextLineLoader textHeight={18} height={28} topOffset={5} />
                </>
            )}
        </Flex>
    )
}

const StateText = styled.div`
    font-size: 17px;
    font-weight: 500;
    display: flex;
    letter-spacing: -0.2px;
    margin-top: 3px;
`

const AcceptedIcon = styled.div`
    margin-right: 2px;
    position: relative;
    top: 0px;
    left: -2px;
`

const DeclinedReason = styled.div`
    padding: 7px 0;
    padding-top: 10px;
    margin-top: 10px;
    display: flex;
    align-items: stretch;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    flex-direction: column;
`
