import React from 'react'
import { Table, TableColHeader, TableRow } from '@/components/tables/table'
import { CardInset } from '@/components/cards/cardInset'
import { Card } from '@/components/cards/card'
import { Spacer } from '@/components/layout/spacer'

interface PostingsTableProps {
    isLoading: boolean
    rows: TableRow[]
    cols: TableColHeader[]
}

export const PostingsTable: React.FC<PostingsTableProps> = (props) => {
    const { isLoading, rows, cols } = props

    return (
        <>
            <Card>
                <CardInset>
                    <Table
                        displayLoader={isLoading}
                        loaderRows={20}
                        rows={rows}
                        cols={cols}
                        background="front.background"
                        columnLayout="min-content min-content minmax(0, 1fr) minmax(0, 2fr) minmax(0, 2fr)
                            minmax(0, 2fr) minmax(0, 2fr) min-content min-content min-content"
                        emptyText="No unreconciled postings found."
                    />
                </CardInset>
            </Card>
            <Spacer width={20} />
        </>
    )
}
