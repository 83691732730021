import styled from 'styled-components'
import { Separator } from '../../../components/layout/separator'

export const MerchantApplicationSeparatorRow = () => {
    return (
        <Holder>
            <Separator />
        </Holder>
    )
}

const Holder = styled.div`
    grid-column: span 3;
    padding: 13px 0;
`
