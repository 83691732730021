interface Balance {
    type: string
    amount: number
}

export class ReconciliationBalance {
    private balance: Balance
    private reconciliations: Balance[]

    constructor(balance: Balance, reconciliations: Balance[]) {
        this.balance = balance
        this.reconciliations = reconciliations
    }

    public getAmount(balance: Balance): number {
        const { amount, type } = balance

        const amt = type === 'credit' ? amount : -amount

        return amt
    }

    public getReconcileAmount(items: Balance[]): number {
        const debits = items.filter((item) => item.type === 'debit')
        const credits = items.filter((item) => item.type === 'credit')

        const debitAmount = debits.reduce((acc, item) => acc + item.amount, 0)
        const creditAmount = credits.reduce((acc, item) => acc + item.amount, 0)

        return creditAmount - debitAmount
    }

    public calculateBalance(): number {
        const amount = this.getAmount(this.balance)
        const reconcileAmount = this.getReconcileAmount(this.reconciliations)

        return amount - reconcileAmount
    }
}
