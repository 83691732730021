import { Moment } from 'moment'

import { Task } from '../../store/tasks/types'
import { DateFormats } from '../../utils/dateUtils'
import { Flex } from '../layout/flex'
import { Text } from '../general/text'
import { TimelineCard } from '../listPages/timelineCard'
import { CurrencyContextCard } from './contextCards/currencyContextCard'
import { DistributionContextCard } from './contextCards/distributionContextCard'
import { TransactionsContextCard } from './contextCards/transactionsContextCard'
import { VolumeContextCard } from './contextCards/volumeContextCard'

export const TaskTimelineEvent: React.FC<{
    last?: boolean
    e: any
    task?: Task
    date: Moment
}> = (p) => {
    return (
        <TimelineCard
            title={<Text bold>{p.e.name}</Text>}
            background="front.subtleAccent.background"
            rightSideMemo={
                <Flex grow justify="flex-end">
                    <Text color="front.text">{p.date ? p.date.format(DateFormats.dayAndTime(p.date)) : null}</Text>
                </Flex>
            }
        >
            {(() => {
                if (!p.e.data) return null
                if (p.e.data.valueType == 'transactions') {
                    return <TransactionsContextCard data={p.e} />
                }
                if (p.e.data.valueType == 'account-currency') return <CurrencyContextCard data={p.e} task={p.task!} />
                if (p.e.data.valueType == 'distribution') {
                    return <DistributionContextCard data={p.e} task={p.task!} />
                }
                if (p.e.data.valueType == 'monthly-transaction-volume')
                    return <VolumeContextCard data={p.e} task={p.task!} />
                return <Text size="s">{JSON.stringify(p.e.data.value)}</Text>
            })()}
        </TimelineCard>
    )
}
