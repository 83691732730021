import { ReactNode } from 'react'
import { useMedia } from 'react-use'
import styled, { css } from 'styled-components'

type ColumnProp = React.CSSProperties['gridTemplateColumns']
type RowsProp = React.CSSProperties['gridTemplateRows']
type GridRowGap = 'tiny' | 'small' | 'medium' | 'large'
type GridColumnGap = 'tiny' | 'small' | 'medium' | 'large' | 'extra-large'

export const Grid: React.FC<{
    children: ReactNode
    horizontalTemplate: ColumnProp
    verticalTemplate?: RowsProp
    gridRowGap?: GridRowGap
    gridColumnGap?: GridColumnGap
    switchToRowsAt?: number
}> = ({
    children,
    horizontalTemplate,
    verticalTemplate,
    switchToRowsAt,
    gridColumnGap = 'medium',
    gridRowGap = 'medium'
}) => {
    const shouldBeVertical = useMedia(switchToRowsAt ? `(max-width: ${switchToRowsAt}px)` : '')
    return (
        <Element
            shouldBeVertical={shouldBeVertical}
            gridRowGap={gridRowGap}
            gridColumnGap={gridColumnGap}
            horizontalTemplate={horizontalTemplate}
            verticalTemplate={verticalTemplate}
        >
            {children}
        </Element>
    )
}

const Element = styled.div<{
    horizontalTemplate: ColumnProp
    verticalTemplate?: RowsProp
    shouldBeVertical?: boolean
    gridRowGap: GridRowGap
    gridColumnGap: GridColumnGap
}>`
    display: grid;
    grid-template-columns: ${(p) => p.horizontalTemplate};
    grid-column-gap: ${(p) => {
        switch (p.gridColumnGap) {
            case 'tiny':
                return '7px'
            case 'small':
                return '10px'
            case 'medium':
                return '30px'
            case 'large':
                return '40px'
            case 'extra-large':
                return '60px'
        }
    }};
    grid-row-gap: ${(p) => {
        switch (p.gridRowGap) {
            case 'tiny':
                return '7px'
            case 'small':
                return '10px'
            case 'medium':
                return '20px'
            case 'large':
                return '40px'
        }
    }};

    ${(p) =>
        p.shouldBeVertical &&
        p.verticalTemplate &&
        css`
            grid-template-columns: 100%;
            grid-template-rows: ${p.verticalTemplate};
        `}
`
