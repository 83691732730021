import styled, { css } from 'styled-components'

export const ColorSpot = styled.div<{ hash: string }>`
    ${(p) =>
        css`
            background-color: ${p.hash};
        `};
    width: 6px;
    height: 6px;
    border-radius: 999px;
    margin-right: 8px;
    margin-top: -1px;
`
