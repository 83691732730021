import { ApplicationTasksTable, ChartsTable, ReportsTable } from '@/features/ongoing-due-diligence'
import React from 'react'
import { Spacer } from '@/components/layout/spacer'
import { useParams } from 'react-router-dom'

export const Reports: React.FC = () => {
    const { id: applicationId } = useParams()

    if (!applicationId) return null

    return (
        <>
            <ReportsTable applicationId={applicationId} />
            <Spacer height={20} />
            <ChartsTable applicationId={applicationId} />
            <Spacer height={20} />
            <ApplicationTasksTable applicationId={applicationId} />
            <Spacer height={64} />
        </>
    )
}
