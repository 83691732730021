import { Url } from '@/services/common'
import { useGetFiles } from '@/services/merchant-api'
import React from 'react'
import { ManagementLinks } from './management-links'

interface ManagementFilesProps {
    filesUrl: Url
}

export const ManagementFiles: React.FC<ManagementFilesProps> = (props) => {
    const { filesUrl } = props

    const { isLoading, data, isError } = useGetFiles(filesUrl)

    if (isLoading || !data || isError) {
        return null
    }

    return <ManagementLinks files={data} />
}
