import { createReducer } from 'deox'
import { produce } from 'immer'
import { ApplicationLoadingActions } from './actions'
import { InitialApplicationLoadingState } from './types'

export const ApplicationLoadingReducer = createReducer(InitialApplicationLoadingState, (handleAction) => [
    handleAction(ApplicationLoadingActions.SIDEBAR_LOADED, (state, { payload: p }) =>
        produce(state, (draft) => {
            draft.at[p.applicationId] = {
                ...state.at[p.applicationId],
                hasSidebarLoaded: true
            }
        })
    ),
    handleAction(ApplicationLoadingActions.SECTION_LOADED, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (!draft.at[p.applicationId]) draft.at[p.applicationId] = {} as any

            if (!draft.at[p.applicationId].sections) draft.at[p.applicationId].sections = {}

            draft.at[p.applicationId].sections[p.section] = {
                [p.type]: true
            }
        })
    ),
    handleAction(ApplicationLoadingActions.INTERNALS_LOADED, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft.at[p.applicationId] = {
                ...state.at[p.applicationId],
                haveInternalsLoaded: true
            }
        })
    }),
    handleAction(ApplicationLoadingActions.RELATED_APPLICATIONS_LOADED, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft.at[p.applicationId] = {
                ...state.at[p.applicationId],
                relatedApplicationsLoaded: true
            }
        })
    }),
    handleAction(ApplicationLoadingActions.CLEAR, (state, { payload: p }) => {
        return produce(state, (draft) => {
            delete draft.at[p.applicationId]
        })
    }),
    handleAction(ApplicationLoadingActions.STORE_TASKS_COUNT, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft.at[p.applicationId] = {
                ...state.at[p.applicationId],
                tasksCount: p.count
            }
        })
    }),
    handleAction(ApplicationLoadingActions.STORE_MOST_COMMENTED_FIELD, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft.at[p.applicationId] = {
                ...state.at[p.applicationId],
                fieldWithMostComments: {
                    fieldKey: p.fieldKey,
                    subsectionId: p.subsectionId
                }
            }
        })
    })
])
