import React from 'react'
import { useApplicationValidation } from '../../../hooks/application/useApplicationValidation'
import { ApplicationValidationSectionCompany } from './Application.ValidationSection.Company'
import { ApplicationValidationSectionBusinessModel } from './Application.ValidationSection.BusinessModel'
import { ApplicationValidationSectionBankAccount } from './Application.ValidationSection.BankAccount'
import { ApplicationValidationSectionContact } from './Application.ValidationSection.Contact'
import { ApplicationValidationSectionPeople } from './Application.ValidationSection.People'
import { ApplicationValidationSectionSigner } from '@/pages/Merchant/Application/Application.ValidationSection.Signer'
// eslint-disable-next-line max-len
import { ApplicationValidationSectionWebsites } from '@/pages/Merchant/Application/Application.ValidationSection.Websites'
import { Box, Card, Collapsible, Flex, Text, Separator, InfoCircledIcon } from '@/design-system'
import { useTheme } from 'styled-components'

type ApplicationValidationSectionProps = {
    applicationId: string
}

export const ApplicationValidationSection: React.FC<ApplicationValidationSectionProps> = ({ applicationId }) => {
    const [validationResults, fullApplicationData] = useApplicationValidation(applicationId)

    const theme = useTheme()

    const textColor = theme.name === 'darkTheme' ? 'white' : 'danger'
    const textShade = theme.name === 'darkTheme' ? undefined : '700'
    const separatorShade = theme.name === 'darkTheme' ? '100' : '700'
    const chevronColor = theme.name === 'darkTheme' ? 'white' : 'danger'

    if (validationResults === undefined || validationResults === true || fullApplicationData === null) {
        return null
    }

    return (
        <Collapsible>
            <Card color="danger">
                <Card.Body>
                    <Flex>
                        <Collapsible.Trigger testid="application-validation-trigger">
                            <Flex align="center" justify="between">
                                <Text color={textColor} shade={textShade} weight="medium" lineHeight="none">
                                    Application has missing data
                                </Text>
                                <Collapsible.Chevron color={chevronColor} />
                            </Flex>
                        </Collapsible.Trigger>
                    </Flex>
                    <Collapsible.Content>
                        <Box my="3">
                            <Separator color="danger" shade={separatorShade} opacity="5" />
                        </Box>
                        <Text color={textColor} shade={textShade} lineHeight="loose">
                            Please review the following sections:
                        </Text>
                        <ApplicationValidationSectionCompany validationResults={validationResults} />
                        <ApplicationValidationSectionContact validationResults={validationResults} />
                        <ApplicationValidationSectionSigner validationResults={validationResults} />
                        <ApplicationValidationSectionPeople validationResults={validationResults} />
                        <ApplicationValidationSectionBusinessModel validationResults={validationResults} />
                        <ApplicationValidationSectionWebsites validationResults={validationResults} />
                        <ApplicationValidationSectionBankAccount validationResults={validationResults} />
                    </Collapsible.Content>
                </Card.Body>
            </Card>
        </Collapsible>
    )
}
