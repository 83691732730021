import React from 'react'
import { Flex } from '../../../../../../../components/layout/flex'
import { Separator } from '../../../../../../../components/layout/separator'
import { Spacer } from '../../../../../../../components/layout/spacer'
import { MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { uppercaseFirstLetter } from '../../../../../../../utils'
import { ExistingUBOsList } from './ExistingUBOsList/ExistingUBOsList'
import { MonitoringTemplate } from '../../MonitoringTemplate'
import { Text } from '../../../../../../../components/general/text'

interface ChangeProps {
    after: { [key: string]: string | number } | null
    applicationData?: MonitoredFetchedApplicationData
}

export const Change: React.FC<ChangeProps> = (props) => {
    const { after, applicationData } = props

    return (
        <>
            {after ? (
                <Flex>
                    {Object.keys(after).map((item) => {
                        return (
                            <React.Fragment key={item}>
                                <MonitoringTemplate.Info label={uppercaseFirstLetter(item)}>
                                    {after[item]}
                                </MonitoringTemplate.Info>
                                <Spacer width={48} height={1} />
                            </React.Fragment>
                        )
                    })}
                </Flex>
            ) : (
                <Text oneLine color="front.text.subtlerI">
                    No info available
                </Text>
            )}

            <Flex column align="stretch">
                <Spacer height={16} width={1} />
                <Separator type="solid" background="side.background" />
                <Spacer height={16} width={1} />
                <Flex column>
                    <Text oneLine color="front.text.subtlerI">
                        Existing UBO's
                    </Text>
                    <Spacer height={16} width={1} />
                    <ExistingUBOsList applicationData={applicationData} />
                </Flex>
            </Flex>
        </>
    )
}
