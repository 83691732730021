import { ChevronRightIcon, Flex, Text } from '@/design-system'
import { TruncatedText } from '@/ui/truncated-text'
import React from 'react'

interface IntegrationRowDetailsProps {
    technicalPartnerName: string
    supportPartnerName: string
}

const IntegrationRowDetails: React.FC<IntegrationRowDetailsProps> = (props) => {
    const { technicalPartnerName, supportPartnerName } = props

    return (
        <Flex gap="3">
            <Flex gap="1">
                <TruncatedText weight="medium">{technicalPartnerName}</TruncatedText>
                <Text color="neutral">(Technical)</Text>
            </Flex>
            <Flex align="center">
                <ChevronRightIcon color="neutral" size="3.5" />
            </Flex>
            <Flex gap="1">
                <TruncatedText weight="medium">{supportPartnerName}</TruncatedText>
                <Text color="neutral">(Support)</Text>
            </Flex>
        </Flex>
    )
}

export { IntegrationRowDetails }
