import React, { ReactElement, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { Icon } from '../icons/icon'
import { LoadingStatus, Pagination } from '../../utils'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { FiltersController } from '../../hooks/pages/useListPage'
import { MODAL_ID } from '../modals/modalIds'

export type FiltersInfo = 'disputes' | 'tasks' | 'transactions' | 'settlements' | 'applications'

const MAX = 9999
export function FiltersResultsInfo<T>(p: {
    resultsCount: number
    filters: FiltersController<T>
    loadingStatus?: LoadingStatus
    pagination?: Pagination
    entityName: FiltersInfo
    hotkeysScope: MODAL_ID
    noClearButton?: boolean
    onClearFiltersClicked?: () => void
    onTextClick: (e: any) => void
}): ReactElement {
    const labelCache = useRef<string>()
    const itemsText = useMemo(() => {
        if (!p.pagination && !labelCache.current) return null

        let message
        if (p.pagination) {
            const page = (p.pagination.page - 1) * p.pagination.perPage
            const displayedResults = p.resultsCount

            if (p.pagination?.total != 0)
                message = `Showing ${page + 1} - ${page + displayedResults} of ${
                    p.pagination.total > MAX ? 'many' : p.pagination.total
                }`
            else if (displayedResults) message = `Showing ${page + 1} - ${page + displayedResults}`
            else message = 'No results found'
        }

        if (p.resultsCount == 0 && !labelCache.current) {
            if (p.loadingStatus == 'started') message = `Loading`
            message = `No results found`
        }

        if (message == undefined && labelCache.current) message = labelCache.current

        if (message) labelCache.current = message

        return message
    }, [p.loadingStatus, p.pagination, p.resultsCount])

    return (
        <Flex style={{ marginRight: 10 }} align="baseline">
            {p.filters.activeFilters().length ? (
                [
                    <Flex style={{ marginRight: 10 }} align="center" key="filters">
                        <Flex align="baseline">
                            <Flex
                                style={{
                                    marginRight: 20
                                }}
                            >
                                <Text color="front.text.subtlerI" size="m">
                                    {itemsText}
                                </Text>
                            </Flex>
                            <SimpleButton onClick={p.onTextClick}>
                                <ButtonInset noHorizontalPadding>
                                    {`Filtering by ${p.filters.activeFilters().join(', ')}`}
                                </ButtonInset>
                            </SimpleButton>
                        </Flex>
                        {p.noClearButton ? null : (
                            <>
                                <Spacer width={20} />
                                <SimpleButton
                                    hotkeysScope={p.hotkeysScope}
                                    hotkeys="alt+x"
                                    hotkeysClueDirection="bottom-left"
                                    onClick={() => {
                                        p.filters.clearFilters()
                                        p.onClearFiltersClicked?.()
                                    }}
                                    cy="clear-all"
                                >
                                    <ButtonInset noHorizontalPadding>
                                        <IconHolder>
                                            <Icon type="x" size={7} weight={1.5} />
                                        </IconHolder>
                                        <Spacer width={5} />
                                        Clear filters
                                    </ButtonInset>
                                </SimpleButton>
                            </>
                        )}
                    </Flex>
                ]
            ) : (
                <Flex style={{ marginRight: 10 }} align="baseline" key="noFilters">
                    <Text color="front.text.subtlerI" size="s">
                        {itemsText}
                    </Text>
                </Flex>
            )}
        </Flex>
    )
}

const IconHolder = styled.div`
    background-color: ${(p) => p.theme['front.accent.color']};
    color: ${(p) => p.theme['front.accent.text']};
    border-radius: 999px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
`
