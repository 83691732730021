import { Metadata } from './metadata'
import React from 'react'
import { SectionDetails } from '@/services/ongoing-due-diligence'
import { Section } from '../../common/section'
import { SectionContainer } from '../section-container'
import { useReportDetails, useReportSection } from '../../provider/report-provider/report-provider'

export const DetailsSection: React.FC = () => {
    const section = useReportSection<SectionDetails>('details')
    const details = useReportDetails()

    const author = details.author_id
    const createdAt = details.date_started
    const data = section.report_reason

    const metadata = <Metadata author={author} createdAt={createdAt} />

    return (
        <SectionContainer title="Details" metadata={metadata}>
            <Section>
                <Section.Content>
                    <Section.Field name="report_reason" data={data} />
                </Section.Content>
            </Section>
        </SectionContainer>
    )
}
