import styled, { css } from 'styled-components'

import { Text } from '../../../components/general/text'

interface MerchantApplicationInternalLabelProps {
    fullspan?: boolean
}

export const MerchantApplicationInternalLabel = styled(Text).attrs<MerchantApplicationInternalLabelProps>((p) => ({
    fullspan: p.fullspan
}))`
    min-width: 155px;
    ${(p) =>
        p.fullspan &&
        css`
            grid-column: span 3;
        `};
    color: ${(p) => p.theme['back.text']};
`
