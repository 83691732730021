import { useCallback } from 'react'
import { WatcherButton } from '../../../../components/buttons/watcherButton'
import { Text } from '../../../../components/general/text'
import { Flex } from '../../../../components/layout/flex'
import { useDialog } from '../../../../hooks/general/useDialog'
import { ApplicationInternalsRecordActions } from '../../../../store/applicationInternals/record/actions'
import { MerchantNotification } from '../../../../store/applicationInternals/record/types'

export const MoreNotificationsRevokeButton: React.FC<{
    applicationId: string
    notification: MerchantNotification
}> = ({ applicationId, notification }) => {
    const confirmRevoking = useDialog(
        useCallback(
            (buttonWatcherId) => {
                return ApplicationInternalsRecordActions.REVOKE_NOTIFICATION(
                    buttonWatcherId,
                    applicationId,
                    notification.id,
                    notification.selfLink
                )
            },
            [notification, applicationId]
        ),
        'Confirm notification revoking',
        useCallback(() => {
            return (
                <Flex column>
                    <Text>
                        Are you sure you want to cancel this{' '}
                        {notification.protocol == 'https' ? 'webhook' : 'notification'}?
                    </Text>
                </Flex>
            )
        }, [notification]),
        `Yes, remove ${notification.protocol == 'https' ? 'webhook' : 'notification'}`,
        'front.accent.color'
    )

    return (
        <WatcherButton color="front.danger.color" onClick={confirmRevoking}>
            Revoke
        </WatcherButton>
    )
}
