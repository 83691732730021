export type MerchantApplicationInternalFieldCheck = {
    checkLink: string
    isChecked: boolean
}

export type MerchantApplicationsInternalsChecksState = {
    forApplication: {
        [applicationId: string]: Partial<{
            [checkKey: string]: {
                sectionLink: string
                fields: {
                    [fieldKey: string]: MerchantApplicationInternalFieldCheck
                }
            }
        }>
    }
}

export const InitialApplicationInternalsChecksState: MerchantApplicationsInternalsChecksState = {
    forApplication: {}
}
