import { Url } from '@/services/common'
import { api } from '../api'
import { Person, People } from '../types/people'

export const peopleApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPeople: build.query<People, Url>({
            query: (url) => {
                return { url }
            },
            transformResponse: (response: People) => {
                if (response.embedded['ch:people'] && !Array.isArray(response.embedded['ch:people'])) {
                    response.embedded['ch:people'] = [response.embedded['ch:people']]
                }

                return response
            }
        }),
        getPerson: build.query<Person, Url>({
            query: (url) => {
                return { url }
            }
        })
    })
})

export const { useGetPeopleQuery: useGetPeople, useGetPersonQuery: useGetPerson } = peopleApi
