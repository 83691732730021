import { Flex, Text } from "@/design-system"
import moment from "moment"
import countries from 'i18n-iso-countries'
import { MerchantApplicationFile } from "./Application.File"

const roles: any = {
    'director': 'Director',
    'owner': 'Owner',
    'director-and-owner': 'Director & Owner'
}

export const ResourceHistoryBody: React.FC<any> = (props) =>{
    const {fields, resource, resourceKeys, textColor, textShade, labelColor, applicationId, subsectionIndex} = props

    const getFieldLabels = () =>{
        const labels: React.ReactNode[] = []
        fields.map(field=>{
            if(field.label && !field.skipRendering){
                labels.push(
                    <Text key={field.label} color={labelColor} shade="400">{field.label}</Text>
                )
            }
        })
        return labels
    }

    const getValues = () =>{
        const values: React.ReactNode[] = []
        resourceKeys.map(key=>{
            if(key === 'dateOfBirth'){
                return values.push(
                    <Text key={key} color={textColor} shade={textShade}>{resource[key] ? moment(resource[key])
                        .format('D MMM YYYY') : "-"}</Text>
                )
            }
            if(key === 'country'){
                return values.push(
                    <Text key={key} color={textColor} shade={textShade}>
                        {resource[key] ? countries.getName(resource[key], 'en'): "-"}
                    </Text>
                )
            }
            if(key === 'role'){
                return values.push(
                    <Text key={key} color={textColor} shade={textShade}>
                        {resource[key] ? roles[resource[key]] : "-"}
                    </Text>
                )
            }
            if(key === 'addressLegitimation'){
                return values.push(
                    <MerchantApplicationFile applicationId={applicationId} indexPath={{
                        resourceKey: "peopleHistory",
                        fieldKey: "addressLegitimation",
                        subsectionIndex: subsectionIndex
                    }} preventUpload key={key} />
                )
            }
            if(key === 'pictureLegitimation'){
                return values.push(
                    <MerchantApplicationFile applicationId={applicationId} indexPath={{
                        resourceKey: "peopleHistory",
                        fieldKey: "pictureLegitimation",
                        subsectionIndex: subsectionIndex
                    }} preventUpload key={key} />
                )
            }
            if(key === 'ownership'){
                return values.push(
                    <MerchantApplicationFile applicationId={applicationId} indexPath={{
                        resourceKey: "websitesHistory",
                        fieldKey: "ownership",
                        subsectionIndex: subsectionIndex
                    }} preventUpload key={key} />
                )
            }
            
            values.push(
                <Text key={key} color={textColor} shade={textShade}>{resource[key] ? resource[key] : "-"}</Text>
            )
        })
        return values
    }


    return(
        <Flex gap="9">
            <Flex direction="column" gap="2">
                {getFieldLabels()}
            </Flex>
            <Flex direction="column" gap="2" grow={"1"}>
                {getValues()}
            </Flex>
        </Flex>
    )
}
