import { ISO8601DateTime } from '@/services/common'
import React from 'react'
import { RelativeDate as RelativeDateComp } from '@/components/general/relativeDate'
import { Text } from '@/components/general/text'

interface RelativeDateProps {
    children?: ISO8601DateTime
}

export const RelativeDate: React.FC<RelativeDateProps> = (props) => {
    const { children } = props

    if (!children) {
        return (
            <Text oneLine color="back.text.subtlerI">
                -
            </Text>
        )
    }

    return (
        <Text oneLine>
            <RelativeDateComp dateString={children} />
        </Text>
    )
}
