import React, { createContext, useCallback, useContext, useMemo } from 'react'

interface Context {
    disabled?: boolean
    openWindow: (url: string) => void
}

const SectionsContext = createContext<Context>({ disabled: false, openWindow: () => null })

interface SectionsProps {
    children: React.ReactNode
    disabled?: boolean
}

export const Sections: React.FC<SectionsProps> = (props) => {
    const { children, disabled } = props

    const openWindow = useCallback((url: string) => {
        window.open(url, 'ongoing-due-diligence', `popup`)
    }, [])

    const value = useMemo(() => ({ disabled, openWindow }), [disabled, openWindow])

    return <SectionsContext.Provider value={value}>{children}</SectionsContext.Provider>
}

export const useSectionsContext = () => {
    return useContext(SectionsContext)
}
