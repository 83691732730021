import React, { ReactElement, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@/store'
import { TasksFilters } from '../../store/tasks/types'
import { TextInputAutocomplete } from '../forms/textInputAutocomplete'
import { FiltersController } from '../../hooks/pages/useListPage'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'
import { ApplicationsFilters } from '@/store/applications/types'

export function ListPageFilterAgent<T extends TasksFilters | ApplicationsFilters>({
    filters,
    fieldKey,
    isDark
}: {
    filters: FiltersController<T>
    isDark: boolean
    fieldKey: keyof FiltersController<T>['get']
}): ReactElement {
    const [ref, setRef] = useRefTaker()
    const results = useSelector((state: RootState) => state.autocomplete.agents)

    const onSelect = useCallback(
        (item: string) => {
            filters.set((prevState: any) => ({
                ...prevState,
                [fieldKey]: prevState[fieldKey] === item ? undefined : item
            }))
        },
        [fieldKey, filters]
    )

    const handleBlur = useCallback(() => {
        ref.value = ''
    }, [ref])

    const placeholder = useMemo(() => {
        if (!filters.get[fieldKey]) return '-'
        return 'Filter by another Agent'
    }, [fieldKey, filters.get])

    const textForItem = useCallback(
        (item) => {
            return `${results.at?.[item]?.email}`
        },
        [results]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputAutocomplete
                ref={setRef}
                placeholder={placeholder}
                onSelect={onSelect}
                selected={filters.get[fieldKey]}
                dropdownId="TasksList.AgentFilter"
                forEntity="agents"
                overBackground={isDark ? 'floating.background' : 'side.background'}
                isSeamless
                noClear
                onBlur={handleBlur}
                isHigher={isDark}
                cy="agent-filter"
            />
            <SelectedListForListPageFilters<T>
                isDark={isDark}
                noHolderEmphasize={isDark}
                filterKey={fieldKey}
                filters={filters}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
