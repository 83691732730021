import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useModalStackSync } from '../general/useModalStackSync'
import { PaginationConfig, useFilters } from './useFilters'
import { Pagination } from '../../utils'
import { FiltersParams } from '../../utils/store'
import { Paginator } from '../../components/general/paginator'
import { MODAL_ID } from '../../components/modals/modalIds'
import { AnyAction } from 'deox'
import { useParams } from 'react-router-dom'

export type ListPageFiltersShowHideController = {
    showPopular: boolean
    showAll: boolean
    set: React.Dispatch<
        React.SetStateAction<{
            showPopular: boolean
            showAll: boolean
        }>
    >
}

export interface FiltersController<T> {
    get: {
        [P in keyof T]: any
    }
    clearFilters: () => void
    activeFilters: () => string[]
    set: React.Dispatch<React.SetStateAction<Partial<T>>>
    showHideController: ListPageFiltersShowHideController
}

interface NavigationContext {
    active: number
    outOf: number
    next?: string
    prev?: string
}

export function useListPage<T>(
    pageId: MODAL_ID,
    title: string,
    onLoadTrigger: (filters: T) => AnyAction,
    params?: FiltersParams,
    summaries?: { all: string[]; pagination?: Pagination },
    pageURL?: string,
    location?: any,
    defaultFilters?: Partial<T>,
    paginationConfig: PaginationConfig = {
        pageKey: 'page',
        perPageKey: 'per_page'
    },
    allowedValuesDictionary?: { [key: string]: any },
    loadIfFieldDefined?: string,
    clearSummaries?: () => AnyAction
): {
    filters: FiltersController<T>
    paginationNode: React.ReactNode
} {
    useModalStackSync(title, pageId, 0)

    const { filters } = useFilters(params, defaultFilters, allowedValuesDictionary, paginationConfig)
    const urlParams = useParams()

    useEffect(() => {
        const soleMid = urlParams.sole_mid

        if (!soleMid) return

        switch (pageId) {
            case 'DisputesList':
                filters.set((prevState: any) => {
                    if (prevState.disputes_mid === soleMid) return prevState
                    return {
                        ...prevState,
                        disputes_mid: soleMid
                    }
                })
                return
            case 'TransactionsList':
                filters.set((prevState: any) => {
                    if (prevState.transactions_mid === soleMid) return prevState
                    return {
                        ...prevState,
                        transactions_mid: soleMid
                    }
                })
                return
            case 'SettlementsList':
                filters.set((prevState: any) => {
                    if (prevState.settlements_mid === soleMid) return prevState
                    return {
                        ...prevState,
                        settlements_mid: soleMid
                    }
                })
                return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, pageId])

    const showHideCache = useRef<any>({
        showPopular: false,
        showAll: false
    })
    const filtersShowHideController = useState({
        showPopular: showHideCache.current.showPopular,
        showAll: showHideCache.current.showAll
    })
    const [getController, setController] = filtersShowHideController

    const [activePage, setActivePage] = useState<any>(undefined)
    const [navigationContext, setNavigationContext] = useState<NavigationContext>({
        active: 0,
        outOf: 0,
        next: undefined,
        prev: undefined
    })

    const locationCache = useRef<any>(undefined)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!summaries || !location || !pageURL) return
        if (locationCache.current == location.pathname) return

        const currentlyOpenTaskId = getInsidePageIdFromPath(location.pathname, pageURL)

        if (currentlyOpenTaskId) {
            setActivePage(currentlyOpenTaskId)
            const tempCtx: NavigationContext = {
                outOf: 0,
                active: 0,
                next: undefined,
                prev: undefined
            }

            summaries.all.map((taskId: string, i) => {
                if (taskId == currentlyOpenTaskId) {
                    tempCtx.active = i
                    tempCtx.prev = summaries.all[i - 1]
                        ? `${pageURL}${summaries.all[i - 1]}${location.search}`
                        : undefined
                    tempCtx.next = summaries.all[i + 1]
                        ? `${pageURL}${summaries.all[i + 1]}${location.search}`
                        : undefined
                }
            })

            setNavigationContext({
                ...tempCtx,
                outOf: summaries.all.length
            })
        } else {
            setActivePage(undefined)
        }

        if (summaries.all.length > 0) locationCache.current = location.pathname
    }, [summaries, location, pageURL])

    useEffect(() => {
        showHideCache.current.showPopular = getController.showPopular
        showHideCache.current.showAll = getController.showAll
    }, [filtersShowHideController, getController.showAll, getController.showPopular])

    const load = useCallback(
        (s?: any) => {
            let state = filters.get
            if (typeof s === 'object') {
                state = s
            } else if (s) {
                state = s(state)
            }

            if (loadIfFieldDefined && !state[loadIfFieldDefined]) {
                if (clearSummaries) dispatch(clearSummaries())
                return
            }

            dispatch(
                onLoadTrigger({
                    ...state,
                    [paginationConfig.pageKey]: state?.[paginationConfig.pageKey]
                        ? parseInt(state[paginationConfig.pageKey])
                        : 1,
                    [paginationConfig.perPageKey]: state?.[paginationConfig.perPageKey]
                        ? parseInt(state[paginationConfig.perPageKey])
                        : 20
                })
            )
        },
        [
            clearSummaries,
            dispatch,
            filters.get,
            loadIfFieldDefined,
            onLoadTrigger,
            paginationConfig.pageKey,
            paginationConfig.perPageKey
        ]
    )

    useEffect(() => {
        load()
    }, [load])

    return {
        filters: {
            get: filters.get as { [P in keyof T]: any },
            set: useCallback(
                (s: any, doNotResetPage?: boolean) => {
                    filters.set(s, doNotResetPage)
                },
                [filters]
            ),
            clearFilters: () => {
                load({})
                filters.clearFilters()
            },
            activeFilters: filters.activeFilters,
            showHideController: {
                ...getController,
                set: setController
            }
        },
        paginationNode: summaries?.pagination ? (
            <Paginator
                pagination={summaries.pagination}
                onPageChanged={(page) => {
                    filters.set(
                        (s: any) => ({
                            ...s,
                            [paginationConfig.pageKey]: page
                        }),
                        true
                    )
                }}
            />
        ) : null
    }
}

const getInsidePageIdFromPath = (path: string, urlPrefix: string) => {
    let id

    if (path.includes(urlPrefix)) {
        if (path.includes('?')) id = path.substr(path.indexOf(urlPrefix) + urlPrefix.length, path.indexOf('?'))
        else id = path.substr(path.indexOf(urlPrefix) + urlPrefix.length, path.length - 1)
    }

    return id
}
