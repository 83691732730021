import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { useDialog } from '../../../hooks/general/useDialog'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { RootState } from '@/store'

export const AccountControlButton: React.FC<{
    stamp: 'closed' | 'live'
    accountId: string
    label: string
    stampsLink: string
}> = ({ stamp, accountId, label, stampsLink }) => {
    const confirmStamping = useDialog<{
        action: string
        accountId: string
        stampsLink: string
        successMessage: string
    }>(
        useCallback((watcherId, { action, accountId, stampsLink, successMessage }) => {
            return MerchantAccountsActions.STAMP_ACCOUNT(watcherId, accountId, stampsLink, successMessage)
        }, []),
        stamp === 'closed' ? 'Close account confirmation' : 'Set account live confirmation',
        useCallback(() => {
            if (stamp === 'closed')
                return (
                    <Flex column>
                        <Text>
                            Are you sure you want to <Text bold>close</Text> this account?
                        </Text>
                        <Spacer height={15} />
                        <Text>
                            <Text bold>WARNING:</Text>
                            &nbsp; This will disable the merchant&apos;s API key and prevent the merchant from making
                            any transactions
                        </Text>
                    </Flex>
                )
            return `Are you sure you want to set this account live?
            This will enable the API key and allow the merchant to make transactions`
        }, [stamp]),
        stamp === 'closed' ? 'Yes, close the account' : 'Yes, set the account live',
        stamp === 'closed' ? 'front.danger.color' : 'front.accent.color'
    )

    return (
        <WatcherButton
            background={stamp == 'closed' ? 'front.danger.color' : 'front.accent.color'}
            predefinedWatcher={`${accountId}_STAMP`}
            onClick={() => {
                confirmStamping({
                    action: stamp,
                    accountId: accountId,
                    stampsLink: stampsLink,
                    successMessage:
                        stamp == 'closed'
                            ? 'Account has been CLOSED successfully'
                            : 'Account has been set LIVE successfully'
                })
            }}
        >
            <ButtonInset>{label}</ButtonInset>
        </WatcherButton>
    )
}

export const MerchantAccountControls: React.FC<{ accountId: string }> = ({ accountId }) => {
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])

    const renderedActionButton = useMemo(() => {
        if (!account?.stampsLink) return null
        const labelsForStamp: any = {
            LIVE: {
                title: 'Set account live',
                key: 'live'
            },
            CLOSED: {
                title: 'Close account',
                key: 'closed'
            }
        }
        const stamps = account.stampsLink.map((i) => {
            return {
                name: labelsForStamp[i.name.toUpperCase()].title,
                key: labelsForStamp[i.name.toUpperCase()].key,
                href: i.href
            }
        })
        return stamps.map((stamp) => {
            return (
                <React.Fragment key={stamp.key}>
                    <Spacer width={5} />
                    <AccountControlButton
                        stamp={stamp.key}
                        label={stamp.name}
                        key={stamp.key}
                        stampsLink={stamp.href}
                        accountId={accountId}
                    />
                </React.Fragment>
            )
        })
    }, [account, accountId])

    return <>{renderedActionButton}</>
}
