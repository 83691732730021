import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { FileBox } from '../../components/files/fileBox'
import { Flex } from '../../components/layout/flex'
import { MarkdownPreviewItem } from '../../components/general/markdownPreviewItem'
import { Text } from '../../components/general/text'
import { TimelineCard } from '../../components/listPages/timelineCard'
import { UserComment } from '../../components/complexComponents/userComment'
import { TaskTimelineAlert } from '../../components/taskPages/taskTimelineAlert'
import { TaskTimelineApplicationLink } from '../../components/taskPages/taskTimelineApplicationLink'
import { TaskTimelineEvent } from '../../components/taskPages/taskTimelineEvent'
import { Timeline } from '../../components/taskPages/timeline'
import { FileActionDispatchDelete } from '../../store/files/actions'
import { TaskDispatchEditComment, TaskDispatchRemoveComment, TaskDispatchUnlinkFiles } from '../../store/tasks/actions'
import { Task } from '../../store/tasks/types'
import { WatcherID } from '../../store/watcher/types'
import { uppercaseFirstLetter } from '../../utils'
import { DateFormats } from '../../utils/dateUtils'
import { AlertModalType } from './types'

export const AlertModalTimeline: React.FC<{
    task: Task
    preview?: string
    type: AlertModalType
}> = ({ task, preview, type }) => {
    const dispatch = useDispatch()

    const handleItemDeletion = useCallback(
        (id: string, watcherId: WatcherID) => {
            const item = task?.timeline?.timeline.filter((i) => i.data.id == id)[0]
            if (item?.data?.subjectId && item?.data?.selfLink)
                dispatch(TaskDispatchRemoveComment(watcherId, item.data.subjectId, item.data.selfLink))
        },
        [dispatch, task]
    )

    const handleItemEdit = useCallback(
        (id: string, watcherId: WatcherID, val: string) => {
            const item = task?.timeline?.timeline.filter((i) => i.data.id == id)[0]
            if (item?.data?.subjectId && item?.data?.selfLink)
                dispatch(TaskDispatchEditComment(watcherId, item.data.subjectId, item.data.selfLink, val))
        },
        [dispatch, task]
    )

    const skipRenderingGeneralCards = useMemo(() => {
        if (task.type == 'company-change') return true
        return false
    }, [task])

    const renderedDynamicTimeline = useMemo(() => {
        const timeline = task?.timeline?.timeline

        if (!timeline) return []

        const timelineItems = [
            ...(skipRenderingGeneralCards
                ? []
                : [
                      {
                          tabIndex: 'Everything',
                          date: moment(task.createdAt).subtract('day', 1),
                          node: (
                              <TimelineCard
                                  key={`createdAt${task.createdAt}`}
                                  title={<Text bold>{task.title ? uppercaseFirstLetter(task.title) : null}</Text>}
                                  rightSideMemo={
                                      <Flex cy="created-date" grow justify="flex-end">
                                          <Text color="front.text.subtlerI">
                                              {moment(task.createdAt).format(
                                                  DateFormats.dayAndTime(moment(task.createdAt))
                                              )}
                                          </Text>
                                      </Flex>
                                  }
                              />
                          )
                      }
                  ]),
            ...timeline.map((item: any, i: number, arr: any[]) => {
                if (item.type === 'comment')
                    return {
                        tabIndex: 'Comments',
                        date: moment.unix(parseInt(item.at)),
                        node: (
                            <TimelineCard key={item.data.id}>
                                <UserComment
                                    authorType="id"
                                    author={item.data.authorId}
                                    id={item.data.id}
                                    body={item.data.body}
                                    date={moment.unix(item.at).toISOString()}
                                    onDelete={handleItemDeletion}
                                    onEdit={handleItemEdit}
                                />
                            </TimelineCard>
                        )
                    }
                if (item.type === 'file')
                    return {
                        tabIndex: 'Files',
                        date: moment.unix(parseInt(item.at)),
                        node: (
                            <TimelineCard key={item.data.id}>
                                <FileBox
                                    fileId={item.data.id}
                                    showFileMenu
                                    onRemoveClicked={() => {
                                        dispatch(
                                            FileActionDispatchDelete(
                                                `${item.data.id}.DeleteFile`,
                                                item.data,
                                                (files) => TaskDispatchUnlinkFiles(item.data.subjectId, files),
                                                true
                                            )
                                        )
                                    }}
                                />
                            </TimelineCard>
                        )
                    }
                if (item.type === 'event') {
                    if (skipRenderingGeneralCards) return null
                    if (item.data.source === 'alertr')
                        return {
                            tabIndex: 'Alerts',
                            date: moment.unix(parseInt(item.at)),
                            node: (
                                <TaskTimelineAlert
                                    key={item.data.id}
                                    date={moment.unix(parseInt(item.at))}
                                    data={item.data}
                                />
                            )
                        }
                    else
                        return {
                            tabIndex: 'Events',
                            date: moment.unix(parseInt(item.at)),
                            node: (
                                <TaskTimelineEvent
                                    e={item.data}
                                    key={item.data.id}
                                    date={moment.unix(parseInt(item.at))}
                                    task={task}
                                />
                            )
                        }
                }
                return null
            })
        ]

        if (type === 'task' && task.application?.id)
            timelineItems.push({
                tabIndex: 'Events',
                date: moment(task.createdAt),
                node: (
                    <TaskTimelineApplicationLink
                        key="application-link"
                        applicationId={task.application.id}
                        last={timelineItems.length === 0}
                    />
                )
            })

        timelineItems.push({
            tabIndex: 'Everything',
            date: moment(),
            node: <MarkdownPreviewItem key={'markdownpreview'} showContainerCard pageId="AlertPage" showHeader />
        })

        return timelineItems.filter((t: any) => t)
    }, [task, type, handleItemDeletion, handleItemEdit, skipRenderingGeneralCards, dispatch])

    return <Timeline items={renderedDynamicTimeline} preview={preview} hotkeysScope="AlertPage" />
}
