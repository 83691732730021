import { createReducer } from 'deox'
import dotProp from 'dot-prop'
/* eslint-disable no-param-reassign */
import { produce } from 'immer'
import { ApplicationInternalsActions } from '../actions'

import { ApplicationInternalsChecksActions } from './actions'
import { InitialApplicationInternalsChecksState } from './types'

export const ApplicationInternalsChecksReducer = createReducer(
    InitialApplicationInternalsChecksState,
    (handleAction) => [
        handleAction(ApplicationInternalsChecksActions.STORE_CHECK, (state, { payload: p }) => {
            return produce(state, (draft) => {
                dotProp.set(
                    draft,
                    `forApplication.${p.applicationId}.${p.checkPath.replace('.', '\\.')}.fields.${p.fieldKey}`,
                    p.check
                )
            })
        }),
        handleAction(ApplicationInternalsChecksActions.STORE_CHECKS, (state, { payload: p }) => {
            return produce(state, (draft: any) => {
                dotProp.set(draft, `forApplication.${p.applicationId}`, p.checks)
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = { ...InitialApplicationInternalsChecksState }
                return draft
            })
        })
    ]
)
