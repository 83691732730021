import { ExternalLink } from '@/components/buttons/externalLink'
import { HelpTip } from '@/components/general/helpTip'
import { Text } from '@/components/general/text'
import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import React from 'react'
import { PartnerGuideline } from '../types'

interface PartnerGuidelinesHelpTipProps {
    guidelines: PartnerGuideline[]
}

export const PartnerGuidelinesHelpTip: React.FC<PartnerGuidelinesHelpTipProps> = (props) => {
    const { guidelines } = props

    return (
        <div>
            <HelpTip>
                <Flex column>
                    <Text color="floating.text">Partner guidelines:</Text>
                    {guidelines.map((guideline) => (
                        <React.Fragment key={`${guideline.name}-${guideline.url}`}>
                            <Spacer width={4} />
                            <ExternalLink defaultColor="floating.subtleAccent.text" target="blank" url={guideline.url}>
                                {guideline.name}
                            </ExternalLink>
                        </React.Fragment>
                    ))}
                </Flex>
            </HelpTip>
        </div>
    )
}
