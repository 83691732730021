import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePostingBalance, useReconcilePostingActions } from '../../../hooks'
import { ReconcileAction } from '../common/reconcile-action'

export const ReconcilePosting: React.FC = () => {
    const navigate = useNavigate()
    const { isLoading, reconcile } = useReconcilePostingActions()

    const balance = usePostingBalance()
    const isDisabled = balance !== 0

    const handleReconcile = async () => {
        await reconcile()

        navigate('/more/reconciliation-postings')
    }

    return <ReconcileAction onClick={handleReconcile} isDisabled={isDisabled} isLoading={isLoading} />
}
