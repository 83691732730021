import { LoaderView } from '../../../components/loaders/loader'
import { PageContent } from '../../../components/layout/pageContent'
import { useListPageMerchantFilter } from '../../../hooks/pages/useListPageMerchantFilter'
import { Transactions } from '../../Transactions/Transactions'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantTransactions: React.FC = () => {
    const params = useParams() as Params

    const merchantId = params.id

    const [mids, midsLoadingFinished] = useListPageMerchantFilter('transactions_mid', merchantId)

    if (!midsLoadingFinished) return <LoaderView overBackground="back.background" />

    return (
        <PageContent className="POPPER_PARENT" noVerticalPadding>
            <Transactions inline forMids={mids} />
        </PageContent>
    )
}
