import { SectionCheck } from '@/services/ongoing-due-diligence'
import React from 'react'
import { Section } from '../../../common/section'
import { useReportSection } from '../../../provider/report-provider/report-provider'

export const WebsiteAvailableRefundPolicy: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')

    return (
        <Section>
            <Section.Content>
                <Section.Field name="website_available_refund_policy" data={checks.website_available_refund_policy} />
            </Section.Content>
        </Section>
    )
}
