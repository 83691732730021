import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { PageHeader } from '../../components/layout/pageHeader'
import { Table, TableRow } from '../../components/tables/table'
import { VouchersActions } from '../../store/manage/vouchers/actions'
import { RootState } from '@/store'
import { LinkButton } from '../../components/buttons/linkButton'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { useCopyVoucherNodes } from './useCopyVoucherNodes'
import { Spacer } from '../../components/layout/spacer'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { TableFuzzySearch } from '../../components/layout/tableFuzzySearch'
import { Icon } from '../../components/icons/icon'
import styled from 'styled-components'
import moment from 'moment'
import { DateFormats } from '../../utils/dateUtils'
import { Buffer } from 'buffer'
import { useLocation } from 'react-use'

export const ManageVouchers: React.FC = () => {
    const vouchers = useSelector((state: RootState) => state.manage.vouchers)
    const dispatch = useDispatch()
    const [filteredRows, setFilteredRows] = useState<TableRow[]>([])
    useModalStackSync('Manage vouchers', 'Manage.Vouchers', 0)

    useEffect(() => {
        if (vouchers.loadingStatus === 'not-started') dispatch(VouchersActions.LOAD())
    }, [dispatch, vouchers])

    const columns = useMemo(() => {
        return [
            {
                text: 'PSP partner',
                loaderSize: {
                    min: 30,
                    max: 160
                }
            },
            {
                text: 'Referral partner',
                loaderSize: {
                    min: 15,
                    max: 15
                }
            },
            {
                text: 'Instant',
                loaderSize: {
                    min: 15,
                    max: 15
                }
            },
            {
                text: 'Created at',
                loaderSize: {
                    min: 25,
                    max: 25
                }
            },
            {
                text: 'Voucher Code',
                loaderSize: {
                    min: 100,
                    max: 800
                }
            },
            {
                text: 'Template',
                loaderSize: {
                    min: 35,
                    max: 35
                }
            },
            {
                text: 'Actions',
                alignRight: true,
                loaderSize: {
                    min: 165,
                    max: 165
                }
            }
        ]
    }, [])

    const [, renderNodes] = useCopyVoucherNodes()

    const location = useLocation()
    const rows = useMemo(() => {
        return vouchers.all.map((id: string) => {
            const voucher = vouchers.at[id]

            const buff = Buffer.from(voucher.code, 'base64')
            const str = buff.toString('utf-8')
            let isInstant: any = undefined
            let createdAt: any = undefined
            let templateId: any = undefined

            try {
                const decodedVoucher = JSON.parse(str)
                isInstant = decodedVoucher.instant
                createdAt = decodedVoucher.iat
                templateId = decodedVoucher.psi
            } catch (e) {}

            return {
                type: 'normal',
                key: voucher.id,
                noAction: true,
                items: [
                    {
                        node: (
                            <Text
                                noWrap
                                bold={!!voucher.gatewayName}
                                color={voucher.gatewayName ? 'front.text' : 'front.text.subtlerI'}
                            >
                                {voucher.gatewayName || '-'}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text
                                noWrap
                                bold={!!voucher.referralPartnerName}
                                color={voucher.referralPartnerName ? 'front.text' : 'front.text.subtlerI'}
                            >
                                {voucher.referralPartnerName || '-'}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text oneLine>
                                {isInstant ? (
                                    <Flex>
                                        <IconOffset>
                                            <Icon type="lightning" />
                                        </IconOffset>
                                        Instant
                                    </Flex>
                                ) : (
                                    '-'
                                )}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text oneLine>
                                {createdAt ? moment(createdAt).format(DateFormats.dayAndTime(moment(createdAt))) : '-'}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text oneLine color="front.text.subtlerI">
                                {voucher.code}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <LinkButton
                                to={{
                                    pathname: `/more/contract-templates/${templateId}`
                                }}
                                state={{
                                    backTo: location.pathname
                                }}
                            >
                                {templateId ? `See template` : null}
                            </LinkButton>
                        )
                    },
                    {
                        node: <Flex>{renderNodes(voucher.code)}</Flex>
                    }
                ]
            } as TableRow
        })
    }, [vouchers, location, renderNodes])

    const rightSideMemo = useMemo(() => {
        return (
            <>
                <Spacer width={10} />
                <LinkButton passBackToState background="front.accent.color" to="/more/vouchers/new">
                    <ButtonInset>Create a new voucher</ButtonInset>
                </LinkButton>
            </>
        )
    }, [])

    const leftSideMemo = useMemo(() => {
        return (
            <TableFuzzySearch
                headers={columns}
                rows={rows}
                queryParamName="vouchers-query"
                setFilteredRows={setFilteredRows}
                ignoreHeaderWithText="Actions"
            />
        )
    }, [rows, setFilteredRows, columns])

    return (
        <>
            <PageHeader
                noBottomBorder
                leftSideMemo={leftSideMemo}
                title="Vouchers"
                subtitle=""
                rightSideMemo={rightSideMemo}
            />
            <Card>
                <CardInset>
                    <Table
                        background="front.background"
                        cols={columns}
                        columnLayout={
                            'min-content min-content min-content' + ' 0.5fr minmax(0, 2fr) min-content min-content'
                        }
                        showLastRowBorder
                        displayLoader={vouchers.loadingStatus !== 'done'}
                        loaderStyles={{ minHeight: '90vh' }}
                        loaderRows={40}
                        rows={filteredRows}
                        emptyText="No vouchers found."
                    />
                </CardInset>
            </Card>
            <Spacer height={20} />
        </>
    )
}

const IconOffset = styled.div`
    position: relative;
    top: 2px;
    left: -2px;
    color: ${(p) => p.theme['front.success.color']};
`
