import { createReducer } from 'deox'
import { produce } from 'immer'
import {
    AvailableLineOptions,
    AvailableNotificaitonOptions
} from '../../components/listPages/sidebarNavigation/sidebarCallSettingsPanel'
import { CallsPrefActions } from './actions'
import { InitialCallsPrefState } from './types'

export const CallsPrefReducer = createReducer(InitialCallsPrefState, (handleAction) => [
    handleAction(CallsPrefActions.SET_IS_CALLING, (state, { payload: p }) =>
        produce(state, (draft) => {
            localStorage.setItem('previousIsCalling', state.isCalling)
            localStorage.setItem('isCalling', p.isCalling)
            draft.isCalling = p.isCalling
        })
    ),
    handleAction(CallsPrefActions.UPDATE_PREF, (state, { payload: p }) =>
        produce(state, (draft) => {
            localStorage.setItem('previousNotifications', state.notifications)
            localStorage.setItem('notifications', p.notifications)
            if (AvailableNotificaitonOptions.includes(p.notifications)) draft.notifications = p.notifications

            localStorage.setItem('previousLocalNumber', state.local_number)
            localStorage.setItem('localNumber', p.local_number)
            draft.local_number = p.local_number

            localStorage.setItem('previousLine', state.line)
            localStorage.setItem('line', p.line)
            if (AvailableLineOptions.includes(p.line)) draft.line = p.line

            localStorage.setItem('nickname', p.nickname)
        })
    )
])
