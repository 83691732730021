import styled, { css } from 'styled-components'

export const Flag: React.FC<{ code?: string; smaller?: boolean }> = ({ code, smaller }) => {
    if (!code) return null
    return (
        <Holder smaller={smaller}>
            <img src={`/res/flags/${code.toLowerCase()}.svg`} />
        </Holder>
    )
}
const Holder = styled.div<{ smaller?: boolean }>`
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
    border-radius: 9999px;
    margin-left: -2px;
    overflow: hidden;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1), inset 0px 2px 2px 0px rgba(255, 255, 255, 1);

    & > * {
        height: 100%;
        transform: translateX(-3px) translateY(0px);
    }

    img {
        max-width: unset;
    }

    ${(p) =>
        p.smaller &&
        css`
            width: 13px;
            height: 13px;
            margin-left: 0;
            & > * {
                height: 100%;
                transform: translateX(-2px) translateY(0px);
            }
        `}
`
