import { Flex, Text } from '@/design-system'
import React from 'react'
import { TransactionRules } from './transaction-rules'

interface IntegrationRowContentProps {
    children: React.ReactNode
    transactionRules: string
}

const IntegrationRowContent: React.FC<IntegrationRowContentProps> = (props) => {
    const { children, transactionRules } = props

    return (
        <Flex direction="column" gap="10">
            <Flex direction="column" gap="4">
                <Text color="neutral">Transaction rules</Text>
                <TransactionRules transactionRules={transactionRules} />
            </Flex>
            <Flex direction="column" gap="4">
                {children}
            </Flex>
        </Flex>
    )
}

export { IntegrationRowContent }
