import { useGetApplication, useGetCompany, useGetPeople } from '@/services/merchant-api'
import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'

export const useApplicationCompanyPeople = (applicationId: string) => {
    const url = `${import.meta.env.VITE_API_ROOT}/applications/${applicationId}`

    // Fetch application
    const application = useGetApplication(url)

    // Fetch company
    const companyLink = application.data?.relations.company
    const company = useGetCompany(application.isSuccess && companyLink ? companyLink : skipToken)

    // Fetch people
    const peopleLink = company.data?.relations.people
    const people = useGetPeople(company.isSuccess && peopleLink ? peopleLink : skipToken)

    // Result
    const isLoading = application.isLoading || company.isLoading || people.isLoading
    const isError = application.isError || company.isError || people.isError
    const data = people.data

    const result = useMemo(() => ({ data, isLoading, isError }), [data, isLoading, isError])

    return result
}
