import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { Icon } from '../icons/icon'
import { zIndexes } from '../../styles/zIndexes'
import { MODAL_ID } from './modalIds'
import { LinkButton } from '../buttons/linkButton'
import { useLocation, useNavigate } from 'react-router-dom'

export const FullscreenModalBackButton: React.FC<{
    onClick?: () => void
    backTo?: string
    type?: 'navigationBackArrow' | 'x'
    hotkeyScope?: MODAL_ID
    overBackground?: 'side.background'
}> = ({ overBackground, backTo, type = 'navigationBackArrow', onClick, hotkeyScope }) => {
    const location = useLocation()
    const navigate = useNavigate()

    const EXIT = useCallback(() => {
        if (onClick) {
            onClick()
        } else if (backTo) {
            navigate({
                pathname: backTo,
                search: location.search
            })
        }
    }, [onClick, backTo, navigate, location.search])

    const clue = useHotkey({
        keys: 'esc, alt+esc',
        action: EXIT,
        scope: hotkeyScope,
        clue: 'right'
    })

    return (
        <CloseButton
            overBackground={overBackground}
            color="currentColor"
            to={{
                pathname: backTo,
                search: location.search
            }}
            cy="modal-close-button"
            onClick={(e: any) => {
                if (onClick) {
                    e.preventDefault()
                    onClick()
                }
            }}
        >
            <Icon type={type} />
            {clue}
        </CloseButton>
    )
}

const CloseButton = styled<any>(LinkButton)`
    padding: 0;
    height: 30px;
    width: 30px;
    padding: 10px;
    margin: -10px;
    border-radius: 999px;
    z-index: ${zIndexes.popover};
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 1;
    position: relative;
    left: -0px;
    ${(p) =>
        p.overBackground == 'side.background' &&
        css`
            color: ${p.theme['side.text']};

            &:hover {
                color: ${p.theme['side.text.subtlerI']};
            }
            &:active {
                color: ${p.theme['side.text.subtlerII']};
            }
        `}
` as any
