import { Container, Flex, Grid, Page } from '@/design-system'
import { SigningKeySchema, useSigningKeyForm } from '@/features/signing-keys'
import { RTKQuery } from '@/services/common'
import { MAPI_INTEGRATIONS_ROOT, createSigningKey } from '@/services/merchant-api'
import { FormCheckbox, FormDatePicker, FormTextarea, PageHeader, SaveCard } from '@/ui'
import { FormProvider } from 'react-hook-form'
import { Form, redirect, useNavigate, useSubmit } from 'react-router-dom'

export const action = async ({ request, params }) => {
    const { partnerId, integrationId } = params

    const formData = await request.formData()
    const data = Object.fromEntries(formData)
    const url = `${MAPI_INTEGRATIONS_ROOT}/${integrationId}/signing_keys`
    const body = { url, data }
    await RTKQuery.post(createSigningKey, body)

    return redirect(`/more/partners/${partnerId}`)
}

const SigningKeyAdd = () => {
    const navigate = useNavigate()
    const form = useSigningKeyForm()
    const submit = useSubmit()

    const onBack = () => {
        navigate(-1)
    }

    const onSubmit = form.handleSubmit((values: SigningKeySchema) => {
        submit(values, { method: 'post' })
    })

    const errorCount = Object.keys(form.formState.errors).length

    return (
        <Page pb="normal">
            <PageHeader title="Add signing key" onBack={onBack} />
            <Flex justify="center" align="center" grow="1">
                <Container size="lg">
                    <FormProvider {...form}>
                        <Form onSubmit={onSubmit}>
                            <SaveCard>
                                <SaveCard.Body>
                                    <SaveCard.Title>Signing key details</SaveCard.Title>
                                    <SaveCard.Fields>
                                        <Grid columns="3" gap="3">
                                            <FormTextarea>
                                                <FormTextarea.Label name="verification_key">
                                                    Verification key
                                                </FormTextarea.Label>
                                                <FormTextarea.Textarea
                                                    testid="verification-key-input"
                                                    name="verification_key"
                                                    rows={3}
                                                />
                                            </FormTextarea>
                                            <FormDatePicker
                                                testid="expires-at-picker"
                                                label="Expires at"
                                                name="expires_at"
                                            />
                                            <FormCheckbox testid="enabled-checkbox" name="enabled" label="Enabled" />
                                        </Grid>
                                    </SaveCard.Fields>
                                </SaveCard.Body>
                                <SaveCard.Footer testid="card-footer" errorCount={errorCount} />
                            </SaveCard>
                        </Form>
                    </FormProvider>
                </Container>
            </Flex>
        </Page>
    )
}

SigningKeyAdd.action = action

export { SigningKeyAdd }
