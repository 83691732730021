import { createReducer } from 'deox'
import dotProp from 'dot-prop'
/* eslint-disable no-param-reassign */
import { produce } from 'immer'
import { camelCase, difference } from 'lodash'
import moment from 'moment'

import { AllMerchantApplicationSections } from '../../pages/Merchant/Application/Application.Structure'
import { Comment } from '../applications/types'
import { File } from '../files/types'
import { ApplicationResourceActions } from './actions'
import { InitialApplicationResourcesState } from './types'

export const ApplicationResourcesReducer = createReducer(InitialApplicationResourcesState, (handleAction) => [
    handleAction(ApplicationResourceActions.STORE, (state, { payload: p }) =>
        produce(state, (draft) => {
            if (!draft.data.forApplication[p.applicationId]) draft.data.forApplication[p.applicationId] = {} as any

            if (!p?.selfLink) {
                draft.data.forApplication[p.applicationId][p.resourceKey] = {
                    ...draft.data.forApplication[p.applicationId][p.resourceKey],
                    loadingStatus: 'error'
                }
            } else {
                draft.data.forApplication[p.applicationId][p.resourceKey] = {
                    ...draft.data.forApplication[p.applicationId][p.resourceKey],
                    loadingStatus: 'done',
                    selfLink: p.selfLink,
                    nextLink: p?.nextLink,
                    fields: [...p.fields]
                }
            }
        })
    ),
    handleAction(ApplicationResourceActions.STORE_ITEM, (state, { payload: p }) =>
        produce(state, (draft) => {
            dotProp.set(
                draft.data.forApplication[p.applicationId],
                `${p.indexPath.resourceKey}.field.${p.indexPath.subsectionIndex}.${p.indexPath.fieldKey}`,
                p.value
            )
        })
    ),
    handleAction(ApplicationResourceActions.CLEANUP, (state, { payload: p }) =>
        produce(state, (draft) => {
            delete draft.history.forApplication[p.applicationId]
            delete draft.comments.forApplication[p.applicationId]
            delete draft.data.forApplication[p.applicationId]
        })
    ),
    handleAction(ApplicationResourceActions.STORE_COMMENT, (state, { payload: p }) =>
        produce(state, (draft) => {
            const commentsPath = `comments.forApplication.${p.applicationId}.${p.indexPath.resourceKey}.fields.${
                p.indexPath.subsectionIndex || 0
            }.${p.indexPath.fieldKey}.comments`
            const comments: any = dotProp.get(draft, commentsPath)

            if (!comments) {
                dotProp.set(draft, commentsPath, [p.comment])
            } else {
                comments.push(p.comment)
                comments.sort((a: Comment, b: Comment) => {
                    if (moment(a.createdAt).isBefore(moment(b.createdAt))) return 1
                    return -1
                })
            }
        })
    ),
    handleAction(ApplicationResourceActions.STORE_COMMENTS, (state, { payload: p }) =>
        produce(state, (draft) => {
            const sectionLoadingPath = `comments.forApplication.${p.applicationId}.${p.sectionKey}`
            // POTENTIAL TO-DO ADD LINK TO REMOTE COMMENTS URL

            dotProp.set(draft, `${sectionLoadingPath}.loadingStatus`, 'done')
            if (p.comments && p.comments.length)
                p.comments.forEach((c) => {
                    const tagSegments = c.tags[0].split(':')[1].split('.')
                    const fieldKey = tagSegments.map((t) => camelCase(t)).join('.')
                    const sectionCommentsPath = `comments.forApplication.${p.applicationId}.${p.sectionKey}.fields.${
                        p.subsectionIndex || 0
                    }.${fieldKey}`

                    const previousComments = dotProp.get<any>(draft, sectionCommentsPath)

                    let commentIncluded = false
                    previousComments?.comments.map((com: any) => {
                        if (JSON.stringify(com) === JSON.stringify(c)) commentIncluded = true
                    })

                    dotProp.set(draft, sectionCommentsPath, {
                        comments: [...(previousComments?.comments || []), ...(commentIncluded ? [] : [c])],
                        commentsLink: c.commentsLink
                    })
                })
        })
    ),
    handleAction(ApplicationResourceActions.UNSTORE_COMMENT, (state, { payload: p }) =>
        produce(state, (draft) => {
            const commentPath = `comments.forApplication.${p.applicationId}.${p.indexPath.resourceKey}.fields.${
                p.indexPath.subsectionIndex || 0
            }.${p.indexPath.fieldKey}.comments`
            const comments: any = dotProp.get(draft, commentPath)

            if (!comments?.filter) return
            dotProp.set(
                draft,
                commentPath,
                comments.filter((c: Comment) => c.id !== p.commentId)
            )
        })
    ),
    handleAction(ApplicationResourceActions.LINK_FILES, (state, { payload: p }) =>
        produce(state, (draft) => {
            p.files.forEach((f: File) => {
                const fileLabel = camelCase(f.label)

                // Link to file drom applicationResourceDate
                const data =
                    draft.data.forApplication?.[p.applicationId]?.[p.resourceKey]?.fields?.[p.subsectionIndex || 0]

                if (!data) return draft

                if (data?.[fileLabel]) data[fileLabel] = [...data[fileLabel], f.id]
                else data[fileLabel] = [f.id]

                // Add the comment link to applicationResourceComments
                if (!draft.comments.forApplication[p.applicationId])
                    draft.comments.forApplication[p.applicationId] = {} as any

                const comments = draft.comments.forApplication[p.applicationId]
                if (!comments[p.resourceKey])
                    comments[p.resourceKey] = {
                        fields: [],
                        selfLink: undefined,
                        loadingStatus: 'not-started'
                    }
                if (!comments[p.resourceKey].fields) comments[p.resourceKey].fields = []
                if (!comments[p.resourceKey].fields[p.subsectionIndex])
                    comments[p.resourceKey].fields[p.subsectionIndex] = {}

                if (!comments[p.resourceKey].fields[p.subsectionIndex][fileLabel])
                    comments[p.resourceKey].fields[p.subsectionIndex][fileLabel] = {
                        comments: [],
                        commentsLink: f.commentsLink
                    }
            })

            if (draft.data.forApplication?.[p.applicationId]?.[p.resourceKey]?.fields?.[p.subsectionIndex])
                draft.data.forApplication[p.applicationId][p.resourceKey].fields[p.subsectionIndex].filesLoadingStatus =
                    'done'

            return draft
        })
    ),
    handleAction(ApplicationResourceActions.UNLINK_FILES, (state, { payload: p }) =>
        produce(state, (draft) => {
            const resourcePath = `data.forApplication.${p.applicationId}.${p.resourceKey}.fields.${p.subsectionIndex}`
            const fileIdsToUnlink: any = p.files.map((f: File) => f.id)

            p.files.forEach((f: File) => {
                const fileCommentsPath = `comments.forApplication.${p.applicationId}.${p.resourceKey}.fields.${
                    p.subsectionIndex
                }.${camelCase(f.label)}`
                dotProp.delete(draft, fileCommentsPath)
            })

            if (fileIdsToUnlink)
                p.files.forEach((f: File) => {
                    const fileIds: any = dotProp.get(draft, `${resourcePath}.${camelCase(f.label)}`)
                    if (fileIds) {
                        dotProp.set(
                            draft,
                            `${resourcePath}.${camelCase(f.label)}`,
                            fileIds.filter((fileId: string) => fileId !== f.id)
                        )
                    }
                })
        })
    ),
    handleAction(ApplicationResourceActions.STORE_FIELDS_HISTORY, (state, { payload: p }) =>
        produce(state, (draft: any) => {
            const cutterFieldsInfo = p.updates
            const sectionsWithHistory: string[] = []
            Object.keys(cutterFieldsInfo).forEach((indexPathString) => {
                const resourceKey = indexPathString.split('.')[0]
                sectionsWithHistory.push(resourceKey)
                dotProp.set(
                    draft,
                    `history.forApplication.${p.applicationId}.${indexPathString}`,
                    cutterFieldsInfo[indexPathString]
                )
                dotProp.set(draft, `history.forApplication.${p.applicationId}.${resourceKey}.loadingStatus`, 'done')
            })

            const sectionsWithoutFeedback = difference(AllMerchantApplicationSections, sectionsWithHistory)

            sectionsWithoutFeedback.forEach((key) => {
                dotProp.set(draft, `history.forApplication.${p.applicationId}.${key}.loadingStatus`, 'done')
            })
        })
    ),
    handleAction(ApplicationResourceActions.STORE_FIELD_HISTORY, (state, { payload: p }) =>
        produce(state, (draft) => {
            const historyPath = `history.forApplication.${p.applicationId}.${p.indexPath.resourceKey}.fields.${
                p.indexPath.subsectionIndex || 0
            }.${p.indexPath.fieldKey}`
            const fieldPath = `data.forApplication.${p.applicationId}.${p.indexPath.resourceKey}.fields.${
                p.indexPath.subsectionIndex || 0
            }.${p.indexPath.fieldKey}`
            const array = (dotProp.get(draft, historyPath) as any[]) || []

            dotProp.set(draft, historyPath, [...array, p.event])
            dotProp.set(draft, fieldPath, p.event.to)
        })
    ),
    handleAction(ApplicationResourceActions.STORE_SUBSECTION, (state, { payload: p }) =>
        produce(state, (draft) => {
            const subsectionsCount =
                dotProp.get<any[]>(draft, `data.forApplication.${p.applicationId}.${p.resourceKey}.fields`)?.length || 0
            dotProp.set(
                draft,
                `data.forApplication.${p.applicationId}.${p.resourceKey}.fields.${subsectionsCount}`,
                p.links
            )
        })
    ),
    handleAction(ApplicationResourceActions.UNSTORE_SUBSECTION, (state, { payload: p }) =>
        produce(state, (draft) => {
            ;['data', 'comments', 'history'].forEach((resourceType) => {
                const subsections =
                    dotProp.get<any>(
                        draft,
                        `${resourceType}.forApplication.${p.applicationId}.${p.indexPath.resourceKey}.fields`
                    ) || {}
                const newArray = Object.keys(subsections)
                    .map((k) => subsections[k])
                    .filter((f, i) => {
                        if (i === p.indexPath.subsectionIndex) return false
                        return true
                    })

                dotProp.set(
                    draft,
                    `${resourceType}.forApplication.${p.applicationId}.${p.indexPath.resourceKey}.fields`,
                    newArray
                )
            })

            if (['websites', 'people'].includes(p.indexPath.resourceKey)) {
                draft.data.forApplication[p.applicationId][p.indexPath.resourceKey + 'History'].fields.push({
                    ...state.data.forApplication[p.applicationId][p.indexPath.resourceKey].fields[
                        p.indexPath.subsectionIndex as number
                    ],
                    metadata: {
                        deleted: true
                    }
                })
            }
        })
    )
])
