import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { LoaderView } from '../../../components/loaders/loader'
import { PageContent } from '../../../components/layout/pageContent'

import { useListPageMerchantFilter } from '../../../hooks/pages/useListPageMerchantFilter'
import { RootState } from '@/store'
import { Tasks } from '../../Tasks/Tasks'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantTasks: React.FC = () => {
    const params = useParams() as Params
    const merchantId = params.id
    const accounts = useSelector((state: RootState) => state.merchantAccounts.at)
    const textForItem = useCallback(
        (key: string) => {
            return accounts?.[key]?.merchantId
        },
        [accounts]
    )

    const [ids, idsLoadingFinished] = useListPageMerchantFilter('tasks_subject_id', merchantId, true)

    if (!idsLoadingFinished) return <LoaderView overBackground="back.background" />

    return (
        <PageContent className="POPPER_PARENT" noVerticalPadding>
            <Tasks inline merchantId={merchantId} forIds={ids as any} textForItem={textForItem} />
        </PageContent>
    )
}
