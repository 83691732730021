import { ReactElement } from 'react'
import styled, { css } from 'styled-components'

export const ListPageFiltersSeparator: React.FC<{ isDark: boolean }> = ({ isDark }): ReactElement => {
    return <SeparatorHolder isDark={isDark}>{/* <Separator background="overlay.background" /> */}</SeparatorHolder>
}

const SeparatorHolder = styled.div<{ isDark?: boolean }>`
    display: flex;
    align-items: center;
    height: 10px;

    ${(p) =>
        p.isDark &&
        css`
            display: none;
        `}
`
