import { Url } from '@/services/common'
import { api } from '../api'
import { Company } from '../types/company'

export const companyApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCompany: build.query<Company, Url>({
            query: (url) => {
                return { url }
            }
        })
    })
})

export const { useGetCompanyQuery: useGetCompany } = companyApi
