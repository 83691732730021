import { useMemo } from 'react'
import { Person } from '../../../store/applications/types'
import { BatchOfMonitoredChanges } from '../../../store/tasksCompanyChanges/types'
import { usePersonInfoChecks } from './Tasks.CompanyChange.usePersonInfoChecks'

export const getMatchingPersonApplicationIndex = (applicationData: any, subsectionId?: string, name?: string) => {
    const notFound = -1
    if (!applicationData || !applicationData.application.metadata.state) {
        return notFound
    }

    const index = applicationData.people.findIndex((p: Person) => p.name == name)
    if (index < 0) {
        return notFound
    }

    return index
}

export const useApplicationPersonAndChecks = (
    applicationData: BatchOfMonitoredChanges['fetchedApplicationData']['data'] | undefined,
    name?: string,
    subsectionId?: string
) => {
    const { person, personIndex, personFiles } = useMemo(() => {
        const notFound = {
            person: undefined,
            personIndex: undefined,
            personFiles: undefined
        }

        if (!applicationData || !applicationData.application.metadata.state) {
            return notFound
        }

        const index = getMatchingPersonApplicationIndex(applicationData, subsectionId, name)
        if (index < 0) {
            return notFound
        }

        const person = applicationData.people[index]
        const personFiles = applicationData.filesByPersonId[person.id]

        return { person, personIndex: index, personFiles }
    }, [applicationData, subsectionId, name])

    const { allInfoPresent, missingInfo, requiredInfo } = usePersonInfoChecks(person, personFiles)

    return {
        person,
        personIndex,
        personFiles,
        allInfoPresent,
        missingInfo,
        requiredInfo
    }
}
