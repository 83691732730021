import hotkeys from 'hotkeys-js'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { ClueDirection, HotkeyClue } from '../../components/hotkeys/hotkeyClue'
import { MODAL_ID } from '../../components/modals/modalIds'
import { RootState } from '@/store'
import { isMacOS } from '../../utils'

export const shouldAltHotkeyBeStopped = (e: any) => {
    if (['™', '@'].includes(e.key)) return false
    return true
}

export const useHotkey = ({
    keys: k,
    action,
    scope: passedScope,
    clue = 'bottom-right'
}: {
    keys?: string
    action?: (keyboardEvent: KeyboardEvent) => void
    scope?: MODAL_ID
    clue?: ClueDirection
}): ReactElement | undefined => {
    const keys = useMemo(() => {
        if (isMacOS) return k?.replace('alt', 'ctrl')
        return k
    }, [k])
    const previousAction = useRef<any>()
    const actionWrapper = useCallback(
        (e: any) => {
            e.preventDefault()
            action?.(e)
        },
        [action]
    )
    const activeScope = useSelector((s: RootState) => s.interface.modals.frontmost)

    const scope = useMemo(() => {
        if (passedScope === '-ANY-') return activeScope
        return passedScope
    }, [passedScope, activeScope])

    useEffect(() => {
        if (scope !== activeScope) return
        if (scope && keys && actionWrapper) {
            if (previousAction.current && previousAction.current !== actionWrapper)
                hotkeys.unbind(keys, scope, previousAction.current)

            hotkeys(keys, scope, actionWrapper)
            previousAction.current = actionWrapper
        }
    }, [actionWrapper, activeScope, keys, scope])

    const prepareTooltip = useCallback((keys) => {
        let keysWithNodes = <></>
        const keysArray = keys.split('ALT')
        keysWithNodes = keysArray.map((k: any) => {
            return !k ? null : (
                <React.Fragment key={k}>
                    {/* <Icon type="alt" size={10} weight={1.33} />
                    <Spacer width={3} /> */}
                    {k}
                </React.Fragment>
            )
        })

        return keysWithNodes
    }, [])

    const renderedClue = useMemo(() => {
        if (scope && keys) {
            if (scope !== activeScope) return undefined
            const keysLabel = keys.replace('+', ' ').toUpperCase().split(',')[0]
            return (
                <HotkeyClue hotkeyScope={scope} clueDirection={clue} key="clue">
                    {prepareTooltip(keysLabel)}
                </HotkeyClue>
            )
        }
        return undefined
    }, [scope, keys, prepareTooltip, clue, activeScope])

    useEffect(() => {
        return () => {
            if (scope && keys) {
                hotkeys.unbind(keys, scope, previousAction.current)
            }
        }
    }, [keys, scope])

    return renderedClue
}
