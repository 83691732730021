import { AgentBubble, Date, State } from '../../common'
import { TableRow } from '@/components/tables/table'
import { Tasks } from '@/services/taskr'
import { ApplicationTaskAction } from './application-task-action'

export const getApplicationTasksRows = (data?: Tasks) => {
    if (!data || !data.embedded.tasks) {
        return []
    }

    const rows: TableRow[] = data.embedded.tasks.map((task) => {
        const { id: key, due_at, state, assignee, relations } = task

        const isClosed = state === 'closed'

        const Action = relations.self ? (
            <ApplicationTaskAction url={relations.self} action={isClosed ? 'open' : 'close'} />
        ) : (
            '-'
        )

        return {
            type: 'normal' as const,
            key,
            noAction: true,
            items: [
                {
                    node: <Date>{due_at}</Date>
                },
                {
                    node: <AgentBubble agentSub={assignee} />
                },
                {
                    node: <State>{state}</State>
                },
                {
                    node: Action
                }
            ]
        }
    })

    return rows
}
