import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { List } from '../../../components/layout/list'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { RootState } from '@/store'
import { Flex } from '../../../components/layout/flex'
import { Flag } from '../../../components/icons/flag'
import { useGateways } from '../../../hooks/general/useGateways'
import { MerchantSummaryCompanyInformationWebsites } from './SummaryWebsites'
import { MerchantSummaryCompanyInformationBusinessModel } from './SummaryCompanyInformation.businessModel'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { LoaderView } from '../../../components/loaders/loader'
import { MerchantSummaryWarnings } from './SummaryWarnings'
import { LoadingSpinner } from '../../../components/loaders/spinner'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { useCopyToClipboard } from '../../../hooks/general/useCopyToClipboard'
import { Icon } from '../../../components/icons/icon'
import countries from 'i18n-iso-countries'
import { PartnerGuidelinesTooltip } from '@/features/partner-guidelines'

export const MerchantSummaryCompanyInformation: React.FC<{
    merchantId: string
}> = ({ merchantId }) => {
    const dispatch = useDispatch()
    const applicationSections = useSelector(
        (state: RootState) => state.applicationResources.data.forApplication[merchantId]
    )
    const allWarningsChecked = useSelector(
        (state: RootState) => state.applicationInternals.warnings?.forApplication?.[merchantId]?.isReady?.all
    )

    const gateways = useGateways()

    const state = useMemo(() => {
        if (
            applicationSections?.company?.loadingStatus !== 'done' ||
            applicationSections?.contact?.loadingStatus !== 'done' ||
            applicationSections?.websites?.loadingStatus !== 'done' ||
            applicationSections?.gateway?.loadingStatus !== 'done' ||
            applicationSections?.people?.loadingStatus !== 'done' ||
            gateways.loadingStatus !== 'done'
        )
            return {
                isLoading: true
            }

        const gatewayId = applicationSections.gateway?.fields?.[0]?.gateways?.[0]?.id

        const companyStructure = (() => {
            const directorsAndOwners: string[] = []
            const directors: string[] = []
            const owners: string[] = []
            applicationSections.people?.fields?.forEach((person) => {
                if (!person?.name) return
                if (person.role === 'director-and-owner') {
                    directorsAndOwners.push(person.name)
                    return
                }
                if (person.role === 'director') {
                    directors.push(person.name)
                    return
                }
                if (person.role === 'owner') {
                    owners.push(person.name)
                    return
                }
            })

            return (
                <>
                    {directorsAndOwners.length ? (
                        <Flex column>
                            <Text oneLine color="front.text.subtlerI">
                                {directorsAndOwners.length == 1 ? 'Director & Owner' : 'Directors & Owners'}
                            </Text>
                            {directorsAndOwners.map((dao) => (
                                <Text oneLine>-&nbsp;&nbsp;{dao}</Text>
                            ))}
                            <Spacer height={15} />
                        </Flex>
                    ) : null}
                    {directors.length ? (
                        <Flex column>
                            <Text oneLine color="front.text.subtlerI">
                                {directors.length > 1 ? 'Directors' : 'Director'}
                            </Text>
                            {directors.map((d) => (
                                <Text oneLine>-&nbsp;&nbsp;{d}</Text>
                            ))}

                            <Spacer height={15} />
                        </Flex>
                    ) : null}
                    {owners.length ? (
                        <Flex column>
                            <Text oneLine color="front.text.subtlerI">
                                {owners.length > 1 ? 'Owners' : 'Owner'}
                            </Text>
                            {owners.map((o) => (
                                <Text oneLine>-&nbsp;&nbsp;{o}</Text>
                            ))}
                            <Spacer height={15} />
                        </Flex>
                    ) : null}
                </>
            )
        })()

        return {
            isLoading: false,
            company: applicationSections.company,
            businessModel: applicationSections.businessModel,
            contact: applicationSections.contact,
            gateway: gateways.at?.[gatewayId]?.name,
            gatewayId,
            companyStructure
        }
    }, [applicationSections, gateways])

    const company = state.company?.fields[0]
    const copy = useCopyToClipboard()

    return (
        <Card>
            <CardInset>
                {state.isLoading ? (
                    <TextLineLoader />
                ) : (
                    <Flex justify="space-between">
                        <Text size="xl" bold>
                            {company?.name || 'Unnamed merchant'}
                        </Text>
                        {allWarningsChecked ? null : (
                            <Flex align="center">
                                <LoadingSpinner type="s" overBackground="front.background" />
                                <Spacer width={5} />
                                <Text>Checking for warnings</Text>
                            </Flex>
                        )}
                    </Flex>
                )}
                {state.isLoading ? (
                    <LoaderView overBackground="front.background" minHeight="291px" />
                ) : (
                    <Flex column>
                        <Spacer height={22} />
                        <List
                            isLoading={state.isLoading}
                            background="front.background"
                            rowGap="medium"
                            items={{
                                'Country': company?.country
                                    ? {
                                          type: 'custom',
                                          node: (
                                              <Flex column>
                                                  <Flex fullWidth>
                                                      <Flag code={company?.country} />
                                                      <Spacer width={5} />
                                                      {countries.getName(company?.country?.toUpperCase(), 'en')}
                                                  </Flex>
                                              </Flex>
                                          )
                                      }
                                    : 'No country',
                                'Business model': {
                                    type: 'custom',
                                    node: (
                                        <Flex column fullWidth>
                                            <MerchantSummaryCompanyInformationBusinessModel
                                                text={state.businessModel?.fields[0]?.businessModel?.description}
                                            />
                                            <Spacer height={15} />
                                            <Flex column>
                                                <Text oneLine color="front.text.subtlerI">
                                                    Business contact details
                                                </Text>
                                                <Text oneLine>
                                                    {company?.phone || company?.email
                                                        ? [company?.phone, company?.email].filter((f) => f).join(' · ')
                                                        : 'None provided'}
                                                </Text>
                                            </Flex>

                                            <Spacer height={15} />
                                            <Flex column>
                                                <Flex align="center">
                                                    <Text oneLine color="front.text.subtlerI">
                                                        Gateway
                                                    </Text>
                                                    <Spacer width={2} />
                                                    <Icon type="gateway" color="front.text.subtlerI" size={10} />
                                                </Flex>
                                                <Flex align="center">
                                                    <Text>{state.gateway || 'No gateway selected'}</Text>
                                                    <PartnerGuidelinesTooltip gatewayIds={[state.gatewayId]} />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    )
                                },
                                'Address': {
                                    type: 'custom',
                                    rightSide: (
                                        <Flex grow>
                                            <Spacer width={7} />
                                            <SimpleButton
                                                onClick={() =>
                                                    copy(
                                                        company?.addressLine1 +
                                                            ' ' +
                                                            company?.addressLine2 +
                                                            ' ' +
                                                            company?.city +
                                                            ' ' +
                                                            company?.zipcode +
                                                            ' ' +
                                                            countries.getName(company?.country?.toUpperCase(), 'en') ||
                                                            ''
                                                    )
                                                }
                                            >
                                                <Text size="s">Copy address</Text>
                                            </SimpleButton>
                                        </Flex>
                                    ),
                                    node: (
                                        <Flex column>
                                            <Flex></Flex>
                                            <Address>
                                                {!company?.addressLine1 &&
                                                !company?.addressLine2 &&
                                                !company?.city &&
                                                !company?.zipcode ? (
                                                    <Text oneLine>No address provided</Text>
                                                ) : null}
                                                {company?.addressLine1 ? (
                                                    <>
                                                        <Text oneLine>{company?.addressLine1}</Text>
                                                        {',\n'}
                                                    </>
                                                ) : null}
                                                {company?.addressLine2 ? (
                                                    <>
                                                        <Text oneLine>{company?.addressLine2}</Text>
                                                        {',\n'}
                                                    </>
                                                ) : null}
                                                {company?.city ? company?.city + ', ' : null}
                                                {company?.zipcode ? company?.zipcode + '\n' : null}
                                            </Address>
                                            <Spacer height={15} />
                                            <Flex column>
                                                <Text oneLine color="front.text.subtlerI">
                                                    Contact person
                                                </Text>
                                                {state.contact?.fields[0]?.name || state.contact?.fields[0]?.email
                                                    ? [state.contact?.fields[0]?.name, state.contact?.fields[0]?.email]
                                                          .filter((f) => f)
                                                          .join(' · ')
                                                    : 'None provided'}
                                            </Flex>
                                        </Flex>
                                    )
                                },
                                '': {
                                    type: 'custom',
                                    node: <Flex column>{state.companyStructure}</Flex>
                                }
                            }}
                            switchToRowsAt={600}
                            template="90px 1fr 1fr 1fr"
                        />
                        <WebsiteHolder>
                            <MerchantSummaryCompanyInformationWebsites
                                websites={applicationSections?.websites?.fields as any}
                            />
                            <MerchantSummaryWarnings merchantId={merchantId} />
                        </WebsiteHolder>
                    </Flex>
                )}
            </CardInset>
        </Card>
    )
}

const WebsiteHolder = styled.div`
    padding-left: 120px;
    margin-top: 20px;
    border-top: 1px solid ${(p) => p.theme['front.border']};
`

const Address = styled.div`
    white-space: pre-line;
`
