import { useSelector } from 'react-redux'
import { Params, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CardInset } from '../../../components/cards/cardInset'
import { useMemo, useState } from 'react'
import { List } from '../../../components/layout/list'
import { Separator } from '../../../components/layout/separator'
import { Spacer } from '../../../components/layout/spacer'
import moment from 'moment'
import { DateFormats } from '../../../utils/dateUtils'
import * as YAML from 'js-yaml'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { ToggleBar } from '../../../components/forms/toggleBar'
import { Flag } from '../../../components/icons/flag'
import { SchemesIcon } from '../../../components/icons/schemesIcon'
import { Offset } from '../../../components/layout/offset'
import { useGoBackHook } from '../../../hooks/general/useGoBackHook'
import { ApplicationSchemesScreeningPanelMatchBlock } from './Application.SchemesScreeningPanel.MatchBlock'
import { LoaderView } from '../../../components/loaders/loader'
import { PastInquiry, Result } from '../../../store/applicationInternals/schemesScreening/types'
import { RootState } from '@/store'
import { SchemesScreeningCountry2LetterCode, SchemesScreeningCountryName } from './Application.SchemesScreeningCountry'
import { ApplicationSchemesScreeningPastInquiriesBody } from './Application.SchemesScreeningPastInquiriesBody'

export const ApplicationSchemesScreeningModalResultDetails: React.FC<any> = ({
    latestInquiry,
    result,
    pastInquiry
}) => {
    const params: any = useParams() as Params
    const id = params.id

    const [show, setShow] = useState('response')

    const localState = useMemo(() => {
        if (result) {
            return {
                isResult: true as const,
                entity: result as Result
            }
        } else {
            return {
                isResult: false as const,
                entity: pastInquiry as PastInquiry
            }
        }
    }, [result, pastInquiry])

    const loadingStatus = useSelector((state: RootState) => {
        return state.applicationInternals.schemesScreening?.forApplication?.[id]?.loadingStatus
    })

    if (loadingStatus == 'started') return <LoaderView minHeight="500px" overBackground="front.background" />

    if (loadingStatus == 'done') {
        if (pastInquiry) {
            return (
                <>
                    <CardInset>
                        <List
                            background="front.background"
                            items={{
                                'Scheme': {
                                    type: 'custom',
                                    node: <SchemesIcon scheme="mastercard" />
                                },
                                'Company Country': {
                                    type: 'custom',
                                    node: pastInquiry?.company?.country ? (
                                        <Flex>
                                            <Flag
                                                code={SchemesScreeningCountry2LetterCode(
                                                    'MATCH',
                                                    localState.entity.company?.country
                                                )}
                                            />
                                            <Spacer width={5} />
                                            <Text oneLine>
                                                {SchemesScreeningCountryName('MATCH', pastInquiry.company?.country)}
                                            </Text>
                                        </Flex>
                                    ) : (
                                        '-'
                                    )
                                },
                                'Company Name': pastInquiry?.company?.name,
                                'Reg. ID': pastInquiry?.company?.registrationId || '-'
                            }}
                            switchToRowsAt={60}
                            template="1fr 1fr 1fr 2fr"
                        />
                        <Spacer height={20} />
                        <Separator />
                        <Spacer height={15} />
                        <ApplicationSchemesScreeningPastInquiriesBody pastInquiry={pastInquiry} />
                    </CardInset>
                </>
            )
        }

        if (localState.entity) {
            return (
                <CardInset>
                    <List
                        background="front.background"
                        items={{
                            'Company Country': {
                                type: 'custom',
                                node: localState.entity?.company?.country ? (
                                    <Flex>
                                        <Flag
                                            code={SchemesScreeningCountry2LetterCode(
                                                localState.entity.type || 'MATCH',
                                                localState.entity.company?.country
                                            )}
                                        />
                                        <Spacer width={5} />
                                        <Text oneLine>
                                            {SchemesScreeningCountryName(
                                                localState.entity.type || 'MATCH',
                                                localState.entity.company?.country
                                            )}
                                        </Text>
                                    </Flex>
                                ) : (
                                    '-'
                                )
                            },
                            'Company Name': localState.entity?.company?.name,
                            'Reg. ID': localState.entity?.company?.registrationId || '-'
                        }}
                        switchToRowsAt={60}
                        template="1fr 1fr 2fr"
                    />
                    <Spacer height={15} />
                    <List
                        background="front.background"
                        items={{
                            Scheme: {
                                type: 'custom',
                                node: (
                                    <Flex align="center">
                                        <Flex column>
                                            <Spacer height={3} />
                                            {localState.entity?.type == 'MATCH' || !localState.entity?.type ? (
                                                <SchemesIcon scheme="mastercard" />
                                            ) : null}
                                            {localState.entity?.type == 'VMSS' ? <SchemesIcon scheme="visa" /> : null}
                                        </Flex>
                                        <Spacer width={5} />
                                        <Offset top={1.5}>
                                            <Text>{localState.entity?.type || 'MATCH'}</Text>
                                        </Offset>
                                    </Flex>
                                )
                            },
                            ...(localState.isResult
                                ? {
                                      Score: `${localState.entity.matchingScore} ${
                                          localState.entity.matchingScore == 1 ? 'field' : 'fields'
                                      }`,
                                      Since: moment(localState.entity?.termination.madeAt).format(
                                          DateFormats.day(moment(localState.entity?.termination.madeAt))
                                      )
                                  }
                                : {})
                        }}
                        switchToRowsAt={60}
                        template="1fr 1fr 2fr"
                    />
                    <Spacer height={20} />
                    <Separator background="back.background" />
                    <Spacer height={20} />
                    <List
                        background="front.background"
                        items={
                            localState.isResult
                                ? {
                                      'Termination AID': localState.entity?.termination.acquirerId,
                                      'Acq. Name': localState.entity?.termination.acquirerName || '-',
                                      'Acq. Country': localState.entity?.termination.acquirerCountry || '-'
                                  }
                                : {
                                      'Termination AID': localState.entity?.acquirer.acquirerId,
                                      'Acq. Name': localState.entity?.acquirer.name || '-',
                                      'Acq. Country': localState.entity?.acquirer.country || '-'
                                  }
                        }
                        switchToRowsAt={60}
                        template="1fr 1fr 1fr 1fr"
                    />
                    {localState.isResult ? (
                        <>
                            <Spacer height={15} />
                            <List
                                background="front.background"
                                items={{
                                    'Main RC': localState.entity?.termination.mainReasonCode
                                        ? {
                                              type: 'custom',
                                              node: (
                                                  <Text bold color="front.danger.color">
                                                      {'#' + result?.termination.mainReasonCode}
                                                  </Text>
                                              )
                                          }
                                        : '-',
                                    'Main Reason': result?.termination.mainReasonDescription
                                        ? {
                                              type: 'custom',
                                              node: (
                                                  <Text bold color="front.danger.color">
                                                      {result?.termination.mainReasonDescription}
                                                  </Text>
                                              )
                                          }
                                        : '-',
                                    'Second RC': result?.termination.secondaryReasonCode
                                        ? {
                                              type: 'custom',
                                              node: (
                                                  <Text bold color="front.danger.color">
                                                      {'#' + result?.termination.secondaryReasonCode}
                                                  </Text>
                                              )
                                          }
                                        : '-',
                                    'Second Reason': result?.termination.secondaryReasonDescription
                                        ? {
                                              type: 'custom',
                                              node: (
                                                  <Text bold color="front.danger.color">
                                                      {'#' + result?.termination.secondaryReasonDescription}
                                                  </Text>
                                              )
                                          }
                                        : '-'
                                }}
                                switchToRowsAt={60}
                                template="1fr 1fr 1fr 1fr"
                            />{' '}
                            <Spacer height={15} />
                            <List
                                background="front.background"
                                items={{
                                    Matches: {
                                        type: 'custom',
                                        node: (
                                            <Offset left={-2} top={2}>
                                                <Flex>
                                                    {localState.entity.matchingFields.map((f) => {
                                                        return (
                                                            <ApplicationSchemesScreeningPanelMatchBlock
                                                                key={localState.entity.resultId}
                                                                type={localState.entity.type}
                                                                expanded
                                                                f={f}
                                                            />
                                                        )
                                                    })}
                                                </Flex>
                                            </Offset>
                                        )
                                    }
                                }}
                                switchToRowsAt={60}
                                template="auto"
                            />
                        </>
                    ) : null}
                    <Spacer height={40} />
                    <ToggleBar
                        options={{
                            request: 'API Request',
                            response: 'API Response'
                        }}
                        activeKey={show}
                        onClick={(r) => {
                            setShow(r.toLowerCase())
                        }}
                    />
                    <Spacer height={20} />
                    <Separator background="back.background" />
                    <Spacer height={20} />
                    {show == 'request' ? (
                        <Flex>
                            {localState.entity?.type === 'MATCH' || !localState.entity?.type ? (
                                <Flex column grow>
                                    <Text color="front.text" bold>
                                        {localState.entity?.type || 'MATCH'} Request
                                    </Text>
                                    <Spacer width={10} />
                                    <Context>
                                        <pre>{YAML.dump(latestInquiry?.queryData.match)}</pre>
                                    </Context>
                                </Flex>
                            ) : (
                                <Flex column grow>
                                    <Text color="front.text" bold>
                                        {localState.entity?.type} Request
                                    </Text>
                                    <Spacer width={10} />
                                    <Context>
                                        <pre>{YAML.dump(latestInquiry?.queryData.vmss)}</pre>
                                    </Context>
                                </Flex>
                            )}
                        </Flex>
                    ) : null}
                    {show == 'response' ? (
                        <Flex>
                            {localState.isResult ? (
                                <>
                                    {(localState.entity?.raw as any)?.info ? (
                                        <Flex column grow>
                                            <Text color="front.text" bold>
                                                {localState.entity.type} Response
                                            </Text>
                                            <Spacer width={10} />
                                            <Context>
                                                <pre>{YAML.dump((localState.entity.raw as any).info)}</pre>
                                            </Context>
                                        </Flex>
                                    ) : null}
                                    <Spacer width={20} />
                                    {(localState.entity?.raw as any).match ? (
                                        <Flex column grow>
                                            <Text color="front.text" bold>
                                                {localState.entity.type} Fields Matching
                                            </Text>
                                            <Spacer width={10} />
                                            <Context>
                                                <pre>{YAML.dump((localState.entity.raw as any).match)}</pre>
                                            </Context>
                                        </Flex>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    {localState.entity?.raw ? (
                                        <Flex column grow>
                                            <Text bold>{localState.entity.type || 'MATCH'} Response</Text>
                                            <Spacer width={10} />
                                            <Context>
                                                <pre>{YAML.dump(localState.entity.raw)}</pre>
                                            </Context>
                                        </Flex>
                                    ) : null}
                                </>
                            )}
                        </Flex>
                    ) : null}
                    <Spacer height={20} />
                </CardInset>
            )
        } else {
            return <Text color="front.text">Result not found</Text>
        }
    }
    return <Text>Failed to connect to the screening service</Text>
}

const Context = styled.div<{ pre?: boolean }>`
    margin-bottom: 0px;
    margin-top: 0px;
    background-color: ${(p) => p.theme['back.background']};
    color: ${(p) => p.theme['front.text']};
    padding: 15px;
    border-radius: 10px;
    display: pre;
    white-space: pre;
`
