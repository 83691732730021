import { Box, Card, Flex, Text } from '@/design-system'
import { numberWithCurrency } from '@/utils'
import React from 'react'
import { useTheme } from 'styled-components'

interface BalanceCardProps {
    balance?: number
    currency: string
}

const BalanceCard: React.FC<BalanceCardProps> = (props) => {
    const { balance, currency } = props

    const theme = useTheme()
    const isDark = theme.name === 'darkTheme'

    const cardColor = balance === 0 ? 'success' : 'danger'
    const color = balance === 0 ? (isDark ? 'white' : 'success') : isDark ? 'white' : 'danger'
    const textShade = balance === 0 ? (isDark ? '100' : '600') : isDark ? '100' : '900'

    if (balance === undefined || balance === null) return null

    return (
        <Card variant="surface" color={cardColor} size="sm">
            <Box px="4" py="1.5">
                <Flex gap="1.5">
                    <Text color={color} shade={textShade}>
                        Balance:
                    </Text>
                    <Text color={color} shade={textShade} weight="medium">
                        {`${numberWithCurrency(currency, balance)} ${currency}`}
                    </Text>
                </Flex>
            </Box>
        </Card>
    )
}

export { BalanceCard }
