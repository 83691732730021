import hotkeys from 'hotkeys-js'
import { BoundKey } from '../components/complexComponents/hotkeysObserver'

hotkeys.filter = (event) => {
    if (!event) return false
    const target = (event.target || event.srcElement)! as HTMLElement
    const { tagName } = target
    const classes = target.className
    if (tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA') {
        if (event.key === 'Escape') {
            if (!classes.includes('HOTKEYS_ESC_FORCES_EXIT')) return false
            return true
        }
        // if arrow keys and enter, let it go
        if ([37, 38, 39, 40, 13].includes(event.keyCode)) {
            if (event.keyCode === 13 && (event.ctrlKey || event.altKey)) {
                event.preventDefault()
            }
            return true
        }
        if (event.keyCode === BoundKey) return true
        return false
    }
    return true
}
