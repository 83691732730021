import { getType } from 'deox'
import { camelCase } from 'lodash'
import moment from 'moment'
import { take } from 'typed-redux-saga'

import { HistoryChange } from './types'

export function* deoxWaitFor(deoxAction: any, until: (payload: any) => boolean) {
    let action: any
    do {
        action = yield* take(getType(deoxAction))
    } while (!until(action?.payload))
    return action?.payload
}

export function camelCaseWithDots(s: string) {
    return s
        .split('.')
        .map((sub) => camelCase(sub))
        .join('.')
}

export function GetPerSectionChangesHistory(cleanedRequest: any): any {
    const redundantFieldKeys = ['manager', 'clonableStates', 'userIds']

    if (typeof cleanedRequest === 'object') {
        const newObject: any = {}

        Object.keys(cleanedRequest).forEach((key) => {
            if (key === 'versions') {
                newObject[key] = cleanedRequest[key]
            } else if (redundantFieldKeys.includes(key)) {
            } else if (Array.isArray(cleanedRequest[key])) {
                newObject[key] = cleanedRequest[key].map((k: any) => {
                    return GetPerSectionChangesHistory(k)
                })
            } else if (typeof cleanedRequest[key] === 'object') {
                newObject[key] = GetPerSectionChangesHistory(cleanedRequest[key])
            }
        })
        return newObject
    }
    return cleanedRequest
}

// const fixPath = (path: string, fieldKey: string) => {
//     if (path) return `${path}.${fieldKey}`
//     switch (fieldKey) {
//         case 'signerEmail':
//             return `signer.signer.email`
//         case 'signerName':
//             return `signer.signer.name`
//         case 'gatewayId':
//             return 'gateway.id'
//         default: {
//         }
//     }

//     if (
//         [
//             'tradingName',
//             'deliveryPhysical',
//             'deliveryDelay',
//             'dropShipping',
//             'estimateAverageTransactionAmount',
//             'estimateMonthlyTurnoverCurrency',
//             'estimateMonthlyTurnover',
//             'recurring',
//             'businessModel'
//         ].includes(fieldKey)
//     )
//         return `businessModel.${fieldKey}`
// }

export function ConvertPerSectionChangesToFieldPathChanges(body: any, acc: any = {}, path: any = '') {
    if (typeof body === 'object') {
        Object.keys(body).forEach((key) => {
            if (key === 'versions') {
                body[key].forEach((version: any) => {
                    if (version.objectChanges)
                        Object.keys(version.objectChanges).forEach((fieldKey) => {
                            const fieldChangesPath = path ? `${path}.${fieldKey}` : `${fieldKey}`
                            if (!acc[fieldChangesPath]) acc[fieldChangesPath] = []
                            acc[fieldChangesPath].push({
                                by: version?.author,
                                at: version?.createdAt,
                                id: version?.id,
                                from: version?.objectChanges?.[fieldKey]?.[0],
                                to: version?.objectChanges?.[fieldKey]?.[1]
                            })
                        })
                })
            } else if (typeof body[key] === 'object') {
                ConvertPerSectionChangesToFieldPathChanges(body[key], acc, (path ? `${path}.` : '') + key)
            }
        })
    }
    return acc
}

export function DedupChangeHistoryAndSortIt(body: any) {
    // and sort
    const fieldKeys = Object.keys(body)
    fieldKeys.forEach((fieldKey: string, i: number) => {
        const fieldHistory = body[fieldKey] as HistoryChange[]
        fieldHistory.sort((a, b) => {
            if (moment(a.at).isBefore(moment(b.at))) return -1
            return 0
        })
        body[fieldKey] = fieldHistory.filter((historyChange: HistoryChange, i: number): boolean => {
            if (i === 0) return true
            const prevChange = fieldHistory[i - 1]
            const currentChange = fieldHistory[i]
            if (
                moment(prevChange.at).isSame(moment(currentChange.at), 'minute') &&
                prevChange.from === currentChange.from &&
                prevChange.to === currentChange.to &&
                prevChange.by === currentChange.by
            )
                return false
            return true
        })
    })
}

export function ParseCommentsInfoFromCutterRequest(body: any, acc: any = {}) {
    if (typeof body === 'object') {
        Object.keys(body).forEach((key) => {
            if (key === 'comments') {
                body[key].forEach((comment: any) => {
                    acc[comment.id] = {
                        id: comment.id,
                        readAt: comment.readAt,
                        cutterSelfLink: comment.selfLink,
                        private: comment.private,
                        field: comment.field
                    }
                })
            } else if (typeof body[key] === 'object') {
                ParseCommentsInfoFromCutterRequest(body[key], acc)
            }
        })
    }
    return acc
}
