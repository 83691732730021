import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { useOnClickWithoutMouseMove } from '../../hooks/general/useOnClickWithoutMouseMove'
import { Icon } from '../icons/icon'
import { Card, CardHeader } from './card'
import { CardInset } from './cardInset'
import { ExpandableCardContent } from './expandableCardContent'
import { Flex } from '../layout/flex'

interface ExpandableCardProps {
    children: any
    isInitiallyExpanded?: boolean
    headerMemo: any
    insetSize?: React.ComponentProps<typeof CardInset>['type']
    onExpand?: () => void
}

export const ExpandableCard: React.FC<ExpandableCardProps> = (props) => {
    const { children, isInitiallyExpanded, headerMemo, insetSize = 'small', onExpand } = props

    const [isOpen, setOpen] = useState(!!isInitiallyExpanded)

    const onClick = useCallback(() => {
        setOpen((t) => !t)
    }, [setOpen])

    const setRef = useOnClickWithoutMouseMove(onClick)

    return (
        <Card cy="expandable-card">
            <CardHeader borderColor="front.border.subtlerI" ref={setRef} interactive isClosed={!isOpen} paddingLess>
                <CardInset type={insetSize} isForHeader={false}>
                    <Flex grow justify="space-between" align="center">
                        {headerMemo}
                        <IconHolder className={isOpen ? 'opened' : ''}>
                            <Icon type="downTick" size={10} />
                        </IconHolder>
                    </Flex>
                </CardInset>
            </CardHeader>
            <ExpandableCardContent isOpen={isOpen} onExpand={onExpand}>
                {children}
            </ExpandableCardContent>
        </Card>
    )
}

const IconHolder = styled.div<{ isOpen?: boolean }>`
    position: relative;
    transition: 0.2s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
    width: 20px;
    height: 20px;
    margin-top: -2px;
    border-radius: 999px;
    background-color: ${(p) => p.theme['front.background']};
    border: 1px solid ${(p) => p.theme['front.border']};
    color: ${(p) => p.theme['front.text']};

    &.opened {
        transform: rotate(180deg);
    }
`
