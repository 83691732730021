import React from 'react'
import { PartnerGuideline } from '../types'
import { getPartnerGuidelines } from '../utils/getPartnerGuidelines'
import { PartnerGuidelinesHelpTip } from './partner-guidelines-help-tip'

interface PartnerGuidelinesTooltipProps {
    gatewayIds?: string[]
}

export const PartnerGuidelinesTooltip: React.FC<PartnerGuidelinesTooltipProps> = (props) => {
    const { gatewayIds } = props

    const guidelines = gatewayIds
        ?.map((gatewayId) => getPartnerGuidelines(gatewayId))
        .filter((guideline): guideline is PartnerGuideline => !!guideline)

    if (!guidelines || guidelines?.length === 0) {
        return null
    }

    return <PartnerGuidelinesHelpTip guidelines={guidelines} />
}
