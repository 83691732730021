import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { LoaderView } from '../../../components/loaders/loader'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { MerchantApplicationStructure } from './Application.Structure'
import { IMerchantApplicationSectionStructure } from './Application.StructureTypes'
import { useHasSectionLoaded } from './useHasSectionLoaded'

export type DedicatedSectionProps = {
    errors: MerchantApplicationFieldFormProps['formErrors']
    executeBatchChanges: MerchantApplicationFieldFormProps['executeBatchChanges']
    applicationId: string
    form?: any
    focusInvalidField?: any
    formRef: MerchantApplicationFieldFormProps['formRef']
}

export const useDedicatedSection = (id: string, name: string) => {
    const sectionStructure = MerchantApplicationStructure.find(
        (s: any) => s?.label === name
    ) as IMerchantApplicationSectionStructure
    if (!sectionStructure) throw `Couldn't find section ${name}`

    const loader = useMemo(() => ({ Loader: <LoaderView overBackground="back.background" /> }), [])
    const loadingStatus = useHasSectionLoaded(id, sectionStructure)

    const shouldRenderFullCard = !(loadingStatus === 'waiting-for-section' || loadingStatus === 'waiting-for-files')

    const field = sectionStructure.fields[0] as any

    return {
        elements: shouldRenderFullCard ? null : loader,
        structure: sectionStructure,
        field
    }
}

export const DedicatedSectionSeparator: React.FC<{ alignLeft?: boolean; title?: string; id?: string }> = ({
    title,
    alignLeft,
    id
}) => {
    return (
        <>
            <Separator alignLeft={alignLeft}>{title}</Separator>
            <div id={id} />
        </>
    )
}

const Separator = styled.div<{ alignLeft?: boolean }>`
    width: 100%;
    padding: 0px 5px;
    box-sizing: border-box;
    letter-spacing: 1.5px;
    border-radius: 5px;
    margin-bottom: 0px;
    margin-top: 30px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;

    text-align: center;
    letter-spacing: 0.03em;

    color: ${(p) => p.theme['back.text']};

    ${(p) =>
        p.alignLeft &&
        css`
            justify-content: flex-start;
            text-align: left;
            padding: 0px 25px;
        `}
`
