import { useFinalizeClassification } from '@/services/risk-classification'
import { useClassificationParams } from '../../../hooks/use-classification-params'

export const useFinalizeActions = () => {
    const { classificationId } = useClassificationParams()

    const [finalizeClassification, classificationResult] = useFinalizeClassification()

    const isLoading = classificationResult.isLoading

    const finalize = async () => {
        const createClassificationPayload = { classificationId }
        const classification = await finalizeClassification(createClassificationPayload).unwrap()


        return { classification }
    }

    return { isLoading, finalize }
}
