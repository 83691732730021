import React, { ReactNode, ReactNodeArray, useState } from 'react'
import styled from 'styled-components'

import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { Color } from '../../styles/colors'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { Floater } from './floater'
import { FloaterInset } from './floaterInset'

export const Expander: React.FC<{
    count: number
    listMemo: ReactNode | ReactNodeArray
    textColor?: Color
    insetButton?: boolean
}> = ({ count, listMemo, textColor, insetButton }) => {
    const [holder, setHolder] = useRefTaker()
    const [anchor, setAnchor] = useRefTaker()
    const [shouldShowMore, setShouldShowMore] = useState(false)

    useOnHoverOutside(holder, () => {
        setShouldShowMore(false)
    })

    if (count === 0) return null

    return (
        <Holder ref={setHolder}>
            <ButtonAnchor ref={setAnchor}>
                <SimpleButton
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setShouldShowMore(true)
                    }}
                    inline
                    color={textColor}
                >
                    <ButtonInset
                        noHorizontalPadding={!insetButton}
                        noVerticalPadding={!insetButton}
                    >{`+${count} more`}</ButtonInset>
                </SimpleButton>
            </ButtonAnchor>
            <Floater
                cardId="GenericExpander"
                anchor={anchor}
                hideOverflow
                shouldShow={shouldShowMore}
                onHide={() => {
                    setShouldShowMore(false)
                }}
            >
                <FloaterInset>{listMemo}</FloaterInset>
            </Floater>
        </Holder>
    )
}

const Holder = styled.div`
    display: contents;
`

const ButtonAnchor = styled.div`
    display: inline;
`
