import C from 'color'

export const lighten = (color: string, times = 1) => {
    return C(color)
        .lighten(0.075 * times)
        .hex()
}
export const darken = (color: string, times = 1) => {
    return C(color)
        .darken(0.075 * times)
        .hex()
}

export type ThemePalette = {
    // Sidebars
    'side.text': string
    'side.background': string
    'side.accent.color': string
    'side.accent.background': string
    'side.highlights': string
    'side.border': string
    'side.subtleAccent.text': string
    'side.subtleAccent.background': string
    // Regular Background
    'back.background': string
    'back.highlights': string
    'back.border': string
    'back.text': string
    // Cards
    'front.background': string
    'front.highlights': string
    'front.text': string
    'front.danger.color': string
    'front.danger.text': string
    'front.accent.color': string
    'front.accent.text': string
    'front.subtleAccent.background': string
    'front.border': string
    'front.subtleAccent.highlights': string
    'front.subtleAccent.text': string
    'front.subtleAccent.border': string
    'front.subtleDanger.text': string
    'front.subtleDanger.background': string
    'front.subtleDanger.highlights': string
    'front.subtleDanger.border': string
    'front.subtleSuccess.border': string
    'front.subtleInfo.border': string
    'front.success.text': string
    'front.success.color': string
    'front.info.text': string
    'front.info.background': string
    'front.subtleSuccess.text': string
    'front.subtleSuccess.background': string
    'front.subtleInfo.text': string
    'front.subtleInfo.background': string
    // Modal Backgrounds
    'overlay.background': string
    'overlay.text': string
    'overlay.highlights': string
    // Tooltips, Popovers, Dropdown menus
    'floating.background': string
    'floating.text': string
    'floating.border': string
    'floating.highlights': string
    'floating.subtleAccent.background': string
    'floating.subtleAccent.text': string
}
export type ColorKey = keyof ThemePalette

const Colors: ThemePalette = {
    'side.text': '#F6F7FA',
    'side.background': '#034A7D',
    'side.border': '#2D5584',
    'side.accent.color': '#f7c83f',
    'side.accent.background': '#395873',
    'side.highlights': '#375584',
    'side.subtleAccent.text': '#EDC85A',
    'side.subtleAccent.background': '#345D7E',
    'back.background': '#E3E5EA',
    'back.text': '#6C7888',
    'back.highlights': '#D9DBDE',
    'back.border': '#D7DEE5',
    'front.background': '#FFF',
    'front.highlights': '#F8F8F9',
    'front.text': '#1D2125',
    'front.danger.color': '#DC1E1E',
    'front.danger.text': '#FFF',
    'front.accent.color': '#087AFF',
    'front.accent.text': '#FFF',
    'front.subtleAccent.background': '#E9F0FA',
    'front.subtleAccent.border': '#D6E5FA',
    'front.border': '#EEEEEE',
    'front.subtleAccent.highlights': '#dcebf9',
    'front.subtleAccent.text': '#087AFF',
    'front.info.background': '#DFCA07',
    'front.info.text': '#1D2125',
    'front.subtleDanger.text': '#9F0600',
    'front.subtleDanger.background': '#EBD2D1',
    'front.subtleDanger.highlights': '#F5D5D4',
    'front.subtleDanger.border': '#E5BABA',
    'front.success.text': '#FFF',
    'front.success.color': '#0D886D',
    'front.subtleSuccess.text': '#5FAC5F',
    'front.subtleSuccess.background': '#D6FFD6',
    'front.subtleSuccess.border': '#CBEACB',
    'front.subtleInfo.text': '#697A00',
    'front.subtleInfo.background': '#FFF9BE',
    'front.subtleInfo.border': '#EEE8B2',
    'overlay.background': '#E6EAF1',
    'overlay.text': '#24272C',
    'overlay.highlights': '#d0d8e5',
    'floating.background': '#272F38',
    'floating.text': '#FFF',
    'floating.border': '#39434E',
    'floating.highlights': '#2E363F',
    'floating.subtleAccent.background': '#343F4E',
    'floating.subtleAccent.text': '#6DD5F0'
}
const DarkTheme: ThemePalette = {
    'side.text': '#F4F6F6',
    'side.background': '#262B31',
    'side.border': '#1A2129',
    'side.accent.color': '#DC8A53',
    'side.accent.background': '#36332A',
    'side.highlights': '#222A32',
    'side.subtleAccent.text': '#7A9FFF',
    'side.subtleAccent.background': '#2E3949',
    'back.text': '#838C8D',
    'back.background': '#1C2126',
    'back.highlights': '#1B1F23',
    'back.border': '#22262B',
    'front.background': '#262B31',
    'front.highlights': '#292E34',
    'front.text': '#F5F6F6',
    'front.danger.color': '#DC1E1E',
    'front.danger.text': '#FFF',
    'front.info.background': '#DFCA07',
    'front.info.text': '#1D2125',
    'front.accent.color': '#2F8DFB',
    'front.border': '#2F3139',
    'front.accent.text': '#FFF',
    'front.subtleAccent.background': '#2E3949',
    'front.subtleAccent.border': '#D6E5FA',
    'front.subtleAccent.highlights': '#212f3e',
    'front.subtleAccent.text': '#7A9FFF',
    'front.subtleDanger.text': '#DC1E1E',
    'front.subtleDanger.background': '#372424',
    'front.subtleDanger.highlights': '#331F1E',
    'front.subtleDanger.border': '#442A28',
    'front.success.text': '#FFF',
    'front.success.color': '#0D886D',
    'front.subtleSuccess.text': '#D2EDDB',
    'front.subtleSuccess.background': '#0E362F',
    'front.subtleSuccess.border': '#11433A',
    'front.subtleInfo.text': '#A6FC39',
    'front.subtleInfo.background': '#295132',
    'front.subtleInfo.border': '#253F29',
    'overlay.background': '#171B20',
    'overlay.text': '#DBE2E8',
    'overlay.highlights': '#000',
    'floating.background': '#2C3137',
    'floating.text': '#F5F6F6',
    'floating.border': '#39434E',
    'floating.highlights': '#2E363F',
    'floating.subtleAccent.background': '#343F4E',
    'floating.subtleAccent.text': '#6DD5F0'
}
const WhiteTheme: ThemePalette = {
    'side.text': '#1D2125',
    'side.background': '#FFF',
    'side.border': '#F2F2F2',
    'side.accent.color': '#087AFF',
    'side.accent.background': '#D3E8FF',
    'side.highlights': '#FBFBFB',
    'side.subtleAccent.text': '#087AFF',
    'side.subtleAccent.background': '#E9F0FA',
    'back.background': '#F2F5F6',
    'back.border': '#E9ECED',
    'back.text': '#949BAB',
    'back.highlights': '#EDEEEE',
    'front.background': '#FFF',
    'front.highlights': '#F9F9F9',
    'front.text': '#1D2125',
    'front.danger.color': '#DC1E1E',
    'front.danger.text': '#FFF',
    'front.accent.color': '#087AFF',
    'front.accent.text': '#FFF',
    'front.border': '#F1F1F4',
    'front.subtleAccent.background': '#E2EEFF',
    'front.subtleAccent.border': '#D6E5FA',
    'front.subtleAccent.highlights': '#dcebf9',
    'front.subtleAccent.text': '#087AFF',
    'front.info.background': '#FFF184',
    'front.info.text': '#4B441B',
    'front.subtleDanger.border': '#F5D3D2',
    'front.subtleDanger.text': '#9F0600',
    'front.subtleDanger.background': '#FFDBD9',
    'front.subtleDanger.highlights': '#F5D5D4',
    'front.success.text': '#FFF',
    'front.success.color': '#43B58C',
    'front.subtleSuccess.text': '#5FAC5F',
    'front.subtleSuccess.background': '#F6FFF2',
    'front.subtleSuccess.border': '#E0ECDB',
    'front.subtleInfo.text': '#697A00',
    'front.subtleInfo.background': '#FFF9BE',
    'front.subtleInfo.border': '#EEE7B2',
    'overlay.background': '#E6EEEE',
    'overlay.text': '#1D2125',
    'overlay.highlights': '#F9F9F9',
    'floating.background': '#272F38',
    'floating.text': '#FFF',
    'floating.border': '#39434E',
    'floating.highlights': '#2E363F',
    'floating.subtleAccent.background': '#343F4E',
    'floating.subtleAccent.text': '#6DD5F0'
}

type WithShadesAndTints<T> = {
    [P in keyof T & string as
        | `${P}`
        | `${P}.strongerI`
        | `${P}.strongerII`
        | `${P}.strongerIII`
        | `${P}.subtlerI`
        | `${P}.subtlerII`
        | `${P}.subtlerIII`]: T[P]
}

const buildTheme = (
    c: typeof Colors,
    shadeLevels: {
        general: number
        backgrounds: number
    },
    config: {
        [key: string]: {
            intensity: number
            shadesIntensity?: number
            tintsIntensity?: number
            fullShades?: boolean
            fullTints?: boolean
            swapTintsAndShades?: boolean
        }
    },
    preciseTintsAndShades?: Partial<{
        [key in keyof WithShadesAndTints<ThemePalette>]: string
    }>
): WithShadesAndTints<typeof Colors> => {
    return (Object.keys(c) as (keyof typeof c)[]).reduce((acc: any, key) => {
        acc[key] = c[key]

        const generateTintsAndShades = (a: any, intensity: number) => {
            const shouldSwapTintsAndShades = config[key]?.swapTintsAndShades
            const shouldFullTints = config[key]?.fullTints
            const shouldFullShades = config[key]?.fullShades
            let shadesIntensity: number = intensity
            let tintsIntensity: number = intensity

            if (config[key]?.intensity) {
                tintsIntensity = config[key]?.intensity
                shadesIntensity = config[key]?.intensity
            }

            if (config[key]?.shadesIntensity) {
                shadesIntensity = config[key].shadesIntensity as number
            }

            if (config[key]?.tintsIntensity) {
                tintsIntensity = config[key].tintsIntensity as number
            }

            if (shouldFullTints) {
                a[key + '.strongerI'] = lighten(c[key], shadesIntensity)
                a[key + '.strongerII'] = lighten(c[key], shadesIntensity * 2)
                a[key + '.strongerIII'] = lighten(c[key], shadesIntensity * 3)
                a[key + '.subtlerI'] = lighten(c[key], tintsIntensity)
                a[key + '.subtlerII'] = lighten(c[key], tintsIntensity * 2)
                a[key + '.subtlerIII'] = lighten(c[key], tintsIntensity * 3)
            } else if (shouldFullShades) {
                a[key + '.strongerI'] = darken(c[key], shadesIntensity)
                a[key + '.strongerII'] = darken(c[key], shadesIntensity * 2)
                a[key + '.strongerIII'] = darken(c[key], shadesIntensity * 3)
                a[key + '.subtlerI'] = darken(c[key], tintsIntensity)
                a[key + '.subtlerII'] = darken(c[key], tintsIntensity * 2)
                a[key + '.subtlerIII'] = darken(c[key], tintsIntensity * 3)
            } else if (shouldSwapTintsAndShades) {
                a[key + '.strongerI'] = lighten(c[key], shadesIntensity)
                a[key + '.strongerII'] = lighten(c[key], shadesIntensity * 2)
                a[key + '.strongerIII'] = lighten(c[key], shadesIntensity * 3)
                a[key + '.subtlerI'] = darken(c[key], tintsIntensity)
                a[key + '.subtlerII'] = darken(c[key], tintsIntensity * 2)
                a[key + '.subtlerIII'] = darken(c[key], tintsIntensity * 3)
            } else {
                a[key + '.strongerI'] = darken(c[key], shadesIntensity)
                a[key + '.strongerII'] = darken(c[key], shadesIntensity * 2)
                a[key + '.strongerIII'] = darken(c[key], shadesIntensity * 3)
                a[key + '.subtlerI'] = lighten(c[key], tintsIntensity)
                a[key + '.subtlerII'] = lighten(c[key], tintsIntensity * 2)
                a[key + '.subtlerIII'] = lighten(c[key], tintsIntensity * 3)
            }
        }

        if (key.includes('highlights')) {
            generateTintsAndShades(acc, 0.65)
        } else if (!key.includes('background')) {
            generateTintsAndShades(acc, shadeLevels.general)
        } else {
            generateTintsAndShades(acc, shadeLevels.backgrounds)
        }

        if (preciseTintsAndShades) {
            Object.keys(preciseTintsAndShades).forEach((key: string) => {
                acc[key] = preciseTintsAndShades[key as keyof WithShadesAndTints<ThemePalette>]
            })
        }

        return acc
    }, {})
}

export const Themes = {
    regular: buildTheme(
        Colors as any,
        {
            general: 3,
            backgrounds: 1.5
        },
        {
            'front.text': {
                intensity: 45
            },
            'back.text': {
                intensity: 3
            },
            'floating.text': {
                intensity: 3,
                fullShades: true
            },
            'side.border': {
                intensity: 1.5,
                fullTints: true
            },
            'front.success.text': {
                intensity: 2,
                swapTintsAndShades: true
            },
            'side.background': {
                intensity: 2
            },
            'back.background': {
                intensity: 0.75
            },
            'front.background': {
                intensity: 0.5
            },
            'floating.subtleAccent.text': {
                intensity: 3,
                fullShades: true
            },
            'front.subtleAccent.background': {
                intensity: 0.5
            },
            'front.subtleInfo.background': {
                intensity: 2.5
            },
            'front.danger.color': {
                intensity: 1.2
            },
            'side.text': {
                intensity: 2.3,
                swapTintsAndShades: true
            },
            'overlay.background': {
                intensity: 0.5
            },
            'front.subtleDanger.background': {
                intensity: 0.5
            }
        },
        {
            'front.text.strongerI': '#5C636F',
            'front.text.subtlerII': '#C1C1C1',
            'front.text.subtlerIII': '#D8DEE7',
            'back.highlights.strongerI': '#c9cdd0',
            'back.background.strongerI': '#DDE0E5',
            'back.background.strongerII': '#D6DAE0',
            'back.border.strongerI': '#CBD2DC',
            'front.highlights.strongerI': '#E7E8EC',
            'front.subtleAccent.highlights.subtlerI': '#D6E2F1',
            'front.subtleDanger.highlights.subtlerI': '#DFC0C0',
            'floating.background.strongerI': '#35404D',
            'side.background.strongerII': '#174171'
        }
    ),
    dark: buildTheme(
        DarkTheme as any,
        {
            general: 4.5,
            backgrounds: 1.5
        },
        {
            'front.text': {
                intensity: 6,
                swapTintsAndShades: true
            },
            'floating.text': {
                intensity: 2.8,
                fullShades: true
            },
            'floating.background': {
                intensity: 3
            },
            'floating.subtleAccent.text': {
                intensity: 3,
                fullShades: true
            },
            'side.subtleAccent.text': {
                intensity: 1
            },
            'side.background': {
                intensity: 2
            },
            'back.text': {
                intensity: 3,
                swapTintsAndShades: true
            },
            'side.text': {
                intensity: 3.3,
                swapTintsAndShades: true
            },
            'front.accent.color': {
                intensity: 3
            },
            'front.accent.text': {
                intensity: 2,
                fullShades: true
            },
            'side.border': {
                intensity: 5,
                fullTints: true
            },
            'front.danger.color': {
                intensity: 3
            },
            'front.subtleAccent.background': {
                intensity: 1.5,
                swapTintsAndShades: true
            },
            'front.subtleSuccess.background': {
                intensity: 1.5,
                swapTintsAndShades: true
            },
            'front.highlights': {
                intensity: 2,
                swapTintsAndShades: true
            },
            'front.success.text': {
                intensity: 2,
                swapTintsAndShades: true
            },
            'front.background': {
                intensity: 2,
                swapTintsAndShades: true
            },
            'front.subtleDanger.background': {
                intensity: 2,
                swapTintsAndShades: true
            },
            'back.background': {
                intensity: 2,
                swapTintsAndShades: true
            }
        },
        {
            'back.background.strongerI': '#1B1F23',
            'side.background.subtlerII': '#171f27',
            'front.text.subtlerII': '#838C8D',
            'back.border.strongerI': '#2B2B2B',
            'side.text.subtlerII': '#949E9E'
        }
    ),
    white: buildTheme(
        WhiteTheme as any,
        {
            general: 4.5,
            backgrounds: 1.5
        },
        {
            'front.text': {
                intensity: 40
            },
            'back.text': {
                intensity: 3
            },
            'floating.text': {
                intensity: 3,
                fullShades: true
            },
            'floating.subtleAccent.text': {
                intensity: 3,
                fullShades: true
            },
            'side.background': {
                intensity: 0.5,
                fullShades: true
            },
            'front.success.text': {
                intensity: 2,
                swapTintsAndShades: true
            },
            'front.accent.text': {
                intensity: 2,
                swapTintsAndShades: true
            },
            'front.accent.color': {
                intensity: 3
            },
            'back.background': {
                intensity: 0.3
            },
            'floating.background': {
                intensity: 2,
                shadesIntensity: 4
            },
            'back.border': {
                intensity: 0.4
            },
            'front.background': {
                intensity: 0.5
            },
            'front.subtleAccent.background': {
                intensity: 0.5
            },
            'front.subtleInfo.background': {
                intensity: 2.5
            },
            'front.danger.color': {
                intensity: 1.2
            },
            'side.border': {
                intensity: 0.8
            },
            'side.text': {
                intensity: 40
            },
            'front.success.color': {
                intensity: 2
            },
            'overlay.background': {
                intensity: 0.5
            },
            'front.border': {
                intensity: 1.1
            },
            'front.subtleDanger.background': {
                intensity: 0.3,
                swapTintsAndShades: true
            },
            'front.highlights': {
                intensity: 0.25
            }
        },
        {
            'front.subtleInfo.background.strongerI': '#F1ECB7',
            'front.text': '#000',
            'front.text.subtlerII': '#C1C1C1',
            'side.text.subtlerII': '#949BAB',
            'back.border.strongerI': '#DBDDE0',
            'side.text.subtlerIII': '#AEAEAE'
        }
    )
}

export type Color = keyof WithShadesAndTints<typeof Colors>
