import { BaseState, StateFactory } from 'ketting/dist/state'
import Client, { Link, resolve } from 'ketting'
import { HalLink, HalResource } from 'hal-types'

import { Links } from 'ketting/dist/link'
import { parseLink } from 'ketting/dist/http/util'

export class HalState<T = any> extends BaseState<T> {
    public fromEmbedded: string | null = null

    public serializeBody(): string {
        return JSON.stringify({
            _links: this.serializeLinks(),
            ...this.data
        })
    }

    private serializeLinks(): HalResource['_links'] {
        const links: HalResource['_links'] = {
            self: { href: this.uri }
        }
        for (const link of this.links.getAll()) {
            const { rel, context, ...attributes } = link

            if (rel === 'self') {
                // skip
                continue
            }

            if (links[rel] === undefined) {
                // First link of its kind
                links[rel] = attributes
            } else if (Array.isArray(links[rel])) {
                // Add link to link array.
                ;(links[rel] as HalLink[]).push(attributes)
            } else {
                // 1 link with this rel existed, so we will transform it to an array.
                links[rel] = [links[rel] as HalLink, attributes]
            }
        }

        return links
    }

    public clone(): HalState {
        return new HalState({
            client: this.client,
            uri: this.uri,
            data: this.data,
            headers: new Headers(this.headers),
            links: new Links(this.links.defaultContext, this.links.getAll())
        })
    }
}

export const factory: StateFactory = async (client, uri, response): Promise<HalState> => {
    const body = await response.json()
    const links = parseLink(uri, response.headers.get('Link'))

    // The HAL factory is also respondible for plain JSON, which might be an
    // array.
    if (Array.isArray(body)) {
        return new HalState({
            client,
            uri,
            data: body,
            headers: response.headers,
            links
        })
    }

    links.add(...parseHalLinks(uri, body))

    const parsedEmbedded = parseHalEmbedded(client, uri, body, response.headers)

    // Remove _links and _embedded from body
    const { _embedded, _links, _templates, ...newBody } = body

    return new HalState({
        client,
        uri,
        data: newBody,
        headers: response.headers,
        links,
        embedded: parsedEmbedded
    })
}

function parseHalLinks(context: string, body: HalResource): Link[] {
    if (body._links === undefined) {
        return []
    }

    const result: Link[] = []

    const foundLinks = new Set()

    for (const [relType, links] of Object.entries(body._links)) {
        const linkList = Array.isArray(links) ? links : [links]

        for (const link of linkList) {
            foundLinks.add(relType + ';' + link.href)
        }

        result.push(...parseHalLink(context, relType, linkList))
    }

    if (body._embedded) {
        // eslint-disable-next-line prefer-const
        for (let [rel, innerBodies] of Object.entries(body._embedded)) {
            if (!Array.isArray(innerBodies)) {
                innerBodies = [innerBodies]
            }

            for (const innerBody of innerBodies) {
                const href: string = innerBody?._links?.self?.href
                if (!href) {
                    continue
                }

                if (foundLinks.has(rel + ';' + href)) {
                    continue
                }
                result.push({
                    rel,
                    href,
                    context
                })
            }
        }
    }

    return result
}

function parseHalLink(context: string, rel: string, links: HalLink[]): Link[] {
    const result: Link[] = []

    for (const link of links) {
        result.push({
            rel,
            context,
            ...link
        })
    }

    return result
}

function parseHalEmbedded(client: Client, context: string, body: HalResource, headers: Headers): Array<HalState<any>> {
    if (body._embedded === undefined) {
        return []
    }

    const result: Array<HalState<any>> = []

    for (const [relType, embedded] of Object.entries(body._embedded)) {
        let embeddedList: HalResource[]

        if (!Array.isArray(embedded)) {
            embeddedList = [embedded]
        } else {
            embeddedList = embedded
        }
        for (const embeddedItem of embeddedList) {
            if (
                embeddedItem._links === undefined ||
                embeddedItem._links.self === undefined ||
                Array.isArray(embeddedItem._links.self)
            ) {
                // If embeddedItem does not have a self link, append the item to body
                if (body[relType]) {
                    if (!Array.isArray(body[relType])) {
                        body[relType] = [body[relType]]
                        body[relType].push(embeddedItem)
                    } else {
                        body[relType].push(embeddedItem)
                    }
                } else {
                    body[relType] = embeddedItem
                }

                continue
            }

            // Parsing nested embedded items. Note that we assume that the base url is relative to
            // the outermost parent, not relative to the embedded item. HAL is not clear on this.
            const parsedEmbedded = parseHalEmbedded(client, context, embeddedItem, headers)

            // Remove _links and _embedded from body
            const { _embedded, _links, ...newBody } = embeddedItem

            result.push(
                new HalState({
                    client,
                    uri: resolve(context, embeddedItem._links.self.href),
                    data: newBody,
                    headers: new Headers({
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        'Content-Type': headers.get('Content-Type')!
                    }),
                    links: new Links(context, parseHalLinks(context, embeddedItem)),
                    embedded: parsedEmbedded
                })
            )
        }
    }

    return result
}
