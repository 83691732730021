import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Flex } from '../../../components/layout/flex'
import { Grid } from '../../../components/layout/grid'
import { List } from '../../../components/layout/list'
import { Separator } from '../../../components/layout/separator'
import { Spacer } from '../../../components/layout/spacer'
import { LoaderView } from '../../../components/loaders/loader'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { ApplicationFilterToTitle } from '../../../store/applications/types'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../../utils'
import { DateFormats } from '../../../utils/dateUtils'
import { SummaryReferralCard } from './SummaryReferralCard'
import { MerchantSummaryCollaboratorsCard } from './SummaryCollaboratorsCard'
import { MerchantSummaryRelatedCard } from './SummaryRelatedCard'
import { MerchantSummaryTimelineCard } from './SummaryTimelineCard'

{
    /* <MerchantSummaryWarnings merchantId={merchantId} /> */
}
export const MerchantSummaryApplicationCard: React.FC<{ merchantId: string }> = ({ merchantId }) => {
    const applicationState = useSelector(
        (state: RootState) => state.applications.applications.at[merchantId]?.metadata?.state
    )
    const declinedReason = useSelector(
        (state: RootState) => state.applications.applications.at[merchantId]?.metadata?.declinedReason
    )
    const timeline = useSelector((state: RootState) => state.merchantTimeline[merchantId])
    const isSidebarStillLoading = useSelector((state: RootState) => {
        let isLoading = false
        if (state.applicationInternals.related.forApplication?.[merchantId]?.loadingStatus !== 'done') isLoading = true
        if (state.applicationInternals.collaborators.forApplication?.[merchantId]?.loadingStatus !== 'done')
            isLoading = true
        if (state.merchantTimeline?.[merchantId]?.loadingStatus !== 'done') isLoading = true
        return isLoading
    })

    const firstRow = useMemo(() => {
        const stateString =
            (ApplicationFilterToTitle as any)[applicationState] || uppercaseFirstLetter(applicationState)
        return {
            State: {
                type: 'custom' as const,
                node: (
                    <StateBox>
                        <Text oneLine noWrap bold size="l">
                            {applicationState === 'accepted' ? (
                                <IconOffset>
                                    <Icon color="front.success.color" type="checkmark" size={20} weight={2} />
                                </IconOffset>
                            ) : null}
                            {!stateString ? <TextLineLoader maxWidth={80} /> : stateString}
                        </Text>
                    </StateBox>
                )
            },
            ...(declinedReason
                ? {
                      Reason: {
                          type: 'custom' as const,
                          node: (
                              <Flex column>
                                  <Spacer height={3} />
                                  <Text>{declinedReason}</Text>
                              </Flex>
                          )
                      }
                  }
                : {
                      [stateString ? stateString + ' since' : 'Since']: {
                          type: 'custom' as const,
                          node: (
                              <Flex column>
                                  {timeline?.loadingStatus == 'done' ? (
                                      <Text oneLine>
                                          {timeline.byKind?.['state-changes']?.[0]
                                              ? moment(timeline.at[timeline.byKind['state-changes'][0]].at).format(
                                                    DateFormats.fullDate(
                                                        moment(timeline.at[timeline.byKind['state-changes'][0]].at)
                                                    )
                                                )
                                              : '-'}
                                      </Text>
                                  ) : (
                                      <TextLineLoader />
                                  )}
                              </Flex>
                          )
                      }
                  })
        }
    }, [declinedReason, applicationState, timeline])

    return (
        <Holder>
            <Card title="Merchant's Application">
                <CardInset>
                    <Flex column>
                        <FirstHolder>
                            <List
                                background="front.background"
                                items={firstRow}
                                template={'min-content auto'}
                                switchToRowsAt={300}
                            />
                            <SummaryReferralCard id={merchantId} />
                        </FirstHolder>
                        <Spacer height={20} />
                        <Separator background="front.background" />
                        <Spacer height={20} />
                        <Grid horizontalTemplate={'1fr 1fr'} gridColumnGap="medium" switchToRowsAt={0}>
                            <MerchantSummaryCollaboratorsCard
                                merchantId={merchantId}
                                stillLoading={isSidebarStillLoading}
                            />
                            <MerchantSummaryRelatedCard merchantId={merchantId} />
                        </Grid>
                    </Flex>
                </CardInset>
            </Card>
            {isSidebarStillLoading ? (
                <LoaderView overBackground="back.background" />
            ) : (
                <MerchantSummaryTimelineCard merchantId={merchantId} />
            )}
        </Holder>
    )
}

const Holder = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: auto minmax(auto, 270px);
`

const IconOffset = styled.span`
    position: relative;
    display: inline-block;
    top: 2px;
    left: -2px;
    margin-top: -2px;
    margin-bottom: -2px;
`

const StateBox = styled.div`
    border: 1px solid ${(p) => p.theme['front.border']};
    padding: 4px 7px;
    margin: 2px 0 0 -8px;
    border-radius: 8px;
    background-color: ${(p) => p.theme['front.highlights']};

    display: flex;
    align-items: center;
    * {
        line-height: 1em;
    }
`

const FirstHolder = styled.div`
    display: grid;
    column-gap: 20px;
    grid-template-columns: auto minmax(auto, 280px);
`
