import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationValidationSection } from './Application.ValidationSection'

import { useModalStackSync } from '../../../hooks/general/useModalStackSync'
import { RootState } from '@/store'
import { MerchantApplicationNavigation } from './Application.Navigation'
import { MerchantApplicationFieldSidebar } from './Application.FieldSidebar'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { useForm } from '../../../hooks/general/useForm'
import { InterfaceActions } from '../../../store/interface/actions'
import styled from 'styled-components'
import { MerchantApplicationFieldDependenciesProvider } from './useMerchantApplicationFieldDependencies'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { useRouteGuard } from '../../../hooks/general/useRouteGuard'
import { useSecretaryFileRefresher } from '../../../hooks/general/useSecretaryFileRefresher'
import { CenteredScreen } from '../../../components/layout/centeredScreen'
import { MerchantApplicationDedicatedSectionCompany } from './Application.DedicatedSectionCompany'
import { MerchantApplicationControls } from './Application.Controls'
import { DedicatedSectionSeparator } from './Application.DedicatedSection'
import { MerchantApplicationDedicatedSectionPeople } from './Application.DedicatedSectionPeople'
import { MerchantApplicationDedicatedSectionBusinessModel } from './Application.DedicatedSectionBusinessModel'
import { MerchantApplicationDedicatedSectionWebsites } from './Application.DedicatedSectionWebsites'
import { MerchantApplicationDedicateSectionGateway } from './Application.DedicatedSectionGateway'
import { MerchantApplicationDedicateSectionBankAccount } from './Application.DedicatedSectionBankAccount'
import { MerchantApplicationDedicatedSectionAdditional } from './Application.DedicatedSectionAdditional'
import { Spacer } from '../../../components/layout/spacer'
import { MerchantApplicationDedicateSectionContact } from './Application.DedicatedSectionContact'
import { MerchantApplicationDedicateSectionOwnership } from './Application.DedicatedSectionOwnership'
import { MerchantApplicationStructure } from './Application.Structure'
import { ApplicationFieldModalInput } from './Application.FieldModalInput'
import { MerchantApplicationTopBar } from './Application.TopBar'
import { MerchantApplicationDedicateSectionReferralPartner } from './Application.DedicatedSectionReferralPartner'
import { useParams } from 'react-router-dom'
import { useNewClassification } from '@/features/risk-classification'
import { LoaderView } from '@/components/loaders/loader'
import { Flex } from '@/design-system'
import { ApplicationSectionHeading } from './Application.SectionHeading'
import { ApplicationWarnings } from './Application.Warnings'
import { useApplicationHasWarnings } from '@/hooks/application/useApplicationHasWarnings'

type Params = {
    id: string
}

export const MerchantApplication: React.FC = () => {
    const params = useParams<Params>() as Params
    const { id } = params || {}

    const { redirect: redirectToClassification, isRedirecting: isRedirectingToClassification } = useNewClassification(
        id,
        `/merchant/${id}/accounts`
    )

    const application = useSelector((state: RootState) => state.applications.applications.at[id])
    const name = useMerchantName(id, `Application`)
    const collaborators = useSelector(
        (state: RootState) => state.applicationInternals?.collaborators?.forApplication?.[id]?.collaborators
    )
    const previousSignerName = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication?.[id]?.signer?.fields?.[0]?.signer.name
    )

    useModalStackSync(name, `Merchant.ApplicationPage.${id}` as any)
    // Let's consider enabling it if the hash navigation fails to restore the position properly
    // or we receive complaints
    const dispatch = useDispatch()
    const [sequentialLoad, setSequentialLoad] = useState(0)
    const storedChanges = useSelector((state: RootState) => state.interface.applications[id]?.changes)
    useSecretaryFileRefresher(id)

    const [watcher, watcherId] = useNamedWatcher(`Merchant.ApplicationPage.${id}.Save`)
    const {
        formRef,
        resetForm,
        hasChanges,
        formMounted,
        form,
        submitHandler,
        focusInvalidField,
        errors,
        executeBatchChanges
    } = useForm(
        watcher,
        useCallback(
            (changes: any) => {
                dispatch(InterfaceActions.SET_APPLICATION_CHANGES(id, changes))
            },
            [dispatch, id]
        ),
        storedChanges,
        true
    )

    const isInitial = useRef(true)
    const stateString = application?.metadata?.state

    useEffect(() => {
        // If not initial and state is accepted, redirect to Risk Classification
        const createClassification = async () => {
            if (!isInitial.current && ['accepted', 'pre_accepted'].includes(stateString)) {
                redirectToClassification()
            }

            if (isInitial.current && stateString) {
                isInitial.current = false
            }
        }
        createClassification()
    }, [stateString, id])

    useRouteGuard('The application has unsaved edits. Are your sure you want to leave?', hasChanges)

    const handleSubmitForm = useMemo(() => {
        return submitHandler((form: any) => {
            dispatch(InterfaceActions.START_APPLICATION_REVIEW(id, form.changes, collaborators, previousSignerName))
        })
    }, [dispatch, id, submitHandler, collaborators, previousSignerName])

    const applicationControls = useMemo(() => {
        return (
            <MerchantApplicationControls
                errors={errors}
                id={id}
                watcherId={watcherId}
                submitHandler={handleSubmitForm}
                resetForm={resetForm}
                focusInvalidField={focusInvalidField}
                form={form}
            />
        )
    }, [id, watcherId, handleSubmitForm, resetForm, focusInvalidField, errors, form])

    useEffect(() => {
        if (sequentialLoad > MerchantApplicationStructure.length) return
        setTimeout(() => {
            setSequentialLoad((s) => (s += 1))
        }, 16)
    }, [sequentialLoad])

    const { hasWarnings } = useApplicationHasWarnings(id)

    if (isRedirectingToClassification) {
        return (
            <Flex grow="1" justify="center" align="center" direction="column">
                <LoaderView overBackground="back.background" />
                <Spacer height={100} />
            </Flex>
        )
    }

    return (
        <MerchantApplicationFieldDependenciesProvider formData={form.data} formMounted={formMounted}>
            <MerchantApplicationTopBar controls={applicationControls} applicationId={id} />
            <NavigationContainer>
                <CenteredScreen>
                    <OffsetContent>
                        {hasWarnings && (
                            <Flex>
                                <Sidebar />
                                <ContentWrapper>
                                    <ContentGrid>
                                        <ApplicationWarnings applicationId={id} />
                                    </ContentGrid>
                                </ContentWrapper>
                            </Flex>
                        )}
                        <Flex>
                            <Sidebar hasMargin={hasWarnings}>
                                <MerchantApplicationNavigation applicationId={id} />
                            </Sidebar>
                            <ContentWrapper>
                                <ContentGrid>
                                    {hasWarnings && (
                                        <>
                                            <ApplicationSectionHeading>Application</ApplicationSectionHeading>
                                            <div />
                                        </>
                                    )}
                                    <MerchantApplicationDedicatedSectionCompany
                                        key={'company'}
                                        errors={errors}
                                        form={form}
                                        focusInvalidField={focusInvalidField}
                                        executeBatchChanges={executeBatchChanges}
                                        formRef={formRef}
                                        applicationId={id}
                                    />
                                    {sequentialLoad > 0 && (
                                        <MerchantApplicationDedicateSectionContact
                                            key={'contract'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                    {sequentialLoad > 1 && (
                                        <MerchantApplicationDedicateSectionOwnership
                                            key={'ownership'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                    {sequentialLoad > 2 && <DedicatedSectionSeparator id="people" title="PEOPLE" />}
                                    {sequentialLoad > 2 && (
                                        <MerchantApplicationDedicatedSectionPeople
                                            key={'people'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                    {sequentialLoad > 3 && <DedicatedSectionSeparator title="BUSINESS MODEL" />}
                                    {sequentialLoad > 3 && (
                                        <MerchantApplicationDedicatedSectionBusinessModel
                                            key={'businessmodel'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                    {sequentialLoad > 4 && <DedicatedSectionSeparator title="WEBSITES" />}
                                    {sequentialLoad > 4 && (
                                        <MerchantApplicationDedicateSectionGateway
                                            key={'gateway'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                    {sequentialLoad > 4 && (
                                        <MerchantApplicationDedicateSectionReferralPartner
                                            key={'referralpartner'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                    {sequentialLoad > 4 && (
                                        <MerchantApplicationDedicatedSectionWebsites
                                            key={'websites'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                    {sequentialLoad > 5 && (
                                        <DedicatedSectionSeparator title="BANK ACCOUNT & ADDITIONAL INFO" />
                                    )}
                                    {sequentialLoad > 5 && (
                                        <MerchantApplicationDedicateSectionBankAccount
                                            key={'bankaccount'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                    {sequentialLoad > 6 && (
                                        <MerchantApplicationDedicatedSectionAdditional
                                            key={'key'}
                                            errors={errors}
                                            form={form}
                                            focusInvalidField={focusInvalidField}
                                            executeBatchChanges={executeBatchChanges}
                                            formRef={formRef}
                                            applicationId={id}
                                        />
                                    )}
                                </ContentGrid>
                            </ContentWrapper>
                        </Flex>
                        <Spacer height={80} />
                    </OffsetContent>
                </CenteredScreen>
                <ApplicationFieldModalInput applicationId={id} />
                <MerchantApplicationFieldSidebar />
            </NavigationContainer>
        </MerchantApplicationFieldDependenciesProvider>
    )
}

const Sidebar = styled.div<{ hasMargin?: boolean }>`
    width: 195px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: ${({ hasMargin }) => (hasMargin ? '65px' : '0')};

    @media (max-width: 1450px) {
        display: none;
    }
`

const ContentWrapper = styled.div`
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 16px;
    margin-bottom: 16px;
`

const OffsetContent = styled.div`
    margin-left: 0px;

    @media (max-width: 1300px) {
        padding: 0 30px 0 30px;
    }
`

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media (max-width: 1300px) {
        display: block;
    }
`

const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(380px, 600px) minmax(400px, 410px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    gap: 10px 30px;

    @media (min-width: 1700px) {
        grid-template-columns: minmax(600px, 700px) minmax(410px, 510px);
    }
    @media (max-width: 1250px) {
        flex-direction: row;
        grid-template-columns: 100%;
    }
`
