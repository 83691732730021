import React, { ReactElement, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { zIndexes } from '../../styles/zIndexes'
import { Pagination } from '../../utils'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { Flex } from '../layout/flex'
import { Floater } from '../layout/floater'
import { FloaterInset } from '../layout/floaterInset'
import { FiltersController, ListPageFiltersShowHideController } from '../../hooks/pages/useListPage'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { FiltersInfo, FiltersResultsInfo } from './filtersResultsInfo'
import { useOnClickOutside } from '../../hooks/general/useOnClickOutside'

export const HIDE_FILTERS_AT_WIDTH = 1600
export function Filters<T>(p: {
    showHideController: ListPageFiltersShowHideController
    filters: FiltersController<T>
    resultsCount: number
    children: React.ReactNode
    entityName: FiltersInfo
    hotkeysScope: MODAL_ID
    inline?: boolean
    shouldAlwaysHide?: boolean
    noClearButton?: boolean
    pagination?: Pagination
}): ReactElement {
    const [triggerRef, setTriggerRef] = useRefTaker()
    const [holder, setHolder] = useRefTaker()
    const activeFilters = p.filters.activeFilters()

    const actions = useMemo(() => {
        return {
            hide: (e?: any) => {
                e?.stopPropagation()
                p.showHideController.set((s) => {
                    return {
                        ...s,
                        showAll: false
                    }
                })
            },
            toggle: (e: any) => {
                e?.stopPropagation()
                p.showHideController.set((s) => {
                    return {
                        ...s,
                        showAll: !s.showAll
                    }
                })
            }
        }
    }, [p.showHideController])

    useOnClickOutside(holder, actions.hide)

    return (
        <Flex align="center">
            <FiltersResultsInfo
                filters={p.filters}
                noClearButton={p.noClearButton}
                loadingStatus="done"
                key="resultsInfo"
                entityName={p.entityName}
                onTextClick={actions.toggle}
                onClearFiltersClicked={actions.hide}
                resultsCount={p.resultsCount}
                pagination={p.pagination}
                hotkeysScope={p.hotkeysScope}
            />
            <Capsule inline={p.inline} shouldAlwaysHide={p.shouldAlwaysHide}>
                <Holder ref={setHolder}>
                    <div ref={setTriggerRef}>
                        <SimpleButton
                            background="front.accent.color"
                            onClick={actions.toggle}
                            cy="filters"
                            hotkeysScope={p.hotkeysScope}
                            hotkeys="alt+f"
                            hotkeysClueDirection="bottom-left"
                        >
                            <ButtonInset>
                                <ButtonMinWidth>
                                    {activeFilters.length ? `Active filters (${activeFilters.length})` : 'Filters'}
                                </ButtonMinWidth>
                            </ButtonInset>
                        </SimpleButton>
                    </div>
                    <Floater
                        cardId="ListPage.Filters"
                        anchor={triggerRef}
                        shouldShow={p.showHideController.showAll}
                        onHide={actions.hide}
                        cy="filters-menu"
                    >
                        <FloaterInset equalPadding padding="medium">
                            {p.children}
                        </FloaterInset>
                    </Floater>
                </Holder>
            </Capsule>
        </Flex>
    )
}

const Capsule = styled.div<{ inline?: boolean; shouldAlwaysHide?: boolean }>`
    display: none;
    ${(p) =>
        p.inline
            ? css`
                  display: contents;
              `
            : css`
                  @media (max-width: ${HIDE_FILTERS_AT_WIDTH}px) {
                      display: contents;
                  }
              `}

    ${(p) =>
        p.shouldAlwaysHide
            ? css`
                  display: none !important;
              `
            : null}
`

const Holder = styled.div`
    position: relative;
    z-index: ${zIndexes.popover};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .filterBox {
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }
    }
`

const ButtonMinWidth = styled.div`
    min-width: 120px;
    text-align: center;
    justify-content: center;
    text-align: center;
`
