import { cva, VariantProps } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('', {
    variants: {
        whiteSpace: {
            normal: 'whitespace-normal',
            nowrap: 'whitespace-nowrap',
            pre: 'whitespace-pre',
            preLine: 'whitespace-pre-line',
            preWrap: 'whitespace-pre-wrap',
            breakSpaces: 'whitespace-break-spaces'
        }
    }
})
