import { PageHeader } from '@/components/layout/pageHeader'
import { TaskState } from '@/services/taskr'
import React from 'react'
import { mapTaskState } from '../../../helpers'
import { useRouterMatchFilter } from '../../../hooks'

export const ScheduledTasksPageHeader: React.FC = () => {
    const state = useRouterMatchFilter()

    const subtitle = mapTaskState(state as TaskState).replace('_', ' ')

    return <PageHeader noBottomBorder title="ODD tasks" subtitle={subtitle} />
}
