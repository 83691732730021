import moment from 'moment'
import React from 'react'

import { Dispute } from '../../store/disputes/types'
import { numberWithCurrency, uppercaseFirstLetter } from '../../utils'
import { List } from '../../components/layout/list'
import { Text } from '../../components/general/text'
import { TimelineCard } from '../../components/listPages/timelineCard'
import { Separator } from '../../components/layout/separator'
import { Spacer } from '../../components/layout/spacer'
import styled from 'styled-components'

export const DisputeTimelineCard: React.FC<{ dispute: Dispute }> = ({ dispute }) => {
    return (
        <TimelineCard title={<Text bold>Dispute</Text>} background="front.subtleDanger.background">
            <List
                background="front.subtleDanger.background"
                items={{
                    Type: uppercaseFirstLetter(dispute.type),
                    RC: dispute.reasonCode,
                    Reason: dispute.reason ? uppercaseFirstLetter(dispute.reason) : '',
                    Amount: `${numberWithCurrency(dispute.currency, `${dispute.amount}`,
                        dispute.openedAt)} ${dispute.currency}`,
                    Opened: moment(dispute.openedAt).format('DD MMM YYYY')
                }}
                template="130px min-content auto  min-content 100px"
                switchToRowsAt={500}
            />
            <Spacer height={25} />
            <Separator background="front.subtleDanger.background" />
            <Spacer height={25} />
            <List
                background="front.subtleDanger.background"
                items={{
                    ' ': ' ',
                    'Due': {
                        type: 'custom',
                        node: <Due>{moment(dispute.dueAt).format('DD MMM YYYY')}</Due>
                    },
                    'Expires': dispute.expiresAt ? moment(dispute.expiresAt).format('DD MMM YYYY') : '-',
                    'Reference': dispute.reference
                }}
                template="130px 100px 100px auto"
                switchToRowsAt={500}
            />
        </TimelineCard>
    )
}

const Due = styled.div`
    background-color: ${(p) => p.theme['front.subtleDanger.background.strongerII']};
    padding: 1px 7px;
    border-radius: 8px;
    margin: -1px -7px;
`
