import React, { useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../components/buttons/buttonInset'
import { LinkButton } from '../components/buttons/linkButton'
import { LinkButtonArrow } from '../components/buttons/linkButtonArrow'
import { SimpleButton } from '../components/buttons/simpleButton'
import { Card } from '../components/cards/card'
import { CardInset } from '../components/cards/cardInset'
import { Text } from '../components/general/text'
import { Flex } from '../components/layout/flex'
import { ModalPage } from '../components/layout/modalPage'
import { Spacer } from '../components/layout/spacer'
import { Spinner } from '../components/loaders/spinner'
import { TextLineLoader } from '../components/loaders/textLineLoader'
import { ModalHeader } from '../components/modals/modalHeader'
import { useRouteGuard } from '../hooks/general/useRouteGuard'
import { RootState } from '@/store'
import { SettlementsReservesActions } from '../store/settlementsReserves/actions'
import { numberWithCurrency, uppercaseFirstLetter } from '../utils'
import { CriticalFlowSettlementsStatusArea } from './CriticalFlowSettlementsStatusArea'
import { CriticalFlowSettlementsTable } from './CriticalFlowSettlementsTable'

export const CriticalFlowSettlements: React.FC = () => {
    const reserves = useSelector((state: RootState) => state.settlementsReserves?.forMerchantId)
    const flowContext = useSelector((state: RootState) => state.interface.criticalFlow?.context?.forMerchantId)
    const mid = useMemo(() => {
        if (Object.keys(flowContext).length > 1) throw new Error('More than 1 MID detected, clear browser localStorage')
        return Object.keys(flowContext)[0]
    }, [flowContext])
    const settlementId = reserves?.[mid]?.openSettlementId
    const openSettlement = useSelector((state: RootState) =>
        settlementId ? state.settlements.settlements.at[settlementId] : undefined
    )
    const loadingStatus = useSelector(
        (state: RootState) => state.settlementsReserves.forMerchantId?.[mid]?.reserves?.loadingStatus
    )

    const state = useMemo(() => {
        return reserves[mid]
    }, [reserves, mid])

    const closeable = useMemo(() => {
        if (!state) return true
        return state.phase === 'selection' || state.phase === 'final'
    }, [state])

    useRouteGuard(
        `You're in the process of closing a selection of settlement reserves. Are you sure you want to leave?`,
        closeable
    )

    const dispatch = useDispatch()

    const cancelFlow = useCallback(() => {
        dispatch(SettlementsReservesActions.CANCEL_FLOW())
    }, [dispatch])

    const currency = useMemo(() => {
        if (!state) return ''
        return state.currency
    }, [state])

    useEffect(() => {
        if (!loadingStatus || loadingStatus === 'not-started')
            dispatch(SettlementsReservesActions.FETCH_SETTLEMENTS_WITH_RESERVES(mid))
    }, [dispatch, mid, loadingStatus])

    const content = useMemo(() => {
        if (loadingStatus !== 'done' || !state)
            return (
                <Flex grow justify="center" column align="center">
                    <Spinner overBackground="front.background" />
                </Flex>
            )

        if (state.phase === 'final') {
            const execution = state.executing

            const buildNumber = (
                t: RootState['settlementsReserves']['forMerchantId']['X']['reserves']['at']['X']['type']
            ) => {
                const array = Object.keys(execution).filter((id) => {
                    return state.cachedReserves.at?.[id]?.type === t
                })

                const status = {
                    all: array,
                    done: array.filter((id) => execution[id] === 'done'),
                    failed: array.filter((id) => execution[id] === 'failed'),
                    skipped: array.filter((id) => execution[id] === 'pending')
                }

                const selectedAmount = status.all.reduce((acc, i) => {
                    if (!state.cachedReserves.at[i]) throw `${t} at ${i} does not exist`
                    acc += state.cachedReserves.at[i].amount
                    return acc
                }, 0)

                const amountCancelled = status.done.reduce((acc, i) => {
                    if (!state.cachedReserves?.at[i]) throw `${t} at ${i} does not exist`
                    acc += state.cachedReserves.at[i].amount
                    return acc
                }, 0)

                return {
                    status,
                    selectedAmount,
                    amountCancelled
                }
            }

            const colorForStatus = {
                done: 'front.accent.color',
                failed: 'front.danger.color',
                skipped: 'front.text.subtlerI'
            }

            const payouts = buildNumber('payout')
            const reserves = buildNumber('reserve')

            const totalSelectedAmount = payouts.selectedAmount + reserves.selectedAmount
            const totalAmountCancelled = payouts.amountCancelled + reserves.amountCancelled

            if (currency)
                return (
                    <Flex column grow justify="center">
                        <Card>
                            <CardInset>
                                <MaxWidth>
                                    <Flex column align="flex-start" justify="center" grow>
                                        <Text size="xl" bold>
                                            Funds cancelling overview
                                        </Text>
                                        <Spacer height={10} />
                                        <Text>Here&apos;s an overview of the cancellation process:</Text>
                                        <RowHolder>
                                            <Row>
                                                <div />
                                                <Text align="right" color="front.text.subtlerI">
                                                    Payouts
                                                </Text>
                                                <Text align="right" color="front.text.subtlerI">
                                                    Reserves
                                                </Text>
                                            </Row>
                                            <Row>
                                                <Text color="front.text.subtlerI">Selected for cancellation</Text>
                                                <Text align="right" color="front.text.subtlerI">
                                                    {payouts.status.all.length}
                                                </Text>
                                                <Text align="right" color="front.text.subtlerI">
                                                    {reserves.status.all.length}
                                                </Text>
                                            </Row>
                                            {Object.keys(colorForStatus).map((label) => {
                                                const payoutsArray = (payouts.status as any)[label]
                                                const reservesArray = (reserves.status as any)[label]
                                                return (
                                                    <Row key={label}>
                                                        <Text
                                                            color={
                                                                payoutsArray.length || reservesArray.length
                                                                    ? (colorForStatus as any)[label]
                                                                    : 'front.text.subtlerII'
                                                            }
                                                            bold={
                                                                payoutsArray.length || reservesArray.length
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            {uppercaseFirstLetter(label)}
                                                        </Text>
                                                        <Text
                                                            align="right"
                                                            bold={!!payoutsArray.length}
                                                            color={
                                                                payoutsArray.length
                                                                    ? (colorForStatus as any)[label]
                                                                    : 'front.text.subtlerII'
                                                            }
                                                        >
                                                            {payoutsArray.length}
                                                        </Text>
                                                        <Text
                                                            align="right"
                                                            bold={!!reservesArray.length}
                                                            color={
                                                                reservesArray.length
                                                                    ? (colorForStatus as any)[label]
                                                                    : 'front.text.subtlerII'
                                                            }
                                                        >
                                                            {reservesArray.length}
                                                        </Text>
                                                    </Row>
                                                )
                                            })}
                                        </RowHolder>
                                        <Text>
                                            Total amount cancelled:{' '}
                                            <Text bold>
                                                {numberWithCurrency(currency, `${totalAmountCancelled}`)} {currency}
                                            </Text>
                                            .&nbsp;
                                            {totalSelectedAmount - totalAmountCancelled <= 0 ? (
                                                <>All selected reserves have been cancelled.</>
                                            ) : (
                                                <Text color="front.danger.color">
                                                    Amount left for cancelling based on your initial selection:{' '}
                                                    <Text bold>
                                                        {numberWithCurrency(
                                                            currency,
                                                            `${totalSelectedAmount - totalAmountCancelled}`
                                                        )}{' '}
                                                        {currency}.
                                                    </Text>
                                                </Text>
                                            )}
                                        </Text>
                                    </Flex>
                                    <Spacer height={10} />
                                    <SimpleButton
                                        background="front.accent.color"
                                        onClick={() => {
                                            dispatch(SettlementsReservesActions.FINISH_RESERVES_CANCELLATION(mid))
                                        }}
                                    >
                                        <ButtonInset>Back to merchant&apos;s funds</ButtonInset>
                                    </SimpleButton>
                                </MaxWidth>
                            </CardInset>
                        </Card>
                    </Flex>
                )
        }

        return (
            <CustomCard higher background="front.highlights">
                <ScrollingSide>
                    <Spacer height={5} />
                    <CriticalFlowSettlementsTable mid={mid} />
                    <Spacer height={10} />
                </ScrollingSide>
                <BottomPart>
                    <CardInset>
                        <Flex grow justify="space-between">
                            <CriticalFlowSettlementsStatusArea mid={mid} />
                        </Flex>
                    </CardInset>
                </BottomPart>
            </CustomCard>
        )
    }, [state, mid, currency, dispatch, loadingStatus])

    return (
        <ModalPage
            title={`${mid} · Upcoming payments`}
            pageId="Merchant.ApplicationPage.TagsManager"
            key="applicationTagsManager"
        >
            <ModalHolder>
                <ModalHeader
                    pageId="Merchant.ApplicationPage.TagsManager"
                    onBack={closeable ? cancelFlow : undefined}
                    noStateBasedRedirect
                    title={`${mid} · Upcoming payments`}
                />
                {content}
                {openSettlement?.account ? (
                    <BottomBlock>
                        <div>
                            All funds will be appended to{' '}
                            <Text bold>
                                {openSettlement?.account.name} ({openSettlement?.account.merchantId})
                            </Text>
                            &apos;s&nbsp;upcoming settlement
                            {openSettlement?.payout ? (
                                <Text>
                                    , which will be paid out on <Text bold>{openSettlement?.payout?.date}.</Text>
                                </Text>
                            ) : null}
                        </div>
                        <Flex align="baseline">
                            <LinkButton
                                target="blank"
                                color="front.accent.color"
                                to={
                                    `/settlements/${openSettlement.id}?&` +
                                    `settlements_mid=${openSettlement.account.merchantId}&skip-critical-flows=true`
                                }
                            >
                                See upcoming settlement
                                <Spacer width={5} />
                                <LinkButtonArrow shouldBeBordered background="overlay.background" />
                            </LinkButton>
                        </Flex>
                    </BottomBlock>
                ) : (
                    <BottomBlock>
                        <BottomBlockLoaderInset>
                            <TextLineLoader minWidth={150} height={3} />
                            <TextLineLoader minWidth={240} height={3} />
                        </BottomBlockLoaderInset>
                    </BottomBlock>
                )}
            </ModalHolder>
        </ModalPage>
    )
}

const BottomBlockLoaderInset = styled.div`
    display: flex;
    width: 100%;
    height: 18px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 0px;
`

const ModalHolder = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
`

const BottomBlock = styled.div`
    background-color: ${(p) => p.theme['overlay.background.strongerI']};
    color: ${(p) => p.theme['front.text']};
    margin: 0 auto;
    max-width: 1080px;
    width: 100vw;
    padding: 20px 20px 20px 20px;
    border-radius: 15px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 20px;
`

const MaxWidth = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    max-width: 400px;
`

const RowHolder = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    margin-top: 10px;
    padding: 20px 0;
    margin: 20px 0;
    border-top: 1px solid ${(p) => p.theme['front.border']};
    border-bottom: 1px solid ${(p) => p.theme['front.border']};
    width: 100%;
`

const Row = styled.div`
    display: contents;
    align-items: baseline;

    & > * {
        padding: 5px 0;
        border-bottom: 1px solid ${(p) => p.theme['front.border']};
    }

    &:last-child {
        & > * {
            border-bottom: none;
        }
    }
`

const ScrollingSide = styled.div`
    overflow: auto;
    flex-shrink: 1;
    flex-grow: 1;
    padding: 10px;
    box-sizing: border-box;
    overscroll-behavior-y: none;
`

const CustomCard = styled(Card)`
    width: 100vw;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    flex-basis: 0;
    min-height: 0;
    flex-grow: 1;
    margin: 0px 0 20px 0;
`

const BottomPart = styled.div`
    z-index: 1000;
    flex-shrink: 0;
    width: calc(100%);
    background-color: ${(p) => p.theme['front.background']};
    border-top: 1px solid ${(p) => p.theme['back.border']};
    border-radius: 0 0 16px 16px;
`
