import { Text } from '../../../components/general/text'
import { TopBar } from '../../../components/layout/topBar'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { useHaveApplicationInternalDetailsLoaded } from './useHaveApplicationInternalDetailsLoaded'

export const MerchantApplicationTopBar: React.FC<{
    controls: any
    applicationId: string
}> = ({ controls, applicationId }) => {
    const haveInternalDetailsLoaded = useHaveApplicationInternalDetailsLoaded(applicationId)

    return (
        <TopBar
            leftSide={
                haveInternalDetailsLoaded ? (
                    <Text color="overlay.text" bold>
                        Merchant&apos;s application
                    </Text>
                ) : (
                    <TextLineLoader minWidth={120} />
                )
            }
            rightSide={controls}
        />
    )
}
