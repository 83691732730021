import { useGetApplication, useGetCompany } from '@/services/merchant-api'
import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'

export const useApplicationCompany = (applicationId: string) => {
    const url = `${import.meta.env.VITE_API_ROOT}/applications/${applicationId}`

    // Fetch application
    const application = useGetApplication(url)

    // Fetch company
    const link = application.data?.relations.company
    const company = useGetCompany(application.isSuccess && link ? link : skipToken)

    // Result
    const isLoading = application.isLoading || company.isLoading
    const isError = application.isError || company.isError
    const data = company.data

    const result = useMemo(() => ({ data, isLoading, isError }), [data, isLoading, isError])

    return result
}
