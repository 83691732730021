import React, { useRef } from 'react'
// import { OverlayTrigger, Tooltip } from "react-bootstrap"
import moment from 'moment'
import styled from 'styled-components'
import { Tooltip } from './tooltip'
import { PrintOnly } from './printOnly'

export const RelativeDate: React.FC<{
    dateString?: string
    relativeFormat?: string
    withFormat?: string
}> = ({ dateString, withFormat, relativeFormat }) => {
    const spanRef = useRef<any>()

    return (
        <span ref={spanRef}>
            <ShortDate>
                {relativeFormat ? moment(dateString).format(relativeFormat) : moment(dateString).fromNow()}
            </ShortDate>
            <Tooltip element={spanRef} tooltip tooltipTiming="instant">
                {moment(dateString).format(withFormat || 'MMMM DD YYYY, HH:mm:ss')}
            </Tooltip>
            <PrintOnly inline>{moment(dateString).format('YYYY/MM/DD, HH:mm')}</PrintOnly>
        </span>
    )
}

const ShortDate = styled.span`
    @media print {
        display: none !important;
    }
`
