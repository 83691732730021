import React from 'react'
import { Section } from '../../../common/section'
import { trustpilotSearch } from '@/utils/trustpilotSearch'
import { ToggleContent } from '../toggle-content/toggle-content'
import { useReportMerchantData, useReportSection } from '../../../provider/report-provider/report-provider'
import { SectionCheck } from '@/services/ongoing-due-diligence'

export const TrustpilotSatisfying: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')
    const merchantData = useReportMerchantData()

    const data = checks.trustpilot_satisfying
    const commentData = checks.comment_trustpilot_satisfying

    const trustpilotLink = merchantData.company.name && trustpilotSearch(merchantData.company.name)

    return (
        <Section>
            <Section.Content>
                <ToggleContent
                    dataName="trustpilot_satisfying"
                    data={data}
                    commentName={'comment_trustpilot_satisfying'}
                    commentData={commentData}
                    visibleValue="No"
                />
            </Section.Content>
            <Section.Metadata>
                <Section.ExternalLink url={trustpilotLink}>Trustpilot</Section.ExternalLink>
            </Section.Metadata>
        </Section>
    )
}
