import { css } from 'styled-components'
import { Theme } from '../hooks/general/useTheme'
import { Color } from '../styles/colors'

const commonStyles = css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
`

const selectShadingTypeBasedOnBackground = (
    color?: Color | 'currentColor',
    isDisabled?: boolean
): {
    hover: Color | 'currentColor'
    active: Color | 'currentColor'
} => {
    if (color === 'currentColor')
        return {
            hover: 'currentColor',
            active: 'currentColor'
        }
    if (isDisabled)
        return {
            hover: color || 'front.background',
            active: color || 'front.background'
        }
    if (color?.includes('subtler')) {
        return {
            hover: `${color
                .replace('.subtlerIII', '')
                .replace('.subtlerII', '')
                .replace('.subtlerI', '')}.strongerI` as Color,
            active: `${color
                .replace('.subtlerIII', '')
                .replace('.subtlerII', '')
                .replace('.subtlerI', '')}.strongerII` as Color
        }
    }
    if (color?.includes('stronger')) {
        return {
            hover: `${color.replace('.strongerI', '')}.subtlerII` as Color,
            active: `${color.replace('.strongerI', '')}.subtlerIII` as Color
        }
    }

    if (!color)
        return {
            hover: `front.danger.color` as Color,
            active: `front.danger.color` as Color
        }

    return {
        hover: `${color}.strongerI` as Color,
        active: `${color}.strongerII` as Color
    }
}
const selectShadowTypeBasedOnBackground = (color?: Color, textColor?: Color | 'currentColor'): keyof Theme => {
    switch (color) {
        case 'front.info.background':
        case 'front.background':
            return 'subtleButtonsShadow'
        default:
            return 'buttonsShadow'
    }
}
const selectTextColorBasedOnBackground = (color?: Color, textColor?: Color): Color => {
    let correspondingTextColor = textColor

    switch (color) {
        case 'front.success.color':
            correspondingTextColor = 'front.success.text'
            break
        case 'floating.subtleAccent.background':
            correspondingTextColor = 'floating.subtleAccent.text'
            break
        case 'floating.background':
            correspondingTextColor = 'floating.text'
            break
        case 'floating.background.strongerI':
            correspondingTextColor = 'floating.text'
            break
        case 'front.info.background':
            correspondingTextColor = 'front.info.text'
            break
        case 'front.accent.color':
            correspondingTextColor = 'front.accent.text'
            break
        case 'side.subtleAccent.background':
            correspondingTextColor = 'side.subtleAccent.text'
            break
        case 'front.subtleAccent.background':
            correspondingTextColor = 'front.subtleAccent.text'
            break
        case 'front.subtleSuccess.background':
            correspondingTextColor = 'front.subtleSuccess.text'
            break
        case 'overlay.background':
            correspondingTextColor = 'overlay.text'
            break
        case 'side.background':
            correspondingTextColor = 'side.accent.color'
            break
        case 'front.danger.color':
            correspondingTextColor = 'front.danger.text'
            break
        case 'front.danger.color.subtlerII':
            correspondingTextColor = 'front.danger.text'
            break
        default:
            correspondingTextColor = 'front.accent.color'
            break
    }
    if (textColor) {
        return textColor
    } else {
        return correspondingTextColor
    }
}

const checkIfBackgroundShouldHaveBoxShadow = (color?: Color): boolean => {
    if (!color) return false
    if ((color as string).includes('subtle')) return false
    if ((color as string).includes('less')) return false
    if ((color as string).includes('more')) return false
    if ((color as string) === 'side.background') return false
    if ((color as string).includes('floating.background')) return false
    switch (color) {
        default:
            return true
    }
}

const selectUnderlineBasedOnBackground = (color?: Color, textColor?: Color | 'currentColor'): boolean => {
    if (color === undefined) {
        return true
    }
    return false
}

export const generateButtonStyles = (
    color?: Color,
    textColor?: Color | 'currentColor',
    isDisabled?: boolean,
    isPressed?: boolean,
    tabIndex?: number
): any => {
    const shade = selectShadingTypeBasedOnBackground(color, isDisabled)
    const inferredTextColor =
        textColor === 'currentColor' ? 'currentColor' : selectTextColorBasedOnBackground(color, textColor)
    const shouldUnderline = selectUnderlineBasedOnBackground(color, textColor)
    const shadow = selectShadowTypeBasedOnBackground(color, textColor)
    const textShade = color
        ? { hover: inferredTextColor, active: inferredTextColor }
        : selectShadingTypeBasedOnBackground(inferredTextColor)
    const shouldHaveBoxShadow = checkIfBackgroundShouldHaveBoxShadow(color)

    return css`
        ${commonStyles};
        background-color: ${(p) => (color ? p.theme[color] : 'transparent')};
        color: ${(p) => (inferredTextColor === 'currentColor' ? 'currentColor' : p.theme[inferredTextColor])};
        box-shadow: ${(p) => (shouldHaveBoxShadow ? p.theme[shadow] : 'none')} !important;
        ${color?.includes('subtle') &&
        css`
            border: 1px solid rgba(0, 0, 0, 0.05) !important;
        `}

        &:hover {
            background-color: ${(p) => (color ? p.theme[shade.hover as Color] : 'transparent')};
            color: ${(p) => (textShade.active === 'currentColor' ? 'currentColor' : (p.theme as any)[textShade.hover])};
            text-decoration: ${shouldUnderline ? 'underline' : 'none'};
        }

        &:active {
            background-color: ${(p) => (color ? p.theme[shade.active as Color] : 'transparent')};
            color: ${(p) =>
                textShade.active === 'currentColor' ? 'currentColor' : (p.theme as any)[textShade.active]};
        }
        &.active {
            cursor: default;
            color: ${(p) =>
                textShade.active === 'currentColor' ? 'currentColor' : (p.theme as any)[textShade.active]};
        }

        &:focus {
            background-color: ${(p) => (color ? p.theme[shade.hover as Color] : 'transparent')};
            color: ${(p) => (textShade.active === 'currentColor' ? 'currentColor' : (p.theme as any)[textShade.hover])};
            text-decoration: ${shouldUnderline ? 'underline' : 'none'};
        }
        ${isDisabled || isPressed
            ? css`
                  cursor: default !important;
                  opacity: 0.65;
                  filter: grayscale(1);
              `
            : null}
        ${(p) =>
            tabIndex !== undefined && tabIndex !== -1
                ? css`
                      &:focus {
                          box-shadow:
                              0px 0px 0px 1.5px
                                  ${(p) =>
                                      inferredTextColor === 'currentColor'
                                          ? 'currentColor'
                                          : p.theme[inferredTextColor]},
                              0px 0px 0px 2.5px ${(p) => (color ? p.theme[color] : 'transparent')} !important;
                      }
                  `
                : null};
    `
}
