import { SectionDocumentation } from '@/services/ongoing-due-diligence'

import React from 'react'
import { Section } from '../../../common/section'
import { useReportSection } from '../../../provider/report-provider/report-provider'
import { ManagementDataItems } from './management-data-items'
import { ManagementMetadata } from './management-metadata'

export const Management: React.FC = () => {
    const section = useReportSection<SectionDocumentation>('documentation')

    return (
        <Section>
            <Section.Content>
                <Section.Field name="management" data={section.management} />
                <Section.Spacer size="s" />
                <ManagementDataItems />
            </Section.Content>
            <ManagementMetadata />
        </Section>
    )
}
