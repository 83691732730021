import { createActionCreator } from 'deox'

import {
    MerchantApplicationInternalFieldCheck,
    MerchantApplicationsInternalsChecksState
} from './types'

// --

const Prefix = 'APPLICATION_INTERNALS_CHECKS'

export const ApplicationInternalsChecksActions = {
    TOGGLE_SECTION_CHECK: createActionCreator(
        `${Prefix}/TOGGLE_SECTION_CHECK`,
        (resolve) => (
            watcherId: string,
            applicationId: string,
            checkPath: string,
            fieldKey: string
        ) => resolve({ watcherId, applicationId, checkPath, fieldKey })
    ),
    STORE_CHECKS: createActionCreator(
        `${Prefix}/STORE_CHECKS_STATUS`,
        (resolve) => (
            applicationId: string,
            checks: MerchantApplicationsInternalsChecksState
        ) => resolve({ applicationId, checks })
    ),
    STORE_CHECK: createActionCreator(
        `${Prefix}/STORE_CHECK`,
        (resolve) => (
            applicationId: string,
            checkPath: string,
            fieldKey: string,
            check: MerchantApplicationInternalFieldCheck
        ) => resolve({ applicationId, check, checkPath, fieldKey })
    )
}
