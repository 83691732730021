import { startCase } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { TextInput } from '../../../components/forms/textInput'
import { IconPaymentMethod } from '../../../components/icons/iconPaymentMethod'
import { FormFieldWithFormattedNumber, uppercaseFirstLetter } from '../../../utils'
import { MerchantAccountContractsProps } from './Accounts.ID.ContractProps'
import { ContractValidationRules } from './utils'
import { ContractCard } from '../../../components/cards/contractCard'
import { Offset } from '../../../components/layout/offset'
import { Icon } from '../../../components/icons/icon'
import { useContractSectionHelper } from './useContractSectionHelper'

export const MerchantAccountContractsSectionServiceCharges: React.FC<MerchantAccountContractsProps> = ({
    contract,
    draftOrTemplate,
    formErrors,
    formRef,
    isDisabled,
    currency
}) => {
    const { isEditable, getValue } = useContractSectionHelper(contract, draftOrTemplate)

    const renderRow = useCallback(
        (tabIndex: number, region: 'inter' | 'intra' | 'domestic', scheme: 'visa' | 'mastercard') => {
            const renderInput = (
                tabIndex: number,
                segment: 'consumer' | 'business' | undefined,
                type: 'debit' | 'credit'
            ) => {
                const zone = (() => {
                    if (region == 'domestic') return 'domestic'
                    return region + 'Region'
                })()
                const localPath = `rates_${scheme}_${segment}.${zone + uppercaseFirstLetter(type)}`

                return (
                    <>
                        <TextInput
                            placeholder=""
                            isSeamless
                            initialValue={getValue(localPath)}
                            validation={formErrors[localPath]}
                            ref={(ref) =>
                                formRef(
                                    ref,
                                    localPath,
                                    ContractValidationRules.limitedPercentage,
                                    FormFieldWithFormattedNumber
                                )
                            }
                            cy={`${scheme}${startCase(region)}${startCase(type)}${startCase(segment)}`}
                            isDisabled={isDisabled || !isEditable || !localPath}
                            rightAlign
                            tabIndex={tabIndex}
                            overBackground="front.background"
                            suffix="%"
                        />
                    </>
                )
            }

            return (
                <DoubleRow>
                    <Flex>
                        <Text color="front.text.subtlerI">{startCase(region)}</Text>
                        <Spacer width={20} />
                    </Flex>
                    {renderInput(tabIndex, 'consumer', 'debit')}
                    {renderInput(tabIndex + 20, 'consumer', 'credit')}
                    <span key="span" />
                    <ColorWrap key="business-debit">{renderInput(tabIndex + 30, 'business', 'debit')}</ColorWrap>
                    <ColorWrap key="business-credit">{renderInput(tabIndex + 40, 'business', 'credit')}</ColorWrap>
                </DoubleRow>
            )
        },
        [formRef, getValue, formErrors, isEditable, isDisabled]
    )

    const renderCardHeader = useCallback((scheme: 'visa' | 'mastercard') => {
        return (
            <>
                {' '}
                <DoubleRow mainHeaderRow>
                    <HeaderCell>
                        <Offset left={scheme === 'visa' ? -10 : -12} top={scheme === 'visa' ? 1 : 0}>
                            <IconPaymentMethod noBox method="card" card={{ scheme }} size={32} />
                        </Offset>
                    </HeaderCell>
                    <HeaderCell spanTwice alignRight>
                        <Text bold>
                            <Offset top={1} marginRight={5} display="inline-block">
                                <Icon type="consumerFee" />
                            </Offset>
                            Consumer
                        </Text>
                    </HeaderCell>
                    <HeaderCell key="blank-header" />
                    <HeaderCell spanTwice alignRight key="business-header">
                        <Text bold>
                            <Offset top={1} marginRight={5} display="inline-block">
                                <Icon type="businessFee" />
                            </Offset>
                            Business
                        </Text>
                    </HeaderCell>
                </DoubleRow>
                <DoubleRow secondHeaderRow>
                    <HeaderCell></HeaderCell>
                    <HeaderCell alignRight>
                        <Text color="front.text.subtlerI">Debit</Text>
                    </HeaderCell>
                    <HeaderCell alignRight>
                        <Text color="front.text.subtlerI">Credit</Text>
                    </HeaderCell>
                    <HeaderCell key="span" />
                    <ColorWrap key="debit">
                        <HeaderCell alignRight>
                            <Text color="front.text.subtlerI">Debit</Text>
                        </HeaderCell>
                    </ColorWrap>
                    <ColorWrap key="credit">
                        <HeaderCell alignRight>
                            <Text color="front.text.subtlerI">Credit</Text>
                        </HeaderCell>
                    </ColorWrap>
                </DoubleRow>
            </>
        )
    }, [])

    const mastercard = useMemo(() => {
        return (
            <Flex column>
                {renderCardHeader('mastercard')}
                {renderRow(1, 'domestic', 'mastercard')}
                {renderRow(1, 'intra', 'mastercard')}
                {renderRow(1, 'inter', 'mastercard')}
            </Flex>
        )
    }, [renderRow, renderCardHeader])

    const visa = useMemo(() => {
        return (
            <Flex column>
                {renderCardHeader('visa')}
                {renderRow(100, 'domestic', 'visa')}
                {renderRow(100, 'intra', 'visa')}
                {renderRow(100, 'inter', 'visa')}
            </Flex>
        )
    }, [renderRow, renderCardHeader])

    const minimum = useMemo(() => {
        return (
            <Flex column>
                <SingleRow>
                    <Text color="front.text.subtlerI">Domestic</Text>
                    <SpannedInput>
                        <TextInput
                            placeholder=""
                            isSeamless
                            rightAlign
                            initialValue={getValue('charges.minimumDomesticTransactionFee')}
                            isDisabled={isDisabled || !isEditable}
                            validation={formErrors[`charges.minimumDomesticTransactionFee`]}
                            ref={(ref) =>
                                formRef(
                                    ref,
                                    `charges.minimumDomesticTransactionFee`,
                                    ContractValidationRules.limitedFee,
                                    FormFieldWithFormattedNumber
                                )
                            }
                            cy="minDomestic"
                            overBackground="front.background"
                            suffix={currency}
                        />
                    </SpannedInput>
                </SingleRow>
                <SingleRow>
                    <Text color="front.text.subtlerI">Intra</Text>
                    <SpannedInput>
                        <TextInput
                            placeholder=""
                            isSeamless
                            rightAlign
                            initialValue={getValue('charges.minimumIntraTransactionFee')}
                            validation={formErrors[`charges.minimumIntraTransactionFee`]}
                            ref={(ref) =>
                                formRef(
                                    ref,
                                    `charges.minimumIntraTransactionFee`,
                                    ContractValidationRules.limitedFee,
                                    FormFieldWithFormattedNumber
                                )
                            }
                            cy="minIntra"
                            isDisabled={isDisabled || !isEditable}
                            overBackground="front.background"
                            suffix={currency}
                        />
                    </SpannedInput>
                </SingleRow>
                <SingleRow>
                    <Text color="front.text.subtlerI">Inter</Text>
                    <SpannedInput>
                        <TextInput
                            initialValue={getValue('charges.minimumInterTransactionFee')}
                            validation={formErrors[`charges.minimumInterTransactionFee`]}
                            ref={(ref) =>
                                formRef(
                                    ref,
                                    `charges.minimumInterTransactionFee`,
                                    ContractValidationRules.limitedFee,
                                    FormFieldWithFormattedNumber
                                )
                            }
                            isDisabled={isDisabled || !isEditable}
                            placeholder=""
                            isSeamless
                            rightAlign
                            cy="minInter"
                            overBackground="front.background"
                            suffix={currency}
                        />
                    </SpannedInput>
                </SingleRow>
            </Flex>
        )
    }, [formErrors, currency, getValue, formRef, isDisabled, isEditable])

    return (
        <Flex align="stretch">
            <MinColumnHolder>
                <ContractCard showEditable={isEditable} title="Minimum">
                    <CardInset>{minimum}</CardInset>
                </ContractCard>
            </MinColumnHolder>
            <Spacer width={20} />
            <Flex column shrink>
                <ContractCard grow showEditable={isEditable}>
                    <Flex>
                        <CardInset>{mastercard}</CardInset>
                    </Flex>
                </ContractCard>
                <Spacer width={10} />
                <ContractCard grow showEditable={isEditable}>
                    <Flex>
                        <CardInset>{visa}</CardInset>
                    </Flex>
                </ContractCard>
            </Flex>
        </Flex>
    )
}

const MinColumnHolder = styled.div`
    display: flex;
    flex-direction: column;
    width: 320px;
    flex-shrink: 0;

    & > div {
        height: auto;
    }
`

const DoubleRow = styled.div<{ mainHeaderRow?: boolean; secondHeaderRow?: boolean }>`
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 0.5fr 1fr 1fr;

    & > * {
        padding-top: 3px;
        padding-bottom: 1px;
    }

    ${(p) =>
        p.mainHeaderRow &&
        css`
            & > * {
                padding: 0px !important;
                height: 15px !important;
                padding-bottom: 16px !important;
                margin-top: -8px !important;
                box-sizing: content-box !important;
                margin-bottom: 5px;
            }
            border-bottom: 1px solid ${(p) => p.theme['front.border']};
        `}

    ${(p) =>
        p.secondHeaderRow &&
        css`
            & > * {
                padding: 0px !important;
                text-transform: uppercase !important;
                margin-top: 10px !important;
            }
            & * {
                text-transform: uppercase !important;
                font-weight: 500 !important;
                font-size: 11px !important;
                letter-spacing: 0.33px !important;
            }
        `}
`

const SingleRow = styled.div`
    display: grid;
    grid-template-columns: auto 90px 90px;
    & > * {
        padding-top: 3px;
        padding-bottom: 1px;
    }
`

const HeaderCell = styled.div<{ alignRight?: boolean; spanTwice?: boolean }>`
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    height: 35px;
    box-sizing: border-box;
    padding: 5px 0 5px 0;

    ${(p) =>
        p.spanTwice &&
        css`
            grid-column: span 2;
        `}
    ${(p) =>
        p.alignRight &&
        css`
            justify-content: flex-end;
        `}
`

const SpannedInput = styled.div`
    grid-column: span 2;
`

const ColorWrap = styled.div`
    background-color: ${(p) => p.theme['front.background.strongerI']};
    padding: 6px;
    margin: -6px;
    flex-grow: 1;
    text-align: right;
    box-sizing: border-box;
    position: relative !important;

    ${DoubleRow}:last-child & {
        &:nth-child(5) {
            border-radius: 0 0 0 10px !important;
        }
        &:last-child {
            border-radius: 0 0 10px 0 !important;
            z-index: 15 !important;
        }
    }

    ${DoubleRow}:nth-child(2) & {
        &:nth-child(5),
        &:last-child {
            padding: 0 6px !important;
            margin-left: -6px !important;
            margin-right: -6px !important;
            box-sizing: border-box !important;
        }
        &:nth-child(5) {
            z-index: 10 !important;
            border-radius: 10px 0 0 0 !important;
        }
        &:last-child {
            z-index: 0 !important;
            border-radius: 0 10px 0 0 !important;
            margin-right: -10px !important;
            padding-right: 10px !important;
        }
    }
    &:nth-child(5) {
        z-index: 20 !important;
    }
    &:last-child {
        margin-right: -10px !important;
        padding-right: 10px !important;
    }
`
