import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { FilePreviewer } from './components/modals/filePreviewer'
import { useQueryParams } from './hooks/general/useQueryParam'
import { isEqual } from 'lodash'

const queryParams = ['preview']

export const FilePreviewsRoot: React.FC = (p) => {
    const [previewStack, setPreviewStack] = useState([])
    const [query] = useQueryParams(queryParams, undefined)

    useEffect(() => {
        setPreviewStack((s) => {
            const queryArray = typeof query.preview === 'object' ? query.preview : query.preview ? [query.preview] : []

            if (isEqual(queryArray.sort(), s.sort())) {
                return s
            } else {
                let newS: any = [...(s || [])]
                newS = newS.filter((i: any) => queryArray.includes(i))
                queryArray.forEach((e: any) => {
                    if (!newS.includes(e)) newS = [...newS, e]
                })
                return newS
            }
        })
    }, [query])

    const moveFileToTop = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            const id =
                e.currentTarget?.getAttribute('data-id') ||
                e.currentTarget?.closest('[data-id]')?.getAttribute('data-id')

            if (!id) return

            return setPreviewStack((p) => {
                return [...p.filter((i) => i !== id), id] as any
            })
        },
        [setPreviewStack]
    )

    const filePreviews = useMemo(() => {
        return previewStack.map((p: any, i: number) => {
            return <FilePreviewer key={p} id={p} stackIndex={i} onFocus={moveFileToTop} />
        })
    }, [previewStack, moveFileToTop])

    return <>{filePreviews}</>
}
