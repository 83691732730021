/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from 'deox'
import { put, select } from 'typed-redux-saga'
import { findMerchantApplicationFieldWithMostCommentsRecursively } from './utils'
import { RootState } from '@/store'
import { InterfaceActions } from './actions'
import dotProp from 'dot-prop'
import { ConvertIndexPathStringToIndexPath } from '../applicationResources/utils'
import { ConvertIndexPathToFieldDetails } from '../../pages/Merchant/Application/Application.Structure'
import { WatcherDispatchSuccess } from '../watcher/actions'

export const InterfaceSagas = {
    *INIT_CRITICAL_FLOW_IF_IT_EXISTS() {
        const flow = localStorage.getItem('criticalFlow')
        if (flow) {
            yield put(InterfaceActions.START_CRITICAL_FLOW(JSON.parse(flow) as any))
        }
    },
    *ON_COMPLETE_CRITICAL_FLOW({ payload: p }: ActionType<typeof InterfaceActions.COMPLETE_CRITICAL_FLOW>) {
        if (p.watcherId) yield put(WatcherDispatchSuccess([p.watcherId]))
    },
    *START_APPLICATION_REVIEW({ payload: p }: ActionType<typeof InterfaceActions.START_APPLICATION_REVIEW>) {
        const commentsResources = yield* select(
            (state: RootState) => state.applicationResources.comments.forApplication[p.applicationId]
        )
        const mostCommentedField = findMerchantApplicationFieldWithMostCommentsRecursively(commentsResources)

        let fieldKey = Object.keys(p.changes)[0]

        if (mostCommentedField.indexPathString) {
            if (Object.keys(p.changes).length > 1) fieldKey = mostCommentedField.indexPathString
            else {
                // If there's only one change buy the field doesn't accept comments
                const ip = ConvertIndexPathStringToIndexPath(fieldKey)
                const fieldDetails = ip ? ConvertIndexPathToFieldDetails(ip) : undefined
                if (fieldDetails?.field?.hasCommentsDisabled) fieldKey = mostCommentedField.indexPathString
            }
        }

        const [section, index, ...rest] = fieldKey.split('.')
        const subsectionId = yield* select((state: RootState) =>
            dotProp.get(
                state,
                `applicationResources.data.forApplication.${p.applicationId}.${section}.fields.${index}.id`
            )
        )

        yield put(InterfaceActions.STORE_RECOMMENDED_MERCHANT_COMMENT_FIELD(p.applicationId, fieldKey, subsectionId))
    },
    *TOGGLE_THEME() {
        const previousTheme = localStorage.getItem('previousTheme') as any
        if (previousTheme) yield put(InterfaceActions.SWITCH_THEME(previousTheme))
    }
}
