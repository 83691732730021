import { Transaction, TransactionsFilters, TransactionSummary } from './types'

export const TRANSACTIONS_LOAD_SUMMARIES = 'TRANSACTIONS_LOAD_SUMMARIES'
export const TRANSACTIONS_STORE_SUMMARIES = 'TRANSACTIONS_STORE_SUMMARIES'
export const TRANSACTIONS_CLEAR_SUMMARIES = 'TRANSACTIONS_CLEAR_SUMMARIES'
export const TRANSACTIONS_LOAD_NEXT_SUMMARIES =
    'TRANSACTIONS_LOAD_NEXT_SUMMARIES'
export const TRANSACTIONS_APPEND_SUMMARIES = 'TRANSACTIONS_APPEND_SUMMARIES'
export const TRANSACTION_LOAD = 'TRANSACTION_LOAD'
export const TRANSACTION_STORE = 'TRANSACTION_STORE'
export const TRANSACTION_UNSTORE = 'TRANSACTION_UNSTORE'
export const TRANSACTION_LOAD_EXTRA_DETAILS = 'TRANSACTION_LOAD_EXTRA_DETAILS'
export const TRANSACTION_STORE_EXTRA_DETAILS = 'TRANSACTION_STORE_EXTRA_DETAILS'
export const TRANSACTION_LOAD_APPENDIX = 'TRANSACTION_LOAD_APPENDIX'
export const TRANSACTION_STORE_APPENDIX = 'TRANSACTION_STORE_APPENDIX'

export interface TransactionsActionLoadSummaries {
    type: typeof TRANSACTIONS_LOAD_SUMMARIES
    filters?: TransactionsFilters
}
export interface TransactionsActionClearSummaries {
    type: typeof TRANSACTIONS_CLEAR_SUMMARIES
}

export interface TransactionsActionStoreSummaries {
    type: typeof TRANSACTIONS_STORE_SUMMARIES
    summaries?: TransactionSummary[]
    nextLink?: string
    error?: string
}

export interface TransactionActionUnstore {
    type: typeof TRANSACTION_UNSTORE
    transactionId: string
}

export interface TransactionsActionLoadNextSummaries {
    type: typeof TRANSACTIONS_LOAD_NEXT_SUMMARIES
}

export interface TransactionsActionAppendSummaries {
    type: typeof TRANSACTIONS_APPEND_SUMMARIES
    summaries: TransactionSummary[]
    nextLink?: string
}

export interface TransactionActionLoadExtraDetails {
    type: typeof TRANSACTION_LOAD_EXTRA_DETAILS
    transaction: Transaction
}

export interface TransactionActionStoreExtraDetails {
    type: typeof TRANSACTION_STORE_EXTRA_DETAILS
    transaction: Transaction
}

export interface TransactionActionLoadAppendix {
    type: typeof TRANSACTION_LOAD_APPENDIX
    transaction: Transaction
}

export interface TransactionActionStoreAppendix {
    type: typeof TRANSACTION_STORE_APPENDIX
    transaction: Transaction
}

export interface TransactionActionLoad {
    type: typeof TRANSACTION_LOAD
    id: string
}

export interface TransactionActionStore {
    type: typeof TRANSACTION_STORE
    transaction: Transaction
}

//--

export const TransactionsDispatchLoadSummaries = (
    filters?: TransactionsFilters
): TransactionsActionLoadSummaries => {
    return {
        type: TRANSACTIONS_LOAD_SUMMARIES,
        filters
    }
}

export const TransactionsDispatchStoreSummaries = (
    summaries?: TransactionSummary[],
    nextLink?: string,
    error?: string
): TransactionsActionStoreSummaries => {
    return {
        type: TRANSACTIONS_STORE_SUMMARIES,
        summaries,
        nextLink,
        error
    }
}

export const TransactionDispatchUnstore = (
    transactionId: string
): TransactionActionUnstore => {
    return {
        type: TRANSACTION_UNSTORE,
        transactionId
    }
}

export const TransactionsDispatchLoadNextSummaries = (): TransactionsActionLoadNextSummaries => {
    return {
        type: TRANSACTIONS_LOAD_NEXT_SUMMARIES
    }
}

export const TransactionsDispatchAppendSummaries = (
    summaries: TransactionSummary[],
    nextLink?: string
): TransactionsActionAppendSummaries => {
    return {
        type: TRANSACTIONS_APPEND_SUMMARIES,
        summaries,
        nextLink
    }
}

export const TransactionDispatchLoad = (id: string): TransactionActionLoad => {
    return {
        type: TRANSACTION_LOAD,
        id
    }
}

export const TransactionDispatchStore = (
    transaction: Transaction
): TransactionActionStore => {
    return {
        type: TRANSACTION_STORE,
        transaction
    }
}

export const TransactionsDispatchClearSummaries = (): TransactionsActionClearSummaries => {
    return { type: TRANSACTIONS_CLEAR_SUMMARIES }
}

export const TransactionDispatchLoadExtraDetails = (
    transaction: Transaction
): TransactionActionLoadExtraDetails => {
    return { type: TRANSACTION_LOAD_EXTRA_DETAILS, transaction }
}

export const TransactionDispatchStoreExtraDetails = (
    transaction: Transaction
): TransactionActionStoreExtraDetails => {
    return { type: TRANSACTION_STORE_EXTRA_DETAILS, transaction }
}

export const TransactionDispatchLoadAppendix = (
    transaction: Transaction
) : TransactionActionLoadAppendix => {
    return { type: TRANSACTION_LOAD_APPENDIX, transaction }
}

export const TransactionDispatchStoreAppendix = (
    transaction: Transaction
) : TransactionActionStoreAppendix => {
    return { type: TRANSACTION_STORE_APPENDIX, transaction }
}

export interface SagasForTransactions {
    loadSummaries(action: TransactionsActionLoadSummaries): void
    loadNextSummaries(action: TransactionsActionLoadNextSummaries): void
    load(action: TransactionActionLoad): void
}

export type ReducedTransactionsActions =
    | TransactionActionStore
    | TransactionActionUnstore
    | TransactionActionLoadExtraDetails
    | TransactionActionStoreExtraDetails
    | TransactionActionLoadAppendix
    | TransactionActionStoreAppendix
    | TransactionActionLoad
    | TransactionsActionStoreSummaries
    | TransactionsActionLoadSummaries
    | TransactionsActionLoadNextSummaries
    | TransactionsActionAppendSummaries
    | TransactionsActionClearSummaries
