import { Text } from '@/components/general/text'
import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import { LoaderView } from '@/components/loaders/loader'
import { Box } from '@/design-system'
import {
    FormProvider,
    ListVoucherSection,
    ReconciliationModal,
    SinglePostingSection,
    useReconciliationParams
} from '@/features/reconciliation'
import { PostingActions } from '@/features/reconciliation/components'
import { useGetAccounts, useGetPosting, useGetVouchers } from '@/services/reconciliation'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ReconciliationPostingReconcile: React.FC = () => {
    const { id } = useReconciliationParams()
    const navigate = useNavigate()

    const { data: postingData, isLoading: postingLoading, isError: postingError } = useGetPosting(id)
    const { data: vouchersData, isLoading: vouchersLoading, isError: vouchersError } = useGetVouchers()
    const { data: accountsData, isLoading: accountsLoading, isError: accountsError } = useGetAccounts()

    const isLoading = postingLoading || vouchersLoading || accountsLoading
    const hasNoData = !postingData || !vouchersData || !accountsData

    useEffect(() => {
        if (postingError || vouchersError || accountsError) {
            navigate(-1)
        }
    }, [accountsError, postingError, vouchersError])

    if (isLoading) {
        return <LoaderView overBackground="front.background" type="l" minHeight="200" />
    }

    if (hasNoData) {
        return <Text>No data available</Text>
    }

    return (
        <FormProvider data={'posting'}>
            <ReconciliationModal type="posting">
                <Box mb="20">
                    <Flex column>
                        <SinglePostingSection posting={postingData} accounts={accountsData.items} />
                        {!postingData.reconciled && (
                            <>
                                <Spacer width={20} />
                                <ListVoucherSection />
                                <Box mt="6">
                                    <PostingActions />
                                </Box>
                            </>
                        )}
                    </Flex>
                </Box>
                <Spacer height={64} />
            </ReconciliationModal>
        </FormProvider>
    )
}
