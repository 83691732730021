import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { GeneralConflictBox } from '../../components/general/generalConflictBox'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { ApplicationDataProvidersActions } from '../../store/applicationDataProviders/actions'
import {
    DataPorviderStateAssignedAndReady,
    DataProviderOwnershipConflictUBOsMismatch
} from '../../store/applicationDataProviders/types'
import { RootState } from '@/store'
import { MerchantApplicationConflictsOwnershipSOTTable } from './MerchantApplicationConflicts.Ownership.SOTTable'
import { MerchantApplicationConflictsOwnershipTable } from './MerchantApplicationConflicts.Ownership.Table'
import { MerchantApplicationConflictsSection } from './MerchantApplicationConflictsSection'

export const MerchantApplicationConflictsOwnership: React.FC<{
    applicationId: string
    showMuted: boolean
}> = ({ applicationId, showMuted }) => {
    const dispatch = useDispatch()
    const conflict = useSelector((state: RootState) => state.dataProviders.forApplication?.[applicationId])
    const ownershipConflict =
        conflict.state == 'assigned-and-ready'
            ? (conflict as DataPorviderStateAssignedAndReady).computed.conflicts.ownership
            : null

    const leftSide = useMemo(() => {
        if (!ownershipConflict) return null

        if (ownershipConflict.type == 'application-zero-owners')
            return (
                <GeneralConflictBox>
                    ⚠️ No owners found in merchant&apos;s application, while the ownership structure indicates that
                    there should be owners.
                </GeneralConflictBox>
            )

        return <MerchantApplicationConflictsOwnershipTable conflict={ownershipConflict} applicationId={applicationId} />
    }, [ownershipConflict, applicationId])

    const rightSide = useMemo(() => {
        if (!ownershipConflict) return null
        if (ownershipConflict.type == 'data-provider-blank')
            return <GeneralConflictBox>The data provider has found no owners for this company</GeneralConflictBox>
        return (
            <MerchantApplicationConflictsOwnershipSOTTable conflict={ownershipConflict} applicationId={applicationId} />
        )
    }, [ownershipConflict, applicationId])

    const sectionProps = useMemo((): Partial<React.ComponentProps<typeof MerchantApplicationConflictsSection>> => {
        if (!ownershipConflict || ownershipConflict?.type == 'no-conflicts')
            return {
                showEvenIfMuted: showMuted,
                isMuted: ownershipConflict?.muted
            }

        const hasTypoConflicts = Object.keys(
            (ownershipConflict as DataProviderOwnershipConflictUBOsMismatch).perUBOConflicts
        ).find((c) => {
            const o = ownershipConflict.perUBOConflicts[c]
            return o.type == 'typo'
        })

        return {
            showEvenIfMuted: showMuted,
            isMuted: ownershipConflict.muted,
            onUnignoreClick: (_, generatedId) => {
                dispatch(
                    ApplicationDataProvidersActions.UNIGNORE_CHANGES(applicationId, generatedId, {
                        ownership: false
                    })
                )
            },
            onIgnoreClick: (_, generatedId) => {
                dispatch(
                    ApplicationDataProvidersActions.IGNORE_CHANGES(applicationId, generatedId, {
                        ownership: true
                    })
                )
            },
            onTypoFix: hasTypoConflicts
                ? (e, watcherId) => {
                      dispatch(ApplicationDataProvidersActions.FIX_TYPOS(watcherId, applicationId, 'ownership'))
                  }
                : undefined
        }
    }, [dispatch, ownershipConflict, applicationId, showMuted])

    const subtitle = useMemo(() => {
        if (!ownershipConflict) return null
        switch (ownershipConflict.type) {
            case 'application-zero-owners':
                return 'The application has no owners defined.'
            case 'data-provider-blank':
                return "We've failed to get the ownership context from the data provider."
            case 'no-conflicts':
                return 'The ownership structure seems to match with the details we have from the data provider.'
            case 'ubos-mismatch':
                // eslint-disable-next-line max-len
                return "We've found some discrepancies between the application's ownership structure are the data from our data provider."
            default:
                break
        }
    }, [ownershipConflict])
    if (!ownershipConflict) return <></>

    if (ownershipConflict.type == 'no-conflicts') return <></>
    if (conflict.state !== 'assigned-and-ready') return <></>

    return (
        <MerchantApplicationConflictsSection
            title="Ownership structure"
            isMuteable={ownershipConflict.type === 'application-zero-owners' ? ownershipConflict.muted : true}
            subtitle={subtitle}
            {...(sectionProps as any)}
        >
            <Flex cy="ownership-conflicts">
                <Flex grow noShrink basis="50%">
                    <Card grow>
                        <CardInset>{leftSide}</CardInset>
                    </Card>
                </Flex>
                <Spacer width={20} />
                <Flex grow noShrink basis="50%" column>
                    <Card grow>
                        <CardInset>{rightSide}</CardInset>
                    </Card>
                </Flex>
            </Flex>
            <Spacer height={80} />
        </MerchantApplicationConflictsSection>
    )
}
