import React, { ComponentProps } from 'react'
import { useOnClickWithoutMouseMove } from '../../hooks/general/useOnClickWithoutMouseMove'
import { NavLink } from 'react-router-dom'

export const SmartNavLinkWithRef: React.ForwardRefRenderFunction<any, ComponentProps<typeof NavLink>> = (
    { ...props },
    r
) => {
    const ref = useOnClickWithoutMouseMove(undefined, (r as any)?.current)
    return <NavLink {...props} ref={ref} />
}

export const SmartNavLink = React.forwardRef(SmartNavLinkWithRef)
