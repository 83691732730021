import dotProp from 'dot-prop'
import { useSelector } from 'react-redux'

import { ConvertURLPartsToFieldDetails } from './Application.Structure'
import {
    IMerchantApplicationSectionItemDetails,
    IMerchantApplicationSubsectionStructure,
    TMerchantApplicationResourceKey
} from './Application.StructureTypes'
import {
    ApplicationResourceCommentsResource,
    ApplicationResourceHistory
} from '../../../store/applicationResources/types'
import { camelCaseWithDots } from '../../../store/applications/utils'
import { RootState } from '@/store'
import { LoadingStatus } from '../../../utils'

export function UseMerchantApplicationFieldResourceSelector(
    urlParams: any,
    type: 'edits'
): {
    loadingStatus: LoadingStatus
    resources?: ApplicationResourceHistory[]
}
export function UseMerchantApplicationFieldResourceSelector(
    urlParams: any,
    type: 'comments'
): {
    loadingStatus: LoadingStatus
    resources?: ApplicationResourceCommentsResource
}
export function UseMerchantApplicationFieldResourceSelector(
    urlParams: any,
    type: 'comments' | 'edits'
): {
    loadingStatus: LoadingStatus
    resources?: ApplicationResourceHistory[] | ApplicationResourceCommentsResource
} {
    const {
        id,
        sectionLabel,
        fieldLabelOrID,
        fieldLabel
    }: {
        id: string
        sectionLabel: TMerchantApplicationResourceKey
        fieldLabelOrID: string
        fieldLabel: string
    } = urlParams

    const fieldDetails = ConvertURLPartsToFieldDetails(sectionLabel, fieldLabelOrID, fieldLabel)

    const resourceFieldKey = type === 'comments' ? 'comments' : 'history'

    const applicationData = useSelector((state: RootState) => {
        return state.applicationResources?.data.forApplication?.[id]
    })
    const applicationResourceData = useSelector((state: RootState) => {
        return state.applicationResources?.[resourceFieldKey].forApplication?.[id]
    })

    if (!urlParams) return { loadingStatus: 'not-started', resources: undefined }

    let resource: TMerchantApplicationResourceKey | undefined
    if (fieldDetails?.section?.type === 'dynamic') {
        resource = (fieldDetails?.section as IMerchantApplicationSubsectionStructure)?.resource
    } else {
        resource = (fieldDetails?.field as IMerchantApplicationSectionItemDetails)?.resource
    }

    if (applicationResourceData?.[resource]?.loadingStatus !== 'done')
        return {
            loadingStatus: applicationResourceData?.[resource]?.loadingStatus || 'not-started',
            resources: undefined
        }

    if (fieldDetails?.section?.type === 'dynamic') {
        let subsectionIndex = -1

        applicationData?.[resource]?.fields.forEach((f: any, i: number) => {
            if (f.id === fieldLabelOrID) {
                subsectionIndex = i
            }
        })

        if (subsectionIndex === -1)
            return {
                loadingStatus: 'started',
                resources: undefined
            }

        return {
            loadingStatus: 'done',
            resources: dotProp.get(
                applicationResourceData,
                `${resource}.fields.${subsectionIndex}.${camelCaseWithDots(
                    fieldDetails.field.id.mapi || fieldDetails.field.id.cutter
                )}`
            )
        }
    }

    return {
        loadingStatus: applicationResourceData?.[resource]?.loadingStatus,
        resources: dotProp.get(
            applicationResourceData,
            `${resource}.fields.0.${camelCaseWithDots(fieldDetails.field.id.mapi)}`
        )
    }
}
