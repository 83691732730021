import { produce } from 'immer'
import {
    InitialFilesState,
    FilesState,
    File,
    SubmitFileData,
    SubmitFileDataWithPrefix,
    FileWithPrefix,
    ConvertedSubmitFileData,
    ConvertedSubmitFileDataWithPrefix
} from './types'
import {
    ReducedFilesActions,
    FILES_STORE,
    FILE_STORE,
    FILE_GENERATE_DOWNLOAD_LINK,
    FILE_STORE_DOWNLOAD_LINK,
    FILE_REMOVE_DOWNLOAD_LINK,
    FILE_STORE_PROGRESS,
    FILE_UNSTORE,
    FILE_ADD_METADATA,
    FILES_CLEAR_ALL
} from './actions'
import { returnUnique } from '../../utils'

export function FilesReducer(state = InitialFilesState, action: ReducedFilesActions): FilesState {
    switch (action.type) {
        case FILE_STORE_PROGRESS: {
            return {
                ...state,
                at: {
                    ...state.at,
                    [action.fileId]: {
                        ...state.at[action.fileId],
                        progress: action.progress == 100 ? undefined : action.progress
                    }
                }
            }
        }
        case FILES_CLEAR_ALL: {
            return {
                at: {},
                all: []
            }
        }
        case FILE_STORE: {
            return {
                at: {
                    ...state.at,
                    [action.file.id]: {
                        ...(state.at[action.file.id] ? state.at[action.file.id] : []),
                        ...convertPrefixedBackendFileToNormalFile(action.file as any)
                    }
                },
                all: returnUnique(state.all, action.file.id)
            }
        }
        case FILES_STORE: {
            return produce(state, (draft) => {
                if (!action.files) return
                const newFileIds = action.files.map((f) => f.id)
                action.files.forEach((f: File) => {
                    draft.at[f.id] = {
                        ...(draft.at[f.id] ? draft.at[f.id] : {}),
                        ...convertPrefixedBackendFileToNormalFile(f as any)
                    }
                })
                draft.all = [...draft.all.filter((fId) => !newFileIds.includes(fId)), ...newFileIds]
            })
        }
        case FILE_UNSTORE: {
            return produce(state, (draft) => {
                delete draft.at[action.file.id]
                draft.all = draft.all.filter((fId) => fId != action.file.id)
            })
        }
        case FILE_ADD_METADATA: {
            return produce(state, (draft) => {
                draft.at[action.file.id] = {
                    ...state.at[action.file.id],
                    metadata: {
                        deleted: true
                    }
                }
            })
        }
        case FILE_GENERATE_DOWNLOAD_LINK: {
            return {
                at: {
                    ...state.at,
                    [action.file.id]: {
                        ...state.at[action.file.id],
                        generatingDownloadLink: 'started'
                    }
                },
                all: [...state.all]
            }
        }
        case FILE_STORE_DOWNLOAD_LINK: {
            return {
                at: {
                    ...state.at,
                    [action.fileId]: {
                        ...state.at[action.fileId],
                        generatingDownloadLink: 'done',
                        generatedDownloadLink: action.link
                    }
                },
                all: [...state.all]
            }
        }
        case FILE_REMOVE_DOWNLOAD_LINK: {
            if (!action.file?.id) return state
            return {
                at: {
                    ...state.at,
                    [action.file.id]: {
                        ...state.at[action.file.id],
                        generatedDownloadLink: undefined,
                        generatingDownloadLink: 'not-started'
                    }
                },
                all: [...state.all]
            }
        }
    }
    return state
}

export const convertPrefixedSubmittedFileToSubmittedNormalFile = (file: SubmitFileDataWithPrefix): SubmitFileData => {
    const { documentFileName, documentFileSize, documentContentType, ...restOfFile } = {
        ...file,
        name: file.documentFileName,
        contentType: file.documentContentType,
        size: file.documentFileSize
    }

    return restOfFile
}

export const convertPrefixedBackendFileToNormalFile = (file: FileWithPrefix): File => {
    const restOfFile: any = { ...file }

    if (file.documentFileName) {
        restOfFile.name = file.documentFileName
        // delete file.documentFileName
    }
    if (file.documentFileSize) {
        restOfFile.size = file.documentFileSize
        // delete file.documentFileSize
    }
    if (file.documentContentType) {
        restOfFile.contentType = file.documentContentType
        // delete file.documentContentType
    }

    const dataLink = (file as any).dataLink
    if (dataLink) {
        restOfFile.uploadLink = dataLink[0].href
        restOfFile.downloadLink = dataLink[1].href
        if (dataLink[2]?.href.includes('refetch')) {
            restOfFile.refetchLink = dataLink[2].href
        }
    }
    return restOfFile
}

export const convertSubmittedNormalFileToSubmittedPrefixedBackendFile = (
    file: ConvertedSubmitFileData
): ConvertedSubmitFileDataWithPrefix => {
    const { content_type, name, wholeFile, size, ...newFile } = {
        ...file,
        document_content_type: file.content_type,
        document_file_name: file.name,
        document_file_size: file.size
    }
    return newFile
}
