import {
    ForceReconciliationCategory,
    ForceReconciliationPostingData,
    useForceReconcilePosting
} from '@/services/reconciliation'
import { useReconciliationParams } from '.'
import { useNavigate } from 'react-router-dom'

export const useForceReconcilePostingActions = () => {
    const { id } = useReconciliationParams()
    const navigate = useNavigate()

    const [forceReconcileAction, { isLoading }] = useForceReconcilePosting()

    const forceReconcilePosting = async (category: ForceReconciliationCategory) => {
        const payload = {
            postingId: id,
            data: {
                category
            }
        }

        await forceReconcileAction(payload)

        navigate('/more/reconciliation-postings')
    }

    return { isLoading, forceReconcilePosting }
}
