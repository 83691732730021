import { Badge, Button, Flex, Table, Text } from '@/design-system'
import { Partner } from '@/services/merchant-api'
import React from 'react'

interface PartnersTableProps {
    partners: Partner[]
    onRowClick: (id: string) => void
    onEditClick: (id: string) => void
}

export const PartnersTable: React.FC<PartnersTableProps> = (props) => {
    const { partners, onRowClick, onEditClick } = props

    const handleOnRowClick = (id) => () => {
        onRowClick(id)
    }

    const handleOnEditClick = (id) => (e: React.MouseEvent) => {
        e.stopPropagation()
        onEditClick(id)
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.Head>Name</Table.Head>
                    <Table.Head>Type</Table.Head>
                    <Table.Head>Merchant payment gateway id</Table.Head>
                    <Table.Head justify="end">Actions</Table.Head>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {partners.map((partner: Partner) => {
                    const { id, name, merchant_payment_gateway_id, technical, support, referral } = partner
                    const isTypeAvailable = technical || support || referral

                    return (
                        <Table.Row testid="table-partner-row" key={id} onClick={handleOnRowClick(id)}>
                            <Table.Cell testid="table-partner-name">
                                <Text weight="medium">{name}</Text>
                            </Table.Cell>
                            <Table.Cell>
                                {isTypeAvailable && (
                                    <Flex gap="2">
                                        {technical && <Badge testid="table-partner-technical">Technical</Badge>}
                                        {support && <Badge testid="table-partner-support">Support</Badge>}
                                        {referral && <Badge testid="table-partner-referral">Referral</Badge>}
                                    </Flex>
                                )}
                            </Table.Cell>
                            <Table.Cell testid="table-merchant-payment-gateway-id">
                                <Text color="neutral">
                                    {merchant_payment_gateway_id ? merchant_payment_gateway_id : '-'}
                                </Text>
                            </Table.Cell>
                            <Table.Cell justify="end">
                                <Button
                                    testid="table-partner-edit-button"
                                    variant="link"
                                    onClick={handleOnEditClick(id)}
                                >
                                    Edit
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}
