import { useBatchUpdateTask } from '@/services/taskr'
import { useCreateReport } from '@/services/ongoing-due-diligence'
import { useTasks } from '../../../api'
import { getTaskUrls } from '../../../helpers'
import { useReportParams } from '../../../hooks'
import { useAuth } from '@/hooks/auth/useAuth'

export const useNewTasks = () => {
    const { applicationId } = useReportParams()

    return useTasks({ state: 'new', applicationId })
}

export const useCreateActions = () => {
    const { applicationId } = useReportParams()
    const { user } = useAuth()

    const newTasks = useNewTasks()
    const [createReport, reportResult] = useCreateReport()
    const [batchUpdateTask, batchUpdateTaskResult] = useBatchUpdateTask()

    const isLoading = reportResult.isLoading || batchUpdateTaskResult.isLoading || newTasks.isLoading

    const create = async () => {
        // create report
        const createReportPayload = { applicationId }
        const report = await createReport(createReportPayload).unwrap()

        // set new tasks open
        const taskUrls = getTaskUrls(newTasks.data?.embedded.tasks)
        await batchUpdateTask({ urls: taskUrls, data: { action: 'open_task' } })

        if (user && user['https://clearhaus.com/app_metadata'].sub) {
            const { sub } = user['https://clearhaus.com/app_metadata']
            await batchUpdateTask({ urls: taskUrls, data: { assignee: sub } })
        }

        return { report }
    }

    return { isLoading, create }
}
