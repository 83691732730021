import { CollectionWithPagination, CollectionWSeparateLoading, LoadingStatus, Pagination } from '../../utils'
import { Application } from '../applications/types'
import { MerchantAccount } from '../merchantAccounts/types'
import { Transaction } from '../transactions/types'

export const AvailableSettlementCurrencies = [
    'DKK',
    'EUR',
    'GBP',
    'NOK',
    'SEK',
    'USD',
    'CHF',
    'CZK',
    'HUF',
    'PLN',
    'RON'
]

export interface SettlementsFilters {
    settlements_mid: string
    settlements_settled: string
    settlements_currency: string[]
    settlements_startDate: string
    settlements_endDate: string
    settlements_payoutAfterDate: string
    settlements_payoutBeforeDate: string
    settlements_reserveAfterDate: string
    settlements_reserveBeforeDate: string
    settlements_custom_filters: string
    settlements_page: number
    settlements_per_page: number
    sql: string
}

export const SettlementsFiltersParams: SettlementsFilters = {
    settlements_mid: '',
    settlements_settled: '',
    settlements_currency: [],
    settlements_startDate: '',
    settlements_endDate: '',
    settlements_payoutAfterDate: '',
    settlements_payoutBeforeDate: '',
    settlements_reserveAfterDate: '',
    settlements_reserveBeforeDate: '',
    settlements_custom_filters: '',
    settlements_page: 1,
    settlements_per_page: 20,
    sql: ''
}

export interface SettlementSummary {
    id: string
    settled: boolean
    currency: string
    selfLink: string
    period: {
        startDate: string
        endDate?: string
    }
    feeDetails: {
        type: string
        amount: number
    }[]
    summary: {
        sales: number
        credits: number
        refunds: number
        chargebacks: number
        fees: number
        otherPostings: number
        net: number
    }
    fees: {
        sales: number
        refunds: number
        authorisations: number
        "3dsecure": number
        credits: number
        interchange: number
        lateCapture: number
        scheme: number
        minimumProcessing: number
        service: number
        wireTransfer: number
        chargebacks: number
        retrievalRequests: number
        series: number
    }
    otherPostings: {
        text: number
        amount: number
    }[]
    payout: {
        amount: number
        date: string
        referenceNumber: string
        descriptor: string
        cancelledDate: string
        linkedSettlement?: string
    }
    reserve: {
        amount: number
        date: string
        referenceNumber: string
        descriptor: string
        cancelledDate: string
        linkedSettlement?: string
    }
    account: {
        name: string
        merchantId: string
        currency: string
        mcc: string
        country: string
    }
    accountLink: string
    merchantDetails: {
        loadingStatus: LoadingStatus
        account: MerchantAccount
        application: Application
    }
    transactionsLink: string
    stampsLink: {
        href: string
        name: 'cancelled_reserve' | 'cancelled_payout'
    }[]
    transactions: {
        loadingStatus: LoadingStatus
        all: Transaction[]
        pagination?: Pagination
    }
}

export interface Settlement extends SettlementSummary {
    loadingStatus: LoadingStatus
    otherPostingsLoadingStatus: LoadingStatus
}

export interface SettlementsState {
    settlements: CollectionWSeparateLoading<Settlement>
    settlementsSummaries: CollectionWithPagination<SettlementSummary, Pagination> & { forQuery?: string }
}

export const InitialSettlementsState: SettlementsState = {
    settlements: {
        at: {},
        all: []
    },
    settlementsSummaries: {
        at: {},
        all: [],
        pagination: undefined,
        loadingStatus: 'not-started',
        forQuery: undefined
    }
}
