import { useSelector } from 'react-redux'

import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Separator } from '../../../components/layout/separator'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { ApplicationStatus } from '../../../store/applications/types'
import { RootState } from '@/store'
import { MerchantApplicationSection, MerchantApplicationSectionProps } from './Application.Section'

export const disabledBankAccountSectionStates: ApplicationStatus[] = ['accepted']

export const MerchantApplicationBankAccountSection: React.FC<MerchantApplicationSectionProps> = ({
    applicationId,
    formRef,
    ...props
}) => {
    const shouldDisableSection = useSelector((state: RootState) => {
        return disabledBankAccountSectionStates.includes(
            state.applications?.applications?.at?.[applicationId]?.metadata?.state
        )
    })

    if (shouldDisableSection)
        return (
            <>
                <Card title="Bank Account">
                    <CardInset>
                        <Text>
                            As this application has been accepted, the bank account details are no longer part of the
                            application, but are linked directly to an account.
                        </Text>
                        <Spacer height={10} />
                        <Separator />
                        <Spacer height={10} />
                        <Text bold>You can find the bank accounts in the accounts tab.</Text>
                    </CardInset>
                </Card>
                <span />
            </>
        )
    return <MerchantApplicationSection applicationId={applicationId} formRef={formRef} {...props} />
}
