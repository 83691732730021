import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../components/buttons/buttonInset'
import { WatcherButton } from '../components/buttons/watcherButton'
import { TextInput } from '../components/forms/textInput'
import { useForm } from '../hooks/general/useForm'
import { useNamedWatcher } from '../hooks/general/useWatcher'
import { SettlementsReservesActions } from '../store/settlementsReserves/actions'
import { FormFieldWithFormattedNumber, inverseCurrencyFilter } from '../utils'
import { Spacer } from '../components/layout/spacer'
import { RootState } from '@/store'

export const CriticalFlowSettlementsSmartSelect: React.FC<{ mid: string; currency: string }> = ({ mid, currency }) => {
    const [watcher, watcherId] = useNamedWatcher(`${mid}-autoselect`)
    const { formRef, form, errors, submitHandler } = useForm(watcher, undefined, undefined, undefined, true)
    const dispatch = useDispatch()
    const currentAutoselectQuery = useSelector(
        (state: RootState) => state.settlementsReserves.forMerchantId[mid].autoSelect
    ) as any

    const handleAutoSelect = useCallback(
        ({ data }) => {
            if (data.amount <= 0) return
            const fixedAmount = inverseCurrencyFilter(currency, data.amount)
            dispatch(SettlementsReservesActions.AUTOSELECT(mid, fixedAmount, watcherId))
        },
        [dispatch, watcherId, mid, currency]
    )

    const isDisabled = useMemo(() => {
        const fixedAmount = inverseCurrencyFilter(currency, form.data.amount)
        if (fixedAmount <= 0) return true
        return fixedAmount === currentAutoselectQuery?.for
    }, [form.data.amount, currentAutoselectQuery, currency])

    const button = useMemo(() => {
        return (
            <WatcherButton
                background="front.background"
                onClick={submitHandler(handleAutoSelect)}
                isDisabled={isDisabled}
                predefinedWatcher={watcherId}
            >
                <ButtonInset>Smart select reserves</ButtonInset>
            </WatcherButton>
        )
    }, [submitHandler, watcherId, isDisabled, handleAutoSelect])

    return (
        <Holder>
            <InputHolder>
                <TextInput
                    placeholder="Enter and amount for smart selection"
                    overBackground="back.background"
                    cy="amount-input"
                    dimension="smaller"
                    initialValue=""
                    onEnter={submitHandler(handleAutoSelect)}
                    ref={(ref) => formRef(ref, 'amount', undefined, FormFieldWithFormattedNumber)}
                />
            </InputHolder>
            <Spacer width={10} />
            {button}
        </Holder>
    )
}

const Holder = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
`

const InputHolder = styled.div`
    width: 400px;
`
