import { kebabCase } from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Card } from '../../../components/cards/card'
import { Flex } from '../../../components/layout/flex'
import { LoaderView } from '../../../components/loaders/loader'
import { Separator } from '../../../components/layout/separator'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { ModalPage } from '../../../components/layout/modalPage'
import { ModalPageInset } from '../../../components/layout/modalPageInset'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { useQueryParams } from '../../../hooks/general/useQueryParam'
import { Icon } from '../../../components/icons/icon'
import { MerchantTimelinePointLabel } from '../../../store/merchantTimeline/types'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../../utils'
import { DateFormats } from '../../../utils/dateUtils'
import { MerchantTimelineItem } from './SummaryTimelineItem'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { DateSelector } from '../../../components/forms/dateSelector'
import { useParams } from 'react-router-dom'

type Params = { id: string }

const queryParams = ['on-date', 'filter']
export const MerchantExploreTimeline: React.FC = () => {
    const params = useParams() as Params

    const name = useMerchantName(params.id, 'Timeline')

    const [query, setQuery] = useQueryParams(queryParams, undefined)
    const timeline = useSelector((state: RootState) => state.merchantTimeline[params.id])

    const onSelect = useCallback(
        (date) => {
            setQuery((q: any) => ({
                ...q,
                'on-date': moment(date).format(DateFormats.dayStamp)
            }))
        },
        [setQuery]
    )

    const config = useMemo(() => {
        if (!timeline) return undefined

        const percents: any = {}

        let highest = 0
        Object.keys(timeline.byDay).forEach((dayStamp) => {
            if (timeline.byDay[dayStamp].length > highest) highest = timeline.byDay[dayStamp].length
        })

        Object.keys(timeline.byDay).forEach((dayStamp) => {
            const percentIndex = timeline.byDay[dayStamp].length / highest

            if (!percents[percentIndex]) percents[percentIndex] = []

            percents[percentIndex].push(moment(dayStamp).toDate())
        })

        return {
            highlightedDates: Object.keys(percents).map((percent) => {
                return {
                    [`day-highlight-${kebabCase(percent)}`]: percents[percent]
                }
            }),
            enabledDates: Object.keys(timeline.byDay).map((date) => moment(date).toDate())
        } as any
    }, [timeline])

    const timelinePoints = useMemo(() => {
        let filteredResults = timeline?.all || []

        if (query['on-date']) {
            filteredResults = filteredResults.filter(
                (id: string) => moment(timeline?.at[id].at).format(DateFormats.dayStamp) === query['on-date']
            )
        }

        if (query.filter) {
            filteredResults = filteredResults.filter((id: string) => timeline?.at[id]?.labels.includes(query.filter))
        }

        return filteredResults
    }, [query, timeline])

    const renderedTimeline = useMemo(() => {
        let lastDayStamp: any

        if (!timelinePoints || timelinePoints.length === 0) {
            if (query['on-date'] || query.filter)
                return <NothingFound key="nothing-found-matching">No change found matching these filters</NothingFound>
            return <NothingFound key="nothing-found">No items found</NothingFound>
        }

        return timelinePoints.map((id) => {
            let shouldShowStamp = false
            const currentStamp = moment(timeline.at[id].at).format(DateFormats.dayStamp)
            if (lastDayStamp !== currentStamp) shouldShowStamp = true
            lastDayStamp = currentStamp
            return (
                <React.Fragment key={timeline.at[id].at}>
                    {shouldShowStamp && (
                        <>
                            <Spacer width={20} />
                            <Text bold>
                                {moment(timeline.at[id].at).format(DateFormats.fullDay(moment(timeline.at[id].at)))}
                            </Text>
                            <Spacer width={10} />
                        </>
                    )}
                    <MerchantTimelineItem point={timeline.at[id]} />
                </React.Fragment>
            )
        })
    }, [query, timeline, timelinePoints])

    const assignLabel = useCallback(
        (label: MerchantTimelinePointLabel) => {
            setQuery((q: any) => ({
                ...q,
                filter: label
            }))
        },
        [setQuery]
    )

    const renderFilters = useMemo(() => {
        const filters: any = {
            'Application edits': 'edits',
            'State transitions': 'state-changes'
        }

        return Object.keys(filters).map((key, index, array) => {
            return (
                <React.Fragment key={key}>
                    <SimpleButton
                        onClick={() => {
                            assignLabel(filters[key])
                        }}
                        background={query.filter === filters[key] ? 'front.accent.color' : 'back.background.subtlerI'}
                    >
                        <ButtonInset padding="medium">{key}</ButtonInset>
                    </SimpleButton>
                    {index !== array.length - 1 && <Spacer height={5} />}
                </React.Fragment>
            )
        })
    }, [assignLabel, query])

    const activeFilters = useMemo(() => {
        return (
            <Flex column>
                {query['on-date'] ? (
                    <SimpleButton
                        onClick={() =>
                            setQuery((q: any) => ({
                                ...q,
                                'on-date': undefined
                            }))
                        }
                        background="front.accent.color"
                    >
                        <ButtonInset leftAlign>
                            <RemoveFilterIcon>
                                <Icon type="x" size={11} />
                            </RemoveFilterIcon>
                            <Spacer width={10} />
                            Filtering by date {query['on-date']}
                        </ButtonInset>
                    </SimpleButton>
                ) : undefined}
                <Spacer height={5} />
                {query.filter ? (
                    <SimpleButton
                        background="front.accent.color"
                        onClick={() =>
                            setQuery((q: any) => ({
                                ...q,
                                filter: undefined
                            }))
                        }
                    >
                        <ButtonInset leftAlign>
                            <Flex align="center">
                                <RemoveFilterIcon>
                                    <Icon type="x" size={11} />
                                </RemoveFilterIcon>
                                <Spacer width={10} />
                                Filtering by &laquo;
                                {uppercaseFirstLetter(query.filter)}&raquo;
                            </Flex>
                        </ButtonInset>
                    </SimpleButton>
                ) : undefined}
            </Flex>
        )
    }, [query, setQuery])

    return (
        <ModalPage title={name} pageId="Merchant.Summary.ExploreTimeline" backTo={`/merchant/${params.id}/summary`}>
            <ModalHeader
                title="Merchant Timeline"
                pageId="Merchant.Summary.ExploreTimeline"
                backTo={`/merchant/${params.id}/summary`}
                keepBackTo
            />
            <ModalPageInset>
                {timeline?.loadingStatus === 'done' ? (
                    <Card frameless grow>
                        <Holder>
                            <TimelineHolder>{renderedTimeline}</TimelineHolder>
                            <Spacer width={40} />
                            <Sidebar>
                                <Card frameless>
                                    {activeFilters}
                                    <Spacer height={30} />
                                    <Text color="front.text.subtlerI">Filter by date:</Text>
                                    <Spacer height={6} />
                                    <Separator />
                                    <Spacer height={10} />
                                    <DateSelector
                                        onSelect={onSelect}
                                        inline
                                        enabledDates={config?.enabledDates || undefined}
                                        preselectedDate={
                                            query['on-date'] ? moment.utc(query['on-date']).toDate() : undefined
                                        }
                                    />
                                    <Spacer height={20} />
                                    <Flex column align="stretch">
                                        <Text color="front.text.subtlerI">Popular filters:</Text>
                                        <Spacer height={6} />
                                        <Separator />
                                        <Spacer height={20} />
                                        {renderFilters}
                                    </Flex>
                                </Card>
                            </Sidebar>
                            <Spacer width={20} />
                        </Holder>
                    </Card>
                ) : (
                    <LoaderView overBackground="overlay.background" />
                )}
            </ModalPageInset>
        </ModalPage>
    )
}

const Sidebar = styled.div`
    width: 240px;
`

const RemoveFilterIcon = styled.div`
    position: relative;
    top: 1px;
`

const NothingFound = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.03);
    align-items: center;
    flex-grow: 0;
`

const Holder = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const TimelineHolder = styled.div`
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;
`
