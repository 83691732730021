import React from 'react'
import { Styles, styles } from './styles'
import { Slot } from '@radix-ui/react-slot'

interface LinkProps extends Styles {
    children: React.ReactNode
    asChild?: boolean
    href?: string
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const { children, asChild, href, color, weight } = props

    const classnames = styles({ color, weight })

    const Comp = asChild ? Slot : 'a'

    return (
        <Comp className={classnames} href={href} ref={ref}>
            {children}
        </Comp>
    )
})

Link.displayName = 'Link'
