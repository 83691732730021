import { useGetPostings, useGetVoucher } from '@/services/reconciliation'
import { useReconciliationParams } from './use-reconciliation-params'
import { useFormContext } from 'react-hook-form'
import { ReconciliationBalance, filterReconciliationsById } from '../utils'

export const useVoucherBalance = () => {
    const { id } = useReconciliationParams()

    const { data: voucher } = useGetVoucher(id)
    const { data: postings } = useGetPostings()

    const ids = useFormContext().watch('posting_ids')

    if (!voucher) return

    const balance = { type: voucher.posting_type, amount: voucher.amount }
    const reconciliations = filterReconciliationsById(ids, postings?.items)

    const reconciliationBalance = new ReconciliationBalance(balance, reconciliations)

    return reconciliationBalance.calculateBalance()
}
