import { Charts } from '../types/charts'
import { api } from '../api'

export const chartsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCharts: build.query<Charts, string>({
            query: (applicationId) => `/applications/${applicationId}/odd/charts`,
            providesTags: ['charts']
        }),
        createCharts: build.mutation<void, string>({
            query: (applicationId) => ({
                url: `/applications/${applicationId}/odd/charts`,
                method: 'POST'
            }),
            invalidatesTags: ['charts']
        })
    })
})

export const { useGetChartsQuery: useGetCharts, useCreateChartsMutation: useCreateCharts } = chartsApi
