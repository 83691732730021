import { CollectionWithPagination } from '../../utils'
import { ApplicationStatus } from '../applications/types'

export interface SearchResult {
    id: string
    agreement: any
    bankAccounts: any[]
    businessModel: string
    company: any
    contact: any
    createdAt: string
    gateway: any
    highlight: any
    people: any[]
    publicState: ApplicationStatus
    tags: any[]
    tradingName: string
    websites: any[]
    account: {
        currency: string
        id: string
        merchantId: string
        state: string
    }
    accounts: {
        currency: string
        id: string
        merchantId: string
        state: string
    }[]
}

export interface SearchState {
    shown: boolean
    disabled: boolean
    results: CollectionWithPagination<
        SearchResult,
        {
            total: number
            page: number
            perPage: number
        }
    >
}

export const InitialSearchState: SearchState = {
    shown: false,
    disabled: false,
    results: {
        at: {},
        all: [],
        pagination: undefined,
        loadingStatus: 'not-started'
    }
}
