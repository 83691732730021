import { useParams } from 'react-router-dom'

type Params = {
    id: string
    classification_id: string
}

export const useClassificationParams = () => {
    const { classification_id: classificationId, id: applicationId } = useParams() as Params

    return { classificationId, applicationId }
}
