import { createActionCreator } from 'deox'
import { LineOption, NotificationStatus } from '../../components/listPages/sidebarNavigation/sidebarCallSettingsPanel'

const Prefix = 'CALLS_PREF'

export const CallsPrefActions = {
    SET_LINE: createActionCreator(`${Prefix}/SET_LINE`, (resolve) => (line: LineOption) => resolve({ line })),
    SET_LOCAL_NUMBER: createActionCreator(
        `${Prefix}/SET_LOCAL_NUMBER`,
        (resolve) => (local_number: string) => resolve({ local_number })
    ),
    TOGGLE_NOTIFICATIONS: createActionCreator(
        `${Prefix}/TOGGLE_NOFITICATIONS`,
        (resolve) => (notifications: NotificationStatus) => resolve({ notifications })
    ),
    UPDATE_PREF: createActionCreator(
        `${Prefix}/UPDATE_PREF`,
        (resolve) => (notifications: NotificationStatus, local_number: string, line: LineOption, nickname: string) =>
            resolve({ notifications, local_number, line, nickname })
    ),
    SET_IS_CALLING: createActionCreator(
        `${Prefix}/SET_IS_CALLING`,
        (resolve) => (isCalling: string) => resolve({ isCalling })
    )
}
