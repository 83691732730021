import { VariantProps, cva } from 'class-variance-authority'

export type ContentStyles = VariantProps<typeof styles.subTrigger>

export const styles = {
    subTrigger: cva([
        'flex',
        'cursor-default',
        'select-none',
        'items-center',
        'justify-between',
        'rounded-lg',
        'focus:bg-neutral-50',
        'data-[state=open]:bg-neutral-50',
        'px-3',
        'py-1.5',
        'outline-none',
        'dark:border-gray-600',
        'dark:text-neutral-200',
        'dark:bg-gray-900',
        'dark:text-neutral-200',
        'dark:hover:bg-gray-700'
    ]),
    subContent: cva([
        'z-[2000000]',
        'min-w-[10rem]',
        'max-h-[calc(100vh_-_4rem)]',
        'my-4',
        'overflow-scroll',
        'rounded-xl',
        'bg-white',
        'p-4',
        'text-black',
        'outline-none',
        'shadow-md',
        'focus:!shadow-md',
        'border',
        'border-neutral-50',
        'shadow-primary-900/5',
        'focus:!shadow-primary-900/5',
        'dark:text-neutral-200',
        'dark:border-gray-600',
        'dark:bg-gray-900',
        'dark:shadow-gray-900/20'
    ]),
    content: cva([
        'z-[2000000]',
        'min-w-[10rem]',
        'max-h-[calc(100vh_-_4rem)]',
        'my-4',
        'overflow-scroll',
        'rounded-xl',
        'bg-white',
        'p-4',
        'text-black',
        'outline-none',
        'shadow-md',
        'focus:!shadow-md',
        'border',
        'border-neutral-50',
        'shadow-primary-900/5',
        'focus:!shadow-primary-900/5',
        'dark:border-gray-600',
        'dark:text-neutral-200',
        'dark:bg-gray-900',
        'dark:shadow-gray-900/20'
    ]),
    item: cva([
        'relative',
        'flex',
        'cursor-default',
        'select-none',
        'items-center',
        'justify-between',
        'rounded-lg',
        'px-3',
        'py-1.5',
        'outline-none',
        'data-[disabled]:pointer-events-none',
        'data-[disabled]:opacity-50',
        'focus:bg-neutral-50',
        'data-[state=open]:bg-neutral-50',
        'dark:text-neutral-200',
        'dark:hover:bg-gray-700'
    ]),
    checkboxItem: cva([
        'relative',
        'flex',
        'cursor-default',
        'select-none',
        'items-center',
        'rounded-sm',
        'py-1.5',
        'pl-8',
        'pr-2',
        'text-sm',
        'outline-none',
        'data-[disabled]:pointer-events-none',
        'data-[disabled]:opacity-50'
    ]),
    radioItem: cva([
        'relative',
        'flex',
        'cursor-default',
        'select-none',
        'items-center',
        'rounded-sm',
        'py-1.5',
        'pl-8',
        'pr-2',
        'text-sm',
        'outline-none',
        'data-[disabled]:pointer-events-none',
        'data-[disabled]:opacity-50'
    ]),
    indicator: cva(['flex', 'items-center', 'justify-center']),
    label: cva('px-2 py-1.5 text-sm font-semibold'),
    separator: cva(['-mx-1', 'my-1', 'h-px'])
}
