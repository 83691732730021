import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { LinkButton } from '../../components/buttons/linkButton'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { ExportsNamer } from '../../components/general/exportsNamer'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { RootState } from '@/store'
import { ExportsActions } from './actions'

export const ExportToastContent: React.FC<{ eId: string }> = ({ eId }) => {
    const e = useSelector((state: RootState) => state.exports.exports.at[eId])
    const dispatch = useDispatch()

    const download = useCallback(
        (_, watcherId) => {
            dispatch(ExportsActions.DOWNLOAD(watcherId, e))
        },
        [dispatch, e]
    )

    return (
        <Flex grow justify="space-between" align="center">
            <Flex>
                <ExportsNamer e={e} floating />
                <Text>&nbsp;export is ready</Text>
            </Flex>
            <Flex>
                <LinkButton to="/more/exports" color="floating.subtleAccent.text">
                    <ButtonInset>See all</ButtonInset>
                </LinkButton>
                <WatcherButton onClick={download} background="front.accent.color">
                    <ButtonInset>Download</ButtonInset>
                </WatcherButton>
            </Flex>
        </Flex>
    )
}
