import React from 'react'
import { Text } from '@/components/general/text'
import { TableBubble } from '@/components/layout/tableBubble'
import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import { Account } from '@/services/reconciliation'

interface AccountProps {
    account?: Account
}

export const AccountDetails: React.FC<AccountProps> = (props) => {
    const { account } = props

    if (!account) {
        return <Text oneLine>-</Text>
    }

    let accountDetails
    switch (account.account_type) {
        case 'bank':
        case 'partner': {
            accountDetails = <TableBubble type="outlined">{account.iban}</TableBubble>
            break
        }
        case 'merchant': {
            accountDetails = (
                <Flex align="center">
                    <TableBubble type="outlined">{account.account_merchant_id}</TableBubble>
                    <Spacer width={10} />
                    <Text bold oneLine>{account.account_name}</Text>
                </Flex>
            )
            break
        }
        case 'partner_funding':
        case 'scheme':
        case 'virtual': {
            accountDetails = <Text bold oneLine>{account.account_name}</Text>
            break
        }
        default: {
            if (account.account_name) {
                accountDetails = <Text bold oneLine>{account.account_name}</Text>
            } else {
                if (account.iban) {
                    accountDetails = <TableBubble type="outlined">{account.iban}</TableBubble>
                } else {
                    accountDetails = <TableBubble type="outlined">Unsupported type: {account.account_type}</TableBubble>
                }
            }
            break
        }
    }

    return accountDetails
}
