import { Moment } from 'moment'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Icon } from '../icons/icon'
import { uppercaseFirstLetter } from '../../utils'
import { DateFormats } from '../../utils/dateUtils'
import { ButtonInset } from '../buttons/buttonInset'
import { LinkButton } from '../buttons/linkButton'
import { LinkButtonArrow } from '../buttons/linkButtonArrow'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { TimelineCard } from '../listPages/timelineCard'
import { TaskTimelineAlertTable } from './taskTimelineAlertTable'
import { useIsPrint } from '../../hooks/general/useIsPrint'
import { convertBackendQueriesToQueryParams } from '../../utils/apiHelpers'

export const TaskTimelineAlert: React.FC<{
    date: Moment
    data: any
    last?: boolean
}> = (p) => {
    const isPrinting = useIsPrint()
    const renderAlertActions = useMemo(() => {
        return p.data.data?.context?.map(({ title, url }: { title: string; url: string }) => {
            // eslint-disable-next-line max-len
            // https://dashboard.clearhaus.com/#!/transactions?mid=2222222&query=date:2022-08-16..2022-08-16%20is:capture%20status:20000
            // Backend links are sent as above, which is not a valid TQL since the MID is passed separately...
            const fixedBackendFormattedTQL = url.replaceAll('=', ':').replaceAll('query:', '').replaceAll('%20', '&')

            if (title.includes('Disputes'))
                return (
                    <Flex column key={title + url}>
                        <Spacer width={4} />
                        <LinkButton
                            color="front.accent.color"
                            key={title}
                            target="managerDisputes"
                            to={convertBackendQueriesToQueryParams('disputes', fixedBackendFormattedTQL)}
                        >
                            <ButtonInset leftAlign noVerticalPadding noHorizontalPadding>
                                <Flex align="center">
                                    <Icon type="sidebarDisputes" weight={1} />
                                    <Spacer width={5} />
                                    {title.includes('Disputes (')
                                        ? uppercaseFirstLetter(title.replace('Disputes (', '').replace(')', ''))
                                        : title}
                                    <Spacer width={7} />
                                    <LinkButtonArrow background="front.subtleDanger.background" shouldBeBordered />
                                </Flex>
                            </ButtonInset>
                        </LinkButton>
                    </Flex>
                )
            if (title.includes('Transactions'))
                return (
                    <Flex column key={title + url}>
                        <Spacer width={4} />
                        <LinkButton
                            color="front.accent.color"
                            key={title}
                            target="managerTransactions"
                            to={convertBackendQueriesToQueryParams('transactions', fixedBackendFormattedTQL)}
                        >
                            <ButtonInset leftAlign noVerticalPadding noHorizontalPadding>
                                <Icon type="sidebarTransactions" weight={1} />
                                <Spacer width={5} />
                                {title.includes('Transactions (')
                                    ? uppercaseFirstLetter(title.replace('Transactions (', '').replace(')', ''))
                                    : title}
                                <Spacer width={7} />
                                <LinkButtonArrow background="front.subtleDanger.background" shouldBeBordered />
                            </ButtonInset>
                        </LinkButton>
                    </Flex>
                )
            return 'ERROR - Contact console team'
        })
    }, [p.data])

    return (
        <TimelineCard
            background="front.subtleDanger.background"
            title={p.data?.name}
            rightSideMemo={
                <Flex grow justify="flex-end">
                    <Text color="front.subtleDanger.text">
                        {p.date ? p.date.format(DateFormats.dayAndTime(p.date)) : null}
                    </Text>
                </Flex>
            }
        >
            <TaskTimelineAlertTable
                data={p.data}
                extraColumns={{
                    Segment: p.data.data?.source?.split(':')[1],
                    ...(isPrinting
                        ? {}
                        : {
                              Actions: {
                                  type: 'custom',
                                  node: <LinksHolder>{renderAlertActions}</LinksHolder>
                              }
                          })
                }}
            />{' '}
        </TimelineCard>
    )
}

const LinksHolder = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -4px;
`
