import { useSelector } from 'react-redux'
import { MerchantAccountWithContract } from '../../../store/merchantAccounts/types'
import { RootState } from '@/store'
import { LoadingStatus } from '../../../utils'

export const useMerchantAccounts = (
    merchantId: string
): {
    loadingStatus: LoadingStatus
    accounts: undefined | MerchantAccountWithContract[]
} => {
    return useSelector((state: RootState) => {
        const accs = state.merchantAccounts.forApplication[merchantId]
        if (accs?.loadingStatus !== 'done')
            return {
                loadingStatus: accs?.loadingStatus,
                accounts: undefined
            }
        const sortedAccs = [...accs.all].sort((aI, bI) => {
            const a = state.merchantAccounts.at[aI].merchantId
            const b = state.merchantAccounts.at[bI].merchantId
            return parseInt(a) < parseInt(b) ? 1 : -1
        })
        return {
            loadingStatus: 'done',
            accounts: sortedAccs.map((accId: string) => state.merchantAccounts.at[accId])
        }
    })
}
