import styled, { css } from 'styled-components'

import { CardInsetType } from './cardInset'

export const CardSection: React.FC<{
    children: React.ReactNode
    background?: 'transparent' | 'lime' | 'subtleBlue' | 'front'
    insetType?: CardInsetType
    negativeMarginTop?: boolean
}> = ({ children, background, insetType = 'regular', negativeMarginTop }) => {
    return (
        <Background background={background} insetType={insetType} negativeMarginTop={negativeMarginTop}>
            {children}
        </Background>
    )
}

const Background = styled.div<{
    background?: 'transparent' | 'lime' | 'subtleBlue' | 'front'
    negativeMarginTop?: boolean
    insetType: CardInsetType
}>`
    background-color: rgba(0, 0, 0, 0);
    border-top: 1px solid rgba(0, 0, 0, 0.055);
    display: flex;
    border-radius: 0;
    flex-direction: row;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% + 60px);

    ${(p) =>
        p.background &&
        css`
            background-color: transparent;
            ${p.background === 'lime' &&
            css`
                border-top-color: ${(p) => p.theme['front.subtleInfo.background.strongerI']};
                background-color: ${(p) => p.theme['front.subtleInfo.background']};
            `}
            ${p.background === 'subtleBlue' &&
            css`
                border-top-color: ${(p) => p.theme['front.subtleAccent.background.strongerI']};
                background-color: ${(p) => p.theme['front.subtleAccent.background']};
            `}
            ${p.background === 'front' &&
            css`
                border-top-color: ${(p) => p.theme['back.border']};
                background-color: ${(p) => p.theme['front.background']};
            `}
        `}

    ${(p) => {
        if (p.insetType === 'regular')
            return css`
                margin-bottom: -30px;
                margin-top: 15px;
                margin-left: -30px;
                margin-right: -30px;
                ${p.negativeMarginTop === true &&
                css`
                    margin-top: -20px;
                `}
            `
        return null
    }}

    @media print {
        background-color: #fff !important;
    }
`
