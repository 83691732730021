import { Box, Button, Flex, Popover, Separator, Text } from '@/design-system'
import { useRecoverVoucher } from '@/services/reconciliation'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface RecoverVoucherProps {
    id: string
}

const RecoverVoucher: React.FC<RecoverVoucherProps> = (props) => {
    const { id } = props
    const navigate = useNavigate()

    const [recover, { isLoading }] = useRecoverVoucher()

    const handleOnRecover = async () => {
        await recover({ voucherId: id })

        navigate(`/more/reconciliation-vouchers/${id}/reconcile`)
    }

    return (
        <Popover>
            <Popover.Trigger>
                <Button variant="link" size="none">
                    Recover
                </Button>
            </Popover.Trigger>
            <Popover.Content collisionPadding={25}>
                <Box m="2" width="auto" maxWidth="sm">
                    <Flex direction="column" gap="3.5">
                        <Text weight="medium" lineHeight="none">
                            Are you sure you want to recover this voucher?
                        </Text>
                        <Text color="neutral">
                            Recovering this deleted voucher will make it available for reconciliation again.
                        </Text>
                        <Box mt="1.5">
                            <Flex direction="column" gap="6">
                                <Separator />
                                <Flex gap="4" justify="evenly">
                                    <Popover.Close asChild>
                                        <Button color="neutral" size="sm" width="full">
                                            Cancel
                                        </Button>
                                    </Popover.Close>
                                    <Button size="sm" width="full" onClick={handleOnRecover} loading={isLoading}>
                                        Recover
                                    </Button>
                                </Flex>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Popover.Content>
        </Popover>
    )
}

export { RecoverVoucher }
