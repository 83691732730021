import { WatcherID } from './types'

export const WATCHER_START = 'WATCHER_START'
export const WATCHER_FAIL = 'WATCHER_FAIL'
export const WATCHER_SUCCESS = 'WATCHER_SUCCESS'
export const WATCHER_COMPLETE = 'WATCHER_COMPLETE'

// To-do

export interface WatcherActionStart {
    type: typeof WATCHER_START
    watchers: WatcherID[]
}
export interface WatcherActionSuccess {
    type: typeof WATCHER_SUCCESS
    watchers: WatcherID[]
    message?: string
    context?: any
}
export interface WatcherActionFail {
    type: typeof WATCHER_FAIL
    watchers: WatcherID[]
    message?: string
    context?: string
}
export interface WatcherActionComplete {
    type: typeof WATCHER_COMPLETE
    watchers: WatcherID[]
}

//---

export const WatcherDispatchStart = (watchers: WatcherID[]): WatcherActionStart => {
    return { type: WATCHER_START, watchers }
}

export const WatcherDispatchSuccess = (
    watchers: WatcherID[],
    message?: string,
    context?: any
): WatcherActionSuccess => {
    return { type: WATCHER_SUCCESS, watchers, message, context }
}

export const WatcherDispatchFail = (watchers: WatcherID[], message?: any, context?: any): WatcherActionFail => {
    return { type: WATCHER_FAIL, watchers, message, context }
}

export const WatcherDispatchComplete = (watchers: WatcherID[]): WatcherActionComplete => {
    return { type: WATCHER_COMPLETE, watchers }
}

export interface SagasForWatchers {
    success(action: WatcherActionSuccess): void
    throw(action: WatcherActionFail): void
}

export type WatchersNeedingReducing =
    | WatcherActionStart
    | WatcherActionSuccess
    | WatcherActionFail
    | WatcherActionComplete
