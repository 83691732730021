import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'

export const CompanyRiskClasification: React.FC = () => {
    const merchantData = useReportMerchantData()

    const riskClassification = merchantData.tags.find((tag) => tag.name.includes('AML'))
    const riskClassificationValue = riskClassification ? riskClassification.name.replace('AML ', '') : '-'

    return <Section.DataItem title="Risk clasification" value={riskClassificationValue} />
}
