import { createReducer } from 'deox'

import { produce } from 'immer'
import { ApplicationInternalsActions } from '../actions'

import { BVDActions } from './actions'
import { InitialBVDState } from './types'

export const BVDReducer = createReducer(InitialBVDState, (handleAction) => [
    handleAction(BVDActions.STORE_BVD, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft[p.applicationId] = {
                bvd: p.bvd,
                size: 'half'
            }
        })
    }),
    handleAction(BVDActions.UNSTORE_BVD, (state, { payload: p }) => {
        return produce(state, (draft) => {
            delete draft[p.applicationId]
        })
    }),
    handleAction(BVDActions.SWITCH_UI, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft[p.applicationId]) return draft
            draft[p.applicationId].size = p.to
        })
    }),
    handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
        return produce(state, (draft) => {
            draft = { ...InitialBVDState }
            return draft
        })
    })
])
