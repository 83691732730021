import { createActionCreator } from 'deox'
import {
    ReserveCancellationStatus,
    SettlementsReserve,
    SettlementsReservesSort,
    SettlementsReservesState
} from './types'

const Prefix = 'SETTLEMENTS_RESERVES'

export const SettlementsReservesActions = {
    START_FLOW: createActionCreator(
        `${Prefix}/START_FLOW`,
        (resolve) =>
            (
                merchantId: string,
                preselected?: {
                    settlementId: string
                    type?: 'payout' | 'reserve'
                }
            ) =>
                resolve({ merchantId, preselected })
    ),
    CANCEL_FLOW: createActionCreator(`${Prefix}/CANCEL_FLOW`, (resolve) => () => resolve()),
    FETCH_SETTLEMENTS_WITH_RESERVES: createActionCreator(
        `${Prefix}/FETCH_SETTLEMENTS_WITH_RESERVE`,
        (resolve) => (merchantId: string) => resolve({ merchantId })
    ),
    STORE_SETTLEMENTS_WITH_RESERVES: createActionCreator(
        `${Prefix}/STORE_SETTLEMENTS_WITH_RESERVES`,
        (resolve) => (merchantId: string, reserves: SettlementsReserve[], openSettlementId?: string) =>
            resolve({ merchantId, reserves, openSettlementId })
    ),
    DESELECT_SETTLEMENTS: createActionCreator(
        `${Prefix}/DESELECT_SETTLEMENTS`,
        (resolve) => (merchantId: string, settlementIDs: string[]) => resolve({ merchantId, settlementIDs })
    ),
    SELECT_SETTLEMENTS: createActionCreator(
        `${Prefix}/SELECT_SETTLEMENTS`,
        (resolve) => (merchantId: string, settlementIDs: string[]) => resolve({ merchantId, settlementIDs })
    ),
    EXCLUSIVELY_SELECT_SETTLEMENTS: createActionCreator(
        `${Prefix}/EXCLUSIVELY_SELECT_SETTLEMENTS`,
        (resolve) => (merchantId: string, settlementIDs: string[]) => resolve({ merchantId, settlementIDs })
    ),
    PROCESS_SETTLEMENTS: createActionCreator(
        `${Prefix}/PROCESS_SETTLEMENTS`,
        (resolve) => (settlementIDs: string[]) => resolve({ settlementIDs })
    ),
    AUTOSELECT: createActionCreator(
        `${Prefix}/AUTOSELECT`,
        (resolve) => (merchantId: string, upTo: number, watcherId: string) => resolve({ merchantId, upTo, watcherId })
    ),
    ABORT_PROCESS: createActionCreator(
        `${Prefix}/ABORT_PROCESS`,
        (resolve) => (merchantId: string) => resolve({ merchantId })
    ),
    INTERRUPT_PROCESS: createActionCreator(
        `${Prefix}/INTERRUPT_PROCESS`,
        (resolve) => (merchantId: string) => resolve({ merchantId })
    ),
    START_CANCELLING_RESERVES: createActionCreator(
        `${Prefix}/START_CANCELLING_RESERVES`,
        (resolve) => (merchantId: string, skipExistingErrors?: boolean, firstStartRequest?: boolean) =>
            resolve({ merchantId, skipExistingErrors, firstStartRequest })
    ),
    FINISH_RESERVES_CANCELLATION: createActionCreator(
        `${Prefix}/FINISH_RESERVES_CANCELLATION`,
        (resolve) => (merchantId: string) => resolve({ merchantId })
    ),
    RETRY_FROM_FAILED: createActionCreator(
        `${Prefix}/RETRY_FROM_FAILED`,
        (resolve) => (merchantId: string) => resolve({ merchantId })
    ),
    CANCELLING_RESERVES_SUCCESS: createActionCreator(
        `${Prefix}/CANCELLING_RESERVES_SUCCESS`,
        (resolve) => (merchantId: string) => resolve({ merchantId })
    ),
    SET_RESERVE_STATUS: createActionCreator(
        `${Prefix}/SET_RESERVE_STATUS`,
        (resolve) => (merchantId: string, reserveId: string, status: ReserveCancellationStatus) =>
            resolve({ merchantId, reserveId, status })
    ),
    SET_RESERVES_SORT: createActionCreator(
        `${Prefix}/SET_RESERVES_SORT`,
        (resolve) => (merchantId: string, sort: SettlementsReservesSort['type']) => resolve({ merchantId, sort })
    ),
    INFUSE_STATE: createActionCreator(
        `${Prefix}/INFUSE_STATE`,
        (resolve) => (merchantId: string, state: SettlementsReservesState) => resolve({ merchantId, state })
    ),
    PREPARE_FOR_GRACEFUL_STOP: createActionCreator(
        `${Prefix}/PREPARE_FOR_GRACEFUL_STOP`,
        (resolve) => (merchantId: string, watcherId: string) => resolve({ merchantId, watcherId })
    ),
    SAVE_AUTOSELECT_SOLUTION: createActionCreator(
        `${Prefix}/SAVE_AUTOSELECT_SOLUTION`,
        (resolve) =>
            (
                merchantId: string,
                state: {
                    for: number
                    solution: string[]
                }
            ) =>
                resolve({ merchantId, state })
    )
}
