import React, { useCallback } from 'react'
import { Flex } from '../../components/layout/flex'

import { Text } from '../../components/general/text'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { DashboardEntriesTypes, DashboardState, DashboardStatsDictionary } from '../../store/dashboard/types'
import { numberWithCommas } from '../../utils'
import { ListPageSidebarLink } from '../../components/layout/listPageSidebarLink'

export const ApplicationsSidebarStat: React.FC<{
    isActive: boolean
    onClick: (stat: DashboardEntriesTypes) => void
    dashboard: DashboardState
    stat: DashboardEntriesTypes
    isBeta?: boolean
    hotkey: string
}> = ({ isActive, dashboard, isBeta, onClick, stat, hotkey }) => {
    const clicked = useCallback(() => {
        onClick(stat)
    }, [onClick, stat])
    const [ref, setRef] = useRefTaker()

    const clue = useHotkey({
        keys: hotkey,
        scope: 'ApplicationsList',
        clue: 'right',
        action: useCallback(() => {
            ref?.click()
        }, [ref])
    })

    return (
        <ListPageSidebarLink isActive={isActive} onClick={clicked} ref={setRef}>
            <Flex align="center">
                <Text>{DashboardStatsDictionary[stat]}</Text>
            </Flex>
            <Text>
                {dashboard.loadingStatus === 'done' ? numberWithCommas(dashboard.stats[stat]?.count, true) : ''}
            </Text>
            {clue}
        </ListPageSidebarLink>
    )
}
