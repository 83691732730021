import { LoadingStatus } from '../../../utils'

export type MerchantApplicationInternalDetailValue =
    | 'noteOwnershipStructure'
    | 'notePerson'
    | 'bvdId'
    | 'ownershipPercentage'
    | 'shortBusinessModel'
    | 'marketplaceId'
// | 'longBusinessModel'

export type MerchantApplicationsInternalsDetailsState = {
    [applicationId: string]: {
        businessModel: {
            shortBusinessModel: string
            longBusinessModel: string
            marketplaceId: string
            selfLink: string
        }
        company: {
            bvdId: string
            noteOwnershipStructure: string
            selfLink: string
        }
        shouldClone?: boolean
        clonableStates?: string[]
        people: {
            [id: string]: {
                notePerson: string
                ownershipPercentage: string
                selfLink: string
            }
        }
        loadingStatus?: LoadingStatus
    }
}

export const InitialApplicationInternalsDetailsState: MerchantApplicationsInternalsDetailsState = {}

export const InternalDetailsStructure: {
    [key in MerchantApplicationInternalDetailValue]: {
        resource: keyof MerchantApplicationsInternalsDetailsState['ID']
        label: string
        type: 'percent' | 'text' | 'multilineText' | 'shortBusinessModel' | 'marketplaceId' | 'modaledInput'
        path: string
    }
} = {
    bvdId: {
        resource: 'company',
        label: 'BvD ID',
        type: 'text',
        path: 'company'
    },
    noteOwnershipStructure: {
        resource: 'company',
        label: 'Co. ownership note',
        type: 'modaledInput',
        path: 'company'
    },
    ownershipPercentage: {
        resource: 'people',
        label: 'Ownership %',
        type: 'percent',
        path: `people.<ID>`
    },
    notePerson: {
        resource: 'people',
        label: 'Ownership note',
        type: 'multilineText',
        path: `people.<ID>`
    },
    shortBusinessModel: {
        resource: 'businessModel',
        label: 'Short business model',
        type: 'shortBusinessModel',
        path: `businessModel`
    },
    marketplaceId: {
        resource: 'businessModel',
        label: 'Marketplace ID',
        type: 'marketplaceId',
        path: `businessModel`
    }
    // longBusinessModel: {
    //     resource: 'businessModel',
    //     label: 'Long business model',
    //     type: 'multilineText',
    //     path: `businessModel`
    // }
}
