import React from 'react'
import styled, { css } from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'

export const MerchantApplicationConflictsSection: React.FC<{
    title: string
    subtitle: React.ReactElement | string
    extraTopPadding?: boolean
    onIgnoreClick?: (e: any, generatedId: string) => void
    onUnignoreClick?: (e: any, generatedId: string) => void
    onTypoFix?: (e: any, generatedId: string) => void
    isMuted?: boolean
    isMuteable: boolean
    dependsOnTheOneAbove?: boolean
    showEvenIfMuted?: boolean
    children?: React.ReactNode
}> = ({
    children,
    showEvenIfMuted,
    title,
    subtitle,
    onIgnoreClick,
    onUnignoreClick,
    onTypoFix,
    isMuteable,
    extraTopPadding,
    isMuted,
    dependsOnTheOneAbove
}) => {
    if (isMuted && !showEvenIfMuted) return null
    return (
        <>
            <HeaderElement extraTopPadding={extraTopPadding}>
                <HeaderTop>
                    {title}
                    <Flex>
                        {onTypoFix ? (
                            <>
                                <WatcherButton background="front.background" onClick={onTypoFix}>
                                    <ButtonInset>Auto-fix typos</ButtonInset>
                                </WatcherButton>
                                <Spacer width={10} />
                            </>
                        ) : null}
                        {isMuteable ? (
                            <>
                                {!isMuted && onIgnoreClick ? (
                                    <WatcherButton background="front.accent.color" onClick={onIgnoreClick}>
                                        <ButtonInset>Mute this section</ButtonInset>
                                    </WatcherButton>
                                ) : null}
                                {isMuted && onUnignoreClick ? (
                                    <WatcherButton background="front.background" onClick={onUnignoreClick}>
                                        <ButtonInset>Unmute this section</ButtonInset>
                                    </WatcherButton>
                                ) : null}
                            </>
                        ) : null}
                    </Flex>
                    {dependsOnTheOneAbove ? <Depends>Linked to the Ownership Structure (Mute below)</Depends> : null}
                </HeaderTop>
                {typeof subtitle == 'string' ? <Text>{subtitle}</Text> : subtitle}
            </HeaderElement>
            <ContentHolder isMuted={isMuted}>{children}</ContentHolder>
        </>
    )
}

const Depends = styled.div`
    border: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    margin-right: 0px;
    margin-top: -15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    color: ${(p) => p.theme['overlay.text.subtlerII']};
`

const ContentHolder = styled.div<{ isMuted?: boolean }>`
    ${(p) =>
        p.isMuted &&
        css`
            .mutedValues * {
                text-decoration: line-through;
                color: ${(p) => p.theme['front.text.subtlerII']};
            }
        `}
`

const HeaderTop = styled.div`
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme['overlay.background.strongerI']};
    font-size: 15px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    &:first-child {
        margin-top: 80px;
    }
`
const HeaderElement = styled.div<{ extraTopPadding?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    ${(p) =>
        p.extraTopPadding &&
        css`
            margin-bottom: 50px;
        `}
`
