/* eslint-disable no-param-reassign */
import { produce } from 'immer'
import dotProp from 'dot-prop'
import { createReducer } from 'deox'
import { ApplicationInternalsAgentsActions } from './actions'
import { InitialApplicationInternalAgentsState } from './types'
import { ApplicationInternalsActions } from '../actions'

export const ApplicationInternalsAgentsReducer = createReducer(
    InitialApplicationInternalAgentsState,
    (handleAction) => [
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = JSON.parse(JSON.stringify(InitialApplicationInternalAgentsState))
                return draft
            })
        }),
        handleAction(ApplicationInternalsAgentsActions.STORE, (state, { payload: p }) => {
            return produce(state, (draft: any) => {
                dotProp.set(draft, `forApplication.${p.applicationId}`, {
                    loadingStatus: 'done',
                    agent: p.response?.agents?.[0],
                    selectable: p.response?.selectable
                })
            })
        })
    ]
)
