import { ButtonInset } from '@/components/buttons/buttonInset'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { useReportParams } from '../../../../hooks'
import { useDeleteReport } from '@/services/ongoing-due-diligence'
import React from 'react'
import { getApplicationUrl } from '../../../../helpers'
import { useNavigate } from 'react-router-dom'

export const DeleteAction: React.FC = () => {
    const navigate = useNavigate()
    const { reportId, applicationId } = useReportParams()

    const [deleteReport, deleteReportResult] = useDeleteReport()
    const watcher = deleteReportResult.isLoading ? 'started' : undefined

    const handleOnDeleteClick = async () => {
        await deleteReport({ reportId }).unwrap()

        const reportUrl = getApplicationUrl(applicationId)
        navigate(reportUrl)
    }

    return (
        <SimpleButton color="front.danger.color" onClick={handleOnDeleteClick}>
            <ButtonLoadingCover color="front.danger.color" background="front.subtleDanger.background" watcher={watcher}>
                <ButtonInset>Delete</ButtonInset>
            </ButtonLoadingCover>
        </SimpleButton>
    )
}
