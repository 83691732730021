import React, { useState } from 'react'
import { Footer, Section } from '../../common'
import { AddNote } from '../common/add-note'
import { ReconcilePosting } from './reconcile-posting'
import { usePostingBalance, useReconciliationParams } from '@/features/reconciliation/hooks'
import { useGetPosting } from '@/services/reconciliation'
import { Box, Flex } from '@/design-system'
import { NoteSection } from '../common/note-section'
import { BalanceCard } from '../common/balance-card'

const PostingActions: React.FC = () => {
    const { id } = useReconciliationParams()

    const { data } = useGetPosting(id)
    const balance = usePostingBalance()

    const [showNotes, setShowNotes] = useState(false)

    if (!data) return null

    return (
        <Section>
            <Section.Content>
                <Footer>
                    {showNotes && (
                        <Box mb="6">
                            <NoteSection />
                        </Box>
                    )}
                    <Footer.Summary>
                        <Flex grow="1">
                            <Box width="auto">
                                <BalanceCard balance={balance} currency={data.currency} />
                            </Box>
                        </Flex>
                        <Footer.Summary.Actions>
                            <Flex gap="6" align="center">
                                <AddNote onCheckedChange={setShowNotes} />
                                <ReconcilePosting />
                            </Flex>
                        </Footer.Summary.Actions>
                    </Footer.Summary>
                </Footer>
            </Section.Content>
        </Section>
    )
}

export { PostingActions }
