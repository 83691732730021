import React from 'react'
import { Flex } from '@/components/layout/flex'
import styled from 'styled-components'
import { DetailsStatus } from './details-status/details-status'
import { DocumentationStatus } from './documentation-status/documentation-status'
import { ChecksStatus } from './checks-status/checks-status'
import { CommentsStatus } from './comments-status/comments-status'

export const ReportStatus: React.FC = () => {
    return (
        <Sticky>
            <Flex column>
                <DetailsStatus />
                <DocumentationStatus />
                <ChecksStatus />
                <CommentsStatus />
            </Flex>
        </Sticky>
    )
}

const Sticky = styled.div`
    position: sticky;
    top: 65px;
`
