import React, { useState } from 'react'
import styled from 'styled-components'
import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { Floater } from '../layout/floater'
import { FloaterInset } from '../layout/floaterInset'

export const HelpTip: React.FC<{ children?: any }> = ({ children }) => {
    const [show, setShow] = useState(false)
    const [ref, setRef] = useRefTaker()
    const [holderRef, setHolderRef] = useRefTaker()
    useOnHoverOutside(holderRef, () => {
        setShow(false)
    })

    return (
        <Holder ref={setHolderRef}>
            <Circle
                ref={setRef}
                onMouseEnter={() => {
                    setShow(true)
                }}
            >
                ?
            </Circle>
            <Floater anchor={ref} shouldShow={show}>
                <FloaterInset>{children}</FloaterInset>
            </Floater>
        </Holder>
    )
}

const Holder = styled.div`
    display: contents;
`

const Circle = styled.div`
    width: 13px;
    height: 13px;
    border-radius: 9999px;
    border: 1px solid ${(p) => p.theme['front.border']};
    cursor: pointer;
    display: inline-flex;
    font-size: 8px;
    margin-left: 5px;
    color: ${(p) => p.theme['front.text.subtlerI']};
    align-items: center;
    justify-content: center;
    position: relative;
    top: -1.5px;
`
