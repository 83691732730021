import { Account } from '../../../components/general/account'
import { AgentBubble } from '../../../components/general/agentBubble'
import { ApplicationStatus } from '../../../components/general/applicationStatus'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { MerchantTimelinePoint } from '../../../store/merchantTimeline/types'
import { MerchantTimelineItemDate } from './SummaryTimelineItemDate'

export const MerchantTimelineItemStateChange: React.FC<{
    point: MerchantTimelinePoint
}> = ({ point }) => {
    return (
        <Card background="front.background">
            <CardInset type="small">
                <Flex align="center">
                    <AgentBubble id={point.by} slim />
                    <Spacer width={10} />
                    <Text bold>
                        <Account id={point.by} />
                    </Text>
                    &nbsp;has marked the application as&nbsp;
                    <ApplicationStatus status={point.edits[0].to as string} />
                </Flex>
                <MerchantTimelineItemDate point={point} />
            </CardInset>
        </Card>
    )
}
