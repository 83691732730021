import { createReducer } from 'deox'
import { produce } from 'immer'
import moment from 'moment'
import { SettlementsReservesActions } from './actions'
import { InitialSettlementsReservesState, SettlementsReservesSort } from './types'

const sortCollection = (
    o: any,
    sort: SettlementsReservesSort = {
        type: 'paidOn',
        dir: 'desc'
    }
) => {
    const switchDir = sort?.dir === 'desc' ? -1 : 1

    if (!o?.all)
        return {
            all: [],
            sort
        }

    const arr: string[] = [...o.all]
    switch (sort?.type) {
        case 'date': {
            arr.sort((a, b) => {
                const x = o.at[a]?.settlementStart ? moment(o.at[a]?.settlementStart) : undefined
                const y = o.at[b]?.settlementStart ? moment(o.at[b]?.settlementStart) : undefined
                if (!x) return switchDir
                if (!y) return switchDir * -1
                return x > y ? switchDir * 1 : -1 * switchDir
            })
            break
        }
        case 'paidOn': {
            arr.sort((a, b) => {
                const x = o.at[a]?.paidOn ? moment(o.at[a]?.paidOn) : undefined
                const y = o.at[b]?.paidOn ? moment(o.at[b]?.paidOn) : undefined
                if (!x) return switchDir
                if (!y) return switchDir * -1
                return x.isBefore(y) ? switchDir * 1 : -1 * switchDir
            })
            break
        }
        case 'settled_amount': {
            arr.sort((a, b) => {
                const x = o.at[a]?.settlementAmount || 0
                const y = o.at[b]?.settlementAmount || 0
                return x > y ? switchDir * 1 : -1 * switchDir
            })
            break
        }
        case 'percent': {
            arr.sort((a, b) => {
                const x = o.at[a]?.amount / (o.at[a]?.settlementAmount || 1)
                const y = o.at[b]?.amount / (o.at[b]?.settlementAmount || 1)
                return x > y ? switchDir * 1 : -1 * switchDir
            })
            break
        }
        case 'type': {
            arr.sort((a, b) => {
                const x = o.at[a]?.type
                const y = o.at[b]?.type
                return x > y ? switchDir * 1 : -1 * switchDir
            })
            break
        }
        case 'amount': {
            const arr: string[] = [...o.all]
            arr.sort((a, b) => {
                const x = o.at[a]?.amount
                const y = o.at[b]?.amount
                return x > y ? switchDir * 1 : -1 * switchDir
            })
            break
        }
        default:
            break
    }
    return {
        all: arr,
        sort: sort
    }
}

export const SettlementsReservesReducer = createReducer(InitialSettlementsReservesState, (handleAction) => [
    handleAction(SettlementsReservesActions.STORE_SETTLEMENTS_WITH_RESERVES, (state, { payload }) =>
        produce(state, (draft) => {
            const newReserves = {
                at: payload.reserves.reduce((acc, i) => {
                    acc[i.id + '_' + i.type] = {
                        ...i
                    }
                    return acc
                }, {} as any),
                all: payload.reserves.map((i) => i.id + '_' + i.type)
            }
            draft.forMerchantId = {
                [payload.merchantId]: {
                    ...draft.forMerchantId[payload.merchantId],
                    ...(draft.forMerchantId[payload.merchantId]?.selection ? {} : { selection: {} }),
                    openSettlementId: payload.openSettlementId,
                    reserves: {
                        loadingStatus: 'done',
                        ...newReserves
                    }
                }
            }

            // Detect whether the stamp dissapeared
            state?.forMerchantId[payload.merchantId]?.cachedReserves?.all.forEach((id) => {
                const newReserve = newReserves.at[id]
                if (!newReserve) {
                    if (!state.forMerchantId[payload.merchantId].executing)
                        draft.forMerchantId[payload.merchantId].executing = {}
                    draft.forMerchantId[payload.merchantId].executing[id] = 'done'
                }
            })

            if (newReserves.all[0])
                draft.forMerchantId[payload.merchantId].currency = newReserves.at[newReserves.all[0]].currency

            const { all, sort } = sortCollection(draft.forMerchantId[payload.merchantId].reserves)
            draft.forMerchantId[payload.merchantId].sort = sort
            draft.forMerchantId[payload.merchantId].reserves.all = all
        })
    ),
    handleAction(SettlementsReservesActions.FETCH_SETTLEMENTS_WITH_RESERVES, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId = {
                [payload.merchantId]: {
                    ...draft.forMerchantId[payload.merchantId],
                    reserves: {
                        loadingStatus: 'started',
                        at: {},
                        all: []
                    }
                }
            }
        })
    ),
    handleAction(SettlementsReservesActions.INFUSE_STATE, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId = JSON.parse(JSON.stringify(payload.state.forMerchantId))
            if (!draft.forMerchantId[payload.merchantId]?.selection)
                draft.forMerchantId[payload.merchantId].selection = {}

            if (!draft.forMerchantId[payload.merchantId]?.executing)
                draft.forMerchantId[payload.merchantId].executing = {}

            if (!draft.forMerchantId[payload.merchantId]?.phase)
                draft.forMerchantId[payload.merchantId].phase = 'selection'

            const s = draft.forMerchantId[payload.merchantId].sort

            if (s) {
                const { all, sort } = sortCollection(draft.forMerchantId[payload.merchantId].reserves, s)
                draft.forMerchantId[payload.merchantId].sort = sort
                if (draft.forMerchantId[payload.merchantId]?.reserves)
                    draft.forMerchantId[payload.merchantId].reserves.all = all
            } else {
                const { all, sort } = sortCollection(draft.forMerchantId[payload.merchantId].reserves)
                draft.forMerchantId[payload.merchantId].sort = sort
                if (draft.forMerchantId[payload.merchantId]?.reserves)
                    draft.forMerchantId[payload.merchantId].reserves.all = all
            }
        })
    ),
    handleAction(SettlementsReservesActions.SELECT_SETTLEMENTS, (state, { payload }) =>
        produce(state, (draft) => {
            if (!draft.forMerchantId[payload.merchantId]?.selection) return
            payload.settlementIDs.forEach((id) => {
                draft.forMerchantId[payload.merchantId].selection[id] = true
            })
            draft.forMerchantId[payload.merchantId].autoSelect = undefined
        })
    ),
    handleAction(SettlementsReservesActions.DESELECT_SETTLEMENTS, (state, { payload }) =>
        produce(state, (draft) => {
            if (!draft.forMerchantId[payload.merchantId]?.selection) return
            payload.settlementIDs.forEach((id) => {
                delete draft.forMerchantId[payload.merchantId].selection[id]
            })
            draft.forMerchantId[payload.merchantId].autoSelect = undefined
        })
    ),
    handleAction(SettlementsReservesActions.EXCLUSIVELY_SELECT_SETTLEMENTS, (state, { payload }) =>
        produce(state, (draft) => {
            if (!draft.forMerchantId[payload.merchantId]?.selection) return
            draft.forMerchantId[payload.merchantId].selection = {}
            payload.settlementIDs.forEach((id) => {
                draft.forMerchantId[payload.merchantId].selection[id] = true
            })
            draft.forMerchantId[payload.merchantId].autoSelect = undefined
        })
    ),
    handleAction(SettlementsReservesActions.SET_RESERVES_SORT, (state, { payload }) =>
        produce(state, (draft) => {
            let s = state.forMerchantId[payload.merchantId].sort

            if (s?.type == payload.sort) {
                if (s?.dir === 'asc') {
                    s = {
                        ...s,
                        dir: 'desc'
                    }
                } else {
                    s = undefined
                }
            } else {
                s = {
                    type: payload.sort,
                    dir: 'asc'
                }
            }

            draft.forMerchantId[payload.merchantId].sort = s
            const reserves = {
                at: draft.forMerchantId[payload.merchantId]?.reserves?.at,
                all: draft.forMerchantId[payload.merchantId]?.reserves?.all
            }
            if (reserves.at && reserves.all) {
                const { all, sort } = sortCollection(reserves, s)
                draft.forMerchantId[payload.merchantId].reserves.all = [...all]
                draft.forMerchantId[payload.merchantId].sort = sort
            }
        })
    ),
    handleAction(SettlementsReservesActions.START_CANCELLING_RESERVES, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId[payload.merchantId].phase = 'processing'

            if (payload.firstStartRequest) {
                draft.forMerchantId[payload.merchantId].cachedReserves = JSON.parse(
                    JSON.stringify(draft.forMerchantId[payload.merchantId].reserves)
                )
                draft.forMerchantId[payload.merchantId].executing = {}
            }

            if (!draft.forMerchantId[payload.merchantId].executing)
                draft.forMerchantId[payload.merchantId].executing = {}
        })
    ),
    handleAction(SettlementsReservesActions.SET_RESERVE_STATUS, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId[payload.merchantId].executing[payload.reserveId] = payload.status
            if (payload.status === 'failed') {
                draft.forMerchantId[payload.merchantId].phase = 'error'
            }
        })
    ),
    handleAction(SettlementsReservesActions.INTERRUPT_PROCESS, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId[payload.merchantId].phase = 'interrupt'
            Object.keys(state.forMerchantId[payload.merchantId].executing).forEach((k) => {
                if (state.forMerchantId[payload.merchantId].executing[k] === 'in-progress')
                    draft.forMerchantId[payload.merchantId].executing[k] = 'pending'
            })
            draft.forMerchantId[payload.merchantId].shouldGracefullyStop = undefined
        })
    ),
    handleAction(SettlementsReservesActions.ABORT_PROCESS, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId[payload.merchantId].phase = 'final'
            draft.forMerchantId[payload.merchantId].shouldGracefullyStop = undefined
        })
    ),
    handleAction(SettlementsReservesActions.PREPARE_FOR_GRACEFUL_STOP, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId[payload.merchantId].shouldGracefullyStop = true
        })
    ),
    handleAction(SettlementsReservesActions.CANCELLING_RESERVES_SUCCESS, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId[payload.merchantId].phase = 'final'
            draft.forMerchantId[payload.merchantId].shouldGracefullyStop = undefined
        })
    ),
    handleAction(SettlementsReservesActions.CANCEL_FLOW, (state) =>
        produce(state, (draft) => {
            draft.forMerchantId = {}
        })
    ),
    handleAction(SettlementsReservesActions.FINISH_RESERVES_CANCELLATION, (state, { payload }) =>
        produce(state, (draft) => {
            draft.forMerchantId[payload.merchantId].phase = 'selection'
            draft.forMerchantId[payload.merchantId].reserves = { at: {}, all: [], loadingStatus: 'not-started' }
            draft.forMerchantId[payload.merchantId].executing = {}
            draft.forMerchantId[payload.merchantId].shouldGracefullyStop = undefined
        })
    ),
    handleAction(SettlementsReservesActions.SAVE_AUTOSELECT_SOLUTION, (state, { payload }) =>
        produce(state, (draft) => {
            const selectRows = () => {
                draft.forMerchantId[payload.merchantId].selection = {}
                payload.state.solution.forEach((id) => {
                    draft.forMerchantId[payload.merchantId].selection[id] = true
                })
            }
            if (!payload.state) return

            if (!state.forMerchantId[payload.merchantId].autoSelect) {
                draft.forMerchantId[payload.merchantId].autoSelect = {
                    ...payload.state
                }
                selectRows()
                return
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

            draft.forMerchantId[payload.merchantId].autoSelect = {
                ...(draft.forMerchantId[payload.merchantId].autoSelect as any),
                ...payload.state
            }

            selectRows()
        })
    )
])
