import { InitialSearchState, SearchState } from './types'
import {
    ReducedSearchActions,
    SEARCH_SHOW,
    SEARCH_HIDE,
    SEARCH_STORE_RESULTS,
    SEARCH_CLEAR_RESULTS,
    SEARCH_FIND_RESULTS,
    SEARCH_SET_DISABLED
} from './actions'

export function SearchReducer(
    state = InitialSearchState,
    action: ReducedSearchActions
): SearchState {
    switch (action.type) {
        case SEARCH_SHOW: {
            if (state.disabled) return { ...state, shown: false }
            return { ...state, shown: true }
        }
        case SEARCH_HIDE: {
            return { ...state, shown: false }
        }
        case SEARCH_CLEAR_RESULTS: {
            return {
                ...state,
                results: {
                    all: [],
                    at: {},
                    pagination: undefined,
                    loadingStatus: 'not-started'
                }
            }
        }
        case SEARCH_STORE_RESULTS: {
            const newState = {
                ...state,
                results: {
                    at: {
                        ...action.results.reduce((a, r) => {
                            return {
                                ...a,
                                [r.id]: {
                                    ...state.results.at[r.id],
                                    ...r
                                }
                            }
                        }, {})
                    },
                    all: action.results.map((r) => r.id),
                    pagination: { ...action.pagination } as any,
                    loadingStatus: 'done'
                }
            }
            return { ...newState } as any
        }
        case SEARCH_FIND_RESULTS: {
            return {
                ...state,
                results: { ...state.results, loadingStatus: 'started' }
            }
        }
        case SEARCH_SET_DISABLED: {
            return {
                ...state,
                disabled: action.disabled
            }
        }
        default:
            return state
    }
}
