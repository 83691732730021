import React from 'react'
import { useApplicationCompanyPeople } from '../../../../../api'
import { useReportParams } from '../../../../../hooks'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'
import { ManagementFiles } from './management-files'

export const ManagementMetadata: React.FC = () => {
    const merchantData = useReportMerchantData()
    const { applicationId } = useReportParams()

    const { data, isLoading, isError } = useApplicationCompanyPeople(applicationId)

    if (!data || isLoading || isError) {
        return null
    }

    const embeddedPeople = data?.embedded['ch:people']

    if (!embeddedPeople) {
        return null
    }

    const filteredPeople = embeddedPeople.filter((person) => merchantData.people.some((p) => p.id === person.id))

    return (
        <Section.Metadata>
            {filteredPeople.map((person, i) => {
                const last = i === filteredPeople.length - 1

                return (
                    <React.Fragment key={person.id}>
                        <Section.Title color="normal">{`${i + 1}. Person`}</Section.Title>
                        <Section.Spacer size="s" />
                        <ManagementFiles filesUrl={person.relations.files} />
                        {!last && <Section.Spacer />}
                    </React.Fragment>
                )
            })}
        </Section.Metadata>
    )
}
