import jsonScopes from './scopes.json'

const universal = jsonScopes.universal
const sensitive = jsonScopes.sensitive

const crud_resources = jsonScopes.crud_resources.reduce((acc: string[], i) => {
    acc.push(`create:${i}`)
    acc.push(`read:${i}`)
    acc.push(`update:${i}`)
    acc.push(`delete:${i}`)
    return acc
}, [])

const cru_resources = jsonScopes.cru_resources.reduce((acc: string[], i) => {
    acc.push(`create:${i}`)
    acc.push(`read:${i}`)
    acc.push(`update:${i}`)
    return acc
}, [])

const ru_resources = jsonScopes.ru_resources.reduce((acc: string[], i) => {
    acc.push(`read:${i}`)
    acc.push(`update:${i}`)
    return acc
}, [])

const r_resources = jsonScopes.r_resources.reduce((acc: string[], i) => {
    acc.push(`read:${i}`)
    return acc
}, [])


export const scopes = [
    ...universal,
    ...sensitive,
    ...crud_resources,
    ...cru_resources,
    ...ru_resources,
    ...r_resources
]