import { InitialToastsState, ToastsState } from './types'
import { ReducedToastsActions, TOASTS_POP, TOASTS_PUSH } from './actions'
import { returnUnique, returnWithout } from '../../utils'

export function ToastsReducer(state = InitialToastsState, action: ReducedToastsActions): ToastsState {
    switch (action.type) {
        case TOASTS_PUSH: {
            return {
                ...state,
                at: {
                    ...state.at,
                    [action.toast.id]: {
                        ...action.toast
                    }
                },
                all: returnUnique(state.all, action.toast.id)
            }
        }
        case TOASTS_POP: {
            const newState = {
                ...state,
                all: returnWithout(state.all, action.toastId)
            }
            delete state.at[action.toastId]
            return { ...newState }
        }
    }
    return state
}
