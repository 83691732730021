import React, { ReactElement, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { WatcherButton } from '../buttons/watcherButton'
import { Flex } from '../layout/flex'
import { Floater } from '../layout/floater'
import { FloaterInset } from '../layout/floaterInset'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { Icon } from '../icons/icon'

export const FormHandler: React.FC<{
    formSubmitLabel: string
    brandNewForm?: boolean
    changes: {
        [key: string]: {
            from: string
            to: string
        }
    }
    errors: any
    focusInvalidField: () => void
    textForChange: (key: string, value: any) => string | ReactElement
    textForFieldName: (key: string) => string
    resetForm: () => void
    submitForm: (form: any) => void
    submitWatcherId: string
}> = ({
    changes: formChanges,
    errors,
    focusInvalidField,
    textForChange,
    textForFieldName,
    resetForm,
    submitForm,
    brandNewForm,
    formSubmitLabel,
    submitWatcherId
}) => {
    const [container, setContainer] = useRefTaker()
    const [floaterAnchor, setFloaterAnchor] = useRefTaker()
    const [showTooltip, setShowTooltip] = useState(false)

    useOnHoverOutside(container, () => {
        setShowTooltip(false)
    })

    const changes = useMemo(() => {
        return Object.keys(formChanges)
    }, [formChanges])

    const renderedChangesTooltip = useMemo(() => {
        if (!floaterAnchor) return null

        return (
            <Floater anchor={floaterAnchor} shouldShow={showTooltip}>
                <FloaterInset>
                    <ChangesGrid>
                        {changes.length ? (
                            <>
                                {changes.map((e: any) => {
                                    return (
                                        <>
                                            <Flex align="flex-start" justify="flex-start">
                                                <Text cy="change-label" noWrap>
                                                    {textForFieldName(e)}
                                                </Text>
                                                <Spacer width={20} />
                                            </Flex>
                                            <TextHolder>
                                                <Text cy="change-from" noWrap>
                                                    {textForChange(e, formChanges[e].from)}
                                                </Text>
                                            </TextHolder>
                                            <Flex align="flex-start" column>
                                                <Spacer height={5} />
                                                <Icon type="arrow" />
                                            </Flex>
                                            <TextHolder>
                                                <Text cy="change-to" noWrap>
                                                    {textForChange(e, formChanges[e].to)}
                                                </Text>
                                            </TextHolder>
                                        </>
                                    )
                                })}
                            </>
                        ) : (
                            'No changes found'
                        )}
                    </ChangesGrid>
                </FloaterInset>
            </Floater>
        )
    }, [floaterAnchor, showTooltip, changes, textForChange, textForFieldName, formChanges])

    const errorsCount = Object.keys(errors)?.length || 0
    if (errorsCount)
        return (
            <Flex align="center">
                <Text color="front.text.subtlerI">
                    {errorsCount === 1 ? `${errorsCount} field` : `${errorsCount} fields`} with error
                </Text>
                <Spacer width={10} />
                <SimpleButton background="front.background" cy="reset-form" onClick={resetForm}>
                    <ButtonInset>Reset</ButtonInset>
                </SimpleButton>
                <Spacer width={5} />
                <SimpleButton background="front.danger.color" cy="go-to-field" onClick={focusInvalidField}>
                    <ButtonInset>Go to field</ButtonInset>
                </SimpleButton>
            </Flex>
        )

    if (!brandNewForm)
        if (!changes || changes?.length === 0)
            return (
                <Flex align="center">
                    <Text color="front.text.subtlerI">No changes have been made</Text>
                </Flex>
            )

    return (
        <Flex align="center">
            {!brandNewForm && (
                <>
                    <div ref={setContainer}>
                        <ChangesText
                            onMouseEnter={() => {
                                setShowTooltip(true)
                            }}
                        >
                            <Text color="front.accent.color" ref={setFloaterAnchor}>
                                {changes.length === 1 ? `1 unsaved change` : `${changes.length} unsaved changes`}
                            </Text>
                        </ChangesText>
                        {renderedChangesTooltip}
                    </div>
                    <Spacer width={10} />
                    <SimpleButton background="front.background" onClick={resetForm}>
                        <ButtonInset>Reset</ButtonInset>
                    </SimpleButton>
                    <Spacer width={5} />
                </>
            )}
            <WatcherButton
                background="front.accent.color"
                cy="form-submit"
                onClick={submitForm}
                predefinedWatcher={submitWatcherId}
            >
                <ButtonInset>{formSubmitLabel}</ButtonInset>
            </WatcherButton>
        </Flex>
    )
}

const ChangesGrid = styled.div`
    color: #fff;
    display: grid;
    grid-template-columns: min-content min-content min-content min-content;
    grid-column-gap: 10px;
    white-space: nowrap;
`

const ChangesText = styled.div`
    cursor: pointer;
    transition: 0.3s ease all;
    &:hover {
        text-decoration: underline;
    }
`

const TextHolder = styled.div`
    max-width: 300px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
