import { Component } from 'react'
import { TrackJS } from 'trackjs'

export class ErrorBoundary extends Component<{ children: any }> {
    componentDidCatch(error: any, errorInfo: any) {
        if (import.meta.env.VITE_TRACK_JS_TOKEN) TrackJS.track(error)
        else console.error(error, errorInfo)
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true }
    }

    render() {
        return this.props.children
    }
}
