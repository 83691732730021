import 'overlayscrollbars/overlayscrollbars.css'

import { TrackJS } from 'trackjs'

import './configs/_setupHotkeysListener'
import './configs/_setupYup'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import './index.css'
import { Provider } from 'react-redux'
import { store } from '@/store'
import { Authentication } from './Authentication'

if (import.meta.env.VITE_TRACK_JS_TOKEN)
    TrackJS.install({
        token: import.meta.env.VITE_TRACK_JS_TOKEN,
        onError: (payload) => {
            if (
                import.meta.env.VITE_KVASIR_API &&
                payload.message?.includes(import.meta.env.VITE_KVASIR_API) &&
                (payload.message?.includes('404') || payload.message?.includes('504'))
            )
                return false
            return true
        }
    })
const container = document.getElementById('root')
if (!container) throw new Error('Failed to mount container')

const root = createRoot(container)
root.render(
    <Provider store={store}>
        <Authentication>
            <RouterProvider router={router} />
        </Authentication>
    </Provider>
)
