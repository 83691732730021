import { Spacer } from '@/components/layout/spacer'
import React from 'react'
import { Sections } from '../common/sections'
import { useReportDetails } from '../provider/report-provider/report-provider'
import { ChecksSection } from './checks-section/check-section'
import { CommentsSection } from './comments-section/comments-section'
import { DetailsSection } from './details-section/details-section'
import { DocumentationSection } from './documentation-section/documentation-section'

export const ReportSections: React.FC = () => {
    const details = useReportDetails()

    const isFinalized = details.report_status === 'finalized'

    return (
        <Sections disabled={isFinalized}>
            <DetailsSection />
            <Spacer height={24} />
            <DocumentationSection />
            <Spacer height={24} />
            <ChecksSection />
            <Spacer height={24} />
            <CommentsSection />
            <Spacer height={64} />
        </Sections>
    )
}
