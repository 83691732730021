import React from 'react'
import { ReportStatusItem } from '../../common/report-status-item'
import { useDocumentationStatus } from './use-documentation-status'

export const DocumentationStatus: React.FC = () => {
    const completed = useDocumentationStatus()

    return (
        <ReportStatusItem completed={completed} of={6}>
            Documentation
        </ReportStatusItem>
    )
}
