import { Transaction } from '../../store/transactions/types'
import { Text } from '../../components/general/text'
import { ExpandableCard } from '../../components/cards/expandableCard'
import { CardInset } from '../../components/cards/cardInset'
import { useCallback } from 'react'
import { TransactionDispatchLoadAppendix } from '../../store/transactions/actions'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { LoaderView } from '../../components/loaders/loader'

export const TransactionAppendix: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
    const dispatch = useDispatch()

    const fetchAppendix = useCallback(() => {
        if (transaction?.appendixLink && transaction?.appendix.loadingStatus === 'not-started') {
            dispatch(TransactionDispatchLoadAppendix(transaction))
        }
    },[dispatch, transaction])

    return (
        <ExpandableCard
            isInitiallyExpanded={false}
            headerMemo={
                <Text bold>Transaction Details</Text>
            }
            onExpand={fetchAppendix}
        >
            <CardInset>
                <ResponseBlock>
                    <CardInset type="small">
                        {transaction.appendix.loadingStatus === "started" &&
                            <LoaderView overBackground="front.background" minHeight="600px" />
                        }
                        {(transaction.appendix.loadingStatus === "done" && transaction.appendix.data) &&
                            <Text>
                                <pre>
                                    {JSON.stringify(transaction.appendix.data, null, 2)}
                                </pre>
                            </Text>
                        }
                    </CardInset>
                </ResponseBlock>
            </CardInset>
        </ExpandableCard>
    )
}

const ResponseBlock = styled.div`
    border-radius: 10px;
    background-color: ${(p) => p.theme['front.highlights']};
    pre {
        white-space: pre-wrap;
    }
`
