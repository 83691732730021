import Color from 'color'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useRefTaker } from '../../hooks/general/useRefTaker'

import { RootState } from '@/store'
import { UserAccountsDispatchGetAccount } from '../../store/userAccounts/actions'
import { UserAccount as AccountDatatype } from '../../store/userAccounts/types'
import { Icon } from '../icons/icon'
import { Floater } from '../layout/floater'
import { FloaterInset } from '../layout/floaterInset'
import { colorHash } from './colorTag'
import { SlimLoader } from '../loaders/loader'
import { Spinner } from '../loaders/spinner'
import { Text } from './text'
import { isAgent } from '../../utils'

export const AgentBubble: React.FC<{
    id?: string
    staticEmail?: string
    staticName?: string
    filled?: boolean
    slim?: boolean
}> = ({ slim, ...p }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [circleRef, setCircleRef] = useRefTaker()
    const account: AccountDatatype | undefined = useSelector((state: RootState) =>
        p.id ? state.userAccounts.at[p.id] : undefined
    )

    useEffect(() => {
        if (p.id && (!account || account.loadingStatus == 'not-started'))
            dispatch(UserAccountsDispatchGetAccount(p.id, { isMerchant: false }))
    }, [account, dispatch, p.id])

    const showFloater = useCallback(() => {
        setShow(true)
    }, [])
    const hideFloater = useCallback(() => {
        setShow(false)
    }, [])

    if (!p.staticEmail && !p.staticName) {
        if (!p.id) return null
        if (account?.data === 'started')
            return (
                <Circle background="#FF" slim={slim}>
                    {slim ? (
                        <SlimLoader background="front.background" />
                    ) : (
                        <Spinner overBackground="front.background" type="s" />
                    )}
                </Circle>
            )
        if (!account || !account.data || !account.data.id)
            return (
                <UnknownCircle background="" ref={circleRef} slim={slim}>
                    {slim ? (
                        <Letters>?</Letters>
                    ) : (
                        <Text size="s" bold>
                            ?
                        </Text>
                    )}
                </UnknownCircle>
            )
    }

    const email = account?.data?.email || p.staticEmail
    const name = p.staticName
    const color = colorHash.hex(email || ' ')

    if (!email && !name)
        return (
            <>
                <Circle background={color} ref={setCircleRef} slim={slim}>
                    {slim ? (
                        <Letters>?</Letters>
                    ) : (
                        <Text size="s" bold>
                            ?
                        </Text>
                    )}
                </Circle>
                <Floater anchor={circleRef} shouldShow={show} noFocusLock>
                    <FloaterInset>
                        <Letters>Unknown</Letters>
                    </FloaterInset>
                </Floater>
            </>
        )

    if (!isAgent(email)) {
        if (slim)
            return (
                <Circle background={'#F00'} filled={p.filled} slim={slim}>
                    <Letters>!?!</Letters>
                </Circle>
            )
        return (
            <Circle background="yellow" merchant slim={slim}>
                <Icon type="merchant" />
            </Circle>
        )
    }

    const clearhausPrefix = email.split('@')[0]
    return (
        <>
            <Circle
                background={color}
                ref={setCircleRef}
                filled={p.filled}
                slim={slim}
                onMouseEnter={showFloater}
                onMouseLeave={hideFloater}
            >
                <Letters>{clearhausPrefix.length > 4 ? clearhausPrefix.substr(0, 4) : clearhausPrefix}</Letters>
            </Circle>
            <Floater anchor={circleRef} shouldShow={show} noFocusLock>
                <FloaterInset>
                    <Text color="floating.text" size="s">
                        {email}
                    </Text>
                </FloaterInset>
            </Floater>
        </>
    )
}

const Letters = styled.span`
    display: block;
    text-align: center;
    line-height: 10px;
    text-transform: uppercase;
    color: ${(p) => p.theme['front.text']};
    font-size: 9px;
    font-weight: 500;
    top: 2px;
    left: 0px;
    margin-right: 0;
    position: relative;
`

const Circle = styled.div<{
    slim?: boolean
    background: string
    merchant?: boolean
    filled?: boolean
}>`
    position: relative;
    width: 30px;
    height: 25px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    opacity: 1;
    z-index: 0;
    color: ${(p) => p.theme['front.text']};
    background-color: ${(p) =>
        p.background ? Color(p.background).saturate(1).mix(Color(p.theme['front.background']), 0.62).string() : 'red'};
    border: 1px solid transparent;

    ${(p) =>
        p.slim &&
        css`
            padding: 2px 0;
            height: auto;
            ${Letters} {
                left: 0;
                top: 0;
                line-height: 10px;
            }
        `}

    ${(p) =>
        p.merchant &&
        css`
            background-color: ${(p) => p.theme['front.background']};
            border: 1px solid ${(p) => p.theme['front.background.strongerII']};
        `}
    div {
        text-transform: none;
    }
`

const UnknownCircle = styled(Circle)`
    background-color: ${(p) => p.theme['back.background']};
    color: ${(p) => p.theme['front.text.subtlerI']};
`
