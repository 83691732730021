import React from 'react'
import { GridStyles, ItemStyles, styles } from './styles'
import { cn } from '../utils'
import { Slot } from '@radix-ui/react-slot'

interface GridProps extends GridStyles {
    children: React.ReactNode
    className?: string
    customColumns?: string
}

const Grid = (props: GridProps) => {
    const { children, className, customColumns, display, overflow, columns, columnsAuto, rows, gap, align, justify } =
        props

    const style = customColumns ? { gridTemplateColumns: customColumns } : undefined

    const classnames = cn(
        styles.grid({ display, overflow, columns, columnsAuto, rows, gap, align, justify }),
        className
    )

    return (
        <div className={classnames} style={style}>
            {children}
        </div>
    )
}

interface ItemProps extends ItemStyles {
    children: React.ReactNode
    className?: string
    asChild?: boolean
}

const Item: React.FC<ItemProps> = (props) => {
    const {
        children,
        className,
        asChild,
        columnSpan,
        columnStart,
        columnEnd,
        rowSpan,
        rowStart,
        rowEnd,
        align,
        justify
    } = props

    const classnames = cn(
        styles.item({ columnSpan, columnStart, columnEnd, rowSpan, rowStart, rowEnd, align, justify }),
        className
    )
    const Component = asChild ? Slot : 'div'

    return <Component className={classnames}>{children}</Component>
}

Grid.Item = Item

export { Grid }
