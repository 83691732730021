import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { Tooltip } from '../../components/general/tooltip'

export const TransactionsFlagWithTooltip: React.FC<{
    flag: 'settled' | 'recurring' | 'fraud' | '3ds-attempt' | '3ds' | 'no-3ds' | 'empty'
    version?: string
}> = ({ flag, version }) => {
    const ref = useRef<any>()

    if (flag === '3ds') {
        return (
            <Holder>
                <TooltipTrigger ref={ref}>3DS{version?.includes('2.') ? 'v2\u00a0' : 'v1\u00a0'}</TooltipTrigger>
                <Tooltip element={ref}>3-D Secure {version}</Tooltip>
            </Holder>
        )
    }

    if (flag === 'no-3ds') {
        return <TooltipTrigger noFlag>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TooltipTrigger>
    }

    if (flag === '3ds-attempt') {
        return (
            <Holder>
                <TooltipTrigger ref={ref}>
                    3DS-a&nbsp;
                </TooltipTrigger>
                <Tooltip element={ref}>3-D Secure has been attempted</Tooltip>
            </Holder>
        )
    }

    if (flag === 'settled')
        return (
            <Holder>
                <TooltipTrigger ref={ref}>S</TooltipTrigger>
                <Tooltip element={ref}>Transaction has been SETTLED</Tooltip>
            </Holder>
        )

    if (flag === 'recurring')
        return (
            <Holder>
                <TooltipTrigger ref={ref}>R</TooltipTrigger>
                <Tooltip element={ref}>Recurring Transaction</Tooltip>
            </Holder>
        )

    if (flag === 'fraud')
        return (
            <Holder>
                <TooltipTrigger ref={ref}>F</TooltipTrigger>
                <Tooltip element={ref}>Transaction flagged as FRAUD</Tooltip>
            </Holder>
        )

    if (flag === 'empty') return <TooltipTrigger noFlag>&nbsp;</TooltipTrigger>

    return <TooltipTrigger noFlag>{flag}</TooltipTrigger>
}

const Holder = styled.span``
export const TooltipTrigger = styled.span<{ noFlag?: boolean }>`
    cursor: help;

    font-family: 'Roboto Mono', mono;
    font-weight: 500;
    letter-spacing: -0.5px;
    transition: 0.1s ease opacity;
    &:hover {
        opacity: 0.3;
    }

    ${(p) =>
        p.noFlag &&
        css`
            transition: none;
            cursor: pointer;
            &:hover {
                cursor: pointer;
                opacity: 1;
                text-decoration: none;
            }
        `}
`
