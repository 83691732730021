import { createReducer } from 'deox'
import { produce } from 'immer'
import moment from 'moment'
import { ApplicationInternalsSchemesScreeningActions } from './actions'
import { InitialApplicationInternalSchemesScreeningState, Inquiry } from './types'

export const ApplicationInternalsSchemesScreeningReducer = createReducer(
    InitialApplicationInternalSchemesScreeningState,
    (handleAction) => [
        handleAction(ApplicationInternalsSchemesScreeningActions.STORE_INQUIRIES_FAILED, (state, { payload: p }) => {
            return produce(state, (draft) => {
                if (!draft.forApplication[p.applicationId]) draft.forApplication[p.applicationId] = {} as any

                draft.forApplication[p.applicationId].latestInquiry = undefined
                draft.forApplication[p.applicationId].inquiries = []
                draft.forApplication[p.applicationId].loadingStatus = 'error'
            })
        }),
        handleAction(ApplicationInternalsSchemesScreeningActions.FETCH_INQUIRIES, (state, { payload: p }) => {
            return produce(state, (draft) => {
                if (!draft.forApplication[p.applicationId]) draft.forApplication[p.applicationId] = {} as any
                draft.forApplication[p.applicationId].loadingStatus = 'started'
            })
        }),
        handleAction(ApplicationInternalsSchemesScreeningActions.STORE_INQUIRIES, (state, { payload: p }) => {
            const sortedInquiries = JSON.parse(JSON.stringify(p.inquiries))
            sortedInquiries.sort((a, b) => {
                const am = moment(a.at)
                const bm = moment(b.at)

                return am.isBefore(bm) ? 1 : -1
            })

            const latestInquiry: Inquiry = sortedInquiries[0]

            return produce(state, (draft) => {
                if (!draft.forApplication[p.applicationId]) draft.forApplication[p.applicationId] = {} as any

                draft.forApplication[p.applicationId].latestInquiry = latestInquiry
                draft.forApplication[p.applicationId].inquiries = sortedInquiries
                draft.forApplication[p.applicationId].loadingStatus = 'done'

                resolveInquiryStatus(draft, p.applicationId, latestInquiry)
            })
        }),
        handleAction(ApplicationInternalsSchemesScreeningActions.STORE_NEW_INQUIRY, (state, { payload: p }) => {
            return produce(state, (draft) => {
                if (!draft.forApplication[p.applicationId]) draft.forApplication[p.applicationId] = {} as any
                if (!draft.forApplication[p.applicationId].inquiries.length)
                    draft.forApplication[p.applicationId].inquiries = [] as any

                draft.forApplication[p.applicationId].inquiries.splice(0, 0, p.newInquiry)
                draft.forApplication[p.applicationId].latestInquiry = p.newInquiry

                const latestInquiry: Inquiry = p.newInquiry

                resolveInquiryStatus(draft, p.applicationId, latestInquiry)
            })
        })
    ]
)

const resolveInquiryStatus = (draft: any, applicationId: string, latestInquiry: Inquiry) => {
    const matchLength = latestInquiry?.matchResults?.length || 0
    const vmssLength = latestInquiry?.vmssResults?.length || 0
    const concludedLength =
        latestInquiry?.conclusion?.matches.length + (latestInquiry?.conclusion?.pastInquiryMatches.length || 0)
    if (!latestInquiry) {
        draft.forApplication[applicationId].status = 'no-inquiry'
        return
    }

    if (latestInquiry.conclusion && !latestInquiry.conclusion.passed) {
        draft.forApplication[applicationId].status = 'failed-inquiry'
        return
    }

    if (latestInquiry.conclusion && latestInquiry.conclusion.passed) {
        draft.forApplication[applicationId].status = 'successful-inquiry'
        return
    }
    if (!(matchLength + vmssLength)) {
        draft.forApplication[applicationId].status = 'no-results-inquiry'
        return
    }

    if (!latestInquiry.conclusion) draft.forApplication[applicationId].status = 'inconclusive-inquiry'
}
