import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../../../components/general/text'
import { LoaderView } from '../../../components/loaders/loader'
import { CenteredScreen } from '../../../components/layout/centeredScreen'
import { CenteredScreenHeader } from '../../../components/layout/centeredScreenHeader'
import { InnerPageBackButton } from '../../../components/buttons/innerPageBackButton'
import { MerchantApplicationAddScreenshot } from './Application.AddScreenshotButton'
import { Spacer } from '../../../components/layout/spacer'
import { useSecretaryFileRefresher } from '../../../hooks/general/useSecretaryFileRefresher'
import { TopBar } from '../../../components/layout/topBar'
import { getWebsiteLabel } from '../../../utils/formatting'
import { camelCase } from 'lodash'
import { ExternalLink } from '../../../components/buttons/externalLink'
import { WebsiteSectionScreenshots } from './Application.WebsiteSectionScreenshots'
import { RootState } from '@/store'
import { useSelector } from 'react-redux'
import { useModalStackSync } from '../../../hooks/general/useModalStackSync'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { useParams } from 'react-router-dom'

type Params = {
    id: string
}

export const MerchantApplicationWebsites: React.FC = () => {
    const params = useParams() as Params

    const { id } = params
    useSecretaryFileRefresher(id)
    const websites = useSelector((state: RootState) => state.applicationResources?.data?.forApplication[id]?.websites)
    const name = useMerchantName(id, `Application`)
    useModalStackSync('Websites:' + name, `Merchant.ApplicationPage.WebsitesGallery`)
    const backButton = useMemo(() => {
        return <InnerPageBackButton backTo={`/merchant/${id}/application`} suffix="Application" />
    }, [id])

    const addButtons = useMemo(() => {
        return websites?.fields?.map((w, i) => {
            if (w.url) return <MerchantApplicationAddScreenshot key={id} applicationId={id} subsectionIndex={i} />
            return null
        })
    }, [id, websites])

    return (
        <>
            <TopBar leftSide={backButton} title="Website Screenshots Gallery" />
            <CenteredScreen>
                {websites?.fields ? (
                    websites.fields.map((test, i) => {
                        return (
                            <WebsiteWrapper spaceAbove={i !== 0} key={test.url}>
                                <CenteredScreenHeader
                                    offset={10}
                                    sticky
                                    left={
                                        test.url ? (
                                            <Text align="center" oneLine bold>
                                                Screenshots for&nbsp;
                                                <ExternalLink
                                                    url={test.url}
                                                    target="blank"
                                                    alwaysShowArrow
                                                    key={camelCase(test.url)}
                                                    label={getWebsiteLabel(test.url)}
                                                />
                                            </Text>
                                        ) : (
                                            <Text>Unnamed website</Text>
                                        )
                                    }
                                    right={addButtons[i]}
                                />
                                <WebsiteSectionScreenshots applicationId={id} index={i} />
                            </WebsiteWrapper>
                        )
                    })
                ) : (
                    <LoaderView overBackground="back.background" />
                )}
                <Spacer height={80} />
            </CenteredScreen>
        </>
    )
}

const WebsiteWrapper = styled.div<{ spaceAbove?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: calc(${290 * 3}px);

    ${(p) =>
        p.spaceAbove &&
        css`
            margin-top: 60px;
        `}
`
