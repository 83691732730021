import React from 'react'
import { MonitoredChange, MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { Text } from '../../../../../../../components/general/text'
import { MonitoringPersonAdded } from '../../../cards/MonitoringPersonAdded/MonitoringPersonAdded'
import { isObject } from 'lodash'
import { TasksCompanyChangesActions } from '../../../../../../../store/tasksCompanyChanges/actions'
import { useDispatch } from 'react-redux'

interface CardDirectorAddedProps {
    taskId: string
    applicationData: MonitoredFetchedApplicationData
    change: MonitoredChange
}

export const CardDirectorAdded: React.FC<CardDirectorAddedProps> = (props) => {
    const { taskId, applicationData, change } = props

    const dispatch = useDispatch()

    if (!applicationData) {
        return <Text>Failed to fetch application data, task is therefore not shown.</Text>
    }

    const after = isObject(change.after) ? change.after : { name: change.after }

    const isMuted = applicationData.muted?.director?.name?.includes(change.after.toString())

    const handleOnMute = (_, watcherId) => {
        const applicationId = applicationData?.application.id

        const mutedNameArray = isMuted
            ? applicationData?.muted?.director?.name.filter((n) => n != change.after)
            : [...(applicationData?.muted?.director?.name || []), change.after]

        if (applicationId) {
            dispatch(
                TasksCompanyChangesActions.CHANGE_MUTED_STATE(applicationId, taskId, watcherId, {
                    director: {
                        name: mutedNameArray
                    }
                })
            )
        }
    }

    return (
        <MonitoringPersonAdded
            type="management"
            applicationData={applicationData}
            subsectionId={change.subsectionId}
            after={after}
            onMute={handleOnMute}
            isMuted={isMuted}
        />
    )
}
