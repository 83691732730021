import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { Icon } from '../../icons/icon'
import { Floater } from '../../layout/floater'
import { FloaterInset } from '../../layout/floaterInset'
import { Flex } from '../../layout/flex'
import { Spacer } from '../../layout/spacer'
import { SimpleButton } from '../../buttons/simpleButton'
import { ButtonInset } from '../../buttons/buttonInset'
import { List } from '../../layout/list'
import { Text } from '../../../components/general/text'
import { SlimLoader } from '../../../components/loaders/loader'
import { SideBarLink } from './sidebarLink'
import { SidebarLinkHolder } from './sidebarLinkHolder'
import { useDispatch, useSelector } from 'react-redux'
import { CallsPrefActions } from '../../../store/calls-preferences/actions'
import { RootState } from '@/store'
import { useOnClickOutside } from '../../../hooks/general/useOnClickOutside'
import { CallsDispatchLoadPhoneName } from '../../../store/calls/actions'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

export type LineOption = '1' | '2' | '3'
export const AvailableLineOptions: LineOption[] = ['1', '2', '3']

export type NotificationStatus = 'on' | 'off'
export const AvailableNotificaitonOptions: NotificationStatus[] = ['on', 'off']

const lineOptions = (line: LineOption) => {
    switch (line) {
        case '1':
            return '1 (first, second and last line)'
        case '2':
            return '2 (second and last line)'
        case '3':
            return '3 (last line only)'
    }
}

const notificationOptions = (status: NotificationStatus) => {
    switch (status) {
        case 'on':
            return 'On'
        case 'off':
            return 'Off'
    }
}

export const SidebarCallSettingsPanel = () => {
    const stateLine = useSelector((state: RootState) => state.callsPref.line)
    const stateLocalNumber = useSelector((state: RootState) => state.callsPref.local_number)
    const stateNotifications = useSelector((state: RootState) => state.callsPref.notifications)
    const phoneName = useSelector((state: RootState) => state.calls.phoneName)
    const [showFloater, setShowFloater] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [ref, setRef] = useRefTaker()
    const [floaterRef, setFloaterRef] = useRefTaker()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useSelector((state: RootState) => state.auth)
    const nickname = auth.user ? auth.user.nickname : 'unknown'
    const [lineRef, setLineRef] = useState<string>(stateLine)
    const [localnumRef, setLocalnumRef] = useState<string>(stateLocalNumber)
    const [notifRef, setNotifRef] = useState<string>(stateNotifications)
    const [indicator, setIndicator] = useState<string>(notifRef === 'off' ? '✕' : lineRef)
    const [isLoading, setIsLoading] = useState(false)
    const [checkedName, setCheckedName] = useState(false)
    const disabled = notifRef === 'off'

    const isOnActiveCallsPage = location.pathname == '/calls/active-calls'

    const handleHide = useCallback(() => {
        setShowFloater(false)
    }, [])
    useOnClickOutside(floaterRef, handleHide)

    const onSavePref = useCallback(() => {
        if (notifRef === 'off') {
            if (checkedName) {
                const name = phoneName.name === nickname ? nickname : ''
                dispatch(
                    CallsPrefActions.UPDATE_PREF(
                        notifRef as NotificationStatus,
                        localnumRef,
                        lineRef as LineOption,
                        name
                    )
                )
                setIndicator('✕')
                setCheckedName(false)
                setShowWarning(false)
                handleHide()
            } else {
                dispatch(CallsDispatchLoadPhoneName(localnumRef))
            }
        } else {
            if (checkedName && phoneName.localNumber == localnumRef) {
                setIndicator(lineRef)
                dispatch(
                    CallsPrefActions.UPDATE_PREF(
                        notifRef as NotificationStatus,
                        localnumRef,
                        lineRef as LineOption,
                        nickname
                    )
                )
                setCheckedName(false)
                setShowWarning(false)
                handleHide()
            } else {
                dispatch(CallsDispatchLoadPhoneName(localnumRef))
            }
        }
    }, [
        lineRef,
        notifRef,
        localnumRef,
        checkedName,
        phoneName,
        nickname,
        dispatch,
        handleHide,
        setCheckedName,
        setShowWarning,
        setIndicator
    ])

    useEffect(() => {
        if (phoneName.loadingStatus == 'started') setIsLoading(true)
        setShowWarning(false)
        if (phoneName.loadingStatus == 'done') {
            setIsLoading(false)
            setCheckedName(true)
            if (!phoneName.name || phoneName.name === nickname) {
                setShowWarning(false)
                onSavePref()
            } else {
                if (notifRef === 'on') setShowWarning(true)
                if (notifRef === 'off') onSavePref()
            }
        }
    }, [phoneName, nickname, onSavePref, notifRef])

    const handleClick = useCallback(() => {
        setShowFloater((s) => !s)
    }, [])

    const icon = useMemo(() => {
        const faded = indicator === '✕'
        return (
            <CallsIcon>
                <div ref={setRef} className={faded ? 'fadedIcon' : ''}>
                    <span>{indicator}</span>
                    <Icon type="sidebarCalls" />
                </div>
            </CallsIcon>
        )
    }, [indicator, setRef])

    const warningMessage = useMemo(() => {
        if (!showWarning) return
        return (
            <WarningText>
                <Text color="front.danger.color">
                    Local number {phoneName.localNumber} is currently used by {phoneName.name}. Press Save to claim the
                    local number or change your settings.
                </Text>
            </WarningText>
        )
    }, [showWarning, phoneName])

    const handleLineSelection = useCallback(
        (line: string) => {
            setLineRef(line)
        },
        [setLineRef]
    )

    const handleLocalNumberSelection = useCallback(
        (local_number: string) => {
            setLocalnumRef(local_number)
        },
        [setLocalnumRef]
    )

    const handleNotificationsSelection = useCallback(
        (notifications: string) => {
            setNotifRef(notifications)
        },
        [setNotifRef]
    )

    const onActiveCallsClick = useCallback(() => {
        setShowFloater((s) => !s)
        navigate(`/calls/active-calls`)
    }, [navigate])

    return (
        <>
            <div ref={setFloaterRef}>
                <Floater
                    cardId="Sidebar.SettingsPanel"
                    anchor={ref}
                    shouldShow={showFloater}
                    onHide={handleHide}
                    placement="top"
                    cy="calls-menu"
                >
                    <FloaterInset equalPadding padding="medium">
                        <List
                            background="floating.background"
                            alignRight
                            items={{
                                'Status': {
                                    type: 'select',
                                    textForItem: notificationOptions,
                                    selected: notifRef,
                                    items: AvailableNotificaitonOptions,
                                    onSelect: handleNotificationsSelection,
                                    overBackground: 'floating.background',
                                    dropdownId: 'CallsPref.Status',
                                    noClear: true,
                                    rightAlign: true
                                },
                                'Local number': {
                                    type: 'input',
                                    initialValue: localnumRef,
                                    placeholder: 'Type in your local number',
                                    overBackground: 'floating.background',
                                    rightAlign: true,
                                    isDisabled: disabled,
                                    onChange(e, val) {
                                        handleLocalNumberSelection(val)
                                    }
                                },
                                'Line': {
                                    type: 'select',
                                    textForItem: lineOptions,
                                    overBackground: 'floating.background',
                                    dropdownId: 'CallsPref.Line',
                                    items: AvailableLineOptions,
                                    onSelect: handleLineSelection,
                                    placeholder: 'Select a line',
                                    noClear: true,
                                    selected: lineRef,
                                    isDisabled: disabled,
                                    isHigher: true,
                                    rightAlign: true
                                }
                            }}
                            switchToRowsAt={10000}
                            rowGap="tiny"
                            cellHorizontalTemplate="auto 200px"
                        />
                        <Spacer height={20} />
                        <Flex justify="space-between">
                            {!isOnActiveCallsPage ? (
                                <SimpleButton onClick={onActiveCallsClick}>See active calls</SimpleButton>
                            ) : (
                                <></>
                            )}
                            <SimpleButton background="front.background" onClick={onSavePref}>
                                <ButtonInset>Save</ButtonInset>
                            </SimpleButton>
                        </Flex>
                        <Spacer height={20} />
                        {isLoading && <SlimLoader background="front.background" color="front.accent.color" />}
                        {warningMessage}
                    </FloaterInset>
                </Floater>
            </div>
            <SidebarLinkHolder label="Calls settings" cy="calls-settings">
                <SideBarLink onClick={handleClick}>{icon}</SideBarLink>
            </SidebarLinkHolder>
        </>
    )
}

const CallsIcon = styled.div`
    span {
        position: absolute;
        font-size: 10px;
        font-weight: 600;
        left: 50%;
        transform: translateX(-50%);
    }
    .fadedIcon {
        opacity: 0.5;
    }
`

const WarningText = styled.div`
    max-width: 280px;
    display: flex;
    align-items: center;
`
