export interface AuthUser {
    'https://clearhaus.com/app_metadata': {
        uuid: string
        airtables_key: string
        roles: string[]
        sub: string
    }
    'sub': string
    'given_name': string
    'family_name': string
    'nickname': string
    'name': string
    'picture': string
    'locale': string
    'updated_at': string
    'email': string
    'email_verified': string
}

export interface AuthState {
    inProgress: boolean
    meta: any
    token: string
    user?: AuthUser
}

export const InitialAuthState: AuthState = {
    meta: {},
    token: '',
    inProgress: true,
    user: undefined
}
