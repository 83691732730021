import { RootState } from '@/store'
import { WatcherID, WatcherState } from '../store/watcher/types'

export function watcherSelector(state: RootState, watcher?: WatcherID): WatcherState {
    if (!watcher) return undefined
    return state.watchers[watcher]?.state
}

export function watcherSelectorWithContext(
    state: RootState,
    watcher?: WatcherID
):
    | {
          state: WatcherState
          context?: any
      }
    | undefined {
    if (!watcher) return undefined
    return {
        state: state.watchers[watcher]?.state,
        context: state.watchers[watcher]?.context
    }
}
