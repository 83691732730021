import React from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { useAuth } from './hooks/auth/useAuth'
import Login from './pages/Welcome'
import { useTheme } from './hooks/general/useTheme'
import { LoaderView } from './components/loaders/loader'

interface AuthenticationProps {
    children: React.ReactNode
}

export const Authentication: React.FC<AuthenticationProps> = (props) => {
    const { children } = props

    const { isAuthenticated, isLoading } = useAuth()
    const theme = useTheme()

    if (isLoading) {
        return (
            <ThemeProvider theme={theme as DefaultTheme}>
                <div style={{ height: '100vh', width: '100vw' }}>
                    <LoaderView overBackground="back.background" />
                </div>
            </ThemeProvider>
        )
    }

    if (!isLoading) {
        if (!isAuthenticated)
            return (
                <ThemeProvider theme={theme as DefaultTheme}>
                    <Login />
                </ThemeProvider>
            )
    }

    return <>{children}</>
}
