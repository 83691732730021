import React from 'react'
import styled from 'styled-components'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flag } from '../../../components/icons/flag'
import { SchemesIcon } from '../../../components/icons/schemesIcon'
import { Flex } from '../../../components/layout/flex'
import { Separator } from '../../../components/layout/separator'
import { Spacer } from '../../../components/layout/spacer'

import countries from 'i18n-iso-countries'
import { Result } from '../../../store/applicationInternals/schemesScreening/types'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { CircleCheck } from '../../../components/icons/circleCheck'
import { Offset } from '../../../components/layout/offset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { ApplicationSchemesScreeningPanelMatchBlock } from './Application.SchemesScreeningPanel.MatchBlock'
import moment from 'moment'

export const ApplicationSchemesScreeningPanelResultBlock: React.FC<{
    applicationId: string
    r: Result
    handleSelectClick?: (rId: string) => void
    isSelected?: boolean
}> = ({ r, isSelected, applicationId, handleSelectClick }) => {
    return (
        <React.Fragment key={r.resultId}>
            <Card background="front.background">
                <CardInset type="small">
                    <Flex fullWidth justify="space-between">
                        <Flex column align="stretch">
                            <Flex fullWidth>
                                <Flex noShrink>
                                    <Flag
                                        code={
                                            r.type == 'MATCH'
                                                ? r.company?.country?.length == 3
                                                    ? countries.alpha3ToAlpha2(r.company?.country?.toUpperCase())
                                                    : countries.getAlpha2Code(r.company?.country?.toUpperCase(), 'en')
                                                : r.company?.country?.toUpperCase()
                                        }
                                    />
                                </Flex>
                                <Spacer width={10} />
                                <Flex column fullWidth>
                                    <Flex>
                                        <Text bold>{r.company?.name || 'Unnamed company'}</Text>
                                        <Text color="front.text.subtlerI">
                                            &nbsp;&middot;&nbsp;
                                            {r.company?.registrationId || 'No ID'}
                                        </Text>
                                    </Flex>
                                    <Spacer width={4} />
                                    <Flex>
                                        <Text color="front.text.subtlerI">Score: {r.matchingScore}</Text>
                                        <Spacer width={4} />
                                        <LinkButton
                                            color="front.accent.color"
                                            // eslint-disable-next-line max-len
                                            to={`/merchant/${applicationId}/application/screening-results/${r.resultId}`}
                                        >
                                            See details
                                        </LinkButton>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        {handleSelectClick ? (
                            <Offset top={-5} right={-10}>
                                <Flex column>
                                    <SimpleButton
                                        color={isSelected ? 'front.danger.color' : undefined}
                                        onClick={() => {
                                            handleSelectClick?.(r.resultId)
                                        }}
                                    >
                                        <ButtonInset>
                                            <CircleCheck selectable checked={isSelected} isDanger={isSelected} />
                                            {isSelected ? 'Deselect' : 'Select'}
                                        </ButtonInset>
                                    </SimpleButton>
                                </Flex>
                            </Offset>
                        ) : null}
                    </Flex>
                    <Spacer height={15} />
                    <Separator />
                    <Spacer height={15} />
                    <MatchingBlocksHolder>
                        {r.matchingFields.map((f) => (
                            <ApplicationSchemesScreeningPanelMatchBlock key={r.resultId} f={f} type={r.type} />
                        ))}
                    </MatchingBlocksHolder>
                    <Spacer height={15} />
                    <Separator />
                    <Spacer height={15} />
                    <Flex fullWidth>
                        <SchemeHolder>
                            {r.type == 'MATCH' ? <SchemesIcon scheme="mastercard" /> : null}
                            {r.type == 'VMSS' ? <SchemesIcon scheme="visa" /> : null}
                        </SchemeHolder>
                        <Spacer width={10} />
                        <Flex column shrink>
                            <ResultDescription>
                                <Text color="front.danger.color" bold>
                                    #{r.termination.mainReasonCode} {r.termination.mainReasonDescription}
                                </Text>
                            </ResultDescription>
                            <Text color="front.text.subtlerI">
                                Reported by {r.termination.acquirerName || r.termination.acquirerId} on{' '}
                                {moment(r.termination.madeAt).format('DD MMM, YYYY')}
                            </Text>
                        </Flex>
                    </Flex>
                </CardInset>
            </Card>
            <Spacer height={10} />
        </React.Fragment>
    )
}

const SchemeHolder = styled.div`
    min-width: 40px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    margin-top: 2px;
`

const MatchingBlocksHolder = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -4px;
`

const ResultDescription = styled.span`
    margin-top: -1px;
`
