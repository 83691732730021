import { createActionCreator } from 'deox'
import { Channel } from 'redux-saga'
// eslint-disable-next-line max-len
import { TMerchantApplicationResourceKey } from '../../pages/Merchant/Application/Application.StructureTypes'
import { Application, Comment } from '../applications/types'
import { File } from '../files/types'
import { WatcherID } from '../watcher/types'
import {
    ApplicationResourceCommentWithLink,
    ApplicationSectionItemCommentsLoadingPayload,
    MerchantApplicationResourceIndexPath
} from './types'

// --

const Prefix = 'APPLICATION_RESOURCE'

export const ApplicationResourceActions = {
    MAKE_EDITS: createActionCreator(
        `${Prefix}/MAKE_EDITS`,
        (resolve) => (watcherId: string, id: string, changes: any, messages?: any) =>
            resolve({ watcherId, id, changes, messages })
    ),
    LOAD: createActionCreator(
        `${Prefix}/LOAD`,
        (resolve) => (applicationId: string, sectionKey: TMerchantApplicationResourceKey, url: string) =>
            resolve({ applicationId, sectionKey, url })
    ),
    CACHE_REQUEST: createActionCreator(
        `${Prefix}/CACHE_REQUEST`,
        (resolve) => (applicationId: string, url: string, body: string) => resolve({ applicationId, url, body })
    ),
    CLEANUP: createActionCreator(
        `${Prefix}/CLEANUP`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    FETCH_COMPANY: createActionCreator(
        `${Prefix}/FETCH_COMPANY`,
        (resolve) => (applicationId: string, url: string) => resolve({ applicationId, url })
    ),
    FETCH_PEOPLE: createActionCreator(
        `${Prefix}/FETCH_PEOPLE`,
        (resolve) => (applicationId: string, url: string) => resolve({ applicationId, url })
    ),
    FETCH_PEOPLE_HISTORY: createActionCreator(
        `${Prefix}/FETCH_PEOPLE_HISTORY`,
        (resolve) => (applicationId: string, url: string) => resolve({ applicationId, url })
    ),
    FETCH_CONTACT: createActionCreator(
        `${Prefix}/FETCH_CONTACT`,
        (resolve) => (applicationId: string, url: string) => resolve({ applicationId, url })
    ),
    FETCH_COMMENTS: createActionCreator(
        `${Prefix}/FETCH_COMMENTS`,
        (resolve) =>
            (
                applicationId: string,
                resourceKey: TMerchantApplicationResourceKey,
                subsectionIndex: number,
                commentsLink: string,
                overwriteTagWith?: string,
                sectionCommentsLoadingChannel?: Channel<ApplicationSectionItemCommentsLoadingPayload>
            ) =>
                resolve({
                    sectionCommentsLoadingChannel,
                    applicationId,
                    resourceKey,
                    subsectionIndex,
                    overwriteTagWith,
                    commentsLink
                })
    ),
    STORE_SIGNER: createActionCreator(
        `${Prefix}/STORE_SIGNER`,
        (resolve) => (applicationId: string, signer: any, application: Application) =>
            resolve({ applicationId, signer, application })
    ),
    STORE_BUSINESS_MODEL: createActionCreator(
        `${Prefix}/STORE_BUSINESS_MODEL`,
        (resolve) => (applicationId: string, businessModel: any, application: Application) =>
            resolve({ applicationId, businessModel, application })
    ),
    STORE_ADDITIONAL: createActionCreator(
        `${Prefix}/STORE_ADDITIONAL`,
        (resolve) => (applicationId: string, additionalInfo: any, application: Application) =>
            resolve({ applicationId, additionalInfo, application })
    ),
    FETCH_WEBSITES: createActionCreator(
        `${Prefix}/FETCH_WEBSITES`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    FETCH_MORE_WEBSITES: createActionCreator(
        `${Prefix}/FETCH_MORE_WEBSITES`,
        (resolve) => (applicationId: string, websites: any[], nextLink: string ) => 
            resolve({ applicationId, websites, nextLink })
    ),
    FETCH_WEBSITES_HISTORY: createActionCreator(
        `${Prefix}/FETCH_WEBSITES_HISTORY`,
        (resolve) => (applicationId: string) => resolve({ applicationId})
    ),
    FETCH_BANK_ACCOUNT: createActionCreator(
        `${Prefix}/FETCH_BANK_ACCOUNT`,
        (resolve) => (applicationId: string, url: string) => resolve({ applicationId, url })
    ),
    FETCH_GATEWAY: createActionCreator(
        `${Prefix}/FETCH_GATEWAY`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    STORE_FILE_COMMENTS: createActionCreator(
        `${Prefix}/STORE_FILE_COMMENTS`,
        (resolve) => (applicationId: string, fieldName: string, sectionName: string, comments: Comment[]) =>
            resolve({ applicationId, fieldName, sectionName, comments })
    ),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) =>
            (applicationId: string, resourceKey: TMerchantApplicationResourceKey, 
            selfLink: string, fields: any[], nextLink?: string) =>
                resolve({ applicationId, resourceKey, selfLink, fields, nextLink })
    ),
    BOOTSTRAP: createActionCreator(
        `${Prefix}/BOOTSTRAP`,
        (resolve) => (applicationId: string, resourceName: string) => resolve({ applicationId, resourceName })
    ),
    ALL_LOADED: createActionCreator(
        `${Prefix}/ALL_LOADED`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    LOAD_FILES: createActionCreator(
        `${Prefix}/LOAD_FILES`,
        (resolve) =>
            (
                applicationId: string,
                resourceKey: TMerchantApplicationResourceKey,
                subsectionIndex: number,
                filesLink?: string,
                sectionCommentsLoadingChannel?: Channel<ApplicationSectionItemCommentsLoadingPayload>
            ) =>
                resolve({
                    applicationId,
                    resourceKey,
                    subsectionIndex,
                    filesLink,
                    sectionCommentsLoadingChannel
                })
    ),
    LINK_FILES: createActionCreator(
        `${Prefix}/LINK_FILES`,
        (resolve) =>
            (
                applicationId: string,
                resourceKey: TMerchantApplicationResourceKey,
                subsectionIndex: number,
                files: File[]
            ) =>
                resolve({ applicationId, resourceKey, subsectionIndex, files })
    ),
    UNLINK_FILES: createActionCreator(
        `${Prefix}/UNLINK_FILES`,
        (resolve) =>
            (
                applicationId: string,
                resourceKey: TMerchantApplicationResourceKey,
                subsectionIndex: number,
                files: File[]
            ) =>
                resolve({ applicationId, resourceKey, subsectionIndex, files })
    ),
    SEND_COMMENT: createActionCreator(
        `${Prefix}/SEND_COMMENT`,
        (resolve) =>
            (
                watcherID: WatcherID,
                applicationId: string,
                body: string,
                indexPath: MerchantApplicationResourceIndexPath
            ) =>
                resolve({ watcherID, applicationId, body, indexPath })
    ),
    STORE_COMMENT: createActionCreator(
        `${Prefix}/STORE_COMMENT`,
        (resolve) =>
            (
                indexPath: MerchantApplicationResourceIndexPath,
                applicationId: string,
                comment: Comment,
                withTag?: string
            ) =>
                resolve({ indexPath, applicationId, comment, withTag })
    ),
    STORE_COMMENTS: createActionCreator(
        `${Prefix}/STORE_COMMENTS`,
        (resolve) =>
            (
                applicationId: string,
                sectionKey: TMerchantApplicationResourceKey,
                subsectionIndex: number,
                comments: ApplicationResourceCommentWithLink[]
            ) =>
                resolve({
                    applicationId,
                    sectionKey,
                    subsectionIndex,
                    comments
                })
    ),
    REMOVE_COMMENT: createActionCreator(
        `${Prefix}/REMOVE_COMMENT`,
        (resolve) =>
            (
                commentId: string,
                watcherID: string,
                indexPath: MerchantApplicationResourceIndexPath,
                applicationId: string
            ) =>
                resolve({ commentId, watcherID, indexPath, applicationId })
    ),
    UNSTORE_COMMENT: createActionCreator(
        `${Prefix}/UNSTORE_COMMENT`,
        (resolve) => (commentId: string, indexPath: MerchantApplicationResourceIndexPath, applicationId: string) =>
            resolve({ commentId, indexPath, applicationId })
    ),
    EDIT_ITEMS: createActionCreator(
        `${Prefix}/EDIT_ITEMS`,
        (resolve) =>
            (
                watcherID: string,
                applicationId: string,
                link: string,
                items: {
                    indexPath: MerchantApplicationResourceIndexPath
                    from?: string
                    to: string
                }[]
            ) =>
                resolve({ watcherID, applicationId, link, items })
    ),
    SELECT_GATEWAY: createActionCreator(
        `${Prefix}/SELECT_GATEWAY`,
        (resolve) => (watcherID: string, applicationId: string, value: string) =>
            resolve({ watcherID, applicationId, value })
    ),
    STORE_ITEM: createActionCreator(
        `${Prefix}/STORE_ITEM`,
        (resolve) => (indexPath: MerchantApplicationResourceIndexPath, applicationId: string, value: string) =>
            resolve({ indexPath, applicationId, value })
    ),
    STORE_FIELDS_HISTORY: createActionCreator(
        `${Prefix}/STORE_FIELDS_HISTORY`,
        (resolve) => (applicationId: string, updates: any) => resolve({ applicationId, updates })
    ),
    STORE_FIELD_HISTORY: createActionCreator(
        `${Prefix}/STORE_FIELD_HISTORY`,
        (resolve) =>
            (indexPath: MerchantApplicationResourceIndexPath, applicationId: string, event: any, watcherId?: string) =>
                resolve({ indexPath, applicationId, event, watcherId })
    ),
    ADD_SUBSECTION: createActionCreator(
        `${Prefix}/ADD_SUBSECTION`,
        (resolve) =>
            (
                applicationId: string,
                sectionKey: TMerchantApplicationResourceKey,
                subsectionLabel: string,
                watcherId: WatcherID
            ) =>
                resolve({ applicationId, sectionKey, subsectionLabel, watcherId })
    ),
    REMOVE_SUBSECTION: createActionCreator(
        `${Prefix}/REMOVE_SUBSECTION`,
        (resolve) =>
            (
                applicationId: string,
                indexPath: Omit<MerchantApplicationResourceIndexPath, 'fieldKey'>,
                subsectionLabel: string,
                watcherId: WatcherID
            ) =>
                resolve({ applicationId, indexPath, subsectionLabel, watcherId })
    ),
    STORE_SUBSECTION: createActionCreator(
        `${Prefix}/STORE_SUBSECTION`,
        (resolve) =>
            (
                applicationId: string,
                resourceKey: TMerchantApplicationResourceKey,
                links: {
                    id: string
                    commentsLink: string
                    selfLink: string
                    filesLink: string
                }
            ) =>
                resolve({ applicationId, resourceKey, links })
    ),
    UNSTORE_SUBSECTION: createActionCreator(
        `${Prefix}/UNSTORE_SUBSECTION`,
        (resolve) => (applicationId: string, indexPath: Omit<MerchantApplicationResourceIndexPath, 'fieldKey'>) =>
            resolve({ applicationId, indexPath })
    )
}
