import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Dropdown } from '../../../components/forms/dropdown'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { useOnClickOutside } from '../../../hooks/general/useOnClickOutside'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { LocallyStoredDraftContract, MerchantAccountWithContract } from '../../../store/merchantAccounts/types'
import { ContractEffectivePeriod } from './Accounts.ID.ContractEffectivePeriod'
import { MerchantAccountContractsHistory } from './Accounts.ID.ContractHistory'
import { MerchantAccountsContractStatus } from './Accounts.ID.ContractStatus'
import { useContractHistoryAnalyzer } from './useContractHistoryAnalyzer'
import { useLatestSignedContract } from './useLatestSignedContract'
import { MerchantPageUISelectedContract } from './utils'

export const MerchantAccountVersionSelector: React.FC<{
    account: MerchantAccountWithContract
    draft: LocallyStoredDraftContract
    selectedContract: MerchantPageUISelectedContract
    merchantId: string
    actions?: any
}> = ({ actions, account, merchantId, draft, selectedContract }) => {
    const { effectivePeriods } = useContractHistoryAnalyzer(account?.contractHistory)

    const [holder, setHolder] = useRefTaker()
    const [anchor, setAnchor] = useRefTaker()
    const [show, setShow] = useState(false)
    useOnClickOutside(
        holder,
        useCallback(() => {
            setShow(false)
        }, [])
    )
    const onClick = useCallback(() => {
        setShow((s) => true)
    }, [setShow])

    const latestSignedContractId = useLatestSignedContract(account?.contractHistory)

    const versionIndex = useMemo(() => {
        const contractsHistory = account?.contractHistory
        if (!contractsHistory) return 1
        const keys = Object.keys(contractsHistory)
        if (!keys?.length) return 1
        return (
            keys.length -
            keys.findIndex((id) => {
                return id === selectedContract?.contract?.id
            })
        )
    }, [selectedContract, account])

    const isLatest = account?.contractHistory && versionIndex === Object.keys(account?.contractHistory).length

    const versionNaming = useMemo(() => {
        if (selectedContract?.isDraft) {
            if (selectedContract?.contract?.state === 'draft' || selectedContract?.contract?.state === 'new')
                return (
                    <Text bold color="front.text">
                        Editable contract draft
                    </Text>
                )
            return (
                <Text bold color="front.text">
                    Pending approval draft contract
                </Text>
            )
        }
        return isLatest ? (
            <Text bold={false}>&nbsp;&nbsp;·&nbsp;&nbsp;Latest</Text>
        ) : (
            <Text color={isLatest ? 'front.text' : 'back.text.strongerI'} bold={isLatest}>
                &nbsp;&nbsp;·&nbsp;&nbsp;Version {versionIndex}
            </Text>
        )
    }, [versionIndex, isLatest, selectedContract])

    const effectivePeriod = useMemo(() => {
        if (selectedContract?.isDraft) return null

        return (
            <Text>
                {effectivePeriods?.[selectedContract?.contract?.id] ? (
                    <Flex align="center">
                        <ContractEffectivePeriod
                            from={effectivePeriods[selectedContract?.contract?.id]?.from}
                            to={effectivePeriods[selectedContract?.contract?.id]?.to}
                            color="back.text.strongerI"
                        />
                    </Flex>
                ) : (
                    <Text color="back.text.strongerI">Never been effective</Text>
                )}
            </Text>
        )
    }, [selectedContract, effectivePeriods])

    return (
        <Holder ref={setHolder}>
            <Clickable ref={setAnchor} onClick={onClick}>
                <Container data-cy="version-selector-text">
                    <Text bold>
                        {!selectedContract.isDraft ? (
                            <MerchantAccountsContractStatus contract={selectedContract.contract} />
                        ) : null}
                    </Text>
                    {versionNaming}
                    <Spacer width={10} />
                    {effectivePeriod}
                </Container>
                {actions}
            </Clickable>
            <Dropdown anchor={anchor} show={show}>
                <MerchantAccountContractsHistory
                    selectedContract={selectedContract}
                    draft={draft}
                    baseLink={`/merchant/${merchantId}/accounts/${account?.id}/contracts`}
                    history={account?.contractHistory}
                />
            </Dropdown>
        </Holder>
    )
}

const Holder = styled.div`
    display: contents;
`
const Container = styled.div`
    display: flex;
    align-items: center;
    color: ${(p) => p.theme['front.text']};
`

const Clickable = styled.div`
    background-color: ${(p) => p.theme['back.background.strongerI']};
    display: flex;
    align-items: stretch;
    padding: 10px 25px;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 55px;
    border-radius: 14px;
    box-sizing: border-box;

    &:hover {
        background-color: ${(p) => p.theme['back.background.strongerII']};
        cursor: pointer;
    }

    &:active {
        background-color: ${(p) => p.theme['back.background.strongerIII']};
    }
`

export const PresentBox = styled.div`
    padding: 2px 6px;
    margin: -2px -6px -2px 0px;
    background-color: transparent;
    border: 1px solid ${(p) => p.theme['back.border.strongerI']};
    border-radius: 6px;
`
