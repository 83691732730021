import React, { useCallback, useState, useMemo } from 'react'
import { LinkButton } from '../../components/buttons/linkButton'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { PageHeader } from '../../components/layout/pageHeader'
import { Spacer } from '../../components/layout/spacer'
import { TableBubble } from '../../components/layout/tableBubble'
import { TableFuzzySearch } from '../../components/layout/tableFuzzySearch'
import { Table, TableRow } from '../../components/tables/table'
import { useDialog } from '../../hooks/general/useDialog'
import { useGateways } from '../../hooks/general/useGateways'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { ContractTemplateActions } from '../../store/contractTemplates/actions'
import { LocallyStoredContractTemplate } from '../../store/contractTemplates/types'
import { usePricingSchemes } from '../Merchant/Accounts/usePricingSchemeDropdownConfig'
import { ContractTemplatesNewButton } from './More.ContractTemplates.NewButton'

export const ManageContractTemplates: React.FC = () => {
    const [templates] = usePricingSchemes()
    const gateways = useGateways()
    const [filteredRows, setFilteredRows] = useState<TableRow[]>([])
    const confirmDeletion = useDialog<{ templateId: string }>(
        useCallback(
            (watcherId, { templateId }) => {
                const template = templates.at[templateId]
                return ContractTemplateActions.DELETE(watcherId, template.selfLink)
            },
            [templates]
        ),
        'Confirm deletion',
        'Do you want to delete this contract template?'
    )
    useModalStackSync('Manage contract templates', 'Manage.ContractTemplates', 0)

    const columns = useMemo(() => {
        return [
            {
                text: 'Name',
                loaderSize: {
                    min: 70,
                    max: 260
                }
            },
            {
                text: 'Gateway',
                loaderSize: {
                    min: 10,
                    max: 80
                }
            },
            {
                text: 'Currency',
                loaderSize: {
                    min: 30,
                    max: 30
                }
            },
            {
                text: 'Pricing Principle',
                loaderSize: {
                    min: 10,
                    max: 70
                }
            },
            {
                text: 'Payment Period',
                loaderSize: {
                    min: 40,
                    max: 60
                }
            },
            {
                text: 'Min. Domestic',
                loaderSize: {
                    min: 20,
                    max: 20
                }
            },
            {
                text: 'Min. Intra',
                loaderSize: {
                    min: 20,
                    max: 20
                }
            },
            {
                text: 'Min. Inter',
                loaderSize: {
                    min: 20,
                    max: 20
                }
            },
            {
                text: 'Actions',
                loaderSize: {
                    min: 140,
                    max: 140
                }
            }
        ]
    }, [])

    const getPricingPrinciple = useCallback((template: LocallyStoredContractTemplate) => {
        if (template.charges.interchange == undefined) return undefined
        if (template.charges.interchange == true) return 'Interchange Plus'
        return 'Pricing Plus'
    }, [])

    const rows = useMemo(() => {
        return templates.all.map((id: string) => {
            const template = templates.at[id]
            return {
                type: 'normal' as const,
                key: id,
                noAction: true,
                items: [
                    {
                        node: (
                            <Text bold oneLine>
                                {template.label}
                            </Text>
                        )
                    },
                    {
                        node: template?.gatewayId ? (
                            <Text color="front.text" oneLine>
                                {gateways.at?.[template?.gatewayId]?.name}
                            </Text>
                        ) : (
                            <Text color="front.text.subtlerI">-</Text>
                        )
                    },
                    {
                        node: template?.currency ? (
                            <TableBubble type="important">{template.currency}</TableBubble>
                        ) : (
                            <Text color="front.text.subtlerI">-</Text>
                        )
                    },
                    {
                        node: getPricingPrinciple(template) ? (
                            <TableBubble type="outlined">{getPricingPrinciple(template)}</TableBubble>
                        ) : (
                            <Text color="front.text.subtlerI">-</Text>
                        )
                    },
                    {
                        node: template.paymentPeriod ? (
                            <TableBubble type="outlined">{template.paymentPeriod}</TableBubble>
                        ) : (
                            <Text color="front.text.subtlerI">-</Text>
                        )
                    },
                    {
                        node: template.charges ? (
                            <TableBubble>{template.charges.minimumDomesticTransactionFee}</TableBubble>
                        ) : (
                            <Text color="front.text.subtlerI">-</Text>
                        )
                    },
                    {
                        node: template.charges.minimumIntraTransactionFee ? (
                            <TableBubble>{template.charges.minimumIntraTransactionFee}</TableBubble>
                        ) : (
                            <Text color="front.text.subtlerI">-</Text>
                        )
                    },
                    {
                        node: template.charges.minimumInterTransactionFee ? (
                            <TableBubble>{template.charges.minimumInterTransactionFee}</TableBubble>
                        ) : (
                            <Text color="front.text.subtlerI">-</Text>
                        )
                    },
                    {
                        node: (
                            <Text color="front.text.subtlerI">
                                <Flex>
                                    <LinkButton keepQuery to={`/more/contract-templates/${template.id}`}>
                                        Edit template
                                    </LinkButton>
                                    <Spacer width={20} />
                                    <SimpleButton
                                        onClick={() => {
                                            if (template.id) confirmDeletion({ templateId: template.id })
                                        }}
                                    >
                                        Delete
                                    </SimpleButton>
                                </Flex>
                            </Text>
                        )
                    }
                ]
            }
        })
    }, [templates, getPricingPrinciple, gateways, confirmDeletion])

    const leftSideMemo = useMemo(() => {
        return (
            <TableFuzzySearch
                headers={columns}
                queryParamName="contract-templates-query"
                rows={rows}
                setFilteredRows={setFilteredRows}
                ignoreHeaderWithText="Actions"
            />
        )
    }, [rows, setFilteredRows, columns])

    return (
        <>
            <PageHeader
                noBottomBorder
                title="Contract Templates"
                subtitle=""
                leftSideMemo={leftSideMemo}
                rightSideMemo={<ContractTemplatesNewButton />}
            />
            <Card>
                <CardInset>
                    <Table
                        cols={columns}
                        background="front.background"
                        // eslint-disable-next-line max-len
                        columnLayout="3fr 1fr min-content min-content min-content min-content min-content min-content min-content"
                        showLastRowBorder
                        displayLoader={templates.loadingStatus !== 'done'}
                        loaderStyles={{ minHeight: '90vh' }}
                        loaderRows={40}
                        rows={filteredRows}
                        emptyText="No vouchers found."
                    />
                </CardInset>
            </Card>
        </>
    )
}
