import { useCreateClassification } from '@/services/risk-classification'
import { useClassificationParams } from '../../../hooks/use-classification-params'
import { useAuth } from '@/hooks/auth/useAuth'

export const useCreateActions = () => {
    const { applicationId } = useClassificationParams()
    const { user } = useAuth()

    const [createClassification, classificationResult] = useCreateClassification()

    const isLoading = classificationResult.isLoading

    const create = async () => {
        // create report
        const createClassificationPayload = { applicationId }
        const classification = await createClassification(createClassificationPayload).unwrap()

        return { classification }
    }

    return { isLoading, create }
}
