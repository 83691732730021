import dotProp from 'dot-prop'
import { useCallback, useMemo } from 'react'
import {
    LocalContractFieldPaths,
    localContractFieldTypes,
    LocalContractPathsMappedToMAPIContractPaths
} from '../../../store/contractTemplates/helpers'
import { LocallyStoredContractTemplate } from '../../../store/contractTemplates/types'
import { LocallyStoredDraftContract, MerchantAccountContract } from '../../../store/merchantAccounts/types'
import { numberWithCommas } from '../../../utils'

export const useContractSectionHelper = (
    contract?: MerchantAccountContract,
    draftOrTemplate?: LocallyStoredContractTemplate | LocallyStoredDraftContract
) => {
    const isEditable = useMemo(() => {
        if (contract) return false
        return true
    }, [contract])

    const getValue = useCallback(
        (path: string): any => {
            let value = dotProp.get(draftOrTemplate, path)
            const type = dotProp.get(localContractFieldTypes, path)

            if (!Object.keys(LocalContractFieldPaths).includes(path))
                throw new Error(`Failed to read field. CONTRACT_LOCAL_PATH is wrong (${path})`)

            if (!isEditable) {
                const mapiContractPath = dotProp.get<string | null>(LocalContractPathsMappedToMAPIContractPaths, path)
                if (mapiContractPath) value = dotProp.get(contract, mapiContractPath)
            }

            if (type === 'number') {
                return value ? numberWithCommas(value as string) : '0.00'
            } else if (type === 'uppercased-string') {
                return value ? (value as string).toUpperCase() : ''
            } else if (type === 'unchanged') {
                return value
            } else if (type === 'pricing-plan') {
                if (isEditable) return value === true
                return value === 'interchange_plus'
            } else {
                return (value as any)?.toString()
                // throw `Unhandled contract field type: ${correctPath}`
            }
        },
        [isEditable, contract, draftOrTemplate]
    )

    const currency = useMemo(() => {
        return isEditable ? draftOrTemplate?.currency : contract?.currency
    }, [contract, isEditable, draftOrTemplate])

    return { isEditable, getValue, currency }
}
