import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isFileAPDF, LoadingStatus } from '../../utils'
import { File } from '../../store/files/types'
import { FileActionDispatchRemoveDownloadLink } from '../../store/files/actions'

interface PDFPreview {
    loadingStatus: LoadingStatus
    pdf?: any
}

export const useFetchPDFPreview = (file?: File): PDFPreview => {
    const fetchedFile = useRef(file)
    const [state, setState] = useState<PDFPreview>({
        loadingStatus: 'not-started'
    })

    const dispatch = useDispatch()
    const awaitDownload = useRef<boolean>(true)

    useEffect(() => {
        fetchedFile.current = file
    }, [file])

    useEffect(() => {
        return () => {
            if (fetchedFile.current && isFileAPDF(fetchedFile.current.contentType))
                dispatch(FileActionDispatchRemoveDownloadLink(fetchedFile.current))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        if (file && isFileAPDF(file.contentType)) {
            if (
                file.generatedDownloadLink &&
                file.generatingDownloadLink === 'done' &&
                awaitDownload.current === true
            ) {
                awaitDownload.current = false

                if (!file.generatedDownloadLink) return

                fetch(file.generatedDownloadLink.toString())
                    .then((pdf) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        pdf.blob().then((r: any) => {
                            setState({
                                loadingStatus: 'done',
                                pdf: window.URL.createObjectURL(r)
                            })
                        })

                        dispatch(FileActionDispatchRemoveDownloadLink(file))
                    })
                    .catch(() => {
                        setState({
                            loadingStatus: 'done'
                        })
                        dispatch(FileActionDispatchRemoveDownloadLink(file))
                    })
            }
        }
    }, [dispatch, file])

    return state
}
