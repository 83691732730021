import styled, { css } from 'styled-components'
import { Icon } from '../icons/icon'
import { Flex } from '../layout/flex'
import { Offset } from '../layout/offset'
import { Spacer } from '../layout/spacer'

type PossibleWarns = 'info' | 'warning' | 'subtle'
export const WarnBox: React.FC<{
    children: any
    withMargin?: boolean
    type?: PossibleWarns
    noIcon?: boolean
    className?: string
    withTopMargin?: boolean
}> = ({ children, noIcon, withMargin, withTopMargin, type = 'info' }) => {
    return (
        <Box withMargin={withMargin} withTopMargin={withTopMargin} type={type}>
            <Flex align="center">
                {noIcon ? null : (
                    <>
                        <Offset top={-0.1}>
                            <Icon type="info" />
                        </Offset>
                        <Spacer width={10} />
                    </>
                )}
                <Flex grow>{children}</Flex>
            </Flex>
        </Box>
    )
}

const Box = styled.div<{ withMargin?: boolean; withTopMargin?: boolean; type: PossibleWarns }>`
    padding: 10px 10px;
    border-radius: 10px;
    ${(p) =>
        p.type == 'info' &&
        css`
            background-color: ${(p) => p.theme['front.subtleAccent.background']};
            border: 1px solid ${(p) => p.theme['front.subtleAccent.border']};
        `}
    ${(p) =>
        p.type == 'warning' &&
        css`
            background-color: ${(p) => p.theme['front.subtleInfo.background']};
            border: 1px solid ${(p) => p.theme['front.subtleInfo.border']};
        `}
    ${(p) =>
        p.withTopMargin &&
        css`
            margin-top: 15px;
        `}
    ${(p) =>
        p.type == 'subtle' &&
        css`
            background-color: ${(p) => p.theme['front.background']};
            border: 1px solid ${(p) => p.theme['front.border']};
        `}
    ${(p) =>
        p.withMargin &&
        css`
            margin: 30px;
            margin-top: 0;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 30px;
            }
        `}
`
