import dotProp from 'dot-prop'
import { produce } from 'immer'

import { returnUnique, returnUniqueIdsArray, returnWithoutIdsArray } from '../../utils'
import {
    APPLICATIONS_CLEANUP,
    APPLICATIONS_LINK_FILES,
    APPLICATIONS_LOAD_APPLICATION,
    APPLICATIONS_LOAD_BVD_INFO,
    APPLICATIONS_LOAD_CVR_INFO,
    APPLICATIONS_LOAD_ENTRIES,
    APPLICATIONS_SAVE_LAST_APPLICATION_ROUTE,
    APPLICATIONS_STORE_APPLICATION,
    APPLICATIONS_STORE_BVD_INFO,
    APPLICATIONS_STORE_CUTTER_COMMENTS_INFO,
    APPLICATIONS_STORE_CVR_INFO,
    APPLICATIONS_STORE_DRAFT_AGREEMENT,
    APPLICATIONS_STORE_ENTITY,
    APPLICATIONS_STORE_ENTRIES,
    APPLICATIONS_STORE_INTERNAL_COMMENT,
    APPLICATIONS_STORE_INTERNAL_FILES,
    APPLICATIONS_STORE_INTERNAL_INTERACTIONS,
    APPLICATIONS_STORE_NEW_STATUS,
    APPLICATIONS_STORE_READ_COMMENT,
    APPLICATIONS_UNLINK_FILES,
    APPLICATIONS_UNSTORE_INTERNAL_COMMENT,
    APPLICATIONS_UNSTORE_INTERNAL_FILES,
    APPLICATIONS_UPDATE_CUTTER_COMMENTS_INFO,
    ReducedApplicationsActions
} from './actions'
import { ApplicationsState, InitialApplicationsState } from './types'

export function ApplicationsReducers(
    state = InitialApplicationsState,
    action: ReducedApplicationsActions
): ApplicationsState {
    switch (action.type) {
        case APPLICATIONS_LOAD_ENTRIES: {
            return {
                ...state,
                table: {
                    ...state.table,
                    loadingStatus: 'started'
                }
            }
        }
        case APPLICATIONS_STORE_ENTRIES: {
            return {
                ...state,
                table: {
                    ...action.summaries,
                    loadingStatus: 'done'
                }
            }
        }
        case APPLICATIONS_STORE_READ_COMMENT: {
            return produce(state, (draft) => {
                return dotProp.set(
                    draft,
                    `cutterCommentsInfo.forApplication.${action.applicationId}.${action.commentId}.readAt`,
                    new Date()
                )
            })
        }
        case APPLICATIONS_LOAD_APPLICATION: {
            return {
                ...state,
                applications: {
                    ...state.applications,
                    at: {
                        ...state.applications.at,
                        [action.id]: {
                            ...state.applications.at[action.id],
                            businessModel: {
                                loadingStatus: 'started'
                            },
                            loadingStatus: 'started'
                        }
                    },
                    all: returnUnique(state.applications.all, action.id)
                }
            }
        }
        case APPLICATIONS_STORE_NEW_STATUS: {
            return {
                ...state,
                applications: {
                    ...state.applications,
                    at: {
                        ...state.applications.at,
                        [action.applicationId]: {
                            ...(state.applications?.at?.[action.applicationId] || {}),
                            metadata: {
                                ...(state.applications?.at?.[action.applicationId]?.metadata || {}),
                                state: action.status
                            }
                        }
                    }
                }
            }
        }
        case APPLICATIONS_STORE_INTERNAL_INTERACTIONS: {
            const interactions: any = {
                comments: [],
                files: [],
                commentsLink: action.application?.commentsLink,
                filesLink: action.application?.applicationFilesLink,
                loadingStatus: 'done'
            }

            // eslint-disable-next-line no-unused-expressions
            action.application?.comments?.forEach((c: any) => {
                if (c.private) interactions.comments.push(c)
            })
            // eslint-disable-next-line no-unused-expressions
            action.application?.applicationFiles?.forEach((f: any) => {
                if (f.scope === 'private') interactions.files.push(f)
            })

            return produce(state, (draft) => {
                // if (!action.application?.id) return draft
                dotProp.set(draft, `applications.at.${action.application.id}.internalInteractions`, interactions)
                // if (draft.applications) {
                //     draft.applications = {
                //         at: {},
                //         all: []
                //     }
                // }
                // if (!state.applications.at?.[action.application?.id])
                //     draft.applications.at[action.application.id] = {} as any
                // draft.applications.at[
                //     action.application.id
                // ].internalInteractions = interactions
            })
        }
        case APPLICATIONS_STORE_INTERNAL_COMMENT: {
            return produce(state, (draft) => {
                if (draft.applications.at[action.applicationId]?.internalInteractions?.comments) {
                    if (
                        draft.applications.at[action.applicationId].internalInteractions.comments.find(
                            (c) => c.id === action.comment.id
                        )
                    )
                        return draft
                    draft.applications.at[action.applicationId].internalInteractions.comments.push(action.comment)
                }
            })
        }
        case APPLICATIONS_STORE_INTERNAL_FILES: {
            return produce(state, (draft) => {
                if (draft.applications.at[action.applicationId]?.internalInteractions?.files) {
                    draft.applications.at[action.applicationId].internalInteractions.files = [
                        ...draft.applications.at[action.applicationId].internalInteractions.files,
                        ...action.files
                    ]
                }
            })
        }
        case APPLICATIONS_UNSTORE_INTERNAL_FILES: {
            return produce(state, (draft) => {
                const filesToUnstoreIds = action.files.map((f: any) => f.id)
                if (draft.applications.at[action.applicationId]?.internalInteractions?.files) {
                    dotProp.set(
                        draft.applications,
                        `at.${action.applicationId}.internalInteractions.files`,
                        draft.applications.at[action.applicationId].internalInteractions.files.filter(
                            (f: any) => !filesToUnstoreIds.includes(f.id)
                        )
                    )
                }
            })
        }
        case APPLICATIONS_UNSTORE_INTERNAL_COMMENT: {
            return produce(state, (draft) => {
                if (draft.applications.at[action.applicationId]?.internalInteractions?.comments) {
                    dotProp.set(
                        draft.applications,
                        `at.${action.applicationId}.internalInteractions.comments`,
                        draft.applications.at[action.applicationId]?.internalInteractions?.comments.filter(
                            (c: any) => c.id !== action.comment.id
                        )
                    )
                }
            })
        }
        case APPLICATIONS_STORE_APPLICATION: {
            if (!action?.application?.id) return state
            const newState = {
                ...state,
                applications: {
                    ...state.applications,
                    at: {
                        ...state.applications.at,
                        [action.application.id]: {
                            ...state.applications.at[action.application.id],
                            ...action.application,
                            businessModel: {
                                ...state.applications.at[action.application.id]?.businessModel,
                                ...action.application.businessModel,
                                loadingStatus: 'done'
                            },
                            loadingStatus: 'done'
                        }
                    },
                    all: returnUnique(state.applications.all, action.application.id)
                }
            }

            // Take the stamps out, as we handle them in the ApplicationInternals store module
            // mostly because we have to refetch them every now and then
            if ((newState.applications.at[action.application.id] as any).stampsLink)
                delete (newState.applications.at[action.application.id] as any).stampsLink

            return newState as any
        }
        case APPLICATIONS_UPDATE_CUTTER_COMMENTS_INFO: {
            return produce(state, (draft) => {
                if (draft.cutterCommentsInfo?.forApplication?.[action.applicationId]?.loadingStatus)
                    (draft.cutterCommentsInfo?.forApplication?.[action.applicationId] as any).loadingStatus = 'started'
                if (draft.applications.at?.[action.applicationId])
                    draft.applications.at[action.applicationId].cutterInfoLoadingStatus = 'started'
            })
        }
        case APPLICATIONS_STORE_CUTTER_COMMENTS_INFO: {
            return produce(state, (draft) => {
                if (!draft.cutterCommentsInfo)
                    draft.cutterCommentsInfo = {
                        forApplication: {},
                        loadingStatus: 'not-started'
                    }
                draft.cutterCommentsInfo.forApplication[action.applicationId] = {
                    ...action.cutterCommentsInfo,
                    loadingStatus: 'done'
                }
                if (draft.applications.at?.[action.applicationId])
                    draft.applications.at[action.applicationId].cutterInfoLoadingStatus = 'done'
            })
        }
        case APPLICATIONS_STORE_ENTITY: {
            const newState = JSON.parse(JSON.stringify(state))
            const path = action.path.replace('applications.', '')
            const previousEntity = dotProp.get<any>(newState, path)

            if (Array.isArray(previousEntity)) dotProp.set(newState, path, [...action.entity])
            else
                dotProp.set(newState, path, {
                    ...(previousEntity || {}),
                    ...action.entity
                })

            return newState
        }
        case APPLICATIONS_LOAD_CVR_INFO: {
            return {
                ...state,
                applications: {
                    ...state.applications,
                    at: {
                        ...state.applications.at,
                        [action.applicationId]: {
                            ...state.applications.at[action.applicationId],
                            cvrInfo: {
                                data: undefined,
                                loadingStatus: 'started'
                            }
                        }
                    }
                }
            }
        }
        case APPLICATIONS_STORE_CVR_INFO: {
            return {
                ...state,
                applications: {
                    ...state.applications,
                    at: {
                        ...state.applications.at,
                        [action.applicationId]: {
                            ...state.applications.at[action.applicationId],
                            cvrInfo: {
                                data: { ...action.data },
                                loadingStatus: 'done'
                            }
                        }
                    }
                }
            }
        }
        case APPLICATIONS_LOAD_BVD_INFO: {
            return {
                ...state,
                applications: {
                    ...state.applications,
                    at: {
                        ...state.applications.at,
                        [action.applicationId]: {
                            ...state.applications.at[action.applicationId],
                            bvdInfo: {
                                details: {
                                    data: undefined,
                                    loadingStatus: 'started'
                                },
                                ownership: {
                                    data: undefined,
                                    loadingStatus: 'started'
                                }
                            }
                        }
                    }
                }
            }
        }
        case APPLICATIONS_STORE_BVD_INFO: {
            return {
                ...state,
                applications: {
                    ...state.applications,
                    at: {
                        ...state.applications.at,
                        [action.applicationId]: {
                            ...state.applications.at[action.applicationId],
                            bvdInfo: {
                                ...state.applications.at[action.applicationId].bvdInfo,
                                [action.infoType]: {
                                    data: action.infoType === 'ownership' ? action.data : { ...action.data },
                                    loadingStatus: 'done'
                                }
                            }
                        }
                    }
                }
            }
        }
        case APPLICATIONS_CLEANUP: {
            return produce(state, (draft) => {
                if (draft?.cutterCommentsInfo?.forApplication?.[action.applicationId])
                    delete draft.cutterCommentsInfo.forApplication?.[action.applicationId]
                if (draft?.applications?.at?.[action.applicationId]) delete draft.applications.at[action.applicationId]
                draft.applications.all = draft.applications.all.filter((id) => id !== action.applicationId)
            })
        }
        case APPLICATIONS_STORE_DRAFT_AGREEMENT: {
            const newState = JSON.parse(JSON.stringify(state))
            const path = action.agreementPath.replace('.applications.', '.')
            dotProp.set(newState, path, {
                ...(dotProp.get<any>(state, path) || {}),
                // TO-DO May need request cleanup
                ...action.payload
            })
            return newState
        }
        case APPLICATIONS_SAVE_LAST_APPLICATION_ROUTE: {
            return {
                ...state,
                lastRoute: action.route
            }
        }
        case APPLICATIONS_UNLINK_FILES:
        case APPLICATIONS_LINK_FILES: {
            const application = dotProp.get<any>(state, `applications.at.${action.applicationId}.application`)

            if (action.type == APPLICATIONS_LINK_FILES) {
                dotProp.set(
                    application,
                    action.path,
                    returnUniqueIdsArray(dotProp.get<any>(application, action.path), action.files)
                )
            } else {
                dotProp.set(
                    application,
                    action.path,
                    returnWithoutIdsArray(dotProp.get<any>(application, action.path), action.files)
                )
            }

            return {
                ...state,
                applications: {
                    ...state.applications,
                    at: {
                        ...state.applications.at,
                        [action.applicationId]: {
                            ...state.applications.at[action.applicationId],
                            ...application
                        }
                    }
                }
            }
        }
        default:
            return state
    }
}
