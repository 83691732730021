import { ReactNode, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useHotkey } from '../../../hooks/hotkeys/useHotkey'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { MODAL_ID } from '../../modals/modalIds'
import { SmartNavLink } from '../../tables/smartNavLink'
import { useNavigate } from 'react-router-dom'

export const SideBarLink: React.FC<{
    query?: string
    onClick?: () => void
    to?: string
    hotkey?: string
    hotkeysScope?: MODAL_ID
    children?: ReactNode
}> = ({ query, onClick, children, to, hotkey, hotkeysScope }) => {
    const navigate = useNavigate()
    const [_, setRef] = useRefTaker()

    const linkTo = useMemo(() => {
        if (query) return { pathname: to, search: query }
        return { pathname: to }
    }, [to, query])

    const action = useCallback(() => {
        if (onClick !== undefined) {
            onClick()
        } else {
            navigate(linkTo || '/')
        }
    }, [navigate, onClick, linkTo])

    const navLinkClickHandler = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault()
            action()
        },
        [action]
    )

    const clue = useHotkey({
        keys: hotkey,
        action,
        clue: 'bottom',
        scope: hotkeysScope
    })

    return (
        <Holder>
            <Link onClick={navLinkClickHandler} to={linkTo} tabIndex={-1} ref={setRef}>
                {children}
            </Link>
            {clue}
        </Holder>
    )
}
const Holder = styled.div`
    position: relative;
`

const Link = styled(SmartNavLink)`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 50px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 1;
    color: ${(p) => p.theme['side.text.subtlerI']};

    &:hover {
        color: ${(p) => p.theme['side.text.subtlerII']};
    }
    &:active {
        background-color: ${(p) => p.theme['side.background.strongerII']};
        color: ${(p) => p.theme['side.text.subtlerI']};
    }
    &.active {
        background-color: ${(p) => p.theme['side.background.strongerI']};
        color: ${(p) => p.theme['side.text']};
    }
`
