import { Tooltip, Link } from '@/design-system'
import _ from 'lodash'
import React from 'react'

interface HoverCardFingerprintProps {
    fingerprint: string
}

export const HoverCardFingerprint: React.FC<HoverCardFingerprintProps> = (props) => {
    const { fingerprint } = props

    const truncatedFingerprint = _.truncate(fingerprint, { length: 16, omission: '...' })

    return (
        <Tooltip delayDuration={0}>
            <Tooltip.Trigger>
                <Link asChild>
                    <span>{truncatedFingerprint}</span>
                </Link>
            </Tooltip.Trigger>
            <Tooltip.Content>{fingerprint}</Tooltip.Content>
        </Tooltip>
    )
}
