import { Company } from '@/store/applications/types'
import { getCompanyRegister } from '@/utils/companyRegister'

export const getCompanyReg = (company?: Company) => {
    if (!company || !company.country || !company.registrationNumber) return null

    const companyRegInfo = getCompanyRegister(company.country, company.registrationNumber)

    if (!companyRegInfo.name || !companyRegInfo.url) return null

    return companyRegInfo
}
