// import { OverlayTrigger, Tooltip } from "react-bootstrap"
import React, { useRef } from 'react'
import styled from 'styled-components'

import { Tooltip } from './tooltip'

export const IDShortener: React.FC<{
    id: string
    length?: number
    isReference?: boolean
    noTooltip?: boolean
}> = ({ id, isReference, noTooltip }) => {
    const spanRef = useRef<any>()

    if (!id) return null
    return (
        <DisplayThrough ref={spanRef}>
            {isReference ? '' : '#'}
            {shortenId(id, isReference)}
            {noTooltip ? null : (
                <Tooltip element={spanRef} tooltip tooltipTiming="instant">
                    {isReference ? `${id}` : `#${id}`}
                </Tooltip>
            )}
        </DisplayThrough>
    )
}

export const shortenId = (id: string, isReference = false, length = 8): string => {
    if (!id) return '-'
    if (id.length > 8) {
        if (isReference) return id.substr(id.length - length, length)
    }
    return id.substr(0, length)
}

const DisplayThrough = styled.div`
    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
`
