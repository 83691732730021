import React from 'react'
import { Section } from '../../../common/section'
import { googleSearch } from '@/utils/googleSearch'
import { ToggleContent } from '../toggle-content/toggle-content'
import { SectionCheck } from '@/services/ongoing-due-diligence'
import { useReportMerchantData, useReportSection } from '../../../provider/report-provider/report-provider'

export const GoogleNegativeInfoOwners: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')
    const merchantData = useReportMerchantData()

    const data = checks.google_negative_info_owners
    const commentData = checks.comment_google_negative_info_owners

    const hasPeople = merchantData.people && merchantData.people.length > 0

    return (
        <Section>
            <Section.Content>
                <ToggleContent
                    dataName="google_negative_info_owners"
                    data={data}
                    commentName="comment_google_negative_info_owners"
                    commentData={commentData}
                    visibleValue="Yes"
                />
            </Section.Content>
            <Section.Metadata>
                <Section.Title>{hasPeople ? 'People:' : 'No people found'}</Section.Title>
                {hasPeople && <Section.Spacer size="s" />}
                {hasPeople &&
                    merchantData.people.map((person, i) => {
                        const last = i === merchantData.people.length - 1

                        const googleLink = person.name && googleSearch(person.name)

                        if (!person.name || !googleLink) return null

                        return (
                            <React.Fragment key={person.id}>
                                <Section.ExternalLink
                                    url={googleLink}
                                >{`Google (${person.name})`}</Section.ExternalLink>
                                {!last && <Section.Spacer size="s" />}
                            </React.Fragment>
                        )
                    })}
            </Section.Metadata>
        </Section>
    )
}
