import styled from 'styled-components'

export const MerchantApplicationSectionGrid: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {
    return <FieldsList>{children}</FieldsList>
}

const FieldsList = styled.div`
    display: grid;
    grid-template-columns: 120px 1fr min-content;
    grid-row-gap: 0px;
`
