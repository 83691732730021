import { getFileByLabel } from '../../../../../helpers'
import { Files } from '@/services/merchant-api'
import React from 'react'
import { FileLink } from '../../../common/file-link'

interface ManagementLinksProps {
    files: Files
}

export const ManagementLinks: React.FC<ManagementLinksProps> = (props) => {
    const { files } = props

    const whoisFile = getFileByLabel('world-compliance', files)

    return <FileLink file={whoisFile}>World compliance</FileLink>
}
