import { createReducer } from 'deox'
import dotProp from 'dot-prop'
/* eslint-disable no-param-reassign */
import { produce } from 'immer'

import { Collaborator, InitialApplicationInternalsCollaboratorsState } from './types'
import { ApplicationInternalsCollaboratorsActions } from './actions'
import { ApplicationInternalsActions } from '../actions'

export const ApplicationInternalsCollaboratorsReducer = createReducer(
    InitialApplicationInternalsCollaboratorsState,
    (handleAction) => [
        handleAction(ApplicationInternalsCollaboratorsActions.STORE, (state, { payload: p }) => {
            return produce(state, (draft) => {
                const collabs: any[] = dotProp.get(draft, `forApplication.${p.applicationId}.collaborators`) || []
                dotProp.set(draft, `forApplication.${p.applicationId}`, {
                    collaborators: p.keepsExisting ? [...collabs, ...p.collaborators] : p.collaborators,
                    loadingStatus: 'done'
                })
            })
        }),
        handleAction(ApplicationInternalsCollaboratorsActions.UNSTORE, (state, { payload: p }) => {
            return produce(state, (draft) => {
                const collaborators: Collaborator[] | undefined =
                    dotProp.get(draft, `forApplication.${p.applicationId}.collaborators`) || []
                dotProp.set(
                    draft,
                    `forApplication.${p.applicationId}.collaborators`,
                    (collaborators || []).filter((c) => c.email !== p.email)
                )
            })
        }),
        handleAction(ApplicationInternalsCollaboratorsActions.LOAD, (state, { payload: p }) => {
            return produce(state, (draft: any) => {
                dotProp.set(draft, `forApplication.${p.applicationId}`, {
                    loadingStatus: 'started'
                })
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = { ...InitialApplicationInternalsCollaboratorsState }
                return draft
            })
        })
    ]
)
