import { WatcherID } from '../watcher/types'
import { Gateway } from './types'

export const GATEWAYS_LOAD = 'GATEWAYS_LOAD'
export const GATEWAYS_STORE = 'GATEWAYS_STORE'
export const GATEWAYS_STORE_NEW = 'GATEWAYS_STORE_NEW'
export const GATEWAYS_CREATE = 'GATEWAYS_CREATE'
export const GATEWAYS_EDIT = 'GATEWAYS_EDIT'

//-

export interface GatewaysActionLoad {
    type: typeof GATEWAYS_LOAD
}
export interface GatewaysActionStore {
    type: typeof GATEWAYS_STORE
    gateways: Gateway[]
}
export interface GatewaysActionStoreNew {
    type: typeof GATEWAYS_STORE_NEW
    gateway: Gateway
}
export interface GatewaysActionCreate {
    type: typeof GATEWAYS_CREATE
    watcher: WatcherID
    gateway?: Omit<Gateway, 'id' | 'selfLink'>
}
export interface GatewaysActionEdit {
    type: typeof GATEWAYS_EDIT
    watcher: WatcherID
    link: string
    gateway: Omit<Gateway, 'id' | 'selfLink'>
}

//-

export const GatewaysDispatchLoad = (): GatewaysActionLoad => {
    return { type: GATEWAYS_LOAD }
}

export const GatewaysDispatchStore = (gateways: Gateway[]): GatewaysActionStore => {
    return { type: GATEWAYS_STORE, gateways }
}

export const GatewaysDispatchStoreNew = (gateway: Gateway): GatewaysActionStoreNew => {
    return { type: GATEWAYS_STORE_NEW, gateway }
}

export const GatewaysDispatchEdit = (
    watcher: WatcherID,
    link: string,
    gateway: Omit<Gateway, 'selfLink' | 'id'>
): GatewaysActionEdit => {
    return { type: GATEWAYS_EDIT, watcher, link, gateway }
}

export const GatewaysDispatchCreate = (
    watcher: WatcherID,
    gateway: Omit<Gateway, 'id' | 'selfLink'>
): GatewaysActionCreate => {
    return { type: GATEWAYS_CREATE, watcher, gateway }
}
export interface SagasForGateways {
    loadGateways(action: GatewaysActionLoad): void
    createGateway(action: GatewaysActionCreate): void
    editGateway(action: GatewaysActionEdit): void
}

export type ReducedGatewaysActions = GatewaysActionStore | GatewaysActionLoad | GatewaysActionStoreNew
