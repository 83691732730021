export const FindKeyEntriesInObject = (obj: any, key: string, path?: string): { value: string; path: string }[] => {
    let entries: { value: string; path: string }[] = []
    Object.keys(obj).forEach((k: string) => {
        if (k === key) {
            entries.push({
                value: obj[k],
                path: path || ''
            })
        } else if (typeof obj[k] === 'object') {
            entries = [...entries, ...FindKeyEntriesInObject(obj[k], key, (path ? `${path}.` : '') + k)]
        }
    })
    return entries
}

export function findKeyValueInObject(entireObj, keyToFind, valToFind) {
    let foundObj
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind && !foundObj) {
            foundObj = nestedValue
        }
        return nestedValue
    })
    return foundObj
}
