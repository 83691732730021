import { useEffect, useRef, useState } from 'react'

export const useOnHoverOutside = (element: any, cb: (e?: any) => void, precondition?: boolean) => {
    const cbTimeout = useRef<null | any>()
    const focusListener = useRef<null | any>()
    const [isHovered, setIsHovered] = useState(false)

    // make element focusable so we can get focus when blur takes place
    useEffect(() => {
        if (element && element.tabIndex !== -1) element.tabIndex = -1
    }, [element])

    useEffect(() => {
        if (!element) return

        if (!element.addEventListener) throw new Error('Failed to bind mouse events to the given component.')

        const mouseOut = () => {
            if (!cbTimeout.current)
                cbTimeout.current = setTimeout(() => {
                    // prevent closing the modal if something has focus
                    if (element.contains(document.activeElement)) {
                        if (
                            document.activeElement?.nodeName.toLowerCase().includes('input') ||
                            document.activeElement?.nodeName.toLowerCase().includes('textarea')
                        ) {
                            // add a blur event to hide the modal once it loses focus
                            focusListener.current = (e: any) => {
                                // if focus is going back to element, don't hide
                                if (e.relatedTarget?.contains(element)) return
                                if (e.relatedTarget === element) return
                                setIsHovered(false)
                                cb?.(e)
                            }
                            document.activeElement.addEventListener('blur', focusListener.current, { once: true })
                            return
                        }
                    }
                    setIsHovered(false)
                    cb?.()
                }, 500)
        }

        const clickedOut = (event: any) => {
            if (!element.contains(event.target)) {
                setIsHovered(false)
                cb?.()
            }
        }

        const mouseIn = () => {
            setIsHovered(true)
            clearTimeout(cbTimeout.current)
            cbTimeout.current = null
            if (focusListener.current) {
                document.activeElement?.removeEventListener('blur', focusListener.current)
            }
        }

        element.addEventListener('mouseenter', mouseIn)
        element.addEventListener('mouseleave', mouseOut)
        document.getElementsByTagName('body')[0].addEventListener('click', clickedOut)
        return () => {
            element.removeEventListener('mouseenter', mouseIn)
            element.removeEventListener('mouseleave', mouseOut)
            document.getElementsByTagName('body')[0].removeEventListener('click', clickedOut)
        }
    }, [cb, element])

    return {
        isHovered: precondition !== undefined ? precondition && isHovered : isHovered
    }
}
