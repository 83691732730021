import React from 'react'
import { styles } from './styles'

interface LoaderProps {
    children: React.ReactNode
    isLoading: boolean
}

export const Loader: React.FC<LoaderProps> = (props) => {
    const { children, isLoading } = props

    const loading = isLoading ? 'yes' : 'no'

    const classnames = styles({ loading })

    return <div className={classnames}>{children}</div>
}
