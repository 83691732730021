import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export const useNotificationsFetching = (forApplicationId: string, forHooks?: boolean) => {
    const [filter, setFilter] = useState<string | undefined>(undefined)
    const notifications = useSelector(
        (state: RootState) => state.applicationInternals.record[forApplicationId]?.notifications
    )
    const filteredNotifications = useMemo(() => {
        let newN = [...(notifications?.all || [])]
        newN.sort((a: string) => {
            if (notifications.at[a].events.find((n) => n.toLowerCase().includes('disputes'))) return -1
            return 1
        })
        newN.sort((a: string, b: string) => {
            return notifications.at[a].endpoint > notifications.at[b].endpoint ? 1 : -1
        })
        newN.sort((a: string) => {
            return notifications.at[a].enabled ? -1 : 1
        })

        if (filter)
            newN = newN.filter((n) => {
                if (notifications.at[n].endpoint.toLowerCase().includes(filter.toLowerCase())) return true
                if (notifications.at[n].events.find((e) => e.toLowerCase().includes(filter.toLowerCase()))) return true
                return false
            })

        newN = newN.filter((n) => {
            switch (notifications.at[n].protocol) {
                case 'email':
                    if (forHooks) return false
                    return true
                case 'https':
                    if (forHooks) return true
                    return false
            }
        })

        return {
            ...notifications,
            all: newN
        }
    }, [notifications, filter, forHooks])

    const emptyTableText = useMemo(() => {
        const kind = forHooks ? 'webhooks' : 'e-mail notifications'
        return filter ? `No ${kind} matching the filter` : `No ${kind} exist for this account.`
    }, [forHooks, filter])

    return { filter: setFilter, notifications: filteredNotifications, emptyTableText }
}
