import { cn } from '../utils'
import { styles as buttonStyles } from '../button/styles'

const navButtonClasses = cn(buttonStyles.button({ variant: 'solid', size: 'sm' }))

export const styles = {
    months: 'months',
    month: 'month',
    monthCaption: 'pl-11 pr-11 py-1 text-center',
    captionLabel: 'captionLabel',
    nav: 'flex items-center',
    buttonPrevious: `${navButtonClasses} absolute left-8 top-7`,
    buttonNext: `${navButtonClasses} absolute right-0 top-7`,
    monthGrid: 'mt-4 w-full border-collapse',
    weekdays: 'flex',
    weekday: 'm-0.5 grow basis-0 text-center',
    week: 'flex',
    day: 'h-9 w-9 grow basis-0 text-center p-0 m-0.5',
    selected:
        // eslint-disable-next-line max-len
        '!bg-primary text-white rounded-xl hover:bg-primary-100 hover:text-primary-950 focus:bg-primary focus:text-white',
    dayButton: `h-full w-full bg-transparent rounded-xl hover:bg-neutral-200 dark:text-neutral-200 dark:bg-neutral-200/5 dark:hover:bg-neutral-200/10`,
    today: 'text-primary',
    outside: '',
    disabled: '',
    hidden: 'invisible'
}
