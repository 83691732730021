import { useParams } from 'react-router-dom'

type Params = {
    id: string
    report_id: string
}

export const useReportParams = () => {
    const { report_id: reportId, id: applicationId } = useParams() as Params

    return { reportId, applicationId }
}
