/* eslint-disable max-len */
import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../components/buttons/buttonInset'
import { SimpleButton } from '../components/buttons/simpleButton'
import { WatcherButton } from '../components/buttons/watcherButton'
import { Text } from '../components/general/text'
import { Flex } from '../components/layout/flex'
import { Spacer } from '../components/layout/spacer'
import { useDialog } from '../hooks/general/useDialog'
import { RootState } from '@/store'
import { SettlementsReservesActions } from '../store/settlementsReserves/actions'
import { WatcherDispatchSuccess } from '../store/watcher/actions'
import { numberWithCommas, numberWithCurrency } from '../utils'
import { CriticalFlowSettlementsSmartSelect } from './CriticalFlowSettlementsSmartSelect'

export const CriticalFlowSettlementsStatusArea: React.FC<{ mid: string }> = ({ mid }) => {
    const items = useSelector((state: RootState) => state.settlementsReserves.forMerchantId?.[mid])
    const dispatch = useDispatch()
    const selection = items?.selection
    const selectionIds = useMemo(() => (selection ? Object.keys(selection) : []), [selection])

    const currency = items?.reserves?.at?.[items.reserves?.all?.[0]]?.currency

    const amount = useMemo(() => {
        return selectionIds.reduce((acc, selectionId) => {
            acc += items.reserves?.at?.[selectionId]?.amount || 0
            return acc
        }, 0)
    }, [items, selectionIds])

    const selectedCount = useMemo(() => {
        let payouts = 0
        let reserves = 0
        selectionIds.forEach(
            (sId) => {
                if (items.reserves.at[sId]?.type === 'payout') payouts++
                if (items.reserves.at[sId]?.type === 'reserve') reserves++
            },
            [items]
        )
        return { payouts, reserves }
    }, [items, selectionIds])

    const selectedSums = useMemo(() => {
        let payouts = 0
        let reserves = 0
        selectionIds.forEach(
            (sId) => {
                if (items.reserves.at[sId]?.type === 'payout') payouts += items.reserves.at[sId]?.amount
                if (items.reserves.at[sId]?.type === 'reserve') reserves += items.reserves.at[sId]?.amount
            },
            [items]
        )
        return { payouts, reserves }
    }, [items, selectionIds])

    const selectionText = useMemo(() => {
        const payoutsSuffix = selectedCount.payouts === 1 ? 'payout' : 'payouts'
        const reservesSuffix = selectedCount.reserves === 1 ? 'reserve' : 'reserves'

        if (!selectedCount.payouts) return `${selectedCount.reserves} ${reservesSuffix}`
        if (!selectedCount.reserves) return `${selectedCount.payouts} ${payoutsSuffix}`

        return `${selectedCount.payouts} ${payoutsSuffix} and ${selectedCount.reserves} ${reservesSuffix}`
    }, [selectedCount])

    const totalSum = useMemo(() => {
        return items?.reserves?.all?.reduce(
            (acc, id) => {
                acc.counts.all += 1
                acc.amounts.all += items.reserves.at[id].amount
                if (items.reserves.at[id].type === 'payout') {
                    acc.counts.payouts += 1
                    acc.amounts.payouts += items.reserves.at[id].amount
                }
                if (items.reserves.at[id].type === 'reserve') {
                    acc.counts.reserves += 1
                    acc.amounts.reserves += items.reserves.at[id].amount
                }
                return acc
            },
            {
                counts: {
                    all: 0,
                    payouts: 0,
                    reserves: 0
                },
                amounts: {
                    all: 0,
                    payouts: 0,
                    reserves: 0
                }
            }
        )
    }, [items])

    // &nbsp;from&nbsp;<Text bold>MID {mid}.</Text>
    const handleStartCancelation = useDialog<{ templateId: string }>(
        useCallback(
            (watcherId) => {
                dispatch(WatcherDispatchSuccess([watcherId]))
                return SettlementsReservesActions.START_CANCELLING_RESERVES(mid, undefined, true)
            },
            [mid, dispatch]
        ),
        `«${mid}» fund cancellation`,
        useCallback(
            () => (
                <Flex column>
                    <Summary>
                        {selectedCount.payouts ? (
                            <SummaryItem>
                                {selectedCount.payouts === 1 ? '1 payout' : `${selectedCount.payouts} payouts`}
                                <em>
                                    &nbsp;&middot;&nbsp;{numberWithCurrency(currency, selectedSums.payouts)} {currency}
                                </em>
                            </SummaryItem>
                        ) : null}
                        {selectedCount.reserves ? (
                            <SummaryItem>
                                {selectedCount.reserves === 1 ? '1 reserve' : `${selectedCount.reserves} reserves`}
                                <em>
                                    &nbsp;&middot;&nbsp;{numberWithCurrency(currency, selectedSums.reserves)} {currency}
                                </em>
                            </SummaryItem>
                        ) : null}
                        <SummaryItem>
                            <em>A total of </em>
                            {numberWithCurrency(currency, amount)}
                            &nbsp;{currency} <em> will be cancelled</em>
                        </SummaryItem>
                    </Summary>
                    <Text>Cancelling reserves and payouts is an undoable action, so proceed with caution.</Text>
                    <Spacer height={10} />
                    <Text bold>Are you sure you want to start this process?</Text>
                    <Spacer height={10} />
                </Flex>
            ),
            [amount, currency, selectedCount, selectedSums]
        ),
        'Yes, continue',
        'front.danger.color'
    )

    const handleSelectAll = useCallback(() => {
        dispatch(
            SettlementsReservesActions.EXCLUSIVELY_SELECT_SETTLEMENTS(
                mid,
                items.reserves.all.filter((id) => items.reserves.at[id].type === 'reserve')
            )
        )
    }, [dispatch, items, mid])

    const handleClearSelection = useCallback(() => {
        dispatch(SettlementsReservesActions.EXCLUSIVELY_SELECT_SETTLEMENTS(mid, []))
    }, [dispatch, mid])

    const handleGracefulStop = useCallback(
        (e, watcherId) => {
            dispatch(SettlementsReservesActions.PREPARE_FOR_GRACEFUL_STOP(mid, watcherId))
        },
        [dispatch, mid]
    )

    const shouldButtonBeDisabled = useMemo(() => {
        if (!selectedCount.reserves && !selectedCount.payouts) return true
        return false
    }, [selectedCount])

    const dynamicText = useMemo(() => {
        if (!selectedCount.reserves && !selectedCount.payouts)
            return {
                button: `Select items for cancellation`,
                info: ``
            }
        return {
            button: `Cancel ${selectionText}`,
            info: `Amount selected from ${selectionText}`
        }
    }, [selectedCount, selectionText])

    if (items?.reserves?.loadingStatus !== 'done') return null
    if (!(items?.reserves?.all?.length > 0)) return null

    if (!currency) throw 'Currency not found'

    if (items.phase === 'error')
        return (
            <Flex justify="space-between" grow align="baseline">
                <Text>We have encountered an error while cancelling the reserves.</Text>
                <Flex align="center">
                    <SimpleButton
                        background="front.background.subtlerI"
                        onClick={() => {
                            dispatch(SettlementsReservesActions.ABORT_PROCESS(mid))
                        }}
                    >
                        <ButtonInset>Go back to selection</ButtonInset>
                    </SimpleButton>
                    <Spacer width={5} />
                    <SimpleButton
                        background="front.danger.color"
                        onClick={() => {
                            dispatch(SettlementsReservesActions.RETRY_FROM_FAILED(mid))
                        }}
                    >
                        <ButtonInset>Re-try from failed</ButtonInset>
                    </SimpleButton>
                    <Spacer width={5} />
                    <SimpleButton
                        background="front.accent.color"
                        onClick={() => {
                            dispatch(SettlementsReservesActions.START_CANCELLING_RESERVES(mid, true))
                        }}
                    >
                        <ButtonInset>Continue with the rest</ButtonInset>
                    </SimpleButton>
                </Flex>
            </Flex>
        )
    if (items.phase === 'interrupt')
        return (
            <Flex justify="space-between" grow align="baseline">
                <Text>
                    The cancellation process has been interrupted. Most likely due to a browser session being closed.
                </Text>
                <Flex align="center">
                    <SimpleButton
                        background="front.background.subtlerI"
                        onClick={() => {
                            dispatch(SettlementsReservesActions.ABORT_PROCESS(mid))
                        }}
                    >
                        <ButtonInset>Go back to selection</ButtonInset>
                    </SimpleButton>
                    <Spacer width={5} />
                    <SimpleButton
                        background="front.accent.color"
                        onClick={() => {
                            dispatch(SettlementsReservesActions.START_CANCELLING_RESERVES(mid, true))
                        }}
                    >
                        <ButtonInset>Continue the cancellation</ButtonInset>
                    </SimpleButton>
                </Flex>
            </Flex>
        )
    if (items.phase === 'processing') {
        const percentageComplete =
            (Object.keys(items.executing).filter((id) => items.executing[id] === 'done').length /
                Object.keys(items.executing).length) *
            100
        return (
            <Flex align="center" justify="space-between" grow>
                <Text>
                    Reserve cancellation in progress ({numberWithCommas(percentageComplete, true)}% complete)...
                </Text>
                <WatcherButton
                    predefinedWatcher={`${mid}_GRACEFUL_STOP`}
                    background="front.danger.color"
                    onClick={handleGracefulStop}
                >
                    <ButtonInset>Stop gracefully</ButtonInset>
                </WatcherButton>
            </Flex>
        )
    }

    return (
        <Flex column align="stretch" grow>
            <Flex align="center" justify="space-between" grow>
                <Flex align="baseline">
                    <AmountHolder>
                        <Amount>
                            {numberWithCurrency(currency, amount)}
                            <SmallAmount>{currency}</SmallAmount>
                        </Amount>
                    </AmountHolder>
                    {selectionIds?.length ? <Text color="front.text.subtlerI">{dynamicText.info}</Text> : null}
                </Flex>
                <Flex>
                    <Status>
                        <Text>Available for cancellation:</Text>
                        <TotalAmount>
                            {numberWithCurrency(currency, totalSum.amounts.all)} {currency}
                        </TotalAmount>
                        <br />
                        {totalSum.counts.reserves} reserves{' '}
                        <TotalAmount>
                            {numberWithCurrency(currency, totalSum.amounts.reserves)} {currency}
                        </TotalAmount>{' '}
                        and {totalSum.counts.payouts} payouts{' '}
                        <TotalAmount>
                            {numberWithCurrency(currency, totalSum.amounts.payouts)} {currency}
                        </TotalAmount>
                    </Status>
                </Flex>
            </Flex>
            <Controls>
                <Flex align="center" grow>
                    <CriticalFlowSettlementsSmartSelect mid={mid} currency={currency} />
                    <Spacer width={10} />
                    <SimpleButton onClick={handleSelectAll}>
                        <ButtonInset noHorizontalPadding>Select all reserves</ButtonInset>
                    </SimpleButton>
                    <Spacer width={15} />
                    {selectionIds.length ? (
                        <>
                            <SimpleButton onClick={handleClearSelection}>
                                <ButtonInset noHorizontalPadding>Deselect all</ButtonInset>
                            </SimpleButton>
                            <Spacer width={20} />
                        </>
                    ) : null}
                </Flex>
                <SimpleButton
                    background="front.accent.color"
                    onClick={handleStartCancelation}
                    isDisabled={shouldButtonBeDisabled}
                >
                    <ButtonInset>{dynamicText.button}</ButtonInset>
                </SimpleButton>
            </Controls>
        </Flex>
    )
}

const Summary = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 10px;
`

const SummaryItem = styled.li`
    padding: 10px 0;
    padding-left: 0;
    border-bottom: 1px solid ${(p) => p.theme['front.border']};
    font-weight: 500;

    &:first-child {
        padding-top: 0%;
    }

    &:last-child {
        border-bottom: none;
    }

    em {
        font-weight: 400;
    }
`

const Controls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -25px;
    margin-top: 20px;
    padding: 20px;
    background-color: ${(p) => p.theme['front.background.strongerI']};
    border-top: 1px solid ${(p) => p.theme['front.background.strongerII']};
    border-radius: 0 0 16px 16px;
`

const Amount = styled.div`
    font-size: 32px;
    font-weight: 500;
    display: flex;
    align-items: baseline;
    border-right: 1px solid ${(p) => p.theme['front.background']};
    margin-right: 10px;
    height: 20px;
`

const SmallAmount = styled.span`
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
`

const AmountHolder = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
`

const Status = styled.div`
    color: ${(p) => p.theme['front.text.subtlerI']};
    max-width: 400px;
    display: inline-block;
    text-align: right;
`

const TotalAmount = styled.div`
    background-color: ${(p) => p.theme['front.highlights']};
    color: ${(p) => p.theme['front.text']};
    font-weight: 500;
    padding: 1px 5px;
    margin: 2px 0;
    border-radius: 10px;
    display: inline-block;
`

//{items?.reserves?.all?.length} available reserves,
