import { Url } from '@/services/common'
import { api } from '../api'
import { Website, Websites } from '../types/websites'

export const websitesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getWebsites: build.query<Websites, Url>({
            query: (url) => {
                return { url }
            },
            transformResponse: (response: Websites) => {
                if (response.embedded['ch:websites'] && !Array.isArray(response.embedded['ch:websites'])) {
                    response.embedded['ch:websites'] = [response.embedded['ch:websites']]
                }

                return response
            }
        }),
        getWebsite: build.query<Website, Url>({
            query: (url) => {
                return { url }
            }
        })
    })
})

export const { useGetWebsitesQuery: useGetWebsites } = websitesApi
