import styled from 'styled-components'
import { uppercaseFirstLetter } from '../../utils'
import { Flex } from '../layout/flex'
import { Text } from './text'

export const AccountState: React.FC<{ state: string }> = ({ state }) => {
    if (state == 'live')
        return (
            <Flex align="center">
                <Dot />
                <Text bold>{uppercaseFirstLetter(state)}</Text>
            </Flex>
        )
    return <Text color="front.text.subtlerI">{uppercaseFirstLetter(state)}</Text>
}

const Dot = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 99px;
    background-color: ${(p) => p.theme['front.success.color']};
    margin-right: 6px;
`
