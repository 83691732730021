import { ModalHeader } from '@/components/modals/modalHeader'
import { ModalPage } from '@/components/layout/modalPage'
import { ModalPageInset } from '@/components/layout/modalPageInset'
import React from 'react'
import { ReportModalActions } from './report-modal-actions'
import { FinalizedLabel } from './finalized-label'
import { useReportDetails } from '../../provider/report-provider/report-provider'
import { useReportParams } from '../../../../hooks'

const actions = <ReportModalActions />
const label = <FinalizedLabel />

interface ReportModalProps {
    children: React.ReactNode
}

export const ReportModal: React.FC<ReportModalProps> = (props) => {
    const { children } = props
    const details = useReportDetails()

    const { applicationId } = useReportParams()

    const isReportFinalized = details.report_status === 'finalized'

    return (
        <ModalPage title="Report" pageId="Merchant.ODDReport" noExitOnBackgroundClick>
            <ModalHeader
                title="Report"
                pageId="Merchant.ODDReport"
                backTo={`/merchant/${applicationId}/more/ongoing-due-diligence`}
                rightSideMemo={!isReportFinalized ? actions : label}
            />
            <ModalPageInset>{children}</ModalPageInset>
        </ModalPage>
    )
}
