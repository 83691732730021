import { PartnerGuidelinesTooltip } from '@/features/partner-guidelines'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { LinkButton } from '../../../components/buttons/linkButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { Table } from '../../../components/tables/table'
import { DialogContext } from '../../../contexts/dialogProvider'
import { ApplicationInternalsReferralActions } from '../../../store/applicationInternals/referral/actions'
import { RootState } from '@/store'

export const SummaryReferralCard: React.FC<{
    id: string
}> = ({ id }) => {
    const dialogContext = useContext(DialogContext)
    const referrals = useSelector((state: RootState) => state.applicationInternals.referral[id])
    const dispatch = useDispatch()

    const handleRemoveClick = useCallback(
        (partnerId, generatedWatcherId) => {
            dialogContext?.setDialog({
                title: 'Confirm deletion',
                description: 'Are you sure you want to delete this section permanently?',
                action: {
                    label: 'Delete permanently',
                    buttonBackground: 'front.danger.color',
                    watcherId: generatedWatcherId,
                    action: () => {
                        dispatch(ApplicationInternalsReferralActions.REMOVE(id, partnerId, generatedWatcherId))
                    }
                }
            })
        },
        [dialogContext, dispatch, id]
    )

    useEffect(() => {
        if (referrals?.loadingStatus !== 'started' && referrals?.loadingStatus !== 'done')
            dispatch(ApplicationInternalsReferralActions.FETCH(id))
    }, [id, dispatch, referrals])

    const rightSide = useMemo(() => {
        return (
            <Flex grow justify="flex-end">
                <LinkButton
                    color="front.accent.color"
                    to={`/merchant/${id}/more/add-referral`}
                    hotkeysScope="Merchant.SettingsPage"
                    hotkeys="alt+n"
                    keepQuery
                >
                    <ButtonInset noVerticalPadding noHorizontalPadding>
                        + Add
                    </ButtonInset>
                </LinkButton>
            </Flex>
        )
    }, [id])

    return (
        <Flex column>
            <Flex>
                <Text color="front.text.subtlerI">Referral partner</Text>
                {rightSide}
            </Flex>
            <Spacer height={15} />
            <Table
                type="narrow"
                background="front.background"
                noHeader
                cols={[
                    {
                        text: 'Partner Name',
                        loaderSize: {
                            min: 30,
                            max: 110
                        }
                    },
                    {
                        text: 'Actions',
                        alignRight: true,
                        loaderSize: {
                            min: 100,
                            max: 100
                        }
                    }
                ]}
                loaderRows={1}
                columnLayout="auto min-content"
                showLastRowBorder
                bordered
                overrideText="No referral partners have been assigned."
                displayLoader={referrals?.loadingStatus !== 'done'}
                rows={referrals?.activeReferrals?.map((r) => {
                    return {
                        type: 'normal',
                        noAction: true,
                        key: r.id,
                        items: [
                            {
                                node: (
                                    <Flex align="center">
                                        <Text>{r.name}</Text>
                                        <PartnerGuidelinesTooltip gatewayIds={[r.id]} />
                                    </Flex>
                                )
                            },
                            {
                                node: (
                                    <Text>
                                        <WatcherButton
                                            color="front.danger.color"
                                            onClick={(e, generatedId) => handleRemoveClick(r.id, generatedId)}
                                        >
                                            <ButtonInset noVerticalPadding noHorizontalPadding>
                                                Remove
                                            </ButtonInset>
                                        </WatcherButton>
                                    </Text>
                                )
                            }
                        ]
                    }
                })}
                emptyText="No tasks found matching the filtered criterias."
            />
        </Flex>
    )
}
