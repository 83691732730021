import dotProp from 'dot-prop'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Action } from 'redux'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { FileActionDispatchReplace, FilesActionDispatchUpload } from '../../store/files/actions'
import { CutterSubmitFileData, File, SubmitFileData } from '../../store/files/types'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../../store/toasts/actions'
import { LabelButton } from '../buttons/labelButton'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { useActionSaverChild } from '../../hooks/general/useActionSaverChild'
import { Icon } from '../icons/icon'

export const SyncdFile: React.FC<{
    label?: string
    fileLabel: string
    multiple?: boolean
    sourcePath: string
    scope: 'public' | 'private'
    marginless?: boolean
    linkFiles: (files: any) => Action
    unlinkFiles?: (files: any) => Action
    cy?: string
    cutterBased?: boolean
    customButton?: (forField: string) => React.ReactNode
    withDocumentPrefix?: boolean
    handleFileReplace?: {
        file?: File
        onSuccess: () => void
    }
    clickActionSaver?: (onClick?: () => void) => void
}> = ({
    label,
    sourcePath,
    fileLabel,
    multiple = false,
    scope = undefined,
    linkFiles,
    unlinkFiles,
    cutterBased,
    withDocumentPrefix = false,
    customButton,
    clickActionSaver,
    handleFileReplace,
    cy
}) => {
    const [fileSubmitRef, setFileSubmitRef] = useRefTaker()
    const source = useSelector((state: RootState) => {
        return dotProp.get(state, sourcePath) as string
    })
    const dispatch = useDispatch()
    const fieldIdRef = useMemo(() => uuid(), [])
    const [refreshFieldAfterUpload, setRefreshFieldAfterUpload] = useState(fieldIdRef)
    const fieldId = fieldIdRef
    useActionSaverChild(
        clickActionSaver,
        useCallback(() => {
            if (!fileSubmitRef) return
            fileSubmitRef.click()
        }, [fileSubmitRef])
    )

    useEffect(() => {
        if (handleFileReplace?.file && fileSubmitRef) {
            fileSubmitRef.click()
        }
    }, [fileSubmitRef, handleFileReplace])

    const onFileUpload = useCallback(
        (event: any) => {
            setRefreshFieldAfterUpload(uuid())
            const uploadedFilesData = [...event.target.files]
            const fileData: Array<SubmitFileData | CutterSubmitFileData> = []
            uploadedFilesData.map((wholeFile: any, i: number) => {
                const newFileData: SubmitFileData | CutterSubmitFileData = {
                    label: fileLabel,
                    wholeFile
                } as any

                if (cutterBased) {
                    newFileData.API = 'cutter'
                    if (newFileData.API === 'cutter') {
                        newFileData.document_content_type = wholeFile.type || 'image/jpeg'
                        newFileData.document_file_name = wholeFile.name
                        newFileData.document_file_size = wholeFile.size
                    }
                } else {
                    newFileData.API = 'mapi'
                    if (newFileData.API === 'mapi') {
                        newFileData.contentType = wholeFile.type || 'image/jpeg'
                        newFileData.name = wholeFile.name
                        newFileData.size = wholeFile.size
                    }
                }

                if (scope !== undefined) newFileData.scope = scope
                fileData.push(newFileData)
            })

            if (source)
                if (handleFileReplace?.file && unlinkFiles) {
                    dispatch(
                        FileActionDispatchReplace(
                            uuid(),
                            source,
                            fileData,
                            handleFileReplace.file,
                            linkFiles,
                            unlinkFiles,
                            withDocumentPrefix
                        )
                    )
                    handleFileReplace.onSuccess()
                } else {
                    dispatch(FilesActionDispatchUpload(source, fileData, linkFiles, withDocumentPrefix))
                }
            else dispatch(ToastsDispatchPush('File upload remote link not found.', 'error'))
        },
        [source, handleFileReplace, unlinkFiles, dispatch, fileLabel, cutterBased, scope, linkFiles, withDocumentPrefix]
    )

    return (
        <>
            {label}
            {uploadButton(multiple, fieldId, customButton)}
            <HiddenInput
                multiple={multiple}
                onChange={onFileUpload}
                key={refreshFieldAfterUpload}
                id={fieldId}
                data-cy={cy}
                ref={setFileSubmitRef}
                type="file"
                name="file"
            />
        </>
    )
}

const uploadButton = (multiple: boolean, fieldId: string, customButton: any) => {
    if (customButton) return customButton(fieldId)

    return (
        <LabelButton background="front.accent.color" htmlFor={fieldId}>
            <Flex align="center">
                <Icon type="attachment" key="attachIcon" />
                <Spacer width={10} />
                {multiple ? 'Send files' : 'Send file'}
            </Flex>
        </LabelButton>
    )
}

const HiddenInput = styled.input`
    display: none;
`
