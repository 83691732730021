import { SectionCheck } from '@/services/ongoing-due-diligence'
import React from 'react'
import { Section } from '../../../common/section'
import { useReportSection } from '../../../provider/report-provider/report-provider'
import { ToggleContent } from '../toggle-content/toggle-content'

export const CompanyFundamentalChanges: React.FC = () => {
    const checks = useReportSection<SectionCheck>('checks')

    const data = checks.company_fundamental_changes
    const commentData = checks.comment_company_fundamental_changes

    return (
        <Section>
            <Section.Content>
                <ToggleContent
                    dataName="company_fundamental_changes"
                    data={data}
                    commentName="comment_company_fundamental_changes"
                    commentData={commentData}
                    visibleValue="Yes"
                />
            </Section.Content>
        </Section>
    )
}
