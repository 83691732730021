type Entry = {
    id: string
    amount: number
    [key: string]: any
}

export function filterReconciliationsById(ids?: string[], entries?: Entry[]) {
    if (!ids) return []

    const reconciliationsWithIds = (Array.isArray(entries) ? entries : []).filter((item) => ids.includes(item.id))

    const reconciliations = reconciliationsWithIds.map((item) => {
        return { type: item?.posting_type, amount: item?.amount }
    })

    return reconciliations
}
