import { Button, Select } from '@/design-system'
import React from 'react'
import { ActionPopover } from '../common/action-popover'
import { useForceReconcilePostingActions } from '@/features/reconciliation/hooks'
import { ForceReconciliationCategory } from '@/services/reconciliation'

const ForceReconcilePosting: React.FC = () => {
    const { isLoading, forceReconcilePosting } = useForceReconcilePostingActions()
    const [category, setCategory] = React.useState<ForceReconciliationCategory | undefined>(undefined)

    const handleOnForceReconciliation = async () => {
        if (category) {
            await forceReconcilePosting(category)
        }
    }

    const handleCategoryChange = (value: string) => {
        setCategory(value as ForceReconciliationCategory)
    }

    return (
        <ActionPopover>
            <ActionPopover.Trigger>
                <Button color="danger" variant="link">
                    Force reconcile
                </Button>
            </ActionPopover.Trigger>
            <ActionPopover.Content>
                <ActionPopover.Header>
                    <ActionPopover.Title>Are you sure you want to force reconcile this posting?</ActionPopover.Title>
                    <ActionPopover.Subtitle>
                        Force reconciling this posting will remove it from the reconciliation process after a brief
                        amount of time.
                    </ActionPopover.Subtitle>
                </ActionPopover.Header>
                <ActionPopover.Fields>
                    <Select
                        variant="surface"
                        placeholder="Select a category"
                        value={category}
                        onValueChange={handleCategoryChange}
                    >
                        <Select.Item value="bank_account_fee">Bank account fee</Select.Item>
                        <Select.Item value="bank_transfer_fee">Bank transfer fee</Select.Item>
                        <Select.Item value="clearhaus_internal_transfer">Clearhaus internal transfer</Select.Item>
                        <Select.Item value="interest">Interest</Select.Item>
                        <Select.Item value="mastercard_funding">Mastercard funding</Select.Item>
                        <Select.Item value="mastercard_funding_vibrant">Mastercard funding vibrant</Select.Item>
                        <Select.Item value="vibrant_funding">Vibrant funding</Select.Item>
                        <Select.Item value="visa_funding">Visa funding</Select.Item>
                    </Select>
                </ActionPopover.Fields>
                <ActionPopover.Warning>
                    Don't do this lighly and make sure this is a conscious decision.
                </ActionPopover.Warning>
                <ActionPopover.Footer>
                    <Button
                        color="danger"
                        onClick={handleOnForceReconciliation}
                        loading={isLoading}
                        disabled={!category}
                        width="full"
                    >
                        Force reconcile
                    </Button>
                </ActionPopover.Footer>
            </ActionPopover.Content>
        </ActionPopover>
    )
}

export { ForceReconcilePosting }
