import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { Table } from '@/components/tables/table'
import { useGetClassifications } from '@/services/risk-classification'
import { CreateClassificationButton } from './create-classification-button/create-classification-button'
import { getClassificationRows } from './helpers'

const cols = [
    { text: 'Created on' },
    { text: 'Author' },
    { text: 'Risk Level' },
    { text: 'Comment' },
    { text: 'Status', alignRight: true }
]

interface IClassificationsTableProps {
    applicationId: string
}

export const ClassificationsTable: React.FC<IClassificationsTableProps> = (props) => {
    const { applicationId } = props

    const { isLoading, data } = useGetClassifications(applicationId)
    const rows = getClassificationRows(data)

    return (
        <Card title="Risk classifications" rightSideMemo={<CreateClassificationButton />}>
            <CardInset>
                <Table
                    displayLoader={isLoading}
                    loaderRows={1}
                    rows={rows}
                    cols={cols}
                    background="front.background"
                    columnLayout="1fr 1fr 1fr 3fr auto"
                />
            </CardInset>
        </Card>
    )
}
