import { PartnerGuidelinesTooltip } from '@/features/partner-guidelines'
import { ApplicationResourceActions } from '@/store/applicationResources/actions'
import { camelCase } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { ExternalLink } from '../../components/buttons/externalLink'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Text } from '../../components/general/text'
import { Flex } from '../../components/layout/flex'
import { Floater } from '../../components/layout/floater'
import { FloaterInset } from '../../components/layout/floaterInset'
import { Spacer } from '../../components/layout/spacer'
import { HeaderText } from '../../components/tables/table'
import { useOnClickOutside } from '../../hooks/general/useOnClickOutside'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { RootState } from '@/store'
import { getWebsiteLabel, getWebsiteUrl } from '../../utils/formatting'

export const MerchantSidebarDetails: React.FC<{ id: string }> = ({ id }) => {
    const [show, setShow] = useState(false)
    const [holder, setHolder] = useRefTaker()
    const [anchor, setAnchor] = useRefTaker()
    const [showAllMids, setShowAllMids] = useState(false)
    useOnClickOutside(
        holder,
        useCallback(() => {
            setShow(false)
        }, [])
    )
    const gatewayResource = useSelector(
        (state: RootState) => state.applicationResources.data?.forApplication?.[id]?.gateway?.fields?.[0]
    )
    const websitesRessource = useSelector(
        (state: RootState) => state.applicationResources.data?.forApplication?.[id]?.websites
    )

    const gateway = useMemo(() => {
        const selectedGatewayId = gatewayResource?.gateways?.[0]?.id

        if (!selectedGatewayId) return { name: '-', id: null }
        const selectedGateway = gatewayResource?.selectable?.find((k: any) => k.id === selectedGatewayId)
        return { name: selectedGateway?.name, id: selectedGatewayId } || { name: `⚠️ Wrong gateway`, id: null }
    }, [gatewayResource])

    const urls = useMemo(() => {
        return (websitesRessource?.fields || []).filter((w) => w?.url).map((w) => w?.url) || []
    }, [websitesRessource])

    const mids = useSelector((state: RootState) => {
        return state.merchantAccounts.forApplication?.[id]?.all.map((id) => state.merchantAccounts.at[id].merchantId)
    })

    const MIDString = useMemo(() => {
        if (showAllMids) return mids?.filter((id, i) => id).join(', ') || ''
        if (mids?.length > 5) return mids?.filter((id, i) => id && i < 5).join(', ') + '...'
        return mids?.filter((id) => id).join(', ') || ''
    }, [mids, showAllMids])

    const dispatch = useDispatch()

    const resultsFetcher = useMemo(() => {
        if (!websitesRessource?.nextLink) return null
        if (websitesRessource?.nextLink) {
            return (
                <SimpleButton
                    onClick={() => {
                        if (websitesRessource?.nextLink) {
                            dispatch(
                                ApplicationResourceActions.FETCH_MORE_WEBSITES(
                                    id,
                                    websitesRessource?.fields,
                                    websitesRessource?.nextLink
                                )
                            )
                        }
                    }}
                    defaultColor="floating.subtleAccent.text"
                >
                    <ButtonInset leftAlign>See More</ButtonInset>
                </SimpleButton>
            )
        }
        return null
    }, [id, websitesRessource, dispatch])

    return (
        <Holder>
            <DetailFlex>
                <HeaderText color="side.text.subtlerI">GATEWAY</HeaderText>
                <Flex align="center">
                    <Text color="side.text">{gateway.name || '⚠️ Wrong gateway'}</Text>
                    <PartnerGuidelinesTooltip gatewayIds={[gateway.id]} />
                </Flex>
            </DetailFlex>
            <DetailFlex ref={setHolder}>
                <Flex>
                    <div ref={setAnchor}>
                        {urls?.length > 1 ? (
                            <WebsiteLinkHolder>
                                <SimpleButton
                                    defaultColor="side.text"
                                    onClick={() => {
                                        setShow(true)
                                    }}
                                >
                                    <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                                        <HeaderText color="side.text.subtlerI">
                                            WEBSITES ({urls?.length || 0})
                                        </HeaderText>
                                    </ButtonInset>
                                </SimpleButton>
                            </WebsiteLinkHolder>
                        ) : (
                            <HeaderText color="side.text.subtlerI">WEBSITES ({urls?.length || 0})</HeaderText>
                        )}
                    </div>
                </Flex>
                {urls?.length > 1 ? (
                    <Floater anchor={anchor} shouldShow={show}>
                        <FloaterInset padding="small" equalPadding>
                            <Flex column>
                                {urls?.map((url) => {
                                    if (!url) return
                                    return (
                                        <ExternalLink
                                            url={getWebsiteUrl(url)}
                                            target="blank"
                                            key={camelCase(url)}
                                            alwaysShowArrow
                                            defaultColor="floating.subtleAccent.text"
                                        >
                                            <ButtonInset leftAlign>{getWebsiteLabel(url)}</ButtonInset>
                                        </ExternalLink>
                                    )
                                })}
                                {resultsFetcher}
                            </Flex>
                        </FloaterInset>
                    </Floater>
                ) : null}
                {urls?.[0] ? (
                    <ExternalLink
                        url={getWebsiteUrl(urls[0])}
                        target="blank"
                        key={camelCase(urls[0])}
                        defaultColor="side.text"
                        accentColor="side.subtleAccent.text"
                        alwaysShowArrow
                    >
                        {getWebsiteLabel(urls[0])}
                    </ExternalLink>
                ) : (
                    <Text color="side.text">-</Text>
                )}
            </DetailFlex>{' '}
            <DetailFlex>
                <HeaderText color="side.text.subtlerI">MIDs ({mids?.length})</HeaderText>
                <Text color="side.text">
                    {mids?.length ? MIDString : <Text color="side.text.subtlerI">Merchant has no MIDs</Text>}
                    <Spacer width={5} inline />

                    {mids?.length > 5 ? (
                        <SimpleButton
                            color="side.accent.color"
                            inline
                            onClick={() => {
                                setShowAllMids((s) => !s)
                            }}
                        >
                            {showAllMids ? 'See less' : 'See all'}
                        </SimpleButton>
                    ) : null}
                </Text>
            </DetailFlex>
        </Holder>
    )
}

const Holder = styled.div`
    color: ${(p) => p.theme['front.text.subtlerI']};
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    margin-top: 23px;
`

const WebsiteLinkHolder = styled.div`
    word-break: keep-all;
    white-space: nowrap;
    * {
        word-break: keep-all;
        white-space: nowrap;
    }
`

const DetailFlex = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    margin-bottom: 20px;
`
