import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { List } from '../../components/layout/list'
import { ModalPage } from '../../components/layout/modalPage'
import { ModalPageInset } from '../../components/layout/modalPageInset'
import { Spacer } from '../../components/layout/spacer'
import { ModalHeader } from '../../components/modals/modalHeader'
import { useForm } from '../../hooks/general/useForm'
import * as yup from 'yup'
import { FormHandler } from '../../components/forms/formHandler'
import { Separator } from '../../components/layout/separator'
import { useNamedWatcherWithContext } from '../../hooks/general/useWatcher'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { usePricingSchemes } from '../Merchant/Accounts/usePricingSchemeDropdownConfig'
import { VouchersActions } from '../../store/manage/vouchers/actions'
import { Text } from '../../components/general/text'
import { CardSection } from '../../components/cards/cardSection'
import { useCopyVoucherNodes } from './useCopyVoucherNodes'
import styled from 'styled-components'
import { ContractTemplateActions } from '../../store/contractTemplates/actions'
import { useGateways } from '../../hooks/general/useGateways'

export const ManageNewVoucher: React.FC = () => {
    const [watcher, watcherId, context] = useNamedWatcherWithContext(`CreateNewVoucher`)
    const { errors, formRef, focusInvalidField, form, submitHandler, resetForm } = useForm(watcher)
    const [createdVoucherId, setCreatedVoucherId] = useState<string | undefined>()
    const dispatch = useDispatch()
    const gateways = useGateways()
    const vouchers = useSelector((state: RootState) => {
        return state.manage.vouchers
    })

    const [templates, config] = usePricingSchemes()

    const textForChange = useCallback(
        (k, v) => {
            if (v === undefined || v === '') return <Text color="front.text.subtlerI">-</Text>
            if (k === 'psp' || k === 'referral') return gateways?.at?.[v]?.name || v
            if (k === 'priceScheme') return templates?.at?.[v]?.label
            if (v === false) return 'No'
            if (v === true) return 'Yes'
            return v
        },
        [gateways, templates]
    )

    useEffect(() => {
        if (watcher === 'success') {
            setCreatedVoucherId(context)
        }
    }, [watcher, context])

    const [websiteLink, renderNodes] = useCopyVoucherNodes()

    const handleSubmit = useCallback(
        (form: any) => {
            dispatch(
                VouchersActions.NEW(
                    watcherId,
                    form.data.psp,
                    form.data.referral,
                    form.data.instantFlow,
                    form.data.priceScheme
                )
            )
        },
        [dispatch, watcherId]
    )

    const renderCreatedVoucher = useMemo(() => {
        if (!createdVoucherId) return undefined
        const v = vouchers.at[createdVoucherId]
        return (
            <CardInset>
                <Text bold>The voucher has been successfully created.</Text>
                <Spacer height={20} />
                <SimpleGrid>
                    <Text oneLine>PSP: </Text>
                    <Text>{(v?.gatewayId && gateways.at?.[v?.gatewayId].name) || '-'}</Text>
                    <Text oneLine>Referral:</Text>
                    <Text>{(v?.referralPartnerId && gateways.at?.[v?.referralPartnerId].name) || '-'}</Text>
                    <Text oneLine>Voucher:</Text>
                    <CodeHolder>{v?.code || '-'}</CodeHolder>
                    <Text oneLine>Link:</Text>
                    <CodeHolder>
                        {websiteLink}
                        {v?.code || '-'}
                    </CodeHolder>
                </SimpleGrid>
                {v?.code && (
                    <CardSection background="lime">
                        <CardInset>
                            <Flex justify="flex-end">{renderNodes(v.code)}</Flex>
                        </CardInset>
                    </CardSection>
                )}
            </CardInset>
        )
    }, [websiteLink, createdVoucherId, vouchers, gateways, renderNodes])

    useEffect(() => {
        if (templates.loadingStatus === 'not-started') dispatch(ContractTemplateActions.FETCH_ALL())
    }, [dispatch, templates])

    const PSP = useMemo(() => {
        const pspIds = gateways.all
            .filter((g) => gateways.at[g].support)
            .sort((a, b) => {
                if (gateways.at[a].active) return -1
                return 1
            })
        const dictionary = pspIds.reduce((acc, id) => {
            acc[id] = gateways.at[id].name
            return acc
        }, {} as any)
        return {
            options: pspIds,
            textForItem: (k: string) => {
                if (!k) return undefined
                return gateways.at[k]?.active ? dictionary[k] : `${dictionary[k]} [Inactive]`
            }
        }
    }, [gateways])

    const referrals = useMemo(() => {
        const referralIds = gateways.all.filter((g) => gateways.at[g].referral)
        const dictionary = referralIds.reduce((acc, id) => {
            acc[id] = gateways.at[id].name
            return acc
        }, {} as any)
        return {
            options: referralIds,
            textForItem: (k: string) => dictionary[k]
        }
    }, [gateways])

    const instantFlow = useMemo(() => {
        return {
            options: [true, false],
            textForItem: (e: boolean) => {
                if (e === true) return 'Yes'
                return 'No'
            }
        }
    }, [])

    const isStillLoading = useMemo(() => {
        if (gateways.loadingStatus !== 'done') return true
        if (templates.loadingStatus !== 'done') return true
        return false
    }, [gateways, templates])

    return (
        <ModalPage title="New voucher" pageId="Manage.Vouchers.New" isLoading={isStillLoading}>
            <ModalHeader title="New Voucher" pageId="Manage.Vouchers.New" />
            <ModalPageInset>
                <Flex justify="center" align="center" grow column>
                    <Card higher title="Voucher details">
                        {renderCreatedVoucher ? (
                            renderCreatedVoucher
                        ) : (
                            <CardInset>
                                <Spacer height={15} />
                                <List
                                    background="front.background"
                                    items={{
                                        'PSP Partner': {
                                            type: 'select',
                                            placeholder: '-',
                                            overBackground: 'front.background',
                                            validation: errors.psp,
                                            dropdownId: 'Manage.Vouchers.New.PSPDropdown',
                                            textForItem: PSP.textForItem,
                                            items: PSP.options,
                                            ref: (ref) => formRef(ref, 'psp', yup.string())
                                        },
                                        'Referral Partner': {
                                            type: 'select',
                                            placeholder: '-',
                                            overBackground: 'front.background',
                                            items: referrals.options,
                                            dropdownId: 'Manage.Vouchers.New.ReferralDropdown',
                                            textForItem: referrals.textForItem,
                                            validation: errors.referral,
                                            ref: (ref) => formRef(ref, 'referral', yup.string())
                                        },
                                        'Separator 1': {
                                            key: 'separator-1',
                                            type: 'separator',
                                            node: <Separator />
                                        },
                                        'Instant flow': {
                                            type: 'select',
                                            placeholder: '-',
                                            overBackground: 'front.background',
                                            items: instantFlow.options,
                                            dropdownId: 'Manage.Vouchers.New.InstantFlow',
                                            textForItem: instantFlow.textForItem,
                                            isQuestion: true,
                                            selected: false,
                                            validation: errors.instantFlow,
                                            ref: (ref) => formRef(ref, 'instantFlow', yup.string())
                                        },
                                        'Price scheme': {
                                            type: 'select',
                                            placeholder: '-',
                                            overBackground: 'front.background',
                                            items: config.items,
                                            tableLike: config.tableLike,
                                            dropdownId: 'Manage.Vouchers.New.PriceSchemeDropdown',
                                            textForItem: config.textForItem,
                                            shouldHide: form.data.instantFlow !== true,
                                            validation: errors.instantFlow,
                                            ref: (ref) => formRef(ref, 'priceScheme', yup.string())
                                        }
                                    }}
                                    switchToRowsAt={10000}
                                    cellHorizontalTemplate="120px minmax(auto, 200px)"
                                />
                                <Spacer height={20} />
                                <CardSection background="subtleBlue">
                                    <CardInset reduceVerticalPadding>
                                        <Flex column align="flex-end">
                                            <FormHandler
                                                errors={errors}
                                                textForFieldName={(t) => t}
                                                formSubmitLabel="Create voucher"
                                                focusInvalidField={focusInvalidField}
                                                changes={form.changes}
                                                textForChange={textForChange}
                                                submitForm={submitHandler(handleSubmit)}
                                                submitWatcherId={watcherId}
                                                resetForm={resetForm}
                                            />
                                        </Flex>
                                    </CardInset>
                                </CardSection>
                            </CardInset>
                        )}
                    </Card>
                    <Spacer height={120} />
                </Flex>
            </ModalPageInset>
        </ModalPage>
    )
}

const CodeHolder = styled.div`
    padding: 0px 5px;
    background-color: ${(p) => p.theme['front.background.strongerI']};
    border-radius: 4px;
    margin: 0 -5px;
    max-width: 360px;
    word-break: break-word;
`

const SimpleGrid = styled.div`
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-row-gap: 4px;
    grid-column-gap: 20px;
`
