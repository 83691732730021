import React from 'react'
import { Export } from '../../store/exports/types'
import { dictionaryToSearchString, URLSearchParamsToDictionary } from '../../utils/general'
import { ButtonInset } from '../buttons/buttonInset'
import { LinkButton } from '../buttons/linkButton'
import { IDShortener } from './idShortener'
import { Text } from './text'

export const ExportsNamer: React.FC<{ e: Export; floating?: boolean }> = ({ e, floating }) => {
    if (!e?.sources?.[0]) return null
    if (e.sources[0].includes('settlements/') && e.sources[0].includes('/transactions')) {
        let settlementId = e.sources[0].split('settlements/')[1]
        settlementId = settlementId.split('/transactions')[0]
        return (
            <LinkButton
                to={`/settlements/${settlementId}`}
                color={floating ? 'floating.subtleAccent.text' : 'front.accent.color'}
            >
                <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                    Settlement&nbsp;
                    <IDShortener id={settlementId} noTooltip />
                    &nbsp;&middot;&nbsp;{floating ? 'Tran.' : 'Transactions'}
                </ButtonInset>
            </LinkButton>
        )
    }
    if (e.sources[0].includes('settlements/')) {
        const settlementId = e.sources[0].split('settlements/')[1]
        return (
            <LinkButton
                to={`/settlements/${settlementId}`}
                color={floating ? 'floating.subtleAccent.text' : 'front.accent.color'}
            >
                <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                    Settlement&nbsp;
                    <IDShortener id={settlementId} noTooltip />
                </ButtonInset>
            </LinkButton>
        )
    }
    if (e.sources?.[0].includes('/transactions') || e.sources?.[0].includes('/settlements')) {
        // if the export is unfinished, we add the export date to simulate a query matching it
        const config = (() => {
            if (e.sources?.[0].includes('/transactions'))
                return {
                    label: 'Transactions',
                    to: '/transactions',
                    api: 'tql'
                }
            if (e.sources?.[0].includes('/settlements'))
                return {
                    label: 'Settlements',
                    to: '/settlements',
                    api: 'sql'
                }
            return undefined
        })()!
        const source: string = e.sources[0]
        const params: any = URLSearchParamsToDictionary(new URLSearchParams(source.split('?')[1]))
        if (!params.query) {
            return <Text>Cannot link back</Text>
        }
        return (
            <LinkButton
                to={{
                    pathname: config.to,
                    search: dictionaryToSearchString({
                        [config.api]: params.query
                    })
                }}
                // to="#"
                color={floating ? 'floating.subtleAccent.text' : 'front.accent.color'}
            >
                <ButtonInset leftAlign noHorizontalPadding noVerticalPadding>
                    Queried {config.label}
                </ButtonInset>
            </LinkButton>
        )
    }
    return null
}
