import styled from 'styled-components'
import { Text } from './text'
import { Flex } from '../layout/flex'

export const ProgressBar: React.FC<{ progress: number }> = ({ progress }) => {
    return (
        <Flex align="center" justify="flex-start">
            <Holder>
                <Filler progress={progress} />
            </Holder>
            <Text size="xs" color="front.accent.color" bold>
                {progress} / 100
            </Text>
        </Flex>
    )
}

const Holder = styled.div`
    background-color: ${(p) => p.theme['back.background']};
    height: 3px;
    margin: 10px 0;
    flex-grow: 1;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 20px;
`

const Filler = styled.div<{ progress: number }>`
    height: 100%;
    background-color: ${(p) => p.theme['front.accent.color']};
    width: ${(p) => p.progress}%;
`
