import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { InterfaceActions } from '../../../store/interface/actions'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { ConvertIndexPathToFieldDetails } from './Application.Structure'

import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { MerchantApplicationEditsModalDateOfBirth } from './Application.InputDateOfBirth'
import { MerchantApplicationEditsModalInputCurrency } from './Application.InputCurrency'
// eslint-disable-next-line max-len
import { MerchantApplicationEditsModalInputOwnershipStructure } from './Application.InputOwnershipStructure'
import { MerchantApplicationEditsModalInputRole } from './Application.InputRole'
import { MerchantApplicationEditsModalInputCompanyForm } from './Application.InputCompanyForm'
import { MerchantApplicationEditsModalInputGateway } from './Application.InputGateway'
import { MerchantApplicationEditsModalInputDeliveryDelay } from './Application.InputDeliveryDelay'
import { MerchantApplicationEditsModalInputCountry } from './Application.InputCountry'
import { QuestionToggle } from '../../../components/forms/questionToggle'
import { MerchantApplicationEditsModalInputNumber } from './Application.InputNumber'
import { FormFieldFormatting, ValidationStatus } from '../../../hooks/general/useForm'
import { ApplicationValidationRules } from '../Accounts/utils'
import { MerchantApplicationFile } from './Application.File'
import { fixUrl } from './../../../utils'

const urlFormatter: FormFieldFormatting = {
    fromServer: (s) => s,
    toServer: {
        beforeValidation: fixUrl
    }
}
export const MerchantApplicationEditsModalInput: React.FC<{
    applicationId: string
    indexPath: MerchantApplicationResourceIndexPath
    initialValue?: any
    isSelected?: boolean
    smallerSuperField?: boolean
    superField?: boolean
    highlighted?: 'top' | 'bottom' | 'both'
    formErrors: { [key: string]: ValidationStatus }
    formRef: MerchantApplicationFieldFormProps['formRef']
}> = ({
    applicationId,
    smallerSuperField,
    isSelected,
    superField,
    formRef,
    indexPath,
    highlighted,
    formErrors,
    initialValue
}) => {
    const dispatch = useDispatch()

    const indexPathString = useMemo(() => {
        return ConvertIndexPathToIndexPathString(indexPath)
    }, [indexPath])

    const fieldDetails = useMemo(() => ConvertIndexPathToFieldDetails(indexPath), [indexPath])

    const handleBlur = useCallback(
        (e) => {
            if (e.relatedTarget?.closest('[data-prevent-blur-exit]')) return
            dispatch(InterfaceActions.EDIT_APPLICATION_FIELD(undefined))
        },
        [dispatch]
    )

    const fieldRef = useCallback(
        (ref: any) => {
            if (!indexPathString) return
            if (fieldDetails.field.type === 'email') {
                return formRef(ref, indexPathString, ApplicationValidationRules.optionalValue.email())
            }
            if (fieldDetails.field.sanitised) {
                return formRef(
                    ref,
                    indexPathString,
                    ApplicationValidationRules.sanitisedValue(fieldDetails.field.validation?.maxLength || undefined)
                )
            }
            if (indexPath.resourceKey == 'additionalInformation')
                return formRef(ref, indexPathString, ApplicationValidationRules.optionalValue)
            if (fieldDetails.field.validation) {
                if (fieldDetails.field.validation.maxLength !== undefined)
                    return formRef(
                        ref,
                        indexPathString,
                        ApplicationValidationRules.value.max(fieldDetails.field.validation.maxLength)
                    )
            }
            return formRef(ref, indexPathString, ApplicationValidationRules.value)
        },
        [formRef, indexPathString, indexPath, fieldDetails]
    )

    const defaultBackground = useMemo(() => {
        if (highlighted) return 'back.background'
        return 'front.background'
    }, [highlighted])

    const field = useMemo(() => {
        if (!indexPath) return undefined
        if (!fieldDetails) return undefined

        if (fieldDetails.field.type === 'file' || fieldDetails.field.type === 'multipleFiles') {
            return (
                <MerchantApplicationFile
                    indexPath={indexPath}
                    applicationId={applicationId}
                    acceptMultiple={fieldDetails.field.type === 'multipleFiles'}
                    preventUpload={fieldDetails.field.deprecated}
                />
            )
        }
        if (fieldDetails.field.type === 'dropdown') {
            if (indexPath.resourceKey === 'businessModel' && indexPath.fieldKey === 'businessModel.deliveryDelay')
                return (
                    <MerchantApplicationEditsModalInputDeliveryDelay
                        preSelected={initialValue}
                        formRef={formRef}
                        overBackground={isSelected ? 'floating.background' : defaultBackground}
                        indexPath={indexPath}
                        validation={formErrors[indexPathString]}
                        onBlur={handleBlur}
                    />
                )
            if (indexPath.resourceKey === 'gateway' && indexPath.fieldKey === 'gateways.0.id')
                return (
                    <MerchantApplicationEditsModalInputGateway
                        applicationId={applicationId}
                        preSelected={initialValue}
                        formRef={formRef}
                        validation={formErrors[indexPathString]}
                        overBackground={isSelected ? 'floating.background' : defaultBackground}
                        indexPath={indexPath}
                        onBlur={handleBlur}
                    />
                )
            if (indexPath.resourceKey === 'company' && indexPath.fieldKey === 'form')
                return (
                    <MerchantApplicationEditsModalInputCompanyForm
                        preSelected={initialValue}
                        formRef={formRef}
                        indexPath={indexPath}
                        validation={formErrors[indexPathString]}
                        overBackground={isSelected ? 'floating.background' : defaultBackground}
                        onBlur={handleBlur}
                    />
                )
            if (indexPath.fieldKey === 'country')
                return (
                    <MerchantApplicationEditsModalInputCountry
                        preSelected={initialValue}
                        formRef={formRef}
                        indexPath={indexPath}
                        validation={formErrors[indexPathString]}
                        overBackground={isSelected ? 'floating.background' : defaultBackground}
                        onBlur={handleBlur}
                    />
                )
            if (indexPath.fieldKey === 'role')
                return (
                    <MerchantApplicationEditsModalInputRole
                        preSelected={initialValue}
                        formRef={formRef}
                        indexPath={indexPath}
                        validation={formErrors[indexPathString]}
                        overBackground={isSelected ? 'floating.background' : defaultBackground}
                        onBlur={handleBlur}
                    />
                )
            if (indexPath.fieldKey === 'businessModel.estimateCurrency' || indexPath.fieldKey === 'currency')
                return (
                    <MerchantApplicationEditsModalInputCurrency
                        preSelected={initialValue}
                        formRef={formRef}
                        indexPath={indexPath}
                        validation={formErrors[indexPathString]}
                        overBackground={isSelected ? 'floating.background' : defaultBackground}
                        onBlur={handleBlur}
                    />
                )
            if (indexPath.fieldKey === 'ownershipStructure')
                return (
                    <MerchantApplicationEditsModalInputOwnershipStructure
                        preSelected={initialValue}
                        overBackground={isSelected ? 'floating.background' : defaultBackground}
                        formRef={formRef}
                        validation={formErrors[indexPathString]}
                        indexPath={indexPath}
                        onBlur={handleBlur}
                    />
                )
        }

        if (fieldDetails.field.type === 'question') {
            return (
                <QuestionToggle
                    formRef={formRef}
                    indexPath={indexPath}
                    selectedOption={initialValue}
                    overBackground={isSelected ? 'floating.background' : defaultBackground}
                    validation={formErrors[indexPathString]}
                    onBlur={handleBlur}
                />
            )
        }

        if (indexPath.fieldKey === 'dateOfBirth')
            return (
                <MerchantApplicationEditsModalDateOfBirth
                    preSelected={initialValue}
                    formRef={formRef}
                    indexPath={indexPath}
                    validation={formErrors[indexPathString]}
                    overBackground={isSelected ? 'floating.background' : defaultBackground}
                    onBlur={handleBlur}
                />
            )

        if (fieldDetails.field.type === 'number')
            return (
                <MerchantApplicationEditsModalInputNumber
                    key={indexPathString}
                    formRef={formRef}
                    validation={formErrors[indexPathString]}
                    indexPathString={indexPathString}
                    placeholder="-"
                    initialValue={initialValue}
                    forceValue={initialValue}
                    overBackground={isSelected ? 'floating.background' : defaultBackground}
                    cy="new-value"
                    onBlur={handleBlur}
                />
            )
        if (fieldDetails.field.type === 'multilineText')
            return (
                <TextareaInput
                    key={initialValue}
                    isSeamless
                    ref={(ref) => {
                        formRef(ref, indexPathString, ApplicationValidationRules.value)
                    }}
                    placeholder="-"
                    validation={formErrors[indexPathString]}
                    overBackground={isSelected ? 'floating.background' : defaultBackground}
                    initialValue={initialValue}
                    cy="new-value"
                    escKeyOnceIsEnough
                    onBlur={handleBlur}
                />
            )

        if (fieldDetails.field.type === 'url') {
            return (
                <>
                    <TextareaInput
                        overBackground={isSelected ? 'floating.background' : defaultBackground}
                        key={indexPathString}
                        placeholder="-"
                        initialValue={initialValue}
                        isSeamless
                        validation={formErrors[indexPathString]}
                        cy="new-value"
                        smallerSuperField={smallerSuperField}
                        superField={superField}
                        onBlur={handleBlur}
                        ref={(ref) => {
                            formRef(ref, indexPathString, ApplicationValidationRules.website, urlFormatter)
                        }}
                        noNewlines
                        escKeyOnceIsEnough
                    />
                    {/* TO-DO: add the wesite link */}
                    {/* <MerchantApplicationFieldValue rawValue={initialValue} indexPathString={indexPathString} /> */}
                </>
            )
        }

        return (
            <TextareaInput
                overBackground={isSelected ? 'floating.background' : defaultBackground}
                key={indexPathString}
                placeholder="-"
                initialValue={initialValue}
                isSeamless
                cy="new-value"
                onBlur={handleBlur}
                superField={superField}
                smallerSuperField={smallerSuperField}
                ref={fieldRef}
                validation={formErrors[indexPathString]}
                escKeyOnceIsEnough
                noNewlines
            />
        )
    }, [
        applicationId,
        formRef,
        initialValue,
        fieldRef,
        fieldDetails,
        superField,
        indexPath,
        handleBlur,
        smallerSuperField,
        isSelected,
        defaultBackground,
        formErrors,
        indexPathString
    ])

    return <FieldContainer data-prevent-blur-exit>{field}</FieldContainer>
}

const FieldContainer = styled.div`
    flex-grow: 1;
    width: 100%;
`
