import { throttle } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as yup from 'yup'

import { WatcherState } from '../../store/watcher/types'

export type ValidationStatus = string | undefined

let throttleFormUpdate: any
interface Field {
    name: string
    validationRules: yup.StringSchema
    listeners: any
    ref: any
}
export interface FormFieldFormatting {
    fromServer: (val: any) => any
    toServer: {
        beforeValidation?: (val: any) => any
        beforeSending?: (val: any) => any
    }
}
export interface FormState {
    data: { [key: string]: any }
    changes: {
        [key: string]: {
            from: string | boolean | number
            to: string | boolean | number
        }
    }
}
export function useForm(
    watcher: WatcherState,
    onFieldChange?: (changes: FormState['changes']) => void,
    changes?: FormState['changes'],
    validateOnlyUpdates?: boolean,
    focusOnMount?: boolean
): any {
    const fields = useRef<{
        [key: string]: Field
    }>({})
    const initialData = useRef<{ [key: string]: string | number | boolean | undefined }>({})
    const formatter = useRef<{ [key: string]: FormFieldFormatting | undefined }>({})
    const data = useRef<{ [key: string]: string | number | boolean | undefined }>({})
    const [form, setForm] = useState<FormState>({ data: {}, changes: {} })
    const [errors, setErrors] = useState<{ [key: string]: ValidationStatus }>({})
    const [formMounted, setFormMounted] = useState(false)
    const touched = useRef<{
        [key: string]: boolean
    }>({})
    const hasFocusedOnMount = useRef(false)

    const changeDelay = useRef<any>()
    const fieldCheck = useCallback(
        (name: string) => {
            if (validateOnlyUpdates && initialData.current[name] == data.current[name] && !touched.current[name]) return
            if (!fields.current[name]) return null
            try {
                fields.current[name].validationRules.validateSync(data.current[name], {
                    abortEarly: true
                })

                setErrors((e) => {
                    const newE = { ...e }
                    delete newE[name]
                    return newE
                })
            } catch (e) {
                setErrors((s) => {
                    return { ...s, [name]: (e as any).errors?.[0] || 'Unknown error' }
                })
            }
        },
        [setErrors, validateOnlyUpdates, touched]
    )

    useEffect(() => {
        if (form.changes) onFieldChange?.(form.changes)
    }, [onFieldChange, form])

    const buildLatestForm = useCallback(() => {
        const changes: any = {}

        Object.keys(data.current).forEach((key) => {
            if (!data.current[key] && !initialData.current[key]) return
            if (data.current[key] != initialData.current[key]) {
                changes[key] = {
                    from: initialData.current[key],
                    to: data.current[key]
                }
            }
        })

        const values: any = {}

        Object.keys(data.current).forEach((key) => {
            values[key] = data.current[key]
        })
        return { data: values, changes }
    }, [])

    const updateForm = useCallback(
        throttle(
            () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                setForm(buildLatestForm())
            },
            350,
            {
                leading: true,
                trailing: true
            }
        ),
        [buildLatestForm]
    )

    const formatDataBeforeSubmit = useCallback((form: FormState) => {
        const newForm = JSON.parse(JSON.stringify(form))

        Object.keys(newForm.changes).forEach((fId) => {
            const formatBeforeSending = formatter.current[fId]?.toServer.beforeSending
            if (!formatBeforeSending) return
            newForm.changes[fId].from = formatBeforeSending(newForm.changes[fId].from)
            newForm.changes[fId].to = formatBeforeSending(newForm.changes[fId].to)
        })

        Object.keys(newForm.data).forEach((fId) => {
            const formatBeforeSending = formatter.current[fId]?.toServer.beforeSending
            if (!formatBeforeSending) return
            newForm.data[fId] = formatBeforeSending(newForm.data[fId])
        })

        return newForm
    }, [])

    const evaluateForm = useCallback(() => {
        const schemaData: any = {}
        const values: any = {}

        Object.keys(fields.current).forEach((key) => {
            if (validateOnlyUpdates && initialData.current[key] == data.current[key]) return
            schemaData[key] = fields.current[key].validationRules
            values[key] = data.current[key]
        })
        const schema = yup.object(schemaData)

        try {
            schema.validateSync(values, {
                abortEarly: false
            })
            setErrors({})
            return true
        } catch (e) {
            const newE: { [key: string]: ValidationStatus } = {}
            ;(e as any)?.inner?.forEach((err: any) => {
                const fieldName = err.path.replace('["', '').replace('"]', '')

                newE[fieldName] = err.errors[0]
            })
            setErrors(newE)
        }
    }, [setErrors, validateOnlyUpdates])

    const submitHandler = useCallback(
        (callback: (formData: FormState) => void, skipFocus?: boolean) => {
            return () => {
                setForm((f) => {
                    const schemaData: any = {}
                    const values: any = {}

                    Object.keys(fields.current).forEach((key) => {
                        if (validateOnlyUpdates && initialData.current[key] == data.current[key]) return
                        schemaData[key] = fields.current[key].validationRules
                        values[key] = data.current[key]
                    })
                    const schema = yup.object(schemaData)

                    try {
                        schema.validateSync(values, {
                            abortEarly: false
                        })
                        setErrors({})
                        callback(formatDataBeforeSubmit(buildLatestForm()))
                    } catch (e) {
                        const newE: { [key: string]: ValidationStatus } = {}
                        let hasFocused = false
                        ;(e as any)?.inner?.forEach((err: any) => {
                            const fieldName = err.path.replace('["', '').replace('"]', '')

                            // eslint-disable-next-line prefer-destructuring
                            if (!skipFocus && !hasFocused) {
                                fields.current[fieldName].ref?.focus()
                                hasFocused = true
                            }
                            newE[fieldName] = err.errors[0]
                        })
                        setErrors(newE)
                    }

                    return f
                })
            }
        },
        [buildLatestForm, formatDataBeforeSubmit, validateOnlyUpdates, setErrors]
    )

    const focusInvalidField = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        submitHandler(() => {})()
        const key = Object.keys(errors)[0]
        if (key && errors[key]) fields.current[key]?.ref?.focus()
    }, [errors, submitHandler])

    const updateAndHighlightField = useCallback(
        (key: string, value?: string) => {
            const formattedValue = formatter.current[key]?.fromServer
                ? formatter.current[key]?.fromServer(value)
                : value
            if (!fields.current[key]?.ref) {
                data.current[key] = formattedValue
            } else if (data.current[key] !== formattedValue) {
                data.current[key] = formattedValue
                fields.current[key].ref.setFieldValue?.(formattedValue)
            }
            fieldCheck(key)
        },
        [fieldCheck]
    )

    const executeBatchChanges = useCallback(
        (changes: { [key: string]: string }) => {
            Object.keys(changes).forEach((key) => {
                updateAndHighlightField(key, changes[key])
            })
            updateForm()
        },
        [updateAndHighlightField, updateForm]
    )

    const resetForm = useCallback(() => {
        Object.keys(data.current).forEach((key) => {
            const formatBeforeSending = formatter.current[key]?.toServer.beforeSending
            updateAndHighlightField(
                key,
                formatBeforeSending ? formatBeforeSending(initialData.current[key] as any) : initialData.current[key]
            )
        })
        updateForm()
    }, [updateForm, updateAndHighlightField])

    const refreshForm = useCallback(() => {
        initialData.current = { ...form.data }
        data.current = { ...form.data }
        updateForm()
    }, [updateForm, form])

    useEffect(() => {
        if (watcher === 'success') refreshForm()
    }, [watcher, refreshForm])

    const onBlur = useCallback(
        (name: string) => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            evaluateForm()
            updateForm()
        },
        [updateForm, evaluateForm]
    )

    const onChange = useCallback(
        (name: string, e: any) => {
            const formatBeforeValidating = formatter.current[name]?.toServer.beforeValidation
            const newValue = formatBeforeValidating ? formatBeforeValidating(e?.detail?.value) : e?.detail.value
            touched.current[name] = true

            if (data.current[name] == newValue) return
            data.current[name] = newValue

            if (changeDelay.current) {
                clearTimeout(changeDelay.current)
                changeDelay.current = null
            }

            changeDelay.current = setTimeout(() => {
                if (fields.current[name]?.validationRules) {
                    fieldCheck(name)
                }
                updateForm()
            }, 300)
        },
        [updateForm, fieldCheck]
    )
    const onMounted = useCallback(
        (name: string, e: any) => {
            const formatServerValue = formatter.current[name]?.fromServer

            initialData.current[name] = formatServerValue
                ? formatServerValue(e?.detail?.value as any)
                : e?.detail?.value

            data.current[name] = initialData.current[name]

            if (formatServerValue && formatServerValue(e?.detail?.value as any) !== e?.detail?.value) {
                fields.current[name]?.ref?.setFieldValue?.(formatServerValue(e?.detail?.value), true)
            }
            if (throttleFormUpdate) {
                clearTimeout(throttleFormUpdate)
                throttleFormUpdate = undefined
            }

            throttleFormUpdate = setTimeout(() => {
                const keys = Object.keys(fields.current) || []
                if (focusOnMount && keys[0] && !hasFocusedOnMount.current) {
                    fields.current[keys[0]]?.ref?.focus()
                    hasFocusedOnMount.current = true
                }

                setFormMounted(true)
                updateForm()
            }, 150)
        },
        [updateForm, focusOnMount]
    )

    const formRef = useCallback(
        (node: any, name: string, validationRules: yup.Schema<any>, formatting?: FormFieldFormatting) => {
            if (!node) return

            if (fields.current?.[name]?.ref === node) return

            if (fields.current?.[name]?.listeners) {
                fields.current[name].ref.removeEventListener?.('fieldUpdate', fields.current[name].listeners.update)
                fields.current[name].ref.removeEventListener?.('fieldMounted', fields.current[name].listeners.mounted)
                fields.current[name].ref.removeEventListener?.('blur', fields.current[name].listeners.blur)
            }

            formatter.current[name] = formatting

            fields.current = {
                ...fields.current,
                [name]: {
                    name,
                    validationRules: validationRules as any,
                    listeners: {
                        update: node?.addEventListener?.('fieldUpdate', onChange.bind(null, name)),
                        mounted: node?.addEventListener?.('fieldMounted', onMounted.bind(null, name)),
                        blur: node?.addEventListener?.('blur', onBlur.bind(null, name))
                    },
                    ref: node
                }
            }
        },
        [onBlur, onChange, onMounted]
    )

    useEffect(() => {
        Object.keys(fields.current).forEach((key) => {
            if (fields.current[key].listeners.blur) {
                fields.current[key].ref.removeEventListener?.('blur', fields.current[key].listeners.blur)
                fields.current[key].listeners.blur = fields.current[key].ref.addEventListener?.(
                    'blur',
                    onBlur.bind(null, key)
                )
            }
        })
    }, [onBlur])

    useEffect(() => {
        Object.keys(fields.current).forEach((key) => {
            fields.current[key].ref.removeEventListener?.('fieldUpdate', fields.current[key].listeners.update)
            fields.current[key].listeners.update = fields.current[key].ref.addEventListener?.(
                'fieldUpdate',
                onChange.bind(null, key)
            )
        })
    }, [onChange])

    // This allows the form to inherit the state passed in the changes param, on mount
    useEffect(() => {
        if (!formMounted) return
        if (!changes) return

        Object.keys(changes).map((key) => {
            if (touched.current[key]) return
            if (!changes[key]) return
            initialData.current[key] = changes[key].from
            data.current[key] = changes[key].to
            const formatServerValue = formatter.current[key]?.fromServer
            fields.current[key]?.ref.setFieldValue?.(
                formatServerValue ? formatServerValue(data.current[key] as any) : data.current[key],
                true
            )
        })

        updateForm()
    }, [changes, updateForm, formMounted])

    const isDisabled = useMemo(() => {
        if (Object.keys(errors || {}).length) return true
        return false
    }, [errors])

    return {
        formRef,
        focusInvalidField,
        resetForm,
        form,
        formMounted,
        hasInitialised: formMounted,
        errors,
        isDisabled,
        touched: touched.current,
        refreshForm,
        hasChanges: Object.keys(form.changes).length > 0,
        executeBatchChanges,
        submitHandler,
        fields
    }
}
