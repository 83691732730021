import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('flex flex-col bg-neutral-100 grow dark:bg-gray-950', {
    variants: {
        pt: {
            normal: 'pt-4',
            none: 'pt-0'
        },
        pb: {
            normal: 'pb-4',
            none: 'pb-0'
        }
    }
})
