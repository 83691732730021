/* eslint-disable max-len */
import { startCase } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { FormHandler } from '../../../components/forms/formHandler'
import { List } from '../../../components/layout/list'
import { Spacer } from '../../../components/layout/spacer'
import { ModalPage } from '../../../components/layout/modalPage'
import { ModalPageInset } from '../../../components/layout/modalPageInset'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { useForm } from '../../../hooks/general/useForm'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../../utils'
import moment from 'moment'

type Params = { id: string; accountId: string; apiKeyId: string }

import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { ToastsDispatchPush } from '../../../store/toasts/actions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
const textForFieldName = (k: string) => uppercaseFirstLetter(k)
const textForChange = (f: any, val: any) => val
const YESNO = [true, false]
export const MerchantAPIKey: React.FC = () => {
    const params = useParams() as Params

    const { id, accountId, apiKeyId } = params
    const dispatch = useDispatch()
    const location = useLocation()
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])
    const [watcher, watcherId] = useNamedWatcher(`${accountId}.SetSettlementMethod`)
    const navigate = useNavigate()
    useEffect(() => {
        if (watcher === 'success')
            navigate({
                pathname: `/merchant/${id}/accounts/${accountId}`,
                search: location.search
            })
    }, [navigate, id, watcher, location, accountId])

    const apiKey = useSelector(
        (state: RootState) =>
            state.merchantAccounts.at?.[accountId]?.apiKeys?.filter((key) => key.token === decodeURI(apiKeyId))?.[0]
    )

    const isCreatingNew = useMemo(() => {
        if (apiKeyId === 'new') return true
        return false
    }, [apiKeyId])

    const { form, errors, formRef, submitHandler, resetForm, focusInvalidField } = useForm(watcher)

    useEffect(() => {
        if (!accountId) return
        if (!account) {
            dispatch(MerchantAccountsActions.FETCH_ACCOUNT(accountId))
            return
        }
        if (account.loadingStatus !== 'started' && account.loadingStatus !== 'done')
            dispatch(MerchantAccountsActions.FETCH_ACCOUNT(accountId))
    }, [account, accountId, dispatch])

    const onSubmit = useCallback(
        (form: any) => {
            const { expiresAt, enabled } = form.data
            if (isCreatingNew) dispatch(MerchantAccountsActions.CREATE_API_KEY(accountId, watcherId, expiresAt))
            else if (!apiKey) dispatch(ToastsDispatchPush("Couldn't find API KEY", apiKey))
            else
                dispatch(
                    MerchantAccountsActions.UPDATE_API_KEY(
                        accountId,
                        watcherId,
                        apiKey.selfLink,
                        enabled,
                        expiresAt
                    )
                )
        },
        [isCreatingNew, dispatch, accountId, watcherId, apiKey]
    )

    const renderedFooter = useMemo(() => {
        if (isCreatingNew)
            return (
                <WatcherButton
                    isDisabled={Object.keys(form.changes)?.length === 0}
                    background="front.accent.color"
                    grow
                    hotkeysScope="Merchant.AccountsPage.Account.NewAPIKey"
                    hotkeys="alt+enter"
                    predefinedWatcher={watcherId}
                    onClick={submitHandler(onSubmit)}
                >
                    <ButtonInset>Create key</ButtonInset>
                </WatcherButton>
            )

        return (
            <Flex column align="flex-end">
                <FormHandler
                    formSubmitLabel={isCreatingNew ? 'Create key' : 'Update key'}
                    changes={form.changes}
                    errors={errors}
                    textForFieldName={textForFieldName}
                    focusInvalidField={focusInvalidField}
                    textForChange={textForChange}
                    submitForm={submitHandler(onSubmit)}
                    submitWatcherId={watcherId}
                    resetForm={resetForm}
                />
            </Flex>
        )
    }, [errors, focusInvalidField, form.changes, isCreatingNew, onSubmit, resetForm, submitHandler, watcherId])

    const name = useMerchantName(id, `New API Key`)
    const expireDate = useMemo(() => {
        return apiKey?.expiresAt ? moment(apiKey?.expiresAt).toDate() : undefined
    }, [apiKey])

    return (
        <ModalPage
            title={name}
            pageId="Merchant.AccountsPage.Account.NewAPIKey"
            isLoading={accountId && accountId !== 'new' ? account?.loadingStatus !== 'done' : false}
        >
            <ModalHeader
                title="New API key"
                pageId="Merchant.AccountsPage.Account.NewAPIKey"
                backTo={`/merchant/${id}/accounts/${accountId}`}
                keepBackTo
            />
            <ModalPageInset>
                <Flex justify="center" align="center" grow column>
                    <Card higher title={isCreatingNew ? 'Create a new API Key' : `Editing API Key ${apiKey?.token}`}>
                        <CardInset>
                            <Spacer height={15} />
                            <List
                                background="front.background"
                                items={{
                                    'Expire on': {
                                        type: 'dateSelect',
                                        overBackground: 'front.background',
                                        preselectedDate: expireDate,
                                        isSeamless: true,
                                        unBlankable: !!expireDate,
                                        cy: 'expireOn',
                                        ref: (ref) => formRef(ref, 'expiresAt', yup.string())
                                    },
                                    ...(isCreatingNew
                                        ? {}
                                        : {
                                              Enabled: {
                                                  type: 'select' as const,
                                                  placeholder: '-',
                                                  overBackground: 'front.background' as const,
                                                  items: YESNO,
                                                  isQuestion: true,
                                                  selected: apiKey?.enabled,
                                                  dropdownId: 'Merchant.AccountsPage.Account.APIKey.Enabled',
                                                  textForItem: enabledTextForItem,
                                                  validation: errors.enabled,
                                                  ref: (ref: any) => formRef(ref, 'enabled', yup.boolean())
                                              }
                                          })
                                }}
                                switchToRowsAt={10000}
                                cellHorizontalTemplate="120px minmax(auto, 200px)"
                            />
                            <Spacer height={22} />
                            <Flex justify="flex-end">{renderedFooter}</Flex>
                        </CardInset>
                    </Card>
                    <Spacer height={120} />
                </Flex>
            </ModalPageInset>
        </ModalPage>
    )
}

const enabledTextForItem = (item: boolean) => {
    if (item) return 'Yes'
    return 'No, not enabled'
}
