import styled, { css } from 'styled-components'

export const PageContent = styled.div<{
    marginLeft?: number
    noPadding?: boolean
    scrollX?: boolean
    noVerticalPadding?: boolean
    containOverflow?: boolean
    topOffset?: number
}>`
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.theme['back.background']};
    flex-grow: 1;
    padding: 0 30px;
    width: 100%;
    box-sizing: border-box;
    min-width: 0;
    min-height: ${(p) => (p.topOffset ? `calc(100vh - ${p.topOffset}px)` : `100vh`)};

    @media print {
        background-color: #fff !important;
    }

    ${(p) =>
        p.noVerticalPadding &&
        css`
            padding-top: 0;
            padding-bottom: 0;
        `}

    ${(p) =>
        p.scrollX &&
        css`
            overflow-x: auto;
        `}

    ${(p) =>
        p.noPadding &&
        css`
            padding: 0;
        `}
    
    ${(p) =>
        p.containOverflow &&
        css`
            overflow-y: scroll;
            overscroll-behavior-y: none;
            height: ${p.topOffset ? `calc(100vh - ${p.topOffset}px)` : `100vh`};
        `}
`

// margin-left: ${(p) => p.marginLeft}px;
// ${(p) =>
//     p.marginLeft &&
//     css`
//         width: calc(100% - ${p.marginLeft}px);
//     `}
// min-height: 100vh;
