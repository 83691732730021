import { ActionType } from 'deox'
import { put } from 'redux-saga/effects'
import { select } from 'typed-redux-saga'

import { DELETE, GET, PATCH, POST, PUT } from '../../../generators/networking'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../../toasts/actions'
import { WatcherDispatchFail } from '../../watcher/actions'
import { ApplicationInternalsTagsActions } from './actions'

export const ApplicationInternalsTagsSagas = {
    *FETCH({ payload: p }: ActionType<typeof ApplicationInternalsTagsActions.FETCH>) {
        const response = yield* GET({
            mapiUrl: (l) => l.tagsLink,
            include: ['self'],
            onSuccessDispatch: (r) => ApplicationInternalsTagsActions.STORE(r.tags),
            errorText: 'Failed to fetch Application Tags.'
        })
    },
    *FETCH_APPLICATION_TAGS({ payload: p }: ActionType<typeof ApplicationInternalsTagsActions.FETCH_APPLICATION_TAGS>) {
        const response = yield* GET({
            url: p.tagsLink,
            include: ['self', 'selection'],
            errorText: 'Failed to fetch Application Tags.'
        })

        if (!response) {
            yield ToastsDispatchPush('Failed to retrieve application tags', 'error')
        }
        yield put(
            ApplicationInternalsTagsActions.STORE_APPLICATION_TAGS(
                p.applicationId,
                response?.selectable,
                response?.tags
            )
        )
    },
    *ASSIGN({ payload: p }: ActionType<typeof ApplicationInternalsTagsActions.ASSIGN>) {
        yield PUT({
            url: p.tag.selectionLink,
            watcher: p.watcherId,
            include: ['self', 'selection'],
            successText: 'Tag assigned successfully.',
            errorText: 'Failed to assign tag.',
            onSuccessDispatch: (r: any) =>
                ApplicationInternalsTagsActions.STORE_APPLICATION_TAGS(p.applicationId, r.selectable, r.tags)
        })
    },
    *UNASSIGN({ payload: p }: ActionType<typeof ApplicationInternalsTagsActions.UNASSIGN>) {
        yield DELETE({
            url: p.tag.selectionLink,
            watcher: p.watcherId,
            include: ['self', 'selection'],
            successCode: 200,
            successText: 'Tag unassigned successfully.',
            errorText: 'Failed to unassign tag.',
            onSuccessDispatch: (r: any) =>
                ApplicationInternalsTagsActions.STORE_APPLICATION_TAGS(p.applicationId, r.selectable, r.tags)
        })
    },
    *CREATE({ payload: p }: ActionType<typeof ApplicationInternalsTagsActions.CREATE>) {
        const tagsLink = yield* select((state: RootState) => state.global.links?.mapi?.tagsLink)
        const applicationTagsLink = yield* select(
            (state: RootState) => state.applications.applications?.at?.[p.applicationId]?.tagsLink
        )

        if (!tagsLink) {
            yield put(WatcherDispatchFail([p.watcherId], 'Missing tags link from the MAPI'))
        }

        yield POST({
            url: tagsLink,
            watcher: p.watcherId,
            successCode: 200,
            include: ['self', 'selection'],
            onSuccessDispatch: () =>
                ApplicationInternalsTagsActions.FETCH_APPLICATION_TAGS(p.applicationId, applicationTagsLink),
            successText: 'Tag created successfully.',
            body: {
                name: p.newTag.name,
                color: p.newTag.color.replace('#', '').toLowerCase(),
                visible: p.newTag.visible
            },
            errorText: 'Failed to create tag.'
        })
    },
    *MODIFY({ payload: p }: ActionType<typeof ApplicationInternalsTagsActions.MODIFY>) {
        const { success, cleanedResponse } = yield PATCH({
            url: p.tagLink,
            watcher: p.watcherId,
            include: ['self', 'selection'],
            onSuccessDispatch: (r) => ApplicationInternalsTagsActions.STORE_SINGLE(p.applicationId, r),
            body: {
                name: p.newTag.name,
                color: p.newTag.color.replace('#', '').toLowerCase(),
                visible: p.newTag.visible
            },
            skipErrorCode: [400],
            successText: 'Tag modified.'
        })

        if (!success) {
            if (cleanedResponse?.errors?.name[0] === 'is immutable when tag is in use') {
                yield put(
                    ToastsDispatchPush(
                        'Editing failed. Unassign tag from application, then try editing it again.',
                        'error',
                        undefined,
                        'longer'
                    )
                )
            } else {
                yield put(
                    ToastsDispatchPush(
                        'Failed to modify the tag.',
                        undefined,
                        undefined,
                        'shorter',
                        JSON.stringify(cleanedResponse)
                    )
                )
            }
        }
    }
}
