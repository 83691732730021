import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva('inline-flex items-center rounded-md border px-1.5 py-0 text-md', {
    variants: {
        variant: {
            default: 'border-transparent'
        },
        color: {
            primary: '',
            neutral: '',
            success: '',
            warning: '',
            danger: '',
            notice: ''
        }
    },
    compoundVariants: [
        {
            variant: 'default',
            color: 'primary',
            class: 'bg-primary-100 text-primary-600 dark:bg-primary-100/5'
        },
        {
            variant: 'default',
            color: 'neutral',
            class: 'bg-neutral-200 text-neutral-800 dark:bg-neutral-200/5 dark:text-neutral-200'
        },
        {
            variant: 'default',
            color: 'success',
            class: 'bg-success-100 text-success-600'
        },
        {
            variant: 'default',
            color: 'warning',
            class: 'bg-warning-100 text-warning-60'
        },
        {
            variant: 'default',
            color: 'danger',
            class: 'bg-danger-100 text-danger-600'
        },
        {
            variant: 'default',
            color: 'notice',
            class: 'bg-notice-50 text-notice-700'
        }
    ],
    defaultVariants: {
        variant: 'default',
        color: 'primary'
    }
})
