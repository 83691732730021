import { ReactElement } from 'react'
import { List } from "../layout/list"
import { MODAL_ID } from "../modals/modalIds"
import { Filters } from "./filters"
import { FiltersInfo } from "./filtersResultsInfo"
import { FiltersController } from '@/hooks/pages/useListPage'

export function RenderFilters<T>({
    filters,
    summaries,
    hotkeysScope,
    items,
    entityName,
    cellHorizontalTemplate,
    inline
}: {
    filters: FiltersController<T>
    summaries: any
    hotkeysScope: MODAL_ID
    items: any
    entityName: FiltersInfo
    cellHorizontalTemplate: string
    inline?: boolean
}): ReactElement {
    return (
        <Filters
            showHideController={filters.showHideController}
            filters={filters}
            resultsCount={summaries.all.length}
            entityName={entityName}
            pagination={summaries.pagination}
            key="allFilters"
            hotkeysScope={hotkeysScope}
            inline={inline}
        >
            <List
                background="floating.background"
                items={items}
                emphasizeLabels
                template="auto"
                switchToRowsAt={100000}
                cellHorizontalTemplate={cellHorizontalTemplate}
            />
        </Filters>
    )
}
