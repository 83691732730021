import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { WatcherState } from '../../store/watcher/types'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { ClueDirection } from '../hotkeys/hotkeyClue'
import { Color } from '../../styles/colors'
import { generateButtonStyles } from '../../utils/buttonStyles'
import { Tooltip } from '../general/tooltip'
import { Theme } from '../../hooks/general/useTheme'

export function SimpleButton({
    children,
    background,
    grow,
    defaultColor,
    color,
    round,
    onClick,
    watcher,
    isPressed,
    hotkeysScope,
    opacity,
    hotkeys,
    inline,
    isDisabled,
    buttonRef,
    tooltip,
    tabIndex,
    className,
    hotkeysClueDirection,
    cy
}: {
    children: any
    onClick?: (e: any) => void
    background?: Color
    color?: Color
    round?: boolean
    isPressed?: boolean
    watcher?: WatcherState
    isDisabled?: boolean
    inline?: boolean
    grow?: boolean
    opacity?: boolean
    tooltip?: string
    className?: string
    defaultColor?: keyof Theme
    buttonRef?: (ref: MutableRefObject<any>) => void
    hotkeysScope?: MODAL_ID
    hotkeys?: string
    hotkeysClueDirection?: ClueDirection
    tabIndex?: number
    cy?: string
}): JSX.Element {
    const [ref, setRef] = useRefTaker()
    const tooltipRef = useRef<any>()

    useEffect(() => {
        buttonRef?.(ref)
    }, [buttonRef, ref])

    const clueElement = useHotkey({
        scope: hotkeysScope,
        keys: hotkeys,
        clue: hotkeysClueDirection,
        action: useCallback(() => {
            if (ref) ref.click()
            else onClick?.(undefined)
        }, [onClick, ref])
    })

    const handleClick = useCallback(
        (e) => {
            if (watcher === 'started') return
            if (isDisabled) return
            if (isPressed) return
            onClick?.(e)
        },
        [watcher, onClick, isPressed, isDisabled]
    )

    return (
        <>
            {tooltip && (
                <Tooltip element={tooltipRef} tooltip tooltipTiming="instant">
                    {tooltip}
                </Tooltip>
            )}
            <ButtonHolder ref={tooltipRef}>
                <Button
                    inline={inline}
                    defaultColor={defaultColor}
                    tabIndex={tabIndex === undefined ? -1 : tabIndex}
                    ref={setRef}
                    opacity={opacity}
                    styles={[isDisabled ? 'disabled' : color]}
                    grow={grow}
                    disabled={isDisabled}
                    isPressed={isPressed}
                    className={className}
                    round={round}
                    onClick={handleClick}
                    data-cy={cy}
                    background={background}
                    color={color}
                >
                    {children}
                    {clueElement}
                </Button>
            </ButtonHolder>
        </>
    )
}

const ButtonHolder = styled.div`
    display: contents;
`

export const Button = styled.button<{
    styles?: any
    grow?: boolean
    round?: boolean
    isPressed?: boolean
    background?: Color
    tabIndex?: number
    disabled?: boolean
    opacity?: boolean
    inline?: boolean
    color?: Color
    defaultColor?: keyof Theme
}>`
    ${(p) => generateButtonStyles(p.background, p.color, p.disabled, p.isPressed, p.tabIndex)}
    position: relative;
    user-select: none;
    border: none;
    outline: none;
    max-width: 100%;
    padding: 0;

    ${(p) =>
        p.inline &&
        css`
            display: inline-flex;
        `}
    ${(p) =>
        p.round &&
        css`
            border-radius: 999px;
        `}

    ${(p) =>
        p.grow &&
        css`
            flex-grow: 1;
            flex-basis: 0;
        `}

    ${(p) =>
        p.disabled &&
        css`
            cursor: not-allowed;
        `}

    &:focus {
        outline: 2px solid yellow;
    }

    ${(p) =>
        p.opacity &&
        css`
            &:hover {
                opacity: 0.8;
            }
            &:active {
                opacity: 0.6;
            }
        `}

    ${(p) =>
        p.defaultColor &&
        css`
            color: ${p.theme[p.defaultColor]};
        `}
`

// ${(p) =>
//     p.isPressed &&
//     css`
//         color:.whiteBackground.text.subtle};
//         background-color: rgba(0, 0, 0, 0.05);
//         cursor: default;

//         &:hover,
//         &:active {
//             color: $whiteBackground.text.subtle};
//             background-color: rgba(0, 0, 0, 0.05);
//         }
//     `}
