import { useMemo } from 'react'
import styled from 'styled-components'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { MerchantApplicationField } from './Application.Field'
import { MerchantApplicationSectionGrid } from './Application.SectionGrid'
import { DedicatedSectionProps, useDedicatedSection } from './Application.DedicatedSection'
import { MerchantApplicationInternalDetails } from './Application.InternalDetails'
import { MerchantApplicationSectionCheckmark } from './Application.SectionCheckmark'
import { GenerateIndexPathForSection } from './Application.Structure'
import { useHasApplicationSectionLoaded } from './useHasApplicationSectionLoaded'
import { ApplicationDedicatedInternalSectionWrapper } from './Application.DedicatedInternalSectionWrapper'

export const MerchantApplicationDedicateSectionOwnership: React.FC<DedicatedSectionProps> = ({
    errors,
    executeBatchChanges,
    applicationId,
    formRef
}) => {
    const { structure, field } = useDedicatedSection(applicationId, 'Ownership')
    const hasLoaded = useHasApplicationSectionLoaded(applicationId, 'company', 'data')
    const indexPath = useMemo(() => {
        return GenerateIndexPathForSection(field)
    }, [field])

    return (
        <>
            <Holder data-cy="ownership" id="ownership">
                {structure.reviewableResource && (
                    <MerchantApplicationSectionCheckmark
                        applicationId={applicationId}
                        resource={structure.reviewableResource}
                    />
                )}
                <Card showEditable grow>
                    <CardInset>
                        <MerchantApplicationSectionGrid>
                            <MerchantApplicationField
                                executeBatchChanges={executeBatchChanges}
                                // highlighted={getHighlightTypeForField(subsectionStructure.fields, i, isHighlighted)}
                                isLoading={!hasLoaded}
                                indexPath={indexPath}
                                formErrors={errors}
                                applicationId={applicationId}
                                formRef={formRef}
                                isFirst={false}
                                type={field.type}
                                label={field.label}
                            />
                        </MerchantApplicationSectionGrid>
                    </CardInset>
                </Card>
            </Holder>
            <Flex column align="stretch">
                <ApplicationDedicatedInternalSectionWrapper>
                    <MerchantApplicationInternalDetails sectionStructure={structure} applicationId={applicationId} />
                </ApplicationDedicatedInternalSectionWrapper>
            </Flex>
        </>
    )
}

const Holder = styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
`
