import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkButton } from '../../components/buttons/linkButton'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { LoaderView } from '../../components/loaders/loader'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { TextInput } from '../../components/forms/textInput'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { ApplicationInternalTag } from '../../store/applicationInternals/tags/types'
import { RootState } from '@/store'
import { MerchantTag } from './MerchantTag'
import styled, { css } from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { ApplicationInternalsTagsActions } from '../../store/applicationInternals/tags/actions'
import { useParams, useLocation } from 'react-router-dom'

export const MerchantTagsManagerList: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const location = useLocation()
    const [inputRef, setInputRef] = useRefTaker()
    const [filter, setFilter] = useState('')
    const loadingStatus = useSelector((state: RootState) => {
        if (id) {
            return state.applicationInternals.tags.forApplication?.[id]?.loadingStatus
        }
    })
    const atTags = useSelector((state: RootState) => {
        if (id) {
            return state.applicationInternals.tags.forApplication?.[id]?.at
        }
    })
    const allTags = useSelector((state: RootState) => {
        if (id) {
            return state.applicationInternals.tags.forApplication?.[id]?.all || []
        }
    })
    const selectedTags = useSelector((state: RootState) => {
        if (id) {
            return state.applicationInternals.tags.forApplication?.[id]?.selected || []
        }
    })

    const tags = useMemo(() => {
        if (!allTags || !atTags) return []

        const sortedTags = [...allTags].filter((tId) => atTags[tId].name.toLowerCase().includes(filter.toLowerCase()))
        sortedTags.sort((a, b) => (atTags[a].name > atTags[b].name ? -1 : 1))
        sortedTags.sort((a) => (atTags[a].visible ? -1 : 1))

        return sortedTags
    }, [allTags, atTags, filter])

    useEffect(() => {
        inputRef?.focus()
    }, [inputRef])

    const onAssignClick = useCallback(
        (watcherId: string, t: ApplicationInternalTag, isSelected: boolean) => {
            if (!watcherId) return
            if (isSelected) {
                if (id) {
                    dispatch(ApplicationInternalsTagsActions.UNASSIGN(watcherId, id, t))
                }
            } else {
                if (id) {
                    dispatch(ApplicationInternalsTagsActions.ASSIGN(watcherId, id, t))
                }
            }
        },
        [dispatch, id]
    )

    const handleSearch = useCallback(
        (e, value: string) => {
            setFilter(value)
        },
        [setFilter]
    )

    const renderedTagsList = useMemo(() => {
        if (loadingStatus !== 'done') return <LoaderView overBackground="front.background" />
        return (
            <>
                <TextInput
                    overBackground="front.background"
                    placeholder="Search through tags"
                    ref={setInputRef}
                    onChange={handleSearch}
                    escKeyOnceIsEnough
                    cy="filter-tags"
                />
                <Spacer height={20} />
                <Table
                    background="front.background"
                    style={{ flexShrink: 0, marginTop: 20 }}
                    rows={
                        atTags
                            ? tags
                                  .filter((tagId) => atTags[tagId])
                                  .map((tagId: any) => {
                                      const t = atTags[tagId]
                                      const isSelected = selectedTags ? selectedTags.includes(t.id) : false

                                      return {
                                          type: 'normal',
                                          key: t.id,
                                          noAction: true,
                                          items: [
                                              {
                                                  node: (
                                                      <Flex align="center">
                                                          <Opacifier visible={t.visible}>
                                                              <MerchantTag name={t.name} />
                                                          </Opacifier>
                                                      </Flex>
                                                  )
                                              },
                                              {
                                                  node: (
                                                      <Flex>
                                                          <LinkButton
                                                              to={{
                                                                  pathname: `${t.id}`,
                                                                  search: location.search
                                                              }}
                                                              state={location.state}
                                                              cy="edit"
                                                          >
                                                              <ButtonInset padding="tiny" noHorizontalPadding>
                                                                  Edit
                                                              </ButtonInset>
                                                          </LinkButton>
                                                          <Spacer width={10} />
                                                          <WatcherButton
                                                              onClick={(e, generatedWatcher) => {
                                                                  onAssignClick(generatedWatcher, t, isSelected)
                                                              }}
                                                              cy={isSelected ? 'unassign' : 'assign'}
                                                              background={
                                                                  isSelected
                                                                      ? 'front.accent.color'
                                                                      : 'front.subtleAccent.background'
                                                              }
                                                          >
                                                              <ButtonInset padding="tiny">
                                                                  {isSelected ? 'Unassign' : 'Assign'}
                                                              </ButtonInset>
                                                          </WatcherButton>
                                                      </Flex>
                                                  )
                                              }
                                          ]
                                      }
                                  })
                            : []
                    }
                    cols={[{ text: 'Tag name' }, { text: 'Actions', alignRight: true }]}
                    columnLayout="minmax(250px, 1fr) min-content"
                    displayLoader={loadingStatus !== 'done'}
                />
            </>
        )
    }, [setInputRef, handleSearch, tags, atTags, loadingStatus, selectedTags, location, onAssignClick])

    return (
        <PageWrapper maxWidth="small">
            <Card higher>
                <CardInset>{renderedTagsList}</CardInset>
            </Card>
        </PageWrapper>
    )
}

const Opacifier = styled.div<{ visible?: boolean }>`
    ${(p) =>
        p.visible
            ? css`
                  opacity: 1;
              `
            : css`
                  opacity: 0.5;
              `}
`
