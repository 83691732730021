import { camelCase } from 'lodash'
import React, { ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { DynamicTableRow } from './dynamicTableRow'
import { ExternalLinkTargets } from '../buttons/externalLink'
import { Flex } from '../layout/flex'
import { Text } from '../general/text'
import { Theme } from '../../hooks/general/useTheme'
import { Color } from '../../styles/colors'

export type TableRowType = 'subtle' | 'normal' | 'emphasized'

export interface TableRowItem {
    extraStyles?: React.CSSProperties
    wrap?: boolean
    span?: number
    node: React.ReactNode
}

export interface TableColHeader {
    text: string
    alignRight?: boolean
    key?: string
    loaderSize?: {
        min: number
        max: number
    }
    emphasized?: boolean
}

export interface TableRow {
    type: TableRowType
    link?: string
    key: string
    cy?: string
    ref?: React.RefObject<HTMLDivElement>
    attributes?: { [key: string]: any }
    linkTarget?: ExternalLinkTargets
    linkState?: any
    items: TableRowItem[]
    noAction?: boolean
}

export interface TableColors {
    active: string
    hover: string
    border: string
}

export type TableType = 'normal' | 'narrow'

export interface TableProps {
    rows: TableRow[]
    cols: TableColHeader[]
    hoverColor?: string
    cy?: string
    verticalAlignTop?: boolean
    noHeader?: boolean
    displayLoader?: boolean
    loaderRows?: number
    keepQuery?: boolean
    colors?: {
        hover: string
        border: string
    }
    loaderStyles?: React.CSSProperties
    type?: TableType
    sortedBy?: {
        column: string
        direction: 'asc' | 'desc'
    }
    style?: React.CSSProperties
    emptyText?: string
    overrideText?: string
    sortExceptedColumn?: string[]
    selectedRow?: number
    noHeaderSeparator?: boolean
    bordered?: boolean
    borderColor?: string
    showLastRowBorder?: boolean
    noWidth?: boolean
    wrap?: boolean
    columnsGap?: number
    minRowHeight?: number
    noHighlights?: boolean
    background: keyof Theme
    onColumnHeaderClicked?: (cKey: string) => void
    onRowClick?: () => void
    columnLayout: React.CSSProperties['gridTemplateColumns']
}

export const Table: React.FC<TableProps> = (p) => {
    const headerCell = useMemo(() => {
        return p.cols.map((c, i) => (
            <Flex align="center" key={c.text}>
                {(() => {
                    if (p.noHeader) return null
                    return <HeaderText>{c.text}</HeaderText>
                })()}
                {c.key && p.sortedBy?.column && camelCase(p.sortedBy?.column) == c.key ? (
                    <TableHeadingSortArrow sorted={p.sortedBy.direction} />
                ) : undefined}
                <HeadingSpacer />
            </Flex>
        ))
    }, [p.cols, p.noHeader, p.sortedBy])

    const renderedHeaderRow = useMemo(() => {
        const onHeaderClick = (c: any) => {
            if (!p.onColumnHeaderClicked) return
            if (!c.key) throw Error('Column has no key')
            if (c.key && !p.sortExceptedColumn?.includes(c.key)) p.onColumnHeaderClicked?.(c.key)
        }

        if (p.noHeader) return null

        return (
            <TableHeaderRow>
                {p.cols.map((c, i) => (
                    <TableHeading
                        noHeaderSeparator={p.noHeaderSeparator}
                        key={camelCase(c.text ? c.text : c.key ? c.key : `col${i}`)}
                        tableType={p.type}
                        alignRight={c.alignRight}
                        onClick={() => {
                            onHeaderClick(c)
                        }}
                        clickable={p.onColumnHeaderClicked && c.key && !p.sortExceptedColumn?.includes(c.key)}
                        sorted={
                            c.key && p.sortedBy?.column && camelCase(p.sortedBy?.column) == c.key
                                ? p.sortedBy.direction
                                : undefined
                        }
                    >
                        {headerCell[i]}
                    </TableHeading>
                ))}
            </TableHeaderRow>
        )
    }, [p, headerCell])

    const renderedRows = useMemo(() => {
        if (p.loaderRows === 0)
            return (
                <EmptyRow span={p.cols.length + 1} tableType={p.type}>
                    <Text color="front.text.subtlerI">{p.overrideText ? p.overrideText : 'No items found.'}</Text>
                </EmptyRow>
            )

        if (p.displayLoader) {
            const rows: ReactNode[] = []
            for (let i = 0; i < (p.loaderRows || 0); i++)
                rows.push(<DynamicTableRow p={p} key={`loading${i}`} isLoading />)
            return (
                <>
                    {renderedHeaderRow}
                    {rows}
                </>
            )
        }

        if (p.rows?.length === 0)
            return (
                <EmptyRow span={p.cols.length + 1} tableType={p.type}>
                    <Text color="front.text.subtlerI">{p.overrideText ? p.overrideText : 'No items found.'}</Text>
                </EmptyRow>
            )

        return (
            <>
                {renderedHeaderRow}
                {p.rows?.map((r, i) => {
                    const c = p.cols[i]
                    return (
                        <DynamicTableRow
                            key={c?.text ? c.text : c?.key ? c.key : `col${i}`}
                            p={p}
                            isSelected={p.selectedRow === i}
                            r={r}
                            onClick={p.onRowClick}
                        />
                    )
                })}
            </>
        )
    }, [p])

    //p.displayLoader
    // if (true) return
    return (
        <TableElement
            data-cy={p.cy}
            key="table"
            noWidth={p.noWidth}
            style={{ ...p.style, gridTemplateColumns: p.columnLayout }}
        >
            {renderedRows}
        </TableElement>
    )
}

const EmptyRow = styled.div<{ span: number; tableType?: TableType }>`
    grid-column: span ${(p) => p.span || 0};
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    margin-top: -7px;

    ${(p) =>
        p.tableType === 'narrow' &&
        css`
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 12px;
            min-height: 0px;
            margin-top: -13px;
        `}
`

const LoaderOverlay = styled.div<{ background: React.ComponentProps<typeof Table>['background'] }>`
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    background-color: ${(p) => p.theme[p.background]};
    display: flex;
    align-items: center;
    justify-content: center;
`

const TableElement = styled.div<{ noWidth?: boolean }>`
    display: grid;
    width: calc(100% + 24px);
    margin: 0 -12px;
    position: relative;

    ${(p) =>
        p.noWidth &&
        css`
            margin: 0;
            width: auto;
        `}
    @media print {
        margin: 0;
        width: 100%;
    }
`

const NoItemsTextHolder = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    position: relative;
    padding-bottom: 10px;
`

const HeadingSpacer = styled.div`
    width: 20px;
    height: 100%;
    flex-basis: 20px;
    flex-shrink: 0;
    /* @media (min-width: 1800px) {
        flex-basis: 40px;
        width: 40px;
    } */
`

export const TableHeadingSortArrow = styled.div<{ sorted?: 'asc' | 'desc' }>`
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    height: 3px;
    width: 3px;
    position: relative;
    top: -6px;
    right: -5px;
    transform: rotateZ(45deg);

    ${(p) =>
        !p.sorted &&
        css`
            display: none;
        `}
    ${(p) =>
        p.sorted == 'asc' &&
        css`
            transform: rotateZ(-135deg);
            top: -4px;
        `}
`

export const TableHeading = styled.div<{
    tableType?: TableType
    alignRight?: boolean
    clickable?: any
    noHeaderSeparator?: boolean
    sorted?: 'asc' | 'desc'
}>`
    color: ${(p) => p.theme['front.text.subtlerI']};
    padding-bottom: 5px;
    text-align: left;
    white-space: nowrap;
    position: relative;

    &:last-child:before {
        width: calc(100% - 12px);

        @media print {
            width: 100%;
        }
    }

    &:first-child:before {
        left: 12px;
        width: calc(100% - 12px);

        @media print {
            width: 100%;
        }
    }

    @media print {
        color: ${(p) => p.theme['back.text.subtlerI']};
        padding: 0 20px !important;
    }

    ${(p) =>
        p.alignRight &&
        css`
            text-align: right;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
        `}

    ${(p) =>
        p.tableType === 'narrow' &&
        css`
            padding-bottom: 2px;
        `}

    ${(p) =>
        p.clickable &&
        css`
            user-select: none;
            &:hover {
                color: ${(p) => p.theme['front.text']};
                cursor: pointer;
            }
        `}
    ${(p) =>
        (p.sorted === 'asc' || p.sorted === 'desc') &&
        css`
            font-weight: 500;
            color: ${(p) => p.theme['front.text']};
        `}
        

    ${(p) =>
        p.noHeaderSeparator &&
        css`
            padding-bottom: 0;
            &:before {
                display: none;
            }
        `}
    
    &:last-child {
        padding-right: 12px;
        width: calc(100% - 12px);
        @media print {
            padding-right: 0 !important;
        }

        ${HeadingSpacer} {
            display: none;
        }
    }

    &:first-child {
        padding-left: 12px;
        @media print {
            padding-left: 0 !important;
        }
    }
`

const TableHeaderRow = styled.div`
    display: contents;
`

// ${(p) =>
//     p.alignRight &&
//     css`
//         padding-right: 0;
//     `}

// &:before {
//     position: absolute;
//     bottom: -1px;
//     content: '';
//     left: 0;
//     background-color: ${(p) => p.theme['back.background.subtlerI']};
//     height: 1px;
//     width: 100%;

//     @media print {
//         display: none;
//     }
// }

export const HeaderText = styled.div<{ noMargin?: boolean; color?: Color }>`
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 500;
    margin-top: -7px;
    opacity: 0.8;
    margin-bottom: 2px;
    letter-spacing: 0.2px;
    line-height: 10px;

    ${(p) =>
        p.noMargin &&
        css`
            margin: 0;
        `}

    ${(p) =>
        p.color &&
        css`
            color: ${p.theme[p.color]};
        `}
`
