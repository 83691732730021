import { cva } from 'class-variance-authority'

export const styles = cva('', {
    variants: {
        family: {
            sans: 'font-sans',
            mono: 'font-mono'
        },
        size: {
            'xs': 'text-xs',
            'sm': 'text-sm',
            'base': 'text-base',
            'lg': 'text-lg',
            'xl': 'text-xl',
            '2xl': 'text-2xl',
            '3xl': 'text-3xl',
            '4xl': 'text-4xl',
            '5xl': 'text-5xl',
            '6xl': 'text-6xl',
            '7xl': 'text-7xl',
            '8xl': 'text-8xl',
            '9xl': 'text-9xl'
        },
        color: {
            white: 'text-white',
            black: 'text-black dark:text-neutral-200',
            neutral: 'text-neutral-500 dark:text-neutral-400',
            success: 'text-success-500',
            warning: 'text-warning-500',
            danger: 'text-danger-500',
            notice: 'text-notice-500'
        },
        shade: {
            '50': 'text-neutral-50',
            '100': 'text-neutral-100',
            '200': 'text-neutral-200',
            '300': 'text-neutral-300',
            '400': 'text-neutral-400',
            '500': 'text-neutral-500',
            '600': 'text-neutral-600',
            '700': 'text-neutral-700',
            '800': 'text-neutral-800',
            '900': 'text-neutral-900',
            '950': 'text-neutral-950'
        },
        weight: {
            normal: 'font-normal',
            medium: 'font-medium',
            bold: 'font-bold'
        },
        style: {
            'italic': 'italic',
            'not-italic': 'not-italic'
        },
        align: {
            left: 'text-left',
            center: 'text-center',
            right: 'text-right'
        },
        lineHeight: {
            none: 'leading-none',
            tight: 'leading-tight',
            snug: 'leading-snug',
            normal: 'leading-normal',
            relaxed: 'leading-relaxed',
            loose: 'leading-loose'
        },
        transform: {
            uppercase: 'uppercase',
            lowercase: 'lowercase',
            capitalize: 'capitalize',
            normal: 'normal-case'
        },
        overflow: {
            truncate: 'truncate',
            ellipsis: 'text-ellipsis',
            clip: 'text-clip'
        }
    },
    compoundVariants: [
        {
            color: 'neutral',
            shade: '50',
            class: 'text-neutral-50'
        },
        {
            color: 'neutral',
            shade: '100',
            class: 'text-neutral-100'
        },
        {
            color: 'neutral',
            shade: '200',
            class: 'text-neutral-200'
        },
        {
            color: 'neutral',
            shade: '300',
            class: 'text-neutral-300'
        },
        {
            color: 'neutral',
            shade: '300',
            class: 'text-neutral-300'
        },
        {
            color: 'neutral',
            shade: '400',
            class: 'text-neutral-400'
        },
        {
            color: 'neutral',
            shade: '500',
            class: 'text-neutral-500'
        },
        {
            color: 'neutral',
            shade: '600',
            class: 'text-neutral-600'
        },
        {
            color: 'neutral',
            shade: '700',
            class: 'text-neutral-700'
        },
        {
            color: 'neutral',
            shade: '800',
            class: 'text-neutral-800'
        },
        {
            color: 'neutral',
            shade: '900',
            class: 'text-neutral-900'
        },
        {
            color: 'neutral',
            shade: '950',
            class: 'text-neutral-950'
        },
        {
            color: 'success',
            shade: '50',
            class: 'text-success-50'
        },
        {
            color: 'success',
            shade: '100',
            class: 'text-success-100'
        },
        {
            color: 'success',
            shade: '200',
            class: 'text-success-200'
        },
        {
            color: 'success',
            shade: '300',
            class: 'text-success-300'
        },
        {
            color: 'success',
            shade: '300',
            class: 'text-success-300'
        },
        {
            color: 'success',
            shade: '400',
            class: 'text-success-400'
        },
        {
            color: 'success',
            shade: '500',
            class: 'text-success-500'
        },
        {
            color: 'success',
            shade: '600',
            class: 'text-success-600'
        },
        {
            color: 'success',
            shade: '700',
            class: 'text-success-700'
        },
        {
            color: 'success',
            shade: '800',
            class: 'text-success-800'
        },
        {
            color: 'success',
            shade: '900',
            class: 'text-success-900'
        },
        {
            color: 'success',
            shade: '950',
            class: 'text-success-950'
        },
        {
            color: 'danger',
            shade: '50',
            class: 'text-danger-50'
        },
        {
            color: 'danger',
            shade: '100',
            class: 'text-danger-100'
        },
        {
            color: 'danger',
            shade: '200',
            class: 'text-danger-200'
        },
        {
            color: 'danger',
            shade: '300',
            class: 'text-danger-300'
        },
        {
            color: 'danger',
            shade: '300',
            class: 'text-danger-300'
        },
        {
            color: 'danger',
            shade: '400',
            class: 'text-danger-400'
        },
        {
            color: 'danger',
            shade: '500',
            class: 'text-danger-500'
        },
        {
            color: 'danger',
            shade: '600',
            class: 'text-danger-600'
        },
        {
            color: 'danger',
            shade: '700',
            class: 'text-danger-700'
        },
        {
            color: 'danger',
            shade: '800',
            class: 'text-danger-800'
        },
        {
            color: 'danger',
            shade: '900',
            class: 'text-danger-900'
        },
        {
            color: 'danger',
            shade: '950',
            class: 'text-danger-950'
        },
        {
            color: 'notice',
            shade: '50',
            class: 'text-notice-50'
        },
        {
            color: 'notice',
            shade: '100',
            class: 'text-notice-100'
        },
        {
            color: 'notice',
            shade: '200',
            class: 'text-notice-200'
        },
        {
            color: 'notice',
            shade: '300',
            class: 'text-notice-300'
        },
        {
            color: 'notice',
            shade: '300',
            class: 'text-notice-300'
        },
        {
            color: 'notice',
            shade: '400',
            class: 'text-notice-400'
        },
        {
            color: 'notice',
            shade: '500',
            class: 'text-notice-500'
        },
        {
            color: 'notice',
            shade: '600',
            class: 'text-notice-600'
        },
        {
            color: 'notice',
            shade: '700',
            class: 'text-notice-700'
        },
        {
            color: 'notice',
            shade: '800',
            class: 'text-notice-800'
        },
        {
            color: 'notice',
            shade: '900',
            class: 'text-notice-900'
        },
        {
            color: 'notice',
            shade: '950',
            class: 'text-notice-950'
        }
    ],
    defaultVariants: {
        family: 'sans',
        size: 'base',
        color: 'black',
        weight: 'normal',
        style: 'not-italic',
        align: 'left',
        lineHeight: 'normal'
    }
})
