/* eslint-disable no-param-reassign */
import { createReducer } from 'deox'
import dotProp from 'dot-prop'
import { produce } from 'immer'
import { ApplicationInternalsActions } from '../actions'
import { ApplicationInternalsCompanyActions } from './actions'
import { InitialApplicationInternalCompanyState } from './types'

export const ApplicationInternalsCompanyReducer = createReducer(
    InitialApplicationInternalCompanyState,
    (handleAction) => [
        handleAction(ApplicationInternalsCompanyActions.STORE, (state, { payload: p }) => {
            return produce(state, (draft: any) => {
                dotProp.set(draft, `forApplication.${p.applicationId}`, {
                    ...p.company,
                    loadingStatus: 'done'
                })
            })
        }),
        handleAction(ApplicationInternalsActions.CLEAR, (state, { payload: p }) => {
            return produce(state, (draft) => {
                draft = { ...InitialApplicationInternalCompanyState }
                return draft
            })
        })
    ]
)
