import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DisputesDueDateButton } from './Disputes.IDDueDateButton'
import { FileBox } from '../../components/files/fileBox'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { Flex } from '../../components/layout/flex'
import { LoaderView } from '../../components/loaders/loader'
import { MarkdownPreviewItem } from '../../components/general/markdownPreviewItem'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { PageWrapper } from '../../components/layout/pageWrapper'
import { Spacer } from '../../components/layout/spacer'
import { TimelineCard } from '../../components/listPages/timelineCard'
import { TimelineMessageBox } from '../../components/listPages/timelineMessageBox'
import { UserComment } from '../../components/complexComponents/userComment'
import { MerchantDetailsSidebar } from '../../components/navigation/merchantDetailsSidebar'
import { ModalHeader } from '../../components/modals/modalHeader'
import { Timeline } from '../../components/taskPages/timeline'
import { DisputeEventTimelineCard } from './Disputes.IDEventTimelineCard'
import { DisputeTimelineCard } from './Disputes.IDTimelineCard'
import { DisputeTransactionTimelineCard } from './Disputes.IDTransactionTimelineCard'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import {
    DisputeDispatchLoad,
    DisputeDispatchLoadExtraDetails,
    DisputeDispatchLoadNextResults,
    DisputeDispatchRemoveComment,
    DisputeDispatchSendComment,
    DisputeDispatchUnstore,
    DisputesDispatchLinkFiles,
    DisputesDispatchStamp,
    DisputesDispatchUnlinkFiles
} from '../../store/disputes/actions'
import { Dispute } from '../../store/disputes/types'
import { FileActionDispatchDelete } from '../../store/files/actions'
import { RootState } from '@/store'
import { WatcherID } from '../../store/watcher/types'
import { uppercaseFirstLetter } from '../../utils'
import { shortenId } from '../../components/general/idShortener'
import { MessageBoxAction } from '../../components/forms/messageBox'
import { useParams } from 'react-router-dom'
import { FetchMoreButton } from '@/components/buttons/fetchMoreButton'

type Params = { id: string; disputeId: string }

export const DisputeModal: React.FC = () => {
    const dispatch = useDispatch()
    const params = useParams() as Params
    const disputeId = params.disputeId
    const dispute = useSelector((state: RootState) => {
        return state.disputes.disputes.at[disputeId]
    })
    const title = useMemo(() => {
        if (dispute?.id && dispute?.extraDetails?.account?.name)
            return `Dispute ${shortenId(dispute.reference, true)} • ${dispute?.extraDetails?.account?.name}`
        return `Loading…`
    }, [dispute])
    useModalStackSync(title, 'DisputePage', 0)

    useEffect(() => {
        dispatch(DisputeDispatchLoad(disputeId))
        return () => {
            dispatch(DisputeDispatchUnstore(disputeId))
        }
    }, [dispatch, disputeId])

    useEffect(() => {
        if (dispute?.extraDetails?.loadingStatus == 'not-started') {
            dispatch(DisputeDispatchLoadExtraDetails(dispute))
        }
    }, [dispute, dispatch])

    const renderedActionButtons = useMemo(() => {
        if (!dispute?.stampsLink) return null
        const labelsForStamp: any = {
            'LOST-REVERTED': {
                title: 'Revert lost',
                key: 'L'
            },
            'ACCEPTED': {
                title: 'Accept',
                key: 'A'
            },
            'REFUTED': {
                title: 'Refute',
                key: 'R'
            },
            'REPRESENTED': {
                title: 'Represent',
                key: 'P'
            },
            'EVIDENCE-REJECTED': {
                title: 'Reject evidence',
                key: 'X'
            },
            'EVIDENCE-REQUESTED': {
                title: 'Request evidence',
                key: 'Q'
            },
            'REOPENED': {
                title: 'Reopen',
                key: 'O'
            }
        }

        const stamps = dispute.stampsLink.map((i) => {
            return {
                name: labelsForStamp[i.name.toUpperCase()].title,
                key: labelsForStamp[i.name.toUpperCase()].key,
                href: i.href
            }
        })

        return stamps.map((stamp) => {
            return (
                <React.Fragment key={stamp.name}>
                    <WatcherButton
                        background="front.background"
                        hotkeys={`alt+${stamp.key}`}
                        hotkeysScope="DisputePage"
                        onClick={() => {
                            dispatch(DisputesDispatchStamp(dispute.id, stamp.href))
                        }}
                        predefinedWatcher={`${dispute.id}_STAMP`}
                    >
                        <ButtonInset>{stamp.name}</ButtonInset>
                    </WatcherButton>
                    <Spacer width={10} />
                </React.Fragment>
            )
        })
    }, [dispute, dispatch])

    const renderedRightSide = useMemo(() => {
        return (
            <Flex>
                {renderedActionButtons}
                <DisputesDueDateButton dispute={dispute} />
            </Flex>
        )
    }, [renderedActionButtons, dispute])

    const handleItemDeletion = useCallback(
        (id: string, watcherId: WatcherID) => {
            const comment = dispute.comments?.filter((c) => c.id === id)[0]
            if (!comment) return
            dispatch(DisputeDispatchRemoveComment(watcherId, dispute, comment))
        },
        [dispute, dispatch]
    )

    const renderedTimelineItems = useMemo(() => {
        if (!dispute) return []
        const onlyExpandEventsOnceDictionary: { [key: string]: boolean } = {}
        return [
            {
                tabIndex: 'Everything',
                date: dispute.transaction?.processedAt ? moment(dispute.transaction.processedAt) : moment(),
                node: <DisputeTransactionTimelineCard key="transaction-card" dispute={dispute} />
            },
            {
                tabIndex: 'Everything',
                date: moment().add(1, 'day'),
                node: <MarkdownPreviewItem key="dispute-page" showContainerCard pageId="DisputePage" showHeader />
            },
            {
                tabIndex: 'Everything',
                date: moment(dispute.openedAt),
                node: <DisputeTimelineCard key="dispute-card" dispute={dispute} />
            },
            ...(dispute?.events?.map
                ? dispute.events.map((e: Dispute['events'][0]) => {
                      let hasBeenSeenBefore = false
                      if (onlyExpandEventsOnceDictionary[e.event]) hasBeenSeenBefore = true
                      onlyExpandEventsOnceDictionary[e.event] = true
                      return {
                          tabIndex: 'Events',
                          date: moment(e.date),
                          node: (
                              <DisputeEventTimelineCard
                                  key={`event-${e.date}-${e.event}`}
                                  event={e}
                                  shouldShowBody={false}
                                  hasBeenSeenBefore={hasBeenSeenBefore}
                                  dispute={dispute}
                              />
                          )
                      }
                  })
                : []),
            ...(dispute.comments?.map
                ? dispute.comments.map((c: Dispute['comments'][0]) => ({
                      tabIndex: 'Comments',
                      date: moment(c.createdAt),
                      node: (
                          <TimelineCard key={`comment-${c.id}`}>
                              {c.author.email ? (
                                  <UserComment
                                      authorType="email"
                                      author={c.author.email}
                                      id={c.id}
                                      body={c.body}
                                      date={c.createdAt}
                                      onDelete={handleItemDeletion}
                                  />
                              ) : (
                                  <UserComment
                                      authorType="email+name"
                                      author={c.author.email}
                                      authorName={c.author.name}
                                      id={c.id}
                                      body={c.body}
                                      date={c.createdAt}
                                      onDelete={handleItemDeletion}
                                  />
                              )}
                          </TimelineCard>
                      )
                  }))
                : []),
            ...(dispute.files?.map
                ? dispute.files.map((f: Dispute['files'][0]) => ({
                      tabIndex: 'Documentation',
                      date: moment(f.createdAt),
                      node: (
                          <TimelineCard key={f.id}>
                              <FileBox
                                  fileId={f.id}
                                  onRemoveClicked={() => {
                                      dispatch(
                                          FileActionDispatchDelete(`${f.id}.DeleteFile`, f, (files) =>
                                              DisputesDispatchUnlinkFiles(dispute.id, files)
                                          )
                                      )
                                  }}
                                  showFileMenu
                              />
                          </TimelineCard>
                      )
                  }))
                : [])
        ]
    }, [dispatch, dispute, handleItemDeletion])

    const fileHandlingMemo = useMemo(() => {
        if (!dispute?.id) return undefined
        return {
            uploadLink: `disputes.disputes.at.${dispute.id}.filesLink`,
            linkFiles: (files: any) => DisputesDispatchLinkFiles(dispute.id, files),
            unlinkFiles: (files: any) => DisputesDispatchUnlinkFiles(dispute.id, files),
            scope: 'public' as const
        }
    }, [dispute])

    const messageBoxActions = useMemo(() => {
        const action: MessageBoxAction = {
            label: 'Comment',
            action: (watcherId: WatcherID, value: string | undefined, reset: () => void) => {
                if (value) dispatch(DisputeDispatchSendComment(watcherId, dispute, value))
                reset()
            }
        }

        return [action]
    }, [dispatch, dispute])

    const pageContent = useMemo(() => {
        let backLink = '/disputes'
        if (params.id) {
            backLink = `/merchant/${params.id}/disputes`
        }

        if (!dispute || dispute.loadingStatus != 'done')
            return <LoaderView overBackground="back.background" key="timelineLoader" />
        return (
            <>
                <ModalHeader pageId="DisputePage" backTo={backLink}>
                    <PageWrapper>
                        <PageHeader
                            title="Dispute"
                            insetLeft
                            subtitle={uppercaseFirstLetter(dispute?.status || '')}
                            rightSideMemo={renderedRightSide}
                            noBottomBorder
                        />
                    </PageWrapper>
                </ModalHeader>
                <PageWrapper>
                    <Timeline hotkeysScope="DisputePage" items={renderedTimelineItems} />
                    {
                        !dispute?.commentsNextLink && !dispute?.filesNextLink ? null :
                            <FetchMoreButton
                                loadingStatus={dispute.loadingNextStatus}
                                actions={
                                    DisputeDispatchLoadNextResults(
                                        dispute, 
                                        dispute.commentsNextLink,
                                        dispute.filesNextLink
                                    )
                                }
                            />
                    }
                    <TimelineMessageBox
                        placeholder="Leave a comment"
                        fileHandlingMemo={fileHandlingMemo}
                        pageId="DisputePage"
                        actions={messageBoxActions}
                    />
                </PageWrapper>
            </>
        )
    }, [dispute, fileHandlingMemo, params, messageBoxActions, renderedRightSide, renderedTimelineItems])

    return (
        <Flex align="stretch">
            <PageContent marginLeft={0} noPadding>
                {pageContent}
            </PageContent>
            <MerchantDetailsSidebar
                isLoading={!dispute || dispute.extraDetails?.loadingStatus !== 'done'}
                merchantId={dispute?.accountId}
                account={dispute?.extraDetails?.account}
                application={dispute?.extraDetails?.application}
            />
        </Flex>
    )
}
