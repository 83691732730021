import React, { ReactElement, useCallback } from 'react'
import styled from 'styled-components'

import { WatcherButton } from './watcherButton'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { WatcherID } from '../../store/watcher/types'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { ButtonInset } from './buttonInset'
import { ClueDirection } from '../hotkeys/hotkeyClue'
import { SimpleButton } from './simpleButton'

export function ToggleWatcherButton({
    inactiveLabel,
    inactiveBackground,
    inactiveColor,
    activeLabel,
    activeBackground,
    activeColor,
    isActive,
    predefinedWatcher,
    onToggle,
    activeHotkeys,
    inactiveHotkeys,
    hotkeysScope,
    hotkeysClueDirection,
    cy
}: {
    inactiveLabel: string
    inactiveBackground: React.ComponentProps<typeof SimpleButton>['background']
    inactiveColor?: React.ComponentProps<typeof SimpleButton>['color']
    activeLabel: string
    activeBackground: React.ComponentProps<typeof SimpleButton>['background']
    activeColor?: React.ComponentProps<typeof SimpleButton>['color']
    isActive?: boolean
    cy?: string
    predefinedWatcher?: WatcherID
    onToggle: (active: boolean, generatedWatcherId: WatcherID) => void
    hotkeysScope?: MODAL_ID
    hotkeysClueDirection?: ClueDirection
    activeHotkeys?: string
    inactiveHotkeys?: string
}): ReactElement {
    const [ref, setRef] = useRefTaker()

    const onInactiveClicked = useCallback(
        (e, generatedId) => {
            onToggle(true, generatedId)
        },
        [onToggle]
    )

    const onActiveClicked = useCallback(
        (e, generatedId) => {
            onToggle(false, generatedId)
        },
        [onToggle]
    )

    const clueElement = useHotkey({
        scope: hotkeysScope,
        keys: isActive ? activeHotkeys : inactiveHotkeys,
        clue: hotkeysClueDirection,
        action: useCallback(() => {
            ref?.click()
        }, [ref])
    })

    if (isActive)
        return (
            <Holder>
                <WatcherButton
                    background={activeBackground}
                    color={activeColor}
                    cy={cy}
                    buttonRef={setRef}
                    predefinedWatcher={predefinedWatcher}
                    onClick={onActiveClicked}
                >
                    <ButtonInset>{activeLabel}</ButtonInset>
                </WatcherButton>
                {clueElement}
            </Holder>
        )

    return (
        <Holder>
            <WatcherButton
                cy={cy}
                background={inactiveBackground}
                buttonRef={setRef}
                color={inactiveColor}
                predefinedWatcher={predefinedWatcher}
                onClick={onInactiveClicked}
            >
                <ButtonInset>{inactiveLabel}</ButtonInset>
            </WatcherButton>
            {clueElement}
        </Holder>
    )
}

const Holder = styled.div`
    position: relative;
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
`
