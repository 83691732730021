import { Flex, Text } from '@/design-system'
import React from 'react'

interface EnabledProps {
    enabled: boolean
}

export const Enabled: React.FC<EnabledProps> = (props) => {
    const { enabled } = props

    return (
        <Flex gap="1" align="center">
            {enabled ? (
                <Flex align="center" gap="2">
                    <Text color="success">Enabled</Text>
                </Flex>
            ) : (
                <Flex align="center" gap="2">
                    <Text color="danger">Disabled</Text>
                </Flex>
            )}
            <Text>{enabled}</Text>
        </Flex>
    )
}
