import { ScheduledTasksPageHeader, ScheduledTasksSidebar, ScheduledTasksTable } from '@/features/ongoing-due-diligence'

import { Flex } from '@/components/layout/flex'
import { PageContent } from '@/components/layout/pageContent'
import React from 'react'
import { Sidebar } from '@/components/listPages/sidebarNavigation/sidebar'

export const ODDTasks: React.FC = () => {
    return (
        <Flex align="stretch">
            <Sidebar hotkeysScope="TasksList" />
            <ScheduledTasksSidebar />
            <PageContent>
                <ScheduledTasksPageHeader />
                <ScheduledTasksTable />
            </PageContent>
        </Flex>
    )
}
