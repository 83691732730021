import { Account } from '../../../components/general/account'
import { AgentBubble } from '../../../components/general/agentBubble'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { MerchantTimelinePoint } from '../../../store/merchantTimeline/types'
import { MerchantTimelineItemDate } from './SummaryTimelineItemDate'

export const MerchantTimelineItemCreation: React.FC<{
    point: MerchantTimelinePoint
}> = ({ point }) => {
    return (
        <Card>
            <CardInset type="small">
                <Flex align="center">
                    <AgentBubble slim id={point.by} />
                    <Spacer width={10} />
                    <Text bold>
                        <Account id={point.by} />
                    </Text>
                    &nbsp;has&nbsp;<Text bold>created the application.</Text>
                </Flex>
                <MerchantTimelineItemDate point={point} />
            </CardInset>
        </Card>
    )
}
