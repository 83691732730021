import { AgentBubble as AgentBubbleComp } from '@/components/general/agentBubble'
import React from 'react'
import { Text } from '@/components/general/text'
import { isAgent } from '@/utils'

interface AgentBubbleProps {
    agentSub?: string
    filled?: boolean
}

export const AgentBubble: React.FC<AgentBubbleProps> = (props) => {
    const { agentSub, filled } = props

    if (!agentSub) {
        return (
            <Text oneLine color="back.text.subtlerI">
                -
            </Text>
        )
    }

    return <AgentBubbleComp filled={filled} staticEmail={agentSub} id={!isAgent(agentSub) ? agentSub : ''} slim />
}
