// eslint-disable-next-line max-len
import { TMerchantApplicationResourceKey } from '../../../pages/Merchant/Application/Application.StructureTypes'
import { LoadingStatus } from '../../../utils'

export interface ApplicationInternalHarvesterState {
    forApplication: {
        [applicationId: string]: {
            issuesBySection: {
                [sectionKey in TMerchantApplicationResourceKey]: {
                    [subsectionKey: number]: Issue[]
                }
            }
            additionalInfoBySection: {
                [sectionKey in TMerchantApplicationResourceKey]: {
                    [subsectionKey: number]: AdditionalInfo[]
                }
            }
            checksBySection: {
                [sectionKey in TMerchantApplicationResourceKey]: {
                    [subsectionKey: number]: HarvesterCheckResult[]
                }
            }
            loadingStatus: LoadingStatus
        }
    }
}

export type IssueType =
    | 'trustpilotBlacklistedWords'
    | 'blacklistedWords'
    | 'blacklistedAddress'
    | 'validateOwnersCountry'
    | 'missingPolicies'
    | 'validateAddress'
    | 'whois'
    | 'contactInfo'
    | 'externalLinks'
export interface Issue {
    name: IssueType
    type: 'critical' | 'non-risk' | 'potential'
    data: any
}

export interface TextSearch {
    sentence: string
    terms: string[]
    url?: string
    link?: string
}

export interface AdditionalInfo {
    name: string
    data: any
}

export interface ExternalLinks {
    name: string
    data: ExternalLinks
}

export interface ExternalLink {
    title: string
    url: string
    id: string
}

export interface HarvesterCheckResult {
    label: string
    checks: HarvesterCheck[]
}

export interface HarvesterCheck {
    success: boolean
    message: string
}

interface HarvesterContactInfo {
    country: string
    cvr: any | null
    emails: any[]
    phones: any
    phonesWithSearch: any
}

export interface HarvesterReport {
    id: string
    websiteId: string
    url: string
    status: string
    dataPoints: {
        blacklistedWords: {
            items: TextSearch[]
        }
        blacklistedAddress: any
        cardLogos: any
        company: {
            fromSite: HarvesterContactInfo
            fromRegister: any
            validateAddress: any
            validateOwnersCountry: any
        }
        policies: {
            found: {
                name: string
                other: any[]
                text: string
                type: string
            }[]
            notFound: string[]
        }
        policyScreenshots: {
            tc?: string
            subscription?: string
            shipping?: string
            refund?: string
            privacy?: string
        }
        searchTrustpilotReviews: {
            items: TextSearch[]
        }
        frontPageScreenshot: string
        site?: {
            resolvedUrl: string
            requiresPassword: boolean
        }
        ssl: boolean
        findTrustpilot: {
            id: string
            url: string
        }
        whois: {
            raw: any
            filtered: any
        }
    }
}
export const InitialApplicationInternalHarvesterState: ApplicationInternalHarvesterState = {
    forApplication: {}
}
