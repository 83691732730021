import { Link } from '../../services/common/types'

export function linksToRelationsObject<Relations = unknown>(links: Link[]) {
    const relations = {} as Relations

    for (const link of links) {
        const { name } = link
        let { rel } = link
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        rel = rel.split('ch:').pop()!

        let href = link.href
        if (link.templated) {
            href = href.replace(/\{.*\}/g, '')
        }

        if (name) {
            if (!relations[rel]) {
                Object.defineProperty(relations, rel, {
                    enumerable: true,
                    value: {}
                })
            }

            Object.defineProperty(relations[rel], name, {
                enumerable: true,
                value: href
            })
        } else {
            if (relations[rel]) {
                if (!Array.isArray(relations[rel])) {
                    Object.defineProperty(relations, rel, {
                        enumerable: true,
                        value: [relations[rel]]
                    })
                }
                relations[rel].push(href)
            } else {
                Object.defineProperty(relations, rel, {
                    enumerable: true,
                    value: href,
                    writable: true
                })
            }
        }
    }

    return relations
}
