import React, { ReactElement, useCallback, useMemo } from 'react'

import { TransactionsFilters } from '../../store/transactions/types'
import { TextInput } from '../forms/textInput'
import { FiltersController } from '../../hooks/pages/useListPage'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'
import { SettlementsFilters } from '../../store/settlements/types'
import { ExternalLink } from '../buttons/externalLink'
import styled from 'styled-components'
import { HIDE_FILTERS_AT_WIDTH } from '../taskPages/filters'
import { DisputesFilters } from '../../store/disputes/types'

export function ListPageFilterCustomQuery<T extends TransactionsFilters | SettlementsFilters | DisputesFilters>({
    filters,
    fieldKey,
    isDark
}: {
    filters: FiltersController<T>
    fieldKey: keyof T
    isDark: boolean
}): ReactElement {
    const [fieldRef, setFieldRef] = useRefTaker()

    const handleEnter = useCallback(
        (e, value) => {
            if (fieldRef) fieldRef.value = ''
            filters.set((prevState) => ({
                ...prevState,
                [fieldKey]: value
            }))
        },
        [fieldRef, filters, fieldKey]
    )

    const helpText = useMemo(() => {
        if ((fieldKey as any).includes?.('transactions'))
            return (
                <ExternalLink
                    defaultColor="side.accent.color"
                    alwaysShowArrow
                    url="https://developer.clearhaus.com/merchant-api/tql"
                    target="blank"
                >
                    TQL
                </ExternalLink>
            )
        if ((fieldKey as any).includes?.('settlements'))
            return (
                <ExternalLink
                    defaultColor="side.accent.color"
                    alwaysShowArrow
                    url="https://developer.clearhaus.com/merchant-api/sql"
                    target="blank"
                >
                    SQL
                </ExternalLink>
            )
        if ((fieldKey as any).includes?.('disputes'))
            return (
                <ExternalLink
                    defaultColor="side.accent.color"
                    alwaysShowArrow
                    url="https://developer.clearhaus.com/merchant-api/dql"
                    target="blank"
                >
                    DQL
                </ExternalLink>
            )
        return undefined
    }, [fieldKey])

    const textForItem = useCallback((val) => val, [])

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInput
                overBackground={isDark ? 'floating.background' : 'side.background'}
                onEnter={handleEnter}
                cy="custom-filter"
                placeholder="-"
                ref={setFieldRef}
                isSeamless
            />
            <ButtonHolder>{helpText}</ButtonHolder>
            <SelectedListForListPageFilters<T>
                filterKey={fieldKey}
                noHolderEmphasize={isDark}
                filters={filters}
                isDark={isDark}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}

const ButtonHolder = styled.div`
    position: absolute;
    top: 0px;
    right: 0;

    @media (max-width: ${HIDE_FILTERS_AT_WIDTH}px) {
        position: relative;
    }
`
