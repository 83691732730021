import styled from 'styled-components'
import { Text } from '../../../components/general/text'
import { useRelevantWebsites } from './Application.useRelevantWebsites'
import { Link } from 'react-router-dom'

export const MerchantApplicationWebsiteScreenshotsButton: React.FC<{
    applicationId: string
    subsectionIndex: number
}> = ({ applicationId, subsectionIndex }) => {
    const relevantScreenshots = useRelevantWebsites(applicationId, subsectionIndex)

    if (!relevantScreenshots.all.length) return <Text color="back.text">No screenshots</Text>
    return (
        <>
            <StatusBox to={`/merchant/${applicationId}/application/websites`}>
                {relevantScreenshots.all.map((k: string) =>
                    k === 'Others' ? (
                        <Text key={`${subsectionIndex}-${k}`} color="back.text.strongerI">
                            Others
                            <Count>{relevantScreenshots.at['Others'].length}</Count>
                        </Text>
                    ) : (
                        <Text key={`${subsectionIndex}-${k}`} color="front.text">
                            {k}
                            <Count>{relevantScreenshots.at[k].length}</Count>
                        </Text>
                    )
                )}
            </StatusBox>
        </>
    )
}

const StatusBox = styled(Link)`
    padding: 10px 20px;
    background-color: ${(p) => p.theme['back.background.strongerII']};
    display: flex;
    flex-direction: column;
    margin: 0 0 0 -20px;
    border-radius: 8px;
    text-decoration: none;
    color: ${(p) => p.theme['front.accent.color']};

    &:hover {
        background-color: ${(p) => p.theme['back.background.strongerIII']};
    }
    &:active {
        background-color: ${(p) => p.theme['back.background']};
    }
`

const Count = styled.div`
    background-color: ${(p) => p.theme['back.background.strongerIII']};
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 5px;
    position: relative;
    padding: 1px 0 0;
    text-align: center;
    top: -2px;
    width: 12px;
    height: 12px;
    font-size: 8px;
    line-height: 6px;
    font-weight: 600;
    text-align: center;
    color: currentColor;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
`
