import { ActionType } from 'deox'
import { put } from 'typed-redux-saga'

import { GET } from '../../generators/networking'
import { MCCActions } from './actions'

export const MCCSagas = {
    *FETCH({ payload: p }: ActionType<typeof MCCActions.MCC_FIND>) {
        const q = p.query
        const response: { [key: string]: any } = yield GET({
            url: `${import.meta.env.VITE_MCC_ENDPOINT}?limit=25&q=${q}`,
            errorText: 'Failed to load Application.'
        })

        if (response) {
            yield* put(
                MCCActions.MCC_STORE(
                    Object.keys(response)
                        .map((k) => response[k])
                        .sort((a, b) => (a.value.indexOf(q) < b.value.indexOf(q) ? -1 : 1))
                )
            )
        }
    }
}
