import { VariantProps, cva } from 'class-variance-authority'

export type ContentStyles = VariantProps<typeof styles.content>

export const styles = {
    content: cva(
        [
            'z-[2000000]',
            'rounded-md',
            'border',
            'border-neutral-100',
            'bg-white',
            'p-4',
            'text-black',
            'drop-shadow-md',
            'outline-none',
            'dark:border-gray-600',
            'dark:bg-gray-900'
        ],
        {
            variants: {
                size: {
                    sm: 'p-4',
                    md: 'p-6',
                    lg: 'p-8'
                }
            },
            defaultVariants: {
                size: 'sm'
            }
        }
    )
}
