import { partnerGuidelines } from '../assets'

export const getPartnerGuidelines = (gatewayId?: string) => {
    if (!gatewayId) {
        return
    }

    const guidelines = partnerGuidelines.guidelines.find((guideline) => guideline.gateways.includes(gatewayId))

    return guidelines
}
