import { diff_match_patch } from 'diff-match-patch'
import { select } from 'typed-redux-saga'
import { Company, Person } from '../applications/types'
import { RootState } from '@/store'
import { scanForCompanyConflicts } from './sagas.scanFor.companyConflicts'
import { scanForDirectorConflicts } from './sagas.scanFor.directorConflicts'
import { scanForOwnershipConflicts } from './sagas.scanFor.ownershipConflicts'
import { ConflictsAndOwnershipShares, MutedConflictsPayload, CompanyInfoPotentialMatch, ConflictsStatus } from './types'
import { computeOwnershipShares } from './sagas.compute.ownershipShares'

export const UBO_OWNERSHIP_LOWER_LIMIT = 0.25
export function* computeConflictsAndOwnershipShares(
    id: string,
    data?: CompanyInfoPotentialMatch,
    mutedConflicts?: Partial<MutedConflictsPayload>
) {
    if (!data) return undefined

    const appData = {
        company: yield* select(
            (state: RootState) => state.applicationResources.data.forApplication[id]?.company?.fields?.[0]
        ),
        people: yield* select((state: RootState) => state.applicationResources.data.forApplication[id]?.people?.fields)
    }

    if (!appData.people || !appData.company) return

    const appDirectorsArray = appData.people.filter((p) => p.role === 'director' || p.role == 'director-and-owner')
    const appOwnersArray = appData.people.filter((p) => p.role === 'owner' || p.role == 'director-and-owner')
    const sotOwnership: CompanyInfoPotentialMatch['ownership'] = data.ownership
        ? JSON.parse(JSON.stringify(data.ownership)) || []
        : undefined
    const sotOwners = sotOwnership?.beneficial
    const ubos = sotOwners?.filter?.((o) => o.ownershipPercentage > UBO_OWNERSHIP_LOWER_LIMIT) || []

    const dmp = new diff_match_patch()

    const conflicts: ConflictsAndOwnershipShares['conflicts'] = {
        company: scanForCompanyConflicts(dmp, appData.company as Company, data.company, mutedConflicts),
        director: scanForDirectorConflicts(
            dmp,
            appDirectorsArray as Person[],
            appData.people as Person[],
            data.director,
            mutedConflicts
        ),
        ownership: scanForOwnershipConflicts(
            dmp,
            appOwnersArray as Person[],
            appData.people as Person[],
            sotOwners,
            ubos,
            mutedConflicts
        )
    }

    const conflictsStatus = ((): ConflictsStatus => {
        let hasUnmutedConflicts = false
        let hasMutedConflicts = false
        let hasConflicts = false

        if (conflicts.company?.conflictsCount != 0) {
            hasConflicts = true
            if (conflicts.company?.mutedConflictsCount > 0) hasMutedConflicts = true
            if (conflicts.company?.mutedConflictsCount < conflicts.company?.conflictsCount) hasUnmutedConflicts = true
        }

        if (conflicts.director.type !== 'no-conflicts') {
            hasConflicts = true
            if ((conflicts.director as any).muted) hasMutedConflicts = true
            else hasUnmutedConflicts = true
        }

        if (conflicts.ownership.type !== 'no-conflicts') {
            hasConflicts = true
            if ((conflicts.ownership as any).muted) hasMutedConflicts = true
            else hasUnmutedConflicts = true
        }

        if (hasConflicts && hasUnmutedConflicts) {
            if (!hasMutedConflicts) return 'has-conflicts'
            else return 'has-conflicts-some-muted'
        }

        if (hasMutedConflicts) {
            return 'all-muted'
        }

        return 'no-conflicts'
    })()

    return {
        conflicts,
        conflictsStatus,
        ownershipShares: computeOwnershipShares(sotOwners)
    } as ConflictsAndOwnershipShares
}
