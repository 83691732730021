import React from 'react'
import { Table } from '@/components/tables/table'
import { getReportRows } from './helpers'
import { useGetReports } from '@/services/ongoing-due-diligence'
import { CardInset } from '@/components/cards/cardInset'
import { Card } from '@/components/cards/card'
import { CreateReportButton } from '../create-report-button/create-report-button'

const cols = [{ text: 'Date' }, { text: 'Author' }, { text: 'Status', alignRight: true }]

const createReportButton = <CreateReportButton />

interface IReportsTableProps {
    applicationId: string
}

export const ReportsTable: React.FC<IReportsTableProps> = (props) => {
    const { applicationId } = props

    const { isLoading, data } = useGetReports(applicationId)
    const rows = getReportRows(data)

    return (
        <Card title="Reports" rightSideMemo={createReportButton}>
            <CardInset>
                <Table
                    displayLoader={isLoading}
                    loaderRows={1}
                    rows={rows}
                    cols={cols}
                    background="front.background"
                    columnLayout="1fr 1fr auto"
                />
            </CardInset>
        </Card>
    )
}
