import React from 'react'
import styled, { css } from 'styled-components'
import { Card } from './card'

export const ContractCard: React.FC<{ children: any; title?: string; grow?: boolean; showEditable?: boolean }> = ({
    children,
    title,
    showEditable,
    grow
}) => {
    return (
        <CustomCard title={title} grow={grow} showEditable={showEditable}>
            {children}
        </CustomCard>
    )
}

const CustomCard = styled(Card)`
    max-width: 320px;
    width: 100%;
    margin-top: 10px;
    flex-grow: 1;

    ${(p) =>
        p.grow &&
        css`
            max-width: 100%;
        `}
`
