import { Account, Voucher } from '@/services/reconciliation'
import React from 'react'
import { HeaderTitle, Section } from '../../common'
import { Header } from '../../common/section'
import { SingleVoucherTable } from './single-voucher-table'
import { DeleteVoucher } from './delete-voucher'
import { UnreconcileVoucher } from './unreconcile-voucher'

interface SingleVoucherSectionProps {
    voucher: Voucher
    accounts: Account[]
}

export const SingleVoucherSection: React.FC<SingleVoucherSectionProps> = (props) => {
    const { voucher, accounts } = props

    const showUnreconcile = voucher.reconciled

    return (
        <Section>
            <Section.Header>
                <Header.Title>
                    <HeaderTitle title="Voucher details" id={voucher.id} />
                </Header.Title>
                <Header.Actions>
                    {showUnreconcile && <UnreconcileVoucher />}
                    {!showUnreconcile && <DeleteVoucher />}
                </Header.Actions>
            </Section.Header>
            <Section.Content>
                <SingleVoucherTable voucher={voucher} accounts={accounts} />
            </Section.Content>
        </Section>
    )
}
