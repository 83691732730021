/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { select } from 'typed-redux-saga'

import { GET, POST } from '../../generators/networking'
import { RootState } from '@/store'
import {
    GatewaysActionCreate,
    GatewaysActionEdit,
    GatewaysDispatchStore,
    GatewaysDispatchStoreNew,
    SagasForGateways
} from './actions'

export class GatewaysSaga implements SagasForGateways {
    *createGateway(action: GatewaysActionCreate) {
        yield POST({
            watcher: `${action.watcher}`,
            cutterUrl: (l) => l.gatewaysLink,
            body: action.gateway,
            errorText: 'Failed to create the gateway.',
            successText: 'Gateway created successfully.',
            include: ['self'],
            onSuccessDispatch: (c) => {
                return GatewaysDispatchStoreNew(c)
            }
        })
    }
    *editGateway(action: GatewaysActionEdit) {
        yield POST({
            watcher: `${action.watcher}`,
            url: action.link,
            body: action.gateway,
            errorText: 'Failed to update the gateway.',
            include: ['self'],
            successCode: 200,
            successText: 'Gateway updated successfully.',
            onSuccessDispatch: (c) => {
                return GatewaysDispatchStoreNew(c)
            }
        })
    }
    *loadGateways() {
        const gatewaysLoadingStatus = yield* select((state: RootState) => {
            return state.gateways.gateways.loadingStatus
        })

        if (gatewaysLoadingStatus === 'done') return

        yield GET({
            cutterUrl: (l) => l.gatewaysLink,
            include: ['self'],
            onSuccessDispatch: (r) => GatewaysDispatchStore(r.gateways),
            errorText: 'Failed to load gateways.'
        })
    }
}
