export const defaultReport = {
    id: '',
    application_id: '',
    report_detail: {
        report_status: 'draft' as const,
        date_started: '',
        author_id: ''
    },
    merchant_data: {
        application: {
            metadata: {
                created_at: '',
                voucher_code: '',
                state: ''
            },
            id: '',
            business_model: {
                physical_delivery: false,
                trading_name: '',
                estimated_monthly_turnover: '',
                estimated_average_transaction_amount: '',
                description: '',
                delivery_delay: '',
                drop_shipping: false,
                estimate_currency: 'DKK' as const
            },
            signer: { name: '', email: '' }
        },
        accounts: [
            {
                country: '',
                metadata: { created_at: '', state: '' },
                timezone: '',
                sanitised_name: '',
                transaction_rules: '',
                merchant_id: '',
                descriptor: '',
                mcc: '',
                sanitised_zipcode: '',
                first_contract_signed: '',
                trusted: false,
                ch_billing: true,
                name: '',
                sanitised_address_line_1: '',
                currency: 'EUR' as const,
                sanitised_city: '',
                id: '',
                payments_held: true,
                parent_mid: '',
                ch_billing_date: '',
                bank_account: { swift_code: '', iban: '' }
            }
        ],
        bank_account: {
            bank: '',
            swift_code: '',
            iban: '',
            currency: 'EUR' as const,
            id: ''
        },
        company: {
            country: '',
            city: '',
            registration_number: '',
            sanitised: { name: '', address_line_1: '', zipcode: '', city: '' },
            zipcode: '',
            form: '',
            phone: '',
            ownership_structure: '',
            name: '',
            address_line_1: '',
            ownership_structure_comment: '',
            id: '',
            address_line_2: '',
            email: '',
            note_ownership_structure: ''
        },
        people: [
            {
                zipcode: '',
                country: '',
                role_director: true,
                city: '',
                date_of_birth: '',
                name: '',
                address_line_1: '',
                social_security_number: '',
                id: '',
                address_line_2: '',
                role_owner: false
            }
        ],
        websites: [
            {
                url: '',
                id: ''
            }
        ],
        gateway: {
            name: '',
            id: ''
        },
        tags: [
            {
                name: '',
                id: '',
                visible: true,
                color: ''
            },
            {
                name: '',
                id: '',
                visible: true,
                color: ''
            }
        ],
        ownership_structure: ''
    },
    report_data: {
        sections: {
            Details: { report_reason: { type: 'textarea' as const, value: '', label: '' } },
            Checks: {
                check: {
                    risk_classification: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    comment_google_negative_info_owners: {
                        type: 'textarea' as const,
                        value: '',
                        placeholder: ''
                    },
                    website_likely_chargeback_pattern: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    comment_website_available_contact_info: {
                        type: 'textarea' as const,
                        value: '',
                        placeholder: ''
                    },
                    website_credible_capture_pattern: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    google_negative_info_company_url: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    website_credible_refund_credit_pattern: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    comment_risk_classification: {
                        type: 'textarea' as const,
                        value: '',
                        placeholder: ''
                    },
                    comment_google_negative_info_company_url: {
                        type: 'textarea' as const,
                        value: '',
                        placeholder: ''
                    },
                    comment_sanction_lists_owners_listed: {
                        type: 'textarea' as const,
                        value: '',
                        placeholder: ''
                    },
                    website_available_terms_and_condition: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    website_average_expected_transaction_size: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    company_payment_accounts: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    comment_trustpilot_satisfying: {
                        type: 'textarea' as const,
                        value: '',
                        placeholder: ''
                    },
                    google_negative_info_owners: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    company_fundamental_changes: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    comment_company_fundamental_changes: {
                        type: 'textarea' as const,
                        value: '',
                        label: ''
                    },
                    trustpilot_satisfying: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    comment_website_satisfying_business_model: {
                        type: 'textarea' as const,
                        value: '',
                        placeholder: ''
                    },
                    sanction_lists_owners_listed: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    website_available_contact_info: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    website_available_refund_policy: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    },
                    website_satisfying_business_model: {
                        type: 'radio' as const,
                        value: '',
                        label: ''
                    }
                }
            },
            Comments: {
                comment: {
                    review_confirmation: {
                        type: 'checkbox' as const,
                        value: 'false',
                        label: ''
                    },
                    aml_escalated: {
                        type: 'checkbox' as const,
                        value: '',
                        label: ''
                    },
                    possibility_of_misconduct: {
                        type: 'checkbox' as const,
                        value: '',
                        label: ''
                    },
                    comment: { type: 'textarea' as const, value: '', label: 'Comment' },
                    no_suspecious_behaviour: {
                        type: 'checkbox' as const,
                        value: '',
                        label: ''
                    }
                }
            },
            Documentation: {
                check_doc: {
                    company: { type: 'checkbox' as const, value: '', label: '' },
                    websites: { type: 'checkbox' as const, value: '', label: '' },
                    accounts: { type: 'checkbox' as const, value: '', label: '' },
                    ownership_ubo: { type: 'checkbox' as const, value: '', label: '' },
                    management: { type: 'checkbox' as const, value: '', label: '' },
                    ownership_structure: {
                        type: 'checkbox' as const,
                        value: '',
                        label: ''
                    }
                }
            }
        }
    }
}
