import { ActionType } from 'deox'

import { GET } from '../../../generators/networking'
import { ApplicationInternalsRelatedActions } from './actions'
import { Related, RelatedApplication } from './types'

export const ApplicationInternalsRelatedSagas = {
    *LOAD({ payload: p }: ActionType<typeof ApplicationInternalsRelatedActions.LOAD>) {
        yield GET({
            url: `${import.meta.env.VITE_CUTTER_ROOT}/applications/${p.applicationId}/related`,
            include: ['self', 'next', 'count'],
            onSuccessDispatch: (relatedApplications: { related: RelatedApplication[], nextLink, count }) => {
                const related =
                    relatedApplications?.related?.map((relatedApplication) => {
                        return {
                            name: relatedApplication.company.name,
                            id: relatedApplication.application.id,
                            state: relatedApplication.application.state,
                            mid: relatedApplication?.bankAccount?.mid,
                            matchingOn: relatedApplication.matchingOn,
                            closedAccounts: relatedApplication.closedAccounts
                        }
                    }) || []
                return ApplicationInternalsRelatedActions.STORE(
                    p.applicationId, 
                    related, 
                    relatedApplications.nextLink, 
                    relatedApplications.count
                )
            },
            errorText: 'Failed to load related applications.'
        })
    },

    *LOAD_NEXT({ payload: p }: ActionType<typeof ApplicationInternalsRelatedActions.LOAD_NEXT>) {
        yield GET({
            url: p.nextLink,
            include: ['self', 'next', 'count'],
            onSuccessDispatch: (relatedApplications: { related: RelatedApplication[], nextLink, count }) => {
                const related =
                    relatedApplications?.related?.map((relatedApplication) => {
                        return {
                            name: relatedApplication.company.name,
                            id: relatedApplication.application.id,
                            state: relatedApplication.application.state,
                            mid: relatedApplication?.bankAccount?.mid,
                            matchingOn: relatedApplication.matchingOn,
                            closedAccounts: relatedApplication.closedAccounts
                        }
                    }) || []
                return ApplicationInternalsRelatedActions.STORE(
                    p.applicationId, [...(p.related as Related[]), ...related], 
                    relatedApplications.nextLink, 
                    relatedApplications.count
                )
            },
            errorText: 'Failed to load related applications.'
        })
    }
}
