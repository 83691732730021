import {
    HighlightableBackgrounds,
    getBorderForBackground,
    getHighlightForBackground
} from '../../hooks/general/useTheme'
import React, { useMemo, useRef } from 'react'
import { TableProps, TableRow, TableRowType, TableType } from './table'
import styled, { css } from 'styled-components'

import { Color } from '../../styles/colors'
import { SimpleButton } from '../buttons/simpleButton'
import { SmartNavLink } from './smartNavLink'
import { TextLineLoader } from '../loaders/textLineLoader'
import { v4 as uuid } from 'uuid'
import { useLocation } from 'react-router-dom'

interface RowProps {
    rowType: TableRowType
    noAction?: boolean
    hover?: string
    highlightColor: Color
    background: HighlightableBackgrounds
    tableType: TableType
}

export const DynamicTableRow: React.FC<{
    r?: TableRow
    p: TableProps
    isSelected?: boolean
    background?: HighlightableBackgrounds
    isLoading?: boolean
    onClick?: () => void
}> = ({ r, p, isSelected, isLoading }) => {
    const key = useRef(uuid())
    const location = useLocation()
    const type = useMemo(() => {
        if (r?.link) {
            if (r.linkTarget) {
                return 'link-with-target'
            }
        } else {
            return 'simple-row'
        }
        return 'simple-link'
    }, [r])

    const Element: React.FC<any> = useMemo(() => {
        if (type === 'simple-row') return TableRowDiv
        if (type === 'link-with-target') return TableRowAnchor
        return TableRowLink
    }, [type])

    const shouldPassBackToState = useMemo(() => {
        switch (type) {
            case 'simple-row':
                return false
            case 'link-with-target':
                return false
            default:
                return true
        }
    }, [type])

    const to = useMemo(() => {
        // This might lead to the query string being parsed in a parameter
        const linkWithoutQuery = r?.link?.split('?')[0]

        if (r?.linkState)
            return {
                pathname: linkWithoutQuery,
                search: p?.keepQuery ? location.search : undefined
            }
        if (p?.keepQuery) {
            return {
                pathname: linkWithoutQuery,
                search: location.search
            }
        }

        return {
            pathname: linkWithoutQuery
        }
    }, [r?.link, r?.linkState, p, location])

    const state = useMemo(() => {
        let extraState = {}

        if (shouldPassBackToState)
            extraState = {
                backTo: location.pathname + location.search
            }

        if (r?.linkState)
            return {
                ...extraState,
                ...r?.linkState
            }
        if (p?.keepQuery) {
            return {
                ...extraState
            }
        }

        return {
            ...extraState
        }
    }, [r, p, location, shouldPassBackToState])

    if (isLoading)
        return (
            <Element
                data-cy="table-row"
                background={p.background}
                $noHeader={p.noHeader}
                bordered={p.bordered}
                $noHighlights={p.noHighlights}
                noAction={true}
                isSelected={isSelected}
                key={key?.current + 'loading'}
            >
                {p.cols.map((c, i) => {
                    return (
                        <TableCell
                            tableType={p.type}
                            verticalAlignTop={p.verticalAlignTop}
                            data-cy="table-cell"
                            key={`test${i}`}
                            $columnsGap={p.columnsGap}
                            minRowHeight={p.minRowHeight}
                            align={p.cols[i]?.alignRight ? 'right' : 'left'}
                        >
                            <TextLineLoader
                                height={p.minRowHeight || 21}
                                minWidth={c.loaderSize?.min}
                                maxWidth={c.loaderSize?.max}
                            />
                        </TableCell>
                    )
                })}
            </Element>
        )

    return (
        <Element
            ref={r?.ref}
            rowType={r?.type}
            data-cy={`table-row ${r?.cy}`}
            background={p.background}
            to={to}
            state={state}
            $noHeader={p.noHeader}
            target={r?.linkTarget}
            href={r?.link}
            bordered={p.bordered}
            $noHighlights={p.noHighlights}
            noAction={r?.noAction}
            isSelected={isSelected}
            key={key?.current + 'row'}
            {...(r?.attributes || {})}
        >
            {r?.items.map((c, i) => {
                return (
                    <TableCell
                        key={`${r.key}Col${i}`}
                        $tableType={p.type}
                        $rowType={r.type}
                        $columnsGap={p.columnsGap}
                        wrap={c?.wrap}
                        $minRowHeight={p.minRowHeight}
                        style={c.extraStyles}
                        $verticalAlignTop={p.verticalAlignTop}
                        data-cy="table-cell"
                        span={c?.span}
                        align={p.cols[i]?.alignRight ? 'right' : 'left'}
                    >
                        {c.node}
                    </TableCell>
                )
            })}
        </Element>
    )
}

const RowStyles = ({
    rowType,
    background,
    noAction,
    isSelected,
    $noHeader,
    bordered,
    highlightable,
    $noHighlights
}: {
    rowType: TableRowType
    noAction?: boolean
    background: HighlightableBackgrounds
    $noHeader?: boolean
    isSelected?: boolean
    bordered?: boolean
    highlightable?: boolean
    $noHighlights?: boolean
}) => css`
    display: contents;
    text-decoration: none;
    color: inherit;

    ${$noHeader &&
    css`
        &:first-child > ${TableCell} {
            margin-top: -14px;
        }
    `}

    ${!bordered &&
    css`
        &:nth-child(${$noHeader ? 'odd' : 'even'}) > ${TableCell} {
            background-color: ${(p) => p.theme[getHighlightForBackground(background)]};
        }
    `}

    ${bordered &&
    css`
        & > ${TableCell} {
            position: relative;
            &:after {
                content: '';
                background-color: #ccc;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(
                    90deg,
                    ${(p) => p.theme[getBorderForBackground(background)]},
                    ${(p) => p.theme[getBorderForBackground(background)]} 40%,
                    transparent 40%,
                    transparent 100%
                );
                background-size: 5px 1px;
            }
        }
    `}
    ${$noHighlights &&
    css`
        & > ${TableCell} {
            background-color: transparent !important;
        }
    `}

    & > ${TableCell} {
        position: relative;
        overflow: visible;
        color: inherit;
        z-index: auto;

        &:first-child {
            border-radius: 6px 0 0 6px;
        }
        &:last-child {
            border-radius: 0px 6px 6px 0;
        }

        &:first-child:after {
            left: 12px;
            @media print {
                left: 0;
            }
        }

        &:last-child:after {
            width: calc(100% - 12px);
            @media print {
                width: 100%;
            }
        }

        ${rowType === 'emphasized' &&
        css`
            &:first-child:before {
                content: '';
                position: absolute;
                top: 15px;
                left: 2px;
                width: 4px;
                z-index: 0;
                border-radius: 9px;
                height: 4px;
                background-color: ${(p) => p.theme['front.accent.color']};
                @media print {
                    display: none;
                }
            }
            /* &:last-child:before {
                content: '';
                position: absolute;
                top: 13px;
                right: 2px;
                border-radius: 99px;
                z-index: 0;
                width: 4px;
                height: 4px;
                background-color: ${(p) => p.theme['front.accent.color']};
            } */
        `}

        &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0px;
            height: 1px;
            width: 100%;

            @media print {
                background-color: #ccc;
            }
        }
    }

    &:last-child > ${TableCell} {
        margin-bottom: 2px;

        ${(p: any) =>
            !p.showLastRowBorder &&
            css`
                &:after {
                    display: none;
                }
            `}
    }

    ${isSelected &&
    css`
        ${TableCell} + ${TableCell}:after {
            opacity: 0;
        }

        ${TableCell} {
            background-color: ${(p) => p.theme['front.accent.color']};

            &:after {
                opacity: 0;
            }
        }
    `}

    &:hover > ${TableCell} {
        &:after {
            ${!bordered &&
            css`
                opacity: 0;
            `}
        }
        background-color: ${(p) => {
            const bg = getHighlightForBackground(background)
            if (background.includes('.strongerI'))
                return p.theme[(background.replace('.strongerI', '') + '.strongerII') as Color]
            return p.theme[(bg + '.strongerI') as Color]
        }};
    }

    &:active > ${TableCell} {
        background-color: ${(p) => {
            const bg = getHighlightForBackground(background)
            if (background.includes('.strongerI'))
                return p.theme[(background.replace('.strongerII', '') + '.strongerII') as Color]
            return p.theme[(bg + '.strongerIII') as Color]
        }};
    }

    &:hover > ${TableCell} + ${TableCell}:after {
        ${!bordered &&
        css`
            opacity: 0;
        `}
    }

    & > ${TableCell} {
        &:first-child {
            border-left: 1px solid rgba(0, 0, 0, 0);
        }

        &:last-child {
            border-right: 1px solid rgba(0, 0, 0, 0);
        }

        ${noAction
            ? null
            : css`
                  ${!bordered &&
                  css`
                      cursor: pointer;
                  `}
              `}
    }

    @media print {
        background-color: #fff;
    }
`

// eslint-disable-next-line react/jsx-props-no-spreading
const TableRowLink = styled(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line react/display-name
    React.forwardRef<any, any>(
        ({ tableRow, noAction, rowType, isSelected, noHeader, noHighlights, ...p }, passedRef) => (
            <SmartNavLink {...p} ref={passedRef} />
        )
    )
)`
    ${(p: any) => RowStyles(p)}
`

const TableRowDiv = styled.div<RowProps>`
    ${(p) => RowStyles(p)}
`

const TableRowAnchor = styled(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line react/display-name
    React.forwardRef<any, any>(({ tableRow, noAction, rowType, isSelected, ...p }, passedRef) => (
        <a {...p} ref={passedRef} />
    ))
)`
    ${(p: any) => RowStyles(p)}
`

const TableCell = styled(
    ({ wrap, minRowHeight, span, noHighlights, tableType, rowType, verticalAlignTop, columnsGap, ...p }: any) => (
        <div {...p} />
    )
)`
    padding: 6px 0 4px 0;
    padding-right: ${(p) => p.$columnsGap || 20}px;
    padding-left: 0px;
    flex-grow: 0;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    user-select: text;
    min-height: 22px;
    position: relative;
    z-index: auto;

    @media print {
        padding: 0 20px !important;
        line-height: 10px !important;
    }

    ${(p) =>
        p.verticalAlignTop &&
        css`
            padding-top: 10px;
            justify-content: flex-start;
        `}

    ${(p) =>
        p.span &&
        css`
            grid-column: span ${p.span};
        `}

    ${(p) =>
        !p.wrap &&
        css`
            white-space: nowrap;
        `}

    ${(p) =>
        p.align === 'right' &&
        css`
            align-items: flex-end;
        `};

    ${(p) =>
        p.tableType === 'narrow' &&
        css`
            padding: 3px 0 2px 0;
            line-height: 0.9em;
            padding-right: ${p.$columnsGap || 20}px;
            min-height: 16px;
            padding-left: 0px;

            ${p.align === 'right' &&
            css`
                padding-right: 0px;
                padding-left: ${p.$columnsGap || 20}px;
            `};
            /* height: 15px; */

            @media print {
                padding: 0 20px !important;
                line-height: 10px !important;
            }
        `}

    ${(p) =>
        p.rowType === 'emphasized' &&
        css`
            z-index: 0;
        `}
        
    &:first-child {
        padding-left: 12px;

        @media print {
            padding-left: 0px !important;
        }
    }
    &:last-child {
        padding-right: 12px;
        @media print {
            padding-right: 0 !important;
        }
    }

    ${(p) => {
        if (p.rowType === 'emphasized')
            return css`
                color: #454545;
                font-weight: 400;
            `
        if (p.rowType === 'normal')
            return css`
                color: #000;
            `
        if (p.rowType === 'subtle')
            return css`
                color: ${(p) => p.theme['front.text.subtlerI']};
            `
    }}
    ${(p) =>
        p.align === 'right' &&
        css`
            text-align: right;
        `}

    ${(p) =>
        p.minRowHeight &&
        css`
            min-height: ${p.minRowHeight}px;
        `}
    @media print {
        background-color: #fff !important;
        font-size: 12px;
        border: none;
    }
`
