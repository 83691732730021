import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { ButtonInset } from '../../components/buttons/buttonInset'
import { LinkButton } from '../../components/buttons/linkButton'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { TextInputAgentSelector } from '../../components/forms/textInputAgentSelector'
import { ToggleWatcherButton } from '../../components/buttons/toggleWatcherButton'
import {
    TaskDispatchAssignAgent,
    TaskDispatchAssignTag,
    TaskDispatchClose,
    TaskDispatchOpen,
    TaskDispatchRemoveTag
} from '../../store/tasks/actions'
import { Task } from '../../store/tasks/types'
import { WatcherID } from '../../store/watcher/types'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { useLocation } from 'react-router-dom'

export const AlertActions: React.FC<{ task: Task }> = ({ task }) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const handleStateToggle = useCallback(
        (togglingTowardActive: boolean, toggleWatcherId: WatcherID) => {
            if (togglingTowardActive) dispatch(TaskDispatchOpen(toggleWatcherId, task.selfLink!))
            else dispatch(TaskDispatchClose(toggleWatcherId, task.selfLink!))
        },
        [dispatch, task.selfLink]
    )

    const handleStateOpen = useCallback(
        (e, watcherId) => {
            dispatch(TaskDispatchOpen(watcherId, task.selfLink!))
        },
        [dispatch, task.selfLink]
    )

    const handleStateClose = useCallback(
        (e, watcherId) => {
            dispatch(TaskDispatchClose(watcherId, task.selfLink!))
        },
        [dispatch, task.selfLink]
    )

    const handleImportantToggle = useCallback(
        (togglingTowardActive: boolean, toggleWatcherId: WatcherID) => {
            if (!task.selfLink) return
            if (togglingTowardActive) dispatch(TaskDispatchAssignTag(toggleWatcherId, task.selfLink, 'important'))
            else dispatch(TaskDispatchRemoveTag(toggleWatcherId, task.selfLink, 'important'))
        },
        [dispatch, task]
    )

    const handleAgentSelection = useCallback(
        (agent: string, watcherId: WatcherID) => {
            if (task.selfLink) dispatch(TaskDispatchAssignAgent(watcherId, task.selfLink, agent))
        },
        [dispatch, task]
    )

    if (task.loadingStatus != 'done') return null

    return (
        <Flex>
            {task.state === 'new' ? (
                <>
                    <WatcherButton
                        predefinedWatcher="AlertPage.ChangeAlertStatus"
                        onClick={handleStateOpen}
                        background="front.background"
                        color="front.danger.color"
                    >
                        <ButtonInset>Open</ButtonInset>
                    </WatcherButton>
                    <Spacer width={10} />
                    <WatcherButton
                        predefinedWatcher="AlertPage.ChangeAlertStatus"
                        onClick={handleStateClose}
                        background="front.accent.color"
                        color="front.accent.text"
                    >
                        <ButtonInset>Close</ButtonInset>
                    </WatcherButton>
                </>
            ) : (
                <ToggleWatcherButton
                    inactiveBackground="front.background"
                    inactiveLabel="Open"
                    cy="alert-toggle"
                    inactiveColor="front.danger.color"
                    activeLabel="Close"
                    activeColor="front.accent.text"
                    activeBackground="front.accent.color"
                    predefinedWatcher="AlertPage.ChangeAlertStatus"
                    isActive={task.state === 'open'}
                    onToggle={handleStateToggle}
                    hotkeysScope="AlertPage"
                    activeHotkeys="alt+c"
                    inactiveHotkeys="alt+o"
                />
            )}
            <Spacer width={10} />
            <TextInputAgentSelector
                onSelect={handleAgentSelection}
                selectedAgent={task.assignee}
                pageId="AlertPage"
                overBackground="back.background"
            />
            <Spacer width={10} />
            <ToggleWatcherButton
                inactiveColor="front.danger.color"
                inactiveBackground="front.background"
                inactiveLabel="Mark «Important»"
                activeColor="front.danger.text"
                activeBackground="front.danger.color"
                activeLabel="Unmark «Important»"
                predefinedWatcher="AlertPage.ChangeAlertImportance"
                isActive={task.tags.includes('important')}
                onToggle={handleImportantToggle}
                hotkeysScope="AlertPage"
                activeHotkeys="alt+u"
                inactiveHotkeys="alt+i"
            />
            <Spacer width={10} />
            <LinkButton
                background="front.background"
                cy="alert-tags"
                hotkeysScope="AlertPage"
                hotkeys="alt+t"
                to={{
                    pathname: `${location.pathname}/tags`,
                    search: location.search
                }}
                state={{
                    backTo: location.pathname
                }}
            >
                <ButtonInset>Tags</ButtonInset>
            </LinkButton>
        </Flex>
    )
}
