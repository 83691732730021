import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'

export const CompanyMid: React.FC = () => {
    const merchantData = useReportMerchantData()

    const mids = merchantData.accounts.map((account) => account.merchant_id).filter(Boolean)

    const value = mids.length !== 0 ? mids.join(', ') : '-'

    return <Section.DataItem title="Mid(s)" value={value} />
}
