import { useCallback, useRef } from 'react'
import { v4 as uuid } from 'uuid'

export const useOnBlurOutside = () => {
    const parentClassName = useRef(`P${uuid()}`)
    const isBlurOutsideBounds = useCallback((e) => {
        if (!e?.relatedTarget) return true
        return e.relatedTarget.closest(`.${parentClassName.current}`) === null
    }, [])
    return {
        blurBoundaryClassName: parentClassName.current,
        isBlurOutsideBounds
    }
}
