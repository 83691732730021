import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Text } from '../../../components/general/text'
import { LoaderView } from '../../../components/loaders/loader'
import { FileActionDispatchGenerateDownloadLink } from '../../../store/files/actions'
import { RootState } from '@/store'
import { useRelevantWebsites } from './Application.useRelevantWebsites'
import { MerchantApplicationWebsiteBox } from './Application.WebsiteBox'

export const WebsiteSectionScreenshots: React.FC<{ applicationId: string; index: number }> = ({
    applicationId,
    index
}) => {
    const dispatch = useDispatch()
    const filesHaveBeenFetched = useRef(false)
    const test = useRelevantWebsites(applicationId, index)
    const websites = useSelector(
        (state: RootState) => state.applicationResources?.data?.forApplication[applicationId]?.websites
    )

    useEffect(() => {
        if (filesHaveBeenFetched.current) return
        if (!websites) return
        for (const website of websites.fields) if (website?.filesLoadingStatus !== 'done') return
        filesHaveBeenFetched.current = true
        test.all.forEach((category: string) => {
            const files = test.at[category]
            if (files)
                files.forEach((file: any) => {
                    if (!file) return
                    dispatch(FileActionDispatchGenerateDownloadLink(file))
                })
        })
    }, [websites, test, dispatch])

    if (!filesHaveBeenFetched.current) return <LoaderView overBackground="back.background" />

    return (
        <Holder>
            {test.all.length > 0 ? (
                test.all.map((k: any) => (
                    <Section key={k}>
                        <Header>
                            <Text color="back.text" bold>
                                {k} ({test.at[k].length})
                            </Text>
                        </Header>
                        <Boxes>
                            {test.at[k].map((file: any, i: any) => {
                                if (!file || !file.label) return
                                return (
                                    <MerchantApplicationWebsiteBox
                                        index={i}
                                        merchantId={applicationId}
                                        websiteIndex={index}
                                        section={k}
                                        key={file.id}
                                        file={file}
                                    />
                                )
                            })}
                        </Boxes>
                    </Section>
                ))
            ) : (
                <Centerer>
                    <Text color="back.text">No screenshots captured.</Text>
                </Centerer>
            )}
        </Holder>
    )
}

const Boxes = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Header = styled.div`
    width: 100%;
    margin-bottom: 10px;
`
const Section = styled.div`
    padding: 20px;
    background: ${(p) => p.theme['back.background.strongerI']};
    border-radius: 20px;
    margin: 10px;
`

const Holder = styled.div`
    display: flex;
    width: 100%;
    max-width: calc(${290 * 3}px);
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
`
const Centerer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
