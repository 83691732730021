import { useGetIntegrations } from '@/services/merchant-api'

export const useEmbeddedIntegrations = (url?: string) => {
    const link = url ? url : ''
    const integrations = useGetIntegrations(link).data

    const embeddedIntegrations = integrations?.embedded['ch:integrations']
    const hasEmbeddedIntegrations = embeddedIntegrations && embeddedIntegrations.length > 0

    const embeddedIntegrationsArray = hasEmbeddedIntegrations ? embeddedIntegrations : []

    return { integrations, embeddedIntegrations: embeddedIntegrationsArray }
}
