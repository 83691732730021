import { kebabCase } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useOnHoverOutside } from '../../hooks/general/useOnHoverOutside'
import { ButtonInset } from '../buttons/buttonInset'
import { SimpleButton } from '../buttons/simpleButton'
import { Floater } from '../layout/floater'
import { FloaterInset } from '../layout/floaterInset'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { ListPageFiltersShowHideController } from '../../hooks/pages/useListPage'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'
import { zIndexes } from '../../styles/zIndexes'

export type PopularFiltersConfig = { label: string; action: () => void } | string

export const PopularFilters: React.FC<{
    showHideController: ListPageFiltersShowHideController
    config: PopularFiltersConfig[]
    hotkeysScope: MODAL_ID
    buttonLabel?: string
}> = ({ showHideController, config, hotkeysScope, buttonLabel }) => {
    const [anchor, setAnchor] = useRefTaker()
    const [holder, setHolder] = useRefTaker()

    const handleFilterClick = useCallback(
        (item?: any) => {
            showHideController.set((s) => ({
                ...s,
                showPopular: !s.showPopular
            }))
            item?.action?.()
        },
        [showHideController]
    )

    const handleMouseOutside = useCallback(() => {
        showHideController.set((s) => ({
            ...s,
            showPopular: false
        }))
    }, [showHideController])

    useOnHoverOutside(holder, handleMouseOutside)

    const renderedList = useMemo(() => {
        return config.map((item, i) => {
            if (typeof item === 'string') {
                if (i === 0) return null
                return <Spacer height={10} />
                // <ListPageFiltersSeparator key={i} />
            }

            return (
                <SimpleButton
                    color="floating.text"
                    cy={kebabCase(item.label)}
                    key={item.label}
                    onClick={() => {
                        handleFilterClick(item)
                    }}
                >
                    <ButtonInset leftAlign noHorizontalPadding>
                        {parseString(item.label)}
                    </ButtonInset>
                </SimpleButton>
            )
        })
    }, [config, handleFilterClick])

    return (
        <Holder key="popularFilters" ref={setHolder}>
            <div ref={setAnchor}>
                <SimpleButton
                    background="front.background"
                    cy="popular-filters"
                    onClick={handleFilterClick}
                    hotkeysScope={hotkeysScope}
                    hotkeys="alt+p"
                    hotkeysClueDirection="bottom-right"
                >
                    <ButtonInset>{buttonLabel ? buttonLabel : 'Popular filters'}</ButtonInset>
                </SimpleButton>
            </div>
            <Floater
                cardId="ListPage.PopularFilters"
                anchor={anchor}
                cy="popular-filters"
                shouldShow={showHideController.showPopular}
                onHide={() => {
                    showHideController.set((s) => ({
                        ...s,
                        showPopular: false
                    }))
                }}
            >
                <FloaterInset padding="medium">{renderedList}</FloaterInset>
            </Floater>
        </Holder>
    )
}

const parseString = (s: string) => {
    return s.split('*').map((t: string, i: number) => {
        if (i % 2 === 1)
            return (
                <React.Fragment key={t}>
                    <Text oneLine bold>
                        {`${t} `}
                    </Text>
                    <Spacer width={3} />
                </React.Fragment>
            )
        return (
            <React.Fragment key={t}>
                <Text oneLine>{`${t} `}</Text>
                <Spacer width={3} />
            </React.Fragment>
        )
    })
}

const Holder = styled.div`
    position: relative;
    z-index: ${zIndexes.popoverLevel3};
`
