import TrackJSLogger from '../trackjs/trackJSLogger'
import { configureStore } from '@reduxjs/toolkit'
import { createReducers } from './rootReducer'
import createSagaMiddleware from 'redux-saga'
import { api as merchantApi } from '@/services/merchant-api'
import { api as ongoingDueDiligenceApi } from '@/services/ongoing-due-diligence'
import { api as reconciliationApi } from '@/services/reconciliation'
import { api as riskClassificationApi } from '@/services/risk-classification'
import rootSaga from './rootSaga'
import { api as taskrApi } from '@/services/taskr'
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger'

const defaultMiddlewareSetup = {
    // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    serializableCheck: false,
    // Checks if mutations happen, a provide a warning. These errors need to be fixed.
    immutableCheck: false
}

export default function configureReduxStore() {
    const sagaMiddleware = createSagaMiddleware()

    const store = configureStore({
        reducer: createReducers(),
        middleware: (getDefaultMiddleware) => {
            const commonMiddleware = getDefaultMiddleware(defaultMiddlewareSetup).concat(
                sagaMiddleware,
                rtkQueryErrorLogger,
                ongoingDueDiligenceApi.middleware,
                reconciliationApi.middleware,
                riskClassificationApi.middleware,
                taskrApi.middleware,
                merchantApi.middleware
            )

            if (import.meta.env.VITE_TRACK_JS_TOKEN) {
                return commonMiddleware.concat(TrackJSLogger)
            } else {
                return commonMiddleware
            }
        }
    })
    sagaMiddleware.run(rootSaga)
    return store
}

const store = configureReduxStore()

export { store }

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
