import { LoadingStatus } from '../../utils'
import { Application, Company, Person, PersonVerificationFilesKeys } from '../applications/types'
import { File } from '../files/types'

export interface TasksCompanyChangesState {
    [taskId: string]: {
        loadingStatus: LoadingStatus
        changes?: MonitoredChangesState
    }
}
export const InitialTasksCompanyChangesState: TasksCompanyChangesState = {}

export interface NeededExtraRequestsPersonWithCutterInfo extends Person {
    cutter: {
        ownershipPercentage?: number
        notePerson?: string
        selfLink: string
    }
}
export type NeededExtraRequestsPayload = {
    [applicationId: string]: {
        application: Application
        company: Company
        muted: { [key: string]: { [key: string]: string[] } }
        providerOwnership: {
            name: string
            ownershipPercentage: number
        }[]
        people: NeededExtraRequestsPersonWithCutterInfo[]
        filesByPersonId: {
            [personId: string]: {
                [fileLabel in PersonVerificationFilesKeys]: File
            }
        }
    }
}
export type MonitoredChangeKind =
    | 'company-name-replace'
    | 'ownership-beneficial-add'
    | 'director-name-replace'
    | 'other'

export type MonitoringAfter = number | string | { [key: string]: string | number }

export type MonitoredChange = {
    applicationValue: string
    subsectionId?: string
    before: string
    after: MonitoringAfter
    field: string
    kind: MonitoredChangeKind
}

export type BisnodeUBOAddedInfo = {
    country: string
    dateOfBirth: string
    name: string
    ownershipPercentage: number
}

export type MonitoredFetchedApplicationData = NeededExtraRequestsPayload['id'] | undefined

export type BatchOfMonitoredChanges = {
    changes: MonitoredChange[]
    applicationId: string
    fetchedApplicationData: {
        loadingStatus: LoadingStatus
        data?: MonitoredFetchedApplicationData
    }
}
export type MonitoredChangesState = {
    [applicationId: string]: BatchOfMonitoredChanges
}

export type CompanyChangePayload = {
    op: 'replace'
    path: string
    previous: string
    recordValues: {
        recordId: string
        resourceId: string
        value: string
    }[]
    values: string
}
export type CompanyChangesFromBackend = {
    at: number
    data: {
        data: {
            id: string
            changes: CompanyChangePayload[]
        }
        id: string
        name: string
        priority: string
        selfLink: string
        taskId: string
        type: 'changes'
    }
    type: 'event'
}[]
