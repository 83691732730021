import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Table, TableRow } from '../../../components/tables/table'
import { Text } from '../../../components/general/text'
import { ModalPage } from '../../../components/layout/modalPage'
import { ModalPageInset } from '../../../components/layout/modalPageInset'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../../utils'
import { camelCase } from 'lodash'
import { ApplicationStatus } from '../../../components/general/applicationStatus'
import { Flex } from '../../../components/layout/flex'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { useParams } from 'react-router-dom'
import { ApplicationInternalsRelatedActions } from '@/store/applicationInternals/related/actions'
import { FetchMoreButton } from '@/components/buttons/fetchMoreButton'

type Params = { id: string }

export const MerchantExploreRelated: React.FC = () => {
    const params = useParams() as Params

    const name = useMerchantName(params.id, 'Related applications')
    const related = useSelector((state: RootState) => state.applicationInternals.related.forApplication[params.id])

    const columns = useMemo(() => {
        const acc: any = {}
        related?.related?.forEach((r) => {
            r.matchingOn.forEach((c) => {
                acc[camelCase(c.split(': ')[0])] = true
            })
        })
        return {
            array: Object.keys(acc),
            forTable: Object.keys(acc).map((k) => ({
                text: uppercaseFirstLetter(k.replace('application', ''))
            }))
        }
    }, [related])

    const rows = useMemo(() => {
        if (!related?.related?.length) return [] as TableRow[]

        return related.related.map((r) => {
            const dictionary = r.matchingOn.reduce((acc, c) => {
                acc[camelCase(c.split(': ')[0])] = c.split(': ')[1] || '-'
                return acc
            }, {} as any)

            return {
                type: 'normal',
                key: r.id,
                link: `/merchant/${r.id}`,
                linkTarget: 'consoleApplication',
                items: [
                    {
                        node: r.state ? (
                            <Flex>
                                <ApplicationStatus status={r.state} />
                            </Flex>
                        ) : (
                            '-'
                        )
                        // <Text>{r.state ? uppercaseFirstLetter(r.state) : '-'}</Text>
                    },
                    {
                        node: (
                            <Text noWrap bold>
                                {r.mid || '-'}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text noWrap bold oneLine>
                                {r.name}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text noWrap oneLine>
                                {r.closedAccounts}
                            </Text>
                        )
                    },
                    
                    ...columns.array.map((k) => {
                        return {
                            node: <Text color={'front.text.subtlerI'}>{dictionary[k]}</Text>
                        }
                    })
                ]
            } as TableRow
        })
    }, [related, columns])

    const cols = useMemo(() => {
        return [
            {
                text: 'State'
            },
            {
                text: 'MID'
            },
            {
                text: 'Company'
            },
            {
                text: 'Closed Accounts'
            },
            ...columns.forTable
        ]
    }, [columns])

    return (
        <ModalPage
            title={name}
            pageId="Merchant.Summary.RelatedApplications"
            backTo={`/merchant/${params.id}/summary`}
            isLoading={related?.loadingStatus !== 'done'}
            noExitOnBackgroundClick
        >
            <ModalHeader
                title="Related Applications"
                pageId="Merchant.Summary.RelatedApplications"
                backTo={`/merchant/${params.id}/summary`}
                keepBackTo
            />
            <ModalPageInset>
                <Card>
                    <CardInset>
                        <Table
                            cols={cols}
                            rows={rows}
                            background="front.background"
                            columnLayout={`min-content min-content min-content min-content ${columns.array
                                .map((k, i, arr) => (i === arr.length - 1 ? 'auto' : 'auto'))
                                .join(' ')}`}
                        />
                    </CardInset>
                </Card>
                {
                    !related?.nextLink ? null :
                        <FetchMoreButton
                            actions={ApplicationInternalsRelatedActions.LOAD_NEXT(params.id, 
                                related.related, related.nextLink )}
                            loadingStatus={related.loadingNextStatus}
                        />
                }
            </ModalPageInset>
        </ModalPage>
    )
}
