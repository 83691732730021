import React, { useCallback, useState } from 'react'
import { Grid } from '../../components/layout/grid'
import { List } from '../../components/layout/list'
import { Separator } from '../../components/layout/separator'
import { Spacer } from '../../components/layout/spacer'
import * as yup from 'yup'
import { usePricingSchemes } from '../Merchant/Accounts/usePricingSchemeDropdownConfig'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { SimpleButton } from '../../components/buttons/simpleButton'
import styled from 'styled-components'
import { useGateways } from '../../hooks/general/useGateways'
import { AvailableSettlementCurrencies } from '../../store/settlements/types'
import { FormFieldFormatting, ValidationStatus } from '../../hooks/general/useForm'
import { LocallyStoredContractTemplate } from '../../store/contractTemplates/types'
import { InlineLocalContractFormFields } from '../../store/contractTemplates/helpers'

const nameForOption = (k: string) => {
    return k
}
const defaultTemplateOptions = [false, true]

export type ManageNewContractTemplateSectionProps = {
    errors: { [key: string]: ValidationStatus }
    executeBatchChanges?: (changes: { [key: string]: string }) => void
    formRef: (node: any, name: string, validationRules: yup.Schema<any>, formatting?: FormFieldFormatting) => void
    t: LocallyStoredContractTemplate
    suffix?: string
    currency?: string
}

export const ManageNewContractTemplateSectionGeneral: React.FC<ManageNewContractTemplateSectionProps> = ({
    errors,
    executeBatchChanges,
    t,
    formRef,
    currency
}) => {
    const gateways = useGateways()

    const textForGatewaySelect = useCallback((id: string) => gateways.at[id]?.name, [gateways])
    const [templates, config] = usePricingSchemes(currency)
    const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>()

    const textForItem = useCallback((val: any) => {
        if (val === true) return 'Yes'
        if (val === false) return 'No'
        return val
    }, [])

    const handleApplyTemplate = useCallback(() => {
        if (!selectedTemplate) return
        const inlinedChanges = InlineLocalContractFormFields(templates.at[selectedTemplate])
        if (inlinedChanges['standard']) delete inlinedChanges['standard']
        if (inlinedChanges['gatewayId']) delete inlinedChanges['gatewayId']

        // ^ Do not mark the current template as default,
        // even if the previous one has been

        executeBatchChanges?.(inlinedChanges)
        setSelectedTemplate(undefined)
    }, [executeBatchChanges, templates, selectedTemplate])

    return (
        <>
            <TemplateHolder>
                <List
                    background="overlay.background"
                    alignRight
                    items={{
                        'Copy from template': {
                            type: 'select',
                            overBackground: 'overlay.background',
                            placeholder: 'Apply the values from another template',
                            selected: selectedTemplate,
                            onSelect(id: string) {
                                setSelectedTemplate(id)
                            },
                            noClear: true,
                            dropdownId: 'Merchant.AccountsPage.Contracts.NewContract',
                            items: config.items,
                            textForItem: config.textForItem,
                            tableLike: config.tableLike
                        }
                    }}
                    switchToRowsAt={10000}
                    cellHorizontalTemplate="min-content auto"
                />
                <Spacer width={10} />
                <ButtonOffset>
                    <SimpleButton
                        isDisabled={!selectedTemplate}
                        onClick={handleApplyTemplate}
                        cy="apply"
                        background={'front.subtleAccent.background'}
                    >
                        <ButtonInset>Apply override</ButtonInset>
                    </SimpleButton>
                </ButtonOffset>
            </TemplateHolder>
            <Spacer height={15} />
            <Separator />
            <Spacer height={20} />
            <Grid horizontalTemplate="1fr 1px 1fr 1px 1fr" verticalTemplate="auto auto auto" switchToRowsAt={1100}>
                <List
                    background="overlay.background"
                    alignRight
                    items={{
                        'For Gateway': {
                            type: 'select',
                            textForItem: textForGatewaySelect,
                            items: gateways.all,
                            dropdownId: 'Manage.ContractTemplates.New',
                            rightAlign: true,
                            selected: t.gatewayId,
                            lazyLoaded: true,
                            placeholder: '',
                            noClear: true,
                            overBackground: 'overlay.background',
                            validation: errors['gatewayId'],
                            ref: (ref) => formRef(ref, 'gatewayId', yup.string())
                        }
                    }}
                    switchToRowsAt={10000}
                    rowGap="tiny"
                    cellHorizontalTemplate="min-content auto"
                />
                <Separator vertical />
                <List
                    background="overlay.background"
                    alignRight
                    items={{
                        'For Currency': {
                            type: 'select',
                            rightAlign: true,
                            selected: t.currency,
                            textForItem: nameForOption,
                            dropdownId: 'Manage.ContractTemplates.Currency',
                            noClear: true,
                            items: AvailableSettlementCurrencies,
                            placeholder: '',
                            overBackground: 'overlay.background',
                            validation: errors['currency'],
                            ref: (ref) => formRef(ref, 'currency', yup.string())
                        }
                    }}
                    switchToRowsAt={10000}
                    rowGap="tiny"
                    cellHorizontalTemplate="min-content auto"
                />
                <Separator vertical />
                <List
                    background="overlay.background"
                    alignRight
                    items={{
                        'Is default template': {
                            type: 'select',
                            rightAlign: true,
                            selected: t.standard || false,
                            // t.standard || false
                            items: defaultTemplateOptions,
                            placeholder: '',
                            noClear: true,
                            textForItem,
                            dropdownId: 'Manage.ContractTemplates.New.DefaultTemplate',
                            overBackground: 'overlay.background',
                            validation: errors['standard'],
                            ref: (ref) => formRef(ref, 'standard', yup.mixed())
                        }
                    }}
                    switchToRowsAt={10000}
                    rowGap="tiny"
                    cellHorizontalTemplate="min-content auto"
                />
            </Grid>
        </>
    )
}

const TemplateHolder = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
`

const ButtonOffset = styled.div`
    margin-top: -20px;
`
