import moment from 'moment'
import React, { ReactElement, useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { DialogContext } from '../../contexts/dialogProvider'
import { RootState } from '@/store'
import { WatcherID } from '../../store/watcher/types'
import { isAgent as utilIsAgent } from '../../utils/index'
import { DateFormats } from '../../utils/dateUtils'
import { Account } from '../general/account'
import { AgentBubble } from '../general/agentBubble'
import { SimpleButton } from '../buttons/simpleButton'
import { Flex } from '../layout/flex'
import { MarkdownHolder } from '../general/markdownHolder'
import { Spacer } from '../layout/spacer'
import { Text } from '../general/text'
import { UserCommentEdit } from './userCommentEdit'
import { useReadCommentsOnScroll } from '../../hooks/general/useReadCommentsOnScroll'

export const UserComment: React.FC<{
    authorType: 'email' | 'id' | 'email+name'
    author: string
    authorName?: string
    applicationId?: string
    date: string
    body: string | ReactElement
    id?: string
    onEdit?: (id: string, watcherId: WatcherID, val: string) => void
    onDelete?: (id: string, watcherId: WatcherID) => void
    isUnread?: boolean
    towardsMerchants?: boolean
    deletableAtAnyPoint?: boolean
    rightSide?: ReactElement
    cy?: string
}> = ({
    date,
    id,
    body,
    cy,
    isUnread,
    author,
    authorName,
    onDelete,
    applicationId,
    rightSide,
    towardsMerchants,
    authorType,
    onEdit,
    deletableAtAnyPoint
}) => {
    const dialogContext = useContext(DialogContext)
    const [isInEditMode, setIsInEditMode] = useState(false)
    const { setRef } = useReadCommentsOnScroll(applicationId, id, isUnread)

    const isAgent = useSelector((state: RootState) => {
        if (authorType === 'id') {
            return utilIsAgent(state.userAccounts.at[author]?.data?.email)
        }
        return utilIsAgent(author)
    })

    const renderedAuthor = useMemo(() => {
        let authorString = author
        if (authorType === 'email+name') authorString = authorName + (author ? `(${author})` : '')
        if (authorType === 'email' || authorType === 'email+name')
            return (
                <Flex align="center">
                    <Author>
                        <AgentBubbleOffset>
                            <AgentBubble staticEmail={author} staticName={authorName} />
                        </AgentBubbleOffset>
                        <Spacer width={15} />
                    </Author>
                    <Text bold oneLine>
                        {authorString}
                    </Text>
                </Flex>
            )
        return (
            <Flex align="center">
                <Author>
                    <AgentBubble id={author} />
                    <Spacer width={15} />
                </Author>
                <b>
                    <Account id={author} />
                </b>
            </Flex>
        )
    }, [author, authorType, authorName])

    const handleEditModeCancel = useCallback(() => {
        setIsInEditMode(false)
    }, [setIsInEditMode])

    const handleEditModeSuccess = useCallback(
        (val: string) => {
            if (id) onEdit?.(id, `${id}.EditComment`, val)
        },
        [onEdit, id]
    )

    const renderedEditModeButton = useMemo(() => {
        if (!onEdit) return null

        if (!deletableAtAnyPoint) {
            if (moment(date).isBefore(moment().subtract(5, 'minutes'))) return null
        }

        return (
            <>
                <SimpleButton cy="user-comment-edit" onClick={() => setIsInEditMode(true)}>
                    Edit
                </SimpleButton>
                <Spacer width={10} />
            </>
        )
    }, [date, deletableAtAnyPoint, onEdit])

    const renderedDeleteButton = useMemo(() => {
        if (!id) throw 'Comment id could not be found.'

        if (!deletableAtAnyPoint) {
            if (moment(date).isBefore(moment().subtract(5, 'minutes'))) return null
        }

        if (towardsMerchants && !isAgent) return null

        if (!onDelete) return null
        const handleDelete = (e: any) => {
            dialogContext?.setDialog({
                title: 'Confirm comment deletion',
                description: 'Are you sure you want to delete this comment permanently?',
                action: {
                    label: 'Delete permanently',
                    buttonBackground: 'front.danger.color',
                    watcherId: `${id}.RemoveComment`,
                    action: () => {
                        onDelete?.(id, `${id}.RemoveComment`)
                    }
                }
            })
        }

        return (
            <>
                <SimpleButton cy="uc-delete" onClick={handleDelete}>
                    Remove
                </SimpleButton>
                <Spacer width={10} />
            </>
        )
    }, [dialogContext, isAgent, deletableAtAnyPoint, towardsMerchants, date, id, onDelete])

    if (isInEditMode && typeof body === 'string')
        return (
            <Holder ref={setRef} isUnread={isUnread}>
                <UserCommentEdit
                    editedCommentBody={body}
                    watcherId={`${id}.EditComment`}
                    onCancel={handleEditModeCancel}
                    onEdit={handleEditModeSuccess}
                />
            </Holder>
        )

    return (
        <Holder ref={setRef} data-cy={`${cy} user-comment ${isUnread ? 'unread' : ''}`} isUnread={isUnread}>
            <Header>
                {renderedAuthor}
                <Flex noShrink align="baseline">
                    <Text color="front.text.subtlerI" oneLine>
                        {moment(date).format(DateFormats.dayAndTime(moment(date)))}
                    </Text>
                    {renderedDeleteButton || renderedEditModeButton || rightSide ? <Spacer width={10} /> : null}
                    {renderedDeleteButton}
                    {typeof body === 'string' ? renderedEditModeButton : null}
                    {rightSide}
                </Flex>
            </Header>
            <Body isAgent={isAgent}>{typeof body === 'string' ? <MarkdownHolder>{body}</MarkdownHolder> : body}</Body>
        </Holder>
    )
}

const Body = styled.div<{ isAgent?: boolean }>`
    padding-left: 43px;
`

const Holder = styled.div<{ isUnread?: boolean }>`
    display: flex;
    flex-direction: column;
    margin-top: -4px;
    align-items: stretch;
    width: 100%;
    justify-content: space-between;
    color: ${(p) => p.theme['front.text']};
    transition: 0.2s ease-in-out background-color;
    border-radius: 10px;
    border: none;

    ${(p) =>
        p.isUnread &&
        css`
            background-color: ${p.theme['front.subtleAccent.background']};
        `}
`

const Header = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    align-items: center;
    justify-content: space-between;
    @media print {
        padding-bottom: 2px;
        line-height: 10px;
        border-bottom: 1px solid #efefef;
        align-items: baseline;
        margin-bottom: 10px;
    }
`

const Author = styled.div`
    display: contents;

    @media print {
        display: none;
    }
`

const AgentBubbleOffset = styled.div`
    position: relative;
`
