import { createApi } from '@reduxjs/toolkit/query/react'
import { halBaseQuery } from '../common/hal-base-query'

const reducerPath = 'taskr-api'
const tagTypes = ['tasks']

const baseQuery = halBaseQuery()

export const api = createApi({
    reducerPath,
    baseQuery,
    tagTypes,
    endpoints: () => ({})
})
