import React, { useMemo } from 'react'
import { useApplicationValidation } from '../../../hooks/application/useApplicationValidation'
import { ApplicationValidationSectionItem } from './Application.ValidationSection.Item'

type ApplicationValidationSectionWebsitesProps = {
    validationResults: ReturnType<typeof useApplicationValidation>[0]
}

export const ApplicationValidationSectionWebsites: React.FC<ApplicationValidationSectionWebsitesProps> = ({
    validationResults
}) => {
    const websitesResults = useMemo(() => {
        if (validationResults === undefined || !Array.isArray(validationResults)) {
            return null
        }

        return validationResults.filter((validation) => validation.path && validation.path.startsWith('websites'))
    }, [validationResults])

    if (!websitesResults || websitesResults.length === 0) {
        return null
    }

    return (
        <ApplicationValidationSectionItem testid="validation-websites">
            Website section has missing data
        </ApplicationValidationSectionItem>
    )
}
