import React from 'react'

interface ApplicationValidationSectionItemProps {
    testid?: string
    children: string
}

export const ApplicationValidationSectionItem: React.FC<ApplicationValidationSectionItemProps> = (props) => {
    const { testid, children } = props

    return (
        <li
            data-cy={testid}
            className="leading-loose text-danger-700 dark:text-neutral-200"
            dangerouslySetInnerHTML={{ __html: children }}
        />
    )
}
