import { useEffect } from 'react'
import { useBlocker } from 'react-router-dom'

export const useRouteGuard = (message: string, when: boolean): void => {
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return when && currentLocation.pathname !== nextLocation.pathname
    })

    useEffect(() => {
        if (blocker.state === 'blocked') {
            const proceed = window.confirm(message)
            if (proceed) {
                // This timeout is needed to avoid a weird "race" on POP navigations
                // between the `window.history` revert navigation and the result of
                // `window.confirm`
                setTimeout(blocker.proceed, 0)
            } else {
                blocker.reset()
            }
        }
    }, [blocker, message])

    useEffect(() => {
        if (blocker.state === 'blocked' && !when) {
            blocker.reset()
        }
    }, [blocker, when])
}
