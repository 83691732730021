import { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { MarkdownHolder } from '../../../components/general/markdownHolder'
import { Text } from '../../../components/general/text'
import { Icon } from '../../../components/icons/icon'
import { Floater } from '../../../components/layout/floater'
import { FloaterInset } from '../../../components/layout/floaterInset'
import { useOnHoverOutside } from '../../../hooks/general/useOnHoverOutside'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { MerchantWarning } from '../../../store/applicationInternals/warning/types'

export const SummaryBriefWarning: React.FC<{ warning: MerchantWarning }> = ({ warning }) => {
    const [show, setShow] = useState(false)
    const [ref, setRef] = useRefTaker()
    const [holderRef, setHolderRef] = useRefTaker()
    const timer = useRef<any | undefined>()

    const hasExited = useCallback(() => {
        if (timer) {
            clearTimeout(timer.current)
            timer.current = null
        }
        setShow(false)
    }, [])
    useOnHoverOutside(holderRef, hasExited)

    const render = useMemo(() => {
        switch (warning.type) {
            case 'merchant-account-no-transaction':
                return (
                    <Text size="xs" oneLine>
                        No trans.
                    </Text>
                )
            case 'merchant-contract-no-signature':
                return (
                    <Text size="xs" oneLine>
                        No sign.
                    </Text>
                )
            case 'general':
                return null
        }
    }, [warning])

    return render ? (
        <span ref={setHolderRef}>
            <Floater anchor={ref} shouldShow={show}>
                <FloaterInset>
                    <Text color="floating.text">
                        {warning.title ? <MarkdownHolder>{warning.title}</MarkdownHolder> : null}
                    </Text>
                </FloaterInset>
            </Floater>
            <Capsule
                onMouseEnter={() => {
                    timer.current = setTimeout(() => {
                        setShow(true)
                    }, 400)
                }}
                onMouseLeave={hasExited}
                ref={setRef}
            >
                <IconHolder>
                    <Icon type="warning" size={10} />
                </IconHolder>
                {render}
            </Capsule>
        </span>
    ) : null
}

const Capsule = styled.div`
    padding: 0px 4px;
    border-radius: 6px;
    line-height: 0.8em;
    background-color: ${(p) => p.theme['front.subtleInfo.background']};
    border: 1px solid ${(p) => p.theme['front.subtleInfo.border']};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: help;
`

const IconHolder = styled.div`
    position: relative;
    top: 0px;
    margin-right: 3px;
`
