import { CollectionWSeparateLoading, LoadingStatus } from '../../utils'

export interface UserAccount {
    data: any
    loadingStatus: LoadingStatus
}

export type UserAccountsState = CollectionWSeparateLoading<UserAccount> & {
    atMid: { [key: string]: string }
}

export const UserAccountsInitialState: UserAccountsState = {
    at: {},
    atMid: {},
    all: []
}
