import React from 'react'
import { Card } from '../../../../../../components/cards/card'
import { CardInset } from '../../../../../../components/cards/cardInset'
import { Spacer } from '../../../../../../components/layout/spacer'
import { Text } from '../../../../../../components/general/text'

export const EmptyChangesEvents: React.FC = () => {
    return (
        <Card background="back.background.strongerI" shadowLess>
            <CardInset>
                <Text bold>
                    Something looks wrong with this task. <br />
                    Please leave it in the current state, the non-PCI/console team is working on it.
                </Text>
                <Spacer height={10} />
                <Text color="front.text.subtlerI">The events array for this task contains no company info changes</Text>
            </CardInset>
        </Card>
    )
}
