import { v4 as uuid } from 'uuid'

import { Toast } from './types'

export const TOASTS_PUSH = 'TOASTS_PUSH'
export const TOASTS_POP = 'TOASTS_POP'

//-

export interface ToastsActionPush {
    type: typeof TOASTS_PUSH
    toast: Toast
}
export interface ToastsActionPop {
    type: typeof TOASTS_POP
    toastId: string
}

//-

export const ToastsDispatchPush = (
    message: string | React.ReactNode,
    type?: 'success' | 'error' | 'call' | 'context',
    id?: string,
    duration?: 'shorter' | 'normal' | 'longer' | 'infinite',
    context?: any
): ToastsActionPush => {
    return {
        type: TOASTS_PUSH,
        toast: {
            message,
            type: type || 'error',
            id: id || uuid(),
            duration: duration || 'normal',
            context
        }
    }
}

export const ToastsDispatchPop = (toastId: string): ToastsActionPop => {
    return { type: TOASTS_POP, toastId }
}

//-

export interface SagasForToasts {
    pushToast(action: ToastsActionPush): void
}

export type ReducedToastsActions = ToastsActionPop | ToastsActionPush
