/* eslint-disable no-param-reassign */
import { createReducer } from 'deox'
import dotProp from 'dot-prop'
import { produce } from 'immer'
import { merge } from 'lodash'
import { AdjustContractOrContractTemplateForLocalStorage } from '../contractTemplates/helpers'

import { MerchantAccountsActions } from './actions'
import { InitialMerchantAccountsState, MerchantAccountMember } from './types'

export const MerchantAccountsReducer = createReducer(InitialMerchantAccountsState, (handleAction) => [
    handleAction(MerchantAccountsActions.FETCH_CONTRACT_HISTORY, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft.at) return
            if (!draft.at[p.accountId]) return
            if (state.at[p.accountId]?.contractHistoryLoadingStatus !== 'done')
                draft.at[p.accountId] = {
                    ...draft.at[p.accountId],
                    contractHistoryLoadingStatus: 'started'
                }
        })
    }),
    handleAction(MerchantAccountsActions.REFETCH_UPDATED_DRAFT_CONTRACT, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft.at) return
            if (!draft.at[p.accountId]) return
            if (p.skipLoadingStatusChange) return
            if (state.at[p.accountId]?.draft?.loadingStatus !== 'done')
                draft.at[p.accountId] = {
                    ...draft.at[p.accountId],
                    draft: {
                        ...draft.at[p.accountId].draft,
                        loadingStatus: 'started'
                    }
                }
        })
    }),
    handleAction(MerchantAccountsActions.CLEAR, () => {
        return JSON.parse(JSON.stringify(InitialMerchantAccountsState))
    }),
    handleAction(MerchantAccountsActions.STORE_DRAFT, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft.at) return
            if (!draft.at[p.accountId]) return

            draft.at[p.accountId] = {
                ...draft.at[p.accountId],
                draft: {
                    ...draft.at[p.accountId].draft,
                    ...AdjustContractOrContractTemplateForLocalStorage(p.draft),
                    loadingStatus: 'done'
                }
            }
        })
    }),
    handleAction(MerchantAccountsActions.STORE_CONTRACT_HISTORY, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft.at) return
            if (!draft.at[p.accountId]) return
            draft.at[p.accountId] = {
                ...draft.at[p.accountId],
                contractHistory: p.contracts.reduce((acc, contract, index) => {
                    acc[contract.id] = { ...contract }
                    return acc
                }, {} as any),
                contractHistoryLoadingStatus: 'done'
            }
        })
    }),
    handleAction(MerchantAccountsActions.STORE_API_KEY, (state, { payload: p }) => {
        return produce(state, (draft) => {
            const keys = dotProp.get<any[]>(draft, `at.${p.accountId}.apiKeys`)
            let found = false

            if (!keys) return

            keys.forEach((k, i) => {
                if (k.token === p.data.token) {
                    found = true
                    keys[i] = { ...p.data }
                }
            })

            if (!found) keys?.push(p.data)
        })
    }),
    handleAction(MerchantAccountsActions.STORE_CONTRACT_VERSION, (state, { payload: p }) => {
        return produce(state, (draft) => {
            dotProp.set(draft, `at.${p.accountId}.contractHistory.${p.contract.id}`, p.contract)
        })
    }),
    handleAction(MerchantAccountsActions.FETCH_ACCOUNT, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft.at) draft.at = {}
            if (!draft.at[p.accountId])
                draft.at[p.accountId] = {
                    loadingStatus: 'started'
                } as any
            if (p.forceReload) draft.at[p.accountId].loadingStatus = 'started'
        })
    }),
    handleAction(MerchantAccountsActions.FETCH, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft.forApplication[p.merchantId])
                draft.forApplication[p.merchantId] = {
                    all: [],
                    loadingStatus: 'started'
                }
            draft.forApplication[p.merchantId].loadingStatus = 'started'
        })
    }),
    handleAction(MerchantAccountsActions.STORE_ACCOUNT_WITH_CONTRACT, (state, { payload: p }) => {
        return produce(state, (draft) => {
            if (!draft.at) draft.at = {}

            draft.at[p.accountWithContract.id] = {
                ...draft.at[p.accountWithContract.id],
                ...p.accountWithContract,
                ...(p.accountWithContract?.contractHistoryLoadingStatus
                    ? {}
                    : { contractHistoryLoadingStatus: 'done' }),
                loadingStatus: 'done'
            }

            if (!draft.forApplication[p.applicationId])
                draft.forApplication[p.applicationId] = {
                    all: [],
                    loadingStatus: 'not-started'
                }

            draft.forApplication[p.applicationId].all = [
                ...(draft.forApplication?.[p.applicationId].all?.filter(
                    (accKey) => accKey !== p.accountWithContract.id
                ) || []),
                ...[p.accountWithContract.id]
            ].sort((aId, bId) => {
                const a = draft?.at?.[aId]
                const b = draft?.at?.[bId]
                return parseInt(a?.merchantId) > parseInt(b?.merchantId) ? -1 : 1
            })
        })
    }),
    handleAction(MerchantAccountsActions.STORE_ACCOUNTS_WITHOUT_CONTRACTS, (state, { payload: p }) => {
        return produce(state, (draft) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

            dotProp.set(draft, `forApplication.${p.applicationId}.loadingStatus`, 'done')

            draft.at = {
                ...(draft.at || {}),
                ...p.accountsWithContracts.reduce((merge, account) => {
                    const shouldMarkHistoryAsNotLoaded =
                        !account?.contract &&
                        draft.at?.[account.id]?.contractHistoryLoadingStatus !== 'started' &&
                        draft.at?.[account.id]?.contractHistoryLoadingStatus !== 'done'
                    merge[account.id] = {
                        ...draft.at[account.id],
                        ...account,
                        ...(shouldMarkHistoryAsNotLoaded ? { contractHistoryLoadingStatus: 'not-started' } : {}),
                        loadingStatus: 'done'
                    }
                    return merge
                }, {} as any)
            }

            const accounts = dotProp.get<string[]>(draft, `forApplication.${p.applicationId}.all`)
            dotProp.set(
                draft,
                `forApplication.${p.applicationId}.all`,
                merge(accounts || [], p.accountsWithContracts.map((a) => a.id) || []).sort((aId, bId) => {
                    const a = draft?.at?.[aId]
                    const b = draft?.at?.[bId]
                    return parseInt(a?.merchantId) > parseInt(b?.merchantId) ? -1 : 1
                })
            )
        })
    }),
    handleAction(MerchantAccountsActions.STORE_MEMBER, (state, { payload: p }) => {
        return produce(state, (draft) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const members = dotProp.get<MerchantAccountMember[]>(draft, `at.${p.accountId}.members`)
            const newMembers = [...(members || []).filter((m) => m?.id !== p.memberId), ...(p.data ? [p.data] : [])]

            dotProp.set(draft, `at.${p.accountId}.members`, newMembers)
        })
    })
])
