import React, { useCallback, useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { LoaderView } from '../../../components/loaders/loader'
import { Card } from '../../../components/cards/card'
import { useQueryParams } from '../../../hooks/general/useQueryParam'
import { FileActionDispatchDelete } from '../../../store/files/actions'
import { ApplicationResourceActions } from '../../../store/applicationResources/actions'
import { useDispatch, useSelector } from 'react-redux'
import { DialogContext } from '../../../contexts/dialogProvider'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { uppercaseFirstLetter } from '../../../utils'
import moment from 'moment'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { Spacer } from '../../../components/layout/spacer'
import { ExternalLink } from '@/components/buttons/externalLink'
import { getCategoryFromFileLabel, getWebsiteFromFileLabel } from '@/utils/formatting'
import { RootState } from '@/store'
import { capture } from '@/utils/secretaryCapture'

const params = ['preview']
export const MerchantApplicationWebsiteBox: React.FC<{
    file: any
    section: string
    index: number
    merchantId: string
    websiteIndex: number
}> = ({ file, merchantId, section, index, websiteIndex }) => {
    const [, setQuery] = useQueryParams(params)
    const [hovered, setHovered] = useState(false)
    const dialogContext = useContext(DialogContext)
    const dispatch = useDispatch()
    const screenshotURL = getWebsiteFromFileLabel(file.label)
    const application = useSelector((state: RootState) => state.applicationResources.data.forApplication[merchantId])
    const company = useSelector((state: RootState) => state.applicationInternals.company.forApplication[merchantId])
    const website = application.websites.fields[websiteIndex]

    const handleRemoveClick = useCallback(
        (e, generatedWatcherId) => {
            e?.preventDefault?.()
            e?.stopPropagation?.()
            dialogContext?.setDialog({
                title: 'Confirm deletion',
                description: 'Are you sure you want to delete this file permanently?',
                action: {
                    label: 'Delete permanently',
                    buttonBackground: 'front.danger.color',
                    watcherId: generatedWatcherId,
                    action: () => {
                        dispatch(
                            FileActionDispatchDelete(
                                generatedWatcherId,
                                file,
                                (files: any) => {
                                    return ApplicationResourceActions.UNLINK_FILES(merchantId, 'websites', index, [
                                        file
                                    ])
                                },
                                false
                            )
                        )
                    }
                }
            })
        },
        [dialogContext, dispatch, merchantId, file, index]
    )

    const handleIn = useCallback(() => {
        setHovered(true)
    }, [])

    const handleOut = useCallback(() => {
        setHovered(false)
    }, [])

    return (
        <Contents key={file.id} onMouseEnter={handleIn} onMouseLeave={handleOut}>
            <WebsiteBox>
                <Cover>
                    {screenshotURL && (
                        <>
                            <SimpleButton
                                background="front.background"
                                onClick={() => {
                                    capture(merchantId, company, website, screenshotURL)
                                }}
                            >
                                <ButtonInset>
                                    <ExternalLink
                                        target="blank"
                                        label="URL"
                                        defaultColor="front.accent.color"
                                        alwaysShowArrow
                                        underlineLess
                                    />
                                </ButtonInset>
                            </SimpleButton>
                            <Spacer width={5} />
                        </>
                    )}
                    <SimpleButton
                        onClick={() => {
                            setQuery({
                                preview: file.id
                            })
                        }}
                        background="front.accent.color"
                    >
                        <ButtonInset>Preview file</ButtonInset>
                    </SimpleButton>
                    <Spacer width={5} />
                    <WatcherButton
                        onClick={handleRemoveClick}
                        color={'front.danger.color'}
                        background="front.subtleDanger.background"
                    >
                        <ButtonInset>Delete</ButtonInset>
                    </WatcherButton>
                </Cover>
                {file.generatedDownloadLink ? (
                    <Image
                        style={{
                            backgroundImage: `url("${file.generatedDownloadLink}")`
                        }}
                    />
                ) : (
                    <LoaderView overBackground="front.background" />
                )}
                <Tooltip full={section === 'Others'}>
                    <span>{moment(file.createdAt).fromNow()}</span>
                    {section === 'Others'
                        ? uppercaseFirstLetter(getCategoryFromFileLabel(file.label))
                        : null}
                </Tooltip>
            </WebsiteBox>
        </Contents>
    )
}

const Tooltip = styled.div<{ full?: boolean }>`
    background-color: ${(p) => p.theme['front.background']};
    position: absolute;
    border-radius: 0 12px 12px 12px;
    bottom: 0;
    left: 0;
    padding: 4px 10px 6px 10px;
    box-sizing: border-box;
    color: ${(p) => p.theme['back.text']};

    ${(p) =>
        p.full &&
        css`
            border-radius: 0 0 12px 12px;
            width: 100%;
            display: flex;
            justify-content: space-between;
        `}
`

const Cover = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-color: ${(p) => p.theme['back.background']};
    border-top: 1px solid ${(p) => p.theme['front.border']};
    opacity: 0;
    z-index: 10;
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    padding: 10px;
    color: ${(p) => p.theme['front.accent.text']};
    justify-content: flex-end;
    border-radius: 0px 0px 11px 11px;
`

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 5px;

    &:hover ${Cover} {
        opacity: 1;
    }
    &:nth-child(3n - 2) {
        margin-left: 0;
    }
    &:nth-child(3n) {
        margin-right: 0;
    }
`

const WebsiteBox = styled(Card)`
    background-color: ${(p) => p.theme['front.background']};
    padding: 10px;
    border-radius: 15px;
    width: 263px;
    height: 170px;
    box-sizing: border-box;
`

const Image = styled.div`
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transform: translateZ(0.0001px);
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: cover;
`
