import { Button } from '@/design-system'
import React from 'react'

interface ShowBankDataProps {
    shown: boolean
    onClick: () => void
}

const ShowBankData: React.FC<ShowBankDataProps> = (props) => {
    const { shown, onClick } = props

    return (
        <Button color="primary" variant="link" onClick={onClick}>
            {shown ? 'Hide bank data' : 'Show bank data'}
        </Button>
    )
}

export { ShowBankData }
