/* eslint-disable max-len */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '@/store'
import { ButtonInset } from '../buttons/buttonInset'
import { LinkButton } from '../buttons/linkButton'
import { Text } from '../general/text'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { ToastBlock } from './toastBlock'
import { useLocation } from 'react-router-dom'

export const ExportsInformer: React.FC = () => {
    const location = useLocation()
    const exports = useSelector((state: RootState) => state.exports.exports)

    const exportsInProgress = useMemo(() => {
        return (
            exports.all.filter((id) => {
                const e = exports.at[id]
                return e.status === 'running'
            }) || []
        )
    }, [exports])

    if (location.pathname.includes('exports')) return null
    if (!exportsInProgress.length) return null
    return (
        <Block>
            <Flex column align="stretch" grow>
                <Spacer height={10} />
                <Flex align="center" grow justify="space-between">
                    <Flex align="center">
                        <Spacer width={10} />
                        <Text>
                            {exportsInProgress.length} {exportsInProgress.length === 1 ? 'export is' : 'exports are'}{' '}
                            being processed
                        </Text>
                    </Flex>
                    <LinkButton to="/more/exports" background="floating.subtleAccent.background">
                        <ButtonInset>Go to exports</ButtonInset>
                    </LinkButton>
                </Flex>
                <Spacer height={10} />
            </Flex>
        </Block>
    )
}

const Block = styled(ToastBlock)`
    background-color: ${(p) => p.theme['floating.background']};
`
