import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export type NavigationConfig = {
    title: string
    to: string
}[]

export const TabNavigation: React.FC<{
    config: NavigationConfig
    baseUrl: string
}> = ({ baseUrl, config }) => {
    return (
        <Holder>
            {config.map((n) => {
                return (
                    <LinkButton key={n.title + n.to} to={`${baseUrl}${n.to}`}>
                        {n.title}
                    </LinkButton>
                )
            })}
        </Holder>
    )
}

const LinkButton = styled(NavLink)`
    padding: 5px 10px;
    background-color: ${(p) => p.theme['back.background']};
    color: ${(p) => p.theme['back.text']};
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    margin-right: 3px;

    &:hover {
        color: ${(p) => p.theme['front.text']};
        background-color: ${(p) => p.theme['back.background.strongerI']};
    }

    &:active {
        color: ${(p) => p.theme['front.text']};
        background-color: ${(p) => p.theme['back.background.strongerIII']};
    }

    &.active {
        color: ${(p) => p.theme['front.text']};
        background-color: ${(p) => p.theme['back.background.strongerII']};
        cursor: default;
    }
`

const Holder = styled.div`
    width: 100%;
    border-bottom: 1px solid ${(p) => p.theme['back.border']};
    margin-bottom: 20px;
    padding-bottom: 20px;
`
