import { createActionCreator } from 'deox'

const Prefix = 'APPLICATION_LOADING'

export const ApplicationLoadingActions = {
    APPLICATION_STARTED_LOADING: createActionCreator(
        `${Prefix}/APPLICATION_STARTED_LOADING`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    SIDEBAR_LOADED: createActionCreator(`${Prefix}/SIDEBAR_LOADED`, (resolve) => (applicationId: string) =>
        resolve({ applicationId })
    ),
    SECTION_LOADED: createActionCreator(
        `${Prefix}/SECTION_LOADED`,
        (resolve) => (applicationId: string, section: string, type: 'data' | 'files' | 'internal') =>
            resolve({ applicationId, section, type })
    ),
    INTERNALS_LOADED: createActionCreator(`${Prefix}/INTERNALS_LOADED`, (resolve) => (applicationId: string) =>
        resolve({ applicationId })
    ),
    RELATED_APPLICATIONS_LOADED: createActionCreator(
        `${Prefix}/RELATED_APPLICATIONS_LOADED`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    CLEAR: createActionCreator(`${Prefix}/CLEAR`, (resolve) => (applicationId: string) => resolve({ applicationId })),
    LOAD_TASKS_COUNT: createActionCreator(
        `${Prefix}/LOAD_TASKS_COUNT`,
        (resolve) => (applicationId: string, accountIds?: string[]) => resolve({ applicationId, accountIds })
    ),
    STORE_TASKS_COUNT: createActionCreator(
        `${Prefix}/STORE_TASKS_COUNT`,
        (resolve) => (applicationId: string, count?: number) => resolve({ applicationId, count })
    ),
    STORE_MOST_COMMENTED_FIELD: createActionCreator(
        `${Prefix}/STORE_MOST_COMMENTED_FIELD`,
        (resolve) => (applicationId: string, fieldKey: string, subsectionId?: string) =>
            resolve({ applicationId, fieldKey, subsectionId })
    )
}
