import moment from 'moment'
import React, { useState } from 'react'

import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { Color } from '../../../styles/colors'
import { DateSelector } from '../../../components/forms/dateSelector'
import styled from 'styled-components'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import * as yup from 'yup'

export const MerchantApplicationEditsModalDateOfBirth: React.FC<{
    preSelected?: string
    onBlur: (e: any) => void
    overBackground: Color
    validation: ValidationStatus
    indexPath: MerchantApplicationResourceIndexPath
    formRef: MerchantApplicationFieldFormProps['formRef']
}> = ({ preSelected, onBlur, formRef, indexPath }) => {
    const [date, setSelectedDate] = useState<Date | undefined>(
        preSelected ? moment.utc(preSelected).toDate() : undefined
    )

    return (
        <>
            <DateSelector
                overBackground="front.background"
                preselectedDate={date}
                onBlur={onBlur}
                isSeamless
                ref={(ref) => {
                    formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
                }}
            />
        </>
        // <DateSelector
        //     placeholder="Select a date"

        //     isSeamless
        //     overBackground={overBackground}
        //     validation={validation}
        //     onBlur={onBlur}
        //     ref={(ref) => {
        //         formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
        //     }}
        // />
    )
}

const Hidden = styled.div`
    display: none;
`
