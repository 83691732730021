import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ButtonInset } from '../../components/buttons/buttonInset'
import { SimpleButton } from '../../components/buttons/simpleButton'
import { Icon } from '../../components/icons/icon'
import { LoadingShaper } from '../../components/loaders/loadingShaper'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { RootState } from '@/store'
import { useMerchantApplicationAllComments } from './Application/useMerchantApplicationAllComments'

const Params = ['interactions']
export const MerchantSidebarInteraction: React.FC<{
    applicationId: string
}> = ({ applicationId }) => {
    const [, setQuery] = useQueryParams(Params, undefined)
    const comments = useMerchantApplicationAllComments(applicationId)
    const unreadCommentsResource = useSelector(
        (state: RootState) => state.applicationInternals?.needingAttention?.[applicationId]?.fieldsWithUnreadComments
    )
    const haveInteractionsLoaded = useSelector(
        (state: RootState) => !!state.applicationLoading.at?.[applicationId]?.fieldWithMostComments
    )

    const hasUnreadComments = useMemo(() => {
        return unreadCommentsResource ? Object.keys(unreadCommentsResource)?.length : false
    }, [unreadCommentsResource])

    const handleClick = useCallback(() => {
        setQuery({
            interactions: true
        })
    }, [setQuery])

    if (!haveInteractionsLoaded)
        return (
            <LoadingShaper width={110} height={28} sidebar>
                <rect x="0" y="0" rx="8" ry="8" width="110" height="28" />
            </LoadingShaper>
        )

    return (
        <div data-prevent-notes-exit>
            <SimpleButton
                background={hasUnreadComments ? 'front.info.background' : 'side.subtleAccent.background'}
                onClick={handleClick}
            >
                <ButtonInset padding="small">
                    <IconHolder>
                        <Icon type="comment" />
                    </IconHolder>
                    <span>Interactions{comments?.length ? ` (${comments.length})` : ''}</span>
                </ButtonInset>
            </SimpleButton>
        </div>
    )
}

const IconHolder = styled.div`
    margin-right: 6px;
    position: relative;
    top: 1.5px;
`
