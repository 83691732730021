import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
import { RootState } from '@/store'
import { TransactionRulesValidator } from './Accounts.IDTransactionRulesValidator'
import * as yup from 'yup'
import styled, { css } from 'styled-components'
import { Text } from '../../../components/general/text'
import { useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantAccountTransactionRules: React.FC<{
    accountId: string
    formRef: any
    errors?: any
    data: any
    touched: any
}> = ({ accountId, errors, formRef, touched }) => {
    const params = useParams() as Params
    const applicationId = params.id
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])
    const websites = useSelector(
        (state: RootState) => state.applicationResources.data?.forApplication?.[applicationId]?.websites
    )
    const businessModel = useSelector(
        (state: RootState) =>
            state.applicationResources.data?.forApplication?.[applicationId]?.businessModel?.fields?.[0]
    )

    const shouldWarn = !!errors.transactionRules

    const urls = useMemo(() => {
        return (
            websites?.fields?.map?.((w) => (
                <Value shouldWarn={shouldWarn} key={w.url}>
                    {w.url}
                </Value>
            )) || []
        )
    }, [websites, shouldWarn])
    const turnover = useMemo(() => {
        if (businessModel?.businessModel?.estimateCurrency === undefined) return undefined
        if (businessModel?.businessModel?.estimatedMonthlyTurnover === undefined) return undefined
        return (
            businessModel?.businessModel?.estimatedMonthlyTurnover +
            ' ' +
            businessModel?.businessModel?.estimateCurrency
        )
    }, [businessModel])

    const recurring = useMemo(() => {
        return businessModel?.businessModel?.recurring ? 'true' : 'false'
    }, [businessModel])

    const textColor = shouldWarn ? 'front.subtleDanger.text' : 'front.text.subtlerI'

    return (
        <Card
            title="Transaction rules"
            background={shouldWarn ? `front.subtleDanger.background` : `front.subtleSuccess.background`}
        >
            <CardInset>
                <Flex column align="stretch">
                    <TextareaInput
                        overBackground="front.background"
                        placeholder="Transaction rules"
                        initialValue={account?.transactionRules}
                        // eslint-disable-next-line max-len
                        customStyles="height: calc(100vh - 460px); &:focus{ height: calc(100vh - 460px);}"
                        frameless
                        endless
                        isSeamless
                        monotype
                        cy="transactionRules"
                        skipAnimation
                        // validation={errors.transactionRules}
                        ref={(ref) => formRef(ref, 'transactionRules', yup.string().required().suez())}
                    />
                    <Spacer height={12} />
                    <Flex justify="space-between" align="flex-start">
                        {touched.transactionRules ? (
                            <TransactionRulesValidator error={errors.transactionRules} />
                        ) : (
                            <span />
                        )}
                    </Flex>
                    <Spacer height={15} />
                    <RulesHelper shouldWarn={shouldWarn}>
                        <Row>
                            <Text color={textColor}>Descriptor candidates:</Text>
                            {urls}
                        </Row>
                        {turnover && (
                            <Row noShrink>
                                <Text color={textColor}>Expected monthly turnover:</Text>
                                <Value shouldWarn={shouldWarn}>{turnover}</Value>
                            </Row>
                        )}
                        <Row noShrink>
                            <Text color={textColor}>Recurring:</Text>
                            <Value shouldWarn={shouldWarn}>{recurring}</Value>
                        </Row>
                    </RulesHelper>
                </Flex>
            </CardInset>
        </Card>
    )
}

const Row = styled.div<{ noShrink?: boolean }>`
    display: block;
    margin-right: 20px;
    flex-wrap: wrap;
    &:last-child {
        margin-right: 0;
    }
    ${(p) =>
        p.noShrink &&
        css`
            flex-shrink: 0;
        `}
`

const Value = styled.span<{ shouldWarn?: boolean }>`
    padding: 2px 5px;
    border-radius: 6px;
    background-color: ${(p) =>
        p.theme[p.shouldWarn ? `front.subtleDanger.background.strongerI` : 'front.subtleSuccess.background.strongerI']};
    margin-left: 5px;
    word-break: break-all;
    line-height: 24px;
    &:last-child {
        margin-right: 0;
    }
`

const RulesHelper = styled.div<{ shouldWarn?: boolean }>`
    display: flex;
    align-items: flex-start;
    padding-top: 12px;
    border-top: 1px solid ${(p) => p.theme[p.shouldWarn ? `front.subtleDanger.border` : 'front.subtleSuccess.border']};
    margin-bottom: -5px;
`
