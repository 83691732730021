import { FileDownloadData, Files } from '../types/files'
import { Url } from '@/services/common'
import { api } from '../api'

export const filesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getFile: build.query<FileDownloadData, Url>({
            query: (url) => ({
                url
            })
        }),
        getFiles: build.query<Files, Url>({
            query: (url) => ({
                url
            }),
            transformResponse: (response: Files) => {
                if (response.embedded['ch:files'] && !Array.isArray(response.embedded['ch:files'])) {
                    response.embedded['ch:files'] = [response.embedded['ch:files']]
                }

                return response
            }
        })
    })
})

export const {
    useGetFileQuery: useGetFile,
    useLazyGetFileQuery: useLazyGetFile,
    useGetFilesQuery: useGetFiles
} = filesApi
