import { kebabCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as yup from 'yup'

import { ButtonInset } from '../../../components/buttons/buttonInset'
import { SimpleButton } from '../../../components/buttons/simpleButton'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Flex } from '../../../components/layout/flex'
import { Floater } from '../../../components/layout/floater'
import { FloaterInset } from '../../../components/layout/floaterInset'
import { MarkdownHolder } from '../../../components/general/markdownHolder'
import { Spacer } from '../../../components/layout/spacer'
import { Text } from '../../../components/general/text'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { TextInput } from '../../../components/forms/textInput'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { useForm } from '../../../hooks/general/useForm'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { ApplicationInternalsDetailsActions } from '../../../store/applicationInternals/details/actions'
import { MerchantApplicationInternalDetailValue } from '../../../store/applicationInternals/details/types'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { ToastsDispatchPush } from '../../../store/toasts/actions'
import { Optional } from '../../../store/utils'
import { MerchantApplicationInternalLabel } from './Application.InternalLabel'
import { WatcherDispatchComplete } from '../../../store/watcher/actions'
import { useOnClickOutside } from '../../../hooks/general/useOnClickOutside'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { useQueryParams } from '../../../hooks/general/useQueryParam'
import { useApplicationInternalValue } from './Application.useInternalValue'
import { useValueShortener } from '../../../hooks/general/useValueShortener'
import { numberWithCommas } from '../../../utils'
import { MerchantApplicationInternalValueDataProvider } from './Application.InternalValueDataProviderOwnership'

const params = ['edit-ownership-note']
export const MerchantApplicationInternalValue: React.FC<{
    type: MerchantApplicationInternalDetailValue
    applicationId: string
    isLoading?: boolean
    indexPath: MerchantApplicationResourceIndexPath
}> = ({ type, isLoading, applicationId, indexPath }) => {
    const dispatch = useDispatch()
    const [anchor, setAnchor] = useRefTaker()
    const [holder, setHolder] = useRefTaker()
    const [textInput, setTextInput] = useRefTaker()
    const [showFloater, setShowFloater] = useState(false)
    const [watcher, generatedWatcherId] = useNamedWatcher()
    const [shortBusinessModelWatcher, shortBusinessModelWatcherId] = useNamedWatcher('FOCUS_SHORT_BUSINESS_MODEL')
    const { form, errors, submitHandler, formRef } = useForm(watcher)
    const [query, setQuery] = useQueryParams(params, undefined)

    const [value, setValue] = useState<Optional<string>>()
    const { id, localValue, structure } = useApplicationInternalValue(applicationId, indexPath, type)

    const { text: shortValue, showMore, toggleShowMore, shouldEvenShowMore } = useValueShortener(localValue)
    useEffect(() => {
        if (watcher === 'success') setShowFloater(false)
    }, [watcher])

    useOnClickOutside(
        holder,
        useCallback(() => {
            setShowFloater(false)
        }, [setShowFloater])
    )

    useEffect(() => {
        setTimeout(() => {
            if (textInput) {
                textInput?.focus()
                const container = textInput?.closest('#SCROLL-CONTAINER')
                const rect = textInput.getBoundingClientRect()

                container?.scrollTo(0, rect.top + container?.scrollTop - window.innerHeight / 2 - rect.height / 2)
            }
        }, 16)
    }, [textInput])

    useEffect(() => {
        if (shortBusinessModelWatcher === 'started' && structure?.type === 'shortBusinessModel') {
            setShowFloater(true)
            dispatch(WatcherDispatchComplete([shortBusinessModelWatcherId]))
        }
    }, [dispatch, setShowFloater, shortBusinessModelWatcherId, shortBusinessModelWatcher, structure])

    const update = useCallback(() => {
        if (!structure) return dispatch(ToastsDispatchPush('Failed to find field structure', 'error'))

        if (value === undefined) {
            dispatch(ToastsDispatchPush('No value provided.', 'error'))
            return null
        }

        return dispatch(
            ApplicationInternalsDetailsActions.UPDATE_VALUE(
                type,
                structure.path.replace('<ID>', id || ''),
                applicationId,
                value,
                generatedWatcherId
            )
        )
    }, [dispatch, type, structure, id, generatedWatcherId, applicationId, value])

    const input = useMemo(() => {
        if (structure?.type === 'percent')
            return (
                <TextInput
                    isSimple
                    onCommandEnter={update}
                    placeholder="Enter value"
                    overBackground="floating.background"
                    initialValue={localValue}
                    onChange={(e, val) => {
                        setValue(val)
                    }}
                    suffix="%"
                    ref={(ref) => {
                        setTextInput(ref)
                        formRef(ref, 'ownershipPercentage', yup.number().positive().max(100))
                    }}
                    validation={errors.ownershipPercentage}
                />
            )
        if (structure?.type === 'marketplaceId') 
            return (
                <TextInput
                    isSimple
                    onCommandEnter={update}
                    placeholder="Enter value"
                    overBackground="floating.background"
                    initialValue={localValue}
                    onChange={(e, val) => {
                        setValue(val)
                    }}
                    validation={errors.marketplaceId}
                    ref={(ref) => 
                        formRef(
                            ref,
                            'marketplaceId',
                            yup
                                .string()
                                .nullable()
                                .matches(/^([0-9]{8})$/, {
                                    message: 'Should be empty or 8 digits',
                                    excludeEmptyString: true
                                })
                        )}
                />
        )
        if (structure?.type === 'text' || structure?.type === 'shortBusinessModel')
            return (
                <TextInput
                    isSimple
                    onCommandEnter={update}
                    placeholder="Enter value"
                    overBackground="floating.background"
                    initialValue={localValue}
                    onChange={(e, val) => {
                        setValue(val)
                    }}
                    ref={setTextInput}
                />
            )
        if (structure?.type === 'multilineText')
            return (
                <TextareaInput
                    overBackground="floating.background"
                    placeholder="Enter value"
                    isSimple
                    initialValue={localValue}
                    onChange={(e, val) => {
                        setValue(val)
                    }}
                    skipAnimation
                    onCommandEnter={update}
                    ref={setTextInput}
                />
            )
        return <></>
    }, [structure, update, localValue, setTextInput, setValue, errors, formRef])

    const renderedValue = useMemo(() => {
        if (isLoading) return <TextLineLoader topOffset={3} />
        return (
            <>
                {localValue ? (
                    <>
                        <ValueHolder>
                            <ValueHighlight>
                                {shortValue && (
                                    <Text color="front.text">
                                        <MarkdownHolder>{shortValue}</MarkdownHolder>
                                    </Text>
                                )}
                            </ValueHighlight>
                        </ValueHolder>
                        <span />
                        <span />{' '}
                    </>
                ) : null}
                <Flex>
                    {shouldEvenShowMore && (
                        <Flex>
                            <SimpleButton color="front.accent.color" onClick={toggleShowMore}>
                                <ButtonInset noHorizontalPadding noVerticalPadding padding="small" leftAlign>
                                    {showMore ? 'Show less' : 'Show more'}
                                </ButtonInset>
                            </SimpleButton>
                            <Spacer width={10} />
                        </Flex>
                    )}
                    <Flex column>
                        <SimpleButton buttonRef={setAnchor} onClick={() => setShowFloater((s) => !s)}>
                            <ButtonInset noHorizontalPadding noVerticalPadding padding="small" leftAlign>
                                <Flex align="center" column>
                                    {localValue ? 'Edit text' : '+ Text'}
                                </Flex>
                            </ButtonInset>
                        </SimpleButton>
                        {structure?.type === 'percent' ? (
                            <MerchantApplicationInternalValueDataProvider
                                type="ownershipPercentage"
                                applicationId={applicationId}
                                indexPath={indexPath}
                            />
                        ) : null}
                    </Flex>
                </Flex>
            </>
        )
    }, [
        isLoading,
        localValue,
        setAnchor,
        showMore,
        shortValue,
        structure,
        shouldEvenShowMore,
        indexPath,
        applicationId,
        toggleShowMore
    ])

    if (structure?.type === 'modaledInput') {
        if (isLoading) return <TextLineLoader topOffset={1} />
        const length = localValue?.split(' ')?.length || 0
        return localValue ? (
            <FullRowHolder
                onClick={() => {
                    setQuery({ 'edit-ownership-note': true })
                }}
            >
                <SimpleButton>
                    Edit ownership note ({numberWithCommas(length, true)} {length === 1 ? 'word' : 'words'})
                </SimpleButton>
            </FullRowHolder>
        ) : (
            <FullRowHolder>
                <Flex justify="space-between" grow>
                    <Text color="back.text">No ownership note</Text>
                    <SimpleButton
                        onClick={() => {
                            setQuery({ 'edit-ownership-note': true })
                        }}
                    >
                        + Ownership note
                    </SimpleButton>
                </Flex>
            </FullRowHolder>
        )
    }
    return (
        <>
            <Holder ref={setHolder} data-cy={`detail-${kebabCase(structure.label)}`}>
                <MerchantApplicationInternalLabel>{structure.label}</MerchantApplicationInternalLabel>
                <div />
                {renderedValue}
                <Floater
                    cardId="Merchant.ApplicationPage.AddInternalValue"
                    anchor={anchor}
                    shouldShow={showFloater}
                    onHide={() => setShowFloater(false)}
                >
                    <FloaterInset equalPadding padding="small">
                        <Flex align="flex-start">
                            <InputHolder>
                                <InputSecondHolder>{input}</InputSecondHolder>
                            </InputHolder>
                            <Spacer width={20} />
                            <WatcherButton
                                background="front.accent.color"
                                isDisabled={value === localValue || Object.keys(errors).length !== 0}
                                cy="update-detail"
                                onClick={submitHandler(update)}
                                predefinedWatcher={generatedWatcherId}
                            >
                                <ButtonInset>Update</ButtonInset>
                            </WatcherButton>
                        </Flex>
                    </FloaterInset>
                </Floater>
            </Holder>
            {/* {localValue && (
                <Border>
                    <BorderFill />
                </Border>
            )} */}
        </>
    )
}

// const BorderFill = styled.div`
//     height: 1px;
//     width: 100%;
//     background-color: ${.greyBackground.border};
// `

// const Border = styled.div`
//     grid-column: span 3;
//     height: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     &:last-child {
//         display: none;
//     }
// `

const InputHolder = styled.div`
    min-width: 220px;
    min-height: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const InputSecondHolder = styled.div`
    position: relative;
    flex-grow: 1;
`

const Holder = styled.div`
    display: contents;
`

const IconHolder = styled.div`
    margin-top: -1px;
`

const ValueHolder = styled.div`
    display: flex;
    /* @media (min-width: 1500px) {
        min-width: 250px;
    } */
    margin-right: 10px;
    align-items: flex-start;
`

const ValueHighlight = styled.div`
    border-radius: 5px;
    margin-bottom: 0px;
    border-radius: 0;
    position: relative;
    max-width: 350px;
    margin-right: 15px;

    /* &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: -16px;
        width: 3px;
        background-color: ${(p) => p.theme['back.background.strongerII']};
        height: calc(100% - 8px);
        border-radius: 99px;
    } */
    /* padding: 2px 6px;
    background-color: ${(p) => p.theme['back.highlights']};
    margin-left: -2px;
    margin-bottom: 1px;
    margin-top: -1px; */
`

const Spanner = styled.div<{ localValue?: string }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    grid-column: span 3;
    padding-bottom: 10px;
`

const FullRowHolder = styled.div`
    display: flex;
    grid-column: span 3;
    margin-bottom: 10px;
    padding-bottom: 10px;
    margin-top: -4px;
`
