import { returnUnique } from '../../utils'
import {
    USER_ACCOUNTS_LOAD_ACCOUNT,
    USER_ACCOUNTS_STORE_ACCOUNT,
    UserAccountsNeedingReducing
} from './actions'
import { UserAccountsInitialState, UserAccountsState } from './types'

export function UserAccountsReducer(
    state = UserAccountsInitialState,
    action: UserAccountsNeedingReducing
): UserAccountsState {
    switch (action.type) {
        case USER_ACCOUNTS_LOAD_ACCOUNT: {
            return {
                ...state,
                at: {
                    ...state.at,
                    [action.id]: {
                        data: {},
                        loadingStatus: 'started'
                    }
                },
                all: returnUnique(state.all, action.id)
            }
        }
        case USER_ACCOUNTS_STORE_ACCOUNT: {
            return {
                ...state,
                at: {
                    ...state.at,
                    [action.id]: {
                        data: action.data ? { ...action.data } : undefined,
                        loadingStatus: 'done'
                    }
                },
                atMid: {
                    ...state.atMid,
                    [action.data?.merchantId]: action.id
                },
                all: returnUnique(state.all, action.id)
            }
        }
    }
    return state
}
