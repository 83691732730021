import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flag } from '../../../components/icons/flag'
import { Spacer } from '../../../components/layout/spacer'
import { Params, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { SchemesIcon } from '@/components/icons/schemesIcon'
import { SchemesScreeningCountry2LetterCode, SchemesScreeningCountryName } from './Application.SchemesScreeningCountry'
import moment from 'moment'
import { Offset } from '@/components/layout/offset'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { CircleCheck } from '@/components/icons/circleCheck'
import { ApplicationSchemesScreeningPastInquiriesBody } from './Application.SchemesScreeningPastInquiriesBody'
import { Collapsible, Flex, Separator } from '@/design-system'
import { Inquiry } from '@/store/applicationInternals/schemesScreening/types'

export const ApplicationSchemesScreeningPastInquiries: React.FC<{
    wrap?: boolean
    latestInquiry?: Inquiry
    selectedPastInquiries: any
    setSelectedPastInquiries: any
}> = ({ wrap, latestInquiry, selectedPastInquiries, setSelectedPastInquiries }) => {
    const params: any = useParams() as Params
    const applicationId = params.id
    if (!applicationId) return <></>
    const state = useSelector((state: RootState) => {
        return state.applicationInternals.schemesScreening.forApplication[applicationId]
    })
    const pastInquiries = state?.latestInquiry?.matchPastInquiries || []
    const content = useMemo(() => {
        return (
            <>
                <Text bold>{pastInquiries.length} related past inquiries:</Text>
                <Spacer height={10} />
                {pastInquiries?.map((p) => {
                    const isSelected = selectedPastInquiries?.includes(p.pastInquiryId)
                    return (
                        <CardMargin wrap key={p.pastInquiryId}>
                            <Card background="front.background">
                                <CardInset type="small">
                                    <Collapsible>
                                        <Collapsible.Trigger testid="integration-trigger">
                                            <Flex direction="row">
                                                <Flex align="center" grow="1">
                                                    <SchemeHolder>
                                                        <SchemesIcon scheme="mastercard" />
                                                    </SchemeHolder>
                                                    <Spacer width={25} />
                                                    <Offset top={-2}>
                                                        <Flag
                                                            code={SchemesScreeningCountry2LetterCode(
                                                                'MATCH',
                                                                p.company?.country
                                                            )}
                                                        />
                                                    </Offset>
                                                    <Spacer width={8} />
                                                    <Text color="front.text" oneLine bold>
                                                        {SchemesScreeningCountryName('MATCH', p.company?.country)}
                                                    </Text>
                                                    <Spacer width={25} />
                                                    <Text style={{ width: '150px' }} oneLine bold color="front.text">
                                                        {p.company?.name || 'Unnamed company'}
                                                    </Text>
                                                    <Spacer width={15} />
                                                    <Flex direction="column">
                                                        <Text color="front.danger.color" bold>
                                                            #{p?.termination?.reasonCode}{' '}
                                                            {p?.termination?.reasonDescription}
                                                        </Text>
                                                        <Text color="front.text.subtlerI">
                                                            Reported on{' '}
                                                            {moment(p.acquirer?.dateAdded).format('DD MMM, YYYY')}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                <Flex align="center" justify="end">
                                                    {!latestInquiry?.conclusion ? (
                                                        <SimpleButton
                                                            color={isSelected ? 'front.danger.color' : undefined}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                e.nativeEvent.stopImmediatePropagation()
                                                                const id = p.pastInquiryId
                                                                setSelectedPastInquiries((pastInquiryIds) => {
                                                                    let newArray = [...pastInquiryIds]
                                                                    if (!newArray.includes(id)) newArray.push(id)
                                                                    else newArray = newArray.filter((i) => i != id)
                                                                    return newArray
                                                                })
                                                            }}
                                                        >
                                                            <ButtonInset>
                                                                <CircleCheck
                                                                    selectable
                                                                    checked={isSelected}
                                                                    isDanger={isSelected}
                                                                />
                                                                {isSelected ? 'Hit' : 'Hit'}
                                                            </ButtonInset>
                                                        </SimpleButton>
                                                    ) : latestInquiry?.conclusion.pastInquiryMatches.includes(
                                                          p.pastInquiryId
                                                      ) ? (
                                                        <SimpleButton>
                                                            <ButtonInset>
                                                                <CircleCheck
                                                                    larger
                                                                    checked
                                                                    isSuccess={latestInquiry?.conclusion?.passed}
                                                                    isDanger={!latestInquiry?.conclusion?.passed}
                                                                />
                                                            </ButtonInset>
                                                        </SimpleButton>
                                                    ) : null}
                                                    <Spacer width={6} />
                                                    <Collapsible.Chevron />
                                                </Flex>
                                            </Flex>
                                        </Collapsible.Trigger>
                                        <Collapsible.Content>
                                            <Spacer height={12} />
                                            <Separator />
                                            <Spacer height={12} />
                                            <ApplicationSchemesScreeningPastInquiriesBody pastInquiry={p} />
                                        </Collapsible.Content>
                                    </Collapsible>
                                </CardInset>
                            </Card>
                        </CardMargin>
                    )
                })}
            </>
        )
    }, [pastInquiries, wrap, applicationId, selectedPastInquiries])

    if (!pastInquiries || pastInquiries.length == 0)
        return (
            <Flex align="center" justify="center">
                <Spacer height={50} />
                <Text color="front.text.subtlerI">No relevant past inquiries found</Text>
            </Flex>
        )
    return (
        <Flex>
            <Spacer height={10} />
            <Card background="back.background.strongerI" grow>
                <CardInset type="small">{content}</CardInset>
            </Card>
        </Flex>
    )
}

const SchemeHolder = styled.div`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    margin-top: 2px;
    margin-right: 10px;
`

const CardMargin = styled.div<{ wrap?: boolean }>`
    margin-bottom: 6px;

    ${(p) =>
        p.wrap &&
        css`
            margin-right: 8px;
            margin-bottom: 8px;
        `}
`
