/* eslint-disable max-len */
import React, { useRef } from 'react'

import { uppercaseFirstLetter } from '../../utils'
import { Flex } from '../layout/flex'
import { Text } from '../general/text'
import { Tooltip } from '../general/tooltip'

export const IconPaymentScheme: React.FC<{
    scheme: string
    scale?: number
}> = ({ scale = 1, scheme }) => {
    const ref = useRef<any>()

    const wrapIcon = (icon: React.ReactNode) => {
        return (
            <>
                <Flex align="center" justify="center" ref={ref}>
                    {icon}
                </Flex>
                <Tooltip element={ref} tooltipTiming="instant" tooltip>
                    {uppercaseFirstLetter(scheme)}
                </Tooltip>
            </>
        )
    }

    if (scheme === 'mastercard')
        return wrapIcon(
            <svg
                width={25 * scale}
                height={16 * scale}
                viewBox="0 0 25 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M15.353 13.481H8.8158V1.61453H15.353V13.481Z" fill="#FF5F00" />
                <path
                    d="M9.23462 7.54683C9.23462 5.13965 10.3505 2.99541 12.0881 1.61357C10.8174 0.603107 9.21372 -6.00301e-06 7.47083 -6.00301e-06C3.3447 -6.00301e-06 0 3.37877 0 7.54683C0 11.7149 3.3447 15.0937 7.47083 15.0937C9.21372 15.0937 10.8174 14.4905 12.0881 13.4801C10.3505 12.0982 9.23462 9.954 9.23462 7.54683Z"
                    fill="#EB001B"
                />
                <path
                    d="M24.1697 7.54683C24.1697 11.7149 20.825 15.0937 16.6988 15.0937C14.9559 15.0937 13.3522 14.4905 12.0811 13.4801C13.8192 12.0982 14.935 9.954 14.935 7.54683C14.935 5.13965 13.8192 2.99541 12.0811 1.61357C13.3522 0.603107 14.9559 -6.00301e-06 16.6988 -6.00301e-06C20.825 -6.00301e-06 24.1697 3.37877 24.1697 7.54683Z"
                    fill="#F79E1B"
                />
            </svg>
        )
    if (scheme === 'visa')
        return wrapIcon(
            <Flex style={{ height: 16 * scale }} align="center" justify="center">
                <svg
                    width={35 * scale}
                    height={12 * scale}
                    viewBox="0 0 35 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M15.6276 11.0531H12.8834L14.5998 0.433838H17.3439L15.6276 11.0531Z" fill="#00579F" />
                    <path
                        d="M25.5755 0.693437C25.0343 0.478571 24.1758 0.241333 23.1142 0.241333C20.4043 0.241333 18.4959 1.68728 18.4842 3.75456C18.4617 5.27978 19.8505 6.12693 20.8893 6.63549C21.9509 7.15516 22.3118 7.49435 22.3118 7.95757C22.301 8.66898 21.4539 8.99691 20.6639 8.99691C19.5683 8.99691 18.9812 8.82784 18.0891 8.43205L17.7278 8.26238L17.3438 10.6462C17.9874 10.9396 19.1732 11.1999 20.4043 11.2113C23.2836 11.2113 25.1582 9.78775 25.1804 7.58475C25.1914 6.37588 24.458 5.4496 22.877 4.6927C21.9172 4.20681 21.3294 3.87918 21.3294 3.38203C21.3406 2.93008 21.8265 2.46716 22.91 2.46716C23.8021 2.44449 24.4576 2.65906 24.9541 2.87377L25.2023 2.98654L25.5755 0.693437V0.693437Z"
                        fill="#00579F"
                    />
                    <path
                        d="M29.2227 7.29106C29.4487 6.681 30.3183 4.31988 30.3183 4.31988C30.3069 4.34256 30.5438 3.69856 30.6793 3.30321L30.8711 4.21823C30.8711 4.21823 31.3908 6.76013 31.5036 7.29106C31.0747 7.29106 29.7647 7.29106 29.2227 7.29106ZM32.61 0.433838H30.4874C29.8328 0.433838 29.3355 0.62573 29.0531 1.31492L24.977 11.0529H27.8564C27.8564 11.0529 28.3304 9.74227 28.4323 9.45998C28.7482 9.45998 31.5492 9.45998 31.9556 9.45998C32.0344 9.83281 32.283 11.0529 32.283 11.0529H34.8239L32.61 0.433838V0.433838Z"
                        fill="#00579F"
                    />
                    <path
                        d="M10.5914 0.433838L7.90396 7.67515L7.61029 6.20652C7.11344 4.51193 5.5552 2.67078 3.81628 1.75531L6.27789 11.0418H9.17979L13.4932 0.433838H10.5914V0.433838Z"
                        fill="#00579F"
                    />
                    <path
                        d="M5.4084 0.433838H0.993288L0.94812 0.648403C4.39218 1.52964 6.67312 3.65381 7.61025 6.20697L6.65046 1.32648C6.49245 0.648253 6.00685 0.45621 5.4084 0.433838Z"
                        fill="#FAA61A"
                    />
                </svg>
            </Flex>
        )
    return <Text>{scheme}</Text>
}
