import styled from 'styled-components'
import {
    convertRatioToPercentage,
    fixWrongNumberFormatComingFromBackend,
    numberWithCommas,
    removeNumberDecimals,
    uppercaseFirstLetter
} from '../../utils'
import { IDShortener } from '../general/idShortener'
import { List } from '../layout/list'
import { Table } from '../tables/table'
import { Text } from '../general/text'
import { Flex } from '../layout/flex'
import { Spacer } from '../layout/spacer'
import { useState } from 'react'
import { SimpleButton } from '../buttons/simpleButton'
import { ButtonInset } from '../buttons/buttonInset'
import { useDispatch } from 'react-redux'
import { ToastsDispatchPush } from '../../store/toasts/actions'

export const TaskTimelineAlertTable: React.FC<{
    data?: any
    extraColumns?: any
}> = ({ data, extraColumns }) => {
    const [showMore, setShowMore] = useState(false)
    const dispatch = useDispatch()

    switch (data.data.alarmType) {
        case 'volume-in-period':
            return createRow(extraColumns, 'volume-growth', {
                Limit: fixWrongNumberFormatComingFromBackend(data.data.limit, true),
                [uppercaseFirstLetter(data.data.metric.type)]: fixWrongNumberFormatComingFromBackend(
                    data.data.metric.value,
                    true
                ),
                [`Previous Period (${addDaySuffix(
                    fixWrongNumberFormatComingFromBackend(data.data.previousPeriod.days, true)
                )})`]: numberWithCommas(data.data.previousPeriod.value),
                [`Current Period (${addDaySuffix(
                    fixWrongNumberFormatComingFromBackend(data.data.currentPeriod.days, true)
                )})`]: numberWithCommas(data.data.currentPeriod.value)
            })
        case 'max-subsection-cap':
            return createRow(extraColumns, 'max-subsection-cap', {
                Limit: data.data.limit,
                Period: data.data.period,
                Violations: {
                    type: 'custom',
                    node: (
                        <TableHolder>
                            <Table
                                background="front.subtleDanger.background"
                                columnLayout="50"
                                cols={[
                                    {
                                        text: 'Violation'
                                    },
                                    {
                                        text: 'Amount'
                                    }
                                ]}
                                style={{ marginTop: 10 }}
                                rows={data.data.violations.map((v: any) => ({
                                    type: 'subtle',
                                    items: [
                                        {
                                            node: (
                                                <Text noWrap>
                                                    {v.first} - {v.last}
                                                </Text>
                                            )
                                        },
                                        {
                                            node: (
                                                <Text noWrap>
                                                    {fixWrongNumberFormatComingFromBackend(v.amount, true)}
                                                </Text>
                                            )
                                        }
                                    ]
                                }))}
                            />
                        </TableHolder>
                    )
                }
            })
        case 'volume-subsection-cap':
            if (data?.data?.limit && data?.data?.metric)
                return createRow(extraColumns, 'volume-subsection-cap', {
                    Limit: data.data.limit,
                    [`${uppercaseFirstLetter(data.data.metric.type)} (${addDaySuffix(data.data.metric.period)})`]:
                        data.data.metric.value
                })
            return (
                <Flex column>
                    {createRow(extraColumns, 'occurrences-subsection-cap', {
                        Period: addDaySuffix(data.data.period),
                        Limit: data.data.limit,
                        Subsection: {
                            type: 'custom',
                            node: <SubsectionHighlights>{data.data.subsection}</SubsectionHighlights>
                        }
                    })}
                    <Spacer height={25} />
                    <TableHolder>
                        <Table
                            background="front.subtleDanger.background"
                            style={{ marginTop: 10 }}
                            cols={[
                                {
                                    text: 'Card Subsection'
                                },
                                {
                                    text: 'Amount',
                                    alignRight: true
                                },
                                {
                                    text: 'First',
                                    alignRight: true
                                },
                                {
                                    text: 'Last',
                                    alignRight: true
                                }
                            ]}
                            type="narrow"
                            rows={data.data.violations
                                .filter((_: any, i: number) => (showMore ? true : i < 5))
                                .map((v: any) => {
                                    return {
                                        noAction: true,
                                        items: [
                                            {
                                                node: <Text noWrap>{v.key}</Text>
                                            },
                                            {
                                                node: <Text noWrap>{v.amount}</Text>
                                            },
                                            {
                                                node: <Text noWrap>{v.first}</Text>
                                            },
                                            {
                                                node: <Text noWrap>{v.last}</Text>
                                            }
                                        ]
                                    }
                                })}
                            columnLayout="auto min-content min-content min-content"
                        />
                    </TableHolder>
                    <Spacer height={20} />
                    <SimpleButton
                        background="front.subtleDanger.background.strongerI"
                        color="front.danger.color"
                        onClick={() => setShowMore((s) => !s)}
                    >
                        <ButtonInset>{showMore ? 'Show less' : 'Show all'}</ButtonInset>
                    </SimpleButton>
                </Flex>
            )
        case 'max-ticket-size-cap':
            return createRow(extraColumns, 'max-ticket-size-cap', {
                Limit: data.data.limit,
                Period: data.data.period,
                Subject: data.data.subject
            })
        case 'occurrence-growth':
            return createRow(extraColumns, 'occurrence-growth', {
                Limit: removeNumberDecimals(data.data.limit),
                [uppercaseFirstLetter(data.data.metric.type)]: removeNumberDecimals(data.data.metric.value),
                [`Previous Period (${addDaySuffix(data.data.previousPeriod.days)})`]: getPeriodMetric(
                    data.data.previousPeriod
                ),
                [`Current Period (${addDaySuffix(data.data.currentPeriod.days)})`]: getPeriodMetric(
                    data.data.currentPeriod
                )
            })
        case 'volume-growth':
            return createRow(extraColumns, 'volume-growth', {
                Limit: fixWrongNumberFormatComingFromBackend(data.data.limit, true),
                [uppercaseFirstLetter(data.data.metric.type)]: removeNumberDecimals(data.data.metric.value),
                [`Previous Period Test(${addDaySuffix(
                    fixWrongNumberFormatComingFromBackend(data.data.previousPeriod.days, true)
                )})`]: fixWrongNumberFormatComingFromBackend(data.data.previousPeriod.volume, true),
                [`Current Period (${addDaySuffix(
                    fixWrongNumberFormatComingFromBackend(data.data.currentPeriod.days, true)
                )})`]: fixWrongNumberFormatComingFromBackend(data.data.currentPeriod.volume, true)
            })
        case 'volume-cap':
            return createRow(extraColumns, 'volume-cap', {
                Limit: fixWrongNumberFormatComingFromBackend(data.data.limit, true),
                [`${uppercaseFirstLetter(data.data.metric.type)} (${addDaySuffix(data.data.period)})`]:
                    fixWrongNumberFormatComingFromBackend(data.data.metric.value, true)
            })
        case 'occurrence-cap':
            return createRow(extraColumns, 'occurrences-subsection-cap', {
                Limit: data.data.limit,
                [`${uppercaseFirstLetter(data.data.metric.type)} (${addDaySuffix(data.data.period)})`]:
                    data.data.metric.value
            })
        case 'ticket-size-violation':
            return createRow(extraColumns, 'ticket-size-violation', {
                Limit: data.data.limit,
                [`Previous Period Avg. (${addDaySuffix(
                    fixWrongNumberFormatComingFromBackend(data.data.previousPeriod.days, true)
                )})`]: fixWrongNumberFormatComingFromBackend(data.data.previousPeriod.average, true),
                [`Current Period Violations (${addDaySuffix(
                    fixWrongNumberFormatComingFromBackend(data.data.currentPeriod.days, true)
                )})`]: {
                    type: 'custom',
                    node: (
                        <TableHolder>
                            <Table
                                background="front.subtleDanger.background"
                                type="narrow"
                                style={{ marginTop: 10 }}
                                cols={[
                                    {
                                        text: 'Transaction-id'
                                    },
                                    {
                                        text: 'Amount'
                                    }
                                ]}
                                columnLayout="min-content min-content"
                                rows={data.data.currentPeriod.violations.map((v: any) => {
                                    return {
                                        link: `/transactions/${v.title.replace('Transaction ', '')}`,
                                        linkTarget: 'managerTransaction',
                                        items: [
                                            {
                                                node: (
                                                    <Text color="front.text" noWrap>
                                                        <IDShortener id={v.title.replace('Transaction ', '')} />
                                                    </Text>
                                                )
                                            },
                                            {
                                                node: (
                                                    <Text color="front.text" noWrap>
                                                        {fixWrongNumberFormatComingFromBackend(v.amount)}
                                                    </Text>
                                                )
                                            }
                                        ]
                                    }
                                })}
                            />
                        </TableHolder>
                    )
                }
            })
        case 'occurrences-subsection-cap':
            return createRow(extraColumns, 'occurrences-subsection-cap', {
                Period: addDaySuffix(data.data.period),
                Limit: data.data.limit,
                Violations: {
                    type: 'custom',
                    node: (
                        <TableHolder>
                            <Table
                                background="front.subtleDanger.background"
                                style={{ marginTop: 10 }}
                                cols={[
                                    {
                                        text: uppercaseFirstLetter(data.data.subsection)
                                    },
                                    {
                                        text: 'Occurrences'
                                    },
                                    {
                                        text: 'First'
                                    },
                                    {
                                        text: 'Last'
                                    }
                                ]}
                                type="narrow"
                                rows={data.data.violations.map((v: any) => {
                                    return {
                                        noAction: true,
                                        items: [
                                            {
                                                node: <Text noWrap>{v.subsection}</Text>
                                            },
                                            {
                                                node: <Text noWrap>{v.occurrences}</Text>
                                            },
                                            {
                                                node: <Text noWrap>{v.first}</Text>
                                            },
                                            {
                                                node: <Text noWrap>{v.last}</Text>
                                            }
                                        ]
                                    }
                                })}
                                columnLayout="auto auto auto auto"
                            />
                        </TableHolder>
                    )
                }
            })
        case 'occurrence-ratio':
            return createRow(extraColumns, 'occurrences-subsection-cap', {
                Period: data.data.days,
                Limit: convertRatioToPercentage(data.data.limit),
                Ratio: data.data.metric.value ? convertRatioToPercentage(data.data.metric.value) : '∞',
                [`Benchmark (${data.data.comparison.type})`]: data.data.comparison.value,
                [`Subject (${data.data.subject.type})`]: data.data.subject.value
            })
        case 'volume-ratio':
            return createRow(extraColumns, 'volume-ratio', {
                Period: addDaySuffix(data.data.days),
                Limit: convertRatioToPercentage(data.data.limit),
                [uppercaseFirstLetter(data.data.metric.type)]: data.data.metric.value
                    ? convertRatioToPercentage(data.data.metric.value)
                    : '∞',
                [`Benchmark (${data.data.comparison.type})`]: fixWrongNumberFormatComingFromBackend(
                    data.data.comparison.volume,
                    true
                ),
                [`Subject (${data.data.subject.type})`]: fixWrongNumberFormatComingFromBackend(
                    data.data.subject.volume,
                    true
                )
            })
        default: {
            dispatch(ToastsDispatchPush('Unsupported alert kind found.', 'error'))
            return (
                <UnsupportedAlert>
                    <b>Unsupported alert found (Contact the console team):</b> <br />
                    <br /> {JSON.stringify(data.data)}
                </UnsupportedAlert>
            )
        }
    }
}

const createRow = (extraColumns: any, cy: string, o: any) => {
    const items = {
        ...o,
        ...extraColumns
    }

    return (
        <List
            items={items}
            background="front.subtleDanger.background"
            template={Object.keys(items)
                .map(() => 'min-content')
                .join(' ')}
            switchToRowsAt={400}
        />
    )
}

const addDaySuffix = (s: string) => {
    if (parseInt(s) == 1) return `${s} day`
    return `${s} days`
}
const getPeriodMetric = (data: any) => {
    if (data.volume) return numberWithCommas(data.volume, true)
    if (data.occurrences) return numberWithCommas(data.occurrences, true)
    return '-'
}

const UnsupportedAlert = styled.div`
    background-color: ${(p) => p.theme['front.danger.color']};
    padding: 30px;
    border-radius: 10px;
    color: #fff;
`

const TableHolder = styled.div`
    background-color: ${(p) => p.theme['front.subtleDanger.background']};
    border-radius: 8px;
    padding: 0 12px;
    margin-left: -12px;

    @media print {
        background-color: #fff;
    }
`
const SubsectionHighlights = styled.span`
    background-color: rgba(0, 0, 0, 0.05);
    margin-left: -5px;
    padding: 0 5px;
    font-family: 'Roboto Mono', monospace;
    font-size: 10px;
    letter-spacing: -0.1px;
    white-space: nowrap;
    border-radius: 4px;
`
