import { camelCase } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { TMerchantApplicationFieldType } from './Application.StructureTypes'
import { MerchantApplicationFieldInnerStyles } from './Application.FieldInnerStyles'
import { MerchantApplicationEditsModalInput } from './Application.Input'
import { MerchantApplicationFieldCounter } from './Application.FieldCounter'
import { MerchantApplicationFile } from './Application.File'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { useRefTaker } from '../../../hooks/general/useRefTaker'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { IMerchantApplicationFieldDetails } from './Application.Structure'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { ApplicationFieldWarnNotCollaborator } from './Application.FieldValueEditing.WarnNotCollaborator'
import { PartnerGuidelinesTooltip } from '@/features/partner-guidelines'
import { useParams } from 'react-router-dom'

type Params = {
    sidebar: 'edits' | 'comments'
}

export const MerchantApplicationEditableFieldValue: React.FC<{
    label?: string
    type: TMerchantApplicationFieldType
    applicationId: string
    sanitised?: boolean
    isLoading?: boolean
    fieldDetails?: IMerchantApplicationFieldDetails
    highlighted?: 'top' | 'bottom' | 'both'
    indexPath: MerchantApplicationResourceIndexPath
    formRef: MerchantApplicationFieldFormProps['formRef']
    executeBatchChanges: MerchantApplicationFieldFormProps['executeBatchChanges']
    formErrors: { [key: string]: ValidationStatus }
    initialValue?: string
    isActive?: boolean
}> = ({
    label,
    type,
    isActive,
    applicationId,
    formRef,
    fieldDetails,
    highlighted,
    indexPath,
    formErrors,
    isLoading,
    initialValue,
    sanitised
}) => {
    const [scrollAnchor, setScrollAnchor] = useRefTaker()
    const params = useParams() as Params

    const contents = useMemo(() => {
        if (isLoading)
            return (
                <OffsetLoader>
                    <TextLineLoader height={21} />
                </OffsetLoader>
            )
        if (type === 'file') {
            return <MerchantApplicationFile applicationId={applicationId} indexPath={indexPath} />
        }
        return (
            <>
                <SanitisedStylings sanitised={sanitised}>
                    <MerchantApplicationEditsModalInput
                        initialValue={initialValue}
                        highlighted={highlighted}
                        formErrors={formErrors}
                        applicationId={applicationId}
                        isSelected={isActive}
                        indexPath={indexPath}
                        formRef={formRef}
                    />
                </SanitisedStylings>
            </>
        )
    }, [applicationId, isLoading, indexPath, isActive, highlighted, formErrors, type, initialValue, sanitised, formRef])

    useEffect(() => {
        if (isActive)
            scrollAnchor?.scrollIntoView({
                block: 'center',
                inline: 'center'
            })
    }, [isActive, scrollAnchor])

    if (!fieldDetails) return null

    return (
        <>
            <LabelHolder
                data-cy={`field-label ${label ? camelCase(label) : ''}`}
                tabIndex={-1}
                isActive={isActive}
                sanitised={sanitised}
                highlighted={highlighted}
            >
                {label}{' '}
                {label === 'Gateway' && initialValue && <PartnerGuidelinesTooltip gatewayIds={[initialValue]} />}
            </LabelHolder>
            <Holder ref={setScrollAnchor} isActive={isActive} highlighted={highlighted}>
                {contents}
            </Holder>
            <MerchantApplicationFieldCounter
                applicationId={applicationId}
                type="edits"
                indexPath={indexPath}
                isPressed={'edits' === params.sidebar && isActive}
                isActive={isActive}
                sanitised={fieldDetails?.field.sanitised}
                highlighted={highlighted}
                isLoading={false}
                isLast
            />
            {indexPath.fieldKey == 'signer.email' && (
                <ApplicationFieldWarnNotCollaborator applicationId={applicationId} />
            )}
        </>
    )
}

const Holder = styled.div<{ isActive?: boolean; highlighted?: 'top' | 'bottom' | 'both' }>`
    ${MerchantApplicationFieldInnerStyles};
    min-height: 24px;
    position: relative;
    top: -2px;
`
const SanitisedStylings = styled.div<{ sanitised?: boolean }>`
    display: contents;

    ${(p) =>
        p.sanitised &&
        css`
            input,
            textarea {
                font-style: italic;
                color: ${(p) => p.theme['front.text.subtlerI']};
            }
        `}
`

const LabelHolder = styled.div<{ isActive?: boolean; highlighted?: 'top' | 'bottom' | 'both'; sanitised?: boolean }>`
    margin-top: 0px;
    color: ${(p) => p.theme['front.text.subtlerI']};
    display: flex;
    ${(p) =>
        p.sanitised &&
        css`
            color: ${(p) => p.theme['back.text']};
        `}
`

const OffsetLoader = styled.div`
    position: relative;
    top: -1px;
    margin: 0;
    padding: 0;
`
