export type UBO = {
    name: string
    country: string
    ownershipPercentage: number
    error?:
        | {
              type: 'not-found'
          }
        | {
              type: 'typo'
              highlights: any
              localValue: string
          }
}

export function isSamePerson(appName: string, providerName: string) {
    const presenceCrossChecker: any = {}
    // We cross check each word of the name to make sure it contains the same 'words', no matter the order
    if (!appName?.length) return false
    if (!providerName?.length) return false

    const cleanupString = (str: string) => {
        return str
            .replace(/[._,\ '"]/g, '-')
            .split('-')
            .filter((f) => f)
    }

    cleanupString(appName).forEach((w: string) => (presenceCrossChecker[w.toLowerCase()] = true))
    cleanupString(providerName).forEach((w: string) => {
        if (!presenceCrossChecker[w.toLowerCase()]) return false
        else delete presenceCrossChecker[w.toLowerCase()]
    })

    // If no elements in the array, means we've added then remove the same name segments
    if (Object.keys(presenceCrossChecker).length === 0) {
        return true
    }

    return false
}

// export function deduplicateBeneficials(beneficials: Beneficial[]) {
//     const dedupDictionary: { [name: string]: Beneficial } = {}

//     beneficials.forEach((b) => {
//         const key = b.name + b.country
//         if (!dedupDictionary[key]) {
//             dedupDictionary[key] = { ...b }
//         } else {
//             dedupDictionary[key].ownershipPercentage += b.ownershipPercentage
//         }
//     })

//     return Object.keys(dedupDictionary)
//         .map((k) => dedupDictionary[k])
//         .sort((a, b) => (a.ownershipPercentage > b.ownershipPercentage ? -1 : 1))
// }

// export function removeUnknownBeneficials(beneficials: Beneficial[]) {
//     return beneficials.filter((f) => f.name !== 'Unknown')
// }
