import { createActionCreator } from 'deox'
import { PersonRole } from '../applications/types'
import {
    CompanyInfoPotentialMatch,
    ConflictsAndOwnershipShares,
    DataProvider,
    DataProviderStateAssigned,
    MutedConflictsPayload
} from './types'

const Prefix = 'APPLICATION_DATA_PROVIDERS'

export const ApplicationDataProvidersActions = {
    INIT: createActionCreator(`${Prefix}/INIT`, (resolve) => (applicationId: string) => resolve({ applicationId })),
    FETCH: createActionCreator(
        `${Prefix}/FETCH`,
        (resolve) => (applicationId: string, entityId?: string) => resolve({ applicationId, entityId })
    ),
    SCAN_FOR_CONFLICTS: createActionCreator(
        `${Prefix}/SCAN_FOR_CONFLICTS`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    SEARCH_FOR_MATCHES: createActionCreator(
        `${Prefix}/SEARCH_FOR_MATCHES`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    EDIT_ROLE: createActionCreator(
        `${Prefix}/EDIT_ROLE`,
        (resolve) => (watcherId: string, personId: string, applicationId: string, newRole: PersonRole) =>
            resolve({ watcherId, personId, applicationId, newRole })
    ),
    SKIP_SELECTION: createActionCreator(
        `${Prefix}/SKIP_SELECTION`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    BACK_TO_SELECTION: createActionCreator(
        `${Prefix}/BACK_TO_SELECTION`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    STORE_SEARCH_RESULTS: createActionCreator(
        `${Prefix}/STORE_SEARCH_RESULTS`,
        (resolve) => (results: CompanyInfoPotentialMatch[], applicationId: string) =>
            resolve({ results, applicationId })
    ),
    SELECT_RESULT: createActionCreator(
        `${Prefix}/SELECT_RESULT`,
        (resolve) => (applicationId: string, watcherId: string, resultId: string) =>
            resolve({ applicationId, watcherId, resultId })
    ),
    UNLINK: createActionCreator(
        `${Prefix}/UNLINK`,
        (resolve) => (applicationId: string, watcherId: string) =>
            resolve({ applicationId, watcherId })),
    IGNORE_CHANGES: createActionCreator(
        `${Prefix}/IGNORE_CHANGES`,
        (resolve) => (applicationId: string, watcherId: string, changes: any, skipNotification?: boolean) =>
            resolve({ applicationId, watcherId, changes, skipNotification })
    ),
    UNIGNORE_CHANGES: createActionCreator(
        `${Prefix}/UNIGNORE_CHANGES`,
        (resolve) => (applicationId: string, watcherId: string, changes: any) =>
            resolve({ applicationId, watcherId, changes })
    ),
    STORE: createActionCreator(
        `${Prefix}/STORE`,
        (resolve) =>
            (
                applicationId: string,
                entityId?: string,
                provider?: DataProvider,
                data?: DataProviderStateAssigned['linked'],
                mutedConflicts?: MutedConflictsPayload
            ) =>
                resolve({ applicationId, entityId, provider, data, mutedConflicts })
    ),
    STORE_MUTED_CONFLICTS: createActionCreator(
        `${Prefix}/STORE_MUTED_CONFLICTS`,
        (resolve) => (applicationId: string, mutedConflicts: MutedConflictsPayload) =>
            resolve({ applicationId, mutedConflicts })
    ),
    STORE_CONFLICTS_AND_OWNERSHIP_SHARES: createActionCreator(
        `${Prefix}/STORE_CONFLICTS_AND_OWNERSHIP_SHARES`,
        (resolve) => (applicationId: string, conflictsAndOwnershipShares: ConflictsAndOwnershipShares) =>
            resolve({ applicationId, conflictsAndOwnershipShares })
    ),
    FIX_TYPOS: createActionCreator(
        `${Prefix}/FIX_TYPOS`,
        (resolve) => (watcherId: string, applicationId: string, type: 'director' | 'ownership') =>
            resolve({ applicationId, type, watcherId })
    )
}
