import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Sidebar } from '../../components/listPages/sidebarNavigation/sidebar'
import { Account } from '../../components/general/account'
import { AgentBubble } from '../../components/general/agentBubble'
import { Card } from '../../components/cards/card'
import { CardInset } from '../../components/cards/cardInset'
import { Flex } from '../../components/layout/flex'
import { List } from '../../components/layout/list'
import { PageContent } from '../../components/layout/pageContent'
import { PageHeader } from '../../components/layout/pageHeader'
import { RelativeDate } from '../../components/general/relativeDate'
import { Table, TableRowType } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { FiltersController, useListPage } from '../../hooks/pages/useListPage'
import { ListPageFilterAgent } from '../../components/listPageFilters/listPageFilterAgent'
import { ListPageFilterAlertStatus } from '../../components/listPageFilters/listPageFilterAlertStatus'
import { ListPageFilterAlertTags } from '../../components/listPageFilters/listPageFilterAlertTags'
import { ListPageFilterDate } from '../../components/listPageFilters/listPageFilterDate'
import { ListPageFilterMerchant } from '../../components/listPageFilters/listPageFilterMerchant'
import { ListPageFiltersSeparator } from '../../components/listPageFilters/listPageFiltersSeparator'
import { Filters } from '../../components/taskPages/filters'
import { PopularFilters } from '../../components/taskPages/popularFilters'
import { RootState } from '@/store'
import { TasksDispatchLoadSummaries } from '../../store/tasks/actions'
import { TaskFiltersParams, TasksFilters } from '../../store/tasks/types'
import { Collection, uppercaseFirstLetter } from '../../utils'
import { filterRemoveODDTags } from '../../utils/tagFilters'
import { TagsLister } from '../Merchant/MerchantTagsLister'
import { SideFilters } from '../../components/layout/sideFilters'
import { TableBubble } from '../../components/layout/tableBubble'
import { useLocation } from 'react-router-dom'

const paginationKeys = {
    pageKey: 'tasks_page',
    perPageKey: 'tasks_per_page'
}

export const Alerts: React.FC = () => {
    const summaries = useSelector((state: RootState) => {
        return state.tasks.taskSummaries
    })

    const onLoad = useCallback((filters: any) => {
        return TasksDispatchLoadSummaries(filters, 'transaction-investigation')
    }, [])

    const location = useLocation()
    const me = useSelector((state: RootState) => state.auth?.user?.['https://clearhaus.com/app_metadata'])
    const tags = useSelector((state: RootState) => state.tasks.tags)
    const { filters, paginationNode } = useListPage<TasksFilters>(
        'AlertsList',
        'Alerts',
        onLoad,
        TaskFiltersParams,
        summaries,
        '/alerts/',
        location,
        undefined,
        paginationKeys
    )

    const renderedFilters = useMemo(() => {
        const items: any = (isDark = false) => ({
            'Status': {
                type: 'custom',
                node: <ListPageFilterAlertStatus isDark={isDark} filters={filters} />
            },
            'MID': {
                type: 'custom',
                node: (
                    <ListPageFilterMerchant<TasksFilters>
                        filters={filters}
                        fieldKey="tasks_subject_id"
                        isDark={isDark}
                        secondFilterKey="tasks_subject_type"
                    />
                )
            },
            'Agent': {
                type: 'custom',
                node: <ListPageFilterAgent<TasksFilters> isDark={isDark} filters={filters} fieldKey="tasks_agent" />
            },
            'Tags': {
                type: 'custom',
                node: <ListPageFilterAlertTags isDark={isDark} filter={filterRemoveODDTags} filters={filters} />
            },
            ...(isDark
                ? {
                      'Separator 1': {
                          key: 'separator-1',
                          type: 'separator',
                          node: <ListPageFiltersSeparator isDark={isDark} />
                      }
                  }
                : {}),
            'Created after': {
                type: 'custom',
                node: (
                    <ListPageFilterDate<TasksFilters> isDark={isDark} filters={filters} filterKey="tasks_start_date" />
                )
            },
            'Created before': {
                type: 'custom',
                node: <ListPageFilterDate<TasksFilters> isDark={isDark} filters={filters} filterKey="tasks_end_date" />
            }
        })
        return {
            sideFilters: <SideFilters items={items()} />,
            headerFilters: (
                <Filters
                    showHideController={filters.showHideController}
                    filters={filters}
                    resultsCount={summaries.all.length}
                    entityName="tasks"
                    pagination={summaries.pagination}
                    key="allFilters"
                    hotkeysScope="AlertsList"
                >
                    <List
                        background="floating.background"
                        items={items(true)}
                        emphasizeLabels
                        template="auto"
                        switchToRowsAt={100000}
                        cellHorizontalTemplate="100px auto"
                    />
                </Filters>
            )
        }
    }, [summaries, filters])

    const renderedPopularFilters = useMemo(() => {
        return (
            <PopularFilters
                showHideController={filters.showHideController}
                key="popularFilters"
                hotkeysScope="AlertsList"
                config={[
                    'General filters:',
                    {
                        label: 'All *Open* alerts',
                        action: () => {
                            filters.set({ tasks_status: ['open'] })
                        }
                    },
                    {
                        label: 'All *New* alerts',
                        action: () => {
                            filters.set({ tasks_status: ['new'] })
                        }
                    },
                    {
                        label: 'Alerts assigned to me',
                        action: () => {
                            filters.set({ tasks_agent: me?.uuid })
                        }
                    },
                    {
                        label: 'All alerts *marked as important*',
                        action: () => {
                            filters.set({
                                tasks_tag: ['important']
                            })
                        }
                    },
                    'Alert type filters:',
                    {
                        label: 'Open alerts with tags including *"Capture"*',
                        action: actionForPopularFilter(filters, tags, 'capture')
                    },
                    {
                        label: 'Open alerts with tags including *"Refund"*',
                        action: actionForPopularFilter(filters, tags, 'refund')
                    },
                    {
                        label: 'Open alerts with tags including *"Credit"*',
                        action: actionForPopularFilter(filters, tags, 'credit')
                    },
                    {
                        label: 'Open alerts with tags including *"Dispute"*',
                        action: actionForPopularFilter(filters, tags, 'dispute')
                    },
                    {
                        label: 'Open alerts with tags including *"Fraud"*',
                        action: actionForPopularFilter(filters, tags, 'fraud')
                    }
                ]}
            />
        )
    }, [filters, me, tags])

    return (
        <Flex align="stretch">
            <Sidebar hotkeysScope="AlertsList" />
            {renderedFilters.sideFilters}
            <PageContent marginLeft={0} noVerticalPadding>
                <PageHeader
                    noBottomBorder
                    title="Alerts"
                    subtitle=""
                    leftSideMemo={renderedPopularFilters}
                    rightSideMemo={renderedFilters.headerFilters}
                />
                <Card>
                    <CardInset>
                        <Table
                            background="front.background"
                            cols={[
                                {
                                    text: 'State',
                                    loaderSize: { min: 30, max: 30 }
                                },
                                { text: 'MID', loaderSize: { min: 55, max: 55 } },
                                { text: 'Merchant', loaderSize: { min: 100, max: 200 } },
                                { text: 'Created', loaderSize: { min: 50, max: 90 } },
                                { text: 'Assignee', loaderSize: { min: 35, max: 35 } },
                                { text: 'Tags', loaderSize: { min: 100, max: 505 } }
                            ]}
                            loaderRows={20}
                            // eslint-disable-next-line max-len
                            columnLayout="min-content min-content minmax(15%, min-content) min-content minmax(100px, min-content) auto"
                            showLastRowBorder
                            keepQuery
                            displayLoader={summaries.loadingStatus == 'started'}
                            rows={summaries.all.map((id: string) => {
                                const t = summaries.at[id]
                                return {
                                    type: getRowType(t.state),
                                    link: `/alerts/${t.id}${location.search}`,
                                    key: t.id,
                                    items: [
                                        {
                                            node: (
                                                <Text
                                                    noWrap
                                                    bold={t.state !== 'closed'}
                                                    color={t.state !== 'closed' ? 'front.text' : 'front.text.subtlerI'}
                                                >
                                                    {uppercaseFirstLetter(t.state)}
                                                </Text>
                                            )
                                        },
                                        {
                                            node: (
                                                <TableBubble type="outlined">
                                                    <Account id={t.subjectId} merchant showOnlyMID />
                                                </TableBubble>
                                            )
                                        },
                                        {
                                            node: (
                                                <Text>
                                                    <Account id={t.subjectId} merchant />
                                                </Text>
                                            )
                                        },
                                        {
                                            node: (
                                                <Text color="front.text.subtlerI" noWrap>
                                                    <RelativeDate dateString={t.createdAt} />
                                                </Text>
                                            )
                                        },
                                        {
                                            node: <AgentBubble id={t.assignee} slim filled={t.state != 'closed'} />
                                        },
                                        {
                                            node: (
                                                <TagsLister overBackground="front.background" tags={t.tags} limit={2} />
                                            )
                                        }
                                    ]
                                }
                            })}
                            emptyText="No tasks found matching the filtered criterias."
                        />
                    </CardInset>
                </Card>
                {paginationNode}
            </PageContent>
        </Flex>
    )
}

const getRowType = (state: string): TableRowType => {
    if (state == 'new') return 'emphasized'
    if (state == 'open') return 'normal'
    return 'subtle'
}

const actionForPopularFilter = (
    filters: FiltersController<TasksFilters>,
    tags: Collection<{ name: string }>,
    filterByValue: string
) => {
    const matchingTags = tags.all
        .map((k) => {
            const tag = tags.at[k]
            if (tag.name.toLowerCase().includes(filterByValue)) return tag.name
            return undefined
        })
        .filter((f) => f) as string[]
    return () => {
        filters.set({ tasks_tag: matchingTags })
    }
}
