import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Spacer } from '../../../components/layout/spacer'
// eslint-disable-next-line max-len
import { ApplicationInternalsSchemesScreeningActions } from '../../../store/applicationInternals/schemesScreening/actions'
import { RootState } from '@/store'
import { ApplicationSchemesScreeningPanelResultBlock } from './Application.SchemesScreeningPanel.ResultBlock'
import styled from 'styled-components'
import { Icon } from '../../../components/icons/icon'
import { Offset } from '../../../components/layout/offset'
import { Account } from '../../../components/general/account'
import { DateFormats } from '../../../utils/dateUtils'
import moment from 'moment'
import { Separator } from '../../../components/layout/separator'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { LinkButton } from '../../../components/buttons/linkButton'
import { ApplicationSchemesScreeningPastInquiries } from './Application.SchemesScreeningPastInquiries'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import { useNavigate } from 'react-router-dom'
import { Result } from '@/store/applicationInternals/schemesScreening/types'
import { ApplicationSchemesScreeningPanelPastInquiriesBlock } from './Application.SchemesScreeningPanel.PastInquiriesBlock'

export const ApplicationSchemesScreeningPanel: React.FC<{ applicationId: string }> = ({ applicationId }) => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => {
        return state.applicationInternals.schemesScreening.forApplication[applicationId]
    })
    const status = state?.status
    const latestInquiry = state?.latestInquiry

    let author = state?.latestInquiry?.by || state?.latestInquiry?.conclusion?.by

    const results: Result[] = []
    const latestInquiryResults: Result[] = []
    const latestPastInquiryResults: any[] = []
    if (latestInquiry && latestInquiry.vmssResults) {
        latestInquiryResults.push(...latestInquiry.vmssResults)
        results.push(...latestInquiry.vmssResults)
    }

    if (latestInquiry && latestInquiry.matchResults) {
        results.push(...latestInquiry.matchResults)
        latestInquiryResults.push(...latestInquiry.matchResults)
    }

    if (latestInquiry && latestInquiry.matchPastInquiries) {
        latestPastInquiryResults.push(...latestInquiry.matchPastInquiries)
    }

    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const [watcher, watcherId] = useNamedWatcher(`${applicationId}-screening`)
    const navigate = useNavigate()
    const handleStartScreeningClicked = useCallback(
        (e, wId) => {
            dispatch(ApplicationInternalsSchemesScreeningActions.SCREEN(applicationId, wId))
        },
        [dispatch, applicationId]
    )

    useEffect(() => {
        if (watcher == 'success') {
            navigate(`/merchant/${applicationId}/application/screening-results`)
        }
    }, [watcher, applicationId, history])

    useEffect(() => {
        setSelectedRows([])
    }, [latestInquiry])

    const errorMemo = useMemo(() => {
        return <Text>Something went wrong. Screening service failed.</Text>
    }, [])

    if (state?.loadingStatus === 'error')
        return (
            <Card background="back.background.strongerI" shadowLess>
                <CardInset reduceVerticalPadding>
                    An error has occured while contacting the screening service.
                </CardInset>
            </Card>
        )

    if (state?.loadingStatus !== 'done')
        return (
            <Card background="back.background.strongerI" shadowLess>
                <CardInset reduceVerticalPadding>
                    <TextLineLoader height={30} />
                </CardInset>
            </Card>
        )

    if (status == 'inconclusive-inquiry') {
        if (!latestInquiry) return errorMemo
        return (
            <Card background="back.background.strongerI" hasNoOverflow>
                <CardInset>
                    <Flex column>
                        <Flex align="center" justify="space-between" column>
                            <Text bold>Schemes screening results received successfuly.</Text>
                            <Text color="front.text.subtlerI" size="s">
                                Screened by{' '}
                                <Text bold>
                                    <Account id={latestInquiry?.by} />
                                </Text>{' '}
                                on{' '}
                                <Text bold>
                                    {moment(latestInquiry?.at).format(
                                        DateFormats.dayAndTime(moment(latestInquiry?.at))
                                    )}
                                </Text>
                                .
                            </Text>
                            <Spacer height={10} />
                            <Text bold>
                                We've found {results!.length} results{' '}
                                {latestPastInquiryResults.length
                                    ? `& ${latestPastInquiryResults.length} related past inquiries`
                                    : ''}
                            </Text>
                            <Spacer height={10} />
                            <LinkButton
                                background="front.accent.color"
                                to={`/merchant/${applicationId}/application/screening-results`}
                            >
                                <ButtonInset>Explore results</ButtonInset>
                            </LinkButton>
                        </Flex>
                    </Flex>
                </CardInset>
            </Card>
        )
    }

    if (status == 'no-inquiry')
        return (
            <Card background="back.background.strongerI" shadowLess>
                <CardInset reduceVerticalPadding>
                    <Flex align="center" justify="space-between">
                        <Text color="front.text.subtlerI">
                            <b>VMSS + MATCH</b> screening ready
                        </Text>
                        <WatcherButton
                            background="front.accent.color"
                            onClick={handleStartScreeningClicked}
                            predefinedWatcher={watcherId}
                        >
                            <ButtonInset>Screen Merchant</ButtonInset>
                        </WatcherButton>
                    </Flex>
                </CardInset>
            </Card>
        )

    if (status == 'successful-inquiry') {
        if (!latestInquiry) return errorMemo
        return (
            <Card background="back.background.strongerI" shadowLess>
                <CardInset>
                    <Flex align="center">
                        <Flex column justify="flex-start">
                            <Offset top={1.5}>
                                <Icon type="checkmark" size={16} weight={2} color="front.success.color" />
                            </Offset>
                        </Flex>
                        <Spacer width={15} />
                        <Flex column>
                            <Text color="front.success.color">VMAS + MATCH screening passed </Text>
                            <Text color="front.success.color">
                                Passed by{' '}
                                <Text bold>
                                    <Account id={author} />
                                </Text>{' '}
                                on{' '}
                                <Text bold>
                                    {moment(latestInquiry?.at).format(DateFormats.fullDate(moment(latestInquiry?.at)))}
                                </Text>
                            </Text>
                        </Flex>
                    </Flex>
                    <Spacer height={20} />
                    <Flex column>
                        {latestInquiry?.conclusion?.matches
                            .map((resultId) => {
                                return latestInquiryResults?.find((r) => r.resultId == resultId)
                            })
                            .filter((r) => r)
                            .map((r) => (
                                <ApplicationSchemesScreeningPanelResultBlock applicationId={applicationId} r={r!} />
                            ))}
                        {latestInquiry?.conclusion?.pastInquiryMatches.length > 0 ? (
                            <>
                                <Spacer height={15} />
                                <Text align="center" color="front.text" bold>
                                    Past inquiry hits
                                </Text>
                                <Spacer height={15} />
                            </>
                        ) : null}

                        {latestInquiry?.conclusion?.pastInquiryMatches
                            ?.map((pastInquiryId) => {
                                return latestPastInquiryResults?.find((r) => r.pastInquiryId == pastInquiryId)
                            })
                            .filter((r) => r)
                            .map((r) => {
                                return (
                                    <ApplicationSchemesScreeningPanelPastInquiriesBlock
                                        applicationId={applicationId}
                                        r={r!}
                                    />
                                )
                            })}
                    </Flex>
                    <Spacer height={15} />
                    <Separator background="back.background.strongerI" />
                    <Spacer height={15} />
                    <Flex justify="center">
                        <LinkButton
                            background="front.accent.color"
                            to={`/merchant/${applicationId}/application/screening-results`}
                        >
                            <ButtonInset>Explore results</ButtonInset>
                        </LinkButton>
                    </Flex>
                </CardInset>
            </Card>
        )
    }

    if (status == 'no-results-inquiry') {
        if (!latestInquiry) return errorMemo
        return (
            <>
                <Card background="back.background.strongerI" shadowLess>
                    <CardInset>
                        <Flex column>
                            <Flex align="center" justify="space-between" column>
                                <Text>
                                    <Text bold>No VMSS or MATCH results were found on last screening</Text>
                                </Text>
                                <Spacer height={5} />
                                <Text>
                                    Made by{' '}
                                    <Text bold>
                                        <Account id={latestInquiry?.by} />
                                    </Text>{' '}
                                    on{' '}
                                    <Text bold>
                                        {moment(latestInquiry?.at).format(
                                            DateFormats.dayAndTime(moment(latestInquiry?.at))
                                        )}
                                    </Text>
                                    .
                                </Text>
                                <Spacer height={15} />
                                <Flex>
                                    <WatcherButton
                                        background="front.accent.color"
                                        onClick={handleStartScreeningClicked}
                                        predefinedWatcher={watcherId}
                                    >
                                        <ButtonInset>Screen again</ButtonInset>
                                    </WatcherButton>
                                </Flex>
                            </Flex>
                        </Flex>
                    </CardInset>
                </Card>
                <Spacer height={10} />
                {latestPastInquiryResults?.length ? (
                    <Card background="back.background.strongerI" shadowLess>
                        <CardInset>
                            <Flex align="center" justify="space-between" column>
                                <Text>
                                    <Text bold>Found {latestPastInquiryResults?.length} related past inquiries</Text>
                                </Text>
                                <Spacer height={15} />
                                <Flex>
                                    <LinkButton
                                        background="front.accent.color"
                                        to={`/merchant/${applicationId}/application/screening-results`}
                                    >
                                        <ButtonInset>Explore results</ButtonInset>
                                    </LinkButton>
                                </Flex>
                            </Flex>
                        </CardInset>
                    </Card>
                ) : null}
            </>
        )
    }

    if (status == 'failed-inquiry') {
        if (!latestInquiry) return errorMemo
        return (
            <Card background="back.background.strongerI" shadowLess>
                <CardInset>
                    <Flex align="center">
                        <Flex column justify="flex-start">
                            <Offset top={1.5}>
                                <Icon type="x" size={16} weight={2} color="front.danger.color" />
                            </Offset>
                        </Flex>
                        <Spacer width={15} />
                        <Flex column>
                            <Text color="front.danger.color">VMAS + MATCH screening failed </Text>
                            <Text color="front.danger.color">
                                Failed by{' '}
                                <Text bold>
                                    <Account id={author} />
                                </Text>{' '}
                                on{' '}
                                <Text bold>
                                    {moment(latestInquiry?.at).format(DateFormats.fullDate(moment(latestInquiry?.at)))}
                                </Text>
                            </Text>
                        </Flex>
                    </Flex>
                    <Spacer height={15} />
                    <Separator background="back.background.strongerI" />
                    <Spacer height={15} />
                    <Flex column>
                        {latestInquiry?.conclusion?.matches
                            .map((resultId) => {
                                return latestInquiryResults?.find((r) => r.resultId == resultId)
                            })
                            .filter((r) => r)
                            .map((r) => (
                                <ApplicationSchemesScreeningPanelResultBlock applicationId={applicationId} r={r!} />
                            ))}
                        {latestInquiry?.conclusion?.pastInquiryMatches.length > 0 ? (
                            <>
                                <Spacer height={15} />
                                <Text align="center" color="front.text" bold>
                                    Past inquiry hits
                                </Text>
                                <Spacer height={15} />
                            </>
                        ) : null}

                        {latestInquiry?.conclusion?.pastInquiryMatches
                            ?.map((pastInquiryId) => {
                                return latestPastInquiryResults?.find((r) => r.pastInquiryId == pastInquiryId)
                            })
                            .filter((r) => r)
                            .map((r) => {
                                return (
                                    <ApplicationSchemesScreeningPanelPastInquiriesBlock
                                        applicationId={applicationId}
                                        r={r!}
                                    />
                                )
                            })}
                    </Flex>
                    <Spacer height={15} />
                    <Separator background="back.background.strongerI" />
                    <Spacer height={15} />
                    <Flex justify="center">
                        <LinkButton
                            background="front.accent.color"
                            to={`/merchant/${applicationId}/application/screening-results`}
                        >
                            <ButtonInset>Explore results</ButtonInset>
                        </LinkButton>
                    </Flex>
                </CardInset>
            </Card>
        )
    }

    return <span>Unknown screening state detected</span>
}

const Footer = styled.div`
    display: flex;
    position: sticky;
    bottom: 0;
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 15px;
    background: ${(p) => p.theme['back.background.strongerI']};
`
