import React, { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { DialogContext } from '../../../contexts/dialogProvider'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { RootState } from '@/store'
import { MerchantApplicationField } from './Application.Field'
import { GenerateIndexPathForSubsection } from './Application.Structure'
import { IMerchantApplicationSubsectionStructure } from './Application.StructureTypes'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { MerchantApplicationSectionGrid } from './Application.SectionGrid'
import { MerchantApplicationSeparatorRow } from './Application.SeparatorRow'

interface MerchantApplicationSubsectionProps {
    applicationId: string
    formRef: MerchantApplicationFieldFormProps['formRef']
    subsectionStructure: IMerchantApplicationSubsectionStructure
    formErrors: { [key: string]: ValidationStatus }
    subsection: any
    fieldIndex: number
    executeBatchChanges: MerchantApplicationFieldFormProps['executeBatchChanges']
}

export const MerchantApplicationSubsection: React.FC<MerchantApplicationSubsectionProps> = ({
    subsectionStructure,
    applicationId,
    executeBatchChanges,
    formRef,
    subsection,
    fieldIndex,
    formErrors
}) => {
    const [hovered, setHovered] = useState(false)
    const dispatch = useDispatch()
    const dialogContext = useContext(DialogContext)
    const section = useSelector((state: RootState) => {
        return state.applicationResources.data.forApplication[applicationId]?.[subsectionStructure.resource]
    })

    const fields = useMemo(() => {
        if (section?.loadingStatus !== 'done') return []
        // return section.fields.map((section, subsectionIndex) => {
        //     const isHighlighted = subsectionIndex % 2 == 0
        return subsectionStructure.fields.map((field, i: number) => {
            if (!field) return null

            if (field.kind === 'spacer') return <MerchantApplicationSeparatorRow key={`separator-${i}`} />

            if (field.skipRendering) return null

            const indexPath: MerchantApplicationResourceIndexPath = GenerateIndexPathForSubsection(
                subsectionStructure,
                fieldIndex,
                field
            )

            return (
                <React.Fragment key={field.label}>
                    <MerchantApplicationField
                        executeBatchChanges={executeBatchChanges}
                        // highlighted={getHighlightTypeForField(subsectionStructure.fields, i, isHighlighted)}
                        indexPath={indexPath}
                        formErrors={formErrors}
                        applicationId={applicationId}
                        isFirst={i === 0}
                        formRef={formRef}
                        type={field.type}
                        label={field.label}
                    />
                </React.Fragment>
            )
            // })
        })
    }, [formRef, section, fieldIndex, applicationId, subsectionStructure, executeBatchChanges, formErrors])

    // const handleRemoveClick = useCallback(
    //     (subsectionIndex, watcherId) => {
    //         dialogContext?.setDialog({
    //             title: 'Confirm deletion',
    //             description: 'Are you sure you want to delete this section permanently?',
    //             action: {
    //                 label: 'Delete permanently',
    //                 buttonBackground: 'front.danger.color',
    //                 watcherId,
    //                 action: () => {
    //                     const indexPath: MerchantApplicationResourceIndexPath = {
    //                         resourceKey: subsectionStructure.resource,
    //                         subsectionIndex,
    //                         fieldKey: ''
    //                     }

    //                     dispatch(
    //                         ApplicationResourceActions.REMOVE_SUBSECTION(
    //                             applicationId,
    //                             indexPath,
    //                             subsectionStructure.suffix,
    //                             watcherId
    //                         )
    //                     )
    //                 }
    //             }
    //         })
    //     },
    //     [subsectionStructure, dispatch, applicationId, dialogContext]
    // )
    // const checkmarks = useMemo(() => {
    //     if (subsectionStructure.reviewableResource) {
    //         const resource = subsectionStructure.reviewableResource
    //         return subSections.map((s, i) => {
    //             return (
    //                 <MerchantApplicationSectionCheckmark
    //                     key={i}
    //                     applicationId={applicationId}
    //                     resource={resource}
    //                     subsectionIndex={i}
    //                 />
    //             )
    //         })
    //     }
    //     return []
    // }, [applicationId, subsectionStructure, subSections])

    return (
        // <WholeSectionHolder>
        <DynamicSectionHolder>
            <MerchantApplicationSectionGrid key="grid">{fields}</MerchantApplicationSectionGrid>
            {/* <CardHolder
                            data-cy="fields"
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                        >
                            <CardSpecial isFirst={i === 0}>
                                <ScrollHasher hash={kebabCase(subsectionStructure.suffix + '-' + i)} />
                                <CardInset>
                                    <MerchantApplicationSectionGrid>
                                        <SubsectionHeader>
                                            {checkmarks[i]}
                                            <Text bold color="front.text">
                                                {i + 1}.&nbsp;&nbsp;
                                                {subsectionStructure.suffix}
                                            </Text>
                                            {hovered && (
                                                <SubsectionHeaderAction>
                                                    <WatcherButton
                                                        color="front.danger.color"
                                                        cy="remove-section"
                                                        onClick={(e, watcherId) => {
                                                            handleRemoveClick(i, watcherId)
                                                        }}
                                                    >
                                                        <ButtonInset noHorizontalPadding noVerticalPadding>
                                                            Remove
                                                        </ButtonInset>
                                                    </WatcherButton>
                                                </SubsectionHeaderAction>
                                            )}
                                        </SubsectionHeader>
                                        {subsection}
                                    </MerchantApplicationSectionGrid>
                                </CardInset>
                            </CardSpecial>
                        </CardHolder>
                        <MerchantApplicationInternalDetails
                            applicationId={applicationId}
                            sectionStructure={subsectionStructure}
                            subsectionIndex={i}
                            isSectionLoaded={section.loadingStatus === 'done'}
                        /> */}
        </DynamicSectionHolder>
    )
    // </WholeSectionHolder>
}

const SubsectionHeaderAction = styled.div`
    position: absolute;
    right: 20px;
    top: 25px;
`

const CardSpecial = styled.div<{ isFirst?: boolean }>`
    background-color: ${(p) => p.theme['front.background']};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-top: 0;
    margin-top: -25px;
    height: calc(100% + 25px);
`

const CardHolder = styled.div`
    margin-top: -10px;
`

const WholeSectionHolder = styled.div`
    display: contents;
`

const DynamicSectionHolder = styled.div`
    display: contents;
`

const SubsectionHeader = styled.div`
    grid-column: span 7;
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;
`
