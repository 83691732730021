import { getFileByLabel } from '../../../../../helpers'
import { Files } from '@/services/merchant-api'
import React from 'react'
import { FileLink } from '../../../common/file-link'
import { Section } from '../../../common/section'

interface PersonDataItemsProps {
    files: Files
}

export const PersonDataItems: React.FC<PersonDataItemsProps> = (props) => {
    const { files } = props

    const pictureFile = getFileByLabel('picture_legitimation', files)
    const addressFile = getFileByLabel('address_legitimation', files)

    return (
        <>
            <Section.DataItem title="Picture legitimation" value={<FileLink file={pictureFile}>Download</FileLink>} />
            <Section.Spacer size="s" />
            <Section.DataItem title="Address legitimation" value={<FileLink file={addressFile}>Download</FileLink>} />
        </>
    )
}
