import { Collection, CollectionWithSelected } from '../../utils'
import { Agent } from '../applicationInternals/agents/types'

export interface Merchant {
    chBilling: false
    country: string
    currency: string
    descriptor: string
    id: string
    mcc: string
    merchantId: string
    metadata: {
        state: string
    }
    name: string
    paymentsHeld: false
    sanitisedName: string
    timezone: string
}

export interface AutocompleteState {
    agents: { forCurrentQuery: string[]; dataset: Agent[] } & CollectionWithSelected<Agent>
    merchants: { forCurrentQuery: string[] } & Collection<Merchant> & {
            atMid: { [key: string]: string }
        }
}

export const InitialAutocompleteState: AutocompleteState = {
    agents: {
        at: {},
        forCurrentQuery: [],
        all: [],
        dataset: [],
        selectedLoadingStatus: 'not-started',
        loadingStatus: 'not-started'
    },
    merchants: {
        at: {},
        forCurrentQuery: [],
        all: [],
        atMid: {},
        loadingStatus: 'not-started'
    }
}
