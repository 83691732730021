/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef } from 'react'
import FocusLock from 'react-focus-lock'
import { usePopper } from 'react-popper'
import styled, { css } from 'styled-components'
import { useModalStackSync } from '../../hooks/general/useModalStackSync'
import { useHotkey } from '../../hooks/hotkeys/useHotkey'
import { zIndexes } from '../../styles/zIndexes'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { MODAL_ID } from '../modals/modalIds'

export const Floater: React.FC<{
    cardId?: MODAL_ID
    anchor: HTMLElement
    children?: any
    shouldShow?: boolean
    cy?: string
    noStackSync?: boolean
    onHide?: () => void
    noFocusLock?: boolean
    hideOverflow?: boolean
    higher?: boolean
    placement?: 'bottom' | 'top' | 'right'
}> = ({
    cardId,
    children,
    cy,
    anchor,
    shouldShow,
    noFocusLock,
    noStackSync,
    higher,
    hideOverflow,
    onHide,
    placement = 'bottom'
}) => {
    const [popperHolder, setPopperHolder] = useRefTaker()
    const [arrow, setArrow] = useRefTaker()
    const mounted = useRef(false)

    useHotkey({
        keys: 'escape, alt+escape',
        action: onHide,
        scope: cardId
    })

    const [push, pop] = useModalStackSync(null, noStackSync ? undefined : cardId, undefined, true)

    const modifiers = useMemo(() => {
        return {
            placement,
            strategy: 'fixed',
            modifiers: [
                {
                    name: 'arrow',
                    options: {
                        element: arrow
                    }
                },
                {
                    name: 'offset',
                    options: {
                        offset: [5, 5]
                    }
                },
                {
                    name: 'preventOverflow',
                    options: {
                        padding: 15,
                        boundary: anchor?.closest('[data-floater-boundary]') || window
                    }
                },
                {
                    name: 'sameWidth',
                    enabled: true,
                    fn: ({ state }: { state: any }) => {
                        state.styles.popper.minWidth = `${state.rects.reference.width}px`
                    },
                    phase: 'beforeWrite',
                    requires: ['computeStyles'],
                    effect({ state }: any): any {
                        state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`
                    }
                }
            ]
        } as any
    }, [arrow, placement])

    const { styles, attributes, update } = usePopper(anchor, popperHolder, modifiers)

    useEffect(() => {
        if (shouldShow) {
            update?.()
            if (mounted.current === false) {
                push()
                mounted.current = true
            }
        } else if (mounted.current === true) {
            mounted.current = false
            pop()
        }
    }, [pop, push, shouldShow, update])

    const content = useMemo(() => {
        return (
            <Holder
                ref={setPopperHolder}
                style={styles.popper}
                shown={shouldShow}
                tabIndex={-1}
                higher={higher}
                {...attributes.popper}
            >
                <StyledCard data-cy={cy} hideOverflow={hideOverflow} higher={higher}>
                    {children}
                </StyledCard>
                <Arrow ref={setArrow} style={styles.arrow} {...(attributes.arrow as any)} />
            </Holder>
        )
    }, [
        attributes.arrow,
        attributes.popper,
        children,
        cy,
        hideOverflow,
        higher,
        setArrow,
        setPopperHolder,
        shouldShow,
        styles.arrow,
        styles.popper
    ])

    if (!shouldShow) return null

    if (noFocusLock) return content
    return <InvisibleFocusLock autoFocus={false}>{content}</InvisibleFocusLock>
}

const InvisibleFocusLock = styled(FocusLock)`
    display: contents;
`

const StyledCard = styled.div<{
    arrowLess?: boolean
    hideOverflow?: boolean
    higher?: boolean
}>`
    background-color: ${(p) => p.theme['floating.background']};
    box-shadow: ${(p) => p.theme.cardsHigherShadow};
    border-radius: 12px;
    z-index: ${zIndexes.popover};

    ${(p) =>
        p.higher &&
        css`
            background-color: ${(p) => p.theme['floating.background.strongerII']};
        `}

    ${(p) =>
        p.hideOverflow &&
        css`
            max-height: 40vh;
            overflow: auto;
        `}
`
export const Arrow = styled('div')`
    position: absolute;
    z-index: ${zIndexes.popover};
    width: 0;
    height: 0;
    border-style: solid;
`

const Holder = styled.div<any>`
    position: absolute;
    z-index: ${zIndexes.modals.megamodal};
    background-color: transparent;
    display: none;


    &[data-popper-placement^='bottom'] {
        ${(p) =>
            p.shown &&
            css`
                display: block;
            `}
        ${Arrow} {
            top: -3px;
            bottom: auto;
            border-width: 0 4.5px 4px 4.5px;
            border-color: transparent transparent ${(p) => p.theme['floating.background']}; transparent;
            ${(p) =>
                p.higher &&
                css`
                    border-color: transparent transparent ${(p) =>
                        p.theme['floating.background.strongerII']}; transparent;
                `}
        }
    }

    &[data-popper-placement^='top'] {
        ${(p) =>
            p.shown &&
            css`
                display: block;
            `}
        ${Arrow} {
            bottom: -3px !important;
            top: auto !important;
            border-width: 4px 4.5px 0 4.5px !important;
            border-color: ${(p) => p.theme['floating.background']} transparent transparent transparent !important;
            ${(p) =>
                p.higher &&
                css`
                    border-color: ${(p) =>
                        p.theme['floating.background.strongerII']}; transparent transparent transparent !important;
                `}
        }
    }
`
