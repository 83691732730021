import { TaskState, useGetTasks } from '@/services/taskr'

import { useMemo } from 'react'
import { TASKS_TYPE } from '../config'

export const useTasks = (options: {
    state?: TaskState
    assignee?: string
    applicationId?: string
    page?: number
    per_page?: number
}) => {
    const query = useMemo(() => {
        const { state, applicationId, assignee, page = 1, per_page = 20 } = options

        const typeQuery = `type:${TASKS_TYPE}`
        const stateQuery = state && `state:${state}`
        const applicationQuery = applicationId && applicationId
        const assigneeQuery = assignee && `assignee:${assignee}`

        const query = [typeQuery, stateQuery, applicationQuery, assigneeQuery].filter(Boolean).join(' ')

        return { query, per_page, page }
    }, [options])

    return useGetTasks(query)
}
