import { isTypo } from '../../utils/typosMatching/isTypo'
import { Person } from '../applications/types'
import { isSamePerson } from './sagas.conflictsFinder.utils'
import { CompanyInfoPotentialMatch, DataProviderDirectorConflict, MutedConflictsPayload } from './types'

// sot - sourceOfTruth
export const scanForDirectorConflicts = (
    dmp: any,
    appDirectorsAndOwners: Person[],
    appDirectors: Person[],
    sotDirector?: CompanyInfoPotentialMatch['director'],
    mutedConflicts?: Partial<MutedConflictsPayload>
): DataProviderDirectorConflict => {
    if (!sotDirector?.name) {
        return {
            type: 'data-provider-blank'
        }
    }
    const areDirectorConflictsMuted = mutedConflicts?.['director']?.['name']?.includes(sotDirector.name) || false

    const matchedDirector = appDirectors?.filter((d) => {
        if (!d.name) return false
        return isSamePerson(d.name, sotDirector.name)
    })[0]

    if (appDirectors.length === 0) {
        return {
            type: 'application-zero-directors',
            muted: areDirectorConflictsMuted
        }
    }

    if (!matchedDirector) {
        for (const d of appDirectors) {
            if (!d.name) continue
            const diff = isTypo(dmp, sotDirector.name, d.name)
            if (diff) {
                return {
                    type: 'typo',
                    ctx: {
                        sot: {
                            name: sotDirector.name,
                            country: sotDirector.country
                        },
                        highlights: diff
                    },
                    applicationDirectorMatch: d.id,
                    muted: areDirectorConflictsMuted
                }
            }
        }

        return {
            type: 'not-found',
            muted: areDirectorConflictsMuted,
            ctx: {
                sot: {
                    name: sotDirector.name,
                    country: sotDirector.country
                }
            }
        }
    } else {
        if (matchedDirector.role !== 'director-and-owner' && matchedDirector.role !== 'director') {
            return {
                type: 'wrong-role',
                muted: areDirectorConflictsMuted,
                applicationDirectorMatch: matchedDirector.id,
                ctx: {
                    sot: {
                        name: sotDirector.name,
                        country: sotDirector.country
                    }
                }
            }
        }
        if (sotDirector.country && matchedDirector.country !== sotDirector.country) {
            return {
                type: 'country-mismatch',
                muted: areDirectorConflictsMuted,
                ctx: {
                    sot: {
                        name: sotDirector.name,
                        country: sotDirector.country
                    }
                }
            }
        }
    }
    return {
        type: 'no-conflicts'
    }
}
