import React from 'react'
import { Text } from '../../../../../../components/general/text'

export const EmptyChanges: React.FC = () => {
    return (
        <Text>
            No changes have been found regarding this task. If this was present in the tasks list view, it's probably a
            backend error.
        </Text>
    )
}
