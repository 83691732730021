import React from 'react'
import { ReportStatusItem } from '../../common/report-status-item'
import { useDetailsStatus } from './use-details-status'

export const DetailsStatus: React.FC = () => {
    const completed = useDetailsStatus()

    return (
        <ReportStatusItem completed={completed} of={1}>
            Details
        </ReportStatusItem>
    )
}
