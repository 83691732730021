import React from 'react'
import { Styles, styles } from './styles'

interface LabelProps extends Styles {
    testid?: string
    children: React.ReactNode
    htmlFor?: string
}

export const Label: React.FC<LabelProps> = (props) => {
    const { testid, children, htmlFor, color } = props

    const onMouseDown = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
        // prevent text selection when double clicking label
        if (!event.defaultPrevented && event.detail > 1) event.preventDefault()
    }

    const classnames = styles({ color })

    return (
        <label data-cy={testid} className={classnames} htmlFor={htmlFor} onMouseDown={onMouseDown}>
            {children}
        </label>
    )
}
