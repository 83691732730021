// import { Table, OverlayTrigger, Tooltip } from "react-bootstrap"
import React, { useEffect, useState } from 'react'

import { useIsPrint } from '../../../hooks/general/useIsPrint'
import { currencyFilter, numberWithCommas, uppercaseFirstLetter } from '../../../utils'
import { ButtonInset } from '../../buttons/buttonInset'
import { SimpleButton } from '../../buttons/simpleButton'
import { IDShortener } from '../../general/idShortener'
import { PrintOnly } from '../../general/printOnly'
import { RelativeDate } from '../../general/relativeDate'
import { Spacer } from '../../layout/spacer'
import { Table } from '../../tables/table'
import { Text } from '../../general/text'
import { ContextCardHolder } from './commons'
import { ContextCardsNoDataFound } from './noDataFound'

export const TransactionsContextCard: React.FC<{ data?: any }> = ({ data }) => {
    const dataset = data.data.value
    const [showMore, setShowMore] = useState(false)
    const isPrinting = useIsPrint()

    useEffect(() => {
        if (isPrinting) setShowMore(true)
        else setShowMore(false)
    }, [isPrinting])

    if (!dataset || dataset.length == 0) return <ContextCardsNoDataFound />

    return (
        <ContextCardHolder align="stretch" column>
            <PrintOnly>
                <Spacer width={5} />
            </PrintOnly>
            <Table
                background="front.subtleAccent.background"
                cols={['id', 'processedAt', 'textOnStatement', 'amount', 'currency', 'cardData'].map((c, i, arr) => ({
                    text: uppercaseFirstLetter(c).replace(/-/g, ' '),
                    alignRight: ['amount', 'cardData', 'currency'].includes(c)
                }))}
                columnLayout="min-content min-content auto min-content minmax(70px, min-content) min-content"
                colors={{
                    border: 'transparent',
                    hover: 'transparent'
                }}
                type="narrow"
                rows={dataset
                    .map((data: any, i: number) => {
                        if (!showMore && i > 10) return null
                        return {
                            type: 'normal',
                            link: `/transactions/${data.id}`,
                            key: data.id,
                            linkTarget: 'managerTransactions',
                            items: [
                                {
                                    node: (
                                        <Text size="s" mono noWrap>
                                            <IDShortener id={data.id} />
                                        </Text>
                                    )
                                },
                                {
                                    node: (
                                        <Text size="s" noWrap>
                                            <span>
                                                <RelativeDate dateString={data.processedAt.toString().toString()} />
                                            </span>
                                        </Text>
                                    )
                                },
                                {
                                    node: <Text size="s">{data.textOnStatement}</Text>
                                },
                                {
                                    node: (
                                        <Text size="s" align="right" mono noWrap>
                                            {numberWithCommas(currencyFilter(data.currency, data.amount))}
                                        </Text>
                                    )
                                },
                                {
                                    node: (
                                        <Text size="s" align="right" mono noWrap>
                                            {data.currency}
                                        </Text>
                                    )
                                },
                                {
                                    node: (
                                        <Text size="s" align="right" mono noWrap>
                                            {extractCardDataString(data)}
                                        </Text>
                                    )
                                }
                            ]
                        }
                    })
                    .filter((f: any) => f)}
            />
            {dataset.length > 10 && (
                <>
                    <Spacer height={10} />
                    {isPrinting ? null : (
                        <SimpleButton onClick={() => setShowMore((t) => !t)}>
                            <ButtonInset>{showMore ? `Show less` : `Show more`}</ButtonInset>
                        </SimpleButton>
                    )}
                </>
            )}
        </ContextCardHolder>
    )
}

const extractCardDataString = (row: any) => {
    const cardType = row.cardType.substring(0, 1).toUpperCase()
    const cardScheme = row.cardScheme.substring(0, 1).toUpperCase()

    return `${cardScheme}, ${cardType}, ${row.cardCountry}, ${row.cardBin} *** ${row.cardLast4}`
}
