import styled, { css } from 'styled-components'

import { Spacer } from './spacer'
import { Text } from '../general/text'
import { zIndexes } from '../../styles/zIndexes'
import Color from 'color'

export const PageHeader: React.FC<{
    title: string | React.ReactElement
    subtitle: string | React.ReactChild
    leftSideMemo?: React.ReactNode
    rightSideMemo?: React.ReactNode
    noBottomBorder?: boolean
    float?: boolean
    insetLeft?: boolean
    insetTop?: boolean
    leftOffset?: number
    slimmer?: boolean
}> = ({
    title,
    subtitle,
    leftOffset,
    float,
    rightSideMemo,
    leftSideMemo,
    noBottomBorder,
    insetLeft,
    insetTop,
    slimmer
}) => {
    return (
        <Holder
            noBottomBorder={noBottomBorder}
            data-cy="page-header"
            insetLeft={insetLeft}
            insetTop={insetTop}
            leftOffset={leftOffset}
            float={float}
            slimmer={slimmer}
            data-prevent-sidebar-exit
        >
            <LeftSide>
                <UnshrinkablePart>
                    <Text bold color="front.text" oneLine>
                        {title}
                    </Text>
                    <Spacer width={5} />
                    <Text color="back.text.subtlerI" cy="page-subtitle">
                        {subtitle}
                    </Text>
                    <Spacer width={10} />
                </UnshrinkablePart>
                {leftSideMemo}
            </LeftSide>
            <RightSide>{rightSideMemo}</RightSide>
        </Holder>
    )
}

const UnshrinkablePart = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
`

const RightSide = styled.div`
    display: flex;
`

const LeftSide = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
`

const Holder = styled.div<{
    noBottomBorder?: boolean
    insetLeft?: boolean
    insetTop?: boolean
    slimmer?: boolean
    float?: boolean
    leftOffset?: number
}>`
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 7px;
    margin-top: 7px;

    ${(p) =>
        p.float &&
        css`
            position: sticky;
            top: 0;
            margin: 0;
            padding: 0 25px;
            height: 50px;
            flex-shrink: 0;
            box-sizing: border-box;
            background-color: ${(p) => Color(p.theme['back.background']).string()};
            border-bottom: 1px solid ${(p) => p.theme['back.background.strongerI']};
            z-index: ${zIndexes.sidebar};
        `}

    ${(p) =>
        p.slimmer &&
        css`
            height: 30px;
            margin-top: 10px;
            margin-bottom: 10px;
        `}
    
    ${(p) =>
        p.insetLeft &&
        css`
            margin-left: -5px;
        `}

    ${(p) =>
        p.noBottomBorder &&
        css`
            margin-top: 0;
            margin-bottom: 0;
            border-bottom: none;
        `}
    ${(p) =>
        p.insetTop &&
        css`
            margin-top: -30px;
        `}
`
