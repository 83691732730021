import { delay, put } from 'redux-saga/effects'
import { ToastsDispatchPush } from '../toasts/actions'
import { SagasForWatchers, WatcherActionFail, WatcherActionSuccess, WatcherDispatchComplete } from './actions'

export class WatcherSagas implements SagasForWatchers {
    *success(action: WatcherActionSuccess) {
        if (action.message) yield put(ToastsDispatchPush(action.message, 'success'))
        yield delay(1000)
        yield put(WatcherDispatchComplete(action.watchers))
    }

    *throw(action: WatcherActionFail) {
        if (action.message) yield put(ToastsDispatchPush(action.message, 'error', undefined, undefined, action.context))
        yield delay(1000)
        yield put(WatcherDispatchComplete(action.watchers))
    }
}
