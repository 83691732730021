import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { Table } from '../../components/tables/table'
import { Text } from '../../components/general/text'
import { TimelineCard } from '../../components/listPages/timelineCard'
import { Settlement } from '../../store/settlements/types'
import { numberWithCurrency } from '../../utils'

export const SettlementCardFees: React.FC<{ settlement: Settlement }> = ({ settlement }) => {
    if (!settlement.fees) return <></>

    const fees: any = {
        'Sales': settlement.fees.sales,
        'Refunds': settlement.fees.refunds,
        'Authorisations': settlement.fees.authorisations,
        '3-D Secure': settlement.fees["3Dsecure"],
        'Credits': settlement.fees.credits,
        'Interchange': settlement.fees.interchange,
        'Late capture': settlement.fees.lateCapture,
        'Scheme': settlement.fees.scheme,
        'Minimum processing': settlement.fees.minimumProcessing,
        'Service': settlement.fees.service,
        'Wire transfer': settlement.fees.wireTransfer,
        'Chargebacks': settlement.fees.chargebacks,
        'Retrieval requests': settlement.fees.retrievalRequests,
        'Series': settlement.fees.series
    }

    const rows: any = Object.keys(fees)
        .filter((f) => fees[f] !== 0)
        .map((f) => {
            return {
                type: 'normal' as const,
                key: 'payout',
                noAction: true,
                items: [
                    {
                        node: f
                    },
                    {
                        node: (
                            <Flex align="baseline">
                                {numberWithCurrency(settlement.currency, fees[f])}
                                <Spacer width={10} />
                                <Text noWrap color="front.text.subtlerI">
                                    {settlement.currency}
                                </Text>
                            </Flex>
                        )
                    }
                ]
            }
        })

    rows.push({
        type: 'normal' as const,
        key: 'payout',
        noAction: true,
        items: [
            {
                node: <b>Total</b>
            },
            {
                node: (
                    <b>
                        <Flex align="baseline">
                            {numberWithCurrency(settlement.currency, settlement.summary.fees)}
                            <Spacer width={10} />
                            <Text noWrap bold>
                                {settlement.currency}
                            </Text>
                        </Flex>
                    </b>
                )
            }
        ]
    })

    return (
        <TimelineCard title="Fees" background="front.background">
            <Table
                background="front.background"
                cols={[
                    {
                        text: 'Fee type'
                    },
                    {
                        alignRight: true,
                        text: 'Amount'
                    }
                ]}
                rows={rows}
                noHeader
                columnLayout="auto min-content"
            />
        </TimelineCard>
    )
}
