import { Tooltip, Link } from '@/design-system'
import _ from 'lodash'
import React from 'react'

interface HoverCardVerificationKeyProps {
    verificationKey: string
}

export const HoverCardVerificationKey: React.FC<HoverCardVerificationKeyProps> = (props) => {
    const { verificationKey } = props

    const truncatedVerificationKey = _.truncate(verificationKey, { length: 32, omission: '...' })

    return (
        <Tooltip delayDuration={0}>
            <Tooltip.Trigger>
                <Link asChild>
                    <span>{truncatedVerificationKey}</span>
                </Link>
            </Tooltip.Trigger>
            <Tooltip.Content>
                <pre>{verificationKey}</pre>
            </Tooltip.Content>
        </Tooltip>
    )
}
