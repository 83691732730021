import styled from 'styled-components'
import { TypoHighlight } from '../../store/utils'
import { Color } from '../../styles/colors'
import { Text } from './text'

export const TyposHighlighter: React.FC<{
    highlights?: TypoHighlight[]
    fallbackText: string
    isSourceOfTruth?: boolean
}> = ({ highlights, fallbackText, isSourceOfTruth }) => {
    if (highlights) {
        return (
            <Text>
                {highlights
                    .filter((h) => h[0] == 0 || (h[0] == 1 && !isSourceOfTruth) || (h[0] == -1 && isSourceOfTruth))
                    .map((h) => {
                        const color: Color = h[0] == 1 ? 'front.accent.color' : 'front.text'
                        if (h[0] == 1) {
                            return (
                                <BGChanger data-cy="typo-highlight">
                                    <Text>{h[1].toUpperCase()}</Text>
                                </BGChanger>
                            )
                        }
                        if (h[0] == -1) {
                            return (
                                <Bordered data-cy="typo-border">
                                    <Text bold>{h[1].toUpperCase()}</Text>
                                </Bordered>
                            )
                        }
                        return <Text color={color}>{h[1].toUpperCase()}</Text>
                    })}
            </Text>
        )
    }
    return <Text>{fallbackText}</Text>
}

const BGChanger = styled.span`
    border-radius: 3px;
    padding: 0 1px;
    margin: 0 -1px;
    font-weight: bold;
    background-color: ${(p) => p.theme['front.info.background']};
    border: 1px solid ${(p) => p.theme['front.info.background.strongerI']};
    color: ${(p) => p.theme['front.info.text']};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
`

const Bordered = styled.span`
    position: relative;

    &:after {
        position: absolute;
        content: '';
        bottom: -3px;
        background-color: ${(p) => p.theme['front.accent.color']};
        height: 2px;
        width: 100%;
        left: 0;
        border-radius: 2px;
    }
`
