export interface Toast {
    id: string
    type: 'error' | 'success' | 'call' | 'context'
    message: string | React.ReactNode
    duration: 'normal' | 'longer' | 'infinite' | 'shorter'
    context?: any
}

export interface ToastsState {
    at: {
        [key: string]: Toast
    }
    all: string[]
}

export const InitialToastsState: ToastsState = {
    at: {},
    all: []
}
