import { useEffect, useRef } from 'react'

import { useRefTaker } from './useRefTaker'

export const useOnClickWithoutMouseMove = (onClick?: (e: any) => void, r?: any) => {
    const initialEvent = useRef<{ x: number; y: number }>()

    const [ref, setRef] = useRefTaker()

    useEffect(() => {
        if (r) {
            setRef(r)
        }
    }, [r, setRef])

    useEffect(() => {
        if (!ref) return
        const clickDown = (event: any) => {
            initialEvent.current = {
                x: event.screenX,
                y: event.screenY
            }
        }
        const clickUp = (event: any) => {
            const previous = {
                x: initialEvent.current?.x ? initialEvent.current?.x : 0,
                y: initialEvent.current?.y ? initialEvent.current?.y : 0
            }

            if (Math.abs(previous.x - event.screenX) > 10 || Math.abs(previous.y - event.screenY) > 10) {
                event.preventDefault()
                return
            }
            onClick?.(event)
        }

        ref.addEventListener('mousedown', clickDown)
        ref.addEventListener('click', clickUp)
        return () => {
            ref.removeEventListener('mousedown', clickDown)
            ref.removeEventListener('click', clickUp)
        }
    }, [onClick, ref])

    return setRef
}
