import React from 'react'
import { Section } from '../../../common/section'
import { useReportMerchantData } from '../../../provider/report-provider/report-provider'

const order = [
    { key: 'name' },
    { key: 'address_line_1', title: 'Address line 1' },
    { key: 'address_line_2', title: 'Address line 2' },
    { key: 'city' },
    { key: 'zipcode' },
    { key: 'country' },
    { key: 'email' },
    { key: 'phone' }
]

export const CompanyGeneralInfo: React.FC = () => {
    const merchantData = useReportMerchantData()

    return <Section.DataItems data={merchantData.company} order={order} />
}
