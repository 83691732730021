import { TableRowType } from '../../../../../../../../components/tables/table'
import { Text } from '../../../../../../../../components/general/text'
import { NeededExtraRequestsPersonWithCutterInfo } from '../../../../../../../../store/tasksCompanyChanges/types'
import { uppercaseFirstLetter } from '../../../../../../../../utils'

export const getRows = (people: NeededExtraRequestsPersonWithCutterInfo[]) => {
    return people.map((p) => {
        return {
            type: 'normal' as TableRowType,
            noAction: true,
            key: p.id,
            items: [
                {
                    node: <Text>{p.name}</Text>
                },
                {
                    node: <Text oneLine>{p.socialSecurityNumber}</Text>
                },
                {
                    node: <Text oneLine>{p.role ? uppercaseFirstLetter(p.role) : 'No role'}</Text>
                }
            ]
        }
    })
}
