import { createActionCreator } from 'deox'

import { MODAL_ID } from '../../components/modals/modalIds'
import { ThemeType } from '../../hooks/general/useTheme'
import { Collaborator } from '../applicationInternals/collaborators/types'
import { MerchantApplicationResourceIndexPath } from '../applicationResources/types'
import { CriticalFlow, InterfaceState } from './types'

const Prefix = 'INTERFACE'

export const InterfaceActions = {
    START_APPLICATION_REVIEW: createActionCreator(
        `${Prefix}/START_APPLICATION_REVIEW`,
        (resolve) =>
            (applicationId: string, changes: any, collaborators?: Collaborator[], previousSignerName?: string) =>
                resolve({ applicationId, changes, collaborators, previousSignerName })
    ),
    STORE_RECOMMENDED_MERCHANT_COMMENT_FIELD: createActionCreator(
        `${Prefix}/STORE_RECOMMENDED_MERCHANT_COMMENT_FIELD`,
        (resolve) => (applicationId: string, indexPathString: any, subsectionId?: string) =>
            resolve({ applicationId, indexPathString, subsectionId })
    ),
    FINISH_APPLICATION_REVIEW: createActionCreator(
        `${Prefix}/FINISH_APPLICATION_REVIEW`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    SET_APPLICATION_STEP_STATE: createActionCreator(
        `${Prefix}/SET_APPLICATION_STEP_STATE`,
        (resolve) =>
            (applicationId: string, stepId: keyof InterfaceState['applications']['id']['steps'], cancelled: boolean) =>
                resolve({ applicationId, stepId, cancelled })
    ),
    CANCEL_APPLICATION_REVIEW: createActionCreator(
        `${Prefix}/CANCEL_APPLICATION_REVIEW`,
        (resolve) => (applicationId: string) => resolve({ applicationId })
    ),
    EDIT_APPLICATION_FIELD: createActionCreator(
        `${Prefix}/EDIT_APPLICATION_FIELD`,
        (resolve) => (indexPath?: MerchantApplicationResourceIndexPath) => resolve({ indexPath })
    ),
    SWITCH_THEME: createActionCreator(`${Prefix}/SWITCH_THEME`, (resolve) => (theme: ThemeType) => resolve({ theme })),
    TOGGLE_THEME: createActionCreator(`${Prefix}/TOGGLE_THEME`, (resolve) => () => resolve()),
    CLEAR: createActionCreator(`${Prefix}/CLEAR`, (resolve) => () => resolve()),
    PUSH_MODAL: createActionCreator(
        `${Prefix}/PUSH_MODAL`,
        (resolve) => (title: string | null, modalId: MODAL_ID, priority?: number) =>
            resolve({ title, modalId, priority })
    ),
    POP_MODAL: createActionCreator(`${Prefix}/POP_MODAL`, (resolve) => (modalId: MODAL_ID) => resolve({ modalId })),
    UPDATE_NAME: createActionCreator(
        `${Prefix}/UPDATE_TITLE`,
        (resolve) => (modalId: MODAL_ID, title: string) => resolve({ modalId, title })
    ),
    SHOW_KEYTIPS: createActionCreator(`${Prefix}/SHOW_KEYTIPS`, (resolve) => () => resolve({})),
    HIDE_KEYTIPS: createActionCreator(`${Prefix}/HIDE_KEYTIPS`, (resolve) => () => resolve({})),
    SET_MARKDOWN_PREVIEW: createActionCreator(
        `${Prefix}/SET_MARKDOWN_PREVIEW`,
        (resolve) => (inputId: MODAL_ID, markdown: string) => resolve({ markdown, inputId })
    ),
    CLEAR_MARKDOWN_PREVIEW: createActionCreator(
        `${Prefix}/CLEAR_MARKDOWN_PREVIEW`,
        (resolve) => (inputId: MODAL_ID) => resolve({ inputId })
    ),
    SET_SHOW_INTERACTIONS_DATE: createActionCreator(
        `${Prefix}/SET_SHOW_INTERACTIONS_DATE`,
        (resolve) => (show: boolean) => resolve({ show })
    ),
    SET_APPLICATION_CHANGES: createActionCreator(
        `${Prefix}/SET_APPLICATION_CHANGES`,
        (resolve) =>
            (
                applicationId: string,
                changes: {
                    [fieldKey: string]: {
                        from: string
                        to: string
                    }
                }
            ) =>
                resolve({ applicationId, changes })
    ),
    INIT_CRITICAL_FLOW_IF_IT_EXISTS: createActionCreator(
        `${Prefix}/INIT_CRITICAL_FLOW_IF_IT_EXISTS`,
        (resolve) => () => resolve()
    ),
    START_CRITICAL_FLOW: createActionCreator(
        `${Prefix}/START_CRITICAL_FLOW`,
        (resolve) => (flow: CriticalFlow) => resolve({ flow })
    ),
    REPLACE_CRITICAL_FLOW: createActionCreator(
        `${Prefix}/REPLACE_CRITICAL_FLOW`,
        (resolve) => (flow: CriticalFlow, append?: boolean) => resolve({ flow, append })
    ),
    COMPLETE_CRITICAL_FLOW: createActionCreator(
        `${Prefix}/COMPLETE_CRITICAL_FLOW`,
        (resolve) => (watcherId?: string) => resolve({ watcherId })
    )
}
