import { LoadingStatus } from '../../utils'

export type SettlementsReserve = {
    id: string
    type: 'reserve' | 'payout'
    descriptor: string
    currency: string
    settlementAmount?: number
    settlementStart?: string
    settlementEnd?: string
    amount: number
    hasBeenCancelled?: boolean
    paidOn?: string
    stampsLink?: string
}

export type ReserveCancellationStatus = 'in-progress' | 'failed' | 'done' | 'pending'
export type SettlementsReservesSort = {
    type: 'date' | 'percent' | 'amount' | 'settled_amount' | 'type' | 'paidOn'
    dir: 'asc' | 'desc'
}
export type SettlementsReservesState = {
    forMerchantId: {
        [id: string]: {
            reserves: {
                at: {
                    [key: string]: SettlementsReserve
                }
                all: string[]
                loadingStatus: LoadingStatus
            }
            cachedReserves: {
                at: {
                    [key: string]: SettlementsReserve
                }
                all: string[]
                loadingStatus: LoadingStatus
            }
            openSettlementId?: string
            phase: 'selection' | 'processing' | 'final' | 'error' | 'interrupt'
            selection: { [key: string]: true }
            currency?: string
            executing: { [key: string]: ReserveCancellationStatus }
            sort?: SettlementsReservesSort
            shouldGracefullyStop?: boolean
            autoSelect?: {
                for: number
                solution: string[]
            }
        }
    }
}

export const InitialSettlementsReservesState: SettlementsReservesState = {
    forMerchantId: {}
}
