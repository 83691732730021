import { Text } from '@/components/general/text'
import { Flex } from '@/components/layout/flex'
import { Separator } from '@/components/layout/separator'
import { Spacer } from '@/components/layout/spacer'
import React from 'react'
import { useTheme } from 'styled-components'

interface ReportStatusItemProps {
    children: string
    completed: number
    of: number
}

export const ReportStatusItem: React.FC<ReportStatusItemProps> = (props) => {
    const { children, completed, of } = props

    const theme = useTheme()

    const background = theme.name === 'darkTheme' ? 'overlay.background' : 'back.background.strongerI'

    return (
        <Flex column>
            <Spacer height={16} />
            <Text bold color="front.text">
                {children}
            </Text>
            <Spacer height={1} />
            <Text color="front.text.subtlerI">
                {completed} of {of} completed
            </Text>
            <Spacer height={16} />
            <Separator type="solid" background={background} />
        </Flex>
    )
}
