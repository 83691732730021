import { DisputeFiltersParams, DisputesFilters } from '../store/disputes/types'
import { SettlementsFilters, SettlementsFiltersParams } from '../store/settlements/types'
import { TransactionsFilters, TransactionsFiltersParams } from '../store/transactions/types'

type EntitiesWithConvertableLinks = 'disputes' | 'transactions' | 'settlements'
export const convertBackendQueriesToQueryParams = (
    entity: EntitiesWithConvertableLinks,
    link: string
): {
    pathname: string
    search?: string
} => {
    let entityParams:
        | Partial<DisputesFilters>
        | Partial<TransactionsFilters>
        | Partial<SettlementsFilters>
        | undefined = undefined

    switch (entity) {
        case 'disputes':
            entityParams = DisputeFiltersParams
            break
        case 'transactions':
            entityParams = TransactionsFiltersParams
            break
        case 'settlements':
            entityParams = SettlementsFiltersParams
            break
        default:
            break
    }

    if (!link || link.split('?')?.length === 0 || !link.split('?')?.[1])
        return {
            pathname: `/${entity}`
        }
    link = link.split('?')?.[1]

    const objectWithFilters = link
        .replace(/&/g, '","')
        .split('","')
        .reduce((acc, i) => {
            const [key, ...val] = i.split(':')
            const arr = val.join(':')
            if (acc[key]) acc[key] = acc[key] + ',' + arr
            else acc[key] = arr
            return acc
        }, {} as any)

    const rewriteKeyValueForClientside = (key: string, val: string) => {
        if (entity === 'transactions' && key === 'is') {
            return ['type', val]
        }
        if (entity === 'settlements') {
            if (key === 'payout.date' && val.includes('..')) return ['payoutDate', val]
            if (key === 'reserve.date' && val.includes('..')) return ['reserveDate', val]
            if (key === 'settled') return ['settled', val ? 'settled' : 'not-settled']
            if (key === 'currency') return ['currency', val?.toLowerCase()]
        }
        return [key, val]
    }

    const queryObject: any = {}
    Object.keys(objectWithFilters).forEach((backendKey: string) => {
        const [key, value] = rewriteKeyValueForClientside(backendKey, objectWithFilters[backendKey])
        queryObject[key] = value
    })

    const mapDateToBeforeAndAfter = (keyAfter: string, keyBefore: string, date: string, fallbackKey: string) => {
        if (!date) return {}
        if (date.includes('..')) {
            const split = date.split('..')
            if (split[1] == '*')
                return {
                    [keyAfter]: split[0]
                }
            if (split[1] == '*')
                return {
                    [keyBefore]: split[1]
                }
            return {
                [keyAfter]: split[0],
                [keyBefore]: split[1]
            }
        }
        return {
            [fallbackKey]: date
        }
    }

    const convertUnifiedDateToSplitDates = () => {
        // Transactions only
        switch (entity) {
            case 'transactions': {
                const { date, ...queryObjectWithoutDate } = queryObject
                return {
                    ...queryObjectWithoutDate,
                    ...mapDateToBeforeAndAfter('after', 'before', date, 'date')
                }
            }
            case 'disputes': {
                const { opened, ...queryObjectWithoutDate } = queryObject
                return {
                    ...queryObjectWithoutDate,
                    ...mapDateToBeforeAndAfter('after', 'before', opened, 'opened')
                }
            }
            case 'settlements': {
                const { period, payoutDate, reserveDate, ...queryObjectWithoutDate } = queryObject

                return {
                    ...queryObjectWithoutDate,
                    ...mapDateToBeforeAndAfter('payoutAfterDate', 'payoutBeforeDate', payoutDate, 'payoutDate'),
                    ...mapDateToBeforeAndAfter('startDate', 'endDate', period, 'period'),
                    ...mapDateToBeforeAndAfter('reserveAfterDate', 'reserveBeforeDate', reserveDate, 'reserveDate')
                }
            }
            default:
                return {}
        }
    }

    const paramObject = {
        ...convertUnifiedDateToSplitDates()
    }

    const customFilters: any = {}
    const filters: any = {}

    Object.keys(paramObject).map((k) => {
        if ((entityParams as any)[`${entity}_` + k] !== undefined) {
            filters[`${entity}_` + k] = paramObject[k]
        } else {
            customFilters[k] = paramObject[k]
        }
    })

    const filtersQueryString = Object.keys(filters)
        .map((key) => {
            const values = filters?.[key]?.split(',')
            if (values?.length > 1) return `${key}=${values.join(`&${key}=`)}`
            if (values?.length == 1) {
                if (values[0] === '*') return ''
                return `${key}=${values[0]}`
            }
            return ``
        })
        .filter((f) => f)
        .join('&')

    const customFiltersQueryString =
        Object.keys(customFilters).length > 0
            ? `${entity}_custom_filters=${Object.keys(customFilters)
                  .map((key) => `${key}:${customFilters[key]}`)
                  .join(' ')}`
            : ''

    return {
        pathname: `/${entity}`,
        search: `${filtersQueryString ? `${filtersQueryString}` : ''}${
            filtersQueryString.length && customFiltersQueryString.length
                ? `&${customFiltersQueryString}`
                : customFiltersQueryString
        }`
    }
}
