import React from 'react'
import { Styles, styles } from './styles'
import { cn } from '../utils'
import { Slot } from '@radix-ui/react-slot'

interface FlexProps extends Styles {
    children: React.ReactNode
    asChild?: boolean
}

export const Flex: React.FC<FlexProps> = (props) => {
    const { children, asChild, display, direction, justify, align, wrap, flex, grow, shrink, basis, gap } = props

    const classnames = cn(styles({ display, direction, justify, align, wrap, flex, grow, shrink, basis, gap }))
    const Component = asChild ? Slot : 'div'

    return <Component className={classnames}>{children}</Component>
}
