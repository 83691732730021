import { FC } from 'react'
import { CardInset } from '../../../components/cards/cardInset'
import { ContractCard } from '../../../components/cards/contractCard'
import { List } from '../../../components/layout/list'
import { FormFieldWithFormattedNumber } from '../../../utils'
import { MerchantAccountContractsProps } from './Accounts.ID.ContractProps'
import { useContractSectionHelper } from './useContractSectionHelper'
import { ContractValidationRules } from './utils'

export const MerchantAccountSectionExchangeFees: FC<MerchantAccountContractsProps> = ({
    contract,
    formRef,
    formErrors,
    draftOrTemplate,
    isDisabled
}) => {
    const { getValue, isEditable } = useContractSectionHelper(contract, draftOrTemplate)

    return (
        <ContractCard title="Exchange fees" showEditable={isEditable}>
            <CardInset>
                <List
                    background="front.background"
                    items={{
                        'Exchange fee': {
                            key: 'exchange-fee',
                            type: 'input',
                            initialValue: getValue('charges.exchangeFee'),
                            isDisabled: isDisabled || !isEditable,
                            rightAlign: true,
                            placeholder: '-',
                            suffix: '%',
                            tabIndex: 200,
                            validation: formErrors[`charges.exchangeFee`],
                            ref: (ref) =>
                                formRef(
                                    ref,
                                    `charges.exchangeFee`,
                                    ContractValidationRules.limitedPercentage,
                                    FormFieldWithFormattedNumber
                                ),
                            overBackground: 'front.background'
                        }
                    }}
                    alignRight
                    switchToRowsAt={10000}
                    cellHorizontalTemplate="min-content auto"
                />
            </CardInset>
        </ContractCard>
    )
}
