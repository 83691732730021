import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva(
    [
        'flex',
        'w-full',
        'text-md',
        'resize-none',
        'disabled:cursor-not-allowed',
        'disabled:opacity-50',
        'dark:bg-gray-900',
        'dark:caret-white',
        'dark:text-neutral-200'
    ],
    {
        variants: {
            variant: {
                default: 'dark:bg-gray-900 dark:caret-white dark:text-neutral-200',
                surface:
                    'shadow-sm focus:!shadow-sm focus:border-primary-600 dark:focus:border-primary-600 py-3.5 px-4 rounded-xl bg-transparent border border-neutral-200 dark:border-neutral-700 placeholder:text-neutral-500 dark:caret-white dark:text-neutral-200'
            }
        }
    }
)
