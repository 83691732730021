import { createActionCreator } from 'deox'
import { Voucher } from './types'

const Prefix = 'VOUCHERS'

export const VouchersActions = {
    LOAD: createActionCreator(`${Prefix}/LOAD`, (resolve) => () => resolve()),
    STORE: createActionCreator(`${Prefix}/STORE`, (resolve) => (vouchers: Voucher[]) => resolve({ vouchers })),
    APPEND_NEW: createActionCreator(`${Prefix}/APPEND_NEW`, (resolve) => (voucher: Voucher) => resolve({ voucher })),
    NEW: createActionCreator(
        `${Prefix}/NEW`,
        (resolve) => (
            watcherId: string,
            pspId?: string,
            referralId?: string,
            instantFlow?: boolean,
            templateId?: string
        ) => resolve({ watcherId, pspId, referralId, instantFlow, templateId })
    )
}
