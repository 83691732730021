import { createActionCreator } from 'deox'
import { Pagination } from '../../utils'
import { WatcherID } from '../watcher/types'
import { Export, ExportFileFormats, ExportsFilters } from './types'

const Prefix = 'INTERFACE'

export const ExportsActions = {
    FETCH_EXPORTS: createActionCreator(
        `${Prefix}/FETCH_EXPORTS`,
        (resolve) => (filters: ExportsFilters, triggeredExport?: string) => resolve({ filters, triggeredExport })
    ),
    STORE_EXPORTS: createActionCreator(
        `${Prefix}/STORE_EXPORTS`,
        (resolve) => (exports: Export[], pagination: Pagination) => resolve({ exports, pagination })
    ),
    STORE_EXPORT: createActionCreator(`${Prefix}/STORE_EXPORT`, (resolve) => (e: Export) => resolve({ e })),
    DOWNLOAD: createActionCreator(`${Prefix}/DOWNLOAD`, (resolve) => (watcherId: WatcherID, e: Export) =>
        resolve({ e, watcherId })
    ),
    TRIGGER_EXPORT: createActionCreator(
        `${Prefix}/TRIGGER_EXPORT`,
        (resolve) => (resourceLink: string, format: ExportFileFormats) => resolve({ resourceLink, format })
    )
}
