import React, { ReactElement, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { DisputesFilters } from '../../store/disputes/types'
import { RootState } from '@/store'
import { SettlementsFilters } from '../../store/settlements/types'
import { TasksFilters } from '../../store/tasks/types'
import { TransactionsFilters } from '../../store/transactions/types'
import { TextInputAutocomplete } from '../forms/textInputAutocomplete'
import { FiltersController } from '../../hooks/pages/useListPage'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import { SelectedListForListPageFilters } from './selectedListForListPageFilters'
import { FilterPageHolder } from './listPageFilterHolder'

export function ListPageFilterMerchant<
    T extends DisputesFilters | TasksFilters | TransactionsFilters | SettlementsFilters
>({
    filters,
    fieldKey,
    secondFilterKey,
    isDark
}: {
    filters: FiltersController<T>
    fieldKey: keyof FiltersController<T>['get']
    secondFilterKey?: keyof FiltersController<T>['get']
    isDark: boolean
}): ReactElement {
    const [ref, setRef] = useRefTaker()
    const results = useSelector((state: RootState) => state.autocomplete.merchants)

    const onSelect = useCallback(
        (item: string) => {
            filters.set((prevState: any) => ({
                ...prevState,
                [fieldKey]: prevState[fieldKey] === item ? undefined : item,
                ...(secondFilterKey ? { [secondFilterKey]: 'account' } : {})
            }))
        },
        [fieldKey, filters, secondFilterKey]
    )

    const handleBlur = useCallback(() => {
        ref.value = ''
    }, [ref])

    const placeholder = useMemo(() => {
        if (!filters.get[fieldKey]) return '-'
        return 'Filter by another MID'
    }, [fieldKey, filters.get])

    const textForItem = useCallback(
        (item) => {
            if (!item) return undefined
            if ((fieldKey as string).includes('mid'))
                return `${results.at?.[results.atMid?.[item]]?.name} · ${results.at?.[results.atMid?.[item]]
                    ?.merchantId}`
            return `${results.at?.[item]?.name} · ${results.at?.[item]?.merchantId}`
        },
        [fieldKey, results.at, results.atMid]
    )

    return (
        <FilterPageHolder isDark={isDark}>
            <TextInputAutocomplete
                ref={setRef}
                cy="merchant-filter"
                placeholder={placeholder}
                onSelect={onSelect}
                selected={filters.get[fieldKey]}
                dropdownId="DisputesList.MIDFilter"
                forEntity={(fieldKey as string).includes('mid') ? 'merchantsByMID' : 'merchants'}
                overBackground={isDark ? 'floating.background' : 'side.background'}
                isSeamless
                onBlur={handleBlur}
                isHigher={isDark}
            />
            <SelectedListForListPageFilters<T>
                filterKey={fieldKey}
                isDark={isDark}
                secondFilterKey={secondFilterKey}
                noHolderEmphasize={isDark}
                filters={filters}
                textForItem={textForItem}
            />
        </FilterPageHolder>
    )
}
