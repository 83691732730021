import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { WatcherButton } from '../../components/buttons/watcherButton'
import { Text } from '../../components/general/text'
import { TyposHighlighter } from '../../components/general/typosHighlighter'
import { Flex } from '../../components/layout/flex'
import { Spacer } from '../../components/layout/spacer'
import { ApplicationDataProvidersActions } from '../../store/applicationDataProviders/actions'
import { DataProviderCompanyInfoFieldConflicts } from '../../store/applicationDataProviders/types'
import { ApplicationResourceActions } from '../../store/applicationResources/actions'
import { uppercaseFirstLetter } from '../../utils'

export const SplitRepresenter: React.FC<{
    merchantId: string
    showMuted?: boolean
    conflicts: DataProviderCompanyInfoFieldConflicts
}> = ({ conflicts, showMuted, merchantId }) => {
    const dispatch = useDispatch()
    if (!conflicts || !Object.keys(conflicts)?.length) return null

    return (
        <GridHolder>
            <GridBackgroundCards>
                <span />
                <GridBackgroundCard />
                <GridBackgroundCard />
                <FinalSpacerColumn />
            </GridBackgroundCards>
            <Grid>
                <span />
                <SplitRepresenterColumnHeader>Application</SplitRepresenterColumnHeader>
                <SplitRepresenterColumnHeader>Data Provider</SplitRepresenterColumnHeader>
                <FinalSpacerColumn />
                {Object.keys(conflicts.forField).map((k: any) => {
                    const fieldConflicts = conflicts.forField[k]
                    return fieldConflicts.map((field) => {
                        if (field.isMuted && !showMuted) return null
                        return (
                            <>
                                <OutsideValue>{uppercaseFirstLetter(k)}</OutsideValue>
                                <CardValue>
                                    <span className="value">
                                        {field.type == 'typo' ? (
                                            <TyposHighlighter
                                                highlights={field.highlights}
                                                fallbackText={field.application}
                                            />
                                        ) : (
                                            field.application
                                        )}
                                    </span>
                                </CardValue>
                                <CardValue isMuted={field.isMuted} data-cy={`provider-${k}`}>
                                    <span className="value">
                                        {field.type == 'typo' ? (
                                            <TyposHighlighter
                                                highlights={field.highlights}
                                                fallbackText={field.dataProvider}
                                                isSourceOfTruth
                                            />
                                        ) : (
                                            field.dataProvider
                                        )}
                                    </span>
                                    <Flex justify="flex-end" grow align="center">
                                        {field.isMuted ? (
                                            <WatcherButton
                                                onClick={(e, generatedId) => {
                                                    // eslint-disable-next-line max-len
                                                    dispatch(
                                                        ApplicationDataProvidersActions.UNIGNORE_CHANGES(
                                                            merchantId,
                                                            generatedId,
                                                            {
                                                                [k]: [field.dataProvider]
                                                            }
                                                        )
                                                    )
                                                }}
                                            >
                                                Unmute
                                            </WatcherButton>
                                        ) : (
                                            <>
                                                <WatcherButton
                                                    onClick={(e, generatedId) => {
                                                        dispatch(
                                                            ApplicationResourceActions.MAKE_EDITS(
                                                                generatedId,
                                                                merchantId,
                                                                [
                                                                    {
                                                                        key: `company.0.${k}`,
                                                                        to:
                                                                            typeof field.dataProvider === 'string'
                                                                                ? field.dataProvider
                                                                                : '-'
                                                                    }
                                                                ],
                                                                undefined
                                                            )
                                                        )
                                                    }}
                                                >
                                                    Apply
                                                </WatcherButton>
                                                {k !== 'registrationNumber' && k !== 'registrationNumbers' ? (
                                                    <>
                                                        <Spacer width={10} />
                                                        <WatcherButton
                                                            onClick={(e, generatedId) => {
                                                                dispatch(
                                                                    ApplicationDataProvidersActions.IGNORE_CHANGES(
                                                                        merchantId,
                                                                        generatedId,
                                                                        {
                                                                            [k]: [field.dataProvider]
                                                                        }
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            Mute
                                                        </WatcherButton>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Spacer width={10} />
                                                        <Text color="front.text.subtlerI" oneLine>
                                                            Unmuteable
                                                        </Text>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Flex>
                                </CardValue>
                                <FinalSpacerColumn />
                            </>
                        )
                    })
                })}
            </Grid>
        </GridHolder>
    )
}
const FinalSpacerColumn = styled.div``

const SplitRepresenterColumnHeader = styled.div`
    font-weight: 500;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme['front.border']};
    padding-bottom: 10px;
    margin-top: -5px;
`

const ValuesCommonsCSS = css`
    padding: 8px 0;

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
        border-bottom: none;
    }
`
const OutsideValue = styled.div<{ isMuted?: boolean }>`
    border-bottom: 1px solid ${(p) => p.theme['overlay.background.strongerI']};
    text-transform: capitalize;
    color: ${(p) => p.theme['overlay.text.subtlerI']};
    ${ValuesCommonsCSS};
    height: 100%;
    box-sizing: border-box;
    ${(p) =>
        p.isMuted &&
        css`
            opacity: 0.6;
        `}
`
const CardValue = styled.div<{ isMuted?: boolean }>`
    border-bottom: 1px solid ${(p) => p.theme['front.border']};
    ${ValuesCommonsCSS};
    flex-grow: 1;
    min-height: 0;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    ${(p) =>
        p.isMuted &&
        css`
            .value {
                color: ${p.theme['front.text.subtlerII']};
                text-decoration: line-through;
            }
        `}
`

const GridCommonStyles = css`
    display: grid;
    grid-template-columns: 160px 300px 300px 0px;
    grid-column-gap: 70px;
`
const GridBackgroundCard = styled.div`
    background-color: ${(p) => p.theme['front.background']};
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    height: 100%;
    padding: 20px 25px;
    border-radius: 10px;
    margin: -20px -25px;
`
const GridBackgroundCards = styled.div`
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    ${GridCommonStyles}
`

const GridHolder = styled.div`
    position: relative;
    margin-right: -45px;
`

const Grid = styled.div`
    ${GridCommonStyles}
    align-items: center;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
`
