import styled from 'styled-components'

import { Card } from '../cards/card'
import { CardInset } from '../cards/cardInset'

export const TimelineCard: React.FC<React.ComponentProps<typeof Card>> = (p) => {
    return (
        <CardHolder data-cy="timeline-card">
            {p.children ? (
                <Card {...p}>
                    <CardInset>{p.children}</CardInset>
                </Card>
            ) : (
                <Card {...p} />
            )}
        </CardHolder>
    )
}

const CardHolder = styled.div`
    margin-bottom: 10px;
    @media print {
        margin-bottom: 20px;
    }
`
