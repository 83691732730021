export function formatXml(xmlString: string): string {
    const PADDING = '    ' // indent size
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml')

    // Recursively format the XML nodes
    function formatNode(node: Node, level: number): string {
        let result = ''
        const indent = PADDING.repeat(level)

        if (node.nodeType === Node.ELEMENT_NODE) {
            result += `\n${indent}<${(node as Element).tagName}`

            // Add attributes
            const attrs = (node as Element).attributes
            for (let i = 0; i < attrs.length; i++) {
                const attr = attrs[i]
                result += ` ${attr.name}="${attr.value}"`
            }

            if (node.childNodes.length === 0) {
                result += '/>'
            } else {
                result += '>'
                node.childNodes.forEach((child) => {
                    result += formatNode(child, level + 1)
                })
                result += `\n${indent}</${(node as Element).tagName}>`
            }
        } else if (node.nodeType === Node.TEXT_NODE) {
            const textContent = node.textContent?.trim()
            if (textContent) {
                result += `\n${indent}${textContent}`
            }
        }
        return result
    }

    let formattedXml = ''
    xmlDoc.childNodes.forEach((child) => {
        formattedXml += formatNode(child, 0)
    })

    return formattedXml.trim()
}
