import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { MODAL_ID } from '../../components/modals/modalIds'
import { InterfaceActions } from '../../store/interface/actions'

export const useModalStackSync = (
    title: string | null,
    modalId?: MODAL_ID,
    priority?: number,
    noSyncOnMount?: boolean
) => {
    const dispatch = useDispatch()
    const initialTitle = useRef(title)

    const push = useCallback(() => {
        if (!modalId) return
        dispatch(InterfaceActions.PUSH_MODAL(initialTitle.current, modalId, priority))
    }, [dispatch, modalId, priority])

    const pop = useCallback(() => {
        if (!modalId) return
        dispatch(InterfaceActions.POP_MODAL(modalId))
    }, [dispatch, modalId])

    useEffect(() => {
        if (modalId && title) dispatch(InterfaceActions.UPDATE_NAME(modalId, title))
    }, [title, dispatch, modalId])

    useEffect(() => {
        if (noSyncOnMount !== true) {
            push()
            return () => {
                pop()
            }
        }
    }, [noSyncOnMount, pop, push])

    return [push, pop]
}
