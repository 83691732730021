import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TextInput } from './textInput'
import moment, { Moment } from 'moment'
import { useRefTaker } from '../../hooks/general/useRefTaker'
import styled, { css, keyframes } from 'styled-components'
import { useOnBlurOutside } from '../../hooks/general/useOnBlurOutside'
import { throttle } from 'lodash'
import { SimpleButton } from '../buttons/simpleButton'
import { Icon } from '../icons/icon'
import { ButtonInset } from '../buttons/buttonInset'
import { Text } from '../general/text'
import { Spacer } from '../layout/spacer'
import 'moment/locale/en-gb'
import React from 'react'
import { listTimeZones, findTimeZone, getUTCOffset, getZonedTime, getUnixTime } from 'timezone-support'
import { TextInputSelect } from './textInputSelect'
import { Floater } from '../layout/floater'
import { Color } from '../../styles/colors'
import { useFormField } from '../../hooks/general/useFormField'
import { v4 as uuid } from 'uuid'

const timezoneLabel = (tz: any) => {
    const t = findTimeZone(tz)
    const offset = getUTCOffset(new Date(), t)
    const time = (-1 * offset.offset) / 60
    let suffix = ''
    if (time > 0) suffix = `+${time}`
    if (time < 0) suffix = `${time}`
    return `UTC ${suffix} → ${tz.replace('_', ' ')}`
}

const initDate = (preselectedDate?: Date) => {
    if (preselectedDate) return moment(preselectedDate)
    return moment().set('hours', 0).set('minutes', 0).set('seconds', 0)
}

const timeZones = listTimeZones()
const clientTimeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone
const daysOfTheWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
export const DateSelectorWithRef: React.ForwardRefRenderFunction<
    any,
    {
        hasTimeSupport?: boolean
        overBackground?: Color
        onBlur?: (e: any) => void
        inline?: boolean
        disabled?: boolean
        cy?: string
        preselectedDate?: Date
        isSeamless?: boolean
        unBlankable?: boolean
        refTaker?: (r: any) => void
        onSelect?: (date?: Moment) => void
        enabledDates?: Date[]
    }
> = (
    {
        hasTimeSupport,
        enabledDates,
        inline,
        onBlur,
        isSeamless,
        cy,
        disabled,
        unBlankable,
        preselectedDate,
        refTaker,
        onSelect,
        overBackground = 'front.background'
    },
    ref
) => {
    const { blurBoundaryClassName, isBlurOutsideBounds } = useOnBlurOutside()

    const initialLimitRedirect = useRef(false)
    const [highlight, setHighlight] = useState<Moment>(initDate(preselectedDate))
    const [inputRef, setInputRef] = useRefTaker()
    const [selectedDate, setSelectedDate] = useState<Moment | undefined>(
        preselectedDate ? moment(preselectedDate) : undefined
    )
    const [shownMonth, setShownMonth] = useState<Moment>(initDate(preselectedDate))
    const [showPicker, setShowPicker] = useState(false)
    const [fieldKey, setFieldKey] = useState(uuid())
    const selectedDateValue = useRef<any>(preselectedDate ? moment(preselectedDate) : undefined)
    const handleChange = useCallback((e) => {
        if (moment(e.currentTarget.value).isValid()) setHighlight(moment(e.currentTarget.value))
        else setHighlight(initDate())
    }, [])

    const limits = useMemo(() => {
        if (!enabledDates) return undefined
        const dates = [...enabledDates]
        dates.sort((a, b) => (moment(a).isBefore(moment(b)) ? -1 : 1))
        return {
            start: moment(dates[0]).startOf('month').set({ minute: 0, seconds: 0, hours: 0 }),
            end: moment(dates[dates.length - 1])
                .endOf('month')
                .set({ minute: 0, seconds: 0, hours: 0 })
        }
    }, [enabledDates])

    const buttonLimits = useMemo(() => {
        if (!limits) return undefined
        return {
            left: shownMonth.clone().startOf('month').subtract(1, 'day').isBefore(limits?.start) ? true : false,
            right: shownMonth.clone().endOf('month').add(1, 'day').isAfter(limits?.end) ? true : false
        }
    }, [limits, shownMonth])

    useEffect(() => {
        selectedDateValue.current = selectedDate?.toISOString()
    }, [selectedDate])

    const { refInterceptor, onFieldUpdate, fieldRef } = useFormField(
        useCallback((value, ref) => {
            if (!value) setSelectedDate(undefined)
            else setSelectedDate(moment(value))
        }, []),
        useCallback((ref) => {
            return selectedDateValue.current
        }, []),
        useCallback(
            (r: any) => {
                setInputRef(r)
                if (ref) {
                    if (typeof ref === 'function') ref(r)
                    else ref.current = r
                }
                refTaker?.(r)
            },
            [ref, refTaker, setInputRef]
        ),
        false,
        preselectedDate ? moment(preselectedDate).format() : undefined
    )

    const handleNewDateSelection = useCallback(
        (m?: Moment) => {
            if (m) {
                const newDate = m.clone()
                if (!hasTimeSupport) {
                    newDate.utc(true)
                    newDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    ;(document.activeElement as any).blur?.()
                }

                onFieldUpdate(newDate.format())
                onSelect?.(newDate.clone())
                setSelectedDate(newDate.clone())
                setHighlight(newDate.clone())
            } else {
                onFieldUpdate(undefined)
                onSelect?.(undefined)
                setSelectedDate(undefined)
                setHighlight(moment())
            }
        },
        [onSelect, onFieldUpdate, hasTimeSupport]
    )

    useEffect(() => {
        if (!preselectedDate) {
            setSelectedDate(undefined)
            return
        }
        if (!hasTimeSupport) {
            setSelectedDate((sD: Moment | undefined) => {
                if (!sD) return moment.utc(preselectedDate)
                if (sD.isSame(moment.utc(preselectedDate), 'date')) return sD
                return moment.utc(preselectedDate)
            })
            setHighlight((hD: Moment | undefined) => {
                if (!hD) return moment.utc(preselectedDate)
                if (hD.isSame(moment.utc(preselectedDate), 'date')) return hD
                return moment.utc(preselectedDate)
            })
            return
        }
        const tz = findTimeZone(clientTimeZoneString)
        const pDate = moment.utc(preselectedDate).toDate()
        const newDate = moment(getUnixTime(getZonedTime(pDate, tz)))
        setSelectedDate(newDate)
        setHighlight(newDate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preselectedDate, hasTimeSupport])

    const selectedDateHumanFormat = useCallback(
        (date: Moment | undefined) => {
            if (!date) return ''
            if (hasTimeSupport) {
                if (date?.minutes() !== 0 || date?.hours() !== 0) {
                    if (date?.seconds() !== 0) return date?.format('D MMM YYYY HH:mm:ss')
                    return date?.format('D MMM YYYY HH:mm')
                }
            }
            return date?.format('D MMM YYYY')
        },
        [hasTimeSupport]
    )

    useEffect(() => {
        const newShownMonth = highlight.clone()
        newShownMonth.utc(true)
        newShownMonth.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        setShownMonth(newShownMonth)
    }, [highlight])

    useEffect(() => {
        if (selectedDate) setShownMonth(selectedDate.clone())
        if (limits?.end && !initialLimitRedirect.current) {
            initialLimitRedirect.current = true
            setShownMonth(limits.end)
        }
    }, [limits, selectedDate])

    const keyPress = useCallback(
        throttle(
            (keyCode: any) => {
                switch (keyCode) {
                    case 13:
                        setHighlight((d) => {
                            handleNewDateSelection(d.clone())
                            return d
                        })
                        break
                    // LEFT
                    case 37:
                        setHighlight((d) => d.clone().subtract(1, 'days'))
                        break
                    // UP
                    case 38:
                        setHighlight((d) => d.clone().subtract(7, 'days'))
                        break
                    // RIGHT
                    case 39:
                        setHighlight((d) => d.clone().add(1, 'day'))
                        break
                    // DOWN
                    case 40:
                        setHighlight((d) => d.clone().add(7, 'days'))
                        break
                }
            },
            50,
            {
                leading: true,
                trailing: false
            }
        ) as any,
        [setHighlight, setSelectedDate]
    )

    useEffect(() => {
        if (!inputRef) return
        const onKeyDown = (e: any) => {
            keyPress(e.keyCode)
        }
        inputRef.addEventListener('keydown', onKeyDown)
        return () => {
            inputRef.removeEventListener('keydown', onKeyDown)
        }
    }, [inputRef, keyPress])

    const handleOnClick = useCallback(
        (e) => {
            if (e.target.getAttribute('data-date')) {
                if (e.target.getAttribute('data-disabled')) return
                handleNewDateSelection(moment(e.target.getAttribute('data-date')))
            }
        },
        [handleNewDateSelection]
    )

    const renderCalendar = useMemo(() => {
        // Start week on Monday
        const date = shownMonth.clone().startOf('month').set('minute', 0).set('seconds', 0).set('hours', 0)
        const firstDay = date.clone().startOf('month').set('minute', 0).set('seconds', 0).set('hours', 0)
        const endDay = date.clone().endOf('month').set('minute', 0).set('seconds', 0).set('hours', 0)
        const enabledDatesDictionary: { [key: string]: true } = {}

        enabledDates?.forEach((d) => {
            enabledDatesDictionary[moment(d).format('YYYY-MM-DD')] = true
        })

        const isDisabled = (d: Moment) => enabledDates && !enabledDatesDictionary[d.format('YYYY-MM-DD')]
        const isHighlighted = (date: Moment) => {
            return highlight.clone().isSame(date, 'day')
        }
        const isSelected = (date: Moment) => {
            return selectedDate?.clone().isSame(date, 'day')
        }

        const isInBounds = (date: Moment) => {
            if (!limits) return true
            if (date.isBefore(limits?.start)) return false
            if (date.isAfter(limits?.end)) return false
            return true
        }

        const days: React.ReactNode[] = []
        if (firstDay.isoWeekday() >= 1)
            for (let i = 0; i < firstDay.isoWeekday() - 1; i++) {
                const nD = firstDay.clone()
                const d = nD.subtract(nD.isoWeekday() - i - 1, 'day')
                if (isInBounds(d))
                    days.push(
                        <Day
                            data-cy={`picker-day ${
                                isHighlighted(d) ? 'picker-highlighted' : ''
                            } ${`picker-date-${d.format('YYYY-MM-DD')}`}`}
                            outside
                            key={d.format()}
                            data-date={d.format()}
                            isHighlighted={isHighlighted(d)}
                            isSelected={isSelected(d)}
                            inline={inline}
                            data-disabled={isDisabled(d)}
                            isDisabled={isDisabled(d)}
                            onClick={handleOnClick}
                        >
                            {d.date()}
                        </Day>
                    )
                else days.push(<BlankDay />)
            }
        for (let i = 0; i < date?.daysInMonth(); i++) {
            const nD = firstDay.clone()
            const d = nD.add(i, 'day')
            if (isInBounds(d))
                days.push(
                    <Day
                        data-cy={`picker-day-current-month picker-day ${
                            isHighlighted(d) ? 'picker-highlighted' : ''
                        } ${`picker-date-${d.format('YYYY-MM-DD')}`}`}
                        data-date={d.format()}
                        key={d.format()}
                        isHighlighted={isHighlighted(d)}
                        isSelected={isSelected(d)}
                        inline={inline}
                        {...(isDisabled(d)
                            ? {
                                  'data-disabled': 'true'
                              }
                            : {})}
                        isDisabled={isDisabled(d)}
                        onClick={handleOnClick}
                    >
                        {d.date()}
                    </Day>
                )
            else days.push(<BlankDay />)
        }
        for (let i = 1; i < 7 - endDay.isoWeekday() + 1; i++) {
            const nD = endDay.clone()
            const d = nD.add(i, 'day')
            if (isInBounds(d))
                days.push(
                    <Day
                        data-cy={`picker-day ${isHighlighted(d) ? 'picker-highlighted' : ''} ${`picker-date-${d.format(
                            'YYYY-MM-DD'
                        )}`}`}
                        outside
                        key={d.format()}
                        data-date={d.format()}
                        isHighlighted={isHighlighted(d)}
                        isSelected={isSelected(d)}
                        inline={inline}
                        data-disabled={isDisabled(d)}
                        onClick={handleOnClick}
                        isDisabled={isDisabled(d)}
                    >
                        {d.date()}
                    </Day>
                )
            else days.push(<BlankDay />)
        }
        return days
    }, [highlight, enabledDates, limits, inline, handleOnClick, selectedDate, shownMonth])

    const handleFieldBlur = useCallback(
        (e?: any) => {
            const value = fieldRef?.current.value
            if (!e) {
                setShowPicker(false)
            }
            onBlur?.(e)
            setFieldKey(uuid())
            if (isBlurOutsideBounds(e)) {
                setShowPicker(false)
            }
        },
        [onBlur, fieldRef, setShowPicker, isBlurOutsideBounds]
    )

    const handleNextMonth = useCallback(() => {
        setShownMonth((s: any) => {
            return s.clone().endOf('month').add(1, 'day')
        })
    }, [])

    const handlePrevMonth = useCallback(() => {
        setShownMonth((s: any) => {
            return s.clone().startOf('month').subtract(1, 'day')
        })
    }, [])

    const handleTimeInput = useCallback((e: any, val: any) => {
        if (!val) return true
        if (new RegExp('^[0-9:]+$').test(val)) return true
        return false
    }, [])

    const handleTimeSelection = useCallback(
        (e: any) => {
            if (!selectedDate) return
            const val = e.target.value
            const h = val.split(':')[0]
            const m = val.split(':')[1]
            const s = val.split(':')[2]

            const date = selectedDate.clone()

            if (h || m) {
                if (s) {
                    date.set({ hour: h, minute: m, second: s, millisecond: 0 })
                    handleNewDateSelection(date)
                } else {
                    date.set({ hour: h, minute: m, second: 0, millisecond: 0 })
                    handleNewDateSelection(date)
                }
            }
        },
        [selectedDate, handleNewDateSelection]
    )

    const handleTimeBlur = useCallback(
        (e) => {
            handleTimeSelection(e)
            handleFieldBlur()
        },
        [handleTimeSelection, handleFieldBlur]
    )

    const handleTimeKeypress = useCallback(
        (e, val) => {
            if (e.keyCode === 13) {
                handleTimeSelection(e)
            }
        },
        [handleTimeSelection]
    )

    const handleTimezoneSelection = useCallback(
        (tz) => {
            if (!selectedDate) return
            const t = findTimeZone(tz)
            const utcOffset = getUTCOffset(selectedDate.toDate(), t).offset * -1
            const date = selectedDate.clone()
            date.utcOffset(utcOffset)

            handleNewDateSelection(date)
        },
        [selectedDate, handleNewDateSelection]
    )

    const handleFieldFocus = useCallback(() => {
        setShowPicker(true)
    }, [])

    const isFloaterHigher = useMemo(() => {
        if (overBackground === 'floating.background') return true
        return false
    }, [overBackground])

    const content = useMemo(() => {
        return (
            <Content inline={inline}>
                <Header>
                    {!buttonLimits?.left ? (
                        <SimpleButton
                            onClick={handlePrevMonth}
                            color={inline ? 'front.subtleAccent.text' : 'floating.text'}
                            background={
                                inline ? 'front.subtleAccent.background.strongerI' : 'floating.background.subtlerIII'
                            }
                        >
                            <ButtonInset>
                                <RotateIcon>
                                    <Icon type="arrow" />
                                </RotateIcon>
                            </ButtonInset>
                        </SimpleButton>
                    ) : (
                        <BlankButton />
                    )}
                    <Month>
                        <Text>{shownMonth?.format('MMMM')}</Text>
                        <Spacer width={5} />
                        <Text>{shownMonth?.format('yyyy')}</Text>
                    </Month>
                    {!buttonLimits?.right ? (
                        <SimpleButton
                            onClick={handleNextMonth}
                            color={inline ? 'front.subtleAccent.text' : 'floating.text'}
                            background={
                                inline ? 'front.subtleAccent.background.strongerI' : 'floating.background.subtlerIII'
                            }
                        >
                            <ButtonInset>
                                <Icon type="arrow" />
                            </ButtonInset>
                        </SimpleButton>
                    ) : (
                        <BlankButton />
                    )}
                </Header>
                <Days>
                    {daysOfTheWeek.map((d) => (
                        <DayName key={d}>{d}</DayName>
                    ))}
                    {renderCalendar}
                </Days>
                {selectedDate && hasTimeSupport && (
                    <HourBox>
                        <Label>Time</Label>
                        <TextInput
                            isSeamless
                            onBlur={handleTimeBlur}
                            rightAlign
                            onKeyDown={handleTimeKeypress}
                            onInput={handleTimeInput}
                            forceValue={selectedDate?.format('HH:mm:ss')}
                            placeholder={'-'}
                            overBackground="floating.background"
                        />
                    </HourBox>
                )}
                {selectedDate && hasTimeSupport && (
                    <HourBox>
                        <Label>Timezone</Label>
                        <TextInputSelect
                            dropdownId="-ANY-"
                            placeholder="Select a timezone"
                            overBackground="floating.background"
                            items={timeZones}
                            selected={clientTimeZoneString}
                            isSeamless
                            onSelect={handleTimezoneSelection}
                            rightAlign
                            onBlur={handleFieldBlur}
                            noClear
                            textForItem={timezoneLabel}
                        />
                    </HourBox>
                )}
            </Content>
        )
    }, [
        handleFieldBlur,
        handleNextMonth,
        handlePrevMonth,
        handleTimeBlur,
        handleTimeInput,
        handleTimeKeypress,
        handleTimezoneSelection,
        hasTimeSupport,
        buttonLimits,
        inline,
        renderCalendar,
        selectedDate,
        shownMonth
    ])

    return (
        <Holder className={blurBoundaryClassName} onBlur={handleFieldBlur} tabIndex={-1}>
            {inline ? null : (
                <TextInput
                    cy={`date-selector ${cy}`}
                    overBackground={overBackground}
                    isSeamless={isSeamless}
                    key="textinput"
                    isDisabled={disabled}
                    noFormFieldBehaviour
                    forceValueKey={fieldKey}
                    forceValue={selectedDateHumanFormat(selectedDate)}
                    initialValue={
                        preselectedDate
                            ? selectedDateHumanFormat(preselectedDate ? moment(preselectedDate) : undefined)
                            : undefined
                    }
                    placeholder="-"
                    onChange={handleChange}
                    onFocus={handleFieldFocus}
                    onBlur={handleFieldBlur}
                    refInterceptor={refInterceptor}
                />
            )}
            {inline ? (
                content
            ) : (
                <Floater noFocusLock higher={isFloaterHigher} anchor={inputRef} shouldShow={showPicker}>
                    {content}
                </Floater>
            )}
        </Holder>
    )
}

export const DateSelector = React.forwardRef(DateSelectorWithRef)

const Label = styled.div`
    flex-shrink: 0;
    margin-right: 10px;
`

const HourBox = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
    border-radius: 7px;
    padding: 6px 10px 4px 10px;
    justify-content: space-between;
    border-radius: 7px 7px 0 0;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-bottom: none;
    margin-left: -2px;
    width: calc(100% + 4px);
    box-sizing: border-box;

    &:last-child {
        border-radius: 0 0 7px 7px;
        border: 1px solid rgba(255, 255, 255, 0.05);
    }
`
const Holder = styled.div`
    display: contents;
`

const Header = styled.div`
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    font-weight: 500;
    align-items: stretch;
    box-sizing: border-box;
`

const Year = styled.div`
    font-size: 15px;
    font-weight: 400;
    opacity: 0.8;
    margin-left: 10px;
`

const Days = styled.div`
    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 15px 0 10px 0;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`
const DayName = styled.div`
    width: auto;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 5px;
    opacity: 0.7;
`

const blinkHighlightedDate = keyframes`
    0% {
		background-color: rgba(255,255,255,0);
	}
    30% {
        background-color: rgba(255,255,255,0.1);
    }
    50% {
        background-color: rgba(255,255,255,0.1);
    }
    80% {
		background-color: rgba(255,255,255,0);
	}
    100% {
		background-color: rgba(255,255,255,0);
	}
`

const Day = styled.div<{
    outside?: boolean
    inline?: boolean
    isDisabled?: boolean
    isHighlighted?: boolean
    isSelected?: boolean
}>`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 26px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    &:active {
        background-color: rgba(255, 255, 255, 0.2);
    }
    ${(p) =>
        p.inline
            ? css`
                  &:hover {
                      background-color: rgba(0, 0, 0, 0.05);
                  }

                  &:active {
                      background-color: rgba(0, 0, 0, 0.1);
                  }
              `
            : ''}

    ${(p) =>
        p.isHighlighted &&
        css`
            background-color: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            animation: ${blinkHighlightedDate} 0.75s ease-in-out infinite;
        `}

    ${(p) =>
        p.isSelected &&
        css`
            background-color: ${(p) => p.theme['front.accent.color']} !important;
            border-color: ${(p) => p.theme['front.accent.color']} !important;
            ${p.inline
                ? css`
                      color: ${(p) => p.theme['front.accent.text']} !important;
                  `
                : ''}
            animation: none !important;
        `}

    ${(p) =>
        p.outside &&
        css`
            opacity: 0.5;
        `}


        ${(p) =>
        p.isDisabled &&
        css`
            border-color: transparent;
            cursor: default !important;
            opacity: 0.2;

            &:hover,
            &:active {
                background-color: transparent;
            }
        `}
                ${(p) =>
        p.inline &&
        !p.isDisabled &&
        css`
            /* background-color: ${(p) => p.theme['front.subtleAccent.background.strongerII']}; */
        `}
`

const Content = styled.div<{ inline?: boolean }>`
    padding: 10px 10px 9px 10px;
    min-width: 190px;
    color: ${(p) => (p.inline ? p.theme['front.text'] : p.theme['floating.text'])};
`

const RotateIcon = styled.div`
    position: relative;
    transform: rotateZ(180deg);
`

const Month = styled.div`
    display: flex;
    align-items: baseline;
    align-self: center;
`

const BlankDay = styled(Day)`
    opacity: 0;
`

const BlankButton = styled.div`
    width: 30px;
    height: 26px;
`
