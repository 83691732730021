import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { TextInputSelect } from '../../../components/forms/textInputSelect'
import { MerchantApplicationResourceIndexPath } from '../../../store/applicationResources/types'
import { ConvertIndexPathToIndexPathString } from '../../../store/applicationResources/utils'
import { RootState } from '@/store'
import { MerchantApplicationFieldFormProps } from './Application.FieldFormProps'
import * as yup from 'yup'
import { ValidationStatus } from '../../../hooks/general/useForm'
import { Color } from '../../../styles/colors'

export const MerchantApplicationEditsModalInputGateway: React.FC<{
    preSelected?: string
    applicationId: string
    validation: ValidationStatus
    onSelect?: (item: string) => void
    onBlur: (e: any) => void
    indexPath: MerchantApplicationResourceIndexPath
    overBackground: Color
    formRef: MerchantApplicationFieldFormProps['formRef']
}> = ({ preSelected, applicationId, onSelect, validation, onBlur, indexPath, formRef, overBackground }) => {
    const hasLoaded = useSelector((state: RootState) => {
        return state.applicationResources.data.forApplication?.[applicationId]?.gateway?.loadingStatus === 'done'
    })

    const gateways = useSelector((state: RootState) => {
        return state.applicationResources.data.forApplication?.[applicationId]?.gateway?.fields?.[0]?.selectable
    })

    const { gatewaysList, gatewaysDictionary } = useMemo(() => {
        const dictionary =
            gateways?.reduce((acc: any, item: any) => {
                acc[item.id] = item.name
                return acc
            }, {}) || {}

        return {
            gatewaysList: Object.keys(dictionary),
            gatewaysDictionary: dictionary
        }
    }, [gateways])

    const textForItem = useCallback(
        (item) => {
            return gatewaysDictionary[item]
        },
        [gatewaysDictionary]
    )

    if (!hasLoaded) return null

    return (
        <TextInputSelect
            ref={(ref) => {
                formRef(ref, ConvertIndexPathToIndexPathString(indexPath), yup.string())
            }}
            validation={validation}
            textareaBased
            placeholder="Select a gateway"
            selected={preSelected || ''}
            overBackground={overBackground}
            onBlur={onBlur}
            dropdownId="GenericDropdown"
            textForItem={textForItem}
            isSeamless
            noClear
            lazyLoaded
            items={gatewaysList}
        />
    )
}
