import { DateFormats } from '@/utils/dateUtils'
import { Flex } from '@/components/layout/flex'
import { ISO8601DateTime } from '@/services/common'
import React from 'react'
import { Spacer } from '@/components/layout/spacer'
import { Text } from '@/components/general/text'
import moment from 'moment'
import { AgentBubble } from '@/features/risk-classification'

interface MetadataProps {
    author: string
    createdAt: ISO8601DateTime
}

export const Metadata: React.FC<MetadataProps> = (props) => {
    const { author, createdAt } = props

    return (
        <Flex grow justify="flex-end" align="center">
            <AgentBubble agentSub={author} filled />
            <Spacer width={12} />
            <Text color="front.text.subtlerI">
                {moment(createdAt).format(DateFormats.dayAndTime(moment(createdAt)))}
            </Text>
        </Flex>
    )
}
