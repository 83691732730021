import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Color } from '../../styles/colors'

export const Keyface: React.FC<{
    k: string | 'enter' | 'alt'
    background?: 'overlay.background'
}> = ({ k, background = 'overlay.background' }) => {
    const content = useMemo(() => {
        const fill = 'currentColor'
        if (k === 'enter')
            return (
                <svg width="9" height="6" viewBox="0 0 9 6" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        // eslint-disable-next-line max-len
                        d="M6.82052 1.5H5.81251V0.5H6.82052C7.74807 0.5 8.5 1.25193 8.5 2.17949C8.5 3.10704 7.74807 3.85897 6.82052 3.85897H2.26417L3.09202 4.63527L2.40799 5.36473L0.269043 3.35897L2.40799 1.35322L3.09202 2.08268L2.26417 2.85897H6.82052C7.19579 2.85897 7.50001 2.55476 7.50001 2.17949C7.50001 1.80422 7.19579 1.5 6.82052 1.5Z"
                        fill={fill}
                    />
                </svg>
            )
        if (k === 'alt')
            return (
                <svg width="11" height="5" viewBox="0 0 11 5" fill="none">
                    <path
                        // eslint-disable-next-line max-len
                        d="M2.80334 4.20312H1.39417L1.14807 5H0.0494385L1.61389 0.734375H2.58069L4.15686 5H3.05237L2.80334 4.20312ZM1.64026 3.40918H2.55725L2.09729 1.92969L1.64026 3.40918Z"
                        fill={fill}
                    />
                    <path d="M5.67866 4.20898H7.4687V5H4.65034V0.734375H5.67866V4.20898Z" fill={fill} />
                    <path
                        d="M10.8274 1.52832H9.54713V5H8.51881V1.52832H7.26198V0.734375H10.8274V1.52832Z"
                        fill={fill}
                    />
                </svg>
            )
        return k
    }, [k])

    return <Holder background={background}>{content}</Holder>
}

const Holder = styled.div<{ background?: Color }>`
    width: 17px;
    height: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px 0 4px;
    padding-bottom: 1px;

    background: ${(p) => p.theme['front.background.subtlerII']};
    color: ${(p) => p.theme['front.text']};
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.075);
    border-radius: 4px;
`
