import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { Icon } from '../icons/icon'
import { LinkButton } from '../buttons/linkButton'
import { SimpleButton } from '../buttons/simpleButton'
import { Text } from '../general/text'
import { MODAL_ID } from './modalIds'
import Color from 'color'
import { useHasScrolled } from '../../hooks/interface/useHasScrolled'
import { ButtonInset } from '../buttons/buttonInset'
import { useGoBackHook } from '../../hooks/general/useGoBackHook'

export const ModalHeader: React.FC<{
    backTo?: string
    onBack?: () => void
    title?: string | React.ReactElement
    pageId: MODAL_ID
    children?: any
    backToState?: any
    noStateBasedRedirect?: any
    rightSideMemo?: React.ReactNode
    overlay?: boolean
    keepBackTo?: boolean
}> = ({ title, noStateBasedRedirect, rightSideMemo, pageId, children, onBack, backToState, backTo, keepBackTo }) => {
    const { isMoving, setRef } = useHasScrolled()
    const { canGoBack, goBackConfig } = useGoBackHook(backTo, backToState, false, keepBackTo)

    const backButton = useMemo(() => {
        if (onBack) {
            return (
                <ButtonInset equalPadding padding="larger" inversePadding>
                    <SimpleButton
                        cy="back"
                        onClick={onBack}
                        color="front.text.subtlerI"
                        hotkeys="esc, alt+esc"
                        hotkeysScope={pageId}
                        hotkeysClueDirection="bottom-right"
                    >
                        <ButtonInset equalPadding padding="larger">
                            <Icon type="x" />
                        </ButtonInset>
                    </SimpleButton>
                </ButtonInset>
            )
        }

        if (canGoBack) {
            if (noStateBasedRedirect) return null

            return (
                <ButtonInset equalPadding padding="larger" inversePadding>
                    <LinkButton
                        cy="back"
                        to={{ pathname: goBackConfig?.pathname, search: goBackConfig?.search }}
                        state={goBackConfig?.state}
                        color="front.text.subtlerI"
                        hotkeys="esc, alt+esc"
                        hotkeysScope={pageId}
                        clueDirection="right"
                    >
                        <ButtonInset equalPadding padding="larger">
                            <Icon type="x" />
                        </ButtonInset>
                    </LinkButton>
                </ButtonInset>
            )
        }
        return null
    }, [noStateBasedRedirect, goBackConfig, canGoBack, onBack, pageId])

    return (
        <>
            <Header isMoving={isMoving} ref={setRef} className="PREVENT_MODAL_CLOSE">
                <BackHolder>{backButton}</BackHolder>
                <Content>
                    {title && (
                        <Title>
                            <Text bold>{title}</Text>
                        </Title>
                    )}
                    {children}
                </Content>
                <RightSide>{rightSideMemo}</RightSide>
            </Header>
        </>
    )
}

const Content = styled.div`
    flex-grow: 1;
    justify-content: flex-end;
`

const BackHolder = styled.div`
    position: relative;
    z-index: 1;
`

const RightSide = styled.div`
    position: relative;
    flex-grow: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const Title = styled.div`
    display: flex;
    width: 100%;
    height: 65px;
    z-index: 0;
    top: 0;
    left: 0;
    flex-shrink: 0;
    position: absolute;
    color: ${(p) => p.theme['overlay.text']};
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
`

const Header = styled.div<{ isMoving?: boolean }>`
    display: flex;
    padding: 15px 0px;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 65px;
    padding: 0 20px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000;
    border-bottom: 1px solid transparent;
    background: transparent;
    flex-shrink: 0;

    @media print {
        display: none;
    }

    ${(p) =>
        p.isMoving &&
        css`
            background: ${(p) => Color(p.theme['overlay.background.subtlerI']).string()};
        `}
`
