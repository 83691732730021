import { all, put } from 'typed-redux-saga'
import { GET } from '../../generators/networking'
import { GlobalActions } from './actions'

export const GlobalSagas = {
    *FETCH_LINKS() {
        const requests = [
            GET({
                url: import.meta.env.VITE_CUTTER_ROOT,
                include: ['*'],
                skipRootLinksReadyCheck: true,
                errorText: 'Failed to load root cutter links.'
            }),
            GET({
                url: import.meta.env.VITE_API_ROOT,
                include: ['*'],
                skipRootLinksReadyCheck: true,
                errorText: 'Failed to load root MAPI links.'
            })
        ]

        const responses = yield* all(requests)

        yield put(
            GlobalActions.STORE_LINKS({
                cutter: responses[0],
                mapi: responses[1]
            })
        )
    }
}
