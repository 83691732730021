import { isArray, isObject } from 'lodash'

export function extractVersionsFromCutterRequestRecursively(
    body: any,
    acc: any = {},
    previousKey?: string
) {
    let newAcc: any = acc
    if (isObject(body)) {
        Object.keys(body).forEach((key: string) => {
            if (key === 'versions') {
                (body as any)[key].forEach((version: any) => {
                    newAcc[version.id] = {
                        ...version,
                        resource: previousKey || 'application'
                    }
                })
            } else if (
                isArray((body as any)[key]) ||
                isObject((body as any)[key])
            ) {
                newAcc = {
                    ...newAcc,
                    ...extractVersionsFromCutterRequestRecursively(
                        (body as any)[key],
                        newAcc,
                        `${previousKey || 'application'}.${key}`
                    )
                }
            }
        })
    }
    return newAcc
}
