import axios from 'axios'
import { snakeCase } from 'lodash'
import { END, EventChannel, eventChannel } from 'redux-saga'
import { delay } from 'redux-saga/effects'
import { all, put, select, take, takeEvery } from 'typed-redux-saga'
import { v4 as uuid } from 'uuid'

import { DELETE, GET, PATCH, POST } from '../../generators/networking'
import { RootState } from '@/store'
import { ToastsDispatchPush } from '../toasts/actions'
import { Optional } from '../utils'
import { Text } from '@/components/general/text'
import {
    WATCHER_FAIL,
    WATCHER_SUCCESS,
    WatcherDispatchFail,
    WatcherDispatchStart,
    WatcherDispatchSuccess
} from '../watcher/actions'
import {
    FileActionDelete,
    FileActionLookoutFetch,
    FileActionLookoutRefetch,
    FileActionDispatchStore,
    FileActionDispatchStoreDownloadLink,
    FileActionDispatchStoreProgress,
    FileActionDispatchUnstore,
    FileActionDispatchAddMetadata,
    FileActionFetch,
    FileActionGenerateDownloadLink,
    FileActionReplace,
    FilesActionDispatchStore,
    FilesActionDispatchUpload,
    FilesActionUpload,
    SagasForFiles
} from './actions'
import { convertSubmittedNormalFileToSubmittedPrefixedBackendFile } from './reducers'
import { ConvertedSubmitFileData, CutterSubmitFileData, File, SubmitFileData } from './types'

function convertRemoteFileToLocalFile(r: any) {
    if (r.selfLink) r.id = r.selfLink.substr(r.selfLink.lastIndexOf('/') + 1)
    if (r.dataLink) {
        r.uploadLink = r.dataLink[0].href
        r.downloadLink = r.dataLink[1].href
    }
    return {
        ...r,
        API: r.documentFileName ? 'cutter' : 'mapi'
    }
}

export class FilesSagas implements SagasForFiles {
    *fetchFile(action: FileActionFetch) {
        if (action.watcherId) {
            yield put(WatcherDispatchStart([action.watcherId]))
        }

        const file: Optional<File> = yield GET({
            url: `${import.meta.env.VITE_API_ROOT}/files/${action.id}`,
            include: ['self', 'download', 'data'],
            errorText: 'Failed to replace the file.'
        })

        if (!file) {
            if (action.watcherId) yield put(WatcherDispatchFail([action.watcherId], undefined, undefined))
            return
        }

        yield put(FilesActionDispatchStore([file]))

        if (action.watcherId) {
            yield put(
                WatcherDispatchSuccess([action.watcherId], undefined, {
                    file,
                    extraContext: action.extraContext
                })
            )
        }
    }
    *uploadFiles(action: FilesActionUpload) {
        if (action.watcherId) yield put(WatcherDispatchStart([action.watcherId]))
        let files: any = []
        const filesData = action.fileData.map((file: CutterSubmitFileData | SubmitFileData) => {
            const { wholeFile, ...newFile } = file
            return newFile
        })
        if (action.fileToReplace) {
            files = [
                {
                    ...action.fileToReplace,
                    API: action.withDocumentPrefix ? 'cutter' : 'mapi'
                }
            ]
        } else {
            const PostRequests = filesData.map((fileData: any) => {
                const { api, ...file } = Object.keys(fileData).reduce((acc, k) => {
                    return { ...acc, [snakeCase(k)]: fileData[k] }
                }, {} as any)

                return POST({
                    url: action.source,
                    errorText: 'Failed to start uploading the file',
                    include: ['self', 'download', 'data', 'comments'],
                    body: action.withDocumentPrefix
                        ? convertSubmittedNormalFileToSubmittedPrefixedBackendFile(file as ConvertedSubmitFileData)
                        : file
                })
            })

            files = ((yield all(PostRequests)) as any[]).map((r: any) => {
                return convertRemoteFileToLocalFile(r.cleanedResponse)
            })

            yield put(
                FilesActionDispatchStore(
                    files.map((f: any) => {
                        if (!f.uploadLink && f.uploadUrl) f.uploadLink = f.uploadUrl
                        return { ...f, progress: 0 }
                    })
                )
            )
            yield put(action.linkFiles(files))
        }
        for (let i = 0; i < files.length; i++) {
            yield put(FileActionDispatchStoreProgress(files[i].id, 0))
        }

        yield delay(750)

        const chan: EventChannel<any> = yield eventChannel((emitter: any): any => {
            const promises: any[] = []

            for (let i = 0; i < files.length; i++) {
                const { uploadLink } = files[i]
                const fileToUpload = action.fileData[i].wholeFile
                const file = files[i]

                promises.push(
                    axios({
                        method: 'put',
                        url: uploadLink,
                        data: fileToUpload,
                        onUploadProgress: ({ total, loaded }) => {
                            const progress = total && total > 0 ? Math.round((loaded * 100) / total) : 0
                            emitter({
                                file: { ...file },
                                forCutter: file.API === 'cutter',
                                progress,
                                shouldStallProgress: action.shouldStallProgress
                            })
                        },
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': file.API === 'cutter' ? file.documentContentType : file?.contentType
                        },
                        transformRequest: (data: any) => {
                            return data
                        }
                    })
                        .then(() => {
                            emitter({
                                success: true,
                                file: { ...file },
                                watcherId: action.watcherId,
                                forCutter: file.API === 'cutter'
                            })
                        })
                        .catch((error) => {
                            emitter({
                                err: error,
                                watcherId: action.watcherId,
                                file: undefined,
                                forCutter: file.API === 'cutter'
                            })
                        })
                )
            }

            Promise.all(promises)
                .then((responses) => {
                    emitter(END)
                })
                .catch((err) => {
                    emitter(END)
                })

            return () => {
                emitter(END)
            }
        })

        yield takeEvery(chan, onUploadProgress)
    }

    *deleteFile(action: FileActionDelete) {
        if (!action.file.selfLink) {
            console.error('File has no self link. Cannot delete.')
            return
        }

        const { success } = action.byPost
            ? yield POST({
                  url: action.file.selfLink,
                  watcher: action.watcherId,
                  body: { deleted: true },
                  successText: 'File deleted.',
                  successCode: 200,
                  errorText: 'Failed to delete file.'
              })
            : yield DELETE({
                  url: action.file.selfLink,
                  watcher: action.watcherId,
                  successText: 'File deleted.',
                  successCode: 204,
                  errorText: 'Failed to delete file.'
              })

        if (success) {
            if(action.resourceKey && ["people", "websites", "company"].includes(action.resourceKey)) {
                yield put(FileActionDispatchAddMetadata(action.file))
            } else {
                yield put(FileActionDispatchUnstore(action.file))
                yield put(action.unlinkFiles([action.file]))
            }
        }
    }

    *fetchLookoutFile(action: FileActionLookoutFetch) {
        yield POST({
            url: `${import.meta.env.VITE_API_ROOT}/applications/${action.applicationId}/refetch/${action.reportType}`,
            successCode: 204,
            errorText: 'Failed to fetch report.'
        })
        const message = (
            <>
                <Text bold>File was requested</Text>
                <Text>Please refresh the page in a few seconds. The file will appear as a new file in the list.</Text>
            </>
        )
        yield put(ToastsDispatchPush(message, 'success', undefined, 'infinite'))
    }

    *refetchLookoutFile(action: FileActionLookoutRefetch) {
        yield POST({
            url: action.file.refetchLink,
            successCode: 204,
            errorText: 'Failed to refetch report.'
        })
        const message = (
            <>
                <Text bold>Refetch of file was requested</Text>
                <Text>Please refresh the page in a few seconds. The file will appear as a new file in the list.</Text>
            </>
        )
        yield put(ToastsDispatchPush(message, 'success', undefined, 'infinite'))
    }

    *replaceFile(action: FileActionReplace) {
        // We update the previous file in order to get a recent uploadLink (as uploadLinks expire)
        const fileWithUpdatedUploadLink: File = yield GET({
            url: action.replacingFile.selfLink,
            include: ['self', 'download', 'data'],
            errorText: 'Failed to replace the file.'
        })

        yield put(FileActionDispatchStore(fileWithUpdatedUploadLink))

        const updatedFile = yield* select((state: RootState) => state.files.at[action.replacingFile.id])

        if (!updatedFile?.uploadLink) return yield* put(ToastsDispatchPush("Couldn't find a file to replace", 'error'))

        const uploadWatcher = uuid()
        yield put(
            FilesActionDispatchUpload(
                action.uploadTo,
                action.newFileData,
                action.linkFiles,
                action.withDocumentPrefix,
                updatedFile,
                uploadWatcher,
                true
            )
        )

        let watcherAction: any
        do {
            watcherAction = yield* take([WATCHER_SUCCESS, WATCHER_FAIL])
        } while (!watcherAction?.watchers?.includes?.(uploadWatcher))
        // we stall the file loading till the following POST response (that changes the file details), goes through

        if (watcherAction.type == WATCHER_SUCCESS) {
            yield PATCH({
                url: updatedFile.selfLink,
                body: action.withDocumentPrefix
                    ? {
                          documentFileName: (action.newFileData[0] as CutterSubmitFileData).document_file_name,
                          documentFileSize: (action.newFileData[0] as CutterSubmitFileData).document_file_size,
                          documentContentType: (action.newFileData[0] as CutterSubmitFileData).document_content_type
                      }
                    : {
                          name: (action.newFileData[0] as SubmitFileData).name,
                          content_type: (action.newFileData[0] as SubmitFileData).contentType,
                          size: (action.newFileData[0] as SubmitFileData).size
                      },
                include: ['self', 'download', 'data'],
                errorText: 'Failed to replace the file.',
                successText: 'File replace successfully.',
                onSuccessDispatch: (r) => FileActionDispatchStore(convertRemoteFileToLocalFile(r))
            })
        } else {
            return yield* put(ToastsDispatchPush('Failed to upload the replacement file.', 'error'))
        }
        yield put(FileActionDispatchStoreProgress(updatedFile.id, 100))
    }

    *generateDownloadLink(action: FileActionGenerateDownloadLink) {
        if (!action.file.downloadLink) return

        yield GET({
            url: action.file.downloadLink,
            errorText: 'Failed to generate file download link.',
            onSuccessDispatch: (r) =>
                FileActionDispatchStoreDownloadLink(action.file.id, r.downloadUrl ? r.downloadUrl : r.download)
        })
    }
}

function* onUploadProgress({ err, success, progress, file, forCutter, watcherId, shouldStallProgress }: any) {
    if (success) {
        if (forCutter)
            yield POST({
                watcher: watcherId,
                url: file.selfLink,
                successCode: 200,
                body: { deleted: false },
                successText: 'File uploaded successfully',
                errorText: 'File failed to upload'
            })

        if (watcherId) yield put(WatcherDispatchSuccess([watcherId], 'File uploaded successfully'))
        else yield put(ToastsDispatchPush('File uploaded successfully', 'success'))
    } else if (err) {
        if (watcherId) yield put(WatcherDispatchFail([watcherId]))
        yield put(ToastsDispatchPush(`Uploading failed while uploading: ${err}`, 'error'))
        throw err
    } else if (progress != 100) yield put(FileActionDispatchStoreProgress(file.id, progress))
    else if (!shouldStallProgress) yield put(FileActionDispatchStoreProgress(file.id, 100))
}
