import { Checkbox, Grid, Label } from '@/design-system'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface FormCheckboxProps {
    testid?: string
    name: string
    label: string
    defaultChecked?: boolean
}

export const FormCheckbox: React.FC<FormCheckboxProps> = (props) => {
    const { testid, name, label } = props

    const { register, getValues, setValue } = useFormContext()

    const handleOnEnabledChange = (checked: boolean) => {
        setValue(name, checked)
    }

    return (
        <>
            <Grid.Item align="center">
                <Label htmlFor={name}>{label}</Label>
            </Grid.Item>
            <Grid.Item columnSpan="2">
                <Checkbox
                    testid={testid}
                    defaultChecked={getValues(name)}
                    id={name}
                    name={register(name).name}
                    ref={register(name).ref}
                    onCheckedChange={handleOnEnabledChange}
                />
            </Grid.Item>
        </>
    )
}
