import React, { useCallback, useEffect } from 'react'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { ModalPage } from '../../../components/layout/modalPage'
import { ModalPageInset } from '../../../components/layout/modalPageInset'
import { Spacer } from '../../../components/layout/spacer'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { useNamedWatcher } from '../../../hooks/general/useWatcher'
import * as yup from 'yup'
import { List } from '../../../components/layout/list'
import { ButtonInset } from '../../../components/buttons/buttonInset'
import { WatcherButton } from '../../../components/buttons/watcherButton'
import { useForm } from '../../../hooks/general/useForm'
import { CardSection } from '../../../components/cards/cardSection'
import { useDispatch, useSelector } from 'react-redux'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { RootState } from '@/store'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

type Params = { id: string; accountId: string }

export const MerchantAccountAddANewMember: React.FC = () => {
    const { id, accountId } = useParams() as Params
    const [watcher, watcherId] = useNamedWatcher(`${accountId}.AddNewMember`)
    const { _, errors, formRef, submitHandler } = useForm(watcher, undefined, undefined, undefined, true)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])
    const location = useLocation()
    const title = useMerchantName(id, 'New member')

    useEffect(() => {
        if (!accountId) return
        if (account?.loadingStatus === 'started' || account?.loadingStatus === 'done') return
        if (account?.draft?.loadingStatus !== 'started' && account?.draft?.loadingStatus !== 'done')
            dispatch(MerchantAccountsActions.FETCH_ACCOUNT(accountId))
    }, [account, accountId, dispatch])

    const onSubmit = useCallback(
        (form) => {
            dispatch(
                MerchantAccountsActions.INVITE_MEMBER(
                    watcherId,
                    accountId,
                    form.data.email,
                    form.data.message,
                    account.membersLink
                )
            )
        },
        [dispatch, watcherId, account, accountId]
    )

    useEffect(() => {
        if (watcher === 'success') {
            navigate(
                {
                    pathname: `/merchant/${id}/accounts/${accountId || ''}`,
                    search: location.search
                },
                { replace: true }
            )
        }
    }, [id, accountId, navigate, location, watcher])

    return (
        <ModalPage
            title={title}
            pageId="Merchant.AccountsPage.Account.NewMember"
            backTo={`/merchant/${id}/accounts/${accountId || ''}`}
            isLoading={account?.loadingStatus !== 'done'}
        >
            <ModalHeader
                title="New merchant account member"
                pageId="Merchant.ApplicationPage.FieldEdits"
                backTo={`/merchant/${id}/accounts/${accountId || ''}`}
            />
            <ModalPageInset>
                <Flex justify="center" align="center" grow column>
                    <Card higher title="Invite a member">
                        <CardInset>
                            <Spacer height={15} />
                            <List
                                background="front.background"
                                items={{
                                    Email: {
                                        type: 'input',
                                        placeholder: '-',
                                        overBackground: 'front.background',
                                        ref: (ref) => formRef(ref, 'email', yup.string().email().required())
                                    },
                                    Message: {
                                        type: 'custom',
                                        node: (
                                            <TextareaInput
                                                cy="invitation-message"
                                                initialValue={
                                                    account?.name
                                                        ? // eslint-disable-next-line max-len
                                                          `You have been invited to join a merchant account: ${account.name} (${account.merchantId})`
                                                        : ''
                                                }
                                                placeholder="-"
                                                overBackground="front.background"
                                                isSimple
                                                ref={(ref) => formRef(ref, 'message', yup.string())}
                                            />
                                        )
                                    }
                                }}
                                switchToRowsAt={10000}
                                cellHorizontalTemplate="100px minmax(auto, 240px)"
                            />
                            <Spacer height={10} />
                            <CardSection background="subtleBlue">
                                <CardInset reduceVerticalPadding>
                                    <Flex justify="flex-end">
                                        <WatcherButton
                                            background="front.accent.color"
                                            predefinedWatcher={watcherId}
                                            hotkeysScope="Merchant.AccountsPage.Account.NewMember"
                                            hotkeys="alt+enter"
                                            onClick={submitHandler(onSubmit)}
                                            isDisabled={!!Object.keys(errors || {}).length}
                                        >
                                            <ButtonInset>Invite member</ButtonInset>
                                        </WatcherButton>
                                    </Flex>
                                </CardInset>
                            </CardSection>
                        </CardInset>
                    </Card>
                    <Spacer height={120} />
                </Flex>
            </ModalPageInset>
        </ModalPage>
    )
}
